//Rich text notes input
.comment-input-container {
  width: 100%;
  height: 100%;
  background-color: #f8f8fa;
  padding: 10px;
  border-radius: 10px;
  position: relative; // for private & public switcher

  .private-note-switcher {
    position: absolute;
    top: 44px;
    right: 10px;
    cursor: pointer;
    z-index: 1;
  }

  .comment_input_wrapper {
    display: flex;
    align-items: flex-end;
    gap: 5px;
    flex-direction: column;
  }

  .new_comment_wrapper {
  }

  //Edit comment
  .edit_comment_wrapper {
    // padding: 20px 0px 20px 20px;
    margin: -10px;

    .update-close-icons {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      gap: 5px;
      padding: 5px;
      span {
        padding: 5px;
        font-size: 22px;
        border-radius: 5px;
      }

      .anticon-check:hover {
        background: #71cf48;
        color: #fff !important;
      }

      .anticon-close:hover {
        background: #fe4c4a;
        color: #fff !important;
      }
    }
  }

  .rich-editor-description {
    flex: 1;
  }

  .react-quill-button {
    height: fit-content;
    .mondayButtonContainer {
      svg {
        width: 15px;
        height: 15px;
        transform: rotate(180deg); // Rotate the icon by 45 degrees
      }
    }
  }
}

.CommentInputDark {
  background-color: #12131b;

  .private-note-switcher {
    .ant-switch-handle::before {
      background-color: #414255;
    }
    .ant-switch-inner {
      .anticon {
        color: #414255;
      }
    }
  }
}
