@import "../../../../../../index.scss";

.stepHeaderContainer {
  $border-style: 1px solid #ececec;
  @include flex(center, center, column);
  width: 100%;
  margin: 20px 0;
  .stepTitle {
    width: 100%;
    @include flex(center, center);
    @include text(18px, 600, 0, #323232);
    padding: 2px 0;
  }
  // .borderedTitle {
  //   border-top: $border-style;
  //   border-bottom: $border-style;
  // }
  .stepSubtitle {
    width: 100%;
    @include flex(center, center);
    @include text(16px, 400, 0, #323232);
    padding-top: 5px;
  }
}
