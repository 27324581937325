.agGridContainer {
  // height: 100% !important;

  .dynamicBasePage {
    height: 100% !important;
  }
}
.invoiceAgGrid {
  // height: inherit;
  height: 100%;
  .ag-row {
    cursor: pointer;
  }
}
