@import "../../../../../../index.scss";

.video-transcription {
  display: flex;
  flex-direction: column;
  background-color: #f5f5f7;
  padding: 8px 12px;
  border-radius: 8px;
  box-shadow: "0 2px 4px rgba(0, 0, 0, 0.1)";

  .section {
    margin-bottom: 10px;
    padding: 5px;
    background-color: transparent;

    .text {
      padding: 4px 2px;
      border-radius: 5px;
      color: #323338;
      cursor: pointer;
    }
  }

  .active-section {
    background-color: #f1f5fd;
    .text {
      color: #1264a3;
    }
  }
}

.video-transcriptionDark {
  background-color: $cardDarkBackgroundColor;

  .section {
    .text {
      color: #323338;
    }
  }

  .active-section {
    background-color: transparent;
    .text {
      color: #fff;
    }
  }
}
