.switcher-container {
  width: 100%;
  min-height: 32px;
  border-radius: 20px;
  padding-left: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  gap: 5px;

  .switch-label {
    width: 100%;
    overflow: hidden;
    color: #fff;
    font: normal normal normal 14px Open Sans;
    display: flex;
    align-items: center;
  }
  .switcher {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;

    .ant-switch {
      background-color: white !important;
    }
  }
  .switcher-true {
    .ant-switch {
      .ant-switch-handle::before {
        background-color: #01c06a;
      }
    }
  }
  .switcher-false {
    .ant-switch {
      .ant-switch-handle::before {
        background-color: #fe4c4a;
      }
    }
  }
}
