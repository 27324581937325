$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.serviceDocumentation {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 5px;
  background: #1264a3;
  cursor: pointer;
  border: none;
  color: white;
  margin-top: 5px;
}

.serviceDocumentationModal {
  --smallPadding: 0.625rem;
  --mediumPadding: 1.25rem;

  .ant-modal-body {
    padding-bottom: var(--mediumPadding) !important;
    height: fit-content;
    max-height: 85vh;
    max-width: 60vw;
    overflow-y: auto;

    .ant-modal-close-x {
      margin-right: 10px;
    }

    .allEstimations {
      display: flex;
      flex-wrap: wrap;
      gap: var(--smallPadding);

      .allServices {
        background: #f5f5f7;

        padding: var(--smallPadding);
        flex: 1;
        display: flex;
        flex-direction: column;
        border-radius: var(--smallPadding);

        align-items: center;

        .title {
          font-weight: 600;
        }

        .services {
          display: flex;
          gap: var(--smallPadding);
          .uploadButton {
            border-radius: 5px;
            background: white;
            color: black;
            flex: 1;
            font-weight: 400;
            border: none;
            box-shadow: none;
            &.selected {
              background: #1264a3;
              color: white;
            }
          }
        }

        // span {
        //   font-weight: 600;
        // }
      }
    }

    // min-width: 700px;
  }

  .ant-modal-footer {
    padding: 10px 20px !important;

    .footerModal {
      width: 100%;
      display: flex;

      &.twoButtons {
        justify-content: space-between;
      }

      &.oneButton {
        justify-content: center;
      }
      padding-bottom: 10px;
    }
  }
  .serviceDocumentationContainer {
    display: flex;
    flex-direction: column;
    gap: var(--mediumPadding);
    .serviceDocumentationView {
      display: grid;
      grid-template-columns: repeat(3, 298px);
      grid-template-rows: 1fr;
      grid-auto-flow: dense;
      justify-content: center;
      gap: var(--mediumPadding);

      .gridSpan1 {
        grid-column: span 1;
        .future {
          &:first-child {
            margin-top: 5px;
          }

          &:last-child {
            margin-bottom: 5px;
          }
          box-shadow: #fe4c4a 0px 0px 3px 1px;
          margin: 0px 3px;
        }
      }

      .gridSpan3 {
        grid-column: span 3;
        .future {
          box-shadow: #fe4c4a 0px 0px 3px 1px;
          margin: 3px 0px;
        }
      }

      .folderSection {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: var(--smallPadding);
        height: fit-content;
        max-height: 39rem;

        overflow-y: auto;

        &::-webkit-scrollbar {
          display: none;
          width: 0px;
        }

        .folderContainer {
          flex: 1;
          max-width: 298px;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: row;
          gap: var(--smallPadding);
          padding: var(--smallPadding);
          height: 63px;
          // width: 298px;

          flex-shrink: 0;
          border-radius: 5px;
          background: #f8f8fa;
          cursor: pointer;

          .folderName {
            font-size: 0.875rem;
            display: flex;
            flex-direction: column;
            &:not(:first-child) {
              margin-left: 5px;
            }
            b {
              font-size: 1rem;
              font-weight: 600;
            }
            span {
              font-weight: 500;
            }
          }
          .uploadButton {
            background: #1264a3;
            width: 8rem;
            height: 2rem;
          }
        }
        // .future {
        //   &:first-child {
        //     margin-top: 5px;
        //   }

        //   &:last-child {
        //     margin-bottom: 5px;
        //   }
        //   box-shadow: #fe4c4a 0px 0px 3px 1px;
        //   margin: 0px 3px;
        // }
        .disabled {
          justify-content: flex-start;
        }
        .selected {
          border: 1px solid #1264a3;
          background: #f1f5fd;
          box-shadow: unset;
        }
      }
      .imagesContainer {
        display: flex;
        flex-direction: column;
        gap: var(--smallPadding);
        max-height: 39rem;
        max-width: 954px;

        &.gridSpan3 {
          grid-column: span 3;
        }
        &.gridSpan1 {
          grid-column: span 2;
        }

        .carouselContainer {
          display: flex;
          flex-direction: column;
          justify-content: center;

          .allImages {
            display: flex;
            max-width: 100%;
            justify-content: flex-start;
            padding: 10px 10px 20px 10px;

            overflow-x: auto;
            overflow-y: hidden;
            &::-webkit-scrollbar {
              width: 5px !important;
              height: 5px;
            }

            img {
              cursor: pointer;
              width: 72px;
              height: 82px;
              object-fit: cover !important;
            }
          }
          &.active {
            min-height: 400px;
          }

          &.empty {
            min-height: 300px;
            height: 100%;
            display: flex;
            color: black;
            font-weight: 600;
            gap: var(--smallPadding);
            flex-direction: column;
          }

          color: #fff;
          border-radius: 10px;
          background: #f8f8fa;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;

          .carousel-slider-wrapper {
            border-radius: 10px;
            height: 100%;
            .carousel-slide {
              height: 100%;
            }
          }

          .ant-image {
            padding: 10px;
            width: 100%;
            height: 100%;
          }
          img {
            max-height: calc(39rem);
            height: 490px;
            object-fit: cover !important;
            border-radius: 5px;
          }
        }
      }
    }

    .serviceDocumentationInfo {
      display: flex;
      align-items: center;
      font-weight: 500;
    }
  }
  .footer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.serviceDocumentationModalDark {
  .serviceDocumentationContainer {
    .serviceDocumentationInfo {
      color: #fff;
    }
    .serviceDocumentationView {
      .folderSection {
        .folderContainer {
          background-color: $cardModalHeaderDark;
          .ant-badge {
            sup {
              box-shadow: 0 0 0 1px $cardModalHeaderDark;
            }
            svg path {
              fill: #fff;
            }
          }
          .folderName {
            color: #fff;
          }
        }
        .selected {
          background-color: #252535;
        }
      }
      .imagesContainer {
        .carousel-slide,
        .ant-image {
          background-color: $cardModalBodyDark;
        }
        .carouselContainer {
          background-color: $cardModalBodyDark;
          svg g path {
            fill: $cardModalHeaderDark;
          }
          span {
            color: #fff;
          }
        }
      }
    }
  }
}
