$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.newAddDepartmentModal {
  width: fit-content !important;
  @media screen and (min-width: 1600px) {
    width: 800px !important;
  }
  .ant-modal-content {
    .ant-modal-body {
      display: flex;
      flex-direction: column;
      gap: 20px;
      .dep-form-container {
        display: flex;
        justify-content: center;
        gap: 10px;
        @media screen and (min-width: 1024px) {
          .labeledInputContainer {
            width: 300px;
          }
          .select-department {
            width: 300px;
          }
        }
        > :first-child {
          margin-right: 30px;
        }
        .select-department {
          min-width: 150px;
          height: 32px;
        }
      }
      .role-viewer {
        border-radius: 5px;
        background-color: #f8f8fa;
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 20px;
        .role-viewer-header {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 5px;
          font-size: 24px;
          font-weight: 600;
        }
        .tags-container {
          display: flex;
          gap: 10px;
          justify-content: center;
          flex-wrap: wrap;
          .role-tag {
            display: flex;
            gap: 5px;
            font-size: 14px;
            align-items: center;
            border-radius: 5px;
            background-color: #fff;
            padding: 5px;
            height: 28px;
            border: 1px solid #fff;
            cursor: default;
            svg {
              height: 8px;
              width: 8px;
              cursor: pointer;
            }
            &:hover {
              border-color: #fe4c4a;
              color: #fe4c4a;
              svg {
                fill: #fe4c4a;
              }
            }
          }
        }
      }
    }
    .ant-modal-header {
      height: 45px;
      display: flex;
      align-items: center;
      background-color: #f8f8fa;
      overflow: hidden;
      border: none;

      .ant-modal-title {
        font-size: 16px;
        width: 100%;
        font-weight: 600;
        color: #323338;
        padding: 0 !important;
        height: 45px;
        display: flex;
        align-items: center;
      }
    }
    .ant-modal-footer {
      padding-bottom: 20px !important;
      > :first-child {
        display: flex;
        justify-content: space-between;
        @media screen and (min-width: 1600px) {
          button {
            width: 150px;
          }
        }
      }
    }
  }
}

.newAddDepartmentModalDark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      .role-viewer {
        background-color: #363746;
        color: #fff;
        .role-viewer-header svg path {
          fill: #fff;
        }
        .role-tag {
          background-color: #fff;
          color: #363746;
          svg {
            fill: #363746;
          }
        }
      }

      .labeledInputLabel {
        color: #fff;
      }
      .ant-select {
        .ant-select-selector {
          background-color: $inputModalDark !important;
          .ant-select-selection-search-input {
            color: #fff;
          }
          .ant-select-selection-item {
            color: #fff;
          }
          .ant-select-selection-placeholder {
            color: #acacca;
          }
        }
        .ant-select-clear {
          background-color: $inputModalDark;
          svg {
            fill: #acacca;
          }
        }
        .ant-select-arrow svg {
          fill: #fff;
        }
      }
      .ant-select-disabled {
        .ant-select-selector {
          .ant-select-selection-search-input {
            color: #acacca !important;
          }
          .ant-select-selection-item {
            color: #acacca !important;
          }
        }
      }
    }
  }
}
