@import "../../../index.scss";

.quillTextEditor {
  @include flex(flex-start, flex-start, column);
  width: inherit;

  .ql-container {
    width: 100%;
  }
}
