@import "../../../../../../index.scss";

.hoist-price-calculator-modal-wrapper {
  top: 0 !important;
  padding: 0 !important;
  width: 100% !important;
  height: 100% !important;
  margin: 0;
  max-width: 100%;
  overflow: hidden;
  .ant-modal-body {
    padding: 10px !important;
    width: 100vw !important;
  }
}

.hoist-price-calculator-modal-content {
  max-height: 90vh;
  padding: 10px;
  overflow-y: auto;

  .pricing-section {
    @include flex(flex-end, center, column);
    gap: 10px;
  }

  .footer-section {
    @include flex(center, space-between);
    margin-top: 10px;
    width: 100%;
  }

  .input-divider {
    height: 30px;
    width: 1px;
    background-color: #323338;
    margin-bottom: 24px;
  }

  .total-cards-container {
    margin-top: 10px;
    display: grid;
    // grid-template-columns: 1fr 1fr;
    gap: 10px;
  }

  .labeledInputContainer {
    @include flex(baseline, center);
    gap: 10px;

    .ant-form-item {
      min-width: 200px;
    }
  }

  .hoist-price-scheme-selector {
    position: absolute;
    top: 6.5px;
    right: 55px;

    .ant-select-selector {
      background-color: #1264a3 !important;
      color: #fff;
      border: 0 !important;
      border-radius: 5px !important;
    }
    .ant-select-arrow {
      color: #fff;
    }
  }

  .ant-form-item-label > label {
    @include text(16px !important, 600, 0, #323338);
  }
}
