.pick-req-wrapper {
	margin-top: 20px;
	display: flex;
	justify-content: center;

	.req-checkable-el {
		width: 100%;
		display: flex;
		justify-content: flex-start;
		color: #fff;
		align-items: center;
	}
	h3 {
		color: #fff;
	}
}

label.ant-checkbox-wrapper.credit-modal-checkbox {
	.ant-checkbox-inner {
		width: 25px;
		height: 25px;
		background: #e6e6ee 0% 0% no-repeat padding-box;
		border-radius: 2px;
		border: none !important;
	}
	span.ant-checkbox.ant-checkbox-checked {
		.ant-checkbox-inner {
			background: #1264a3 0% 0% no-repeat padding-box;
		}

		.ant-checkbox-inner:after {
			width: 8px;
			height: 15px;
		}
	}
}
.req-checkable-el {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	color: #fff;
	align-items: center;
}
