.descriptionModal {
  .ant-modal-title {
    color: white;
  }
  .ant-modal-content {
    width: 1200px;
    margin-right: auto;
    margin-left: auto;
  }
  .ant-modal-body {
    padding: 10px 15px !important;

    // #tiny-react_18568194721670961017240_ifr {
    //   background: inherit;
    //   color: white;
    //   border: 1px solid white;
    // }
    // .mce-content-body {
    //   color: white !important;
    // }
  }
  .ant-modal-close-x {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    svg,
    path {
      fill: white;
    }
  }
  .includesContainer {
    display: flex;
    gap: 20px;
    span {
      color: white;
    }
  }
  .tox-tinymce {
    height: 500px !important;
  }
  .richTextComp {
    background-color: inherit !important;
    .tox-toolbar-overlord {
      background-color: inherit !important;
      .tox-tbtn__icon-wrap {
        svg {
          fill: white !important;
        }
      }
      .tox-tbtn {
        color: white;
        .tox-tbtn__select-chevron {
          svg {
            fill: white;
          }
        }
      }
    }
  }
}
