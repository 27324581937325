.trips-picker-form {
  .time-select-section {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    gap: 10px;
  }
  .ant-form-item-control-input-content {
    width: max-content;

    .ant-picker {
      width: 100%;
    }
  }

  .buttons-section {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
  }
}

.trips-picker-form.trips-picker-form-dark {
  .ant-select-selector {
    background-color: #3a3c4e !important;
    color: #f5f5f7 !important;
    border: 0;
  }
  .ant-select-arrow,
  .ant-picker-suffix {
    color: #f5f5f7 !important;
  }

  .ant-select-arrow {
    svg {
      fill: #f5f5f7 !important;
    }
  }

  .anticon {
    color: #fff;
    background-color: #3a3c4e;
  }

  .ant-select-selection-placeholder {
    color: #acacca;
  }

  input::placeholder {
    color: #acacca;
  }

  .ant-picker {
    background-color: #3a3c4e !important;
    color: #f5f5f7 !important;
    border: 0;

    .ant-picker-input {
      input {
        color: #f5f5f7 !important;
      }
    }
  }
}
