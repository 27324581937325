.sovPickerContainer {
  height: 320px;
  width: 305px;
  overflow-x: hidden;
  overflow-y: auto;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0px 1px 4px #00000029;
  .ant-radio-wrapper {
    font-size: 15px !important;
  }

  .title {
    font-weight: bold;
    font-size: 18px;
    margin: 0 0 5px 5px;
  }

  .ant-radio-wrapper {
    height: 40px;
    width: 285px;
    overflow: hidden;
    display: flex;
    align-items: center;

    &:hover {
      background-color: #d3d3d3;

      .ant-radio-inner {
        border: 10px solid #fe4c4a !important;
        background-color: #e03d3a;
        margin: 0px !important;
        border-radius: 0 !important;
        height: 40px;
        width: 40px;
      }
    }

    .ant-radio {
      height: 100%;
      width: 40px;
      margin: 0;
      margin-top: -5px !important;
    }
    .ant-radio-inner {
      border: 4px solid #fe4c4a !important;
      background-color: #e03d3a;
      margin: 0px !important;
      border-radius: 0 !important;
      height: 100%;
      width: 0%;
      &:hover {
        border: 10px solid #fe4c4a !important;
        background-color: #e03d3a;
        margin: 0px !important;
        border-radius: 0 !important;
        height: 40px;
        width: 40px;
        position: relative;
      }
      &::after {
        width: 0;
        height: 0;
      }
    }
  }

  .ant-radio-wrapper-checked {
    position: relative;
    &:hover {
      &::after {
        border: 10px solid #68c142 !important;
      }
    }

    &::after {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 10px solid #71cf48 !important;
      content: url("../../../../../../../../pages/Settings/settingsComponents/Roles/src/Tick.svg");
      fill: black !important;
      background-color: white;
      width: 40px;
      height: 40px;
      border-radius: 0px;
      position: absolute;
      z-index: 5;
    }
  }
}

// schedule details view

.ScheduleDetailsView {
  height: 360px;
  .ScheduleDetails-container {
    background: #fff;
    margin: 20px;
    height: 340px;
    display: flex;
    border-radius: 15px;
    box-shadow: 0px 1px 4px rgb(0 0 0 / 16%);
    .item-label-contanier {
      width: 25%;
      height: 100%;
      background: #12131b;
      overflow: auto;
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;
      .item-label {
        padding: 5px;
        cursor: pointer;
        font-size: 19px;
        font-weight: 500;
        margin-top: 5px;
        margin-bottom: 5px;
        background: #1f212d;
        color: #fff;
      }
      .item-label-active {
        padding: 5px;
        cursor: pointer;
        font-size: 19px;
        font-weight: 500;
        background: #4a4b55;
        margin-top: 5px;
        margin-bottom: 5px;
        color: #fff;
      }
    }
    .notes-content {
      width: 75%;
      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;
      background: #1f212d;
      padding: 15px;
      .name-div {
        font-size: 20px;
        color: #fff;
        width: 100%;
        .name-content {
          background: #fff;
          width: 100%;
          border-radius: 5px;
          color: #1f212d;
          margin-bottom: 10px;
          height: 31.42px;
        }
        .name-content-desc {
          background: #fff;
          width: 100%;
          border-radius: 5px;
          color: #1f212d;
          height: 120px;
          overflow: auto;
        }
      }
      .select-service {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
      }
    }
  }
}

.ScheduleDetailsViewLight {
  height: 360px;
  .ScheduleDetails-container {
    background: #fff;
    margin: 20px;
    height: 340px;
    display: flex;
    border-radius: 15px;
    box-shadow: 0px 1px 4px rgb(0 0 0 / 16%);
    .item-label-contanier {
      width: 25%;
      height: 100%;
      background: #1264a3;
      overflow: auto;
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;
      .item-label {
        padding: 5px;
        cursor: pointer;
        font-size: 19px;
        font-weight: 500;
        margin-top: 5px;
        margin-bottom: 5px;
        // background: #1f212d;
        color: #fff;
      }
      .item-label-active {
        padding: 5px;
        cursor: pointer;
        font-size: 19px;
        font-weight: 500;
        background: #299af0a4;
        margin-top: 5px;
        margin-bottom: 5px;
        color: #fff;
      }
    }
    .notes-content {
      width: 75%;
      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;
      background: #1264a3;
      padding: 15px;
      .name-div {
        font-size: 20px;
        color: #fff;
        width: 100%;
        .name-content {
          background: #fff;
          width: 100%;
          border-radius: 5px;
          color: #1f212d;
          margin-bottom: 10px;
          height: 31.42px;
        }
        .name-content-desc {
          background: #fff;
          width: 100%;
          border-radius: 5px;
          color: #1f212d;
          height: 120px;
          overflow: auto;
        }
      }
      .select-service {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
      }
    }
  }
}
