@import "../../../../Communication/Communication.scss";

.emailItemComponent {
  transition: max-height 5s ease-out;

  .single-email-view-rich-editor-container {
    margin: 12px 0px;
    padding-left: 70px;
  }


  @media screen and (max-width:767px) {
    .fromEmail {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100px;
    }

    .EmailInformationRight {
      padding-right: 0px !important;
    }

    .EmailInformation {
      padding: 0px 10px;
    }
  }

  .EmailInformation {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    cursor: pointer;
    padding: 0px 20px;

    @media screen and (max-width:767px) {
      padding: 0px 10px;

    }

    &.last {
      cursor: default;
    }




    .EmailInformationLeft {
      display: flex;
      justify-content: flex-start;
      max-width: calc(100% - 220px);

      .userLetter {
        min-width: 40px;
        max-height: 40px;
        font-size: 24px;
        font-weight: bold;
        background-color: #7532a0;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
      }

      .emailParts {
        display: flex;
        flex-direction: column;
        padding: 0 10px 0 10px;
        margin-right: 20px;
        margin-bottom: 2px;
        max-width: 100%;

        .fromEmail {
          font-weight: bold;
          font-size: 0.875rem;
          letter-spacing: 0.2px;
          line-height: 20px;



        }

        .toEmail {
          @extend .fromEmail;
          font-weight: unset;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 900px;
        }
      }
    }

    .EmailInformationRight {
      display: flex;
      align-items: center;
      gap: 20px;

      svg {
        color: $icon-color;
        fill: $icon-color
      }



      .threeDots {
        cursor: pointer;
      }
    }
  }

  .mainEmailBody {
    opacity: 1;
    transition: opacity 0.3s ease-out;
    padding-left: 70px;
    padding-top: 20px;
    height: relative;

    @media screen and (max-width:767px) {
      padding-left: 10px;
      padding-top: 10px;
    }


  }






  .EmailInformationRight {
    svg {
      color: $dark-text-color;
      fill: $dark-text-color;
    }
  }
}

.emailItemComponentCollapsed {
  @extend .emailItemComponent;
  height: 50px;
  max-height: 50px;
  transition: height 5s ease-in;

  .mainEmailBody {
    height: 0px;
    overflow: hidden;
    opacity: 0;
    transition: opacity 0.3s ease-out;
  }
}

.divider {
  border: 1px solid $border-color;
  margin: 10px 0px;
}