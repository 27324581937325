@import "../../../../../../../../../../../../../index.scss";

//light mode
.serviceDetailsCardHeaderLight {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f8f8fa;
  height: 45px;
  padding: 0 50px 0 20px;
  border-bottom: 0;
  .service-details-card-header-title {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 1.5rem;
  }

  .service-details-card-header-title-color {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }

  .service-details-card-header-options {
    margin-left: 5px;
    display: flex;

    .service-details-card-header-option {
      margin-left: 15px;
      @include mondayButton(#1264a3, #0f5c97, #fff);
    }
  }
}

//dark mode
.serviceDetailsCardHeaderDark {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #12131b !important;
  color: #f8f8fa;
  height: 45px;
  padding: 0 50px 0 20px;
  border-bottom: 0;
  .service-details-card-header-title {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 1.5rem;
  }

  .service-details-card-header-title-color {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }

  .service-details-card-header-options {
    margin-left: 5px;
    display: flex;

    .service-details-card-header-option {
      margin-left: 15px;
      @include mondayButton(#1264a3, #0f5c97, #fff);
    }
  }
}
