.driver-link-no-driver {
  height: fit-content;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 2rem 0;
  gap: 10px;
}

.card-view-logs-modal {
  height: fit-content !important;
  width: fit-content !important;
  padding: 0;
  .ant-modal-content {
    padding: 0;
    height: fit-content !important;
    width: fit-content !important;
    .ant-modal-body {
      padding: 0;
      height: fit-content !important;
      width: fit-content !important;
    }
  }
  .DifferenceModalContainer {
    position: relative;
    top: 0;
    left: 0;
  }
}

.driver-link-dark {
  color: #fff;
}
