.assistant-chat-body {
  background-color: #fff;
  overflow-y: auto;
  padding: 10px 20px 0px 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 20px;

  .default-message-container {
    display: grid;
    grid-template-columns: calc(100% - 54px) 24px;
    grid-template-rows: auto;
    column-gap: 10px;
    padding: 12.5px 10px;
    place-content: center;
    width: 100%;
    background-color: #f4f5f6;
    border-radius: 5px;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
    cursor: pointer;

    &:hover {
      background-color: #f1f5fd;
      color: #1264a3;
    }
  }

  .arrow-container {
    display: grid;
    place-items: center;
    color: inherit;
    svg,
    path {
      fill: currentColor;
    }
  }

  .time-fragment-separator {
    width: calc(100% - 40px);
    display: grid;
    place-items: center;
    color: #231f20;
    position: relative;
    font-weight: 400;
    font-size: 12px;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      border-top: 1px dashed #acacaa;
      height: 0px;
      width: calc(50% - 45px);
    }

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: 0;
      border-top: 1px dashed #acacaa;
      height: 0px;
      width: calc(50% - 45px);
    }
  }
}

.assistant-chat-body.default-messages {
  gap: 10px;
}
