$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.detailsViewModal {
  width: calc(100vw - 20px) !important;
  max-width: 1500px;
  .ant-modal-content {
    width: calc(100vw - 20px) !important;
    max-width: 1500px;
    .ant-modal-close {
      height: 45px;
      width: 45px;
      .ant-modal-close-x {
        height: 45px;
        width: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          fill: #333238;
        }
      }
    }
    .ant-modal-header {
      height: 45px;
      display: flex;
      align-items: center;
      background-color: #f5f5f7;
      .ant-modal-title {
        font-size: 16px;
        font-weight: 600;
        color: #333238;
      }
    }
    .ant-modal-body {
      .ant-form {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .labeledInputContainer {
          min-width: 250px;
        }
      }
      .controlPanel {
        display: flex;
        justify-content: flex-end;
        gap: 10px;
        .mondayButtonContainer {
          .mondayButtonText {
            display: flex;
            align-items: center;
          }
        }
        .exportActions {
          display: flex;
          gap: 10px;
        }
      }
      .deg-grid-container {
        .ag-layout-normal {
          min-height: 15rem;
        }
      }
      .overallData {
        display: flex;
        justify-content: space-between;
        .dataContainer {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          .dataLabel {
            font-weight: 600;
          }
        }
      }
    }
    .ant-modal-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .mondayButtonStyle {
        width: 150px;
      }
    }
  }
}

.detailsViewModalDark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      .overallData {
        .dataContainer {
          label, p {
            color: #fff;
          }
        }
      }
      .labeledInputLabel {
        color: #fff;
      }
      .ant-select {
        .ant-select-selector {
          background-color: $inputModalDark !important;
          .ant-select-selection-overflow-item {
            .ant-select-selection-item {
              background-color: #333238;
              .ant-select-selection-item-remove svg {
                fill: #fff;
              }
            }
          }
          .ant-select-selection-search-input {
            color: #fff;
          }
          .ant-select-selection-item {
            color: #fff;
          }
          .ant-select-selection-placeholder {
            color: #acacca;
          }
        }
        .ant-select-clear {
          background-color: $inputModalDark;
          svg {
            fill: #acacca;
          }
        }
        .ant-select-arrow svg {
          fill: #fff;
        }
      }
      .ant-select-disabled {
        .ant-select-selector {
          .ant-select-selection-search-input {
            color: #acacca !important;
          }
          .ant-select-selection-item {
            color: #acacca !important;
          }
        }
      }
      .ant-picker {
        background-color: $inputModalDark !important;
        .ant-picker-input {
          background-color: $inputModalDark;
          input {
            color: #f2f2f2;
            &::placeholder {
              color: #acacca;
            }
          }
          .ant-picker-suffix svg {
            color: #acacca;
          }
          .ant-picker-clear {
            background-color: $inputModalDark;
            svg {
              fill: #acacca;
            }
          }
        }
      }
      .ant-picker-disabled {
        .ant-picker-input input {
          color: #acacca !important;
        }
      }
      .deg-grid-container {
        .ag-root-wrapper {
          background-color: $cardModalBodyDark;
          .ag-header {
            background-color: $cardModalBodyDark;
            .ag-header-row {
              border-top: none;
            }
            .ag-header-cell-resize::after {
              width: 1px !important;
              background-color: #4a4a4a;
            }
          }
          .ag-paging-panel {
            background-color: $cardModalBodyDark;
          }
        }
      }
    }
  }
}
