$mainDarkBackgroundColor: #20212d;
$cardDarkHeaderColor: #2a2b3a;
$cardDarkBackgroundColor: #12131b;
$inputDarkColor: #2a2b3a;
.logs-session-drawer {
  .ant-drawer-header {
    .session-drawer-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      .site-badge-count {
        cursor: pointer;
      }
    }
  }
  .ant-drawer-body {
    padding: 0;
    display: flex;
    flex-direction: column;
    .session-logs-quick-filters {
      height: 50px;
      align-items: center;
      display: flex;
      gap: 15px;
      border-bottom: 1px solid #dee2eb;
      padding: 0 10px;
      .quick-filters-text {
        font-size: 16px;
        font-weight: 600;
      }
      .quick-filters-container {
        display: flex;
        gap: 10px;
        .quick-filters-item {
          height: 32px;
          min-width: 80px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 5px;
          border: 1px solid #acacac;
          cursor: pointer;
        }
      }
    }
    .session-logs-body {
      flex: 1;
      max-height: calc(100vh - 160px);
      overflow-y: auto;
      .session-log-item {
        min-height: 30px;
        font-size: 14px;
        padding: 10px;
        &:nth-child(odd) {
          background-color: #fcfcfc;
        }
        &:nth-child(even) {
          background-color: #fff;
        }
        &:last-child {
          border-bottom: none;
        }
        &:hover {
          cursor: pointer;
          background-color: #f1f5fd;
        }
      }
      .new-log {
        background-color: #ffe4ce !important;
      }
    }
    .ant-pagination {
      padding: 10px;
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }
}

.logs-session-drawer-dark {
  .ant-drawer-header {
    background-color: $cardDarkHeaderColor;
    border-bottom: 1px solid $mainDarkBackgroundColor;
    .ant-drawer-title {
      color: #fff;
    }
    svg {
      fill: #fff;
    }
  }
  .ant-drawer-body {
    background-color: $cardDarkBackgroundColor;
  }
}
