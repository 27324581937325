@import "../../../index.scss";
:root {
  --content-dynamic: "";
}

.switch-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.switch-toggle {
  position: relative;
  width: 120px;
  display: inline-block;
  text-align: left;
}
.switch-toggle-input {
  display: none;
}
.switch-toggle-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 0 solid #bbb;
  border-radius: 20px;
  margin-bottom: 0;
  height: 32px;
}
.switch-toggle-label-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}
.switch-toggle-label-inner:before,
.switch-toggle-label-inner:after {
  float: left;
  width: 50%;
  height: 32px;
  padding: 0;
  line-height: 32px;
  color: #fff;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 32px;
  box-sizing: border-box;
  // display: flex;
  // align-items: center;
}
.switch-toggle-label-inner:before {
  content: var(--content-dynamic);
  padding-left: 10px;
  background-color: #68c142;
  color: #fff;
  text-align: left;
}
.switch-toggle-label-inner:after {
  content: var(--content-dynamic);
  padding-right: 10px;
  background-color: #fe4c4a;
  color: #fff;
  text-align: right;
}
.switch-toggle-label-switch {
  display: block;
  width: 22px;
  height: 22px;
  margin: 5px;
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 89px;
  border: 0 solid #bbb;
  border-radius: 50%;
  transition: all 0.3s ease-in 0s;
}
.switch-toggle-input:checked + .switch-toggle-label .switch-toggle-label-inner {
  margin-left: 0;
}
.switch-toggle-input:checked
  + .switch-toggle-label
  .switch-toggle-label-switch {
  right: 0px;
}
