.smallContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  padding-top: 20px;
  .IconStyle {
    border-radius: 50%;
    background-color: #323338;
    width: 55px;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .labelStyle {
    margin-left: 20px;
    text-align: left;
    font: normal normal bold 24px/33px Open Sans;
    letter-spacing: 0px;
    color: #323338;
    opacity: 1;
  }
  .lastChangeStatus {
    font-size: 24px;
    letter-spacing: 0;
    color: #323338;
    margin-left: 20px;
  }
   .lastChangeStage {
    font-size: 24px;
    letter-spacing: 0;
    color: #323338;
    margin-left: 20px;
  }
  .lastChangeServiceStatus {
    font-size: 24px;
    letter-spacing: 0;
    color: #323338;
    margin-left: 20px;
  }
}
