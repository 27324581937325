@import "../../Communication.scss";

.reply-emailbox-container {
  display: flex;
  gap: 10px;
  padding: 10px 0 10px 0;
  position: relative;

  &.one-attachment {
    .ant-upload-list.ant-upload-list-text {
      bottom: -20px !important;
    }
  }

  @media screen and (max-width: 767px) {

    .reply-fwd-container {
      flex-direction: column;
    }

    .reply-email-box {
      overflow-y: auto;
      gap: 4px;
    }

    .mondayButtonText {
      display: block;
    }


    .ant-select-selector,
    .ant-select {
      max-width: 170px;
      min-width: 170px;
    }

    .cc-bcc-triggers-container {
      flex-direction: column;
    }


  }

  .userLetter {
    width: 40px;
    height: 40px;
    font-size: 24px;
    font-weight: bold;
    background-color: #7532a0;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }

  .reply-email-box {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid #dadce0;
    border-radius: 5px;
    min-height: 200px;
    overflow: hidden;

    .reply-emailbox-header {
      display: flex;
      justify-content: space-between;
      padding: 4px;
      overflow-y: auto;

      .reply-fwd-container {
        display: flex;
        justify-content: flex-start;
        gap: 10px;
        width: fit-content;
        height: 100%;

        .reply-all-form-item {
          .ant-select {
            border-bottom: 1px solid #d9d9d9;
            height: 33px;
          }
        }
      }

      .ant-form-item {
        margin-bottom: 0px;
      }

      .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        position: relative;
        border: 0px solid #d9d9d9;
        border-radius: 5px;
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      }

      .ant-select-selection-overflow {
        position: relative;
        display: flex;
        flex: auto;
        flex-wrap: wrap;
        min-width: 100%;
        width: 100%;
        min-width: 50px;

        .ant-select-selection-search {
          width: 100% !important;
        }

        .ant-form-item-explain-error {
          color: #ff4d4f;
          margin-left: 35px;
        }
      }

      .ant-select-focused {
        border: 0px solid white;
      }

      .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
        border-color: white;
        border-right-width: 0px !important;
      }

      .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border-color: white;
        border-right-width: 0px !important;
        outline: 0;
        box-shadow: none;
      }

      .ant-select-arrow {
        top: 16px;
      }
    }


    &:hover,
    &:active {
      box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
        0 2px 6px 2px rgba(60, 64, 67, 0.15);
      border: 1px solid transparent;
    }

  }



  .ant-upload-list.ant-upload-list-text {
    display: flex !important;
    flex-direction: column;
    left: 310px !important;
    height: 60px;
    bottom: 0px !important;
    overflow-x: auto !important;
    max-width: 50%;

    &.one-attachment {
      bottom: -20px !important;
    }

  }






  &.dark-mode {


    .typeStyle {
      .ant-select-selector {
        background-color: $inputDarkColor;
        color: $dark-text-color;

        :focus,
        :active {
          color: $dark-text-color;
        }
      }


      .ant-select-arrow {
        color: #fff;
      }
    }

  }
}

.dropdownClassName {
  width: max-content !important;
}

.emailBoxDropDownStyle {
  min-width: max-content !important;
  width: max-content !important;
}