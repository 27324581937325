@import "../../../../../../index.scss";

.videoCard-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 5px;
  padding: 4px;
  border-radius: 5px;
  background: #f8f8fa;
  align-items: center;
  cursor: pointer;

  .new-video-card-category-info {
    width: 100%;
    padding: 5px;
    display: flex;
    align-items: center;
    gap: 10px;

    .play-icon-container {
      height: 40px;
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 25px;
      color: #fff;
      background-color: #a87878;
      border-radius: 5px;
      padding: 5px;
    }

    .video-information {
      flex-grow: 1;
      width: 70%;

      .card-text-info {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  &.newCategory {
    background-color: #f6872c31;
  }

  &:hover {
    border-radius: 5px;
    background: #f1f5fd;
    color: #1264a3;
  }
}

.videoCard-containerDark {
  background-color: $cardDarkHeaderColor;
  color: #fff;
}

.videoCard-containerPlaying {
  background: #f1f5fd;
  color: #1264a3;
}
