.startConversation {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 550px;
  height: 325px;
  .selectUsersToChat {
    display: flex;
    flex-direction: column;
    span {
      font-weight: 600;
    }
    .ant-select {
      width: 100% !important;
      .ant-select-selector {
        border-radius: 5px;
        border: none;
        background-color: #f4f5f6;
      }
    }
  }
  .btnsContent {
    width: 550px;
    position: absolute;
    bottom: 0;
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
  }
}
