$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.linkExistingUserModalContainer {
  .ant-modal-content {
    border-radius: 10px;
    .ant-modal-header {
      height: 45px;
      display: flex;
      align-items: center;
      background-color: #f8f8fa;
      padding: 0px 20px;
      border-radius: 10px 10px 0 0;

      .ant-modal-title {
        font-weight: 600;
        cursor: default;
      }
    }

    .ant-modal-body {
      padding-top: 2.5rem;
      padding-bottom: 3.5rem;
      display: flex;
      justify-content: center;
      align-items: center;

      .ant-form {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .labeledInputContainer {
        width: 60%;
      }
    }
  }

  .ant-modal-footer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
  }
}

.linkExistingUserModalContainerDark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      .ant-select {
        .ant-select-selector {
          background-color: $inputModalDark !important;
          .ant-select-selection-search-input {
            color: #fff;
          }
          .ant-select-selection-item {
            color: #fff;
          }
          .ant-select-selection-placeholder {
            color: #acacca;
          }
        }
        .ant-select-clear {
          background-color: $inputModalDark;
          svg {
            fill: #acacca;
          }
        }
        .ant-select-arrow svg {
          fill: #fff;
        }
      }

      .ant-select-disabled {
        .ant-select-selector {
          .ant-select-selection-search-input {
            color: #acacca !important;
          }
          .ant-select-selection-item {
            color: #acacca !important;
          }
        }
      }
    }
  }
}
