.boarding-steps {
  height: 80px;
  overflow-y: hidden;
  overflow-x: auto;
  padding: 0 20px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  gap: 28px;

  .boarding-step {
    height: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    position: relative;

    // &:after {
    //   content: "";
    //   position: absolute;
    //   bottom: 0;
    //   width: calc(100% - 0px);
    //   height: 3px;
    //   background: #1264a3;
    //   border-radius: 5px;
    //   box-sizing: border-box;
    //   transition: opacity 0.4s ease;
    //   opacity: 0;
    // }

    .step-container {
      height: 100%;
      display: flex;
      align-items: center;
      gap: 10px;
      cursor: pointer;

      .step-icon {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: transform 0.5s ease-in-out;

        &.shake {
          animation: shake 0.5s;
        }
      }

      .dashboard-icon {
        svg {
          path {
            fill: #323338;
          }
        }
      }

      .step-status {
        width: 112px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        background: rgb(239, 239, 239);
        color: #acacac;
        border: 1px solid #acacac;
      }

      .step-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        font-size: 14px;
        text-wrap: nowrap;

        .step-title {
          font-weight: 600;
        }
        .step-description {
          font-weight: 400;
        }
      }
    }

    .step-arrow {
      position: relative;
      padding-left: 18px;

      // &:after {
      //   content: "";
      //   position: absolute;
      //   bottom: -110%;
      //   left: -7px;
      //   width: 46px;
      //   height: 3px;
      //   background: #fff;
      //   z-index: 9;
      // }
    }
  }
  // .selected-step {
  //   &:after {
  //     opacity: 1 !important;
  //   }
  //   &:hover::after {
  //     opacity: 1;
  //   }
  // }

  &.dark-mode {
    background: #20212d;
  }
}

@media (max-width: 1024px) {
  .boarding-steps {
    height: 87px;
  }
}

@media (max-width: 460px) {
  .boarding-steps {
    height: 60px;
    padding: 10px 10px 0 10px;
    gap: 10px;

    .boarding-step {
      padding: 0;
      gap: 0;
      .step-content {
        .step-title,
        .step-description {
          font-size: 12px !important;
        }
      }
      .step-arrow {
        padding-left: 5px;
      }
    }
  }
}

@keyframes shake {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-5px);
  }
  50% {
    transform: translateY(5px);
  }
  75% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
}
