@import "../../../../../../../index.scss";

$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.newSovModalWrapper {
  .ant-modal {
    width: fit-content !important;
  }
  .ant-modal-content {
    border-radius: 10px;
    overflow: hidden;

    .ant-modal-close-x {
      svg {
        fill: #383838;
      }
    }

    .ant-modal-body {
      padding: 0;
      height: 355px !important;
      overflow-x: hidden;
      overflow-y: auto;
    }

    .ant-modal-footer {
      border-top: 0;
      display: flex;
      justify-content: flex-end;
      padding: 15px;
    }

    .form-content {
      .mainBodyContainer {
        display: flex;
        width: 1200px;
        justify-content: space-between;
      }
    }
  }
}

.newSovModalWrapperDark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      .form-content {
        .chooseFromContainer {
          background-color: $cardModalHeaderDark;
          box-shadow: 0px 1px 4px #ffffff29;
          .choose-from-title {
            color: #fff;
          }
          .estimationButtonPressed {
            background-color: $cardModalHeaderDark;
            color: #fff;
          }
        }
        .mainBodyContainer {
          .estimationHolder {
            box-shadow: 0px 1px 4px #ffffff29;
            background-color: $cardModalHeaderDark;
            .select-options-estimations-title {
              color: #fff;
            }
            .ant-checkbox-group .ant-checkbox-wrapper  {
              background-color: $cardModalBodyDark;
              span {
                color: #fff;
              }
              svg {
                fill: #fff;
              }
            }
          }
          .informationHolder {
            box-shadow: 0px 1px 4px #ffffff29;
            background-color: $cardModalHeaderDark;
            .select-options-information-title {
              color: #fff;
            }
            .titleHolderInformation {
              color: #fff;
            }
            .informationDataHolder {
              .estimationSelected, .titleHolderInformation, .contentHolderInformation {
                color: #fff;
              }
            }
          }
        }
      }
    }
    .ant-modal-footer .sovModalContainer {
      .dropDownButtonNewSOV .ant-btn:disabled {
        border: 1px solid #acacca;
        color: #acacca;
      }
    }
  }
}

@media (max-width: 850px) {
  .newSovModalWrapper {
    .ant-modal-body {
      .chooseFromContainer {
        width: 92%;
      }
      .mainBodyContainer {
        .estimationHolder {
          width: 50%;
          .selectOptionsEstimationsNewSov {
            overflow-x: auto;
          }
        }
        .informationHolder {
          width: 50%;
        }
      }
    }
    .ant-modal-footer {
      .sovModalContainer {
        justify-content: space-between;
        .dropDownButtonNewSOV {
          justify-content: flex-end;
        }
      }
    }
  }
}

@media (max-width: 550px) {
  .newSovModalWrapper {
    .ant-modal {
      max-width: 100vw;
      width: 100% !important;
    }
    .ant-modal-content {
      width: 100% !important;
      .ant-modal-body {
        // height: fit-content;
        .mainBodyContainer {
          width: 100%;
          flex-direction: column;
          align-items: center;
          .estimationHolder {
            width: 92%;
            margin: 10px;
          }
          .informationHolder {
            width: 92%;
            margin: 10px;
          }
        }
      }
    }
  }
}
