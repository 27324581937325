.applicationsBodyResizable {
  width: stretch !important;
  position: relative;
  display: flex;

  //resizable handler style
  .applicationBodyResizableGripContainer {
    position: absolute;
    width: stretch;
    display: flex;
    bottom: 0;
    margin-bottom: -9px;
    transition: opacity 0.3s;
    opacity: 0;
    cursor: grab;

    .leftSize {
      flex: 1;
      height: 3px;
    }
    .grabberContainer {
      height: 9px;
      width: 55px;

      border-radius: 0 0 3px 3px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;

      .gripLines {
        width: 30px;
        height: 1px;
        background-color: white;
      }
    }
    .rightSize {
      flex: 1;
      height: 3px;
    }

    &:hover {
      .leftSize {
        transition: height 0.3s;
        flex: 1;
        height: 9px;
      }
      .grabberContainer {
        height: 9px;
        width: 55px;

        border-radius: 0;
        transition: border-radius 0.3s;
      }
      .rightSize {
        transition: height 0.3s;
        flex: 1;
        height: 9px;
      }
    }
  }

  &:hover {
    .applicationBodyResizableGripContainer {
      opacity: 1;
      transition: opacity 0.3s;
    }
  }

  .applicationAgGridContainer {
    width: stretch;
    height: stretch;
  }
}

.applicationAgGridContainer.dark-ag-theme .ag-cell.ag-cell-range-selected {
  border: 1px solid white !important;
}
.applicationAgGridContainer.grey-ag-theme .ag-cell.ag-cell-range-selected {
  border: 1px solid blue !important;
}
.applicationAgGridContainer {
  .ag-cell-editor.ag-labeled.ag-label-align-left.ag-text-field.ag-input-field {
    color: #000 !important;
  }
}
