.rentalBreakdownHeaderLight {
  height: 45px;
  width: stretch;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 0 20px;
  background-color: #f8f8fa;
  transition: background-color 0.3s ;

  .tittle {
    // margin-left: 20px;
    font-size: 16px;
    color: black;
  }

  .information {
    // margin-left: 20px;
    font-size: 16px;
    color: black;
    display: flex;
    .currencyPercentageContainer {
      margin-left: 5px;
      color: #71cf48;
    }

    .currencyPercentageContainerZero {
      margin-left: 5px;
      color: #eb4345;
    }
  }

  .datePickerInputHeaderRentals {
    background-color: white;
  }
}

.rentalBreakdownHeaderDark {
  height: 45px;
  width: stretch;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 0 20px;
  background-color: #1f212d;
  border-bottom: 1px solid #4a4a4a;
  transition: background-color 0.3s ;
  
  .tittle {
    // margin-left: 20px;
    font-size: 16px;
    color: rgb(199, 199, 199);
  }

  .information {
    // margin-left: 20px;
    font-size: 16px;
    color: white;
    display: flex;
    .currencyPercentageContainer {
      margin-left: 5px;
      color: #71cf48;
    }

    .currencyPercentageContainerZero {
      margin-left: 5px;
      color: #eb4345;
    }
  }
}
