@use "/src/index.scss" as index;

.unmatched-entries-card.card-component {
  width: 100%;
  height: 100%;
  .card-header .custom-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    .ant-form-item {
      margin-bottom: 0px;
      .ant-select-selector {
        background-color: #fff !important;
        .ant-select {
          background-color: #fff !important;
        }
      }
    }
    .mondayButtonWhite {
      @include index.mondayButton(#fff, #e6e9ef, #323338);
    }
    .mondayButtonContainer .ant-btn {
      padding: 0px 5px !important;
    }
  }
  .card-body {
    height: calc(100% - 62px);
    padding: 10px 0px;
    .card-content {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 0px 10px;
      overflow-y: auto;
      .unmatched-card {
        height: 140px;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 10px;
        border-radius: 5px;
        background-color: #f4f5f6;
        animation: cardFadeIn 0.8s ease-out;
        box-shadow: 0px 1px 2px 0px #211f2027;
        .unmatched-header {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          label {
            font-weight: 600;
            user-select: none;
            cursor: pointer;
            &:hover {
              color: index.$blue;
            }
          }
          span {
            color: #acacac;
          }
        }
        .unmatched-body {
          display: grid;
          gap: 5px;
          grid-template-columns: 75% 25%;
          .distance-data,
          .main-data-container {
            width: 100%;
            display: grid;
            gap: 5px;
            .data-row {
              display: flex;
              gap: 20px;
              span {
                font-weight: 600;
              }
            }
          }
        }
      }
      .no-schedule-match {
        box-shadow: 0px 1px 2px 0px #dfab0199;
      }
      .empty-unmatched-entries {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #acacac;
        font-size: 16px;
      }
    }
    .unmatched-count {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0px 20px;
      color: #acacac;
    }
  }
}

.unmatched-entries-card.card-component-dark {
  .card-header .custom-header {
    display: flex;
    gap: 20px;
    .ant-form-item {
      margin-bottom: 0px;
      .ant-select-selector {
        background-color: index.$inputDarkColor !important;
        .ant-select {
          background-color: index.$inputDarkColor !important;
        }
      }
    }
  }
  .card-body {
    .card-content {
      .unmatched-card {
        background-color: index.$mainDarkBackgroundColor;
        .unmatched-header {
          label {
            color: #fff;
          }
          span {
            color: #acacca;
          }
        }
        .unmatched-body {
          color: #fff;
        }
      }
      .empty-unmatched-entries {
        color: #acacca;
      }
    }
    .unmatched-count {
      color: #acacca;
    }
  }
}

@media (max-width: 1024px) {
  .unmatched-entries-card {
    .card-body {
      .card-content {
        .unmatched-body {
          font-size: 11px !important;
          // border: 1px solid red;
        }
      }
    }
  }
}

@keyframes cardFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
