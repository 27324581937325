// @import "../../../../../../index.scss";

$inputModalDark: #12131b;

// .newDriverModalContainer {
//   .labeledInputLabel {
//     @include text(14px, 600, 00.21px, #383554);
//     font-family: "Open Sans";
//     margin-bottom: 2px;
//   }

//   .ant-modal-content {
//     border-radius: 10px;

//     .ant-modal-body {
//       width: 100%;
//       background-color: #fff;
//       border-radius: 0px 0px 10px 10px;
//       padding: 20px 40px 60px;
//       height: 100%;
//       @include flex(flex-start, flex-start, column);
//     }
//   }
//   .newFooterContainer button {
//     width: 150px;
//   }
// }

// .newDriverFormContainer {
//   @include flex(flex-start, flex-start, column);
//   // min-width: 100%;

//   .ant-form {
//     width: 100%;
//   }
//   .labeledInputContainer {
//     margin-top: -24px;
//     margin-right: 15px;
//   }

//   .labeledInputContainer:last-child {
//     margin-right: 0;
//   }
//   .newDriverFormCard {
//     @include flex(flex-start, flex-start, column);
//     border: 0.2px solid rgba(0, 0, 0, 0.2);
//     border-radius: 2px;
//     margin-bottom: 25px;
//     min-width: 100%;
//     padding: 11px 20px 0;
//   }

//   .newDriverFormInputContainer {
//     @include flex(baseline, flex-start);
//     min-width: 100%;
//   }
//   .noFormCardInput {
//     @include flex(flex-start, space-between);
//     .labeledInputContainer {
//       margin-top: 0;
//       margin-bottom: 8px;
//       width: 100%;
//     }
//   }
//   .newDriverFormContainer .noFormCardInput {
//     flex-wrap: wrap;
//   }
//   .ant-divider-horizontal.ant-divider-with-text {
//     @include text(15px, 600, 0.23px, #d69615);
//     font-family: "Open Sans";
//     border-top-color: rgba(0, 0, 0, 0.2);
//     width: 100%;
//     margin-bottom: -11%;
//   }

//   .cardTitle {
//     @include text(15px, 600, 0.23px, #d69615);
//     margin-left: -12px;
//     position: relative;
//     top: -24px;
//     font-family: "Open Sans";
//     background-color: #fff;
//     z-index: 10;
//     padding: 0 5px;
//   }

//   .googlePickerContainer {
//     @include flex(flex-start, flex-start);

//     .uploadButton {
//       font-family: Helvetica;
//       @include text(14, 400, 00.21px, #7b7b7b);
//       background-color: #f8f8f8;
//     }

//     .uploadedFileNamesContainer {
//       @include flex(flex-start, flex-start, column);
//       @include text(11px, 400, 0, #d69615);
//       margin-left: 10px;
//     }
//   }
// }

// @media (max-width: 700px) {
//   .newDriverModalContainer::before {
//     height: 40%;
//   }
// }

// @media (max-width: 650px) {
//   .newDriverFormContainer .noFormCardInput {
//     flex-wrap: wrap;
//   }
// }

// @media (max-width: 450px) {
//   .newDriverModalContainer::before {
//     height: 10%;
//   }
// }

.newDriverModalContainer {
  overflow: hidden;
  max-height: 100dvh;
  max-width: 100dvw;
  display: flex;
  justify-content: center;
  align-items: center;
  .ant-modal-content {
    .ant-modal-body {
      .newDriverFormContainer {
        .ant-form {
          display: grid;
          grid-template-columns: auto auto;
          column-gap: 1.5rem;
          row-gap: 0.7rem;
          place-items: center stretch;
        }
      }
    }
  }
}

.newDriverModalContainerDark {
  .labeledInputLabel {
    color: #fff;
  }
  .ant-input {
    background-color: $inputModalDark !important;
    color: #fff;
    &::placeholder {
      color: #acacca;
    }
  }
  .ant-select {
    .ant-select-selector {
      background-color: $inputModalDark !important;
      .ant-select-selection-search-input {
        color: #fff;
      }
      .ant-select-selection-item {
        color: #fff;
      }
      .ant-select-selection-placeholder {
        color: #acacca;
      }
    }
    .ant-select-clear {
      background-color: $inputModalDark;
      svg {
        fill: #acacca;
      }
    }
    .ant-select-arrow svg {
      fill: #fff;
    }
  }
}
