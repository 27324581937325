$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.requestModal {
  max-width: 97% !important;
  width: fit-content !important;
  max-height: 97%;
  overflow: hidden;
  &::before {
    display: none !important;
  }

  .ant-modal-content {
    width: 100%;
    height: 100%;
    .ant-modal-header {
      background-color: #f5f5f7;
      // padding: 20px;
      display: flex;
      align-items: center;
      height: 45px;

      .ant-modal-title {
        color: #323338;
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        width: 100%;

        .titleContainer .buttons .settingsIcon {
          height: 22px;
          width: 22px;

          cursor: pointer;
        }
      }
    }
    .ant-modal-body {
      //   height: calc(100vh - 119px);
      width: 100%;
      // padding: 10px !important;
      padding: 10px 20px 20px 20px !important;
      // padding: 0;
      .headerInfo {
        display: flex;
        gap: 10px;
        font-size: 14px;
        font-weight: 500;
        user-select: none;
        align-items: center;
        margin-bottom: 10px;
        .redirectIcon {
          width: 22px;
          height: 22px;
          padding: 2px;
          border-radius: 4px;
          background-color: #1264a3;
          svg {
            background: transparent;
          }
        }
      }
      .stepper-section {
        margin-bottom: 1rem;
      }

      .stepper-section-mobile {
        padding: 10px;
        .ant-form-item {
          margin-bottom: 0;
        }
      }

      .ant-form {
        overflow-x: hidden;
        max-height: calc(100dvh - 300px);
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: row-reverse;
        overflow-y: auto;
        scroll-behavior: smooth;

        .uploaderSection {
          position: sticky !important;
          padding-right: 20px;
          width: 25vw;
          top: 0;
          z-index: 100;
          background-color: white;

          .uploaderButton {
            cursor: pointer !important;
          }
        }

        .request-form-section {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-direction: column;
          overflow-x: hidden;
          padding-bottom: 20px;

          .borderedTextCard {
            margin-top: 1rem;
            padding: 15px 0px 0px 0px;
            border-radius: 0;
            border: none;
            width: 100%;
            min-width: unset;
            border-top: 1px solid black;
            align-items: center;
            .cardTitle {
              font-size: 16px;
              color: black;
              z-index: unset !important;
            }
            .childrenContainer {
              display: flex;
              gap: 1.2rem;
              flex-wrap: wrap;
              align-items: end;
              row-gap: 40px;

              .ant-form-item {
                margin-bottom: 0;
              }

              .labeledInputContainer {
                // flex: 1;
                // max-width: 300px;
                height: 55px;

                min-width: 180px;
                .ant-picker {
                  width: -webkit-fill-available;
                }

                .ant-input-number {
                  width: 100%;
                }
              }
              .multipleSelect {
                max-width: 40%;
              }
            }
          }
          .otherDetailsContainer {
            display: flex;
            // flex-direction: column;
            gap: 1rem;
            width: 100%;

            .scheduleDaysWrapper {
              margin-top: 4px;
              display: flex;
              flex-direction: column;
              gap: 10px;
              // overflow: auto;
              overflow-y: hidden;
              max-width: 500px;
              width: 100%;
              min-width: 400px;
              max-height: 400px;
              @media (max-width: 1030px) {
                max-width: 100% !important;
                max-height: unset !important;
              }
              .scheduleDaysHeader {
                display: flex;
                flex-direction: column;
                // align-items: end;
                position: sticky;
                top: 0;
                z-index: 1;
                background-color: white;
                width: 100%;
                padding: 5px;
                .scheduleDaysHeaderTitle {
                  display: flex;
                  align-items: center;
                  position: sticky;
                  top: 0;
                  .line {
                    flex-grow: 1;
                    height: 1px;
                    background-color: #000;
                  }
                  .text {
                    font-size: 16px;
                    color: black;
                    font-weight: 600;
                    margin: 0 5px;
                  }
                }
              }

              .schedule-days-list {
                overflow-y: auto;
              }
            }

            .typeOfWorkBorderedTextCard {
              width: fit-content;
              .childrenContainer {
                width: fit-content;
                min-width: fit-content;
                height: 350px;

                .ant-form-item {
                  height: 100%;
                  .ant-row {
                    height: 100%;
                    .ant-col {
                      height: 100%;
                      .ant-form-item-control-input {
                        height: 100%;
                        .ant-form-item-control-input-content {
                          height: 100%;
                          .typeOfWorkTransfer {
                            .source,
                            .listContainer {
                              max-height: 250px;
                            }

                            .source {
                              min-width: 330px;
                              .itemsContainer {
                                width: 100%;
                              }
                            }
                            .transferredKeys {
                              min-width: 330px;
                              .transferredItemContainer {
                                width: 100%;
                              }
                            }
                            @media (max-width: 600px) {
                              .transfersContainer {
                                flex-direction: column !important;
                                .transferIcon {
                                  margin: 20px 20px !important;
                                  align-self: center !important;
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            .request-comments-wrapper {
              margin-top: 4px;
              display: flex;
              flex-direction: column;
              gap: 10px;
              // max-width: 500px;
              width: 100%;
              min-width: 400px;
              // max-height: 400px;
              @media (max-width: 1030px) {
                max-width: 100% !important;
                max-height: unset !important;
              }
              .request-comments-header {
                display: flex;
                align-items: center;
                position: sticky;
                top: 0;
                .line {
                  flex-grow: 1;
                  height: 1px;
                  background-color: #000;
                }
                .text {
                  font-size: 16px;
                  color: black;
                  font-weight: 600;
                  margin: 0 5px;
                }
              }
              .request-form-comment {
                width: 100%;
                max-height: 400px;
                overflow: auto;

                .ant-form-item {
                  .ant-row {
                    .ant-col {
                      .ant-form-item-control-input {
                        .ant-form-item-control-input-content {
                          .all-comments-container {
                            display: flex;
                            flex-direction: column-reverse;
                            width: 100%;
                            justify-content: space-between;
                            .current-comments {
                              max-height: unset;
                              margin-top: 50px;
                              // overflow-y: auto;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }

              .request-form-comment-full {
                width: 100%;
                max-height: 400px;
                overflow: auto;
                .ant-form-item {
                  width: 100% !important;
                  .ant-row {
                    .ant-col {
                      .ant-form-item-control-input {
                        .ant-form-item-control-input-content {
                          .all-comments-container {
                            width: 100%;
                            display: flex;
                            flex-direction: row-reverse;
                            // justify-content: space-between;
                            .comments-left-side {
                              width: 50%;
                              .comment-input-container {
                                width: 100%;
                                max-height: 200px;
                              }
                            }

                            .current-comments {
                              width: 49%;
                              max-height: 310px;
                              overflow-y: auto;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            // .commentsBorderedTextCard {
            //   width: 100%;
            //   max-height: 350px;
            //   // overflow-y: auto;
            //   // min-width: fit-content;
            //   .childrenContainer {
            //     .request-form-comment-full {
            //       width: 100%;
            //       max-height: 450px;
            //       .ant-form-item {
            //         width: 100% !important;
            //         .ant-row {
            //           .ant-col {
            //             .ant-form-item-control-input {
            //               .ant-form-item-control-input-content {
            //                 .all-comments-container {
            //                   width: 100%;
            //                   display: flex;
            //                   flex-direction: row-reverse;
            //                   // justify-content: space-between;
            //                   .comments-left-side {
            //                     width: 50%;
            //                     .comment-input-container {
            //                       width: 100%;
            //                       max-height: 200px;
            //                     }
            //                   }

            //                   .current-comments {
            //                     width: 49%;
            //                     max-height: 310px;
            //                     overflow-y: auto;
            //                   }
            //                 }
            //               }
            //             }
            //           }
            //         }
            //       }
            //     }
            //     .request-form-comment {
            //       width: 100%;
            //       .ant-form-item {
            //         .ant-row {
            //           .ant-col {
            //             .ant-form-item-control-input {
            //               .ant-form-item-control-input-content {
            //                 .all-comments-container {
            //                   display: flex;
            //                   // flex-wrap: wrap;
            //                   flex-direction: column-reverse;
            //                   width: 100%;
            //                   justify-content: space-between;
            //                   // max-height: 350px;
            //                   overflow-y: auto;
            //                   .comments-left-side {
            //                     height: fit-content;
            //                     // width: 50%;
            //                   }
            //                   .ant-divider {
            //                     max-height: 40px;
            //                   }
            //                   .current-comments {
            //                     max-height: 310px;

            //                     margin-top: 50px;
            //                     // width: 49%;
            //                     overflow-y: auto;
            //                   }
            //                 }
            //               }
            //             }
            //           }
            //         }
            //       }
            //     }
            //   }
            // }
          }
        }
        .detailsContainer {
          .mondayButtonContainer {
            top: 30px !important;
            position: relative;
          }
          .linkBtnContainer {
            top: 30px !important;
            position: relative;
          }
        }
      }

      .request-modal-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        flex-wrap: nowrap;
        padding-top: 10px;
        button {
          width: 150px;
        }
        .buttonContainer {
          display: flex;
          flex-direction: row;
          gap: 10px;
        }
        @media (max-width: 1025px) {
          .mondayButtonText,
          .mondayButtonDivider {
            display: none !important;
          }
          button {
            width: auto !important;
          }
        }
      }
    }
    .requestEmailBox {
      width: 75%;
      display: flex;
      justify-content: center;
      // align-items: center;

      .EmailBoxContainer {
        height: 550px;
        z-index: 99999;
        position: absolute;
        bottom: 0;
        display: flex !important;
        justify-content: flex-start !important;
        flex-direction: column !important;
        .ant-form-item {
          width: 100%;
          margin-bottom: 0;
        }
        .ant-form {
          display: flex !important;
          justify-content: flex-start !important;
          flex-direction: column !important;
          height: 100% !important;
          .emailBoxContainer {
            // height: 100% !important;
            .tox-tinymce {
              border: none;
              border-radius: 5px;
              .tox-menubar {
                display: none;
              }
              .tox-statusbar {
                display: none;
              }
              .tox-toolbar.tox-toolbar--scrolling:nth-child(n + 1) {
                display: none;
              }
              .tox-toolbar__primary {
                display: flex;
                justify-content: flex-start;
                width: 100%;
                .tox-toolbar__group {
                  border: none;
                }
              }
              .tox-sidebar-wrap {
                border-radius: 5px;
                .tox-edit-area {
                  border-radius: 5px;
                  .tox-edit-area__iframe {
                    margin-top: 10px;
                    border-radius: 5px;
                    border: none;
                  }
                }
              }
            }
          }
        }
        .ant-upload-wrapper.uploadDocumentsClass
          .ant-upload-list
          .ant-upload-list-item
          .ant-upload-list-item-name {
          width: 200px;
          overflow: hidden;
        }
      }
    }
    .ant-modal-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      flex-wrap: nowrap;
      button {
        width: 150px;
      }
      .buttonContainer {
        display: flex;
        flex-direction: row;
        gap: 10px;
      }
      @media (max-width: 1025px) {
        .mondayButtonText,
        .mondayButtonDivider {
          display: none !important;
        }
        button {
          width: auto !important;
        }
      }
    }
  }
}

@media (min-width: 1660px) {
  .requestModal .ant-modal-content {
    .otherDetailsContainer {
      .typeOfWorkBorderedTextCard {
        .typeOfWorkTransfer {
          .source,
          .listContainer {
            // width: 300px;
            .transferLabelContainer {
              width: 300px !important;
            }
          }
        }
      }
    }
    .docUploader {
      min-width: unset !important;
    }
    .uploaderSection {
      .fileCard {
        max-width: 20vw;
        width: 20vw;
      }
    }
  }
}

@media (max-width: 1030px) {
  .requestModal .ant-modal-content .ant-modal-body .otherDetailsContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    .typeOfWorkBorderedTextCard,
    .commentBorderedTextCard {
      .childrenContainer {
        justify-content: center !important;
      }
      width: 100% !important;
      min-width: fit-content;
    }
  }
}

@media (max-width: 950px) {
  .requestModal .ant-modal-content .ant-modal-body .ant-form {
    .request-form-section {
      .otherDetailsContainer {
        .request-comments-wrapper {
          .request-form-comment-full {
            .ant-form-item {
              .ant-row {
                .ant-col {
                  .ant-form-item-control-input {
                    .ant-form-item-control-input-content {
                      .all-comments-container {
                        display: flex;
                        flex-direction: column-reverse;

                        .comments-left-side {
                          width: 100%;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 470px) {
  .requestModal
    .ant-modal-content
    .ant-modal-body
    .ant-form
    .request-form-section
    .request-bodyContent {
    width: -webkit-fill-available;
    .request-inputFieldsContainer {
      .detailsContainer .childrenContainer {
        display: grid !important;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 20px;
        .labeledInputContainer {
          max-width: unset !important;
        }
      }
    }
  }
}

@media (max-width: 800px) {
  .requestModal .ant-modal-content .ant-modal-body .ant-form {
    .uploaderSection {
      margin-top: 0.5rem;
      .uploaderTitle {
        display: none;
      }
    }
    .request-inputFieldsContainer {
      width: unset !important;
      @media (max-width: 570px) {
        width: unset !important;
      }
    }
    .borderedTextCard {
      margin-left: 0 !important;
      min-width: unset !important;
      width: 100%;
      .multipleSelect {
        max-width: unset !important;
      }
      // .childrenContainer {
      //    align-items: center;
      //   justify-content: center;
      // }
      .childrenContainer .labeledInputContainer {
        width: 100% !important;
        margin: 0 !important;
      }
    }
  }
}

@media (max-width: 1450px) {
  .requestModal .ant-modal-content .ant-modal-body .ant-form {
    max-height: calc(100dvh - 250px);
    flex-direction: column !important;
    overflow-y: auto !important;
    .uploaderSection {
      padding-right: 0 !important;
      display: flex;
      justify-content: center;
      width: 100% !important;
      padding-top: 1rem;
      .anticon-file-text {
        display: none;
      }
    }
    .request-form-section {
      max-height: 80vh;
      overflow-y: auto;
    }
  }
}

@media (max-width: 600px) {
  .requestModal
    .ant-modal-content
    .ant-modal-body
    .ant-form
    .request-form-section {
    .borderedTextCard {
      .childrenContainer {
        width: 100% !important  ;
        .labeledInputContainer {
          width: 100% !important;
        }
      }
    }

    .otherDetailsContainer {
      .request-comments-wrapper {
        min-width: unset;
      }
      .scheduleDaysWrapper {
        .schedule-days-list {
          width: 100%;
        }
      }
    }
  }
}

//isNewRequestFormModal  - remove comments -- space between footer buttons
.newRequestFormModal {
  .ant-modal-content {
    .ant-modal-body
      .ant-form
      .request-form-section
      .otherDetailsContainer
      .commentBorderedTextCard
      .requestNotes
      .customComments {
      .commentsContainer {
        flex-basis: 0;
      }
    }
    .ant-modal-footer {
      .buttonContainer {
        width: 100%;
        display: flex;
        justify-content: space-between !important;
      }
    }
  }
}

//without ProposeTypeOfWork component full width notes
.schedulingRequestFormModal {
  .ant-modal-content
    .ant-modal-body
    .ant-form
    .request-form-section
    .otherDetailsContainer
    .commentBorderedTextCard {
    width: 100% !important;
    .requestNotes {
      width: 100% !important;
      .customComments {
        width: 100% !important;
      }
    }
    @media (max-width: 1150px) {
    }
  }
}

.requestModalDark {
  .ant-modal-content {
    background-color: $cardModalBodyDark !important;
    .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;

        .titleContainer .buttons .settingsIcon path {
          fill: #fff;
        }
      }
    }
    .ant-modal-body {
      .stepper-section {
        .ant-steps {
          .ant-steps-item-finish ::before,
          .ant-steps-item-active::before,
          .ant-steps-item::before {
            border-left-color: $cardModalBodyDark;
          }
          .ant-steps-item-wait {
            background-color: $cardModalBodyDark;
            //&::after {
            // border-left-color: $cardModalHeaderDark;
            //}
            .ant-steps-item-content .ant-steps-item-title {
              color: #acacca;
            }
          }
        }
      }
      .ant-form {
        .request-form-section {
          .borderedTextCard {
            border-top: 1px solid #fff;
            .cardTitle {
              background-color: $cardModalBodyDark;
              color: #fff;
            }
            .childrenContainer {
              .labeledInputLabel {
                color: #fff;
              }
              .ant-input {
                background-color: $inputModalDark !important;
                color: #fff;
                &::placeholder {
                  color: #acacca;
                }
              }
              .ant-select {
                .ant-select-selector {
                  background-color: $inputModalDark !important;
                  .ant-select-selection-search-input {
                    color: #fff;
                  }
                  .ant-select-selection-item {
                    color: #fff;
                  }
                  .ant-select-selection-placeholder {
                    color: #acacca;
                  }
                }
                .ant-select-clear {
                  background-color: $inputModalDark;
                  svg {
                    fill: #acacca;
                  }
                }
                .ant-select-arrow svg {
                  fill: #fff;
                }
              }
              .ant-picker {
                background-color: $inputModalDark !important;
                .ant-picker-input {
                  background-color: $inputModalDark;
                  input {
                    color: #f2f2f2;
                    &::placeholder {
                      color: #acacca;
                    }
                  }
                  .ant-picker-suffix svg {
                    color: #acacca;
                  }
                  .ant-picker-clear {
                    background-color: $inputModalDark;
                    svg {
                      fill: #acacca;
                    }
                  }
                }
              }
              .ant-radio-wrapper span {
                color: #fff !important;
              }
              .commentsContainer {
                .noCommentsLabel {
                  color: #fff;
                }
              }
              .quill {
                background-color: $cardModalBodyDark !important;
                .ql-toolbar {
                  background-color: #15161f !important;
                  .ql-formats {
                    color: #fff;
                    .ql-picker-label {
                      color: #fff;
                      &::before {
                        color: #fff;
                      }
                      .ql-stroke {
                        stroke: #fff;
                      }
                    }
                    button .ql-stroke {
                      stroke: #fff;
                    }
                  }
                }
                .ql-container {
                  background-color: $inputModalDark !important;
                  .ql-editor {
                    background-color: $inputModalDark !important;
                    &::before {
                      color: #acacca !important;
                    }
                    p {
                      color: #fff !important;
                    }
                  }
                }
              }
              .transfersContainer {
                .source,
                .transferredKeys {
                  .itemCounter {
                    color: #fff;
                  }
                  .listContainer {
                    .transferLabelContainer {
                      background-color: $inputModalDark;
                      .titleContainer {
                        color: #fff;
                      }
                    }
                  }
                }
                .transferIcon {
                  svg {
                    rect {
                      fill: $inputModalDark;
                    }
                    path {
                      fill: #fff;
                    }
                  }
                }
              }
            }
          }
          .scheduleDaysWrapper {
            overflow-x: hidden;
            .scheduleDaysHeader {
              background-color: $cardModalBodyDark !important;
              .line {
                background-color: #fff !important;
              }
              .text {
                color: #fff !important;
              }
            }
            #days-container-scroll {
              .inner-schedule {
                &:hover {
                  background-color: #323c5d !important;
                }
                .delete-schedule-day-X svg {
                  fill: #fff;
                }
                .bottom-structure-day-inner span {
                  color: #fff;
                }
                .date-values-schedule {
                  .value-title {
                    color: #fff;
                  }
                  .ant-picker {
                    background-color: $inputModalDark !important;
                    .ant-picker-input {
                      background-color: $inputModalDark;
                      input {
                        color: #f2f2f2;
                        &::placeholder {
                          color: #acacca;
                        }
                      }
                      .ant-picker-suffix svg {
                        color: #acacca;
                      }
                      .ant-picker-clear {
                        background-color: $inputModalDark;
                        svg {
                          fill: #acacca;
                        }
                      }
                    }
                  }
                  .ant-picker-disabled {
                    .ant-picker-input input {
                      color: #acacca !important;
                    }
                  }
                }
              }
            }
          }

          .request-comments-wrapper {
            .request-comments-header {
              background-color: $cardModalBodyDark;
              .line {
                background-color: #fff !important;
              }
              .text {
                color: #fff !important;
              }
            }
          }
        }
        .newCommentContainer {
          .ant-btn:disabled {
            border: 1px solid #acacca;
            color: #acacca;
          }
        }
        .uploaderSection {
          background-color: $cardModalBodyDark;
          .uploadSection {
            border: 1px dashed #fff;
          }
        }
      }
    }
  }
}
