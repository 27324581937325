$border-color: #acacca;

.OTP_confirm_code {
  .ant-otp-input {
    width: 50px;
    height: 50px;
    border: 1px solid $border-color;
    padding-inline: 15px !important;
  }
}
