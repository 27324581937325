@import "../../../../../../../../../index.scss";

.dimensionPricer {
  @include flex(center, flex-end, column);
  position: relative;
  padding: 45px 0 20px;

  .lengthInput,
  .priceInput {
    // @include flex(center, center);
    display: flex;
    align-items: center;
    height: 38px;
    width: 84px;
    border-radius: 10px;
  }

  .inputsDivider {
    width: 2px;
    height: 10px;
    background-color: #dfdfdf;
    margin-left: -10px;
  }

  .dimensionInputWrapper {
    @include flex(center, flex-start);
    &::after {
      content: "";
      width: 10px;
      height: 2px;
      background-color: #dfdfdf;
    }
  }

  .afterLessWrapper {
    .inputsDivider {
      margin-left: 0;
    }

    &::after {
      display: none;
    }
  }

  .lengthInput {
    background-color: #1f2a44;
    border: 1px solid #1f2a44;
    .ant-input-number-input {
      color: #fff;
    }
  }

  .dimensionPricerDeleteIcon {
    @include flex(center, center);
    position: absolute;
    width: 30px;
    height: 30px;
    top: 10px;
    border-radius: 5px;
    background-color: #fe4c4a;
    margin-left: -5px;
    cursor: pointer;

    svg {
      fill: #fff;
    }

    &:hover {
      background-color: #eb4345;
    }
  }

  .duplicateLabel {
    position: absolute;
    color: #ff0000;
    bottom: 0;
    margin-left: -10px;
    font-size: 12px;
  }
}

.afterLessDimensionPricer {
  .inputsDivider {
    margin-left: 0;
  }
  .dimensionInputWrapper::after {
    display: none;
  }
}
