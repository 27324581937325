@import "../../../../../.././Communication.scss";

.email-box-templates-dropdown-overlay {

  .ant-dropdown-menu {
    max-height: 300px;
    max-width: 200px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .dropdown-item-button {
    span {
      color: $light-text-color;
      max-width: 150px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: start;
    }

    &:hover {
      span {
        color: inherit;
      }
    }
  }

}

.templates-icon {
  fill: $icon-color;
  color: $icon-color;
  width: 16px;
  height: 20px;
}

.email-box-template-dropdown-anchor {
  display: flex;
}