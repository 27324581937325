/* .recent-event {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: -20px;
  gap: 90px;
}

.recent-event > strong {
  font-size: large;
} */

.select-calendar {
  display: flex;
  justify-content: space-between;
}

.events-list-container {
  // margin-top: -10px;
  // height: 400px;
  overflow-y: auto;
  flex-grow: 1;

  .li {
    display: flex;
    align-items: center;
    padding: 0px 0;
    box-sizing: border-box;
    cursor: pointer;
    list-style-type: none;
    margin-top: 10px;
  }
  .list-event-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 40px;
    width: 100%;
    margin-top: 10px;
    border-radius: 5px;
    background-color: #f8f8fa;

    .dot-style {
      height: 20px;
      width: 20px;
      border-radius: 50%;
      display: inline-block;
    }

    .event-name {
      width: 50%;
    }
    .event-summary {
      border-right: 1px solid #d2d6dc;
      margin-right: 10px;
      font-size: 14px;
      font-weight: 400;
      color: #323338;

      &:hover {
        color: #1264a3;
        cursor: pointer;
      }
    }

    .event-time {
      margin-right: 5px;
      width: 30%;
      .event-time-title {
        border-right: 1px solid #d2d6dc;
        line-height: 32px !important;
        margin-right: 10px;
        text-align: left;
        font: normal normal normal 16px/22px Open Sans;
        letter-spacing: 0px;
        color: #323338;
        opacity: 1;
        &:hover {
          color: #0f5c97;
          cursor: pointer;
        }
      }
    }

    .open-event-btn {
      margin-right: 0px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 15%;
      gap: 10px;
      margin-top: 7px;

      .event-btn {
        height: 32px;
        width: 32px;
        display: flex;
        cursor: pointer;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        color: white;
        margin-bottom: 10px;

        svg {
          fill: white;
        }
      }
      .print-btn {
        background-color: #1264a3;
        &:hover {
          background-color: #0f5c97;
        }
      }
      .delete-btn {
        background-color: #fe4c4a;
        &:hover {
          background-color: #e53e3c;
        }
      }
    }
    .dot-calendar-color {
      margin-right: 10px;
      width: 5%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .listEventHeader {
    background-color: unset;
    border-bottom: 1px solid #d2d6dc;
    padding: 10px 0px;
    margin: 10px 0px;
    border-radius: unset;
    .event-summary {
      font-weight: 600;
    }
    .actions-title {
      width: 15%;
      .event-summary {
        border: none;
      }
    }
  }
}

.events-list-container > ul {
  padding: 0;
  margin-top: 5px;
  list-style-type: none;
}

.events-list-container .li {
  display: flex;
  align-items: center;
  padding: 0px 0;
  box-sizing: border-box;
  cursor: pointer;
  list-style-type: none;
  margin-top: 10px;
}
