@import "../../../../../../../../../../index.scss";

.serviceListServiceCardHeaderSovLight {
  display: flex;
  height: 45px;
  align-items: center;
  gap: 10px;
  padding: 0 20px;
  justify-content: space-between;
  min-width: fit-content;
  overflow-x: auto;
  overflow-y: hidden;
  .serviceHeaderServiceTitleSov {
    font: normal normal 600 16px/39px Open Sans;
  }

  .leftContainer,
  .rightContainer {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

.serviceListServiceCardHeaderSovDark {
  display: flex;
  height: 45px;
  align-items: center;
  gap: 10px;
  padding: 0 20px;
  justify-content: space-between;
  min-width: fit-content;
  overflow-x: auto;
  overflow-y: hidden;

  .serviceHeaderServiceTitleSov {
    color: white;
    font: normal normal 600 16px/39px Open Sans;
  }

  .leftContainer,
  .rightContainer {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}
