.redirect-approvals-button {
  border-radius: 5px;
  color: white;
  height: 25px;
  width: 50px;
  padding: 10px;
  line-height: 5px;
  text-align: center;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 400;
}

.redirect-approvals-disabled {
  background-color: #f5f5f7;
  color: #323338;
}

.redirect-approvals-enabled {
  cursor: pointer;
  background-color: #1065a4;

  &:hover {
    background-color: #0f5c97;
  }
}
