$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.subPliBreakdowns-modal {
  .ant-modal-header {
    display: flex;
    align-items: center;
    height: 45px;
    background-color: #f5f5f5;
  }
  .ant-modal-body {
    padding: 0px;
  }
}

.jumpsPreview-modal {
  .ant-modal-header {
    display: flex;
    align-items: center;
    height: 45px;
    background-color: #f5f5f5;
  }
  .ant-modal-body {
    padding: 0px;
  }
  .jumpsPreview-table {
    animation: jumpsPreview 2s;
  }
}

.jumpsPreview-modal-dark,
.subPliBreakdowns-modal-dark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close-x svg {
      fill: #fff;
    }
  }
  .ant-modal-header {
    background-color: $cardModalHeaderDark;
    .ant-modal-title {
      color: #fff;
    }
  }
  .ant-modal-body {
    th,
    td {
      color: #fff;
      border: 1px solid grey !important;
    }
  }
}
