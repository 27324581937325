.transferContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-left: 30px;
  max-height: 300px;
  overflow-y: auto;
  margin-bottom: 15px;
  .transferItemContainer {
    width: 250px;
    height: 42px;
    background: #b2dd9b 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 10px;
    .transferItemStyle {
      display: flex;
      justify-content: center;
      text-align: left;
      font: normal normal 600 18px/39px Open Sans;
      letter-spacing: 0px;
      color: #ffffff;
      opacity: 1;
    }
  }
}
