@import "../Management/DepartmentUser/DepartmentUser.scss";
@import "../../../../../index.scss";

@mixin roleImage() {
  position: absolute;
  top: -2px;
  z-index: 2;
  left: 88px;
  width: 35px;
  height: 35px;
  font-size: 41px;
}
span.DeLETE-DEP-SETINGS.ant-tooltip-disabled-compatible-wrapper {
  button.ant-btn.ant-btn-default {
    background: #ea3943;
    border-radius: 5px;
    border: 0px;
    color: #fff;
  }
}

.headerSearch {
  background-color: #fff;
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  height: 32px;
}
.iconSearch {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  path#Path_6858_00000086666139294581268680000013212754614146113725_ {
    fill: #142735;
  }
}
.searchInput {
  margin-left: 10px;
  border: none;
  border-radius: 5px;
}

button.ant-btn.ant-btn-default.DeLETE-DEP-SETINGS {
  background: #ea3943;
  border-radius: 5px;
  border: 0px;
  color: #fff;
}

@mixin checkBoxContainer($bodyClassName, $bgColor, $navyBgColor) {
  #{$bodyClassName} {
    width: 8px;
    background-color: $bgColor;
    transition: width 0.5s;
    .navyBlueContainer {
      margin: 9px;
      @include size(18px, 18px);
      background-color: $navyBgColor;
      overflow: hidden; /* Hide the element content, while height = 0 */
      opacity: 0;
      transition: opacity 300ms 0ms;
    }
    .ant-checkbox-inner::after {
      position: absolute;
      display: table;
      border: 2px solid $navyBgColor;
      border-top: 0;
      border-left: 0;
      transform: rotate(45deg) scale(1) translate(-50%, -50%);
      opacity: 1;
      transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
      content: " ";
    }
    .ant-checkbox-checked::after {
      position: absolute;
      top: 0;
      left: 0;
      @include size(100%, 100%);
      border: 1px solid white;
      border-radius: 2px;
      visibility: hidden;
      -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
      animation: antCheckboxEffect 0.36s ease-in-out;
      -webkit-animation-fill-mode: backwards;
      animation-fill-mode: backwards;
      content: "";
    }
    .ant-checkbox-inner::after {
      position: absolute;
      display: table;
      border-top: 0;
      border-left: 0;
      transform: rotate(45deg) scale(1) translate(-50%, -50%);
      opacity: 1;
      transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
      content: " ";
    }
    .ant-checkbox-inner {
      position: relative;
      top: -3px;
      left: 0;
      display: block;
      @include size(18px, 18px);
      direction: ltr;
      background-color: white;
      border: 1px solid $borderColor;
      border-radius: 2px;
      border-collapse: separate;
      transition: all 0.3s;
    }
  }
}

@mixin switch($className, $background) {
  #{"." + $className + "Container"} {
    @include size(361px, 28px);
    background: transparent
      linear-gradient(270deg, $background 0%, #f8f9fe 100%) 0% 0% no-repeat
      padding-box;
    opacity: 1;
    border-radius: 20px;
    padding-left: 10px;
    margin-bottom: 10px;
    @include div(row, "", space-between);

    .switchlabel {
      @include textStyle(left);
      font: normal normal normal 16px Open Sans;
      display: flex;
      align-items: center;
    }
    #{".switch" + $className + "Container"} {
      @include div(row, center, center);
      margin-right: 5px;
      .ant-switch-handle::before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: $background;
        border-radius: 9px;
        transition: all 0.2s ease-in-out;
        content: "";
      }
      .ant-switch-checked {
        background-color: white !important;
      }
      .ant-switch {
        background-color: white !important;
      }
    }
  }
}

@mixin buttonStyle($color, $left, $imageClassName) {
  @include size(36px, 32px);
  background: $color 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 5px;
  margin-top: 80px;
  margin-left: 5px;
  cursor: pointer;
  #{$imageClassName} {
    position: absolute;
    top: 155px;
    z-index: 2;
    left: $left;
    @include size(20px, 20px);
    font-size: 41px;
  }
}

.settingPageTitleDiv {
  @include div(row, center, space-between);
  border-bottom: 1px solid black;
  .settingPageTitle {
    font-size: 36px;
  }
}
.settingRolesBody {
  @include div(column, "", "");
  .roleTypesTab {
    .ant-tabs-nav .ant-tabs-tab {
      position: relative;
      display: inline-block;
      box-sizing: border-box;
      margin: 0;
      padding: 12px 16px;
      text-decoration: none;
      cursor: pointer;
      transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
    .ant-transfer-list {
      flex-direction: column;
      @include size(50%, 600px);
      border: 1px solid $borderColor;
      border-radius: 2px;
    }
  }
  .buttomContainer {
    @include div(row, "", flex-end);
    margin-top: 10px;
  }
}

.entryRolePage {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 10px;

  .entryRolePage-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    padding: 20px;
    background: #fff 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #00000029;
    border-radius: 10px;
    transition: 0.2s ease-in;
    .anticon {
      transition: 0.2s ease-in;
    }

    .entryPageDescription {
      text-align: center;
      font: small small 600 25px/34px Open Sans;
      letter-spacing: 0px;
      color: #323338;
      transition: 0.2s ease-in;
    }
  }

  .entryRolePage-body {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 10px;
    background: #fff 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #00000029;
    border-radius: 10px;
    overflow-y: auto;
    transition: 0.2s ease-in;

    .submitButtonContainer {
      position: absolute;
      top: 20px;
      left: 20px;

      .backButton {
        @include buttons(".previewButton", #1264a3, #0f578d);
        border-radius: 5px;
        .mondayButtonContainer .backButton {
          margin-right: 0px;
        }
        .previewButton {
          margin: 0;
          .previewButtonDivider {
            left: 84px !important;
            font-size: 29px !important;
          }

          &:hover {
            margin: 0;
          }
        }
      }
      .tourButton {
        .mondayButtonText,
        .mondayButtonDivider {
          display: none;
        }
      }
    }

    .headerSearch {
      display: flex;
      flex-direction: column;
      text-align: center;
      height: fit-content;
      align-items: center;
      transition: 0.2s ease-in;

      .header {
        font: normal normal 600 16px/22px Open Sans;
        letter-spacing: 0px;
        color: #323338;
        transition: 0.2s ease-in;
      }

      .headerSearch-iconInput {
        display: flex;
        flex-direction: row;
        border-radius: 5px;
        background-color: #f4f5f6;
        // border: 1px solid red;

        .iconSearch {
          position: relative;
          padding: 10px;
          background: #f4f5f6 0% 0% no-repeat padding-box;
        }
        .searchInput {
          margin: 0;
          background: #f4f5f6 0% 0% no-repeat padding-box;
        }

        .ant-input {
          background: #f4f5f6 0% 0% no-repeat padding-box;
        }

        .userSelect .ant-select-selector {
          border-radius: 10px;
          border: 10px;
          background: #f4f5f6 0% 0% no-repeat padding-box;
        }
      }
    }

    .entryPageDepartments {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 10px;
      background-color: #fff;

      .entryPageDepartmentName {
        width: 320px;
        height: 161px;
        display: flex;
        flex-direction: column;
        text-align: center;
        // background: #F04F4E 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 4px #00000029;
        border-radius: 10px;
        cursor: pointer;

        .header {
          position: relative;
          padding: 10.8px 35px 5.4px 35px;
          background: #f7f8fa;
          font: normal normal 600 16px/22px Open Sans;
          letter-spacing: 0px;
          color: #323338;
          cursor: default;
          text-align: center;
          box-sizing: border-box;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          display: flex;
          align-items: center;
          justify-content: center;

          .deleteButton {
            position: absolute;
            right: 14px;
            font-size: 22px;
            color: #fe4c4a;

            &:hover {
              filter: brightness(85%);
            }
          }
        }

        .link {
          flex-grow: 1;
          .body {
            height: 100%;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border-radius: 0 0 10px 10px;
            color: #fff;
            p {
              margin: 0;
            }

            &:hover {
              filter: brightness(85%);
            }

            .number {
              margin: 0;
              font: normal normal 600 56px/76px Open Sans;
              letter-spacing: 0px;
            }

            .text {
              white-space: nowrap;
              width: 90%;
              overflow: hidden;
              text-overflow: ellipsis;
              color: #fff;
              font: normal normal normal 17px/23px Open Sans;
              letter-spacing: 0px;
            }
          }
        }
      }
    }

    .addRole {
      @include div(row, "", "");
      gap: 10px;
      align-items: center;
      .ant-form-horizontal .ant-form-item {
        margin-bottom: 0px;
      }
      .addRoleLabel {
        @include textStyle(left);
        font: normal normal bold 24px/39px Open Sans;
        @include size("", 33px);
        margin-right: 5px;
      }
      .addRoleInput {
        background: #f5f6f8 0% 0% no-repeat padding-box;
        opacity: 1;
        border-radius: 5px;
        @include size(300px, 32px);
        margin-right: 5px;
      }
      .AddRoleButtonDepartment {
        margin-top: 5px;
      }
      .tourButton {
        .mondayButtonText,
        .mondayButtonDivider {
          display: none;
        }
      }
    }
  }

  .entryRolePage-body-mobile {
    .addRole {
      display: flex;
      flex-direction: row;
      width: 100%;
      #addRoleSection {
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 8px;
        .ant-form {
          max-width: 50% !important;

          #newRoleName {
            width: 240px;
          }
        }
      }
    }
  }

  .entryRolePage-unselected {
    display: flex;
    height: 226px;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    padding: 10px 20px;
    background: #fff 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #00000029;
    border-radius: 10px;
    overflow-y: auto;
    @keyframes spinnerLoading {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
    .spinner {
      animation: spinnerLoading 1000ms infinite linear forwards;
    }
    .entryPageDepartmentName {
      width: 320px;
      min-width: 300px;
      height: 161px;
      display: flex;
      flex-direction: column;
      text-align: center;
      box-shadow: 0px 1px 4px #00000029;
      border-radius: 10px;

      .header {
        padding: 12px 5px 6px 5px;
        text-align: center;
        letter-spacing: 0px;
        font: normal normal 600 16px/22px Open Sans;
        background: #f7f8fa;
        color: #323338;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: flex;
        align-items: center;
        justify-content: center;

        p {
          margin: 0;
        }
      }
      .link {
        flex: 1;
        .body {
          height: 100%;
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border-radius: 0 0 10px 10px;
          color: #fff;
          cursor: pointer;

          p {
            margin: 0;
          }

          &:hover {
            filter: brightness(85%);
          }

          .number {
            margin: 0;
            font: normal normal 600 56px/76px Open Sans;
            letter-spacing: 0px;
          }

          .text {
            font: normal normal normal 17px/23px Open Sans;
            letter-spacing: 0px;
            white-space: nowrap;
            width: 90%;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }

  .entryRolePage-unselected-mobile {
    flex-direction: column;
    gap: 24px;

    .body {
      padding: 10px 0;
    }
  }
}
//#region BODY
//---------------------------USER ACCESS-------------------------
.settingPane {
  display: flex;
  flex-direction: column;
  height: 100%;

  //---------------------------USER ACCESS BODY-------------------------
  .bodyContainer {
    display: flex;
    padding: 10px;
    gap: 10px;
    flex: 1;
    // height: 100%;
    //---------------------------USER ACCESS BODY LEFT-------------------------
    .leftContainer {
      height: calc(100% - 60px);
      flex: 0 0 500px;
    }
    //---------------------------USER ACCESS BODY RIGHT-------------------------
    .rightContainer {
      flex: 1;
      width: 100%;
      height: 100%;
      height: calc(100% - 60px);
      display: flex;
      flex-direction: column;
      gap: 20px;
      // display: inline-block;
      // border-radius: 10px;
      .collapsedContainer-header {
        display: flex;
        flex-direction: row;
        gap: 10px;
        width: 100%;
        // margin-bottom: 10px;
        // background: #ffffff 0% 0% no-repeat padding-box;
        // box-shadow: 0px 1px 4px #00000029;
        padding: 0px 20px;
        // border-radius: 10px;

        .configTitleContainer {
          flex-grow: 1;
          height: 32px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          gap: 10px;
          padding: 5px;
          margin: 0;
          background: #f5f5f7 0% 0% no-repeat padding-box;
          color: #323338;

          border-radius: 5px;
          cursor: pointer;
          .titleIcon {
            margin: 0;
            @include size(30px, 30px);
          }
          .configTitle {
            font: normal normal 600 16px/39px Open Sans;
            letter-spacing: 0px;
            color: #fff;
          }
          &:hover {
            background: #f1f5fd 0% 0% no-repeat padding-box;
            color: #1264a3;
          }
        }

        .configTitleContainerSelected {
          flex-grow: 1;
          height: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;
          padding: 5px;
          margin: 0;
          background: #f1f5fd 0% 0% no-repeat padding-box;
          border: 1px solid #1264a3;
          color: #1264a3;
          border-radius: 5px;
          cursor: pointer;
          .titleIcon {
            margin: 0;
            @include size(30px, 30px);
          }
          .configTitle {
            font: normal normal 600 16px/39px Open Sans;
            letter-spacing: 0px;
          }
        }
      }

      .collapsedContainer {
        @include div(column, "", flex-start);
        .collapsedContainer-body {
          overflow: auto;
          height: 690px;
          background: #ffffff 0% 0% no-repeat padding-box;
          box-shadow: 0px 1px 4px #00000029;
          // padding: 20px;
          border-radius: 10px;
          .configSearch {
            margin-bottom: 20px;
            .ant-input {
              border-radius: 5px;
              width: 300px;
              min-width: 0px;
              border: none;
              background-color: #f5f5f7;
            }
          }
          .configBody {
            @include size(100%, fit-content);
            @include div(row, "", "");
            flex-wrap: wrap;
            // gap: 20px;
            .configElementContainer {
              background-color: #f7f8fa;
              @include size(245px, 36px);
              display: flex;
              // margin-right: 37px;
              opacity: 1;
              margin-bottom: 5px;
              cursor: pointer;
              @include checkBoxContainer(".greenContainer", #71cf48, #68c53f);
              @include checkBoxContainer(".redContainer", #ff4d4f, #ec1316);
              @include checkBoxContainer(".bluContainer", #0073ea, #0060b9);
              .configElementTitle {
                @include textStyle(left);
                font: normal normal normal 20px/39px Open Sans;
                margin-left: 7px;
              }
            }
            .configElementContainer:hover {
              background-color: $secondaryColor;
              .navyBlueContainer {
                opacity: 1;
              }
              .greenContainer {
                width: 36px;
              }
              .bluContainer {
                width: 36px;
              }
              .redContainer {
                width: 36px;
              }
            }
          }
          .configBodyDrive {
            // @extend .configBody;

            width: 100%;
            display: flex;
            flex-wrap: wrap;
            padding: 10px;
            // height: 100%;
            .ant-form-vertical {
              width: inherit;
              display: flex;
              flex-wrap: wrap;
              gap: 20px;
              .configBodyDrive-item {
                display: flex;
                gap: 10px;
                align-items: center;
                .subfoldersButton {
                  margin-top: 3px;
                }
                .ant-form-item {
                  min-width: 200px;
                  width: auto;
                  height: fit-content;
                  .ant-select-selector {
                    border-radius: 5px;
                    background-color: #f5f5f7;
                    border: none;
                  }
                }
              }
            }
          }
          .configBodyWithOptions {
            @include div(column, "", "");
            align-self: flex-start;
          }
        }

        .preferencesCollapse {
          display: flex;
          height: 100%;
          @keyframes wobble {
            0% {
              transform: translateY(-30px);
            }
            100% {
              transform: translateY(0);
            }
          }
          animation: wobble 0.4s;
          .preferencesCollapseRightSide {
            @include div(column, "", "");
            margin-left: 25px;
            margin-top: 20px;
            overflow-y: auto;
            flex-grow: 1;
            .preferencesSearch {
              max-width: 300px;
              height: 35px;
            }
            .selectedPreferenceTitle {
              @include textStyle(left);
              margin-bottom: 20px;
              font: normal normal 600 24px/39px Open Sans;
              margin-left: 5px;
            }
            .selectedBody {
              @include div(column, "", "");
              margin-top: 20px;
              @include switch("True", #71cf48);
              @include switch("False", #ff4d4f);
            }

            .selectedBody-mobile {
              .TrueContainer {
                background-color: lightblue;
                width: 100%;
              }

              .FalseContainer {
                background-color: lightblue;
                width: 100%;
              }
            }
          }
          .preferencesCollapseLeftSide {
            @include div(column, "", "");
            width: max-content;
            margin-top: 20px;
            height: 100%;
            overflow-y: auto;
            padding-left: 10px;
            overflow-x: hidden;
            .preferencesSearch {
              max-width: 250px;
              height: 35px;
            }
            .actionTitle {
              @include textStyle(left);
              font: normal normal 600 24px/39px Open Sans;
              margin-left: 5px;
              @include size(255px, 33px);
              margin-bottom: 20px;
            }
            .actionLabelContainerSelected {
              // @include size(500px, 33px);
              opacity: 1;
              padding-left: 5px;
              margin-bottom: 3px;
              background: $secondaryColor 0% 0% no-repeat padding-box;
              border-top-left-radius: 8px;
              border-bottom-left-radius: 8px;
              height: auto !important;
              cursor: pointer;
              .actionLabel {
                @include textStyle(left);
                font: normal normal normal 20px/39px Open Sans;
              }
            }
            .actionLabelContainer {
              // @include size(500px, 33px);
              opacity: 1;
              padding-left: 5px;
              margin-bottom: 3px;
              height: auto !important;
              cursor: pointer;
              .actionLabel {
                @include textStyle(left);
                font: normal normal normal 20px/39px Open Sans;
              }
            }
            .actionLabelContainer:hover {
              background: $secondaryColor 0% 0% no-repeat padding-box;
              border-top-left-radius: 8px;
              border-bottom-left-radius: 8px;
              padding-left: 5px;
            }
          }
        }
      }
    }
  }
  //---------------------------USER ACCESS FOOTER-------------------------
  .submitButtonContainer {
    @include div(row, "", center);
    @include buttons(".previewButton", #1264a3, #0f578d);
    @include buttons(".submitButton", #71cf48, #68c142);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #00000029;

    .mondayButtonContainer .mondayButton {
      svg {
        height: 17px;
        width: 17px;
      }
      @media screen and (min-width: 1300px) {
        width: 150px;
      }
    }

    .submitButton {
      margin-top: 0;

      &:hover {
        margin-top: 0;
      }
    }

    .left {
      display: flex;
      flex-direction: row;
      gap: 10px;
      .previewButton {
        margin: 0;
      }
      .submitButton {
        margin: 0;
      }
    }

    .right {
      display: flex;
      flex-direction: row;
      gap: 10px;

      .filterIcons {
        width: 32px;
        height: 32px;
        background: #1264a3;
        fill: white;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        cursor: pointer;
      }

      .previewButton {
        margin: 0;
      }
      .submitButton {
        margin: 0;
        height: 32px;
        padding: 0px !important;
      }

      .pdfExcelDropdown .mondayButtonContainer .mondayButtonStyle {
        @media screen and (min-width: 1300px) {
          width: 150px;
        }
      }
    }
    .iconButton {
      width: 32px;
      .mondayButtonText,
      .mondayButtonDivider {
        display: none;
      }
    }
  }
  .submitButtonContainer-mobile {
    .left {
      width: 100%;
      justify-content: space-between;
    }

    .right {
      justify-content: space-between;
    }
  }
}

.smallWarningModal {
  .ant-modal-content {
    border-radius: 10px;
    .ant-modal-body {
      padding: 20px;
    }
    .ant-modal-confirm-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      .anticon {
        margin-bottom: 10px;
      }
    }
    .ant-modal-confirm-btns {
      display: flex;
      justify-content: space-between;
      text-align: center;
      margin-top: 15px;
      .ant-btn {
        border-radius: 5px;
        border: none;
      }
      .ant-btn:first-child {
        background-color: #fe4c4a;
        color: #fff;
      }
      .ant-btn:first-child:hover {
        background-color: #ea3943;
      }
      .ant-btn:nth-child(2) {
        background-color: #71cf48;
      }
      .ant-btn:nth-child(2):hover {
        background-color: #68c53f;
      }
    }
  }
}

.submitButtonContainer {
  @include div(row, "", center);
  .backButton {
    // margin-left: 10px;
    // margin-right: 10px;
    @include mondayButton(#1264a3, #0f5c97, #fff);
    padding-right: 5px;
    .mondayButtonDivider {
      margin-right: 5px;
    }
    svg {
      width: 25px;
      height: 25px;
      color: white;
    }
    @media screen and (min-width: 1300px) {
      width: 150px;
    }
  }
}
///////////////////////// Dark Mode /////////////////////////////
.entryRolePageDark {
  .entryRolePage-header {
    background-color: #12131b;
    transition: 0.2s ease-in;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    .anticon {
      svg {
        fill: #fff;
        transition: 0.2s ease-in;
      }
    }
    .entryPageDescription {
      color: #fff;
      transition: 0.2s ease-in;
      padding: 0;
    }
  }
  .entryRolePage-body {
    background: $cardDarkBackgroundColor;
    .headerSearch {
      background: $cardDarkBackgroundColor;
      .anticon {
        svg {
          fill: #fff !important;
        }
      }
      .header {
        color: #fff;
      }
      .headerSearch-iconInput {
        background-color: $inputDarkColor !important;
        .labeledInputLabel {
          display: none;
        }
        .searchInput {
          background-color: $inputDarkColor;
          .ant-input-prefix {
            svg {
              fill: #fff;
              path {
                fill: #fff !important;
              }
            }
          }
          .ant-input {
            background-color: $inputDarkColor;
            color: #fff;
            &::placeholder {
              color: #acacca;
            }
          }
        }
        .ant-select {
          .ant-select-selector {
            background-color: $inputDarkColor !important;
            .ant-select-selection-search {
              background-color: $inputDarkColor;
              .ant-select-selection-search-input {
                color: #fff;
              }
            }
          }
          .ant-select-selection-placeholder {
            color: #acacca !important;
            z-index: 2;
          }
          .ant-select-arrow {
            svg {
              fill: #fff !important;
            }
          }
        }
      }
    }
    .entryPageDepartments {
      background-color: $cardDarkBackgroundColor;
      .entryPageDepartmentName {
        .header {
          border-radius: 10px 10px 0px 0px;
          background-color: $cardDarkHeaderColor;
          .title {
            color: #fff;
          }
        }
      }
    }
    .addRole {
      .addRoleLabel {
        color: #fff;
      }
      .ant-input {
        background-color: $inputDarkColor;
        color: #fff;
        border: none;
        &::placeholder {
          color: #acacca;
        }
      }
    }
  }
  .entryRolePage-unselected {
    background-color: $cardDarkBackgroundColor;
    .entryPageDepartmentName {
      .header {
        border-radius: 10px 10px 0px 0px;
        background-color: $cardDarkHeaderColor;
        .title {
          color: #fff;
        }
      }
    }
  }
}

.settingPaneDark {
  .bodyContainer {
    .leftContainer {
      // .userInfoContainer {
      //   background-color: $cardDarkBackgroundColor;
      //   .profileFullName,
      //   .profileDepartment {
      //     color: #fff;
      //   }
      //   .descriptionContainer {
      //     .descriptionRow {
      //       .logo svg {
      //         fill: #fff;
      //       }
      //       .info {
      //         .label,
      //         .content {
      //           color: #fff;
      //         }
      //       }
      //     }
      //   }
      //   .profileStatus .online-offline {
      //     color: #fff;
      //   }
      //   .task-assign-checkbox {
      //     background-color: $cardDarkBackgroundColor;
      //     .configElementTitle {
      //       color: #fff;
      //     }
      //   }
      // }
      .usersListContainer {
        background-color: $cardDarkBackgroundColor;
        .usersSearch .ant-input {
          background-color: $inputDarkColor;
          color: #fff;
          &::placeholder {
            color: #acacca;
          }
        }
        .usersContainer {
          .userlistBodyElementName {
            .name,
            .online-offline {
              color: #fff;
            }
          }
        }
      }
      .selectedRolesName {
        background-color: $cardDarkBackgroundColor !important;
        color: #fff !important;
        span {
          color: #ddd;
        }
        ul {
          li {
            color: #dddddd;
          }
        }
      }
      .cloneAccess {
        background-color: $cardDarkBackgroundColor !important;
        color: #fff !important;
      }
    }
    .rightContainer {
      // .collapsedContainer-header {
      //   background-color: $cardDarkBackgroundColor;
      //   .configTitleContainerSelected {
      //     background-color: #42444d;
      //     color: #fff;
      //   }
      //   .configTitleContainer {
      //     background-color: #1f2a44;
      //     color: #fff;
      //   }
      // }
      .collapsedContainer-header {
        .configTitleContainer,
        .configTitleContainerSelected {
          background-color: $inputDarkColor !important;
        }
        .configTitleContainer {
          color: #fff !important;
        }
      }
      .collapsedContainer {
        .collapsedContainer-body {
          background-color: $cardDarkBackgroundColor;
          .configSearch .ant-input {
            background-color: $inputDarkColor;
            color: #fff;
            &::placeholder {
              color: #acacca;
            }
          }
          .configBody {
            .configBodyWithOptions .configElementContainer {
              background-color: $inputDarkColor;
              &:hover {
                background-color: $mainDarkBackgroundColor;
              }
              .configElementTitle {
                color: #fff;
              }
            }
          }
          .configBodyDrive {
            .ant-form-item {
              .ant-form-item-label label {
                color: #fff;
              }
              .ant-select {
                background-color: $inputDarkColor;
                border-radius: 5px;
                .ant-select-selector {
                  background-color: $inputDarkColor !important;
                  .ant-select-selection-search-input {
                    color: #fff !important;
                  }
                  .ant-select-selection-item {
                    color: #fff !important;
                  }
                  .ant-select-selection-placeholder {
                    color: #acacca;
                  }
                }
                .ant-select-arrow {
                  svg {
                    fill: #fff !important;
                    path {
                      fill: #fff !important;
                    }
                  }
                }
              }
            }
          }
          .preferencesCollapse {
            .preferencesCollapseLeftSide {
              border-color: $mainDarkBackgroundColor;
              .actionTitle {
                color: #fff;
              }
              .actionLabelContainer {
                &:hover {
                  background-color: $mainDarkBackgroundColor;
                }
                .actionLabel {
                  color: #fff;
                }
              }
              .actionLabelContainerSelected {
                background-color: $mainDarkBackgroundColor;
                .actionLabel {
                  color: #fff;
                }
              }
            }
            .preferencesCollapseRightSide {
              .selectedPreferenceTitle {
                color: #fff;
              }
              .selectedBody {
                .TrueContainer {
                  background: transparent
                    linear-gradient(
                      270deg,
                      #71cf48 0%,
                      $cardDarkBackgroundColor 100%
                    )
                    0% 0% no-repeat padding-box;
                }
                .FalseContainer {
                  background: transparent
                    linear-gradient(
                      270deg,
                      #ff4d4f 0%,
                      $cardDarkBackgroundColor 100%
                    )
                    0% 0% no-repeat padding-box;
                }
                .switchlabel {
                  color: #fff;
                }
              }
            }
          }
        }
      }
    }
  }
  .submitButtonContainer {
    background-color: $cardDarkBackgroundColor;
  }
}
//////////////////////////// Dark Mode ///////////////////////////

@media (max-width: 1450px) {
  .settingPane {
    .bodyContainer {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin: 10px;

      .leftContainer {
        .profileInformation {
          // .userInfoContainer {
          //   .descriptionContainer {
          //     margin: 0 auto;
          //   }
          // }

          .usersListContainer {
            height: 300px;
            overflow: scroll;
          }
        }
      }

      .rightContainer {
        .collapsedContainer {
          .collapsedContainer-header {
            flex-wrap: wrap;
          }

          .collapsedContainer-body {
            .preferencesCollapse {
              display: flex;
              flex-direction: column;
            }
          }
        }

        .submitButtonContainer {
          .left {
            width: 100%;
            display: flex;
            justify-content: space-between;
          }

          .right {
            justify-content: space-between;
          }
        }
      }
    }
  }

  .entryRolePage {
    .entryRolePage-body {
      .submitButtonContainer {
        top: 15px;
        left: 5px;
      }
      .addRole {
        display: flex;
        flex-direction: column;
      }
    }
  }
}

@media (max-width: 700px) {
  .bodyContainer .rightContainer {
    .collapsedContainer-header {
      flex-wrap: wrap;
    }
    .collapsedContainer .collapsedContainer-body {
      .configSearch .ant-input {
        width: 100%;
      }
    }
    .submitButtonContainer {
      flex-wrap: wrap;
      justify-content: center;
      .left {
        margin-bottom: 10px;
      }
      .right {
        flex-wrap: wrap;
      }
    }
  }
}

@media (max-width: 460px) {
  span.anticon.anticon-lock {
    font-size: 50px;
  }
  .entryRolePage .entryRolePage-header .entryPageDescription {
    font-size: 16px;
  }
  .entryRolePage .entryRolePage-body .headerSearch .headerSearch-iconInput {
    width: 170px;
  }
}

// @media screen and (max-width: 980px) {
//   .entryRolePage
//   .addRole{
//     display: flex;
//     flex-direction: column;
//     .addRoleLabel{
//       margin-left: auto;
//       margin-right: auto;
//     }
//     .addRoleInput{
//       margin: 10px;
//     }
//     .AddRoleButtonDepartment{
//       margin: 0px auto;
//     }
//   }
// }
/*

.configTitleContainer {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 10px;
        width: 100%;
        background-color: #1264a3;
        // @include size(calc(100% - 15px), 36px);
        // background-color: $labelColor;
        // opacity: 1;
        // margin-bottom: 3px;
        // margin-left: 20px;
        // padding-left: 20px;
        cursor: pointer;
        .titleIcon {
          @include size(30px, 30px);
          // margin-top: 5px;
          // margin-left: -5px;
          color: #fff;
        }
        .configTitle {
          font: normal normal 600 16px/39px Open Sans;
          letter-spacing: 0px;
          color: #fff;
          // @include textStyle(left);
          // font: normal normal normal 20px/39px Open Sans;
        }
      }

*/
