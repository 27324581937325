@import "../../index.scss";
@import "../../config.scss";
@import './../../assets/styles/breakpoints.scss';

$headerDarkColor: #12131b;
$inputDarkColor: #2a2b3a;
$mainDarkBackgroundColor: #20212d;

.sidebarContainer {
  background-color: #1f2a44 !important;
  background-size: cover !important;
  overflow: auto;
  display: flex;
  z-index: 2;
  flex-direction: column;
  flex: 0 0 content !important;
  max-width: 250px !important;
  height: 100vh !important;
  animation: sidebarAnimation 0.5s;
  // Scrollbar
  ::-webkit-scrollbar {
    width: 8px !important;
    height: 8px !important;
  }

  // display none for mobile dimensions
  @media only screen and (max-width: 413px) {
    ::-webkit-scrollbar {
      display: none;
    }
  }

  @include mobile{
    display: none;
  }

  /* Track */

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */

  ::-webkit-scrollbar-thumb {
    background: lighten(#1f2a44, 10%);
    border-radius: 5px;
    border: none;
    &:hover {
      background-color: #35415e;
    }
  }
  .ant-menu-vertical {
    border: 0;
  }
  .pageTitle {
    color: white;
    font-size: 16px;
    margin-top: 2px;
    padding-left: 10px;
  }
  .add-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    max-width: 122px;
    height: 32px;
    background-color: transparent;
    border: 1px solid #ffffff;
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 20px;
    opacity: 1;
    // display: flex;
    // align-items: center;
    // justify-content: space-evenly;
    padding: 4px 10px !important;
    &:hover,
    &:focus,
    &:active {
      background-color: #35415e;
      border-color: #fff;
    }
  }
  .add-button-text {
    text-align: center;
    padding: 0;
    margin: 0;
    font: normal normal 500 14px "Open Sans";
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
  }

  .pageIcon {
    fill: #fff;
    width: 20px;
    opacity: 1;
    min-width: 20px !important;
  }

  .sidebarCompanyLogo {
    margin-top: 30px;
    padding-bottom: 10px;
    height: 90px;
    filter: brightness(10);
  }

  .pageContainer {
    .pageIconContainer {
      display: flex;
    }
    @include flex(center, flex-start);
    padding-bottom: 50px;
  }

  .centeredItem {
    @include flex(center, center);
    width: 100%;
    cursor: pointer;
    .ant-btn-icon {
      justify-content: space-evenly;
      display: flex;
      align-items: center;
      width: 100%;
    }
  }
  .menu {
    max-height: 77%;
    overflow-y: auto;
    width: 100%;
    margin-left: 0px;
    display: flex;
    flex-direction: column;

    .ant-menu-vertical .ant-menu-item,
    .ant-menu-vertical-left .ant-menu-item,
    .ant-menu-vertical-right .ant-menu-item,
    .ant-menu-inline .ant-menu-item,
    .ant-menu-vertical .ant-menu-submenu-title,
    .ant-menu-vertical-left .ant-menu-submenu-title,
    .ant-menu-vertical-right .ant-menu-submenu-title,
    .ant-menu-inline .ant-menu-submenu-title,
    .ant-menu-submenu {
      height: 40px;
      display: flex;
      align-items: center;
      margin-top: 0px;
      margin-bottom: 0px;
      overflow: hidden;
      line-height: 33px;
      border-radius: 0px;
      color: white;
    }
    .ant-menu-inline-collapsed {
      width: 80px !important;
      > .ant-menu-item .ant-menu-item-icon + span {
        display: none !important;
      }
    }
    .ant-menu {
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      font-variant: tabular-nums;
      line-height: 1.5715;
      font-feature-settings: "tnum", "tnum";
      margin-bottom: 0;
      padding-left: 0;
      font-size: 14px;
      line-height: 0;
      text-align: left;
      list-style: none;
      outline: none;
      transition: background 0.3s, width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;

      .ant-menu-inline,
      .ant-menu-vertical,
      .ant-menu-vertical-left {
        border-right: 0px solid #f0f0f0;
      }
      .ant-menu-item,
      .ant-menu-submenu {
        .ant-menu-title-content {
          a:hover {
            color: transparent !important;
          }
        }
      }
      .ant-menu-submenu {
        padding: 0;
        color: #f0f0f0;
        height: 38px;
        .ant-menu-submenu-title {
          display: flex;
          align-items: center;
          margin-bottom: 0;
          margin-inline: 0px !important;
          .ant-menu-item-icon {
            min-width: 20px;
          }
          .ant-menu-title-content {
            font-size: 16px;
            padding-left: 4px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .ant-menu-submenu-arrow {
            color: #f0f0f0;
          }
          .ant-menu-submenu-expand-icon {
            right: 5px;
            height: 100%;
          }
        }
      }
      .ant-menu-submenu-selected {
        border-right: 10px solid #fff;
      }
      .ant-menu-item-selected,
      .ant-menu-submenu-selected {
        margin: 3px 0;
        background-color: #4c5569 !important;
        color: #fff;
        margin-right: -1px;
      }
      .ant-menu-vertical .ant-menu-item:not(:last-child),
      .ant-menu-vertical-left .ant-menu-item:not(:last-child),
      .ant-menu-vertical-right .ant-menu-item:not(:last-child),
      .ant-menu-inline .ant-menu-item:not(:last-child) {
        margin-bottom: 0px;
      }
      .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
        background-color: #4c5569 !important;
      }

      .ant-menu-item:hover,
      .ant-menu-item-active,
      .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
      .ant-menu-submenu-active,
      .ant-menu-submenu-title:hover,
      .ant-menu-submenu:hover {
        color: #fff;
        background-color: #4c5569 !important;
      }
      .ant-menu-item-selected::after {
        position: relative;
        top: -82px;
        right: -224px;
        bottom: 2px;
        font-size: 38px;
        border-right: 10px solid white;
        transform: scaleY(0.0001);
        opacity: 1;
        transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1),
          opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
        content: "";
      }
    }
  }
}
.ant-menu-light.ant-menu-submenu {
  z-index: 9999 !important;
  > .ant-menu {
    background-color: #1f2a44;
    border-radius: 0px 5px 5px 0px !important;
    left: 0px;
    .ant-menu-item {
      border-bottom: 1px solid rgba(255, 255, 255, 0.432) !important;
      margin: 0 auto !important;
      height: 40px;
      display: flex;
      border-radius: 0px;
      align-items: center;
      &:last-child {
        border-bottom: none !important;
      }
    }

    .pageContainer {
      display: flex;
      .pageIconContainer {
        display: flex;
        align-items: center;
      }
      .pageIcon {
        height: 100%;
        max-height: 26px;
        fill: #fff;
        width: 20px;
        opacity: 1;
      }
      .pageTitle {
        color: white;
        font-size: 16px !important;
        margin-top: 2px;
        padding-left: 10px;
      }
    }
    .ant-menu-item-active {
      color: #fff;
      background-color: #4c5569 !important;
      a:hover {
        text-decoration: none !important;
      }
    }
    .ant-menu-item-selected {
      background-color: #4c5569 !important;
    }
  }
}

.ant-menu-light.sidebar-submenu-popup-dark {
  > .ant-menu {
    background-color: $headerDarkColor;
  }
}

.sidebarContainerDark {
  background: $headerDarkColor !important;
  .newShortcutButtonStyle {
    .ant-btn:hover {
      background-color: $inputDarkColor;
    }
  }
  .menu {
    background: $headerDarkColor;
    .sidebar-submenu-popup .ant-menu {
      background-color: $headerDarkColor !important;
    }
  }
  .ant-layout-sider-trigger {
    background-color: #0b081d;
  }
}

@media only screen and (max-width: 768px) {
  .sidebar-submenu-popup {
    left: 280px !important;
  }
}

@media only screen and (max-width: 390px) {
  .sidebar-submenu-popup {
    left: 100px !important;
  }
}

@media (max-width: 460px) {
  button.ant-btn.css-dev-only-do-not-override-1lhfg1q.ant-btn-default.ant-btn-icon-only.add-button {
    display: flex;
    align-items: center;
    background-color: transparent;
    border: 1px solid #fff;
    border-radius: 5px;
    height: 32px;
    justify-content: space-evenly;
    margin-bottom: 20px;
    margin-top: 10px;
    max-width: 122px;
    opacity: 1;
    width: 90%;
  }
  span.ant-btn-icon {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
  }
  p.add-button-text {
    color: #fff;
  }
}

@keyframes sidebarAnimation {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(0%);
  }
}
