@import "../../../../../../../../index.scss";

.hoist-pricing-row {
  @include flex(center, flex-start);
  width: 100%;
  padding: 34px 10px 10px;
  border-radius: 5px;
  background-color: #f8f8fa;
  gap: 20px;

  .ant-input,
  .ant-input-number,
  .ant-select-selector {
    border-radius: 5px !important;
    border: 0 !important;
    background-color: #fff !important;
  }
}
