.image-preview-modal {
  .ant-modal-content {
    min-width: 600px;
    height: auto;
  }
  .ant-modal-body {
    padding: 0;
    img {
      width: 600px !important;
      height: 400px !important;
      object-fit: contain;
    }
  }
}