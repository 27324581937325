@import "../../../index.scss";
.confirmationModalAccountingLight {
  .ant-modal {
    width: auto !important;
    height: 210px !important;
  }

  .ant-modal-content {
    max-width: 660px;
    width: calc(100vw - 20px);
    height: 210px;
    border-radius: 10px;
    overflow: hidden;

    .ant-modal-header {
      height: 45px;
      background-color: #f8f8fa;
      align-items: center;
      color: #333238;
      display: flex;

      .ant-modal-title {
        font: normal normal 600 16px/39px Open Sans;
      }
    }

    .ant-modal-close-x {
      justify-content: center;
      align-items: center;
      display: flex;
      height: 45px;
      width: 45px;
      padding: 0;
      svg {
        fill: #333238;
        height: 15px;
        width: 15px;
      }
    }

    .ant-modal-footer {
      border-bottom: 0;
      display: none;
    }

    .ant-modal-body {
      background-color: white;
      padding: 20px;
      max-width: 660px;
      width: calc(100vw - 20px);
      height: 165px;

      .accountingConfirmationModalContainer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: stretch;
        width: stretch;
      }
      // .iconContainer {
      //   height: 35px;
      //   width: 35px;
      // }
      .question {
        display: flex;
        justify-content: center;
        align-items: center;
        font: normal normal normal 16px/25px Open Sans;
      }

      .buttonContainer {
        display: flex;
        gap: 10px;

        .confirmButton {
          @include mondayButton(#68c142, #71cf48, #fff);
        }
        .discardButton {
          @include mondayButton(#fe4c4a, #eb4345, #fff);
          svg {
            fill: white !important;
          }
        }
        .cancelButton {
          @include mondayButton(#f5f5f7, #e6e9ef, black);
          svg {
            fill: black;
          }
        }

        .textContainer {
          width: 90px;
        }
      }
    }
  }
}

.confirmationModalAccountingDark {
  .ant-modal {
    width: auto !important;
    height: 210px !important;
  }

  .ant-modal-content {
    max-width: 660px;
    width: calc(100vw - 20px);
    height: 210px;
    border-radius: 10px;
    overflow: hidden;
    background-color: #1f212d;

    .ant-modal-header {
      border-bottom: 0;
      height: 45px;
      background-color: #12131b;
      align-items: center;
      display: flex;

      .ant-modal-title {
        font: normal normal 600 16px/39px Open Sans;
        color: white;
      }
    }

    .ant-modal-close-x {
      justify-content: center;
      align-items: center;
      display: flex;
      height: 45px;
      width: 45px;
      padding: 0;
      svg{
        width: 15px;
        height: 15px;
      }
    }

    .ant-modal-footer {
      display: none;
    }

    .ant-modal-body {
      background-color: #1f212d;
      padding: 20px;
      max-width: 660px;
      width: calc(100vw - 20px);
      height: 165px;

      .accountingConfirmationModalContainer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: stretch;
        width: stretch;
      }
      // .iconContainer {
      //   height: 35px;
      //   width: 35px;
      // }
      .question {
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        font: normal normal normal 16px/25px Open Sans;
      }
      div :nth-child(3){
        color: #fff;
        span.ant-radio+*{
          color: #fff;
        }
      }
      .buttonContainer {
        display: flex;
        gap: 10px;

        .confirmButton {
          @include mondayButton(#68c142, #71cf48, #fff);
        }
        .discardButton {
          @include mondayButton(#fe4c4a, #eb4345, #fff);
          svg {
            fill: white !important;
          }
        }
        .cancelButton {
          @include mondayButton(#f5f5f7, #e6e9ef, black);
          svg {
            fill: black;
          }
        }

        .textContainer {
          width: 90px;
        }
      }
    }
  }
}
