$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.multipleHrShiftsModal {
  height: calc(100vh - 20px) !important;
  width: calc(100vw - 20px) !important;
  max-height: 500px;
  max-width: 1400px;

  .ant-modal-content {
    height: calc(100vh - 20px) !important;
    width: calc(100vw - 20px) !important;
    max-height: 500px;
    max-width: 1400px;
    .ant-modal-close {
      height: 45px;
      width: 45px;
      .ant-modal-close-x {
        height: 45px;
        width: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .ant-modal-header {
      height: 45px;
      display: flex;
      align-items: center;
      background-color: #f8f8fa;
    }
    .ant-modal-body {
      height: calc(100% - 120px);
      display: flex;
      gap: 10px;
      .ant-select {
        .ant-select-selector {
          .ant-select-selection-overflow {
            max-height: calc(100vh - 600px);
            flex-wrap: nowrap;
            overflow-y: scroll !important;
            .ant-select-selection-overflow-item:nth-last-child(2) {
              margin-top: 10px;
            }
            .ant-select-selection-item {
              padding-left: 5px;
            }
          }
        }
      }
      > .ant-form {
        width: 55%;
      }
      .ant-form {
        height: 100%;
        display: flex;
        gap: 10px;
        .employeeSelection {
          width: 30%;
        }
        .shiftDetails {
          width: 70%;
          .detailsSection {
            width: 100%;
            display: flex;
            gap: 10px;
            .labeledInputContainer {
              width: 50%;
            }
          }
        }
      }
      .selection-grid-container {
        width: 45%;
      }
    }
    .ant-modal-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

.multipleHrShiftsModalDark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      .labeledInputLabel {
        color: #fff;
      }
      .ant-select {
        .ant-select-selector {
          background-color: $inputModalDark !important;
          .ant-select-selection-overflow-item .ant-select-selection-item {
            background-color: #333238;
          }
          .ant-select-selection-search-input {
            color: #fff;
          }
          .ant-select-selection-item {
            color: #fff;
          }
          .ant-select-selection-placeholder {
            color: #acacca;
          }
        }
        .ant-select-clear {
          background-color: $inputModalDark;
          svg {
            fill: #acacca;
          }
        }
        .ant-select-arrow svg {
          fill: #fff;
        }
      }
      //Disabled
      .ant-select-disabled {
        .ant-select-selector {
          .ant-select-selection-search-input {
            color: #acacca !important;
          }
          .ant-select-selection-item {
            color: #acacca !important;
          }
        }
      }

      .ant-picker {
        background-color: $inputModalDark !important;
        .ant-picker-input {
          background-color: $inputModalDark;
          input {
            color: #f2f2f2;
            &::placeholder {
              color: #acacca;
            }
          }
          .ant-picker-suffix svg {
            color: #acacca;
          }
        }
        .ant-picker-clear {
          background-color: $inputModalDark !important;
          svg {
            fill: #acacca;
          }
        }
      }
      //Dissabled
      .ant-picker-disabled {
        .ant-picker-input input {
          color: #acacca !important;
        }
      }
      .regularShiftCheckbox {
        display: flex;
        align-items: center;
        .ant-form-item {
          margin-bottom: 0px;
          .ant-checkbox-wrapper {
            span {
              color: #fff;
            }
          }
        }
      }
      .selection-grid-container {
        .ag-root-wrapper {
          background-color: $cardModalBodyDark;
          .ag-header {
            min-height: 49px !important;
            background-color: $cardModalBodyDark;
            .ag-header-row {
              border-top: none;
            }
            .ag-header-cell-resize::after {
              width: 1px !important;
              background-color: #4a4a4a;
            }
          }
          .ag-paging-panel {
            background-color: $cardModalBodyDark;
          }
        }
      }
    }
  }
}
