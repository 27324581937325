$mainDarkBackgroundColor: #1c1d29;
$cardDarkHeaderColor: #2a2b3a;
$cardDarkBackgroundColor: #12131b;
$inputDarkColor: #2a2b3a;

.sidewalkShedPriceSheetTableContainer {
  * {
    font-family: "Open Sans" -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif;
    font-size: 14px;
  }
  // width: 100%;
  flex-wrap: nowrap;
  overflow-x: scroll;
  // border-top: solid 1px #c1c7d0;
  // border-left: solid 1px #c1c7d0;
  // border-top-left-radius: 5px;
  // margin-right: 10px;

  .blankArea {
    &:hover {
      // background-color: #2fa5ff;
    }
  }

  .sSPSRow {
    display: flex;
    //border-bottom: solid 1px #c1c7d0;

    &.blankArea {
      border-top-left-radius: 5px;

      .sSPSCol {
        // background-color: #f4f5f7;
        height: 8px;
        cursor: pointer;
        //border: 1px solid #707070;
        //border-radius: 15px;

        &:hover {
          background-color: #2fa5ff;
        }
        &:after {
          content: none;
        }
        &:before {
          content: none;
        }
      }
    }

    &.xAxis {
      // background-color: #f4f5f7;
    }

    .sSPSCol {
      //border-right: solid 1px #c1c7d0;
      // background-color: #ffffff;
      width: 100%;
      margin: 5px;
      position: relative;
      z-index: 1;

      &:after {
        content: "";
        background-color: #dfdfdf;
        position: absolute;
        width: 2px;
        height: 100%;
        left: 50%;
        top: 50%;
        bottom: 10px;
        z-index: -1;
      }

      &:before {
        content: "";
        background-color: #dfdfdf;
        position: absolute;
        width: 100%;
        height: 2px;
        left: 50%;
        top: 50%;
        z-index: -1;
      }

      &:last-child {
        &:before {
          content: none;
        }
      }

      &.blankArea {
        width: 105px;
        // background-color: #f4f5f7;
        cursor: pointer;

        &:hover {
          background-color: #2fa5ff;
        }
        &:after {
          content: none;
        }
        &:before {
          content: none;
        }
      }

      &.blankCorner {
        border-top-left-radius: 5px;
      }

      &.yAxis .sSPSInput {
        background: #1f2a44;
        color: #fff;
        font-weight: bold;
        text-align: center;
        border: none;
        min-width: 50px;
      }

      &.aggregateCorner {
        // background-color: #f4f5f7;
      }
      .yAxis {
        display: flex;
        justify-content: center;
      }
      .sSPSInput {
        min-height: unset;
        height: 32px;
        border: unset;
        border: 1px solid #dfdfdf;
        border-radius: 10px;
        width: 100%;
        transition: background-color 150ms;
        text-align: center;
        background: #f5f5f7;
        max-width: 100px;
        min-width: 50px;
        padding: 0px;

        &:hover {
          background-color: #e1f2ff;
          color: #000;
          border: 1px solid #1f2a44;
        }

        &:focus {
          background-color: #bbcdda;
          border: solid 1px #2fa5ff;
        }

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }

      .invalid-value {
        border: 1px solid red !important;
      }
    }

    &:last-child {
      .sSPSCol {
        &:after {
          content: none;
        }
      }
    }
  }
}

.settings-pricing-wrapper {
  padding: 0px 20px;
  .service-selector-section {
    margin-bottom: 20px;
    transition: 0.2s ease-in;
    .pricing-header {
      position: relative;
      text-align: center;
      h2 {
        color: #323338;
        font-size: 1.6rem;
      }
      p {
        padding: 0 50px !important;
      }
      .notesGuide {
        position: absolute;
        top: 4px;
        right: 0;
        display: flex;
        flex-direction: row;
        gap: 10px;
      }
    }
    background: #ffffff;
    box-shadow: 0px 1px 4px #00000029;
    border-radius: 10px;
    margin-top: 20px;
    padding: 15px 20px;
    height: auto;
  }

  .pricing-wrapper {
    .ant-select {
      .ant-select-selector {
        display: flex;
        align-items: center;
        background: #f5f5f7;
        border: none;
        border-radius: 5px;
        height: 32px;
        .ant-select-selection-item {
          display: flex !important;
          align-items: center !important;
        }
      }
    }
    .ant-input {
      height: 32px;
    }
    &:last-child {
      margin-top: 15px;
    }

    .subPLIAddonMatrix-card {
      margin-bottom: 30px;
      border-radius: 10px;
      box-shadow: 0px 1px 4px #00000029;
      .collapsable-item {
        display: flex;
        flex-direction: column;

        .summary-title-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 45px;
          background-color: #f8f8fa;
          border-bottom: 0;
          font-size: 16px;
          color: #323338;
          font-weight: 700;
          padding: 10px 20px;
          border-radius: 10px 10px 0px 0px;

          .left-side {
            display: flex;
            flex-direction: row;
          }

          .buttons {
            display: flex;
            flex-direction: row;
            gap: 20px;
          }
        }

        .subPLIAddonMatrix-body {
          background-color: #fff;
          border-radius: 0 0 10px 10px;

          .editable-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            gap: 10px;
            padding: 8px;

            .editable-container-div {
              display: flex;
              flex-direction: row;
              gap: 20px;
              align-items: flex-end;

              .editable-section {
                display: flex;
                flex-direction: column;
                gap: 2px;

                .title-indic {
                  font-weight: 600;
                  padding-left: 10px;
                }

                .ant-input-affix-wrapper {
                  overflow: hidden;
                  padding: 0 11px;
                  background-color: #f5f5f7;

                  .ant-input {
                    border-radius: 5px;
                  }
                  .ant-input-suffix {
                    translate: 50px;
                    background-color: #71cf48;

                    .tickIcon {
                      min-width: 26px;
                      height: 26px;
                      padding: 5px;
                      border-radius: 5px;
                      background-color: #71cf48;
                      cursor: pointer;
                      transition: 0.4s all ease-in-out;
                    }
                  }
                }
              }
            }

            .new-heat-buttons {
              display: flex;
              flex-direction: row;
              gap: 20px;
              align-items: flex-end;

              .editable-section {
                display: flex;
                flex-direction: column;
                gap: 2px;

                .title-indic {
                  font-weight: 600;
                }
              }
            }
          }

          .collapsable-content-item {
            margin-top: 20px;
            min-height: 400px;
          }
        }
      }
    }
  }

  .service-selector-section {
    .ant-select {
      .ant-select-selector {
        display: flex;
        align-items: center;
        background: #f5f5f7;
        border: none;
        border-radius: 5px;
        height: 32px;
        .ant-select-selection-item {
          display: flex !important;
          align-items: center !important;
        }
      }
    }
    .ant-input {
      height: 32px;
    }
    &:last-child {
      margin-top: 15px;
    }
  }
}

.settings-pricing-wrapper-mobile {
  padding: 8px;

  .service-selector-section {
    .pricing-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    h2 {
      margin: 0 auto;
    }
  }

  .pricing-wrapper {
    .pricing-scheme-container {
      .price-sheet-width-label {
        padding-left: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }

    .subPLIAddonMatrix-card {
      .editable-container {
        overflow-x: auto;
      }

      .collapsable-content-item {
        .price-sheet-component {
          .price-sheet-width-label {
            padding-left: 20px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          }
        }
      }
    }
  }
}

.settings-pricing-wrapper-dark {
  .service-selector-section {
    background-color: $cardDarkBackgroundColor;
    .pricing-header {
      h2 {
        color: #fff;
      }
    }
    .ant-badge {
      background-color: $inputDarkColor !important;
      .ant-badge-status-text {
        color: #fff;
      }
    }
    .ant-select {
      .ant-select-selector {
        background-color: $inputDarkColor !important;
        color: #fff;
        .ant-select-selection-search-input {
          height: 32px;
          background-color: $inputDarkColor;
          color: #fff;
        }
        .ant-select-selection-item {
          height: 32px;
        }
      }
      .ant-select-arrow svg {
        fill: #fff;
      }
    }
  }
  .pricing-wrapper {
    .ant-alert-info {
      background-color: #373c4b;
      .ant-alert-description {
        color: #fff;
      }
    }
    .pricing-scheme-container {
      background-color: $cardDarkBackgroundColor;
      .price-schemes-actions-container {
        .price-input-label {
          color: #fff;
        }
        .ant-input-affix-wrapper {
          background-color: $inputDarkColor;
          border: none;
          .ant-input {
            background-color: $inputDarkColor !important;
            color: #fff;
            &::placeholder {
              color: #acacca;
            }
          }
        }
        .ant-select {
          .ant-select-selector {
            background-color: $inputDarkColor !important;
            color: #fff;
            .ant-select-selection-search-input {
              background-color: $inputDarkColor;
              color: #fff;
            }
            .ant-select-selection-item {
              color: #fff;
            }
          }
          .ant-select-arrow svg {
            fill: #fff;
          }
        }
        .ant-switch {
          background-color: $inputDarkColor;
        }
        .ant-switch-checked {
          background-color: #fe4c4a;
        }
      }
      .price-sheet-component {
        .price-sheet-width-label,
        .price-sheet-height-label {
          color: #fff;
        }
        .sidewalkShedPriceSheetTableContainer {
          .sSPSCol .sSPSInput {
            background-color: $inputDarkColor;
            color: #fff;
            border: 1px solid #1f2a44;
          }
          .yAxis,
          .xAxis {
            .sSPSInput {
              background-color: #1f2a44;
            }
          }
        }
      }
      .price-list-table .addonsList {
        background-color: $cardDarkBackgroundColor;
        .ant-btn {
          border: none;
        }
      }
    }
    .singleDimensionPriceTable {
      .priceTable {
        .table-labels {
          color: #fff;
        }
        .priceInput {
          background-color: $inputDarkColor;
          .ant-input-number-input {
            background-color: $inputDarkColor;
            color: #fff;
          }
        }
      }
    }
    .settingSection {
      .addons-table-section {
        background-color: $cardDarkBackgroundColor;
        .sectionTitle {
          background-color: $cardDarkHeaderColor;
          svg {
            fill: #fff;
          }
          h3 {
            color: #fff;
          }
        }
        .pricingAddonsDataContainer {
          transition: 0.2s ease-in;
          .addonsList {
            transition: 0.2s ease-in;
            background-color: $cardDarkBackgroundColor !important;
          }
          .cardList {
            .card-component {
              background-color: $mainDarkBackgroundColor;
              .card-title {
                background-color: $cardDarkHeaderColor;
                p {
                  color: #fff;
                }
              }
              .card-createdBy,
              .card-lastModified,
              .card-description {
                color: #fff;
              }
            }
          }
        }
      }
      .sectionTitleCollapsed {
        background-color: $cardDarkHeaderColor;
        svg,
        h3 {
          fill: #fff;
          color: #fff;
        }
      }
    }
    .subPLIAddonMatrix-card {
      background-color: $cardDarkBackgroundColor;
      .collapsable-item {
        .summary-title-item {
          background-color: $cardDarkHeaderColor;
          svg,
          h3 {
            fill: #fff;
            color: #fff;
          }
        }

        $mainDarkBackgroundColor: #1c1d29;
        $cardDarkHeaderColor: #2a2b3a;
        $cardDarkBackgroundColor: #12131b;
        $inputDarkColor: #2a2b3a;

        .subPLIAddonMatrix-body {
          background-color: $mainDarkBackgroundColor;

          .editable-container {
            .editable-container-div {
              .editable-section {
                .ant-input-affix-wrapper {
                  background-color: $inputDarkColor;
                }
              }
            }
          }

          .collapsable-content-item {
            margin-top: 20px;
            min-height: 400px;
          }
        }

        .editable-section {
          .title-indic {
            color: #fff;
          }
          .ant-input {
            background-color: $inputDarkColor !important;
            color: #fff;
            &::placeholder {
              color: #acacca;
            }
          }
          .ant-select {
            .ant-select-selector {
              background-color: $inputDarkColor !important;
              color: #fff;
              .ant-select-selection-search-input {
                background-color: $inputDarkColor;
                color: #fff;
              }
              .ant-select-selection-item {
                color: #fff;
              }
              .ant-select-selection-placeholder {
                color: #acacca;
                z-index: 2;
              }
            }
            .ant-select-arrow svg {
              fill: #fff;
            }
          }
        }
        .ant-switch {
          background-color: $inputDarkColor;
        }
        .ant-switch-checked {
          background-color: #1677ff;
        }
        .collapsable-content-item {
          .price-sheet-component {
            .price-sheet-width-label,
            .price-sheet-height-label {
              color: #fff;
            }
            .sidewalkShedPriceSheetTableContainer {
              .sSPSCol .sSPSInput {
                background-color: $inputDarkColor;
                color: #fff;
                border: 1px solid #1f2a44;
              }
              .yAxis,
              .xAxis {
                .sSPSInput {
                  background-color: #1f2a44;
                }
              }
            }
          }
        }
      }
    }
    .hoist-pricing-config-card {
      background-color: $cardDarkBackgroundColor;
      .config-card-header {
        background-color: $cardDarkHeaderColor;
        color: #fff;
      }
      .config-card-body {
        background-color: $cardDarkBackgroundColor;
        .hoist-config-card {
          .header {
            background-color: $cardDarkHeaderColor;
            .title-section {
              color: #fff;
            }
          }
          .content {
            background-color: $mainDarkBackgroundColor;
            .metadata {
              color: #fff;
              svg,
              b {
                fill: #fff;
                color: #fff;
                path {
                  fill: #fff;
                }
              }
            }
          }
        }
      }
    }
  }
  .grid-container {
    background-color: $cardDarkBackgroundColor !important;
    .ag-column-drop {
      background-color: $cardDarkBackgroundColor !important;
    }
    .ag-header {
      height: 49px !important;
      max-height: 49px !important;
      box-shadow: none;
      background-color: $cardDarkBackgroundColor !important;
      .ag-header-cell {
        padding-left: var(--ag-cell-horizontal-padding) !important;
        padding-right: var(--ag-cell-horizontal-padding) !important;
        .ag-header-cell-text {
          font-size: 16px;
          font-weight: 600;
        }
      }
    }
    .ag-side-buttons {
      background-color: $cardDarkBackgroundColor;
    }
    .ag-paging-panel {
      border: none;
      background-color: $cardDarkBackgroundColor;
    }
  }
}

.price-modal-container {
  justify-content: center;
}

.appendAddonButton,
.settingsFooter {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  .mondayButtonContainer {
    display: flex;
    align-items: center;
  }
  .create-new-btn,
  .create-price-btn {
    height: 32px;
    background: #71cf48;
    &:hover {
      color: #fff;
      filter: brightness(1.2);
      transition: 0.3s;
    }
    &:focus {
      color: #fff;
    }
  }
}

.pricing-scheme-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 1px 4px #00000029;
  border-radius: 10px;
  padding: 25px;
  height: auto;
  transition: 0.2s ease-in;
  .price-schemes-actions-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    margin-bottom: 15px;
    .form-group {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      // width: 22%;
      &:last-child {
        width: auto;
      }

      .price-input-label {
        color: #323338;
        font-weight: 600;
        font-size: 0.9rem;
      }

      // .save-button {
      //   position: absolute;
      //   bottom: 2px;
      //   right: 4px;
      //   min-width: 26px;
      //   height: 26px;
      //   padding: 5px;
      //   background-color: #71cf48;
      //   cursor: pointer;
      //   transition: 0.4s all ease-in-out;

      //   &:hover {
      //     background-color: #7ce74f;
      //   }

      //   path {
      //     fill: #fff;
      //   }
      // }

      .ant-switch-checked {
        background: #fe4c4a;
      }
      .ant-input-affix-wrapper {
        overflow: hidden;
        padding: 0 11px;
        .ant-input {
          border-radius: 5px;
        }
        .ant-input-suffix {
          translate: 50px;
          background-color: #71cf48;

          .Test123 {
            min-width: 26px;
            height: 26px;
            padding: 5px;
            border-radius: 5px;
            background-color: #71cf48;
            cursor: pointer;
            transition: 0.4s all ease-in-out;
          }
        }
      }
    }

    // .sectionTitle {
    //   font: normal normal normal 32px/37px Helvetica;
    //   letter-spacing: 0.48px;
    //   color: #383554;
    //   margin-bottom: 30px;
    // }

    .new-price-btn {
      margin-top: 5px;
      height: 32px;
      &:hover {
        color: #fff;
        filter: brightness(1.2);
        transition: 0.3s;
      }
      &:focus {
        color: #fff;
      }
    }

    .remove-price-btn {
      margin-top: 5px;
      background: #fe4c4a;
      height: 32px;
      color: #fff;
      .mondayButtonDivider {
        background-color: #eb4345;
      }
      &:hover {
        color: #fff;
        filter: brightness(1.2);
        transition: 0.3s;
      }
      &:focus {
        color: #fff;
      }
    }

    .edit-logs-btn {
      margin-top: 5px;
      background: #1264a3;
      height: 32px;
      color: #fff;
      .mondayButtonDivider {
        background-color: #1264a3;
      }
      &:hover {
        color: #fff;
        filter: brightness(1.2);
        transition: 0.3s;
      }
      &:focus {
        color: #fff;
      }
    }

    .invalid-value {
      border: 1px solid red !important;
    }
  }
}

.apply-changes-btn {
  position: absolute !important;
  right: 2% !important;
  bottom: 2% !important;
  z-index: 2;
  height: 32px !important;
  box-shadow: 0px 0px 22px -11px rgba(0, 0, 0, 1) !important;
  -webkit-box-shadow: 0px 0px 22px -11px rgba(0, 0, 0, 1) !important;
  -moz-box-shadow: 0px 0px 22px -11px rgba(0, 0, 0, 1) !important;
  &:hover {
    color: #fff !important;
    filter: brightness(1.2);
    transition: 0.3s;
  }
  &:focus {
    color: #fff !important;
  }
}

.price-sheet-table-width {
  margin: 10px;
  font-weight: bold;
  text-align: center;
}

.price-sheet-table-flex-box {
  display: flex;

  .rotate-270-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .price-sheet-table-container {
    width: 100%;
  }
}

.price-sheet-height-label {
  text-orientation: mixed;
  writing-mode: vertical-rl;
  text-align: center;
  transform: rotate(180deg);
  // margin-right: 7px;
  font: normal normal 900 20px Avenir;
  letter-spacing: 10px;
  color: #323338;
}

.price-sheet-width-label {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font: normal normal 900 20px Avenir;
  letter-spacing: 10px;
  color: #323338;
  position: relative;

  .action-buttons {
    position: absolute;
    right: 10px;
    display: flex;
    flex-direction: row;
    gap: 10px;

    .editIcon {
      min-width: 32px;
      height: 32px;
      padding: 7px 9px;
      background-color: #1264a3;
      border-radius: 5px;
      cursor: pointer;

      &:hover {
        background-color: #0f5c97;
      }

      path {
        fill: #fff;
      }
    }
  }
}

.price-sheet-height-table-element {
  display: flex;
  justify-content: center;
}

.appendButtonsContainer {
  display: flex;
  margin: 0 10px 0 72px;

  .appendButton {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 5px;

    .ant-btn {
      width: 20px;
      height: 20px;
      padding: 3px;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      transition: 0.4s ease;

      &.customVisible {
        opacity: 1;
        pointer-events: auto;
      }

      &.customInvisible {
        opacity: 0;
        pointer-events: none;
      }

      img {
        margin-top: -10px;
      }
    }
  }
}

.verticalAppendButtonsContainer {
  width: 25px;
  margin-top: 21px;

  .appendButton {
    height: 38px;
    display: flex;
    align-items: center;
    padding-right: 5px;
    position: relative;
    margin-top: 3px;

    .ant-btn {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: flex-end;
      padding: 3px;
      transition: 0.4s ease;

      &.customVisible {
        opacity: 1;
        pointer-events: auto;
      }

      &.customInvisible {
        opacity: 0;
        pointer-events: none;
      }

      img {
        margin-top: -10px;
      }
    }
  }
}

.ant-popover.removeButtonContainer {
  padding: 0;

  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-inner {
    background-color: transparent;
    box-shadow: none;

    .ant-popover-inner-content {
      padding: 0;

      .ant-btn {
        width: 20px;
        height: 20px;
        padding: 3px;

        img {
          margin-top: -10px;
        }
      }
    }
  }
}

.append-new-price-in-price-list {
  margin-top: 20px;
}
.pricingAddonsDataContainerCollapsed {
}

.pricingAddonsDataContainer {
  padding: 50px 20px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  transition: 0.2s ease-in;
  .search-Div {
    width: 100%;
    height: 32px;

    .search-comp {
      width: 300px;
      background: #f5f5f7 0% 0% no-repeat padding-box;
      border: none;
      border-radius: 5px;
      opacity: 1;

      .ant-input {
        background: transparent;
      }
    }
  }

  .addonsList {
    width: 100%;
    margin-top: 1rem;
    padding: 40px 30px;
    box-shadow: 0px 3px 6px #00000029;
    background-color: #fff;
    border-radius: 10px;
    transition: 0.2s ease-in;
    .toBeRemoved {
      background-color: rgb(224, 78, 78) !important;
      color: #fff;
    }

    .addonColumnHeader {
      background-color: #fff;
      color: #000;
      font-size: 1.2rem;
      border-left: none;
      border-top: none;
      border-right: none;
      position: relative;
      height: 50px;
      transition: 0.2s ease-in;
      &:last-child {
        border-right: none;
      }
      &:not(.addonColumnHeader:last-child)::after {
        content: "";
        height: 15px;
        background-color: #000;
        width: 1px;
        position: absolute;
        right: 0;
        margin-top: 4px;
      }
      &:hover {
        background-color: rgba(245, 245, 245, 0.986);
        cursor: pointer;
      }
    }

    tr:nth-child(even) {
      background-color: rgb(248, 248, 248);
      .addonCell {
        input[type="text"],
        input[type="number"] {
          background-color: rgb(248, 248, 248);
        }
      }
    }

    .addonCell {
      border-left: none;
      border-top: none;
      border-right: none;
      border-color: #f5f8ff;
      input[type="text"]:hover {
        background-color: #e1f2ff !important;
      }
      input[type="text"]:focus {
        background-color: #e1f2ff;
      }
      .ant-btn {
        border: none;
        background: transparent;
      }
    }

    table {
      position: relative;
    }

    .add-unit {
      // position: absolute;
      top: 0;
      left: 0;
      width: 32px;
      height: 32px;
      padding: 0;
      background-color: #1264a3;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      margin-top: 10px;
    }
  }
}

.sectionTitleCollapsed {
  margin-top: 20px;
  // margin-bottom: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 30px;
  background: #f7f8fa;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  .sectionTitleCollapsed-title {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 1.2rem;
    margin: 0;
    padding: 5px;
  }
}

.addons-table-section {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 1px 4px #00000029;
  border-radius: 10px;
  height: auto;

  .pricingAddonsDataContainer {
    width: 100%;
    padding: 50px 20px !important;
    box-shadow: none;
    transition: 0.2s ease-in;

    .addonsList {
      box-shadow: none;
      padding: 0 15px;
      margin: 0;
      transition: 0.2s ease-in;

      .searchAndButton {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 16px;

        .search-comp {
          width: 300px;
          background: #f5f5f7 0% 0% no-repeat padding-box;
          border: none;
          border-radius: 5px;
          opacity: 1;

          .ant-input {
            background: transparent;
          }
        }
      }

      .actionButtons {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 20px;

        .showButton {
          padding: 0px 6px;
          background-color: transparent;
          border: none;
          font-size: 10px;
          text-decoration: underline;
        }
      }
    }
  }
  .sectionTitle {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 30px;
    background: #f7f8fa;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    h3 {
      display: flex;
      align-items: center;
      margin-bottom: 0px;
      font-weight: bold;
      font-size: 1.2rem;
    }
  }

  .headerSearch {
    background-color: #fff;
    display: flex;
    border-radius: 5px;
    height: 32px;
    .ant-input-affix-wrapper > input.ant-input {
      height: 32px;
      background-color: #f5f5f5;
    }
    .iconSearch {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 10px;
      path#Path_6858_00000086666139294581268680000013212754614146113725_ {
        fill: #142735;
      }
    }
    .searchInput {
      margin-left: 10px;
      border: none;
      border-radius: 5px;
    }
  }
}

.price-list-table {
  .pricingAddonsDataContainer {
    width: 100%;
    box-shadow: none;
    padding: 0 !important;
    .addonsList {
      box-shadow: none;
      padding: 0 15px;
      margin: 0;
      transition: 0.2s ease-in;
    }
  }
}
.addonsList {
  transition: 0.2s ease-in;
  table {
    width: 100%;
    input {
      width: 100%;
      min-height: unset;
      height: 38px;
      border-radius: unset;
      border: none;
      transition: background-color 150ms;
      padding: 0 25px;

      &:hover {
        background-color: #e1f2ff;
      }

      &:focus {
        background-color: #e1f2ff;
        border: solid 1px #2fa5ff;
      }
    }
    td {
      text-align: center;
      border: solid 0.5px #383554;
      .radio-group-addon-type {
        padding: 5px 10px;
        div {
          text-align: start;
          width: min-content;
        }
      }
    }
    thead {
      background-color: #1f2a44;
      th {
        text-align: center;
        border: solid 0.5px #383554;
        padding: 5px 25px;
        font: normal normal bold 20px Helvetica;
        letter-spacing: 2.2px;
        color: #fff;
      }
    }

    .invalid-value {
      border: 1px solid red !important;
    }
  }
  margin-bottom: 14px;

  .addonHeader {
    display: flex;
    background-color: #1f2a44;
    color: #fff;

    .addonColumnHeader {
      padding: 5px 25px;
      width: 100%;
      text-align: center;
      font: normal normal bold 22px Helvetica;
      letter-spacing: 2.2px;
      color: #383554;
      border-right: solid 0.5px #383554;
      border-bottom: solid 0.5px #383554;
      border-top: solid 0.5px #383554;
      &:first-child {
        border-left: solid 0.5px #383554;
      }
    }
  }

  .addonRow {
    display: flex;
    background-color: #ffffff;
    border-right: solid 1px #c1c7d0;
    border-left: solid 1px #c1c7d0;

    .addonCell {
      width: 100%;
      text-align: center;
      vertical-align: center;
      border-right: solid 0.5px #383554;
      border-bottom: solid 0.5px #383554;

      &:first-child {
        border-left: solid 0.5px #383554;
      }

      input {
        min-height: unset;
        height: 38px;
        border-radius: unset;
        border: none;
        width: 100%;
        transition: background-color 150ms;
        padding: 0 25px;

        &:hover {
          background-color: #e1f2ff;
        }

        &:focus {
          background-color: #e1f2ff;
          border: solid 1px #2fa5ff;
        }
      }

      .radio-group-addon-type {
        //display: flex;
        padding: 5px;
        text-align: start;

        .ant-radio-inner {
          height: 20px;
          width: 20px;
        }

        .ant-radio-inner::after {
          top: 3px;
          left: 3px;
          width: 12px;
          height: 12px;
        }
      }

      &.modifierCell {
        padding: 7px 0;

        .link {
          cursor: pointer;
          color: #0000ee;
          vertical-align: center;
          margin: 0 25px;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    &:nth-child(2n - 1) {
      background-color: #f7f7f7;

      input {
        background-color: #f7f7f7;
      }
    }
  }

  .toBeRemoved {
    background: #a10000;
  }
}

.noPricingModal {
  .ant-modal-content {
    .ant-modal-close {
      height: 45px;
      width: 45px;
      .ant-modal-close-x {
        height: 45px;
        width: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .ant-modal-header {
      height: 45px;
      display: flex;
      align-items: center;
      background-color: #f8f8fa;
    }
    .ant-modal-body {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px;

      .body-container {
        p {
          font-weight: 600;
          font-size: 16px;
          color: #323338;
        }

        label {
          padding-left: 10px;
          font-weight: 500;
          font-size: 16px;
          color: #323338;
        }
      }
    }
    .ant-modal-footer {
      display: flex;
      justify-content: space-between;
    }
  }
}

@media only screen and (max-width: 821px) {
  .pricing-scheme-container .price-schemes-actions-container .form-group {
    width: 98%;
  }
  .pricing-scheme-container .price-schemes-actions-container {
    display: grid;
    grid-template-columns: auto auto;
  }
  .addons-table-section .pricingAddonsDataContainer {
    .addonsList {
      margin-left: -40px;
    }
  }
}
.addonsList {
  table {
    width: 100%;
    input {
      width: 100%;
      min-height: unset;
      height: 38px;
      border-radius: unset;
      border: none;
      transition: background-color 150ms;
      padding: 0 25px;

      &:hover {
        background-color: #e1f2ff;
      }

      &:focus {
        background-color: #e1f2ff;
        border: solid 1px #2fa5ff;
      }
    }
    td {
      text-align: center;
      border: solid 0.5px #383554;
      .radio-group-addon-type {
        padding: 5px 10px;
        div {
          text-align: start;
          width: min-content;
        }
      }
    }
    thead {
      background-color: #1f2a44;
      th {
        text-align: center;
        border: solid 0.5px #383554;
        padding: 5px 25px;
        font: normal normal bold 20px Helvetica;
        letter-spacing: 2.2px;
        color: #fff;
      }
    }

    .invalid-value {
      border: 1px solid red !important;
    }
  }
  margin-bottom: 14px;

  .addonHeader {
    display: flex;
    background-color: #1f2a44;
    color: #fff;

    .addonColumnHeader {
      padding: 5px 25px;
      width: 100%;
      text-align: center;
      font: normal normal bold 22px Helvetica;
      letter-spacing: 2.2px;
      color: #383554;
      border-right: solid 0.5px #383554;
      border-bottom: solid 0.5px #383554;
      border-top: solid 0.5px #383554;
      &:first-child {
        border-left: solid 0.5px #383554;
      }
    }
  }

  .addonRow {
    display: flex;
    background-color: #ffffff;
    border-right: solid 1px #c1c7d0;
    border-left: solid 1px #c1c7d0;

    .addonCell {
      width: 100%;
      text-align: center;
      vertical-align: center;
      border-right: solid 0.5px #383554;
      border-bottom: solid 0.5px #383554;

      &:first-child {
        border-left: solid 0.5px #383554;
      }

      input {
        min-height: unset;
        height: 38px;
        border-radius: unset;
        border: none;
        width: 100%;
        transition: background-color 150ms;
        padding: 0 25px;

        &:hover {
          background-color: #e1f2ff;
        }

        &:focus {
          background-color: #e1f2ff;
          border: solid 1px #2fa5ff;
        }
      }

      .radio-group-addon-type {
        //display: flex;
        padding: 5px;
        text-align: start;

        .ant-radio-inner {
          height: 20px;
          width: 20px;
        }

        .ant-radio-inner::after {
          top: 3px;
          left: 3px;
          width: 12px;
          height: 12px;
        }
      }

      &.modifierCell {
        padding: 7px 0;

        .link {
          cursor: pointer;
          color: #0000ee;
          vertical-align: center;
          margin: 0 25px;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    &:nth-child(2n - 1) {
      background-color: #f7f7f7;

      input {
        background-color: #f7f7f7;
      }
    }
  }

  .toBeRemoved {
    background: #a10000;
  }
}

@media only screen and (max-width: 821px) {
  .pricing-scheme-container .price-schemes-actions-container .form-group {
    width: 98%;
  }
  .pricing-scheme-container .price-schemes-actions-container {
    display: grid;
    grid-template-columns: auto auto;
  }
  .addons-table-section {
    .pricingAddonsDataContainer {
      .addonsList {
        .actionButtons {
          gap: 10px;
        }
      }
    }
  }
  .new-price-btn {
    margin-top: 10px;
  }
  .remove-price-btn {
    margin-top: 10px;
  }
}

@media (max-width: 460px) {
  .pricing-scheme-container .price-schemes-actions-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .pricing-scheme-container .price-schemes-actions-container .priceSchemeTitle {
    display: flex;
    flex-direction: column;
  }
  .pricing-scheme-container .price-schemes-actions-container .mondayButtonText {
    display: none;
  }
  .pricing-scheme-container
    .price-schemes-actions-container
    .mondayButtonDivider {
    display: none;
  }
  .addons-table-section .sectionTitle {
    display: flex;
    flex-direction: column;
  }
  .addons-table-section .sectionTitle h3 {
    font-size: 1rem;
  }
  .appendAddonButton .mondayButtonContainer .mondayButtonText {
    display: none;
  }
  .appendAddonButton .mondayButtonContainer .mondayButtonDivider {
    display: none;
  }
  .card-title p {
    font-size: 14px;
  }
  .card-createdAndModified {
    font-size: 14px;
  }
}

// .CardViewComponentCollapsed {
//   @extend .addons-table-section;
//   background: #f7f8fa 0% 0% no-repeat padding-box;
//   max-height: 45px;
//   transition: max-height 0.5s linear;
// }
