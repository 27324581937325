@import "../../../../../../../index.scss";
@import "../../../../../../../assets/styles/breakpoints.scss";

$grayColorLight: #f4f5f6;

.ant-modal-wrap.newChargeItemWrap {
  overflow: hidden !important;
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.newChargeItemModal {
  min-width: 100%;
  height: 100%;
  overflow: hidden;
  top: 0 !important;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  .ant-modal-content {
    display: flex;
    height: 100%;
    flex-direction: column;
    padding: 0 !important;
    margin: 0 !important;
    background-color: #1f212d;

    .ant-modal-body {
      height: calc(100vh - 64px);
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin: 0;
      padding: 5px !important;
      box-sizing: border-box;

      ::-webkit-scrollbar-thumb {
        background: #45485b !important;
        border-radius: 10px;
      }
      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #3c3e4b !important;
      }
    }
    .ant-modal-body::-webkit-scrollbar-thumb {
      background: #45485b !important;
      border-radius: 10px;
    }
    /* Handle on hover */
    .ant-modal-body::-webkit-scrollbar-thumb:hover {
      background: #3c3e4b !important;
    }
    .ant-modal-footer {
      display: none;
    }
  }

  .ant-progress-text {
    color: white !important;
  }

  .ag-text-field-input {
    background-color: #1f212d !important;
    color: white !important;
  }
  .discardButton {
    @include mondayButton(#fe4c4a, #eb4345);
  }
  .NewItemChargeFooter {
    @include flex(center, space-between);
    .NewItemChargeFooterCancel {
      @include mondayButton(#fe4c4a, #eb4345, #fff);
    }
  }
  .ant-modal-header {
    background: #12131b;
    border: 0;

    .charge-main-header .chargesExportButtons {
      .charge-notes-btn-dark {
        background-color: rgb(18, 100, 163) !important;
        border-color: rgb(18, 100, 163) !important;
        color: #fff !important;
        border-radius: 5px !important;
        display: flex;
        justify-content: center;
        align-items: center;

        icon {
          width: 21px;
          height: 21px;
          margin-right: -1px;
          color: black;
        }
        &:hover {
          background-color: #0d4c7c !important;
          border-color: #0d4c7c !important;
          transition: 0.2s;
        }
      }
      .charge-notes-btn-dark {
        width: 32px;
        height: 32px;
      }
    }

    .headerContainer {
      display: flex;
      width: 100%;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;

      .saveChanges {
        height: 30px;
        span {
          font-weight: 500 !important;
        }
      }
      .disabled {
        background-color: white;
        cursor: not-allowed;
        .mondayButtonDivider {
          background-color: #e8e5e5;
        }
      }
    }
  }
  .newChargeItemContainer {
    display: flex;
    gap: 10px;
    height: 100%;
    overflow: auto;

    .reqTables {
      border-bottom: 5px solid #12131b;
      margin-bottom: 60px;
      border-radius: 5px;
      .header {
        width: 100%;
        background: #12131b;
        color: white;
        padding: 10px;
        border-radius: 10px 10px 0 0;
        font-weight: 600;
        font-size: 18px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
    .header-service-display {
      width: 100%;
      display: flex;
      height: 32px;
      justify-content: center;
      margin-bottom: 15px;
      .service-label-div {
        width: 960px;
        margin-left: 30px;
        margin-right: 30px;
        background: #f5f5f7;
        display: flex;
        border-radius: 10px;
        justify-content: center;
        align-items: center;
        color: #323338;
        font: normal normal 600 16px/39px Open Sans;
      }
    }
    .hideswitchChargeContainer {
      display: none;
    }
    .switchChargeContainer {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      gap: 20px;
      .switchCharge {
        height: 30px;
        border-radius: 5px;
        width: 180px;
        .ant-switch-handle {
          width: 26px;
          height: 26px;
          left: calc(100% - 30px);
        }
        .ant-switch-handle::before {
          border-radius: 50%;
        }
      }
      .appliedValueinput {
        width: 200px;
        border-radius: 5px;
      }
    }

    .itemsGroupContainer {
      justify-content: flex-start;
      gap: 20px;
      overflow: auto;
      padding: 10px;
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 300px;
      background: #12131b;
      border-radius: 5px !important;
      position: relative;
      transition: width 0.3s ease-in-out;
      color: #fff;

      .checkbox-with-label-container {
        padding: 5px;
        .new-checkbox-item {
          .ant-checkbox-wrapper {
            gap: 16px;
          }
        }
      }

      &.collapsed {
        width: 60px;
        overflow: hidden;

        > * {
          display: none;
        }
      }

      .service-estimation-name {
        font-weight: 800;
        font-size: 16px;
        background-color: transparent !important;
      }

      .services-collapse {
        display: none;
        background-color: #1f212d;
        padding: 10px;
        border-radius: 50px;
        width: fit-content;
        align-self: flex-end;

        @include tablet {
          display: block;
        }
      }

      .groupItem {
        gap: 20px;
        // height: 100vh;
        // padding: 15px;
        display: flex;
        align-items: center;
        min-width: fit-content;
        cursor: pointer;
        border-radius: 5px;

        &.collapsed {
          overflow: hidden;

          > * {
            display: none;
          }
        }

        .disabled {
          pointer-events: none;
        }
        .serviceContainer {
          display: flex;
          flex-direction: column;
          .groupItemLabel {
            height: 22px;
            font-family: "Open Sans";
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            color: #ffffff;
          }
          .groupItemSubLabel {
            font-family: "Open Sans";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            color: #ffffff;
            height: 19px;
          }
          .groupItemSubChangeOrder {
            @extend .groupItemLabel;
            font: normal normal normal 12px/12px Open Sans;
          }
        }
        .groupItemIcon {
          cursor: pointer;
          svg {
            fill: #1264a3;
          }
        }
      }
      .checkBox {
        height: 32px;
        width: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #fe4c4a 0% 0% no-repeat padding-box;
        border-radius: 50%;
      }
      .checkedActive {
        background: #71cf48 0% 0% no-repeat padding-box;
      }
    }

    .eventsContainer {
      width: 100%;
      display: flex;
      flex-direction: row;
      padding: 10px 20px;
      .groupItem {
        width: fit-content;
        border-radius: 5px;
        background-color: #3a3c4e;
        height: 42px !important;
        padding: 5px 10px !important;
        .groupItemLabel {
          font-weight: 600 !important;
          font-size: 14px !important;
          line-height: 17px;
          margin-bottom: 0 !important;
        }
        .groupItemSubLabel {
          font-weight: 400 !important;
          font-size: 14px !important;
          line-height: 17px !important;
        }
      }
      .selectedGroupItem {
        background-color: #1264a3;
      }
      .groupItem:hover {
        background-color: #1264a3;
      }
    }
    .ag-overlay-no-rows-center {
      color: #fff !important;
    }
    .PLISContainer {
      width: -webkit-fill-available;
      background: #1f212d;
      overflow: auto;
      padding: 0 !important;

      .estimation-name-charge {
        color: #fff;
        background: #12131b;
        padding: 10px;
        margin-bottom: 10px;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: #45485b;
        border-radius: 10px;
      }
      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #3c3e4b;
      }
      .ag-theme-alpine {
        padding: 0 !important;
        border-radius: 5px;
        box-shadow: none !important;
        background-color: inherit;
        // box-shadow: 10px 5px 5px red !important;
        .ag-root-wrapper {
          background: #1f212d;
          .ag-root {
            border: 0;
            .ag-header,
            .ag-header-viewport,
            .ag-header-container,
            .ag-header-row,
            .ag-header-cell,
            .ag-center-cols-clipper,
            .ag-row-even {
              background: #1f212d !important;
              color: white;
              .ag-cell {
                border: 0 !important;
                background: #1f212d !important;
                color: white;
                border-right: 1px solid #4a4a4a !important;
                font-size: 16px !important;
                font-weight: 450 !important;
              }
            }
            .ag-row-odd {
              background: #292b3a !important;
              .ag-cell {
                background: #292b3a !important;
                border-right: 1px solid #4a4a4a !important;
              }
            }
            .ag-cell {
              border: 0 !important;
              color: white;
              border-right: 1px solid #4a4a4a !important;
            }
          }
          .ag-row {
            border: 0 !important;
            border-top: 1px solid #4a4a4a !important;
          }
          .ag-row-last {
            border-bottom: 1px solid #4a4a4a !important;
          }
          .ag-side-bar {
            background-color: #12131b !important;
            .ag-side-button-button {
              color: white !important;
            }
          }
          .ag-cell-range-selected {
            border: 1px solid blue !important;
          }
        }
      }
      .reqTables {
        .checkBox {
          cursor: not-allowed !important;
          .ant-checkbox {
            cursor: not-allowed !important;
            span,
            input {
              cursor: not-allowed !important;
            }
            span::after,
            input::after {
              cursor: not-allowed !important;
            }
          }
        }
      }
    }

    .agGridCustomStyle {
      height: auto;
      min-height: 350px;

      .applyAmount {
        background-color: #fe4c4a !important;
        color: #fff !important;
        svg {
          g {
            stroke: #fff;
          }
          path {
            fill: white !important;
          }
        }
      }
      .ag-body-viewport.ag-layout-normal.ag-row-no-animation {
        min-height: 200px;
        height: 100%;
      }
      .ag-header-row-column-group {
        height: 30px !important;
        margin-top: 18px !important;
      }
      .ag-header-group-cell-with-group {
        background-color: #1264a3;
        color: #fff;
        height: 30px;
        .ag-icon-contracted,
        .ag-icon-expanded {
          color: white;
        }
        .ag-header-group-text {
          font: normal normal normal 14px Open Sans;
          letter-spacing: 0px;
          color: #ffffff;
        }
      }
      .tableHeader {
        .input-percentage-amount {
          width: 100px;
          border: none;
          height: 32px;
          background: #1f212d;
          color: #fff;
        }
        .serviceDescriptionContainerModal {
          width: fit-content !important;
        }
        @extend .ant-modal-header;
        display: flex;
        justify-content: space-between;
        height: 50px;

        .descEditHeaderModals {
          display: flex !important;
          gap: 20px;
          align-items: center;
        }
        .inforamtionContainer {
          @extend .ant-modal-header;
          width: 100%;
          display: flex;
          flex-direction: row;
          color: white;
          justify-content: space-between;
          align-items: center;
        }
        .informationChargeHeaderContainer {
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
          @extend .ant-modal-header;
          padding: 0px 9px;
          width: 100%;
          display: flex;
          flex-direction: row;
          color: white;
          align-items: center;

          .ant-switch {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 3px 0px;
            height: 30px;
          }
          .ant-switch-handle {
            top: unset;
          }
          .ant-switch-checked {
            background: #68c142 !important;
          }
          .ant-switch-unchecked {
            background: #fe4c4a !important;
          }
        }
        .titleHeaderLabel {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          font: normal normal bold Open Sans;
          font-size: 15px;
          color: white !important;
        }

        .withTooltipTitle {
          margin-left: 5px;
          cursor: pointer;
        }
        .progressChargeHeader {
          width: 200px;
          padding: 0 15px;
          > span {
            color: white;
          }
        }
        .EditDatePickerComponent {
          background: #1f212d 0% 0% no-repeat padding-box;
          border-radius: 5px;
          border: 1px solid #12131b;
          padding-bottom: 3px;

          input {
            color: #9a9b9d;
          }
          svg {
            fill: #9a9b9d !important;
          }
        }
      }
      .footerTable {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        padding: 0px 9px;
        margin-bottom: 10px;
      }
      .headerTable {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }

      .eRootWrapper,
      .ag-root-wrapper-body {
        @extend .agGridCustomStyle;
      }

      .ag-column-drop-wrapper {
        height: auto;
        border: none !important;
      }

      .ag-header-cell {
        border-right: unset !important;
      }
    }
  }
}

.chargeInvoiceDesc {
  border-radius: 10px;
  border: solid #0f1520 1px;
  .ant-popover-inner-content {
    padding: 1px 5px !important;
    background-color: #1f212d;
  }
  textarea {
    border: 0;
  }
}
.serviceDescriptionModal {
  height: fit-content !important;
  .accountingConfirmationModalContainer {
    height: fit-content !important;
    padding: 0 10px;
    .generateTemplateContainer {
      display: flex;
      width: -webkit-fill-available;
      gap: 10px;
      justify-content: space-between;
      .selectKeysFromObjDescription {
        width: -webkit-fill-available;
      }
    }
  }
  .genTemplateWarning {
    div {
      color: white;
    }
    button {
      color: white;
    }
    #no {
      background-color: #ff4d4f;
    }
    #yes {
      background-color: #1890ff;
    }
  }
}

/*LIGHT MODE*/
.newChargeItemModal-light {
  @extend .newChargeItemModal;

  .ant-progress-text {
    color: #12131b !important;
  }
  .ant-modal-content {
    background: $grayColorLight !important;
    .ant-modal-body {
      background: $grayColorLight !important;
      ::-webkit-scrollbar-thumb {
        background: $grayColorLight !important;
      }
      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: $grayColorLight !important;
      }
    }
    .ant-modal-body::-webkit-scrollbar-thumb {
      background: $grayColorLight !important;
    }
    /* Handle on hover */
    .ant-modal-body::-webkit-scrollbar-thumb:hover {
      background: $grayColorLight !important;
    }
    .ant-modal-footer {
      background: $grayColorLight;
    }
  }

  .ag-text-field-input {
    background-color: #fff !important;
    color: #1f212d !important;
  }
  .ant-modal-header {
    background: #1264a3;

    .mondayButtonBlue {
      box-shadow: rgba(17, 17, 26, 0.5) 0px 4px 16px,
        rgba(17, 17, 26, 0.05) 0px 8px 32px;
      @include mondayButton(#1264a3, #0f5c97);
    }

    .charge-main-header .chargesExportButtons {
      .charge-notes-btn-dark {
        box-shadow: rgba(17, 17, 26, 0.5) 0px 4px 16px,
          rgba(17, 17, 26, 0.05) 0px 8px 32px;
        color: #1f212d !important;
      }
    }

    .headerContainer {
      .disabled {
        background-color: #1f212d;
        .mondayButtonDivider {
          background-color: #1f212d;
        }
      }
    }
  }
  .newChargeItemContainer {
    .reqTables {
      border-bottom: 5px solid #fff;
      .header {
        background: #fff;
        color: #1f212d;
      }
    }
    .header-service-display {
      .service-label-div {
        background: #1f212d;

        color: #323338;
      }
    }

    .itemsGroupContainer {
      background: #1264a3;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

      .service-estimation-name {
        background: #1264a3;
      }

      .services-collapse {
        background-color: #fff;
        svg {
          fill: #1264a3;
        }
      }
      .groupItem {
        .serviceContainer {
          .groupItemLabel {
            color: #1f212d;
          }
          .groupItemSubLabel {
            color: #1f212d;
          }
        }
      }
    }
    .ag-overlay-no-rows-center {
      color: #1f212d !important;
    }
    .PLISContainer {
      background: $grayColorLight;

      .estimation-name-charge {
        background: #1264a3;
      }
      .ag-theme-alpine {
        .ag-root-wrapper {
          background: #fff;
          .ag-root {
            .ag-header,
            .ag-header-viewport,
            .ag-header-container,
            .ag-header-row,
            .ag-header-cell,
            .ag-center-cols-clipper,
            .ag-row-even {
              background: #fff !important;
              color: #1f212d;
              .ag-cell {
                background: #fff !important;
                color: #1f212d;
              }
            }
            .ag-row-odd {
              background: #fff !important;
              .ag-cell {
                background: #fff !important;
              }
            }
            .ag-cell {
              color: #1f212d;
            }
          }
          .ag-side-bar {
            background-color: #fff !important;
            .ag-side-button-button {
              color: #1f212d !important;
            }
          }
        }
      }
    }

    .agGridCustomStyle {
      .applyAmount {
        color: #1f212d !important;
        svg {
          g {
            stroke: #1f212d;
          }
          path {
            fill: #1f212d !important;
          }
        }
      }
      .tableHeader {
        .input-percentage-amount {
          background: #fff;
          color: #1f212d;
        }
        .progressChargeHeader {
          > span {
            color: #1f212d;
          }
        }
        .EditDatePickerComponent {
          background: #ececee 0% 0% no-repeat padding-box;
        }
      }
      .eRootWrapper,
      .ag-root-wrapper-body {
        background-color: #fff !important;
        color: #1f212d;
      }
    }
  }
}
