.assistant-message-group {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: fit-content;
  position: relative;
  flex-direction: row;
  gap: 10px;

  .group-avatar {
    background-color: #231f20;
    svg,
    path {
      fill: #fff;
    }
  }

  .avatar-initials {
    font-weight: 600;
  }

  .triangle-wrap {
    position: absolute;
    top: 9px;
    left: 44px;
    z-index: 2;
  }

  .sender-triangle {
    clip-path: polygon(100% 100%, 100% 50%, 0% 50%, 100% 100%);
    width: 7px;
    height: 30px;
    background-color: #f4f5f6;
  }

  .sender-triangle.arrow-error {
    background-color: #fde5e4;
  }

  .sender-triangle.arrow-pending {
    animation: pending 1s linear infinite;
  }

  .messages-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 5px;
    width: calc(100% - 50px);
  }
}

.assistant-message-group.message-sent {
  flex-direction: row-reverse;
  position: relative;

  .triangle-wrap {
    position: absolute;
    top: 9px;
    left: unset;
    right: 43px;
    z-index: 2;
  }

  .sender-triangle {
    clip-path: polygon(0% 100%, 0% 50%, 100% 50%, 0% 100%);
    width: 7px;
    height: 30px;
    background-color: #f4f5f6;
  }

  .sender-triangle.arrow-error {
    background-color: #fde5e4;
  }
}

@keyframes pending {
  50% {
    background-color: #f1f5fd;
  }
}
