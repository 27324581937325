.item-statistics-view {
  display: flex;
  align-content: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.progressScheduleStatisticsView {
  font-size: 48px;
  color: #fff;
  position: relative;
  animation: glitch-animation 1s linear alternate-reverse;
}

@keyframes glitch-animation {
  0% {
    text-shadow: 0 0 red, 0 0 red;
    opacity: 1;
  }
  25% {
    text-shadow: 0 0 blue, 0 0 blue;
    opacity: 0;
  }
  50% {
    text-shadow: 0 0 green, 0 0 green;
    opacity: 1;
  }
  75% {
    text-shadow: 0 0 white, 0 0 white;
    opacity: 0;
  }
  100% {
    text-shadow: 0 0 red, 0 0 red;
    opacity: 1;
  }
}
