.service_categories_picker {
  height: 100%;
  width: 1100px;
  display: flex;
  color: #231f20;
  overflow-y: hidden;
  border-radius: 10px;
  background: #f4f5f6;
  flex-direction: column;
  border: 1px solid #eff0f6;
  box-shadow: 0px 5px 16px 0px rgba(8, 15, 52, 0.06);
  .pick-header {
    width: 100%;
    height: 45px;
    font-size: 16px;
    color: #1f2a44;
    font-weight: 600;
    padding: 10px 20px;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    background-color: #f7f8fa;
    border-radius: 10px 10px 0 0;
  }
  .pick-body {
    flex: 1;
    gap: 10px;
    padding: 20px;
    display: flex;
    overflow-y: hidden;
    flex-direction: column;
    background-color: #fff;
    .pick-title {
      display: flex;
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 10px;
      justify-content: space-between;
    }
    .pick-drags {
      gap: 10px;
      width: 100%;
      height: 100%;
      display: grid;
      overflow-y: auto;
      grid-auto-rows: 50px;
      box-sizing: border-box;
      // justify-content: center;
      grid-template-columns: repeat(auto-fill, 200px);
      .cardProposal {
        gap: 10px;
        width: 200px;
        cursor: grab;
        display: flex;
        padding: 0 10px;
        min-height: 50px;
        position: relative;
        border-radius: 5px;
        height: fit-content;
        align-items: center;
        box-sizing: border-box;
        background-color: #f4f5f6;
        justify-content: space-between;
        &:hover {
          background-color: #f1f5fd;
        }
        .cardProposalTitle {
          width: 90%;
          font-size: 14px;
          font-weight: 600;
        }

        .iconOpen {
          top: 5px;
          right: 5px;
          width: 25px;
          height: 25px;
          z-index: 100;
          display: none;
          cursor: pointer;
          position: absolute;
          border-radius: 50%;
          text-align: center;
          align-items: center;
          justify-content: center;
          transition: all 0.3s ease;
          background-color: #4caf50;
          svg {
            fill: #fff !important;
          }
        }

        &:hover {
          .iconOpen {
            position: absolute;
            display: unset;
          }
        }
      }
    }
  }
}

.edit_filled_icon_holder {
  position: absolute;
  // top: 10px;
  // right: 10px;
  width: 25px;
  height: 25px;
  background-color: red;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 100;
  top: 0px;
  left: 0px;
}
