@import "/src/index.scss";

.CheckboxCardMember {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 50px;
  width: 100%;
  padding: 10px;
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 0;

  .ant-checkbox-wrapper:hover.CheckboxCardMember__checkbox {
    .ant-checkbox:after,
    .ant-checkbox:hover:after {
      visibility: hidden;
    }
  }
  .CheckboxCardMember__checkbox {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: $textColor;
    .ant-checkbox {
      height: 25px;
      width: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $red;
      &:checked {
        border: 0;
      }
      input &:hover {
        border: 0;
      }
      .ant-checkbox-inner {
        height: 17px;
        width: 17px;
        background: #eb4345;
        border: 1px solid #ff7274;
      }
    }
    .ant-checkbox.ant-checkbox-checked {
      background: $green;
      .ant-checkbox-inner {
        background-color: $green;
        border: 1px solid #fff;
        &::after {
          height: 10px;
          width: 5px;
          left: 8px;
        }
      }
    }
  }
}

.CheckboxCardMemberDark {
  background-color: $cardDarkBackgroundColor;
}
