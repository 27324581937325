.rate-cell-renderer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  padding: 0 10px;

  .rate-value {
    font-size: 16px;
  }
}

.rate-cell-renderer-dark {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  padding: 0 10px;
  
  .rate-value {
    font-size: 16px;
    color: white;
  }
}
