.logs-session-filters-modal {
  height: fit-content !important;
  width: fit-content !important;
  max-width: 90vw;
  min-width: 300px;
  .ant-modal-content {
    .ant-modal-body {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .filters-container {
        display: grid;
        grid-template-columns: repeat(3, minmax(250px, 1fr));
        gap: 1rem;
        justify-content: center;
        align-items: center;
      }
    }
    .ant-modal-footer {
      .session-logs-filter-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .logs-filter-right-footer {
          display: flex;
          gap: 20px;
          align-items: center;
          .clear-button:hover {
            color: #ff4d4f;
            font-weight: 600;
            cursor: pointer;
          }
        }
      }
    }
  }
}
