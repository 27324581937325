@import "../../../../../../../../../index.scss";

$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.editActivityModalContainer {
  .ant-modal-content {
    width: 100%;
    .ant-modal-body {
      height: fit-content !important;
    }
  }
  .antCustomStepper {
    margin-bottom: 20px !important;
  }
  .formContainer {
    @include flex(flex-start, flex-start, column);
    max-height: calc(100vh - 200px);
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    .ant-form {
      width: 100%;
    }
  }

  .borderedTextCard {
    padding: 0;
    border: none;
    border-top: 1px solid black;
    // align-items: center;
    border-radius: 0;
    padding-top: 15px;
    align-items: center;
    margin-bottom: 0;
    .cardTitle {
      color: black;
      font-size: 16px;
      font-weight: 600;
    }
    .childrenContainer {
      width: 100%;
    }
  }

  .detailsCard {
    .childrenContainer {
      align-items: center;
      gap: 10px;
    }
  }

  .ant-modal-title {
    .modalTitle {
      display: flex;
      // justify-content: space-between;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      gap: 1rem;

      .title {
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #323338;
      }
    }
  }

  .inspectionButtonContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    height: fit-content;
    width: fit-content;
    flex-wrap: wrap;
    gap: 1.5rem;
    margin-left: 0.5rem;
    margin-bottom: 1rem;
    width: 100%;

    .editInspectionButton:disabled {
      pointer-events: none !important;
    }

    .inspectionButton {
      cursor: pointer;
      color: white;
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
      padding-left: 0.55rem;
      padding-right: 0.55rem;
      border-radius: 5px;
      border: 2px solid;
      transition: background-color 0.2s ease;
      font-weight: 600;
      letter-spacing: 0.4px;
    }
    .enabled {
      background-color: rgb(96, 172, 96);
      border-color: rgb(56, 167, 56);
      &:hover {
        background-color: rgb(54, 167, 54);
      }
    }

    .disabled {
      background-color: rgb(238, 100, 100);
      border-color: rgb(241, 61, 61);
      &:hover {
        background-color: rgb(241, 58, 58);
      }
    }
  }

  .inputContainer {
    width: 100%;
    // @include flex(flex-start, space-around);
    display: flex;
    justify-content: flex-start;
    gap: 10px;
  }
  .labeledInputContainer {
    .labeledInputLabel {
      margin-bottom: 2px;
      @include text(14px, 600, 0.21px, #383554);
    }
  }
  .inputContainer:first-child {
    .labeledInputContainer {
      min-width: 315px !important;
      width: 100%;
    }
  }
  .inputContainer:nth-child(2) {
    .labeledInputContainer {
      min-width: 0px !important;
      width: 100%;
    }
  }
  .ant-modal-body {
    padding-top: 8px;
    padding-bottom: 40px;
    height: 580px;
  }
  .ant-modal-footer {
    border: none;
  }
  .iconContainer {
    width: 25px;
    // margin-right: 15px;
    align-self: center;
  }
  .logs-div {
    display: flex;
    justify-content: center;
    align-items: center;
    .icon {
      background-color: #1f2a44;
      border-radius: 5px;
      width: 35px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      fill: #fff;
    }
  }
}

.editActivityModalContainerDark {
  .ant-modal-header {
    .titleContainer .title {
      color: #fff;
    }
  }
  .ant-modal-body {
    .formContainer {
      .antCustomStepper {
        .ant-steps {
          .ant-steps-item-finish ::before,
          .ant-steps-item-active::before,
          .ant-steps-item::before {
            border-left-color: $cardModalBodyDark;
          }
          .ant-steps-item-wait {
            background-color: $cardModalHeaderDark;
            &::after {
              border-left-color: $cardModalHeaderDark;
            }
            .ant-steps-item-content .ant-steps-item-title {
              color: #acacca;
            }
          }
        }
      }
      .childrenContainer {
        .labeledInputLabel {
          color: #fff;
        }
        .ant-input {
          background-color: $inputModalDark !important;
          color: #fff;
          &::placeholder {
            color: #acacca;
          }
        }
        .ant-input-disabled {
          color: #acacca;
        }
        .ant-select {
          .ant-select-selector {
            background-color: $inputModalDark !important;
            .ant-select-selection-search-input {
              color: #fff;
            }
            .ant-select-selection-item {
              color: #fff;
            }
            .ant-select-selection-placeholder {
              color: #acacca;
            }
          }
          .ant-select-clear {
            background-color: $inputModalDark;
            svg {
              fill: #acacca;
            }
          }
          .ant-select-arrow svg {
            fill: #fff;
          }
        }
        .ant-select-disabled {
          .ant-select-selector {
            .ant-select-selection-search-input {
              color: #acacca !important;
            }
            .ant-select-selection-item {
              color: #acacca !important;
            }
          }
        }
        .ant-picker {
          background-color: $inputModalDark !important;
          .ant-picker-input {
            background-color: $inputModalDark;
            input {
              color: #f2f2f2;
              &::placeholder {
                color: #acacca;
              }
            }
            .ant-picker-suffix svg {
              color: #acacca;
            }
            .ant-picker-clear {
              background-color: $inputModalDark;
              svg {
                fill: #acacca;
              }
            }
          }
        }
        .ant-picker-disabled {
          .ant-picker-input input {
            color: #acacca !important;
          }
        }
        .ant-radio-wrapper span {
          color: #fff !important;
        }
      }
    }
  }
}

@media (max-width: 830px) {
  .editActivityModalContainer {
    .inputContainer:not(:nth-child(2)) {
      display: grid;
      grid-template-columns: 49% 49%;
      gap: 10px;
    }
    .inputContainer:nth-child(5) {
      display: flex;
      flex-direction: column;
      .types-div {
        width: 100%;
        display: grid !important;
        grid-template-columns: auto auto auto;
      }
    }
  }
}

@media (max-width: 680px) {
  .editActivityModalContainer {
    .inputContainer:nth-child(5) {
      .types-div {
        width: 60%;
        display: flex !important;
        flex-direction: column;
      }
    }
  }
}

@media (max-width: 575px) {
  .editActivityModalContainer {
    .antCustomStepper {
      width: 95%;
      .ant-steps {
        gap: 5px;
        .ant-steps-item {
          height: 36px;
        }
        .ant-steps-item:last-child::after {
          display: none;
        }
        .ant-steps-item-active:after,
        .ant-steps-item-finish:after {
          top: -36px;
          left: 100%;
          transform: rotate(0);
        }
      }
      .ant-steps-vertical > .ant-steps-item .ant-steps-item-content {
        min-height: 0px;
      }
    }
  }
}

@media (max-width: 450px) {
  .editActivityModalContainer {
    padding: 5px !important;
    .formContainer {
      width: 100% !important;
      height: 100% !important;
      form.ant-form.ant-form-horizontal {
        width: 100% !important;
      }
    }
  }
  .editActivityModalContainer .inputContainer {
    display: grid;
    grid-template-columns: 100% !important;
  }
  .editActivityModalContainer .inputContainer:nth-child(5) {
    .types-div {
      width: 100%;
    }
  }
  .editActivityModalContainer .antCustomStepper {
    width: 92%;
  }
}
