.theme-step {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .darkModeToggleDivContainer,
  .lightModeToggleDivContainer {
    width: 40px;
    height: 40px;

    .firstSquare,
    .secondSquare,
    .innerCircle {
      background: #1264a3;
    }
    .outerCircle {
      background: white;
    }
  }
}
