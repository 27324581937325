.user-profile-container {
  height: 100%;
  background-color: #f4f5f6;
  display: flex;
  flex-direction: column;
  .user-profile-content {
    height: 100%;
    display: flex;
    gap: 10px;
    padding: 10px;
    overflow: auto;
    transition: 0.2s ease-in;

    //general style for CardContentWrapper
    .CardContentWrapper {
      .CardContentWrapper-header {
        height: 45px;
        min-height: 45px;
      }
    }

    .left-section {
      height: 100%;
      width: 60%;
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    .right-section {
      height: 100%;
      width: 40%;
    }
  }

  .user-profile-footer {
    height: 52px;
    background-color: #ffffff;
    padding: 16px 20px !important;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
    text-wrap: nowrap;
    overflow: hidden;
    transition: 0.2s ease-in;
  }

  &.dark-mode {
    .user-profile-content {
      background: #20212d;
    }
    .user-profile-footer {
      background-color: #12131b;
    }
  }
}

@media (max-width: 560px) {
  .user-profile-container {
    .user-profile-content {
      .right-section {
        height: 150% !important;
      }
    }
  }
}

@media (max-width: 1024px) {
  .user-profile-container {
    .user-profile-content {
      flex-direction: column;
      .left-section {
        width: 100%;
        height: fit-content;

        .account-info-container {
          height: fit-content;
        }
        .dashboard-prefs-container {
          min-height: 150px;
          height: fit-content;
          max-height: 500px;
          overflow: auto;
        }
      }
      .right-section {
        width: 100%;
        height: 70%;
      }
    }
  }
}
