.drag-drop-icon-schedule {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1264a3;
  width: 32px;
  height: 32px;
  border-radius: 5px;
}

.est-progress-status-schedule {
  animation: 1s est-progress-status-schedule;
}

.non-drag-service {
  // animate each child of serviceData
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
  animation-delay: 0.1s;
  animation-name: serviceData;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-play-state: running;
  animation-delay: 0.1s;
}

.dragable-services-label {
  overflow: hidden;
  white-space: nowrap;
  animation: typing 2s steps(40, end);
}

@keyframes est-progress-status-schedule {
  0% {
    transform: translateX(-10px);
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes serviceData {
  0% {
    transform: translateX(-10px);
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}
