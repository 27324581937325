$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.events-notif-modal .ant-drawer {
  border-top-left-radius: 10px;
}

.events-notif-modal .ant-drawer-content {
  height: 100vh !important;
  display: flex;
  flex-direction: column;
  .ant-drawer-wrapper-body {
    height: 100vh; //!important;
    .ant-drawer-header {
      background: #f7f8fa 0% 0% no-repeat padding-box;
      // border-top-left-radius: 10px;
      height: 45px;
      padding: 10px 20px;
      border-bottom: unset;
      .ant-drawer-header-title .ant-drawer-close {
        padding: 5px 0 0 0;
        margin-inline-end: 20px;
      }
    }
    .ant-drawer-body {
      // height: 100vh !important;
      overflow-x: hidden;
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }
}

.preview-events-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .user-info-display {
    display: flex;
    gap: 10px;
    .info-style {
      // height: 20px;
      svg {
        font-size: 20px;
        fill: #1264a3;
      }
    }
  }
  .action-btns {
    display: flex;
    gap: 10px;
    .button {
      height: 32px;
      width: 32px;
      display: flex;
      cursor: pointer;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      color: white;
      svg {
        fill: white;
      }
    }
    .user-icon-style {
      background-color: #1264a3;
      &:hover {
        background-color: #0f5c97;
      }
    }
    .add-icon-style {
      background-color: #71cf48;
      &:hover {
        background-color: #68c142;
      }
    }
  }
}

.calendar-menu {
  .calendar-item:hover {
    background-color: #1f2a44;
    color: white;
  }
  border-radius: 5px;
}

.events-notif-modal-dark {
  .ant-drawer-content {
    background-color: $cardModalBodyDark;
    .ant-drawer-header {
      background-color: $cardModalHeaderDark;
      .ant-drawer-header-title {
        .ant-drawer-title {
          .fullTitleContainer {
            .title {
              color: #fff;
            }
          }
        }
        .ant-drawer-close {
          svg {
            fill: #fff;
          }
        }
      }
    }
    .ant-drawer-body {
      background-color: $cardModalBodyDark !important;
      hr {
        color: #acacca !important;
      }
      .preview-events-header .user-info-display h3 {
        color: #fff !important;
      }
      .filter_container .filter_title {
        color: #fff !important;
      }
      .list-event-item {
        background-color: $inputModalDark !important;
        .event-summary {
          color: #fff !important;
        }
      }
      .listEventHeader {
        background-color: unset !important;
        .event-summary {
          color: #fff !important;
        }
      }
    }
  }
}
