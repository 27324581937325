.canvas-container {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  gap: 10px;
  .canvas-toolbox {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
  }
}
