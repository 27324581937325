// $background-color: red;
$background-color: #1f1f1f;
$text-color: #f5f5f5;
$header-background-color: #2a2a2a;
$header-text-color: #ffffff;
$row-hover-background-color: #333333;
$border-color: #444444;
$pagination-background-color: #2a2a2a;
$pagination-active-color: #1890ff;

.ChainRentalsModal_dark {
  top: 0 !important;
  padding: 0 !important;
  width: 100% !important;
  height: 100dvh !important;
  margin: 0;
  max-width: 100%;
  overflow: hidden;

  &::before {
    display: none !important;
  }
  .ant-modal-content {
    border-radius: 0;
    width: 100%;
    height: 100dvh;
    .ant-modal-header {
      background-color: #12131b;
      border-bottom: none;
      display: flex;
      justify-content: flex-start;
      height: 55px;
      align-items: center;
      .ant-modal-title {
        // font-weight: 500;
        // // font-size: 1.5rem;
        color: #f8f8fa;
        // font-family: "Helvetica";
      }
    }
    .ant-modal-body {
      height: calc(100vh - 50px);
      background-color: #1f212d;
      width: 100%;
      padding: 10px 10px 0 10px !important;
      padding: 0;
      overflow-y: hidden !important;

      .chainRentalsTable {
        .ant-table {
          background-color: $background-color;
          color: $text-color;
          max-height: 700px !important; // Adjust the height as needed
          overflow-y: auto; // Set overflow to auto
          .ant-table-thead > tr > th {
            background-color: $header-background-color;
            color: $header-text-color;
            border-color: $border-color;
          }

          .ant-table-tbody > tr > td {
            border-color: $border-color;
          }

          .ant-table-tbody > tr:hover > td {
            background-color: $row-hover-background-color;
          }

          .ant-pagination {
            color: #fff !important;
            .ant-pagination-item-link {
              color: #fff !important;
            }
            .ant-pagination-item {
              background-color: $pagination-background-color;
              border-color: $border-color;

              a {
                color: $text-color;
              }

              &:hover {
                border-color: $pagination-active-color;
              }

              &.ant-pagination-item-active {
                border-color: $pagination-active-color;

                a {
                  color: $pagination-active-color;
                }
              }
            }

            .ant-pagination-prev,
            .ant-pagination-next {
              a {
                color: $text-color;
              }

              &:hover {
                a {
                  color: $pagination-active-color;
                }
              }
            }
          }

          .ant-checkbox-wrapper {
            .ant-checkbox {
              .ant-checkbox-inner {
                background-color: #1264a3;
                border-color: #1264a3;
                width: 32px;
                height: 32px;
              }

              &.ant-checkbox-checked .ant-checkbox-inner {
                background-color: #1264a3;
                border-color: #1264a3;
                &:after {
                  border-color: #fff;
                  height: 20px;
                }
              }
            }

            span {
              color: $text-color;
            }
          }

          .ant-spin {
            .ant-spin-dot {
              i {
                background-color: $pagination-active-color;
              }
            }
          }

          .ant-tooltip-inner {
            background-color: $header-background-color;
            color: $text-color;
          }

          .ant-tooltip-arrow {
            border-top-color: $header-background-color;
          }
        }

        .ant-table-row-selected {
          .ant-table-cell {
            background-color: $row-hover-background-color;
          }
        }

        .ant-table-column-sorter,
        .ant-table-filter-dropdown {
          .anticon {
            color: $text-color;
          }

          &:hover {
            .anticon {
              color: $pagination-active-color;
            }
          }
        }

        .custom-table-component {
          background-color: $background-color;
          color: $text-color;

          .custom-header {
            background-color: $header-background-color;
            color: $header-text-color;
          }

          .custom-cell {
            border-color: $border-color;
          }

          .custom-cell-hover:hover {
            background-color: $row-hover-background-color;
          }
        }
        // .ant-table-thead {
        //   .ant-table-cell {
        //     background-color: #1264a3 !important;
        //     color: #fff !important;
        //   }
        // }
        // tr {
        //   color: #fff !important;
        //   background-color: #1f2a44 !important;
        //   border: 0px !important;
        //   &:hover {
        //     background-color: #1f2a44 !important;
        //   }
        // }
      }
      .chainRentalsModalFooter {
        // top: 0;
        // position: fixed;
        // bottom: 0;
        display: flex;
        justify-content: flex-end;
        // right: 40px;
        width: 100%;
        gap: 20px;
        margin-top: 10px;
        margin-bottom: 10px;
        padding-right: 20px;
      }
    }
  }
}
