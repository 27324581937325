.footerButtons {
  gap: 10px;
  justify-content: space-between;
  padding: 10px;
}

.information {
  flex-direction: column;
  gap: 10px;
}

.qrCode {
  margin-top: 20px;
  align-self: center;
}

.otpInput :global(.ant-otp-input) {
  width: 50px;
  height: 50px;
  padding-inline: 15px !important;
}
