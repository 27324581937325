@import "../../../index.scss";
$mainDarkBackgroundColor: #20212d;
$cardDarkHeaderColor: #2a2b3a;
$cardDarkBackgroundColor: #12131b;

.tableOptionBody {
  padding-left: 10px;
  width: 100%;
}

.pdfExportClassName {
  .ant-modal div .ant-modal-content {
    .ant-modal-header {
      background: #f5f5f5 !important;
      .ant-modal-title {
        color: #000;
      }
    }
    .ant-modal-close .ant-modal-close-x span svg {
      color: #000;
    }
    .ant-modal-body {
      background: #fff;
      div h3 {
        color: #000;
      }
    }
    .ant-modal-footer {
      background: #fff;
    }
  }
}

.pdfExportClassNameDark {
  .ant-modal div .ant-modal-content {
    .ant-modal-header {
      background: $cardDarkHeaderColor !important;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-close .ant-modal-close-x span svg {
      color: #f5f5f5;
    }
    .ant-modal-body {
      background: $mainDarkBackgroundColor;
      div h3 {
        color: #fff;
      }
    }
    .ant-modal-footer {
      background: $mainDarkBackgroundColor;
    }
  }
}
