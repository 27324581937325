.defectInfoModal {
  .ant-modal-close {
    height: 45px;
    width: 45px;
    .ant-modal-close-x {
      height: 45px;
      width: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .ant-modal-header {
    height: 45px;
    display: flex;
    align-items: center;
    background-color: #f8f8f8;
    font-weight: 600;

    .ant-modal-title {
      color: #323338;
      font-weight: 600;
    }
  }

  .defectInfoModalContainer {
    height: fit-content;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 1rem;

    .infoLine {
      width: 80%;
      letter-spacing: 0.28px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .defectPriority,
      .defectStatus {
        padding: 0.3rem 0.45rem;
        width: 130px;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-weight: 500;
      }
      .New {
        background-color: #86bbd8;
      }

      .In,
      .Progress,
      .InProgress {
        background-color: #ad37d5;
      }

      .Corrected {
        background-color: #ff9c46;
      }

      .High {
        background-color: #f34213;
      }

      .Low {
        background-color: #b3d99b;
      }
    }

    .infoImages {
      width: 80%;
      letter-spacing: 0.28px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      .imagesContainer {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
      }
    }
  }
}

.defectInfoModal.defect-modal-dark {
  .ant-modal-content {
    background-color: #1f212d;
    color: #ffffff;

    .ant-modal-header {
      background-color: #12131b;
      .ant-modal-title {
        color: #ffffff;
      }
    }

    .ant-modal-close {
      .ant-modal-close-x {
        svg {
          fill: #ffffff;
        }
      }
    }
  }
}
