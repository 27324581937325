.rowData-grid-modal.ant-modal {
  &.rowData-grid-modal-dark {
    .ant-modal-content,
    .ant-modal-header {
      background-color: #12131b;

      .ant-modal-title,
      .ant-modal-close-x {
        color: #fff;
        fill: #fff;
      }
    }
  }

  .ant-modal-footer {
    padding: 20px;
  }

  .HeaderContentWrapper {
    .HeaderContentWrapper-header {
      box-shadow: none;
    }
  }
}
