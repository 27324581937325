@import "/src/index.scss";

.user-list-container {
  .card-template-body {
    .usersSearch {
      height: 32px;
      padding: 0 20px;
    }
    .user-list-table {
      display: flex;
      flex-direction: column;
      width: 100%;
      .user-list-table-header {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #bbbfc7;
        padding: 0 20px 10px 20px;
        font-size: 16px;
        font-weight: 600;
      }
      .user-list-table-body {
        height: 345px;
        overflow-y: auto;
        border-radius: 0 0 10px 10px;
        display: flex;
        flex-direction: column;
        .usernameRenderer {
          height: 40px;
          padding: 10px 10px 10px 20px;
          border-bottom: 1px solid #dee2eb;
          &:nth-child(odd) {
            background-color: #fcfcfc;
          }
          &:nth-child(even) {
            background-color: #fff;
          }
          &:last-child {
            border-bottom: none;
          }
          &:hover {
            background-color: #f1f5fd;
            cursor: pointer;
            .username {
              color: #1264a3;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}

.user-list-container-dark {
  background-color: $cardDarkBackgroundColor;
  .usersSearch {
    .labeledInputContainer.dark-mode .ant-input-group,
    .labeledInputContainer.dark-mode .ant-input-group-addon,
    .labeledInputContainer.dark-mode .ant-input-affix-wrapper,
    .labeledInputContainer.dark-mode .ant-select-selector,
    .labeledInputContainer.dark-mode .ant-picker,
    .labeledInputContainer.dark-mode .ant-input {
      background-color: $cardDarkHeaderColor !important;
    }
  }
  .user-list-title {
    color: #fff !important;
    background-color: $cardDarkHeaderColor;
  }
  .user-list-table-header {
    color: #fff !important;
  }
  .usernameRenderer {
    background-color: $cardDarkBackgroundColor !important;
    .username {
      color: #fff !important;
    }
    &:hover {
      background-color: lighten($mainDarkBackgroundColor, 10%) !important;
      .username {
        color: #1264a3 !important;
      }
    }
  }
}
