@import "../../../../../index.scss";

.reportCategoryCard {
  @include cardLayout();
  overflow: hidden;
  display: grid;
  color: #323338;
  font-size: 16px;
  min-width: 415px;
  height: inherit;
  transition: 0.2s ease-in;

  .normalModeContainer {
    @include flex(stretch, flex-start);
    height: inherit;
    width: 100%;
    grid-area: 1 / 1 / 2 / 2;
  }

  .hoverModeWrapper {
    position: absolute;
    height: inherit;
    width: inherit;
  }

  .hidden {
    display: none;
  }

  .hoverModeContainer {
    @include flex(center, center, column);
    position: relative;
    width: inherit;
    height: inherit;
    background-color: #f7f8fa;
    animation: fadeIn 1000ms both;
    grid-area: 1 / 1 / 2 / 2;
    transition: 0.2s ease-in;
    z-index: 2;
  }

  .reportsCount {
    min-width: 55px;
    @include flex(center, center);
    @include text(30px, 700, 0, #fff);
  }

  .categoryLabel {
    font-weight: 700;
    padding: 0px 5px;
  }

  .previewCategoryInfo {
    @include flex(center, center);
  }

  .categoryIcon {
    @include flex(center, center);
    height: fit-content;
    width: fit-content;
    margin-right: 10px;
    svg {
      height: 17px;
      width: 17px;
      path {
        fill: #323338;
      }
    }
  }

  .categoryContent {
    flex: 1;

    .contentHeader {
      @include flex(center, space-between);
      background-color: #f7f8fa;
      padding: 5px 20px;
      height: 45px;
      transition: 0.2s ease-in;
    }

    .leftHeaderSection {
      @include flex(center, center);
    }

    .rightHeaderSection {
      @include flex(center, flex-start);
    }

    .categoryTag {
      @include text(12px, 400, 0, #fff);
      @include flex(center, center);
      border-radius: 5px;
      padding: 0 5px;
      opacity: 0.6;
      background-color: #1f2a44;
      height: 25px;
    }

    .favoriteTag {
      margin-right: 10px;
    }

    .categoryInfo {
      padding: 10px 20px;
      transition: 0.2s ease-in;
      p {
        margin: 0;
      }
    }
  }
  .openReportsBtn {
    @include mondayButton(#1264a3, #0f5c97);
  }

  .deleteBtn {
    @include flex(center, center);

    position: absolute;
    bottom: 0;
    right: 0;
    width: 32px;
    height: 32px;
    background-color: #fe4c4a;
    border-top-left-radius: 5px;
    cursor: pointer;

    svg {
      fill: #fff;
    }
  }
}
