.violationPieChartEdit {
  padding: 20px;
  .ant-form {
    display: flex;
    flex-direction: column;
    align-items: center;

    .amountFormDiv {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
    }
  }
}

.violationPieChartEditDark {
  .feePaymentIcon {
    fill: #fff;
  }
  .labeledInputContainer .labeledInputLabel {
    color: #fff;
  }
}
