@import "./../../../../index.scss";

.settingsToDoModal {
  .ant-modal-content {
    .ant-modal-close {
      width: 45px;
      height: 45px;
    }
    .ant-modal-header {
      display: flex;
      align-items: center;
      height: 45px;
      background-color: #f8f8fa;

      color: #323338;
      font-size: 16px;
      font-weight: 600;
      width: 100%;
    }
  }
  .ant-modal-body {
    .settings {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .ant-select-selector {
        background: #f5f5f7 0% 0% no-repeat padding-box;
        border-radius: 5px;
        font-weight: 400;
        border-style: none;
        transition: 0s;
        opacity: 1;
        min-height: 32px;
        width: 100%;
        height: fit-content;
      }

      .label-input {
        display: flex;
        flex-direction: column;
        gap: 2px;

        .fieldLabel {
          margin-left: 10px;
          font-size: 16px;
          font-weight: 600;
        }
      }

      .default-topicContainer {
        border: "1px solid red";

        .modal__default__props {
          display: flex;
          align-items: center;
          padding: 0 10px;
          opacity: 0.6;
          font-style: italic;
          user-select: none;
          cursor: default;
          border-radius: 5px;
          background-color: #fffbe6;
          border-left: 3px solid #d8c568;
          margin: 18px 20px 18px 0;
          h3 {
            display: flex;
            align-items: center;
            font-size: 13px;
            margin-bottom: 0;
            margin-right: 3px;
          }
          p {
            text-align: start;
            color: #a29a37;
            font-size: 13px;
            letter-spacing: 0.5px;
            display: flex;
            align-items: center;
            margin-bottom: 0;
            b {
              font-weight: bold;
              font-size: 1.1rem;
              letter-spacing: 0.5px;
            }
          }
        }
      }
    }
  }
}

.settingsToDoModal-dark {
  .ant-modal-content {
    .ant-modal-close-x {
      svg {
        fill: #fff;
      }
    }
    .ant-modal-header {
      background-color: $cardDarkBackgroundColor;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      background-color: $cardDarkHeaderColor;
      .settings {
        .label-input {
          .fieldLabel {
            color: #fff;
          }

          .ant-select {
            .ant-select-selector {
              border: none;
              background-color: $cardDarkBackgroundColor !important;
              color: #f2f2f2;

              .ant-select-selection-search-input {
                background-color: $cardDarkBackgroundColor !important;
                color: #f2f2f2;
              }
              .ant-select-selection-placeholder {
                color: #acacca;
                z-index: 2;
              }
              .ant-select-selection-overflow {
                .ant-select-selection-item {
                  background-color: $cardDarkBackgroundColor !important;
                  .anticon svg {
                    fill: #f2f2f2;
                  }
                }
              }
            }

            .ant-select-clear {
              background-color: $cardDarkBackgroundColor !important;
            }
            .ant-select-arrow svg {
              fill: #f2f2f2;
            }
          }
        }
      }
    }
    .ant-modal-footer {
      background-color: $cardDarkHeaderColor;
    }
    // .ant-select-dropdown {
    //   background-color: steelblue !important;
    //   .ant-select-item-option {
    //     color: #fff;
    //   }
    //   .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    //     background-color: steelblue !important;
    //   }
    //   .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    //     background-color: steelblue !important;
    //   }
    // }
  }
}
.settingsToDoModal-selectDark {
  background-color: $cardDarkBackgroundColor;

  .ant-select-item {
    .ant-select-item-option-content {
      color: #fff;
      //   &:hover {
      //     background-color: $cardDarkHeaderColor !important;
      //   }
    }

    &.ant-select-item-option-selected {
      background-color: transparent !important;
      color: #fff;
    }

    &.ant-select-item-option-active {
      background-color: $cardDarkBackgroundColor;
    }
  }

  .rc-virtual-list-scrollbar {
    background-color: #34343b;

    .rc-virtual-list-scrollbar-thumb {
      background-color: $cardDarkHeaderColor;
    }
  }
}
