@import "/src/index.scss";

.uploadEmployeesModal {
  width: calc(100vw - 20px) !important;
  height: fit-content !important;
  max-width: 700px;

  .ant-modal-content {
    width: 100%;
    max-width: 700px;
    height: fit-content;
    transition: 0.5s ease;
    .ant-modal-close {
      height: 45px;
      width: 45px;
      .ant-modal-close-x {
        height: 45px;
        width: 45px;
        display: flex;
        align-items: center;
      }
    }
    .ant-modal-header {
      height: 45px;
      display: flex;
      align-items: center;
      background-color: #f8f8fa;
      .ant-modal-title {
        font-size: 16px;
        font-weight: 600;
        width: 100%;
      }
    }
    .ant-modal-body {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      transition: 0.5s ease;
      .uploadEmployeeInfo {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        width: 100%;
        height: 20px;
        margin-bottom: 48px;
        .uploadEmployeeText {
          width: 600;
          height: 20px;
          font-size: 14px;
          font-weight: 400;
        }
      }
      .uploadEmployeeDiv {
        // width: 83.5%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }
    }
    .ant-modal-footer {
      .uploadEmployeesFooterDiv {
        display: flex;
        justify-content: space-between;
        .mondayButtonStyle {
          width: 150px;
        }
        .uploadEmployeeLogs {
          width: 32px;
        }
        .uploadEmployeesFooter {
          display: flex;
          gap: 10px;
        }
      }
    }
  }
}

.uploadEmployeesModalRoot {
  .ant-modal-wrap::before {
    display: none;
  }
  .uploadEmployeesModalTable {
    height: calc(100vh - 0px) !important;
    max-height: 100vh;
    width: 100vw !important;
    max-width: 100vw;
    transition: 0.5s ease;
    .ant-modal-content {
      height: calc(100vh - 0px) !important;
      max-height: 100vh;
      width: 100vw !important;
      max-width: 100vw;
      border-radius: 0px;
      transition: 0.5s ease;
      background-color: #f8f8fa;
      .ant-modal-close-x svg {
        fill: #fff;
      }
      .ant-modal-header {
        background-color: #222a44;
        border-radius: 0px;
        .ant-modal-title {
          color: #fff;
        }
      }
      .ant-modal-body {
        height: calc(100% - 117px);
        width: calc(100% - 20px);
        background-color: #fff;
        border-radius: 10px;
        padding: 20px 0px;
        margin: 10px;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
        .antCustomStepper {
          padding: 0px 25px;
        }
      }
      .ant-modal-footer {
        background-color: #fff;
      }
    }
  }
  .uploadEmployeesModalDark {
    .ant-modal-content {
      background-color: $mainDarkBackgroundColor;
      .ant-modal-header {
        background-color: $cardDarkBackgroundColor;
      }
      .ant-modal-body {
        background-color: $cardDarkHeaderColor;
        .ant-steps {
          .ant-steps-item-finish ::before,
          .ant-steps-item-active::before,
          .ant-steps-item::before {
            border-left-color: $cardDarkHeaderColor;
          }
          .ant-steps-item-wait {
            background-color: $cardDarkHeaderColor;
            &::after {
              border-left-color: $cardDarkHeaderColor;
            }
            .ant-steps-item-content .ant-steps-item-title {
              color: #acacca;
            }
          }
        }
      }
      .ant-modal-footer {
        background-color: $cardDarkHeaderColor;
      }
    }
  }
}

.uploadEmployeesModalDark {
  .ant-modal-content {
    background-color: $mainDarkBackgroundColor;
    .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardDarkBackgroundColor;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      .uploadEmployeeText {
        color: #fff;
      }
      .ant-steps {
        .ant-steps-item-finish ::before,
        .ant-steps-item-active::before,
        .ant-steps-item::before {
          border-left-color: $mainDarkBackgroundColor;
        }
        .ant-steps-item-wait {
          background-color: $cardDarkBackgroundColor;
          &::after {
            border-left-color: $cardDarkBackgroundColor;
          }
          .ant-steps-item-content .ant-steps-item-title {
            color: #acacca;
          }
        }
      }
    }
  }
}
