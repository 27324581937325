.eventsCalculatorModalDark {
  .ant-modal-content {
    .ant-modal-header {
      background-color: #20212d;
      border-bottom: 1px solid #20212d;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-close-x {
      justify-content: center;
      align-items: center;
      display: flex;
      height: 45px;
      width: 45px;
      padding: 0;
      svg {
        fill: white;
      }
    }
    .ant-modal-body {
      background-color: #20212d;
      height: 700px;
      overflow-y: scroll;
    }
    .ant-modal-footer {
      background-color: #222a44;
      border-top: 1px solid #20212d;
      .ant-btn {
        color: #fff;
        border-color: #fff;
        &:hover {
          color: #fff;
          border-color: #fff;
        }
      }
    }
  }
}
