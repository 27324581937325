@use "src/index.scss" as index;

.radius-tolerance-modal {
  height: fit-content !important;
  width: fit-content !important;
  max-height: calc(100vh - 20px);
  max-width: calc(100vw - 20px);
  .ant-modal-content {
    min-height: 200px;
    min-width: 1200px;
    max-height: calc(100vh - 20px);
    max-width: calc(100vw - 20px);
    .ant-modal-close {
      height: 45px;
      width: 45px;
      .ant-modal-close-x {
        height: 45px;
        width: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .ant-modal-header {
      height: 45px;
      display: flex;
      align-items: center;
      background-color: #f8f8fa;
    }
    .ant-modal-body {
      min-height: calc(180px - 97px);
      min-width: 300px;
      .ant-form {
        display: flex;
        align-items: center;
        flex-direction: column;
        .slider {
          height: 20px;
          width: 100%;
          .ant-slider {
            width: 100%;
            margin: 0px;
          }
        }
        .marks {
          height: 34px;
          width: 100%;
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          .radius-range {
            font-size: 16px;
            margin-top: 5px;
          }
        }
        .form-footer {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 20px;
          span {
            font-size: 16;
          }
          .job-found {
            color: index.$blue;
            font-weight: 600;
            cursor: pointer;
          }
        }
        .ant-form-item {
          margin-bottom: 0px;
        }
        .labeledInputContainer {
          min-width: 90px !important;
        }
      }
      .jobsites-found-grid {
        height: 500px;
        margin-top: 20px;
      }
    }
    .ant-modal-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      flex-wrap: nowrap;
      width: 100%;
      .mondayButtonStyle {
        width: 150px;
      }
    }
  }
}

.radius-tolerance-modal.radius-tolerance-modal-dark {
  .ant-modal-content {
    background-color: index.$mainDarkBackgroundColor;
    .ant-modal-close .ant-modal-close-x svg {
      fill: #fff !important;
    }
    .ant-modal-header {
      background-color: index.$cardDarkBackgroundColor;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      .ant-form {
        .slider {
          .ant-slider {
            .ant-slider-rail {
              background-color: index.$cardDarkHeaderColor;
            }
            .ant-slider-track {
              background-color: index.$blue;
            }
          }
        }
        .marks {
          color: #fff;
          .radius-range {
            color: #fff;
          }
        }
        .form-footer {
          span:not(.job-found) {
            color: #fff;
          }
        }
      }
    }
  }
}
