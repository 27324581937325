$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.teamShiftModalWrap {
  overflow: hidden;
}

.teamShiftModal {
  height: fit-content;
  width: fit-content !important;

  .ant-modal-content {
    height: fit-content;
    width: fit-content;
    // width: 30dvw;
    // max-height: 80dvh;

    .ant-modal-header {
      width: 100%;
      font-weight: 600;
      border-radius: 10px 10px 0 0;
      background-color: #f8f8f8;
      padding: 10px 24px;
      margin-bottom: 12px;

      .ant-modal-title {
        color: #323338;
        font-weight: 600;

        .titleInfoSpan {
          color: #fe4c4a;
        }
      }
    }

    .ant-modal-body {
      height: 55dvh;
      width: 60dvw;

      .teamsGridContainer {
        height: calc(100% - 30px);
        width: 100%;
      }
    }

    .ant-modal-footer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      flex-direction: row;
      flex-wrap: nowrap;
      .mondayButtonStyle {
        width: 150px;
      }
    }
  }
}

.teamShiftModalDark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      .ant-input-affix-wrapper {
        background-color: $inputModalDark !important;
        .ant-input-prefix svg {
          fill: #fff;
        }
        .ant-input {
          background-color: $inputModalDark !important;
          color: #fff;
          &::placeholder {
            color: #acacca;
          }
        }
      }
      .teamsGridContainer {
        .ag-root-wrapper {
          background-color: $cardModalBodyDark;
          .ag-header {
            background-color: $cardModalBodyDark;
            .ag-header-row {
              border-top: none;
            }
            .ag-header-cell-resize::after {
              width: 1px !important;
              background-color: #4a4a4a;
            }
          }
          .ag-paging-panel {
            background-color: $cardModalBodyDark;
          }
        }
      }
    }
  }
}
