@import "../../../../../../index.scss";

// .documentationListCard {
//   display: flex;
//   flex-direction: column;
//   // height: 800px;
//   height: 100%;
//   width: fit-content;
//   border-radius: 10px;
//   overflow: hidden !important;
//   padding-bottom: 25px;
//   overflow-y: auto;
//   flex-grow: 1;
//   margin-right: 0 !important;
//   padding: 0;
//   .documentationListCardHeader {
//     height: 50px !important;
//   }

//   .documentationListContainer {
//     // display: grid !important;
//     // grid-template-columns: 49.5% 49.5%;
//     // grid-auto-rows: min-content;
//     // grid-gap: 10px 14px;
//     // overflow-y: auto;
//     // overflow-x: hidden;
//     // height: 100% !important;
//     flex: 1;
//     padding: 20px;
//   }

//   .formCardTitle {
//     @include flex(center, center);
//     @include text(16px, 600, 0, #323338);
//     width: stretch;
//     height: 45px !important;
//     background-color: #f7f8fa;
//     // margin: -20px -20px 25px;
//     padding: 10px 20px;
//     margin-bottom: unset !important;
//   }
// }

@media only screen and (max-width: 1100px) {
  .documentationViewContainer {
    // .documentationListCardHeader {
    //   display: flex !important;
    //   justify-content: space-between !important;
    // }
    .documentationListCard {
      width: 100%;
    }
    .childrenContainer.documentationListContainer {
      display: block;
      .documentationViewCard {
        margin-bottom: 15px;
      }
    }
  }
}
@media only screen and (max-width: 450px) {
  .documentationViewContainer {
    .documentationListCardHeader {
      justify-content: flex-end;
    }
  }
}

.documentationListCard {
  height: 100%;
  .card-template-title {
    overflow-y: hidden;
    padding: 0 20px;
    display: flex;
    align-items: center;
  }
  .card-template-body {
    overflow-y: auto;
    flex: 1;
    // height: 100%;
    flex: 1;
    padding: 20px !important;
  }
}
