$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.rental-template-desc-modal-wrapper {
  .ant-modal-body {
    // padding: 0;
    height: 100%;
    .rental-template-desc-body__item {
      background-color: #fff;
      border: none;
      iframe {
        border: 1px solid rgb(204, 204, 204);
      }
    }
    .ant-modal-close-x svg {
      fill: #fff;
    }

    .rental-template-desc-modal {
      .header-pick-template {
        .cardContainer {
          width: 100%;
          height: 200px;
          height: auto;
          background-color: white;
          box-shadow: 0px 1px 4px #00000029;
          border-radius: 10px;
          display: flex;
          flex-direction: column;
          .cardTitleCont {
            height: 45px;
            width: 100%;
            background-color: #f8f8fa;
            border-radius: 10px 10px 0 0;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 0px 20px 0px 20px;
            .cardTitle {
              text-align: left;
              font: normal normal bold 16px/39px Open Sans;
              letter-spacing: 0px;
              color: #323338;
              opacity: 1;
            }
          }

          .cardbody {
            padding: 20px;
            display: flex;
            flex-wrap: wrap;
            .cardBodyRadio {
              display: grid;
              grid-template-columns: repeat(4, 1fr);
            }
            .cardBodyContainer {
              display: flex;
              flex-direction: column;
              height: 100%;
              width: 100%;
              gap: 20px;
              .cardContainerTo {
                display: flex;
                gap: 15px;
                .ant-form-item {
                  width: 100%;
                  margin-bottom: 10px;
                }
                .ant-form-item-label {
                  padding-top: 3px;
                  text-align: left;
                  font: normal normal 600 16px/7px Open Sans;
                  letter-spacing: 0px;
                  color: #323338;
                  opacity: 1;
                }
                .ant-select:not(.ant-select-customize-input)
                  .ant-select-selector {
                  height: 35px;
                }
                .ant-select-selection-item-remove::after {
                  display: flex;
                  //   content: url("../../../../../SidebarPages//DynamicView/src/deleteIconWhite.svg");
                  width: 32px;
                  height: 32px;
                  padding-top: 8px;
                  margin-right: -4px;
                  align-items: center;
                  justify-content: center;
                  border-color: white;
                  background: #fe4c4a 0% 0% no-repeat padding-box;
                  border-radius: 5px;
                }
                .ant-select-multiple
                  .ant-select-selection-item-remove
                  > .anticon {
                  display: none;
                }
                .ant-select-selection-overflow-item {
                  top: 0px;
                  position: relative;
                  flex: none;
                  align-self: flex-start;
                  min-width: 100%;
                }
                .ant-select:not(.ant-select-customize-input)
                  .ant-select-selector {
                  height: 32px;
                  border: 1px solid #707070;
                  border-radius: 5px;
                  opacity: 1;
                }
                .ant-select-focused:not(.ant-select-disabled).ant-select:not(
                    .ant-select-customize-input
                  )
                  .ant-select-selector {
                  box-shadow: none;
                }
                .ant-select-multiple .ant-select-selection-item {
                  justify-content: space-between;
                  height: 32px;
                  background: #f5f5f7 0% 0% no-repeat padding-box;
                  border-radius: 5px 0px 0px 5px;
                  align-items: center;
                }
                .ant-select-selection-overflow {
                  position: relative;
                  display: flex;
                  flex-direction: column-reverse;
                  align-items: flex-start;
                  top: 0px;
                  flex-wrap: nowrap;
                  max-width: 100%;
                  gap: 5px;
                }
              }
              .cardContainerSubject {
                display: flex;
                width: 100%;
                gap: 20px;
                .ant-form-item-label {
                  padding-top: 3px;
                  text-align: left;
                  font: normal normal 600 16px/7px Open Sans;
                  letter-spacing: 0px;
                  color: #323338;
                  opacity: 1;
                }
                .subjectItem {
                  width: 100%;
                }
                .ant-input:focus,
                .ant-input-focused {
                  box-shadow: none;
                }
                .ant-select-selection-overflow {
                  display: none !important;
                }
                .ant-input {
                  height: 32px;
                  border: 1px solid #707070;
                  border-radius: 5px;
                }
                .ant-select:not(.ant-select-customize-input)
                  .ant-select-selector {
                  width: 180px;
                  height: 32px;
                  border: 1px solid #707070;
                  border-radius: 5px;
                }
                .ant-select-focused:not(.ant-select-disabled).ant-select:not(
                    .ant-select-customize-input
                  )
                  .ant-select-selector {
                  box-shadow: none;
                }
              }
            }
            .uploadDivBody {
              width: 100%;
              display: grid;
              grid-template-columns: 50% 50%;
              // display: flex;
              // flex-direction: column;
              height: fit-content;
              width: 100%;
              gap: 10px;
              .uploadDivContainer {
                display: flex;
                width: 100%;
                height: fit-content;
                justify-content: space-between;
                .documentsList {
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  align-items: center;
                }
                .showDocuments {
                  display: flex;
                  flex-direction: row;
                  gap: 20px;
                  flex-wrap: wrap;
                  align-items: center;
                  justify-content: center;
                }

                .uploadContainer {
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-start;
                  width: 250px;
                  .ant-form-item-label {
                    padding-top: 3px;
                    text-align: left;
                    font: normal normal 600 16px/7px Open Sans;
                    letter-spacing: 0px;
                    color: #323338;
                    opacity: 1;
                  }
                  .subjectItem {
                    width: 100%;
                  }
                  .ant-input:focus,
                  .ant-input-focused {
                    box-shadow: none;
                  }
                  .ant-select-selection-overflow {
                    display: none !important;
                  }
                  .ant-input {
                    height: 32px;
                    border: 1px solid #707070;
                    border-radius: 5px;
                  }
                  .ant-select:not(.ant-select-customize-input)
                    .ant-select-selector {
                    width: 200px;
                    height: 32px;
                    border: 1px solid #707070;
                    border-radius: 5px;
                  }
                  .ant-select-focused:not(.ant-select-disabled).ant-select:not(
                      .ant-select-customize-input
                    )
                    .ant-select-selector {
                    box-shadow: none;
                  }
                }
              }
              .staticDocumentsList {
                display: flex;
                flex-direction: column;
                gap: 20px;
                flex-wrap: wrap;
                align-items: flex-start;
                justify-content: center;
                .documentContainer {
                  display: grid;
                  grid-template-columns: 250px 1fr;
                  .deleteToStatic {
                    cursor: pointer;
                  }
                }
              }
              .staticDocuments {
                display: flex;
                width: 100%;
                flex-wrap: wrap;

                .ant-upload-list-picture .ant-upload-list-item-error,
                .ant-upload-list-picture-card .ant-upload-list-item-error {
                  border-color: #d9d9d9;
                }
                .ant-upload-list-item-error,
                .ant-upload-list-item-error .ant-upload-text-icon > .anticon,
                .ant-upload-list-item-error .ant-upload-list-item-name {
                  color: black;
                }
                .ant-upload-list-picture
                  .ant-upload-list-item-error
                  .ant-upload-list-item-thumbnail
                  .anticon
                  svg
                  path[fill="#e6f7ff"],
                .ant-upload-list-picture-card
                  .ant-upload-list-item-error
                  .ant-upload-list-item-thumbnail
                  .anticon
                  svg
                  path[fill="#e6f7ff"] {
                  fill: white;
                }
                .ant-upload-list-picture
                  .ant-upload-list-item-error
                  .ant-upload-list-item-thumbnail
                  .anticon
                  svg
                  path[fill="#1890ff"],
                .ant-upload-list-picture-card
                  .ant-upload-list-item-error
                  .ant-upload-list-item-thumbnail
                  .anticon
                  svg
                  path[fill="#1890ff"] {
                  fill: #d9d9d9;
                }
                .ant-tooltip.ant-tooltip-placement-top {
                  visibility: hidden;
                }
              }
            }

            .ant-form label {
              padding: 3px;
              font-size: 16px;
            }
          }
        }
      }
      .rental-template-desc-body {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        gap: 20px;
        height: 100%;
        .rental-template-desc-body__item {
          flex: 1;
        }
      }
    }
  }
  .ant-modal-content {
    border-radius: 10px 10px;
    height: 100%;

    .ant-modal-footer {
      width: 100%;
      display: flex;
      justify-content: space-between;
      border: none;
      .footerModal {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
    }
  }
  .ant-modal-header {
    height: 48px;
    display: flex;
    align-items: center;
    background-color: #f8f8fa;
    border-radius: 10px 10px 0px 0px;
  }
  .footerModal {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}

.dark-modal-template {
  .ant-modal-close-x {
    svg {
      fill: #fff;
    }
  }
  .ant-modal-content {
    background-color: $cardModalBodyDark;
  }
  .ant-modal-header {
    background-color: $cardModalHeaderDark;
    border-bottom: none;
    .ant-modal-title {
      color: #fff;
    }
  }
  .ant-modal-body {
    background-color: $cardModalBodyDark;
    .rental-template-desc-body__item {
      &:first-child {
        background-color: $cardModalBodyDark;
      }
      background-color: #fff;
      border: none;
      iframe {
        border: none;
      }
    }
    .ql-toolbar.ql-snow {
      background-color: $cardModalBodyDark;
      width: 100%;
    }
    .ant-radio-wrapper {
      color: #fff;
    }
    .rental-template-desc-modal {
      .header-pick-template {
        .cardContainer {
          background-color: $cardModalBodyDark;
          box-shadow: 0px 1px 4px #ffffff29;
          .cardTitleCont {
            background-color: $cardModalHeaderDark;
            .cardTitle {
              color: #fff;
            }
          }
        }
      }
    }
    .tox {
      border: none;
      .tox-menubar {
        background-color: $cardModalHeaderDark;
        border-radius: 5px 5px 0px 0px;
        .tox-mbtn__select-label {
          color: #fff;
        }
      }
      .tox-mbtn--select:hover {
        background-color: $cardModalBodyDark;
      }
      .tox-toolbar-overlord {
        background-color: $cardModalHeaderDark !important;
        .tox-toolbar__primary {
          background-color: $cardModalHeaderDark !important;
        }
        .tox-toolbar__group {
          background-color: $cardModalHeaderDark !important;
          border: none;
          .tox-split-button:hover {
            border: none;
          }
          .tox-tbtn {
            svg {
              fill: #fff;
            }
            &:focus {
              background-color: $cardModalBodyDark;
            }
            &:hover {
              background-color: $cardModalBodyDark;
            }
          }
          .tox-tbtn__select-label {
            color: #fff !important;
          }
        }
      }
    }
  }
  .ant-modal-footer {
    background-color: #1f212d;
  }
}
