$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.preSafetyModal {
  width: fit-content !important;
  height: fit-content !important;
  .ant-modal-content {
    min-width: 380px !important;
    width: fit-content !important;
    .ant-modal-close-x svg {
      fill: #333238;
    }
    .ant-modal-header {
      height: 45px;
      display: flex;
      align-items: center;
      background-color: #f8f8fa;
      padding: 0px 20px;
    }

    .note-modal-content {
      .labeledInputContainer {
        .ant-select {
          width: 100%;
        }
      }
    }

    .ant-modal-footer {
      padding: 10px 20px;
    }
  }
}

.preSafetyModalDark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      .labeledInputLabel {
        color: #fff;
      }
      .ant-select {
        .ant-select-selector {
          background-color: $inputModalDark !important;
          .ant-select-selection-search-input {
            color: #fff;
          }
          .ant-select-selection-item {
            color: #fff;
          }
          .ant-select-selection-placeholder {
            color: #acacca;
          }
        }
        .ant-select-clear {
          background-color: $inputModalDark;
          svg {
            fill: #acacca;
          }
        }
        .ant-select-arrow svg {
          fill: #fff;
        }
      }
      .ant-alert-error {
        background-color: #ff3e3e36;
        border: 1px solid rgb(255, 114, 114);
        .ant-alert-message {
          color: #fff;
        }
      }
    }
  }
}
