.EditDropdown {
  .ant-form-item {
    margin-bottom: 0px;
  }
  .EditDropdownComponent {
    width: 350px;
    height: 33px;
    background: #f5f5f7 0% 0% no-repeat padding-box;
    opacity: 1;
    border-radius: 5px;
    border: 0px;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #f5f5f7;
    border: 0px;
    border-radius: 5px;
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    box-shadow: none;
  }
}
.EditDropDownClassName {
  padding: 0;
  border-radius: 5px;

  .ant-select-item {
    padding: 3px 12px;
    width: 350px;
  }
}
