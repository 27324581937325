@import "../../../../../../../assets/styles/breakpoints.scss";

$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.invoiceFormContainer {
  display: flex;
  flex-direction: row;
  gap: 20px;
  background-color: inherit;
  justify-content: space-between;
  font-family: inherit;

  @include tablet {
    gap: 10px;
    flex-wrap: wrap;
  }

  @include laptop {
    gap: 10px;
  }

  .invoiceFormWrapper {
    width: 50%;

    @include laptop {
      width: 100%;
    }

    @include mobile {
      width: 100%;
    }

    .header {
      padding: 0 20px;
      display: flex;
      justify-content: space-between;
      display: flex;
    }
  }
  .estimationsContainer {
    width: 30%;

    @include laptop {
      width: 50%;
      margin-left: 0px;
    }

    @include mobile {
      width: 100%;
      margin-left: 0px;
    }
  }
  .totalContainer {
    width: calc(20% - 50px);

    @include laptop {
      width: calc(50% - 10px);
      margin-left: 0px;
    }

    @include mobile {
      width: 100%;
      margin-left: 0px;
    }

    margin-left: 20px;
    .body {
      height: 100%;
      > div {
        border: solid 1px #71cf48;
        border-radius: 10px;
        background: #eaf8e3;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        > p {
          font-weight: 600;
          font-size: 16px;
          font-family: inherit;
        }
        > h2 {
          font-weight: 600;
          font-size: 46px;
          font-family: inherit;
          max-width: 90%;
          text-align: center;
        }
      }
      .payment-button {
        background-color: #1264a3;
        color: #ffffff;
        border-radius: 5px;
        border: none;
        margin-top: 20px;
      }
    }
  }
  > div {
    box-shadow: 0px 0px 11px -8px;
    border-radius: 10px;
    background-color: white;
    .header {
      display: flex;
      height: 45px;
      background: #f7f8fa 0% 0% no-repeat padding-box;
      align-items: center;
      z-index: 2;
      padding: 0 10px 0 5px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;

      > div {
        width: calc(100% - 35px);
        letter-spacing: 0px;
        color: #323338;
        opacity: 1;
        cursor: pointer;
        font-weight: 600;
        font-size: 16px;
        font-family: inherit !important;
      }
      .invoice-header-buttons {
        display: flex;
        justify-content: flex-end;
        margin: 10px;
      }
    }

    .body {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 20px;
      font-family: inherit;
      form {
        width: 100%;
      }

      .subContainer {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        width: 100%;
        justify-content: space-between;

        @include mobile {
          flex-direction: column;
        }

        > div {
          //margin: 5px;
          display: flex;
          flex-direction: column;
          margin-right: 15px;
          width: 34%;
        }
        > .billingAddress {
          width: 100%;
        }

        .ant-picker {
          background-color: #f4f5f6 !important;
          border: none;
        }

        .Autocomplete {
          > div {
            background-color: #f4f5f6 !important;
            border-radius: 5px;
            height: 32px;
            border: 0;
          }
        }

        .Autocomplete:focus {
          border-color: #87c0ef6d !important;
          border-width: 2px;
          outline: 0;
          -webkit-box-shadow: 0 0 0 0px;
          box-shadow: 0 0 0 0px rgba(87, 168, 233, 0.2);
        }

        .Autocomplete:hover {
          border-color: #87c0ef6d !important;
          border-width: 2px;
          outline: 0;
          -webkit-box-shadow: 0 0 0 0px;
          box-shadow: 0 0 0 0px rgba(87, 168, 233, 0.2);
        }

        .inputForm {
          background-color: #f4f5f6 !important;
          padding: 2px;
          // margin: 2px;
          border: 2px solid transparent;
        }

        .inputForm:focus {
          border-radius: 5px;
          border: 2px solid #87c0ef6d !important;
          outline: 0;
          -webkit-box-shadow: 0 0 0 0px;
          box-shadow: 0 0 0 0px rgba(87, 168, 233, 0.2);
        }

        .inputForm:hover {
          outline: 0;
          //border-color: #d9d9d9;
          -webkit-box-shadow: 0 0 0 0px;
          box-shadow: 0 0 0 0px rgba(87, 168, 233, 0.2);
        }
      }

      .serviceGroupsContainer {
        display: flex;
        width: 100%;
        flex-direction: column;
        flex-wrap: nowrap;
        margin-left: -5px;
        align-items: center;
        .body {
          display: flex !important;
          flex-direction: row !important;
          flex-wrap: wrap !important;
          justify-content: flex-start;
          width: 100%;
          align-items: center;
          .estimationContainer {
            width: 33%;
            display: flex;
            align-items: center;
            text-align: center;
            padding-right: 5px;
            .ant-checkbox-wrapper {
              margin-left: 10px;
            }
          }
        }
        > div > label {
          margin: 5px;
        }
      }

      label,
      .serviceGroupsContainer > .estimationContainer > label,
      .serviceGroupsContainer > .estimationContainer {
        font-size: 14px;
        font-weight: 600;
        opacity: 1;
        text-transform: none;
        letter-spacing: 0.21px;
        color: #383554;
        margin-bottom: 2px;
      }

      .cancelFooterButton {
        background-color: #f3f3f3;
        box-shadow: 2px 2px 2px rgb(0 0 0 / 7%);
        border: 1px solid #bfbfbf;
        border-radius: 7px;
        color: #383554;
        margin-right: 15px;
      }
    }
  }
  .autoCompleteProduct {
    > div {
      background-color: #f4f5f6 !important;
      border-radius: 5px !important;
      height: 32px !important;
      border: 0 !important;
    }

    .ant-select-dropdown {
      padding: 0px;
    }

    .ant-select-item {
      padding: 0px;
    }

    .menuItemWrapper {
      border-bottom: 1px solid #d4d7dc;
      padding: 10px;
      outline: none;
      font-size: 16px;
      color: rgb(57, 58, 61);
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      background-color: white;
    }

    .menuItemWrapper:hover {
      background-color: rgb(16, 128, 0);
      color: rgb(255, 255, 255);
    }

    .menuItemContainer {
      min-width: 700px;
      padding-right: inherit;
      display: flex !important;
      max-width: 800px;
      width: 100%;
      height: 100%;
    }

    .column {
      min-width: 150px;
      display: flex;
      flex-direction: column;
      width: 50%;
      margin: 0;
      padding: 0;
    }
  }

  .autoCompleteProduct:focus {
    border-color: #87c0ef6d !important;
    border-width: 2px;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0px;
    box-shadow: 0 0 0 0px rgba(87, 168, 233, 0.2);
  }

  .autoCompleteProduct:hover {
    border-color: #87c0ef6d !important;
    border-width: 2px;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0px;
    box-shadow: 0 0 0 0px rgba(87, 168, 233, 0.2);
    > span {
      > svg {
        height: 7px !important;
      }
    }
  }
}

.invoiceFormContainer-dark {
  @extend .invoiceFormContainer;

  .invoiceFormWrapper {
    .header {
      background-color: $inputModalDark;
    }
    .header > div:first-child {
      color: #fff;
    }
    box-shadow: 0px 0px 4px 2px #71cf48;
    background-color: $cardModalBodyDark;
  }
  .estimationsContainer {
    box-shadow: 0px 0px 4px 2px #71cf48;
    background-color: $cardModalBodyDark;
  }
  .totalContainer {
    box-shadow: 0px 0px 4px 2px #71cf48;
    background-color: $cardModalBodyDark;
  }
  > div {
    background-color: $inputModalDark;
    .header {
      background: $inputModalDark 0% 0% no-repeat padding-box;
      > div {
        color: #fff;
      }
    }

    .body {
      .subContainer {
        .ant-select {
          border: none;
          .ant-select-selector {
            background-color: $inputModalDark !important;
            border: none;
            .ant-select-selection-search-input {
              color: #fff;
            }
            .ant-select-selection-item {
              color: #fff;
            }
            .ant-select-selection-placeholder {
              color: #acacca;
            }
          }
          .ant-select-clear {
            background-color: $inputModalDark;
            svg {
              fill: #acacca;
            }
          }
          .ant-select-arrow svg {
            fill: #fff;
          }
        }
        //Disabled
        .ant-select-disabled {
          .ant-select-selector {
            .ant-select-selection-search-input {
              color: #acacca !important;
            }
            .ant-select-selection-item {
              color: #acacca !important;
            }
          }
        }

        .ant-picker {
          background-color: $inputModalDark !important;
          border: none;
          .ant-picker-input {
            background-color: $inputModalDark;
            input {
              color: #f2f2f2;
              &::placeholder {
                color: #acacca;
              }
            }
            .ant-picker-suffix svg {
              color: #acacca;
            }
            .ant-picker-clear {
              background-color: $inputModalDark;
              svg {
                fill: #acacca;
              }
            }
          }
        }
        //Dissabled
        .ant-picker-disabled {
          .ant-picker-input input {
            color: #acacca !important;
          }
        }

        .ant-input {
          background-color: $inputModalDark !important;
          color: #fff;
          border: none;
          &::placeholder {
            color: #acacca;
          }
        }
      }

      .serviceGroupsContainer {
        .body {
          background-color: #292b3a !important;
        }
      }

      label,
      .serviceGroupsContainer > .estimationContainer > label,
      .serviceGroupsContainer > .estimationContainer {
        color: #fff;
      }
    }
  }
  .autoCompleteProduct {
    > div {
      background-color: $inputModalDark !important;
      color: #fff;
    }
    .ant-select-selector {
      background-color: $inputModalDark !important;
      .ant-select-selection-search-input {
        color: #fff;
      }
      .ant-select-selection-item {
        color: #fff;
      }
      .ant-select-selection-placeholder {
        color: #acacca;
      }
    }
    .ant-select-clear {
      background-color: $inputModalDark;
      svg {
        fill: #acacca;
      }
    }
    .ant-select-arrow svg {
      fill: #fff;
    }

    .ant-select-item {
      padding: 0px;
      background-color: #292b3a !important;
    }

    .menuItemWrapper {
      border-bottom: 1px solid $inputModalDark;
      background-color: $inputModalDark;
    }
  }
}
