.sov-service-content-info-details-hoist {
  height: 45px;
  padding: 10px 25px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: #f8f8f8;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  .scheduleOfValuesContentInfoDetailsHoist {
    display: flex;
    font: normal normal 600 16px/22px Open Sans;

    .sovDetailsHoistAmount {
      margin-left: 5px;
      margin-right: 5px;
      color: #71cf48;
    }
  }
}

.sov-service-content-info-details-hoist-dark {
  height: 45px;
  padding: 10px 25px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  .scheduleOfValuesContentInfoDetailsHoist {
    display: flex;
    font: normal normal 600 16px/22px Open Sans;

    .sovDetailsHoistLabel {
      color: white;
    }

    .sovDetailsHoistAmount {
      margin-left: 5px;
      margin-right: 5px;
      color: #71cf48;
    }
  }
}
