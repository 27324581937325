@import "../../../../../../../../index.scss";

.documentationListCardHeader {
  width: 100%;
  @include flex(center, space-between);

  .leftSection {
    @include flex(center, space-between);
    gap: 20px;
  }

  .documentationListCardHeaderTitle {
    @include text(16px, 600, 0, #323338);
    margin-right: 47px;
  }

  .addressTitle {
    @include text(16px, 600, 0, #323338);
    max-width: 450px;
    margin-right: 47px;
    @include textEllipsis();
  }

  .newButton {
    align-self: center;
    @include mondayButton(#71cf48, #68c142);
    .mondayButtonIcon {
      width: 30px;
    }
  }
}
