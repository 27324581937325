.bill-footer-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px;

  .bill-footer-buttons {
    display: flex;
    gap: 20px;
  }
}
