.comment-changes-history {
  width: unset !important;
  max-width: 1000px;
  .ant-modal-body {
    max-height: 600px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    .comment-change {
      display: flex;
      gap: 5px;
      align-items: center;
    }
  }
}
