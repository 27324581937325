:root {
  --overview-Dynamic-height: 70vh;
}

$sideButtonDarkColor: #2a2b3a;

.OverviewPage {
  background: #f4f5f6;
  opacity: 1;
  max-width: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .overviewBody {
    display: flex;
    flex-direction: row;
    padding-bottom: 20px;
    width: 100%;
  }
  .cardsBody {
    display: flex;
    max-width: calc(100% - 60px);
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
  }
  .leftPart {
    display: flex;
    flex-direction: column;
    height: initial;
    min-width: 600px;
    flex-grow: 1;
    flex-basis: 0;

    &:not(:last-child) {
      .CardViewComponent {
        margin: 10px 5px 0 0 !important;
      }
      .CardViewComponentCollapsed {
        margin: 10px 5px 0 0 !important;
      }
    }
    &:not(:first-child) {
      .CardViewComponent {
        margin: 10px 0 0 5px !important;
      }
      .CardViewComponentCollapsed {
        margin: 10px 0 0 5px !important;
      }
    }

    .smallPart {
      display: flex;
    }
  }
  .smallPartContainer {
    display: flex;
  }
  .sideBarIcons {
    z-index: 2;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 20px;
    right: 20px;
    .hiddenButton {
      display: flex;
      justify-content: center;
      height: 40px;
      width: 40px;
      line-height: 40px;
      border-radius: 4px;
      margin-bottom: 10px;
      color: #290909;
      background-color: #1f2a44;
      text-align: center;
      font-size: 14px;
      cursor: pointer;
      .hiddenIcon {
        display: flex;
        align-items: center;
        fill: #ffffff;
        svg {
          width: 22px !important;
          height: 22px;
          fill: #ffffff;
        }
      }
    }
  }
  .sideBarIconsDark {
    .hiddenButton {
      background-color: #373b5a;
    }
    .loading-dots b {
      color: #fff;
    }
  }

  @media screen and (max-width: 1650px) {
    .overviewBody {
      flex-direction: column;
    }
  }
}

@media (max-width: 1000px) {
  .OverviewPage {
    .leftPart {
      min-width: 100%;
      .CardViewComponent {
        width: 100% !important;
      }
    }
  }
}

@media only screen and (max-width: 821px) {
  .OverviewPage .overviewBody {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .OverviewPage {
    .cardsBody {
      max-width: calc(100vw - 65px);
    }
    .sideBarIcons {
      right: 10px;
    }
  }
}

@media only screen and (max-width: 440px) {
  .OverviewPage .overviewBody {
    width: 100%;
  }
  .OverviewPage {
    .InformationBody .InforamtionBodyElementContainer {
      .InforamtionBodyElementContainerContent {
        font: normal normal normal 14px/14px Open Sans;
      }
      span.InformationBodyElementContainerTitle {
        font: normal normal normal 14px/14px Open Sans;
      }
    }
    .CardViewHeaderLabel .headerlabel {
      font: normal normal normal 14px/14px Open Sans;
    }
    .CardViewComponentHeader .CardViewComponentHeaderTitle {
      font: normal normal 600 14px/14px Open Sans;
    }
  }
}

.popoverLinkOverview
  .ant-popover-content
  .ant-popover-inner
  .ant-popover-inner-content {
  overflow: hidden !important;
  padding: 0 !important;
}

.darkPopoverLinkOverview {
  .ant-popover-arrow {
    &::before {
      background-color: #12131b;
    }
  }
  .ant-popover-inner-content {
    border-radius: 5px !important;
  }
}
