$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.EditCardComponent {
  z-index: 1000;
  position: absolute;
  cursor: auto;

  .EditCardComponentBody {
    width: fit-content;
    max-width: calc(100vw - 20px);
    height: fit-content;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1 !important;
    position: relative;
    // padding: 20px;
    // padding-left: 47px;
    // padding-top: 23px;
    // padding-bottom: 23px;
    margin: 20px 10px 20px 10px;
    animation: scaleD 0.3s;

    .headerSearch {
      display: flex;
      width: 200px;
      height: 32px;
      // border: 0.2px solid #333;
      background-color: #f5f5f5;
      .searchEditMembers {
        width: 200px;
        border-radius: 5px;
        background-color: #f5f5f5;
        border: none;
      }
      input.ant-input {
        background-color: #f5f5f5;
      }
    }
    .editCardBodyContent {
      padding: 20px;

      .teamsCardContainer {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .labeledInputContainer {
          width: 300px;
          align-self: center;
        }
      }
    }

    .editHeaderCont {
      height: 45px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // margin-bottom: 28px;
      background-color: #f8f8fa;
      border-radius: 10px 10px 0px 0px;
      .editHeaderTitle {
        text-align: left;
        font: normal normal 600 16px Open Sans;
        letter-spacing: 0px;
        color: #323338;
        opacity: 1;
        padding-left: 20px;
      }
      .closeIcon {
        height: 45px;
        width: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          fill: #323338;
          cursor: pointer;
        }
      }
      // .editHeaderAlert {
      //   text-align: left;
      //   font: normal normal bold 16px/26px Open Sans;
      //   letter-spacing: 0px;
      //   color: #fe4c4a;
      //   opacity: 1;
      //   display: flex;
      //   align-items: center;
      //   margin-top: 5px;
      // }
    }
  }
}
.editMask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  display: flex;
  align-items: center;
  justify-content: center;
}

.EditCardComponentDark {
  .EditCardComponentBody {
    background-color: $cardModalBodyDark;
    .editHeaderCont {
      background-color: $cardModalHeaderDark;
      .editHeaderTitle {
        color: #fff;
      }
      .closeIcon svg {
        fill: #fff;
      }
    }
    .editCardBodyContent {
      .react-tel-input {
        .form-control {
          background-color: $inputModalDark !important;
          color: #fff;
          &::placeholder {
            color: #acacca;
          }
        }
        .flag-dropdown {
          background-color: $inputModalDark;
          border-radius: 5px 0px 0px 5px;
          .selected-flag {
            &:hover {
              background-color: $inputModalDark;
            }
            &:focus {
              background-color: $inputModalDark;
            }
          }
          .arrow {
            border-top: 4px solid #fff;
          }
        }
        .open {
          background-color: $inputModalDark;
          border-radius: 5px 0px 0px 5px;
          .selected-flag {
            background-color: $inputModalDark;
            .arrow {
              border-bottom: 4px solid #fff;
              border-top: 4px solid transparent;
            }
          }
        }
      }
      .ant-input-number {
        background-color: $inputModalDark;
        .ant-input-number-handler-wrap {
          background-color: $inputModalDark;
          .ant-input-number-handler {
            border-inline-start: 1px solid #acacca;
          }
          .ant-input-number-handler-down {
            border-block-start: 1px solid #acacca;
          }
        }
        .anticon-down svg,
        .anticon-up svg {
          fill: #fff;
        }
        .ant-input-number-input {
          color: #fff;
          &::placeholder {
            color: #acacca;
          }
        }
      }
      .InforamtionBodyElementContainer {
        .InformationBodyElementContainerTitle {
          color: #fff;
        }
        .FormItemsClassName {
          .ant-input {
            background-color: $inputModalDark !important;
            color: #fff;
            &::placeholder {
              color: #acacca;
            }
          }
          .ant-input-disabled {
            color: #acacca;
          }
          .ant-select {
            background-color: $inputModalDark !important;
            .ant-select-selector {
              background-color: $inputModalDark !important;
              .ant-select-selection-search-input {
                color: #fff;
              }
              .ant-select-selection-item {
                color: #fff;
              }
              .ant-select-selection-placeholder {
                color: #acacca;
              }
            }
            .ant-select-clear {
              background-color: $inputModalDark;
              svg {
                fill: #acacca;
              }
            }
            .ant-select-arrow svg {
              fill: #fff;
            }
          }
          .ant-select-disabled {
            .ant-select-selector {
              .ant-select-selection-search-input {
                color: #acacca !important;
              }
              .ant-select-selection-item {
                color: #acacca !important;
              }
            }
          }
          .checkboxeditFormItem {
            background-color: $inputModalDark;
            .configElementTitle {
              color: #fff;
            }
          }
        }
      }
      .LongTextBodyEdit {
        .dobContainerInput {
          .InformationBodyElementContainerTitle {
            color: #fff;
          }
          .ant-select {
            background-color: $inputModalDark !important;
            .ant-select-selector {
              background-color: $inputModalDark !important;
              .ant-select-selection-search-input {
                color: #fff;
              }
              .ant-select-selection-item {
                color: #fff;
              }
              .ant-select-selection-placeholder {
                color: #acacca;
              }
            }
            .ant-select-clear {
              background-color: $inputModalDark;
              svg {
                fill: #acacca;
              }
            }
            .ant-select-arrow svg {
              fill: #fff;
            }
          }
          .ant-select-disabled {
            .ant-select-selector {
              .ant-select-selection-search-input {
                color: #acacca !important;
              }
              .ant-select-selection-item {
                color: #acacca !important;
              }
            }
          }
          .ant-input {
            background-color: $inputModalDark !important;
            color: #fff;
            &::placeholder {
              color: #acacca;
            }
          }
          .ant-input-disabled {
            color: #acacca;
          }
        }
      }
      .InformationBodyElementContainerTitle {
        color: #fff !important;
      }
      .richTextComp {
        .tox {
          border: none;
          .tox-menubar {
            background-color: $cardModalHeaderDark;
            border-radius: 5px 5px 0px 0px;
            .tox-mbtn__select-label {
              color: #fff;
            }
          }
          .tox-mbtn--select:hover {
            background-color: $cardModalBodyDark;
          }
          .tox-toolbar-overlord {
            background-color: $cardModalHeaderDark !important;
            .tox-toolbar__primary {
              background-color: $cardModalHeaderDark !important;
            }
            .tox-toolbar__group {
              background-color: $cardModalHeaderDark !important;
              border: none;
              .tox-split-button:hover {
                border: none;
              }
              .tox-tbtn {
                svg {
                  fill: #fff;
                }
                &:focus {
                  background-color: $cardModalBodyDark;
                }
                &:hover {
                  background-color: $cardModalBodyDark;
                }
              }
              .tox-tbtn__select-label {
                color: #fff !important;
              }
            }
          }
        }
      }
      .editMembersContent {
        .editMembersContentElementContainer {
          .editMembersContentElementContainerTitle {
            color: #fff;
          }
          .EditDropdownComponent {
            background-color: $inputModalDark !important;
            .ant-select-selector {
              background-color: $inputModalDark !important;
              .ant-select-selection-search-input {
                color: #fff;
              }
              .ant-select-selection-item {
                color: #fff;
              }
              .ant-select-selection-placeholder {
                color: #acacca;
              }
              .ant-select-selection-overflow .ant-select-selection-item {
                background-color: #323338;
                svg {
                  fill: #fff;
                }
              }
            }
            .ant-select-clear {
              background-color: $inputModalDark;
              svg {
                fill: #acacca;
              }
            }
            .ant-select-arrow svg {
              fill: #fff;
            }
          }
          .ant-select-disabled {
            .ant-select-selector {
              .ant-select-selection-search-input {
                color: #acacca !important;
              }
              .ant-select-selection-item {
                color: #acacca !important;
              }
            }
          }
        }
      }
      .teamsCardContainer {
        .headerSearch {
          background-color: $inputModalDark;
          .iconSearch svg {
            fill: #fff;
          }
          .searchEditMembers {
            background-color: $inputModalDark;
            .ant-input {
              background-color: $inputModalDark !important;
              color: #fff;
              &::placeholder {
                color: #acacca;
              }
            }
          }
        }
        .teamsInnerContainer {
          .transferEditedContainer {
            .unSelectedContainer,
            .selectedContainer {
              .countItemsLabel {
                color: #fff;
              }
              .configElementContainer {
                background-color: $inputModalDark;
                .configElementTitle {
                  color: #fff;
                }
              }
            }
            .middleContainer .circleStyle svg {
              fill: #fff;
            }
          }
          .scrollTopContainer svg path,
          .scrollBottomContainer svg path {
            fill: #fff;
          }
        }
      }
      .transferEditedContainer {
        .unSelectedContainer,
        .selectedContainer {
          .countItemsLabel {
            color: #fff;
          }
          .configElementContainer {
            background-color: $inputModalDark;
            .configElementTitle {
              color: #fff;
            }
          }
        }
        .middleContainer .circleStyle svg {
          fill: #fff;
        }
      }
      .scrollTopContainer svg path,
      .scrollBottomContainer svg path {
        fill: #fff;
      }
      .smallEditComponent {
        .smallEditContainer {
          gap: 10px;
          .SmallLabel {
            color: #fff;
          }
          .ant-select {
            .ant-select-selector {
              background-color: $inputModalDark !important;
              .ant-select-selection-search-input {
                color: #fff;
              }
              .ant-select-selection-item {
                color: #fff;
              }
              .ant-select-selection-placeholder {
                color: #acacca;
              }
            }
            .ant-select-clear {
              background-color: $inputModalDark;
              svg {
                fill: #acacca;
              }
            }
            .ant-select-arrow svg {
              fill: #fff;
            }
          }
          .ant-select-disabled {
            .ant-select-selector {
              .ant-select-selection-search-input {
                color: #acacca !important;
              }
              .ant-select-selection-item {
                color: #acacca !important;
              }
            }
          }
        }
      }
      .addressCardContainer {
        .checkSameAsBilling span {
          color: #fff;
        }
        .addDetails {
          .address {
            .label {
              color: #fff;
            }
            svg {
              fill: #fff;
            }
            .ant-select {
              .ant-select-selector {
                background-color: $inputModalDark !important;
                .ant-select-selection-search-input {
                  color: #fff;
                }
                .ant-select-selection-item {
                  color: #fff;
                }
                .ant-select-selection-placeholder {
                  color: #acacca;
                }
              }
              .ant-select-clear {
                background-color: $inputModalDark;
                svg {
                  fill: #acacca;
                }
              }
              .ant-select-arrow svg {
                fill: #fff;
              }
            }
            .ant-select-disabled {
              .ant-select-selector {
                .ant-select-selection-search-input {
                  color: #acacca !important;
                }
                .ant-select-selection-item {
                  color: #acacca !important;
                }
              }
            }
          }
          .detailsContainer {
            .label {
              color: #fff;
            }
            .ant-input {
              background-color: $inputModalDark !important;
              color: #fff;
              &::placeholder {
                color: #acacca;
              }
            }
            .ant-input-disabled {
              background-color: $inputModalDark;
              color: #acacca;
            }
          }
        }
      }
      .UploadComponent {
        .UploadContainer {
          .documentTitle .documentName {
            color: #fff;
          }
        }
      }
      .DateRangeBodyElementContainer {
        .ant-picker {
          background-color: $inputModalDark !important;
          .ant-picker-input {
            background-color: $inputModalDark;
            input {
              color: #f2f2f2;
              &::placeholder {
                color: #acacca;
              }
            }
            .ant-picker-suffix svg {
              color: #acacca;
            }
            .ant-picker-clear {
              background-color: $inputModalDark;
              svg {
                fill: #acacca;
              }
            }
          }
        }
        .ant-picker-disabled {
          .ant-picker-input input {
            color: #acacca !important;
          }
        }
      }
      .CustomStatusContainer {
        .CustomStatusDevider {
          background: transparent
            linear-gradient(
              90deg,
              $cardModalBodyDark 0%,
              $cardModalHeaderDark 51%,
              $cardModalBodyDark 100%
            )
            0% 0% no-repeat padding-box;
        }
      }
    }
    .confirmContainer {
      background-color: $cardModalBodyDark;
    }
  }
}

@media (max-width: 680px) {
  .EditCardComponent .EditCardComponentBody .InformationBodyEdit {
    align-items: center;
    .InforamtionBodyElementContainer {
      display: flex;
      flex-direction: column;
    }
  }
}

@media (max-width: 520px) {
  .EditCardComponent .EditCardComponentBody {
    .InforamtionBodyElementContainer {
      .InformationBodyElementContainerTitle {
        font-size: 16px;
      }
    }
    .editHeaderCont {
      flex-direction: row;
      align-items: center;
      .editHeaderTitle {
        font-weight: 600;
        font-size: 20px;
        margin-right: 0px;
      }
      .editHeaderAlert {
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
}

@media (max-width: 450px) {
  .EditCardComponent .EditCardComponentBody .InforamtionBodyElementContainer {
    .EditDropdown .EditDropdownComponent {
      width: 230px !important;
    }
    .EditInput .EditInputComponent {
      width: 230px;
    }
  }
}

@keyframes scaleD {
  0% {
    opacity: 0;
    transform: scale(0);
    -webkit-transform: scale(0);
  }

  100% {
    opacity: 1;
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
