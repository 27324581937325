$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.videoAndDescription {
  flex: 5;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  height: 100% !important;

  .reactPlayerDiv {
    flex: 1;
    width: 100%;
    border-radius: 10px;
    background-color: #000;
  }

  .description-container {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .fav-link {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;

        button {
          padding: 8px 16px;
          font-size: 16px;
          background-color: #1264a3;
          color: #fff;
          border: none;
          border-radius: 8px;

          &:hover {
            background-color: #0f5c97;
          }
        }
      }
    }

    .description-container {
      display: flex;
      flex-direction: column;
      gap: 2px;

      .title {
        font-size: 14px;
        font-weight: 600;
        padding-left: 10px;
      }

      .description-box {
        flex-grow: 1;
        padding: 4px 8px;
        height: 80px;
        border-radius: 7px;
        background-color: #f5f5f7;
        overflow-y: auto;
      }
    }
  }
}

.videoAndDescription-tablet {
  width: 100%;
  .reactPlayerDiv {
    height: 580px;
    width: 100%;
  }
}

.videoAndDescription-mobile {
  margin-bottom: 10px;
  .reactPlayerDiv {
    height: 60vh;
  }

  .description-container {
    .header {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 4px;

      .fav-link {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;

        button {
          padding: 8px 16px;
          font-size: 16px;
          background-color: #1264a3;
          color: #fff;
          border: none;
          border-radius: 8px;

          &:hover {
            background-color: #0f5c97;
          }
        }
      }
    }
  }
}

.videoAndDescription-dark {
  .description-container {
    .description-container {
      .title {
        color: #fff;
      }

      .description-box {
        background-color: $cardModalHeaderDark;
        color: #fff;
      }
    }
  }
}
