//filter container style
$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.filterComponents {
  &:first-child {
    margin-top: 20px;
  }
  margin-bottom: 35px;

  .tittle {
    width: stretch;
    font-size: 18px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    margin: 20px 0;
  }
  .filterContainer {
    display: flex;
    align-items: center;

    .leftSideContainer {
      display: flex;
      flex-direction: column;
      display: flex;
      width: 220px;
      gap: 25px;

      .tittleContainer {
        height: 32px;
        display: flex;
        justify-content: flex-end;
        margin-right: 15px;
        align-items: center;
        font-size: 16px;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
          Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
      }
    }

    .rightSideContainer {
      display: flex;
      flex-direction: column;
      gap: 25px;

      .filter-by-date-picker {
        height: 32px;
        width: 200px;
        border-radius: 5px;
        border: 0px;
        background-color: #f5f5f7;

        .ant-picker-suffix {
          svg {
            fill: #cccccc;
          }
        }
      }

      .filter-by-range-picker {
        height: 32px;
        width: 280px;
        border-radius: 5px;
        border: 0px;
        background-color: #f5f5f7;

        .ant-picker-suffix {
          svg {
            fill: #cccccc;
          }
        }
      }

      .filter-dropdown {
        height: 32px;
        width: 200px;
        border-radius: 5px;
        border: 0px;
        background-color: #f5f5f7;
        display: flex;
        justify-content: space-between;
        color: #cccccc;

        svg {
          margin-top: 2px;
          fill: #cccccc !important;
        }
      }

      .status-selector {
        .ant-select-selector {
          height: 32px;
          width: 250px;
          border-radius: 5px;
          border: 0px;
          background-color: #f5f5f7;
          display: flex;
          justify-content: space-between;
          color: #cccccc;
        }

        .ant-select-arrow {
          margin-right: 5px;
          svg {
            fill: #cccccc;
          }
        }
      }
    }

    .slider-container {
      width: 45%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      flex-direction: row;

      .slider-value {
        width: 50px;
        height: 32px;
        border-radius: 5px;
        border: 0px;
        background-color: #f5f5f7;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #323338;
        font-weight: 450;
      }
    }
  }
}

//calendar dropdown style
.filter-by-date-picker-dropdown {
  transition: height 0s ease !important;
  border-radius: 10px;
  overflow: hidden;
  padding: 0px !important;
  box-shadow: 0px 1px 4px #00000029;

  .ant-picker-panel {
    border: 0px solid #f0f0f0;
  }

  .ant-picker-range-arrow {
    display: none;
  }
  .ant-picker-header {
    border: 0;
  }

  .ant-picker-header-view {
    text-align: center;
    font: normal normal 600 22px/30px Open Sans;
    letter-spacing: 0px;
    color: #323338;

    .ant-picker-header-super-prev-btn {
      display: none;
    }

    .ant-picker-header-super-next-btn {
      display: none;
    }
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(
      .ant-picker-cell-range-start-single
    ):not(.ant-picker-cell-range-end)
    .ant-picker-cell-inner {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    background-color: #1264a3;
    font-weight: bold;
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(
      .ant-picker-cell-range-end-single
    ):not(.ant-picker-cell-range-start)
    .ant-picker-cell-inner {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    background-color: #1264a3;
    font-weight: bold;
  }

  .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
    background-color: #1264a3;
    color: #fff !important;
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(
      .ant-picker-cell-range-start-single
    )::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(
      .ant-picker-cell-range-end-single
    )::before {
    background: #1264a3;
  }

  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    border-radius: 8px 8px 8px 8px;

    background-color: #1264a3;
  }

  .ant-picker-cell-in-view.ant-picker-cell-in-range {
    .ant-picker-cell-inner {
      color: white;
    }
  }
}

.dateDropDownDark {
  .ant-picker-header-view {
    color: #f2f2f2;
  }
  .ant-picker-panel-container {
    background-color: $inputModalDark;
    .ant-picker-panels {
      .ant-picker-panel {
        .ant-picker-date-panel {
          .ant-picker-header {
            color: #f2f2f2;
            .ant-picker-header-super-prev-btn,
            .ant-picker-header-prev-btn,
            .ant-picker-header-next-btn,
            .ant-picker-header-super-next-btn {
              color: #acacca;
            }
          }
          .ant-picker-body .ant-picker-content {
            th {
              color: #f2f2f2;
            }
            .ant-picker-cell {
              color: #acacca;
            }
            .ant-picker-cell-in-view {
              color: #f2f2f2;
            }
          }
        }
      }
    }
  }
}
