.rental-calculator-modal {
  .ant-modal-header {
    background-color: #f8f8fa;
    border-radius: 5px;
    .ant-modal-title {
      color: #323338;
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
    }
  }

  .ant-modal-body {
    .rental-calculator-body_content {
      height: 85dvh;
      overflow-y: auto;
      .warning-info-rental-desc {
        display: flex;
        gap: 5px;
        align-items: center;
      }
      .rental-calculator-actions_wrapper {
        margin-top: 20px;
        .rental-calculator-actions_header {
          display: flex;
          gap: 20px;
          margin-left: 25px;
          .rental-calculator-input-holder {
            display: flex;
            // align-items: center;
            gap: 0px;
            flex-direction: column;
            label {
              font-family: "Open Sans";
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 22px;
              color: #323338;
            }
            .rental-calculator-input-price {
              background-color: red;
            }
          }
        }
        .rental-calculator-table {
          height: fit-content;
          //   border: 1px solid red;
          margin-top: 20px;
          .rental-calculator-table_first-row {
            width: 100%;
            display: flex;
            justify-content: center;
            gap: 40px;
            .rental-calculator-input-holder {
              display: flex;
              // align-items: center;
              gap: 0px;
              flex-direction: column;
              label {
                font-family: "Open Sans";
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 22px;
                color: #323338;
              }
              .rental-calculator-input-price {
                // background-color: red;
                display: flex;
                // width: 100%;
                // padding: 0 20px 0 20px;
                justify-content: center;
                .ant-input-number {
                  border-radius: 5px;
                  border: none;
                }
                .ant-input-number-input {
                  text-align: center;
                  border-radius: 5px;
                  border: none;
                }
              }
            }
          }
        }
        .hightlight-prices-container {
          height: 78px;
          width: 100%;
          background: #f8f8fa;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;
          label {
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            margin-top: 7px;
          }
        }
        .rental-calculator-footer {
          display: flex;
          justify-content: space-between;
          margin-top: 20px;
        }
      }
    }
  }
}

.rental-calculator-modal__dark {
  .ant-modal-content {
    border-radius: 10px;
    background-color: #12131b;
    .ant-modal-close-x {
      justify-content: center;
      align-items: center;
      display: flex;
      height: 45px;
      width: 45px;
      padding: 0;
      color: #fff;

      svg {
        fill: #fff !important;
        color: #fff;
        height: 15px;
        width: 15px;
      }
    }
  }
  .ant-modal-header {
    background-color: #12131b;
    border: none;
    border-radius: 10px 10px 0 0;
    .ant-modal-title {
      color: #ffffff;
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
    }
  }
  .ant-modal-body {
    background-color: #1f212d;
    .ant-input-number.rental-calculator-input-price {
      background-color: #1f212d;
      border: none;
      .ant-input-number-input {
        background-color: #1f212d;
        border: none;
      }
    }
    .ant-input-number.rental-calculator-input {
      background-color: #1f212d;
      border: none;
      .ant-input-number-input {
        background-color: #12131b;
        border: none;
        color: #fff;
        &:disabled {
          background-color: #2f2f37;
          border: none;
          color: #fff;
        }
      }
    }
    .ant-input.ant-input-disabled.rental-calculator-input {
      background-color: #2f2f37;
      border: none;
      color: #fff;
    }
    .rental-calculator-body_content {
      height: 85dvh;
      overflow-y: auto;
      .warning-info-rental-desc {
        display: flex;
        gap: 5px;
        align-items: center;
        color: #fff;
      }
      .rental-calculator-actions_wrapper {
        margin-top: 20px;
        .rental-calculator-actions_header {
          display: flex;
          gap: 20px;
          margin-left: 25px;
          .rental-calculator-input-holder {
            display: flex;
            // align-items: center;
            gap: 0px;
            flex-direction: column;
            label {
              font-family: "Open Sans";
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 22px;
              color: #fff;
            }
            .rental-calculator-input-price {
              background-color: red;
            }
          }
        }
        .rental-calculator-table {
          height: fit-content;
          //   border: 1px solid red;
          margin-top: 20px;
          .rental-calculator-table_first-row {
            width: 100%;
            display: flex;
            justify-content: center;
            gap: 40px;
            .rental-calculator-input-holder {
              display: flex;
              // align-items: center;
              gap: 0px;
              flex-direction: column;
              label {
                font-family: "Open Sans";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                color: #fff;
              }
              .rental-calculator-input-price {
                // background-color: red;
                display: flex;
                // width: 100%;
                // padding: 0 20px 0 20px;
                justify-content: center;
                .ant-input-number {
                  border-radius: 5px;
                  border: none;
                }
                .ant-input-number-input {
                  text-align: center;
                  border-radius: 5px;
                  border: none;
                  background-color: #12131b;
                  color: #fff;
                }
              }
            }
          }
        }
        .hightlight-prices-container {
          height: 78px;
          width: 100%;
          background: #3282b8;
          display: flex;
          border-radius: 5px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          color: #fff;
          label {
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            margin-top: 7px;
          }
        }
        .rental-calculator-footer {
          display: flex;
          justify-content: space-between;
          margin-top: 20px;
        }
      }
    }
  }
}

.ant-input-number.rental-calculator-input-price {
  border: none;
  background: #f5f5f7;
  border-radius: 5px;
}

.ant-input-number.rental-calculator-input {
  border: none;
  background: #f5f5f7;
  border-radius: 5px;
}

.ant-input.ant-input-disabled.rental-calculator-input {
  color: #000;
}
.rental-calculator-body_content {
  height: 85dvh;
  overflow-y: auto;
  .warning-info-rental-desc {
    display: flex;
    gap: 5px;
    align-items: center;
  }
  .rental-calculator-actions_wrapper {
    margin-top: 20px;
    .rental-calculator-actions_header {
      display: flex;
      gap: 20px;
      margin-left: 25px;
      .rental-calculator-input-holder {
        display: flex;
        // align-items: center;
        gap: 0px;
        flex-direction: column;
        label {
          font-family: "Open Sans";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
          color: #323338;
        }
        .rental-calculator-input-price {
          background-color: red;
        }
      }
    }
    .rental-calculator-table {
      height: fit-content;
      //   border: 1px solid red;
      padding: 20px 0;
      .rental-calculator-table_first-row {
        width: 100%;
        display: flex;
        justify-content: center;
        gap: 40px;
        .rental-calculator-input-holder {
          display: flex;
          // align-items: center;
          gap: 0px;
          flex-direction: column;
          label {
            font-family: "Open Sans";
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            color: #323338;
          }
          .rental-calculator-input-price {
            // background-color: red;
            display: flex;
            // width: 100%;
            // padding: 0 20px 0 20px;
            justify-content: center;
            .ant-input-number {
              border-radius: 5px;
              border: none;
            }
            .ant-input-number-input {
              text-align: center;
              border-radius: 5px;
              border: none;
            }
          }
        }
      }
    }
    .hightlight-prices-container {
      height: 78px;
      width: 100%;
      background: #f8f8fa;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      label {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        margin-top: 7px;
      }
    }
    .rental-calculator-footer {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
    }
  }
}
.hightlight-prices-container {
  height: 78px;
  width: 100%;
  background: #3282b8;
  display: flex;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #fff;
  label {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    margin-top: 7px;
  }
}

.decide_free_months {
  .documentationsTable {
    .ag-root-wrapper-body.ag-layout-normal {
      height: fit-content !important;
      min-height: 150px;
      max-height: 300px !important;
      overflow-y: auto !important;
    }
    .ag-header-cell-label {
      padding-left: 20px;
    }
  }
}
