@import "../../../../../index.scss";

$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.createCategoryPanel {
  width: calc(100vw - 20px) !important;
  height: calc(100vh - 20px) !important;
  max-width: 670px;
  max-height: 500px;
  .ant-modal-content {
    width: calc(100vw - 20px) !important;
    height: calc(100vh - 20px) !important;
    max-width: 670px;
    max-height: 500px;
    .ant-modal-close {
      width: 45px;
      height: 45px;
    }
    .ant-modal-header {
      display: flex;
      align-items: center;
      height: 45px;
      background-color: #f5f5f7;
      .ant-modal-title {
        color: #323338;
        font-size: 16px;
        font-weight: 600;
        width: 100%;
      }
    }
    .ant-modal-body {
      height: calc(100% - 100px);

      .createCategoryPanel-container {
        display: flex;
        flex-direction: column;
        row-gap: 30px;
        .createCategoryPanel-info {
          display: flex;
          align-items: center;
          height: 20px;
          width: 100%;
          gap: 10px;
          .createCategoryPanel-text {
            font-size: 14px;
            font-weight: 400;
          }
        }
        .categoryCreatorSection {
          display: flex;
          gap: 30px;
          .categoryReport-select {
            width: 300;
            height: 53px;
            .labeledInputLabel {
              height: 19px;
              font-size: 14px;
            }
          }
        }
      }
    }
    .ant-modal-footer {
      .createCategoryPanel-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .mondayButtonStyle {
          width: 150px;
        }
      }
    }
  }
}

.createCategoryPanelDark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      .createCategoryPanel-info,
      .createCategoryPanel-text {
        color: #fff;
      }
      .ant-input-disabled {
        color: #acacca;
      }
    }
  }
}
