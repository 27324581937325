$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.labeled-qb-input {
  .qb-label {
    font-size: 16px;
    font-weight: 600;
    opacity: 1;
    text-transform: capitalize;
    letter-spacing: 0;
    color: #323338;
    font-family: "Open Sans";
    margin-bottom: 2px;
    margin-left: 10px;
    border: none;
  }
  .qb-input {
    border-radius: 5px;
    background-color: #f4f5f6;
    max-height: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5px;
    width: 100%;
    .ant-form-item {
      margin: 0;
    }
    .ant-input {
      //   height: 40px;
      //   border-radius: 5px;
      //   background-color: #f4f5f6;
      //   max-height: 32px;
      //   font-size: 16px;
      //   font-weight: 400;
      //   letter-spacing: 0;
      //   color: #323338;
      //   font-family: "Open Sans";
      //   border: none;
      //   width: 120px;
      &:hover {
        border-color: none;
        border: none;
      }
      &:focus {
        border: none;
        box-shadow: none;
        outline: none;
      }
      &:where {
        border: none;
        box-shadow: none;
        outline: none;
        background-color: #f4f5f6;
      }
    }
    .ant-picker {
      padding: 4px;
      height: 32px;
      border: none;
      background-color: #f4f5f6;
      .ant-picker-input {
        display: flex;
        align-items: center;
        input {
          display: none;
        }
        .ant-picker-suffix {
          margin-inline-start: 0;
        }
      }
      :hover {
        border-color: none !important;
        border: none !important;
        cursor: pointer;
      }
    }
  }
  .qb-time-input {
    //   .ant-form-item {
    //     margin: 0;
    //   }
    //   .ant-input {
    //     height: 40px;
    //     border-radius: 4px;
    //     background-color: #f4f5f6;
    //     max-height: 32px;
    //     font-size: 16px;
    //     font-weight: 400;
    //     letter-spacing: 0;
    //     color: #323338;
    //     font-family: "Open Sans";
    //     border: none;
    //     width: 100px;
    &:hover {
      border-color: none !important;
      border: none !important;
    }
    &:focus {
      border: none !important;
      box-shadow: none !important;
      outline: none !important;
    }
    &:where {
      border: none !important;
      box-shadow: none !important;
      outline: none !important;
      background-color: #f4f5f6 !important;
    }
  }
  // }
  .qb-error {
    font-size: 14px;
    color: #ff4d4f;
    overflow: visible;
  }
}

.task-date-input {
  .qb-input {
    background-color: #e2e9f8;
    border-radius: 5px;
    .ant-picker {
      background-color: #e2e9f8;
      .ant-picker-input {
        .ant-picker-suffix svg {
          color: #acacca;
        }
      }
    }
    .ant-input {
      background-color: #e2e9f8;
    }
  }

  .qb-time-input {
    .ant-input {
      background-color: #e2e9f8;
    }
  }
}

.labeled-qb-input-dark {
  .qb-input {
    background-color: $cardModalBodyDark;
    .ant-picker {
      background-color: $cardModalBodyDark;
      .ant-picker-input {
        .ant-picker-suffix svg {
          color: #acacca;
        }
      }
    }
    .ant-input {
      background-color: $cardModalBodyDark;
      color: #fff;
      &::placeholder {
        color: #acacca;
      }
    }
  }
  .qb-time-input {
    .ant-input {
      background-color: $cardModalBodyDark;
      color: #fff;
      &::placeholder {
        color: #acacca;
      }
    }
  }
}

.qb-date-picker-dark {
  .ant-picker-header {
    background-color: $cardModalHeaderDark;
    .ant-picker-header-super-prev-btn,
    .ant-picker-header-super-next-btn,
    .ant-picker-header-prev-btn,
    .ant-picker-header-next-btn {
      color: #fff;
    }
    .ant-picker-header-view {
      color: #fff;
    }
  }
  .ant-picker-body {
    background-color: $cardModalBodyDark;
    .ant-picker-content {
      background-color: $cardModalBodyDark;
      color: #fff;
      .ant-picker-cell {
        color: #fff;
      }
      .ant-picker-cell-disabled {
        color: #acacca;
      }
    }
  }
  .ant-picker-footer {
    background-color: $cardModalHeaderDark;
    .ant-picker-today-btn {
      color: #fff;
    }
  }
}
