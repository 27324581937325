@import "../../../../../../index.scss";

.card-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding: 6.5px 10px;
  border-radius: 5px;
  background: #f8f8fa;
  cursor: pointer;

  .page-icon {
    &.sales {
      path:first-child {
        fill: #323338;
      }
    }
    &.salesDarkMode {
      path:first-child {
        fill: none !important;
      }
    }

    &.other {
      path {
        fill: #323338;
      }
    }

    &.otherDark {
      path {
        fill: #fff;
      }
    }

    width: 20px;
    height: 20px;
    opacity: 1;
  }

  .name {
    flex-grow: 1;
  }

  &:hover {
    background-color: #f1f5fd;
    color: #1264a3;

    .page-icon {
      &.sales {
        path:first-child {
          fill: #1264a3;
        }
      }

      &.other {
        path {
          fill: #1264a3;
        }
      }

      &.otherDark {
        path {
          fill: #1264a3;
        }
      }
    }
  }

  &.activeCategory {
    background: #1264a3;
    color: #fff;

    .page-icon {
      &.sales {
        path:first-child {
          fill: #fff;
        }
        path:not(:first-child) {
          fill: #1264a3;
        }
      }

      &.other {
        path {
          fill: #fff;
        }
      }
    }
  }
}

.singleCard-containerDark {
  background-color: $cardDarkHeaderColor;

  svg {
    path {
      fill: #dadada;
    }
  }
}
