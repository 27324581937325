.chatsView {
  display: flex;
  .internalChatsContent {
    display: flex;
    flex-direction: column;
    width: 400px;
    .internalChatsContentTabs {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 30px;
      width: 400px;
      height: 44px;
      background-color: #f4f5f6;
      border-bottom: 0.5px solid #e6e9ef;
      border-right: 0.5px solid #e6e9ef;
      .tab {
        cursor: pointer;
        color: #acacac;
      }
      :hover {
        color: #1264a3;
        font-weight: 600;
      }
      .activeTab {
        color: #231f20;
        font-weight: 400 !important;
      }
    }
  }
}
