$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.createNewUserModal {
  width: fit-content !important;
  @media screen and (min-width: 1600px) {
    width: 1250px !important;
  }
  .ant-modal-content {
    .ant-modal-body {
      display: flex;
      flex-direction: column;
      gap: 20px;
      @media screen and (min-width: 1600px) {
        height: 620px !important;
      }
      .new-user-step {
        display: flex;
        max-height: 70dvh;
        flex-direction: column;
        gap: 20px;
        align-items: center;
        width: 100%;
        .team-permissions-tab {
          width: 100%;
          display: flex;
          gap: 50px;
          justify-content: center;
          padding-top: 40px;
          .labeledInputContainer {
            min-width: 200px;
            max-width: 450px;
          }
        }
        .ant-form {
          max-height: 70dvh;
          overflow-y: auto;
          overflow-x: hidden;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 20px;
          .roles-access-link {
            color: #1264a3;
            font-weight: 600;
            cursor: pointer;
            &:hover {
              color: #0f5c97;
            }
          }
        }

        .create-user-details {
          display: flex;
          flex-direction: column;
          gap: 10px;
          width: 100%;
          align-items: center;
          .create-user-form-header {
            display: flex;
            gap: 10px;
            align-items: center;
          }
          .formInputs {
            display: flex;
            width: 70%;
            overflow-y: hidden;
            overflow-y: auto;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            gap: 10px;
            .react-tel-input .form-control {
              width: 100%;
              height: 32px;
            }
            .labeledInputContainer,
            .formElement {
              width: 300px;
              height: 68px;
              .ant-form-item {
                width: 93%;
              }
            }
            .role-input-container {
              display: flex;
              align-items: center;
            }
            .role-input-container.withButton {
              .labeledInputContainer {
                width: 205px;
              }
            }
          }
          .pascodeInputs {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            .otpInput {
              display: flex;
              height: 100%;
              gap: 10px;
              .inputStyle {
                width: 59px;
                height: 73px;
                font-size: 35px;
                border: none;
                border-radius: 10px;
                .focusStyle {
                  border: 1px solid #cfd3db;
                  outline: none;
                }
                .ant-input-number-input-wrap {
                  height: 100%;
                  .ant-input-number-input {
                    text-align: center !important;
                    font-size: 30px !important;
                    width: 100% !important;
                    height: 100% !important;
                    background-color: #f5f5f7;
                    border-radius: 10px !important;
                  }
                }
              }
            }
          }
        }
      }
    }
    .ant-modal-header {
      height: 45px;
      display: flex;
      align-items: center;
      background-color: #f8f8fa;
      overflow: hidden;
      border: none;

      .ant-modal-title {
        font-size: 16px;
        width: 100%;
        font-weight: 600;
        color: #323338;
        padding: 0 !important;
        height: 45px;
        display: flex;
        align-items: center;
      }
    }
    .ant-modal-footer {
      padding-bottom: 20px !important;
      > :first-child {
        display: flex;
        justify-content: space-between;
        @media screen and (min-width: 1600px) {
          button {
            width: 150px;
          }
        }
      }
      .NewUserFooterRight {
        display: flex;
        gap: 20px;
      }
    }
  }
}
.createNewUserModalDark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      .ant-form {
        color: #fff;
        .create-user-form-header svg {
          fill: #fff;
        }
        .create-user-form-header.otp {
          svg {
            fill: #71cf48;
          }
        }

        .react-tel-input {
          .form-control {
            background-color: $inputModalDark;
            color: #fff;
            &::placeholder {
              color: #acacca;
            }
          }
          .flag-dropdown {
            background-color: $inputModalDark;
            border-radius: 5px 0px 0px 5px;
            .selected-flag {
              &:hover {
                background-color: $inputModalDark;
              }
              &:focus {
                background-color: $inputModalDark;
              }
            }
            .arrow {
              border-top: 4px solid #fff;
            }
          }
          .open {
            background-color: $inputModalDark;
            border-radius: 5px 0px 0px 5px;
            .selected-flag {
              background-color: $inputModalDark;
              .arrow {
                border-bottom: 4px solid #fff;
                border-top: 4px solid transparent;
              }
            }
          }
        }

        .labeledInputLabel {
          color: #fff;
        }
        .ant-select {
          .ant-select-selector {
            background-color: $inputModalDark !important;
            .ant-select-selection-search-input {
              color: #fff;
            }
            .ant-select-selection-item {
              color: #fff;
            }
            .ant-select-selection-placeholder {
              color: #acacca;
            }
          }
          .ant-select-clear {
            background-color: $inputModalDark;
            svg {
              fill: #acacca;
            }
          }
          .ant-select-arrow svg {
            fill: #fff;
          }
        }
        .ant-select-disabled {
          .ant-select-selector {
            .ant-select-selection-search-input {
              color: #acacca !important;
            }
            .ant-select-selection-item {
              color: #acacca !important;
            }
          }
        }
      }
    }
  }
}
