.send-email-buttton-container {


    .send-email-button {
        width: 100px;
        background-color: #1a73e8 !important;

        &:hover,
        &:focus {
            background-color: #1a73e8e5 !important;
        }

        &:disabled {
            background-color: #1a73e8ce !important;
        }

        .mondayButtonDivider {
            display: none;
        }

        @media only screen and (max-width: 767px) {
            .mondayButtonText {
                display: block;
            }
        }
    }


}

@supports (-webkit-appearance: none) {
    .undo_notification_communication .ant-notification-notice-message {
        margin-bottom: -5px !important;
    }
}

@supports (-moz-appearance:none) {
    .undo_notification_communication .ant-notification-notice-message {
        margin-bottom: 0 !important;
    }
}