.bodyCheckboxContainer {
  .checkBoxNotClickable.generalCheck {
    cursor: not-allowed !important;
    opacity: 0.62 !important;

    .ant-checkbox {
      cursor: not-allowed !important;

      > input {
        cursor: not-allowed !important;
      }
    }
  }
  .ant-checkbox-wrapper.generalCheck {
    height: 40px !important;
    display: flex;
    padding-top: 6px;

    .ant-checkbox {
      border: 5px solid #fe4c4a;
      border-radius: 2px;

      .ant-checkbox-inner {
        border-radius: 0;
        border: 2px solid #ff7274;
        background-color: #eb4345;
        height: 14px;
        width: 14px;
      }
    }

    //checked
    .ant-checkbox-checked {
      border: 5px solid #68c142;

      .ant-checkbox-inner {
        border-radius: 0;
        border: 2px solid #71cf48;
        background-color: #68c142;
        height: 14px;
        width: 14px;
        content: url("../../../../../../../../../../pages/Settings/settingsComponents/Roles/src/TickIcon.svg");
      }
    }

    //indeterminate
    .ant-checkbox-indeterminate {
      border: 5px solid #1264a3;
      border-radius: 2px;

      .ant-checkbox-inner {
        border-radius: 0;
        border: 2px solid #1c7ac2;
        background-color: #0f5c97;
        height: 14px;
        width: 14px;
        padding: 2px;
        content: "";
        &::after {
          background-color: white;
        }
      }
    }
  }

  .ant-checkbox-wrapper-disabled.generalCheck {
    filter: brightness(0.65);
    height: 40px !important;
    display: flex;

    .ant-checkbox {
      border: 5px solid #fe4c4a;
      border-radius: 2px;

      .ant-checkbox-inner {
        border-radius: 0;
        border: 2px solid #ff7274 !important;
        background-color: #eb4345;
        height: 14px;
        width: 14px;
      }
    }

    //checked
    .ant-checkbox-checked {
      border: 5px solid #68c142;

      .ant-checkbox-inner {
        border-radius: 0;
        border: 2px solid #71cf48 !important;
        background-color: #68c142;
        height: 14px;
        width: 14px;
        content: url("../../../../../../../../../../pages/Settings/settingsComponents/Roles/src/TickIcon.svg");
      }
    }

    //indeterminate
    .ant-checkbox-indeterminate {
      border: 5px solid #1264a3;
      border-radius: 2px;

      .ant-checkbox-inner {
        border-radius: 0;
        border: 2px solid #1c7ac2 !important;
        background-color: #0f5c97;
        height: 14px;
        width: 14px;
        padding: 2px;
        content: "";
        &::after {
          background-color: white;
        }
      }
    }
  }

  .checkBoxNotClickable.allChecked {
    cursor: not-allowed !important;
    opacity: 0.62 !important;

    .ant-checkbox {
      cursor: not-allowed !important;

      > input {
        cursor: not-allowed !important;
      }
    }
  }
  .ant-checkbox-wrapper.allChecked {
    height: 40px !important;
    display: flex;
    padding-top: 6px;

    .ant-checkbox {
      border: 5px solid #f2a359;
      border-radius: 2px;

      .ant-checkbox-inner {
        border-radius: 0;
        border: 2px solid #f4b67b;
        background-color: #f4b67b;
        height: 14px;
        width: 14px;
      }
    }

    //checked
    .ant-checkbox-checked {
      border: 5px solid #f2a359;

      .ant-checkbox-inner {
        border-radius: 0;
        border: 2px solid #f4b67b;
        background-color: #f4b67b;
        height: 14px;
        width: 14px;
        content: url("../../../../../../../../../../pages/Settings/settingsComponents/Roles/src/TickIcon.svg");
      }
    }

    //indeterminate
    .ant-checkbox-indeterminate {
      border: 5px solid #f2a359;
      border-radius: 2px;

      .ant-checkbox-inner {
        border-radius: 0;
        border: 2px solid #f4b67b;
        background-color: #f4b67b;
        height: 14px;
        width: 14px;
        padding: 2px;
        content: "";
        &::after {
          background-color: white;
        }
      }
    }
  }

  .ant-checkbox-wrapper-disabled.hasPrevious {
    filter: brightness(0.65);
    height: 40px !important;
    display: flex;

    .ant-checkbox {
      border: 5px solid #fe4c4a;
      border-radius: 2px;

      .ant-checkbox-inner {
        border-radius: 0;
        border: 2px solid #ff7274 !important;
        background-color: #eb4345;
        height: 14px;
        width: 14px;
      }
    }

    //checked
    .ant-checkbox-checked {
      border: 5px solid #68c142;

      .ant-checkbox-inner {
        border-radius: 0;
        border: 2px solid #71cf48 !important;
        background-color: #68c142;
        height: 14px;
        width: 14px;
        content: url("../../../../../../../../../../pages/Settings/settingsComponents/Roles/src/TickIcon.svg");
      }
    }

    //indeterminate
    .ant-checkbox-indeterminate {
      border: 5px solid #1264a3;
      border-radius: 2px;

      .ant-checkbox-inner {
        border-radius: 0;
        border: 2px solid #1c7ac2 !important;
        background-color: #0f5c97;
        height: 14px;
        width: 14px;
        padding: 2px;
        content: "";
        &::after {
          background-color: white;
        }
      }
    }
  }
}
