.actionButtonsContainerSov {
  display: flex;
  gap: 10px;
  // width: stretch;
  // position: fixed;
  justify-content: center;
  min-width: 120px;
  align-items: center;
  height: 32px;
  .removeThisRowButton {
    width: 32px !important;
    height: 32px;
    padding: 0;
    color: white;
    background-color: #fe4c4a;
    border: 0;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      fill: white;
    }
  }

  .addRowBelowThisButton {
    width: 32px !important;
    height: 32px;
    padding: 0;
    color: white;
    background-color: #68c142;
    border: 0;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10002323232;

    svg {
      fill: white;
    }
  }
}
