@import "../../../../../index.scss";

.billingBorderedTextCard {
  .copyIconDiv {
    position: absolute;
    top: 35%;
    left: 42.5%;
    cursor: pointer;
    svg:hover {
      #copyIconFile2,
      #copyIconArrow {
        fill: #71cf48;
      }
      #copyIconFile1 {
        fill: #6775c0;
      }
    }
  }
  .activeCopyIcon {
    svg {
      #copyIconFile2,
      #copyIconArrow {
        fill: #71cf48;
      }
      #copyIconFile1 {
        fill: #6775c0;
      }
    }
  }
}

.billingAndShippingAddressesContainer {
  padding-top: 15px;
  max-width: 850px;
  .streetViewIcon {
    height: 35px;
    cursor: pointer;
  }
  .shippingAddIcon {
    position: absolute;
    top: 56px;
    right: 90px;
  }

  .billingAddIcon {
    position: absolute;
    left: 295px;
    top: 56px;
  }

  .businessAddContainer {
    @include flex(flex-start, flex-end);
  }
  .splitAddContainer {
    // width: 100%;
    @include flex(flex-start, flex-start);
    flex-wrap: wrap;
  }

  .addressCheckbox {
    position: absolute;
    top: 0;
    right: 0;
  }

  .labeledInputContainer {
    margin-right: 15px;

    .labeledInputLabel {
      @include text(14px, 600, 0.21px, #383554);
      margin-bottom: 2px;
    }
  }
}

@media (max-width: 450px) {
  .billingAndShippingAddressesContainer .businessAddContainer {
    display: grid !important;
  }
}
