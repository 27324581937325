$headerDarkColor: #12131b;
$inputDarkColor: #2a2b3a;
$mainDarkBackgroundColor: #20212d;

.CardViewComponent {
  flex-grow: 1;
  flex-basis: 0;
  height: fit-content;
  max-height: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  @keyframes wobbleee {
    0% {
      transform: translateY(-30px);
    }
    100% {
      transform: translateY(0px);
    }
  }
  animation: wobbleee 0.4s;
  // padding: 20px 10px 0 30px;
  margin: 20px 10px 20px 10px;
  transition: max-height 0.8s linear;
  overflow: hidden;
  box-shadow: 0px 1px 4px #00000029;
  &.active {
    animation: scale-display 0.3s;
    display: inline-flex;
  }

  &.out {
    animation: scale-display--reversed 0.8s;
    animation-fill-mode: forwards;
    display: inline-flex;
  }

  &.blur {
    animation: blurAnimation 0.5s;
    filter: blur(7px);
    pointer-events: none;
    svg {
      height: 32px !important;
    }
  }

  &.border {
    animation: borderAnimation 0.5s;
    border: 1px solid grey;
  }
}
.CardViewComponentCollapsed {
  @extend .CardViewComponent;
  background: #f7f8fa 0% 0% no-repeat padding-box;
  max-height: 45px;
  transition: max-height 0.5s linear;
}
.CardViewComponentBody {
  opacity: 1;
  transition: opacity 0.8s;
  padding: 0px 10px 0 20px;
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  @media only screen and (max-width: 413px) {
    ::-webkit-scrollbar {
      display: none;
    }
  }

  /* Track */

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  // /* Handle */

  ::-webkit-scrollbar-thumb {
    background: #1f2a44;
    border-radius: 5px;
    &:hover {
      background-color: #35415e;
    }
  }
}
.CardViewComponentBodyCollapsed {
  opacity: 0;
  transition: opacity 0.5s;
  padding: 0px 10px 0 30px;
}
.CardViewComponentHeader {
  display: flex;
  height: 45px;
  background: #f7f8fa 0% 0% no-repeat padding-box;
  z-index: 2;
  padding: 0 10px 0 20px;
  align-items: center;

  .CardViewComponentHeaderTitle {
    cursor: pointer;
    // min-width: calc(100% - 200px);
    width: calc(100% - 35px);
    font: normal normal 600 16px Open Sans;
    letter-spacing: 0px;
    color: #323338;
    opacity: 1;
  }
  .CardViewComponentHeaderActions {
    cursor: pointer;
    min-width: 35px;
    height: 35px;
    margin-left: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      height: 16px;
      width: 16px;
    }

    // .ant-popover-open {
    // }
  }
  .CardViewComponentHeaderActions:hover {
    @extend .CardViewComponentHeaderActions;
    background-color: #32333821;
    border-radius: 5px !important;
    fill: #323338;
  }
  .CardViewComponentHeaderActions:active {
    @extend .CardViewComponentHeaderActions;
    background-color: #323338;
    fill: white;
  }
}
.CardViewComponentHeaderActionWindow {
  width: 140px;
  position: relative !important;
  .ant-popover-arrow {
    display: none !important;
  }
  .ant-popover-inner {
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 5px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    overflow: hidden;
  }
  .ant-popover-inner-content {
    padding: 0 !important;
    width: 100%;
    z-index: 3;
    .ActionContent {
      padding: 0;
      width: 100%;
      .ActionElement {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 6.5px;
        padding-bottom: 6.5px;
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 100%;
        height: 28px;
        &:not(:last-child) {
          border-bottom: 1px solid #efefef;
        }
        &:hover {
          background: #f1f5fd;
          color: #1264a3;
        }
      }

      .ActionElement[keyword="Remove"]:hover {
        color: #fe4c4a;
      }
    }
  }
}
.CardViewComponentHeaderActionWindowDark {
  .ant-popover-inner {
    background-color: $inputDarkColor;
  }
  .ant-popover-inner-content .ActionContent {
    .ActionElement {
      color: #f2f2f2;
      &:not(:last-child) {
        border-bottom: 1px solid $headerDarkColor;
      }
      &:hover {
        background-color: $headerDarkColor;
      }
    }
  }
}
.footerCardView {
  display: flex;
  justify-content: center;
  .footerCardViewIcon {
    margin-bottom: -15px;
    margin-left: -60px;
  }
}

.CardViewHeaderLabel {
  justify-content: flex-end;
  display: flex;
  align-items: center;
  .headerlabel {
    margin-top: 3px;
    margin-right: 10px;
    width: inherit;
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font: normal normal 600 16px Open Sans;
    letter-spacing: 0px;
    color: #323338;
    opacity: 1;
  }
  .headerIcon {
    margin-right: 10px;
    margin-top: 5px;
    cursor: pointer;
  }
}

.CardViewComponentDark {
  background-color: $headerDarkColor;
  .CardViewComponentHeader {
    background-color: $inputDarkColor;
    border-radius: 10px 10px 0px 0px;
    .CardViewComponentHeaderTitle {
      color: #f2f2f2;
      .ant-tag {
        background-color: #373c4b;
        border: none;
        color: #f2f2f2;
        margin-left: 20px !important;
        // color: #1264a3;
      }
    }
    .CardViewHeaderLabel {
      .headerlabel {
        color: #f2f2f2;
      }
      .headerIcon svg path {
        fill: #f2f2f2;
      }
    }
    .CardViewComponentHeaderActions {
      svg path {
        fill: #f2f2f2;
      }
    }
    .CardViewComponentHeaderActions:hover {
      background-color: $headerDarkColor;
    }
  }
  .CardViewComponentBody {
    .ant-result-warning {
      .ant-result-title,
      .ant-result-subtitle {
        color: #fff;
      }
    }
    .InformationBody {
      .InforamtionBodyElementContainer {
        .InformationBodyElementContainerTitle {
          color: #f2f2f2;
        }
        .InforamtionBodyElementContainerContent {
          color: #acacca;
        }
      }
      .configElementContainer {
        background-color: $inputDarkColor;
        .configElementTitle {
          color: #f2f2f2;
        }
      }
    }
    .smallContainer {
      .labelStyle {
        color: #f2f2f2;
      }
    }
    .UploadCard {
      .descriptionValue {
        color: #f2f2f2;
      }
    }
    .membersContent-container {
      .member-content {
        .groupName {
          color: #fff;
        }
      }
    }
    .membersContent {
      .columnContentContainer {
        .roleStyle {
          color: #fff;
        }
      }
    }
    .DOBCard {
      // .InforamtionBodyElementContainer {
      .InformationBodyElementContainerTitle {
        color: #fff;
      }
      .InforamtionBodyElementContainerContent {
        color: #acacca;
      }
    }
    // }
    .scrollBottomContainer svg path,
    .scrollTopContainer svg path {
      fill: #fff;
    }
    .progressBarContainer {
      .ant-progress-inner {
        background-color: $inputDarkColor;
      }
      .ant-progress-text {
        color: #fff;
      }
    }
  }
}

.editCardTitlePopover {
  .ant-popover-content {
    .ant-popover-inner {
      border-radius: 5px;
      .ant-input {
        height: 32px;
        border: none;
      }
    }
  }
}

.editCardTitlePopoverDark {
  .ant-popover-arrow::before {
    background-color: $inputDarkColor;
  }
  .ant-popover-content {
    .ant-popover-inner {
      // border-radius: 5px;
      background-color: $inputDarkColor;
      label {
        color: #f2f2f2;
      }
      .ant-input {
        background-color: $headerDarkColor !important;
        // border: none;
        color: #f2f2f2;
        &::placeholder {
          color {
            color: #acacca;
          }
        }
      }
    }
  }
}

@keyframes scale-display {
  0% {
    opacity: 0;
    transform: translateX(2000px);
  }
  60% {
    opacity: 1;
    transform: translateX(-30px);
  }
  80% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes scale-display--reversed {
  0% {
    transform: translateX(0);
  }
  20% {
    opacity: 1;
    transform: translateX(-20px);
  }
  100% {
    opacity: 0;
    transform: translateX(2000px);
  }
}

@keyframes blurAnimation {
  0% {
    filter: blur(0px);
  }

  100% {
    filter: blur(7px);
  }
}

@keyframes unBlurAnimation {
  0% {
    filter: blur(7px);
  }

  100% {
    filter: blur(0px);
  }
}

@keyframes borderAnimation {
  0% {
    border: 1px solid inherit;
  }

  100% {
    border: 1px solid grey;
  }
}

@media (max-width: 460px) {
  .userLinkCardNotLinkedContainer
    .buttonContainer
    .mondayButtonContainer
    .mondayButtonText {
    display: none;
  }
  .userLinkCardNotLinkedContainer
    .buttonContainer
    .mondayButtonContainer
    .mondayButtonDivider {
    display: none;
  }
}
