.communication-content-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 24px;
  border-bottom: 1px solid #dad9d975;

  &.mobile {
    padding: 8px;

    .communication-content-header-left {
      gap: 0px;
    }
  }

  .refresh-button {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .communication-content-header-left {
    display: flex;
    align-items: center;
    gap: 15px;

    .communication-content-header-left-buttons {
      display: flex;
      align-items: center;

      .more-actions-not-selected-info-text {
        margin: 0;
        padding: 12px;
        font-style: italic;
        color: #707070;
      }

      .refresh-button {
        background-color: #1264a3;
      }
    }

    .select-emails {
      height: 32px;
    }
  }

  .communication-content-header-left-checkbox {
    margin-right: 14px;
  }

  .three-dots-icon {
    display: flex;
    align-items: center;
  }

  .communication-content-header-left-checkbox {
    background-color: #1264a3;
    padding: 2.5px 5px;

    .ant-checkbox-inner {
      border-radius: 0 !important;
      border-color: #3387c7;
      background-color: #0f5c97;
    }

    /* Apply background color on hover */
    &:hover .ant-checkbox-inner {
      border-color: #3387c7;
    }
  }

  .communication-content-header-left-checkbox:hover
    .ant-checkbox-input:checked
    + .ant-checkbox-inner {
    background-color: #0f5c97;
    border-color: #3387c7;
  }

  &.dark-mode {
    .communication-content-header-left .select-emails {
      .ant-form-item {
        border: 1px solid #acacac;
        border-radius: 4px;
      }
    }

    .communication-content-header-left-buttons {
      .more-actions-not-selected-info-text {
        color: #fff;
      }
    }
  }
}
