.virtual-assistant-group {
  inset-inline-end: 44px;
  inset-block-end: 20px;
  width: fit-content;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  flex-wrap: nowrap;

  .ant-float-btn-group-wrap {
    width: max-content;
  }

  .ant-float-btn-square {
    background-color: transparent;
  }

  .ant-float-btn-body {
    background-color: #231f20;

    &:hover {
      background-color: #231f20;

      .ant-float-btn-icon {
        transform: rotate(15deg);
      }
    }
  }

  .ant-float-btn-icon {
    transition: transform 0.15s ease-in-out;
  }

  .ant-float-btn .ant-float-btn-body .ant-float-btn-content {
    width: 100%;
    .ant-float-btn-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;

      svg,
      path {
        fill: #f4f5f6;
      }
    }
  }
}

.virtual-assistant-group.virtual-assistant-group-dark {
  .ant-float-btn-body {
    background-color: #4c5569;

    &:hover {
      background-color: #4c5569;
    }
  }
}
