@import "../../../../../index.scss";

$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.DifferenceModalContainer {
  position: absolute;
  top: 0;
  left: 10vw;
  right: 10vw;
  display: flex;
  flex-direction: column;
  min-height: 500px;
  height: fit-content;
  width: 80vw;
  margin: auto;
  z-index: 1003;
  bottom: 0;
  justify-self: center;
  align-self: center;
  position: absolute;
  background-color: white;
  border-radius: 10px;
  .DifferenceModalHeader {
    height: 45px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px 10px 0px 0px;
    background-color: #f5f7fa;
    .DifferenceModalTitle {
      font-size: 16px;
      font-weight: 600;
      margin-left: 20px;
    }
    .DifferenceModalCloseIcon {
      height: 45px;
      width: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        fill: #323338;
        height: 17px;
        width: 17px;
      }
    }
  }
  .DifferenceModal {
    width: 100%;
    height: 540px;
    display: flex;
    overflow-y: auto;
    overflow-x: hidden;
    .differenceModalContent {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      display: flex;
      flex: 0.5;
      > div {
        width: 100%;
      }
      .CardViewComponent {
        flex-grow: 1;
        flex-basis: 0;
        height: fit-content;
        max-height: 840px;
        width: 98% !important;
        // max-height: 100%;
        background: #ffffff 0% 0% no-repeat padding-box;
        border-radius: 10px;
        opacity: 1;
        display: flex;
        flex-direction: column;
        @keyframes wobbleee {
          0% {
            transform: translateY(-30px);
          }
          100% {
            transform: translateY(0px);
          }
        }
        animation: wobbleee 0.4s;
        margin: 20px auto;
        transition: max-height 0.8s linear;
        overflow: auto;
        box-shadow: 0px 1px 4px #00000029;
        .CardViewComponentHeaderActions {
          display: none;
        }
        .CardViewComponentHeader {
          pointer-events: none;
          border-radius: 10px 10px 0px 0px;
          .CardViewHeaderLabel {
            pointer-events: visible;
          }
        }
      }
      .differenceModalContentTitle {
        margin-top: 10px;
        font: normal normal bold 24px Open Sans;
        letter-spacing: 0px;
        color: #323338;
        opacity: 1;
      }
    }
  }
  .ButtonContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 55px;
    .actionButton {
      @include mondayButton(#f5f5f7, #e6e9ef, #000);
      margin-right: 10px;
      .mondayButtonIcon {
        fill: #000;
        z-index: 2;
        margin: 0;
        font-size: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: unset;
      }
    }
  }
}

.DifferenceModalContainerDark {
  background-color: $cardModalBodyDark;
  .DifferenceModalHeader {
    background-color: $cardModalHeaderDark;
    .DifferenceModalTitle {
      color: #fff;
    }
    .DifferenceModalCloseIcon svg {
      fill: #fff;
    }
  }
  .DifferenceModal {
    .differenceModalContent {
      .differenceModalContentTitle {
        color: #fff;
      }
      .CardViewComponentDark {
        background-color: $cardModalHeaderDark;
      }
    }
  }
}

@media (max-width: 1180px) {
  .DifferenceModalContainer {
    left: 5vw;
    right: 5vw;
    width: 90vw;
    .DifferenceModal {
      flex-direction: column;
    }
  }
}

@media (max-width: 1050px) {
}
