.invoceDataRCont {
    grid-area: auto;
    width: 95%;
    height: auto;
    box-sizing: border-box;
    justify-self: center;
    padding: 20px 30px;
    border-radius: 5px;
    margin: 50px 0;
    align-self: start;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto 50px;
    align-items: start;
}

.invoceDataRCont .previewBtnCont {
    grid-area: 2/1/3/-1;
    width: 100%;
    height: 100%;
    padding: 20px 0;
    width: auto;
    height: min-content;
    display: flex;
    justify-content: center;
    align-items: center;
}

.invoceDataRCont .previewBtnCont .previewBtn {
    width: fit-content;
    height: fit-content;
    background-color: #1264A3;
    align-self: center;
    padding: 5px 20px;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.4196078431);
    box-shadow: 0 2px 7px rgba(0, 0, 0, 0.4823529412);
    font-size: 1rem;
    font-weight: 400;
    font-style: normal;
    color: #000000;
    text-align: center;
    text-decoration: none;
    color: white;
    text-align: center;
    cursor: pointer;
}

@media (min-width: 481px) and (max-width: 767px) {
    .invoceDataRCont .previewBtnCont .previewBtn {
        font-size: 1rem;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .invoceDataRCont .previewBtnCont .previewBtn {
        font-size: 1.1rem;
    }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .invoceDataRCont .previewBtnCont .previewBtn {
        font-size: 1.1rem;
    }
}

@media (min-width: 1025px) and (max-width: 1280px) {
    .invoceDataRCont .previewBtnCont .previewBtn {
        font-size: 1.1rem;
    }
}

@media (min-width: 1281px) {
    .invoceDataRCont .previewBtnCont .previewBtn {
        font-size: 1.2rem;
    }
}

.invoceDataRCont .previewBtnCont .previewBtn:hover {
    color: auto;
}

@media (min-width: 1025px) and (max-width: 1280px) {
    .invoceDataRCont .previewBtnCont .previewBtn:hover {
        color: #00c3ff;
    }
}

@media (min-width: 1281px) {
    .invoceDataRCont .previewBtnCont .previewBtn:hover {
        color: #00c3ff;
    }
}

.invoceDataRCont .emptyMsg {
    grid-area: auto;
    width: 100%;
    height: auto;
    align-self: center;
    font-size: 1.6rem;
    font-weight: 600;
    font-style: normal;
    color: #000000;
}

@media (min-width: 481px) and (max-width: 767px) {
    .invoceDataRCont .emptyMsg {
        font-size: 1.6rem;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .invoceDataRCont .emptyMsg {
        font-size: 1.7rem;
    }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .invoceDataRCont .emptyMsg {
        font-size: 1.7rem;
    }
}

@media (min-width: 1025px) and (max-width: 1280px) {
    .invoceDataRCont .emptyMsg {
        font-size: 1.7rem;
    }
}

@media (min-width: 1281px) {
    .invoceDataRCont .emptyMsg {
        font-size: 1.8rem;
    }
}