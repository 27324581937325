@import "../../../../../../../../../index.scss";

.hoist-config-card {
  border-radius: 10px;

  .header {
    @include flex(center, space-between);
    @include text(16px, 600, 0, #323338);

    padding: 6.5px 20px;
    background-color: #f7f8fa;
    border-radius: 10px 10px 0 0;
    width: stretch;

    .title-section {
      flex: 1;
    }

    .actions-section {
      @include flex(center, center);
      gap: 10px;
    }

    .action-button {
      @include flex(center, center);
      width: 32px;
      height: 32px;
      border-radius: 5px;

      svg {
        fill: #fff;
      }
    }

    .blue {
      @include backgroundColor(#1264a3, #0f5c97, pointer);
    }

    .red {
      @include backgroundColor(#fe4c4a, #eb4345, pointer);
    }
  }

  .content {
    width: stretch;
    padding: 20px;
    background-color: #f4f5f6;
    border-radius: 0 0 10px 10px;

    .metadata {
      display: grid;
      grid-template-columns: 16px 1fr;
      align-items: center;
      column-gap: 10px;
      row-gap: 5px;
      svg {
        width: 18px;
      }
    }
  }
}
