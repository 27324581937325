@use "~ag-grid-enterprise/styles" as ag;
@include ag.grid-styles(
  (
    theme: alpine,
  )
);
@import "../../../../../../index.scss";

.newDispatchModalContainer .ag-theme-custom-react .ag-root-wrapper {
  overflow: auto !important;
}
.ant-modal-wrap.normalSizedModal.newDispatchModalContainer.ant-modal-centered {
  .ant-modal-header {
    padding: 11px !important;
  }
  .footerContainer {
    display: flex;
    justify-content: space-between;
    .cancelFooterButton {
      background: #fe4c4a !important;
      color: #fff !important;
      border: 0px;
      width: 150px;
      height: 32px;
    }
  }
}
.newDispatchModalContainer {
  .mainContainer {
    height: calc(100vh - 160px);
    @include flex(baseline, space-between);
    padding: 5px 0;
    flex-direction: column !important;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .background-grid-modal {
    width: 100%;
    margin-top: 10px;
    box-shadow: 0px 1px 4px #00000029;
    border-radius: 5px;
  }
  .contentContainer {
    @include flex(flex-start, flex-start, column);
    min-width: fit-content;
    padding: 20px;
    padding-right: 0;
    width: 100%;
    &:first-child {
      padding: 0px !important;
      border-top: 1.5px solid rgba(0, 0, 0, 0.4);
    }

    .ag-root-wrapper {
      height: 100%;
      width: 100%;
      max-width: 100%;

      .ag-root-wrapper-body {
        height: 100% !important;
      }

      .ag-header {
        position: sticky;
        top: 0;

        .ag-header-cell {
          background-color: #f8f8fa !important;
        }
      }
    }
  }
  .routeDuration {
    min-width: 85px;
    .ant-input {
      width: 85px;
    }
  }
  .overtimeWarningIcon {
    height: 22px;
    position: absolute;
    top: 134px;
    left: 425px;
  }

  .addRouteOnIndexArrow {
    width: 100%;
    min-height: calc(13px + 1.3rem) !important;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 1rem;

    span {
      background-color: #fff;
      transition: background-color 0.3s ease-in-out;
      padding: 0.3rem 0.6rem;
      border-radius: 5px;
      cursor: pointer;
      color: white;
      font-weight: 400;
      font-size: 14px;
    }
    p {
      color: #fff;
      transition: color 0.3s ease-in-out;
      margin: 0;
    }

    .addRouteButton {
      background-color: #71cf48;
    }

    .addRouteButtonHoist {
      background-color: #529934;
    }

    .addRouteFromRequestButton {
      background-color: #fc7300;
    }
  }
  .with-warning:hover {
    min-height: calc(36px + 1.3rem) !important;
  }
  .no-warning:hover {
    min-height: calc(13px + 1.3rem) !important;
  }

  .selectFilterContainer {
    border-right: 0;
    margin-right: 0;
  }
  .show-table-div {
    height: 50px;
    display: flex;
    align-items: flex-end;
  }
  .hideTable {
    width: 120px;
    background: #1f2a44;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    margin-right: 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  .hideTable:hover {
    opacity: 0.8;
  }
  .suggestedDepartingTime {
    position: absolute;
    left: 95px;
    color: red;
    cursor: pointer;
  }
  .inputContainer {
    @include flex(baseline, space-between);
    width: 100%;
  }
  .inputContainer:last-child {
    @include flex(baseline, flex-start);
  }
  .inputContainer:first-child {
    @include flex(baseline, flex-start);
    height: 60px;
  }
  .clearFilterBtn {
    margin-bottom: 43px;
  }
  .ant-modal-body {
    height: fit-content;
    width: fit-content;
    padding: 0 30px 62px;
  }
  .labeledInputContainer {
    margin-right: 15px;
    .labeledInputLabel {
      margin-bottom: 2px;
      @include text(14px, 600, 0.21px, #383554);
    }
  }
  .labeledInputContainer:last-child {
    padding-right: 0;
  }
  .select-wraper {
    display: flex !important;

    column-gap: 30px;
    .selectFilterContainer {
      .labeledInputContainer:last-child {
        display: flex !important;
        width: 100% !important;
        flex-direction: row;
        gap: 1rem;
        .ant-form-item {
          width: 100%;
        }
        .labeledInputLabel {
          margin-right: 10px;
        }
      }
    }
  }
  .button-div {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    height: 100%;
    button.ant-btn.ant-btn-default.clearFilterBtn-modal {
      background: #fe4c4a;
      border-radius: 5px;
      color: #fff;
      border: 0px;
    }
  }
  .header-items {
    width: 100% !important;
    .labeledInputContainer {
      display: flex;
      align-items: center;
      .labeledInputLabel {
        margin-right: 10px !important;
      }
      .ant-form-item {
        margin: 0px !important;
        width: 100% !important;
      }
    }
  }
  .live-btn {
    background: #ff4d4f;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
    height: 32px;
    width: 120px;
    display: flex;
    align-items: center;
    margin-right: 20px;
    .liveContent {
      font: italic normal 800 21px/15px Open Sans;
      width: 90px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-right: 1px solid #eb4345;
      height: 100%;
    }
  }
  .live-btn:hover {
    opacity: 0.8;
  }
  .cardTitle {
    width: fit-content;
    height: 32px;
    background: #1f2a44;
    border-radius: 5px;
    color: #fff;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    align-items: center;
    margin-right: 20px;
    position: relative;
    top: 28px;
    font: normal normal 600 16px/22px Open Sans;
    text-transform: uppercase;
  }
  .filtersCard {
    @include flex(flex-start, flex-start, column);
    border-radius: 2px;
    min-width: 100%;
    padding: 10px 40px 0px 40px;
    background-color: rgba(0, 0, 0, 0.14);
  }
  .editButton {
    position: absolute;
    right: 35px;
    top: 5px;
    padding: 0 15px;
  }
  .mapsLogo {
    position: relative;
    height: 36px;
    align-self: center;
    top: -7px;
    cursor: pointer;
  }
  .routeCard {
    position: relative;
    border-radius: 2px;
    margin-bottom: 10px;
    min-width: 100%;
    background: #fff;
    border-radius: 5px;
    padding: 11px 20px 11px 11px;
    // height: 170px;
    // div:first-of-type {
    //   .labeledInputContainer {
    //     margin-top: -24px;
    //   }
    // }
  }
  .routeCard:last-child {
    margin-bottom: 10px !important;
    // height: 180px;
  }
  .routeCardsContainer-background {
    background: #f8f8fa;
    box-shadow: 0px 1px 4px #00000029;
    border-radius: 5px;
    padding: 10px;
    overflow: auto;
  }
  .routeCardsContainer {
    // overflow-y: auto;
    // padding-top: 6px;
    // padding-right: 6px;
    height: 380px;
  }
  .routeCardsContainerHeight {
    height: 710px;
  }
  .buttonContainer {
    @include flex(center, flex-end);
    width: 100%;
    .ant-btn {
      margin-right: 15px;
    }
    .ant-btn:first-child {
      background-color: #71cf48;
      color: #fff;
    }
    .ant-btn:last-child {
      margin-right: 0;
    }
  }
  .closeIcon {
    position: absolute;
    top: 5px;
    right: 5px;
    opacity: 0.6;
    cursor: pointer;
  }
  .iconContainer {
    position: relative;
    width: 25px;
    margin-right: 15px;
    align-self: center;
    // top: -7px;
  }
  .contentFlexStart {
    justify-content: flex-start;
  }
  .ant-radio-button-wrapper {
    font-weight: 400;
  }
  .ant-picker {
    width: 100%;
    font-weight: 400;
  }
  .ant-input {
    font-weight: 400;
  }
  .ant-select-selector {
    font-weight: 400;
  }
  .ant-table-thead > tr > th {
    background-color: #fff !important;
    @include text(14px, 700, 0.21px, #383554);
    border-bottom: 2px solid #dbdbdb;
  }
  .ag-theme-custom-react {
    .ag-theme-alpine {
      --ag-odd-row-background-color: #f5f5f5;
      --ag-row-border-color: transparent;
      --ag-row-hover-color: null;
      // --ag-checkbox-checked-color: #2661ad;
      --ag-range-selection-border-color: #ff00b1;
      --ag-range-selection-background-color: #03305633;
      --ag-header-height: 100px;
    }
    .ag-header-cell-label {
      justify-content: space-between;
    }
    .ag-header-cell-text {
      @include text(16px, 600, 0.21px, #383554);
    }
    .ag-header-cell {
      background-color: #fff;
    }
    .ag-root-wrapper {
      border: 0;
    }
    .ag-menu-option-active {
      background-color: #2661ad;
      color: white;
    }
    .ag-row .ag-cell {
      border-right: 0 !important;
      background-color: #fff;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
    }
  }
}
.fleetDispatchingPopover {
  z-index: 1500 !important;
}
.popoverContent {
  .ant-btn {
    background-color: #09a734;
    color: #fff;
  }
}
.trafficModelOptions {
  .ant-radio-wrapper {
    font-weight: 400;
  }
}
.labeledInputContainer.returnRoute-pickTime {
  .ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
    padding-top: 4px;
    height: 201px;
  }
  .ant-picker-time-panel .ant-picker-content {
    display: flex;
    flex: auto;
    height: 195px;
  }
}

@media (max-width: 1750px) {
  .newDispatchModalContainer {
    .inputContainer:first-child {
      .buttonContainer {
        margin-left: 0px !important;
      }
    }
    .routeCardsContainer {
      .routeCard {
        .inputContainer:first-child {
          height: 100%;
        }
        .inputContainer {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          .labeledInputContainer {
            min-width: 200px !important;
          }
          .labeledInputContainer:last-child {
            min-width: auto !important;
          }
        }
      }
    }
    .filtersCard .inputContainer {
      height: fit-content;
      flex-direction: column-reverse;
      align-items: center !important;
      margin-top: 10px;
    }
  }
}

@media (max-width: 1440px) {
  .newDispatchModalContainer {
    .inputContainer:first-child {
      height: fit-content;
      flex-wrap: wrap;
      margin-bottom: 10px;
      .buttonContainer {
        margin-top: 10px;
      }
    }
    .filtersCard .inputContainer {
      .select-wraper {
        display: grid !important;
        grid-template-columns: 50% 50%;
      }
    }
  }
}

@media (max-width: 850px) {
  .newDispatchModalContainer {
    .header-items .inputContainer:first-child {
      .labeledInputContainer {
        margin-top: 10px;
      }
    }
    .routeCardsContainer {
      .routeCard {
        width: 100% !important;
      }
    }
    .filtersCard {
      padding: 0px 5px;
    }
  }
}

@media (max-width: 768px) {
  .newDispatchModalContainer {
    padding: 10px 0px !important;
  }
}

@media (max-width: 700px) {
  .newDispatchModalContainer {
    .header-items .inputContainer:first-child {
      display: grid;
      grid-template-columns: 33.3% 33.3% 33.3%;
    }
    .contentContainer {
      align-items: center;
      .filtersCard .inputContainer .select-wraper {
        grid-template-columns: 100%;
      }
    }
  }
}

@media (max-width: 500px) {
  .newDispatchModalContainer {
    .ant-modal-body {
      padding: 0 10px 62px;
    }
    .header-items .inputContainer:first-child {
      grid-template-columns: 50% 50%;
    }
    .routeCardsContainer {
      .routeCard {
        .inputContainer {
          display: grid;
          grid-template-columns: 100%;
          .cardTitle {
            top: 0px;
            margin-bottom: 5px;
          }
          .labeledInputContainer {
            max-height: 60px;
            margin-bottom: 10px;
          }
          .labeledInputContainer:last-child {
            min-width: auto !important;
          }
        }
      }
    }
  }
}

// @media (max-width: 450px) {
//   .newDispatchModalContainer .routeCard {
//     max-width: 100% !important;
//     width: 100% !important;
//   }
//   .newDispatchModalContainer .ant-modal-body {
//     width: 100% !important;
//   }
//   .newDispatchModalContainer .inputContainer:first-child {
//     display: grid !important;
//     grid-template-columns: auto auto;
//     height: 130px !important;
//   }
//   .newDispatchModalContainer .routeCard {
//     width: 100% !important;
//     min-width: 100% !important;
//   }
//   .newDispatchModalContainer .inputContainer {
//     display: grid !important;
//   }
//   .newDispatchModalContainer .contentContainer {
//     display: grid !important;
//     width: 100% !important;
//   }
// }
