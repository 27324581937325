$mainDarkBackgroundColor: #20212d;
$whiteColor: #ffffff;
$blueColor: #1264a3;
$colorDark: #323338;
$shadowColor: gray;
$lightColor: #f2f2f2;

.dynamic-tabs {
  .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane {
    padding: 10px;
    overflow-y: auto;
    .dynamicBasePage {
      padding: 0;
    }
  }
  .ant-tabs-nav {
    margin: 0;
    .ant-tabs-nav-wrap {
      background: $whiteColor !important;
      box-shadow: 0 3px 3px -3px $shadowColor;
      .ant-tabs-nav-list {
        > :nth-child(1) {
          animation: tabs 1s;
          padding-left: 30px;
        }
        > :nth-child(2) {
          animation: tabs 1.3s;
        }
        > :nth-child(3) {
          animation: tabs 1.6s;
        }
        > :nth-child(4) {
          animation: tabs 1.9s;
        }
        .ant-tabs-tab {
          &:hover {
            opacity: 0.5;
          }
          .ant-tabs-tab-btn {
            font-weight: 600;
            font-size: 18px;
            color: $colorDark;
          }
        }
        .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
          color: $blueColor;
        }
        .ant-tabs-ink-bar {
          background: $blueColor;
          height: 3px;
          border-radius: 5px;
        }
      }
    }
  }
}

.dynamic-tabs-dark {
  @extend .dynamic-tabs;
  .ant-tabs-nav .ant-tabs-nav-wrap {
    background-color: $mainDarkBackgroundColor !important;
    .ant-tabs-nav-list {
      .ant-tabs-tab .ant-tabs-tab-btn {
        color: $lightColor;
      }
      .ant-tabs-ink-bar {
        background: $lightColor;
      }
    }
  }
}

@keyframes tabs {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
