@mixin divide($color) {
  position: absolute;
  top: -11px;
  z-index: 2;
  left: 88px;
  font-size: 29px;
  color: $color;
  font-weight: 50;
}
@mixin currentTextStyle($align) {
  text-align: $align;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}
@mixin roleImage() {
  position: absolute;
  top: -2px;
  z-index: 2;
  left: 88px;
  width: 35px;
  height: 35px;
  font-size: 41px;
}

.styleInput {
  .AddRoleButton {
    width: 121px;
    height: 32px;
    background-color: #01c06a;
    border-radius: 5px;
    .AddRoleButtonText {
      @include currentTextStyle(left);
      font: normal normal normal 14px/19px Open Sans;
      margin-left: -20px;
    }
    .AddRoleButtonDivider {
      @include divide(#01ab5e);
    }

    .AddRoleButtonImage {
      @include roleImage();
    }
  }
  .AddRoleButton:hover {
    background-color: #01ab5e;
    .AddRoleButtonDivider {
      @include divide(#01c06a);
    }
  }
}
