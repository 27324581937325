.pagePDF{
  background-color: white;
}

.logoCont {
  grid-area: auto;
  width: 200px;
  height: 20px;
}
.logoCont img {
  width: 200px;
  height: 20px;
}

.invoicePaperCont {
  grid-area: auto;
  width: 100%;
  padding: 40px 0px;
  height: auto;
  min-height: 50vh;
  justify-self: center;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto;
  justify-content: center;
  align-content: start;
  gap: 0;
}
@media (min-width: 481px) and (max-width: 767px) {
  .invoicePaperCont {
    width: 90%;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .invoicePaperCont {
    width: 80%;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .invoicePaperCont {
    width: 70%;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .invoicePaperCont {
    width: 50%;
  }
}
@media (min-width: 1281px) {
  .invoicePaperCont {
    width: 50%;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .invoicePaperCont {
    padding: nul;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .invoicePaperCont {
    padding: 30px 10px;
  }
}
@media (min-width: 1281px) {
  .invoicePaperCont {
    padding: 30px 10px;
  }
}

.invoicePaperContPDF {
  grid-area: auto;
  width: 0px;
  height: 0px;
  overflow: hidden;
  justify-self: center;
  background-color: transparent;
  justify-self: center;
  align-self: start;
  display: grid;
  grid-template-columns: 90%;
  grid-template-rows: auto;
  align-items: start;
}