.add-notes-for-service-modal {
  .ant-modal-content {
    border-radius: 10px;
    overflow: hidden;
    .ant-modal-header {
      height: 45px;
      background-color: #f8f8fa;
      align-items: center;
      color: black;
      display: flex;

      .ant-modal-title {
        font: normal normal 600 16px/39px Open Sans;
      }
      .ant-modal-footer {
        border-radius: 0 0 15px 15px;
        border-top: none;
        background: #1264a39e;
        .ant-btn {
          border-radius: 5px;
          background: #2196f3;
          color: #fff;
          border: none;
          &:hover {
            background: #2196f3;
            color: #fff;
          }
        }
      }
    }
  }
}

.add-notes-for-service-modal__dark {
  .ant-modal-content {
    border-radius: 10px;
    overflow: hidden;
    .ant-modal-header {
      height: 45px;
      align-items: center;
      background: #1c1c1c;
      align-items: center;
      color: black;
      display: flex;
      color: white;

      .ant-modal-title {
        font: normal normal 600 16px/39px Open Sans;
        color: white;
      }
      .ant-modal-close-x {
        justify-content: center;
        align-items: center;
        display: flex;
        height: 100%;
        width: 45px;
        padding: 0;
        color: white;
      }

      .ant-modal-footer {
        border-radius: 0 0 15px 15px;
        border-top: none;
        background: #1264a39e;
        .ant-btn {
          border-radius: 5px;
          background: #2196f3;
          color: #fff;
          border: none;
          &:hover {
            background: #2196f3;
            color: #fff;
          }
        }
      }
    }
  }
}
