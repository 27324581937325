.custom-otp.success {
  .ant-input-number.inputStyle
    .ant-input-number-input-wrap
    .ant-input-number-input {
    background-color: #00a46480 !important;
    color: #fff !important;
  }
}
.custom-otp.fail {
  .ant-input-number.inputStyle
    .ant-input-number-input-wrap
    .ant-input-number-input {
    background-color: #ff7274 !important;
    color: #fff !important;
  }
}
