.select-phase-wrapper {
  .ant-select-single.ant-select-open .ant-select-selection-item {
    color: white !important;
  }
  border: none !important;

  .select-phase-dropdown {
    width: 100%;
    margin-bottom: 10px;

    &:active {
      color: #f5f5dc !important;
    }

    .ant-select-selector {
      background-color: #1264a3 !important;
      color: #f5f5dc !important;
      border: none !important;
      .ant-select-selection-search {
        .ant-select-selection-search-input {
          color: #f5f5dc !important;
        }
      }
      .ant-select-selection-placeholder {
        color: #f5f5dc !important;
      }
    }
  }
  .ant-select-arrow {
    color: #f5f5dc !important;
    fill: #f5f5dc !important;
    svg {
      color: #f5f5dc !important;
      fill: #f5f5dc !important;
    }
  }
}
