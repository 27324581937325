$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;

.schedule-dispatch-container {
  height: 70vw;
  height: 70dvh;
  width: 1020px;
  max-width: 85vw;
  max-width: 85dvw;

  .schedule-dispatch-header {
    .header-info {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;
      flex-wrap: nowrap;
      column-gap: 1rem;
    }
  }

  .schedule-dispatch-grid {
    // height: 85%;
    height: 55vh;
    height: 55dvh;
    width: 100%;
    max-width: 90vw;
    max-width: 90dvw;
    margin-bottom: 1rem;

    .ag-root-wrapper {
      // height: 100%;
      // width: 100%;
      // max-width: 100%;

      // .ag-root-wrapper-body {
      // height: 100% !important;
      // }

      .ag-header {
        position: sticky;
        top: 0;

        .ag-header-cell {
          background-color: #f8f8fa !important;
        }
      }
    }

    .noDriverButton {
      background-color: #748da0;

      .mondayButtonDivider {
        background-color: #5580a1;
      }
    }

    .schedule-dispatch-grid-status {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 80%;
      max-width: 100px;
      border-radius: 7px;
      background-color: #71cf48;
      font-weight: 500;
      color: white;
      letter-spacing: 0.28px;
    }
  }

  .dark-ag-theme {
    .ag-root-wrapper {
      .ag-header {
        height: 41px !important;
        min-height: 41px !important;
        .ag-header-cell {
          background-color: $cardModalHeaderDark !important;
          padding: 0px var(--ag-cell-horizontal-padding) !important;
          .ag-header-cell-text {
            font-size: 16px;
            font-weight: 600;
          }
          .ag-header-cell-resize::after {
            width: 1px !important;
            background-color: gray !important;
          }
        }
      }
    }
  }

  // .schedule-dispatch-footer {
  //   width: 100%;
  //   max-width: 90vw;
  //   max-width: 90dvw;
  //   display: flex;
  //   justify-content: space-between;
  //   align-items: center;
  //   flex-direction: row;
  //   flex-wrap: nowrap;
  // }
}
