@import "../../../../../index.scss";

.childrenCollapser {
  display: flex;
  width: 100%;

  .commentChildrenToggle {
    padding: 5px;
    margin-right: 7px;
    height: stretch;
    cursor: pointer;
    &:hover {
      .line {
        background-color: #0079d3;
      }
    }
  }

  .line {
    height: 100%;
    width: 2px;
    background-color: #edeff1;
  }

  .collapseIcon {
    transform: rotate(90deg);
    margin-right: 10px;
  }

  .showRepliesLabel {
    @include flex(center, center);
    cursor: pointer;
    color: #1264a3;
    svg {
      fill: #1264a3;
    }
  }
}
