@import "../../../../../../../../../index.scss";

$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.vehicleInspectionModal {
  .ant-modal {
    top: 10px !important;
  }
  .ant-modal-content {
    .ant-modal-close-x {
      svg {
        fill: #333238;
      }
    }
    .ant-modal-body {
      padding-bottom: 10px;
      .footerContainer {
        padding-top: 10px;
      }
    }
    .vehicleInspectionFormContainer {
      overflow-y: auto;
      overflow-x: hidden;
      max-height: calc(95vh - 200px);
      width: 100%;
      .ant-form {
        width: 100%;
      }
      .cardsContainer {
        @include flex(unset, space-between);
        div:last-child {
          margin-right: 0;
        }
      }

      .borderedTextCard {
        border: none;
        border-radius: 0;
        border-top: 1px solid black;
        align-items: center;
        padding: 0;
        padding-top: 15px;
        padding-right: 5px;
        .cardTitle {
          font-size: 16px;
          color: black;
        }
        .childrenContainer {
          .tox-tinymce {
            border: none;
            // .tox-sidebar-wrap
            .tox-sidebar-wrap {
              border-radius: 5px;
              .tox-edit-area {
                border-radius: 5px;
                .tox-edit-area__iframe {
                  margin-top: 10px;
                  background: #f5f5f7;
                  border-radius: 5px;
                  border: none;
                }
              }
            }
            .tox-anchorbar {
              display: none;
            }
            .tox-toolbar__primary {
              border: none;
              border-radius: 10px;
              background-color: #f5f5f7;
            }
            .tox-toolbar__group {
              border: none;
            }
            .tox-menubar {
              display: none;
            }
            .tox-statusbar {
              display: none;
            }
          }
        }
      }
      .detailsFormCard {
        .childrenContainer {
          display: flex;
          align-items: center;
          .labeledInputContainer {
            .ant-form-item {
              .ant-picker,
              .ant-input {
                border: none !important;
                height: 32px;
              }
            }
          }
          .labeledInputContainer:first-child {
            width: 15%;
          }
        }
      }

      .flexColumn {
        @include flex(flex-start, flex-start, column);
      }
      @mixin questionaryBooleanButton($background, $color: #fff) {
        @include text(16px, 500, 0.23px, $color, capitalize, $important: true);
        background: $background !important;
        border-radius: 4px !important;
        min-width: 90px;
        margin-right: 20px;
        border-color: $background !important;
        text-align: center;
      }
      .questionsContainer {
        @include flex(baseline, flex-start);
        flex-wrap: wrap;
        width: 100%;
        .ant-radio-button-wrapper {
          @include questionaryBooleanButton(#e6e6e6, #383554);
        }
        .ant-radio-button-wrapper:not(:first-child)::before {
          width: 0 !important;
        }
        .ant-radio-button-wrapper-checked {
          @include questionaryBooleanButton(#017aff);
        }
        .ant-radio-button-wrapper-checked:not(:first-child) {
          @include questionaryBooleanButton(#f93a3a);
        }
      }
      .signaturesContainer {
        width: 100%;
        @include flex(baseline, space-between);
        flex-wrap: wrap;
      }
      .signatureDate {
        width: 160px;
      }
      .signatureSelector {
        @include flex(baseline, space-between);
        margin-top: 10px;
        min-width: 100%;
        .ant-select-selector {
          min-width: 160px;
        }
      }
      .signContainer {
        width: 100%;
        height: 150px;
        position: relative;
        overflow: hidden;
        background-color: #fff;
        img {
          object-fit: contain;
          width: 100%;
          height: calc((70vw - 250px) / 3);
          position: absolute;
          top: calc((70vw - 250px) / -10);
          @media (max-width: 1600px) {
            height: calc((70vw - 200px) / 3);
            position: absolute;
            top: calc((70vw - 250px) / -12);
          }
        }
      }
      .checkboxItemsContainer {
        @include flex(flex-start, flex-start);
        flex-wrap: wrap;
        margin-bottom: 15px;
        .ant-form-item {
          margin-bottom: 0;
        }
        .labeledInputContainer {
          color: #383554;
          border: none !important;
          box-shadow: none !important;
          background-color: #f5f5f7;
          border-radius: 10px;
          padding: 5px;
          padding-left: 10px;
          margin-top: 5px;
          min-width: 180px;
          transition: 0.3s ease-in-out;
          animation: scopesFadeInBottom 500ms both;
          .ant-radio-wrapper {
            font-weight: 400;
          }
        }
        .Poor {
          background-color: #ff9c46;
          .ant-radio-wrapper-disabled {
            color: #333238 !important;
          }
          .ant-radio-disabled {
            .ant-radio-inner {
              background-color: #f5f5f7 !important;
            }
          }
        }

        .Defective {
          background-color: #ff5549;
          .ant-radio-wrapper-disabled {
            color: #333238 !important;
          }
          .ant-radio-disabled {
            .ant-radio-inner {
              background-color: #f5f5f7 !important;
            }
          }
        }
      }
      .inspectionType {
        .ant-form-item {
          margin-bottom: 0;
        }
        .ant-radio-wrapper {
          padding-left: 10px;
          @include text(1rem, 600, 0.3px, #543548);
        }
      }
      .checkboxContainer {
        margin-bottom: 5px;
        margin-right: 5px;
        padding: 0 5px 5px;
      }
      .checkboxCardSubtitle {
        @include text(13px, 400, 0.23px, #383554);
        font-style: italic;
        position: absolute;
        right: -22px;
        top: -10px;
      }
      .ant-checkbox-wrapper {
        color: #383554;
        border: 1px solid rgba(0, 0, 0, 0.1);
        box-shadow: 1.5px 1.5px 5px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        padding: 5px;
        padding-left: 10px;
        width: 100%;
        margin-top: 5px;
        background-color: #fff;
        min-width: 180px;
        &:hover {
          border-color: #1890ff;
          color: #1890ff;
          background-color: #e2f1ff;
        }
      }
      .ant-radio-button-wrapper {
        width: 90px;
        text-align: center;
        font-weight: 400;
        margin-right: 15px;
      }
      .ant-checkbox-group {
        @include flex(flex-start, flex-start, column);
        padding-left: 25px;
      }
      .noFormCardTitle {
        // @include text(20px, 600, 0.3px, #543548);
        @include flex(baseline, flex-start);
        margin-bottom: 5px;
        border-radius: 10px;
        padding-left: 10px;
        width: fit-content;
        height: fit-content;
        background-color: #f8f8fa;
        .title {
          font-family: "Open Sans";
          font-size: 16px;
          font-weight: 600;
          color: black;
        }
      }
      .labeledInputContainer {
        margin-right: 15px;
        .labeledInputLabel {
          @include text(14px, 600, 0.21px, #383554);
          margin-bottom: 2px;
        }
      }
      .ant-input {
        font-weight: 400;
        border: 0.5px solid rgba(0, 0, 0, 0.25) !important;
        border-radius: 5px !important;
        width: 100%;
      }
      .ant-picker {
        border: 0.5px solid rgba(0, 0, 0, 0.25) !important;
        border-radius: 5px !important;
        width: 100%;
        .ant-picker-input > input {
          font-weight: 400;
        }
      }
      .radioButtonOptions {
        align-self: center;
        .ant-form-item {
          margin-bottom: 0;
          .ant-radio-wrapper {
            @include text(14px, 400, 0, #383554);
          }
        }
      }
      .ant-select-selector {
        // background: #F8F8F8 !important;
        // overflow: hidden !important;
        // border: 0.5px solid rgba(0, 0, 0, 0.25) !important;
        // border-radius: 2px !important;
        font-weight: 400;
        // width: 100% !important;
        // .ant-select-selection-search {
        //   background-color: #fff;
        //   margin-right: 7px;
        //   margin-left: -11px;
        //   padding-left: 11px;
        //   border-right: 0.5px solid #DBDBDB;
        // }
        .ant-select-selection-item {
          font-weight: 400;
          // padding-right: 32px !important;
        }
      }
      .ant-select-arrow {
        color: #7b7b7b;
      }
      .ql-container {
        margin-bottom: 24px;
        .ql-editor {
          height: 80px;
        }
      }
    }
  }
}
.ant-dropdown-menu-item-active {
  color: #1890ff !important;
}

.vehicleInspectionModalDark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;
      }
    }
    .subtitle {
      color: #fff;
    }
    .vehicleInspectionFormContainer {
      .noFormCardTitle {
        color: #fff;
        background-color: $cardModalHeaderDark;
        .title {
          color: #fff;
        }
        .ant-radio-wrapper span {
          color: #fff;
        }
      }
      .childrenContainer {
        .labeledInputLabel {
          color: #fff;
        }
        .ant-radio-wrapper span {
          color: #fff;
        }
        .ant-radio-wrapper-disabled span {
          color: #acacca;
        }
        .ant-input {
          background-color: $inputModalDark !important;
          color: #fff;
          &::placeholder {
            color: #acacca;
          }
        }
        .ant-input-disabled {
          color: #acacca;
        }
        .ant-picker {
          background-color: $inputModalDark !important;
          .ant-picker-input {
            background-color: $inputModalDark;
            input {
              color: #f2f2f2;
              &::placeholder {
                color: #acacca;
              }
            }
            .ant-picker-suffix svg {
              color: #acacca;
            }
            .ant-picker-clear {
              background-color: $inputModalDark;
              svg {
                fill: #acacca;
              }
            }
          }
        }
        .ant-picker-disabled {
          .ant-picker-input input {
            color: #acacca !important;
          }
        }
        .ant-select {
          .ant-select-selector {
            background-color: $inputModalDark !important;
            .ant-select-selection-search-input {
              color: #fff;
            }
            .ant-select-selection-item {
              color: #fff;
            }
            .ant-select-selection-placeholder {
              color: #acacca;
            }
          }
          .ant-select-clear {
            background-color: $inputModalDark;
            svg {
              fill: #acacca;
            }
          }
          .ant-select-arrow svg {
            fill: #fff;
          }
        }
        .ant-select-disabled {
          .ant-select-selector {
            .ant-select-selection-search-input {
              color: #acacca !important;
            }
            .ant-select-selection-item {
              color: #acacca !important;
            }
          }
        }
        svg {
          fill: #fff;
        }
        .checkboxItemsContainer {
          .labeledInputContainer {
            background-color: $inputModalDark;
            .ant-radio-wrapper span {
              color: #fff;
            }
          }
          .Poor {
            background-color: #ff9436be !important;
          }
          .Defective {
            background-color: #ff5549be !important;
          }
        }
        .tox {
          border: none;
          .tox-menubar {
            background-color: $cardModalHeaderDark;
            border-radius: 5px 5px 0px 0px;
            .tox-mbtn__select-label {
              color: #fff;
            }
          }
          .tox-mbtn--select:hover {
            background-color: $cardModalBodyDark;
          }
          .tox-toolbar-overlord {
            background-color: $cardModalHeaderDark !important;
            .tox-toolbar__primary {
              background-color: $cardModalHeaderDark !important;
            }
            .tox-toolbar__group {
              background-color: $cardModalHeaderDark !important;
              border: none;
              .tox-split-button:hover {
                border: none;
              }
              .tox-tbtn {
                svg {
                  fill: #fff;
                }
                &:focus {
                  background-color: $cardModalBodyDark;
                }
                &:hover {
                  background-color: $cardModalBodyDark;
                }
              }
              .tox-tbtn__select-label {
                color: #fff !important;
              }
            }
          }
        }
      }
    }
  }
}

@keyframes scopesFadeInBottom {
  from {
    opacity: 0;
    transform: translate3d(0, -50%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
