.smallEditComponent {
  display: flex;
  flex-direction: column;
  .smallEditContainer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 20px;
    padding-top: 20px;
    .IconStyle {
      border-radius: 50%;
      background-color: #323338;
      width: 55px;
      height: 55px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .labelStyle {
      margin-left: 20px;
      text-align: left;
      font: normal normal bold 24px/33px Open Sans;
      letter-spacing: 0px;
      color: #323338;
      opacity: 1;
    }
    .SmallLabel {
      text-align: left;
      margin-bottom: 10px;
      font: normal normal normal 20px/27px Open Sans;
      letter-spacing: 0px;
      color: #323338;
      opacity: 1;
    }
  }
  .confirmContainer {
    display: flex;
    margin-top: 20px;
    justify-content: center;
    .cancelButton {
      margin-right: 10px;
      background-color: #fe4c4a;
    }
    .cancelButtonDivider {
      color: #f14543;
    }
    .cancelButton:hover {
      background-color: #f14543;
      .cancelButtonDivider {
        color: #fe4c4a;
      }
    }
    .confirmButton {
      margin-right: 10px;
      background-color: #71cf48;
    }
    .confirmButtonDivider {
      color: #69c242;
    }
    .confirmButton:hover {
      background-color: #69c242;
      .confirmButtonDivider {
        color: #71cf48;
      }
    }
  }
}
