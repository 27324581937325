.control-panel-payment-container {
  display: flex;
  padding: 10px;
  justify-content: space-between;
  width: 100%;
  overflow-x: auto;

  .control-panel-payment-left {
    display: flex;
    gap: 10px;
  }

  .control-panel-payment-right {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;

    .control-panel-payment-logs {
      width: 32px;
      height: 32px;
      border: 0;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    .logs-enabled {
      background-color: #1264a3;
    }
    .logs-disabled {
      cursor: not-allowed;
      background-color: #f5f5f7;

      &:hover {
        background-color: rgba(50, 51, 56, 0.1);
      }
    }

    .control-panel-payment-item {
      margin-right: -20px;
    }
  }
}
