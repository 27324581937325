.progress-line-item {
  height: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  font-weight: 600;

  .line-label {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 20px;
    color: #323338;
  }
  .line-value {
    height: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
  }
}
