@import "../../../../../index";

$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.reportsPageModals {
  .ant-modal-content {
    .ant-modal-header {
      background-color: #f5f5f7;
      // padding: 20px;
      display: flex;
      align-items: center;
      height: 45px;

      .ant-modal-title {
        color: #323338;
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        width: 100%;
      }
    }

    .ant-modal-close {
      height: 45px;
      aspect-ratio: 1;
    }
    .ant-modal-body {
      .filtersCard {
        $inputs-color: #f5f5f7;
        grid-column: 3 / span 2;

        .searchBarContainer {
          display: flex;
        }

        .filterSelectors {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(247px, 1fr));
          column-gap: 130px;
          row-gap: 20px;
        }

        .ant-input {
          background-color: $inputs-color;
        }
        .ant-input-affix-wrapper,
        .ant-select-selector,
        .ant-picker {
          border: 0;
          border-radius: 5px;
          background-color: $inputs-color;
        }
        .filterInput {
          @include flex(stretch, center, column);
          min-width: 247px;

          .filterInputLabel {
            font-family: "Open Sans";
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            color: #323338;
            margin-left: 10px;
          }
          .dynamicSelect {
            height: 32px;
            .ant-select-selector {
              background: #f5f5f7;
              border-radius: 5px;
              border: 0;
              .ant-select-selection-item {
                font-family: "Open Sans";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
              }
            }
          }
        }

        .filterBtn {
          @include mondayButton(#1264a3, #0f5c97);
          margin-left: 20px;
        }
      }
      .listContainer {
        @include flex(flex-start, flex-start, column);
        overflow-y: auto;
        overflow-x: hidden;
        padding-right: 5px;
      }
    }
    .ant-modal-footer {
      display: flex;
      justify-content: space-between;
    }
  }
}

.reportsPageModalsDark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    color: #fff;
    .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      .filtersCard .filterInput .filterInputLabel {
        color: #fff;
      }
      .ant-select {
        .ant-select-selector {
          background-color: $inputModalDark !important;
          .ant-select-selection-search-input {
            color: #fff;
          }
          .ant-select-selection-item {
            color: #fff;
          }
          .ant-select-selection-placeholder {
            color: #acacca;
          }
        }
        .ant-select-clear {
          background-color: $inputModalDark;
          svg {
            fill: #acacca;
          }
        }
        .ant-select-arrow svg {
          fill: #fff;
        }
      }
      .ant-input-affix-wrapper {
        background-color: $inputModalDark !important;
        .ant-input-prefix svg {
          fill: #fff;
        }
        .ant-input {
          background-color: $inputModalDark !important;
          color: #fff;
          &::placeholder {
            color: #acacca;
          }
        }
      }
      .ant-picker {
        background-color: $inputModalDark !important;
        .ant-picker-input {
          background-color: $inputModalDark;
          input {
            color: #f2f2f2;
            &::placeholder {
              color: #acacca;
            }
          }
          .ant-picker-suffix svg path {
            fill: #acacca;
          }
          .ant-picker-clear {
            background-color: $inputModalDark;
            svg {
              fill: #acacca;
            }
          }
        }
      }
    }
  }
}
