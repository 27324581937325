.bill-item-select {
  width: 100%;

  .ant-form-item {
    padding: 0;
    margin: 0;
  }
}

.add-new-bill-item-button {
  border: none;
  display: flex;
  align-items: center;
  width: 100% !important ;
  box-shadow: none;

  &:hover {
    opacity: 0.5;
  }
  > svg {
    margin-right: 10px;
    path {
      fill: #71cf48;
    }
  }
}
