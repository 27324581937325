.assistant-chat-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .hover-button-container {
    font-weight: 400;
    font-size: 14px;
    line-height: 19.07px;
    color: #acacac;
    padding: 0;

    .button-inner-content {
      padding: 0px 0px;
      .button-inner-text {
        transition: font-weight 0.2s ease-in-out, color 0.2s ease-in-out;
      }
    }

    &:hover {
      font-weight: 600;
    }
  }
}
