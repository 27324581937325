@import "../../../../../../../../index.scss";

.remaining-floors-container {
  @include flex(center, space-between, column);
  gap: 20px;
  min-width: 515px;

  .action-buttons {
    @include flex(center, center);
    gap: 20px;
  }

  .ant-checkbox-group {
    width: 100%;
    gap: 10px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(165px, auto));
    grid-template-rows: auto;

    .ant-checkbox-wrapper {
      height: 40px !important;
      width: 165px;
      overflow: hidden;
      display: flex;
      align-items: center;
      background-color: #f7f8fa;
      margin: 0;

      &:hover {
        background-color: #d3d3d3;

        .ant-checkbox-inner {
          border: 10px solid #fe4c4a !important;
          background-color: #e03d3a;
          margin: 0px !important;
          border-radius: 0 !important;
          height: 40px;
          width: 40px;
        }
      }

      .ant-checkbox {
        height: 100%;
        width: 40px;
        margin: 0;
        margin-top: -5px !important;
      }
      .ant-checkbox-inner {
        border: 10px solid #fe4c4a !important;
        background-color: #e03d3a;
        margin: 0px !important;
        border-radius: 0 !important;
        height: 40px;
        width: 40px;
        &:hover {
          border: 10px solid #fe4c4a !important;
          background-color: #e03d3a;
          margin: 0px !important;
          border-radius: 0 !important;
          height: 40px;
          width: 40px;
          position: relative;
        }
        &::after {
          width: 0;
          height: 0;
        }
      }

      .container {
        width: stretch;
        display: flex;
        justify-content: center;
        align-items: center;

        .floorStopHandlerButtonSelected {
          background-color: #71cf48;
          height: 32px;
          width: 32px;
          border: 0;
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          svg {
            fill: white;
          }
        }
        .floorStopHandlerButtonUnselected {
          background-color: #fe4c4a;
          height: 32px;
          width: 32px;
          border: 0;
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          svg {
            fill: white;
          }
        }

        .floorTextContainer {
          width: 65px;
        }
      }
    }

    .ant-checkbox-wrapper-checked {
      position: relative;
      &:hover {
        &::after {
          border: 10px solid #68c142 !important;
        }
      }

      &::after {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 10px solid #71cf48 !important;
        content: url("../../../../../../../pages/Settings/settingsComponents/Roles/src/Tick.svg");
        fill: black !important;
        background-color: white;
        width: 40px;
        height: 40px;
        border-radius: 0px;
        position: absolute;
        z-index: 5;
      }
    }
  }

  .label-container {
    @include flex(center, space-between);
    width: stretch;
    gap: 10px;
  }

  .stop-icons-container {
    @include flex(center, center);
  }
}
