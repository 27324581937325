//light mode
.hoistDivContainerLightMode {
  .ag-root-wrapper-body.ag-layout-normal {
    height: fit-content !important;
  }
  animation: fadeInApplicationServiceHoistUp 0.3s;
  border-radius: 10px;
  gap: 20px;
  .ant-collapse {
    .ant-collapse-item {
      .ant-collapse-header {
        .anticon {
          top: 15px;
        }
      }

      .ant-collapse-body {
        background-color: yellowgreen;
      }
    }
  }

  .serviceListHoistCollapsePanel {
    border: 0 !important;
    border-radius: 2px;
    border-radius: 10px;
    overflow: hidden;

    .ant-collapse-header {
      height: 45px;
      display: flex;
      align-items: center;
    }

    .serviceListHoistCollapsePanelTitle {
      font-size: 16px;
      font-weight: 600;
      white-space: nowrap;
      display: flex;
      align-items: center;
      gap: 15px;
    }
  }

  .serviceListServiceHoist {
    border-radius: 2px;
    // box-shadow: 0px 0px 6px 3px #007bff77;
    border-radius: 10px;
    overflow: hidden;
  }
}

//dark mode
.hoistDivContainerDarkMode {
  animation: fadeInApplicationServiceHoistUp 0.3s;
  border-radius: 10px;
  gap: 20px;
  .ag-root-wrapper-body.ag-layout-normal {
    height: fit-content !important;
  }
  .ant-collapse {
    background: #12131b;
    .ant-collapse-item {
      border-bottom: 0;
      .ant-collapse-header {
        .anticon {
          top: 15px;
        }
        svg {
          fill: white;
        }
      }

      .ant-collapse-content {
        background: #12131b;
        border-top: 0;
        border-bottom: 0 !important;
      }
    }
  }

  .serviceListHoistCollapsePanel {
    border: 0px;
    border-radius: 2px;
    border-radius: 10px;
    overflow: hidden;

    :not(:first-child) {
      .ant-collapse-header {
        border-top: 1px solid #292b3a;
      }
    }

    :not(:last-child) {
      .ant-collapse-header {
        border-bottom: 1px solid #292b3a;
      }
    }

    .ant-collapse-header {
      display: flex;
      align-items: center;
      height: 45px;
    }

    .serviceListHoistCollapsePanelTitle {
      font-size: 16px;
      font-weight: 600;
      color: #f5f5f5;
      white-space: nowrap;
      display: flex;
      align-items: center;
      gap: 15px;
    }
  }

  .serviceListServiceHoist {
    border-radius: 2px;
    border-radius: 10px;
    overflow: hidden;
    background: #12131b;
  }
}

@keyframes fadeInApplicationServiceHoistUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
