@import "../../../index.scss";

.notesContainerDiv {
  display: flex;
  justify-content: center;

  .openNotesButton {
    @include mondayButton(#1264a3, #0f5c97, #fff);
  }
}
.richTextEditorModalStyle {
  .ant-modal-content {
    width: 520px;
    height: 345px;
    overflow: hidden;
    border-radius: 20px;

    .ant-modal-header {
      width: 520px;
      height: 53px;

      .ant-modal-title {
        font-family: "Open Sans", sans-serif;
        font-size: 16px;
      }
    }

    .ant-modal-body {
      width: 520px;
      height: 234px;
      padding: 20px;
      .tox-sidebar-wrap {
        .tox-edit-area__iframe {
          border-radius: 10px;
          background: white;
        }
      }
    }

    .ant-modal-footer {
      width: 520px;
      height: 53px;
      border-top: 0 !important;

      .richTextNotesButtonContainer {
        display: flex;
        justify-content: flex-end;

        .richTextSaveMondayButton {
          margin-top: -5px;
          margin-right: 5px;
          @include mondayButton(#71cf48, #68c142, #fff);

          &:disabled {
            opacity: 0.8;
          }
        }
        .richTextCloseMondayButton {
          margin-top: -5px;
          margin-right: 10px;
          @include mondayButton(#fe4c4a, #eb4345, #fff);
        }
      }
    }
  }
}

.richTextEditorModalStyleDark {
  .ant-modal-close-icon {
    svg {
      fill: white;
    }
  }

  .ant-modal-content {
    width: 520px;
    height: 345px;
    overflow: hidden;
    border-radius: 20px;
    background-color: #1f212d;

    .ant-modal-header {
      width: 520px;
      height: 53px;
      background-color: #12131b;
      border-bottom: 0;

      .ant-modal-title {
        font-family: "Open Sans", sans-serif;
        font-size: 16px;
        color: white;
      }

      .ant-modal-close-icon {
        svg {
          fill: white !important;
        }
      }
    }

    .ant-modal-body {
      width: 520px;
      height: 234px;
      background-color: #1f212d;
      padding: 20px;

      .tox-toolbar__primary {
        background-color: #1f212d;

        svg {
          fill: white;
        }
      }

      .tox-tbtn {
        color: white;
        &:hover {
          background: #12131b;
        }
      }

      .tox-sidebar-wrap {
        .tox-edit-area__iframe {
          border-radius: 10px;
          background: white;
        }
      }
    }
    .ant-modal-footer {
      width: 520px;
      height: 53px;
      border-top: 0 !important;

      .richTextNotesButtonContainer {
        display: flex;
        justify-content: flex-end;

        .richTextSaveMondayButton {
          margin-top: -5px;
          margin-right: 5px;
          @include mondayButton(#71cf48, #68c142, #fff);
          &:disabled {
            opacity: 0.8;
          }
        }
        .richTextCloseMondayButton {
          margin-top: -5px;
          margin-right: 10px;
          @include mondayButton(#fe4c4a, #eb4345, #fff);
        }
      }
    }
  }
}
