@import "../../../../../index.scss";

.LongLineSteperBody {
  width: 100%;
  height: 350px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow-x: auto;

  .steperContainer {
    margin: 20px;
    flex-grow: 1;
    flex-basis: 0;

    .steperDescription {
      width: 260px;
      height: fit-content;
      margin: 10px 0px 10px 20px;
      @include textEllipsis();
    }

    .steperTopContent {
      width: 100%;
      width: 100%;
      height: fit-content;
      margin: 0px 0px 10px 20px;
      display: flex;

      .steperTitle {
        font-weight: bold;
        width: 200px;
        @include textEllipsis();
      }
      .steperRefractive {
        display: color;
        margin-top: 3px;
        margin-left: 5px;
        font-size: 13px;
      }
    }
  }

  .steperUnfilled {
    width: 250px;
    height: 40px;
    background-color: #f4f5f6;
    border-radius: 50px;
    overflow: hidden;
    position: relative;
    cursor: pointer;

    .steperFilled {
      height: 100%;
      border-radius: 50px;
      background-color: blue;
      position: absolute;
      z-index: 1;
    }

    .steperNumber {
      position: absolute;
      width: 100%;
      height: 100%;
      text-align: end;
      margin-top: -3px;
      padding-right: 15px;
      z-index: 2;
      font-size: 30px;
      font-weight: bold;
    }
  }
}

.LongLineSteperBodyDark {
  // .LongLineSteperBody {
    .steperContainer {
      .steperTopContent {
        color: #f2f2f2;
      }
      .steperDescription {
        color: #f2f2f2 !important;
      }
    }
  // }
}

.dashToolTipStyleCustom {
  opacity: 1 !important;
  .ant-tooltip-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-radius: 10px !important;
    overflow: hidden !important;
    opacity: 1 !important;
    width: 400px !important;
    max-height: 400px !important;
  }
  .ant-tooltip-inner {
    background-color: black !important;
    opacity: 0.8 !important;
    overflow-y: auto !important;
  }
}
