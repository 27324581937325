$mobile-width: 320px;
$tablet-width: 768px;
$laptop-width: 1024px;
$desktop-width: 1440px;

@mixin mobile {
  @media (max-width: calc(#{$tablet-width} - 1px)) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: calc(#{$laptop-width} - 1px)) {
    @content;
  }
}

@mixin laptop {
  @media (max-width: calc(#{$desktop-width} - 1px)) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}
