.global-search-container {
  display: flex;
  flex: 1;
  align-items: center;
  padding: 8px;

  @media (min-width: 1025px) {
    justify-content: flex-end;
  }

  .ant-select-focused .ant-select-selector,
  .ant-select-selector:focus,
  .ant-select-selector:active,
  .ant-select-open .ant-select-selector {
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
  }

  .search-input-container {
    position: relative;
    background-color: #f4f5f6;
    border-radius: 5px;
    width: 100%;
    overflow: hidden;

    &.dark-mode {
      background-color: #12131b;
      border-radius: 5px;

      .search-icon {
        color: #fff;
      }
    }

    @media (min-width: 769px) {
      min-width: 300px;
      max-width: 300px;
    }

    @media (min-width: 1025px) {
      border-top-right-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
    }

    .ant-form-item {
      margin-bottom: 0;
    }

    .search-input {
      width: 100%;
      padding-left: 20px;

      .search-dropDown {


        .ant-select-item-option-content {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden !important;
        }
      }
    }

    .search-icon {
      position: absolute;
      top: 30%;
      left: 7px;
      // z-index: 999;
      color: #323338;
    }
  }

  .divider {
    height: 30px;
    background-color: #d9d9d9;
    margin-inline: 0;

    &.dark-mode {
      background-color: #acacca;
    }
  }
}

.searchFilter-select {
  width: 160px;
  height: 32px;

  .ant-select-selector {
    border-radius: 0px 5px 5px 0px !important;
  }
}

.searchFilter-select.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #f5f5f7;
  border: none;
}

span.ant-select-selection-placeholder {
  color: #e0e0e1;
}

span.ant-select-arrow {
  width: 11px;
  height: 7px;
}