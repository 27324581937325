@import "../../../../../../../../../../../../../../../../index.scss";

.footerContainerRentalDetails {
  width: fit-content;
  height: 45px;
  display: flex;
  align-items: center;

  .backMondayButton {
    margin-right: 20px;
    @include mondayButton(#1264a3, #0f5c97, #fff);
  }
  .discardMondayButton {
    margin-right: 20px;
    @include mondayButton(#fe4c4a, #eb4345, #fff);
  }
  .saveMondayButton {
    margin-right: 20px;
    @include mondayButton(#71cf48, #68c142, #fff);
    svg {
      margin-left: -5px;
      margin-right: -5px;
      width: 20px;
      height: 20px;
      fill: white;
    }

    &:disabled {
      @include mondayButton(#b4b4b4, #999999, #fff);
    }
  }

  .informationContainerFooterLight {
    margin-right: 60px;
    display: flex;
    align-items: center;
    color: black;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
      "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 14px;

    .iconRentalContainerFooter {
      background-color: #eb4345;
      color: white;
      border-radius: 50%;
      height: 18px;
      width: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 5px;
    }
  }

  .informationContainerFooterDark {
    margin-right: 60px;
    display: flex;
    align-items: center;
    color: white;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
      "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 14px;

    .iconRentalContainerFooter {
      background-color: #eb4345;
      border-radius: 50%;
      height: 18px;
      width: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 5px;
    }
  }
}
