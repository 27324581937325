@import "../../../../../../index.scss";

.overViewStepContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .mainContainer {
    width: 100%;
    height: fit-content;
    @include flex(center, space-evenly);
  }

  .mainGrid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 32%);
    column-gap: 2rem;
  }

  .inputsGrid {
    width: 100%;
    display: grid;
    grid-template-columns: [full-start] 45% 45% [full-end];
    column-gap: 30px;
    grid-auto-rows: min-content;
    position: relative;

    .projectAvatarsDiv {
      display: flex;
      gap: 20px;
    }
  }

  .titleVisible {
    position: absolute;
    top: 145px;
    left: 500px;
    height: 20px;
    width: 20px;
    padding: 0;
    padding-bottom: 20px;
  }

  .fullInput {
    grid-column: full;

    .labeled-qb-input {
      .qb-input {
        width: 100%;
        padding: 0;
        overflow: hidden;
        // border-radius: 0;

        > :nth-child(2) {
          max-width: 25px;
        }

        .ant-form-item {
          width: 100%;
          // border-radius: 0px !important;
          .ant-row {
            // border-radius: 0px !important;
            .ant-col {
              // border-radius: 0px !important;
              .ant-form-item-control-input {
                // border-radius: 0px !important;
                .ant-form-item-control-input-content {
                  // border-radius: 0px !important;
                  .ant-input {
                    border-radius: 0px !important;
                  }
                  .ant-picker {
                    border-radius: 0px !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .ant-picker {
    width: 100% !important;
    background-color: #ececec !important;
  }
  .ant-select-selection-item-remove {
    @include flex(center, center);
  }

  .addressGrid {
    display: flex;
    justify-content: baseline;
    align-items: center;
    flex-direction: column;
    width: 95%;
    .mainAddress {
      width: 100%;
      position: relative;
    }
  }

  .splitAddress {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    width: 100%;
  }
}

@media (max-width: 1200px) {
  .overViewStepContainer {
    .mainGrid {
      width: 100%;
      grid-template-columns: 50% 50%;
      align-items: center;
      column-gap: 1rem;
      .inputsGrid:first-child {
        grid-column: 1/3;
        width: 100%;
        column-gap: 2rem;
        .fullInput {
          grid-column: auto;
        }
      }
      .inputsGrid {
        width: 95%;
      }
    }
  }
}

@media (max-width: 750px) {
  .overViewStepContainer {
    .mainGrid {
      grid-template-columns: 100%;
      column-gap: 0px;
      .inputsGrid:first-child {
        grid-column: auto;
        grid-template-columns: 100%;
        width: 95%;
      }
      .inputsGrid {
        grid-column: auto;
        width: 99%;
      }
      .addressGrid {
        width: 95%;
      }
    }
  }
}

@media (max-width: 450px) {
  .overViewStepContainer {
    .mainGrid {
      .inputsGrid,
      .addressGrid {
        .labeledInputContainer {
          min-width: 0px !important;
        }
      }
      .addressGrid .splitAddress {
        flex-direction: column;
      }
    }
  }
}
