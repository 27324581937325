.tree-element-item {
  text-align: center;
  margin: auto;
  border: none;
  width: 100%;
}

.tree-element-item-previous {
  background-color: #fcd3d3;
}

.tree-element-item-current {
  background-color: #d5f1c9;
}

.tree-element-item-dark {
  width: 100%;
  text-align: center;
  margin: 0;
  border: none;
}
