$mainDarkBackgroundColor: #20212d;

.right-actions-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
  //   padding-top: 5px;

  &.dark-mode {
    .header-icon {
      display: flex;
      fill: #f2f2f2 !important;

      svg {
        fill: #f2f2f2 !important;
      }
    }
    .ant-scroll-number {
      box-shadow: 0 0 0 1px $mainDarkBackgroundColor;
    }
  }

  .icon-container {
    display: flex;
  }

  .header-icon {
    display: flex;
    cursor: pointer;
    fill: #222a44 !important;

    svg {
      fill: #222a44 !important;
    }
  }
}
