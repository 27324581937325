@import "../../Communication.scss";

.communication-sidebar-container {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;

  .communication-sidebar {
    position: relative;
    background: $white;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-right: 1px solid #f0f0f0;

    .collapsed-button {
      background: $white;
      border-color: #d9d9d9;

      &:hover {
        border-color: $main-light-background !important;
      }
    }
  }

  .ant-menu.communication-sidebar-menu {
    border-inline-end: none;
  }


  .communication-sidebar-menu {
    display: flex;
    flex-direction: column;
    border: none;
    padding: 0px;
    color: $black;

    .menu-item-label {
      color: $light-text-color;
    }

    .ant-menu-item {
      margin-block: 0px;
    }

    .ant-menu-item-selected {
      background-color: #F5F5F7;
      color: $black;
      font-weight: 600;
    }

    .ant-menu-item:not(.ant-menu-item-selected):hover {
      background-color: #F1F5FD !important;
      color: $main-light-background !important;

      .menu-item-icon {
        color: $main-light-background !important;
        fill: $main-light-background !important;

        svg>path {
          fill: $main-light-background !important;
          color: $main-light-background !important;
        }
      }
    }




    .menu-item-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      color: $icon-color;
      fill: $icon-color;

    }
  }

  .compose-email-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
    padding: 20px;
    padding-bottom: 0px;

    &.collapsed {
      padding: 10px 24px;

      .compose-email-content {
        width: 50px;
        height: 45px;
        border-radius: 5px;
        padding: 12px;
      }
    }

    .compose-email-content {
      display: flex;
      justify-content: center;
      gap: 10px;
      align-items: center;
      border-radius: 5px;
      background: $secondary-light-background;
      color: $main-light-background;
      width: 100%;
      height: 59px;

      &:hover {
        background-color: $main-light-background;
        color: $white;

        .compose-icon {
          fill: $white;
        }
      }

      .compose-icon {
        fill: $main-light-background;
      }
    }
  }

  .inbox {
    background: #fce8e6 0% 0% no-repeat padding-box;
  }

  .other {
    background: #f5f5f7 0% 0% no-repeat padding-box;
  }



  &.dark-mode {
    background: $mainDarkBackgroundColor;

    .communication-sidebar {
      background: $cardDarkBackgroundColor;
      border-color: #48495b;
    }

    .compose-email-container {
      .compose-email-content {
        background: $cardDarkHeaderColor;
        color: $dark-text-color;


        svg {
          color: $dark-text-color;
          fill: $dark-text-color;
        }
      }
    }

    .ant-menu {
      background: $cardDarkBackgroundColor;

      .ant-menu-item {
        color: $dark-text-color;

        .menu-item-label {
          color: $dark-text-color;
        }

        &:hover {
          background-color: #4c5569;
          color: $light-text-color;

          .menu-item-label {
            color: $light-text-color;
          }
        }

        .menu-item-icon {
          color: $dark-text-color;
          fill: $dark-text-color;
        }
      }
    }

    .ant-menu .ant-menu-item.ant-menu-item-selected {
      background-color: #4c5569;
    }

    .collapsed-button {
      background: $cardDarkHeaderColor !important;
      border-color: #363636 !important;

      #Path_7407,
      #Path_7408 {
        fill: $white;
      }

      &:hover {
        border-color: #676767 !important;
      }
    }
  }
}