@import "../../../../index.scss";

.dateFilterSelect {
  margin: 0 10px !important;
}

.dateFilterArrow {
  @include flex(center, center);
  @include square(40px, 40px, 0, #f8f8f8);
  cursor: pointer;
}
