.save-export-preview {
  justify-content: space-between;
  height: 100%;
  .content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .add-new-container {
      display: flex;
      gap: 10px;
      .labeledInputContainer {
        flex: 1;
      }
    }
    .templates-container {
      display: flex;
      gap: 10px;
      .template {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 5px;
        border-radius: 5px;
        padding: 10px;
        background-color: #f4f5f6;
        border: 1px solid #f4f5f6;
        height: 32px;
        min-width: 150px;
        cursor: pointer;
      }
      .template.selected {
        background-color: #f1f5fd;
        border: 1px solid #3f51b5;
      }
      .template.default {
        background-color: #f1f5fd;
      }
      .template:hover {
        background-color: #f1f5fd;
        border: 1px solid #3f51b5;
      }
    }
  }
  .save-export-footer {
    display: flex;
    gap: 10px;
  }
}
