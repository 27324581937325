// Dark Mode Styles
.dark-mode-progress-type {
  background-color: #121212;
  color: #ffffff;
  width: 100vw !important;
  height: 100vh !important;
  top: 0;
  left: 0;
  max-width: 100vw;
  max-height: 100vh;
  padding: 0;

  .ant-modal-content {
    background-color: #12131b;
    color: #ffffff;
    .ant-modal-header {
      background-color: #12131b;
      border-bottom: 1px solid #333;
      .ant-modal-title {
        color: #ffffff;
      }
    }
    .ant-modal-body {
      background-color: #222a44;
      color: #ffffff;
      .ant-table {
        background-color: #222a44;

        // Table Headers
        .ant-table-thead > tr > th {
          background-color: #222a44;
          color: #ffffff;
          border-color: #f8fafda2;
        }

        // Table Cells
        .ant-table-tbody > tr > td {
          background-color: #222a44;
          color: #ffffff;
          border-color: #f8fafda2;
        }
      }

      // Tag Styles
      .ant-tag {
        background-color: #341088;
        color: #ffffff;
        border-color: transparent;
      }
    }
  }
}
