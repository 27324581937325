@import "../../../../../../../index.scss";

.usernameRenderer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .usernameRendererLeft {
    display: flex;
    gap: 5px;
    align-items: center;
    .statusDot {
      @include circle(8px, transparent);
      margin-left: 5px;
    }

    .username {
      @include textEllipsis();
      cursor: pointer;
    }
  }
  .user-status {
    .suspended-user-status {
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }
  .actionBtn {
    color: #1264a3;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
  }
  &:hover {
    background-color: #f1f5fd;
    cursor: pointer;
    .username {
      color: #1264a3;
      font-weight: 600;
    }
  }
}
