.application-service-list-service-light {
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  animation: fadeInUp 0.3s 
}

.application-service-list-service-dark {
  border-radius: 10px;
  overflow: hidden;
  background: #12131b;
  display: flex;
  flex-direction: column;
  animation: fadeInUp 0.3s 
}


@keyframes fadeInUp {
  from {
      opacity: 0;
      transform: translate3d(0, 100%, 0);
  }

  to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
  }
}
