.mobile-dashboard-table-container {
  height: 100%;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);

  .card-template-title {
    border-radius: 5px 5px 0 0;
    overflow-x: auto;
    overflow-y: hidden;
    text-overflow: unset;
    &::-webkit-scrollbar {
      display: none !important;
    }
  }
  .card-template-body {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: calc(100% - 45px);
    width: 100%;
    padding: 10px 0 10px 10px;
    background-color: #fff;
    border-radius: 0 0 5px 5px;
    .table-search-container {
      display: flex;
      gap: 10px;
      padding-right: 10px;
      .table-search-input {
        flex: 1;
        height: 44px;
        .ant-input-affix-wrapper {
          height: 100%;
        }
      }
      .mondayButtonContainer {
        height: 44px !important;
        .mondayButtonStyle {
          height: 100%;
          width: 44px;
        }
      }
    }
  }
}
