$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.chartType-select {
  .ant-modal-content {
    .ant-modal-close {
      height: 45px;
      width: 45px;
      .ant-modal-close-x {
        height: 45px;
        width: 45px;
        display: flex;
        align-items: center;
        justify-self: center;
        svg {
          fill: #333238;
        }
      }
    }
    .ant-modal-header {
      height: 45px;
      display: flex;
      align-items: center;
      background-color: #f8f8fa;
      .ant-modal-title {
        font-size: 16px;
        font-weight: 600;
        color: #333238;
      }
    }

    .ant-modal-body {
      .chartType-container {
        display: flex;
        flex-direction: column;
        gap: 0px;

        .fieldLabel {
          margin-left: 10px;
          font-size: 16px;
          font-weight: 600;
        }

        .ant-select-selector {
          background: #f5f5f7 0% 0% no-repeat padding-box;
          border-radius: 5px;
          font-weight: 400;
          border-style: none;
          transition: 0s;
          opacity: 1;
          min-height: 32px;
          width: 100%;
          height: fit-content;
        }
      }
    }
  }
}

.chartType-select-dark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    color: #fff;
    .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalBodyDark;
      .ant-modal-title {
        color: #fff;
      }
      .ant-modal-title {
        &::after {
          background-color: #fff;
        }
        span {
          color: #fff;
        }
      }
    }
    .ant-modal-body {
      .ant-select {
        .ant-select-selector {
          border: none;
          background-color: $inputModalDark !important;
          color: #f2f2f2;
          .ant-select-selection-search-input {
            background-color: $inputModalDark;
            color: #f2f2f2;
          }
          .ant-select-selection-placeholder {
            color: #acacca;
            z-index: 2;
          }
        }
        .ant-select-arrow svg {
          fill: #f2f2f2;
        }
      }
    }
  }
}
