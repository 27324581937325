@use "src/index.scss" as index;

.upload-schedule-modal {
  height: fit-content !important;
  width: fit-content !important;
  max-height: calc(100vh - 20px);
  max-width: calc(100vw - 20px);
  .ant-modal-content {
    min-height: 180px;
    min-width: 300px;
    max-height: calc(100vh - 20px);
    max-width: calc(100vw - 20px);
    .ant-modal-close {
      height: 45px;
      width: 45px;
      .ant-modal-close-x {
        height: 45px;
        width: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .ant-modal-header {
      height: 45px;
      display: flex;
      align-items: center;
      background-color: #f8f8fa;
    }
    .ant-modal-body {
      min-height: calc(180px - 97px);
      min-width: 300px;
      display: grid;
      gap: 10px;
      .upload-control-panel {
        display: flex;
        align-items: center;
        gap: 10px;
        .file-name {
          font-size: 16px;
        }
        .ant-form-item {
          margin-bottom: 0px !important;
        }
      }
    }
    .ant-modal-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      flex-wrap: nowrap;
      width: 100%;
      .mondayButtonStyle {
        width: 150px;
      }
    }
  }
}

.upload-schedule-modal.upload-schedule-modal-dark {
  .ant-modal-content {
    background-color: index.$mainDarkBackgroundColor;
    .ant-modal-close .ant-modal-close-x svg {
      fill: #fff !important;
    }
    .ant-modal-body {
      .upload-control-panel .file-name {
        color: #fff;
      }
      .table-wrapper {
        .ag-paging-panel.ag-unselectable,
        .ag-header-cell  {
          background-color: index.$mainDarkBackgroundColor !important;
        }
        .ag-header-row  {
          border-top: none;
        }
      }
    }
    .ant-modal-header {
      background-color: index.$cardDarkBackgroundColor;
      .ant-modal-title {
        color: #fff;
      }
    }
  }
}
