$mainDarkBackgroundColor: #20212d;
$cardDarkHeaderColor: #2a2b3a;
$cardDarkBackgroundColor: #12131b;
$inputDarkColor: #2a2b3a;

.todo-modal {
  width: 80% !important;
  height: 80dvh !important;

  .ant-modal-content {
    border-radius: 10px;

    .ant-modal-close-x {
      color: #12131b;
    }
    .ant-modal-header {
      border-bottom: none;
      display: flex;
      justify-content: flex-start;
      height: 45px;
      align-items: center;
      .ant-modal-title {
        font-weight: 600;
        font-size: 16px;
        color: #333238;
      }
    }

    .ant-modal-body {
      min-width: 500px;
      background-color: #f4f5f6;
      padding: 0 !important;
      overflow: hidden;

      .titleCompBasePage {
        display: none;
      }
    }
  }
}

@media (max-width: 780px) {
  .todo-modal {
    .ant-modal-content {
      .ant-modal-body {
        min-width: 100% !important;
        width: 100% !important;
      }
    }
  }
}

.todo-modal-dark {
  .ant-modal-content {
    background-color: $cardDarkBackgroundColor;
    .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardDarkHeaderColor;
      .ant-modal-title {
        color: #fff !important;
      }
    }
    .ant-modal-body {
      .inspectedServiceName {
        color: #fff;
      }
      .labeledInputLabel {
        color: #fff;
      }
      .ant-select {
        .ant-select-selector {
          background-color: $inputDarkColor !important;
          .ant-select-selection-search-input {
            color: #fff;
          }
          .ant-select-selection-item {
            color: #fff;
          }
          .ant-select-selection-placeholder {
            color: #acacca;
          }
        }
        .ant-select-clear {
          background-color: $inputDarkColor;
          svg {
            fill: #acacca;
          }
        }
        .ant-select-arrow svg {
          fill: #fff;
        }
      }
      .ant-input {
        background-color: $inputDarkColor !important;
        color: #fff;
        &::placeholder {
          color: #acacca;
        }
      }
      .ant-picker {
        background-color: $inputDarkColor !important;
        .ant-picker-input {
          background-color: $inputDarkColor;
          input {
            color: #f2f2f2;
            &::placeholder {
              color: #acacca;
            }
          }
          .ant-picker-suffix svg {
            color: #acacca;
          }
          .ant-picker-clear {
            background-color: $inputDarkColor;
            svg {
              fill: #acacca;
            }
          }
        }
      }
      .basePageModalFooter {
        background-color: $cardDarkBackgroundColor;
      }
    }
  }
}
