.changeOrderIndicatorContainer {
  background-color: #fe4c4a;
  justify-content: center;
  justify-self: end;
  align-items: center;
  border-radius: 5px;
  color: white;
  display: flex;
  height: 32px;
  width: 50px;
  gap: 5px;

  svg {
    fill: white;
  }
}
