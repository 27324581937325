.undo-progress-schedule-popover {
  .undo-progress-schedule-popover-content {
    display: flex;
    gap: 5px;
  }

  .ant-popover-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    font-size: 14px;
    font-weight: 500;
    background-color: #f0f2f5;
    border-radius: 4px 4px 0 0;
  }
  .ant-popover-inner-content {
    display: flex;
    align-items: center;
    padding: 0px 8px 8px 8px !important;
  }
}

.undo-progress-schedule-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
