.moreOptionsDrawerContent {
  .ant-drawer-bottom {
    margin-bottom: 250px;
  }
  .moreOptionsDrawer {
    //header
    border-radius: 10px 10px 10px 10px;
    border: 2px solid #116d6e;
    .ant-drawer-header {
      background-color: #116d6e;
      border-radius: 10px 10px 0 0;
      .ant-drawer-header-title {
        .ant-drawer-title {
          color: white;
          font-family: Open Sans;
          font-size: 16px;
          font-weight: 600;
          line-height: 21.79px;
          text-align: left;
        }
      }
    }
    .ant-drawer-body {
      // padding: 0;
      background-color: #20212d;
      border-radius: 0 0 10px 10px;
    }
  }
}
