$file-ratio: 1;

.gallery-card-container {
  .card-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;

    .body-files-container {
      overflow-y: hidden;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;
      gap: 10px;
      width: calc(100% - 20px);
      height: 100%;
      overflow-x: auto;
    }

    .file-container {
      display: grid;
      grid-template-columns: 100%;
      grid-template-rows: 65% auto auto;
      gap: 10px;
      height: 100%;
      aspect-ratio: $file-ratio;
      background-color: #d7d7d9;
      border-radius: 5px;

      .frame-icon {
        display: grid;
        place-items: center;

        svg {
          height: 40%;
          width: 40%;
        }
      }

      .file-name {
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;
        padding: 0 20px;
        overflow: hidden;
      }

      .mondayButtonContainer {
        display: grid;
        place-items: center;
      }
    }

    .image-container {
      border-radius: 5px;
    }

    .ant-image {
      height: 100%;
      aspect-ratio: $file-ratio;
    }
  }
}

.gallery-card-container.grid-card-dark {
  .card-body {
    .file-container {
      background-color: #20212d;
    }
  }
}
