$mainDarkBackgroundColor: #20212d;
$cardDarkHeaderColor: #2a2b3a;
$cardDarkBackgroundColor: #12131b;
$inputDarkColor: #2a2b3a;

.session-drawer {
  .ant-drawer-header {
    .session-drawer-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      .logsButton {
        color: #1264a3;
        cursor: pointer;
      }
    }
  }
  .ant-drawer-body {
    padding: 0;
    .session-user-list {
      border-radius: 0;
      box-shadow: none;
      .card-template-title {
        display: none !important;
      }
      .card-template-body {
        .usersSearch {
          width: 100%;
          display: flex;
          justify-content: space-between;
          gap: 10px;
          .session-filters-buttons {
            display: flex;
            gap: 10px;
          }
          .labeledInputContainer {
            flex: 1;
            max-width: 400px;
          }
        }
        .user-list-table {
          .user-list-table-header {
            .user-header {
              flex: 2.8;
            }
            .department-header {
              flex: 2;
            }
            .status-header {
              // flex: 1.5;
              text-align: right;
              display: flex;
              justify-content: flex-end;
              align-items: center;
              gap: 10px;
            }
          }
          .user-list-table-body {
            height: calc(100vh - 230px);
            .usernameRenderer {
              .usernameRendererLeft {
                flex: 3;
              }
              .username-groupname {
                flex: 2;
              }
              .user-status {
                flex: 1;
                text-align: right;
              }
            }
          }
        }
      }
    }
  }
}

.session-drawer-dark {
  .ant-drawer-header {
    background-color: $cardDarkHeaderColor;
    border-bottom: 1px solid $mainDarkBackgroundColor;
    .ant-drawer-title {
      color: #fff;
    }
    svg {
      fill: #fff;
    }
  }
  .ant-drawer-body {
    background-color: $cardDarkBackgroundColor;
  }
}
