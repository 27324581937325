.header-statistics-wrapper {
  gap: 10px;
  width: 100%;
  height: 150px;
  display: flex;
  color: #231f20;
  position: relative;
  padding: 10px 50px;
  align-items: center;
  justify-content: space-between;
  background-image: url("../HeaderStatistics/resources/ProposalCoreHeader.png");

  &::before {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    content: "";
    position: absolute;
    background-color: #ffffffd9;
  }
  > * {
    position: relative;
    z-index: 2;
  }

  .header-statistics__left {
    gap: 10px;
    display: flex;
    flex-direction: column;
    .header-statistics__title {
      font-weight: 600;
      font-size: 2.5rem;
      line-height: 49.72px;
    }
    .header-statistics__desc {
      font-weight: 400;
      font-size: 1.25rem;
      line-height: 24.86px;
    }
    .header-statistics__go-back {
      font-size: 14px;
      cursor: pointer;
      font-weight: 600;
      color: #1264a3;
      line-height: 19px;
    }
  }
  .header-statistics__right {
    &:hover {
      cursor: pointer;
    }
    .coreLogoBlackHolder {
      svg {
        height: 4rem !important;
      }
    }
  }
  @media screen and (max-width: 780px) {
    height: 70px;
    padding: 20px 10px;
    background-size: contain;
    flex-direction: column-reverse;
    .header-statistics__left {
      .header-statistics__title {
        font-size: 24px;
        line-height: 1;
      }
      .header-statistics__desc {
        display: none;
      }
    }
    .header-statistics__right {
      display: none;
    }
  }
}
.header-statistics-wrapper.dark {
  color: #fff;

  &::before {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    content: "";
    position: absolute;
    // background-color: #000000cc;
    background-color: #231f20d9;
  }

  .header-statistics__right .coreLogoBlackHolder {
    svg {
      fill: #fff;
      * {
        fill: #fff !important;
      }
    }
  }
}
