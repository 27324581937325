.questions-images-container {
  display: flex;
  background-color: #f8f8fa;
  border-radius: 5px;
  padding: 5px;
  overflow-x: auto;
  gap: 5px;

  .image-container {
    width: 100%;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    cursor: pointer;
    min-height: 55px;
    min-width: 105px;
    max-height: 55px;
    max-width: 105px;

    .thumbnail-img {
      min-width: 100%;
      min-height: 100%;
    }
  }
}
