.SOVServiceMenuContainerLight {
  // height: stretch;
  width: 298px;
  background: #fff;
  border-radius: 10px;
  overflow-y: auto;
  margin-bottom: 20px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.16);
  animation: fadeInLeft 0.3s;
  transition: height 0.3s;

  .react-grid-item {
    cursor: grab;
    position: relative;
  }
  .divider {
    width: 10px;
  }

  .service-menu-container-options {
    width: 100%;
    padding: 10px;
    background: white;
    display: flex;
    justify-content: center;

    .showHideButtonContainer {
      color: #1264a3;
      font-family: Open Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      svg {
        fill: #1264a3;
        width: 18px;
        height: 18px;
      }
    }

    .showHideButton {
      flex: 1;
      border: 0;
      height: 32px;
      border-radius: 5px;

      display: flex;
      justify-content: space-between;
      border: 0;
      background: #1264a3;

      &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
        background-color: #0f5c97 !important;

        .iconHolder {
          svg {
            fill: #548bb3;
          }
        }
      }

      .textHolder {
        white-space: nowrap;
        margin-top: 4px;
        margin-left: 15px;
        font-size: 14px;
        color: white !important;
      }

      .iconHolder {
        margin-top: -1px;
        padding-left: 6px;
        border-left: 1px solid white;
        height: 32px;

        svg {
          margin-top: 4px;
          fill: white;
        }
      }
    }
  }

  .estimation-services-title {
    letter-spacing: 1px;
    font-size: 18px;
    font-weight: 500;
    padding: 0 0 5px 15px;
    // white-space: nowrap;
    display: flex;
    // width: 270px;
    justify-content: space-between;
    align-items: flex-end;
  }

  .estimation-services-item {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    width: 298px;
    height: 36px;
    line-height: 36px;
    padding-left: 12px;
    background: #f5f7f9;
    font-size: 1.125rem;
    cursor: not-allowed;
    align-items: center;

    .ant-checkbox-wrapper {
      height: 36px !important;
      display: flex;
      padding-top: 3.5px;

      .ant-checkbox {
        border: 5px solid #fe4c4a;
        border-radius: 2px;

        .ant-checkbox-inner {
          border-radius: 0;
          border: 2px solid #ff7274;
          background-color: #eb4345;
          height: 14px;
          width: 14px;
        }
      }

      //checked
      .ant-checkbox-checked {
        border: 5px solid #68c142;

        .ant-checkbox-inner {
          border-radius: 0;
          border: 2px solid #71cf48;
          background-color: #68c142;
          height: 14px;
          width: 14px;
          content: url("../../../../../../../pages/Settings/settingsComponents/Roles/src/TickIcon.svg");
        }
      }

      //indeterminate
      .ant-checkbox-indeterminate {
        border: 5px solid #1264a3;
        border-radius: 2px;

        .ant-checkbox-inner {
          border-radius: 0;
          border: 2px solid #1c7ac2;
          background-color: #0f5c97;
          height: 14px;
          width: 14px;
          padding: 2px;
          content: "";
          &::after {
            background-color: white;
          }
        }
      }
    }

    .estimationServicesContainer {
      display: flex;
      flex-direction: row;
      gap: 5px;
      width: 255px;
      padding: 0 12px;
      align-items: center;
      // .deleteContainer {
      //   display: none;
      // }
      // &:hover {
      //   .deleteContainer {
      //     display: block;
      //   }
      // }
      .estimation-services-text {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .changeOrderContainer {
        margin-top: px;
        height: 24px;
        width: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        // background: red;
        border-radius: 5px;
        svg {
          height: 20px;
          width: 20px;
          fill: #000;
        }
      }
    }

    .estimation-services-error {
      margin-left: auto;
      margin-right: 12px;
    }
  }

  .react-grid-layout .react-draggable .estimation-services-item:active {
    cursor: grabbing;
  }

  .react-grid-layout .react-draggable .estimation-services-item {
    cursor: grab;
  }
}

.SOVServiceMenuContainerRetractedLight {
  width: 0;
  background: #fff;
  border-radius: 10px;
  overflow-y: auto;
  margin-bottom: 20px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.16);
  margin-right: -20px;
  animation: fadeOutLeft 0.3s;
  transition: height 0.3s;

  .divider {
    width: 10px;
  }
  .react-grid-item {
    cursor: grab;
    position: relative;
  }

  .service-menu-container-options {
    padding: 10px;
    background: white;
    display: flex;
    justify-content: center;

    .showHideButton {
      flex: 1;
      border: 0;
      height: 32px;
      border-radius: 5px;

      display: flex;
      justify-content: space-between;
      border: 0;
      background: #1264a3;

      &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
        background-color: #0f5c97 !important;

        .iconHolder {
          svg {
            fill: #548bb3;
          }
        }
      }

      .textHolder {
        margin-top: 4px;
        margin-left: 15px;
        font-size: 14px;
        color: white !important;
        white-space: nowrap;
      }

      .iconHolder {
        margin-top: -1px;
        padding-left: 6px;
        border-left: 1px solid white;
        height: 32px;

        svg {
          margin-top: 4px;
          fill: white;
        }
      }
    }
  }

  .estimation-services-title {
    letter-spacing: 1px;
    font-size: 18px;
    font-weight: 500;
    padding: 0 0 5px 15px;
    display: flex;
    // width: 270px;
    align-items: flex-end;
    justify-content: space-between;
  }

  .estimation-services-item {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    width: 298px;
    height: 36px;
    line-height: 36px;
    padding-left: 12px;
    background: #f5f7f9;
    font-size: 1.125rem;
    cursor: not-allowed;
    align-items: center;

    .ant-checkbox-wrapper {
      height: 36px !important;
      display: flex;
      padding-top: 3.5px;

      .ant-checkbox {
        border: 5px solid #fe4c4a;
        border-radius: 2px;

        .ant-checkbox-inner {
          border-radius: 0;
          border: 2px solid #ff7274;
          background-color: #eb4345;
          height: 14px;
          width: 14px;
        }
      }

      //checked
      .ant-checkbox-checked {
        border: 5px solid #68c142;

        .ant-checkbox-inner {
          border-radius: 0;
          border: 2px solid #71cf48;
          background-color: #68c142;
          height: 14px;
          width: 14px;
          content: url("../../../../../../../pages/Settings/settingsComponents/Roles/src/TickIcon.svg");
        }
      }

      //indeterminate
      .ant-checkbox-indeterminate {
        border: 5px solid #1264a3;
        border-radius: 2px;

        .ant-checkbox-inner {
          border-radius: 0;
          border: 2px solid #1c7ac2;
          background-color: #0f5c97;
          height: 14px;
          width: 14px;
          padding: 2px;
          content: "";
          &::after {
            background-color: white;
          }
        }
      }
    }

    .estimationServicesContainer {
      display: flex;
      justify-content: space-between;
      width: 255px;
      padding: 0 12px;
      align-items: center;
      .deleteContainer {
        display: none;
      }
      &:hover {
        .deleteContainer {
          display: block;
        }
      }
      .estimation-services-text {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .changeOrderContainer {
        margin-top: px;
        height: 24px;
        width: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        // background: red;
        border-radius: 5px;
        svg {
          height: 20px;
          width: 20px;
          fill: #000;
        }
      }
      .deleteContainer {
        svg {
          width: 18px;
          height: 18px;
        }
      }
    }
    .estimation-services-error {
      margin-left: auto;
      margin-right: 12px;
    }
  }

  .react-grid-layout .react-draggable .estimation-services-item:active {
    cursor: grabbing;
  }

  .react-grid-layout .react-draggable .estimation-services-item {
    cursor: grab;
  }
}

.SOVServiceMenuContainerDark {
  width: 330px;
  background: #12131b;
  border-radius: 10px;
  overflow-y: auto;
  margin-bottom: 20px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.16);
  animation: fadeInLeft 0.3s;
  transition: height 0.3s;

  .divider {
    width: 10px;
  }

  .service-menu-container-options {
    padding: 10px;
    background: #12131b;
    display: flex;
    justify-content: center;
    .showHideButtonContainer {
      color: white;
      font-family: Open Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      svg {
        fill: white;
        width: 18px;
        height: 18px;
      }
    }

    .showHideButton {
      flex: 1;
      border: 0;
      height: 32px;
      border-radius: 5px;
      display: flex;
      justify-content: space-between;
      border: 0;
      background: #1264a3;

      &:disabled {
        cursor: not-allowed;
        .textHolder {
          white-space: nowrap;
          color: #548bb3;
        }

        background-color: #0b3e64;

        .iconHolder {
          border-color: #548bb3;
          svg {
            fill: #548bb3;
          }
        }
      }

      .textHolder {
        margin-top: 4px;
        margin-left: 15px;
        font-size: 14px;
        color: white;
        white-space: nowrap;
      }

      .iconHolder {
        margin-top: -1px;
        padding-left: 6px;
        border-left: 1px solid #0f5c97;
        height: 32px;

        svg {
          margin-top: 4px;
          fill: white;
        }
      }
    }
  }

  .estimation-services-title {
    letter-spacing: 1px;
    font-size: 18px;
    font-weight: 500;
    padding: 0 0 5px 15px;
    color: #f5f5f5;
    display: flex;
    // width: 270px;
    align-items: flex-end;
    justify-content: space-between;
  }

  .estimation-services-item {
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    width: 298px;
    height: 36px;
    line-height: 36px;
    padding-left: 12px;
    // background: #1f212d;
    font-size: 1.125rem;
    color: #f5f5f5;
    cursor: not-allowed;
    align-items: center;
    &:hover {
      background: #1f212d;
    }

    .ant-checkbox-wrapper {
      height: 36px !important;
      display: flex;
      padding-top: 3.5px;

      .ant-checkbox {
        border: 5px solid #fe4c4a;
        border-radius: 2px;

        .ant-checkbox-inner {
          border-radius: 0;
          border: 2px solid #ff7274;
          background-color: #eb4345;
          height: 14px;
          width: 14px;
        }
      }

      //checked
      .ant-checkbox-checked {
        border: 5px solid #68c142;

        .ant-checkbox-inner {
          border-radius: 0;
          border: 2px solid #71cf48;
          background-color: #68c142;
          height: 14px;
          width: 14px;
          content: url("../../../../../../../pages/Settings/settingsComponents/Roles/src/TickIcon.svg");
        }
      }

      //indeterminate
      .ant-checkbox-indeterminate {
        border: 5px solid #1264a3;
        border-radius: 2px;

        .ant-checkbox-inner {
          border-radius: 0;
          border: 2px solid #1c7ac2;
          background-color: #0f5c97;
          height: 14px;
          width: 14px;
          padding: 2px;
          content: "";
          &::after {
            background-color: white;
          }
        }
      }
    }

    .ant-checkbox-wrapper-disabled {
      filter: brightness(0.65);
      height: 40px !important;
      display: flex;

      .ant-checkbox {
        border: 5px solid #fe4c4a;
        border-radius: 2px;

        .ant-checkbox-inner {
          border-radius: 0;
          border: 2px solid #ff7274 !important;
          background-color: #eb4345;
          height: 14px;
          width: 14px;
        }
      }

      //checked
      .ant-checkbox-checked {
        border: 5px solid #68c142;

        .ant-checkbox-inner {
          border-radius: 0;
          border: 2px solid #71cf48 !important;
          background-color: #68c142;
          height: 14px;
          width: 14px;
          content: url("../../../../../../../pages/Settings/settingsComponents/Roles/src/TickIcon.svg");
        }
      }

      //indeterminate
      .ant-checkbox-indeterminate {
        border: 5px solid #1264a3;
        border-radius: 2px;

        .ant-checkbox-inner {
          border-radius: 0;
          border: 2px solid #1c7ac2 !important;
          background-color: #0f5c97;
          height: 14px;
          width: 14px;
          padding: 2px;
          content: "";
          &::after {
            background-color: white;
          }
        }
      }
    }

    .estimationServicesContainer {
      display: flex;
      justify-content: space-between;
      width: 255px;
      padding: 0 12px;
      align-items: center;
      .deleteContainer {
        display: none;
      }
      &:hover {
        .deleteContainer {
          display: block;
        }
      }
      .estimation-services-text {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .changeOrderContainer {
        margin-top: px;
        height: 24px;
        width: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        // background: red;
        border-radius: 5px;
        svg {
          height: 20px;
          width: 20px;
          fill: white;
        }
      }
      .deleteContainer {
        svg {
          width: 18px;
          height: 18px;
        }
      }
    }
    .estimation-services-error {
      margin-left: auto;
      margin-right: 12px;
    }
  }

  .react-grid-layout .react-draggable .estimation-services-item:active {
    cursor: grabbing;
  }

  .react-grid-layout .react-draggable .estimation-services-item {
    cursor: grab;
  }
}

.SOVServiceMenuContainerRetractedDark {
  width: 0px;
  background: #12131b;
  border-radius: 10px;
  overflow-y: auto;
  margin-right: -20px;
  margin-bottom: 20px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.16);
  animation: fadeOutLeft 0.3s;
  transition: height 0.3s;

  .divider {
    width: 10px;
  }

  .service-menu-container-options {
    padding: 10px;
    background: #12131b;
    display: flex;
    justify-content: center;

    .showHideButton {
      flex: 1;
      border: 0;
      height: 32px;
      border-radius: 5px;
      display: flex;
      justify-content: space-between;
      border: 0;
      background: #1264a3;

      &:disabled {
        cursor: not-allowed;
        .textHolder {
          color: #548bb3;
          white-space: nowrap;
        }

        background-color: #0b3e64;

        .iconHolder {
          border-color: #548bb3;
          svg {
            fill: #548bb3;
          }
        }
      }

      .textHolder {
        white-space: nowrap;
        margin-top: 4px;
        margin-left: 15px;
        font-size: 14px;
        color: white;
      }

      .iconHolder {
        margin-top: -1px;
        padding-left: 6px;
        border-left: 1px solid #0f5c97;
        height: 32px;

        svg {
          margin-top: 4px;
          fill: white;
        }
      }
    }
  }

  .estimation-services-title {
    letter-spacing: 1px;
    font-size: 18px;
    font-weight: 500;
    padding: 0 0 5px 15px;
    color: #f5f5f5;
    display: flex;
    // width: 270px;
    align-items: flex-end;
    justify-content: space-between;
  }

  .estimation-services-item {
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    width: 298px;
    height: 36px;
    line-height: 36px;
    padding-left: 12px;
    // background: #1f212d;
    font-size: 1.125rem;
    color: #f5f5f5;
    cursor: not-allowed;
    align-items: center;
    &:hover {
      background: #1f212d;
    }
    .ant-checkbox-wrapper {
      height: 36px !important;
      display: flex;
      padding-top: 3.5px;

      .ant-checkbox {
        border: 5px solid #fe4c4a;
        border-radius: 2px;

        .ant-checkbox-inner {
          border-radius: 0;
          border: 2px solid #ff7274;
          background-color: #eb4345;
          height: 14px;
          width: 14px;
        }
      }

      //checked
      .ant-checkbox-checked {
        border: 5px solid #68c142;

        .ant-checkbox-inner {
          border-radius: 0;
          border: 2px solid #71cf48;
          background-color: #68c142;
          height: 14px;
          width: 14px;
          content: url("../../../../../../../pages/Settings/settingsComponents/Roles/src/TickIcon.svg");
        }
      }

      //indeterminate
      .ant-checkbox-indeterminate {
        border: 5px solid #1264a3;
        border-radius: 2px;

        .ant-checkbox-inner {
          border-radius: 0;
          border: 2px solid #1c7ac2;
          background-color: #0f5c97;
          height: 14px;
          width: 14px;
          padding: 2px;
          content: "";
          &::after {
            background-color: white;
          }
        }
      }
    }

    .ant-checkbox-wrapper-disabled {
      filter: brightness(0.65);
      height: 40px !important;
      display: flex;

      .ant-checkbox {
        border: 5px solid #fe4c4a;
        border-radius: 2px;

        .ant-checkbox-inner {
          border-radius: 0;
          border: 2px solid #ff7274 !important;
          background-color: #eb4345;
          height: 14px;
          width: 14px;
        }
      }

      //checked
      .ant-checkbox-checked {
        border: 5px solid #68c142;

        .ant-checkbox-inner {
          border-radius: 0;
          border: 2px solid #71cf48 !important;
          background-color: #68c142;
          height: 14px;
          width: 14px;
          content: url("../../../../../../../pages/Settings/settingsComponents/Roles/src/TickIcon.svg");
        }
      }

      //indeterminate
      .ant-checkbox-indeterminate {
        border: 5px solid #1264a3;
        border-radius: 2px;

        .ant-checkbox-inner {
          border-radius: 0;
          border: 2px solid #1c7ac2 !important;
          background-color: #0f5c97;
          height: 14px;
          width: 14px;
          padding: 2px;
          content: "";
          &::after {
            background-color: white;
          }
        }
      }
    }

    .estimationServicesContainer {
      display: flex;
      justify-content: space-between;
      width: 255px;
      padding: 0 12px;
      align-items: center;
      .deleteContainer {
        display: none;
      }
      &:hover {
        .deleteContainer {
          display: block;
        }
      }
      .estimation-services-text {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .changeOrderContainer {
        margin-top: px;
        height: 24px;
        width: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        // background: red;
        border-radius: 5px;
        svg {
          height: 20px;
          width: 20px;
          fill: white;
        }
      }
      .deleteContainer {
        svg {
          width: 18px;
          height: 18px;
        }
      }
    }

    .estimation-services-error {
      margin-left: auto;
      margin-right: 12px;
    }
  }

  .react-grid-layout .react-draggable .estimation-services-item:active {
    cursor: grabbing;
  }

  .react-grid-layout .react-draggable .estimation-services-item {
    cursor: grab;
  }
}

@keyframes fadeOutLeft {
  from {
    width: 298px;
    margin-right: 0;
  }
  to {
    width: 0;
    margin-right: -20px;
  }
}

@keyframes fadeInLeft {
  from {
    margin-right: -20px;
    width: 0;
  }
  to {
    margin-right: 0;
    width: 300px;
  }
}
