@use "/src/index.scss" as index;

.team-sidebar-item {
  background-color: #f4f5f6;
  border-radius: 5px;
  padding: 10px 5px;
  margin-bottom: 10px;
  transition: 0.4s ease-out;
  &.members-open {
    background-color: #f1f5fd;
    border: 1px solid #1264a3;
  }
  .team-data {
    display: flex;
    flex-direction: column;
    padding: 0px 5px;
    span {
      font-weight: 600;
    }
    .team-name {
      font-size: 16px;
      cursor: pointer;
      &:hover {
        color: index.$blue;
      }
    }
    .jobs-container {
      display: flex;
      gap: 10px;
      .job-name {
        cursor: pointer;
        &:hover {
          color: index.$blue;
        }
        &.active {
          color: index.$blue;
        }
      }
      .members-count {
        cursor: pointer;
      }
    }
  }
  .members-statuses {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 10px;
    padding: 0px 5px;
    .status-container {
      width: 100%;
      column-span: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      gap: 5px;
      .count {
        color: #fff;
        font-weight: 600;
      }
    }
  }
  .dropdown-trigger {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
    .members-count {
      cursor: pointer;
      .count {
        font-weight: 600;
      }
    }
    .dropdown-icon {
      cursor: pointer;
      transition: 0.3s ease;
    }
  }
  .members-list {
    width: 100%;
    max-height: 520px;
    overflow-y: auto;
    margin-top: 10px;
    .emp-sidebar-item {
      background-color: #fff;
      &:hover {
        background-color: #d8d8d8;
      }
      &.active {
        background-color: #d8d8d8;
      }
    }
  }
}

.team-sidebar-item-dark {
  background-color: index.$cardDarkHeaderColor;
  &.members-open {
    background-color: #41435d;
  }
  .members-statuses {
    .status-container {
      display: flex;
      align-items: center;
      gap: 5px;
      color: #fff;
    }
  }
  .members-list {
    .emp-sidebar-item {
      background-color: index.$mainDarkBackgroundColor;
      &:hover {
        background-color: index.$cardDarkBackgroundColor;
      }
      &.active {
        background-color: index.$cardDarkBackgroundColor;
      }
    }
  }
  .dropdown-trigger svg {
    fill: #fff;
  }
}

.sidebar-popover-header {
  height: 30px;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  border-radius: 5px 5px 0px 0px;
  background-color: #f4f5f6 !important;
}

.sidebar-popover-content {
  display: grid;
  .employee-included {
    display: flex;
    align-items: center;
    gap: 5px;
    .status-dot {
      height: 8px;
      width: 8px;
      border-radius: 50%;
    }
  }
}

@media (max-width: 1024px) {
  .team-sidebar-item {
    font-size: 12px;
    padding: 8px;
    .team-data .team-name {
      font-size: 14px;
    }
    .members-statuses {
      flex-wrap: wrap-reverse;
    }
    .dropdown-trigger {
      justify-content: center;
      margin-bottom: 0px;
      .members-count {
        font-size: 14px;
      }
    }
    .members-list {
      margin-top: 5px;
    }
  }
}
