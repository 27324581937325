@use "~ag-grid-enterprise/styles" as ag;
@include ag.grid-styles(
  (
    theme: alpine,
  )
);

.detailsModalDarkThemAgGrid {
  .ag-theme-alpine {
    --ag-border-radius: 10px;
  }

  .ag-root-wrapper {
    background-color: #12131b;
    border: 0;

    .ag-header-viewport {
      background-color: black;
      border-bottom: 0;
    }

    .ag-header {
      .ag-header-row {
        color: #f7f8fa;
        border-color: dimgray;
      }
    }

    .ag-header-cell-resize {
      &:after {
        background-color: dimgray;
      }
    }

    .ag-row {
      border-color: dimgray;
    }

    .ag-row-even {
      background-color: #1f212d;
      color: #f7f8fa;
    }

    .ag-row-odd {
      background-color: #292b3a;
      color: #f7f8fa;
    }
  }

  .oddHeaderClassName {
    background-color: #292b3a !important;
  }

  .evenHeaderClassName {
    background-color: black !important;
  }
}

.detailsModalLightThemAgGrid {
  .ag-theme-alpine {
    --ag-border-radius: 10px;
  }

  .ag-root-wrapper {
    border: 0 1px 1px 1px solid dimgray;
    border-radius: 0 0 10px 10px;

    .ag-header-viewport {
      background-color: #cfcece;
      border-bottom: 0;
    }

    .ag-header {
      .ag-header-row {
        color: black;
      }
    }

    .ag-header-cell-resize {
      &:after {
        background-color: dimgray;
      }
    }

    .ag-row {
      border-color: dimgray;
    }

    .ag-row-even {
      background-color: #ececec;
      color: black;
    }

    .ag-row-odd {
      background-color: #f7f8fa;

      color: black;
    }
  }

  .oddHeaderClassName {
    background-color: #bbbbbb !important;
  }

  .evenHeaderClassName {
    background-color: #cfcece !important;
  }
}
