$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

#compareVersionsModal {
  .ant-modal,
  .ant-modal-content {
    min-height: calc(100vh - 20px);
    min-width: calc(100vw - 20px);
    margin: 0;
    inset: 2px;
  }

  .ant-modal-body {
    min-height: calc(100vh - 110px);
  }
}

.compareSelectorContainer {
  width: 100%;
  display: flex;
  gap: 8px;
  justify-content: space-between;

  .compareSelector {
    display: flex;
    gap: 8px;

    .filterIcons {
      cursor: pointer;
      width: 32px;
      height: 32px;
      background: #1264a3;
      color: white;
      fill: white;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
    }
    & .disabled {
      background-color: #888;
      opacity: 0.6;
      cursor: not-allowed;
    }
  }
}

.version-comparison {
  display: flex;
}

.compareVersionsModalDark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      border-radius: 10px 10px 0px 0px;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      .compareSelectorContainer {
        .ant-select {
          .ant-select-selector {
            background-color: $inputModalDark !important;
            .ant-select-selection-search-input {
              color: #fff;
            }
            .ant-select-selection-item {
              color: #fff;
            }
            .ant-select-selection-placeholder {
              color: #acacca;
            }
          }
          .ant-select-clear {
            background-color: $inputModalDark;
            svg {
              fill: #acacca;
            }
          }
          .ant-select-arrow svg {
            fill: #fff;
          }
        }
        .ant-select-disabled {
          .ant-select-selector {
            .ant-select-selection-search-input {
              color: #acacca !important;
            }
            .ant-select-selection-item {
              color: #acacca !important;
            }
          }
        }
      }
    }
  }
}
