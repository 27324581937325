.servicesSort__container {
  gap: 10px;
  display: flex;
  flex-direction: column;
  .servicesSort {
    gap: 10px;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    .droppableDiv {
      gap: 10px;
      height: 100%;
      display: flex;
      overflow-y: auto;
      max-height: 500px;
      padding-right: 10px;
      flex-direction: column;
    }
    .draggableDiv {
      width: 100%;
      height: 50px;
      display: flex;
      padding: 0 10px;
      border-radius: 5px;
      align-items: center;
      background-color: #f8f8fa;
      justify-content: space-between;
      .draggableSpan {
        overflow: hidden;
        user-select: none;
        padding-right: 5px;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
  .servicesSort__buttons {
    .drag-services {
      gap: 10px;
      width: 150px;
      height: 50px;
      cursor: grab;
      display: flex;
      padding: 10px;
      align-items: center;
      background-color: #f4f5f6;
      justify-content: space-between;
    }
  }
}
