.chart-card-content {
  display: flex;
  height: 409px;

  &.small-card {
    flex: 1 0 0;
    min-width: 33%;
  }
  &.default-card {
    flex: 1 0 0;
    min-width: 66%;
  }
  &.large-card {
    flex: 1 0 0;
    min-width: 99%;
  }
  &.blurred {
    .formCard {
      .childrenContainer {
        filter: blur(5px);
      }
    }
  }

  .formCard {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    .formCardTitle {
      padding: 0 20px;
    }
    .childrenContainer {
      height: 100%;
      justify-content: center;
      overflow: hidden; //avoid oversizing
    }
  }

  &.dark-mode {
    background: #20212d;
    .formCard {
      background: inherit;
      .formCardTitle {
        color: #f2f2f2;
        background: #2a2b3a;
      }
      .childrenContainer {
        background: #12131b;
      }
    }
  }
}

@media (max-width: 768px) {
  .chart-card-content {
    &.small-card {
      min-width: 100%;
    }
    &.default-card {
      min-width: 100%;
    }
    &.large-card {
      min-width: 100%;
    }
  }
}
