.user-info {
  height: 100%;
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 5px;

  .user-informations {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    overflow: auto;

    .row-info {
      display: flex;
      align-items: center;
      gap: 10px;
      .icon {
        svg {
          path {
            fill: #323338;
          }
        }
      }
      .label {
        font-family: Open Sans;
        font-size: 16px;
        font-weight: 400;
        line-height: 21.79px;
        text-align: left;
      }
    }
    .nameOfUser {
      font-size: 24px;
      font-weight: 600;
      display: flex;
      gap: 10px;
      align-items: center;
    }

    .progress-button {
      position: absolute;
      right: 0;
    }
  }
  .account-buttons {
    height: fit-content;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }

  &.dark-mode {
    .user-informations {
      color: #fff;
    }
    .icon {
      svg {
        path {
          fill: #fff !important;
        }
      }
    }
  }
}
