.table_hoist_modal {
  width: 100vh !important;
  top: 0 !important;
  padding: 0 !important;
  width: 100% !important;
  height: 100% !important;
  margin: 0;
  max-width: 100%;
  //   overflow: scroll;
  .ag-root-wrapper {
    box-shadow: 0 0 10px #198de6 !important;
    border-radius: 10px;
    height: 100%; // Expand to 100% height
    z-index: 1000000;
  }
  // full screen modal
  .ant-modal-content {
    border-radius: 0;
    height: 100%;
    width: 100vw !important;
    max-width: 100%;
    max-height: 100%;
    background-color: #2a2b3a;
    .ant-modal-header {
      background-color: #0f5c97;
      border-bottom: 1px solid #616481;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      padding: 10px !important;
      width: 100vw !important;
      height: 830px !important;
      overflow-y: auto !important;
      .price-body-wrapper {
        display: flex;
        flex-direction: column;
        height: 94%;
        .hoist-price-table-container {
          flex: 1;
        }
      }
    }
  }
}
