@use "/src/index.scss" as index;

.employee-reports-page {
  height: 100%;
  width: 100%;
  .employee-reports-control-panel {
    height: 51px;
    display: flex;
    align-items: center;
    padding: 0px 10px;
    gap: 10px;
    border-bottom: 1px solid #dee2eb;
    background-color: #f4f5f6;
    .control-panel-form {
      display: flex;
      flex-direction: row;
      gap: 10px;
      .ant-form-item {
        margin-bottom: 0px;
        .ant-input-affix-wrapper {
          background-color: #fff !important;
          .ant-input {
            background-color: #fff !important;
          }
        }
        .ant-picker {
          background-color: #fff !important;
        }
      }
      .time-range {
        display: flex;
        align-items: center;
        gap: 10px;
        .time-input-wrapper {
          background-color: #fff;
          .ant-input {
            background-color: #fff !important;
          }
          .day-period-container {
            background-color: #fff;
          }
          .ant-segmented-item-selected {
            background-color: #f4f5f6;
            box-shadow: none;
          }
          .ant-segmented-thumb,
          .ant-segmented-thumb-motion-appear,
          .ant-segmented-thumb-motion,
          .ant-segmented-thumb-motion-appear-active {
            background-color: #f4f5f6;
          }
        }
        .clear-time-btn {
          height: 20px;
          width: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: none;
          background-color: #fff;
          border-radius: 50%;
        }
      }
    }
  }
  .employee-reports-body {
    height: calc(100% - 51px);
    width: 100%;
    display: grid;
    grid-template-columns: calc(50% - 5px) calc(50% - 5px);
    grid-template-rows: calc(50% - 5px) calc(50% - 5px);
    gap: 10px;
    overflow-y: auto;
    padding: 10px;
  }
}

.employee-reports-page-dark {
  .employee-reports-control-panel {
    border-bottom: 1px solid #616481;
    background-color: index.$cardDarkBackgroundColor;
    .control-panel-form {
      .time-range {
        display: flex;
        align-items: center;
        gap: 10px;
        .time-input-wrapper {
          background-color: index.$inputDarkColor !important;
          .ant-input {
            background-color: index.$inputDarkColor !important;
          }
          .day-period-container {
            background-color: index.$inputDarkColor !important;
          }
        }
        &::after {
          background-color: #acacca;
        }
        .clear-time-btn {
          background-color: index.$mainDarkBackgroundColor;
          svg {
            fill: #fff;
          }
        }
      }
      .ant-form-item {
        .ant-input-affix-wrapper {
          background-color: index.$inputDarkColor !important;
          .ant-input {
            background-color: index.$inputDarkColor !important;
          }
        }
        .ant-picker {
          background-color: index.$inputDarkColor !important;
        }
      }
    }
    .ant-select {
      .ant-select-selector {
        background-color: index.$inputDarkColor !important;
      }
    }
  }
}

@media (max-width: 1000px) {
  .employee-reports-page {
    .employee-reports-body {
      grid-template-columns: calc(100% - 50px);
      grid-template-rows: repeat(4, 500px);
    }
  }
}
