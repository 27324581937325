#audit-overview-grid {
  .audit-grid-status {
    width: 100%;
    height: 30px;
    display: grid;
    place-content: center;
    max-width: 200px;
    border-radius: 5px;
    font-weight: 600;
    color: #fff;
    vertical-align: middle;
  }
}
