@import "../../../../src/components/SidebarPages/Communication/Communication.scss";

.pagination-container {
  display: flex;
  align-items: center;
  gap: 20px;

  .pagination-info-container {
    color: #ACACAC
  }

  .pagination-actions {
    display: flex;

    button {
      svg {
        fill: #000;
      }



      &:not(:disabled):hover {
        svg {
          fill: $main-light-background;
        }

        &:hover {
          background-color: $secondary-light-background;
        }

      }

      &:disabled {
        svg {
          fill: #ACACAC;
        }
      }
    }
  }


}