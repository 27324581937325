.project-match-controller-container {
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  background-color: #f8f8fa;
  gap: 10px;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;

  .controller-section {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 10px;

    &:nth-child(1) {
      justify-content: flex-start;
    }

    &:nth-child(2) {
      justify-content: flex-end;
    }

    .ant-radio-group {
      white-space: nowrap;
    }

    .ant-radio-button-wrapper {
      border-color: transparent;
    }

    .ant-radio-button-wrapper-checked {
      &:hover {
        border-color: transparent;
      }
    }

    .labeledInputContainer {
      border-radius: 5px;
      overflow: hidden;
      .ant-select,
      .ant-picker {
        width: 100%;
        background-color: #fff !important;
      }
    }
  }

  .address-select {
    min-width: 300px;
    .ant-select {
      width: 100%;
    }
  }
}

.project-match-controller-container.match-container-dark {
  background-color: #12131b;

  .controller-section {
    .ant-radio-button-wrapper {
      &::before {
        background-color: #616481;
      }
    }

    .labeledInputContainer {
      .ant-select,
      .ant-picker {
        width: 100%;
        background-color: #3a3c4e !important;
      }
    }
  }
}
