.legend-popover-content {
  height: 300px;
  min-width: 200px;
  max-height: calc(100dvh - 120px);
  overflow-y: auto;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;

  .legend-section {
    width: 100%;
  }

  .section-status {
    width: -moz-fill-available;
    width: -webkit-fill-available;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  }

  .section-status-color {
    height: 1rem;
    aspect-ratio: 1;
    border-radius: 50%;
  }
}

.legend-popover-content.legend-content-dark {
  .ant-divider-with-text {
    color: #fff;
    font-weight: 550;
  }
}
