@import "../../index.scss";

.no-reminder-info {
  width: 190px !important;
  max-width: 100% !important;
}

.task_logged_time {
  display: flex;
}

.task_logged_time span {
  margin-left: 7px;
  font-weight: bold;
}

.task_logged_time h6 {
  margin-bottom: 0;
}

.detailsModal__points {
  background: #bebebe;
  padding: 0 5px;
  margin-right: 5px;
  border-radius: 5px;
}

.detailsModal__profilePic {
  border-radius: 50%;
  width: 58px;
  height: 58px;
  margin-right: 20px;
}

.detailsModal__commentPic {
  border-radius: 50%;
  width: 70px;
  height: 70px;
  margin-right: 20px;
}

.detailsModal__profile {
  margin-left: 15px;
  cursor: pointer;
}

.detailsModal__profile > span {
  padding: 3px 18px;
  border-radius: 20px;
  color: white;
  font-size: 13px;
}

.detailsModal__addItem {
  background: white;
  color: #bebebe;
  border-style: none;
  text-decoration: none;
  cursor: pointer;
  font-size: 13px;
}

.detailsModal__status {
  display: flex;
  align-items: center;
  position: relative;
}

.detailsModal__status > div {
  font-size: 15px;
  color: #e5e5e5;
}
.detailsModal__labels {
  display: flex;
  gap: 5px;
  .detailsModal__label {
    color: white !important;
    font-size: 12px !important;
    padding: 0 5px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    // margin-right: 15px;
  }
}
.detailsModal__showInputComment {
  border-style: none;
  background: #1890ff;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 5px;
  margin-left: 50px;
  padding: 5px 2%;
  margin-bottom: 5%;
  margin-top: 20px;
}

.MuiFormControl-root {
  width: 100%;
}

.detailsModal__comment__buttonsContainer {
  display: flex;
  justify-content: flex-end;
  padding: 10px 20px;
  background: #f2f2f2;
  font-size: 13px;
}

.detailsModal__comment__uploadFiles {
  border-radius: 20px;
  border-style: none;
  background: white;
  color: #666666;
  padding: 5px 15px;
}

.detailsModal__comment__buttonFirst {
  border-radius: 5px;
  border-style: none;
  background: white;
  padding: 5px 15px;
  margin-right: 20px;
}

.detailsModal__comment__buttonFirst > span {
  display: inline-block;
  margin-left: 5px;
}

//  .mention {
//     cursor: pointer;
//  }

.select_note_category .ant-select-selector {
  width: 240px !important;
}

.note_popup_action_buttons {
  display: flex;
  margin-top: 20px;
  justify-content: flex-end;
}

.note_popup_action_buttons .ant-btn {
  margin-left: 7px;
  border-radius: 7px;
  padding: 2px 15px;
  font-size: 1rem;
}

.ant-alert-error {
  display: flex;
  align-items: center;
  padding: 7px 14px !important;
}

.ant-alert-error span {
  font-size: 18px !important;
}

.ant-alert-close-icon span {
  font-size: 16px !important;
}

@media only screen and (max-width: 1024px) {
  .task_details_wrapper {
    .ant-modal-header {
      .ant-modal-title {
        font-size: 1.2rem;
      }
    }
  }
}

@media only screen and (max-width: 450px) {
  .task_details_wrapper {
    .ant-modal-header {
      .ant-modal-title {
        font-size: 1.1rem;
      }
    }
    .ant-modal-body {
      padding: 18px;
      .ant-btn-primary {
        border-radius: 5px;
      }
      .task-statuses-dropdown {
        width: 100%;
        margin-bottom: 10px;
        .ant-select {
          width: 100%;
          .ant-select-selector {
            border-radius: 5px;
          }
        }
      }
    }

    .detailsModal__info__container {
      flex-direction: column;
      padding: 0 0 30px;
    }
    .detailsModal__leftSide {
      width: 100% !important;
      div:first-child {
        span {
          margin: 0;
          padding: 4px 0;
          line-height: 1.2;
          font-size: 14px;
          h4 {
            font-size: 15px;
          }
        }
      }
      .unChecked {
        width: 65px !important;
      }
      .button__addDependency {
        font-size: 12px !important;
      }
      .dependency-wrapper {
        margin-top: 26px !important;
      }
    }
    .detailsModal__rightSide {
      .detailsModal__profilePic {
        margin-right: 10px;
      }
      div:first-child {
        margin-top: 0;
        div {
          display: flex;
          flex-wrap: wrap;
          .current_persons_task {
            padding-left: 5px;
          }
        }
      }
      .loggedIn-section {
        display: flex;
        margin-top: 15px !important;
      }

      .remindMe-update {
        .modal__label {
          padding: 0 0 0 10px;
        }
      }
      .detailsModal__timer {
        padding: 7px;
      }
      .reminder-text {
        margin-bottom: 10px;
      }
    }
    // .comments__container__min {
    //   padding: 0;
    //   .comment_wrapper {
    //     padding: 15px 15px;
    //     .comment__icon {
    //       width: 35px;
    //       height: 35px;
    //       font-size: 1.1rem;
    //     }
    //     .comment_data {
    //       margin-left: 10px;
    //       .top_details {
    //         h5 {
    //           font-size: 0.95rem;
    //         }
    //       }
    //     }
    //   }
    //   .reply__container {
    //     width: auto;
    //     top: auto;
    //     left: auto;
    //     .rich-editor-description {
    //       width: auto;
    //     }
    //   }
    // }
    // .detailsModal__comment {
    //   padding: 0;

    //   .rich-editor-description {
    //     width: auto;
    //   }
    // }
    .detailsModal__showInputComment {
      margin: 10px 0 40px;
    }
    .button__addDependency {
      width: auto;
      font-size: 0.85rem;
    }
    .comments__container__full {
      padding-left: 0;
    }
  }
}

@media (max-width: 920px) {
  .task_details_wrapper {
    .ant-modal-body .detailsModal__info__container {
      flex-direction: column-reverse;
      align-items: center;
      .detailsModal__leftSide {
        width: 90%;
      }
      .detailsModal__rightSide {
        width: 100%;
        display: grid;
        grid-template-columns: 50% 50%;
        margin-bottom: 10px;
        justify-content: center;
      }
    }
    // .detailsModal__comment {
    //   justify-content: flex-start;
    //   .comment-input {
    //     width: 95%;
    //     .rich-editor-description {
    //       min-width: 100%;
    //       width: 100%;
    //     }
    //   }
    // }
  }
}

@media (max-width: 575px) {
  .task_details_wrapper {
    .ant-modal-body .detailsModal__info__container {
      .detailsModal__leftSide {
        width: 100%;
      }
      .detailsModal__rightSide {
        display: flex;
        flex-direction: column;
        align-items: center;
        .users-section {
          display: flex;
          flex-direction: column;
          align-items: center;
          .detailsModal__profilePic {
            margin-right: 0px;
          }
        }
        .remindMe-update .input-section {
          margin: 0px;
        }
      }
    }
    // .detailsModal__comment {
    //   .comment-input {
    //     width: 100%;
    //     .rich-editor-description {
    //       min-width: 90%;
    //       width: 100%;
    //     }
    //   }
    // }
  }
}

@media (max-width: 470px) {
  .task_details_wrapper {
    * {
      max-height: 100vh;
    }
    .ant-modal-body {
      padding: 10px;
      // .taskStatusSteper {
      //   width: 96.5%;
      // }
      .detailsModal__info__container .detailsModal__leftSide {
        align-items: center;
        .subtasks-wrapper {
          width: 80%;
          margin-right: 0px;
        }
        .subtask-depend-wrapper {
          width: 100%;
          flex-direction: column;
          align-items: center;
          .dependency-wrapper {
            width: 80%;
            .dependency--container-wrapper {
              text-align: center;
              div {
                justify-content: center;
              }
            }
          }
        }
      }
    }

    .comments-section .detailsModal__comment .comment-input {
      width: 95%;
    }
  }
}
