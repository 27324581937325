@import "../../../../../../index.scss";

$mainDarkBackgroundColor: #20212d;
$cardDarkHeaderColor: #2a2b3a;
$cardDarkBackgroundColor: #12131b;
$inputDarkColor: #2a2b3a;

.reportsCategoryConfig {
  @include flex(center, flex-start, column);
  width: 100%;
  height: 100%;
  padding: 10px;
  row-gap: 10px;
  overflow-x: hidden;

  .backButton {
    @include flex(center, center);
    background-color: #f5f5f7;
    border-radius: 5px;
    cursor: pointer;
    height: 32px;
    width: 32px;

    &:hover {
      background-color: #e7e9ec;
    }
    .btnIcon {
      fill: #323338;
    }
  }

  .categoryConfigPanel {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 10px;
    height: 52px;
    background-color: #ffffff;
    border-radius: 10px;
    transition: 0.2s ease-in;
    box-shadow: 0px 1px 4px 0px #00000026;

    .categoryConfigHeader-div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      .leftSection {
        .goBack-button {
          width: 150px;
        }
      }
      .rightSection {
        display: flex;
        flex-direction: row;
        gap: 10px;
        .searchField {
          width: 300px;
          height: 32px;
        }
      }
      .cloneBtn {
        svg path {
          fill: #fff;
        }
      }
    }
  }

  .reportsContainer {
    width: 100%;
    flex: 1;
    overflow-y: hidden;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 1px 4px 0px #00000026;
    display: flex;
    flex-direction: column;

    .reportsContainer-header {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      height: 45px;
      border-radius: 10px 10px 0px 0px;
      background-color: #f8f8fa;
      padding: 20px;
      gap: 10px;
      .reportsContainer-icon {
        fill: #323338;
      }
      .reportsContainer-title {
        @include text(16px, 600, 0.21, #323338);
      }
    }
    .reportsContainer-items {
      flex: 1;
      overflow-y: auto;
      padding: 10px;
    }
  }

  .footerSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% + 20px);
    height: 52px;
    margin-left: -10px;
    background-color: #ffffff;
    box-shadow: 0px 1px 4px 0px #00000026;
    padding: 10px;
    margin-bottom: -10px;
  }
}

.reportsCategoryConfigDark {
  .categoryConfigPanel {
    background-color: $cardDarkBackgroundColor;

    .rightSection {
      .ant-input-wrapper {
        background-color: $inputDarkColor !important;
        .ant-input-prefix svg {
          fill: #f2f2f2;
        }
        .ant-input {
          background-color: $inputDarkColor !important;
          color: #f2f2f2;
          &::placeholder {
            color: #acacca;
          }
        }
        .ant-input-group-addon {
          background-color: $inputDarkColor !important;
        }
      }
    }
    .tourButton {
      width: 32px;
      .mondayButtonText,
      .mondayButtonDivider {
        display: none;
      }
    }
  }
  .reportsContainer {
    background-color: $cardDarkBackgroundColor;
    .reportsContainer-header {
      background-color: $cardDarkHeaderColor;
      .reportsContainer-icon {
        fill: #fff;
      }
      .reportsContainer-title {
        color: #fff;
      }
    }
  }
  .footerSection {
    background-color: $cardDarkBackgroundColor;
  }
}

@media (max-width: 1440px) {
  .reportsCategoryConfig .categoryConfigPanel {
    display: grid;
    grid-template-columns: 50% 50%;
    .leftSection {
      width: 100%;
      display: flex;
      .backButton {
        margin-right: 10%;
      }
    }
    .filterInput {
      width: 100%;
      align-items: center;
    }
    .rightSection {
      width: 200%;
      margin-top: 5px;
      justify-content: space-between;
      .searchField {
        width: 300px;
      }
    }
  }
}

@media (max-width: 800px) {
  .reportsCategoryConfig {
    .categoryConfigPanel {
      height: auto;
      grid-template-columns: 100%;
      gap: 1em;
      .rightSection {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 1em;
      }
    }
  }
}
