.fleet-live-sider {
  height: 100%;
  width: fit-content;

  ul {
    height: 100%;

    .ant-menu-item {
      border-radius: 0;
      border-top: 1px solid;
      border-bottom: 0;
      margin-top: 0;
      margin-bottom: 0 !important;
      padding-top: 24.5px;
      padding-bottom: 24.5px;

      &:first-child {
        border-top: 0;
      }

      &:last-child {
        border-bottom: 1px solid;
      }
    }

    .ant-menu-item-selected {
      background-color: #41435e;
    }
  }

  .ant-menu-inline {
    width: 200px;
  }

  .ant-menu-inline-collapsed {
    width: 65px;
    .ant-menu-item {
      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .ant-menu-light {
    .ant-menu-item {
      border-color: #dee2eb;

      &:last-child {
        border-bottom: 1px solid #dee2eb;
      }
    }

    .ant-menu-item-selected {
      background-color: #e9e9e9;
      color: #dee2eb;
      svg {
        fill: #1f1f1f;
      }
      span {
        color: #1f1f1f;
      }
    }
  }

  .ant-menu-dark {
    background-color: #12131b;
    border-right: 1px solid #616481 !important;
    .ant-menu-item {
      border-color: #616481;

      &:last-child {
        border-bottom: 1px solid #616481;
      }
    }

    .ant-menu-item-selected {
      svg {
        fill: rgba(255, 255, 239, 1);
      }
      span {
        color: rgba(255, 255, 239, 1);
      }
    }
  }

  .ant-menu-dark.ant-menu-inline-collapsed {
    background-color: #12131b;
  }
}
