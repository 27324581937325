.DateInputContainer {
  display: flex;
  justify-content: center;

  .datePickerInputRentals {
    background-color: #f5f5f7;
    border-radius: 5px;

    .ant-picker-suffix {
      svg {
        fill: #bfcbdc;
      }
    }
  }
}


.datePickerInputRentalsDark{
  background-color: #817474e3;
  border-radius: 5px;
  .ant-picker-input>input{
    color: #fff !important;
  }
}

.datePickerInputRentalsDark.ant-picker-disabled{
  background-color: #b4a6a6e3;
  border-radius: 5px;
  .ant-picker-input>input{
    color: #fff !important;
  }
}
// ant-picker ant-picker-range datePickerInputHeaderRentals ant-picker-disabled
