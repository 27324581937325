@import "../../../../../../index.scss";

$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.inspectionDispatchModal1 {
  height: 500px;
  width: 660px;
  .ant-modal-content {
    .ant-modal-close {
      margin-right: 7px;
    }

    .ant-modal-header {
      background-color: #f5f5f7;
      height: 45px;
      display: flex;
      align-items: center;
      padding: 11.5px 20px;

      .ant-modal-title {
        font-family: "Open Sans";
        font-size: 16px;
        font-weight: 600;
        color: #323338;
      }
    }
    .ant-modal-body {
      display: flex;
      flex-direction: column;
      gap: 20px;
      // justify-content: space-between;
      .info-container {
        display: flex;
        gap: 10px;
        align-items: center;
        font-family: "Open Sans";
        font-size: 14px;
        font-weight: 400;
      }
      .option-controller-container {
        .ant-form-item {
          .ant-row {
            .ant-col {
              .ant-form-item-control-input {
                .ant-form-item-control-input-content {
                  .ant-input-affix-wrapper {
                    height: 32px;
                    border-radius: 5px;
                    background-color: #f5f5f7;
                    border: unset;
                    .ant-input {
                      background-color: #f5f5f7;
                    }
                  }
                }
              }
            }
          }
        }

        .selected-option-container {
          display: flex;
          gap: 10px;
          flex-wrap: wrap;

          .selected-option {
            height: 22px;
            padding: 5px 10px;
            min-width: 80px;
            display: flex;
            width: fit-content;
            align-items: center;
            gap: 12px;
            justify-content: space-between;
            background-color: #f5f5f7;
            font-family: "Open Sans";
            font-size: 12px;
            font-weight: 400;

            svg {
              cursor: pointer;
              height: 8px;
              width: 8px;
            }
          }
        }
      }
      .options-checkbox-container {
        display: flex;
        flex-direction: column;
        gap: 20px;
        .select-all-checkbox {
          .ant-checkbox-wrapper {
            font-family: "Open Sans";
            font-size: 14px;
            font-weight: 400;
            .ant-checkbox {
              .ant-checkbox-inner {
                width: 25px;
                height: 25px;
                border-radius: 2px;
                background-color: #f5f5f7;
                border: unset;

                &:after {
                  // width: 8px;
                  height: 15px;
                  border: 2px solid #000;
                  border-top: 0;
                  border-left: 0;
                }
              }
            }
          }
        }
        .checkbox-list-container {
          display: flex;
          gap: 10px;
          flex-wrap: wrap;

          .report-check {
            width: 31.5%;
            height: 32px;
            background-color: #f5f5f7;
            font-family: "Open Sans";
            font-size: 16px;
            font-weight: 400;
            .checkbox-container {
              background-color: #fe4c4a;
              width: 8px;
              .ant-checkbox-wrapper {
                .ant-checkbox {
                  .ant-checkbox-inner {
                    background-color: #fe4c4a;
                    border-color: #fe4c4a;
                    &:after {
                      display: none;
                    }
                  }
                }
              }
            }
            .container-checked {
              background-color: #71cf48;
              .ant-checkbox-wrapper {
                .ant-checkbox {
                  .ant-checkbox-inner {
                    background-color: #71cf48;
                    border-color: #71cf48;
                    &:after {
                      display: none;
                    }
                  }
                }
              }
            }
            .check-title {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
    }

    .ant-modal-footer {
      display: flex;
      justify-content: space-between;
      padding: 20px 20px;
    }
  }
}

.inspectionDispatchModalDark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      .dispatchCheckboxContainer {
        .ant-form-item {
          .ant-input-wrapper {
            background-color: $inputModalDark !important;
            .ant-input {
              background-color: $inputModalDark !important;
              color: #fff;
              &::placeholder {
                color: #acacca;
              }
            }
            .ant-input-group-addon .ant-btn {
              background-color: $inputModalDark !important;
              svg {
                fill: #fff;
              }
            }
          }
        }
      }
      .dispatchSelectedListContainer {
        .dispatchSelectedListControl {
          .anticon svg {
            fill: #fff;
          }
        }
      }
      .dispatchCheckboxArea {
        .ant-checkbox-wrapper span {
          color: #fff;
        }
      }
    }
  }
}
