@use "src/index.scss" as i;

.maintenance-side-buttons {
  position: fixed;
  top: 105px;
  right: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
}

.side-action-button.mondayButtonContainer {
  height: 40px !important;
  width: 40px !important;

  .mondayButtonStyle {
    height: 40px !important;
    width: 40px !important;
  }

  svg,
  path {
    fill: #fff;
  }

  .mondayButtonStyle:where(:not(.mondayButtonRed)) {
    @include i.mondayButton(#1f2a44, #1f2a44, #323338);
  }
}

.side-action-button.mondayButtonContainer.side-button-dark {
  .mondayButtonStyle:where(:not(.mondayButtonRed)) {
    @include i.mondayButton(#373b5a, #373b5a, #323338);
  }
}
