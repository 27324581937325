$inputModalDark: #20212d;

.predict-autocomplete-container {
  position: relative;
  animation: stretch-animation 800ms ease;
  background-color: #f5f5f7;
  border-radius: 5px;
  z-index: 1;
  height: 32px;
  overflow: hidden;

  .predict-autocomplete-input {
    outline: none;
    border: none;
    background-color: transparent !important;
    position: absolute;
    height: 32px;
    font-weight: 500;
    font-size: 14px;
    z-index: 10 !important;

    .ant-select-selector {
      background-color: transparent !important;
    }
  }

  .predict-autocomplete-suggestion-span {
    width: inherit;
    height: inherit;
    position: absolute;
    z-index: 4;
    top: 0;
    left: 0px;
    display: flex;
    align-items: center;
    padding: 0px 0px 0px 11px;
    font-weight: 400;
    font-size: 14px;
    color: #8d8c8c;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;

    .predict-existing-input {
      color: transparent !important;
    }

    .predict-suggestion {
      white-space: nowrap;
    }
  }
}

.predict-autocomplete-container-dark {
  background-color: $inputModalDark !important;
  .ant-select-selector {
    background-color: $inputModalDark !important;
    color: #fff;
    .ant-select-selection-search-input {
      color: #fff;
    }
    .ant-select-selection-item {
      color: #fff;
    }
    .ant-select-selection-placeholder {
      color: #acacca;
    }
  }
  .ant-select-clear {
    background-color: $inputModalDark;
    svg {
      fill: #acacca;
    }
  }
  .ant-select-arrow svg {
    fill: #fff;
  }
}

.ant-select-disabled {
  .ant-select-selector {
    .ant-select-selection-search-input {
      color: #acacca !important;
    }
    .ant-select-selection-item {
      color: #acacca !important;
    }
  }
}
.predict-autocomplete-suggestion-span {
  color: #acacca;
  .predict-existing-input {
    color: #acacca;
  }
  .predict-suggestion {
    color: #acacca;
  }
}
