.deg-export-menu {
  .ant-cascader-menu {
    height: auto !important;
  }
}
.est-note-modal {
  .ant-modal-footer {
    border-top: none;
  }
}

// min hegiht for addons row that have no data to show
.ag-body-viewport.ag-layout-normal.ag-row-animation {
  min-height: 60px;
}

.take-off-secondary-header__dark {
  display: flex;
  // overflow-x: scroll;
  width: 100%;
  border-radius: 10px;
  height: 50px;
  margin-bottom: 20px;
  align-items: center;
  align-content: center;
  background-color: #12131b;
  // box-shadow: 0px 0px 10px 3px #116d6e;

  // h1 {
  //   width: 100%;
  //   max-height: 60px;
  //   height: fit-content;
  //   font-size: medium;
  //   display: flex;
  //   overflow-x: scroll;
  //   flex-wrap: wrap;
  //   max-width: 300px;
  // }
  .service-menu-toggle {
    width: 42px;
    height: 42px;
    background: #1264a3;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
    border: none;
  }
  .icon-btn-holder {
    width: 32px;
    height: 32px;
    background: #1264a3;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    svg {
      margin-right: 1px;
      color: white;
      fill: white !important;
    }
    .disabled {
      opacity: 0.5;
      background-color: none;
      cursor: not-allowed;
    }
    button {
      &:disabled {
        width: 32px;
        height: 32px;
        background: grey;
        border-radius: 5px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
      }
    }
  }
  .ant-switch-checked {
    background-color: #71cf48 !important;
    // width: 140px;
  }
  .takeoff-auto-save-switch {
    background-color: #1264a3;
    margin-right: 20px;
  }
  .take-off-secondary-header-btn-cont {
    margin-right: 20px;
  }
  .editLogs {
    // display: none;

    > svg {
      margin-top: 1px;
      > path {
        fill: white;
      }
    }
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background: #1f212d;
    border: none;
    color: #fff;
    border-radius: 2px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .show-on-dropdown {
    background: #1f212d;
    border: none;
    color: #fff;
    width: 160px;
    border-radius: 5px;
    display: flex;
    justify-content: flex-start;
  }
  .ant-select-selector {
    .show-on-dropdown {
      background: #1f212d;
      border: none;
      color: #fff;
      width: 160px;
      border-radius: 5px;
      display: flex;
      justify-content: flex-start;
    }
  }
}

.take-off-secondary-header__light {
  display: flex;
  // overflow-x: scroll;
  width: 100%;
  border-radius: 10px;
  height: 50px;
  margin-bottom: 20px;
  align-items: center;
  align-content: center;
  background: #ffffff;
  // h1 {
  //   width: 100%;
  //   max-height: 60px;
  //   height: fit-content;
  //   font-size: medium;
  //   display: flex;
  //   overflow-x: scroll;
  //   flex-wrap: wrap;
  //   max-width: 300px;
  // }
  .dataEntryTitle {
    color: #fff !important;
    background-color: #334928 !important;
  }
  .service-menu-toggle {
    width: 42px;
    height: 42px;
    background: #1264a3;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
    border: none;
  }
  .icon-btn-holder {
    width: 32px;
    height: 32px;
    background: #1264a3;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    svg {
      margin-right: 1px;
      color: white;
      fill: white !important;
    }
    .disabled {
      opacity: 0.5;
      background-color: none;
      cursor: not-allowed;
    }
    button {
      &:disabled {
        width: 32px;
        height: 32px;
        background: grey;
        border-radius: 5px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
      }
    }
  }
  .ant-switch-checked {
    background-color: #71cf48 !important;
    // width: 140px;
  }
  .takeoff-auto-save-switch {
    background-color: #1264a3;
    margin-right: 20px;
  }
  .take-off-secondary-header-btn-cont {
    margin-right: 20px;
  }
  .editLogs {
    // display: none;

    > svg {
      margin-top: 1px;
      > path {
        fill: white;
      }
    }
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background: #f5f5f7;
    border: none;
    color: #fff;
    border-radius: 2px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .show-on-dropdown {
    background: #f5f5f7;
    border: none;
    // color: #fff;
    width: 160px;
    border-radius: 5px;
    display: flex;
    justify-content: flex-start;
  }
  .ant-select-selector {
    .show-on-dropdown {
      background: #f5f5f7;
      border: none;
      color: #fff;
      width: 160px;
      border-radius: 5px;
      display: flex;
      justify-content: flex-start;
    }
  }
}

@keyframes slide {
  0% {
    transform: translateY(-50%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes slideFromBotton {
  0% {
    transform: translateY(50%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes slideFromLeft {
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0%);
  }
}
