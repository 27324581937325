@import "../../../../../../../index.scss";

.general-reports-cards {
  height: 100%;
  display: grid;
  gap: 20px;
  column-gap: 50px;
  grid-template-columns: repeat(auto-fit, 353px);
  padding: 20px;
  overflow-y: auto;
}

.general-reports-container {
  @include cardLayout();
  height: calc(100% - 55px);
  // margin-bottom: 40px;
  .general-no-reports {
    display: flex;
    justify-content: center;
    align-items: center;
    // height: calc(100vh - 330px);
    height: 100%;
    .general-no-report-title {
      font-size: 2vw;
      color: #d8dde6;
    }
  }
}
.general-reports-pages {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
