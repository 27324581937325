.automation-step {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  .automation-functions {
    width: 330px;
    overflow: auto;
    background-color: #f8f8fa;
    padding: 10px;
    border-radius: 5px;

    display: flex;
    flex-direction: column;
    gap: 10px;
    .automation-function {
      background: #ffffff;
      .custom-checkbox-wrapper {
        background: inherit;
      }
    }
  }
  .boarding-navigation-buttons {
    margin: 0;
  }
}
