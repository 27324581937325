@import "../../../../../index.scss";

$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.reportDescriptionModal {
  width: calc(100vw - 20px) !important;
  height: calc(100vh - 20px) !important;
  max-width: 1000px;
  max-height: 350px;
  .ant-modal-content {
    width: calc(100vw - 20px) !important;
    height: calc(100vh - 20px) !important;
    max-width: 1000px;
    max-height: 350px;
    .ant-modal-header {
      display: flex;
      align-items: center;
      height: 45px;
      background-color: #f5f5f7;
      .ant-modal-title {
        color: #323338;
        font-size: 16px;
        font-weight: 600;
        width: 100%;
      }
    }
    .ant-modal-body {
      height: calc(100% - 100px);
    }
    .ant-modal-footer {
      .footerContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .mondayButtonStyle {
          width: 150px;
        }
      }
    }
  }
  .reportDescriptionContainer {
    @include flex(flex-end, center, column);

    .mondayButtonContainer {
      margin-top: 20px;
      .saveBtn {
        animation: fadeIn 1000ms both;
      }
    }
  }
}

.reportDescriptionModalDark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    overflow: hidden;
    .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark !important;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      background-color: $cardModalBodyDark;
      .tox {
        .tox-editor-container {
          background-color: $cardModalBodyDark;
        }
        .tox-menubar {
          background-color: $cardModalBodyDark;
        }
        .tox-toolbar-overlord {
          background-color: $cardModalBodyDark;
        }
        .tox-tbtn {
          color: #fff;
          &:focus {
            background-color: $inputModalDark;
          }
          &:hover {
            background-color: $inputModalDark;
          }
          svg {
            fill: #fff;
          }
        }
        .tox-tbtn--disabled {
          color: #acacca;
          svg {
            fill: #acacca;
          }
        }
        .tox-split-button {
          border-color: $inputModalDark !important;
          &:hover {
            border-color: $inputModalDark !important;
            background-color: $inputModalDark;
          }
          &:focus {
            border-color: $inputModalDark !important;
            background-color: $inputModalDark;
          }
        }
      }
    }
  }
}
