@import "../../../../../../../index.scss";

$cardModalHeaderDark: #12131b;

.updateEmployeesModal {
  width: 900px !important;
  .updateEmployeesModalFormSection {
    padding: 0 24px;
    max-height: 80dvh;
    overflow-y: scroll;
    display: flex !important ;
    flex-wrap: wrap !important;
    gap: 24px !important;

    .ant-form-item {
      width: 250px;
    }
  }
}

.updateEmployeesModalDark {
  .ant-modal-content {
    background-color: $mainDarkBackgroundColor;
    .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardDarkBackgroundColor;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      .uploadEmployeeText {
        color: #fff;
      }
      .ant-steps {
        .ant-steps-item-finish ::before,
        .ant-steps-item-active::before,
        .ant-steps-item::before {
          border-left-color: $mainDarkBackgroundColor;
        }
        .ant-steps-item-wait {
          background-color: $cardDarkBackgroundColor;
          &::after {
            border-left-color: $cardDarkBackgroundColor;
          }
          .ant-steps-item-content .ant-steps-item-title {
            color: #acacca;
          }
        }
      }
    }
  }
}
