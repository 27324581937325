$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.selectCategorySecion {
  display: grid;
  align-items: center;
  gap: 15px;
  width: 100%;
  .selectCategorySingleSetion {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .selectLeftSide {
      display: grid;
      .spanTitle {
        font-weight: 500;
        color: #323338;
        font-family: Open Sans;
        font-size: 16px;
      }
    }
  }
  .geofence-section {
    .geofence-section-title {
      display: flex;
      border-radius: 10px 10px 0px 0px;
      background: #f8f8fa;
      height: 45px;
      width: 100%;
      color: #323338;
      font-family: Open Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      align-items: center;
      padding-left: 10px;
    }
  }
}
.newGeofenceShortcut {
  .ant-modal-content {
    .ant-modal-close {
      height: 45px;
      width: 45px;
      .ant-modal-close-x {
        height: 45px;
        width: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          fill: #323338;
        }
      }
    }
    .ant-modal-header {
      height: 45px;
      display: flex;
      align-items: center;
      background-color: #f5f5f7;
      .ant-modal-title {
        color: #323338;
        font-size: 16px;
        font-weight: 600;
      }
    }
    .ant-modal-body {
      .warningContainer {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #fe4c4a;
        padding-bottom: 12px;
        gap: 10px;
        svg {
          font-size: 20px;
          // background-color: white;
          color: #fe4c4a;
        }
      }
      .modal-inputs {
        display: flex;
        flex-direction: column;
        gap: 20px;
        .fieldContainer {
          width: 100%;
          display: flex;
          flex-direction: column;
          .fieldLabel {
            font-size: 16px;
            font-weight: 600;
            color: #323338;
            margin-bottom: 5px;
            padding-left: 5px;
          }
          .ant-input {
            background-color: #f5f5f7;
            border: none;
          }
          .ant-select {
            .ant-select-selector {
              background-color: #f5f5f7;
              border: none;
              .ant-select-selection-search-input {
                background-color: #f5f5f7;
              }
            }
          }
        }
      }
    }
    .ant-modal-footer {
      .modal-footer-mappingConfig {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
}

.newGeofenceShortcut-dark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close-x svg {
      fill: #fff !important;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      .spanTitle {
        color: #fff !important;
      }
      .ant-select {
        .ant-select-selector {
          background-color: $inputModalDark !important;
          .ant-select-selection-search-input {
            color: #fff;
          }
          .ant-select-selection-item {
            color: #fff;
          }
          .ant-select-selection-placeholder {
            color: #acacca;
          }
        }
        .ant-select-clear {
          background-color: $inputModalDark;
          svg {
            fill: #acacca;
          }
        }
        .ant-select-arrow svg {
          fill: #fff;
        }
      }
      .ant-select-disabled {
        .ant-select-selector {
          .ant-select-selection-search-input {
            color: #acacca !important;
          }
          .ant-select-selection-item {
            color: #acacca !important;
          }
        }
      }
      .geofence-section {
        .geofence-section-title {
          background: $cardModalHeaderDark;
          color: #fff;
        }
      }
    }
  }
}
