.add-filter-button-container {
  position: relative;
}

.line-button-container {
  position: relative;
  height: 50px;
  margin: 5px 0;
}

.line-button-container::before,
.line-button-container::after {
  content: "";
  position: absolute;
  top: 50%;
  height: 0.1px;
  background-color: gray;
  transform: translateY(-50%);
}

.line-button-container::before {
  left: 0;
  width: 3%; /* Adjust the width of the left line to stop at 3% */
}

.line-button-container::after {
  left: calc(3% + 60px); /* Start the line after the button */
  right: 0; /* Extend the line till the end of the container */
}

.offset-button {
  font-size: 16px;
  position: absolute;
  top: 50%;
  left: 3%;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  padding: 5px 10px;
  z-index: 1; /* Place the button over the lines */
}

.offset-button:hover {
  text-decoration: underline;
  text-underline-offset: 4px; /* Adjust the value to get the desired spacing */
}

.button-list {
  width: fit-content;
  position: absolute;
  top: 20px;
  left: calc(3% + 60px);
  background-color: #fff;
  z-index: 10;
  cursor: pointer;
}

/* Styling for disabled button */
.offset-button:disabled {
  color: black; /* Set color to black when disabled */
  pointer-events: none; /* Disable all mouse events */
}

/* Remove hover effects when disabled */
.offset-button:disabled:hover {
  text-decoration: none; /* Remove underline */
}


.add-filter-button-container-dark{
  .offset-button{
    color: #fff;
  }
}