.tittleContainerRentalsDetails {
  display: flex;
  gap: 60px;

  .tittleContainer {
    margin-right: 40px;
  }

  .informationContainer {
    display: flex;
    gap: 10px;
    font-size: 14px;
    justify-content: center;
    align-items: center;

    .iconRentalContainer {
      background-color: red;
      width: 18px;
      height: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      margin-left: -2px;
    }
  }
}
