@import "/src/index.scss";

.conflicts-wrap-table-modal {
  &::before {
    display: none !important;
  }
  .conflicts-table-modal {
    height: 100vh !important;
    width: 100vw !important;
    max-height: 100vh !important;
    max-width: 100vw !important;
    .ant-modal-content {
      height: calc(100vh - 0px) !important;
      max-height: 100vh;
      width: 100vw !important;
      max-width: 100vw;
      border-radius: 0px;
      transition: 0.5s ease;
      background-color: #f8f8fa;
      .ant-modal-close {
        height: 45px;
        width: 45px;
        .ant-modal-close-x {
          height: 45px;
          width: 45px;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            fill: #fff;
          }
        }
      }
      .ant-modal-header {
        height: 45px;
        display: flex;
        align-items: center;
        background-color: #222a44;
        border-radius: 0px;
        .ant-modal-title {
          color: #fff;
        }
      }
      .ant-modal-body {
        height: calc(100% - 97px);
        padding: 10px !important;
        .control-panel {
          height: 52px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 10px;
          border-radius: 10px;
          padding: 0px 10px;
          background-color: #fff;
          box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
          .conflicts-info {
            display: flex;
            align-items: center;
            gap: 10px;
          }
          .labeledInputContainer {
            width: 100%;
            max-width: 300px;
            .ant-form-item {
              margin-bottom: 0px;
            }
          }
        }

        .ant-table-cell {
          padding: 12px;
          white-space: nowrap;
        }
        .selected {
          background-color: #00a464 !important;
          .ant-table-cell {
            background-color: #00a464 !important;
            color: #fff;
          }
        }
        .ant-table-cell-row-hover {
          background-color: #00000011;
        }
        .tables-wrapper {
          width: 100%;
          height: calc(100vh - 180px);
          display: flex;
          gap: 20px;
          padding: 20px;
          background-color: #fff;
          box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
          border-radius: 10px;
          .table-container {
            height: 100%;
            width: calc(50% - 10px);
            display: flex;
            flex-direction: column;
            gap: 10px;
            .control-header {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 0px 10px;
              .table-title {
                width: calc(50% + 70px);
                font-weight: 600;
              }
              .select-all,
              .de-select-all {
                justify-self: flex-start;
                cursor: pointer;
                user-select: none;
                padding-right: 2px;
                .icon {
                  display: none;
                  svg {
                    width: 14px;
                    height: 14px;
                  }
                }
                &:hover {
                  color: $blue;
                  .icon {
                    display: inline;
                    svg {
                      fill: $blue;
                    }
                  }
                }
              }
              .de-select-all:hover {
                color: $red;
                .icon svg {
                  height: 12px;
                  width: 12px;
                  fill: $red;
                }
              }
            }
            .ant-table-wrapper {
              height: calc(100% - 42px) !important;
              .ant-spin-nested-loading {
                height: 100%;
              }
              .ant-spin-container {
                height: 100%;
                background-color: #f8f8fa;
                border-radius: 10px;
                .ant-table {
                  height: 100%;
                  .ant-table-container {
                    height: 100%;
                    .ant-table-thead .ant-table-cell {
                      background-color: #f8f8fa;
                    }
                    .ant-table-body {
                      max-height: calc(100% - 47px) !important;
                      overflow-y: auto !important;
                      scrollbar-width: auto;
                      scrollbar-color: auto;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .ant-modal-footer {
        display: flex;
        justify-content: space-between;
        background-color: #fff;
        .mondayButtonStyle {
          min-width: 150px;
        }
      }
    }
  }

  .conflicts-table-modal-dark {
    .ant-modal-content {
      background-color: $mainDarkBackgroundColor;
      .ant-modal-close-x svg {
        fill: #fff;
      }
      .ant-modal-header {
        background-color: $cardDarkBackgroundColor;
        .ant-modal-title {
          color: #fff;
        }
      }
      .ant-modal-body {
        .selected {
          background-color: #01794b !important;
          .ant-table-cell {
            background-color: #01794b !important;
          }
        }
        .control-panel {
          background-color: $cardDarkHeaderColor;
          .conflicts-info {
            color: #fff;
          }
          .labeledInputContainer {
            .ant-input-group-addon {
              border-left: 1px solid #acacca !important;
              .ant-select-selector {
                .ant-select-selection-overflow-item .ant-select-selection-item {
                  background-color: #323338;
                  .anticon svg {
                    fill: #fff;
                  }
                }
                .ant-select-clear .anticon svg {
                  fill: #acacca !important;
                }
              }
            }
          }
        }
        .tables-wrapper {
          background-color: $cardDarkHeaderColor;
          .table-container {
            .control-header {
              .de-select-all,
              .select-all,
              .table-title {
                color: #fff;
              }
            }
            .ant-table-wrapper {
              .ant-spin-container {
                box-shadow: 0 0 3px 1px rgba($color: #fff, $alpha: 0.1);
                .ant-table {
                  background-color: $cardDarkHeaderColor;
                  .ant-table-container {
                    .ant-table-header {
                      background-color: $cardDarkBackgroundColor !important;
                      .ant-table-cell-scrollbar {
                        background-color: $cardDarkBackgroundColor !important;
                      }
                      .ant-table-cell:not(.ant-table-cell-scrollbar) {
                        background-color: $cardDarkBackgroundColor;
                        border-bottom: 1px solid #acacca;
                        color: #fff;
                        &::before {
                          background-color: #acacca;
                        }
                      }
                    }
                    .ant-table-body {
                      .ant-table-cell {
                        border-bottom: 1px solid #acacca;
                        color: #fff;
                        background-color: $cardDarkHeaderColor;
                        .ant-empty-normal {
                          .ant-empty-description {
                            color: #fff;
                          }
                        }
                      }
                      .selected .ant-table-cell {
                        background-color: transparent;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .ant-modal-footer {
        background-color: $cardDarkHeaderColor;
      }
    }
  }
}
