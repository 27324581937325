@import "/src/index.scss";

.employees-table-container {
  width: 100%;
  .table-control-panel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    .uploadEmployeeInfo {
      width: fit-content !important;
      margin-bottom: 0px !important;
      p {
        margin: 0px;
      }
    }
    .labeledInputContainer {
      width: 100%;
      max-width: 300px;
    }
    .ant-form-item {
      margin-bottom: 0px;
    }
    .conflicts {
      display: flex;
      align-items: center;
      gap: 5px;
      color: $red;
      cursor: pointer;
      user-select: none;
      svg {
        height: 15px;
        width: 17px;
        fill: $red;
      }
      .conflictsCount {
        font-weight: 600;
      }
    }
  }
  .payroll-grid {
    height: calc(100vh - 250px);
    width: 100%;
    transition: 0.5s ease;
  }
  .remove-member-cell {
    user-select: none;
    cursor: pointer;
    &:hover {
      color: $red;
      font-weight: 600;
    }
  }
}

.employees-table-container-dark {
  .table-control-panel {
    .ant-input {
      background-color: $cardDarkBackgroundColor !important;
      color: #fff;
      &::placeholder {
        color: #acacca;
      }
    }
  }
  .payroll-grid {
    .ag-unselectable {
      background-color: $cardDarkHeaderColor !important;
    }
    .ag-header-cell {
      background-color: $cardDarkBackgroundColor !important;
    }
  }
}
