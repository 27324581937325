$backgroundDark: #12131b;
$inputDark: #3a3c4e;
$darkModeWhite: #f4f5f6;

.proposal_card_draft_wrapper {
  height: 282px;
  display: flex;
  cursor: pointer;
  border-radius: 7px;
  align-items: center;
  flex-direction: column;
  width: 300px !important;
  background-color: #d9d9d9;
  transition: background-color 0.3s;

  &:hover {
    background-color: scale-color($color: #d9d9d9, $lightness: -10%);
  }
  .cardHeader,
  .cardFooter {
    width: 100%;
    padding: 10px;
    display: flex;
    background-color: #f4f5f6;
  }
  .cardHeader {
    gap: 10px;
    height: 44px;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    border-radius: 5px 5px 0 0;
    justify-content: space-between;
    > span {
      gap: 5px;
      width: 90%;
      display: flex;
      align-items: center;
      .title-text {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    svg {
      width: 24px;
    }
  }
  .cardBody {
    width: 100%;
    height: 175px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .cardFooter {
    gap: 5px;
    height: 63px;
    flex-direction: column;
    justify-content: center;
    border-radius: 0 0 5px 5px;
    .footer-row {
      gap: 10px;
      display: flex;
    }
  }
}

.proposal_card_draft_wrapper.dark {
  color: $darkModeWhite;
  background-color: #3a3c4e;
  &:hover {
    background-color: scale-color($color: #3a3c4e, $lightness: 10%);
  }

  .cardHeader,
  .cardFooter {
    background-color: $backgroundDark;
    svg {
      fill: $darkModeWhite;
    }
  }
}
