@import "../../../../../index.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: end;
}

.rowContainer {
  display: flex;
  gap: 24px;
  align-items: start;
}

.rowButtons {
  display: flex;
  gap: 8px;
  align-items: center;
  margin-top: 22px;
}

.upBtnDark,
.downBtnDark {
  background-color: $cardDarkBackgroundColor;
  border: $cardDarkBackgroundColor;
  color: #f2f2f2;
}

.inputContainer {
  display: flex;
  gap: 24px;
  flex-flow: wrap;
}

.label {
  color: #767b81;
}

.EditInputComponent {
  width: 250px;
  height: 33px;
  background: #f5f5f7 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 5px;
  border: 0px;
}

.EditInputComponent :global(.form-control) {
  width: 100% !important;
}

.EditDropDownComponent :global(.ant-select-selector) {
  width: 250px !important;
  background: #f5f5f7 0% 0% no-repeat padding-box !important;
}

.EditDropDownComponent :global(.ant-select-dropdown) {
  background: #f5f5f7 0% 0% no-repeat padding-box !important;
}

.EditDropDownComponent :global(.ant-select-item-option-active) {
  background: #f5f5f7 0% 0% no-repeat padding-box !important;
}

.EditDropDownComponent :global(.ant-select-item-option-selected) {
  background: #f5f5f7 0% 0% no-repeat padding-box !important;
}
