$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.compose-new-email-shortcut {

  &.dark {
    .ant-modal-content {
      background-color: $cardModalBodyDark;

      .ant-modal-close-x svg {
        fill: #fff !important;
      }

      .ant-modal-header {
        background-color: $cardModalHeaderDark;

        .ant-modal-title {
          color: #fff;
        }
      }

      .ant-modal-body {
        .spanTitle {
          color: #fff !important;
        }

        .ant-select {
          .ant-select-selector {
            background-color: $inputModalDark !important;

            .ant-select-selection-search-input {
              color: #fff;
            }

            .ant-select-selection-item {
              color: #fff;
            }

            .ant-select-selection-placeholder {
              color: #acacca;
            }
          }

          .ant-select-clear {
            background-color: $inputModalDark;

            svg {
              fill: #acacca;
            }
          }

          .ant-select-arrow svg {
            fill: #fff;
          }
        }

        .ant-select-disabled {
          .ant-select-selector {
            .ant-select-selection-search-input {
              color: #acacca !important;
            }

            .ant-select-selection-item {
              color: #acacca !important;
            }
          }
        }

      }
    }
  }
}