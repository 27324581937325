$cardModalBodyDark: #1f212d;

.memberTeamModal {
  width: fit-content !important;
  overflow: hidden;
  padding: 0 !important;
  border-radius: 10px !important;
  .ant-modal-content {
    overflow: hidden;
    .ant-modal-body {
      padding-right: 0px;
    }
  }
}

.transferEditBody {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  width: 100%;
  height: auto;
  color: #231f20 !important;
  .transferEditedContainer {
    display: flex;
    max-height: 400px;
    overflow: hidden;
    justify-content: center;

    .unSelectedContainer {
      display: flex;
      flex-direction: column;
      height: 382px;
      overflow: auto;
      gap: 10px;
      overflow-x: hidden;
      .selectedContainerBody {
        overflow-x: hidden;
        height: 100%;
        overflow-y: auto;
        background-color: #f4f5f6;
        padding: 10px;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        gap: 5px;
        width: 300px;
      }
      .checkBoxStyle {
        background-color: #fff;
        margin: 0px;
      }
    }

    .middleContainer {
      width: 100px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 25px;
      margin-right: 5px;
      .circleStyle {
        width: 44px;
        height: 44px;
        display: grid;
        place-items: center;
        border-radius: 5px;
      }
    }
    .selectedContainer {
      @extend .unSelectedContainer;
    }
    .countItemsLabel {
      text-align: center;
      font-weight: 600;
      font-size: 14px;
    }
    .checkBoxStyle {
      width: 100%;
      background-color: #fff;
      margin: 0px;
      &.active {
        animation: scale-display1 0.3s;
        display: inline-flex;
      }

      &.out {
        animation: scale-display--reversed1 0.3s;
        animation-fill-mode: forwards;
        display: inline-flex;
      }
    }

    .checkedRedStyle {
      display: flex;
      align-items: center;
      background-color: #fe4c4a !important;
    }
    .navyRedStyle {
      background-color: #eb4345 !important;
    }
    .checkedGreenStyle {
      display: flex;
      align-items: center;
      background-color: #71cf48 !important;
    }
    .navyGreenStyle {
      background-color: #68c240 !important;
    }
  }

  .scrollBottomContainer {
    margin-bottom: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(0deg);
    animation: rotateBottom 0.5s linear;
  }
  .scrollTopContainer {
    margin-bottom: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: rotateTop 0.5s linear;
    transform: rotate(180deg);
  }
  .confirmContainer {
    display: flex;
    margin-top: unset !important;
    justify-content: space-between;
    gap: 20px;
    @media screen and (min-width: 1600px) {
      .mondayButtonStyle {
        width: 150px;
      }
    }
  }
}

.transferEditBodyDark {
  .transferEditedContainer {
    .unSelectedContainer {
      .selectedContainerBody {
        background-color: #2a2b3a;
      }
    }
    .selectedContainer {
      @extend .unSelectedContainer;
    }
  }
  .confirmContainer {
    background-color: $cardModalBodyDark;
  }
}

@keyframes scale-display1 {
  0% {
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(300px);
  }
}

@keyframes scale-display--reversed1 {
  0% {
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(-300px);
  }
}
