.application-main-header-dark {
  background: #12131b;
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 50px;
  border: 0;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;

  .applicationMainHeaderTitleDiv {
    margin-left: 20px !important;
    margin-bottom: 0;
    width: fit-content;
    color: white;
    font-size: 18px;
    margin-top: 0px;
    font-weight: 500;
  }

  .application-main-header-options {
    display: flex;
    align-items: center;
    color: white;
    gap: 20px;

    .lightDarkModeToggleContainer {
      width: 22px;
      height: 22px;
      border-radius: 5px;
      background: #f8f8f8;
      display: flex;
      align-items: center;

      .darkModeToggleDivContainer {
        .firstSquare {
          background: #f8f8f8;
        }
        .secondSquare {
          background: #f8f8f8;
        }
        .innerCircle {
          background: #f8f8f8;
        }
        .outerCircle {
          background: #2a2828;
        }
      }
    }
  }

  /* CLOSE BUTTON */
  .close-btn {
    font-size: 1.5rem;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
    margin-right: 10px;
    margin-left: 20px;
  }
}

.application-main-header-light {
  background: #1264a3;
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 50px;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;

  .applicationMainHeaderTitleDiv {
    margin-left: 20px !important;
    width: fit-content;
    color: white;
    font-size: 18px;
    margin-bottom: 0;
    margin-top: 0;
    font-weight: 500;
  }

  .application-main-header-options {
    display: flex;
    align-items: center;
    color: white;
    gap: 20px;

    .lightDarkModeToggleContainer {
      width: 22px;
      height: 22px;
      border-radius: 5px;
      background: #f8f8f8;
      display: flex;
      align-items: center;

      .lightModeToggleDivContainer {
        .firstSquare {
          background: #f8f8f8;
        }
        .secondSquare {
          background: #f8f8f8;
        }
        .innerCircle {
          background: #f8f8f8;
        }
        .outerCircle {
          background: #0c548b;
        }
      }
    }
  }

  /* CLOSE BUTTON */
  .close-btn {
    font-size: 1.5rem;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
    margin-right: 10px;
    margin-left: 20px;
  }
}
