@import "../../../../index.scss";

$headerDarkBackground: #12131b;
$bodyDarkBackground: #1f212d;
$inputDarkColor: #12131b;

.chartModal {
  .ant-modal-content {
    width: unset !important;
    min-height: 300px;
    height: fit-content;

    .ant-modal-header {
      height: 45px;
      display: flex;
      align-items: center;
      background: #f8f8fa;
      border-radius: 10px 10px 0 0;

      .ant-modal-title {
        font-weight: 600;
        font-size: 16px;
        color: #323338;
      }
    }

    .ant-modal-body {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .chartType {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 20px;
        .mondayButtonContainer {
          .mondayButtonStyle {
            justify-content: space-between !important;
            min-width: 150px;
            background-color: #f5f5f7 !important;
            .mondayButtonText {
              text-align: start !important;
              color: #323338 !important;
            }
            .mondayButtonDivider {
              display: none !important;
            }
            svg {
              fill: #323338 !important;
            }
          }
          & .mondayButtonStyle:hover,
          & .activeButton {
            background-color: #f1f5fd !important;
            .mondayButtonText {
              color: #1264a3 !important;
            }
            svg {
              fill: #1264a3 !important;
            }
          }
        }
      }
    }
    .chartOptionsAndTime {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      .chartOption {
        height: 270px;
        // width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        overflow-x: hidden;
        overflow-y: auto;

        & .ant-radio-wrapper {
          margin-top: 5px;
          cursor: pointer;
        }
      }
    }
  }

  .ant-modal-footer {
    padding: 20px !important;
    display: flex;
    justify-content: space-between;
  }
  @media screen and (min-width: 1300px) {
    width: 870px !important;
    .ant-modal-body {
      .chartOptionsAndTime .labeledInputContainer {
        width: 200px !important;
        .labeledInputLabel {
          font-size: 14px !important;
        }
      }
    }
    .ant-modal-footer button {
      width: 150px !important;
    }
  }
}

.chartModalDark {
  .ant-modal-content {
    background-color: $bodyDarkBackground;
    .ant-modal-close-x {
      svg {
        fill: #fff;
      }
    }
    .ant-modal-header {
      background-color: $headerDarkBackground;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      background-color: $bodyDarkBackground;
      .labeledInputLabel {
        color: #fff;
      }
      .chartInfo span {
        color: #fff;
      }
      .chartType {
        color: #fff;
        .unactiveButton {
          @include mondayButton(#35353d, #2d2d35, #fff);
        }
      }
      .chartOption .ant-radio-wrapper {
        color: #fff;
      }
    }
    .ant-modal-footer {
      background-color: $bodyDarkBackground;
    }
  }
}

.chartComponent {
  height: fit-content !important;
  width: fit-content !important;
  .ant-modal-content {
    height: calc(100vh - 20px) !important;
    width: calc(100vw - 20px) !important;
    max-width: 1850px;
    max-height: 700px;
    border-radius: 10px;
    background-color: white;
    .ant-modal-close {
      height: 45px;
      width: 45px;
      .ant-modal-close-x {
        height: 45px;
        width: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .ant-modal-header {
      height: 45px;
      display: flex;
      align-items: center;
      background: #f8f8fa;
      border-radius: 10px 10px 0 0;
      border-bottom: 0;
      .ant-modal-title {
        font-style: "Open Sans";
        font-weight: 600;
        font-size: 16px;
        color: #323338;
      }
    }
    .ant-modal-body {
      height: calc(100% - 100px);

      .chartContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
      }
    }
  }
}

.chartComponentDark {
  .ant-modal-content {
    background-color: $bodyDarkBackground;
    .ant-modal-close-x {
      svg {
        fill: #fff;
      }
    }
    .ant-modal-header {
      background-color: $headerDarkBackground;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      background-color: $bodyDarkBackground;
      .chartInfo span {
        color: #fff;
      }
      .chartType {
        color: #fff;
        .unactiveButton {
          @include mondayButton(#35353d, #2d2d35, #fff);
        }
      }
      .chartOption .ant-radio-wrapper {
        color: #fff;
      }
    }
    .ant-modal-footer {
      background-color: $bodyDarkBackground;
    }
  }
}

@media (max-width: 900px) {
  .chartModal {
    .ant-modal-content {
      width: 100% !important;
      transform: translateX(0%) !important;
    }
    .chartType {
      height: fit-content !important;
      display: grid !important;
      grid-template-columns: 45% 45%;
      gap: 10px;
      .mondayButtonContainer {
        display: flex;
        justify-content: center;
      }
    }
  }
}

@media (max-width: 400px) {
  .chartModal {
    .chartType .mondayButtonContainer .mondayButtonStyle {
      font-size: 12px;
    }
    .ant-modal-content
      .ant-modal-footer
      .mondayButtonContainer
      .mondayButtonStyle {
      width: 100px;
    }
    .chartOption {
      height: 300px !important;
      overflow-y: scroll !important;
      box-shadow: 0 2px 2px -2px gray;
    }
  }
}
