

.sideBarContainerRetractedDark {
  margin: 10px 0;
  border-radius: 10px;
  width: 0;
  height: stretch;
  min-height: 745px;
  overflow: hidden;
  margin-right: -20px;


  .headerContainer {
    width: stretch;
    height: 45px;
    background-color: #292b3a;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
      "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 16px;
    color: white;
    display: flex;
    align-items: center;
    padding: 0 20px;
  }
  animation: fadeOutLeft 0.3s;
}

.sideBarContainerDark {
  margin: 10px 0;
  border-radius: 10px;
  width: 300px;
  height: stretch;
  min-height: 745px;
  overflow: hidden;
  background-color: #1F212D;
  transition: background-color 0.3s ;

  .headerContainer {
    width: stretch;
    height: 45px;
    background-color: #292b3a;
    transition: background-color 0.3s ;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
      "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 16px;
    color: white;
    display: flex;
    align-items: center;
    padding: 0 20px;
  }
  animation: fadeInLeft 0.3s;
}

.sideBarContainerRetractedLight {
  margin: 10px 0;
  border-radius: 10px;
  width: 0;
  height: stretch;
  min-height: 745px;
  overflow: hidden;
  margin-right: -20px;

  .headerContainer {
    width: stretch;
    height: 45px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
      "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 16px;
    color: black;
    display: flex;
    align-items: center;
    padding: 0 20px;
  }
  animation: fadeOutLeft 0.3s;
}

.sideBarContainerLight {
  margin: 10px 0;
  border-radius: 10px;
  width: 300px;
  height: stretch;
  min-height: 745px;
  overflow: hidden;
  background-color: white;
  transition: background-color 0.3s ;


  .headerContainer {
    width: stretch;
    height: 45px;
    background-color: #f5f7f9;
    transition: background-color 0.3s ;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
      "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 16px;
    color: black;
    display: flex;
    align-items: center;
    padding: 0 20px;
  }

  animation: fadeInLeft 0.3s;
}


@keyframes fadeOutLeft {
  from {
    width: 300px;
    margin-right: 0;
  }

  to {
    margin-right: -20px;
    width: 0;
  }
}

@keyframes fadeInLeft {
  from {
    margin-right: -20px;
    width: 0;
  }
  to {
    margin-right: 0;
    width: 300px;
  }
}