@import "../../../../../../../../index.scss";

.price-calculator-totals-section {
  border: 1px solid #dfe3ec;
  border-radius: 5px;
  padding: 0 20px;
  padding-top: 24px;
  .testtest {
    position: absolute;
    top: 8px;
    right: 500px;
    @include text(16px, 700, 0, #323338);
    .ant-form-item {
      margin: 0 !important;
      .ant-col {
        font-size: 16px !important;
      }
    }
  }

  .totals-inputs-container {
    @include flex(center, center);
    flex-wrap: wrap;
    gap: 20px;
  }

  .price-totals-footer {
    position: absolute;
    top: 10px;
    right: 300px;
    // @include flex(center, flex-end);
    @include text(16px, 700, 0, #323338);
    // padding: 0 20px;
    // margin: 0 -20px;
    // height: 45px;
    // background-color: #f7f8fa;
    // border-radius: 0 0 5px 5px;
  }

  .ant-input-number,
  .ant-input-affix-wrapper {
    border-radius: 5px !important;
    border: 0 !important;
    background-color: #f4f5f6 !important;
  }
}
