$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.invoicesInfoDrowerContainer {
  font: normal normal bold Open Sans !important;
  .ant-drawer-content-wrapper {
    width: 520px !important;
  }
  .ant-drawer-header {
    border: 0;
    padding: 0;
    color: white;
    .ant-drawer-header-title {
      .drawerHeader {
        width: 100%;
        height: 100%;
        text-align: center;
        background: #1f2a44 0% 0% no-repeat padding-box;
        padding-top: 10px;
        padding-bottom: 7px;
        padding: 10px 10px 10px 10px;
        height: fit-content;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        p {
          font-weight: 600;
          font-size: 16px;
          color: white;
          width: -webkit-fill-available;
          margin: 0 10px 0 0;
        }
        > div {
          > button {
            display: flex;
            align-items: center;
            height: 100%;
            background-color: inherit;
            svg {
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              fill: #fff;
              width: 17px !important;
              height: 17px !important;
            }
          }
        }
      }
    }
  }
}
.invoicesInfoDrowerContainer .ant-drawer-title {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.invoicesInfoDrowerContainer .ant-drawer-title h3 {
  width: 100%;
  text-align: center;
  font-size: 16px;
  font: normal normal bold Open Sans !important;
  font-weight: 550;
  color: #6b6c72;
  margin-top: 5px;
}
.invoicesInfoDrowerContainer .ant-drawer-title button {
  background: transparent;
  border: 0;
  padding: 3px;
}
.invoicesInfoDrowerContainer .ant-drawer-title button:hover {
  background-color: #e2e2e2;
  border-radius: 5px;
}
.invoicesInfoDrowerContainer .ant-drawer-body {
  padding: 0px;
}
.invoicesInfoDrowerContainer .topInfoContainer {
  margin: 0px 20px;
}
.invoicesInfoDrowerContainer .status {
  display: flex;
}
.invoicesInfoDrowerContainer .status .Badge-icon {
  width: 20px;
  color: #ffbb00;
}
.invoicesInfoDrowerContainer .status .message {
  margin-left: 8px;
  font-weight: 600;
  font-size: 13px;
}
.invoicesInfoDrowerContainer .topInfoContainer {
  margin-bottom: 22px;
}
.invoicesInfoDrowerContainer .topInfoContainer {
  .dateInfoContainer {
    display: flex;
    justify-content: space-between;
    > div {
      background: #f8f8fa 0% 0% no-repeat padding-box;
      border-radius: 5px;
      width: calc(50% - 10px);
      padding: 18px 29px;
      .keyInfo {
        text-align: center;
        width: 100%;
        font-weight: 600;
        font-size: 14px;
      }
      .date {
        text-align: center;
        width: 100%;
        font-size: 14px;
      }
    }
  }
  > .totalPriceContainer {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 15px;
    .totalPrice {
      font-weight: 600;
      font: normal normal 600 49px/69px Open Sans;
      letter-spacing: 0px;
    }
    .keyInfo {
      font: normal normal normal 16px/30px Open Sans;
      letter-spacing: 0px;
      color: #323338;
    }
  }
}
.invoicesInfoDrowerContainer .topInfoContainer .totalPrice sup {
  font-size: 21px;
  margin-left: 5px;
}
.invoicesInfoDrowerContainer .topInfoContainer .totalPrice .dollarSign {
  margin-left: 0;
  margin-right: 5px;
}
.invoicesInfoDrowerContainer .main {
  font-weight: 550;
  color: #6f7177fb;
  margin-top: 11px;
  font-size: 14px;
  color: black;
  .address {
    color: black;
  }
}
.invoicesInfoDrowerContainer .main > .email {
  margin: 1em 0 0;
}
.invoicesInfoDrowerContainer .main > .email > a {
  color: #0077c5;
  font-weight: 550;
  margin-left: 10px;
}
.invoicesInfoDrowerContainer .main > .phone {
  margin-top: 10px;
  padding: 0.1em 0;
  margin: 1em 0 0;
  display: flex;
  align-items: center;
}
.invoicesInfoDrowerContainer .main > .phone > h4 {
  font-weight: 700 !important;
  font-size: 14px;
  margin-bottom: 0px;
  margin-top: 2px;
  color: #323338;
}
.invoicesInfoDrowerContainer .main > .phone > a {
  margin-left: 10px;
  color: #0077c5;
  font-weight: 550;
}
.invoicesInfoDrowerContainer .productsAndServices {
  margin-top: 10px;
  margin-right: 20px;
}
.invoicesInfoDrowerContainer .productsAndServices .mainDetails {
  display: flex;
  justify-content: space-between;
  margin-top: 7px;
  font-weight: 600;
  color: #6b6c72;
}
.invoicesInfoDrowerContainer .productsAndServices .mainDetails > div {
  margin-bottom: 20px;
}
.invoicesInfoDrowerContainer .productsAndServices .mainDetails i {
  font-weight: 400;
  margin-bottom: 5px;
}
.invoicesInfoDrowerContainer .productsAndServices .details > p {
  margin: 10px 8px 10px 2px;
  font-weight: 400;
  font-weight: 15px;
  color: #a6a6aa;
  font-size: 12px;
}
.invoicesInfoDrowerContainer .productsAndServices .details > hr {
  width: 60px;
  margin-left: 0;
  margin-bottom: 10px;
  color: darkgray;
}
.invoicesInfoDrowerContainer .productsAndServices > span {
  border: none;
  padding: 0;
  color: #1264a3;
  background-color: #ffffff;
  font-weight: 600;
  background: none;
  font-size: inherit;
  cursor: pointer;
}
.invoicesInfoDrowerContainer .ant-drawer-footer {
  padding: 0;
  border: 0;
}

.invoicesInfoDrowerContainerDark {
  .ant-drawer-content {
    background-color: $cardModalBodyDark;
    .ant-drawer-header {
      .ant-drawer-title .drawerHeader {
        background-color: $cardModalHeaderDark;
      }
    }
    .ant-drawer-body {
      .topInfoContainer {
        .totalPriceContainer {
          div {
            color: #fff;
          }
        }
        .dateInfoContainer > div {
          background-color: $inputModalDark;
          .keyInfo,
          .date {
            color: #fff;
          }
        }
      }
      .main {
        span {
          color: #fff !important;
        }
        .address {
          color: #fff !important;
        }
      }
      .productsAndServices {
        .mainDetails {
          color: #acacca;
        }
      }
    }
    .ant-drawer-footer .footerInvoiceDrawerWrapper {
      background-color: $cardModalBodyDark;
    }
  }
}
