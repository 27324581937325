.choose-drive-folder-view-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 18px;
    gap: 5px;
    background: #F0F0F0;
    border-radius: 10px;
    cursor: pointer;
    text-align: center;
    width: 100px;
    font-size: 15px;

    &.selected {
        border: 1px solid #939393;
    }

    &:not(.selected) {
        border: none;
    }
}