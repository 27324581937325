.reports-table-choose {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  animation: animateConfig 0.3s ease-in-out forwards;

  .choose-title {
    font-weight: bold;
    letter-spacing: 0.28px;
    font-size: 1.1rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    gap: 10px;

    .button-section {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex-direction: row;
      flex-wrap: nowrap;
    }
  }

  .choose-body {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
  }
}

.table-choose-dark {
  color: #ffffff;
}

@keyframes animateConfig {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
