@import "../../../../../../index.scss";

.documentationFileTree {
  display: block;
  height: 100%;
  width: fit-content;
  min-width: 440px;
  border-radius: 10px;
  overflow: hidden;
  padding-bottom: 25px;
  max-width: 527px;
  overflow-y: auto;

  .treeContainer {
    height: 100%;
  }
  
  .folder {
    @include flex(center, center);
    // background-color: #f5f5f7;
    @include text(16px, 600, 0, #323338);
    border-radius: 2px;
    padding: 0 12px;
  }

  .file {
    @include flex(center, center);
    @include text(14px, 400, 0, #1264a3);
    svg {
      fill: #1264a3;
    }
  }

  .fileIcon {
    margin-right: 10px;
    width: 20px;

    svg {
      width: 16px;
    }
  }

  .ant-tree-node-selected {
    // background-color: #f5f5f7 !important;
    background-color: #fff !important;
  }
}

@media (max-width: 1400px) and (min-width: 1100px) {
  .documentationFileTree {
    min-width: 260px;
  }
}

@media (max-width: 1100px) {
  // .documentationListCard .formCardTitle {
  //   height: 100px !important;
  // }
  .documentationListCard .formCardTitle {
    .documentationListCardHeader {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }
  .documentationViewContainer {
    height: 100vh !important;
  }
  .documentationViewContainer .bodyContainer {
    gap: 20px !important;
  }
  .documentationViewContainer .documentationListCard {
    height: 100% !important;
  }
  .documentationFileTree {
    height: 100% !important;
    min-width: 100% !important;
  }
}

@media (max-width: 768px) {
  .documentationFileTree {
    min-width: 100%;
  }
}
