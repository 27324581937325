@import "../../../../../../../index.scss";
.ChargeInforamtionContainer {
  @include square(100%, fit-content, unset, unset);
  @include flex(unset, unset, column);
  padding: 0px 0px;
  margin-top: -35px;
  max-height: fit-content;
  gap: 10px;

  .ChargeInforamtionContainerHeader {
    @include flex(unset, unset);
    @include square(unset, 100px, unset, unset);
    gap: 20px;

    .chargeTitleContainerLeft {
      @extend .ChargeInforamtionContainerHeader;
      @include flex(center, flex-start);
      @include square(100%, unset, 10px, #fff);
      box-shadow: 0px 1px 4px #00000029;
      padding: 0 10px 0 20px;

      .ChargeTitleStyle {
        font: normal normal bold 16px/22px Open Sans;
        color: #323338;
      }

      .goBackToParent {
        @include mondayButton(#1264a3, #0f5c97, #fff);
      }
    }

    .chargeInforamtionHeaderRight {
      @extend .ChargeInforamtionContainerHeader;
      @extend .chargeTitleContainerLeft;
      @include flex(center, center);
      @include square(320px, unset, 10px, #fff);
      padding: 0px 10px;
      .infoStyle {
        @include square(20px, 20px, 5px, #1264a3);
        @include flex(center, center);
        margin-top: 20px;
        color: white;
        cursor: pointer;
      }
      .ant-form-item {
        margin-bottom: 0 !important;
      }
      .ant-form-item-label {
        padding: 0 !important;
        font-weight: 600;
        text-align: center;
      }
      .ant-input {
        background-color: #f5f5f7;
        border: none;
        border-radius: 5px;
      }
    }
  }

  //Removed the custom styles for Ag-Grid table since we are now using BasePageAgGrid component for tables
  // .chargeViewContainer {
  //   @include flex(unset, unset, row);
  //   gap: 20px;
      //   .CargeTableCard {
      // @include square(inherit, fit-content, 10px, #fff);
      // box-shadow: 0px 1px 4px #00000029;
      
      .agGridCustomStyle {
        height: auto;
        min-height: 350px;
        width: 100%;
        // box-shadow: 0 0px 5px rgba(0, 0, 0, 0.16);
        // .eRootWrapper,
        // .ag-root-wrapper-body {
        //   @extend .agGridCustomStyle;
        // }

        // .ag-column-drop-wrapper {
        //   height: auto;
        //   border: none !important;
        // }

        // .ag-header-cell {
        //   border-right: unset !important;
        // }
      // }
    }
  }
// }
