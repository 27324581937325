.statusPanelContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 3px;
  padding: 3px 0;
  .statusPanelComponent {
    margin-left: 5px;
    padding-top: 0;
    padding-bottom: 0;
    color: #b3b3b3;
    span {
      margin-right: 10px;
      color: #ffffff;
    }
  }
}
