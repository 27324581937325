@import "../../../index.scss";

.customColorPicker {
  position: relative;
  width: fit-content;
  .colorPickerContainer {
    position: absolute;
    right: -240px;
    top: -25px;
    z-index: 10;
  }

  .toggleButton {
    @include flex(center, center);
    height: 30px;
    width: 30px;
    border: 1px solid #707070;
    border-radius: 5px;
    cursor: pointer;
  }

  .ant-form-item-control-input {
    min-height: 0px;
  }
  .ant-form-item-has-error {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    .ant-form-item-explain-error {
      width: max-content;
    }
  }
}
