.hoist_self_check_modal_dark {
  .ant-modal-content {
    .ant-modal-body {
      .hoist_self_check_dark {
        .ant-table-container {
          border: none !important;
        }
        .ant-table-content {
          border: none !important;
          table {
            background-color: #4f4f4f;
            .ant-table-thead {
              tr {
                th {
                  background-color: #12131b;
                  color: #fff;
                  border: solid 1px #4f4f4f !important;
                  //   border: #555555 1px solid;
                }
              }
            }
            .ant-table-tbody {
              tr {
                td {
                  background-color: #2a2b3a;
                  color: #fff;
                  border: solid 1px #4f4f4f !important;
                  //   border: #555555 1px solid;
                }
              }
            }
          }
        }
      }
    }
  }
}
.hoist_self_check_modal_light {
  .ant-modal-content {
    .ant-modal-body {
      .hoist_self_check_light {
        .ant-table-container {
          border: none !important;
        }
        .ant-table-content {
          border: none !important;
          table {
            background-color: #ffffff; /* Change to white */
            .ant-table-thead {
              tr {
                th {
                  background-color: #f0f2f5; /* Light gray */
                  color: #000000; /* Black */
                  border: solid 1px #d9d9d9 !important; /* Light border */
                }
              }
            }
            .ant-table-tbody {
              tr {
                td {
                  background-color: #ffffff; /* White */
                  color: #000000; /* Black */
                  border: solid 1px #d9d9d9 !important; /* Light border */
                }
              }
            }
          }
        }
      }
    }
  }
}
