$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;
.hoistServiceContainerLightMode {
  animation: fadeInSOVServiceHoistUp 0.3s;

  .ant-collapse-item {
    border-bottom: 0 !important;
  }

  .scheduleOfValuesCollapseContainerHoist {
    border-radius: 10px;
    overflow: hidden;
    border: 0;

    :only-child {
      .ant-collapse-header {
        border-bottom: 1px solid lightgray !important;
      }
    }

    :not(:last-child) {
      .ant-collapse-header {
        border-bottom: 1px solid lightgray !important;
      }
    }

    :not(:first-child) {
      .ant-collapse-header {
        border-top: 1px solid lightgray !important;
      }
    }

    .ant-collapse-header {
      height: 45px;
      display: flex;
      align-items: center;

      .service-list-hoist-title {
        font-size: 16px;
        font-weight: 600;
        color: black;
        margin-bottom: 0;
        display: flex;
        gap: 15px;
        align-items: center;
        width: stretch;
      }
    }
  }

  .ant-collapse-content {
    background-color: #f8f8f8;
    border-top: 0px !important;
    border-radius: 0 !important;
  }

  .serviceListServiceHoist {
    border-radius: 10px;
  }
}

.hoistServiceContainerDarkMode {
  animation: fadeInSOVServiceHoistUp 0.3s;
  .ant-collapse-item {
    border-bottom: 0 !important;
    background-color: #12131b;
  }

  .scheduleOfValuesCollapseContainerHoist {
    border-radius: 10px;
    overflow: hidden;
    border: 0;

    :only-child {
      .ant-collapse-header {
        border-bottom: 1px solid #292b3a !important;
      }
    }

    :not(:last-child) {
      .ant-collapse-header {
        border-bottom: 1px solid #292b3a !important;
      }
    }

    :not(:first-child) {
      .ant-collapse-header {
        border-top: 1px solid #292b3a !important;
      }
    }

    .ant-collapse-content {
      background-color: #12131b;
      border-top: 0px !important;
      border-radius: 0 !important;
    }

    .ant-table {
      background-color: $cardModalBodyDark;
      .ant-table-thead {
        th {
          background-color: $cardModalHeaderDark;
          color: #fff;
        }
      }
      td {
        color: #fff;
      }
      // .ant-table-wrapper
      //   .ant-table-tbody
      //   .ant-table-row
      //   >
      .ant-table-cell-row-hover {
        background-color: #272a3a;
      }
    }

    .ant-collapse-header {
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      svg {
        fill: white;
      }

      .service-list-hoist-title {
        font-size: 16px;
        font-weight: 600;
        color: #fdfdfd;
        margin-bottom: 0;
        display: flex;
        gap: 15px;
        align-items: center;
        width: stretch;
      }
    }

    .ant-collapse-content-hidden {
      background-color: #1c1c1c;
      border: 0 !important;
    }

    .serviceListServiceHoist {
      border-radius: 10px;
    }
  }
}

@keyframes fadeInSOVServiceHoistUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
