@use "~ag-grid-enterprise/styles" as ag;
@include ag.grid-styles(
  (
    theme: alpine,
  )
);
@import "../../../../../../../../../../../../../../index.scss";

.rentalDetailsContainer {
  width: stretch;
  display: flex;
  justify-content: center;
  .detailsRentalButton {
    @include mondayButton(#1264a3, #0f5c97, #fff);

    &:disabled {
      @include mondayButton(#0b3e64, #0f5c97, #548bb3);
      svg {
        fill: #548bb3;
      }
    }
  }
}

.detailsRentalModalDark {
  .ant-modal {
    width: 1500px !important;
    height: 650px !important;
  }
  .ant-modal-content {
    width: 1500px !important;
    height: 650px !important;
    border-radius: 20px;
    overflow: hidden;
  }

  // .ant-modal-close-x {
  // width: 45px;
  // height: 45px;

  // svg {
  //   margin-top: -10px;
  //   margin-right: 15px;
  // }
  // }

  .ant-modal-header {
    height: 45px;
    background-color: #12131b;
    border-bottom: 0;
    display: flex;
    align-items: center;

    .ant-modal-title {
      color: white;
      font-size: 16px;
      font-weight: 600;
    }
  }

  .ant-modal-body {
    height: 560px;
    background-color: #1f212d;
  }

  .ant-modal-footer {
    border-top: 0;
    height: 45px;
    background-color: #12131b;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0;
  }
}

.detailsRentalModalLight {
  .ant-modal {
    width: 1700px !important;
    height: 650px !important;
  }
  .ant-modal-content {
    width: 1700px !important;
    height: 650px !important;
    border-radius: 20px;
    overflow: hidden;
  }

  // .ant-modal-close-x {
  //   width: 45px;
  //   height: 45px;

  //   svg {
  //     margin-top: -10px;
  //     margin-right: 15px;
  //   }
  // }

  .ant-modal-header {
    height: 45px;
    background-color: #f8f8fa;
    border-bottom: 0;
    display: flex;
    align-items: center;

    .ant-modal-title {
      color: black;
      font-size: 16px;
      font-weight: 600;
    }
  }

  .ant-modal-body {
    height: 560px;
    background-color: white;
  }

  .ant-modal-footer {
    border-top: 0;
    height: 45px;
    background-color: #f8f8fa;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0;
  }
}


.agGridRentalsDetailsStyleDark {
  .ag-theme-alpine {
    --ag-border-radius: 0px;
  }

  .cell-span {
    transition: background-color 0.3s;
    background-color: #1f212d;
    color: white;
  }

  .ag-header-viewport {
    transition: background-color 0.3s;
    background-color: #12131b;
  }

  .ag-header-row-column {
    color: #ffffff;
  }

  .ag-cell {
    display: flex;
    justify-content: center;
    padding: 0 !important;

    border-color: #4a4a4a;
    border-top: 0;
    border-left: 0;
  }

  .ag-row {
    color: white;
    border-color: #4a4a4a;
  }

  .ag-row-even {
    background-color: #1f212d;
    transition: background-color 0.3s;
    border: 0px !important;
  }

  .ag-row-odd {
    transition: background-color 0.3s;
    background-color: #292b3a;
    border: 0px !important;
  }

  .ag-center-cols-viewport {
    transition: background-color 0.3s;
    background-color: #12131b;
    color: white !important;
  }

  .ag-root-wrapper {
    border: 0;
  }

  .ag-header-group-cell-with-group {
    color: white;
    border-bottom: 1px solid #4a4a4a;
    background-color: #292b3a;
    transition: background-color 0.3s;

    .ag-icon-contracted,
    .ag-icon-expanded {
      transition: background-color 0.3s;
      background-color: #1264a3;
      height: 18px;
      width: 18px;
      margin-left: 3px;
      border-radius: 2px;
      overflow: hidden;

      &::before {
        height: 18px;
        width: 18px;
        background: #1264a3;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .rentalEditableCell {
    transition: background-color 0.3s;
    background-color: #414155;
    // width: fit-content;
    min-width: 130px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    gap: 20px;
  }
}

.agGridRentalsDetailsStyleLight {
  .ag-theme-alpine {
    --ag-border-radius: 0px;
  }

  .ag-root-wrapper {
    border: 0px !important;
    border-radius: 0px !important;
  }

  .cell-span {
    background-color: #ededf2;
    transition: background-color 0.3s;
  }

  .ag-header-viewport {
    background-color: #ffffff;
    transition: background-color 0.3s;
    border-color: #e6e6e6 !important;
  }

  .ag-header {
    border-color: #e6e6e6 !important;
  }

  .ag-row-even {
    background-color: #ffffff;
    transition: background-color 0.3s;
    border: 0px !important;

    .ag-cell {
      display: flex;
      justify-content: center;
      padding: 0 !important;
      border-color: #d0d3d7 !important;
      border-left: 0px !important;
      border-top: 0px !important;

      .cell-span {
        border-bottom: 1px solid red;
      }

      .datePickerInputRentals {
        transition: background-color 0.3s;
        background-color: #f5f5f7;
      }
    }
  }

  .ag-row-odd {
    transition: background-color 0.3s;
    background-color: #f5f5f7;
    border: 0px !important;

    .ag-cell {
      display: flex;
      justify-content: center;
      padding: 0 !important;
      border-color: #d0d3d7 !important;
      border-left: 0px !important;
      border-top: 0px !important;

      .cell-span {
        border-bottom: 1px solid red;
      }

      .datePickerInputRentals {
        transition: background-color 0.3s;
        background-color: #ffffff;
      }
    }
  }

  .ag-header-row {
    border-bottom: 0 !important;
  }

  .ag-header-row-column-group {
    transition: background-color 0.3s;
    background-color: #ffffff;

    .ag-header-cell-resize {
      display: none;
    }
  }

  .ag-header-row-column {
    transition: background-color 0.3s;
    background-color: #e6e6e6 !important;
    border-color: #e6e6e6 !important;

    .ag-header-cell {
      border-color: #e6e6e6 !important;
    }
    .ag-header-cell-resize {
      &::after {
        height: stretch !important;
        top: 0;
        width: 1px;
      }
    }
  }

  .ag-header-group-cell-with-group {
    background-color: #1264a3;
    transition: background-color 0.3s;
    color: white;

    .ag-icon-contracted,
    .ag-icon-expanded {
      background-color: #1264a3;
      transition: background-color 0.3s;
      height: 18px;
      width: 18px;
      margin-left: 3px;
      border-radius: 2px;
      overflow: hidden;

      &::before {
        height: 18px;
        width: 18px;
        background: white;
        border-radius: 3px;
        color: #1264a3;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .rentalEditableCell {
    background-color: #c7cacc;
    transition: background-color 0.3s;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    gap: 20px;
    svg {
      fill: black;
    }
  }
}

.monthContainerCellClassName {
  display: flex;
  justify-content: center;
  .label {
    transform: rotate(-90deg);
  }
}

.schedulingCellsContainer {
  width: stretch;
  display: flex;
  justify-content: center;
  box-shadow: 0px 0px 4px 2px #358153 inset;
}

.estimationCellsContainer {
  width: stretch;
  display: flex;
  justify-content: center;
  box-shadow: 0px 0px 4px 2px orange inset;
}
