@import "../../../../../../../../../index.scss";
.editableCellContainer {
  display: flex;
  align-items: center;
  // justify-content: center;

  .editableCellIcon {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    right: 10px;

    svg {
      fill: white;
    }
  }
}

.descriptionOfWorkCellContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;

  .detailsApplicationButton {
    @include mondayButton(#1264a3, #0f5c97, #fff);
  }
}

.completeCheckboxTextContainer {
  width: stretch;
  display: flex;
  gap: 7px;
  justify-content: center;
  align-items: center;
}
