// .helper-buttons-innerDay-form {
//   display: flex;
//   flex-direction: column;
//   gap: 10px;
//   margin-top: 10px;
//   // padding-left: 10px;

//   #days-container-scroll {
//     &::-webkit-scrollbar {
//       width: 5px !important;
//       height: 5px !important;
//     }
//   }
// }

// .canceled-day-popover {
//   .ant-popover-inner-content {
//     padding: 0 !important;
//     width: 440px;
//     padding: 10px;

//     .form-group {
//       display: flex;
//       flex-direction: column;
//       gap: 5px;
//     }
//   }
// }

.services-schedule-container-data {
  animation: services 0.5s;
}
// @keyframes services {
//   from {
//     opacity: 0;
//     filter: blur(2px);
//     transform: translateY(50px);
//   }
//   to {
//     opacity: 1;
//     filter: blur(0);
//     transform: translateY(0px);
//   }
// }
