.chooseFromContainer {
  display: flex;
  align-items: center;
  height: 40px;
  width: 1200px;
  border-radius: 10px;
  margin: 10px 20px;
  box-shadow: 0px 1px 4px #00000029;

  .choose-from-title {
    margin-left: 30px;
  }

  .estimationButton {
    border: 1px solid #1890ff;
    border-radius: 5px;
    margin-left: 30px;
    width: 90px;
    height: 32px;
    background-color: #1890ff;
    color: white;
  }
  .estimationButtonPressed {
    border-radius: 5px;
    margin-left: 30px;
    width: 90px;
    height: 32px;
    background-color: white;
    border: 1px solid #1890ff;
    color: #1890ff;
  }

  .fromOtherButtonButton {
    border: 1px solid #1890ff;
    border-radius: 5px;
    margin-left: 10px;
    width: 90px;
    height: 32px;
    background-color: #1890ff;
    border-color: 1px solid #1890ff;
    color: white;
  }
  .fromOtherButtonButtonPressed {
    border-radius: 5px;
    margin-left: 10px;
    width: 90px;
    height: 32px;
    background-color: white;
    border: 1px solid #1890ff;
    color: #1890ff;
  }
}
