.chat-message {
  position: relative;
  .message-content {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    max-width: 60%;
    background-color: #f4f5f6;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    position: relative;
    border-radius: 5px;
    line-height: normal;
    color: #231f20;
    text-align: left;
  }

  .sender-name {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    margin-bottom: 5px;
    align-items: center;
    width: 100%;
  }

  .message-body {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 5px;
    text-align: inherit;
    color: inherit;
    font-weight: 400;
  }

  .message-text {
    width: 100%;
    position: relative;
  }

  .space-right {
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .inline-date {
    position: absolute;
    bottom: 5px;
    right: 6px;
    display: inline-block;
    font-size: 12px;
    font-weight: 400;
    color: #acacac;
  }
}
