.InformationBody {
  display: flex;
  flex-direction: column;
  padding: 20px 0px 20px 0px;
  .InforamtionBodyElementContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    .InformationBodyElementContainerTitle {
      text-align: left;
      margin-bottom: 10px;
      font: normal 600 normal 16px Open Sans;
      letter-spacing: 0px;
      color: #323338;
      opacity: 1;
    }
    .InforamtionBodyElementContainerContent {
      @extend .InformationBodyElementContainerTitle;
      color: #767b81;
      margin-bottom: 10px;
      font-weight: 400;
    }
  }
}
