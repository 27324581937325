.notifications-step {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;

  .notification-cards {
    width: 720px;

    .topicsContainer {
      height: 100%;
      width: 350px;
    }
    .notification-actions {
      width: 350px;
    }
  }

  .boarding-navigation-buttons {
    margin-top: 0;
  }
}

@media (max-width: 1024px) {
  .notifications-step {
    .notification-icon {
      svg {
        width: 60px !important;
        height: 60px !important;
      }
    }
  }
}

@media (max-width: 460px) {
  .notifications-step {
    gap: 10px;
    .notification-icon {
      svg {
        width: 32px !important;
        height: 32px !important;
      }
    }
    .notification-cards {
      width: auto;
      flex-direction: column;
      .topicsContainer {
        height: 50%;
      }
    }
  }
}

@keyframes scopesFadeInRight {
  from {
    transform: translate3d(50%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}
