$circleTrailDark: #12131b;
// $inputDarkColor: #2a2b3a;
// $mainDarkBackgroundColor: #20212d;

.CircleProgressBarContent {
  display: flex;
  align-items: center;
  height: 350px;

  .CircleProgressBarBodyStyle {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 350px;
    padding: 20px 10px 0 0;

    .CircleProgressBarStatus {
      text-align: center;
      font-weight: bold;
      margin: -80px 30px 30px 30px;
    }

    .CircleProgressComponent {
      cursor: pointer;
    }
  }
}
.dashToolTipStyle {
  .ant-tooltip {
    margin-top: 50px !important;
  }
  .ant-tooltip-content {
    margin-top: 50px !important;
    border-radius: 5px !important;
    overflow: hidden !important;
  }
  .ant-tooltip-inner {
    background-color: black;
    opacity: 0.5;
  }

  .anticon {
    display: flex;
    justify-content: center !important;
    align-items: center !important;
  }
}

.dashToolTipStyleCustom {
  opacity: 1 !important;
  .ant-tooltip-content {
    margin-top: 50px !important;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-radius: 10px !important;
    overflow: hidden !important;
    opacity: 1 !important;
    width: 400px !important;
    max-height: 400px !important;
  }
  .ant-tooltip-inner {
    background-color: black !important;
    opacity: 0.8 !important;
    overflow-y: auto !important;
  }
}

.CircleProgressBarContent {
  .CircleProgressBarBodyStyleDark {
    .ant-progress {
      .ant-progress-circle {
        .ant-progress-circle-trail {
          stroke: $circleTrailDark;
        }
      }
      .ant-progress-text {
        color: #f2f2f2;
      }
    }
    .CircleProgressBarStatus {
      color: #f2f2f2;
    }
  }
}
