.task-reminder-section {
  .task-reminder {
    padding-top: 10px;
    // margin: 0 20px 20px 0;
    .ant-input,
    .ant-select-selector,
    .ant-picker {
      border: none;
      border-radius: 5px;
      background-color: #ffffff !important;
    }

    span:first-child {
      font-weight: 600;
      // padding-left: 5px;
    }
    .input-section {
      margin-left: 0px;
      .ant-select {
        .ant-select-selector {
          padding: 0;
          height: 30px;
        }
        padding: 0;
        width: 80px;
        // width: 100%;
        .ant-select-arrow {
          svg {
            width: 10.78px;
          }
        }
        // }
        // .ant-select-selection-placeholder {
        //   display: flex;
        //   justify-content: flex-start;
        // }
      }
      // .selectDays {
      //   .ant-select {
      //     padding: 0;
      //     width: 30%;
      //   }
      // }
      .ant-picker {
        // width: 30%;
        // margin-left: 15px;
        .ant-picker-suffix {
          svg {
            width: 14px;
          }
        }
      }
    }
  }
  .task-users {
    display: flex;
    flex-direction: column;
    margin: 0 20px 20px 0;
    .assignee-label {
      display: flex;
      font-size: 16px;
      padding-left: 5px;
      color: #323338;
      font-weight: 600;
    }
    .ant-select {
      width: 100%;
      min-height: 32px;
    }
    .ant-input,
    .ant-select-selector {
      width: 100%;
      border: none;
      border-radius: 5px;
      background-color: #f5f5f7;
    }
    .task-subtopicId-select {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      span {
        cursor: pointer;
      }
    }

    // max-width: 24%;
    margin-bottom: 0;
    .ant-select-arrow {
      svg {
        width: 10.78px;
      }
    }
  }
  .timeDatePicker {
    width: 100%;
    height: 100%;
    margin-bottom: 20px;
    text-align: center;
    display: flex;
    background: #f8f8fa;
    box-shadow: none;
    border-radius: 10px;
    .selectTimeText {
      letter-spacing: 0;
      padding-bottom: 20px;
      padding-left: 20px;
      p {
        padding-top: 0px !important;
      }
    }
    .timeButtons {
      margin: 0px 0px 0px 0px;
      .timeButtonsActive {
        background: #e2e9f8;
      }
      .hour {
        font-size: 40px !important;
      }
      .minute {
        font-size: 40px !important;
      }
    }
    .timeButtons > input {
      border: 0px solid;
      font: Open Sans 600 40px;
      font-size: 40px !important;
    }
    .clock-container {
      width: 100%;
      padding: 20px;
      align-items: center;
      justify-content: space-evenly;
      flex-direction: row;
      // flex-wrap: wrap;
      .clock-block {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        gap: 10px;
        .ownComponent2 {
          .labeledInputLabel {
            display: flex;
            font-size: 16px;
            font-weight: 600;
          }
          .ant-form-item {
            margin-bottom: 0px;
            .notifiedBy .ant-select-selector {
              border: none;
              border-radius: 5px;
              background: white !important;
            }
          }
        }

        .clock {
          width: 211px;
          height: 211px;
          background-color: white;
          .number {
            left: 90px;
          }
        }
      }
      @media (max-width: 450px) {
        flex-direction: column;
      }
    }
  }
}
