@import "../../../../../index.scss";

.StreightProgressBarContent {
  display: flex;
  align-items: center;
  height: 350px;

  .StreightProgresBarBodyStyle {
    width: 100%;
    display: flex;
    flex-direction: column;
    // align-items: center;
    // justify-content: center;
    height: fit-content;
    max-height: 350px;
    // margin-left: -10px;
    padding-top: 10px;
    padding-bottom: 10px;
    overflow-y: auto;

    .StreightProgressBarStyle {
      width: 55%;
      height: 30px;
      align-items: center;
      font-weight: bold;
    }

    .ant-tooltip {
      width: 200px;
    }

    .StreightProgressBarStatus {
      width: 130px;
      height: 30px;
      align-items: center;
      font: normal normal bold 16px Open Sans;
      font-weight: 400;
      padding: 2px 0px 0px 20px;
      @include textEllipsis();
    }
    .content {
      display: flex;
      flex-direction: row;
      width: 100%;
      height: 30px;
      margin-bottom: 10px;
      margin-left: -10px;
      justify-content: center;
      cursor: pointer;
    }
    .StreightProgressTextContent {
      width: 100%;
      height: fit-content;
      margin: 10px 0px;
    }
    .StreightProgressTitle {
      width: 100%;
      height: fit-content;
      text-align: center;
      font-weight: bold;
      font-size: 16px;
      margin-bottom: 10px;
    }
    .StreightProgressSubtitle {
      width: 100%;
      height: fit-content;
      text-align: center;

      font-size: 17px;
    }
  }
}

.StreightProgressBarContentDark {
  .StreightProgresBarBodyStyle {
    .StreightProgressTitle {
      color: #f2f2f2;
    }
    .content {
      .ant-progress-text {
        color: #fff;
      }
      .StreightProgressBarStatus {
        color: #f2f2f2;
      }
    }
  }
}

.dashToolTipStyle {
  opacity: 1 !important;
  .ant-tooltip-content {
    border-radius: 5px !important;
    overflow: hidden !important;
    opacity: 1 !important;
  }
  .ant-tooltip-inner {
    background-color: black !important;
    opacity: 0.8 !important;
  }
}

.dashToolTipStyleCustomm {
  opacity: 1 !important;
  .ant-tooltip-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-radius: 10px !important;
    overflow: hidden !important;
    opacity: 1 !important;
    width: 400px !important;
    max-height: 400px !important;
  }
  .ant-tooltip-inner {
    background-color: black !important;
    opacity: 0.8 !important;
    overflow-y: auto !important;
  }
}
