@use "/src/index.scss" as index;

.schedule-sidebar-item {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  background-color: #f4f5f6;
  transition: 0.2s ease-out;
  cursor: pointer;
  &:hover {
    transform: scale(1.04);
  }
  &.active {
    background-color: #f1f5fd;
    border: 1px solid #1264a3;
  }
  .schedule-status {
    display: flex;
    align-items: center;
    gap: 5px;
    .status-pin {
      height: 8px;
      width: 8px;
      border-radius: 50%;
    }
  }
  .schedule-address {
    display: flex;
    flex-direction: column;
    label {
      font-weight: 600;
    }
  }
  .schedule-data {
    width: 100%;
    display: flex;
    gap: 10px;
    label {
      font-weight: 600;
    }
  }
}

.schedule-sidebar-item-dark {
  background-color: index.$inputDarkColor;
  &.active {
    background-color: #41435d;
  }
}

.sidebar-popover-header {
  height: 30px;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  border-radius: 5px 5px 0px 0px;
  background-color: #f4f5f6 !important;
}

.sidebar-popover-content {
  display: grid;
  .employee-included {
    display: flex;
    align-items: center;
    gap: 5px;
    .status-dot {
      height: 8px;
      width: 8px;
      border-radius: 50%;
    }
  }
}

@media (max-width: 1024px) {
  .schedule-sidebar-item {
    font-size: 12px;
  }
}
