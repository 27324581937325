@import "../../../../../../index.scss";

.recordFilesRenderer {
  .documentationDetailsContainer {
    @include flex(center, flex-start, column);
    max-height: 70vh;
    overflow-y: auto;
  }

  .documentationLine {
    @include flex(center, space-around);
    background-color: #f7f8fa;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 5px;
  }

  .documentationName {
    min-width: 200px;
  }

  //   .documentationUploads {

  //   }

  .ant-btn {
    background-color: #1264a3;
    border: 0;
    border-radius: 5px;

    &:hover {
      background-color: #0f5c97;
    }
  }
}
