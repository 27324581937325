.RentalBreakdownDark {
  width: stretch;
  height: fit-content;
  background-color: #12131b;
  margin: 10px 0 25px 0;
  border-radius: 10px;
  overflow: hidden;
  animation: fadeInRentalUp 0.3s 
}

.RentalBreakdownLight {
  width: stretch;
  height: fit-content;
  background-color: white;
  margin: 10px 0 25px 0;
  border-radius: 10px;
  overflow: hidden;
  animation: fadeInRentalUp 0.3s 
}


@keyframes fadeInRentalUp {
  from {
      opacity: 0;
      transform: translate3d(0, 100%, 0);
  }

  to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
  }
}