@import "../../../../../../../../../index.scss";

.newRentalFooterButtonContainer {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  height: 45px;
  padding: 0 15px;
  align-items: center;
  background-color: #fff;

  .dropDownButtonNewRental {
    border-radius: 5px;
    overflow: hidden;

    .ant-btn {
      background-color: #71cf48;
      color: white;
      border: 0;

      &:disabled {
        background-color: #b4b4b4 !important;
      }
    }
    .ant-dropdown-trigger {
      border-left: 1px solid #68c142;
      border-radius: 0px 5px 5px 0px;
      svg {
        fill: white;
      }

      &:disabled {
        background-color: #b4b4b4 !important;
        border-color: #999999 !important;
      }
    }

    &:hover {
      .ant-btn {
        background-color: #68c142;
      }
      .ant-dropdown-trigger {
        background-color: #68c142;
        border-color: #71cf48;
      }
    }
  }

  .cancelButton {
    @include mondayButton(#fe4c4a, #eb4345, #fff);
  }
}
