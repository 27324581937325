@import "../../../../../../../index.scss";

.singleDimensionPriceTable {
  @include flex(center, flex-start, column);
  max-width: stretch;
  position: relative;

  .priceTable {
    @include flex(flex-end, flex-start);
    padding-bottom: 10px;
    max-width: inherit;
    overflow-x: scroll;
    margin-bottom: 10px;
  }

  .table-labels {
    @include flex(flex-end, space-evenly, column);
    @include text(16px, 600, 0, #323338);
    gap: 10px;
    align-self: stretch;
    padding: 45px 0 20px;
    margin-right: 10px;
  }

  .editIcon {
    position: absolute;
    right: 10px;
    min-width: 32px;
    height: 32px;
    padding: 7px 9px;
    background-color: #1264a3;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
      background-color: #0f5c97;
    }

    path {
      fill: #fff;
    }
  }
}

.dimension-modal {
  @include flex(center, flex-start, column);
  max-width: stretch;
  position: relative;
  // width: 700px;

  .ant-modal-content {
    width: 100%;

    .ant-modal-body {
      .priceTable {
        @include flex(flex-end, flex-start);
        padding-bottom: 10px;
        max-width: inherit;
        overflow-x: scroll;
        margin-bottom: 10px;
      }

      .table-labels {
        @include flex(flex-end, space-evenly, column);
        @include text(16px, 600, 0, #323338);
        gap: 10px;
        align-self: stretch;
        padding: 45px 0 20px;
        margin-right: 10px;
      }
    }

    .ant-modal-footer {
      display: flex;
      justify-content: space-between;
    }
  }
}
