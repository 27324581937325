@import "../../../../../../index.scss";

$mainDarkBackgroundColor: #20212d;
$cardDarkHeaderColor: #2a2b3a;
$cardDarkBackgroundColor: #12131b;
$inputDarkColor: #2a2b3a;

.reportsCategoriesBasePage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  gap: 10px;
  .reportsCategories-view-layout {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 10px;
    gap: 10px;

    .reportsCategories-filters-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 52px;
      padding: 0 10px;
      background-color: #fff;
      border-radius: 10px;
      box-shadow: 0px 1px 4px #00000029;

      .reportsCategories-filter-section,
      .reportCategories-action-section {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        gap: 10px;
        .ant-form-item {
          margin-bottom: 0px;
        }
      }
      .labeledInputContainer.searchReportCategories {
        width: 300px;
      }
      .mondayButtonBlue.reportCategories {
        width: 150px;
      }
      .mondayButtonBlue.categories-filter {
        width: 150px;
      }
    }

    .reportCategories-layout-container {
      height: 100%;
      border-radius: 10px;
      box-shadow: 0px 1px 4px #00000029;

      .reportCategoryName:hover {
        color: $blue;
        font-weight: 600;
        cursor: pointer;
      }

      .cloneReportClick {
        color: $blue;
        font-weight: 600;
        cursor: pointer;
      }

      .agGridHeaderDiv {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 45px;
        border-radius: 10px 10px 0px 0px;
        background-color: #f8f8fa;
        padding: 15px;
        box-shadow: 0px 1px 4px 0px #00000029;
        .agGridHeaderText {
          @include text(16px, 600, 0.21, #323338);
        }
      }
      .reportCategories-wrapper {
        background: #ffffff;
        width: 100%;
        box-shadow: 0px 1px 0px 0px #00000029;
        transition: all 0.5s ease-in-out;
        border-radius: 0px 0px 10px 10px;
        height: -webkit-fill-available;
        overflow-y: auto;

        span.ag-column-drop-empty-message.ag-column-drop-horizontal-empty-message {
          display: flex;
          align-items: center;
          padding: 5px;
          margin-top: 10px;
          width: 354px;
          height: 32px;
          border-radius: 5px;
          background-color: #f5f5f7;
        }
        .ag-cell {
          border-right: solid 0px #4f4f4f !important;
        }
        .ag-header-cell-label {
          justify-content: center;
        }
        .statusCell {
          width: 100%;
        }
        .light-ag-theme .ag-root-wrapper {
          border: 0;
          height: inherit;
          box-shadow: rgb(99 99 99 / 20%) 0px 2px 0px 0px;
          border-radius: 0;
          // .ag-root-wrapper-body {
          //   height: 100%;
          //   overflow: auto;
          // }
        }
        .ag-paging-panel {
          gap: 75px !important;
        }
        .ag-status-bar {
          width: 100%;
          height: 48px;
          .ag-status-bar-right {
            position: relative;
            right: 200px;
            .ag-react-container {
              display: flex;
              align-items: center;
            }
          }
        }
        .reportCategories-info {
          display: flex;
          align-items: center;
          width: 100%;
          padding: 0 20px;
          gap: 10px;
          .reportCategories-text {
            height: 20px;
            font-size: 14px;
            font-weight: 400;
          }
        }
        .reportConfig-grid {
          height: 55dvh;
        }
      }
    }
  }
}

.reportsCategoriesBasePageDark {
  .reportsCategories-view-layout {
    .reportsCategories-filters-header {
      background-color: $cardDarkBackgroundColor;
      .ant-input-wrapper {
        background-color: $inputDarkColor !important;
        .ant-input-prefix svg {
          fill: #f2f2f2;
        }
        .ant-input {
          background-color: $inputDarkColor !important;
          color: #f2f2f2;
          &::placeholder {
            color: #acacca;
          }
        }
        .ant-input-group-addon {
          background-color: $inputDarkColor !important;
        }
      }
    }
    .reportCategories-layout-container {
      background-color: $cardDarkBackgroundColor;
      .agGridHeaderDiv {
        background-color: $cardDarkHeaderColor;
        color: #fff;
        .agGridHeaderText {
          color: #fff;
        }
      }
      .reportCategories-wrapperDark {
        background-color: $cardDarkBackgroundColor;
        .reportCategories-info,
        .reportCategories-text {
          color: #fff;
        }
      }
    }
  }
  .documentationsTable {
    background-color: $cardDarkBackgroundColor;
    overflow: hidden;
    .ag-column-drop {
      background-color: $cardDarkBackgroundColor;
    }
    .ag-header {
      box-shadow: none;
      background-color: $cardDarkBackgroundColor;
      .ag-header-cell {
        padding-left: var(--ag-cell-horizontal-padding) !important;
        padding-right: var(--ag-cell-horizontal-padding) !important;
        .ag-header-cell-text {
          font-size: 16px;
          font-weight: 600;
        }
      }
    }
    .ag-side-buttons {
      background-color: $cardDarkBackgroundColor;
    }
    .ag-paging-panel {
      border: none;
      background-color: $cardDarkBackgroundColor;
    }
  }
}

@media (max-width: 1440px) {
  .reportsCategoriesView .categoriesListContainer {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 30px;
  }
  .categoryCreatorSection {
    display: flex;
    flex-wrap: wrap;
  }
}

@media (max-width: 840px) {
  .categoryCreatorSection .headerSearch {
    width: 300px;
  }
}

@media (max-width: 450px) {
  // .reportsCategoriesView .categoriesListContainer {
  //   display: grid;
  //   grid-template-columns: 100% !important;
  //   padding: 10px !important;
  // }

  .reportsCategoriesView .reportCategoryCard {
    min-width: 100% !important;
    height: fit-content;
  }
}
