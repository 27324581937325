.new_proposal_builder__header-wrapper {
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  background-color: #fff;
  justify-content: space-between;
  border-bottom: 1px solid #e6e9ef;
  .proposal_builder_header_left {
    gap: 10px;
    display: flex;

    .header__title {
      gap: 10px;
      margin: 0;
      display: flex;
      font-size: 20px;
      font-weight: bold;
      padding-left: 10px;
      align-items: center;
      color: rgb(47, 47, 47);
      .divider {
        height: 15px;
        margin: 0 10px;
        border-left: 2px solid #e6e9ef;
      }
      .header-tag {
        padding: 5px;
        font-size: 14px;
        font-weight: 400;
        border-radius: 5px;
        background-color: #f4f5f6;
        strong {
          font-weight: 600;
        }
      }
    }
  }
  .proposal_builder_header_right {
    gap: 10px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .icon-button button {
      width: 32px;
    }
  }
}
.new_proposal_builder__header-wrapper {
  .proposal_builder_header_right {
    .icon-button {
      svg {
        fill: #231f20 !important;
      }
    }
  }
}
