.scaffold-pliaddon-planks-modal {
  .ag-header {
    background-color: #1264a3 !important
    ;
  }
  .ppu-planks-cell-price {
    text-align: right !important;
    color: white;
    display: flex;
    width: 100%;
    justify-content: flex-end !important;
    // background-color: red;
  }
  .ppu-planks-cell {
    text-align: left !important;
    color: white;
    display: flex;
    width: 100%;
    justify-content: flex-start !important;
    // background-color: red;
  }
  .ant-modal-header {
    background-color: #12131b;
    border-radius: 5px 5px 0 0;
    border: none;
  }

  .ant-modal-title {
    color: white;
  }

  .ant-modal-content {
    border-radius: 5px;

    // close icon
    .ant-modal-close-x {
      color: white;
    }

    .ant-modal-body {
      padding-bottom: 10px;
      background-color: #292b3a;
      height: 700px;
      overflow-y: auto;
      overflow-x: hidden;
      .scaffold-pliaddon-planks-content {
        height: 100%;
        .agGridContainer {
          height: 30dvh;
          .documentationsTable {
            height: 100%;
          }
        }
      }
    }
  }

  .ant-modal-footer {
    background-color: #12131b;
    // border-top: 1px solid #e8e8e8;
    // border-radius: 5px;
    border: none;
    border-radius: 0 0 5px 5px;
  }
  .ag-input-field-input {
    background-color: #292b3a !important;
  }
  .ag-header-cell-label {
    justify-content: center !important;
  }
}
.custom-tooltip {
  min-width: 150px;
  padding: 5px;
  color: var(--ag-foreground-color);
  background-color: #5577cc;
}

.custom-tooltip p {
  margin: 5px;
  white-space: nowrap;
}

.custom-tooltip p:first-of-type {
  font-weight: bold;
}
