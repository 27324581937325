.audit-controller {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding-left: 20px;
  padding-right: 20px;
  border-bottom: 1px solid #dee2eb;

  .ant-switch-checked {
    background: #68c142 !important;
  }

  .locations-input {
    min-width: 215px;

    .ant-select-auto-complete {
      width: 100%;
    }
  }

  .location-label {
    font-size: 14px;
    font-weight: 400;
    cursor: default;
    white-space: nowrap;
  }

  .alternate-icon {
    cursor: pointer;
    overflow: visible;
    transition: all 0.3s ease-in-out;
  }

  .writeReadSwitchContainer {
    background-color: #1264a3;
    width: 140px;
    height: 23px;
    font-size: 13px;

    .ant-switch-handle {
      padding: 2px;
      background-color: white;
      border-radius: 9px;
    }

    .ant-switch-inner-checked {
      font-size: 13px !important;
    }
    .ant-switch-inner-unchecked {
      font-size: 13px !important;
    }
  }

  .side-toggle-icon {
    height: 80%;
    aspect-ratio: 1;
    transition: background-color 0.2s ease-in-out;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      cursor: pointer;
      background-color: rgba(60, 60, 77, 0.1);
    }
  }

  .selected-vehicle-name {
    width: fit-content;
    white-space: nowrap;
    font-weight: 550;
    font-size: 1.05rem;
    cursor: default;
  }

  .list-controls {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    width: fit-content;
    gap: 0.5rem;
  }

  .audit-status {
    height: 23px;
    min-width: 130px;
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    color: white;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    text-align: center;
    border-radius: 5px;
    font-weight: 400;
    color: #fff;
    cursor: default;
    margin-left: 10px;
  }

  .audit-status.PENDING {
    background-color: #e9c400;
  }

  .audit-status.COMPLETED {
    background-color: #7cca00;
  }

  .audit-status.UPLOADED {
    background-color: #0a8800;
  }

  .audit-controls {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    gap: 0.5rem;
  }

  .ant-select-selector {
    border-radius: 0;
  }
}

.audit-controller.audit-controller-dark {
  background-color: #20212d;
  border-bottom: 1px solid #616481;

  .writeReadSwitchContainer {
    background-color: #292b3a;
    width: 140px;
    height: 23px;
  }

  .locations-input {
    .ant-select-clear {
      color: #acacac;
      // color: #3a3c4e;
      background-color: transparent;
    }
  }

  .location-label {
    color: #ffffff;
  }

  .side-toggle-icon {
    svg {
      fill: #ffffff;
    }
    &:hover {
      cursor: pointer;
      background-color: rgba(165, 165, 174, 0.1);
    }
  }

  .selected-vehicle-name {
    color: #ffffff;
  }
}
