.stepperContainer {
  width: 660px;
  margin-bottom: 20px;
  padding-top: 20px;
  pointer-events: none;
  .StepperCard {
    margin-bottom: 10px;
  }
  .dividerStyle {
    min-height: 7px;
    width: 690px;
    background: transparent
      linear-gradient(90deg, #ffffff 0%, #f1f3f4 51%, #ffffff 100%) 0% 0%
      no-repeat padding-box;
    opacity: 1;
  }
}
