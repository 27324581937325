.google-sign-in-step {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  .mondayButtonContainer {
    margin-top: 10px;
  }
  .boarding-navigation-buttons {
    margin-top: 30px;
  }

  .calendar-select-input {
    width: 270px;
    .labeledInputLabel {
      display: flex;
      justify-content: center;
    }
    .ant-form-item {
      margin: 0;
    }
  }

  .signed-in-text {
    font-size: 45px;
  }

  &.dark-mode {
    .calendar-select-input {
      .labeledInputLabel {
        color: #fff;
      }
    }
  }
}

@media (max-width: 460px) {
  .google-sign-in-step {
    .signed-in-text {
      font-size: 28px;
    }
  }
}
