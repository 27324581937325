.general-side-info-container {
  .card-body {
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;

    .side-info-item {
      display: grid;
      grid-template-columns: 135px auto;
      column-gap: 10px;
      place-items: center stretch;
      width: 100%;

      .item-status {
        display: grid;
        grid-template-columns: 8px auto;
        column-gap: 5px;
        place-items: start;
        align-items: center;

        .status-dot {
          height: 8px;
          aspect-ratio: 1;
          border-radius: 50%;
        }

        .status-name {
          font-size: 14px;
          font-weight: 400;
        }
      }

      .item-link {
        cursor: pointer;
        font-weight: 600;
        color: #1065a4;
      }
    }
  }

  .card-body.with-title {
    height: calc(100% - 45px - 75px) !important;
  }

  .card-body.no-title {
    height: calc(100% - 75px) !important;
  }

  .teams-footer-info {
    display: grid;
    grid-template-columns: 150px auto;
    grid-template-rows: 35px;
    column-gap: 10px;
    width: 100%;
    padding: 20px;
    align-items: center;
  }

  .item-label {
    font-weight: 600;
    font-size: 14px;
    text-align: end;
  }
}
