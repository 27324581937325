@import "../../../../../../index.scss";

.linkBtnContainer {
  padding: 5px 15px;
  border-radius: 5px;
  background-color: #1264a3;
  color: #fff;
  @include flex(center, center);

  .btnDivider {
    height: auto;
    // width: 0;
    align-self: stretch;
    border-color: #fff;
    // display: none;
    // transition: opacity 1s ease-out;
    // opacity: 0;
  }

  .linkIcon {
    margin-right: 5px;
  }

  .linkBtnChildrenContainer {
    cursor: pointer;
  }

  .editLabel {
    cursor: pointer;
    // display: none;
    // transition: opacity 1s ease-out;
    // opacity: 0;
    // width: 0;
  }

  // &:hover {
  //   .btnDivider {
  //     opacity: 1;
  //     display: block;
  //     width: fit-content;
  //   }

  //   .editLabel {
  //     opacity: 1;
  //     display: block;
  //     width: fit-content;
  //   }
  // }
}
