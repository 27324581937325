$headerDarkBackground: #12131b;
$bodyDarkBackground: #1f212d;
$inputDarkColor: #12131b;
$inputHoverDark: #2a2b3a;

.foldersAccessDrawer {
  .ant-drawer-content-wrapper {
    width: 800px !important;
  }
  .ant-drawer-header {
    .ant-drawer-header-title {
      .ant-drawer-title {
        color: #000;
        font-size: 16px;
        font-weight: 600;
      }
    }
  }

  .ant-input-affix-wrapper {
    span {
      margin-right: 10px !important;
    }
  }

  .mainCollapse {
    border-radius: 5px;
    .ant-collapse-content-box {
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 10px !important;
    }

    .elementWrapper {
      display: flex;
      justify-content: space-between;
      .elementText {
        margin: 0;
      }
      .headerText {
        font-size: 16px;
        font-weight: 600;
        color: #323338;
        line-height: 22px;
      }
      .subfolderText {
        font-size: 14px;
        font-weight: 600;
        color: #323338;
        line-height: 19px;
        max-width: 400px;
      }
      .treeText {
        font-size: 14px;
        font-weight: 400;
        color: #323338;
        line-height: 19px;
      }
    }

    .firstLevelFolders {
      .ant-collapse {
        background-color: #fff;
        border-radius: 0px;
        border-top: 1px solid #dee2eb;
        border-bottom: 1px solid #dee2eb;
      }
    }
    .ant-radio-group {
      > .ant-radio-wrapper:nth-child(1),
      > .ant-radio-wrapper:nth-child(2) {
        border-right: 1px solid #616481;
      }
    }

    //radio
    .ant-radio-inner {
      border-color: #707070 !important;
      background-color: #fff !important;
      &:after {
        background-color: #1264a3 !important;
        transform: scale(0.5) !important;
      }
    }
    .elementWrapper .treeText {
      max-width: 280px !important;
    }

    //tree
    .ant-tree-treenode {
      width: 100% !important;
      display: flex !important;
      .ant-tree-switcher {
        line-height: 17px !important;
      }
      .ant-tree-node-content-wrapper {
        flex-grow: 1 !important;
        &:hover {
          background-color: #f1f5fd !important;
          border-radius: 0 !important;
        }
      }
    }
  }
}

//DARK MODE
.foldersAccessDrawerDark {
  .ant-drawer-header {
    background-color: $headerDarkBackground;
    .ant-drawer-header-title {
      .ant-drawer-title {
        color: #fff;
      }
    }
    .ant-drawer-close {
      svg {
        fill: #fff;
        path {
          fill: #fff;
        }
      }
    }
  }
  .ant-drawer-body {
    background-color: $bodyDarkBackground;
    .mainCollapse {
      background-color: $inputDarkColor !important;
      .elementWrapper {
        .elementText {
          color: #fff !important;
        }
        .ant-radio-wrapper {
          span {
            color: #fff !important;
          }
        }
      }

      .ant-tree-list {
        background-color: $bodyDarkBackground !important;
        .ant-tree-switcher-leaf-line {
          &:before {
            border-inline-end: 1px solid #616481 !important;
          }
          &:after {
            border-bottom: 1px solid #616481 !important;
          }
        }
      }
      .ant-tree-indent-unit {
        &::before {
          border-inline-end: 1px solid #616481 !important;
        }
      }
    }
    .firstLevelFolders {
      .ant-collapse {
        background-color: $bodyDarkBackground;
        border-color: #616481;
      }
    }

    .labeledInputContainer {
      .labeledInputLabel {
        color: #fff;
      }
    }
    .ant-tree-node-selected {
      background-color: $inputHoverDark !important;
    }
    .ant-tree-treenode {
      .ant-tree-node-content-wrapper {
        &:hover {
          background-color: $inputHoverDark !important;
        }
      }
    }

    // .ant-collapse-expand-icon {
    svg {
      fill: #fff !important;
    }
    // }
    .ant-input-affix-wrapper {
      background-color: $inputDarkColor !important;
      .ant-input {
        background-color: $inputDarkColor !important;
        color: #fff;
        &::placeholder {
          color: #acacca;
        }
      }
      .ant-input-suffix svg {
        fill: #fff;
      }
    }
  }
  .ant-drawer-footer {
    background-color: $headerDarkBackground !important;
  }
}
