$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.degWarningsModalWrap {
  overflow: hidden;
}

.degWarningsModal {
  height: fit-content;
  width: fit-content !important;
  .ant-modal-content {
    height: calc(100vh - 20px);
    width: calc(100vw - 20px);
    height: calc(100dvh - 20px);
    width: calc(100dvw - 20px);
    max-width: 580px;
    max-height: 820px;

    .mondayButtonContainer {
      button {
        min-width: 150px;
        &:disabled {
          color: rgba(0, 0, 0, 0.25) !important;
          background-color: rgba(0, 0, 0, 0.04) !important;
          .mondayButtonDivider {
            background-color: transparent !important;
          }
        }
      }
    }

    .ant-modal-header {
      height: 45px;
      width: 100%;
      display: flex;
      align-items: center;
      font-weight: 600;
      border-radius: 10px 10px 0 0;
      background-color: #f8f8f8;

      .ant-modal-title {
        color: #323338;
        font-weight: 600;

        .titleInfoSpan {
          color: #fe4c4a;
        }
      }
    }

    .ant-modal-body {
      height: calc(100% - 165px);
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      gap: 1rem;
      overflow-x: hidden;
      overflow-y: auto;
      .sectionTitle {
        font-size: 0.85rem;
        font-weight: 600;
        padding-left: 0.4rem;
        letter-spacing: 0.3px;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        &:not(:first-child) {
          margin-top: 0.5rem;
        }
      }
      .checkEntry {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 0.85rem;
        letter-spacing: 0.15px;
        flex-direction: row;
        flex-direction: row;
        flex-wrap: nowrap;
        width: 100%;
        border-bottom: 1px dotted rgba(50, 51, 56, 0.2);
        padding-bottom: 0.3rem;
      }
    }
  }
}

.degWarningsModalDark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      .sectionTitle {
        color: #fff;
      }
      .checkEntry {
        border-bottom: 1px dashed #acacca;
        span {
          color: #fff;
        }
      }
    }
  }
}
