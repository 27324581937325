$animation-duration: 1.5s;

.new-proposal-modal-headerStepper {
  .choose_source_wrapper {
    // background-color: #f3f0ca;
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 20px;
    gap: 20px;

    // animation: button-appears $animation-duration ease-in-out forwards;
  }
}

// .antCustomStepper {
//   line-height: 260px;
//   text-align: center;
//   min-width: 1400px;
// }

@keyframes button-appear-from-left {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes button-appear-from-right {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes button-appears {
  0% {
    display: flex;
    justify-content: space-between;
    gap: 0px;
  }
  33% {
    display: flex;
    justify-content: center;
    gap: 100px;
  }

  100% {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
}
