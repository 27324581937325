.editLogs-btn {
  background-color: rgb(18, 100, 163) !important;
  border-color: rgb(18, 100, 163) !important;
  color: #fff !important;
  border-radius: 5px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0;
  padding-right: 0;
  // margin-right: 18px;
  width: 32px;
  height: 32px;
  span {
    width: 21px;
    height: 21px;
    margin-right: -1px;
  }
  &:hover {
    background-color: #0d4c7c !important;
    border-color: #0d4c7c !important;
    transition: 0.2s;
  }
}
