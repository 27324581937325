@import "../../../../../../index.scss";

$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.dynamicDocumentationModal {
  .ant-modal {
    width: unset !important;
  }
  .ant-modal-content {
    max-height: calc(100vh - 20px);
    max-width: calc(100vw - 20px);
  }

  .ant-modal-footer {
    border: none;
    padding-bottom: 20px !important;
  }

  .ant-modal-body {
    padding-bottom: 0px !important;
    .dynamicDocModalBodyContainer {
      height: calc(100vh - 240px);
      width: 70vw;
      overflow-x: hidden;
      // overflow-y: auto;
      overflow-y: hidden;
    }
  }

  .ant-modal-header {
    height: 45px;
    display: flex;
    align-items: center;
    background-color: #f8f8fa;
    overflow: hidden;
    border: none;

    .ant-modal-title {
      font-size: 16px;
      width: 100%;
      font-weight: 600;
      color: #323338;
      padding: 0 !important;
      height: 45px;
      display: flex;
      align-items: center;
    }
  }
  // .ant-modal-header {
  //   border-bottom: 0;
  //   .ant-modal-title {
  //     font-size: 16px;
  //     font-weight: 600;
  //   }
  // }

  .docContent {
    @include flex(flex-start, flex-start);
    height: 90%;
    gap: 20px;
    .configSection {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
      overflow-y: auto;
      width: 100%;
      padding-right: 10px;
      padding-left: unset;
      flex: 0 0 35%;
      gap: 10px;

      .docUploader {
        width: 100%;
        margin-bottom: 10px;
        .uploadedFilesSection {
          max-height: 220px;
        }
      }
    }

    .docConfigTable {
      // margin-top: 10px;
      width: 100%;
      // height: 100%;
      background-color: #f8f8fa;
      border-radius: 5px;
      padding: 10px;
      padding-bottom: 5px;

      .docCofingTableText {
        display: flex;
        gap: 5px;
        @include text(14px, 400, 0, #323338);
        // margin-left: 20px;
        margin-bottom: 10px;
        svg {
          fill: #1264a3;
          font-size: 20px;
        }
        p {
          margin: 0;
        }
      }
    }
    .infoText {
      padding-bottom: 10px;
      height: 55px !important;
      display: flex;
      align-items: center;
      .docCofingTableText {
        margin-bottom: unset !important;
        margin-left: 10px;
      }
    }
    .docShown {
      // margin-top: 10px;
      width: 100%;
      height: fit-content;
      background-color: #f8f8fa;
      border-radius: 5px;
      .docInfoWarning {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 10px;
      }
      .docCofingTableText {
        font-size: 14px;
        font-weight: 600;
        opacity: 1;
        text-transform: none;
        letter-spacing: 0;
        color: #323338;
        margin-left: 20px;
        text-align: center;
      }
      .docCofingTableBody {
        padding: 10px;
        display: grid;
        grid-template-columns: auto auto auto auto;
        gap: 10px;
        // max-height: 94px;
        overflow: auto;
        .docCofingTableBodySingle {
          border: 1px solid #f0f0f0;
          border-radius: 5px;
          background-color: white;
          text-align: center;
          padding: 5px;
        }
      }

      .doc-select {
        width: 100%;
        border-radius: 5px;
        height: 70px;
        .labeledInputLabel {
          @include text(16px, 600, 0, #323338);
          margin-left: 20px;
          margin-top: 10px;
        }
      }
      .ant-select {
        min-height: 32px;
        max-width: 380px;
        border-radius: 5px;
        margin-left: 10px;
        background-color: #ffffff !important;
        .ant-select-selector {
          max-height: 40px;
          overflow-y: auto;
          height: 32px;
          width: 100%;
          background-color: #ffffff !important;
        }
      }
    }
    .enable-switches {
      display: flex;
      justify-content: space-between;
      gap: 10px;
      width: 100%;
      .switch-group {
        display: flex;
        flex-direction: column;
        gap: 10px;
        .switch-group-label {
          font-size: 14px;
          font-weight: 600;
          margin-left: 10px;
        }
      }
    }
  }

  .documentationModalStepper {
    height: 36px;
  }

  .docStatusStepper {
    margin-bottom: 20px;
    overflow-y: hidden;
    overflow-x: hidden;
  }

  .formContainer {
    // width: 44vw;
    height: 100%;
    @include flex(baseline, flex-start);
    flex-wrap: wrap;

    .labeledInputContainer {
      padding-right: 15px;

      .labeledInputLabel {
        @include text(14px, 600, 0.21px, #383554);
        margin-bottom: 2px;
      }
    }
  }

  .formSection {
    @include flex(flex-start, flex-start, column);
    min-height: 586px;
    width: 90%;
    gap: 10px;
    flex-grow: 1;
    flex: 0 0 65%;
    .formContainer {
      width: 100%;

      .notesFormItem {
        // flex-grow: 1;
        min-width: 100%;
        height: 100px !important;
        .ant-input {
          height: 64px;
        }
      }
    }
  }

  .ant-select-auto-complete {
    width: 350px !important;
  }

  .ant-select-auto-complete,
  .ant-input-affix-wrapper,
  .ant-select-selector,
  .ant-picker,
  .ant-input {
    border: 0 !important;
    border-radius: 5px !important;
    background-color: #f5f5f7 !important;
  }
}

.gradientDivider {
  width: 7px;
  background-image: linear-gradient(to bottom, #ffffff, #f1f3f4, #ffffff);
  margin: 0 20px;
  align-self: stretch;
}

.dynamicDocumentationModalDark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      .dynamicDocModalBodyContainer {
        .docStatusStepper {
          .ant-steps {
            .ant-steps-item-finish ::before,
            .ant-steps-item-active::before,
            .ant-steps-item::before {
              border-left-color: $cardModalBodyDark;
            }
            .ant-steps-item-wait {
              background-color: $cardModalHeaderDark;
              .ant-steps-item-content .ant-steps-item-title {
                color: #acacca;
              }
            }
          }
        }
        .switch-group-label {
          color: #fff;
        }
        .docContent {
          .configSection {
            .docShown,
            .doc-members {
              background-color: $inputModalDark;
              .labeledInputLabel,
              .docCofingTableText {
                color: #fff;
              }
              .docCofingTableBodySingle {
                background-color: $cardModalBodyDark;
                color: #fff;
                border: none;
              }
              .ant-select {
                background-color: $cardModalBodyDark !important;
                .ant-select-selector {
                  background-color: $cardModalBodyDark !important;
                  .ant-select-selection-item {
                    background-color: #323338;
                    .ant-select-selection-item-remove svg {
                      fill: #fff;
                    }
                  }
                  .ant-select-selection-search-input {
                    color: #fff;
                  }
                  .ant-select-selection-item {
                    color: #fff;
                  }
                  .ant-select-selection-placeholder {
                    color: #acacca;
                  }
                }
                .ant-select-clear {
                  background-color: $cardModalBodyDark;
                  svg {
                    fill: #acacca;
                  }
                }
                .ant-select-arrow svg {
                  fill: #fff;
                }
              }
              .ant-select-disabled {
                .ant-select-selector {
                  .ant-select-selection-search-input {
                    color: #acacca !important;
                  }
                  .ant-select-selection-item {
                    color: #acacca !important;
                  }
                }
              }
            }
            .docConfigTable {
              background-color: $cardModalHeaderDark;
              .docCofingTableText {
                color: #fff;
              }
            }
          }
          .gradientDivider {
            background: linear-gradient(
              to bottom,
              $cardModalBodyDark,
              $inputModalDark,
              $cardModalBodyDark
            );
          }
          .formSection {
            .labeledInputLabel {
              color: #fff;
            }
            .ant-input {
              background-color: $inputModalDark !important;
              color: #fff;
              &::placeholder {
                color: #acacca;
              }
            }
            .ant-input-disabled {
              color: #acacca;
            }
            .ant-input-affix-wrapper {
              background-color: $inputModalDark !important;
              .ant-input-suffix svg {
                fill: #fff;
              }
            }
            .ant-select {
              background-color: $inputModalDark !important;
              border-radius: 5px;
              .ant-select-selector {
                background-color: $inputModalDark !important;
                .ant-select-selection-search-input {
                  color: #fff;
                }
                .ant-select-selection-item {
                  color: #fff;
                }
                .ant-select-selection-placeholder {
                  color: #acacca;
                }
              }
              .ant-select-clear {
                background-color: $inputModalDark;
                svg {
                  fill: #acacca;
                }
              }
              .ant-select-arrow svg {
                fill: #fff;
              }
            }
            //Disabled
            .ant-select-disabled {
              .ant-select-selector {
                .ant-select-selection-search-input {
                  color: #acacca !important;
                }
                .ant-select-selection-item {
                  color: #acacca !important;
                }
              }
            }
            .ant-picker {
              background-color: $inputModalDark !important;
              .ant-picker-input {
                background-color: $inputModalDark;
                input {
                  color: #f2f2f2;
                  &::placeholder {
                    color: #acacca;
                  }
                }
                .ant-picker-suffix svg {
                  color: #acacca;
                }
                .ant-picker-clear {
                  background-color: $inputModalDark;
                  svg {
                    fill: #acacca;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1367px) {
  .dynamicDocumentationModal {
    .dynamicDocModalBodyContainer {
      width: 85vw !important;
    }
  }
}

@media (max-width: 1025px) {
  .dynamicDocumentationModal {
    .dynamicDocModalBodyContainer {
      width: 85vw !important;
      height: unset !important;
      min-height: 50vh;
      max-height: calc(100vh - 200px);
      overflow-y: auto !important;
      .docContent {
        flex-direction: column;
        // align-items: center;
        .configSection {
          flex: unset !important;
          overflow-y: unset;

          .doc-members {
            flex-direction: row;
            justify-content: space-between;
            .docMembersButton {
              width: unset;
              padding: 0 10px;

              .mondayButtonText,
              .mondayButtonDivider {
                display: unset;
              }
            }
            .membersButtonDiv {
              gap: 10px;
              .doc-selectTeam {
                height: 60px;
              }
            }
          }

          .docConfigTable {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 10px;
            .docCofingTableText {
              margin-bottom: 0px;
            }
          }
        }
        .formSection {
          flex: unset !important;
          width: 100%;
          min-height: unset !important;
          .labeledInputContainer {
            height: 65px;
          }
        }
      }
    }
  }
}

@media (max-width: 700px) {
  .dynamicDocumentationModal {
    .docViewActionButtons {
      display: grid;
      grid-template-columns: 50% 50%;
      justify-items: center;
      .mondayButtonContainer {
        margin-bottom: 10px;
        .mondayButtonText,
        .mondayButtonDivider {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .dynamicDocumentationModal {
    .docStatusStepper {
      height: 250px;
      width: 95%;
      overflow: visible;
      .ant-steps-navigation.ant-steps-vertical > .ant-steps-item:after {
        position: relative;
        top: -60px;
        left: 100%;
        transform: rotate(0deg);
      }
      .ant-steps-navigation.ant-steps-vertical
        > .ant-steps-item.ant-steps-item-active:before {
        display: none;
      }
      .ant-steps-navigation.ant-steps-vertical
        > .ant-steps-item:last-child:after {
        display: none;
      }
    }
    .docViewActionButtons {
      display: grid;
      grid-template-columns: 50% 50%;
      justify-items: center;
      .mondayButtonContainer {
        margin-bottom: 10px;
      }
    }
  }
}

@media (max-width: 450px) {
  .dynamicDocumentationModal {
    .docStatusStepper {
      width: 90%;
    }
    .ant-modal-body {
      padding: 10px 10px 0px 10px !important;
    }
    .doc-members {
      flex-direction: column !important;
      .membersButtonDiv {
        .mondayButtonContainer {
          // padding: 0 20px !important;

          .mondayButtonDivider,
          .mondayButtonText {
            display: none !important;
          }
        }
      }
    }
    .docStatusStepper {
      .ant-steps-navigation.ant-steps-vertical > .ant-steps-item:after {
        top: -34px;
      }
    }
    .docViewActionButtons {
      display: grid;
      grid-template-columns: 100%;
      justify-items: center;
      .mondayButtonContainer {
        margin-bottom: 10px;
      }
    }
  }
}
