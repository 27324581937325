.chart-card-title {
  height: 100%;
  display: flex;
  gap: 5px;
  justify-content: space-between;
  overflow: auto;

  .title {
    height: 100%;
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 5px 0;
    text-wrap: nowrap;
  }

  .title-right-section {
    display: flex;
    gap: 5px;
    align-items: center;

    .chart-card-active-filters {
      height: 100%;
      display: flex;
      align-items: center;
      overflow: auto;
    }

    .chart-card-actions {
      height: 100%;
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 20px;

      .mondayButtonRed {
        svg {
          path {
            fill: #fff;
          }
        }
      }

      svg {
        height: 16px;
        width: 16px;
      }
    }
  }

  &.dark-mode {
    .chart-card-active-filters {
      .ant-tag {
        color: #f2f2f2;
        background: #12131b;
        border-color: #20212d;
        svg {
          path {
            fill: #f2f2f2;
          }
        }
      }
    }
    .chart-card-actions {
      svg {
        path {
          fill: #f2f2f2;
        }
      }
    }
  }
}

.three-doted-actions-popover {
  .ant-popover-content {
    .ant-popover-inner {
      .ant-popover-inner-content {
        padding: 0px;
      }
    }
  }
}
