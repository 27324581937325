@import "../../../../../../../../../../../assets/styles/breakpoints.scss";

.payment-method-elements {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  flex: 1 0 15%;

  @include mobile {
    flex: 1 0 100%;
  }

  .btn-payment-method {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    align-items: center;
    background: #f5f5f7;
    border-radius: 5px;
    color: #323338;
    border: none;
    width: 127.5px;
    height: 32px;
  }

  .selected {
    background: #1264a3;
    color: #ffffff;
  }
}
