.footerInvoiceCont {
  grid-area: auto;
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 55% 45%;
  grid-template-rows: auto;
  justify-content: center;
  gap: 15px 0;
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .footerInvoiceCont {
    grid-template-columns: 65% 35%;
  }
}
@media (min-width: 1281px) {
  .footerInvoiceCont {
    grid-template-columns: 65% 35%;
  }
}
.footerInvoiceCont .textSide {
  grid-area: auto;
  width: 100%;
  height: auto;
  align-self: center;
  padding: 0 10px;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto;
  align-content: start;
  justify-content: center;
  gap: 5px 0;
}
.footerInvoiceCont .textSide p {
  grid-area: auto;
  width: 100%;
  height: auto;
  font-size: 1rem;
  font-weight: 400;
  font-style: normal;
  color: #000000;
  text-align: center;
  height: fit-content;
  text-align: center;
  color: #101010;
  font-size: 7px;
}
@media (min-width: 481px) and (max-width: 767px) {
  .footerInvoiceCont .textSide p {
    font-size: 1rem;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .footerInvoiceCont .textSide p {
    font-size: 1rem;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .footerInvoiceCont .textSide p {
    font-size: 1rem;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .footerInvoiceCont .textSide p {
    font-size: 1rem;
  }
}
@media (min-width: 1281px) {
  .footerInvoiceCont .textSide p {
    font-size: 1rem;
  }
}
@media (min-width: 481px) and (max-width: 767px) {
  .footerInvoiceCont .textSide p {
    font-size: 7px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .footerInvoiceCont .textSide p {
    font-size: 8px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .footerInvoiceCont .textSide p {
    font-size: 9px;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .footerInvoiceCont .textSide p {
    font-size: 13px;
  }
}
@media (min-width: 1281px) {
  .footerInvoiceCont .textSide p {
    font-size: 13px;
  }
}
.footerInvoiceCont .itemsTable {
  grid-area: auto;
  width: 100%;
  height: auto;
  border-left: 1px solid #454545;
  border-right: 1px solid #454545;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto;
  align-content: start;
  justify-content: center;
}
.footerInvoiceCont .itemsTable section {
  grid-area: auto;
  width: 100%;
  height: auto;
  border-bottom: 1px solid #454545;
  padding: 2px 0;
  display: grid;
  grid-template-columns: 58% 35%;
  grid-template-rows: auto;
  justify-content: center;
}
.footerInvoiceCont .itemsTable section strong {
  grid-area: auto;
  width: 100%;
  height: auto;
  height: fit-content;
  align-self: center;
  font-size: 1rem;
  font-weight: 400;
  font-style: normal;
  color: #000000;
  text-align: center;
  font-weight: 800;
  font-size: 9px;
  color: #101010;
  overflow-wrap: break-word;
  text-align: start;
}
@media (min-width: 481px) and (max-width: 767px) {
  .footerInvoiceCont .itemsTable section strong {
    font-size: 1rem;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .footerInvoiceCont .itemsTable section strong {
    font-size: 1rem;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .footerInvoiceCont .itemsTable section strong {
    font-size: 1rem;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .footerInvoiceCont .itemsTable section strong {
    font-size: 1rem;
  }
}
@media (min-width: 1281px) {
  .footerInvoiceCont .itemsTable section strong {
    font-size: 1rem;
  }
}
@media (min-width: 481px) and (max-width: 767px) {
  .footerInvoiceCont .itemsTable section strong {
    font-size: 9px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .footerInvoiceCont .itemsTable section strong {
    font-size: 10px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .footerInvoiceCont .itemsTable section strong {
    font-size: 11px;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .footerInvoiceCont .itemsTable section strong {
    font-size: 14px;
  }
}
@media (min-width: 1281px) {
  .footerInvoiceCont .itemsTable section strong {
    font-size: 14px;
  }
}
.footerInvoiceCont .itemsTable section p {
  grid-area: auto;
  width: 100%;
  height: auto;
  height: fit-content;
  font-size: 1rem;
  font-weight: 400;
  font-style: normal;
  color: #000000;
  text-align: center;
  align-self: center;
  align-self: center;
  color: #1c1c1c;
  overflow-wrap: break-word;
  font-size: 7px;
  text-align: end;
}
@media (min-width: 481px) and (max-width: 767px) {
  .footerInvoiceCont .itemsTable section p {
    font-size: 1rem;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .footerInvoiceCont .itemsTable section p {
    font-size: 1rem;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .footerInvoiceCont .itemsTable section p {
    font-size: 1rem;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .footerInvoiceCont .itemsTable section p {
    font-size: 1rem;
  }
}
@media (min-width: 1281px) {
  .footerInvoiceCont .itemsTable section p {
    font-size: 1rem;
  }
}
@media (min-width: 481px) and (max-width: 767px) {
  .footerInvoiceCont .itemsTable section p {
    font-size: 7px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .footerInvoiceCont .itemsTable section p {
    font-size: 8px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .footerInvoiceCont .itemsTable section p {
    font-size: 9px;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .footerInvoiceCont .itemsTable section p {
    font-size: 13px;
  }
}
@media (min-width: 1281px) {
  .footerInvoiceCont .itemsTable section p {
    font-size: 13px;
  }
}
.footerInvoiceCont h3 {
  grid-area: 3/1/4/-1;
  font-size: 1rem;
  font-weight: 400;
  font-style: normal;
  color: #000000;
  text-align: center;
  font-weight: 800;
  color: #1c1c1c;
  text-align: center;
  font-size: 9px;
}
@media (min-width: 481px) and (max-width: 767px) {
  .footerInvoiceCont h3 {
    font-size: 1rem;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .footerInvoiceCont h3 {
    font-size: 1rem;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .footerInvoiceCont h3 {
    font-size: 1rem;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .footerInvoiceCont h3 {
    font-size: 1rem;
  }
}
@media (min-width: 1281px) {
  .footerInvoiceCont h3 {
    font-size: 1rem;
  }
}
@media (min-width: 481px) and (max-width: 767px) {
  .footerInvoiceCont h3 {
    font-size: 9px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .footerInvoiceCont h3 {
    font-size: 10px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .footerInvoiceCont h3 {
    font-size: 11px;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .footerInvoiceCont h3 {
    font-size: 14px;
  }
}
@media (min-width: 1281px) {
  .footerInvoiceCont h3 {
    font-size: 14px;
  }
}
.footerInvoiceCont ._ON_highlight {
  background-color: rgba(0, 0, 0, 0.1882352941);
}