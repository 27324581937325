$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.memberTeamModal {
  width: fit-content !important;

  .ant-modal-content {
    .ant-modal-close {
      width: 45px;
      height: 45px;
    }
    .ant-modal-header {
      display: flex;
      align-items: center;
      height: 45px;
      background-color: #f5f5f7;
      .ant-modal-title {
        width: 100%;
        color: #323338;
        font-size: 16px;
        font-weight: 600;
      }
    }
    .ant-modal-body {
      .memberTeam-container {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        width: 100%;
        .editMembers-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 20px;
          .searchEditMembers {
            width: 300px;
          }
        }
      }
    }
  }
}

.memberTeamModalDark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close .ant-modal-close-x svg {
      fill: #f2f2f2 !important;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #f2f2f2 !important;
      }
    }
    .ant-modal-body {
      background-color: $cardModalBodyDark;

      .ant-input-affix-wrapper {
        background-color: $inputModalDark !important;
      }
      .ant-input {
        background-color: $inputModalDark !important;
        color: #fff;
        &::placeholder {
          color: #acacca;
        }
      }
      .ant-checkbox-wrapper {
        background-color: $inputModalDark;
      }
      .confirmContainer {
        background-color: $cardModalBodyDark !important;
      }
    }
  }
}

@keyframes team {
  from {
    opacity: 0;
    filter: blur(2px);
  }
  to {
    opacity: 1;
    filter: blur(0);
  }
}
