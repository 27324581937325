@import "../../../../../../../../../../index.scss";

.draftMenuNotClickable {
  display: none !important;
}

.application-control-panel-dark {
  display: flex;
  background: #1f212d !important;
  height: 100%;
  width: 100%;

  .amounts-indic {
    display: flex;
    align-items: center;
    justify-content: center;
    // background-color: #0c3f66 !important;
    background-color: #12131b !important;
    // width: stretch;
    width: 99%;
    overflow-x: auto;
    overflow-y: hidden;
    height: 70px;
    gap: 100px;
    margin: 20px 10px 0 7px;
    border-radius: 10px;
    // box-shadow: 0 3px 5px rgba(0, 0, 0, 0.16);
    box-shadow: #028391 0px 0px 6px 3px;
    background-color: white;
  }

  flex-direction: column;
  .quickSaveButtonUnClickable {
    opacity: 0.62 !important;
    cursor: not-allowed !important;
  }

  .total-amount-price {
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: var(--unnamed-color-323338);
    // text-align: left;
    font: normal normal 600 18px/22px Open Sans;
    // color: #71cf48;
    color: white;
    opacity: 1;
    gap: 10px;
  }
  // .total-retainage-price {
  //   color: var(--unnamed-color-323338);
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: center;
  //   text-align: left;
  //   font: normal normal 600 16px/22px Open Sans;
  //   letter-spacing: 0px;
  //   // color: #eb4345;
  //   color: white;
  //   opacity: 1;
  //   margin-right: 10px;
  // }

  .leftSideContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #12131b !important;
    // width: stretch;
    // width: 80%;
    width: 50%;

    overflow-x: auto;
    overflow-y: hidden;
    height: 70px;
    margin: 0px 10px 0 7px;
    border-radius: 10px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.16);
    background-color: white;

    // .leftSideContainer{
    // 	opacity: 0.4 !important;
    // }
    .draftNotClickable {
      opacity: 0.4;
      cursor: not-allowed;
    }

    .statuses {
      width: fit-content;
      height: fit-content;
      display: flex;
      align-items: center;
      margin-left: 20px;
      gap: 20px;

      .approvedStatus {
        @include mondayButton(#00a464, #00bd74, #fff);

        &:disabled {
          @include mondayButton(#008552, #009b5f, #a6c997);

          svg {
            fill: #a6c997;
          }
        }
        .selectedCategoryHolder {
          width: 65px;
          display: flex;
          justify-content: center;
        }

        svg {
          width: 14px;
          height: 14px;
        }
      }
      .sentStatus {
        @include mondayButton(#f6862c, #faa157, #fff);

        &:disabled {
          @include mondayButton(#d67627, #cc8346, #dacccc);

          svg {
            fill: #dacccc;
          }
        }
        .selectedCategoryHolder {
          width: 65px;
          display: flex;
          justify-content: center;
        }

        svg {
          width: 14px;
          height: 14px;
        }
      }
      .draftStatus {
        @include mondayButton(rgb(238, 179, 31), #fcc94a, #fff);

        &:disabled {
          @include mondayButton(rgb(199, 150, 28), #d1a73e, #dacccc);

          svg {
            fill: #dacccc;
          }
        }
        .selectedCategoryHolder {
          width: 65px;
          display: flex;
          justify-content: center;
        }

        svg {
          width: 14px;
          height: 14px;
        }
      }
      .paidStatus {
        @include mondayButton(#00a88e, #00caac, #fff);

        &:disabled {
          @include mondayButton(#008b77, rgb(3, 170, 145), #a6c997);

          svg {
            fill: #a6c997;
          }
        }
        .selectedCategoryHolder {
          width: 65px;
          display: flex;
          justify-content: center;
        }

        svg {
          width: 14px;
          height: 14px;
        }
      }

      .autoSaveToggle {
        margin: 0;
        white-space: nowrap;

        .ant-switch-handle {
          content: url("../../../../../../../../BasePage/src/switchAutoSaveOff.svg");
          background-color: white;
          padding: 2px;
          border-radius: 9px;
        }
      }

      .ant-switch-checked {
        background-color: #71cf48;

        display: flex;
        justify-content: center;

        .ant-switch-handle {
          content: url("../../../../../../../../BasePage/src/switchSaveIcon.svg");
          background-color: white;
          border-radius: 9px;
        }
      }
    }

    .application-header-options {
      display: flex;
      align-items: center;
      flex-flow: wrap;
      margin-right: 20px;
      gap: 20px;

      .dropdownExportToClassName {
        margin-right: -20px;
        margin-left: -10px;
        .exportToButton {
          border: 0;
          width: 32px;
          height: 32px;
          border-radius: 5px;
          // margin-right: 20px;
          background-color: #1264a3;
          &:hover {
            background-color: #0d4c7c;
            border-color: #0d4c7c;
            transition: 0.2s;
          }
        }
      }

      .quickSaveApplicationHeader {
        @include mondayButton(#68c142, #71cf48, #fff);

        &:disabled {
          @include mondayButton(#5aa33a, #70b652, #a6c997);

          svg {
            fill: #a6c997;
          }
        }
      }
      .ant-switch {
        background: #fe4c4a;

        .ant-switch-checked {
          background: yellowgreen;
        }
      }
    }
  }
  .rightSideContainer {
    display: flex;
    width: 50%;
    align-items: center;
    justify-content: space-between;
    background-color: #12131b !important;
    // width: stretch;
    overflow-x: auto;
    overflow-y: hidden;
    height: 70px;
    margin: 0px 10px 0 7px;
    border-radius: 10px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.16);
    color: white;

    .applicationSwitcherContainer {
      display: flex;
      gap: 10px;
      padding-right: 20px;

      .nextApplicationButton {
        @include mondayButton(#1264a3, #0f5c97, #fff);
        &:disabled {
          @include mondayButton(#0b3e64, #0f5c97, #548bb3);
          svg {
            fill: #548bb3;
          }
        }
      }

      .previousApplicationButton {
        @include mondayButton(#1264a3, #0f5c97, #fff);
        &:disabled {
          @include mondayButton(#0b3e64, #0f5c97, #548bb3);
          svg {
            fill: #548bb3;
          }
        }
      }
      .previousApplicationSpan {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        background-color: #1264a3;
        &:hover {
          background-color: #0d4c7c;
          border-color: #0d4c7c;
          transition: 0.2s;
        }
        cursor: pointer;
        svg {
          fill: white;
        }
        &:disabled {
          cursor: not-allowed;
          svg {
            fill: #548bb3;
          }
        }
      }
    }

    .detailsContainer {
      display: flex;
      margin-left: 20px;

      .ContainerLabel {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-right: 5px;
        .label {
          @include textEllipsis();
        }
      }

      .ContainerDate {
        font-weight: 700;
        margin-right: 10px;
      }
    }
  }

  padding: 15px 20px;
  background: #f8f8f8;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .application-header-address {
    font-family: "Lato", sans-serif;
  }

  .divider {
    width: 1px;
    height: 30px;
    border-right: 1px dashed #666;
  }

  .darkModeToggle {
    margin-top: 2px;
    .ant-switch-handle::before {
      background-color: #fff;
    }
  }
}

.application-control-panel-light {
  display: flex;
  background: #fff !important;
  height: 100%;
  width: 100%;
  flex-direction: column;
  .quickSaveButtonUnClickable {
    opacity: 0.62 !important;
    cursor: not-allowed !important;
  }
  .amounts-indic {
    display: flex;
    align-items: center;
    justify-content: center;
    // background-color: #0c3f66 !important;
    // background-color: #12131b !important;
    // width: stretch;
    width: 99%;
    overflow-x: auto;
    overflow-y: hidden;
    height: 70px;
    gap: 100px;
    margin: 20px 10px 0 7px;
    border-radius: 10px;
    // box-shadow: 0 3px 5px rgba(0, 0, 0, 0.16);
    box-shadow: #c1aefc 0px 0px 6px 3px;
    background-color: white;
  }
  .total-amount-price {
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: var(--unnamed-color-323338);
    // text-align: left;
    font: normal normal 600 18px/22px Open Sans;
    // color: #71cf48;
    // color: white;
    opacity: 1;
    gap: 10px;
  }
  .total-retainage-price {
    color: var(--unnamed-color-323338);
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    font: normal normal 600 16px/22px Open Sans;
    letter-spacing: 0px;
    // color: #eb4345;
    color: black;
    opacity: 1;
    margin-right: 10px;
  }

  // .leftSideContainer {
  // 	display: flex;
  // 	align-items: center;
  // 	justify-content: space-between;
  // 	//=
  // 	width: stretch;
  // 	overflow-x: auto;
  // 	overflow-y: hidden;
  // 	//=
  // 	height: 70px;
  // 	margin: 0px 10px 0 7px;
  // 	border-radius: 10px;
  // 	box-shadow: 0 3px 5px rgba(0, 0, 0, 0.16);
  // 	background-color: white;

  // 	.statuses {
  // 		width: fit-content;
  // 		height: fit-content;
  // 		display: flex;
  // 		align-items: center;
  // 		margin-left: 20px;
  // 		gap: 20px;

  // 		.approvedStatus {
  // 			@include mondayButton(#00a464, #00bd74, #fff);
  // 			&:disabled {
  // 				@include mondayButton(#008552, #009b5f, #a6c997);

  // 				svg {
  // 					fill: #a6c997;
  // 				}
  // 			}
  // 			.selectedCategoryHolder {
  // 				width: 65px;
  // 				display: flex;
  // 				justify-content: center;
  // 			}
  // 			svg {
  // 				width: 14px;
  // 				height: 14px;
  // 			}
  // 		}
  // 		.sentStatus {
  // 			@include mondayButton(#f6862c, #faa157, #fff);
  // 			&:disabled {
  // 				@include mondayButton(#d67627, #cc8346, #dacccc);

  // 				svg {
  // 					fill: #dacccc;
  // 				}
  // 			}
  // 			.selectedCategoryHolder {
  // 				width: 65px;
  // 				display: flex;
  // 				justify-content: center;
  // 			}
  // 			svg {
  // 				width: 14px;
  // 				height: 14px;
  // 			}
  // 		}
  // 		.draftStatus {
  // 			@include mondayButton(rgb(238, 179, 31), #fcc94a, #fff);
  // 			&:disabled {
  // 				@include mondayButton(rgb(199, 150, 28), #d1a73e, #dacccc);

  // 				svg {
  // 					fill: #dacccc;
  // 				}
  // 			}
  // 			.selectedCategoryHolder {
  // 				width: 65px;
  // 				display: flex;
  // 				justify-content: center;
  // 			}
  // 			svg {
  // 				width: 14px;
  // 				height: 14px;
  // 			}
  // 		}
  // 		.paidStatus {
  // 			@include mondayButton(#00a88e, #00caac, #fff);
  // 			&:disabled {
  // 				@include mondayButton(#008b77, rgb(3, 170, 145), #a6c997);

  // 				svg {
  // 					fill: #a6c997;
  // 				}
  // 			}
  // 			.selectedCategoryHolder {
  // 				width: 65px;
  // 				display: flex;
  // 				justify-content: center;
  // 			}
  // 			svg {
  // 				width: 14px;
  // 				height: 14px;
  // 			}
  // 		}

  // 		.autoSaveToggle {
  // 			margin: 0;
  // 			white-space: nowrap;

  // 			.ant-switch-handle {
  // 				content: url("../../../../../../../../BasePage/src/switchAutoSaveOff.svg");
  // 				background-color: white;
  // 				padding: 2px;
  // 				border-radius: 9px;
  // 			}
  // 		}

  // 		.ant-switch-checked {
  // 			background-color: #71cf48;

  // 			display: flex;
  // 			justify-content: center;

  // 			.ant-switch-handle {
  // 				content: url("../../../../../../../../BasePage/src/switchSaveIcon.svg");
  // 				background-color: white;
  // 				border-radius: 9px;
  // 			}
  // 		}
  // 	}

  // 	.application-header-options {
  // 		display: flex;
  // 		align-items: center;
  // 		flex-flow: wrap;
  // 		margin-right: 20px;
  // 		gap: 20px;

  // 		.dropdownExportToClassName {
  // 			margin-right: -20px;
  // 			.exportToButton {
  // 				border: 0;
  // 				width: 32px;
  // 				height: 32px;
  // 				border-radius: 5px;
  // 				margin-right: 20px;
  // 				background-color: #1264a3;
  // 			}
  // 		}

  // 		.quickSaveApplicationHeader {
  // 			@include mondayButton(#68c142, #71cf48, #fff);

  // 			&:disabled {
  // 				@include mondayButton(#5aa33a, #70b652, #a6c997);

  // 				svg {
  // 					fill: #a6c997;
  // 				}
  // 			}
  // 		}
  // 	}
  // }
  // .rightSideContainer {
  // 	display: flex;
  // 	align-items: center;
  // 	justify-content: space-between;
  // 	//=
  // 	width: stretch;
  // 	overflow-x: auto;
  // 	overflow-y: hidden;
  // 	//=
  // 	height: 70px;
  // 	margin: 0px 10px 0 7px;
  // 	border-radius: 10px;
  // 	box-shadow: 0 3px 5px rgba(0, 0, 0, 0.16);
  // 	background-color: white;

  // 	.applicationSwitcherContainer {
  // 		display: flex;
  // 		gap: 10px;
  // 		padding-right: 20px;

  // 		.nextApplicationButton {
  // 			@include mondayButton(#1264a3, #0f5c97, #fff);
  // 			&:disabled {
  // 				@include mondayButton(#0b3e64, #0f5c97, #548bb3);
  // 				svg {
  // 					fill: #548bb3;
  // 				}
  // 			}
  // 		}

  // 		.previousApplicationButton {
  // 			@include mondayButton(#1264a3, #0f5c97, #fff);

  // 			&:disabled {
  // 				@include mondayButton(#0b3e64, #0f5c97, #548bb3);
  // 				svg {
  // 					fill: #548bb3;
  // 				}
  // 			}
  // 		}
  // 	}

  // 	.detailsContainer {
  // 		display: flex;
  // 		margin-left: 20px;

  // 		.ContainerLabel {
  // 			display: flex;
  // 			flex-direction: column;
  // 			align-items: flex-end;
  // 			margin-right: 5px;

  // 			.label {
  // 				max-width: 116.3;
  // 				@include textEllipsis();
  // 			}
  // 		}

  // 		.ContainerDate {
  // 			font-weight: 700;
  // 			margin-right: 10px;
  // 		}
  // 	}
  // }
  .leftSideContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    width: 50%;
    overflow-x: auto;
    overflow-y: hidden;
    height: 70px;
    margin: 0px 10px 0 7px;
    border-radius: 10px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.16);
    background-color: white;
    color: black;

    .draftNotClickable {
      opacity: 0.6;
      cursor: not-allowed;
    }

    .statuses {
      width: fit-content;
      height: fit-content;
      display: flex;
      align-items: center;
      margin-left: 20px;
      gap: 20px;

      .approvedStatus {
        @include mondayButton(#00a464, #00bd74, #fff);

        &:disabled {
          @include mondayButton(#008552, #009b5f, #a6c997);

          svg {
            fill: #a6c997;
          }
        }
        .selectedCategoryHolder {
          width: 65px;
          display: flex;
          justify-content: center;
        }

        svg {
          width: 14px;
          height: 14px;
        }
      }
      .sentStatus {
        @include mondayButton(#f6862c, #faa157, #fff);

        &:disabled {
          @include mondayButton(#d67627, #cc8346, #dacccc);

          svg {
            fill: #dacccc;
          }
        }
        .selectedCategoryHolder {
          width: 65px;
          display: flex;
          justify-content: center;
        }

        svg {
          width: 14px;
          height: 14px;
        }
      }
      .draftStatus {
        @include mondayButton(rgb(238, 179, 31), #fcc94a, #fff);

        &:disabled {
          @include mondayButton(rgb(199, 150, 28), #d1a73e, #dacccc);

          svg {
            fill: #dacccc;
          }
        }
        .selectedCategoryHolder {
          width: 65px;
          display: flex;
          justify-content: center;
        }

        svg {
          width: 14px;
          height: 14px;
        }
      }
      .paidStatus {
        @include mondayButton(#00a88e, #00caac, #fff);

        &:disabled {
          @include mondayButton(#008b77, rgb(3, 170, 145), #a6c997);

          svg {
            fill: #a6c997;
          }
        }
        .selectedCategoryHolder {
          width: 65px;
          display: flex;
          justify-content: center;
        }

        svg {
          width: 14px;
          height: 14px;
        }
      }

      .autoSaveToggle {
        margin: 0;
        white-space: nowrap;

        .ant-switch-handle {
          content: url("../../../../../../../../BasePage/src/switchAutoSaveOff.svg");
          background-color: white;
          padding: 2px;
          border-radius: 9px;
        }
      }

      .ant-switch-checked {
        background-color: #71cf48;

        display: flex;
        justify-content: center;

        .ant-switch-handle {
          content: url("../../../../../../../../BasePage/src/switchSaveIcon.svg");
          background-color: white;
          border-radius: 9px;
        }
      }
    }

    .application-header-options {
      display: flex;
      align-items: center;
      flex-flow: wrap;
      margin-right: 20px;
      gap: 20px;

      .dropdownExportToClassName {
        margin-right: -20px;
        .exportToButton {
          border: 0;
          width: 32px;
          height: 32px;
          border-radius: 5px;
          margin-right: 20px;
          background-color: #1264a3;
          &:hover {
            background-color: #0d4c7c;
            border-color: #0d4c7c;
            transition: 0.2s;
          }
        }
      }

      .quickSaveApplicationHeader {
        @include mondayButton(#68c142, #71cf48, #fff);

        &:disabled {
          @include mondayButton(#5aa33a, #70b652, #a6c997);

          svg {
            fill: #a6c997;
          }
        }
      }
      .ant-switch {
        background: #fe4c4a;

        .ant-switch-checked {
          background: yellowgreen;
        }
      }
    }
  }
  .rightSideContainer {
    display: flex;
    width: 60%;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    // width: stretch;
    overflow-x: auto;
    overflow-y: hidden;
    height: 70px;
    margin: 0px 10px 0 7px;
    border-radius: 10px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.16);
    color: black;

    .applicationSwitcherContainer {
      display: flex;
      gap: 10px;
      padding-right: 20px;

      .nextApplicationButton {
        @include mondayButton(#1264a3, #0f5c97, #fff);
        &:disabled {
          @include mondayButton(#0b3e64, #0f5c97, #548bb3);
          svg {
            fill: #548bb3;
          }
        }
      }

      .previousApplicationButton {
        @include mondayButton(#1264a3, #0f5c97, #fff);
        &:disabled {
          @include mondayButton(#0b3e64, #0f5c97, #548bb3);
          svg {
            fill: #548bb3;
          }
        }
      }
      .previousApplicationSpan {
        display: flex;
        width: 32px;
        height: 32px;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        background-color: #1264a3;
        &:hover {
          background-color: #0d4c7c;
          border-color: #0d4c7c;
          transition: 0.2s;
        }
        cursor: pointer;
        svg {
          fill: white;
        }
        &:disabled {
          cursor: not-allowed;
          svg {
            fill: #548bb3;
          }
        }
      }
    }

    .detailsContainer {
      display: flex;
      margin-left: 20px;
      .ContainerLabel {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-right: 5px;
        .label {
          @include textEllipsis();
        }
      }

      .ContainerDate {
        font-weight: 700;
        margin-right: 10px;
      }
    }
  }

  padding: 15px 20px;
  background: #f8f8f8;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .application-header-address {
    font-family: "Lato", sans-serif;
  }

  .divider {
    width: 1px;
    height: 30px;
    border-right: 1px dashed #666;
  }

  .darkModeToggle {
    margin-top: 2px;
    .ant-switch-handle::before {
      background-color: #fff;
    }
  }
}

.logsButtonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1264a3 !important;
  width: 32px;
  height: 32px;
  border-radius: 5px;
  cursor: pointer;
}

@media screen and (max-width: 1734px) {
  .application-control-panel-dark {
    display: flex;
    flex-direction: column;
    height: 180px !important;
  }
  .application-control-panel-light {
    display: flex;
    flex-direction: column;
    height: 180px !important;
  }
}

.clearfix {
  display: flex;
  overflow-x: scroll;
  width: 100%;
  h1 {
    width: 100%;
    max-height: 60px;
    height: fit-content;
    font-size: medium;
    display: flex;
    overflow-x: scroll;
    flex-wrap: wrap;
    max-width: 300px;
  }
}
