$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.choose-reports-modal {
  height: fit-content !important;
  width: fit-content !important;
  max-height: 100dvh;
  max-width: calc(100dvw - 50px);
  .ant-modal-content {
    .ant-modal-header {
      height: 45px;
      display: flex;
      align-items: center;
      background-color: #f8f8fa;
      overflow: hidden;
      border: none;

      .ant-modal-title {
        font-size: 16px;
        width: 100%;
        font-weight: 600;
        color: #323338;
        padding: 0 !important;
        height: 45px;
        display: flex;
        align-items: center;
      }
    }
    .ant-modal-body {
      width: 100%;
      height: 100%;
      //   overflow: hidden;
      .general-reports-cards {
        display: flex !important;
        flex-wrap: wrap;
        gap: 20px;
        max-height: calc(100dvh - 150px);
        overflow: auto;
        width: 100%;
        height: 100% !important;
        .default-report {
          display: flex;
          align-items: center;
          flex-direction: column;
          padding-right: 20px;
          gap: 10px;
          border-right: 1px solid #e8e8e8;
          > span {
            font-size: 14px;
            font-weight: 600;
            color: #323338;
          }
        }
        .reportPreviewCardWrapper {
          .reportPreviewCardContainer {
            border: 1px solid #e8e8e8;
          }
          .reportMetadata-footer {
            display: none;
          }
        }
      }
    }
    // .ant-modal-footer {

    // }
  }
}

.choose-reports-modal-dark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;

        .titleContainer .buttons .settingsIcon path {
          fill: #fff;
        }
      }
    }
    .ant-modal-body .general-reports-cards .default-report span {
      color: #fff;
    }
  }
}
