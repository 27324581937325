// .service-header-events-modal {
//   .ant-modal-close {
//     .ant-modal-close-x {
//       color: #fff;
//       font-size: 1.25rem;
//     }
//   }
//   .ant-modal-header {
//     background-color: #1679d4;
//     border-bottom-color: #709af5;

//     .ant-modal-title {
//       color: #fff;
//       font-size: 1.25rem;
//       letter-spacing: 2px;
//     }
//   }

//   .ant-modal-footer {
//   }

//   .service-header-events-selector {
//     width: 100%;
//   }
// }

@import "../../../../../../../../../../../../index.scss";

.serviceHeaderEventsModalHoist {
  .ant-modal-content {
    border-radius: 10px;
  }

  border-radius: 10px;
  overflow: hidden;
  .ant-modal-close {
    .ant-modal-close-x {
      color: #fff;
      font-size: 1.25rem;
      margin-top: -7px;
    }
  }
  .ant-modal-header {
    background-color: #1264a3;
    border-bottom-color: #1264a3;
    height: 45px;
    padding: 0;
    padding: 12px 0 0 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    .ant-modal-title {
      color: #fff;
      font-size: 1.25rem;
      letter-spacing: 2px;
      margin-left: 10px;
    }
  }

  .ant-modal-footer {
    height: 45px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top: 0;
    .ant-btn-primary {
      background: #1679d4;
      border-color: #1679d4;
    }

    .serviceFooterEventModal {
      display: flex;
      justify-content: flex-end;
      margin-top: -10px;
      .cancelMondayButton {
        margin-right: 10px;
        @include mondayButton(#fe4c4a, #eb4345, #fff);
      }
      .applyMondayButton {
        @include mondayButton(#71cf48, #68c142, #fff);
        svg {
          height: 20px;
          width: 25px;
          margin-left: -5px;
          margin-right: -5px;
        }
      }
    }
  }

  .ant-modal-body {
    height: 100px;
    display: flex;
    align-items: center;
  }

  .service-header-events-selector {
    width: 100%;
    border: 1px solid black;
    border-radius: 5px;
    overflow: hidden;
  }
}

.serviceHeaderEventsModalHoistDark {
  .ant-modal-content {
    border-radius: 10px;
    background: #1f212d;
  }

  border-radius: 10px;
  overflow: hidden;
  .ant-modal-close {
    .ant-modal-close-x {
      color: #fff;
      font-size: 1.25rem;
      margin-top: -7px;
    }
  }
  .ant-modal-header {
    background-color: #12131b;
    border-bottom-color: #12131b;
    height: 45px;
    padding: 0;
    padding: 12px 0 0 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    .ant-modal-title {
      color: #fff;
      font-size: 1.25rem;
      letter-spacing: 2px;
      margin-left: 10px;
      font-size: 16px;
    }
  }

  .ant-modal-footer {
    background: #1f212d;
    height: 45px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top: 0;
    .ant-btn-primary {
      background: #1679d4;
      border-color: #1679d4;
    }

    .serviceFooterEventModal {
      display: flex;
      justify-content: flex-end;
      margin-top: -10px;
      .cancelMondayButton {
        margin-right: 10px;
        @include mondayButton(#fe4c4a, #eb4345, #fff);
      }
      .applyMondayButton {
        @include mondayButton(#71cf48, #68c142, #fff);
        svg {
          height: 20px;
          width: 25px;
          margin-left: -5px;
          margin-right: -5px;
        }
      }
    }
  }

  .ant-modal-body {
    background: #1f212d;
    height: 100px;
    display: flex;
    align-items: center;
  }

  .service-header-events-selector {
    width: 100%;
    border: 1px solid black;
    border-radius: 5px;
    overflow: hidden;

    .ant-select-selector {
      background: white !important;

      .ant-select-selection-placeholder {
        color: grey;
      }
    }
  }
}
