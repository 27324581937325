$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.usNationalEditModal {
  height: fit-content !important;
  width: fit-content !important;

  .logButtons {
    display: flex;
    gap: 10px;
  }

  .ant-modal-content {
    border-radius: 10px;
    width: calc(100vw - 20px) !important;
    height: calc(100vh - 20px) !important;
    max-height: 900px !important;
    max-width: 1400px;

    .ant-modal-close {
      height: 45px;
      width: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
      .annt-modal-close-x {
        height: 45px;
        width: 45px;
        svg {
          fill: #333238;
        }
      }
    }

    .anticon.anticon-delete {
      color: #fe4c4a !important;
    }

    .ant-modal-header {
      height: 45px;
      display: flex;
      align-items: center;
      background-color: #f5f6f8;
      border-radius: 10px 10px 0px 0px;
      border: none;
      .ant-modal-title {
        font-size: 16px;
        font-weight: 600;
        color: #333238;
      }
    }

    .ant-modal-body {
      height: calc(100% - 100px);
      width: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      .ant-form-item {
        margin-bottom: 10px;
        .ant-form-item-label {
          font-weight: 600;
          label {
            font-size: 15px;
          }
        }
      }

      .ant-form {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: row;
        height: 100%;
        width: 100%;
        gap: 10px;

        @media (max-width: 950px) {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          flex-direction: column;
        }

        .vendor-info-container {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          flex-direction: column;
          width: 35%;
          height: 100%;
          gap: 10px;

          @media (max-width: 950px) {
            width: 100%;
          }

          .basic-vendor-info {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            gap: 10px;

            .labeledInputContainer {
              width: 100%;

              @media (max-width: 950px) {
                max-width: 320px;
              }

              @media (max-width: 710px) {
                max-width: unset;
              }
            }

            @media (max-width: 950px) {
              flex-direction: row;
              flex-wrap: wrap;
            }
          }

          .vendor-upload-section {
            width: 100%;
            .ant-upload-wrapper {
              width: 100%;
              display: flex;
              justify-content: flex-start;
              align-items: flex-start;
              flex-direction: column;
              gap: 10px;

              .ant-upload-select {
                width: 100%;
                .ant-upload {
                  width: 100%;
                  display: flex;
                  justify-content: flex-start;
                  align-items: flex-start;
                  flex-direction: row;
                  gap: 10px;

                  .ant-btn-icon {
                    @media (max-width: 460px) {
                      display: none;
                    }
                  }
                }
              }

              .ant-upload-list {
                width: 100%;
              }
            }
          }
        }

        .vendor-map-section {
          width: 65%;
          height: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          flex-direction: column;

          @media (max-width: 950px) {
            width: 100%;
          }

          .map-control-section {
            min-height: 82px;
            height: fit-content;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            gap: 10px;

            @media (max-width: 640px) {
              justify-content: flex-start;
              align-items: flex-start;
              flex-direction: column;
              padding-bottom: 10px;
            }

            .vendor-controller-buttons {
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 10px;
              flex-direction: row;
              height: 104%;
            }

            .ant-select-selector {
              min-width: 290px;
            }
          }

          #vendor-map {
            width: 100%;
            min-height: 400px;
            flex: 1;

            .gm-style-iw.gm-style-iw-c {
              top: -18px;
              padding: 0 !important;
              .gm-style-iw-d {
                padding: 5px;
                overflow: auto !important;
              }
              button {
                display: none !important;
              }
            }

            .gm-style-iw-ch {
              display: none;
            }

            .gm-style-iw-tc {
              top: -20px;
              width: 18px;
              &::after {
                width: 18px;
              }
            }
          }
        }
      }
    }

    .ant-modal-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: none;
    }
  }
}

.usNationalEditModalDark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close-x {
      svg {
        fill: #fff;
      }
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark !important;
      .ant-modal-title {
        color: #fff !important;
      }
    }
    .ant-modal-body {
      background-color: $cardModalBodyDark;
      .ant-form {
        label {
          color: #fff;
        }
        .ant-upload-icon {
          svg {
            color: #fff;
          }
        }
        .labeledInputLabel {
          color: #fff;
        }
        .ant-upload-list-item-name {
          color: #fff;
        }
        .ant-input {
          background-color: $inputModalDark !important;
          color: #fff;
          &::placeholder {
            color: #acacca;
          }
        }
        .ant-select-clear {
          background-color: $inputModalDark !important;
          color: #acacca !important;
        }
        .ant-select {
          .ant-select-selector {
            background-color: $inputModalDark !important;
            color: #fff;
            border-color: transparent;

            .ant-select-selection-search-input {
              background-color: $inputModalDark !important;
              color: #fff;
            }
            .ant-select-selection-placeholder {
              color: #acacca;
            }
          }
          .ant-select-arrow {
            svg {
              fill: #fff;
            }
          }
        }
      }
    }
    .ant-modal-footer {
      background-color: $cardModalBodyDark;
    }
  }
}
