@import "../../../../../../../../../assets/styles/breakpoints.scss";

.payment-projects-container {
  width: fit-content;
  position: relative;
  animation: slide-in 1s forwards;
  right: -100%;
  margin-bottom: 10px;
  overflow: hidden;

  @include tablet {
    width: 100%;
  }

  .payment-projects-wrapper {
    box-shadow: 0px 0px 11px -8px;
    height: 100%;
    background-color: #ffffff;
    border-radius: 5px;
    .payment-projects-header {
      padding: 0 20px;
      display: flex;
      justify-content: space-between;
    }
    .payment-projects-header {
      display: flex;
      height: 45px;
      background: #f7f8fa 0% 0% no-repeat padding-box;
      align-items: center;
      z-index: 2;
      padding: 0 10px 0 5px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;

      > div {
        width: calc(100% - 35px);
        letter-spacing: 0px;
        color: #323338;
        opacity: 1;
        cursor: pointer;
        font-weight: 600;
        font-size: 16px;
        font-family: inherit !important;
      }
    }

    .payment-projects-body {
      width: 100%;
      padding: 10px 20px 20px 20px;
      font-family: inherit;
      background-color: white;
      border-radius: 0 0 8px 8px;
      height: 200px;
      overflow-y: auto;
      overflow-x: hidden;

      .toggle-projects-selection-check,
      .toggle-projects-selection-uncheck {
        cursor: pointer;
        transition: font-weight 0.3s ease, transform 0.3s ease, color 0.3s ease;
      }

      .toggle-projects-selection-check:hover {
        color: #1264a3;
        font-weight: 600;
        transform: scale(1.01);
      }

      .toggle-projects-selection-uncheck:hover {
        color: #fe4c4a;
        font-weight: 600;
        transform: scale(1.01);
      }

      .payment-project-item {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        gap: 10px;
        width: 100%;
        height: 36px;
        line-height: 36px;
        padding-left: 12px;
        background: #f5f7f9;
        font-size: 1.125rem;
        user-select: none;
        cursor: not-allowed;
        margin-top: 5px;

        @include tablet {
          font-size: 16px;
        }

        .ant-checkbox-wrapper {
          height: 36px !important;
          display: flex;
          padding-top: 3.5px;

          .ant-checkbox {
            border: 5px solid #fe4c4a;
            border-radius: 2px;

            .ant-checkbox-inner {
              border-radius: 0;
              border: 2px solid #ff7274;
              background-color: #eb4345;
              height: 14px;
              width: 14px;
            }
          }

          //checked
          .ant-checkbox-checked {
            border: 5px solid #68c142;

            .ant-checkbox-inner {
              border-radius: 0;
              border: 2px solid #71cf48;
              background-color: #68c142;
              height: 14px;
              width: 14px;
              content: url("../../../../../../../../pages/Settings/settingsComponents/Roles/src/TickIcon.svg");
            }
          }

          //indeterminate
          .ant-checkbox-indeterminate {
            border: 5px solid #1264a3;
            border-radius: 2px;

            .ant-checkbox-inner {
              border-radius: 0;
              border: 2px solid #1c7ac2;
              background-color: #0f5c97;
              height: 14px;
              width: 14px;
              padding: 2px;
              content: "";
              &::after {
                background-color: white;
              }
            }
          }
        }
      }

      .payment-project-disabled {
        @extend .payment-project-item;
        color: gray;
      }
    }
  }
  @keyframes slide-in {
    to {
      right: 0;
    }
  }
}
