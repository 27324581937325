$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #20212d;
$inputModalDark: #12131b;
$whiteText: #f9f9f9;

.redWarningsModal {
  //using !important for the width to override antd inline styles
  width: fit-content !important;
  max-width: 90vw;
  @media screen and (min-width: 1600px) {
    width: 665px !important;
  }
  ::-webkit-scrollbar-thumb {
    background: lighten(#1f2a44, 10%);
    border-radius: 5px;
    border: none;
    &:hover {
      background-color: #35415e;
    }
  }
  .ant-modal-close-x {
    svg {
      fill: #fff !important;
    }
  }
  .ant-modal-content {
    .ant-modal-header {
      height: 45px;
      display: flex;
      align-items: center;
      background-color: #fe4c4a;
      overflow: hidden;
      border: none;

      .ant-modal-title {
        font-size: 16px;
        width: 100%;
        font-weight: 600;
        color: #fff;
        padding: 0 !important;
        height: 45px;
        display: flex;
        align-items: center;
        .warningModalTitleContainer {
          display: flex;
          width: 100%;
          justify-content: space-between;
          .warningModalTitle {
            display: flex;
            gap: 10px;
            align-items: center;
            svg {
              fill: #fff !important;
              height: 17px !important;
              width: 17px !important;
            }
          }
        }
      }
    }
    .ant-modal-body {
      text-align: center;
      font-size: 14px;
      padding-bottom: 0 !important;
      max-height: calc(100dvh - 200px);
      overflow-y: auto;
      p {
        margin: 0;
      }
    }
    .ant-modal-footer {
      padding: 20px !important;
      .redWarningModalFooter {
        display: flex;
        justify-content: center;
        gap: 20px;
        .mondayButtonContainer {
          button {
            @media screen and (min-width: 1600px) {
              width: 150px;
            }
          }
        }
      }
    }
  }
}

.redWarningsModal.redWarningsModalDark {
  .ant-modal-content {
    background-color: $cardModalBodyDark !important;
    color: $whiteText;
  }
}

.redWarningsModal.confirmModal {
  .ant-modal-content {
    .ant-modal-header {
      background-color: #f8f8fa;
      .warningModalTitleContainer {
        color: #323338 !important;
        svg {
          fill: #acacac !important;
        }
        .warningModalTitle {
          svg {
            fill: #323338 !important;
            path {
              fill: #323338 !important;
            }
          }
        }
      }
    }
  }
}
.redWarningsModal.redWarningsModalDark.confirmModal {
  .ant-modal-content {
    .ant-modal-header {
      background-color: $cardModalHeaderDark !important;
      .warningModalTitleContainer {
        color: #fff !important;
        svg {
          fill: #acacac !important;
        }
        .warningModalTitle {
          svg {
            fill: #fff !important;
            path {
              fill: #fff !important;
            }
          }
        }
      }
    }
  }
}
