$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;
.progressAddonsModal {
  .ant-modal-header {
    display: flex;
    align-items: center;
    height: 45px;
    background-color: #f5f5f5;
  }

  .ant-modal-body {
    padding: 0 !important;
  }

  .ant-modal-footer {
    display: flex;
    justify-content: space-between;
  }

  .progressAddonsModalContent {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 10px 10px 0px 10px !important;
    .daysContainerShortcut {
      display: flex;
      align-items: center;
      gap: 10px;
      width: fit-content;
      min-width: 400px;
      max-width: 960px;
      flex-wrap: wrap;
    }
    .shortcut-pliDays-buttons {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
      background-color: #f8f8fa;
      border: none;
      border-radius: 5px;
    }
    .allDaysShortcutPLIButton {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 124px;
      height: 32px;
      border-radius: 5px;
      border: none;
      background-color: #f8f8fa;
      color: #333238;
    }
    .inputAddonsProgressContent {
      display: flex;
      gap: 5px;
      overflow-y: auto;
      height: fit-content;
      max-height: 600px;
      width: fit-content;
      min-width: 400px;
      max-width: 960px;
      flex-wrap: wrap;
      .inputAddonsProgress {
        width: 220px !important;
        animation: slidein 0.3s ease;
      }
    }
    .noDaysAddonsProgress {
      display: flex;
      gap: 5px;
      align-items: center;
    }
  }
}

@keyframes slidein {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.progressAddonsModaldarkMode {
  .ant-modal-header {
    display: flex;
    align-items: center;
    height: 45px;
    background-color: $cardModalHeaderDark;
    color: #f5f5f5 !important;
  }
  .ant-modal-title {
    color: #f5f5f5;
  }

  .ant-modal-body {
    padding: 0 !important;
    background-color: $cardModalBodyDark;
  }
  .ant-modal-content {
    border-radius: 10px;
  }

  .ant-modal-footer {
    display: flex;
    justify-content: space-between;
    background-color: $cardModalBodyDark;
  }

  .progressAddonsModalContent {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 10px 10px 0px 10px !important;
    background-color: $cardModalBodyDark;
    color: #f5f5f5;
    .daysContainerShortcut {
      display: flex;
      align-items: center;
      gap: 10px;
      width: fit-content;
      min-width: 400px;
      max-width: 960px;
      flex-wrap: wrap;
    }
    .shortcut-pliDays-buttons {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
      background-color: $cardModalBodyDark;
      color: #f5f5f5;
      border: none;
      border-radius: 5px;
    }
    .allDaysShortcutPLIButton {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 124px;
      height: 32px;
      border-radius: 5px;
      border: none;
      background-color: $cardModalBodyDark;
      color: #f5f5f5;
    }
    .inputAddonsProgressContent {
      display: flex;
      gap: 5px;
      overflow-y: auto;
      height: fit-content;
      max-height: 600px;
      width: fit-content;
      min-width: 400px;
      max-width: 960px;
      flex-wrap: wrap;
      .inputAddonsProgress {
        width: 220px !important;
        animation: slidein 0.3s ease;
      }
    }
    .noDaysAddonsProgress {
      display: flex;
      gap: 5px;
      align-items: center;
    }
  }
}
