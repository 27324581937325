.dark-ag-grid-mathful {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: 20px;
  align-items: center;
  align-items: center;
  color: white;
  font-size: 16px;
  margin-top: -34px;
  // margin-left: 500px;
  margin-left: -30px;
  flex-direction: row;
  gap: 20px;
  z-index: 10;
}

.light-ag-grid-mathful {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: 40px;
  align-items: center;
  align-items: center;
  color: black;
  font-size: 16px;
  margin-top: -5px;
  // margin-left: 500px;
  margin-left: -30px;
  flex-direction: row;
  gap: 20px;
  z-index: 10;
}

.hide-btn {
  color: gray;
  &:hover {
    color: lightblue;
    cursor: pointer;
  }
}
