$inputDarkColor: #2a2b3a;

.pageInputContainer {
  .labeledInputContainer {
    width: 65px !important;
    min-width: 65px !important;
    max-width: 65px !important;
    .ant-form-item {
      margin: 0px;
      .ant-input {
        width: 70px;
        border: none;
        background-color: #f4f5f6;
      }
    }
  }
}

.pageInputContainerDark {
  .labeledInputContainer .ant-form-item .ant-input {
    background-color: $inputDarkColor !important;
    color: #fff;
    &::placeholder {
      color: #acacca;
    }
  }
}
