.day-stats-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: max-content;
  gap: 5px;
  flex-direction: column;

  .single-day-stat {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    flex-direction: row;
    width: 100%;
  }
}
