@import "../../../../../index.scss";

.googlePickerButtonContainer {
  // @include flex(flex-start, flex-start);
  display: flex;
  justify-content: center;
  .uploadButton {
    font-family: Helvetica;
    @include text(14, 400, 00.21px, #ffffff);
    background-color: #1264a3;
    border-radius: 5px;
    border: none;
  }

  .uploadedFileNamesContainer {
    @include flex(flex-start, flex-start, column);
    @include text(11px, 400, 0, #d69615);
  }
  .fileNameContainer {
    @include flex(center, flex-start);
  }
  .deleteIcon {
    margin-right: 5px;
    color: grey;
  }
  .fileName {
    cursor: pointer;
    display: block;
    max-width: 110px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.GPicker-modal {
  .ant-modal-content {
    .ant-modal-header {
      margin-bottom: 0px;
      border: none;
      padding: 16px 24px;
      background-color: #f5f5f7;
    }

    .ant-modal-body {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .gPicker-container {
        background-color: #f7f8fa;
        border-radius: 5px;
        padding: 10px;
        .first-row {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 6px;

          .title {
            font-size: 16px;
            font-weight: 700;
            opacity: 1;
            text-transform: none;
            letter-spacing: 0;
            color: #1264a3;
            margin: 0 10px;
            cursor: pointer;
            width: auto;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &:hover {
              color: #1f2a44;
            }
          }
        }

        .date {
          color: rgba(0, 0, 0, 0.45);
        }
      }

      .noFiles {
        padding-bottom: 50px;
        font-size: 16px;
        font-weight: 500;
        color: #555;
      }
    }
  }
}
