.table-footer-container {
  display: flex;
  justify-content: flex-end;
  gap: 400px;
  background-color: #f8f8fa;
  box-shadow: 0px 0px 11px -8px;
  border-radius: 0px 0px 10px 10px;
  height: fit-content;
  width: 100%;
  padding: 10px;
  margin-top: -5px;
  padding-right: 200px;
  color: #323338;
  font-weight: 600;

  .table-footer-due {
    color: #fe4c4a;
  }
}
