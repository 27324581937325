.chart-cards-sidebar-actions {
  height: 100%;
  position: sticky;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 10px 0 0;

  > * {
    display: flex;
    flex-direction: column;
    gap: 10px;
    button {
      width: 32px !important;
      height: 32px !important;
      padding: 5px !important;
    }
  }
}
