@import "../../../../../../../index.scss";

$headerDarkBackground: #12131b;
$bodyDarkBackground: #1f212d;
$inputDarkColor: #12131b;
$whiteText: #f9f9f9;

.userSessionModal {
  width: fit-content !important;
  .ant-modal-header {
    border: none;
    background-color: #f8f8fa;
    height: 45px;
    display: flex;
    align-items: center;
    .ant-modal-title {
      font-size: 16px;
      width: 100%;
      font-weight: 600;
      .userSessionModalHeader {
        display: flex;
        align-items: center;
        gap: 10px;
        .dot {
          width: 8px;
          height: 8px;
          border-radius: 50%;
        }
        .dot.on {
          background-color: #00a464;
        }
        .dot.off {
          background-color: #787677;
        }
      }
    }
  }
  .userSessionModalContent {
    height: auto;
    p {
      font-size: 16px;
      margin: 0;
    }
    .controlPanelWrapper-sessions {
      @include flex(center, center, column);
      gap: 30px;
      .controlPanelInfo {
        display: flex;
        gap: 10px;
        align-items: center;
        align-self: flex-start;
        max-width: 1140px;
      }

      .controlPanel {
        @include flex(center, center);
        width: 100%;
        gap: 20px;
        margin-bottom: 20px;

        .mondayButtonContainer {
          button {
            @media screen and (min-width: 1600px) {
              min-width: 150px;
            }
            @media screen and (max-width: 600px) {
              .mondayButtonText,
              .mondayButtonDivider {
                display: none !important;
              }
            }
          }
        }
      }
    }

    .userSessionsPanel {
      width: 100%;

      .header {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-end;
      }
    }

    .description {
      width: 100%;
      @include flex(center, center, column);
    }

    .sessionsGrid {
      height: 100%;
    }

    .ag-overlay-no-rows-wrapper {
      padding-top: 5em;
    }
  }
}

.userSessionModalDark {
  .ant-modal-content {
    background-color: $bodyDarkBackground;
    .ant-modal-close-x {
      svg {
        fill: $whiteText;
      }
    }
    .ant-modal-header {
      background-color: $headerDarkBackground;
      .ant-modal-title {
        color: $whiteText;
      }
    }
    .ant-modal-body {
      background-color: $bodyDarkBackground;
      .controlPanelWrapper-sessions {
        color: $whiteText;
      }
      .userSessionsPanel {
        color: $whiteText;
      }
      .sessionsGrid {
        background-color: $bodyDarkBackground;
        .ag-column-drop {
          background-color: $bodyDarkBackground;
        }
        .ag-header {
          box-shadow: none;
          background-color: $bodyDarkBackground;
          .ag-header-cell {
            padding-left: var(--ag-cell-horizontal-padding) !important;
            padding-right: var(--ag-cell-horizontal-padding) !important;
            .ag-header-cell-text {
              font-size: 16px;
              font-weight: 600;
            }
          }
        }
        .ag-side-buttons {
          background-color: $bodyDarkBackground;
        }
        .ag-paging-panel {
          border: none;
          background-color: $bodyDarkBackground;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .userSessionModalContent {
    min-width: 90vw;
  }
  .controlPanel {
    display: flex;
    flex-wrap: wrap;
  }
}

.force-logout-modal {
  .ant-modal-content {
    .ant-modal-body {
      text-align: left;
      .force-logout-modal-content {
        display: flex;
        flex-direction: column;
        gap: 40px;

        .logout-modal-section {
          display: flex;
          flex-direction: column;
          gap: 10px;

          .force-logout-text {
            font-size: 14px;
            font-weight: 600;
            cursor: pointer;
            svg {
              fill: #fe4c4a !important;
              height: 17px;
              width: 17px;
            }
          }
          .force-logout-text.force {
            color: #fe4c4a;
            display: flex;
            gap: 5px;
            align-items: center;
          }
          .force-logout-text.ask {
            color: #1264a3;
            margin-top: 20px;
          }
        }
        .ask-logout-form {
          display: flex;
          gap: 20px;
          align-items: center;
          .labeledInputContainer {
            .labeledInputLabel {
              font-size: 14px;
              font-weight: 600;
            }
            .ant-select {
              min-width: 150px;
            }
          }
        }
      }
    }
    .ant-modal-footer .redWarningModalFooter {
      justify-content: start;
    }
  }
}
