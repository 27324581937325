.ok-warning-buttons {
  display: flex;
  .mondayButtonContainer {
    .mondayButtonText {
      font-size: 14px;
    }
    .mondayButtonDivider {
      height: 100%;
    }
  }
  .close-tab {
    background-color: #fe4c4a;
    margin-left: 10px;
    .mondayButtonDivider {
      background: #eb4345 !important;
    }
  }
  .close-tab:hover {
    background-color: #fe4c4a;
    opacity: 0.8;
  }
}
