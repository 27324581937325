.replyMessages {
  height: 75px;
  display: flex;
  flex-direction: column;
  padding: 7px;
  background-color: #f1f5fd;
  border: none !important;
  position: relative;
  border-radius: 5px;
  .replyToSender {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
