.UploadCard {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  padding: 20px 0;

  .warningMessage {
    font-size: 14px;
    cursor: pointer;
    // color: #fe4c4a;
    color: #323338;
    display: flex;
    gap: 10px;
    font-weight: 400;
    align-items: center;
    margin-bottom: 10px;
  }
  .descriptionValue {
    top: 100px;
    text-align: left;
    font: normal normal normal 20px/27px Open Sans;
    letter-spacing: 0px;
    color: #323338;
    opacity: 1;
  }
}
