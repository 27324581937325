.google-user-ui-container {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  align-items: center;
  width: 100%;

  .sign-in-button {
    background-color: #0f5c97 !important;

    .mondayButtonDivider {
      background-color: #12548a !important;
    }
  }

  .user-container {
    display: flex;
    justify-content: space-between;
    align-items: center;


    .user-email {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100px;
      color: #1264a3;
      margin: 0 15px 0 8px;
    }



    .logout-button {
      margin-left: 10px;
      width: 32px;
      height: 32px;
      background: #1264a3 0% 0% no-repeat padding-box;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      svg {
        width: 30px;
        height: 20px;
        fill: #fff;
      }
    }
  }
}