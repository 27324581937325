$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.configTable-editModal {
  .ant-modal-content {
    width: 700px;
    display: flex;
    flex-direction: column;

    .ant-modal-header {
      height: 45px;
      background-color: #f5f5f7;
      border: none;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      display: flex;
      align-items: center;

      .ant-modal-title {
        font-weight: 600;
        font-size: 16px;
        color: #323338;
      }
    }

    .ant-modal-body {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      gap: 30px;

      .info {
        display: flex;
        flex-direction: row;
        gap: 10px;

        p {
          margin: 0;
          color: #323338;
          font-size: 14px;
          font-weight: 400;
        }
      }

      .body {
        display: flex;
        flex-direction: row;
        gap: 30px;

        .left {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          gap: 32px;

          .label-input {
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 2px;

            label {
              margin: 0;
              padding-left: 5px;
              font-weight: 600;
              font-size: 16px;
              line-height: 22px;
              color: #323338;
            }

            input {
              height: 32px;
              background: #f5f5f7;
              border-radius: 5px;
              border: none;

              &::placeholder {
                color: #acacca;
              }
            }

            .labeledInputContainer {
              .ant-form-item {
                margin-bottom: 0;
              }
            }

            .quill {
              margin-bottom: 20px;
              max-width: 400px;
              .ql-toolbar {
                background-color: #f5f5f7;
                border-radius: 5px;
                margin-bottom: 5px;
                border: none;
              }
              .ql-container {
                max-height: 120px;
                border-radius: 5px;
                border: none;
                background-color: #f5f5f7;
              }

              .ql-editor {
                width: 100%;
              }
            }
          }

          .name-status {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            gap: 30px;
          }
        }

        .right {
          .sketchPicker {
            .sketch-picker {
              box-shadow: none !important;
              border: none !important;

              .flexbox-fix {
                display: flex;
                flex-direction: row;
                gap: 4px;
                .rc-editable-input-11 {
                  width: 100% !important;
                  background-color: gray !important;
                }
              }
            }
          }
        }
      }

      .footer {
        margin-top: 16px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }
}

.configTable-editModal-mobile {
  .ant-modal-content {
    width: 100%;

    .ant-modal-body {
      .body {
        display: flex;
        flex-direction: column;

        .quill {
          .ql-container {
            overflow-y: auto;
          }
        }
      }
    }
  }
}

.configPageTable-warningModal {
  .ant-modal-content {
    @media screen and (min-width: 1600px) {
      width: 665px !important;
    }

    .ant-modal-header {
      padding: 0;
      background: #f8f8fa;

      .header {
        padding: 12px 20px;
      }
    }

    .ant-modal-body {
      .warning-content {
        text-align: center;
        p {
          span {
            font-weight: 600;
          }
        }
      }
    }

    .ant-modal-footer {
      .footer {
        display: flex;
        justify-content: center;
        gap: 20px;
        padding: 20px;

        .mondayButtonContainer {
          button {
            width: 100%;
          }
        }
      }
    }
  }
}

.configTable-editModal-dark {
  .ant-modal-content {
    background: $cardModalBodyDark;

    .ant-modal-close svg {
      fill: #fff !important;
    }

    .ant-modal-header {
      background: $cardModalHeaderDark;

      .ant-modal-title {
        color: #fff;
      }
    }

    .ant-modal-body {
      .info {
        p {
          color: #fff;
        }
      }

      .body {
        .left {
          .label-input {
            label {
              color: #fff;
            }

            .ant-input {
              background: $inputModalDark !important;
              color: #fff !important;
            }

            .ant-select-selector {
              border: none !important;
              background-color: $inputModalDark !important;
              padding: 0;
              .ant-select-selection-search-input {
                color: #f2f2f2 !important;
                background-color: $inputModalDark !important;
              }
              .ant-select-selection-item {
                color: #f2f2f2 !important;
                background-color: $inputModalDark !important;
              }

              .ant-select-selection-placeholder {
                color: #fff;
              }
            }

            .ant-select-arrow {
              border: none !important;
              svg path {
                fill: #f2f2f2 !important;
              }
            }

            .quill {
              background-color: $cardModalBodyDark;
              .ql-toolbar {
                background-color: $inputModalDark;
                .ql-formats {
                  color: #fff !important;
                  .ql-picker-label {
                    color: #fff;
                    &::before {
                      color: #fff;
                    }
                    .ql-stroke {
                      stroke: #fff;
                    }
                  }
                  .ql-picker-options {
                    background-color: $cardModalBodyDark;
                    .ql-picker-item {
                      color: #fff;
                    }
                  }
                  button .ql-stroke {
                    stroke: #fff;
                  }
                }
              }
              .ql-container {
                background-color: $inputModalDark;
                border: none;
                .ql-editor {
                  background-color: $inputModalDark;
                  &::before {
                    color: #acacca;
                  }
                  p {
                    color: #fff !important;
                  }
                }
              }
            }
          }
        }

        .right {
          .sketchPicker {
            .sketch-picker {
              background: $cardModalBodyDark !important;

              .flexbox-fix {
                label {
                  color: #fff !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

.configPageTable-warningModal-dark {
  .ant-modal-content {
    background: $cardModalBodyDark;

    .ant-modal-header {
      background: $cardModalHeaderDark;
    }
  }
}
