@use "~ag-grid-enterprise/styles" as ag;
@include ag.grid-styles(
  (
    theme: alpine,
  )
);
@import "../../../../../index.scss";

$schedule-error: red;

$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.ant-collapse-item.services-panel-header {
  border: none;
  padding-bottom: 10px;
}
.ant-collapse-item.ant-collapse-item-active.services-panel-header {
  border: none;
  padding-bottom: 0px;
}
$schedule-warning: orange;

$weekend-background: rgba(53, 139, 100, 0.23);

.cell-span {
  // background-color: gray;
}

.forschedule-group {
  background-color: rgb(237 237 237) !important;
  color: #333238;
  font-weight: 600;
  border: none !important;
}

.remaining-group {
  background-color: rgb(237 237 237) !important;
  color: #333238;
  font-weight: 600;
  border: none !important;
}

.addons-group {
  background-color: #c7e0ff !important;
  border: none !important;
}

.progress-group {
  background-color: rgb(237 237 237) !important;
  border: none !important;
  color: #333238;
  font-weight: 600;
}

.from-estimation-group {
  background-color: rgb(237 237 237) !important;
  border: none !important;
  color: #333238;
  font-weight: 600;
}

.addonsModal {
  width: 725px !important;
}

.servicesModal {
  width: calc(100vw - 35px) !important;
}

.servicesModal .dataEntryGrid {
  height: 60vh !important;
}

// .ag-dnd-ghost {
//   z-index: 9999 !important;
// }

.dx-popup-content {
  overflow-y: auto;
}

.even-row-color {
  background-color: #f8f8fa !important;
}

.edit-progress-enabled {
  color: #1890ff;
  font-weight: bold;
  text-shadow: 0 0 10px #1890ff;
}

.edit-progress-disabled {
  color: #868686;
}

.progress-percentage,
.remaining-percentage {
  font-style: italic;
}

.NOT_DONE {
  background-color: #f04f4e !important;
  // text-shadow: 0 0 7px #d9d9d9;
  // margin: 10px 0;
}
.PARTIALLY_DONE {
  background-color: #fcc94a !important;
  // text-shadow: 0 0 7px #d9d9d9;
}
.TOTAL_DONE {
  background-color: #7ac14d !important;
  font-weight: bold;
}
.OVER_DONE {
  background-color: #f04f4e !important;
  // text-decoration: underline wavy;
}

.dimension-to-be-scheduled-0 {
  background: #f6a9aa;
}

.days-in-pli-view-only {
  span {
    padding: 5px;
  }
  .this-day {
  }

  .another-day {
    text-shadow: 0 0 1px #1890ff, 0 0 3px #fff, 0 0 7px #1890ff;
    text-decoration: underline #1890ff;
  }
}

.negative-remaining-value {
  color: #ff0022;
}

.pli-per-service {
  // width: 100% !important;
  // margin-left: 135px;
  // margin-bottom: 25px;
  // .services-container {
  //   display: flex;
  //   .addonsContainer {
  //     .ant-collapse-header {
  //       padding: 10px 30px;
  //       display: flex !important;
  //       align-items: center !important;
  //       .ant-collapse-arrow {
  //         margin-left: 0 !important;
  //       }
  //       span:last-child {
  //         margin-left: 20px;
  //       }
  //     }
  //   }
  // }
}

.invalid-remaining-value {
  color: #e3961c;
}

.scheduleGrid {
  /* UI Properties */
  .ag-header-group-cell.ag-focus-managed.forschedule-group.ag-header-group-cell-with-group {
    background: #ecde7c !important;
    color: #ffffff;
  }
  .ag-root-wrapper-body.ag-layout-normal {
    height: fit-content !important;
    min-height: fit-content !important;
    overflow-y: auto !important;
  }
  .ag-header-group-cell.ag-focus-managed.progress-group.ag-header-group-cell-with-group {
    background: #71cf48 !important;
    color: #ffffff;
  }
  // .ag-root ::-webkit-scrollbar {
  // width: 5px;
  // height: 5px;
  // }

  .ag-header-cell.ag-header-cell-sortable.ag-focus-managed {
    border: none !important;
  }

  .ag-column-drop.ag-column-drop-horizontal {
    height: 32px !important;
    background: #f5f5f7 !important;
    border: none;
  }

  .ag-header-group-cell {
    border: none !important;
  }
  .ag-theme-alpine {
    --ag-font-size: 24px;
    --ag-range-selection-border-style: dashed;
  }

  .ag-header-group-cell-with-group {
    border: none !important;
  }

  .ag-theme-balham .ag-cell-data-changed {
    background-color: #29ab87 !important;
  }

  .ag-header-group-cell.ag-focus-managed.remaining-group.ag-header-group-cell-with-group {
    background-color: #fe4c4a !important;
    color: #ffffff;
  }

  .ag-header-group-cell.ag-focus-managed.from-estimation-group.ag-header-group-cell-with-group {
    background-color: #6bbed1 !important;
    color: #ffffff;
  }

  .ag-header-cell {
    background-color: #f5f5f7 !important;
    color: #ffffff;
  }
  // .disabled-row {
  //   background: #b4e2a1;
  // }

  .ag-body-horizontal-scroll {
    min-height: 5px !important;
  }

  .id-column {
    font-size: 16px;
    background: #e0e0e0;
  }

  .cell-span-extended {
    background-color: #b9b9b9 !important;
  }

  .id-with-subPli {
    color: #1890ff;
    // font-style: italic;
    font-weight: bold;
  }

  .id-without-subPli {
  }

  .ag-theme-balham .ag-header-cell {
    background-color: transparent;
  }

  input.className {
    min-height: unset !important;
  }
  .ant-collapse-item.ant-collapse-item-active.services-panel-header {
    border: none;
  }

  .days-in-schedule-cell-select {
    border: none !important;
    .ant-select-selector {
      border: none !important;
      background: #f5f5f7 !important;
    }
  }

  .ant-select-disabled {
    .ant-select-selector {
      background: #dbdbdb !important;
      .ant-select-selection-placeholder {
        color: #b3b6b6 !important;
      }
      path {
        fill: #b3b6b6 !important;
      }
    }
  }

  .ag-theme-balham .ag-ltr .ag-column-drop-horizontal {
    background-color: #c7e0ff;
    height: 32px !important;
  }

  .ag-paging-panel {
    height: 32px !important;
    min-height: 32px !important;
    border: none !important;
  }

  .ag-theme-balham .ag-ltr .ag-cell {
    border-right: 0.7px solid #999999 !important;
  }

  .ag-theme-balham .ag-cell-data-changed {
    background-color: #29ab87 !important;
  }

  .ag-body-horizontal-scroll-viewport {
    max-height: 5px !important;
    width: 5px !important;
    min-height: 5px !important;
    &::-webkit-scrollbar {
      width: 5px !important;
      height: 5px !important;
    }
  }
  .ag-body-viewport.ag-layout-normal.ag-row-animation {
    min-height: 0px !important;
  }

  .ag-theme-balham:not(.ordersGrid) .ag-header-cell {
    background-color: #c7e0ff;
    border-right: 0.7px solid #999999;
  }

  .ag-root-wrapper {
    border: none !important;
  }

  .ag-root-wrapper-body {
    overflow: auto !important;
    &::-webkit-scrollbar {
      width: 5px !important;
      height: 5px !important;
    }
  }

  .ag-cell-label-container {
    .ag-header-cell-label span.ag-header-cell-text {
      font-family: "Open Sans", sans-serif;
      font-size: 12px;
      font-weight: 600;
      color: #333238;
    }
  }

  .ag-react-container {
    width: 100%;

    .schedule-history-button {
      padding: 2px;
      border-radius: 5px;
      background: #d5e4ff;
    }
  }

  .ant-select-search__field {
    min-height: 16px !important;
  }

  .ag-cell-value {
    justify-content: flex-end;
  }

  .selected-completed {
    background: #20d520;
  }

  .jump-buttons-container {
    display: flex;
    flex-wrap: wrap;
    height: 200px;
    align-items: center;
    overflow: auto;

    .jump-button-container {
      width: 270px;
      display: flex;
      justify-content: space-between;
      margin: 10px;
      padding: 5px;
      border-radius: 5px;
      box-shadow: 0 0 6px 1px #9f9f9f;
    }
  }
}

.cell-wrap-text {
  white-space: normal !important;
  line-height: normal !important;
}
.ag-text-field-input::selection,
.ag-text-area-input::selection {
  background: #ffffff;
}
// .ag-cell {
//   border-right: solid 1px #4f4f4f !important;
// }
.ag-ltr .ag-cell {
  p {
    line-height: normal !important;
    margin: 2px !important;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif !important;
    font-weight: 400 !important;
    font-size: 12px !important;
  }
  h1 {
    line-height: normal !important;
    margin: 0 !important;
    color: unset !important;
  }
  h2 {
    line-height: normal !important;
    margin: 0 !important;
    color: unset !important;
  }
  strong {
    font-weight: bold !important;
  }
}
.ag-cell-inline-editing {
  height: 100% !important;
}
.tableD {
  width: 101%;
}

.serviceMenu {
  width: 100%;
  height: auto;
  .react-grid-item {
    //transition-duration: 0s !important;
    max-width: 200px !important;
    // text-overflow: ellipsis;
    // overflow: hidden;
    // white-space: nowrap;
    .configElementTitle {
      // font-size: 1.05rem;
    }
    &.react-grid-placeholder {
      background-color: grey !important;
      opacity: 0.4 !important;
    }
  }
}

.dragable-services-modal {
  .ant-modal {
    .ant-modal-body {
      .ant-alert-info {
        background-color: #fff !important;
        padding: 0;
        margin-bottom: 20px;
        font-size: 0.95rem !important;
      }
      .serviceMenu {
        display: flex;
        justify-content: center;
        padding-right: 80px;
        position: relative;
        .service-content {
          .drag-icon {
            position: absolute;
            top: 25%;
            right: -80px;
            &:hover {
              cursor: grabbing;
            }
            &:focus {
              cursor: grabbing;
            }
          }
          .dragable-services-label {
            font-size: 1rem !important;
          }
        }
      }
    }
  }
}

.expandedServicesContainer {
  //padding-right: 15px;
  //height: calc(100vh - 131px);
  //overflow-y: auto;
  //padding-bottom: 250px;
  // margin: 10px;
}

.serviceContainerSch {
  transition: 300ms;
  background-color: #ffffff;
  //border-top: solid 1px #6f6f6f;
  // padding-top: 10px;
  margin-bottom: 20px;
  animation: serviceContainerSch 0.5s;
  // padding-top: 20px;
  //margin-bottom: 50px;
  //   border-bottom: 0.5px solid gray;
  //   border-top: 0.5px solid grey;

  // padding: 5px;
  // box-shadow: 0px 0px 6px 3px #967676;
  //margin-left: 20px;
  border-radius: 10px;
  // animation: servicesData 0.5s;
}

.serviceMenuContainer {
  //margin-right: 15px;

  .appendServiceButton {
    width: 100%;
    height: 34px;
    background-color: transparent;
    border-radius: 0;
    border: 1px solid #4f4f4f;
    margin-bottom: 15px;

    img {
      margin-right: 5px;
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
      border-color: #d7d7d7;
    }
  }

  .hideShowAllServicesButton {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 50%;
    height: 34px;
    background-color: transparent;
    border-radius: 0;
    border: 1px solid #4f4f4f;
    margin-bottom: 3px;

    img {
      margin-right: 5px;
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
      border-color: #d7d7d7;
    }
  }

  .checkboxContainerSCH {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    //transition: 350ms;
    width: 100%;
    display: inline-block;
    border-radius: 0;
    height: 38px;
    cursor: grab;
    background-color: rgba(255, 255, 255, 0);
    border: 1px solid rgba(136, 146, 158, 1);
    margin-bottom: 3px;

    .serviceLabelSch {
      color: black;
      margin-left: 5px;
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
      color: black !important;
      border-color: #d7d7d7;
    }

    &:active {
      cursor: grabbing;
    }
  }
}

.est-progress-status-schedule {
  // width: 319px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
}

.serviceSchedulingSiderMenu {
  display: flex;
  justify-content: center;
  align-items: center;
  // width: 100%;
  // padding: 10px 0;
  border-radius: 10px;
  background-color: #fff;
  overflow-x: auto;

  .estimation-label {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    //    margin: 8px auto !important;
  }
  .dragable-services-wrapper {
    width: 100%;
    // height: 100%;
    display: flex;
    // justify-content: center;
    // align-items: center;
    // flex-wrap: wrap;
    // animation: checkboxServices 0.4s;
    .dragable-services-container,
    .configElementContainer {
      background-color: #eceff4 !important;
      min-width: 150px;
      // overflow-x: auto !important;
      margin-bottom: 0px !important;
      // opacity: 0.7 !important;
      // min-width: 245px;
      border: none !important;
      border-radius: 5px;
    }
    .configElementTitle,
    .dragable-services-label {
      font-size: 1rem !important;
      // font-weight: 600 !important;
      color: #333238 !important;
      margin-bottom: 0 !important;
    }
    .non-drag-service {
      width: flex;
      align-items: flex-start;
      flex-wrap: wrap;
    }
  }
  .non-drag-service {
    // margin: 3px 10px;
    margin: 0 5px;
  }
  .appendServiceButton {
    width: 100%;
    height: 34px;
    background-color: transparent;
    border-radius: 0;
    border: 1px solid #4f4f4f;
    margin-bottom: 15px;

    img {
      margin-right: 5px;
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
      border-color: #d7d7d7;
    }
  }

  .hideShowAllServicesButton {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 50%;
    height: 34px;
    background-color: transparent;
    border-radius: 0;
    border: 1px solid #4f4f4f;
    margin-bottom: 3px;

    img {
      margin-right: 5px;
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
      border-color: #d7d7d7;
    }
  }

  .checkboxContainerSCH {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    //transition: 350ms;
    width: 100%;
    display: inline-block;
    border-radius: 0;
    height: 38px;
    cursor: grab;
    background-color: rgba(255, 255, 255, 0);
    border: 1px solid rgba(136, 146, 158, 1);
    margin-bottom: 3px;

    .serviceLabelSch {
      color: black;
      margin-left: 5px;
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
      color: black !important;
      border-color: #d7d7d7;
      cursor: grabbing;
    }

    &:active {
      cursor: grabbing !important;
    }
  }
}

.gridResizer {
  transition: 300ms;
  opacity: 1;
  cursor: ns-resize;
  width: 100%;
  height: 6px;
  display: flex;

  .gridResizerInnerLeft {
    transition: 300ms;
    background-color: #007ac3;
    width: calc(50% - 25px);
    height: 2px;
    border-bottom-left-radius: 2px;
  }

  .gridResizerInnerRight {
    transition: 300ms;
    background-color: #007ac3;
    width: calc(50% - 25px);
    height: 2px;
    border-bottom-right-radius: 2px;
  }

  .gridResizerHandler {
    transition: 300ms;
    width: 50px;
    height: 7px;
    background-color: #007ac3;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    .gridResizerHandlerGrip {
      width: 20px;
      height: 1px;
      margin: 1px auto 0 auto;
      background-color: #78a1ba;
    }
  }

  &:hover {
    .gridResizerInnerLeft {
      height: 7px;
      background-color: #12a3fa !important;
    }

    .gridResizerInnerRight {
      height: 7px;
      background-color: #12a3fa !important;
    }

    .gridResizerHandler {
      background-color: #12a3fa !important;
      border-radius: 0;
    }
  }

  &:active {
    opacity: 1;

    .gridResizerInnerLeft {
      height: 7px;
      background-color: #09659b !important;
    }

    .gridResizerInnerRight {
      height: 7px;
      background-color: #09659b !important;
    }

    .gridResizerHandler {
      background-color: #09659b !important;
      border-radius: 0;
    }
  }
}

.crew-fleet-popover {
  margin: 0;
  padding: 0;
  width: fit-content !important;
  display: flex;
  flex-direction: column;
  .ant-modal-header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 45px;
    background: #f8f8fa 0% 0% no-repeat padding-box;
    opacity: 1;
    border-radius: 10px 10px 0px 0px;
    border-bottom: 0;
  }
  .ant-modal-title {
    width: 100%;
    height: 22px;
    font: normal normal 600 16px/22px Open Sans;
    letter-spacing: 0px;
    color: #323338;
    opacity: 1;
  }
  .ant-modal-close-x {
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-modal-body {
    width: 100%;
    background: #ffffff 0% 0% no-repeat padding-box;
    opacity: 1;
    padding: 20px 0 20px 0;
    border-radius: 0px 0px 10px 10px;
  }
  .ant-modal-content {
    border-radius: 10px 10px 10px 10px;
    width: 100%;
  }
  .trucks-modal-body {
    display: flex;
    flex-direction: column;
    gap: 1em;
    .select-driver-modal-header {
      flex-direction: row !important;
      justify-content: space-between !important;
      gap: 2em !important;
    }
    .crews-modal-header {
      flex-direction: row !important;
      justify-content: space-between !important;
      gap: 2em !important;
    }
    .trucks-modal-body-header {
      display: flex;
      flex-direction: column;
      gap: 1em;
      padding-left: 20px;
      padding-right: 20px;
      .trucks-modal-body-header-info {
        display: flex;
        align-items: center;
        gap: 10px;
        span {
          text-align: left;
          font: normal normal normal 14px/19px Open Sans;
          letter-spacing: 0px;
          color: #323338;
          opacity: 1;
          padding: 0;
          margin: 0;
        }
        Button {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 32px;
          width: 32px;
          border: none;
          border-radius: 5px;
          background-color: #1264a3;
          color: #fff;
        }
      }
      .trucks-modal-body-header-functionality {
        display: flex;
        align-items: center;
        gap: 1rem;
        flex-wrap: wrap;
        .select-driver-search-div {
          .select-driver-search-comp {
            .ant-select-selector {
              width: 492px !important;
            }
          }
          .select-driver-search-logo {
            right: 482px !important;
            opacity: 0.5 !important;
          }
        }

        .crews-modal-search-div {
          .crews-modal-search-comp {
            .ant-select-selector {
              width: 292px !important;
            }
          }
          .crews-modal-search-logo {
            right: 282px !important;
            opacity: 0.5 !important;
          }
        }

        .trucks-modal-search-div {
          display: flex;
          align-items: center;
          margin: 0 !important;
          padding: 0 !important;
          .trucks-modal-search-comp {
            .ant-select-selector {
              padding-left: 30px;
              height: 32px;
              width: 242px;
              background: #fcfcfc 0% 0% no-repeat padding-box;
              border: 1px solid #eaeaea;
              border-radius: 5px;
              .ant-select-selection-search-input {
                padding-left: 20px;
              }
              @media screen and (max-width: 820px) {
                width: 220px !important;
              }
            }
            .ant-select {
              box-sizing: border-box;
              margin: 0;
              padding: 0;
              width: 100%;
              color: rgba(0, 0, 0, 0.85);
              font-size: 14px;
              font-variant: tabular-nums;
              line-height: 1.5715;
              list-style: none;
              font-feature-settings: "tnum", "tnum";
              position: relative;
              display: inline-block;
              cursor: pointer;
              z-index: 2;
            }
          }
          .trucks-modal-search-logo {
            font-size: 120%;
            position: relative;
            right: 232px;
            opacity: 0.5;
            @media screen and (max-width: 820px) {
              right: 210px !important;
            }
          }
        }
        .trucks-modal-button {
          width: 150px;
        }
        .trucks-modal-button:disabled {
          opacity: 0.5;
        }
      }
    }
    .trucks-modal-body-content {
      height: 432px;
      overflow: auto;
      overflow-x: auto;
      scroll-behavior: smooth;
      width: 100%;
      .trucks-modal-body-content-table {
        width: 100%;
        thead {
          background-color: #ffffff;
          position: sticky;
          top: 0;
          z-index: 300;
        }

        tr {
          height: 36px;
          background: #fff 0% 0% no-repeat padding-box;
          opacity: 1;
          th {
            text-align: center;
            font: normal normal 600 16px/39px Open Sans;
            letter-spacing: 0px;
            color: #323338;
            white-space: normal;
            padding: 0px 5px;
            @media screen and (max-width: 820px) {
              line-height: 20px;
            }
          }
          td {
            text-align: center;
            font: normal normal normal 16px/22px Open Sans;
            letter-spacing: 0px;
            color: #323338;
            padding: 0px 5px;
            .ant-tooltip-inner {
              width: 400px !important;
            }
          }
        }
        tr:nth-child(even) {
          background-color: #f8f8fa;
        }

        .truck-modal-mondayButton {
          width: 150px;
          padding: 0 !important;
          margin: 0 !important;
          .mondayButtonStyle {
            width: 170px !important;
          }
          .mondayButtonStyle:disabled {
            opacity: 0.5;
          }
          .mondayButtonText {
            font: normal normal normal 14px/19px Open Sans !important;
            letter-spacing: 0 !important;
            text-overflow: ellipsis !important;
            overflow: hidden !important;
            white-space: nowrap !important;
          }
        }
        label.ant-checkbox-wrapper.trucks-modal-checkbox {
          .ant-checkbox-inner {
            width: 25px;
            height: 25px;
            background: #e6e6ee 0% 0% no-repeat padding-box;
            border-radius: 2px;
            border: none !important;
          }
          span.ant-checkbox.ant-checkbox-checked {
            .ant-checkbox-inner {
              background: #1264a3 0% 0% no-repeat padding-box;
            }

            .ant-checkbox-inner:after {
              width: 8px;
              height: 15px;
            }
          }
        }
      }
    }
    .truck-modal-body-footer.crews-modal-footer {
      height: 40px;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: nowrap;
      gap: 5px;
      padding-left: 10px;
      margin-bottom: 10px;
    }
  }
}

.crew-fleet-popover-dark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      background-color: $cardModalBodyDark;
      .header-info {
        color: #fff;
      }
      .header-search {
        .ant-select {
          .ant-select-selector {
            background-color: $inputModalDark !important;
            .ant-select-selection-search-input {
              color: #fff;
            }
            .ant-select-selection-item {
              color: #fff;
            }
            .ant-select-selection-placeholder {
              color: #acacca;
            }
          }
          .ant-select-clear {
            background-color: $inputModalDark;
            svg {
              fill: #acacca;
            }
          }
          .ant-select-arrow svg {
            fill: #fff;
          }
        }
      }
      .trucks-modal-body {
        .trucks-modal-body-header-info {
          span {
            color: #fff;
          }
          .ant-input {
            background-color: $inputModalDark !important;
            color: #fff;
            &::placeholder {
              color: #acacca;
            }
          }
        }
        .trucks-modal-body-header-functionality {
          .ant-input {
            background-color: $inputModalDark !important;
            color: #fff !important;
            &::placeholder {
              color: #acacca !important;
            }
          }
          .ant-select {
            background-color: $cardModalBodyDark !important;
            .ant-select-selector {
              background-color: $inputModalDark !important;
              border: none;
              .ant-select-selection-search-input {
                color: #fff;
              }
              .ant-select-selection-item {
                color: #fff;
              }
              .ant-select-selection-placeholder {
                color: #acacca;
              }
            }
            .ant-select-clear {
              background-color: $inputModalDark;
              svg {
                fill: #acacca;
              }
            }
            .ant-select-arrow svg {
              fill: #fff;
            }
          }
        }
      }
      .trucks-modal-body-content {
        .trucks-modal-body-content-table {
          tr {
            background-color: $cardModalHeaderDark;
            th,
            td {
              color: #fff;
              .trucks-modal-checkbox {
                .ant-checkbox-inner {
                  background-color: $cardModalBodyDark !important;
                }
              }
              .ant-picker {
                background-color: $cardModalBodyDark !important;
                .ant-picker-input {
                  background-color: $cardModalBodyDark;
                  input {
                    color: #f2f2f2;
                    &::placeholder {
                      color: #acacca;
                    }
                  }
                  .ant-picker-suffix svg {
                    color: #acacca;
                  }
                }
              }
            }
            td:first-child {
              svg path {
                fill: #fff;
              }
            }
          }
        }
      }
    }
  }
}

.trucks-modal-tooltip {
  .ant-tooltip-inner {
    width: 400px;
    border-radius: 5px;
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        padding: 0;
      }
    }
  }
}
.select-driver-tooltip {
  width: max-content;
  .ant-tooltip-inner {
    width: max-content !important;
    border-radius: 5px;
  }
}
.crews-modal-tooltip {
  .ant-tooltip-inner {
    width: 15rem !important;
    border-radius: 5px;
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        padding: 0;
      }
    }
  }
}

.add-remove-row-container {
  .popupLead {
    border: 1px solid;
    width: 50px;
    margin-left: 5px;
    height: 25px;
    vertical-align: center;
    color: #e6ebf1;
  }
}

//schedule modal

.scheduleModal {
  background-color: #f4f5f6 !important;
  width: 100%;
  max-height: 100%;
  //overflow-y: auto;
  z-index: 900 !important; //antd-modals have z-index 1000

  .ant-modal-body {
    overflow-y: hidden !important;
  }
  .ant-modal-footer {
    padding: 10px 16px !important;
  }

  // .ant-modal-header {
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   width: 100%;
  //   height: 50px;
  //   background-color: #1e2a44 !important;
  //   border-radius: 0px;
  // }

  // .ant-modal-title {
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   width: 100%;
  // }

  .MuiDialog-scrollPaper {
    .MuiDialog-paperScrollPaper {
      overflow-y: unset !important;
    }
  }

  .container-fluid {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    gap: 1.5rem;
  }
  .schedule-modal-app-bar {
    background-color: #1e2a44 !important;
    .project-name {
      color: #fff;
      margin-right: 20px;
    }

    .info-column {
      height: 100%;

      .info-label,
      .info-data {
        text-align: center;
        font-family: Helvetica, serif;
        font-size: 14px;
        color: #fff;
      }

      .info-data {
        font-size: 20px;
        font-weight: bold;
        border-top: 3px solid #1e2a44;
      }
    }

    .progress-container {
      position: relative;
      display: flex;
      flex-direction: row;
      gap: 1rem;
      .progress-linear {
        margin-right: 60px;

        &.schedule-line .MuiLinearProgress-bar {
          background: repeating-linear-gradient(
            90deg,
            #58afff 0,
            #ffffff 3rem,
            #58afff 6rem
          );
        }
        &.project-line .MuiLinearProgress-bar {
          background: repeating-linear-gradient(
            90deg,
            #ac7100 0,
            #ff6969 4rem,
            #ac7100 8rem
          );
        }

        &.value-100 {
          .MuiLinearProgress-bar {
            background: #00ff00;
          }
        }
      }
      .progress-percentage-label {
        position: absolute;
        color: #32325d;
        right: 10px;
        top: -9px;
        padding: 0 5px;
        box-shadow: 0 0 5px 2px grey;
        background: #ffffff4f;
        border-radius: 5px;
        font-weight: bold;

        &.value-none {
        }
        &.value-0 {
          color: #32325d6b;
        }
        &.value-100 {
          color: #ffffff;
          text-shadow: 0 0 7px #004e08;
          background-color: #0fc300a3;
        }
      }
    }
  }

  .schedule-main-row {
    background-color: #f4f5f6;
    margin: 0 10px;
    //     height: calc(100vh - 100px) !important;
    height: 100%;
    // overflow-y: auto;

    .step-0 {
      //width: 80%;
      //height: 600px;
      //left: 10%;
      position: relative;
      display: none;

      &.active {
        display: block;
      }

      .top-part {
        display: flex;
      }

      .selection-form {
        display: block;
        justify-content: space-evenly;

        .schedule-simple-selection {
          min-width: 200px;

          .MuiInputBase-root.Mui-focused fieldset {
            border-color: #1890ff;
          }
        }

        .schedule-select-project {
          min-width: 400px;

          .MuiAutocomplete-inputRoot {
            //height: 32px;
          }
        }

        .schedule-calendar-view {
          .dates-range {
            display: flex;
            justify-content: center;
            span {
              background: #d5d5d5;
              padding: 2px;
              border-radius: 2px;
            }
          }

          .calendar-badge {
            &.start-date {
              .calendar-PickersDay {
                //background-color: rgba(22, 111, 192, 0.45);
                background-color: rgba(24, 144, 255, 0.45);
                border-left: 5px solid #1890ff;
              }
            }
            &.end-date {
              .calendar-PickersDay {
                //background-color: rgba(127, 194, 255, 0.45);
                background-color: rgba(24, 144, 255, 0.45);
                border-right: 5px solid #1890ff;
              }
            }
            &.in-range {
              .calendar-PickersDay {
                background-color: rgba(24, 144, 255, 0.45);
              }
            }
          }

          //.schedule-calendar-view-datepicker {
          .calendar-PickersDay.Mui-selected {
            //background-color: rgba(24, 144, 255, 0.45);
            color: #ddeeff;
          }
          [class^="PrivatePickersSlideTransition"] {
            min-height: 200px;
          }
          .schedule-count {
            color: #05a000;
            font-size: larger;
            font-weight: bolder;
          }
          //}

          .date-buttons {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 400px;
            .ant-radio-button-wrapper {
              //margin: 0 5px;
            }
          }

          .date-selector-container {
            display: flex;
            .date-buttons {
              // display: flex;
              // flex-flow: column;
            }
            .RangePicker-container {
              margin-top: 10px;
            }
          }
        }
      }

      .schedule-middle-div {
        //min-height: 200px;
        //max-height: 400px;
        display: flex;
        flex-direction: row;
        gap: 2rem;
        height: 600px;
        width: 100%;
        .scheduleDataInfo {
          width: 400px;
          height: 100%;
          border-radius: 10px;
          ant-card-body {
            position: unset !important;
          }
          .header-schedule-info-data {
            display: flex;
            justify-content: space-between;
          }
          .header-sow-label {
            font-weight: 600;
            font-size: 16px;
          }

          .ant-card-head {
            font-weight: 600;
            font-size: 16px;
            background-color: #f8f8fa;
            border-radius: 10px 10px 0px 0px;
          }
          .ant-card-title {
            display: flex;
            color: #fff;
            justify-content: center;
            align-items: center;
          }
        }

        //.schedule-table-container continues to ScheduleCalendar.scss
      }

      .weather-view {
        margin-top: 10px;
        .weather-container {
          display: flex;
          justify-content: space-around;

          .period-container {
            margin: 0 5px;

            .period-name {
              height: 50px;
            }
          }
        }
      }
    }
    .step-1 {
      width: 100%;
      display: none;

      &.active {
        display: block;
      }

      .info-row {
        position: relative;
        width: 85%;
        height: auto;

        .info-column {
          height: 100%;

          .info-label,
          .info-data {
            text-align: center;
            font-family: Helvetica, serif;
            font-size: 20px;
            color: #383554;
          }

          .info-data {
            font-weight: bold;
            border-top: 3px solid #facf44;
          }
        }
      }

      .interaction-row {
        margin-top: 10px;
        padding: 0;
        width: 100%;

        .interaction-div {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          margin-left: 2px;
          overflow-y: auto;
          max-height: 695px;
          margin-top: 40px;

          .weekend {
            input {
              background: $weekend-background;
            }
          }

          .interaction-form {
            z-index: 1;
            &.not-displayed {
              display: none;
            }

            .day-blocked {
              border: 1px solid $schedule-error;
            }

            .day-warning {
              border: 1px solid $schedule-warning;
            }

            .comes-from-days-container {
              display: flex;
              flex-direction: column;
              //flex-wrap: nowrap;
              justify-content: space-evenly;
              margin: 5px 0;

              .postponed-day-button-note-short-view {
                text-overflow: ellipsis;
                /* overflow-wrap: break-word; */
                /* word-break: break-word; */
                /* white-space: nowrap; */
                overflow: hidden;
                font-size: smaller;
                margin: 0 5px;
                width: 70%;
              }
            }

            .missing-days-collapse {
              .ant-collapse-header,
              .anticon {
                padding-top: 5px;
                padding-bottom: 5px;
                background-color: #004e08;
              }

              .ant-collapse-header:hover {
                background-color: #f9f995b3;
                border-radius: 5px;
              }

              .add-day-div {
                margin: 5px 0;
                padding: 5px;
                border: 3px dashed #f9f995;
                text-align: center;
                color: #9da716;

                &.weekend {
                  background-color: $weekend-background;
                }

                &:hover {
                  box-shadow: inset 0 0 15px 6px #feff83b3;
                  cursor: pointer;
                }
              }
            }

            .canceled-day-collapse {
              background-color: #ffafaf;
              width: 739px;
              .ant-collapse-header,
              .anticon {
                padding-top: 5px;
                padding-bottom: 5px;
              }

              .ant-collapse-header:hover {
                background-color: #ffd9d9;
                border-radius: 5px;
              }

              .ant-collapse-content > .ant-collapse-content-box {
                padding: 5px 0;
              }
            }
          }

          .date-picker-input {
            width: 170px;
            border-radius: 10px;

            input {
              color: #fff;
              padding-bottom: 10px;
              padding-top: 10px;
            }
          }

          .services-for-estimations {
            width: 100% !important;
            // overflow-y: auto;

            // height: 700px;
            .pli-per-service {
              color: #88929e;
              width: 100% !important;
              // width: 100% !important;
              // height: auto;
              // overflow-y: auto;
              // max-height: 696px;              // overflow-x: hidden;

              .services-container {
                display: flex;
                flex-wrap: wrap;
                width: 100% !important;

                .ag-grid-container {
                  //width: 80%;
                  padding-left: 5px;
                  padding-right: 5px;

                  .serviceContainerSch {
                    background-color: #ffffff;

                    // padding: 20px 25px;
                    width: 100% !important;
                    .ant-collapse {
                      width: 100% !important;
                      border: none !important;
                      border-radius: 5px;
                      .services-panel-header {
                        border: none !important;
                        .ant-collapse
                          .ant-collapse-content
                          > .ant-collapse-content-box {
                          padding: 10px 10px !important;
                        }
                        border-bottom: none !important;
                        padding: 7px 0;
                        .ant-collapse-header
                          .ant-collapse
                          > .ant-collapse-item
                          > .ant-collapse-header {
                          position: relative;
                          display: flex;
                          flex-wrap: nowrap;
                          align-items: flex-start;
                          padding: 12px 16px;
                          color: rgba(0, 0, 0, 0.85);
                          line-height: 1.5715;
                          cursor: pointer;
                          background: aquamarine !important;
                          transition: all 0.3s, visibility 0s;
                        }

                        .ant-collapse-header {
                          width: 100% !important;
                          display: flex;
                          align-items: center;
                          padding: 5px 16px;
                          background-color: #00ff00;

                          div:first-child {
                            display: flex;
                            align-items: center;
                          }

                          .elevation-label {
                            // font-weight: bold;
                            margin-top: 0;
                          }
                          .ant-collapse-extra {
                            display: flex;
                            align-items: center;
                            .ant-select {
                              margin: 0 15px;
                              .ant-select-selector {
                                border: none;
                                border-radius: 5px;
                                padding: 4px 8px;
                              }
                            }
                            span:last-child {
                              .ant-btn {
                                background-color: #1264a3;
                                border-radius: 5px;
                                height: 43px;
                                span {
                                  color: #fff;
                                  //   font-size: 1rem;
                                  font-weight: normal;
                                }
                              }
                            }
                          }
                        }
                        .ant-collapse-arrow {
                          display: inline-block;
                          margin-right: 12px;
                          font-size: 12px;
                          vertical-align: -1px;
                        }

                        .title-floors {
                          // padding: 5px;
                          // margin: 0 5px;
                          // box-shadow: 0 0 3px 1px #b3b3b3;
                          // border-radius: 5px;
                        }
                      }
                    }
                  }

                  .elevation-label {
                    margin-left: 20px;
                    margin-top: 7px;
                    display: inline-block;
                  }
                }
              }
            }
          }

          .start-end-date-renderer {
            .show-all-dates-button {
              background: #1890ff;
              color: #fff;
              padding: 5px;
              margin-right: 5px;
            }
          }
        }
      }
    }
    .step-2 {
      //width: 80%;
      margin-left: 15%;
      position: relative;
      height: 600px;
      display: none;

      &.active {
        display: block;
      }
    }
    .step-3 {
      //width: 80%;
      margin: auto;
      height: 600px;
      position: relative;
      display: none;
      &.active {
        display: block;
      }

      .schedule-card {
        padding: 5px;
        box-shadow: 0px 1px 6px 3px rgba(0, 0, 0, 0.19);
        margin: 15px;
      }

      .schedule-overview {
      }

      .select-has-changes {
        .ant-select-selector {
          background-color: rgba(116, 131, 255, 0.45);
        }
      }

      .checkbox-has-changes {
        .ant-checkbox-wrapper {
          background-color: rgba(116, 131, 255, 0.45);
        }
      }

      .schedule-checks-container {
        display: flex;
        flex-wrap: wrap;
        padding-left: 20px;

        .schedule-checks-checkbox-container {
          width: 230px;
          .ant-checkbox-wrapper {
            color: #32325d;
            font-size: 15px;
          }
        }
      }
    }
  }
  .scheduleModal-footer {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 1rem;
    width: 100%;
    justify-content: flex-end;
  }
}

.schedule-date-picker-dialog {
  .MuiPickersModalDialog-action,
  .MuiDialogActions-root {
    //class name may change when updating MaterialUI
    justify-content: center;
  }
  .schedule-date-picker {
    .MuiCalendarPicker-root {
      div[role="row"] {
        div[role="cell"]:last-child,
        div[role="cell"]:first-child {
          .MuiPickersDay-root {
            background-color: rgba(53, 139, 100, 0.71);
          }
          .Mui-disabled {
            background-color: rgba(53, 139, 100, 0.4);
          }
        }
      }
    }
  }
}

.members-view-tooltip {
  display: flex;
  flex-direction: column;
  span {
    padding: 2px;
    font-size: 15px;
  }
}

.schedule-preview-modal-wrapper {
  // .ant-modal-body {
  //   padding: 20px 35px;
  // }
  .schedule-preview-container {
    height: 95vh;
    overflow-y: auto;
    .action-buttons {
      float: right;
      width: 100px;
      justify-content: space-between;
      display: inline-flex;
    }
    .active-day {
      color: #fff;
      background: #1890ff;
      border-bottom: none;
    }

    .inactive-day {
      background-color: #fff;
      color: #1890ff;
      border-bottom: none;
    }
  }
}

.project-option {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .estimations-count {
    color: #ce9200;
    font-weight: bold;
  }
  .services-count {
    color: #5b5e62;
    font-weight: bold;
  }
}

.breakdowns-modal {
  .ant-modal {
    // height: 319px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #00000029;
    border-radius: 10px;
    opacity: 1;
    width: 1300px;
    max-width: 1700px;
  }
  .ant-modal-header {
    display: flex;
    align-items: center;
    height: 45px;
    background: #f7f8fa 0% 0% no-repeat padding-box;
    border-radius: 10px 10px 0px 0px;
    opacity: 1;
  }

  .ant-modal-title {
    display: flex;
    justify-content: center;
    color: var(--unnamed-color-323338);
    text-align: left;
    font: normal normal 600 16px/39px Open Sans;
    letter-spacing: 0px;
    color: #323338;
    opacity: 1;
  }
  .ant-modal-content {
    width: 1300px;
    border-radius: 10px;
  }

  .ant-modal-close-x {
    justify-content: center;
    align-items: center;
    display: flex;
    // padding: 0px 20px 0px 0px;
    svg {
      fill: black;
    }
  }

  .ant-modal-body {
    height: fit-content;
    // max-height: 600px;
    // overflow-y: auto;
  }

  .breakdowns-table-container {
    // height: fit-content;
    // max-height: 600px;
    // overflow-y: auto;
    .header-breakdown-content {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-direction: row;
      /* gap: 2.2rem; */
      height: -moz-fit-content;
      height: fit-content;
      max-height: 230px;
      overflow: auto;
    }
    .MuiTableHead-root {
      th {
        min-width: 200px;
      }
    }
    .progress-slider:not(.Mui-disabled) {
      color: #1890ff;
    }

    .progress-checkbox {
      .ant-checkbox-inner {
        width: 32px;
        height: 32px;
        &::after {
          width: 11px;
          height: 18px;
        }
      }
    }

    .table-row-hover {
      &:hover {
        background-color: rgba(13, 165, 192, 0.38);
      }
      .no-hover:hover {
        background-color: transparent;
      }
    }

    .dimension-progress-row {
      background: #cccccc;
      &.disabled-jump {
        background: #797979;
      }
    }

    .breakdown-day-progress-input {
      width: 50px;
      margin: 0 5px;
      input {
        text-align: center;
      }
    }

    .complete-on-this-day-button {
      margin: 5px;
    }

    .completedOnThisDay {
      background: #3cc632;
      span {
        background: #fff;
        padding: 0 5px;
        border-radius: 5px;
      }
    }

    .completedOnPrevDay {
      background: #b1e8ab;
      span {
        background: #fff;
        padding: 0 5px;
        border-radius: 5px;
      }
    }
  }

  .title-floors {
    // padding: 5px;
    // margin: 0 5px;
    // box-shadow: 0 0 3px 1px #b3b3b3;
    // border-radius: 5px;
  }
}

.schedule-form-logs {
  width: 500px;
  h4 {
    text-align: center;
  }
}
.schedule-work-log-content {
  b {
    //color: #0c85d0;
  }
}

//popover
.schedule-statistics-for-this-day {
  z-index: 1400 !important;
  &.empty-day {
    .ant-popover-inner {
      background: #bfc1c9;
    }
  }
  &.non-empty-day {
    //background: #6adaff;
  }
}
//popover
.schedule-statistics-for-this-day-in-diff-sch {
}

.schedule-statistics {
}

.project-statistics-for-all-schedules {
}

//popover
.schedule-from-this-day {
  .first-day {
    color: #73c8ff;
    font-weight: bold;
  }
  .this-day {
    font-weight: bold;
  }
}

.project-calendar-popover {
  .ant-popover-arrow::before {
    height: 7px;
    background-color: #f8f8fa;
  }
  .ant-popover-title {
    height: 32px;
    display: flex;
    align-items: center;
    background-color: #f8f8fa;
    border-radius: 10px 10px 0px 0px;
    padding: 0px 10px;
    margin: 0px;
  }
  .ant-popover-inner-content {
    .dateContainer {
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      gap: 2rem;
      background-color: #f8f8fa;
      border-radius: 5px;
      padding: 0px 10px;
      p {
        margin-bottom: 0px;
      }
    }
  }

  .this-schedule {
    background: rgba(155, 188, 255, 0.75);
  }

  .error {
    background: $schedule-error;
  }
  .warning {
    background: $schedule-warning;
  }
}

.project-calendar-popover-dark {
  .ant-popover-arrow::before {
    background-color: $cardModalHeaderDark;
  }
  .ant-popover-inner {
    background-color: $cardModalBodyDark;
  }
  .ant-popover-title {
    background-color: $cardModalHeaderDark;
    color: #fff;
  }
  .ant-popover-inner-content {
    .dateContainer {
      background-color: $cardModalHeaderDark;
      color: #fff;
    }
  }
}

.pli-history-popover {
  .schedule-history {
    margin: 10px 0;
    .schedule-history-element {
      margin: 0 5px;
      &.installation {
        color: green;
      }
      &.removal {
        color: red;
      }
      &.repair {
        color: grey;
      }
    }

    .schedule-history-progressByDay {
      //margin: 0 15px;
    }
  }
}

.postpone-view {
  .ant-modal-header {
    height: 45px;
    background: #f7f8fa 0% 0% no-repeat padding-box;
    border-radius: 10px 10px 0px 0px;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-modal-title {
    display: flex;
    // justify-content: center;
    align-items: center;
    width: 100%;
    height: 22px;
    color: var(--unnamed-color-323338);
    text-align: left;
    font: normal normal 600 16px/39px Open Sans;
    letter-spacing: 0px;
    color: #323338;
    opacity: 1;
  }
  .ant-modal-close-x {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 45px;
    width: 45px;
    padding: 0;
    color: #323338;
    font-size: 20px;
  }
  .ant-modal-content {
    border-radius: 10px;
  }

  .postpone-check {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    margin-bottom: 20px;
  }
  .postpone-view-buttons {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .day-status-buttons {
      div {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .day-status-change-container {
      display: flex;
      flex-direction: row;
      gap: 2rem;
    }
  }
  .new-day-container {
    gap: 10px;
  }
  .Postponed-view-buttons {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 3rem;
    width: 197px;
    height: 32px;
    border: none;
    background: #f5f5f7 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
  }
}

.postpone-view-dark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      .postpone-view-buttons {
        .day-status-buttons {
          .ant-btn {
            background-color: $inputModalDark;
            color: #fff;
            svg path {
              fill: #fff;
            }
          }
        }
      }
      .new-day-container {
        .ant-picker {
          border: none;
          background-color: $inputModalDark !important;
          .ant-picker-input {
            background-color: $inputModalDark;
            input {
              color: #f2f2f2;
              &::placeholder {
                color: #acacca;
              }
            }
            .ant-picker-suffix svg {
              color: #acacca;
            }
            .ant-picker-clear {
              background-color: $inputModalDark;
              svg {
                fill: #acacca;
              }
            }
          }
        }
        div {
          color: #fff;
          .optionsForPostponingCheckbox {
            .ant-select {
              .ant-select-selector {
                background-color: $inputModalDark !important;
                .ant-select-selection-search-input {
                  color: #fff;
                }
                .ant-select-selection-item {
                  color: #fff;
                }
                .ant-select-selection-placeholder {
                  color: #acacca;
                }
              }
              .ant-select-clear {
                background-color: $inputModalDark;
                svg {
                  fill: #acacca;
                }
              }
              .ant-select-arrow svg {
                fill: #fff;
              }
            }
            .ant-checkbox-wrapper {
              color: #fff;
            }
          }
        }
      }
    }
  }
}

.schedule-progress-label {
  margin: 5px;
}

.day-status-change-modal {
  .ant-modal-header {
    height: 45px;
    background: #f7f8fa 0% 0% no-repeat padding-box;
    border-radius: 10px 10px 0px 0px;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-modal-title {
    display: flex;
    // justify-content: center;
    align-items: center;
    width: 100%;
    height: 22px;
    color: var(--unnamed-color-323338);
    text-align: left;
    font: normal normal 600 16px/39px Open Sans;
    letter-spacing: 0px;
    color: #323338;
    opacity: 1;
  }
  .ant-modal-content {
    border-radius: 10px;
  }
  .ant-modal-close-x {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 45px;
    width: 45px;
    padding: 0;
    color: #323338;
    font-size: 20px;
  }
  .postpone-check {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 3rem;
  }
  .day-status-change-container-buttons {
    display: flex;
    justify-content: center;
    // align-items: center;
    flex-direction: row;
    gap: 1rem;
    .day-status-change-container {
      display: flex;
      flex-direction: row;
      gap: 2rem;
    }
    .reason-change {
      background: #f5f5f7 0% 0% no-repeat padding-box;
      border-radius: 5px;
      border: none;
      opacity: 1;
    }
    .btn-status-schedule {
      display: flex;
      // justify-content: center;
      align-items: center;
      flex-direction: row;
      gap: 3rem;
      width: 197px;
      height: 32px;
      border: none;
      // background: var(--unnamed-color-f5f5f7) 0% 0% no-repeat padding-box;
      background: #f5f5f7 0% 0% no-repeat padding-box;
      border-radius: 5px;
      opacity: 1;
      div {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .footer-btn-day-status-changes {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    Button {
      // width: 150px;
      height: 32px;
      opacity: 1;
      border-radius: 5px;
    }
  }
  z-index: 900;

  .days-status-radio-and-calendar {
    display: flex;
  }

  .new-day-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;

    .date-container {
      margin: 5px;

      .MuiInternalClock-amButton,
      .MuiInternalClock-pmButton {
        position: inherit !important;
      }
    }

    .weekend {
      background: $weekend-background;
    }
  }
}

.day-status-change-modal-dark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      color: #fff;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      .day-status-change-container-buttons {
        .day-status-change-container {
          .day-status-buttons {
            .ant-btn {
              background-color: $inputModalDark;
              color: #fff;
              svg path {
                fill: #fff;
              }
            }
          }
        }
        .ant-input {
          background-color: $inputModalDark !important;
          color: #fff;
          &::placeholder {
            color: #acacca;
          }
        }
        .ant-select {
          .ant-select-selector {
            background-color: $inputModalDark !important;
            .ant-select-selection-search-input {
              color: #fff;
            }
            .ant-select-selection-item {
              color: #fff;
            }
            .ant-select-selection-placeholder {
              color: #acacca;
            }
          }
          .ant-select-clear {
            background-color: $inputModalDark;
            svg {
              fill: #acacca;
            }
          }
          .ant-select-arrow svg {
            fill: #fff;
          }
        }
      }
    }
  }
}

.schedule-form-notes-drawer {
  .ant-drawer-content-wrapper {
    min-width: 500px;
  }
}

.jumps-config-modal {
  .jumps-config-container {
    .action-buttons {
      margin: 5px;
    }

    .drag-and-drop-layout {
      display: flex;
      flex-wrap: wrap;

      .jump-container {
        margin: 10px;
        border: 2px solid #a1a1a1;
        border-radius: 10px;
        box-shadow: 0px 0px 6px 1px #8f8f8f;
        padding: 5px;

        .jump-container-droppable {
          //box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
          //margin: 10px;

          .floor {
            padding: 5px;
            background: mintcream;
            margin: 5px 0;
            border-radius: 5px;
          }
        }
      }
    }
  }
}

.postpone-label {
  width: 100%;
  height: 22px;
  color: var(--unnamed-color-323338);
  text-align: left;
  font: normal normal 600 16px/39px Open Sans;
  letter-spacing: 0px;
  color: #323338;
  opacity: 1;
}

.postpone-select {
  width: 100%;
}

.postponed-footer-buttons {
  display: flex;
  justify-content: space-between;
}

.origin-schedule-day-popover {
}

.cardLayout {
  // height: 75px;
  overflow: hidden;
  background-color: #fff;
  border-radius: 20px;
  // padding: 20px;
  box-shadow: 0px 1px 4px #00000029;
}

.schedule-day-container {
  @extend .cardLayout;
  // @include flex(flex-start, space-evenly);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  // width: auto;
  // margin: unset;
  border: 0;
  margin-bottom: 10px;
  width: 739px;
  height: 90px;

  .schedule-date-pickers {
    @include flex(center, flex-start, column);
    width: 370px;

    .pickers {
      @include flex(flex-start, flex-start);
      *:not(:last-child) {
        margin-right: 5px;
      }
    }

    .infos {
      @include flex(center, center);

      *:not(:last-child) {
        margin-right: 5px;
      }
    }

    .day-hours {
      font-size: 12px;
      &.invalid {
        color: red;
      }
    }

    .startDate {
      background-color: #71cf48;
      color: #fff;
    }

    .endDate {
      background-color: #fe4c4a;
      color: #fff;
    }
  }

  .weatherIconContainer {
    background-color: #1264a3 !important;
    border-radius: 10px;
    border: 0;
    height: 40px;
    width: 40px;
    padding: 5px;
    cursor: pointer;
    &:hover {
      background-color: #e6e9ef;
    }
  }

  .weatherIcon {
    height: 100%;
    width: 100%;
    color: #fff;
  }

  .crewBtn {
    width: 25px;
    height: 25px;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
  }

  .dayCardBtn {
    background-color: #e6e9ef !important;
    border-radius: 10px;
    border: 0;
    height: 40px;
    width: 40px;
    padding: 5px;
    &:hover {
      background-color: #e6e9ef;
    }
  }
  .dayCardBtn2 {
    border-radius: 10px;
    border: 0;
    height: 40px;
    width: 40px;
    padding: 5px;
    background-color: #1264a3 !important;
    color: #fff;
  }

  .form-group {
    // @include flex(center, flex-start, column);
    //margin-right: 2px;
    //margin-left: 2px;
    // margin-bottom: 0;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // text-align: center;
    .form-control {
      // @extend .weatherIconContainer;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }

  .day-action-buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .change-day-status-button {
    padding: 0;
  }

  .remove-day-button {
    font-size: 10px;
    padding: 2px;
    float: right;
    height: 25px;
    width: 24px;
    color: red;

    &.disabled {
      color: #5b5e62;
      &:hover {
        border: #5b5e62;
        cursor: no-drop;
      }
    }
  }
}

.ant-collapse.schedule-collapse-days {
  .days-in-schedule-cell-select {
    border: none !important;
    .ant-select-selector {
      border: none !important;
    }
  }
  .ant-collapse-item > .ant-collapse-header {
    height: 45px;
    background: #f5f5f7 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    cursor: pointer;
    padding: 3px !important;
    display: flex !important;
    align-items: center !important;

    .ant-collapse-expand-icon {
      padding-left: 5px;
    }

    .ant-collapse-header-text {
      height: inherit;
      display: flex;
      align-items: center;
      width: 100%;
    }
  }
  .ant-collapse-content-box {
    padding: 0 !important;
  }
}

// .ant-collapse
//   > .ant-collapse-item
//   > .ant-collapse-header
//   .ant-collapse-arrow
//   svg {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   font-size: 15px;
//   color: #323338;
//   opacity: 1;
// }

// .css-2iy3mj {
//   min-height: 198px !important;
// }

.optionsForPostponingCheckbox {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
}

.postpone-textarea {
  height: 112px;
  background: #f5f5f7 0% 0% no-repeat padding-box;
  border: none;
  border-radius: 5px;
}

@media (max-width: 768px) {
  .checkboxForPostponingOptions {
    flex-direction: column;
  }
  .postpone-textarea {
    width: 180px;
  }
  .postpone-select {
    width: 100px;
  }
  // .postpone-view .postpone-check {
  //   flex-direction: column;
  // }
}

// @keyframes servicesData {
//   from {
//     opacity: 0;
//     filter: blur(2px);
//     transform: translateY(50px);
//   }
//   to {
//     opacity: 1;
//     filter: blur(0);
//     transform: translateY(0px);
//   }
// }

// @keyframes checkboxServices {
//   from {
//     opacity: 0;
//     filter: blur(2px);
//     // transform: translateX(50px);
//     width: 0%;
//   }
//   to {
//     opacity: 1;
//     filter: blur(0);
//     // transform: translateX(0px);
//     width: 100%;
//   }
// }

// @keyframes statusBtn {
//   from {
//     opacity: 0;
//     filter: blur(2px);
//     transform: translateX(-50px);
//   }
//   to {
//     opacity: 1;
//     filter: blur(0);
//     transform: translateX(0px);
//   }
// }

.dateInfoPostpone {
  display: flex;
  justify-content: center;
  // align-items: center;
  flex-direction: column;
  gap: 1rem;
}

@keyframes serviceAnimation {
  0% {
    opacity: 0;
    filter: blur(2px);
    transform: translateX(-50px);
  }
  100% {
    opacity: 1;
    filter: blur(0);
    transform: translateX(0px);
  }
}

@keyframes serviceContainerSch {
  0% {
    opacity: 0;
    filter: blur(2px);
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    filter: blur(0);
    transform: translateY(0px);
  }
}
