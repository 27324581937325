.footerInvoiceDrawerWrapper{
    padding: 16px 20px;
    background-color: white;

    .container {
        text-align: center;
        display: flex;
        flex-direction: row-reverse;
        flex: 0 0 auto;
        -webkit-box-pack: justify;
        justify-content: space-evenly;
        width: 100%;
    }

    .editContainer {
        margin: 0;
        padding: 0;
        width: 150px;

        .editInvoice {
            background-color: #1264A3 !important;

            .mondayButtonDivider {
                background-color: #769ebd;
            }
        }
    }

    .actionsContainer {
        width: 150px;
        height: 32px;
        background: #1264A3 0% 0% no-repeat padding-box;
        border-radius: 5px;
        
        >a {
            display: flex;
            align-items: center;
            >div {
                border: 0;
                font: normal normal normal 14px/19px Open Sans;
                letter-spacing: 0px;
                color: #FFFFFF;
            }
        }
    }

    .hoverMeContainer {
        .hoverMeText{
            height: 30px;
            >svg {
                    margin-left: 10px;
                    margin-right: -10px;
                    margin-top: 1px;
                }
        }
        >div {
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #6B6C72;
            font-weight: 600;
            border: 2px solid #6B6C72;
            padding: 4px 16px;
            border-radius: 4px;
        }
    }  

    .deleteButton {
        border: 0;
        background-color: #FE4C4A;
        padding: 0px 8px 0px 8px;
        border-radius: 5px;

        >svg>path {
            color: white;
            margin-top: -2px;
        }
    }
}
