@font-face {
  font-family: "GeogrotesqueStencilA-SbItalic";
  src: url(./../../../../fonts/GeogrotesqueStencilA-SbItalic.otf);
}

$base-color: #1f2a44;
$border-color: #231f20;
$back-color: #738ab2;

.left_carousel {
  display: absolute;
  height: 100vh;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  .skewDiv {
    width: var(--componentWidth);
    max-width: 960px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: $base-color;
    clip-path: polygon(100% 0%, 0% 300%, 0% 0%, 20% 0%);
    opacity: 1;
    transition: opacity 0.5s ease;
    overflow: visible;
    z-index: 2;
    .textSpan {
      font-size: 50px;
      width: 100%;
      color: #fff;
      line-height: 59px;
      letter-spacing: 0em;
      font-family: GeogrotesqueStencilA-SbItalic !important;
    }
    .stepperContainer {
      height: 15px;
      width: var(--componentWidth);
      display: flex;
      .stepperSquare {
        height: 15px;
        width: 15px;
        border: 1px solid #fff;
        margin-right: 12px;
        cursor: pointer;
        background-color: transparent;
        z-index: 9999999 !important;
      }
      .selectedStep {
        background-color: #fff;
      }
    }
  }
  .behindDiv {
    position: absolute;
    left: 0;
    right: 0;
    width: var(--afterEffecttWidth);
    max-width: 960px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: $base-color;
    clip-path: polygon(100% 0%, 0% 300%, 0% 0%, 20% 0%);
    transition: 0.7s ease;
  }
  .fadeIn {
    animation: fadeIn ease 0.7s;
  }
  .fadeOut {
    animation: fadeOut ease 0.7s;
  }
  .slick-dots-bottom {
    display: flex !important;
    justify-content: flex-start !important;
  }

  h1 {
    font-family: "Noto Sans KR", sans-serif;
    color: #fff;
    font-size: calc(55px + 6 * ((100vw - 320px) / 680));
  }

  h3 {
    color: #fff;
    font-size: 1.4rem;
    font-weight: 100 !important;
  }

  .slick-dots {
    margin-left: 70px !important;
  }

  .slick-dots > li {
    margin: 0 5px !important;
  }

  .slick-dots > li > button {
    width: 20px !important;
    height: 20px !important;
  }
}

@media (max-width: 892px) {
  .left_carousel {
    .skewDiv .textSpan {
      font-size: 30px;
      line-height: 39px;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
