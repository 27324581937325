@import "../../../../../../index.scss";

.documentationTemplatesModal {
  width: fit-content !important;

  .ant-modal-header {
    height: 45px;
    display: flex;
    align-items: center;
    background-color: #f8f8fa;
    overflow: hidden;
    border: none;

    .ant-modal-title {
      font-size: 16px;
      width: 100%;
      font-weight: 600;
      color: #323338;
      padding: 0 !important;
      height: 45px;
      display: flex;
      align-items: center;
    }
  }
  .ant-modal-body {
    padding: 0;
    .ant-form {
      width: 100%;
    }
  }

  .documentationTemplatesModalContainer {
    @include flex(center, center, column);
    // padding: 25px 120px;

    .docsLengthLabel {
      @include text(18px, 700, 0, #1264a3);
    }
    .configElementContainer {
      margin: 0 !important;
    }
    .ant-row {
      margin: 0;
      margin-top: -10px;
    }

    .checkboxesContainer {
      max-height: 300px;
      margin-bottom: 10px;
      padding-top: 10px;
      overflow-x: hidden;
      overflow-y: auto;
    }
    .checkboxLabel {
      display: flex;
    }

    .templateCheckbox {
      width: 400px;
      background-color: #f7f8fa;
      margin-bottom: 0 !important;
    }
    .templateLabel {
      width: 259px;
      display: inline-block;
      @include textEllipsis();
    }

    .tooltipContent {
      @include flex(center, center, column);
    }

    .templateModalActionButtons {
      @include flex(center, center);
    }

    .rejectButton {
      @include mondayButton(#f5f5f7, #e6e9ef, #000);
      margin-right: 30px;
    }

    .submitButton {
      @include mondayButton(#71cf48, #68c142);
    }
  }
}

@media (max-width: 900px) {
  .documentationTemplatesModal {
    .ant-modal-content {
      width: 100% !important;
      .ant-modal-body {
        min-width: 0 !important;
        width: 100%;
      }
    }
  }
}

@media (max-width: 450px) {
  .documentationTemplatesModal {
    .documentationTemplatesModalContainer {
      .templateCheckbox {
        width: 270px !important;
        .templateLabel {
          width: 150px;
        }
      }
    }
  }
}

@media (max-width: 380px) {
  .documentationTemplatesModal {
    .documentationTemplatesModalContainer {
      padding-bottom: 10px !important;
      .templateModalActionButtons {
        height: fit-content;
        flex-direction: column !important;
        .mondayButtonContainer {
          margin-bottom: 10px;
        }
      }
    }
  }
}
