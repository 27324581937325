@import "../../../../../../../../index.scss";

.selectTypeNewChargeModal {
  width: 755px !important;
  height: 320px !important;
  .ant-modal-close-x {
    display: none !important;
  }
  .modalHeader {
    width: 100%;
  }
  .ant-modal-header,
  .ant-modal-title,
  .modalHeader {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    > svg {
      cursor: pointer;
    }
  }
  .ant-modal-header {
    @include square(unset, 45px, unset, #f8f8fa);
    @include flex(center, flex-start);
    border-radius: 10px 10px 0 0;
    border-bottom: 0px;
    .ant-modal-title {
      font: normal normal bold 16px/39px Open Sans;
      color: #323338;
    }
  }
  .ant-modal-content {
    border-radius: 10px;
  }
  .ant-modal-footer {
    border-style: none !important;
  }
  .ant-radio-wrapper {
    margin-bottom: 12px;
    > span {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #323338;
    }
    .ant-radio {
      margin-bottom: 6px;
    }
    .ant-radio-inner {
      height: 20px;
      width: 20px;
    }
    .ant-radio-inner::after {
      transform: scale(0.7);
      margin-left: -8px;
    }
  }
  .percentContainer {
    background: #f5f5f7;
    border-radius: 5px;
    display: "flex";
    align-items: "center";
    margin-left: 10px;
    > span {
      margin-left: 7px;
    }
    .percentInput {
      width: 172px;
      height: 32px;
      background: #f5f5f7;
      border-radius: 5px;
      border: none;
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      padding-left: 5px;
    }
    .percentInput::placeholder {
      color: #e0e0e1;
    }
  }
  .ant-radio-wrapper {
    display: flex;
    align-items: center;
  }
  .NewItemChargeFooter {
    @include flex(center, space-between);
    .NewItemChargeFooterCancel {
      @include mondayButton(#fe4c4a, #eb4345, #fff);
      border-radius: 5px;
    }
    .NewItemChargeFooterSave {
      @include mondayButton(#68c142, #71cf48, #fff);
      border-radius: 5px;
    }
  }
}
