.single-collapse-info {
  width: calc(100% - 2px);
  height: fit-content;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.08);

  .collapse-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: hidden;
    height: 45px;
    padding: 0 20px;
    gap: 10px;
    font-weight: 600;
    cursor: pointer;
    background-color: #f5f5f7;
    width: 100%;

    .collapse-icon-container {
      transition: transform 0.3s ease-in-out;
    }

    .collapse-icon-container.icon-collapsed {
      transform: rotate(0);
    }

    .collapse-icon-container.icon-expanded {
      transform: rotate(90deg);
    }
  }

  .collapse-content {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
  }

  .collapse-content.content-collapsed {
    height: 0;
  }

  .collapse-content.content-expanded {
    height: max-content;

    .single-item-container {
      padding: 10px;
      width: 100%;
      border-top: 1px solid rgba(0, 0, 0, 0.08);
    }
  }
}

.single-collapse-info.single-collapse-dark {
  border: 1px solid rgba(255, 255, 255, 0.08);
  .collapse-header {
    background-color: #12131b;
  }

  .collapse-content {
    background-color: #2a2b3a;
  }

  .collapse-content.content-expanded {
    height: max-content;

    .single-item-container {
      border-top: 1px solid rgba(255, 255, 255, 0.08);
    }
  }
}
