$mainDarkBackgroundColor: #20212d;
$cardDarkHeaderColor: #2a2b3a;
$cardDarkBackgroundColor: #12131b;
$inputDarkColor: #2a2b3a;

.HeaderContentWrapper {
  height: 100%;
  display: flex;
  flex-direction: column;

  .HeaderContentWrapper-header {
    overflow: auto;
    height: 52px;
    min-height: 52px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.16);
    background-color: #fff;
    width: auto; //need to be auto
    gap: 20px;
    transition: 0.3s ease-out;
    text-wrap: nowrap;
    margin: 10px 10px 0 10px;

    .labeledInputContainer {
      .ant-form-item {
        margin-bottom: unset;
      }
    }

    .header-left {
      height: 100%;
      display: flex;
      align-items: center;
      gap: 20px;
      .title-content {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }

    .header-center {
      height: 100%;
      display: flex;
      align-items: center;
      gap: 5px;
    }

    .widgetHeader-right {
      display: flex;
      flex-direction: row;
      gap: 10px;
    }

    .header-right {
      height: 100%;
      display: flex;
      align-items: center;
      gap: 5px;

      > * {
        min-width: 150px;
        button {
          width: 100%;
        }
      }
    }
  }

  //please add child inside this classname and apply style to that child
  // if you set child with overflow it will hide box shadow
  .HeaderContentWrapper-content {
    width: 100%;
    height: 100%;
    transition: 0.3s ease-out;
    padding: 10px;
    overflow-y: auto;
    overflow-x: hidden;
    .HeaderContentWrapper-header {
      margin: 0;
      margin-bottom: 10px;
    }
    .HeaderContentWrapper-content {
      padding: 0;
    }
  }
}

.HeaderContentWrapper-dark {
  .HeaderContentWrapper-header {
    background-color: $cardDarkBackgroundColor;
    box-shadow: 0px 1px 4px 0px #ffffff29;

    .header-left {
      color: #fff;
    }

    .tab-btn {
      color: #fff;
    }
  }

  .HeaderContentWrapper-content {
    background-color: $mainDarkBackgroundColor;

    // box-shadow: 0px 1px 4px 0px #ffffff29;

    .documentationsTable {
      .ag-root-wrapper-body.ag-layout-normal {
        height: fit-content !important;
      }
      .ag-unselectable {
        background-color: $cardDarkBackgroundColor;
      }
    }

    .HeaderContentWrapper-header {
      background-color: $cardDarkHeaderColor;
      box-shadow: none;
    }
  }
}

@media (max-width: 480px) {
  .HeaderContentWrapper {
    .HeaderContentWrapper-header {
      padding: 0 5px;

      // .header-left {
      //   p,
      //   .anticon {
      //     display: none;
      //   }
      // }
    }
  }
}

// style for white-space in safari
@media screen and (max-width: 767px) and (-webkit-min-device-pixel-ratio: 0) and (pointer: coarse),
  (max-width: 767px) and (pointer: coarse) {
  .HeaderContentWrapper {
    .HeaderContentWrapper-header {
      white-space: nowrap;
    }
  }
}
