.taskRequest {
  .ant-modal-body {
    padding-bottom: 10px !important;
  }
  .question {
    margin-bottom: 10px;
    font-weight: 500;
    font-family: Helvetica;
    font-size: 18px;
  }
  .buttonContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    flex-direction: row;
    gap: 1rem;
    .ant-btn {
      margin-right: 15px;
      border-radius: 5px;
    }
  }
}

.taskRequestDark {
  .question {
    color: #fff;
  }
}
