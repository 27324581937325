@import "../../../../../../../../../../../index.scss";

.service-header-details-modal {
  .ant-modal {
    width: 90% !important;
    height: 780px;
    border-radius: 20px;
    overflow: hidden;

    .ant-modal-content {
      border-radius: 20px;
      overflow: hidden;

      .ant-modal-close {
        margin-top: -4px;
      }
    }

    .ant-modal-body {
      padding: 0;
      overflow: hidden;
    }
  }
}

.service-header-details-modal-dark {
  .ant-modal {
    width: 90% !important;
    height: 780px;
    border-radius: 20px;
    overflow: hidden;

    .ant-modal-content {
      border-radius: 20px;
      overflow: hidden;

      .ant-modal-close {
        margin-top: -4px;

        svg {
          fill: white;
        }
      }
    }

    .ant-modal-body {
      padding: 0;
      overflow: hidden;
    }
  }
}
