.sovNoServicesDarkMode {
  height: stretch;
  width: stretch;
  display: flex;
  justify-content: center;
  align-items: center;

  .iconContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .information {
    font-size: 16px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
      "Open Sans", "Helvetica Neue", sans-serif;
    display: flex;
    justify-content: center;
    color: white;
  }
}

.sovNoServicesLightMode {
  height: stretch;
  width: stretch;
  display: flex;
  justify-content: center;
  align-items: center;

  .iconContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .information {
    font-size: 16px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
      "Open Sans", "Helvetica Neue", sans-serif;
    display: flex;
    justify-content: center;
  }
}
