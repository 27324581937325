.progress-bar-container {
  display: flex;
  gap: 5px;
  width: 100%;
  align-items: center;
  .progressBarEmptyContainer {
    width: 100%;
    height: 10px;
    background-color: #f0f0f0;
    border-radius: 100px;
    .progressBarFill {
      max-width: 100% !important;
      height: 10px;
      border-radius: 100px;
      transition: width 0.3s ease, background-color 0.3s ease;
    }
  }
}
