.dateTypePickerContainer {
  width: 570px;
  margin: 0 0 10px 10px !important;
  margin-bottom: 10px;
  height: 40px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 1px 4px #00000029;
  padding-right: 20px;
  transition: width 0.5s, margin 0.5s;

  .dueDateContainer {
    margin-left: 20px;

    .ant-picker {
      border: 1px solid #f5f5f7;
      border-radius: 5px;
    }
  }

  .monthPicker {
    border-radius: 5px;
    margin-right: 10px;
  }

  .monthPickerUnSelected {
    border-radius: 5px;
    margin-right: 10px;
    background: white;
    color: #2095ff;
  }

  .customPicker {
    border-radius: 5px;
  }

  .customPickerUnSelected {
    border-radius: 5px;
    background: white;
    color: #2095ff;
  }
}
