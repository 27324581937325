@use "src/index.scss" as index;

.audit-report-modal {
  height: fit-content !important;
  width: fit-content !important;
  max-height: calc(100vh - 20px);
  max-width: calc(100vw - 20px);
  .ant-modal-content {
    max-height: 700px !important;
    max-width: 900px;
    width: calc(100vw - 20px) !important;
    height: calc(100vh - 20px) !important;

    .ant-modal-close {
      height: 45px;
      width: 45px;
      .ant-modal-close-x {
        height: 45px;
        width: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .ant-modal-header {
      height: 45px;
      display: flex;
      align-items: center;
      background-color: #f8f8fa;
    }
    .ant-modal-body {
      height: calc(100% - 97px);
      width: 100%;

      .report-modal-grid {
        height: 100%;
        width: 100%;

        .ag-root-wrapper-body.ag-layout-normal {
          height: 100% !important;
          min-height: 100% !important;
          overflow-y: unset !important;
        }

        .card-body {
          .filters-container {
            background-color: #fff;
          }

          .ant-switch-checked {
            background: #68c142 !important;
          }

          .ant-switch-inner-unchecked {
            transform: translateY(-22px);
          }

          .writeReadSwitchContainer {
            background-color: #1264a3;
            width: 140px;
            height: 23px;
            font-size: 13px;

            .ant-switch-handle {
              padding: 2px;
              background-color: white;
              border-radius: 9px;
            }

            .ant-switch-inner-checked {
              font-size: 13px !important;
            }
            .ant-switch-inner-unchecked {
              font-size: 13px !important;
            }
          }
          .ag-root-wrapper {
            overflow-y: auto !important;
          }

          .ag-root-wrapper-body.ag-layout-normal {
            height: calc(100% - 48px) !important;
            overflow-y: auto;
          }

          .ag-paging-panel {
            min-height: 48px !important;
          }

          .audit-grid-status {
            width: 100%;
            // height: 75%;
            height: 30px;
            display: grid;
            place-content: center;
            max-width: 200px;
            border-radius: 5px;
            font-weight: 600;
            color: #fff;
            vertical-align: middle;
          }

          .locations-select-editor {
            .ant-select {
              width: 100%;
            }
          }
        }
      }

      .report-modal-grid.confirmation-grid {
        height: calc(100% - 32px);
      }

      .confirmation-info {
        height: 32px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
        gap: 10px;
      }
    }
    .ant-modal-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      flex-wrap: nowrap;
      width: 100%;
    }
  }
}

.audit-report-modal.audit-report-modal-dark {
  .ant-modal-content {
    background-color: index.$mainDarkBackgroundColor;
    .ant-modal-close .ant-modal-close-x svg {
      fill: #fff !important;
    }
    .ant-modal-header {
      background-color: index.$cardDarkBackgroundColor;
      .ant-modal-title {
        color: #fff;
      }
    }

    .ant-modal-body {
      .report-modal-grid.grid-template-card-dark {
        .card-body {
          .filters-container {
            background-color: index.$mainDarkBackgroundColor;
          }

          .writeReadSwitchContainer {
            background-color: #292b3a;
            width: 140px;
            height: 23px;
          }
        }
      }

      .confirmation-info {
        color: #fff;
      }
    }
  }
}
