.CardContentWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: 0.3s ease-in-out;
  border-radius: 10px;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15);
  overflow: hidden;

  .CardContentWrapper-header {
    height: 45px;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: 700;
    background-color: #f8f8fa;
    border-radius: 10px 10px 0px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    transition: 0.2s ease-in;
    .header-left {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #323338;
      p {
        margin: 0;
      }
    }
    .header-right {
      display: flex;
      flex-direction: row;
      gap: 8px;
    }
  }
  .CardContentWrapper-content {
    height: 100%;
    overflow: auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 0 0 10px 10px;
    transition: 0.2s ease-in;
  }

  &.collapsed {
    .CardContentWrapper-content {
      display: none !important;
    }
  }

  &.dark-mode {
    background-color: #2a2b3a;
    .CardContentWrapper-header {
      background-color: inherit;
      .header-left {
        color: #fff;
      }
    }
    .CardContentWrapper-content {
      background-color: #12131b;
    }
  }
}
