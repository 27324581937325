.automaticSchedulingPickerContainer {
  border-radius: 10px;
  width: 700px;
  height: 320px;
  display: flex;
  gap: 14px;

  .schedulingPickerContainer {
    width: 343px;
    border-radius: 10px;
    height: 320px;
    box-shadow: 0px 1px 4px #00000029;
    padding: 15px 10px;

    .tittle {
      font-weight: bold;
      font-size: 18px;
      margin: 0 0 5px 5px;
    }

    .typeOfWorkContainer {
      display: flex;
      flex-direction: column;
      .information {
        display: flex;
        gap: 4px;
        .installation {
          color: #71cf48;
          margin-left: 3px;
        }
        .removal {
          color: #fe4c4a;
          margin-left: 3px;
        }
      }
      .dateContainer {
        display: flex;
        .haveCollision {
          color: #fe4c4a;
        }
        .dontHaveCollision {
          color: #71cf48;
        }
      }
    }
  }

  .servicePickerContainer {
    width: 343px;
    box-shadow: 0px 1px 4px #00000029;
    border-radius: 10px;
    height: 320px;
    padding: 15px;
    overflow-x: hidden;
    overflow-y: auto;

    .tittle {
      font-weight: bold;
      font-size: 18px;
      margin: 0 0 5px 5px;
    }
  }
}
