.function-picker-container {
  position: relative;
}

.function-picker-container .function-picker-modal {
  position: absolute;
  z-index: 10;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.function-button {
  display: flex;
  align-items: center; /* vertically center the contents */
  justify-content: center; /* center the content horizontally */
  position: relative; /* set positioning context */
  border: 1px solid #a5d6f4;
  border-radius: 5px;
  width: 205px;
  background-color: #ebf4f9;
  transition: box-shadow 0.2s;
  padding: 8px 40px 8px 8px; /* added right padding to account for the icon space */
}

.button-icon {
  position: absolute;
  right: 10px; /* adjust as needed */
  top: 50%;
  transform: translateY(-50%); /* centers the icon vertically */
}

.function-button:active {
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
}

.searchInput {
  margin: 5px 5px 0 5px;
}

.lists {
  margin-top: -15px;
  overflow: auto;
  max-height: 300px;
  width: 100%;
}
