@import "../../../../../../index.scss";

.dobStepContainer {
  $completed-color: #32c672;

  .controlPanel {
    @include flex(center, center);
    width: 100%;
  }

  .inputsContainer {
    @include flex(baseline, flex-start);
    margin-right: 30px;
  }
  .buttonsContainer {
    @include flex(baseline, flex-start);
    .ant-btn {
      margin-right: 30px;
    }
  }

  .labeledInputContainer {
    margin-right: 15px;
  }

  .violationsPanel {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
  }

  .searchBtn {
    @include flex(baseline, center);
  }

  .verifyBtn {
    background-color: $completed-color;
    border-color: $completed-color;
  }
}
