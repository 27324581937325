.boarding-header {
  position: relative;
  height: 120px;
  border-radius: 10px;
  overflow: hidden;

  .header-left {
    padding: 25px 0;
    position: absolute;
    top: 0;
    left: 40px;
    color: #ffff;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    display: flex;
    flex-direction: column;
    .name-of-user {
      // height: 45px;
      font-size: 30px;
      font-weight: 600;
    }
    .description {
      // height: 25px;
      font-size: 16px;
      font-weight: 400;
    }
  }

  img {
    height: 100%;
    width: 100%;
  }

  .header-right {
    padding: 28px 0;
    position: absolute;
    top: 0;
    right: 40px;
    height: 100%;
    display: flex;
    align-items: center;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(31, 42, 68, 0.85);
    pointer-events: none;
  }

  &.dark-mode {
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(18, 19, 27, 0.85);
      pointer-events: none;
    }
  }
}

@media (max-width: 460px) {
  .boarding-header {
    height: 60px;
    .header-right {
      padding: 5px 0;
      right: 10px;
      svg {
        width: 120px;
      }
    }

    .header-left {
      padding: 5px 0;
      left: 10px;
      .name-of-user {
        font-size: 16px;
      }
      .description {
        font-size: 8px;
      }
    }
  }
}
