.priceCalculationInput-select-popover {
  z-index: 2342424;
  position: absolute;
  top: 0;
  left: 0;
  //   width: 100%;
  //   background-color: darkcyan;
  .ant-popover-inner-content {
    background-color: darkcyan;
    color: white;
    border-radius: 10px;
  }
}
