.forecastingCards {
  display: flex;
  flex-direction: row;
  gap: 20px;
  .forecastingCardData {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 136px;
    height: 87px;
    background-color: #f4f5f6;
    border-radius: 5px;
  }
}
