$cardModalBodyDark: #1f212d;

.saveScheduleModal {
  .ant-modal-header {
    width: 510px;
    height: 80px;
    background: #71cf48;
    border-radius: 10px 10px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-modal-title {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-modal-content {
    width: 510px;
    // height: 260px;
    background: #ffffff;
    border-radius: 10px;
  }
  .main-container-schedule-save-modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 7px;
    gap: 10px;
  }
  .main-text-schedule-save-modal {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: bolder;
    font-size: 16px;
    color: #323338;
    margin: 0px;
  }
  .second-text-schedule-save-modal {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    color: #323338;
  }
  .footer-container-schedule-save-modal {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: center;
    align-items: center;
  }
}

.saveScheduleModalDark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-header {
      .ant-modal-title svg path {
        fill: $cardModalBodyDark;
      }
    }
    .ant-modal-body {
      background-color: $cardModalBodyDark;
      .main-container-schedule-save-modal span {
        color: #fff;
      }
    }
  }
}
