$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.newInventoryItemModal {
  width: fit-content !important;

  button {
    &:disabled {
      color: rgba(0, 0, 0, 0.25) !important;
      background-color: rgba(0, 0, 0, 0.04) !important;
      .mondayButtonDivider {
        background-color: transparent !important;
      }
    }
  }

  .ant-modal-content {
    border-radius: 10px;
    width: calc(100vw - 20px);
    max-width: 700px;

    .ant-modal-close {
      height: 45px;
      width: 45px;
      .ant-modal-close-x {
        height: 45px;
        width: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          fill: #323338;
        }
      }
    }

    .footerButtonsContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.3rem;
      flex-direction: row;
    }

    .footerButton {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      flex-wrap: nowrap;
      gap: 0.5rem;
      border-radius: 5px;
      color: white;
    }

    .ant-modal-header {
      height: 45px;
      background-color: #f7f8fa;
      border-radius: 10px 10px 0 0;
      display: flex;
      align-items: center;
      .ant-modal-title {
        color: #323338;
        font-size: 1rem;
        font-weight: 550;
        width: 100%;
      }
    }

    .ant-modal-body {
      height: fit-content;
      width: 100%;
      margin: 0;

      .ant-form {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;
        column-gap: 1rem;

        .inventoryAdditionalItems {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          flex-direction: column;
          max-height: 10rem;
          overflow: auto;
          gap: 1rem;
          width: 100%;
          margin-top: 0.5rem;

          .inventoryAddAdditionalItem {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: row;
            flex-wrap: nowrap;
            gap: 1rem;
            width: 100%;

            .actionButton {
              height: 100% !important;
              display: flex;
              justify-content: center;
              align-items: center;
              margin: 0;

              button {
                transform: translateY(80%);
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }
        }
      }
    }

    .ant-modal-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
}

.newInventoryItemModalDark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      .labeledInputLabel {
        color: #fff;
      }
      .ant-input {
        background-color: $inputModalDark !important;
        color: #fff;
        &::placeholder {
          color: #acacca;
        }
      }
      .ant-input-disabled {
        color: #acacca;
      }
      .ant-select {
        .ant-select-selector {
          background-color: $inputModalDark !important;
          .ant-select-selection-search-input {
            color: #fff;
          }
          .ant-select-selection-item {
            color: #fff;
          }
          .ant-select-selection-placeholder {
            color: #acacca;
          }
        }
        .ant-select-clear {
          background-color: $inputModalDark;
          svg {
            fill: #acacca;
          }
        }
        .ant-select-arrow svg {
          fill: #fff;
        }
      }
      .ant-select-disabled {
        .ant-select-selector {
          .ant-select-selection-search-input {
            color: #acacca !important;
          }
          .ant-select-selection-item {
            color: #acacca !important;
          }
        }
      }
      .ant-input-wrapper {
        background-color: $inputModalDark !important;
        .ant-input-group-addon {
          background-color: $inputModalDark !important;
          color: #fff;
        }
      }
      .tox {
        border: none;
        .tox-menubar {
          background-color: $cardModalHeaderDark;
          border-radius: 5px 5px 0px 0px;
          .tox-mbtn__select-label {
            color: #fff;
          }
        }
        .tox-mbtn--select:hover {
          background-color: $cardModalBodyDark;
        }
        .tox-toolbar-overlord {
          background-color: $cardModalHeaderDark !important;
          .tox-toolbar__primary {
            background-color: $cardModalHeaderDark !important;
          }
          .tox-toolbar__group {
            background-color: $cardModalHeaderDark !important;
            border: none;
            .tox-split-button:hover {
              border: none;
            }
            .tox-tbtn {
              svg {
                fill: #fff;
              }
              &:focus {
                background-color: $cardModalBodyDark;
              }
              &:hover {
                background-color: $cardModalBodyDark;
              }
            }
            .tox-tbtn__select-label {
              color: #fff !important;
            }
          }
        }
      }
      .mondayButtonBlue:disabled {
        background-color: rgba(0, 0, 0, 0.2) !important;
        color: #acacca !important;
      }
    }
  }
}
