$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.dashboard-list-card-container {
  border-radius: 10px;
  cursor: default;
  background-color: #fff;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.1);

  .dashboard-list-jobs {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .ant-card-meta-avatar {
    .InitialsContainer {
      cursor: pointer;
      border-radius: 50%;
      height: 55px;
      min-width: 55px;
      display: flex;
      align-items: center;
      justify-content: center;
      .initialsStyle {
        text-align: left;
        font: normal normal 600 35px/47px Open Sans;
        letter-spacing: 0px;
        opacity: 1;
      }
    }
  }

  .ant-card-head {
    min-height: 45px;
    background-color: #f5f5f7;
  }

  .ant-card-body {
    height: calc(100% - 45px);
  }

  .list-card-container {
    height: 100%;

    .ant-card-body {
      height: calc(100% - 56px) !important;
      padding: 0;
      overflow: hidden;
    }

    .ant-card-body {
      height: 100%;

      .ant-table-wrapper {
        height: 100%;

        .ant-spin-nested-loading {
          height: 100%;

          .ant-spin-container {
            height: 100%;

            .ant-table {
              height: 100%;
              border-top-left-radius: 0;
              border-top-right-radius: 0;

              .ant-table-container {
                height: 100%;

                .ant-table-content {
                  table {
                    tbody {
                      max-height: 100%;
                      overflow: auto;
                    }

                    .ant-table-placeholder {
                      border: 0;
                      border-bottom-color: transparent !important;

                      .ant-table-cell {
                        border: 0;
                        border-bottom: 0 !important;

                        &:hover {
                          background-color: transparent;
                        }
                      }
                    }

                    .ant-table-cell-row-hover {
                      background-color: transparent;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.list-card-container-dark {
  background-color: #2a2b3a;
  color: #fff;

  .ant-card-meta-title,
  .ant-card-meta-description {
    color: #fff;
  }

  .ant-card {
    background: #2a2b3a;
  }

  .ant-card-head {
    background-color: #12131b;
    color: #fff;
  }

  .ant-card-body {
    background-color: #2a2b3a;
    color: #fff;
  }

  .ant-table-container {
    background-color: #2a2b3a !important;
  }

  .ant-table {
    background-color: #2a2b3a !important;
    border-top: 1px solid white;

    .ant-table-thead {
      th {
        background-color: #2a2b3a !important;
        color: #fff;
      }
    }

    td {
      color: #fff;
    }

    .ant-table-tbody {
      height: 100%;
      background-color: #2a2b3a;
    }

    .ant-table-placeholder {
      background-color: #2a2b3a !important;

      .ant-table-cell {
        background-color: #2a2b3a !important;
        border-bottom: 0 !important;

        &:hover {
          background-color: #272a3a !important;
        }
      }

      &:hover {
        background-color: #272a3a !important;
      }
    }

    .ant-table-row.ant-table-row-selected {
      background-color: #12131b;
      .ant-table-cell {
        background-color: #12131b;
      }
      &:hover {
        .ant-table-cell {
          background-color: #12131b !important;
        }
      }
    }

    .ant-table-cell-row-hover {
      background-color: #272a3a !important;
    }

    .ant-table-cell {
      background-color: transparent;
      color: #fff;
      &:hover {
        background-color: #272a3a !important;
      }
    }

    .ant-empty-description {
      color: rgba(255, 255, 255, 0.4);
    }
  }

  .ant-pagination {
    .ant-pagination-prev,
    .ant-pagination-next {
      .ant-pagination-item-link {
        color: #fff;
      }
    }
    .ant-pagination-disabled {
      .ant-pagination-item-link {
        color: #acacca;
      }
    }
    .ant-pagination-item a {
      color: #fff;
    }
    .ant-pagination-item-ellipsis {
      color: #fff;
    }
    .ant-pagination-item-active {
      background-color: $cardModalBodyDark;
    }
  }
}
