.htmlTableContent {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  gap: 10px;
  .htmlTableContentSearchContent {
    display: flex;
    justify-content: flex-end;
    .htmlTableContentSearchInput {
      width: 250px;
      border: none;
      border-radius: 5px;
      background-color: #f2f2f2;
    }
  }
}
