.live-map-view {
  height: calc(100vh - 56px);
  transition: width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;

  .map-control {
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    flex: 1;
    transition: width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;

    .inner-map {
      height: 100%;
      width: 100%;
      margin-top: -100px;
      transition: width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;

      .live-map {
        width: 100%;
        height: calc(100vh - 106px);
      }

      #live-map {
        .gm-style-iw.gm-style-iw-c {
          top: -18px;
          padding: 0 !important;
          .gm-style-iw-d {
            padding: 5px;
            overflow: auto !important;
          }
          button {
            display: none !important;
          }
        }

        .gm-style-iw-ch {
          display: none;
        }

        .gm-style-iw-tc {
          top: -20px;
          width: 18px;
          &::after {
            width: 18px;
          }
        }
      }
    }
  }
}
