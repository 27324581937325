.headerText {
  text-wrap: nowrap;
  min-width: 250px;
  font: 14px;
  line-height: 19px;
}

.attachmentsContent {
  flex-direction: column;
  padding: 10px;
  background: #ffffff;
  border-radius: 5px;
  gap: 10px;
  max-width: 300px;
}

.attachmentsBody {
  flex-wrap: wrap;
  align-items: center;
  align-content: flex-start;
  padding: 0px;
  gap: 10px;
}

.attachmentsHeader {
  align-items: center;
  gap: 10px;
}

.infoIcon {
  width: 16px;
  height: 16px;
  fill: #1264a3;
}

.popOverContainer {
  max-width: 300px;
}
