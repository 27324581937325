$inputModalDark: #12131b;

.EditDatePicker {
  .EditDatePickerComponent {
    width: 350px;
    height: 33px;
    background: #f5f5f7 0% 0% no-repeat padding-box;
    opacity: 1;
    border-radius: 5px;
    border: 0px;
  }
}

.EditDatePickerDark {
  .ant-picker {
    background-color: $inputModalDark !important;
    .ant-picker-input {
      input {
        color: #f2f2f2;
        &::placeholder {
          color: #acacca;
        }
      }
      .ant-picker-suffix svg path {
        fill: #f2f2f2 !important;
      }
    }
  }
}

.dateDropDownDark {
  .ant-picker-header {
    button {
      color: #acacca;
    }
  }
  .ant-picker-header-view button {
    color: #f2f2f2 !important;
  }
  .ant-picker-panel-container {
    background-color: $inputModalDark;
    .ant-picker-panels {
      .ant-picker-panel {
        .ant-picker-date-panel {
          .ant-picker-header {
            color: #f2f2f2;
            .ant-picker-header-super-prev-btn,
            .ant-picker-header-prev-btn,
            .ant-picker-header-next-btn,
            .ant-picker-header-super-next-btn {
              color: #acacca;
            }
          }
        }
      }
    }
  }
  .ant-picker-body .ant-picker-content {
    th {
      color: #f2f2f2 !important;
    }
    .ant-picker-cell {
      color: #acacca !important;
    }
    .ant-picker-cell-in-view {
      color: #f2f2f2 !important;
    }
  }
}
