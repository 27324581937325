@use "/src/index.scss" as index;

.payroll-live-control-panel {
  width: 100%;
  height: 56px;
  padding: 0px 0px 0px 10px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dee2eb;
  .title-section {
    height: 100%;
    width: 190px;
    display: flex;
    align-items: center;
    border-right: 1px solid #dee2eb;
    .menu-icon {
      height: 80%;
      aspect-ratio: 1;
      transition: background-color 0.2s ease-in-out;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        height: 20px;
        width: 20px;
        fill: #000;
      }
      &:hover {
        cursor: pointer;
        background-color: rgba(60, 60, 77, 0.1);
      }
    }
    .title-text {
      font-family: Open Sans;
      font-weight: 800;
      margin-right: 1rem;
      margin-left: 1rem;
      white-space: nowrap;
      font-size: 1.1rem;
      letter-spacing: 0.28px;
      cursor: default;
    }
  }
  .control-section {
    width: calc(100% - 190px);
    min-width: calc(100% - 190px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding: 0px 10px;
    .today-date {
      height: 32px;
      min-width: 150px;
      display: flex;
      border-radius: 5px;
      align-items: center;
      // gap: 5px;
      margin-right: 20px;
      // .label {
      //   font-weight: 600;
      // }
      .ant-picker {
        background-color: #fff !important;
      }
      &::after {
        content: "";
        height: 15px;
        width: 2px;
        margin-left: 10px;
        background-color: #dee2eb;
      }
    }
    .time-range {
      display: flex;
      align-items: center;
      gap: 10px;
      .time-input-wrapper {
        background-color: #fff;
        .ant-input {
          background-color: #fff !important;
        }
        .day-period-container {
          background-color: #fff;
        }
        .ant-segmented-item-selected {
          background-color: #f4f5f6;
          box-shadow: none;
        }
        .ant-segmented-thumb,
        .ant-segmented-thumb-motion-appear,
        .ant-segmented-thumb-motion,
        .ant-segmented-thumb-motion-appear-active {
          background-color: #f4f5f6;
        }
      }
      &::after {
        content: "";
        height: 15px;
        width: 2px;
        margin-left: 10px;
        background-color: #dee2eb;
      }
    }
    .upload-btn {
      margin-left: 10px;
    }
    .ant-form-item {
      margin-bottom: 0px;
    }
    .control-panel-form {
      // width: calc(100% - 550px);
      display: flex;
      align-items: center;
      overflow-y: auto;
      .hover-button-container {
        display: flex;
        align-items: center;
        &::after {
          content: "";
          height: 15px;
          width: 2px;
          margin-left: 10px;
          background-color: #dee2eb;
        }
      }
      .active-filters {
        .button-inner-text {
          color: index.$red !important;
        }
      }
      .labeledInputContainer {
        display: flex;
        gap: 5px;
        min-width: fit-content;

        .labeledInputLabel {
          font-size: 14px;
          margin-bottom: 0px;
          display: flex;
          align-items: center;
        }
        .ant-select {
          min-width: 300px;
          .ant-select-selector {
            background-color: #fff !important;
          }
        }
      }
      .custom-time-error .ant-row .ant-col {
        height: 0px !important;
      }
    }
    .clear-time-btn {
      height: 20px;
      width: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      background-color: #fff;
      border-radius: 50%;
    }
    .buttons-wrapper {
      display: flex;
      align-items: center;
      gap: 10px;

      .full-screen-btn {
        padding: 0px 5px !important;
      }
      .back-btn {
        padding: 0px 7px !important;
      }

      .mondayButtonWhite {
        @include index.mondayButton(#fff, #e6e9ef, #323338);
      }

      .loading-button {
        border: none;
        height: 32px;
        width: 150px;
        padding: 0px;
        border-radius: 5px;
        background-color: #fff;
        overflow: hidden;
        &:hover {
          background-color: #e6e9ef;
        }
        &:disabled {
          cursor: not-allowed;
        }
        .progress {
          height: 32px;
          width: 0px;
        }
        .btn-content {
          position: relative;
          left: 0px;
          top: -24px;
          font-size: 14px;
        }
        .ant-statistic {
          position: relative;
          top: -32px;
          left: 0px;
          height: 32px !important;
          .ant-statistic-content {
            height: 32px !important;
            display: flex;
            align-items: center;
            justify-content: center;
            .ant-statistic-content-value {
              font-size: 16px;
            }
          }
        }
      }

      .animation {
        background-color: #fff !important;
        .progress {
          background-color: #e6e9ef !important;
          animation: backgroundLoading 300s ease-out;
        }
      }
    }
    .last-updated-reports {
      min-width: 150px;
      text-align: center;
      cursor: default;
    }
  }
}

.payroll-live-control-panel-dark {
  background-color: index.$cardDarkBackgroundColor;
  border-bottom: 1px solid #616481;
  .title-section {
    border-right: 1px solid #616481;
    .menu-icon {
      svg {
        fill: #fff;
      }
      &:hover {
        background-color: #3c3c4d;
      }
    }
    .title-text {
      color: #fff;
    }
  }
  .control-section {
    .today-date {
      color: #fff;
      .ant-picker {
        background-color: index.$inputDarkColor !important;
      }
      &::after {
        background-color: #acacca;
      }
    }
    .control-panel-form {
      .labeledInputContainer {
        .ant-select {
          .ant-select-selector {
            background-color: index.$inputDarkColor !important;
          }
        }
      }
      .hover-button-container::after {
        background-color: #acacca;
      }
    }
    .time-range {
      display: flex;
      align-items: center;
      gap: 10px;
      .time-input-wrapper {
        background-color: index.$inputDarkColor !important;
        .ant-input {
          background-color: index.$inputDarkColor !important;
        }
        .day-period-container {
          background-color: index.$inputDarkColor !important;
        }
      }
      &::after {
        background-color: #acacca;
      }
    }
    .clear-time-btn {
      background-color: index.$mainDarkBackgroundColor;
      svg {
        fill: #fff;
      }
    }
    .buttons-wrapper {
      .loading-button {
        color: #fff;
        background-color: index.$blue;
        &:hover {
          background-color: #0f5c97;
        }
        &:disabled {
          color: #e2e2e2 !important;
        }
        .ant-statistic .ant-statistic-content .ant-statistic-content-value {
          color: #fff;
        }
      }

      .animation {
        background-color: index.$blue !important;
        .progress {
          background-color: #0f5c97 !important;
          animation: backgroundLoading 300s ease-out;
        }
      }
    }
    .last-updated-reports {
      color: #fff;
    }
  }
}

.popup-filter {
  padding: 0px !important;
  .ant-popover-inner-content {
    padding: 0px 10px !important;
    .ant-popconfirm-message-icon {
      display: none;
    }
    .ant-popconfirm-title {
      font-size: 14px;
      padding: 0px 10px;
      height: 24px;
      background-color: #f4f5f6;
      border-radius: 5px 5px 0px 0px;
    }
    .ant-popconfirm-description {
      padding: 5px;
      padding-bottom: 10px;
    }
    .ant-popconfirm-buttons {
      display: none;
    }
  }
  .time-range {
    display: flex;
    align-items: center;
    gap: 10px;
    .clear-time-btn {
      height: 20px;
      width: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      background-color: #f4f5f6;
      border-radius: 50%;
    }
  }
  .ant-form {
    gap: 10px;
  }
  .ant-form-item {
    margin-bottom: 0px;
  }
}

.popup-filter-dark {
  .ant-popover-arrow {
    &::before,
    &::after {
      background-color: index.$mainDarkBackgroundColor;
    }
  }
  .ant-popover-inner-content {
    background-color: index.$mainDarkBackgroundColor;
    box-shadow: 0px 1px 4px 0px #aaa5a74d;
    border-radius: 5px;
    .ant-select {
      .ant-select-selector {
        background-color: index.$inputDarkColor !important;
        .ant-select-selection-search-input {
          color: #fff;
        }
        .ant-select-selection-item {
          color: #fff;
        }
        .ant-select-selection-placeholder {
          color: #acacca;
        }
      }
      .ant-select-clear {
        background-color: index.$inputDarkColor;
        svg {
          fill: #acacca;
        }
      }
      .ant-select-arrow svg {
        fill: #fff;
      }
    }
    .ant-popconfirm-description {
      .time-range {
        display: flex;
        align-items: center;
        gap: 10px;
        .time-input-wrapper {
          background-color: index.$inputDarkColor !important;
          .ant-input {
            background-color: index.$inputDarkColor !important;
          }
          .day-period-container {
            background-color: index.$inputDarkColor !important;
          }
        }
        &::after {
          background-color: #acacca;
        }
      }
      .clear-time-btn {
        background-color: index.$cardDarkBackgroundColor;
        svg {
          fill: #fff;
        }
      }
    }
  }
}

@media (max-width: 1400px) {
  .payroll-live-control-panel
    .control-section
    .control-panel-form
    .hover-button-container {
    display: flex;
    align-items: center;
    padding-left: 0px;
    .button-inner-text.type-action {
      color: index.$blue;
    }
    .button-inner-text.type-decline {
      color: index.$red;
    }
  }
}

@media (max-width: 1180px) {
  .payroll-live-control-panel {
    padding: 0px;
    .title-section {
      width: 130px;
    }
    .control-section {
      width: calc(100% - 130px);
      .today-date {
        margin-right: 10px;
      }
      .control-panel-form {
        font-size: 13px;
        width: fit-content;
        .hover-button-container {
          display: flex;
          align-items: center;
          padding-left: 0px;
          .button-inner-text.type-action {
            color: index.$blue;
          }
          .button-inner-text.type-decline {
            color: index.$red;
          }
          &::after {
            margin-left: 5px;
          }
        }
        .labeledInputContainer {
          display: flex;
          gap: 5px;
          min-width: fit-content;

          .labeledInputLabel {
            font-size: 14px;
          }
          .ant-select {
            min-width: 150px;
          }
        }
      }
      .buttons-wrapper {
        gap: 5px;
        .loading-button {
          width: 32px;
        }
      }
    }
  }
}

@keyframes backgroundLoading {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
