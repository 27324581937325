@import "../../../../index.scss";

.labeledIconContainer {
  @include flex(flex-start, space-between);
  border-radius: 5px;
  width: 190px;
  padding: 5px 10px;
  background-color: #38415d;
  gap: 10px;
  margin-right: 3px;

  &:hover {
    background-color: #222a44;
  }
  cursor: pointer;

  .shortcutIcon {
    fill: #fff;
    width: 32px;
    image-rendering: -webkit-optimize-contrast;
  }
  .shortcutLabelContainer {
    @include flex(flex-start, center, column);
  }
  .shortcutTitle {
    @include text(17px, normal, 0, #fff);
  }
  .shortcutSubtitle {
    @include text(12px, normal, 0, #fff);
    opacity: 0.7;
  }
}

.categoryName {
  background-color: transparent;
  cursor: grab;
  :nth-child(n) {
    animation: categoryItems 0.4s;
  }
}

@keyframes categoryName {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(0%);
  }
}
