$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.settingPane.editNewUserAccess {
  width: 100%;
  .bodyContainer {
    padding: 0;
    .rightContainer .card-template {
      box-shadow: none;
      border-radius: 0;
      .card-template-title {
        display: none;
      }
      .card-template-body {
        padding: 0;
        width: 100%;
        .info-and-search {
          justify-content: end;
          .user-access-info-text {
            display: none !important;
          }
        }
        .collapsedContainer-body {
          border-radius: 0;
          max-height: 310px;
        }
        .ant-form {
          max-height: 350px;
          gap: 0px;
        }
      }
    }
  }
}

.editNewUserAccess.review-step .bodyContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  .review-step-user-info {
    display: flex;
    gap: 50px;

    .row-text {
      display: flex;
      gap: 10px;
      align-items: center;
      > span:first-child {
        height: 16px;
      }
      svg {
        fill: #231f20;
        width: 16px;
        height: 16px;
        path {
          fill: #231f20;
        }
      }
    }
    .user-detail-main {
      h3 {
        font-size: 24px;
        font-weight: 600;
      }
      span {
        font-size: 16px;
        font-weight: 400;
      }
    }
    .user-detail {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
  }
  .rightContainer .card-template .card-template-body {
    .collapsedContainer-header {
      padding-bottom: 20px;
      padding-left: 0;
      padding-right: 0;
    }
    .info-and-search {
      display: none;
    }
  }
}

.editNewUserAccessDark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;
      }
    }
  }
}
