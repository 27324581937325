.audit-initial-plan-card {
  .locations-card-info-container {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0px 10px 10px 10px;

    .ant-timeline {
      padding-top: 20px;
      padding-left: 5px;
    }

    .ant-timeline-item-head {
      top: 18px;
    }

    .ant-timeline-item-tail {
      top: 28px;
    }

    .single-card-location {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      transition: transform 0.3s ease-in-out;
      width: 99%;

      .location-arrive-time {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 0.75rem;
        width: calc(100% - 10px);
        opacity: 0.85;
      }

      .location-arrive {
        font-weight: 400;
        letter-spacing: 0.18px;
      }

      .first-location {
        font-weight: 600;
      }
    }

    .single-card-location.update-enter {
      background-color: rgba(137, 148, 196, 0.3);
      border-radius: 5px;
    }

    .audit-dispatch-container {
      width: 100%;
      border-radius: 5px;
    }

    .edit-mode-hover {
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      &:hover {
        background-color: rgba(137, 148, 196, 0.3);
        .single-card-location {
          transform: scale(1.02);
        }
      }
    }
  }
}

.audit-initial-plan-card.initial-plan-dark {
  .ant-timeline-item-tail {
    border-inline-start: 2px solid #acacca;
  }

  .ant-timeline-item-head {
    background-color: transparent;
  }

  .locations-card-info-container {
    .single-card-location {
      color: #f2f2f2;
    }

    .single-card-location.update-enter {
      background-color: rgba(137, 148, 196, 0.3);
      border-radius: 5px;
    }

    .audit-dispatch-container {
      width: 100%;
      border-radius: 5px;
    }

    .edit-mode-hover {
      transition: all 0.3s ease-in-out;
      cursor: pointer;
      &:hover {
        background-color: rgba(137, 148, 196, 0.3);
        .single-card-location {
          transform: scale(1.02);
        }
      }
    }
  }
}
