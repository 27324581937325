.day-foreman-view {
  height: 100%;
  overflow-y: auto;
  // padding-right: 10px;
  display: flex;
  flex-direction: column;
  gap: 50px;
  .service-section {
    display: flex;
    padding-right: 10px;
    flex-direction: column;
    gap: 10px;
    .service-section-header {
      display: flex;
      flex-direction: column;
      .service-section-header-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title {
          color: #323338;
          font-size: 18px;
          font-weight: 600;
        }
        .type-of-work {
          color: #acacac;
          font-size: 14px;
        }
      }
    }
    .elevation-collapse {
      .ant-collapse-header {
        .ant-collapse-header-text {
          width: 80%;
          // white-space: nowrap;
          // overflow: hidden;
          // text-overflow: ellipsis;
          font-size: 16px;
          font-weight: 400;
          color: #323338;
        }
      }
      .ant-collapse-content-box {
        padding: 0;
        .elevation-collapse-progress {
          display: flex;
          padding: 10px;
          padding-top: 0px;
          justify-content: flex-start;
          .expand {
            display: flex;
            align-items: center;
            font-size: 16px;
            font-weight: 600;
            color: #1264a3;
          }
        }
        .ag-root-wrapper-body.ag-layout-normal {
          height: fit-content !important;
        }
        .ag-header-cell {
          padding: 0 5px;
          .ag-header-cell-comp-wrapper
            .ag-cell-label-container
            .ag-header-cell-label {
            justify-content: center;
          }
        }
        .ag-cell {
          padding: 0 5px;
          justify-content: center;
        }
        .ag-icon-menu {
          display: none;
        }
      }
    }
  }
}
