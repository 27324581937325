.maintenance-controller {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.1);
  overflow-x: auto;
  overflow-y: hidden;
  gap: 10px;
  background-color: #fff;
  .ant-form-item {
    margin-bottom: 0;
  }

  .controller-left-form,
  .controller-right-form {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 10px;
  }

  .controller-left-form {
    justify-content: flex-start;
  }

  .controller-right-form {
    justify-content: flex-end;
  }
}

.maintenance-controller-dark {
  background-color: #12131b;
  box-shadow: unset;
}
