.notes-editor-popover-overlay {
  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-inner {
    .ant-popover-inner-content {
      padding: 0;

      .ant-form-item {
        margin: 0;

        .ant-form-item-control-input-content {
          height: fit-content;

          textarea {
            height: unset;
            min-height: 80px;
          }
        }
      }
    }
  }
}
