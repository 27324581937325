.stop-modal-container {
  height: fit-content !important;
  width: fit-content !important;

  button {
    &:disabled {
      color: rgba(0, 0, 0, 0.25) !important;
      background-color: rgba(0, 0, 0, 0.04) !important;
      .mondayButtonDivider {
        background-color: transparent !important;
      }
    }
  }

  .ant-modal-content {
    width: calc(100vw - 20px) !important;
    height: calc(100vh - 20px) !important;
    max-height: 900px !important;
    max-width: 1400px;

    .ant-modal-close {
      height: 45px;
      width: 45px;
      .ant-modal-close-x {
        height: 45px;
        width: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          fill: #333238;
        }
      }
    }

    .ant-modal-header {
      height: 45px;
      display: flex;
      align-items: center;
      background-color: #f5f5f7;
      .ant-modal-title {
        font-weight: 600;
        font-size: 16px;
        color: #333238;
      }
    }

    .ant-modal-body {
      height: calc(100% - 100px);
      width: 100%;
      display: flex;
      padding: 0;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: row;
      min-height: 30dvh;
      flex-wrap: wrap;

      .default-data-container {
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        transition: width 0.3s ease-in-out;
        cursor: default;
        overflow: hidden;

        .default-data-title {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          height: 50px;
          font-size: 1.1rem;
          white-space: nowrap;
          width: 100%;
          border-bottom: 1px solid #dee2eb;
          padding: 0 20px;
        }

        .default-data-list {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          overflow-x: hidden;
          overflow-y: auto;
          gap: 10px;
          padding-top: 10px;
          .default-data {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            width: 90%;

            .data-value {
              font-size: 0.9rem;
            }
          }
        }
      }

      .default-data-container.data-expanded {
        border-right: 1px solid #dee2eb;
        width: 20%;

        .default-data-title {
          padding-left: 10px;
        }

        .default-data-list {
          padding-left: 10px;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          overflow-y: auto;
          overflow-x: hidden;
          flex-direction: column;
        }
      }

      .default-data-container.data-collapsed {
        width: 0;

        .default-data-title {
          display: none;
        }

        .default-data-list {
          display: none;
        }
      }

      .nearest-geofences {
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        width: 30%;
        border-right: 1px solid #dee2eb;

        .nearest-title {
          height: 50px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          cursor: default;
          font-weight: 650;
          letter-spacing: 0.28px;
          width: 100%;
          font-size: 1.1rem;
          border-bottom: 1px solid #dee2eb;
          padding: 0px 20px;
          gap: 10px;

          .side-arrow {
            height: 80%;
            aspect-ratio: 1;
            transition: background-color 0.2s ease-in-out;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;

            &:hover {
              cursor: pointer;
              background-color: rgba(60, 60, 77, 0.1);
            }
          }

          .view-title {
            width: -moz-fill-available;
            width: -webkit-fill-available;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        }

        .nearest-search {
          padding: 0.5rem 0 0.5rem 5%;
          height: 50px;
          width: 100%;
          border-bottom: 1px solid #dee2eb;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .ant-input-affix-wrapper {
            height: 32px;
            border: 0;
            background-color: #f5f5f7;

            input {
              background-color: #f5f5f7;
            }
          }
        }

        .fences-section {
          width: 100%;
          height: calc(100% - 50px) !important;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          flex-direction: column;
          overflow-x: hidden;
          overflow-y: auto;

          .fence-info {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: row;
            flex-wrap: nowrap;
            padding: 10px 10px;
            border-bottom: 1px solid #dee2eb;
            cursor: pointer;
            gap: 10px;

            .fence-distance {
              white-space: nowrap;
            }
          }

          .fence-info.location-selected {
            background-color: #f1f5fd;
          }
        }
      }

      .stop-map-section {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        min-width: 40%;
        height: 100%;
        flex: 1;

        .stop-map-controller {
          width: 100%;
          height: 50px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 10px;
          border-bottom: 1px solid #dee2eb;
          padding-right: 10px;
          overflow-x: auto;
          overflow-y: hidden;

          .locations-input {
            min-width: 300px;
            .ant-select {
              width: 100%;
            }
          }

          .controller-left {
            padding-left: 10px;
          }

          .controller-right {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            flex-direction: row;
            flex-wrap: nowrap;
            gap: 10px;
          }
        }

        #stop-map {
          width: 100%;
          flex: 1;

          .gm-style-iw.gm-style-iw-c {
            top: -18px;
            padding: 0 !important;
            .gm-style-iw-d {
              padding: 5px;
              overflow: auto !important;
            }
            button {
              display: none !important;
            }
          }

          .gm-style-iw-ch {
            display: none;
          }

          .gm-style-iw-tc {
            top: -20px;
            width: 18px;
            &::after {
              width: 18px;
            }
          }
        }
      }
    }

    .ant-modal-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      flex-direction: row;
      border-top: 1px solid #dee2eb;
      overflow-x: auto;
      overflow-y: hidden;
      gap: 10px;

      .inner-footer {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-direction: row;
        gap: 1rem;
      }
    }
  }
}

.popover-stop-save-fence {
  .map-fence-form {
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px;

    .labeledInputContainer {
      width: 100%;
    }

    .info-form-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .info-form-button {
        cursor: pointer;
        padding: 3px 10px;
        border-radius: 5px;
        color: #fff;
        transition: background-color 0.3s ease-in-out;
      }

      .info-form-button.form-button-deny {
        background-color: #fe4c4a;
        &:hover {
          background-color: #eb4345;
        }
      }

      .info-form-button.form-button-confirm {
        background-color: #71cf48;
        &:hover {
          background-color: #68c142;
        }
      }
    }
  }
}

.stop-modal-container.stop-modal-dark {
  .ant-modal-content {
    background-color: #1f212d;
    color: #ffffff;

    .ant-modal-header {
      background-color: #12131b;
      .ant-modal-title {
        color: #ffffff;
      }
    }

    .ant-modal-close {
      .ant-modal-close-x {
        svg {
          fill: #ffffff;
        }
      }
    }

    .ant-modal-body {
      border-color: #616481;

      .default-data-container {
        .default-data-title {
          border-bottom: 1px solid #616481;
        }
      }

      .default-data-container.data-expanded {
        border-right: 1px solid #616481;
      }

      .nearest-geofences {
        border-right: 1px solid #616481;

        .nearest-title {
          border-bottom: 1px solid #616481;

          .side-arrow {
            svg {
              fill: #ffffff;
            }

            &:hover {
              cursor: pointer;
              background-color: rgba(165, 165, 174, 0.1);
            }
          }
        }

        .nearest-search {
          border-bottom: 1px solid #616481;

          .ant-input-affix-wrapper {
            border-color: #616481;
            background-color: rgb(0, 0, 0);
            color: #ffffff;

            .ant-input-clear-icon {
              color: #3a3c4e;
            }

            input {
              background-color: rgba(0, 0, 0, 0);
              color: #ffffff;
              &::placeholder {
                color: #ffffffd5;
              }
            }
            &:active {
              border-color: #616481;
            }
            &:hover {
              border-color: #616481;
            }
          }
        }

        .fences-section {
          .fence-info {
            border-bottom: 1px solid #616481;
          }

          .fence-info.location-selected {
            background-color: #41435e;
          }
        }
      }

      .stop-map-section {
        .stop-map-controller {
          .ant-select {
            .ant-select-selector {
              background-color: #3a3c4e !important;
              color: #f5f5f7;
              border: 0;
            }

            .ant-select-selection-placeholder {
              color: #f5f5f7a9;
            }

            .ant-select-clear {
              background-color: #3a3c4e;
              svg {
                fill: #acacca;
              }
            }
          }
          border-bottom: 1px solid #616481;
        }
      }

      #stop-map {
        color: #323338;
      }
    }

    .ant-modal-footer {
      border-color: #616481;
    }
  }
}

.stop-modal-popover-content {
  .popover-form-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
}

.stop-modal-popover-content.popover-dark {
  background-color: #1f212d;
  color: #ffffff;

  --antd-arrow-background-color: #616481;

  border-radius: 5px;

  .ant-popover-content {
    border-radius: 5px;
  }

  .ant-popover-inner-content {
    background-color: #1f212d;
    color: #ffffff;
    border: 1px solid #616481;
    border-radius: 5px;

    .labeledInputLabel {
      color: #fff;
    }

    .ant-input-affix-wrapper {
      background-color: #12131b !important;
      .ant-input-prefix svg {
        fill: #fff;
      }

      .ant-input-clear-icon {
        color: #3a3c4e;
      }
    }
    .ant-input {
      background-color: #12131b !important;
      color: #fff;
      &::placeholder {
        color: #acacca;
      }
    }

    .ant-select-selector {
      background-color: #12131b !important;
      .ant-select-selection-search-input {
        color: #fff;
      }
      .ant-select-selection-item {
        color: #fff;
      }
      .ant-select-selection-placeholder {
        color: #acacca;
      }
    }
    .ant-select-clear {
      background-color: #12131b;
      svg {
        fill: #acacca;
      }
    }
    .ant-select-arrow svg {
      fill: #fff;
    }
  }
}

@media (max-width: 1100px) {
  .stop-modal-container {
    .ant-modal-content {
      .ant-modal-body {
        .nearest-geofences {
          .nearest-title {
            .side-arrow {
              position: relative;
              top: 0;
              left: 0rem;
            }
          }
        }
      }
    }
  }
}
