.newSovModalWrapper .ant-modal-content .ant-modal-body {
  height: auto;
}

.changeRetenagePercentageApplicationModalLight,
.changeRetenagePercentageApplicationModalDark {
  .ant-modal-content {
    border-radius: 10px;
    .ant-modal-header {
      border-radius: 10px 10px 0px 0px;
    }
  }
  .mainBodyContainer {
    display: flex;
    justify-content: center;
    color: #333238;
    height: 100%;
    .no-estimations-container {
      width: 800;
      display: flex;
      justify-content: "center";
      padding: 20px;
      margin: 20px;
      align-items: center;
    }
    .estimationHolder {
      width: 340px;
    }
    .informationHolder {
      width: 340px;
    }
  }
}

@media (max-width: 700px) {
  .changeRetenagePercentageApplicationModalLight,
  .changeRetenagePercentageApplicationModalDark {
    .mainBodyContainer {
      flex-direction: column;
      align-items: center;
    }
  }
}

.changeRetenagePercentageApplicationModalDark
  .mainBodyContainer
  .informationHolder {
  color: #fff;
}

.changeRetenagePercentageApplicationModalDark
  .mainBodyContainer
  .estimationHolder {
  color: #fff;
}
