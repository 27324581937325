$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;

.setProgressButtonWrapper {
  .setProgressButton {
    height: 26px !important;
  }
}

.pli-progress-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto !important;

  .ant-modal-content {
    border-radius: 10px !important;
    min-height: 315px;
    // width: 920px;
    @media only screen and (max-width: 413px) {
      width: auto;
    }
  }
  .ant-modal-header {
    // border-top-left-radius: 5px !important;
    // border-top-right-radius: 5px !important;
    background: #f5f5f7;
    border: none;
    border-radius: 10px 10px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px !important;

    width: 100%;
    color: #323338;
    font-weight: 600;
    height: 45px;
  }
  .ant-modal-title {
    font-weight: 600 !important;
    font-size: 16px;
    width: 100%;
    .progressPliTitle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .progressPliTitleText {
        // font-weight: 600;
        text-overflow: ellipsis;
        white-space: pre-wrap;
        overflow: hidden;
        span {
          font-weight: bolder;
          text-decoration: underline;
          padding-left: 7px;
        }
      }
    }
  }
  .ant-modal-close-x {
    display: none;
  }
  .ant-modal-body {
    padding: 0 !important;
    width: 100%;
  }
  .ant-modal-footer {
    padding: 20px !important;
    border: none;
    // min-height: 72px;
  }
  .pli-progress-table-container {
    padding: 20px 10px;
    .total-progress-span {
      font-weight: bold;
    }

    .custom-progress-bar {
      margin-top: 0px;
      margin-bottom: 0px;
    }

    .NOT_DONE {
      margin-top: 0px !important;
    }
    .item-statistics-view {
      display: flex;
      gap: 10px;
      padding: 0px 10px;
    }

    .pli-progress-table {
      .breakdowns-table-body {
        padding: 10px 10px 0px 10px !important;
        width: auto;
        @media only screen and (max-width: 413px) {
          padding: 0;
        }
        // max-height: 410px;
        // overflow: overlay;
        .breakdownRow {
          display: flex;
          justify-content: center;
          background-color: #f5f5f7;
          // display: flex;
          // flex-direction: row;
          // flex-wrap: wrap;
          gap: 20px;
          // align-items: center;
          // background: rgb(245, 245, 247);
          border-radius: 5px;
          margin-bottom: 10px;
          padding: 10px;
          // width: auto;
          // margin-bottom: 10px;
          @media only screen and (max-width: 413px) {
            gap: 5px;
          }
        }
        .breakdownRow.with-uploads {
          flex-direction: column;
          gap: 10px;
          .progress-uploader-section {
            .docUploader .uploadedFilesSection .uploadedFilesMapping {
              max-width: 50vw;
            }
          }
        }
        .breakdownRow-inputs {
          display: flex;
          justify-content: center;
          // background-color: #f5f5f7;
          // display: flex;
          // flex-direction: row;
          // flex-wrap: wrap;
          gap: 20px;
          // align-items: center;
          // background: rgb(245, 245, 247);
          border-radius: 5px;
          // margin-bottom: 10px;
          padding: 10px;
          // width: auto;
          // margin-bottom: 10px;
          @media only screen and (max-width: 413px) {
            gap: 5px;
          }
        }
        .totalProgressDisplay {
          width: 120px;
          border-radius: 5px;
          border: none;
          font-weight: 500;
          height: 32px;
          margin-top: 21px;
          // margin-right: 5px;
          background: rgb(245, 245, 247);
          @media only screen and (max-width: 413px) {
            width: 50px;
          }
        }
      }
      .progress-fields-container {
        display: flex;
        flex-direction: row;
        width: auto;
        gap: 20px;
        @media only screen and (max-width: 413px) {
          gap: 5px;
        }
      }
      .day-cell {
        height: 32px;
        width: 76px;
        border-radius: 5px;
        background: #fff;
        display: flex;
        align-items: center;
        font-weight: 600;
        justify-content: center;
        margin-top: 22px;
        // margin-left: 10px;
      }
      .progressField {
        display: flex;
        flex-direction: column;
        width: 175px;
        .ant-input-affix-wrapper {
          border: none;
          // height: 32px;
          border-radius: 5px;
          @media only screen and (max-width: 413px) {
            width: 60px;
          }
        }
        @media only screen and (max-width: 413px) {
          width: 60px;
          display: flex;
          justify-content: center;
        }
        .pliInputLength {
          border: none;
          height: 32px;
          width: 170px;
          border-radius: 5px;
          @media only screen and (max-width: 413px) {
            width: 60px;
          }
        }
        .d1DimensionInputField {
          display: flex;
          gap: 3px;
          margin-left: 10px;
          width: 120px;
          font-weight: 600;
        }
      }
      .inputFieldBtn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        // align-items: center;
        align-items: flex-end;
        // flex-wrap: wrap;
        @media only screen and (max-width: 413px) {
          display: flex;
          flex-direction: column;
          gap: 5px;
        }
      }
      .totalInputField {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        @media only screen and (max-width: 413px) {
          margin-left: 10px;
        }
      }
    }
  }
  .progressFooterBtn {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

.pli-progress-modal-dark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        .progressPliTitle {
          .progressPliTitleText {
            color: #fff;
          }
          svg {
            fill: #fff;
          }
        }
      }
    }
    .ant-modal-body {
      .pli-progress-table-container {
        color: #fff;
        .breakdownRow {
          background-color: $cardModalHeaderDark;
          .day-cell {
            background-color: $cardModalBodyDark;
          }
          .ant-input-affix-wrapper {
            background-color: $cardModalBodyDark;
            .ant-input-suffix,
            .ant-input {
              background-color: $cardModalBodyDark;
              color: #fff;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 730px) {
  .pli-progress-modal {
    .ant-modal-content {
      width: auto;
    }
  }
  .pli-progress-table-container {
    .pli-progress-table {
      .breakdowns-table-body {
        height: 140px;
        height: 140px;
        background: rgb(245, 245, 247);
        border-radius: 10px;
        .breakdownRow {
          height: 140px;
          gap: 5px;
        }
      }
    }
    .item-statistics-view {
      font-size: 16px !important;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      flex-wrap: wrap;

      .custom-progress-bar {
        margin-top: 0px;
      }
    }
  }
}
