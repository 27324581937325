$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.pre-claim-modal {
  height: fit-content !important;
  width: fit-content !important;
  .ant-modal-content {
    .ant-modal-header {
      height: 45px;
      display: flex;
      align-items: center;
      background-color: #f8f8fa;
      overflow: hidden;
      border: none;

      .ant-modal-title {
        font-size: 16px;
        width: 100%;
        font-weight: 600;
        color: #323338;
        padding: 0 !important;
        height: 45px;
        display: flex;
        align-items: center;
      }
    }
    .ant-modal-body {
      .claim-modal-content {
        display: flex;
        flex-direction: column;
        min-width: 380px;
        width: fit-content !important;

        .modalFooter {
          margin-top: 20px;
          align-self: center;
        }
        .loader {
          width: 100%;
          display: flex;
          justify-content: center;
          margin-bottom: 24px;
        }
        // .ant-select-dropdown {
        // max-width: auto;
        // }
      }
    }
  }
}
.pre-claim-modal-dark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      .labeledInputLabel {
        color: #fff;
      }
      .ant-select {
        .ant-select-selector {
          background-color: $inputModalDark !important;
          .ant-select-selection-search-input {
            color: #fff;
          }
          .ant-select-selection-item {
            color: #fff;
          }
          .ant-select-selection-placeholder {
            color: #acacca;
          }
        }
        .ant-select-clear {
          background-color: $inputModalDark;
          svg {
            fill: #acacca;
          }
        }
        .ant-select-arrow svg {
          fill: #fff;
        }
      }
      .ant-select-disabled {
        .ant-select-selector {
          .ant-select-selection-search-input {
            color: #acacca !important;
          }
          .ant-select-selection-item {
            color: #acacca !important;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 575px) {
  .pre-claim-modal {
    width: 250px;
    .claim-modal-content {
      width: 360px;
      .labeledInputContainer {
        width: 300px;
      }
    }
    .noClaimsDiv {
      flex-direction: column;
      align-items: center;
    }
  }
}
