@import "../../../../../../../../../assets/styles/breakpoints.scss";

.payment-details-container {
  background-color: #ffffff;
  box-shadow: 0px 0px 11px -8px;
  border-radius: 5px;
  // height: 100%;
  width: 100%;
  padding: 20px;
  display: flex;
  gap: 20px;

  .payment-input-memo {
    width: 100%;
    min-height: 100%;
    border: none;
    background: #f5f5f7;
    border-radius: 5px;

    @include tablet {
      width: 50%;
    }
  }
  .payment-amounts-container {
    width: 50%;
    height: 100%;
    border: none;
    background: #f5f5f7;
    border-radius: 5px;
    padding: 0 10px;
  }
}
