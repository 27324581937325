.upload-progress-container.no-percent-text {
  .ant-progress-outer {
    margin-inline-end: 0;
    padding-inline-end: 0;
  }

  .ant-progress .ant-progress-text {
    display: none;
  }
}

.upload-progress-container-dark {
  .ant-progress .ant-progress-text {
    color: #fff;
  }
  .ant-progress-status-success .ant-progress-text {
    color: #52c41a;
  }
}
