@import "/src/index.scss";

.custom-time-input {
  height: fit-content !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  input[type="number"] {
    -moz-appearance: textfield;
  }
  .time-input-label {
    display: flex;
    font-size: 16px;
    font-weight: 600;
    margin-left: 10px;
  }
  .time-input-wrapper {
    height: 32px;
    width: fit-content;
    display: flex;
    flex-direction: row !important;
    align-items: center;
    border-radius: 5px;
    background-color: #f4f5f6;
    // margin-bottom: 24px;
    .ant-form-item {
      width: fit-content;
      margin-bottom: 0px;
    }
    .ant-input {
      width: 40px;
      border: none;
      background-color: transparent;
    }
    .semicolon {
      height: 100%;
      display: flex;
      align-items: center;
    }
    .day-period-container {
      &:focus {
        border: 2px solid #4096ff96;
      }
    }
  }
  .custom-item-error {
    .ant-form-item-control-input {
      min-height: 0px;
      margin: 0px;
    }
    height: 0px;
    margin: 0px;
  }
}

.custom-time-input-dark {
  .time-input-label {
    color: #fff;
  }
  .time-input-wrapper {
    background-color: $cardDarkBackgroundColor;
    .ant-input {
      color: #fff;
      &::placeholder {
        color: #acacca;
      }
    }
    .semicolon {
      color: #fff !important;
    }
    .day-period-container {
      background-color: $cardDarkBackgroundColor;
      .ant-segmented-item {
        color: #acacca;
        &:hover {
          color: #fff;
        }
      }
      .ant-segmented-item-selected {
        color: #fff;
        background-color: $mainDarkBackgroundColor;
      }
      .ant-segmented-thumb,
      .ant-segmented-thumb-motion-appear,
      .ant-segmented-thumb-motion,
      .ant-segmented-thumb-motion-appear-active {
        background-color: $mainDarkBackgroundColor !important;
      }
    }
  }
  .reverse-dark-mode {
    background-color: $mainDarkBackgroundColor !important;
    .ant-input,
    #hours,
    #minutes {
      background-color: transparent !important;
    }
    .day-period-container {
      background-color: $mainDarkBackgroundColor !important;
      .ant-segmented-item-selected {
        color: #fff;
        background-color: $cardDarkBackgroundColor !important;
      }
      .ant-segmented-thumb,
      .ant-segmented-thumb-motion-appear,
      .ant-segmented-thumb-motion,
      .ant-segmented-thumb-motion-appear-active {
        background-color: $cardDarkBackgroundColor !important;
      }
    }
  }
}

.labeledInputContainer.darkMode {
  .custom-time-input-dark .reverse-dark-mode .ant-input {
    background-color: transparent !important;
  }
}

.day-period-container {
  .ant-segmented-group {
    &:focus-within {
      box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1) !important;
      border-radius: 5px;
    }
  }
}
