@import "../../../../../index.scss";

.archEngPopoverContent {
  @include flex(center, center, column);
  width: 205px;
  text-align: center;
  .popoverText {
    @include text(14px, 600, 0.21px, #383554);
    margin-bottom: 5px;
  }
  .coreInfo {
    color: #f00;
  }
  .ant-btn:first-child {
    margin-right: 15px;
  }
}
