.ql-editor p {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  // color: greenyellow !important;
  // &::before {
  //   content: "• ";
  // }
}
