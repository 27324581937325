.firstProposalPageModal {
  &.ant-modal {
    &.ant-modal-centered {
      &.ant-modal {
        max-width: 100%;
        margin: 0;
        height: 100vh;
        width: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;

        .ant-modal-content {
          height: 100%;
          width: 100%;
          border-radius: 0;
          box-shadow: none;
        }
      }
    }
  }
}
.firstProposalPageModalDark {
  &.ant-modal {
    &.ant-modal-centered {
      &.ant-modal {
        max-width: 100%;
        margin: 0;
        height: 100vh;
        width: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;

        .ant-modal-content {
          height: 100%;
          width: 100%;
          border-radius: 0;
          box-shadow: none;
          background-color: #333;
          color: #fff;
          .ant-upload-drag-container {
            background-color: #333;
            color: #fff !important;
          }
          .ant-modal-body {
            .mergePdfsContainer {
              .mergePdfsBodyDragger {
                background-color: red;
                .proposalDraggerDocuments {
                  .ant-upload {
                    border: 1px dashed #fff;
                    .ant-upload-drag-icon {
                      color: #fff !important;
                    }
                    .ant-upload-text {
                      color: #fff !important;
                    }
                    .ant-upload-drag-container {
                      background-color: #333;
                      color: #fff !important;
                    }
                    color: #fff !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
