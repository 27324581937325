@import "../../../../../index.scss";

.commentReplier {
  .richTextComp .tox-tinymce {
    height: 150px !important;
  }

  .replyLabel {
    cursor: pointer;
    color: #1264a3;
  }

  .replierActionBtn {
    margin-top: 10px;
    @include flex(center, flex-end);

    .ant-btn {
      border-radius: 10px;
      height: 30px;

      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
}
