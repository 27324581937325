.optionsContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  @keyframes wobble {
    0% {
      transform: translateY(-30px);
    }
    100% {
      transform: translateY(0);
    }
  }
  animation: wobble 0.4s;
  .optionButtonTrue {
    width: 89px;
    height: 35px;
    background-color: #71cf48;
    border-radius: 5px;
    border: none;
    .textOptionButton {
      text-align: center;
      font: italic normal normal 18px Open Sans;
      letter-spacing: 0px;
      color: #ffffff;
      opacity: 1;
    }
  }
  .optionButtonTrue:hover {
    width: 89px;
    height: 35px;
    border-radius: 5px;
    background-color: #ff4d4f;
    border-color: #ff4d4f;
  }
  .optionButtonFalse {
    width: 89px;
    height: 35px;
    background-color: #ff4d4f;
    border-radius: 5px;
    border: none;
    .textOptionButton {
      text-align: center;
      font: italic normal normal 18px Open Sans;
      letter-spacing: 0px;
      color: #ffffff;
      opacity: 1;
    }
  }
  .optionButtonFalse:hover {
    width: 89px;
    height: 35px;
    border-radius: 5px;
    width: 89px;
    height: 35px;
    background-color: #71cf48;
    border-color: #71cf48;
    border-radius: 5px;
    .textOptionButton {
      text-align: center;
      font: italic normal normal 18px Open Sans;
      letter-spacing: 0px;
      color: #ffffff;
      opacity: 1;
    }
  }
  .optionButtonFalse:hover {
    width: 89px;
    height: 35px;
    border-radius: 5px;
    background-color: #71cf48;
  }
}
