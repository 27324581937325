.changeTaxModal {
  .ant-modal {
    // width: fit-content !important;
    // width: 300px;
  }

  .ant-modal-content {
    border-radius: 10px;
    overflow: hidden;
    width: fit-content;
  }

  .ant-modal-title {
    color: white;
  }

  .ant-modal-close {
    height: 45px;
    width: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-modal-header {
    height: 45px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    background-color: #12131b;
    color: white !important;
    border-bottom: 0;
  }

  .ant-modal-footer {
    height: 45px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 15px;
    background-color: #12131b;
    border-top: 0;
  }

  .ant-modal-body {
    background-color: #1f212d;
    // width: fit-content;
    // height: 600px;
    width: 500px;
    display: flex;
    gap: 15px;
  }
}
