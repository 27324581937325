.MultiBoxComponent {
  .MultiBoxContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    max-width: 720px;
    padding-top: 20px;
    .checkboxContainer {
      pointer-events: none;
      .checkBoxStyle {
        width: 200px;
        .greenContainer {
          background-color: rgb(1, 192, 106);
        }
        .redContainer {
          background-color: rgb(255, 77, 79);
        }
      }
    }
  }
  .confirmContainer {
    display: flex;
    margin-top: 20px;
    justify-content: center;
    .cancelButton {
      margin-right: 10px;
      background-color: #fe4c4a;
    }
    .cancelButtonDivider {
      color: #f14543;
    }
    .cancelButton:hover {
      background-color: #f14543;
      .cancelButtonDivider {
        color: #fe4c4a;
      }
    }
    .confirmButton {
      margin-right: 10px;
      background-color: #71cf48;
    }
    .confirmButtonDivider {
      color: #69c242;
    }
    .confirmButton:hover {
      background-color: #69c242;
      .confirmButtonDivider {
        color: #71cf48;
      }
    }
  }
}
