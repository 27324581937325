@import "../../../../../../../../index.scss";

// $cardModalHeaderDark: #12131b;
// $cardModalBodyDark: #1f212d;
// $inputModalDark: #12131b;

.splitShiftModal {
  width: calc(100vw - 20px) !important;
  max-width: 1200px;
  .ant-modal-content {
    width: calc(100vw - 20px) !important;
    height: calc(100vh - 20px) !important;
    max-width: 1200px;
    .ant-modal-close {
      height: 45px;
      width: 45px;
      .ant-modal-close-x {
        height: 45px;
        width: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          fill: #333238;
        }
      }
    }
    .ant-modal-header {
      height: 45px;
      display: flex;
      align-items: center;
      background-color: #f8f8fa;
      .ant-modal-title {
        font-size: 16px;
        font-weight: 600;
      }
    }
    .ant-modal-body {
      height: calc(100vh - 120px) !important;
      .informationText {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 20px;
      }
      .shiftToSplit {
        display: flex;
        justify-content: space-between;
        padding: 20px;
        border-radius: 10px;
        background-color: #f8f8fa;
        .shiftData {
          .shiftLabel {
            font-weight: 600;
          }
          .data {
            .ant-form-item {
              margin-bottom: 0px;
            }
            .ant-select {
              .ant-select-selector {
                background-color: #fff !important;
              }
              .ant-select-clear {
                background-color: #fff;
              }
            }
          }
        }
      }
      .formCardsContainer {
        height: calc(100vh - 280px) !important;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        padding: 10px 0px;
        transition: 0.5s ease;
        .shiftFormCard {
          width: 100%;
          display: flex;
          align-items: flex-end;
          border-radius: 10px;
          background-color: #f8f8fa;
          padding: 20px;
          gap: 10px;
          animation: showFormCard 0.5s ease;
          .fieldWrapper {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 20px;
            .jobsiteContainer {
              display: grid;
              grid-template-columns: 1fr 1fr;
              gap: 20px;
            }
            .timeContainer {
              width: 100%;
              display: grid;
              grid-template-columns: 1fr 1fr 1fr 1fr;
              gap: 20px;
            }
            .timeContainerTwoSplits {
              grid-template-columns: 1fr 1fr;
            }
            .labeledInputContainer {
              max-width: 400px;
              width: 100%;
              .ant-form-item {
                margin-bottom: 0px;
              }
              .ant-select {
                .ant-select-selector {
                  background-color: #fff !important;
                }
              }
              .ant-picker {
                width: 170px;
                background-color: #fff !important;
              }
            }
          }
          .cardActions {
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
          }
        }
        .removeShiftAnimation {
          animation: removeFormCard 0.5s ease;
        }
        .addShiftButton {
          width: 250px;
          // transition: .5s ease;
        }
      }
    }
    .ant-modal-footer {
      padding: 10px 20px;
      .footerContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .mondayButtonStyle {
          width: 150px;
        }
    } 
    }
  }
}

.splitShiftModalDark {
  .ant-modal-content {
    background-color: $mainDarkBackgroundColor;
    .ant-modal-close .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardDarkBackgroundColor;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      .shiftToSplit {
        background-color: $cardDarkBackgroundColor;
        .shiftData {
          label,
          div {
            color: #fff;
          }
          .data
          .ant-select {
            .ant-select-selector {
              background-color: $mainDarkBackgroundColor !important;
              .ant-select-selection-search-input {
                color: #fff;
              }
              .ant-select-selection-item {
                color: #fff;
              }
              .ant-select-selection-placeholder {
                color: #acacca;
              }
            }
            .ant-select-clear {
              background-color: $mainDarkBackgroundColor;
              svg {
                fill: #acacca;
              }
            }
            .ant-select-arrow svg {
              fill: #fff;
            }
          }
        }
      }
      .informationText,
      .labeledInputLabel {
        color: #fff;
      }
      .formCardsContainer .shiftFormCard {
        background-color: $cardDarkBackgroundColor;
        .fieldWrapper .labeledInputContainer {
          .ant-picker {
            background-color: $mainDarkBackgroundColor !important;
            .ant-picker-input {
              background-color: $mainDarkBackgroundColor;
              input {
                color: #f2f2f2;
                &::placeholder {
                  color: #acacca;
                }
              }
              .ant-picker-suffix svg {
                color: #acacca;
              }
              .ant-picker-clear {
                background-color: $mainDarkBackgroundColor;
                svg {
                  fill: #acacca;
                }
              }
            }
          }
          .ant-picker-disabled {
            .ant-picker-input input {
              color: #acacca !important;
            }
          }
          .ant-select {
            .ant-select-selector {
              background-color: $mainDarkBackgroundColor !important;
              .ant-select-selection-overflow-item {
                .ant-select-selection-item {
                  background-color: #333238;
                  .ant-select-selection-item-remove svg {
                    fill: #fff;
                  }
                }
              }
              .ant-select-selection-search-input {
                color: #fff;
              }
              .ant-select-selection-item {
                color: #fff;
              }
              .ant-select-selection-placeholder {
                color: #acacca;
              }
            }
            .ant-select-clear {
              background-color: $mainDarkBackgroundColor;
              svg {
                fill: #acacca;
              }
            }
            .ant-select-arrow svg {
              fill: #fff;
            }
          }
          .ant-select-disabled {
            .ant-select-selector {
              .ant-select-selection-search-input {
                color: #acacca !important;
              }
              .ant-select-selection-item {
                color: #acacca !important;
              }
            }
          }
        }
      }
    }
  }
}

@keyframes showFormCard {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes removeFormCard {
  0% {
    opacity: 1;
    transform: translateY(0px);
    height: 178px;
  }
  100% {
    opacity: 0;
    transform: translateY(-100px);
    height: 0px;
    padding: 0px;
    margin-top: -10px;
  }
}
