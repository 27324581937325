.foremanProjectView {
  .card-template-title {
    border-radius: 5px 5px 0 0;
  }
  .card-template-body {
    height: calc(100% - 45px);
    overflow-y: auto;
    .backToProject {
      color: #1264a3;
      font-weight: 600;
      cursor: pointer;
      width: fit-content;
    }
    .foremanProjectView-header {
      display: flex;

      .foremanProjectView-header-slider {
        padding-right: 10px;
        display: flex;
        gap: 5px;
        align-items: center;
        width: 100%;
        ::-webkit-scrollbar {
          display: none !important;
        }
        .foremanProjectView-slider-body {
          flex: 1;
          overflow-x: auto;
          display: flex;
          gap: 5px;

          .foremanProjectView-slider-body-day {
            min-width: 100px !important;
            border: 1px solid #f8f8fa;
            // height: 48px;
            display: flex;
            border-radius: 5px;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            padding: 5px 10px;
            background-color: #f8f8fa;
            cursor: pointer;
            color: #323338;
            .day-number {
              font-size: 14px;
              font-weight: 600;
              line-height: 21px;
            }
            .day-date {
              font-size: 12px;
              font-weight: 400;
              line-height: 16px;
            }
          }
          .selected {
            border: 1px solid #1264a3;
            background-color: #f1f5fd;
          }
        }
      }

      .no-schedule-found {
        font-size: 16px;
        font-weight: 600;
        color: #acacac;
      }
    }
  }
}

@media screen and (min-width: 450px) {
  .foremanProjectView
    .card-template-body
    .foremanProjectView-header
    .foremanProjectView-header-slider
    .foremanProjectView-slider-body
    .foremanProjectView-slider-body-day:hover {
    border: 1px solid #1264a3;
    background-color: #f1f5fd;
  }
}
