.progress-crews-track-modal {
  .ant-modal-header {
    height: 45px;
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
  }
  .crew-progress-card {
    .ant-card-head {
      background-color: #f5f5f5;
      border-bottom: 1px solid #e8e8e8;
      padding: 0 16px;
      .ant-card-head-title {
        padding: 0;
      }
    }
  }
}
