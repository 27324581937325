.safety-export-container {
  height: fit-content !important;
  width: fit-content !important;

  .ant-modal-content {
    width: calc(100vw - 20px) !important;
    max-width: 1100px;
    height: 85vh;

    .ant-modal-header {
      height: 45px;
      display: flex;
      align-items: center;
      background-color: #f5f5f7;
      .ant-modal-title {
        font-weight: 600;
        font-size: 16px;
        color: #333238;
      }
    }

    .ant-modal-close {
      height: 45px;
      width: 45px;
      .ant-modal-close-x {
        height: 45px;
        width: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          fill: #333238;
        }
      }
    }

    .ant-modal-body {
      height: calc(100% - 52px - 45px);
      width: 100%;
      padding: 10px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-direction: row;
      flex-wrap: wrap;
      overflow-y: auto;
      // gap: 10px;

      .configuration-section {
        width: 47%;
        height: 100%;

        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 10px;
        flex-direction: column;

        .configuration-tabs {
          width: 100%;
          gap: 20px;
          .ant-tabs-tab {
            padding: 5px 0;
            .ant-tabs-tab-btn {
              font-size: 14px;
              font-weight: 400;
            }
          }

          .ant-tabs-tabpane {
            padding: 0;
          }
          .tab-container {
            display: flex;
            flex-direction: column;
            gap: 10px;
            height: 100%;
            overflow: hidden;
          }
          .safety-contacts-tab {
            overflow-y: auto !important;

            .contacts-container {
              overflow-y: unset;
              max-height: unset;
            }
          }

          .droppableDiv {
            display: flex;
            flex-direction: column;
            gap: 10px;
            max-height: 500px;
            overflow-y: auto;
            padding-right: 10px;
            height: 100%;
          }
          .draggableDiv {
            width: 100%;
            height: 50px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 10px;
            background-color: #f8f8fa;
            border-radius: 5px;
            .draggableSpan {
              padding-right: 5px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              user-select: none;
            }
          }
        }
      }

      // .choose-size-section {
      //   display: flex;
      //   justify-content: flex-start;
      //   align-items: center;
      //   gap: 10px;
      // }

      .configuration-section.landscape {
        width: 50%;
        height: 100%;
      }

      .preview-section {
        height: 100%;

        .preview-frame {
          background-color: #fff;
          border: 1.5px solid rgba(0, 0, 0, 0.2);
        }
      }

      .preview-section.landscape {
        width: 50%;
        height: unset;
      }
    }

    .ant-modal-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      width: 100%;
      overflow-y: hidden;
      overflow-x: auto;
      flex-direction: row;
      flex-wrap: nowrap;

      .export-container {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        gap: 10px;
        flex-direction: row;
        flex-wrap: nowrap;
      }
    }
  }
}

.safety-export-container.safety-export-dark {
  .ant-modal-content {
    background-color: #1f212d;

    .ant-modal-header {
      background-color: #12131b;
      .ant-modal-title {
        color: #fff;
      }
    }

    .ant-modal-close {
      .ant-modal-close-x {
        svg {
          fill: #fff;
        }
      }
    }

    .ant-modal-body {
      background-color: #1f212d;
      color: #fff;

      .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-checked) {
        background: #12131b;
        color: #ffffff;
      }
      .pagination-checkbox {
        color: #fff;
      }

      .configuration-section .change-fields-section {
        .dynamic-fields-container {
          .dynamic-field {
            color: #ffffff;
            &:hover {
              box-shadow: 0 0 2px 1px rgba(255, 255, 255, 0.2);
            }
          }
        }
      }

      .preview-label {
        color: #ffffff;
      }

      .ant-radio-button-wrapper-checked {
        background: #1264a3;
        border-color: #3386c5;
      }

      .report-choose {
        border: 1px solid #fff !important;
      }

      .preview-section {
        .preview-frame {
          border: 0;
        }
      }
    }
  }
}

@media (max-width: 825px) {
  .safety-export-container {
    .ant-modal-content {
      height: 96vh;

      .ant-modal-body {
        .configuration-section {
          width: 100%;
        }

        .configuration-section.landscape {
          width: 100%;
        }

        .preview-section {
          max-width: 100%;
        }

        .preview-section.landscape {
          width: 100%;
          height: unset;
        }
      }
    }
  }
}
