@import "../../../../index.scss";

.notes_drawer {
  .ant-input-wrapper {
    background-color: #f8f8fa;
    border-radius: 5px;
    height: 32px;
  }
  .ant-drawer-header {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-alert {
    border-radius: 10px 10px 0 0;
  }
  .ant-drawer-content-wrapper {
    width: auto !important;
    min-width: 550px;
  }
  .ant-drawer-content {
    .schedule-note-header {
      display: flex !important;
      justify-content: center;
      align-items: center;
      flex-direction: row !important;
      gap: 5px;
      h3 {
        color: #323338;
        font-weight: 600;
      }
      h5 {
        margin-top: 7px;
        font-size: 1.1rem;
        margin-bottom: 0;
      }
    }
    .ant-drawer-body {
      padding: 10px;
      padding-top: 0px;
      position: relative; //for loading diamond
    }
  }
  .ant-drawer-header {
    background: #1f2a44;
    border-bottom: none !important;
    border-radius: 0px;
    height: 45px;
    padding: 0;
    .ant-drawer-header-title {
      height: 45px;
      padding: 0px 20px;
      display: flex;
    }
    .ant-drawer-title {
      position: relative;
      display: flex;
      height: 100%;
      padding-top: 0px;

      .headerContent {
        width: 100%;
        display: flex;
        gap: 10px;
        align-items: center;
        padding-left: 10px;
        .notesModalName {
          width: 85%;
          max-width: 420px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .notesModalDarkModeSwitcher {
          // width: 20%;
          cursor: pointer;
        }
      }

      &::before {
        content: "";
        display: none;
        position: absolute;
        bottom: 0;
        width: 36%;
        left: 34%;
        border-bottom: 3px solid #e6e9ef;
      }
      h2 {
        margin: 0px;
        // margin-bottom: 7px !important;
        font-size: 1.2rem;
        font-weight: bolder !important;
      }
      h3 {
        margin: 0 !important;
        font-size: 1.2rem;
        color: #323338;
      }
    }
    .ant-drawer-close {
      left: 0;
      // font-size: 1.2rem;
      height: 16.8px;
      padding: 0px;
      color: #000000;
      margin-right: 0 !important;
    }
  }

  .ant-collapse-content-box {
    padding: 0 !important;
    background-color: #f8f8fa;
    margin-top: -15px;
  }
}

.ant-drawer-close {
  fill: #f8f8fa;
}

//DARK mode of note
.notes_drawer.notes-modal-dark {
  .ant-alert {
    background-color: #ffb7ac;
  }
  // main content style of modal without cat and form for that cat
  .ant-drawer-header,
  .ant-drawer-body {
    background: #1f212d !important;
    .ant-drawer-header-title {
      background: #12131b;
      .ant-drawer-close svg {
        fill: #fff !important;
      }
    }
    .ant-drawer-title {
      color: #f8f8fa !important;
      h2 {
        color: #f8f8fa;
      }
    }
    h6 {
      color: #ffffff;
    }
  }

  .ant-tabs-nav-wrap {
    .ant-tabs-nav-list {
      border-bottom-color: #1f212d !important;
    }
    .ant-tabs-tab {
      .ant-tabs-tab-btn {
        color: #fff !important;
      }
    }
  }

  //search input
  .ant-input-wrapper {
    background: unset;
    .ant-input {
      background: #12131b !important;
      color: #f8f8fa;
    }
    //search icon
    .ant-input-group-addon {
      background-color: #12131b;
      .ant-btn.ant-btn-default.ant-btn-icon-only.ant-input-search-button {
        background: #12131b;
        .anticon-search svg {
          fill: #fff;
        }
      }
    }
  }

  // panel with his form input
  .ant-collapse {
    .ant-collapse-item {
      background: #12131b;
    }
    .comment-input-container {
      background: #12131b !important;
      height: 100%;
    }
  }

  //Dark mode content of category panel when is open

  .ant-collapse-content.ant-collapse-content-active,
  .ant-collapse-content.ant-collapse-content-inactive {
    background: transparent;
    .ant-collapse-content-box {
      background: transparent;
      .comment-content-component {
        background-color: #12131b;
      }
      .comment-component {
        .comment_wrapper,
        .replies_wrapper {
          background-color: transparent;
          //comment person name
          h5,
            h6,
            //comment content
            .comment_data {
            color: #f8f8fa;
            background: transparent;
            .mention {
              color: #1f212d;
              font-weight: 700;
              background: #f8f8fa !important;
            }
          }
        }
      }
      .comment-component .replies_wrapper:first-child {
        background-color: transparent;
      }
    }
    //reply button
    .reply_btn {
      .ant-btn.ant-btn-default {
        border: none;
        background-color: #1f212d;
      }
    }
  }
  .ant-btn .anticon {
    color: #f8f8fa;
  }
  .ant-collapse-header-text,
  .ql-snow .ql-picker {
    color: #f8f8fa;
    .ql-picker-options {
      background-color: #12131b;
      color: #f8f8fa;
    }
  }
  .ant-collapse-expand-icon {
    fill: #f8f8fa;
  }
  .note_input {
    .ql-toolbar {
      background: #12131b !important;
    }
    .ql-container {
      background-color: transparent;
      .ql-editor {
        border: 0px !important;
        background: #1f212d;
        p,
        ol,
        blockquote,
        ul {
          color: #f8f8fa !important;
        }
        .mention {
          background-color: #000000;
        }
      }
      .ql-mention-list {
        background: #1f212d;
        color: #f8f8fa;
      }
      .ql-mention-list-item.selected {
        background: #12131b;
      }
    }
  }
  .ql-editor.ql-blank::before {
    color: #f8f8fa9f;
  }
  .ant-btn.ant-btn-round.ant-btn-default.date-btn,
  .ant-btn.ant-btn-round.ant-btn-default.hour-btn {
    background: #2c2b2b;
    border: 0;
    color: #f8f8fa;
  }
  .ql-mention-list-container {
    background-color: #1f212d;
  }
}

//reused component CommentContent
.notes-container {
  .comment-content-component {
    .comment-component {
      .comment-container {
        .first-line-L {
          height: calc(100% - 35px);
        }
      }
      .comment-input-container {
        .edit_comment_wrapper {
          .rich-editor-description {
          }
        }
      }
    }
    .comment-content-replies {
      .comment-content-component {
        width: 100% !important;
      }
    }
  }
}

//Modal to add a Reply
.add-note-modal {
  .ant-modal-header {
    padding: 0;
    height: 45px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #f8f8fa;
    border: none;
    .ant-modal-title {
      color: #323338;
      font-weight: bold;
      font-size: 1rem;
    }
  }
  .ant-modal-close-x {
    height: 45px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ant-modal-body {
    margin-top: -1px;
    display: flex;
    flex-direction: column;
    padding: 0;
    padding: 20px;
    .comment-content-component {
      .comment-component {
        .comment-container {
          .first-line-L {
            height: calc(100% - 35px);
          }
        }
      }
    }
  }
  .ant-modal-content {
    border-radius: 10px;
    min-height: 200px;
    background: #ffffff;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
  }
  .post-btn {
    display: flex;
    align-items: center;
    margin-top: 15px;
    align-self: flex-end;
    border-radius: 5px;
    background: #1264a3;
  }
  .note-statuses {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 15px 15px 0 15px;
    h4 {
      font-size: 0.9rem;
      margin: 0 15px 0 0;
      display: flex;
      align-items: center;
      font-weight: 600;
      font-style: italic;
    }
    .statuses-list {
      display: flex;
      flex-wrap: wrap;
      margin-top: 10px;
      .ant-btn {
        background: #f5f5f7;
        border-radius: 5px;
        margin: 0 8px 8px 0;
        color: #323338;
        border: none;
      }
    }
  }
  .reply_actions_section {
    width: 100%;
    padding: 25px 15px 15px;
    display: flex;
    flex-direction: column;
    align-content: stretch;
    position: relative;
    gap: 10px;

    .ql-container {
      .ql-editor {
        background: #f5f5f7;
        min-height: 130px !important;
      }
    }

    h4 {
      font-size: 0.9rem;
      margin: 0 15px 0 0;
      display: flex;
      align-items: center;
      font-weight: 600;
      font-style: italic;
    }
    .rich-text-post-btn {
      padding: 5px 10px;
      border-radius: 5px;
      background-color: #1264a3;
      color: white;
      svg {
        width: 15px;
        height: 15px;
      }

      &:hover {
        background-color: #0f5c97;
        .mondayButtonDivider {
          background-color: #1264a3;
        }
      }

      .mondayButtonDivider {
        background-color: #0f5c97;
        z-index: 2;
        width: 1px;
        margin: 0 10px;
        height: 30px;
      }
    }
  }
}

// DARK Comment reply modal & Write a Note modal
.notes-modal-dark {
  .ant-modal-content {
    background-color: #2a2b3a;
    .ant-modal-header {
      background: #12131b;
      border-radius: 10px 10px 0px 0px;
      .ant-modal-title {
        color: #f8f8fa;
      }
    }
    .ant-modal-body {
      .note-statuses {
        // .more-info-square {
        //   margin-bottom: 10px;
        // }
        .statuses-list .ant-btn {
          background: #3a3c4e;
          color: #ffffff;
          border: none;
        }
      }
      .comment-preview {
        color: #f8f8fa;
        background: #12131b !important;
        .comment_wrapper {
          background: #12131b;
          .comment_data {
            h5,
            h6,
            .comment-content {
              color: #f8f8fa !important;
              p {
                .mention {
                  background: #1f212d !important;
                }
              }
            }
          }
        }
        .showReplies {
          color: #f8f8fa;
          span {
            margin-left: 5px;
            font-size: 0.75rem !important;
          }
          svg path {
            fill: #f8f8fa;
          }
        }
        .replies_wrapper {
          background-color: transparent;

          //comment person name
          h5,
            h6 ,
            //comment content
            .comment_data {
            color: #f8f8fa !important;
            background-color: transparent;
            p {
              .mention {
                background: #1f212d !important;
              }
            }
          }
        }
      }

      // note description
      .reply_actions_section {
        background-color: #2a2b3a;
        h6 {
          color: #f8f8fa;
        }

        .ql-toolbar {
          background: #12131b;
        }

        .ql-container {
          background-color: transparent;
          .ql-editor {
            border: 0px !important;
            background: #12131b;
            p,
            ol,
            blockquote,
            ul {
              color: #f8f8fa !important;
            }
            .mention {
              background-color: #000000;
            }
          }
          .ql-mention-list {
            background: #1f212d;
            color: #f8f8fa;
          }
          .ql-mention-list-item.selected {
            background: #12131b;
          }
        }
        .ql-editor.ql-blank::before {
          color: #f8f8fa9f;
        }
        .ql-snow .ql-picker {
          color: #f8f8fa;
          .ql-picker-options {
            background-color: #12131b;
            color: #f8f8fa;
          }
        }

        //date/hour buttons
        .ant-btn.ant-btn-round.ant-btn-default.date-btn,
        .ant-btn.ant-btn-round.ant-btn-default.hour-btn {
          background: #2c2b2b;
          border: 0;
          color: #f8f8fa;
        }
      }
    }
    //Write a comment/select category section
    .note-statuses {
      background: #2a2b3a;
      h6 {
        color: #f8f8fa !important;
      }
    }
    .ql-mention-list-container {
      background-color: #2a2b3a;
    }
  }
}

//Info description of notes
.more-info-square {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px 0px;
  .info-container {
    display: flex;
    align-items: center;
    gap: 10px;
    .anticon-info-circle {
      font-size: 20px;
      color: #1264a3;
    }
    h6 {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      margin: 0;
      color: #323338;
    }
  }
  .export-container {
    width: 100%;
    display: flex;
    // justify-content: center;
    gap: 10px;
    .mondayButtonContainer {
      margin: 0px;
    }
    .labeledInputContainer {
      min-width: 200px;
      .ant-form-item {
        margin-bottom: 0px;
      }
    }
  }
}

.more-info-square-dark {
  .ant-select {
    height: 32px;
    .ant-select-selector {
      background-color: #12131b !important;
      .ant-select-selection-search-input {
        color: #fff;
      }
      .ant-select-selection-item {
        color: #fff;
      }
      .ant-select-selection-placeholder {
        color: #acacca;
      }
    }
    .ant-select-clear {
      background-color: #12131b;
      svg {
        fill: #acacca;
      }
    }
    .ant-select-arrow svg {
      fill: #fff;
    }
  }
  //Disabled
  .ant-select-disabled {
    .ant-select-selector {
      .ant-select-selection-search-input {
        color: #acacca !important;
      }
      .ant-select-selection-item {
        color: #acacca !important;
      }
    }
  }
}

.image-row {
  position: absolute;
  // display: flex;
  left: 20rem;

  // img {
  //   border-radius: 50%;
  //   border: 2px solid #f8f8fa;
  // }
}

//animate selected comment
.animate {
  animation: move 1.3s infinite both !important;
}

.ant-drawer.ant-drawer-right.notes_drawer {
  z-index: 1000 !important;
}

//Responsive for iPhone
@media (max-width: 550px) {
  .notes_drawer,
  .add-note-modal {
    .ant-drawer-content-wrapper {
      width: 100% !important;
      min-width: unset !important;
    }
    .image-row {
      left: 12rem;
    }
    .comment-input-container {
      .new_comment_wrapper {
        flex-direction: column;
        .notes-rich-text {
          width: 100% !important;
        }
        .mondayButtonContainer {
          align-self: unset;
          margin-top: 10px;
          margin-left: unset;
        }
      }
    }
    // .notes__container {
    .comment_wrapper {
      padding: 10px;
    }
    .comment_data {
      width: calc(100% - 50px) !important;
      margin-left: 10px;
      .top_details {
        min-width: unset !important;
      }
    }
    .notes_container_replies {
      width: 100%;
      .replies_wrapper {
        .comment_data {
          width: calc(100% - 50px) !important;
          .top_details {
            min-width: unset !important;
          }
        }
      }
      .vertical-line-L {
        left: 36px;
      }
    }
    // }

    //for add note modal & comment preview
    .comment-preview {
      .vertical-line-L {
        left: 26px !important;
      }
    }
    .reply_actions_section {
      .notes-rich-text {
        .ql-toolbar {
          width: 100% !important;
        }
      }
    }
    .edit_comment_wrapper {
      .update-close-icons {
        flex-direction: column;
        align-self: end;
      }
    }
    .ql-toolbar {
      padding-right: 70px;
    }
  }
}
