.progressBodyContainer {
  // max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  margin-bottom: 20px;
  padding-top: 20px;
  padding-right: 10px;
  &::-webkit-scrollbar-thumb {
    border: unset !important;
  }
  .statusContainer {
    display: flex;
    opacity: 1;
    width: 100%;
    margin-right: 20px;
    margin-bottom: 10px;
    min-height: 30px;
    height: fit-content;
    align-items: center;
    justify-content: space-between;
    padding-right: 10px;
    .statusValueStyle {
      padding-right: 5px;
      text-align: right;
      flex: auto;
      font: normal normal 600 16px/34px Open Sans;
      letter-spacing: 0px;
      color: #ffffff;
      opacity: 1;
      margin-right: 0px;
      max-width: fit-content;
      padding-left: 15px;
      border-left: 4px solid #ffffff;
    }

    .statusLabelStyle {
      width: fit-content;
      text-align: left;
      font: normal normal 600 16px/34px Open Sans;
      letter-spacing: 0px;
      color: #323338;
      opacity: 1;
      padding: 0;
      margin: 0;
      text-overflow: ellipsis;
      overflow: hidden;
      line-height: 1.2;
      // white-space: nowrap;
    }
  }
}

.progressBodyContainerDark {
  .statusContainer {
    .statusLabelStyle {
      color: #f2f2f2;
    }
    .statusValueStyle {
      border-left: 4px solid #2a2b3a;
    }
  }
}

@keyframes rotateTop {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}
@keyframes rotateBottom {
  from {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.scrollBottomContainer {
  margin-bottom: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(0deg);
  animation: rotateBottom 0.5s linear;
}
.scrollTopContainer {
  margin-bottom: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: rotateTop 0.5s linear;
  transform: rotate(180deg);
}

.dashToolTipStyleCustom {
  opacity: 1 !important;
  .ant-tooltip-content {
    margin-top: 50px !important;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-radius: 10px !important;
    overflow: hidden !important;
    opacity: 1 !important;
    width: 300px !important;
    max-height: 400px !important;
  }
  .ant-tooltip-inner {
    background-color: black !important;
    opacity: 0.8 !important;
    overflow-y: auto !important;
  }
}

@media (max-width: 500px) {
  .progressBodyContainer {
    .statusContainer .statusLabelStyle {
      font-size: 14px;
    }
  }
}
