@import "../../../../index.scss";

.labeledInputContainer {
  min-width: 150px;
}

.labeledInputLabel {
  @include text(16px, 600, 0, #323338, capitalize);
  font-family: "Open Sans";
  margin-bottom: 2px;
  margin-left: 10px;
  display: flex;
}
