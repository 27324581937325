@use "~ag-grid-enterprise/styles" as ag;
@include ag.grid-styles(
  (
    theme: balham,
  )
);
@import url("https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@300&display=swap");

//GLOBAL
.rtf-editor-closed-text {
  white-space: nowrap;
  width: 150px;
}
// commented code below for it caused overflow problems in ag-grid body
// .ag-root-wrapper-body.ag-layout-normal {
// height: fit-content !important;
// min-height: fit-content !important;
// overflow-y: auto !important;
//  padding: 15px;
// }

//GLOBAL END

.allTakeOff {
  .ant-notification {
    display: none;
  }
  height: 100% !important;
  .ant-collapse-content-box {
    padding: 0 !important;
  }

  .fullServiceContainer {
    width: 100%;
    margin-left: 20px;
  }
  height: 100%;
  .takeOffContainer {
    .landi {
      // animate spinning
      animation: spin 2s linear infinite;
    }
  }
}

.img.landi {
  animation: spin 2s linear infinite;
}

.ModalConfirmation {
  display: flex;
  text-align: center;
}
.dataEntryModal {
  .ant-collapse-content-box {
    padding: 0;
  }
  top: 0 !important;
  width: 100vw !important;
  height: 100vh;
  padding-bottom: 0 !important;
  max-width: unset !important;

  *:not(.ag-icon):not(.ag-checkbox-input-wrapper.ag-checked:after) {
    font-family: "Lato", sans-serif !important;
  }

  .ant-modal-header {
    border-radius: 0;
    height: 50px;
    display: flex;
    align-items: center;
  }

  .data-grid-icon-button-img {
    height: 100%;
  }

  .dataEntryTitle {
    font-size: 28px !important;
    margin-left: 25px;
    // background-color: red;
    color: #fff;
    background-color: #334928;
  }

  .clearfix {
    .service-container-menu-toggle {
      margin-right: 24px;
      margin-left: 10px;
    }
  }

  .priceCalculationInputSection {
    // margin-top: 10px;
    display: flex;
    overflow: hidden !important;
    flex-wrap: wrap;
    align-items: center;
    // gap: 10px;
    .priceCalculationInput {
      margin-right: 10px;
      //width: 200px;
      display: block;

      input {
        min-height: unset;
        text-align: right;
      }
    }

    .priceCalculationInput-select {
      .ant-input {
        // width: 300px;
      }
    }

    .subtotal-input {
      .ant-input {
        width: 150px;
      }
    }

    .rent-input {
      .ant-input {
        width: 80px;
      }
    }

    .ppu-input {
      .ant-input {
        width: 130px;
      }
    }

    .service-rent-input {
      .ant-input {
        width: 80px;
      }
    }

    .service-total {
      display: flex;
      align-items: center;
      .service-total-element {
        margin: 0 5px;

        .service-total-price {
          font-size: larger;
          font-weight: 600;
        }

        &.rent-higher-than-price,
        &.rent-higher-than-price .elevation-total-priceSubtotal {
          text-decoration-line: underline;
          text-decoration-style: wavy;
          text-decoration-color: #a40000;
        }
      }
    }
  }

  //for hoist

  .lift-height-warning-message {
    padding: 5px;
    vertical-align: middle;
  }

  input.ant-checkbox-input {
    min-height: unset !important;
  }

  .ant-modal-body {
    border-radius: 0;
    padding: 10px !important;
    // overflow: auto;
    overflow-y: hidden;
    height: calc(100vh - 50px) !important;
  }

  .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    width: 3px;
  }

  .ant-tabs-tab {
    //transition: 250ms;
    margin: 0 !important;
    padding: 0 !important;

    .tabText {
      //transition: 200ms;
      font-weight: bold;
      font-size: 15px;
      writing-mode: vertical-rl;
      text-orientation: mixed;
      transform: rotate(180deg);
    }
  }

  .ant-tabs-content {
    border: 0;
  }

  .optionText {
    font-size: 16px;
    margin-top: 15px;
    padding-top: 10px;

    .option-header {
      width: 100%;
      .prices {
        float: right;
        display: flex;
      }

      // .option-header-tooltip {
      //   //display: flex;
      //   //flex-flow: column;
      //   //flex-wrap: wrap;
      // }

      .option-header-element {
        margin: 0 5px;

        .elevation-total-priceSubtotal {
          font-size: 14px;
          font-weight: bold;
        }

        &.rent-higher-than-price,
        &.rent-higher-than-price .elevation-total-priceSubtotal {
          text-decoration-line: underline;
          text-decoration-style: wavy;
          text-decoration-color: #a40000;
        }

        &.rent {
          // color: #7abdff;
          color: #1264a3;
        }

        &.price {
          color: #6d9c4a;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        // &.units {
        //   color: #6d9c4a;
        //   display: flex;
        //   align-items: center;
        //   gap: 10px;
        // }
      }
    }
  }

  .ant-collapse {
    margin-top: 10px;

    .ant-collapse-header {
      height: auto;
      border-radius: 5px !important;
      // animation: heartbeat 2s linear infinite;
      // animation: spin 2s linear infinite;
      //transition: 300ms;
    }
  }

  .gridResizer {
    //transition: 300ms;
    opacity: 0;
    cursor: ns-resize;
    width: 100%;
    height: 6px;
    display: flex;

    .gridResizerInnerLeft {
      width: calc(50% - 25px);
      height: 2px;
      border-bottom-left-radius: 2px;
    }

    .gridResizerInnerRight {
      width: calc(50% - 25px);
      height: 2px;
      border-bottom-right-radius: 2px;
    }

    .gridResizerHandler {
      width: 50px;
      height: 7px;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;

      .gridResizerHandlerGrip {
        width: 20px;
        height: 1px;
        margin: 1px auto 0 auto;
      }
    }

    &:hover {
      .gridResizerInnerLeft {
        height: 7px;
      }

      .gridResizerInnerRight {
        height: 7px;
      }

      .gridResizerHandler {
        border-radius: 0;
      }
    }

    &:active {
      .gridResizerInnerLeft {
        height: 7px;
      }

      .gridResizerInnerRight {
        height: 7px;
      }

      .gridResizerHandler {
        border-radius: 0;
      }
    }
  }

  .elevationContainer {
    .takeOffElevation {
      width: 100% !important;
      .ag-root-wrapper {
        width: 100% !important;
      }
    }
    width: 100% !important;
    .addonsContainer {
      padding: 15px;
    }

    .addAddon {
      width: fit-content;
      margin: 15px auto 0 auto;
    }
  }

  .elevationPriceCalculationContainer {
    margin-left: 40px;

    .beforeText {
      margin-right: 10px;
    }

    .priceCalculation {
      height: 32px;
      min-width: 100px;
      width: auto;
      max-width: 60%;
      word-spacing: 1px;
      margin-top: -3px;
      cursor: text;
      margin-right: 15px;
    }
  }

  .service-documentation-container {
    .service-documentation {
      height: 40px;
      .documentation-input-container {
        margin: 0 20px;
      }
    }
    #dark-input {
      background-color: #272b2d;

      color: #ffffff;
      border: none;
    }
    #light-input {
      background-color: #fff;
      color: #272b2d;
      border: none;
    }
  }

  .approved-row {
    font-weight: 700;
  }

  .price-align,
  .dimension {
    justify-content: flex-end;
  }

  .lock {
    .checkboxRenderer-lock-true {
      padding: 0;
      margin: 0 auto;
      display: block;
    }

    [col-id="floor_height"] {
      font-weight: 800;
      font-size: larger;
    }
  }

  .ppu-advisor-cell {
    .ppu-difference {
      font-weight: 100;
      font-size: 10px;
      margin-right: 10px;
      min-width: 40px;
    }
    .ant-btn {
      &:disabled {
        border-radius: 3px !important;
      }
    }
  }

  .checkboxRenderer-lock-false {
    padding: 0;
    margin: 0 auto;
    display: block;
  }

  .jump-renderer {
    display: flex;
    align-items: center;

    .jump-input {
      margin-left: 5px;
      width: 60px;
      padding: 2px;
    }
  }

  .checkboxRenderer-isJump-false {
    padding: 0;
    //margin: 0 auto;
    display: flex;
    width: 16px;
    height: 16px;
    border-radius: 2px;
  }

  .checkboxRenderer-isJump-true {
    padding: 0;
    //margin: 0 auto;
    display: flex;
    width: 16px;
    height: 16px;
    border-radius: 2px;

    span {
      text-align: center;

      img {
        //width: 16px
        vertical-align: center;
      }
    }
  }

  .addons-count-number {
    display: inline-block;
    font: normal normal normal 14px Open Sans;
  }

  .addons-count-zero {
    display: inline-block;
    font: normal normal normal 14px Open Sans;
  }

  .dataEntryGrid {
    .ag-cell-label-container {
      .ag-header-cell-label span.ag-header-cell-text {
        font-family: "Lato", sans-serif;
        font-size: 12px;
        font-weight: bold;
      }
    }

    .ant-select-search__field {
      min-height: 16px !important;
    }
  }

  .cell-wrap-text {
    white-space: normal !important;
    line-height: normal !important;
    // background-color: red;
    display: flex;
    align-items: center;
  }

  .ag-text-field-input::selection,
  .ag-react-container {
    width: 100% !important;
  }

  .ag-ltr .ag-cell {
    p {
      line-height: normal !important;
      margin: 2px !important;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif !important;
      font-weight: 400 !important;
      font-size: 12px !important;
    }

    h1 {
      line-height: normal !important;
      margin: 0 !important;
      color: unset !important;
    }

    h2 {
      line-height: normal !important;
      margin: 0 !important;
      color: unset !important;
    }

    strong {
      font-weight: bold !important;
    }
  }

  .ag-cell-inline-editing {
    height: 100% !important;
  }

  .expandedServicesContainer {
    // background-color: #12131b;
    height: calc(100vh - 140px);
    overflow-y: auto;
    overflow-x: hidden;
    // margin: 10px;
    margin-top: 10px;
    border-radius: 10px;
    // margin-left: 45px;
    margin-bottom: 10px !important;
    padding-right: 5px;
    scrollbar-color: #1f2a44;
    &::-webkit-scrollbar-thumb {
      color: #1f2a44;
      background-color: #1f2a44;
    }
    .ag-body-horizontal-scroll-viewport {
      background-color: #41435d !important;
      //aggrid scroll color
      // &::-webkit-scrollbar-thumb {
      //   color: red !important;
      //   background-color: red !important;
      // }
    }
  }

  .serviceContainer {
    // margin-bottom: 50px;
    padding: 10px;
    border-radius: 10px;
    // margin-left: -20px;
    // width: 1690px;
    margin-bottom: 20px;
    margin-left: 5px !important;

    .total-and-rent-container {
      .total-and-rent-element {
        margin: 0 0 0 10px;
        font-size: large;
      }
    }

    .service-header {
      .service-title {
        margin-top: 10px;
        display: inline-block;
        width: 100%;
      }

      .service-actions {
        // display: inline-block;
        // float: right;
      }

      .price-input-label {
        margin-right: 5px;
        input {
          // background-color: red;
        }
      }
    }

    .text-center {
      // margin-top: 15px;
      width: 100%;
      display: flex;
      justify-content: center;
    }

    .pricing-button {
      // margin: 7px 0 0 15px;
      // margin: 5px 0 0 5px;
      height: 100%;
      display: flex;
      align-items: center;
    }

    .ant-collapse-extra {
      svg {
        margin-left: 5px;
        font-size: 30px;
      }
    }
  }

  .serviceMenuContainer {
    width: fit-content;
    // margin-right: 15px;
    padding: 20px;

    // width: 260px;
    // margin-top: 10px;
    // padding-top: 20px;
    // padding-bottom: 20px;
    // height: auto;
    border-radius: 10px;
    margin-left: 5px;
    // font-size: small;
    .add-service-btn-cont {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .hide-toggle-container {
      margin-top: 10px;
      margin-bottom: 10px;
      display: flex;
      width: 100%;
      justify-content: center;
      gap: 5px;
      .ant-btn {
        width: 100px;
        background-color: #12131b !important;
      }
    }
    // .serviceLabel {
    //   color: #fff !important;
    // }
    .react-grid-layout {
      .serviceCheckbox {
        // height: 36px !important;
        // display: flex;
        // padding-top: 3.5px;

        color: #fff !important;
        // .serviceLabel {
        //   color: #fff !important;
        // }
        .ant-checkbox {
          border: 5px solid #fe4c4a;
          border-radius: 2px;
          margin-top: -5px;

          .ant-checkbox-inner {
            border-radius: 0;
            border: 2px solid #ff7274;
            background-color: #eb4345;
            height: 14px;
            width: 14px;
          }
        }

        //checked
        .ant-checkbox-checked {
          border: 5px solid #68c142;

          .ant-checkbox-inner {
            border-radius: 0;
            border: 2px solid #71cf48;
            background-color: #68c142;
            height: 14px;
            width: 14px;
            content: url("../../../pages/Settings/settingsComponents/Roles/src/TickIcon.svg");
          }

          //indeterminate
          .ant-checkbox-indeterminate {
            border: 5px solid #1264a3;
            border-radius: 2px;

            .ant-checkbox-inner {
              border-radius: 0;
              border: 2px solid #1c7ac2;
              background-color: #0f5c97;
              height: 14px;
              width: 14px;
              padding: 2px;
              content: "";
              &::after {
                background-color: white;
              }
            }
          }
        }
      }
      .checkboxContainer {
        // width: 235px;
        // margin-left: 12px;
        display: flex;
        height: 100%;
        align-items: center;
        // justify-content: center;
        color: #fff;
        // .serviceLabel {
        //   color: #fff !important;
        // }
      }
    }

    .appendServiceButton {
      width: 100%;
      height: 34px;
      border-radius: 0;
      margin-bottom: 15px;

      img {
        margin-right: 5px;
      }
    }

    .hideShowAllServicesButton {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 50%;
      height: 34px;
      border-radius: 0;
      margin-bottom: 3px;
      padding: 0;

      svg {
        margin-right: 5px;
      }
    }

    .checkboxContainer {
      height: 100%;
      display: flex;
      align-items: center;
      // white-space: nowrap;
      // // overflow: hidden;
      // // text-overflow: ellipsis;
      // // //transition: 350ms;
      // // width: 100%;
      // display: inline-block;
      // // border-radius: 0;
      // height: 36px;
      // display: flex;
      // align-items: center;
      // cursor: grab;
      // // font-size: 16px;
      // margin-bottom: 3px;
      // padding-right: 5px;
      // // padding: 4px 0 0 12px;
      // // color: #fff;
      .serviceLabel {
        margin-left: 12px;
      }

      &:active {
        cursor: grabbing;
      }
    }
  }

  .circleShape {
    width: 11px;
    height: 11px;
    border-radius: 50%;
    margin: 0 11px 4px 5px;
    display: inline-block;
  }

  .emptyPlaceholder {
    transform: translateY(25vh);

    span {
      font-size: 20px;
    }
  }

  .elevation-panel-header {
    display: flex;
    float: left;
    flex-wrap: wrap;
    font-weight: 400 !important;
    align-items: center;
    .elevation-panel-price-container {
      display: flex;
      flex-direction: column;
      font-size: 14px;

      .priceCalculationInput {
        //width: 200px;
        margin-left: 24px;
        margin-right: 20px;

        .ant-input-group-addon {
          min-height: unset;
        }

        .ant-input {
          min-height: unset;
          width: 100px;
        }

        .price-input-label {
          margin-right: 5px;
        }

        input {
          border: none;
          min-height: unset;
          width: 120px;
          text-align: end;
        }

        .rent-higher-than-price {
          text-decoration-line: underline;
          text-decoration-style: wavy;
          text-decoration-color: #a40000;
        }
      }

      .elevation-total {
        .elevation-total-element {
          margin-right: 10px;
          padding-right: 10px;
          display: flex;
          text-align: end;
          gap: 5px;
        }

        .elevation-total-priceSubtotal {
          font-size: 14px;
          // min-width: 80px;
          display: inline-block;
          font-weight: 600;
        }

        .elevation-total-length {
          font-size: 14px;
          min-width: 40px;
          display: inline-block;
        }
      }
    }
  }

  .elevationLabel {
    height: 32px;
    min-width: 220px;
    padding: 4px 11px;
    max-width: 500px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 32px;
    display: flex;
    align-items: center;
    border: 1px solid #ccc; // Add border to indicate the editable area
    cursor: text; // Make it look like a text input
    &[contenteditable="true"] {
      &:focus {
        white-space: normal; // Allow the text to wrap when focused for editing
      }
    }
  }

  .appendElevation {
    // margin-top: 15px;
    // padding: 4px 85px 4px 82px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 280px;
    padding: 0px;

    span {
    }

    img {
      margin-top: -2px;
    }
  }

  .rowModifierAdd {
    transform: scale(0.8);
    border-color: #43a040 !important;
    background-color: #43a040 !important;
    color: #ffffff !important;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background-color: #53b783 !important;
    }

    &:active {
      background-color: #0c9153 !important;
    }
  }

  .rowModifierDuplicate {
    transform: scale(0.8);
    border-color: #005fb8 !important;
    background-color: #1890ff !important;
    color: #ffffff !important;

    &:hover {
      background-color: #40a9ff !important;
    }

    &:active {
      background-color: #096dd9 !important;
    }
  }

  .rowModifierRemove {
    transform: scale(0.8);
    border-color: #e53936 !important;
    background-color: #e53936 !important;
    color: #ffffff !important;

    &:hover {
      background-color: #fa4848 !important;
    }

    &:active {
      background-color: #ba1d1d !important;
    }
  }

  .hoistForm {
    padding-top: 5px;
    .hoistFormSectionParent {
      margin-bottom: 5px !important;
      // padding-bottom: 10px !important;
      // padding-top: 10px !important;
      padding: 10px !important;
      background-color: #2a2b3a;
      display: flex;
      // gap: 20px;
      justify-content: space-between;
      .hoistFormSection {
        background-color: #2a2b3a;
        display: inline-block;
        // margin-right: 35px;
        vertical-align: bottom;

        .ant-checkbox-checked {
          // background-color: red;
        }
        .hoistFormSectionChild {
          display: inline-block;
          // background: #12131B;
          .applyForceHeightBtn {
            background-color: #3a3c4e;
            width: 120px;
            height: 32px;
            border-radius: 4px;
            color: #fff;
            border: none;
            margin-left: 20px;
          }
          &.tagSelect {
            height: 32px;
            // margin-top: 32px;

            .addonBefore {
              font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 21px;
              padding: 7px 11px;
              height: 32px;
              display: inline-block;
            }

            .speedComp {
              // antd input
            }

            .selectComponent {
              width: auto;
              min-width: 200px;
              display: inline-block;

              .ant-select-selector {
                background-color: #3a3c4e;
                border: none;
                height: 32px;
              }
              // .ant-checkbox-inner {
              //   border-radius: 2px !important;
              // }

              // top: -3px;
              // antd select style
              // .ant-select-selection {
              //   height: 32px;
              //   border-radius: 5px;
              //   border: 1px solid #d9d9d9;
              //   box-sizing: border-box;
              //   border-radius: 5px;
              //   padding: 0 11px;
              //   font-family: Lato, "Helvetica Neue", Arial, Helvetica,
              //     sans-serif;
              //   font-style: normal;
              //   font-weight: 400;
              //   font-size: 14px;
              //   line-height: 21px;
              //   color: #000000;
              //   background: #ffffff;
              //   border: 1px solid #d9d9d9;
              //   box-sizing: border-box;
              //   border-radius: 5px;
              //   padding: 0 11px;
              //   height: 32px;
              //   display: flex;
              //   align-items: center;
              //   justify-content: space-between;
              //   .ant-select-selection__rendered {
              //     display: flex;
              //     align-items: center;
              //     justify-content: space-between;
              //     .ant-select-selection__placeholder {
              //       color: #000000;
              //     }
              //   }
              //   .ant-select-selection__choice {
              //     margin-top: 6px;
              //   }
              //   .ant-select-search__field {
              //     margin-top: -2px;
              //   }
              // }
              // .ant-select-selection {
              //   height: 30px;

              //   .ant-select-selection__choice {
              //     margin-top: 6px;
              //   }

              //   .ant-select-search__field {
              //     margin-top: -2px;
              //   }
              // }
            }
            &.hoistInfo {
              display: flex;
              gap: 5px;
              .addonBefore {
                background-color: #2a2b3a;
              }
            }

            .stopsInfo {
              display: flex;
              gap: 5px;
              flex-direction: row;
            }

            &.leftPartial {
              display: flex;
              align-items: center;
              flex-direction: row;

              .addonBefore {
                border-radius: 5px 0 0 5px;
                background-color: #2a2b3a;
              }

              .selectComponent .ant-select-selection {
                border-radius: 0;
              }
            }

            &.rightPartial {
              .selectComponent .ant-select-selection {
                border-bottom-left-radius: 0;
                border-top-left-radius: 0;
              }
            }
          }
        }

        .ant-select {
          width: 150px;
        }
      }

      .hoistFormLabel {
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 5px;
        // antd checkbox style red
        .ant-checkbox-inner {
          background-color: grey;
          border: none;
          border-radius: 50%;
        }

        .ant-checkbox-checked {
          border-radius: 10px;

          .ant-checkbox-inner {
            background-color: #71cf48;
            // border-color: #e53936;
            border: none;
            border-radius: 10px;
          }
        }
      }
    }

    .addShoring {
      padding: 4px 110px;
      background-color: transparent;

      &:hover {
        background-color: #303030;
      }

      &:active {
        background-color: #1c1c1c;
      }
    }
  }

  .approved-header {
    overflow: hidden;
    .checkboxRenderer-approved {
      margin-right: 5px;
    }
  }

  .ag-group-value {
    margin-top: 4px;
  }

  .ag-row-level-1 {
    overflow-y: auto;

    .sidewalkShedDetailContainer {
      padding: 5px 15px;
      overflow-y: auto;
      width: 100%;
      .sidewalkShedDetailHeader {
        margin-bottom: 10px;
      }

      .ant-typography {
        display: inline-block;
      }
    }
  }
}

.dataEntryModal.ag-theme-balham-dark {
  color: #fff !important;
  width: 100vw !important;
  height: 100vh;
  .ag-theme-alpine-dark {
    --ag-border-color: #616481;
    --ag-background-color: #2a2b3a;
    --ag-foreground-color: #fff;
    --ag-secondary-border-color: #61648185;
    --ag-modal-overlay-background-color: #2a2b3a;
    --ag-header-background-color: #0f5c97;
    --ag-tooltip-background-color: #222628;
    --ag-odd-row-background-color: #2a2b3a;
    --ag-control-panel-background-color: #004040;
    --ag-subheader-background-color: #2a2b3a;
    --ag-input-disabled-background-color: #282c2f;
    --ag-input-focus-box-shadow: 0 0 2px 0.5px rgba(255, 255, 255, 0.5),
      0 0 4px 3px var(--ag-input-focus-border-color);
    --ag-card-shadow: 0 1px 20px 1px black;
    --ag-disabled-foreground-color: rgba(255, 255, 255, 0.5);
    --ag-chip-background-color: rgba(255, 255, 255, 0.07);
    --ag-input-disabled-border-color: rgba(104, 104, 110, 0.3);
    --ag-input-disabled-background-color: rgba(104, 104, 110, 0.07);
    // box-shadow: 0 0 10px #00ff00 !important;
    .ag-root-wrapper {
      box-shadow: 0 0 10px #198de6 !important;
      border-radius: 10px;
      height: 100%; // Expand to 100% height
      // transition: height 2s ease;
      z-index: 1000000;
      // transform: translate(-50%, -50%) scale(0);
      // opacity: 0;
      // animation: elevator-animation 0.5s ease-in-out forwards;
      // animation: slide-in-and-fade 1.3s ease-in-out forwards;
    }
    .takeOffElevation {
      padding: 10px;
    }
  }

  .service-total {
    color: #f5f5f5;

    .service-total-element {
      &.rent {
        color: #7abdff;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
      }

      &.price {
        color: #71cf48;
        color: #7abdff;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
      }
    }
  }
  .clearfix {
    .service-container-menu-toggle {
      color: #fff;
      background-color: #4a4a4a;
    }
  }

  .ant-modal-close-x {
    color: #f5f5f5;
    svg {
      height: 17px;
      width: 17px;
    }
  }
  .service-header-alert {
    background: #3a3c4e;
    color: white;
    border: none;
    .ant-alert-close-icon {
      margin-inline-start: 10px;
      svg {
        fill: white;
        transform: scale(0.7);
      }
    }
  }

  .ant-select-selection-overflow {
    color: #fff;
    .ant-select-selection-overflow-item {
      // color: black;
      .ant-select-selection-item {
        background-color: #71cf48;
        border: none;
        color: #fff;
      }
    }
  }
  .excludingSelect {
    color: #fff;
    .ant-select-selection-overflow-item {
      // color: black;
      .ant-select-selection-item {
        background-color: #fe4c4a !important;
        border: none;
        color: #fff;
      }
    }
  }

  .optionText {
    font-size: 16px;
    margin-top: 15px;
    padding-top: 10px;

    .option-header {
      .prices {
        float: right;
        display: flex;
      }

      // .option-header-tooltip {
      //   //display: flex;
      //   //flex-flow: column;
      //   //flex-wrap: wrap;
      // }

      .option-header-element {
        margin: 0 5px;

        .elevation-total-priceSubtotal {
          font-size: 14px;
          font-weight: bold;
        }

        &.rent-higher-than-price,
        &.rent-higher-than-price .elevation-total-priceSubtotal {
          text-decoration-line: underline;
          text-decoration-style: wavy;
          text-decoration-color: #a40000;
        }

        &.rent {
          // color: #7abdff;
          color: #7abdff;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 5px;
        }

        &.price {
          color: #71cf48;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 5px;
        }
      }

      .service-total {
        color: #f5f5f5;

        .service-total-element {
          &.rent {
            color: #7abdff;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 5px;
          }

          &.price {
            color: #71cf48 !important;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 5px;
          }
        }
      }
    }
  }

  .ant-modal-header {
    background-color: #12131b;
    border-color: #4a4a4a;

    .ant-modal-title {
      color: #f5f5f5;
    }
  }

  .ant-input-affix-wrapper {
    background-color: #12131b;
    color: #f5f5f5;
    border: none;
  }

  .dataEntryTitle {
    color: #fff;
    // background-color: #c6efce;
    //text-shadow: 0 0 2px #fff, 0 0 4px #ffffff, 0 0 6px #764203, 0 0 10px #d49800, 0 0 20px #ce6d00, 0 0 30px #bd9000;
  }

  // .ant-btn {
  //   background-color: #4a4a4a;
  //   border-color: #414141;
  //   color: #f5f5f5;
  //   height: 30px;

  //   &:hover {
  //     background-color: #5d5d5d;
  //   }

  //   &:active {
  //     background-color: #212121;
  //   }

  //   &:disabled:not(.ant-btn-primary) {
  //     background-color: #000000;

  //     &:hover {
  //       color: #5d5d5d;
  //       border-color: #5d5d5d;
  //     }
  //   }
  // }

  // .ag-row-hover {
  //   //background-color: #5d5d5d;
  // }

  .priceCalculationInputSection {
    overflow: hidden !important;

    .priceCalculationInput {
      // margin-right: 10px;
      //width: 200px;
      // display: block;

      .ant-input {
        border: none;
        // background-color: red;
        color: #f5f5f5;

        &::selection {
          background-color: ghostwhite;
        }

        &:focus {
          box-shadow: none;
        }
      }

      .ant-input-group-addon {
        border: none;
        // background-color: #5b5e62;
        color: #f5f5f5;
      }

      .ant-select {
        .ant-select-selector {
          background-color: #3a3c4e;
          border: none;

          .ant-select-selection-item {
            color: white;
          }
        }

        .ant-select-arrow {
          svg path {
            fill: white;
          }
          color: white;
        }
      }

      .ant-input-affix-wrapper {
        background-color: #1c1c1c;
        color: #f5f5f5;
        border: none;
      }
    }

    // .service-total {
    //   color: #f5f5f5;

    //   .service-total-element {
    //     &.rent {
    //       color: #7abdff;
    //     }

    //     &.price {
    //       color: #6d9c4a;
    //     }
    //   }
    // }
  }
  //for hoist
  .stops-count-message,
  .total-floor-height {
    color: #f5f5f5;
  }

  .floor-height-message {
    color: #f5f5f5;
    .floor-height-message-difference {
      color: orange;
    }
  }

  .lift-height-warning-message {
    color: orange;
  }

  .ant-dropdown .ant-dropdown-menu {
    background-color: #272b2d !important;
  }

  .ant-btn-primary {
    background-color: #1890ff;
    border-color: #1780e2;
    color: #f5f5f5;

    &:hover {
      background-color: #40a3ff;
    }

    &:active {
      background-color: #1372c6;
    }

    &:disabled {
      background-color: #000000 !important;
      border-color: #1890ff !important;

      &:hover {
        color: #5d5d5d !important;
        border-color: #5d5d5d !important;
      }
    }
  }

  .ant-switch {
    border-color: #4a4a4a;
  }

  .ant-modal-body {
    background-color: #070f2b;
    // background: url("https://wallpapercave.com/wp/wp9263336.jpg");
    background-size: cover;
    overflow: none;
    animation: slide-in-and-fade 1.3s ease-in-out forwards;
  }

  .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    background-color: #f5f5f5 !important;
  }

  .ant-tabs-bar {
    border-color: #4a4a4a !important;
  }

  .ant-tabs-tab {
    border-bottom: solid 1px #4a4a4a;

    &:first-child {
      border-top: solid 1px #4a4a4a;
    }

    .tabText {
      color: #aeaeae;

      &:hover {
        //transition: 400ms;
        color: #f5f5f5;
      }
    }
  }

  .ant-tabs-tab-active {
    .tabText {
      color: #ffffff;
    }
  }

  .optionText {
    color: #f5f5f5;
    border-top: solid 1px #4a4a4a;
  }

  .ant-collapse {
    // border-color: #4a4a4a;
    border: none;
    border-radius: 5px;
    background-color: #12131b;

    .ant-collapse-item:hover {
      .ant-collapse-header {
        //transition: 300ms;
        background-color: #20212d;
        border-radius: 5px !important;

        // background-color: #20212d;
      }

      .ant-collapse-content {
        background-color: #12131b !important;
        color: #fff;
      }

      .gridResizer {
        opacity: 1;
      }
    }

    .ant-collapse-header {
      color: #f5f5f5;
      height: auto;
      //transition: 300ms;
      background-color: #20212d;
      border-radius: 5px !important;
    }

    .ant-collapse-content {
      background-color: #12131b !important;
      border-radius: 5px;
      // border-color: #4a4a4a;
      border: none;
      //transition: 300ms !important;
      color: #fff;
    }
  }

  .ant-collapse-item {
    // border-color: #4a4a4a;
    // background-color: red;
    border: none;
  }

  .gridResizer {
    opacity: 0;

    .gridResizerInnerLeft {
      background-color: #007ac3;
    }

    .gridResizerInnerRight {
      background-color: #007ac3;
    }

    .gridResizerHandler {
      background-color: #007ac3;

      .gridResizerHandlerGrip {
        background-color: #78a1ba;
      }
    }

    &:hover {
      .gridResizerInnerLeft {
        background-color: #12a3fa !important;
      }

      .gridResizerInnerRight {
        background-color: #12a3fa !important;
      }

      .gridResizerHandler {
        background-color: #12a3fa !important;
      }
    }

    &:active {
      opacity: 1;

      .gridResizerInnerLeft {
        background-color: #09659b !important;
      }

      .gridResizerInnerRight {
        background-color: #09659b !important;
      }

      .gridResizerHandler {
        background-color: #09659b !important;
      }
    }
  }

  .elevationContainer {
    .addonsContainer {
      color: white;
    }

    .leftQuoteBlock {
      border-left: solid 5px #f5f5f5;
    }
  }

  .elevationPriceCalculationContainer {
    .priceCalculation {
      background-color: #272b2d;

      color: #f5f5f5;
      border: none;

      &:hover {
        background-color: #383b3c !important;
      }

      &:focus {
        background-color: #383b3c;
      }

      &::selection {
        background-color: #ffffff;
      }
    }
  }

  .approved-header {
    .ant-checkbox-wrapper-checked.checkboxRenderer-approved {
      .ant-checkbox-inner {
        background-color: #43a040;
        border-color: #43a040;
      }
    }
  }

  .approved-row {
    color: #95ec92 !important;

    .checkboxRenderer-approved {
      .ant-checkbox-checked {
        .ant-checkbox-inner {
          background-color: #43a040;
          border-color: #43a040;
        }
      }
    }

    [col-id="id"] {
      border-left: 7px solid #43a040;
    }
  }

  .invalid-price {
    color: #e3961c;
  }

  .out-of-range-dimension-higher {
    //font-family: monospace;
    //font-style: oblique;
    text-decoration-line: underline;
    text-decoration-style: wavy;
    text-decoration-color: #a40000;
  }

  .out-of-range-dimension-lower {
    //font-family: monospace;
    //font-style: oblique;
    text-decoration-line: underline;
    text-decoration-style: double;
    text-decoration-color: #a40000;
  }

  .lock {
    [col-id="ppu"],
    [col-id="price"],
    [col-id="rent"],
    [col-id="lock"] {
      background: linear-gradient(
        to right,
        #2d3436 0%,
        #5a541e60 20%,
        #2d3436 50%,
        #5a541e60 80%,
        #2d3436 100%
      );
    }

    .checkboxRenderer-lock-true {
      color: #c8af35;
    }
  }

  .id-column {
    background-color: #1a1b1c;
  }

  .ppu-advisor-cell {
    .ppu-difference {
      color: #fff;
    }
    .ant-btn {
      background-color: #1890ff !important;
      border-color: #1780e2 !important;
      color: #f5f5f5 !important;

      &:disabled {
        border: 1px solid #188ffe !important;
        border-radius: 3px !important;
        color: #188ffe !important;
        background: transparent !important;
      }
    }
  }

  .jump-renderer {
    .jump-input {
      border: 1px solid #4f4f4f;
      background-color: #272b2d;
    }
  }

  .checkboxRenderer-isJump-false {
    background-color: #fff;
    border: 1px solid #d9d9d9;

    &:hover {
      border-color: #1890ff;
      background-color: #fff;
    }
  }

  .checkboxRenderer-isJump-true {
    background-color: #1890ff;
  }

  .addons-count-number {
    display: inline-block;
    font: normal normal normal 14px Open Sans;
    color: #ffffff;
  }

  .addons-count-zero {
    color: #545454;
  }

  .dataEntryGrid-cell-select-DARK {
    .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
      -webkit-text-fill-color: #fff !important ;
    }
    .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
      -webkit-text-fill-color: #fff !important ;
    }
  }
  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
    -webkit-text-fill-color: #fff !important ;
  }
  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    -webkit-text-fill-color: #fff !important ;
  }

  .dataEntryGrid {
    position: relative;
    .ag-root-wrapper-body.ag-layout-normal {
      height: fit-content !important;
      min-height: 70px !important;
    }

    .dataEntryGrid-cell-select:not(.Mui-disabled) {
      color: #fff;

      svg {
        color: #fff;
      }
    }

    .dataEntryGrid-cell-select.Mui-disabled {
      color: #a7a7a7;
      div {
        -webkit-text-fill-color: unset;
      }

      svg {
        color: #a7a7a7;
      }
    }

    .ag-cell-label-container {
      .ag-header-cell-label span.ag-header-cell-text {
        color: #a7a7a7;
      }
    }
  }

  // .ag-text-field-input::selection,
  // .ag-text-area-input::selection {
  //   background: #ffffff;
  // }

  .ag-react-container {
    .ant-btn {
      background-color: #1890ff;
    }
  }

  .ag-cell:not(.ag-cell-focus):not(.ag-cell-range-selected) {
    border-right: solid 1px #4f4f4f !important;
  }

  .serviceMenu {
    .react-grid-item {
      //transition-duration: 0s !important;
      width: 100%;
      height: 50px;
      &.react-grid-placeholder {
        background-color: #00eaff !important;
        opacity: 0.4 !important;
      }
    }
  }

  .serviceContainer {
    background-color: #12131b;
    .total-and-rent-container {
      .total-and-rent-element {
        color: #f5f5f5;
      }
    }

    .service-header {
      .service-title {
        color: #f5f5f5;
      }

      .service-header-alert {
        background: #3a3c4e;
        color: white;
        border: none;

        .ant-alert-icon {
          svg {
            fill: #f6cb51;
            transform: scale(1.3);
          }
        }
        .ant-alert-close-icon {
          margin-inline-start: 20px;
          // margin-inline-end: 10px;
          svg {
            fill: white;
            transform: scale(0.7);
          }
        }
      }

      input {
        // background-color: #272b2d;
        background-color: #1f212d;

        color: #ffffff;
        border: none;
        // width: 100%;
        width: 100px;
        height: 32px;
      }

      .text-center {
        color: #fff;
      }

      .pricing-button {
        button {
          // background-color: #3a3c4e;

          color: #ffffff;
          border: none;
        }
      }
    }

    .ant-collapse-extra {
      svg {
        &:hover {
          color: #40a9ff;
        }
      }
    }
  }

  .serviceMenuContainer {
    // margin-right: 15px;
    background-color: #12131b;
    color: #fff !important;

    .appendServiceButton {
      background-color: transparent;
      border: 1px solid #4f4f4f;

      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
        border-color: #d7d7d7;
      }
    }

    .hideShowAllServicesButton {
      background-color: transparent;
      border: 1px solid #4f4f4f;

      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
        border-color: #d7d7d7;
      }
    }

    .checkboxContainer {
      background-color: #20212d;
      // border: 1px solid #4f4f4f;
      color: #f5f5f5 !important;

      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
        color: #ffffff !important;
        border-color: #d7d7d7;
      }
    }
  }

  .emptyPlaceholder {
    span {
      color: #6b6b6b !important;
    }
  }

  .elevation-panel-header {
    .elevation-panel-price-container {
      .priceCalculationInput {
        .ant-input-group-addon {
          border: none;
          background-color: #5b5e62;

          color: #f5f5f5;
        }

        .ant-input {
          border: none;
          background-color: #12131b;
          color: #f5f5f5;
        }

        .ant-input-affix-wrapper {
          background-color: #12131b;
          color: #f5f5f5;
          border: none;
        }

        input {
          background-color: #12131b;
          color: #ffffff;
          border: none;
        }

        .rent-higher-than-price {
          text-decoration-line: underline;
          text-decoration-style: wavy;
          text-decoration-color: #a40000;
        }
      }

      .elevation-total {
        .elevation-total-element {
          &:not(:last-child) {
            border-right: 1px #707070 solid;
          }
        }

        .rent-higher-than-price,
        .rent-higher-than-price .elevation-total-priceSubtotal {
          text-decoration-line: underline;
          text-decoration-style: wavy;
          text-decoration-color: #a40000;
        }

        .flash-true {
          animation: pulsatingDark 2s ease-in-out infinite both;
          //color: #0c9153;
        }

        @keyframes pulsatingDark {
          //from {
          //  transform: scale(1);
          //  transform-origin: center center;
          //  animation-timing-function: ease-out;
          //}
          //10% {
          //  transform: scale(0.91);
          //  animation-timing-function: ease-in;
          //}
          //17% {
          //  transform: scale(0.98);
          //  animation-timing-function: ease-out;
          //}
          //33% {
          //  transform: scale(0.87);
          //  animation-timing-function: ease-in;
          //}
          //45% {
          //  transform: scale(1);
          //  animation-timing-function: ease-out;
          //}
          0%,
          20%,
          100% {
            text-shadow: 0 0 4px #fff, 0 0 11px #fff, 0 0 12px #fff,
              0 0 22px #bc13fe;
            //0 0 80px #bc13fe,
            //0 0 90px #bc13fe,
            //0 0 100px #bc13fe,
            //0 0 150px #bc13fe;
          }
          10%,
          60% {
            text-shadow: 0 0 2px #fff, 0 0 4px #fff, 0 0 6px #fff,
              0 0 10px #bc13fe, 0 0 30px #bc13fe;
            //0 0 55px #bc13fe,
            //0 0 70px #bc13fe,
            //0 0 80px #bc13fe;
          }
        }
      }
    }
  }

  .elevationLabel {
    background-color: #12131b;
    color: #f5f5f5;
    border: none;

    &:hover {
      background-color: #383b3c !important;
    }

    &:focus {
      background-color: #383b3c;
    }

    &::selection {
      background-color: #ffffff;
    }
  }

  .appendElevation {
    background-color: transparent;
    border: 1px dashed #71cf48;
    border-radius: 5px;
    color: #fff !important;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 280px;
    padding: 0px;

    svg {
      width: 10px;
      path {
        fill: #71cf48;
      }
    }
    span {
      width: 85%;
      padding-left: 20px;
    }

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      gap: 10px;
    }
    &:hover {
      background-color: #303030;
    }
  }

  .hoistForm {
    padding-top: 5px;

    .hoistFormSectionParent {
      .hoistFormSection {
        .hoistFormSelect {
          // antd select style
          .ant-select-arrow {
            span {
              svg {
                color: #fff !important;
                fill: #fff !important;
              }
            }
          }
          .ant-select-selector {
            background-color: #3a3c4e;
            border: none;
            color: #ffff;
            /// antd select arrow white svg
            ///
            .ant-select-arrow {
              span {
                svg {
                  color: #fff !important;
                  fill: #fff !important;
                }
              }
            }
          }
        }
        .hoistFormSectionChild {
          line-height: 2;

          // background-color: red;
          // antd input number style
          .ant-input-number-input-wrap {
            // background-color: red;
            height: 30px;
            input {
              // background-color: red;
              border: none;
              height: 30px;
              color: #ffff;
            }
          }
          .ant-input-number {
            // background-color: red;

            input {
              // background-color: red;
              border: none;
              height: 30px;
              color: #ffff;
            }
            border: none;
            .ant-input-number-handler-wrap {
              // background-color: none;
            }
            .ant-input-number-handler-wrap {
              display: none;
            }
          }

          .ant-input-number-input-wrap {
            border: none;
            background-color: #12131b;
            height: 30px;
            input {
              background-color: #12131b;
              border: none;
              height: 30px;
              color: #ffff;
            }
          }

          .ant-input-number-group-wrapper {
            margin-left: 20px;
            .ant-input-number-group {
              .ant-input-number-group-addon {
                background-color: #2a2b3a !important;
                border: none;
                color: #fff;
              }
            }
          }
          &.tagSelect {
            .addonBefore {
              height: 32px;
              color: #f5f5f5;
              // background-color: #444a4c;
            }

            .selectComponent {
              .ant-select-selection {
                border: none;
                background-color: #12131b;
                color: #f5f5f5;
                height: 30px;

                &:hover {
                  background-color: #383b3c;
                }

                &:focus {
                  background-color: #383b3c;
                }

                &::selection {
                  // background-color: #ffffff;
                }

                .ant-select-selection__choice {
                  background-color: #1c1c1c;
                  color: #f5f5f5;
                  border: none;

                  &:hover {
                    background-color: #292929;
                  }
                }

                .ant-select-selection__choice__remove {
                  color: #f5f5f5;
                }
              }
            }
          }
        }

        .ant-select {
          .ant-select-selection {
            background-color: #1c1c1c;
            border-color: #414141;

            .ant-select-selection-selected-value {
              color: #f5f5f5;
            }

            .ant-select-arrow {
              color: #f5f5f5;
            }
          }

          &:hover {
            .ant-select-selection {
              background-color: #303030;
            }
          }
        }
      }

      .hoistFormLabel {
        border-bottom: 1px solid grey;
        padding-bottom: 5px;
        color: #f5f5f5;
        background-color: #2a2b3a;
        // border-radius: 5px;
        display: flex;
        justify-content: center;
        // margin-bottom: 2px;
      }

      .ant-radio-group.ant-radio-group-solid {
        background-color: #2a2b3a;
        border: none !important;
        margin-left: 5px;
        border-radius: 5px;
        display: flex;
        gap: 10px;

        .ant-radio-button-wrapper {
          background-color: #3a3c4e;
          color: #f5f5f5;
          // border: none;
          width: 110px;
          border: none !important;
          border-radius: 5px;

          span {
            display: flex;
            justify-content: center;
            align-items: center;
          }

          &:hover {
            background-color: #383b3c;
          }

          &::before {
            border: none !important;
            display: none;
          }

          &.ant-radio-button-wrapper-checked {
            background-color: #71cf48;
            border: none !important;
            border-radius: 5px;
            color: #f5f5f5;
            // border: none;

            &:hover {
              background-color: #71cf48c7;
            }
          }
        }
      }

      .ant-input-group-addon {
        // background-color: #444a4c;'
        background-color: #2a2b3a;
        color: #f5f5f5;
        border: none;
      }

      .ant-input {
        background-color: #12131b;
        color: #f5f5f5;
        border: none;

        &:hover {
          background-color: #383b3c;
        }

        &::selection {
          background-color: #ffffff;
        }
      }
    }
    .firstHeader {
      // background-color: red;
      .hoistFormSection {
        display: flex;
        align-items: center;
      }
    }

    .id-column {
      background-color: #1a1b1c;
    }
  }

  .ag-virtual-list-item {
    color: #fff;
  }

  .ag-row-level-1 {
    .sidewalkShedDetailContainer {
      width: 100%;

      .ant-typography {
        color: #fff;
      }
    }
  }
}

.est-description-btn {
  margin-right: 15px !important;
  height: 32px !important;
}

.elevationContainer {
  .rowModifierButton {
    background-color: #188ffe !important;
  }
}

.vDivider {
  border-left: dashed 1px #4a4a4a;
  height: 32px;
  margin: 0 15px;

  &.rowModifier {
    margin: 0 3px;
  }

  &.whiteBorder {
    border-color: #f5f5f5;
  }
}

.serviceModal {
  width: calc(100vw - 35px) !important;
}

.serviceModal .dataEntryGrid {
  height: 60vh !important;
}

.serviceModal .ant-modal-body {
  background-color: #1f1f1f !important;
}

.serviceModal .ant-modal-footer {
  background-color: #1f1f1f !important;
}

.ag-dnd-ghost {
  z-index: 9999 !important;
}

.no-padding-antd-popover .ant-popover-inner-content {
  padding: 0 !important;
}

// style antd modal named loading-indic-takeoff

.loading-indic-takeoff {
  .ant-modal-wrap.ant-modal-centered {
    cursor: wait;
  }

  // cursor loading
  cursor: wait;

  .ant-modal-mask {
    cursor: wait;
  }

  .ant-modal-content {
    background-color: darkcyan !important;
    border-radius: 5px;
  }

  // hide ant close
  .ant-modal-close-x {
    display: none;
  }

  .ant-modal-body {
    border-radius: 5px;
    cursor: wait;

    background-color: darkcyan !important;
    // spotify font style

    font: 400 15.3333px Arial;

    color: white;
  }

  .ant-modal-footer {
    background-color: darkcyan !important;
  }
}

.dataEntryQuill {
  // min-width: 450px;
  min-width: 800px;

  &::-webkit-resizer {
    background: #1f1f1f;
    border-right: solid 2px #ffffff;
    border-bottom: solid 2px #ffffff;
  }

  button.ql-active {
    color: #ffffff !important;
  }

  .ql-editor strong {
    font-weight: bold;
  }

  .ql-editor p {
    color: #6d9c4a !important;
  }

  .cell-wrap-text {
    white-space: normal !important;
    display: flex;
    align-items: center;
  }

  .ql-size-large {
    font-size: 1.5em !important;
  }

  .ql-snow {
    .ql-picker-options .ql-picker-item {
      padding: 0 !important;
    }

    .ql-picker.ql-size {
      width: 75px !important;
    }
  }

  .ql-container {
    min-height: 200px;
    .ql-editor {
      overflow-y: auto;
      resize: vertical;
      min-height: 200px;
      strong {
        font-weight: bold;
      }
    }
  }
}

.ag-theme-balham .ag-layout-auto-height .ag-center-cols-container {
  background: #ededed !important;
}

.successColor {
  border-color: #0da05c !important;
  background-color: #07ad62 !important;
  color: #ffffff !important;

  &:hover {
    background-color: #53b783 !important;
  }

  &:active {
    background-color: #0c9153 !important;
  }

  &:disabled {
    background-color: #000000 !important;

    &:hover {
      color: #5d5d5d !important;
      border-color: #5d5d5d !important;
    }
  }
}
.successColor {
  border-color: #0da05c !important;
  background-color: #07ad62 !important;
  color: #ffffff !important;

  &:hover {
    background-color: #53b783 !important;
  }

  &:active {
    background-color: #0c9153 !important;
  }

  &:disabled {
    background-color: #000000 !important;

    &:hover {
      color: #5d5d5d !important;
      border-color: #5d5d5d !important;
    }
  }
}

.sansFont {
  font-family: "Lato", sans-serif;
}

.ag-theme-balham-dark.darkModeQuill {
  // min-width: 450px;
  min-width: 800px;
  // width: 100%;
  // margin-left: 50px;
  border: 1px solid #4a4a4a;
  // background-color: red;
  // display: flex;
  // justify-content: center;

  .ql-editor p {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #fff !important;
    background-color: #2a2b3a;

    // &::before {
    //   content: "• ";
    // }
  }

  .ql-toolbar {
    // border-color: red;

    border-radius: 3px 3px 0 0;
    background-color: #2a2b3a;

    button,
    .ql-picker {
      .ql-stroke {
        stroke: #8f8f8f;
      }

      .ql-fill {
        fill: #8f8f8f;
      }

      &.ql-active {
        .ql-stroke {
          stroke: #f5f5f5;
        }

        .ql-fill {
          fill: #f5f5f5;
        }
      }

      &:hover {
        .ql-stroke {
          stroke: #f5f5f5;
        }

        .ql-fill {
          fill: #f5f5f5;
        }
      }
    }

    .ql-size.ql-picker {
      .ql-picker-label {
        color: #8f8f8f;
      }

      .ql-stroke {
        stroke: #8f8f8f;
      }

      .ql-fill {
        fill: #8f8f8f;
      }

      &:hover {
        .ql-picker-label {
          color: #f5f5f5;
        }

        .ql-stroke {
          stroke: #f5f5f5;
        }

        .ql-fill {
          fill: #f5f5f5;
        }
      }
    }
  }

  .ql-container {
    // border-color: #4a4a4a;

    border-radius: 0 0 3px 3px;
    background-color: #2a2b3a;
    min-height: 200px;
    .ql-editor {
      color: #f5f5f5 !important;
      overflow-y: auto;
      resize: vertical;
      min-height: 200px;
      strong {
        font-weight: bold;
      }
      p > span {
        // color: #6d9c4a !important;
      }
    }
  }
}

.modifyRowPopover {
  padding-top: 5px !important;

  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-inner {
    width: 42px !important;
    border-radius: 20px !important;
    backdrop-filter: saturate(200%) blur(5px);
    background-color: rgba(255, 255, 255, 0.2);
    //transition: background-color 0.5s cubic-bezier(0.28, 0.11, 0.32, 1);
    border: solid 1px rgba(255, 255, 255, 0.3) !important;
  }

  .rowModifierAdd {
    display: flex;
    align-items: center;
    justify-content: center;
    border-color: #ffffff !important;
    background-color: #43a040 !important;
    color: #ffffff !important;

    &:hover {
      background-color: #53b783 !important;
    }

    &:active {
      background-color: #0c9153 !important;
    }
  }

  .rowModifierDuplicate {
    margin: 0 0 0 -8px;
    border-color: #ffffff !important;
    background-color: #1890ff !important;
    color: #ffffff !important;

    &:hover {
      background-color: #40a9ff !important;
    }

    &:active {
      background-color: #096dd9 !important;
    }
  }

  .rowModifierRemove {
    margin: 2px 0 -5px -8px;
    border-color: #ffffff !important;
    background-color: #e53936 !important;
    color: #ffffff !important;

    &:hover {
      background-color: #fa4848 !important;
    }

    &:active {
      background-color: #ba1d1d !important;
    }
  }
}

.floor-height-popover-content {
  button {
    margin: 0 5px;
  }
}

.priceSheetTable {
  * {
    font-family: "Lato", sans-serif !important;
    text-align: right;
  }

  width: 100%;
  border: 1px solid #6b8686;

  tr {
    &.xAxis {
      background-color: #dcf3ff;
      font-weight: 900;
      color: #000000;

      td:not(.pivotCell) {
        border-bottom: solid 3px #22546e;
      }
    }

    border-bottom: solid 1px #a0a0a0;
  }

  td {
    &.yAxis {
      background-color: #dcf3ff;
      font-weight: 900;
      color: #000000;
      border-right: 3px solid #22546e;
    }

    &.pivotCell {
      background-color: #ffffff;
      border-bottom: solid 1px #a0a0a0;
    }

    border-right: 1px solid #a0a0a0;
    padding: 5px;
  }
}

.ag-theme-balham-dark label.ant-checkbox-wrapper.takeOff-checkbox {
  span {
    color: #fff !important;
  }
}

.price-list-container {
  border-bottom: solid 1px #c1c7d0;
  margin: 14px auto;
  width: min-content;

  .price-list-header {
    display: flex;
    background-color: #f4f5f7;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border: solid 1px #c1c7d0;
    border-bottom: none;

    .price-list-column-header {
      padding: 15px 25px;
      width: 100%;
      max-width: 400px;
      font-weight: bold;
      text-align: center;
    }
  }

  .price-list-body {
    .price-list-row {
      padding: 5px 0;
      display: flex;
      background-color: #ffffff;
      border-right: solid 1px #c1c7d0;
      border-left: solid 1px #c1c7d0;

      .price-list-cell {
        width: 100%;
        text-align: center;
        max-width: 400px;
      }

      &:nth-child(2n - 1) {
        background-color: #dcf3ff;
      }
    }
  }
}

.ag-center-cols-clipper {
  min-height: 60px;
}

.ag-layout-auto-height .ag-center-cols-clipper {
  min-height: 100px !important;
}

.agGridRentalsDetailsStyleDark [class^="ag-"] {
  color: #fff;
}

.dataEntryModal .ag-theme-balham-dark {
  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    color: #fff !important;
  }
}

.ant-modal.timeoutModal {
  .ant-modal-content {
    background-color: rgba(0, 0, 0, 0.7);

    .ant-modal-body {
      color: #f5f5f5;
      font-size: 18px;
    }
  }
}

.dataEntryModal.ag-theme-balham-dark
  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
  color: #fff !important;
  -webkit-text-fill-color: #fff !important;
}

label.ant-checkbox-wrapper.ant-checkbox-wrapper-checked.ant-checkbox-wrapper-disabled.dark-mode-checkbox {
  span {
    color: #fff;
  }
}

.dataEntryGrid .ag-theme-balham-dark {
  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    -webkit-text-fill-color: #fff !important;
    color: #fff !important;
    svg {
      color: #fff;
    }
  }
  .dataEntryGrid-cell-select.Mui-disabled {
    color: #fff !important;

    svg {
      color: #fff;
    }
  }
}

.select-addons-dropdown-dark {
  // .dark
  .rc-virtual-list {
    .rc-virtual-list-holder {
      .rc-virtual-list-holder-inner {
        .ant-select-item-option-content {
          color: white;
        }
        .ant-select-item-option-selected {
          background: #292b3d !important;
          .ant-select-item-option-content {
            color: white;
          }
        }
        .ant-select-item {
          padding-left: 10px;
          padding-right: 10px;
          padding-top: 6.5px;
          padding-bottom: 6.5px;
          .ant-select-item-option-content {
            font-family: "Open Sans";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            margin: 0;
            padding: 0;
          }
          // &:not(:last-child) {
          //   border-bottom: 1px solid #efefef;
          // }
          &:hover {
            background: #292b3d;
            cursor: pointer;
            .ant-select-item-option-content {
              color: #1264a3;
            }
          }
        }
      }
    }
  }
}

// .select-addons-dropdown-light {
//   // .dark
//   .rc-virtual-list {
//     .rc-virtual-list-holder {
//       .rc-virtual-list-holder-inner {
//         .ant-select-item-option-selected {
//           background: #292b3d !important;
//           .ant-select-item-option-content {
//             color: white;
//           }
//         }
//         .ant-select-item {
//           padding-left: 10px;
//           padding-right: 10px;
//           padding-top: 6.5px;
//           padding-bottom: 6.5px;
//           .ant-select-item-option-content {
//             font-family: "Open Sans";
//             font-style: normal;
//             font-weight: 400;
//             font-size: 14px;
//             margin: 0;
//             padding: 0;
//           }
//           // &:not(:last-child) {
//           //   border-bottom: 1px solid #efefef;
//           // }
//           &:hover {
//             background: #292b3d;
//             cursor: pointer;
//             .ant-select-item-option-content {
//               color: #1264a3;
//             }
//           }
//         }
//       }
//     }
//   }
// }

.addonsContainer {
  width: 100%;

  .addonsHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-weight: 600 !important;
    font-size: 16px !important;
    h3 {
      font-size: 16px !important;
    }
  }

  .dataEntryGrid {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .select-addons-dark {
      .ant-select-selector {
        width: 100%;
        background-color: #12131b;
        border-color: #949494;
        color: white;
        &:hover {
          border-color: #0074d9;
          cursor: pointer;
        }
      }
    }
    .body-bright {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 10px;

      .card-component {
        width: 24%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: #fff;
        border-radius: 10px;

        .card-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          background-color: #f8f8fa;
          border-bottom: 0;

          .card-title {
            margin: 0;
            max-width: 75%;
            font-family: "Open Sans";
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            color: #323338;
          }

          .edit-button {
            height: 32px;
            width: 32px;
            padding: 9px;
            background: #1264a3;
            border-radius: 5px;
            cursor: pointer;

            &:hover {
              background-color: #0f5c97;
            }
          }
        }

        .card-columns {
          display: flex;
          flex-wrap: wrap;
          align-items: flex-start;
          padding: 10px;
          overflow: scroll;

          .card-values {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 5px;
            height: fit-content;
            padding: 8px 10px;
            border-radius: 5px;
            font-family: "Open Sans";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #323338;
          }
        }
        .card-description {
          flex-grow: 1;
          margin-bottom: auto;
          margin: 10px 5px 5px 5px;
          padding: 10px;
          min-height: 80px;
          background: #f5f5f5;
          color: black;
          border-radius: 5px;
          .description-text {
            font-family: "Open Sans";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #323338;
          }
        }
      }
    }

    .body-dark {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 10px;
      margin-top: 10px;
      .card-component {
        width: 24%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: #1f212d;
        border-radius: 10px;

        .card-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          background-color: #12131b;
          border-bottom: 0;

          .card-title {
            margin: 0;
            max-width: 75%;
            font-family: "Open Sans";
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            background-color: #12131b;
            color: #ffffff;
          }

          .edit-button {
            height: 32px;
            width: 32px;
            padding: 9px;
            background: #1264a3;
            border-radius: 5px;
            cursor: pointer;

            &:hover {
              background-color: #0f5c97;
            }
          }
        }

        .card-columns {
          display: flex;
          flex-wrap: wrap;
          padding: 10px;

          .card-values {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 5px;
            padding: 8px 10px;
            background: transparent;
            border-radius: 5px;
            font-family: "Open Sans";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #fff;
          }
        }

        .card-description {
          flex-grow: 1;
          margin-bottom: auto;
          margin: 10px 5px 5px 5px;
          padding: 10px;
          min-height: 80px;
          background: #2a2b3a;
          color: #fff;
          border-radius: 5px;

          .description-text {
            color: #fff;
          }
        }
      }
    }

    .no-data {
      text-align: center;
      font-size: 20px;
      color: #6b6b6b;
    }
  }
}

.reactQuillServiceAddon__dark {
  .ql-container {
    .ql-editor {
      color: white !important;
      p {
        color: white !important;
      }
    }
  }
}
.reactQuillServiceAddon {
  .ql-container {
    .ql-editor {
      // color: white !important;
      p {
        color: black;
      }
    }
  }
}

.editModal-bright {
  .modal-container input:active,
  .modal-container input:focus {
    border-color: #0074d9;
  }

  .ant-modal-header {
    background-color: #e6e6e6;
  }

  .ant-modal-content {
    .ant-modal-header {
      .ant-modal-title {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #0c0c0e;
      }
    }
  }

  .modal-container {
    display: flex;
    flex-direction: column;

    label {
      margin: 10px 0 0 0;
      padding-left: 10px;
      font-size: 18px;
    }

    .quill {
      margin-top: 20px;
    }

    .ql-editor {
      min-height: 110px;
    }

    .ql-editor p {
      color: #323338;
    }
  }

  .ant-btn {
    // background-color: #fff;
    color: #737373;
    &:hover {
      // background-color: #fff;
      color: #1a1a1a;
    }
  }
}

.editModal-dark {
  .modal-container input:active,
  .modal-container input:focus {
    border-color: #0074d9;
  }
  .ant-modal-close {
    color: #fff !important;
  }

  .ant-modal-header {
    background-color: #1264a3;
    .ant-modal-title {
      color: white !important;
    }
  }

  .ant-modal-content {
    background-color: #292b3a;
    color: #f5f5f5;

    .ant-modal-header {
      .ant-modal-title {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #0c0c0e;
      }
    }
  }

  .modal-container {
    display: flex;
    flex-direction: column;

    label {
      margin: 0;
      padding-left: 10px;
      font-size: 18px;
      color: #fff;
    }
    input {
      margin-bottom: 10px;
      background-color: #333333;
      color: #fff;
    }

    .quill {
      margin-top: 20px;

      .ql-container .ql-editor.ql-blank::before {
        color: #ccc;
      }
    }

    .ql-editor {
      min-height: 110px;
    }

    .ql-editor p {
      color: #fff;
    }

    .ql-toolbar .ql-stroke {
      fill: none;
      stroke: #fff;
    }

    .ql-toolbar .ql-fill {
      fill: #fff;
      stroke: none;
    }

    .ql-toolbar .ql-picker {
      color: #fff;

      .ql-picker-options {
        background: #2d2d2d;
      }
    }
  }

  .ant-btn {
    background-color: #2d2d2d;
    color: #ccc;
    &:hover {
      background-color: #2d2d2d;
      color: #fff;
    }
  }
}

// sping animate

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 900px) {
  .addonsContainer {
    .dataEntryGrid {
      .body-bright {
        .card-component {
          width: 32%;
        }
      }

      .body-dark {
        .card-component {
          width: 32%;
        }
      }
    }
  }
}

@media (max-width: 750px) {
  .addonsContainer {
    .dataEntryGrid {
      .body-bright {
        .card-component {
          width: 47%;
        }
      }

      .body-dark {
        .card-component {
          width: 47%;
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .addonsContainer {
    .dataEntryGrid {
      .body-bright {
        .card-component {
          width: 97%;
        }
      }

      .body-dark {
        .card-component {
          width: 97%;
        }
      }
    }
  }
}
@keyframes elevator-animation {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-50%);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
