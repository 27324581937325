$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.jobsiteRatesModal {
  height: fit-content;
  width: fit-content !important;

  .ant-modal-header {
    height: 45px;
    width: 100%;
    display: flex;
    align-items: center;
    font-weight: 600;
    border-radius: 10px 10px 0 0;
    background-color: #f8f8f8;

    .ant-modal-title {
      color: #323338;
      font-weight: 600;

      .titleInfoSpan {
        color: #fe4c4a;
      }
    }
  }

  .ant-modal-content {
    height: fit-content;
    max-height: 100%;
    width: 750px;
    max-width: 100%;

    .ant-modal-body {
      height: calc(100% - 56px);
      width: 100%;
    }
  }
}

.jobsiteRatesModalDark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      .jobsite-rates-grid-container {
        .ag-root-wrapper {
          border-radius: 10px;
          .ag-header {
            height: 50px !important;
            min-height: 50px !important;
            background-color: $cardModalBodyDark !important;
            .ag-header-row {
              border-top: none;
            }
            .ag-header-cell {
              height: 48px !important;
              min-height: 0px !important;
              .ag-header-cell-resize:after {
                width: 1px !important;
                background-color: #4a4a4a;
              }
            }
          }
        }
      }
      .editInfo span {
        color: #fff;
      }
    }
  }
}
