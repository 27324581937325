.richTextComp {
  .ant-form-item {
    display: none;
  }
  .tox-editor-header {
    .tox-menubar {
      display: none;
    }
  }
  .tox:not([dir="rtl"]) .tox-toolbar__group:not(:last-of-type) {
    border-right: none;
  }
  .tox .tox-toolbar,
  .tox .tox-toolbar__overflow,
  .tox .tox-toolbar__primary {
    display: flex;
    justify-content: center;
    padding: 0 0;
    background: none;
  }
  .tox-tinymce {
    border: none;
    .tox-statusbar {
      display: none;
    }
  }
  .tox .tox-edit-area__iframe {
    border: 1px solid #323338;
    border-radius: 20px;
  }

  .tox-anchorbar {
    display: none;
  }
}
