@import "../../../../index.scss";

$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.claimsModal {
  .modalContent {
    max-height: 75vh;
    min-height: 350px;
    width: 90vw;
    max-width: 95vw;
    overflow: auto !important;

    .formStepper {
      margin-bottom: 25px;
    }
    .bodyContent {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: row;

      .uploaderSection {
        width: 20%;
        height: auto;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        .uploaderButton {
          cursor: pointer !important;
        }
        .uploadSection {
          width: unset !important;
          max-width: 355px;
          gap: 20px;
          padding: 0px 10px;
        }
        // .uploadedFilesSection {
        // width: 100%;
        // padding: 0;
        // min-height: 120px !important;
        // }
        .anticon-file-text {
          font-size: 30px;
        }
        // .fileAuthor {
        // display: none;
        // }
        .safetyFilesContainer {
          height: auto;
          .safetyFilesTitle {
            display: flex;
            .safetyFilesTitleLine {
              border-top: 1px solid #000;
              margin: 10px 0;
              flex: 1;
            }
            .safetyFilesTitleText {
              margin: 0 10px;
              font-weight: 600;
            }
          }
          // .docUploader .uploadedFilesSection {
          //   width: 100%;
          //   min-height: 120px !important;
          // }
        }
      }

      .inputFieldsContainer {
        display: flex;
        flex-direction: column;
        width: 80%;
        .borderedTextCard {
          border: none;
          border-radius: 0;
          border-top: 1px solid #323338;
          align-items: center;
          margin-top: 10px !important;
          margin-left: 30px;
          padding: 20px 0 0;

          .cardTitle {
            font-size: 16px;
            color: #323338;
          }

          .claimsAvatarsDiv {
            display: flex;
            position: relative;
            top: 30px;
          }
        }

        .detailsCard {
          min-width: 600px;
          width: 97% !important;
          flex-wrap: wrap;

          // @media (min-width: 1100px) {
          //   width: 68rem;
          // }
          // @media (max-width: 1100px) and (min-width: 850px) {
          //   width: 750px;
          // }
          .rc-virtual-list-holder {
            max-height: 200px !important;
          }

          .labeledInputContainer {
            width: 24%;
            margin-right: 10px;
            #timePredict {
              width: 100% !important;
            }
            @media (max-width: 1100px) {
              width: 31%;
              max-width: 31%;
            }
            @media (max-width: 800px) {
              width: 47%;
              max-width: 47%;
            }
            @media (max-width: 575px) {
              width: 97%;
              max-width: 97%;
            }

            .ant-picker {
              width: 100%;
            }
          }
        }

        .notesCard {
          min-width: 250px;
          width: 97%;
          .ClaimsNotes {
            max-width: 100%;
            width: 100% !important ;
            @media (max-width: 500px) {
              .claims_input {
                max-width: 350px;
              }
            }

            .ant-btn {
              display: none;
            }
          }

          .ql-toolbar {
            background-color: #f8f8fa;
            justify-content: center;
          }
          .ql-editor {
            background-color: #f5f5f7;
            height: 200px !important;
          }
        }
      }
    }
  }
  .newFooterContainer {
    button {
      width: 150px;
    }
  }
}

.claimsModalDark {
  .ant-modal-body {
    .antCustomStepper {
      .ant-steps {
        .ant-steps-item-finish ::before,
        .ant-steps-item-active::before,
        .ant-steps-item::before {
          border-left-color: $cardModalBodyDark;
        }
        .ant-steps-item-wait {
          background-color: $cardModalHeaderDark;
          .ant-steps-item-content .ant-steps-item-title {
            color: #acacca;
          }
        }
      }
    }
    .uploaderSection {
      .uploadSection {
        border-color: #acacca;
      }
    }
    .childrenContainer {
      .labeledInputLabel {
        color: #fff;
      }
      .ant-input {
        background-color: $inputModalDark !important;
        color: #fff;
        &::placeholder {
          color: #acacca;
        }
      }
      .ant-input-disabled {
        color: #acacca;
      }
      .ant-select {
        .ant-select-selector {
          background-color: $inputModalDark !important;
          .ant-select-selection-search-input {
            color: #fff;
          }
          .ant-select-selection-item {
            color: #fff;
          }
          .ant-select-selection-placeholder {
            color: #acacca;
          }
        }
        .ant-select-clear {
          background-color: $inputModalDark;
          svg {
            fill: #acacca;
          }
        }
        .ant-select-arrow svg {
          fill: #fff;
        }
      }
      .ant-select-disabled {
        .ant-select-selector {
          .ant-select-selection-search-input {
            color: #acacca !important;
          }
          .ant-select-selection-item {
            color: #acacca !important;
          }
        }
      }
      .ant-picker {
        background-color: $inputModalDark !important;
        .ant-picker-input {
          background-color: $inputModalDark;
          input {
            color: #f2f2f2;
            &::placeholder {
              color: #acacca;
            }
          }
          .ant-picker-suffix svg {
            color: #acacca;
          }
          .ant-picker-clear {
            background-color: $inputModalDark;
            svg {
              fill: #acacca;
            }
          }
        }
      }
      .ant-radio-wrapper span {
        color: #fff;
      }
      .react-tel-input {
        .form-control {
          background-color: $inputModalDark !important;
          color: #fff;
          &::placeholder {
            color: #acacca;
          }
        }
        .flag-dropdown {
          background-color: $inputModalDark;
          border-radius: 5px 0px 0px 5px;
          .selected-flag {
            &:hover {
              background-color: $inputModalDark;
            }
            &:focus {
              background-color: $inputModalDark;
            }
          }
          .arrow {
            border-top: 4px solid #fff;
          }
        }
        .open {
          background-color: $inputModalDark;
          border-radius: 5px 0px 0px 5px;
          .selected-flag {
            background-color: $inputModalDark;
            .arrow {
              border-bottom: 4px solid #fff;
              border-top: 4px solid transparent;
            }
          }
        }
      }
      .quill {
        background-color: $cardModalBodyDark !important;
        .ql-toolbar {
          background-color: #15161f !important;
          border-radius: 5px;
          .ql-formats {
            color: #fff !important;
            .ql-picker-label {
              color: #fff;
              &::before {
                color: #fff;
              }
              .ql-stroke {
                stroke: #fff;
              }
            }
            button .ql-stroke {
              stroke: #fff;
            }
          }
        }
        .ql-container {
          background-color: $inputModalDark !important;
          border-radius: 5px;
          .ql-editor {
            background-color: $inputModalDark !important;
            &::before {
              color: #acacca;
            }
            p {
              color: #fff !important;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1600px) {
  .claimsModal {
    .bodyContent {
      flex-direction: column-reverse !important;
      align-items: center;
      justify-content: center;
      .uploaderSection {
        width: 98% !important;
      }
      .inputFieldsContainer {
        width: 98% !important;
        // .notesCard .ClaimsNotes .claims_input {
        //   max-width: 520px;
        // }
      }
    }
  }
}
@media (max-width: 1100px) {
  .claimsModal .modalContent {
    width: 90vw;
  }
}
@media (max-width: 820px) {
  .claimsModal .modalContent {
    width: 88vw;
  }
}
@media (max-width: 720px) {
  .claimsModal .modalContent {
    width: 86vw;
  }
}
@media (max-width: 450px) {
  .claimsModal .modalContent {
    width: 80vw;
  }
}

@media (max-width: 700px) {
  .claimsModal {
    .borderedTextCard {
      margin-left: 0 !important;
    }
    .inputFieldsContainer {
      margin-top: 20px !important;
      .detailsCard {
        min-width: unset !important;
        margin-top: 30px !important;
        .childrenContainer .labeledInputContainer {
          width: 100% !important;
          margin: 0 !important;
        }
      }
      .notesCard {
        .ql-toolbar {
          justify-content: flex-start !important;
          flex-wrap: wrap;
          padding: 2px;
        }
      }
    }
  }
}
@media (max-width: 575px) {
  .claimsModal .modalContent .formStepper {
    height: 190px !important;
    width: 90% !important;
    .ant-steps-navigation.ant-steps-vertical > .ant-steps-item {
      height: 35px !important;
    }
    .ant-steps-navigation.ant-steps-vertical
      > .ant-steps-item
      .ant-steps-item-content:last-child {
      min-height: 0px !important;
    }
    .ant-steps-navigation.ant-steps-vertical > .ant-steps-item:after {
      position: relative;
      top: -36px;
      left: 100%;
      transform: rotate(0deg);
    }
    .ant-steps-navigation.ant-steps-vertical
      > .ant-steps-item.ant-steps-item-active:before {
      display: none !important;
    }
    .ant-steps-navigation.ant-steps-vertical
      > .ant-steps-item:last-child:after {
      display: none !important;
    }
    .ant-steps-item {
      margin-bottom: 5px !important;
    }
  }
  .claimsModal {
    padding: 15px;
    .modalContent {
      .bodyContent {
        // flex-direction: column-reverse !important;
        .docUploader {
          min-width: 250px;
          width: unset !important;
        }
      }
    }
  }
}
// @media (max-width: 1600px) and (min-width: 575px) {
//   .claimsModal
//     .modalContent
//     .bodyContent
//     .uploaderSection
//     .uploadedFilesSection {
//     padding: 0 0 0 100px;
//   }
// }

@media (min-width: 1600px) {
  .claimsModal .modalContent {
    overflow-x: hidden !important;
    .bodyContent .inputFieldsContainer .borderedTextCard {
      margin-bottom: 0;
    }
  }
}
