.dashboard-prefs-container {
  width: 100%;
  height: 50%;
  // overflow: hidden;
  border-radius: 10px;

  .CardContentWrapper {
    height: 100%;

    .CardContentWrapper-header {
      cursor: pointer;
      .header-right {
        .toggle-switch-container {
          background-color: #ffffff;

          .toggle-text {
            color: #323338;
          }
        }
      }
    }

    .CardContentWrapper-content {
      position: relative;
      padding: 10px;

      .automation-responses-container {
        padding: 0;
      }
    }

    &.dark-mode {
      .CardContentWrapper-header {
        .toggle-switch-container {
          background-color: #12131b;

          .toggle-text {
            color: #fff;
          }
          .toggle-ball-icon {
            svg {
              fill: #ffffff;
            }
          }
        }
      }
    }
  }

  &.collapsed {
    height: auto;
    min-height: auto !important;
  }
}

@media (max-width: 1440px) {
  .dashboard-prefs-container {
    height: calc(60% - 10px); //its needed!!!!
  }
}

@media (max-width: 560px) {
  .dashboard-prefs-container {
    max-height: 100% !important;
    .CardContentWrapper {
      height: 100%;

      .CardContentWrapper-content {
        .customAntdTransfer {
          .transfersContainer {
            flex-direction: column;
            .transferIcon {
              align-self: center;
              transform: rotate(90deg);
            }
          }
        }
      }
    }
  }
}
