.agGridRentalContainer {
  width: stretch;
  // height: stretch;
  .ag-root-wrapper-body.ag-layout-normal {
    height: fit-content !important;
  }
}

.agGridRentalsDetailsStyleDark .ag-menu {
  background: #414155 !important;
}
