.to-do-modal-button {
  width: fit-content;
  border-radius: 5px;
  height: 32px;
  border: none;

  .mondayButtonContainer {
    .mondayButtonStyle {
      background-color: #1264a3 !important;
    }
  }
  svg {
    margin-top: 2px;
    height: 20px;
    width: 20px;
  }
}

.todo-icon-only {
  width: 32px;
  height: 32px;
  border: 0;
  // margin-right: 20px;
  border-radius: 5px;
  background-color: #1264a3;
  padding: 0;

  button {
    width: 32px;
  }
}
