$colorWhite: #ffffff;
$colorBlack: #12131b;
$colorDark: #3a3c4e;

.filterBalanceTableActions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $colorWhite;
  height: 52px;
  border-radius: 5px;
  padding: 0 10px;

  .leftSideFilterBalance,
  .rightSideFilterBalance {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    .datePickerFilterBalanceTable {
      width: 250px;
    }

    .open-balance-search {
      width: 300px;
      .ant-form-item {
        padding: 0 !important;
        margin: 0 !important;
      }
    }

    .open-balance-filters {
      width: 150px;
    }
  }

  .ant-picker-suffix {
    color: $colorBlack;
  }
  .ant-picker-separator {
    color: $colorBlack;
  }
}

.filterBalanceTableActions-dark {
  @extend .filterBalanceTableActions;

  background-color: $colorBlack;

  .ant-picker {
    background-color: $colorDark;
  }
  .ant-picker-suffix {
    color: $colorWhite;
  }
  .ant-picker-separator {
    color: $colorWhite;
  }

  .ant-picker-input {
    color: $colorWhite;
  }
}
