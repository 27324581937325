.account-info-container {
  height: 50%;
  border-radius: 10px;

  .CardContentWrapper {
    .CardContentWrapper-header {
      cursor: pointer;
    }
    .CardContentWrapper-content {
      display: flex;

      .profile-settings {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }

      .divider {
        height: 100%;
        width: 2px;
        background-color: #dddfe3;
        margin: 0 30px;
        transition: 0.2s ease-in;
      }

      .account-infos {
        width: 100%;
      }
    }
    &.dark-mode {
      .divider {
        background-color: #2a2b3a;
      }
    }
  }

  &.collapsed {
    height: auto;
  }
}

@media (max-width: 1440px) {
  .account-info-container {
    height: 40%;
    border-radius: 10px;
    .CardContentWrapper-content {
      flex-direction: column;
      gap: 20px;

      .divider {
        display: none;
      }
    }
  }
}
