.crewsModalSchedule {
  .ant-modal-header {
    background-color: #f3f4f5;
    height: 45px;
    display: flex;
    align-items: center;
  }
  .ant-modal-footer {
    display: flex;
    justify-content: space-between;
    background-color: #f5f5f7;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .crewsModalContent {
    display: flex;
    gap: 15px;
    width: 100%;
    flex: 1;
    flex-wrap: wrap;
    height: 100%;
    overflow-y: auto;
    .crewTeamCard {
      width: 32.5%;
    }
    .ant-card-head {
      min-height: 45px;
      background-color: #f5f5f7;
    }
    .ant-card-body {
      height: 200px;
      overflow-y: auto;
    }
  }
}
