@import "../../../../index.scss";

.ag-theme-alpine {
  .ag-paging-panel {
    min-height: 45px !important;
  }
  .ag-column-drop-vertical {
    min-height: 50px;
    max-height: 150px;
    border-bottom: 1px solid;
    border-bottom-color: #dde2eb;
    border-bottom-color: var(--ag-secondary-border-color, #dde2eb);
  }

  .ag-popup {
    height: 0 !important;
    min-height: 0 !important;
  }

  .pagination {
    top: -95px;
    position: absolute;
    right: 430px;
    width: 80px !important;
    height: 32px;

    .ant-select-selector {
      background: #f2f2f2 !important;
      border: 1px solid #d0d0d0;
      border-radius: 5px;
    }
  }

  .ant-input-wrapper {
    position: absolute;
    width: 400px;
    margin-top: -45px;
    margin-left: 8px;
    display: flex;
    align-items: center;

    input {
      background: #f2f2f2;
      border: 1px solid #d0d0d0;
      border-radius: 8px 0 0 8px;
    }

    .ant-input-group-addon {
      border: 1px solid #d0d0d0;
      border-radius: 0 8px 8px 0;
      border-left: 0 !important;
      padding: 1px 0 !important;

      .ant-input-search-button {
        background: #f2f2f2;
        @include flex(center, center);
        border-left: 0 !important;

        .anticon-search {
          color: #1f2a44;
          font-size: 18px;
        }
      }
    }
  }
}
