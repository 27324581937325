.LineChartDashboardStyle {
  width: 100%;
  display: flex;
  flex-direction: column;
  // align-items: center;
  // justify-content: center;
  height: 350px;
  // margin-left: -10px;
  padding-top: 10px;
  padding-bottom: 10px;
  overflow-y: auto;

  .LineChartTextContent {
    width: 100%;
    height: fit-content;
    margin: 10px 0px;
  }

  .LineChartTitle {
    width: 100%;
    height: fit-content;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
  }

  .LineChartSubtitle {
    width: 100%;
    height: fit-content;
    text-align: center;

    font-size: 17px;
  }

  .lineChartCount {
    width: 100%;
    height: fit-content;
    // background-color: greenyellow;
    text-align: center;
    font-weight: bold;
    font-size: 43px;
    margin: 20px 0px;
  }
  .lineChartChartAndCount {
    width: 100%;
    height: 70px;
    // background-color:blueviolet;
    display: flex;

    .count {
      width: 60%;
      height: 100%;
    //   background: lightblue;
      display: flex;
      flex-direction: column;

      .mode {
        width: 100%;
        height: 30%;
        // background: lightgreen;
        display: flex;

        .arrow-up {
          width: 0;
          height: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          margin-top: 3px;
          border-bottom: 15px solid green;
        }

        .arrow-down {
          width: 0;
          height: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          margin-top: 3px;
          border-top: 15px solid #f00;
        }
        .income {
          margin-left: 5px;
          font-size: 17px;
        }
       
      }
      .countnumber {
        font-size: 30px;
        font-weight: bold;
      }
    }
    .line {
      width: 40%;
      height: 100%;

    //   background: lightcoral;
    }
  }
}
