.driver-assign-form {
  min-width: 300px;
  display: flex;
  justify-self: flex-start;
  align-items: center;
  flex-direction: column;

  .labeledInputContainer {
    min-width: 100%;
  }

  .confirmContainer {
    margin-top: 1rem;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
  }
}

.driver-assign-form.driver-assign-form-dark {
  .labeledInputLabel {
    color: #fff;
  }
}
