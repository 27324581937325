.avatar-images-group {
  width: fit-content;
  height: fit-content;
  display: flex;
}

//style popover for max elements
.avatar-images-max-popover {
  .ant-popover-content {
    .ant-popover-inner-content {
      display: flex;
    }
  }
}
