.service-actions {
  .service-control-btns {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    // padding-right: 10px;
    .ant-btn {
      margin-left: 5px;
      background: #3a3c4e;
      border: none;
      // border: 1px solid#71cf48;
      border-radius: 5px;
      color: #fff;
    }
  }
  .btn-holder-service-actions {
    display: flex;
    width: 98%;
    justify-content: flex-end;
    // margin-right: 20px;
  }
}
