.internalChatSideHeader {
  display: flex;
  height: 100vw;
  .internalChatSideMenu {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 200px;
    border-right: 0.5px solid #e6e9ef;
    background-color: #f4f5f6;
    .internalChatSideMenuHeader {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 10px 12px 0 10px;

      span {
        font-size: 20px;
        font-weight: 600;
        color: #231f20;
      }
    }
    .internalChatSideMenuItems {
      display: flex;
      flex-direction: column;
      background-color: #f4f5f6;

      .internalChatSideMenuItem {
        display: flex;
        align-items: center;
        gap: 10px;
        height: 44px;
        padding: 0 12px;
        cursor: pointer;
        border-top: 0.5px solid #e6e9ef;
        border-bottom: 0.5px solid #e6e9ef;
      }

      .internalChatSideMenuItem.selected {
        background-color: #e6e9ef;
      }
    }
  }
}
