// .ant-select-item {
//     padding: 0 !important;
//     .column{
//         padding: 5px 10px;
//     }
// }
.autoCompleteProduct {
  width: 100%;
  > div {
    background-color: #f4f5f6 !important;
    // border-radius: 5px !important;
    height: 32px !important;
    border: 0 !important;
  }
  .ant-select-dropdown {
    padding: 0px;
  }
  .menuItemWrapper {
    border-bottom: 1px solid #d4d7dc;
    outline: none;
    font-size: 16px;
    color: rgb(57, 58, 61);
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    background-color: white;
  }
}
.addNewInvoiceServiceButton {
  border: 0;
  display: flex;
  align-items: center;
  > svg {
    margin-right: 10px;
  }
}
