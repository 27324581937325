@import "../../../../../../../../../../../index.scss";

.maintenanceRequestModalContainer {
  .question {
    @include text(20px, 400, 0, #1f2a44);
    margin-bottom: 20px;
  }
  .buttonContainer {
    width: 100%;
    @include flex(center, center);

    .ant-btn {
      margin-right: 15px;
    }
  }
}

.maintenanceRequestModalContainerDark {
  .question {
    color: #fff;
  }
  .buttonContainer {
    padding-bottom: 10px;
  }
}
