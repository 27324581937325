.clearFiltersButton {
  display: flex !important;
  color: #fff !important;
  text-transform: capitalize !important;
  background-color: #e52c2c !important;
  border-radius: 16px !important;

  div {
    font-weight: 600;
    padding-right: 5px;
  }
}

.clearFiltersButton:hover,
.clearFiltersButton:focus {
  color: #e52c2c !important;
  border-color: #e52c2c !important;
  background-color: #fff !important;
}
