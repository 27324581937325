$base-color: #1f2a44;
$border-color: #acacca;
$back-color: #738ab2;

.auth_wrapper {
  width: 100%;
  height: 100dvh;
  overflow: hidden;
  display: flex;
  background: url(../../icons/Auth_icons/background.png) no-repeat center center
    fixed;
  background-size: cover;

  .form-comp-coreLogo {
    height: 80px;
    animation: logoAnimation 1.2s ease-in forwards;
  }
  .left_side {
    position: relative;
  }
  .ant-col {
    padding: 0 !important;
  }
  .right_side {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .top_corner_link {
      width: auto;
      position: absolute;
      top: 5%;
      right: 5%;
      img {
        height: 13px;
      }
      .ant-btn-text {
        padding: 0 5px;
        color: $base-color;
        &:hover {
          background: transparent;
        }
      }
      @media screen and (max-width: 576px) {
        right: 22%;
      }
    }
    .form_comp_wrapper {
      width: 50%;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: center;

      .ant-form {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: auto;
      }
      h1 {
        color: $base-color;
        font: normal normal 600 16px Open Sans;
        margin: 30px 0;
      }

      h1,
      .ant-form {
        opacity: 0;
        animation: formAnimation 0.5s ease-in forwards;
        animation-delay: 1.2s;
      }

      h1,
      .ant-form {
        opacity: 0;
        animation: formAnimation 0.5s ease-in forwards;
        animation-delay: 1.2s;
      }
      .ant-col-16 {
        max-width: 100%;
      }
      .ant-form-horizontal {
        width: 100% !important;
        .ant-form-item {
          width: 100%;
          max-width: 353px;
          .ant-input-affix-wrapper {
            height: 44px;
            border: 1px solid $border-color;
            border-radius: 5px;
            .ant-input-prefix {
              margin-right: 5px;
            }
          }
          .ant-input-password {
            border: 1px solid $border-color;
            height: 44px;
            border-radius: 5px;
            .ant-input {
              border: none;
              height: auto;
            }
          }
        }
      }
      .signin_options {
        width: 100%;
        max-width: 353px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .ant-form-item {
          margin: 5px 0;
        }
        a {
          width: 100%;
          display: flex;
          color: $base-color;
          justify-content: flex-end;
          font-weight: 600;
          p {
            margin: 0;
          }
        }
        .ant-btn:hover {
          text-decoration: underline;
        }
      }
      .attempt_exceeded_wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        color: #323338;
        a {
          font-weight: 400;
          font-size: 14px;
          color: #323338;
          &:hover {
            color: #1264a3;
            font-weight: 600;
          }
        }
      }
    }
  }
  .code_input {
    padding: 4px 8px;
    border: 0.5px solid $border-color;
    border-radius: 5px;
    width: 100%;
    margin-top: 20px;
  }

  .ant-alert {
    border-radius: 7px;
  }

  .ant-alert-success {
    display: flex;
    flex-direction: column;
    align-items: center;
    span {
      font-size: 60px;
    }
    .ant-alert-content {
      margin-top: 25px;
    }
    .ant-alert-description {
      text-align: center;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .forgotpass_action_btn {
    .go-back {
      width: 100%;
      margin-top: 10px;
      border-radius: 5px;
      border-radius: 5px;
      padding: 25px;
      text-transform: uppercase;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $back-color;
      color: #fff;
      font-size: 1.5rem;
      font-weight: bold;
      .ant-btn-icon {
        width: 25px;
      }
    }
  }
  .signin-btn {
    width: 100%;
    height: 64px;
    max-width: 353px;
    margin-top: 10px;
    border-radius: 5px;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $base-color;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    .ant-btn-loading-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 25px;
    }
  }
}

@media (max-width: 1590px) {
  .ant-carousel .slick-dots-bottom {
    bottom: -15px !important;
  }
}
@media (max-width: 1050px) {
  .auth_wrapper .right_side .form_comp_wrapper {
    width: 90%;
  }
}
@media (max-width: 576px) {
  .auth_wrapper {
    .left_side {
      display: none;
    }
    .form-comp-coreLogo {
      position: relative;
      bottom: 30px;
    }
    .signin-btn {
      position: relative;
      background-color: #ffffff;
      font-size: 15px;
      color: #323338;
      top: 0;
      border-color: $border-color;
      text-transform: capitalize;
    }
  }
  .go-back {
    position: relative;
    top: 75px;
  }
}

@media (max-width: 576px) {
  .auth_wrapper .left_side {
    display: none;
  }
}

@media (max-width: 450px) {
  .auth_wrapper .right_side .form_comp_wrapper h1 {
    text-align: center;
    margin: 20px;
    font: normal normal 600 16px/22px Open Sans;
  }
  .form_comp_wrapper {
    .ant-input-prefix {
      display: none;
    }
    width: 30%;
    h1 {
      width: 300px;
      margin: 0px;
      padding: 0px;
      font-size: 20px !important;
    }
  }
  .auth_wrapper
    .right_side
    .form_comp_wrapper
    .ant-form-horizontal
    .ant-form-item {
    width: 300px;
  }
  .auth_wrapper .right_side .form_comp_wrapper .signin_options {
    width: 300px;
  }
  .auth_wrapper .signin-btn {
    width: 300px;
  }
}

@keyframes logoAnimation {
  0% {
    transform: translate(0, 250%) scale(0.2);
    opacity: 0;
  }
  40% {
    transform: translate(0, 250%) scale(1);
    opacity: 1;
  }
  100% {
    transform: translate(0, 0) scale(1);
    opacity: 1;
  }
}

@keyframes formAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
