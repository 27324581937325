.new_proposal_builder {
  height: 100dvh;
  display: flex;
  flex-direction: column;
  background-color: #f4f5f6;

  .new_proposal_builder__content {
    flex: 1;
    gap: 10px;
    padding: 10px;
    display: flex;
    overflow-y: hidden;
    flex-direction: column;

    .new_proposal_builder__body {
      flex: 1;
      gap: 20px;
      width: 100%;
      height: 100%;
      display: flex;
      position: relative;
      overflow-y: hidden;
      justify-content: center;
      .new_proposal_builder__body__editor-wrapper {
        flex: 1;
        width: 100%;
        overflow-y: auto;

        .jodit-react-container {
          border: none;
          width: 100% !important;
          height: 100% !important;

          .jodit-container {
            gap: 10px;
            border: none;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100% !important;
            height: 100% !important;

            .jodit-toolbar__box {
              width: 100%;
              border-radius: 10px;
              border-bottom: none;
              box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15);
              // .jodit-toolbar-editor-collection {
              // }
            }
            .jodit-workplace {
              flex: 1;
              width: 820px;
              height: unset !important;
              .jodit-wysiwyg {
                padding: 35px;
                overflow-y: auto;

                background-image: repeating-linear-gradient(
                  transparent,
                  transparent 26.1cm,
                  #9198e5 26.1cm,
                  #9198e5 26.25cm
                );
                background-attachment: local;
              }
            }

            .jodit-status-bar {
              width: 660px;
            }
          }
        }
        // .jodit-react-container .jodit-container {
        //   height: 100%;
        // }
      }
    }
  }
}

// .new_proposal_builder_dark {
// }
