.includeRentalBodyResizable {
  // width: stretch !important;
  position: relative;
  display: flex;

  //resizable handler style
  .includeRentalBodyResizableGripContainer {
    position: absolute;
    // width: stretch;
    display: flex;
    bottom: 0;
    margin-bottom: -9px;
    transition: opacity 0.3s;
    opacity: 0;
    cursor: grab;

    .leftSize {
      flex: 1;
      height: 3px;
    }
    .grabberContainer {
      height: 9px;
      width: 55px;

      border-radius: 0 0 3px 3px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;

      .gripLines {
        width: 30px;
        height: 1px;
        background-color: white;
      }
    }
    .rightSize {
      flex: 1;
      height: 3px;
    }

    &:hover {
      .leftSize {
        transition: height 0.3s;
        flex: 1;
        height: 9px;
      }
      .grabberContainer {
        height: 9px;
        width: 55px;

        border-radius: 0;
        transition: border-radius 0.3s;
      }
      .rightSize {
        transition: height 0.3s;
        flex: 1;
        height: 9px;
      }
    }
  }

  &:hover {
    .includeRentalBodyResizableGripContainer {
      opacity: 1;
      transition: opacity 0.3s;
    }
  }

  .includeRentalsAgGridContainerLight {
    // width: stretch;
    height: stretch;
  }

  .includeRentalsAgGridContainerDark {
    // width: stretch;
    height: stretch;

    .ag-center-cols-viewport {
      background-color: #1f212d !important;
    }
  }
}

.includeRentalContainerDark {
  .rentalBodyContainerDark {
    .ag-root-wrapper {
      background-color: red !important;
    }
    background-color: red !important;
  }
}
