.schedule-note {
  animation: scheduleNote 0.5s;
}

.notes-other-schedule {
  animation: scheduleOtherNote 0.5s;
}

@keyframes scheduleOtherNote {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes scheduleNote {
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
