@keyframes move {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.ql-editor strong {
  font-weight: bold;
}

strong {
  font-weight: bold !important;
}

.ql-align-right {
  text-align: end;
}

.ql-align-center {
  text-align: center;
}

.ql-size-small {
  font-size: 0.82rem;
}

.ql-size-large {
  font-size: 1.41rem;
}

.ql-size-huge {
  font-size: 2.57rem;
}

.modal__form {
  display: flex;
  background: #f8f8f8;
  flex-direction: column;
}

.modal__header {
  display: flex;
  padding: 8px 17px;
  border-bottom: 1px solid #d4d4d4;
  font-size: 1.2rem;
  background: #f9db55;
  color: #32325d;
  align-items: center;
}

.modal__header > button {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-style: none;
  background-color: transparent;
}

.modal__header > button > svg {
  cursor: pointer;
  color: black;
}

.modal__header > button:hover {
  color: black;
  background-color: #5a5a5a17;
  transition: background-color 200ms linear;
  border-radius: 50%;
}

.modal__label {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 15px;
  color: #32325d;
}

.modal__label .ant-input {
  color: rgba(0, 0, 0, 0.7);
  font-weight: normal;
}

.modal__label > .ant-picker > .ant-picker-input > input::placeholder {
  color: rgba(0, 0, 0, 0.35);
  font-weight: 600;
}

.modal__label .ant-select-selection-item {
  color: rgba(0, 0, 0, 0.4);
  font-size: 0.9rem;
  font-weight: 600;
}

.modal__label .ant-select-selection-placeholder {
  color: rgba(0, 0, 0, 0.4);
  font-size: 0.9rem;
  font-weight: 600;
}

.modal__label .ql-container {
  height: fit-content;
}

.modal__label .quill {
  max-width: 100%;
}

.modal__label .ql-snow {
  word-wrap: break-word !important;
}

.modal__label > span {
  display: flex;
  flex: 1;
  align-self: flex-start;
  font-weight: 700;
}

// .modal__label > .add_task_modal {
//      max-width: 530px;
// }
.task-users {
  width: 100%;
}

.department-select {
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  .ant-select {
    width: 100%;
  }
  h6 {
    font-size: 0.75rem;
    font-weight: bold;
    font-style: italic;
    color: rgba(0, 0, 0, 0.418);
    margin-top: 5px;
    margin-bottom: 0 !important;
  }
}
.task-assignTo {
  margin-right: 0px !important;
}

.modal__multiSelect {
  .assigned-toMe-btn {
    background-color: #0f5c97;
    border-color: #0f5c97;
    border: none;
    font-size: 0.7rem;
    margin-left: 10px;
    width: 100px !important;
    display: flex;
    justify-content: center;
    height: 28px;

    span {
      color: #fff !important;
    }
    // &:focus {
    //   background-color: #0f5c97;
    // }
    &:hover {
      color: red;

      // background-color: unset;
      color: #0f5c97;
    }
  }
  .assign-toMe-btn {
    color: #0f5c97;
    border-color: #0f5c97;
    height: 30px;
    font-size: 0.7rem;
    padding: 4px 8px;

    &:hover {
      background-color: #0f5c97;
      color: #fff;
    }
  }
}

.modal__label > div {
  flex: 2;
  background: white !important;
  align-self: flex-start;
}

.modal__label__link {
  width: 60px;
  padding: 0.4em 0.8em;
  display: flex;
  align-items: center;
  background: #1890ff;
  color: white;
  text-decoration: none;
  justify-content: center;
}

.modal__label__link:hover {
  background: #1060ac;
  transition: 0.3s;
}

.modal__label > div .ant-select-selection--single {
  width: 100%;
  background: white !important;
}

.modal__label > .form-field,
input {
  min-height: 0 !important;
}

.modal__bottomInputs {
  display: flex;
  justify-content: space-between;
}

.modal__bottomInputs > div {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.modal__options {
  flex-direction: column;
  padding: 8px 0;
  text-indent: 15px;
  position: relative;
}

.modal__label__options {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 35px;
  background: white;
}

.modal__label__options > span {
  z-index: 10000;
}

.modal__label__options-hide {
  display: none;
}

select option {
  padding: 10px 0;
}

.modal__label > div {
  width: 100%;
}

.modal__footer {
  display: flex;
  border-top: 1px solid #d4d4d4;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
}

.modal__footer > div:first-child > button {
  display: flex;
  align-items: center;
  color: #777777;
  font-size: 1rem;
  background: white;
  border-style: none;
  border-radius: 20px;
  justify-content: space-between;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.2);
  padding: 5px 10px;
  outline: none;
}

.modal__footer > div:first-child > button:hover {
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.3);
}

.detailsModal__comment__uploadFiles {
  display: flex;
  align-items: center;
  color: #777777;
  font-size: 1rem;
  background: white;
  border-style: none;
  border-radius: 20px;
  justify-content: space-between;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.2);
  padding: 5px 10px;
  outline: none;
}

.detailsModal__comment__uploadFiles:hover {
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.3);
}

.modal__footer > div {
  display: flex;
  justify-items: flex-end;
}

.addTaskModal .dx-dropdowneditor-input-wrapper {
  width: 100%;
}

.ql-editor p {
  color: #787878;
}

.comment-preview,
.pli-note,
.schedule-note {
  display: flex;
  flex-direction: column;
  align-items: center;
  .comment_wrapper {
    // padding: 20px 25px;
    padding: 15px;
    background-color: #f8f8fa;
  }
}

.pli-note,
.schedule-note {
  margin-top: 15px;
  .comment_wrapper {
    border-radius: 10px;
    background-color: #f9f9fa;
    margin-top: 10px;
  }
}

.notes-container {
  width: 100%;
}

.taskTitle {
  color: rgb(16, 101, 164);
  &:hover {
    text-decoration: underline;
  }
}

.notes_drawer
  > .ant-drawer-content-wrapper
  > .ant-drawer-content
  > .ant-drawer-wrapper-body
  > .ant-drawer-body
  > div
  > .ant-collapse
  > .ant-collapse-item
  > .ant-collapse-content {
  > .reply_btn {
    width: 100%;
    padding: 0 15px;
    margin: 8px 0 20px;
    background-color: #f8f8fa;
  }
  &::-webkit-scrollbar-thumb {
    // background: #F8F8FA;
    &:hover {
      // background-color: #F8F8FA;
    }
  }
}

.inputContainer {
  > form {
    > .ant-form-item {
      margin: 0;
    }
  }
}

@media (max-width: 1246px) {
  .add_task_modal .ant-modal-content .ant-modal-body .modal-box-section {
    flex-direction: column;
    .block-section {
      align-items: center;
      .first-section {
      }
      .second-section {
        max-width: 100%;

        .timeDatePicker .clock-container {
          width: 100%;
        }
      }
    }
  }
}

@media (min-width: 1366px) {
  .add_task_modal .ant-modal-content .ant-modal-body .modal-box-section {
    overflow: auto;
  }
  .add_task_modal .ant-modal-content .ant-modal-body .right-section {
    display: flex;
    justify-content: center;
    flex-direction: inherit;

    .login-for-events {
      width: 630px;
    }
  }

  .add_task_modal
    .ant-modal-content
    .ant-modal-body
    .left-section
    .block-section {
    display: flex;
    flex-direction: row;
  }

  .add_task_modal .ant-modal-content .ant-modal-body .task-duration {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    padding-bottom: 10px;
    width: 100%;
    align-items: center;

    .durationContent {
      width: 630px;
    }
  }
}

@media (max-width: 650px) {
  .add_task_modal {
    .ant-modal-content .ant-modal-body .right-section {
      justify-content: center;
    }
  }
}

@media (max-width: 575px) {
  .add_task_modal {
    .ant-modal-content .ant-modal-body {
      padding: 10px;
    }
    .antCustomStepper {
      width: 95%;
      height: 200px;
      .ant-steps-item {
        height: 36px;
      }
      .ant-steps-navigation.ant-steps-vertical > .ant-steps-item-active:after,
      .ant-steps-navigation.ant-steps-vertical > .ant-steps-item-finish:after {
        top: -60px;
        left: 100%;
        transform: rotate(0deg);
      }
      .ant-steps-navigation.ant-steps-vertical
        > .ant-steps-item:last-child::after {
        display: none;
      }
      .ant-steps-item:last-child {
        .ant-steps-item-container {
          height: 36px;
          .ant-steps-item-content {
            display: flex;
            align-items: center;
          }
        }
      }
    }
    .ant-modal-content
      .ant-modal-body
      .modal-box-section
      .left-section
      .block-section
      .second-section
      .timeDatePicker
      .clock-container {
      flex-direction: column;
      align-items: center;
    }
  }
}

@media (max-width: 450px) {
  .add_task_modal {
    .antCustomStepper {
      .ant-steps-item {
        width: 95%;
      }
      .ant-steps-navigation.ant-steps-vertical > .ant-steps-item-active:after,
      .ant-steps-navigation.ant-steps-vertical > .ant-steps-item-finish:after {
        top: -35px;
        left: 100%;
        transform: rotate(0deg);
      }
    }
    .deadlineButton {
      width: 50px;
      .mondayButtonText,
      .mondayButtonDivider {
        display: none;
      }
    }
  }
}

@media (max-width: 380px) {
  .add_task_modal {
    .antCustomStepper {
      width: 85vw;
    }
    .ant-modal-content .ant-modal-body .left-section .block-section {
      .first-section {
        .modal__default__props {
          p b {
            font-size: 14px;
          }
        }
        .task-title {
          .ant-input {
            width: 85vw;
          }
        }
        .task-labels {
          .ant-select {
            width: 85vw !important;
          }
        }
      }
      .second-section {
        .ql-toolbar.ql-snow + .ql-container.ql-snow {
          width: 85vw;
        }
        .timeDatePicker {
          width: 90vw;
        }
      }
    }
    .ant-modal-content .ant-modal-body .right-section .third-section {
      .calendar-container {
        width: 85vw;
      }
    }
  }
}
