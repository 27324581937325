.doc-types-files-modal {
    .doc-types-files-modal-footer-container {
        display: flex;
        gap: 10px;
    }

    .doc-types-files-modal-body-container {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        overflow-y: auto;
    }
}