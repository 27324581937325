.headerCheckAllContainer {
  display: flex;
  gap: 10px;
  align-items: center;

  .ant-tooltip-disabled-compatible-wrapper {
    width: fit-content;
    height: fit-content;
  }
  .ant-checkbox-wrapper {
    //not checked
    .ant-checkbox {
      border: 7px solid #fe4c4a;
      border-radius: 2px;

      .ant-checkbox-inner {
        border-radius: 0;
        border: 2px solid #ff7274;
        background-color: #eb4345;
        height: 18px;
        width: 18px;
        padding: 3px;
      }
    }

    //checked
    .ant-checkbox-checked {
      border: 7px solid #68c142;

      .ant-checkbox-inner {
        border-radius: 0;
        border: 2px solid #71cf48;
        background-color: #68c142;
        height: 18px;
        width: 18px;
        padding: 3px;
        content: url("../../../../../../../../../../../../pages/Settings/settingsComponents/Roles/src/TickIcon.svg");
      }
    }

    //indeterminate
    .ant-checkbox-indeterminate {
      border: 7px solid #1264a3;
      border-radius: 2px;

      .ant-checkbox-inner {
        border-radius: 0;
        border: 2px solid #1c7ac2;
        background-color: #0f5c97;
        height: 18px;
        width: 18px;
        padding: 3px;
        content: "";
        &::after {
          background-color: white;
        }
      }
    }
  }

  .ant-checkbox-wrapper-disabled {
    filter: brightness(0.65);
    //not checked
    .ant-checkbox {
      border: 7px solid #fe4c4a;
      border-radius: 2px;

      .ant-checkbox-inner {
        border-radius: 0;
        border: 2px solid #ff7274 !important;
        background-color: #eb4345;
        height: 18px;
        width: 18px;
        padding: 3px;
      }
    }

    //checked
    .ant-checkbox-checked {
      border: 7px solid #68c142;

      .ant-checkbox-inner {
        border-radius: 0;
        border: 2px solid #71cf48 !important;
        background-color: #68c142;
        height: 18px;
        width: 18px;
        padding: 3px;
        content: url("../../../../../../../../../../../../pages/Settings/settingsComponents/Roles/src/TickIcon.svg");
      }
    }

    //indeterminate
    .ant-checkbox-indeterminate {
      border: 7px solid #1264a3;
      border-radius: 2px;

      .ant-checkbox-inner {
        border-radius: 0;
        border: 2px solid #1c7ac2 !important;
        background-color: #0f5c97;
        height: 18px;
        width: 18px;
        padding: 3px;
        content: "";
        &::after {
          background-color: white;
        }
      }
    }
  }
}
