$inputDarkColor: #2a2b3a;
$mainDarkBackgroundColor: #20212d;
$cardDarkBackgroundColor: #12131b;

.chart-cards-wrapper {
  height: 100%;
  width: 100%;
  overflow: auto;
  .chart-cards-content {
    display: flex;
    width: 100%;
    .chart-cards {
      height: 100%;
      width: 100%;
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
      padding: 10px;
      overflow: hidden; //needed
    }
  }

  &.dark-mode {
    background: #20212d;
  }
}

//general styles for chart popovers
.chart-cards-popover {
  border-radius: 5px;
  background: #ffffff;
  .labeledInputContainer {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
}

.chart-cards-popover-content-dark {
  background-color: #2a2b3a !important;
  .ant-popover-arrow::before {
    border-top-color: #2a2b3a !important;
  }
  .ant-popover-content {
    .ant-popover-inner {
      background-color: #2a2b3a !important;
      .ant-popover-inner-content {
        color: #f2f2f2;
        .chart-card-three-doted-actions {
          border-radius: 5px;
          overflow: auto;
        }
      }
      .ActionElementThreeDots {
        color: #f2f2f2;
        background-color: #2a2b3a !important;
        &:not(:last-child) {
          border-bottom: 1px solid #12131b;
        }
        &:hover {
          background-color: #12131b !important;
        }
      }
    }
  }
  .ant-popover-arrow::before {
    background-color: #2a2b3a;
  }
}
