.payment-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .payment-header-left {
    display: flex;
    gap: 40px;
    align-items: center;
  }
}
