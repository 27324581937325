@use "/src/index.scss" as index;

.legend-popover {
  .ant-popover-arrow {
    &::before {
      background-color: #f4f5f6;
    }
  }
  .ant-popover-content {
    .ant-popover-inner {
      border-radius: 10px;
    }
    .ant-popover-title {
      height: 32px;
      display: flex;
      align-items: center;
      border-radius: 5px 5px 0px 0px;
      background-color: #f4f5f6;
      padding: 0px 10px;
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 0px;
    }
    .ant-popover-inner-content {
      padding: 10px;
      .legend-content {
        display: flex;
        flex-direction: column;
        gap: 5px;
        .legend-item {
          width: 100%;
          display: flex;
          gap: 10px;
          align-items: center;
        }
      }
    }
  }
}

.legend-popover-dark {
  .ant-popover-arrow {
    &::before {
      background-color: index.$mainDarkBackgroundColor;
    }
  }
  .ant-popover-content {
    .ant-popover-inner {
      background-color: index.$mainDarkBackgroundColor;
      box-shadow: 0 6px 16px 0 rgba(120, 120, 120, 0.078),
        0 3px 6px -4px rgba(120, 120, 120, 0.12),
        0 9px 28px 8px rgba(120, 120, 120, 0.05);
    }
    .ant-popover-title {
      color: #fff;
      background-color: index.$cardDarkHeaderColor;
    }
    .ant-popover-inner-content {
      border-radius: 0px 0px 10px 10px;
      .legend-content {
        .legend-item {
          color: #fff;
        }
      }
    }
  }
}
