.ag-grid-payments-table {
  margin-top: 10px;
  // height: 100%;
  // overflow: scroll;
  // height: 100%;
  .ag-wrapper.ag-input-wrapper.ag-text-field-input-wrapper {
    color: black !important;
  }
  .payment-project-item {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    // justify-content: center;
    gap: 10px;
    width: 48px;
    height: 36px;
    line-height: 36px;
    padding-left: 12px;
    background: #f5f7f9;
    font-size: 1.125rem;
    cursor: not-allowed;
    .ant-checkbox-wrapper {
      height: 36px !important;
      display: flex;
      padding-top: 3.5px;

      .ant-checkbox {
        border: 5px solid #fe4c4a;
        border-radius: 2px;

        .ant-checkbox-inner {
          border-radius: 0;
          border: 2px solid #ff7274;
          background-color: #eb4345;
          height: 14px;
          width: 14px;
        }
      }

      //checked
      .ant-checkbox-checked {
        border: 5px solid #68c142;

        .ant-checkbox-inner {
          border-radius: 0;
          border: 2px solid #71cf48;
          background-color: #68c142;
          height: 14px;
          width: 14px;
          content: url("../../../../../../../../pages/Settings/settingsComponents/Roles/src/TickIcon.svg");
        }
      }

      //indeterminate
      .ant-checkbox-indeterminate {
        border: 5px solid #1264a3;
        border-radius: 2px;

        .ant-checkbox-inner {
          border-radius: 0;
          border: 2px solid #1c7ac2;
          background-color: #0f5c97;
          height: 14px;
          width: 14px;
          padding: 2px;
          content: "";
          &::after {
            background-color: white;
          }
        }
      }
    }
  }
}
.invoiceTooltip {
  .ant-tooltip-content {
    min-width: 400px;
    // height: 300px;
  }
}
.invoice-overview-tooltip {
  // width: 1000px !important;
  // // height: 300px;
}
