@import "../../../../../../index.scss";

.opportunityCompanyNameDropdown {
  .buttonsContainer {
    width: 100%;
    margin-top: 5px;
    @include flex(center, center);
    .ant-btn {
      margin: 5px;
    }
  }
}
