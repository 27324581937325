$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.change-chart-type {
  .ant-modal-content {
    max-width: 400px;
    max-height: 300px;
    .ant-modal-header {
      background-color: #f5f5f7;
      // padding: 20px;
      display: flex;
      align-items: center;
      height: 45px;
      .ant-modal-title {
        color: #323338;
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        width: 100%;
      }
    }

    .ant-modal-body {
      .labeledInputContainer {
        .labeledInputLabel {
        }

        .ant-select {
          width: 100%;
          .ant-select-selector {
            border: 1px solid #d9d9d9;
            border-radius: 4px;
            // .ant-select-selection-search {
            //   .ant-select-selection-search-input {
            //     height: 40px;
            //   }
            // }
          }
        }
      }
    }
    .ant-modal-close {
      height: 45px;
      padding-right: 10px;
    }

    .ant-modal-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      flex-wrap: nowrap;
      button {
        width: 100px;
      }
    }
  }
}

.change-chart-type-dark {
  .ant-modal-content {
    background-color: $cardModalHeaderDark;
    overflow: hidden;

    .ant-modal-close {
      svg {
        fill: #fff;
      }
    }

    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: white;
      }
    }

    .ant-modal-body {
      background-color: $cardModalBodyDark;

      .labeledInputContainer {
        .labeledInputLabel {
          color: #fff;
        }

        .ant-select {
          background-color: $inputModalDark;

          .ant-select-selector {
            background-color: $inputModalDark !important;
            .ant-select-selection-search {
              background-color: $inputModalDark;

              .ant-select-selection-search-input {
                background-color: $inputModalDark;
              }

              .ant-select-selection-item {
                color: #fff;
              }
            }

            .ant-select-selection-placeholder {
              color: #fff;
            }
          }

          .ant-select-arrow {
            color: #fff;
          }
        }
      }
    }

    .ant-modal-footer {
      background-color: $cardModalBodyDark;
    }
  }
}

.change-chart-dropdown-dark {
  background-color: $cardModalBodyDark;
  .rc-virtual-list-holder {
    background-color: $cardModalBodyDark;
    .rc-virtual-list-holder-inner {
      background-color: $cardModalBodyDark;

      .ant-select-item {
        color: #fff;
        background-color: $cardModalBodyDark;

        &:hover {
          background-color: lighten($cardModalBodyDark, 10%);
        }
      }
    }
  }
}
