$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 1024px;

.tree-element-item-container {
  display: flex;
  gap: 5px;
  align-items: center;

  @media (max-width: $breakpoint-sm) {
    display: grid;
  }

  @media (min-width: $breakpoint-md) and (max-width: $breakpoint-lg) {
    display: grid;
  }

  .split-line {
    width: 2px;
    height: 15px;
    background-color: #dddfe3;
  }
}

.tree-element-child-item-container {
  display: flex;
  gap: 5px;
  // justify-content: space-between;
  width: 100%;
  .split-line {
    width: 2px;
    height: 15px;
    background-color: #dddfe3;
  }
}
