.widget-confirm-container {
  display: flex;
  gap: 20px;
  .widget-confirm {
    flex: 1;
    background-color: #f8f8fa;
    border-radius: 5px;
    padding: 10px;
    .widget-title {
      height: 30px;
      padding-left: 10px;
      font-size: 16px;
      font-weight: 600;
    }
    .widget-chart {
      display: flex;
      align-items: center;
      .chart-title {
        font-weight: 600;
      }
      .chart-icon {
      }
    }
  }

  &.dark-mode {
    .widget-confirm {
      background: #12131b;
      color: #f2f2f2;
      svg {
        path {
          fill: #f2f2f2;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .widget-confirm-container {
    flex-direction: column;
  }
}
