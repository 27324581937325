.dataEntryModal.ag-theme-balham {
  .clearfix {
    .clearfix-secondary-btn {
      background: transparent;
      border: none;
      color: #444a4c;

      &:hover {
        background-color: #bbbbbb;
      }
    }

    .clearfix-primary-btn {
      button[disabled] {
        background-color: #f5f7f9;
        border: 1px solid #188ffe;
        border-radius: 3px;
        color: #188ffe;

        &:hover {
          color: #5d5d5d;
          border-color: #5d5d5d;
        }
      }
    }

    .service-container-menu-toggle {
      color: #0c1628;
      background-color: #dfe0e2;
      border: none;
    }
  }
  .serviceMenuContainer .hide-toggle-container {
    .ant-btn {
      width: 100px;
      background-color: #1264a3 !important;
    }
  }
  .ant-modal-close-x {
    color: #ffffff;
  }

  .ant-modal-header {
    // background-color: #188ffe;
    background-color: #1264a3;

    .ant-modal-title {
      color: #ffffff;

      .ant-switch {
        background: transparent;
        color: #fff;
        border: 1px solid #fff;

        .ant-switch-handle {
          &::before {
            background-color: #fff;
          }
        }

        .ant-switch-inner {
          color: #fff;
        }

        &.ant-switch-checked {
          // background: #fff;
          background: #71cf48;
          color: #188ffe;
          border: 1px solid #fff;

          .ant-switch-handle {
            &::before {
              background-color: #1890ff;
            }
          }

          .ant-switch-inner {
            color: #188ffe;
          }
        }
      }
    }
  }
  .option_header_wrapper {
    background: linear-gradient(to right, #f0f0f0, #71cf48) !important;
    border-radius: 0 5px 5px 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    color: white !important;
  }
  .option_header_wrapper .totalUnit {
    background: #f0f0f0 !important;
    border-radius: 5px;
    color: black !important;
    border-right: none !important;
    // background: linear-gradient(to right, #f0f0f0, #71cf48) !important;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2) !important;
  }
  .ant-input-affix-wrapper {
    background-color: red !important;
    color: #f5f5f5;
    border: none;
  }

  @function textShadow($precision, $size, $color) {
    $value: null;
    $offset: 0;
    $length: $size * (1 / $precision) - 1;
    @for $i from 0 through $length {
      $offset: $offset + $precision;
      $shadow: $offset + px $offset + px $color;
      $value: append($value, $shadow, comma);
    }
    @return $value;
  }

  .dataEntryTitle {
    // color: #dfe0e2;
    color: #333333;
    background-color: #334928;
    color: #fff;

    //text-shadow: textShadow(1, 6, rgba(133, 162, 248, 1));
  }

  // .ag-row-hover {
  //   //background-color: #5d5d5d;
  // }

  .priceCalculationInputSection {
    overflow: hidden !important;

    .priceCalculationInput {
      margin-right: 10px;
      //width: 200px;
      display: block;

      .ant-input {
        border: none;
        background-color: #d7d8d9;
        color: #444a4c;

        &::selection {
          background-color: ghostwhite;
        }

        &:focus {
          box-shadow: none;
        }
      }

      .ant-input-group-addon {
        border: none;
        background-color: #dfdfdf;
        color: #444a4c;
      }

      .ant-select {
        .ant-select-selector {
          background-color: #d7d8d9;
          border: none;

          .ant-select-selection-item {
            color: #444a4c;
          }
        }

        .ant-select-arrow {
          color: #444a4c;
        }
      }

      .ant-input-affix-wrapper {
        border: 1px solid #dfdfdf;
        border-radius: 4px 0px 0px 4px;
        background-color: #f5f7f9;
        color: #444a4c;
      }
    }

    // .service-total {
    //   color: #f5f5f5;

    //   .service-total-element {
    //     &.rent {
    //       // color: #7abdff;
    //       color: #1264a3;
    //     }

    //     &.price {
    //       color: #6d9c4a;
    //     }
    //   }
    // }
  }

  //for hoist
  .stops-count-message,
  .total-floor-height {
    color: #272b2d;
  }

  .floor-height-message {
    color: #272b2d;
    .floor-height-message-difference {
      color: orange;
    }
  }

  .lift-height-warning-message {
    color: orange;
  }

  .ant-dropdown .ant-dropdown-menu {
    background-color: #272b2d !important;
  }

  .ant-btn-primary {
    background-color: #1890ff;
    border-color: #1780e2;
    color: #f5f5f5;

    &:hover {
      background-color: #40a3ff;
    }

    &:active {
      background-color: #1372c6;
    }

    // &:disabled {
    //   //background-color: #000000 !important;
    //   //border-color: #1890ff !important;
    //   //
    //   //&:hover {
    //   //  color: #5d5d5d !important;
    //   //  border-color: #5d5d5d !important;
    //   //}
    // }
  }

  .ant-switch {
    border-color: #4a4a4a;
  }

  .ant-modal-body {
    background-color: #e5e5e5;
  }

  .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    background-color: #f5f5f5 !important;
  }

  .ant-tabs-bar {
    border-color: #4a4a4a !important;
  }

  .ant-tabs-tab {
    border-bottom: solid 1px #4a4a4a;

    &:first-child {
      border-top: solid 1px #4a4a4a;
    }

    .tabText {
      color: #aeaeae;

      &:hover {
        //transition: 400ms;
        color: #f5f5f5;
      }
    }
  }

  .ant-tabs-tab-active {
    .tabText {
      color: #ffffff;
    }
  }

  .optionText {
    color: #1e1f20;
    border-top: solid 1px #4a4a4a;

    .option-header {
      .units {
        color: #372b1d;
      }
    }
  }

  // Elevation Header
  .ant-collapse {
    // border-color: #4a4a4a;
    border: none !important;
    // background-color: #1264a3;
    color: #323338 !important;
    background-color: #f5f5f7;
    .ant-collapse-expand-icon {
      svg {
        color: #323338 !important;
        fill: #323338 !important;
        path {
          fill: #323338 !important;
        }
      }
    }
    .ant-collapse-header-text {
      .elevation-panel-header {
        // background-color: #1264a3;
        color: #323338 !important;
      }
    }
    .ant-collapse-item:hover {
      .ant-collapse-header {
        // background-color: #a8a8a8;
        border-radius: 5px !important;
        color: #323338;
        .ant-collapse-header-text {
          .elevation-panel-header {
            // background-color: #1264a3;
            color: #323338 !important;
          }
        }
        .ant-collapse-expand-icon {
          svg {
            color: #323338 !important;
            fill: #323338 !important;
            path {
              fill: #323338 !important;
            }
          }
        }
      }

      .gridResizer {
        opacity: 1;
      }
    }

    .ant-collapse-header {
      color: #372b1d;
      height: auto;
      border-radius: 5px !important;
    }

    .ant-collapse-content {
      background-color: #f5f7f9;
      color: #323338;
      border-color: #4a4a4a;
      //transition: 300ms !important;
      .ant-collapse-content-box {
        .elevationContainer {
          .addonsContainer {
            // color: #323338;
            background-color: navajowhite;
            //
          }
        }
      }
    }
  }
  .ant-drawer-body {
    background-color: #f5f5f7 !important;
  }
  .ant-collapse-item {
    // border-color: #4a4a4a;
    border: none !important;
  }

  .gridResizer {
    opacity: 0;

    .gridResizerInnerLeft {
      //transition: 300ms;
      background-color: #007ac3;
    }

    .gridResizerInnerRight {
      //transition: 300ms;
      background-color: #007ac3;
    }

    .gridResizerHandler {
      background-color: #007ac3;

      .gridResizerHandlerGrip {
        background-color: #78a1ba;
      }
    }

    &:hover {
      .gridResizerInnerLeft {
        background-color: #12a3fa !important;
      }

      .gridResizerInnerRight {
        background-color: #12a3fa !important;
      }

      .gridResizerHandler {
        background-color: #12a3fa !important;
      }
    }

    &:active {
      opacity: 1;

      .gridResizerInnerLeft {
        background-color: #09659b !important;
      }

      .gridResizerInnerRight {
        background-color: #09659b !important;
      }

      .gridResizerHandler {
        background-color: #09659b !important;
      }
    }
  }

  .elevationContainer {
    background-color: red;
    .addonsContainer {
      color: white;
    }

    .leftQuoteBlock {
      border-left: solid 5px #f5f5f5;
    }

    //this is moved in the end of this main class
    // .rowModifierButton {
    //   background-color: #188ffe !important;
    // }
  }

  .elevationPriceCalculationContainer {
    .priceCalculation {
      background-color: #272b2d;

      color: #f5f5f5;
      border: none;

      &:hover {
        background-color: #383b3c !important;
      }

      &:focus {
        background-color: #383b3c;
      }

      &::selection {
        background-color: #ffffff;
      }
    }
  }

  .approved-header {
    .ant-checkbox-wrapper-checked.checkboxRenderer-approved {
      .ant-checkbox-inner {
        background-color: #43a040;
        border-color: #43a040;
      }
    }
  }

  .approved-row {
    color: #43a040 !important;
    .checkboxRenderer-approved {
      .ant-checkbox-checked {
        .ant-checkbox-inner {
          background-color: #43a040;
          border-color: #43a040;
        }
      }
    }

    [col-id="id"] {
      border-left: 7px solid #43a040;
    }
  }

  .invalid-price {
    color: #e3961c;
  }

  .out-of-range-dimension-higher {
    //font-family: monospace;
    //font-style: oblique;
    text-decoration-line: underline;
    text-decoration-style: wavy;
    text-decoration-color: #a40000;
  }

  .out-of-range-dimension-lower {
    //font-family: monospace;
    //font-style: oblique;
    text-decoration-line: underline;
    text-decoration-style: double;
    text-decoration-color: #a40000;
  }

  .lock {
    [col-id="ppu"],
    [col-id="price"],
    [col-id="rent"],
    [col-id="lock"] {
      background: linear-gradient(
        to right,
        #eae7cb 0%,
        #f6ffaf60 20%,
        #eae7cb 50%,
        #f6ffaf60 80%,
        #eae7cb 100%
      );
    }

    .checkboxRenderer-lock-true {
      color: #c8af35;
    }
  }

  .id-column {
    // background-color: #f9db55;
    background-color: #e6e6e6;
  }

  .ppu-advisor-cell {
    .ppu-difference {
      color: #372b1d;
    }
    .ant-btn {
      background-color: #1890ff !important;
      border-color: #1780e2 !important;
      color: #f5f5f5 !important;

      &:disabled {
        border: 1px solid #188ffe !important;
        border-radius: 3px !important;
        color: #188ffe !important;
        background: transparent !important;
      }
    }
  }
  // .jump-renderer {
  //   // .jump-input {
  //   // }
  // }

  .checkboxRenderer-isJump-false {
    background-color: #fff;
    border: 1px solid #d9d9d9;

    &:hover {
      border-color: #1890ff;
      background-color: #fff;
    }
  }

  .checkboxRenderer-isJump-true {
    background-color: #1890ff;
  }

  .addons-count-number {
    display: inline-block;
    font: normal normal normal 14px Open Sans;
    color: #372b1d;
  }

  .addons-count-zero {
    color: #545454;
  }

  .dataEntryGrid {
    position: relative;

    .dataEntryGrid-cell-select:not(.Mui-disabled) {
      color: #372b1d;

      svg {
        color: #372b1d;
      }
    }

    .dataEntryGrid-cell-select.Mui-disabled {
      color: #cccccc;

      svg {
        color: #cccccc;
      }
    }

    .ag-cell-label-container {
      .ag-header-cell-label span.ag-header-cell-text {
        color: #a7a7a7;
      }
    }
  }

  // .ag-text-field-input::selection,
  // .ag-text-area-input::selection {
  //   background: #ffffff;
  // }

  .ag-react-container {
    .ant-btn {
      background-color: #1890ff;
    }
  }

  .ag-cell:not(.ag-cell-focus):not(.ag-cell-range-selected) {
    border-right: solid 1px #4f4f4f !important;
  }

  .serviceMenu {
    .react-grid-item {
      //transition-duration: 0s !important;
      width: 100%;
      height: 50px;
      &.react-grid-placeholder {
        background-color: #00eaff !important;
        opacity: 0.4 !important;
      }
    }
  }

  .serviceContainer {
    .total-and-rent-container {
      .total-and-rent-element {
        color: #f5f5f5;
      }
    }

    .service-header {
      .service-title {
        color: #1c1c1c;
      }

      input {
        background-color: #d7d8d9 !important;
        color: #372b1d;
        // width: 100%;
        width: 100px;
        height: 32px;
      }
    }

    .text-center {
      color: #1c1c1c;
    }

    .service-actions {
      .ant-btn {
        // color: #2680eb;
        color: #1264a3;
        background-color: #dfdfdf;
        border: none;
        &:hover {
          background-color: #d7d8d9;
        }
      }
    }

    // .pricing-button {
    //   button {
    //     //no styling at the moment
    //   }
    // }

    .ant-collapse-extra {
      svg {
        &:hover {
          color: #40a9ff;
        }
      }
    }
  }

  .serviceMenuContainer {
    // color: #1c1c1c !important;
    // strong {
    //   color: #1c1c1c !important;
    // }
    // strong.serviceLabel {
    //   color: #1c1c1c !important;
    // }
    background-color: #fff !important;
    border-radius: 10px;
    .appendServiceButton {
      background-color: transparent;
      border: 1px solid #4f4f4f;

      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
        border-color: #d7d7d7;
      }
    }

    .hideShowAllServicesButton {
      background-color: transparent;
      border: 1px solid #4f4f4f;

      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
        border-color: #d7d7d7;
      }
    }

    .checkboxContainer {
      background-color: rgba(255, 255, 255, 0);
      // border: 1px solid #4f4f4f;
      display: flex;
      height: 100%;
      align-items: center;
      svg {
        color: #1264a3 !important;
        fill: #1264a3 !important;
      }
      .scopeIconContainer {
        fill: #1264a3 !important;
        svg {
          fill: #1264a3 !important;
          path {
            fill: #1264a3 !important;
          }
        }
      }
      // color: #1264a3 !important;
      color: #1c1c1c !important;

      &:hover {
        background-color: rgba(200, 200, 200, 0.8);
        color: #1c1c1c !important;
        border-color: #d7d7d7;
      }
    }

    .appendServiceButton,
    .hideShowAllServicesButton {
      color: #444a4c;
    }
  }

  .emptyPlaceholder {
    span {
      color: #6b6b6b !important;
    }
  }

  .elevation-panel-header {
    .elevation-panel-price-container {
      .priceCalculationInput {
        .ant-input-group-addon {
          border: none;
          background-color: #dfe0e2;
          color: #372b1d;
        }

        .ant-input {
          border: none;
          background-color: #d7d8d9;
          color: #372b1d;
        }

        .ant-input-affix-wrapper {
          background-color: #d7d8d9;
          color: #372b1d;
          border: none;
        }

        input {
          background-color: #d7d8d9;
          color: #372b1d;
        }

        .rent-higher-than-price {
          text-decoration-line: underline;
          text-decoration-style: wavy;
          text-decoration-color: #a40000;
        }
      }

      .elevation-total {
        .elevation-total-element {
          border-right: 1px #707070 solid;
        }

        .rent-higher-than-price {
          text-decoration-line: underline;
          text-decoration-style: wavy;
          text-decoration-color: #a40000;
        }

        .flash-true {
          animation: heartbeat 2s ease-in-out infinite both;
        }

        @keyframes heartbeat {
          from {
            transform: scale(1);
            transform-origin: center center;
            animation-timing-function: ease-out;
          }
          10% {
            transform: scale(0.91);
            animation-timing-function: ease-in;
          }
          17% {
            transform: scale(0.98);
            animation-timing-function: ease-out;
          }
          33% {
            transform: scale(0.87);
            animation-timing-function: ease-in;
          }
          45% {
            transform: scale(1);
            animation-timing-function: ease-out;
          }
        }
      }

      .ant-btn {
        background-color: #1890ff;
      }
    }
  }

  .elevationLabel {
    background-color: #d7d8d9;
    color: #372b1d;
    border: none;

    &:hover {
      background-color: #eee !important;
    }

    &:focus {
      background-color: #eee;
    }

    &::selection {
      background-color: #ffffff;
    }
  }

  .appendElevation {
    background-color: transparent;
    border: dashed 1px #1890ff;
    color: #1c1c1c;

    &:hover {
      background-color: #dfe0e2;
    }
  }

  .hoistForm {
    padding-top: 5px;

    .hoistFormSectionParent {
      margin-bottom: 5px !important;
      padding-bottom: 10px !important;
      padding-top: 10px !important;
      //border-bottom: solid 1px #474747;

      .hoistFormSection {
        .ant-input-affix-wrapper {
          background-color: #d7d8d9;
        }
        .ant-input-suffix {
          color: #372b1d;
        }

        .hoistFormSectionChild {
          &.tagSelect {
            .addonBefore {
              height: 30px;
              color: #1c1c1c;
              background-color: #dfe0e2;
            }

            .selectComponent {
              .ant-select-selection {
                border: none;
                background-color: #272b2d;
                color: #f5f5f5;
                height: 30px;

                &:hover {
                  background-color: #383b3c;
                }

                &:focus {
                  background-color: #383b3c;
                }

                &::selection {
                  background-color: #ffffff;
                }

                .ant-select-selection__choice {
                  background-color: #1c1c1c;
                  color: #f5f5f5;
                  border: none;

                  // &:hover {
                  //   //background-color: #292929;
                  // }
                }

                .ant-select-selection__choice__remove {
                  color: #f5f5f5;
                }
              }
            }
          }
        }

        .ant-select {
          .ant-select-selection {
            background-color: #1c1c1c;
            border-color: #414141;

            .ant-select-selection-selected-value {
              color: #1c1c1c;
            }

            .ant-select-arrow {
              color: #1c1c1c;
            }
          }

          &:hover {
            .ant-select-selection {
              background-color: #303030;
            }
          }
        }

        & > button {
          background-color: #1890ff;
        }
      }

      .hoistFormLabel {
        color: #1c1c1c;
      }

      .ant-radio-group.ant-radio-group-solid {
        .ant-radio-button-wrapper {
          background-color: #d7d8d9;
          color: #1c1c1c;
          border: none;

          &.ant-radio-button-wrapper-checked {
            background-color: #1890ff;
            color: #f5f5f5;
            border: none;

            &:hover {
              background-color: #40a9ff;
            }
          }
        }
      }

      .ant-input-group-addon {
        background-color: #dfe0e2;
        color: #372b1d;
        border: none;
      }

      .ant-input {
        background-color: #d7d8d9;
        color: #1c1c1c;
        border: none;
      }
    }
  }

  .ag-virtual-list-item {
    color: #0c1628;
  }

  .ag-row-level-1 {
    .sidewalkShedDetailContainer {
      width: 100%;

      .ant-typography {
        color: #0c1628;
      }
    }
  }
}
