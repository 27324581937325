// .ant-modal-content {
//   .est-description-rich-text {
//     width: auto !important;
//     .date-btn {
//       right: 32%;
//       bottom: 68%;
//     }
//     .hour-btn {
//       right: 18%;
//       bottom: 68%;
//     }
//   }
// }

.est-description-modal__ag-theme-balham-dark {
  // antd modal dark theme
  .ql-fill {
    fill: #fff !important;
  }
  .ql-snow .ql-picker-label {
    cursor: pointer;
    display: inline-block;
    height: 100%;
    padding-left: 8px;
    padding-right: 2px;
    position: relative;
    width: 100%;
    color: white !important;
  }
  .ql-snow .ql-stroke {
    fill: none;
    stroke: #fff !important;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2;
  }
  .ant-modal-content {
    background-color: #1f212d;
    border: 1px solid #1f212d;
    .ant-modal-header {
      background-color: #12131b;
      border-bottom: 1px solid #12131b;
      .ant-modal-title {
        color: #fff;
      }
      .est-description-rich-text {
        width: auto !important;

        .date-btn {
          right: 32%;
          bottom: 68%;
        }
        .hour-btn {
          right: 18%;
          bottom: 68%;
        }
      }
    }
    .ant-modal-close-x {
      justify-content: center;
      align-items: center;
      display: flex;
      height: 45px;
      width: 45px;
      padding: 0;
      svg {
        fill: #fff !important;
        height: 15px;
        width: 15px;
      }
    }
    .ant-modal-body {
      background-color: #1f212d;
      color: #fff;
      .ant-input {
        background-color: #1f212d;
        border: 1px solid #1f212d;
        color: #fff;
      }
      .ant-input::placeholder {
        color: #fff;
      }
      .note_input .ql-editor {
        background-color: #fff;
      }
    }
    .ant-modal-footer {
      background-color: #1f212d;
      border-top: 1px solid #1f212d;
      .ant-btn {
        border: 1px solid #1f212d;
      }
    }
  }
}

.est-description-modal__footer {
  display: flex;
  justify-content: space-between;
}

// .ql-editor p {
//   font-family: "Open Sans";
//   font-style: normal;
//   font-weight: 400;
//   font-size: 14px;
//   line-height: 19px;
//   color: #1f1f1f !important;
// }
