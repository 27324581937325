.preHearingModal {
  z-index: 1000 !important;
  height: fit-content !important;
  width: fit-content !important;
  .ant-modal-content {
    .ant-modal-header {
      height: 45px;
      display: flex;
      align-items: center;
      background-color: #f8f8fa;
      overflow: hidden;
      border: none;

      .ant-modal-title {
        font-size: 16px;
        width: 100%;
        font-weight: 600;
        color: #323338;
        padding: 0 !important;
        height: 45px;
        display: flex;
        align-items: center;
      }
    }
    .ant-modal-body {
      .note-modal-content {
        display: flex;
        flex-direction: column;
        min-width: 380px;
        width: fit-content !important;
        .loader {
          width: 100%;
          display: flex;
          justify-content: center;
          margin-bottom: 24px;
        }
        .modalFooter {
          margin-top: 20px;
          align-self: center;
        }
      }
    }
  }
}
@media screen and (max-width: 575px) {
  .preHearingModal {
    width: 250px;
    .note-modal-content {
      width: 360px;
      .labeledInputContainer {
        width: 300px !important;
      }
    }
    .noClaimsDiv {
      flex-direction: column;
      align-items: center;
    }
  }
}

.ant-modal-mask {
  z-index: 1000 !important;
}
.ant-modal-wrap {
  z-index: 1000 !important;
}
