@import "../../../index.scss";

.RichTextModalEditorLight {
  .ant-modal {
    width: 850px !important;
  }
  .mondayButtonBlue {
    .textContainer {
      display: flex !important;
    }
  }

  .ant-modal-content {
    width: 850px;
    border-radius: 10px;
    overflow: hidden;

    .ant-modal-header {
      border-bottom: 0;
      height: 45px;
      background-color: #f8f8f8;
      align-items: center;
      display: flex;

      .ant-modal-title {
        font: normal normal 600 16px/39px Open Sans;
      }
    }

    .ant-modal-close-x {
      justify-content: center;
      align-items: center;
      display: flex;
      height: 45px;
      width: 45px;
      padding: 0;
      svg {
        fill: black;
      }
    }

    .ant-modal-footer {
      display: flex;
      justify-content: flex-end;
      height: 45px;
      padding: 0 15px;
      align-items: center;
      border-top: 0;
      gap: 15px;
      background-color: #f8f8f8;
    }

    .ant-modal-body {
      background-color: white;
      padding: 20px;
      width: 850px;

      .accountingConfirmationModalContainer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: 400px;
        width: stretch;
        .tox-tinymce {
          height: 400px !important;
          width: 800px !important;
        }

        .tox-tbtn {
          color: #12131b;
          &:hover {
            background: #bdbdbd;
          }
        }

        .tox-sidebar-wrap {
          .tox-edit-area__iframe {
            border-radius: 10px;
            background: white;
          }
        }
      }
    }
  }
}

//==========================================

.RichTextModalEditorDark {
  .ant-modal {
    width: 850px !important;
  }
  .mondayButtonBlue {
    .textContainer {
      display: flex !important;
    }
  }

  .ant-modal-content {
    width: 850px;
    border-radius: 10px;
    overflow: hidden;

    .ant-modal-header {
      border-bottom: 0;
      height: 45px;
      background-color: #12131b;
      align-items: center;
      display: flex;

      .ant-modal-title {
        font: normal normal 600 16px/39px Open Sans;
        color: white;
      }
    }

    .ant-modal-close-x {
      justify-content: center;
      align-items: center;
      display: flex;
      height: 45px;
      width: 45px;
      padding: 0;
    }

    .ant-modal-footer {
      display: flex;
      justify-content: flex-end;
      height: 45px;
      padding: 0 15px;
      align-items: center;
      border-top: 0;
      gap: 15px;
      background-color: #12131b;
    }

    .ant-modal-body {
      background-color: #1f212d;
      padding: 20px;
      width: stretch;

      .accountingConfirmationModalContainer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: 400px;
        width: stretch;
        .tox-tinymce {
          height: 400px !important;
          width: 800px !important;
        }

        .tox-toolbar__primary {
          background-color: #1f212d;

          svg {
            fill: white;
          }
        }

        .tox-tbtn {
          color: white;
          &:hover {
            background: #12131b;
          }
        }

        .tox-sidebar-wrap {
          .tox-edit-area__iframe {
            border-radius: 10px;
            background: white;
          }
        }
      }
    }
  }
}
