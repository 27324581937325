$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.viewDrawer {
  .ant-drawer-header {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    height: 45px;
    background: #1f2a44 0% 0% no-repeat padding-box;
    padding: 14px 20px;
    .ant-drawer-close {
      display: flex;
      align-items: center;
      padding: 0px;
      margin-right: 20px;
      svg {
        width: 17px;
        height: 17px;
      }
    }
    .titleLabel {
      text-align: left;
      font: normal normal 600 16px Open Sans;
      letter-spacing: 0px;
      color: #ffffff;
      opacity: 1;
    }
  }
  .viewDrawerBody {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
    .informationWrapper {
      display: flex;
      align-items: center;
      height: fit-content;
      margin: 20px;
      .informationIcon {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 20px;
        width: 20px;
        background-color: #1264a3;
        color: #fff;
        border-radius: 50%;
        margin: 0px 10px 0px 0px;
      }
      .informationText {
        font-size: 14px;
      }
    }
    .viewDrawerContent {
      display: flex;
      flex-direction: column;
      align-items: center;
      // padding-top: 20px;
      .hiddenElement {
        height: 86px;
        width: 445px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-radius: 10px;
        background-color: #f8f8fa;
        // padding-left: 24px;
        // padding-right: 24px;
        // border-right: 10px solid #ffffff;
        margin-bottom: 10px;
        .headerElementTitle {
          text-align: left;
          font: normal normal 400 18px Open Sans;
          letter-spacing: 0px;
          color: #323338;
          opacity: 1;
        }
        .headerElementIcon {
          cursor: pointer;
          display: flex;
          align-items: center;
          margin-top: 3px;
          .anticon svg {
            height: 19px;
            width: 20px;
          }
        }
        .showElementLink {
          font-size: 12px;
          color: #1264a3;
          cursor: pointer;
        }
      }
      .hiddenElement:hover {
        background-color: #f4f5f6;
        // border-right: 10px solid #1f2a44;
      }
    }
  }
  .ant-drawer-body {
    padding: 0px;
  }
  .ant-drawer-close {
    position: relative;
    color: #000000;
    font-size: 23px;
  }
}

.viewDrawerDark {
  .ant-drawer-wrapper-body {
    background-color: $cardModalBodyDark;
    .ant-drawer-header {
      background-color: $cardModalHeaderDark;
    }
    .ant-drawer-body {
      .viewDrawerBody {
        .informationWrapper {
          .informationText {
            color: #f2f2f2;
          }
        }
        .viewDrawerContent {
          .hiddenElement {
            background-color: $inputModalDark;
            .headerElementIcon svg {
              fill: #f2f2f2;
            }
            .headerElementTitle {
              color: #f2f2f2;
            }
          }
        }
      }
    }
  }
}
