@import "../../../../../../../../../../../index.scss";
.rentalCreditModalDark {
  .ant-modal-header {
    background-color: #12131b !important;
    color: #fff;
    .ant-modal-title {
      color: #fff;
    }
    .ant-modal-close-x {
      .ant-modal-close-icon {
        color: #fff !important;
        fill: white !important;
        svg {
          fill: white !important;
        }
      }
    }
  }
  .ant-modal-close {
    .ant-modal-close-x {
      color: #fff;
      font-size: 1.25rem;
      margin-top: -5px;
    }
  }

  .ant-modal-body {
    background-color: #1f212d !important;
    color: #fff;
    height: 100%;
    overflow-y: auto;
    padding-top: 20px;
    padding-bottom: 20px;
    .ant-steps-item-icon {
      display: none;
    }
  }
  .ant-modal-footer {
    background-color: #12131b !important;
    color: #fff;
  }
  .ant-steps-item-icon {
    display: none;
  }
  .antCustomStepper .outerTriangle,
  .antCustomStepper .ant-steps-navigation .ant-steps-item-process::before,
  .antCustomStepper .ant-steps-navigation .ant-steps-item-active::before,
  .antCustomStepper .ant-steps-navigation .ant-steps-item-finish::before,
  .antCustomStepper
    .ant-steps-navigation
    .ant-steps-item-wait:not(:last-child)::before {
    visibility: hidden !important;
  }
}

.stepper-buttons,
.add-cred-btn-holder {
  .detailsBtn {
    @include mondayButton(#1264a3, #0f5c97);
    svg {
      fill: black;
    }
  }
}
