.audit-map-view {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 10px;

  .audit-map {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    min-width: 40%;
    height: 100%;
    flex: 1;
  }

  #audit-map {
    width: 100%;
    flex: 1;

    .gm-style-iw.gm-style-iw-c {
      top: -18px;
      padding: 0 !important;
      .gm-style-iw-d {
        padding: 5px;
        overflow: auto !important;
      }
      button {
        display: none !important;
      }
    }

    .gm-style-iw-ch {
      display: none;
    }

    .gm-style-iw-tc {
      top: -20px;
      width: 18px;
      &::after {
        width: 18px;
      }
    }
  }
}

.ref-info-content {
  .info-content {
    padding: 5px;
    letter-spacing: 0.18px;
    white-space: nowrap;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;

    .info-description {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      flex-wrap: nowrap;
      font-size: 0.8rem;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.8);

      .marker-stop {
        font-weight: bold;
        letter-spacing: 0.1px;
        color: rgb(220, 64, 69);
      }

      .marker-idle {
        font-weight: bold;
        letter-spacing: 0.1px;
        color: rgb(223, 196, 0);
      }

      .location-dot {
        height: 10px;
        aspect-ratio: 1;
        display: block;
        box-sizing: border-box;
        background-color: transparent;
        border: 3px solid;
        border-radius: 50%;
        margin-right: 5px;
      }

      .location-dot.location-arrive {
        border-color: #ff4d4f;
      }

      .location-dot.location-depart {
        border-color: #52c41a;
      }
    }

    .info-address {
      font-size: 1rem;
      font-weight: bold;
    }
  }
}
