@import "../../../../Communication.scss";

.email-box-header-inputs-container {
  display: flex;
  flex-direction: column;
  padding-right: 8px;
  border-bottom: 1px solid #d9d9d9;
  width: 100%;
  color: $light-text-color;

  .email-box-form-item {
    width: 100%;

    .ant-select-selector {
      width: 100%;
    }
  }

  @media only screen and (max-width: 768px) {
    padding-right: 0px;
    border-bottom: 1px solid #d9d9d9;
  }

  .to-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 5px;

    .cc-bcc-triggers-container {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 10px;
      width: 15%;

      span:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  .cc-bcc-container {
    display: flex;
    flex-direction: column;

    .cc-container,
    .bcc-container {
      display: flex;
      align-items: flex-start;
      width: 100%;
      gap: 10px;
      color: red;

      .cc-input {
        width: 100%;
      }

      .remove-icon-container {
        width: 10%;
        display: flex;
        justify-content: flex-end;

        .remove-cc-label,
        .remove-bcc-label {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 20px;
          width: 20px;
          border-radius: 50%;
          border: 1px solid #e0e0e0;
          cursor: pointer;
        }
      }
    }
  }

  &.dark-mode {
    .cc-bcc-triggers-container {

      span {
        color: $dark-text-color;
      }
    }

    .ant-select-selector {
      background-color: $inputDarkColor;
      color: $dark-text-color;

      .ant-select-selection-placeholder {
        color: $dark-text-color;
        /* Change placeholder color to red */
      }

      svg {
        color: $dark-text-color;
        fill: $dark-text-color;
      }
    }


    .ant-select-clear {
      svg {
        color: #fff;
      }
    }

    .ant-select-clear {
      background-color: #2a2b3a !important;
      color: #ffffff !important;
    }
  }
}