@import "../../../../../Management/DepartmentUser/DepartmentUser.scss";

@mixin labelClassName($color) {
  @include size(80px, 30px);
  background-color: $color;
  margin-right: 10px;
  border-radius: 5px;
  @include div(row, center, center);
  text-align: left;
  font: normal normal normal 20px/39px Open Sans;
  letter-spacing: 0px;
  color: white;
  opacity: 1;
}
.previewModal {
  .ant-modal-content {
    position: relative;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    height: 730px;
    border-radius: 10px;
    box-shadow: 0 3px 6px -4px;
    pointer-events: auto;
    .ant-modal-close .ant-modal-close-x {
      justify-content: center;
      align-items: center;
      display: flex;
      height: 50px;
      width: 50px;
      padding: 0;
      svg {
        fill: #323338;
        height: 18px;
        width: 18px;
      }
    }
  }
  .ant-modal-header {
    @include div(row, center, flex-start);
    background-color: #f4f5f6;
    border-radius: 10px 10px 0px 0px;
    height: 50px;
    border: none;
    .ant-modal-title {
      font-weight: 600;
    }
  }
  .ant-modal-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: none;
    .mondayButtonContainer .mondayButtonStyle .mondayButtonIcon {
      height: 18px;
      width: 18px;
    }
  }

  .ant-modal-body {
    padding: 24px;
    font-size: 14px;
    line-height: 1.5715;
    word-wrap: break-word;
    @include size("", calc(100% - 110px));
  }
  .ant-modal-close-x {
    display: block;
    @include size(56px, 56px);
    font-size: 28px;
    font-style: normal;
    line-height: 45px;
    text-align: center;
    text-transform: none;
    text-rendering: auto;
  }
  .ant-btn-primary {
    display: inline;
    background: #4895ef;
    border-color: #4895ef;
    text-shadow: 0 -1px 0;
    box-shadow: 0 2px 0;
  }
  .previewModalBody {
    @include div(column, "", "");
    overflow-y: auto;
    height: 100%;
    .previewModalBodyContainer {
      @include div(column, "", "");
      margin-bottom: 20px;
      .categoryTitle {
        border-radius: 5px;
        height: 40px;
        background-color: $secondaryColor;
        border: 1px solid white;
        .categoryTitleLabel {
          margin-left: 10px;
          @include textStyle(left);
          font: normal normal normal 20px/39px Open Sans;
        }
      }
      .elementCategoryTitleContainer {
        @include div(column, "", "");
        .elementCategoryTitle {
          border-radius: 5px;
          height: 40px;
          margin-left: 50px;
          background-color: $labelColor;
          border: 1px solid white;
          @include div(row, "", space-between);
          .actionClassName {
            @include div(row, center, "");
            .labelTrueClassName {
              @include labelClassName(#71cf48);
              font-size: 14px;
              font-weight: 400;
            }
            .labelFalseClassName {
              @include labelClassName(#fe4c4a);
              font-size: 14px;
              font-weight: 400;
            }
          }
          .elementCategoryTitleLabel {
            margin-left: 10px;
            @include textStyle(left);
            font: normal normal normal 20px/39px Open Sans;
          }
        }
      }
    }
  }
  width: 90% !important;
}
