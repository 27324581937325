.chargePreviewModalLight {
  .ant-modal {
    width: fit-content !important;
  }

  .ant-modal-content {
    width: fit-content;
    border-radius: 10px;
    overflow: hidden;
  }

  .ant-modal-body {
    width: 800px;
    height: 300px;
    background-color: white;
    padding: 15px;
    display: flex;

    .agGridContainer {
      width: stretch;
      height: stretch;
      box-shadow: 0px 0px 6px 3px #71cf48;
      border-radius: 10px;
      overflow: hidden;
    }
  }

  .ant-modal-header {
    height: 45px;
    display: flex;
    align-items: center;
    border-bottom: 0;
    background-color: #f8f8fa;
  }

  .ant-modal-close {
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      fill: black;
    }
  }

  .ant-modal-footer {
    height: 45px;
    border-top: 0;
    background-color: #f8f8fa;
    display: flex;
    padding: 0 15px;
    gap: 15px;
    align-items: center;
    justify-content: flex-end;
  }
}

.chargePreviewModalDark {
  .ant-modal {
    width: fit-content !important;
  }

  .ant-modal-content {
    width: fit-content;
    border-radius: 10px;
    overflow: hidden;
  }

  .ant-modal-body {
    width: 800px;
    height: 300px;
    background-color: #1f212d;
    padding: 15px;
    display: flex;

    .agGridContainer {
      width: stretch;
      height: stretch;
      box-shadow: 0px 0px 6px 3px #71cf48;
      border-radius: 10px;
      overflow: hidden;
    }
  }

  .ant-modal-header {
    height: 45px;
    display: flex;
    align-items: center;
    border-bottom: 0;
    background-color: black;

    .ant-modal-title {
      color: white;
      font-size: 16px;
    }
  }

  .ant-modal-close {
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      fill: white;
    }
  }

  .ant-modal-footer {
    height: 45px;
    border-top: 0;
    background-color: black;
    display: flex;
    padding: 0 15px;
    gap: 15px;
    align-items: center;
    justify-content: flex-end;
  }
}
