.openBalanceReportContainer {
  justify-content: center;
  gap: 10px;
  width: 100%;
}

.openBalanceReportModal {
  top: 0 !important;
  padding: 0 !important;
  width: 100% !important;
  height: 100dvh !important;
  margin: 0;
  max-width: 100%;
  overflow: hidden;
  &::before {
    display: none !important;
  }
  .ant-modal-close-x {
    color: #fff !important;
    svg {
      color: #fff !important;
      fill: #fff !important;
      path {
        fill: #fff !important;
      }
    }
  }
  .ant-modal-content {
    border-radius: 0;
    width: 100%;
    height: 100dvh;
    background-color: #f4f5f6;

    .ant-modal-header {
      border-radius: 0 !important;
      background-color: #1f2a44;
      // padding: 20px;
      display: flex;
      align-items: center;
      height: 45px;

      .ant-modal-title {
        color: #fff;
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        width: 100%;
      }
      // close icon
      .ant-modal-close-x {
        color: #fff;
      }
    }
    .ant-modal-body {
      height: calc(100vh - 55px);
      width: 100%;
      padding: 10px 10px 0 10px !important;
      padding: 0;
    }
  }
}
