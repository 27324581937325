$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.multiLevelTreeLogModal {
  // height: fit-content !important;
  width: calc(100vw - 20px) !important;
  // max-height: calc(100vh - 20px) !important;
  max-width: 1200px;
  .ant-modal-content {
    // height: fit-content !important;
    width: calc(100vw - 20px) !important;
    // max-height: calc(100vh - 20px) !important;
    max-width: 1200px;
    .ant-modal-close {
      height: 45px;
      width: 45px;
      .ant-modal-close-x {
        height: 45px;
        width: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .ant-modal-header {
      height: 45px;
      display: flex;
      align-items: center;
      background-color: #f8f8fa;
    }
    .ant-modal-body {
      height: calc(100vh - 120px);
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
}

.multiLevelTreeLogModalDark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;
      }
    }
  }
}
