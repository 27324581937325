.deleteCategory-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 625px;
  height: 38px;
  .deleteCategory-question {
    display: flex;
    gap: 2px;
    p.deleteCategory-name {
      font-size: 14px;
      font-weight: 600;
    }
  }
}
