.mondayButtonContainer {
  height: 32px !important;

  .ant-spin {
    margin-right: 8px;

    .ant-spin-dot-item {
      background-color: #fff;
    }
  }

  .mondayButtonDivider {
    z-index: 2;
    height: stretch;
    width: 1px;
    background-color: #68c142;
    margin: 0 10px;
  }

  .mondayButtonText {
    flex: 1;
  }

  .mondayButtonStyle {
    height: 32px;
    background-color: #71cf48;
    border-radius: 5px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    //  @include text(14px, 400, 0, #fff);
    overflow: hidden;
    border: 0;
    padding: 0 10px;
    column-gap: 0px;
    &:hover,
    &:active,
    &:visited,
    &:focus {
      color: #fff !important;
      background-color: #68c142;
      border-color: #68c142;
      .mondayButtonDivider {
        background-color: #71cf48;
      }
    }

    &:not(:disabled):not(.ant-btn-disabled):hover {
      background-color: #68c142;
    }

    &:disabled {
      // color: rgba(0, 0, 0, 0.25) !important;
      color: #323338 !important;
      background-color: #f5f5f7 !important;
      svg {
        fill: #323338 !important;
        color: #323338 !important;
      }

      .mondayButtonDivider {
        background-color: transparent !important;
      }
    }
  }

  .mondayButtonStyleDark {
    .anticon svg {
      fill: #fff !important;
      color: #fff !important;
    }
    &:disabled {
      color: #fff !important;
      background-color: #363746 !important;
      .anticon svg {
        fill: #fff !important;
        color: #fff !important;
      }
      .mondayButtonDivider {
        background-color: #2a2b3a !important;
      }
      .mondayButtonIcon {
        fill: #acacca !important;
        svg {
          fill: #acacca !important;
        }
      }
    }
  }

  .mondayButtonIcon {
    fill: #fff;
    z-index: 2;
    margin: 0;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: unset;
  }
}

// @media (max-width: 480px) {
//   // this displays only icon for a button
//   .mondayButtonContainer {
//     .ant-btn-icon,
//     .mondayButtonText,
//     .mondayButtonDivider {
//       display: none;
//     }

//     //on hover button in mobile view user can see icon and text
//     &:hover {
//       .ant-btn-icon,
//       .mondayButtonText,
//       .mondayButtonDivider {
//         display: unset !important;
//       }
//     }
//   }
// }

// .todo-button {
//   .ant-btn {
//     svg {
//       // margin-top: 2px;
//       height: 20px;
//       width: 20px;
//     }
//   }
// }
