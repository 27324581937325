.progressDaysViewContent {
  display: flex;
  flex-direction: column;
  .progressDaysFilters {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    width: 100%;
    height: 45px;
    border-radius: 5px;
  }
}
