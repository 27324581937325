@import "../../../../../index.scss";
$yellowColor: #fff0c8;
$greenColor: #d5f1c9;
$redColor: #fde5e4;
$blueColor: #1264a3;
$greyColor: #f8f8fa;
.client-balance-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;

  .client-balance-status-panel {
    flex: 0 0 auto;
    .formCard {
      padding: 0;
    }
    .statusValue {
      @include text(32px, 600, 0, #fff);
    }
  }

  .client-balance-filters-bar {
    flex: 0 0 auto;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 0 0 10px 10px;
  }

  .cell-content {
    width: 100%;
    height: 50%;
    text-align: right;
    padding-right: 5px;
  }

  .client-balance-table {
    flex-grow: 1;
    padding: 0;
    margin-top: 0;
    overflow: auto;
    display: flex;
    flex-direction: column;
    border-radius: 0 0 10px 10px;

    .mainGrid {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      .ag-root-wrapper-body.ag-layout-normal {
        min-height: 500px !important;
      }
    }
    .invoice-amount {
      .ag-cell-wrapper {
        @extend .cell-content;
        background-color: $yellowColor;
      }
    }
    .balance-amount {
      .ag-cell-wrapper {
        @extend .cell-content;
        background-color: $redColor;
        display: flex;
        align-content: center;
      }
    }
    .payment-amount {
      .ag-cell-wrapper {
        @extend .cell-content;
        background-color: $greenColor;
      }
    }

    .cell-amount-dark {
      color: #000;
    }

    .link-cell {
      .ag-cell-wrapper {
        color: $blueColor;
        font-weight: 600;
        width: 100%;

        .invoice-link {
          color: $blueColor;
        }
      }
    }
    .ag-cell {
      border-right: none !important;
    }
  }
}
