.profile-configs {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .labeledInputContainer {
    .labeledInputLabel {
      font-size: 14px;
    }
    .ant-form-item {
      margin: 0;
    }
  }
  .noSignature {
    .signatureBox {
      display: none;
    }
  }
  .signatureView {
    padding-bottom: 10px;
  }

  &.dark-mode {
    .labeledInputContainer {
      .labeledInputLabel {
        color: #fff;
      }
      //select
      .ant-select-selector {
        background-color: #2a2b3a !important;
        .ant-select-selection-item {
          color: #acacca;
        }
      }

      //input
      .ant-input-affix-wrapper {
        background-color: #2a2b3a !important;
        color: #acacca;

        .ant-input {
          background-color: #2a2b3a !important;
        }
      }
    }
  }
}
