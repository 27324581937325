.pdf-project-overview_dark {
  width: auto !important;
  .ant-modal-header {
    width: 100%;
    height: 45px;
    background: #12131b;
    border-radius: 10px 10px 0px 0px;
    display: flex;
    align-items: center;
    color: #fff !important;
  }

  .ant-modal-title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #fff !important;
  }
  .ant-modal-close-x {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 21px;
    font-weight: bold;
    height: 45px;
    svg {
      fill: white !important;
    }
  }
  .ant-modal-content {
    max-width: 1380px;
    width: calc(100vw - 20px);
    max-height: 759px;
    background: #1f212d;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    .ant-modal-body {
      padding: 20px 0px;
      margin: 0px 20px;
      overflow-y: hidden;
      overflow-y: hidden;
      animation: pdfProjectPreview 0.5s ease-in-out;
      tr {
        color: white;
      }
      .pdf-project-modal {
        width: 1332px;
        color: white;
      }
    }
  }
  .header-preview-data {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    gap: 1rem;
    width: 1320px;
    height: 39px;
    background: #292b3a;
  }
  .ant-modal-footer {
    border: none;
    .ft-btns-sch-pdf {
      // margin-top: 35px;
      display: flex;
      justify-content: flex-end;
      // align-items: center;
    }
  }
  .pdf-project-modal-table-header {
    display: flex;
    justify-content: space-between;
    background: #292b3a;
    padding: 0px 10px 0px 10px;
  }
  .pdf-project-modal-table-header-item {
    display: flex;
    flex-direction: column;
    color: white;
  }
}
.pdf-project-overview {
  width: 1880px !important;
  .ant-modal-header {
    width: 100%;
    height: 45px;
    background: #f8f8fa;
    border-radius: 10px 10px 0px 0px;
    display: flex;
    align-items: center;
  }

  .ant-modal-title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #323338;
  }
  .ant-modal-close-x {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 21px;
    font-weight: bold;
    height: 45px;
    svg {
      fill: #323338;
    }
  }
  .ant-modal-content {
    max-height: 1200px;
    background: #ffffff;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    .ant-modal-body {
      overflow-y: hidden;
      overflow-y: hidden;
      animation: pdfProjectPreview 0.5s ease-in-out;
      .pdf-project-modal {
        width: 1332px;
      }
    }
  }
  .header-preview-data {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    gap: 1rem;
    width: 1320px;
    height: 39px;
    background: #ebeff1;
  }
  .ant-modal-footer {
    border: none;
    .ft-btns-sch-pdf {
      // margin-top: 35px;
      display: flex;
      justify-content: flex-end;
      padding: 0px 20px 20px 20px !important;
      // align-items: center;
    }
  }
  .pdf-project-modal-table-header {
    display: flex;
    justify-content: space-between;
    background: #ebeff1;
    padding: 0px 10px 0px 10px;
    width: calc(100vw - 90px);
  }
  .pdf-project-modal-table-header-item {
    display: flex;
    flex-direction: column;
  }
}
