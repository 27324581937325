.RentalBreakdownFooterDark {
  width: stretch;
  height: 45px;
  background-color: #1f212d;
  border-top: 1px solid #4a4a4a;
  z-index: 15;
  display: flex;
  align-items: center;
  justify-content: space-around;
  transition: background-color 0.3s ;

  .footerInformationContainer {
    display: flex;
    gap: 10px;
    .footerTextContainer {
      color: white;
    }
    .footerValueContainer {
      color: white;
    }
  }
}

.RentalBreakdownFooterLight {
  width: stretch;
  height: 45px;
  background-color: #f8f8fa;
  z-index: 15;
  display: flex;
  align-items: center;
  justify-content: space-around;
  transition: background-color 0.3s ;

  .footerInformationContainer {
    display: flex;
    gap: 10px;
    .footerTextContainer {
      color: black;
    }
    .footerValueContainer {
      color: black;
    }
  }
}
