.task-reminder {
  width: 100%;
  .messageReminderSet {
    background-color: #dff2bf;
    font-size: 13px;
    margin-top: 10px;
    padding: 5px;
    border-radius: 5px;
  }
  .reminder-input .ant-input {
    background-color: "#ffffff" !important;
  }
  .selectDays {
    .ant-select {
      padding: 0;
      width: 30%;
    }
  }

  .messageReminderError {
    background-color: #ffbaba;
    font-size: 13px;
    margin-top: 10px;
    padding: 5px;
    border-radius: 5px;
  }

  .reminderDays {
    max-height: 130px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 5px;
    }
  }
}
