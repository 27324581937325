@import "../../../../../../../../../index.scss";

$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.incidentModalContainer {
  padding: 0 !important;
  .ant-modal-content {
    border-radius: 0 !important;
    width: calc(100vw - 5px);
    height: calc(100vh);
    max-width: 100vw;
    max-height: 100vh;
    margin-left: -11px;

    .ant-modal-header {
      border-radius: 0 !important;
    }
    .ant-modal-body {
      width: 100% !important;
      height: calc(100vh - 50px) !important;
      .ant-form {
        width: 100%;
        height: 85%;
      }
    }
    .borderedTextCard {
      padding: 0;
      padding-top: 15px;
      border-radius: 0;
      border: none;
      border-top: 1px solid black;
      align-items: center;
    }

    .borderedTextCard.inputContainer {
      .cardTitle {
        left: 46%;
        color: black;
      }
    }

    .borderedTextCard.uploaderCard {
      .cardTitle {
        color: black;
      }
    }

    .borderedTextCard.picturesCard {
      .cardTitle {
        color: black;
      }
    }
    .inputContainer {
      @include flex(baseline, flex-start);
      flex-wrap: wrap;
      width: 80%;

      .labeledInputContainer {
        &:nth-child(1) {
          .ant-form-item {
            width: 300px;
          }
        }
      }
    }
    .container {
      @include flex(flex-start, flex-start, column);
      position: relative;
      max-height: calc(100vh - 150px);
      padding-top: 10px;
      width: 100%;
      height: 100%;
      margin-top: 20px;
      overflow-x: hidden;
      overflow-y: auto;
    }
    .labeledInputContainer {
      margin-right: 15px;

      .labeledInputLabel {
        @include text(14px, 600, 0.21px, #383554);
        margin-bottom: 2px;
      }
    }
    .ant-modal-body {
      height: fit-content;
      width: fit-content;
      padding: 20px;
      // padding-bottom: 62px;
    }

    .innerCards {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      row-gap: 1rem;
    }

    .incidentConditionals {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: row;
      flex-wrap: nowrap;
      width: 100% !important;
      min-width: 100% !important;

      .options {
        width: 50%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        flex-wrap: nowrap;
        min-height: 5rem !important;
      }
    }

    .didShowUp {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: row;
      width: 100%;
      flex-wrap: wrap;

      .title {
        font-size: 14px;
        margin-bottom: 0.5rem;
        font-weight: 600;
        letter-spacing: 0.21px;
        display: flex;
        justify-content: baseline;
        align-items: baseline;
        flex-direction: row;
        flex-wrap: nowrap;

        .titleReq {
          color: red;
        }

        .titleText {
          color: #383554;
        }
      }

      .innerRadioList {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        width: 100%;
        flex-direction: row;
        row-gap: 1rem;

        .labeledInputContainer {
          .ant-form-item {
            padding: 0;
            margin: 0;
          }
        }

        .peopleButtons {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-direction: row;
          flex-wrap: nowrap;
          // width: 100%;
          width: fit-content;
          margin-top: 1rem;
          margin-left: 1rem;

          .btn {
            font-size: 14px;
            cursor: pointer;
            height: fit-content;
            width: fit-content;
            padding-top: 0.3rem;
            padding-bottom: 0.3rem;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
            border-radius: 5px;
            color: #fff;
            transition: background-color 0.3s ease-in-out;
          }

          .add {
            background-color: #1264a3;
            &:hover {
              background-color: #0e5388;
            }
          }

          .removePerson {
            margin-left: 1.5rem;
            background-color: #fe4c4a;
            &:hover {
              background-color: #d4413f;
            }
          }
        }
      }
    }

    .borderedTextCard.injuredDetails {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: row;
      width: 48% !important;
      min-width: 48% !important;
      .cardTitle {
        color: black;
      }
      .childrenContainer {
        width: 100%;
      }
      .injuredMainDetails {
        .labeledInputContainer {
          &:nth-child(2) {
            width: 300px;
            // max-width: 50%;
            max-width: 100%;
          }
        }
      }
      .removeCardButton {
        position: absolute;
        top: 0.7rem;
        right: 1rem;
      }

      .injuredMainDetails {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        // width: 50%;
        width: 100%;
      }
    }

    .uploaderCard {
      padding-bottom: 20px;
      min-width: 0;
      width: 100%;
      &:first-child {
        width: 100%;
        margin-right: 15px;
      }
    }

    .picturesCard {
      padding-bottom: 20px;
      min-width: 0;
      width: 100%;
      &:first-child {
        width: 100%;
        margin-right: 15px;
      }
    }

    @mixin questionaryBooleanButton($background, $color: #fff) {
      @include text(16px, 500, 0.23px, $color, capitalize, $important: true);
      background: $background !important;
      border-radius: 4px !important;
      min-width: 90px;
      margin-right: 20px;
      border-color: $background !important;
      text-align: center;
    }

    .questionsContainer {
      @include flex(baseline, flex-start, row);
      flex-wrap: wrap;
      width: 100%;
      gap: 10%;
      padding-bottom: 10px;

      .ant-radio-button-wrapper {
        @include questionaryBooleanButton(#e6e6e6, #383554);
      }

      .ant-radio-button-wrapper:not(:first-child)::before {
        width: 0 !important;
      }

      .ant-radio-button-wrapper-checked {
        @include questionaryBooleanButton(#017aff);
      }

      .ant-radio-button-wrapper-checked:not(:first-child) {
        @include questionaryBooleanButton(#f93a3a);
      }
    }

    .incidentSignature {
      width: 100%;
      @include flex(center, center);
      margin-bottom: 20px;
    }

    .vehicleInspectionSignControllers {
      @include flex(center, center);
      width: 100%;
    }

    .uploaderContainer {
      width: 100%;
      @include flex(flex-start, flex-start);
    }
    .allFooterButtons {
      display: flex;
      justify-content: space-between;
      padding-top: 20px;
      .deleteAndLogs,
      .cancelAndSave {
        display: flex;
      }
      .logsButton,
      .saveButton,
      .previewButton {
        margin-left: 10px;
      }
      .previewButton {
        width: 40px;
        .mondayButtonDivider,
        .mondayButtonText {
          display: none;
        }
      }
      // @media (min-width: 800px) {
      // button {
      //   width: 150px;
      // }
      // }
    }
    .footerButtons .cancelAndSave {
      display: flex;
      justify-content: space-between;
      padding: 20px 0;
      // @media (min-width: 800px) {
      //   button {
      //     width: 150px;
      //   }
      // }
    }
  }
}

.incidentModalContainerDark {
  .stepper-section {
    .ant-steps {
      .ant-steps-item-finish ::before,
      .ant-steps-item-active::before,
      .ant-steps-item::before {
        border-left-color: $cardModalBodyDark;
      }
      .ant-steps-item-wait {
        background-color: $cardModalHeaderDark;
        .ant-steps-item-content .ant-steps-item-title {
          color: #acacca;
        }
      }
    }
  }
  .questionsContainer,
  .childrenContainer {
    .labeledInputLabel {
      color: #fff !important;
    }
    .ant-input {
      background-color: $inputModalDark !important;
      color: #fff;
      &::placeholder {
        color: #acacca;
      }
    }
    .ant-input-disabled {
      color: #acacca;
    }
    .ant-select {
      .ant-select-selector {
        background-color: $inputModalDark !important;
        .ant-select-selection-search-input {
          color: #fff;
        }
        .ant-select-selection-item {
          color: #fff;
        }
        .ant-select-selection-placeholder {
          color: #acacca;
        }
      }
      .ant-select-clear {
        background-color: $inputModalDark;
        svg {
          fill: #acacca;
        }
      }
      .ant-select-arrow svg {
        fill: #fff;
      }
    }
    .ant-select-disabled {
      .ant-select-selector {
        .ant-select-selection-search-input {
          color: #acacca !important;
        }
        .ant-select-selection-item {
          color: #acacca !important;
        }
      }
    }
    .react-tel-input {
      .form-control {
        background-color: $inputModalDark !important;
        color: #fff;
        &::placeholder {
          color: #acacca;
        }
      }
      .flag-dropdown {
        background-color: $inputModalDark;
        border-radius: 5px 0px 0px 5px;
        .selected-flag {
          &:hover {
            background-color: $inputModalDark;
          }
          &:focus {
            background-color: $inputModalDark;
          }
        }
        .arrow {
          border-top: 4px solid #fff;
        }
      }
      .open {
        background-color: $inputModalDark;
        border-radius: 5px 0px 0px 5px;
        .selected-flag {
          background-color: $inputModalDark;
          .arrow {
            border-bottom: 4px solid #fff;
            border-top: 4px solid transparent;
          }
        }
      }
    }
    .ant-picker {
      background-color: $inputModalDark !important;
      .ant-picker-input {
        background-color: $inputModalDark;
        input {
          color: #f2f2f2;
          &::placeholder {
            color: #acacca;
          }
        }
        .ant-picker-suffix svg {
          color: #acacca;
        }
        .ant-picker-clear {
          background-color: $inputModalDark;
          svg {
            fill: #acacca;
          }
        }
      }
    }
    .ant-radio-wrapper span {
      color: #fff;
    }
    .ant-btn {
      border: none;
    }
  }
  .incidentNotes {
    .labeledInputLabel {
      color: #fff !important;
    }
    .ant-input {
      background-color: $inputModalDark !important;
      color: #fff !important;
      &::placeholder {
        color: #acacca !important;
      }
    }
  }
  .incidentSignature {
    .signaturesContainer {
      .vehicleInspectionSignControllers {
        .ant-btn:disabled {
          color: #acacca;
          border: 1px solid #acacca;
        }
      }
    }
  }
}

@media (max-width: 1460px) {
  .incidentModalContainer {
    .ant-modal-content {
      .ant-modal-body {
        height: calc(100vh - 100px) !important;
        .allFooterButtons {
          flex-direction: column;
          gap: 10px;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}

@media (max-width: 975px) {
  .incidentModalContainer {
    .ant-modal-content {
      .ant-modal-body {
        .allFooterButtons {
          .deleteAndLogs {
            button {
              width: fit-content;
              .mondayButtonText,
              .mondayButtonDivider {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 820px) {
  .incidentModalContainer {
    .ant-modal-content {
      margin-left: unset !important;
    }
  }
}
@media (max-width: 767px) {
  .incidentModalContainer {
    .container {
      .uploaderContainer {
        flex-direction: column;
      }
    }
    .borderedTextCard.inputContainer {
      .cardTitle {
        left: 35% !important;
      }
    }
    .ant-modal-body {
      width: 100%;
    }

    .innerCards {
      row-gap: 1rem;
    }

    .injuredDetails {
      width: 100% !important;
    }

    .innerRadioList {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      flex-direction: column;
      gap: 1.5rem;

      .labeledInputContainer {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 1rem;
        .ant-form-item {
          padding: 0;
          margin: 0;
        }
      }
    }
  }
}

// @media (max-width: 571px) {
//   .incidentModalContainer {
//     .uploaderContainer {
//       display: ;
//     }
//   }
// }

@media (max-width: 575px) {
  .incidentModalContainer {
    .ant-modal-content {
      .ant-modal-body {
        .allFooterButtons {
          padding-top: 5px;
        }
      }
    }
  }
}
@media (max-width: 450px) {
  .incidentModalContainer {
    .ant-modal-body {
      padding: 10px;
      width: 100% !important;
      height: calc(100vh - 150px) !important;
      .container .childrenContainer .labeledInputContainer {
        min-width: 100%;
      }
    }
    .inputContainer {
      width: 100% !important;
    }
    .allFooterButtons {
      flex-direction: column !important;
      gap: 10px !important;
      align-items: center !important;
      // padding-bottom: 10px !important;
      .mondayButtonStyle {
        width: 50px;
        .mondayButtonText,
        .mondayButtonDivider {
          display: none;
        }
      }
    }
  }
}
