.vehicle-sheets-configuration {
  .ant-modal-content {
    .ant-modal-close {
      height: 45px;
      width: 45px;
      .ant-modal-close-x {
        height: 45px;
        width: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          fill: #333238;
        }
      }
    }

    .ant-modal-header {
      height: 45px;
      display: flex;
      align-items: center;
      background-color: #f5f5f7;
      .ant-modal-title {
        font-weight: 600;
        font-size: 16px;
        color: #333238;
      }
    }

    .ant-modal-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      flex-direction: row;
      overflow-x: auto;
      overflow-y: hidden;
      gap: 10px;
    }
  }
}

.vehicle-sheets-configuration.vehicle-sheets-config-dark {
  .ant-modal-content {
    background-color: #1f212d;
    color: #ffffff;

    .ant-modal-header {
      background-color: #12131b;
      .ant-modal-title {
        color: #ffffff;
      }
    }

    .ant-modal-close {
      .ant-modal-close-x {
        svg {
          fill: #ffffff;
        }
      }
    }
  }
}
