.communication-email-view-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    cursor: pointer;



    &.mobile {
        .communication-email-view-container-left {
            flex-direction: column;
            gap: 0px;

            .from-info-container {
                display: flex;
                gap: 5px;

                .from {
                    max-width: 150px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
        }

        .communication-email-view-container-right {
            .subject {
                max-width: 150px;
            }

            .snippet {
                max-width: 50px;
            }
        }

        .communication-view-container-left {
            gap: 5px;
        }

    }

    @media only screen and (max-width: 1440px) and (min-width: 768px) {
        .from {
            max-width: 200px;
        }

        .communication-email-view-container-left {
            gap: 10px;
        }

        .subject,
        .snippet {
            max-width: 100px;
        }
    }

    @media only screen and (min-width: 1440px) and (max-width: 1440px) {
        .from {
            max-width: 200px;
        }

        .subject {
            max-width: 200px;
        }

        .snippet {
            max-width: 200px;
        }
    }

    @media only screen and (min-width: 1441px) {
        .from {
            max-width: 400px;
        }

        .subject {
            max-width: 300px;
        }

        .snippet {
            max-width: 300px;
        }
    }

}


.communication-email-view-container-left {
    display: flex;
    gap: 50px;
}

.communication-email-view-container-right {
    display: flex;
}

.from,
.subject {
    font-weight: 600;
}


.from,
.snippet,
.subject {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.snippet {
    opacity: 0.8;
}