.mobile-table-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
  flex: 1;
  padding-right: 10px;
  .table-row-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: #f8f8fa;
    padding: 10px;
    border-radius: 5px;
    .content-container {
      display: flex;
      flex-direction: column;
      color: #323338;
      .header-text {
        font-size: 18px;
        font-weight: 600;
        // text-overflow: ellipsis;
        // overflow: hidden;
        // white-space: nowrap;
      }
      .normal-text {
        font-size: 16px;
        font-weight: 400;
      }
      .grey-text {
        font-size: 14px;
        font-weight: 400;
        color: #acacac;
      }
      .green-text {
        font-size: 14px;
        font-weight: 600;
        color: #00a854;
      }
    }
    .button-text {
      font-size: 16px;
      font-weight: 600;
      color: #1264a3;
    }
  }
}
