.sov-service-content-info-details-light-mode {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  justify-content: space-around;
  align-items: center;
  display: flex;
  height: 43px;
  .sovServicesContentInfoDetailsEvent {
    font: normal normal 600 16px/22px Open Sans;
    display: flex;
    width: 350px;
    margin-bottom: -3px;

    .amountContainer {
      margin-left: 5px;
      color: #71cf48;
    }
  }
  .sov-service-content-info-details-amount-error {
    display: inline-block;
    margin-left: 10px;
  }
  .sov-service-content-info-details-rate-error {
    display: inline-block;
    margin-left: 10px;
  }
}

.sov-service-content-info-details-dark-mode {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  justify-content: space-around;
  align-items: center;
  display: flex;
  height: 43px;
  background-color: #12131b;
  color: white;
  .sovServicesContentInfoDetailsEvent {
    font: normal normal 600 16px/22px Open Sans;
    display: flex;
    width: 350px;
    margin-bottom: -3px;

    .amountContainer {
      margin-left: 5px;
      color: #71cf48;
    }
  }
  .sov-service-content-info-details-amount-error {
    display: inline-block;
    margin-left: 10px;
  }
  .sov-service-content-info-details-rate-error {
    display: inline-block;
    margin-left: 10px;
  }
}
