$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.dynamicReportDesigner {
  top: 0 !important;
  padding: 0 !important;
  width: 100% !important;
  height: 100dvh !important;
  margin: 0;
  max-width: 100%;
  overflow: hidden;
  &::before {
    display: none !important;
  }
  .ant-modal-content {
    border-radius: 0;
    width: 100%;
    height: 100dvh;
    .ant-modal-header {
      border-radius: 0 !important;
      background-color: #f5f5f7;
      // padding: 20px;
      display: flex;
      align-items: center;
      height: 45px;

      .ant-modal-title {
        color: #323338;
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        width: 100%;

        .titleContainer .buttons .settingsIcon {
          height: 22px;
          width: 22px;

          cursor: pointer;
        }
      }
    }
    .ant-modal-close {
      height: 45px;
      aspect-ratio: 1;
    }

    .ant-modal-body {
      display: flex;
      flex-direction: column;
      gap: 10px;
      height: calc(100dvh - 45px);
      .reports-toolbar {
        height: 45px;
        width: 100%;
      }
      .reports-body {
        width: 100%;
        display: grid;
        gap: 10px;
        flex: 1;
        grid-template-columns: 60% 40%;
        // max-height: calc(100dvh - 120px);
        flex: 1;
        overflow-y: auto;
        #designer-host,
        #viewer-host {
          height: 100%;
          width: 100%;
          overflow: auto;
        }
      }
    }
  }
}

.dynamicReportDesignerDark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;

        .titleContainer .buttons .settingsIcon path {
          fill: #fff;
        }
      }
    }
  }
}
