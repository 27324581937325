@import "../../../../../index.scss";

$cardDarkHeaderColor: #2a2b3a;
$cardDarkBackgroundColor: #12131b;
$inputDarkColor: #2a2b3a;

.reportPreviewCardWrapper {
  width: 314px;
  height: 327px;
  @include flex(center, flex-start, column);

  .previewBtn {
    font-size: 14px;
    font-weight: 600;
    color: #1264a3;
    animation: fadeIn 1000ms both;

    &:hover {
      color: #0f5c97;
      text-decoration: underline;
    }
  }

  .reportPreviewCardContainer {
    // @include cardLayout();
    overflow: hidden;
    position: relative;
    cursor: pointer;
    width: 100%;
    height: 100%;
    transition: 0.2s ease-in;
    border-radius: 5px;

    &:hover {
      filter: brightness(0.9);
    }

    .previewContent {
      // border: 1px solid red;
      height: 100%;
    }

    .previewHeader {
      @include flex(center, space-between);
      @include text(14px, 600, 0, #323338);
      background-color: #f8f8fa;
      height: 39px;
      padding: 20px;
      width: 100%;
      transition: 0.2s ease-in;
      gap: 10px;
    }

    .reportTitle {
      @include flex(center, space-between);
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .reportName {
      @include textEllipsis();
      font-size: 14px;
      font-weight: 600;
      flex: 1;
    }

    .ant-tag {
      margin-right: 20px;
      border-radius: 5px;
    }

    .reportThumbnail {
      width: 100%;
      height: 172px;
      // background-color: #525659;
      .noThumbnail {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
      }
      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }

    .metadataIcon {
      margin-right: 10px;
      width: 20px;
      display: inline-block;
      align-items: center;
      svg {
        max-height: 20px;
      }
    }

    .reportMetadata-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      height: 116px;
      padding: 10px;
      background-color: #f8f8fa;
      .reportMetadata {
        transition: 0.2s ease-in;
        p {
          margin: 0;
        }
      }

      .reportMetadata-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 20px;
        .metadataLink {
          font-size: 14px;
          font-weight: 600;
          color: #1264a3;

          &:hover {
            color: #0f5c97;
            text-decoration: underline;
          }
        }
        p {
          margin: 0;
        }
        .metadataIcon {
          display: none;
        }
      }
    }

    .footerIcons {
      @include flex(center, center);
      width: 100%;
      justify-content: space-between;
      position: absolute;
      bottom: 0;
      right: 0;
      border-top-left-radius: 5px;
      overflow: hidden;

      .notesIcon {
        @include flex(center, center);
        width: 32px;
        height: 32px;
        background-color: #1f2a44;
        cursor: pointer;
        .icon {
          display: flex;
          fill: #ffffff;
          svg {
            width: 22px;
            height: 20px;
          }
        }
      }

      .deleteBtn {
        @include flex(center, center);
        width: 32px;
        height: 32px;
        background-color: #fe4c4a;
        cursor: pointer;
        svg {
          fill: #fff;
        }
      }
    }
  }
}

.reportPreviewCardWrapper-mobile {
  width: 100%;
  padding: 20px;
}

.reportPreviewCardWrapperDark {
  .reportPreviewCardContainer {
    background-color: $cardDarkBackgroundColor;
    box-shadow: 0px 1px 4px rgba(255, 255, 255, 0.4);
    .previewHeader {
      background-color: $cardDarkBackgroundColor;
      .starCheckbox {
        color: #fff;
      }
      .reportTitle {
        color: #fff;
      }
    }
    .previewContent {
      .reportThumbnail {
        background-color: $cardDarkHeaderColor;
      }
      .reportMetadata {
        background-color: $cardDarkBackgroundColor;
        color: #fff;
        span svg {
          fill: #fff;
        }
      }
      .reportMetadata-container {
        background-color: $cardDarkBackgroundColor;
      }
    }
    .footerSection {
      background-color: $cardDarkBackgroundColor;
    }
  }
}

@media (max-width: 395px) {
  .reportPreviewCardWrapper {
    width: calc(100vw - 60px);
    .reportPreviewCardContainer .reportThumbnail {
      width: calc(100vw - 60px);
    }
  }
}

@media only screen and (min-width: 1000px) and (max-width: 1024px) {
  .reportPreviewCardWrapper {
    width: 285px;
  }
}
