.notification-fields-container {
  width: 100%;
  height: 100%;
  border-radius: 10px;

  .CardContentWrapper-header {
    cursor: pointer;
  }

  .CardContentWrapper {
    .CardContentWrapper-header {
      display: flex;
      gap: 20px;
      align-items: center;
      .header-right {
        .tutorial-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          svg {
            width: 28px;
            height: 20px;
            path {
              fill: #1264a3;
            }
          }
        }
      }
    }

    .CardContentWrapper-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .notification-cards {
        .topicsContainer,
        .notification-actions {
          flex: 1 0 0;
          width: fit-content;
        }
      }
    }

    &.dark-mode {
      .CardContentWrapper-header {
        .toggle-switch-container {
          background-color: #12131b;

          .toggle-text {
            color: #fff;
          }
        }
      }

      .ant-divider-inner-text {
        color: #ffffff;
      }
    }
  }

  &.collapsed {
    height: auto;
  }
}

@media (max-width: 560px) {
  .notification-fields-container {
    .notification-cards {
      flex-direction: column;

      .topicsContainer {
        flex: 1 0 0;
        height: 60%;
        width: 100% !important;
      }
      .notification-actions {
        flex: 1 0 0;
        height: 40%;
        width: 100% !important;
      }
    }
  }
}
