$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.upload-records-step {
  margin-top: 10px;
  .ant-upload-wrapper .ant-upload {
    padding: 5px;
  }
}

.upload-records-step-dark {
  .ant-select {
    .ant-select-selector {
      background-color: $inputModalDark !important;
      .ant-select-selection-search-input {
        color: #fff !important;
      }
      .ant-select-selection-item {
        color: #fff;
      }
      .ant-select-selection-placeholder {
        color: #acacca;
      }
    }
    .ant-select-clear {
      background-color: $inputModalDark;
      svg {
        fill: #acacca;
      }
    }
    .ant-select-arrow svg {
      fill: #fff;
    }
  }
  .ant-upload-wrapper {
    .ant-upload {
      .ant-upload-drag-container {
        .ant-upload-drag-icon svg {
          fill: #fff;
        }
        .ant-upload-text {
          color: #fff;
        }
      }
    }
    .ant-upload-disabled {
      .ant-upload-drag-container {
        .ant-upload-drag-icon svg {
          fill: #acacca;
        }
        .ant-upload-text {
          color: #acacca;
        }
      }
    }
    .ant-upload-list-item-container {
      .ant-upload-list-item {
        .ant-upload-icon svg {
          fill: #fff;
        }
        .ant-upload-list-item-name {
          color: #fff;
        }
      }
    }
  }
}
