.viewSignature-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 10px;

  .signatureBox {
    width: 100%;
    height: 180px;
    position: relative;
    overflow: hidden;
    background-color: #fff;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 10px;

    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
      position: absolute;
    }
  }

  .signatureButtons {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
}
