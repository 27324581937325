.service-level-credit-wrapper {
	display: flex;
	margin-top: 20px;
	flex-direction: column;
	// background-color: red;
	// justify-content: space-between;
	// height: 100%;
	// width: 400px !important;
	width: 100%;
	height: 100%;
	justify-content: space-between;

	.service-level-header {
		display: flex;
		width: 100%;
		justify-content: center;
		font-size: large;
		font-weight: bold;
		margin-bottom: 20px;
	}
	.includeRentalsAgGridContainerDark {
		// width: stretch;
		height: stretch;

		.ag-center-cols-viewport {
			background-color: #1f212d !important;
		}
		.ag-cell-inline-editing .ag-cell-edit-wrapper,
		.ag-cell-inline-editing .ag-cell-editor,
		.ag-cell-inline-editing .ag-cell-editor .ag-wrapper,
		.ag-cell-inline-editing .ag-cell-editor input {
			background-color: #68c142 !important;
		}
	}
	.footer-amounts {
		margin-top: 20px;
		font-size: 23px;
		display: flex;
		justify-content: center;
		gap: 20px;
		.amount-el {
			display: flex;
			.label-amount {
				// font-weight: bold;
				margin-right: 10px;
			}
			.animated-val {
				color: #68c142;
			}
		}
	}
}

.editable-cell {
	background-color: #68c142 !important;
}
