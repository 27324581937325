.richTextComp {
  width: 100%;
  height: 100%;
  .quill {
    // height: calc(100% - 50px);
    background-color: #fff;
    overflow-y: hidden !important;
    resize: vertical !important;
    // min-height: 300px !important;
    // height: 100% !important;
    // border: 3px solid red !important;
    // color: #fff !important;
    border-bottom: 0.3px solid rgb(204, 204, 204) !important;
    .ql-editor {
      //   color: #fff !important;
      //   background-color: red !important;
      p {
        color: #000 !important;
      }
    }
    //   font:
  }

  // min-height: 700px !important;
}
.richTextComp_dark {
  width: 100%;
  height: 100%;
  .quill {
    height: calc(100% - 50px);
    //   color: #fff !important;
  }
  // min-height: 700px !important;
}
