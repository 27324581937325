.select-record-category-container {
    display: flex;
    align-items: center;
    justify-content: center;

    .labeledInputContainer {

        .ant-form-item {
            margin-bottom: 0px;
        }
    }
}