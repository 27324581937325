.project-match-card.project-match-schedule-card {
  .header-schedule-select {
    .ant-select {
      width: 100%;
    }
  }

  .project-match-body {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
    gap: 10px;
    padding: 10px;
    position: relative;

    .match-inner-schedule-info-container {
      width: 100%;
      border-radius: 5px;
      display: grid;
      grid-template-columns: calc(25% - 20px) calc(25% - 10px) calc(25% - 10px) 25%;
      grid-template-rows: auto;
      column-gap: 10px;
      box-sizing: border-box;
      white-space: nowrap;
      cursor: pointer;
      background-color: #f8f8fa;

      .schedule-info-data {
        display: grid;
        grid-template-rows: 50% 50%;
        grid-template-columns: 100%;
        place-items: center;
        padding: 10px;
        box-sizing: border-box;
      }
    }

    .single-day-selected-container {
      height: 100%;
      width: 100%;
      display: grid;
      grid-template-rows: 19px calc(100% - 29px);
      row-gap: 10px;

      .back-button {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
        flex-wrap: nowrap;
        overflow: hidden;
        gap: 10px;
        cursor: pointer;
      }

      .day-dispatches-list {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        overflow-y: auto;

        .single-day-dispatch-container {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          flex-direction: column;
          border-style: solid;
          background-color: #fff;
          border: 0;

          .single-dispatch-title {
            font-size: 16px;
            font-weight: 600;
            padding: 10px;
            border-bottom-width: 1px;
            border-bottom-style: solid;
            border-bottom-color: #dee2eb;
            width: 100%;
          }

          .route-even {
            background-color: #f8f8fa;
          }

          .single-dispatch-details-container {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            width: 100%;
            flex-direction: column;
            padding: 10px;
            border-bottom: 1px solid #dee2eb;
          }

          .separated-info-separator {
            height: 15px;
            width: 2px;
            background-color: #dee2eb;
          }

          .route-info {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-direction: row;
            flex-wrap: nowrap;
            overflow: auto;
            text-overflow: ellipsis;
            width: 100%;
            gap: 5px;

            .location-dot {
              height: 8px;
              aspect-ratio: 1;
              border-radius: 50%;
            }

            .location-dot.leave {
              background-color: #f04f4e;
            }

            .location-dot.enter {
              background-color: #00a464;
            }
          }

          .separated-info {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;
            flex-direction: row;
            flex-wrap: nowrap;
            width: 100%;
          }

          .cargo-Loaded {
            color: #00a464;
            font-weight: 600;
          }

          .cargo-Empty {
            color: #f04f4e;
            font-weight: 600;
          }
        }
      }
    }

    .no-schedule-days-info {
      height: 100%;
      width: 100%;
      display: grid;
      place-items: center;
      font-size: 16px;
      font-weight: 600;
    }
  }
}

.project-match-card.project-match-schedule-card.project-match-schedule-dark {
  .project-match-body {
    .match-inner-schedule-info-container {
      background-color: #2a2b3a;
    }

    .single-day-selected-container {
      .day-dispatches-list {
        .single-day-dispatch-container {
          background-color: #21212d;

          .single-dispatch-title {
            border-bottom: 1px solid #616481;
          }

          .single-dispatch-details-container {
            border-bottom: 1px solid #616481;
          }

          .route-even {
            background-color: #2b2b3a;
          }

          .separated-info-separator {
            background-color: #616481;
          }
        }
      }
    }
  }
}
