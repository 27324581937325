@import "../../../../../../../../../../../index.scss";

.ChooseFilesModal {
  height: fit-content;
  width: fit-content;
  background-color: white;
  border-radius: 12px;

  .ChooseFilesModalFooter {
    position: relative;
    bottom: 0px;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    width: 100%;
  }

  .ChooseFilesModalHeader {
    border-radius: 12px 12px 0 0;
    height: 45px;
    width: 100%;
    background-color: #f7f8fa;
    display: flex;
    justify-content: space-between;
    padding-bottom: 1.5rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 1.5rem;
    align-items: center;

    .ChooseFilesModalHeaderTitle {
      font-size: 16px;
      font-weight: 600;
      opacity: 1;
      text-transform: none;
      letter-spacing: 0.41px;
      color: #383554;
      background-color: #f8f8f8;
      margin-left: -11px;
    }
  }

  .ChooseFilesModalBody {
    padding: 8px;
    max-height: 600px;
    min-height: 100px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;

    .pathTitle {
      // padding-left: 20px;
      font-family: "Open Sans";
      font-size: 14px;
      font-weight: 400;
      opacity: 1;
      text-transform: none;
      letter-spacing: 0.41px;
      color: #383554;

      .pathItem {
        cursor: pointer;
      }
    }

    .ChooseFilesModalBodyFolder {
      display: flex;
      flex-direction: column;
      padding-top: 20px;

      .CategoryContainer {
        display: flex;
        flex-direction: column;

        .CategoryTitle {
          font-family: Helvetica;
          font-size: 18px;
          font-weight: bold;
          opacity: 1;
          text-transform: none;
          letter-spacing: 0.41px;
          color: #383554;
        }

        .CategoryItemsContainer {
          display: flex;
          justify-content: flex-start;
          gap: 20px;
          max-width: 1000px;
          flex-wrap: wrap;
          padding-top: 13px;

          .FolderItemContainer {
            cursor: pointer;
            height: fit-content;
            width: fit-content;
            padding: 1rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            border-radius: 5px;
            background-color: #f8f8fa;
            width: 180px;

            .CategoryIcon {
              max-height: 50px;
              max-width: 50px;
            }

            .ItemTitle {
              padding-top: 10px;
              display: flex;
              justify-content: flex-start;
              font-family: "Open Sans";
              font-size: 14px;
              font-weight: 400;
              opacity: 1;
              width: 100%;
              text-transform: none;
              letter-spacing: 0.41px;
              color: black;
              overflow: hidden !important;
              text-overflow: ellipsis;
            }
          }
        }

        .CategoryItemsContainerFiles {
          display: flex;
          justify-content: flex-start;
          gap: 10px;
          max-width: 1000px;
          flex-wrap: wrap;

          .FolderItemContainer {
            padding-top: 5px;
            padding-left: 5px;
            display: flex;
            flex-direction: row;
            cursor: pointer;
            width: 180px;
            height: 32px;
            background-color: #f8f8fa;
            border-radius: 5px;

            &.disabled {
              background-color: #f8f8fa86;
              cursor: not-allowed;

              .ItemTitle {
                color: rgba(112, 112, 112, 0.548)
              }
            }

            .ItemTitle {
              // position: relative;
              margin-left: 5px;
              display: inline-block;
              justify-content: flex-start;
              font-family: "Open Sans";
              font-size: 14px;
              font-weight: 400;
              opacity: 1;
              width: 100px;
              text-transform: none;
              letter-spacing: 0.41px;
              color: black;
              white-space: nowrap;
              overflow: hidden !important;
              text-overflow: ellipsis;
            }
          }

          .FolderItemContainer-selected {
            padding-top: 5px;
            padding-left: 5px;
            display: flex;
            border: 1px solid #1264a3;
            cursor: pointer;
            width: 180px;
            height: fit-content;
            background-color: #f1f5fd;
            border-radius: 5px;

            .ItemTitle {
              margin-left: 5px;
              display: inline-block;
              justify-content: flex-start;
              font-family: "Open Sans";
              font-size: 14px;
              font-weight: 400;
              opacity: 1;
              width: 100px;
              text-transform: none;
              letter-spacing: 0.41px;
              color: #1264a3;
              white-space: nowrap;
              overflow: hidden !important;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }

  .ChooseFilesModalFooter {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .expandable {
    display: flex;
    justify-content: space-between;
    width: 245px;
    transition: width 0.5s ease-in-out;
    gap: 8px;
  }

  .expanded {
    animation: expandAnimation 0.5s ease-in-out forwards;
  }

  .collapse {
    animation: collapseAnimation 0.5s ease-in-out forwards;
  }

  &.dark-mode {

    background-color: #2a2b3a;

    .ChooseFilesModalHeader,
    .ChooseFilesModalHeaderTitle {
      background-color: #2a2b3a;
      color: #fff;

      svg {
        color: #fff;
        fill: #fff;
      }
    }
  }
}

@keyframes expandAnimation {
  0% {
    width: 245px;
  }

  100% {
    width: 100%;
  }
}

@keyframes collapseAnimation {
  0% {
    width: 100%;
  }

  100% {
    width: 105px;
  }
}