@import "../../Communication.scss";

.communication-content-container {
  background-color: #fff;
  overflow-y: auto;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  color: $light-text-color;

  .refresh-button,
  .trash-button,
  .three-dots-icon {
    fill: $icon-color;
  }

  .loader {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .content {
      padding: 50px;
      border-radius: 4px;
    }
  }

  .content-actions {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    padding: 0px 24px;
    margin-bottom: 10px;

    .right-actions {
      display: flex;
      align-items: baseline;
      gap: 10px;
    }

    .refresh-button {
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background-color: transparent;
      }
    }
  }


  .communication-view-container:last-child {
    border-bottom: none;
  }

  .communication-view-container {
    display: flex;
    align-items: center;
    gap: 30px;
    padding: 8px 24px;
    width: 100%;
    background-color: #f5f5f7;
    border-bottom: 1px solid rgba(218, 217, 217, 0.459);

    &.mobile,
    &.tablet {
      padding: 0px 8px;
      gap: 10px;

      .communication-view-container-left {
        gap: 5px;
      }
    }


    .communication-view-container-left {
      display: flex;
      align-items: center;
      gap: 15px;

      .mark-important-btn {
        display: flex;
        align-items: center;
        justify-self: center;
      }
    }





    &.unread {
      background: #fff;
      font-weight: bold;
      color: #1e1e1e;
    }

    &:hover {
      background-color: #F1F5FD;
    }



    .communication-content-checkbox {
      background-color: #1264A3;
      padding: 2.5px 5px;

      .ant-checkbox-inner {
        border-radius: 0 !important;
        border-color: #3387C7;
        background-color: #0F5C97;
      }

      /* Apply background color on hover */
      &:hover .ant-checkbox-inner {
        border-color: #3387C7;
      }
    }

    .communication-content-checkbox:hover .ant-checkbox-input:checked+.ant-checkbox-inner {
      background-color: #0F5C97;
      border-color: #3387C7;
    }

  }

  .no-messages-matched,
  .no-messages-found {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding-bottom: 5px;

    .searchIcon {
      height: 15px;
      width: 15px;
    }
  }

  &.dark-mode {
    background: $cardDarkBackgroundColor;

    color: $dark-text-color;

    .loader {
      .ant-spin {
        background-color: $cardDarkBackgroundColor;

        .ant-spin-text {
          text-shadow: none;
          color: #48aae8;
        }
      }
    }

    .refresh-button,
    .trash-button,
    .three-dots-icon {
      svg {
        fill: $dark-text-color !important;
      }
    }

    .communication-view-container {
      background-color: $inputDarkColor;

      &.unread {
        background-color: #4c5569;
        font-weight: bold;
      }
    }

    .communication-view-container-left {

      .not-important-icon path {
        stroke: $dark-text-color;
      }
    }

    .no-messages-found {
      color: $dark-text-color;
    }
  }



}

.communication-content-layout {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;

  &.dark-mode {
    background: $cardDarkBackgroundColor;
  }

}