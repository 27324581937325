@import "../../../../../../../../../../../../index.scss";

.includeRentalsAgGridContainerDark {
	// width: stretch;
	height: stretch;

	.ag-center-cols-viewport {
		background-color: #1f212d !important;
	}
	.ag-cell-inline-editing .ag-cell-edit-wrapper,
	.ag-cell-inline-editing .ag-cell-editor,
	.ag-cell-inline-editing .ag-cell-editor .ag-wrapper,
	.ag-cell-inline-editing .ag-cell-editor input {
		background-color: #68c142 !important;
	}
}

.elevation-level-button {
	width: 100%;
	display: flex;
	justify-content: center;
	.detailsBtn {
		@include mondayButton(#1264a3, #0f5c97);
		svg {
			fill: black;
		}
	}
}

.elevation-level-footer-btns {
	.cancelButton {
		@include mondayButton(#fe4c4a, #eb4345);
		svg {
			fill: black;
		}
	}
}
