$inputModalDark: #12131b;

.EditInput {
  .ant-form-item {
    margin-bottom: 0px;
  }
  .EditInputComponent {
    width: 350px;
    height: 33px;
    background: #f5f5f7 0% 0% no-repeat padding-box;
    opacity: 1;
    border-radius: 5px;
    border: 0px;
  }
  .ant-input:focus,
  .ant-input-focused {
    box-shadow: none;
  }
  
  .EditInputComponentDark {
    .ant-input {
      background-color: $inputModalDark !important;
      color: #f2f2f2;
      &::placeholder {
        color: #acacca;
      }
    }
  }
}
