.invoice-footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;

  .footer-invoice-group {
    display: flex;
    align-items: center;
    gap: 20px;
  }
}

.invoice-footer-container-dark {
  @extend .invoice-footer-container;
  box-shadow: 0px 0px 4px 2px #71cf48 !important;
}
