.inspection-signatures-container {
  .card-body {
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    flex-direction: column;

    .ant-card-meta {
      width: 100%;
      .ant-card-meta-detail {
        gap: 5px;
        display: flex;
        flex-direction: column;

        .ant-card-meta-title {
          font-size: 12px;
          font-weight: 400;
        }

        .ant-image {
          width: 100%;
        }

        .signature-image {
          height: 100px;
          display: grid;
          place-items: center;
          width: 100%;
        }

        .signature-image {
          border: 1px solid #000;
        }
      }
    }
  }
}

.inspection-signatures-container.grid-card-dark {
  .card-body {
    .ant-card-meta {
      .ant-card-meta-detail {
        .ant-card-meta-title {
          color: #acacac;
        }

        .signature-image {
          border: 1px solid #fff;
        }
      }
    }
  }
}
