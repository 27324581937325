.pdf-card {
  width: 80%;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  margin: 10px;
  position: relative;
  cursor: pointer;
  transition: box-shadow 0.3s ease;
  .pdfCardCanvas {
    width: 100%;
    height: 250px;
    div canvas {
      width: 100% !important;
      height: fit-content !important;
    }

    // transform: scale(0.5);
    background-color: #f5f5f5;
    // position: relative;
  }
  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    .pdf-card__buttons {
      opacity: 1;
      visibility: visible;
    }
  }

  &__thumbnail {
    width: 100%;
    height: 80%;
    z-index: 100;
    top: 0;
    // background-color: #f5f5f5;
    position: absolute;

    .pdf-card__buttons {
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0;
      // visibility: hidden;
      transition: opacity 0.3s ease, visibility 0.3s ease;

      .pdf-card__button--up,
      .pdf-card__button--down {
        // background: #ffffff;
        // border: 1px solid #ddd;
        // padding: 5px 10px;
        padding: 5px;
        border-radius: 4px;
        cursor: pointer;
        border: none;
        // transition: background 0.3s ease;
        position: absolute;
        color: #000;

        &:hover {
          //   background: #f0f0f0;
          background: gainsboro;
        }
      }

      .pdf-card__button--up {
        bottom: 45px;
        right: 10px;
      }

      .pdf-card__button--down {
        bottom: 20px;
        right: 10px;
      }

      .pdf-card__button--delete,
      .pdf-card__button--replace {
        background: #ff4d4d;
        border: none;
        padding: 5px;
        border-radius: 50%;
        color: white;
        font-weight: bold;
        cursor: pointer;
        transition: background 0.3s ease;
        position: absolute;

        &:hover {
          background: #ff1a1a;
        }
      }

      .pdf-card__button--delete {
        top: 10px;
        right: 10px;
      }

      .pdf-card__button--replace {
        top: 10px;
        left: 10px;
        background: #1b9aaa;

        &:hover {
          background: #1b9aaa;
        }
      }
    }
  }

  &__filename {
    z-index: 10;
    padding: 10px;
    text-align: center;
    font-size: 14px;
    background-color: #fff;
    border-top: 1px solid #ddd;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: background-color 0.3s ease;
    position: absolute;
    bottom: 0;
    width: 100%;
    &:hover {
      overflow: visible;
      white-space: normal;
      background-color: rgba(255, 255, 255, 0.9);
    }
  }
}
