@import "../../../../../index.scss";

.statusSquircleContainer {
  @include flex(center, center, column);
  // padding: 13px 10px;
  min-width: 118px;
  border-radius: 10px;
  min-height: 104px;
  height: 100%;
  min-width: 118px;
  width: 100%;
  border-radius: 5px;
  flex-grow: 1;
  flex-basis: 0px;

  .statusValue {
    @include text(56px, 600, 0, #fff);
    line-height: 1;
  }
  .statusName {
    @include text(17px, 500, 0, #fff);
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: normal;
  }
}
.statusCardOverlay {
  .ant-tooltip-content {
    border-radius: 5px;
    overflow: hidden;
  }
}
