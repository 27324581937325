.progress-changes-modal {
  //   width: unset !important;
  //   max-width: 90vw;
  width: 370px !important;
  //   @media screen and (min-width: 1600px) {
  //     width: 665px !important;
  //   }

  .ant-modal-content {
    .ant-modal-header {
      height: 45px;
      display: flex;
      align-items: center;
      background-color: #f8f8fa;
      overflow: hidden;
      border: none;
      color: #323338;
    }
    .ant-modal-body {
      padding: 10px 0 !important;
      max-height: 85dvh;
      overflow-y: auto;
      .progress-input {
        min-width: unset !important;

        .labeledInputLabel {
          font-size: 14px;
          color: #323338;
        }

        .ant-input {
          width: 100px;
          height: 32px;
          text-align: center;
        }
      }
      .progress-inputerror {
        min-width: unset !important;

        .labeledInputLabel {
          font-size: 14px;
          color: #323338;
        }

        .ant-input {
          width: 100px;
          height: 32px;
          text-align: center;
          border: 1px red !important;
          box-shadow: inset 0 0 0 1px red !important;
          animation: overinstalled 0.5s ease-in-out;
        }
      }
      .progress-body-dimensions {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 0 20px;
        .progress-header {
          display: flex;
          width: 100%;
          align-items: center;
          .progress-details-label {
            gap: 5px;
            display: flex;
            align-items: center;
            color: #323338;
            .title {
              font-weight: 600;
            }
          }
          .progress-details-label:not(:last-child) {
            border-right: 2px solid #dee2eb;
            height: 15px;
            padding-right: 10px;
          }
          .progress-details-label:not(:first-child) {
            padding-left: 10px;
          }
        }
        .dimensions-inputs {
          display: flex;
          align-items: end;
          gap: 10px;
          flex-wrap: wrap;
          .auto-complete-icon {
            margin-bottom: 5px;
            cursor: pointer;
          }

          span {
            display: flex;
            gap: 5px;
            align-items: center;
            color: red;
            animation: errorSentence 0.8s 2;
          }
        }
      }
      .progress-body-dimensions.table {
        padding: 0;
        .breakdown-table {
          .breakdown-header {
            display: flex;
            padding: 10px 0;
            align-items: center;
            width: 100%;
            // padding: 0 20px 0 0;
            border-bottom: 1px solid #bbbfc7;
            div {
              font-size: 14px;
              font-weight: 600;
              color: #323338;
              width: 33%;
              padding-left: 20px;
              &:not(:last-child) {
                border-right: 2px solid #dddfe3;
                display: flex;
                align-items: center;
                height: auto;
                // height: 15px;
              }
            }
          }
          .breakdown-header.breakdown-item {
            padding-right: 10px;
            border-bottom: unset;
            div {
              font-weight: 400;
              &:not(:last-child) {
                border: unset;
              }
            }
          }
          .breakdown-el {
            border-bottom: 1px solid #dee2eb;
            display: flex;
            flex-direction: column;
            gap: 5px;
            padding-top: 5px;
          }
        }
      }
      .docUploader
        .uploadedFilesSection
        .uploadedFilesMapping
        .uploadedFilesCard
        .uploadedFile
        .fileName {
        max-width: 200px;
      }
    }
    .ant-modal-footer {
      padding-bottom: 20px !important;
      height: 74px;
      .progress-changes-footer {
        display: flex;
        height: 100%;
        justify-content: space-between;
        .foreman-button {
          width: 150px;
          height: 44px;
        }
      }
    }
  }
  @media screen and (max-width: 369px) {
    width: unset !important;
    .ant-modal-content {
      .ant-modal-footer {
        .progress-changes-footer {
          .foreman-button {
            width: 100px;
          }
        }
      }
    }
  }
}

@keyframes overinstalled {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(5px);
  }
  50% {
    transform: translateX(-5px);
  }
  75% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes errorSentence {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
}
