$cardDarkHeaderColor: #2a2b3a;
$cardDarkBackgroundColor: #12131b;
$inputDarkColor: #2a2b3a;

.task-dates-section {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 25px 0;
  width: 100%;
  .durationSpan {
    font-weight: 600;
    padding-left: 5px;
    // padding-bottom: 10px;
    font-size: 16px;
  }

  .durationContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding: 4px 10px 4px 10px;
    background: #f8f8fa;
    border-radius: 10px;

    .durationButton {
      .mondayButtonContainer .mondayButtonDivider {
        background-color: #1264a3;
      }
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      // width: 32px;
      height: 32px;
      background: #1264a3;
      cursor: pointer;
    }
    .task-date-input {
      .ant-form-item {
        margin: 0;
        .ant-input {
          background-color: #e2e9f8 !important;
          border-radius: 5px;
        }
        .ant-picker {
          background-color: #e2e9f8 !important;
          .ant-picker-input {
            .ant-picker-suffix svg {
              color: #acacca !important;
            }
          }
        }
      }
    }
    .durationStartPicker {
      min-width: 0px;
      .ant-picker {
        margin-left: 0px;
        padding-left: 5px;
        padding-right: 0px;
        background-color: #e2e9f8 !important;
      }
      .ant-form-item {
        .ant-picker-suffix {
          display: none;
        }
        .ant-input {
          border: 0 !important;
          border-radius: 5px !important;
          background-color: #e2e9f8 !important;
        }
        padding-bottom: 0px;
        margin-bottom: 0px;
        max-width: 80px;
      }
    }

    .durationStart {
      display: flex;
      align-items: center;
      gap: 5px;
    }

    .durationEnd {
      display: flex;
      align-items: center;
      width: 100%;
      gap: 5px;
      #redTime {
        path {
          fill: #fe4c4a;
        }
      }
      .durationEnd-container {
        display: flex;
        align-items: center;
        width: 210px;
        gap: 5px;
      }
      .durationEnd-container-mobile {
        width: 155px;
      }

      .duration-end-input {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
      }

      .duration-end-input-mobile {
        flex-direction: column;
        gap: 5px;
      }
      .labeledInputContainer {
        .ant-form-item {
          margin: 0px;
        }
      }
    }
  }
}

//this should be outside because its a popover
.deadlinePopover {
  .ant-popover-content {
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
  }
  .ant-popover-inner-content {
    padding: 0px !important;
    border-radius: 10px;
  }
  .ant-popover-title {
    height: 40px;
    display: flex;
    align-items: center;
    background: #f8f7fa;
    border-radius: 10px 10px 0px 0px;
    border-bottom: none;
    padding: 0px 10px !important;
  }
  .calendar-container,
  .timeDatePicker {
    // flex-wrap: wrap;
    height: 100%;
    box-shadow: none;
    background: white;
    border-radius: 10px;

    .selectTimeText {
      padding-top: 20px;
      letter-spacing: 0;
      padding-bottom: 20px;
      padding-left: 0px;
      p {
        padding-top: 0px !important;
      }
    }
    .weekContainer {
      margin-bottom: 0px;
    }

    .weekContainer2::after {
      border: 0px dashed #707070;
      margin: 0px;
    }
    .weekContainer2 {
      .day {
        margin-bottom: 20px;
        font-size: 14px;
      }
    }

    .timeButtons {
      .timeButtonsActive {
        background: #e2e9f8;
      }
      .hour {
        font-size: 40px !important;
      }
      .minute {
        font-size: 40px !important;
      }
    }
    .timeButtons > input {
      border: 0px solid;
      font: Open Sans 600 40px;
      font-size: 40px !important;
    }

    .clock {
      background-color: #ffffff;
    }
  }
}

.deadlinePopoverDark {
  .ant-popover-arrow::before {
    background-color: $cardDarkHeaderColor;
  }
  .ant-popover-content {
    .ant-popover-inner {
      background-color: $cardDarkBackgroundColor;
      .ant-popover-title {
        background-color: $cardDarkHeaderColor;
        color: #fff;
      }
      .ant-popover-inner-content {
        .calendar-container {
          background-color: $cardDarkBackgroundColor;
          .datePickerHeader {
            .currentMonth {
              color: #fff;
            }
          }
          .weekContainer {
            .day {
              color: #fff;
            }
          }
          .weekContainer2 {
            .day {
              color: #fff;
              &:hover {
                background-color: $inputDarkColor;
              }
            }
          }
        }
      }
    }
  }
}
