@import "../../../../index.scss";

.digitalClockContainer {
  @include flex(flex-end, flex-end);
  @include text(25px, bold, 4.1px, #4791ff, uppercase);
  font-family: Microsoft Sans Serif;
  user-select: none;
  margin-bottom: 30px;
  margin-left: 10px;
  height: fit-content;
}

.timeContainer {
  @include flex(center, space-between);
  @include text(48px, 500, 0, none);
  width: 185px;
}

.time {
  @include flex(center, center);
  @include square(80px, 100px, 13px, #1f2a44);
  box-shadow: 0px 3px 6px #00000029;
}

.period {
  padding-left: 5px;
  margin-bottom: -7px;
}

@media (min-width: 1700px) {
  .digitalClockContainer {
    font-size: 30px;
  }

  .timeContainer {
    font-size: 65px;
    width: 265px;
  }

  .time {
    width: 120px;
    height: 150px;
  }

  .period {
    padding-left: 5px;
    margin-bottom: -7px;
  }
}

@media (max-width: 1400px) {
  .digitalClockContainer {
    margin-right: -14%;
  }
}

@media (max-width: 1025) {
  .digitalClockContainer {
    margin-right: -10%;
  }
}