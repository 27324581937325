@import "../../../../../index.scss";
.UploadComponent {
  // .uploadButtonContainer {
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   margin-bottom: 10px;
  //   .labeledInputContainer {
  //     min-width: none;
  //     .ant-btn {
  //       border: none;
  //     }
  //   }
  // }
  .uploadButtonContainer {
    @include flex(center, space-evenly, row);
    width: 100%;
    height: 55px;
    margin-bottom: 10px;
    border: 1px dashed #323338;
    border-radius: 2px;
    .uploaderIcon {
      color: #0f5c97;
    }
    .uploaderTitle {
      @include text(14px, 400, 0, #0f5c97);
    }
  }
  .documentWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    width: 500px;
    padding: 20px 0;
    .documentContainer .documentTitle {
      width: 100%;
    }
  }
  .confirmContainer {
    display: flex;
    margin-top: 20px;
    justify-content: center;
    align-items: center;
    gap: 15px;
    .cancelButton {
      background-color: #fe4c4a;
    }
    .cancelButtonDivider {
      color: #f14543;
    }
    .cancelButton:hover {
      background-color: #f14543;
      .cancelButtonDivider {
        color: #fe4c4a;
      }
    }
    .confirmButton {
      background-color: #71cf48;
    }
    .confirmButtonDivider {
      color: #69c242;
    }
    .confirmButton:hover {
      background-color: #69c242;
      .confirmButtonDivider {
        color: #71cf48;
      }
    }
  }
}
