@import "../../../../../index.scss";

.favoriteReportLabel {
  @include flex(center, flex-start);
  width: 100%;
  // margin: 0 -20px;
  padding: 5px 20px;
  cursor: pointer;
  gap: 10px;

  &:hover {
    background-color: #f8f8fa;
  }

  .reportIcon {
    path {
      fill: black;
    }
  }

  .verticalDivider {
    height: 20px;
    width: 2px;
    background-color: #dee1e5;
    margin: 0 50px;
  }

  .favoriteCategory {
    margin: 0 24px;
  }

  .reportName {
    @include textEllipsis();
    width: 228px;
    min-width: 80px;
  }

  .categoryIcon {
    width: 20px;
  }

  .categoryLabel {
    @include flex(center, center);
    gap: 10px;
  }

  .categoryName {
    @include textEllipsis();
    width: 104px;
  }

  .departmentTag {
    background-color: #797f8f;
    color: #fff;
    border-radius: 5px;
  }
}

.favoriteReportLabelDark {
  color: #fff;
  &:hover {
    background-color: #222a44;
  }
  .categoryLabel {
    svg {
      fill: #fff;
      path {
        fill: #fff;
      }
    }
  }
}
