@import "../../../../assets/styles/breakpoints.scss";

$mainDarkBackgroundColor: #20212d;
$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.wrapper-bill-modal-container {
  overflow: hidden;

  .ant-modal {
    max-height: 100%;
    max-width: 100%;
  }
}

.bill-modal-container {
  top: 0 !important;
  width: 100% !important;
  height: 100% !important;
  position: initial;
  margin: 0;
  padding: 0;

  .new-bill-ag-grid-table {
    height: 100%;
    width: 100%;

    .ag-layout-normal {
      height: 100% !important;
    }
  }
  .ant-modal-header {
    padding: 0 !important;
    display: flex;

    .ant-modal-title {
      justify-content: space-between;
      padding: 0px 16px;
      font-family: Helvetica;
      font-size: 22px;
      font-weight: bold;
      opacity: 1;
      text-transform: none;
      letter-spacing: 0.41px;
      height: 50px;
      width: 100%;
      display: flex;
      align-items: center;
      background: #1f2a44;
      color: white;

      svg {
        fill: white;
        height: 50px;
        cursor: pointer;
        z-index: 1;
      }
      .bill-modal-title {
        position: absolute;
        width: 100%;
        text-align: center;
      }
      .accountingWriteModeContainerLight {
        z-index: 1;
      }
    }
  }
  .ant-modal-content {
    width: 100%;
    height: 100dvh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #f4f5f6;

    .new-bill-form-container {
      display: flex;
      gap: 10px;
    }

    .new-bill-form-card {
      display: grid;
      grid-template-columns: repeat(6, minmax(0, 200px));
      gap: 15px;
      justify-content: center;

      .ant-picker {
        width: 100% !important;
      }
      .labeledInputContainer:last-child {
        grid-column: 6 / span 2;
        grid-row: 1 / span 2;

        // textarea {
        //   height: 130px !important;
        //   resize: none;
        // }
      }
    }

    .new-bill-form-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .new-bill-table-card-container {
      height: 100%;
      overflow: auto !important;

      .new-bill-table-card {
        height: 100%;
        overflow: auto !important;
      }
    }
  }
  .ant-modal-body {
    height: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    background-color: #f4f5f6;
    overflow: hidden;
    gap: 15px;
    padding: 15px 20px !important;

    .formCard {
      padding: 0 !important;
      margin: 0 !important;
      align-items: flex-start !important;
      justify-content: flex-start !important;

      // &.dark-mode {
      //   * {
      //     color: #000;
      //   }
      // }
    }

    .ant-input-number-group-wrapper-outlined .ant-input-number-group-addon {
      background: #f4f5f6 !important;
    }
    .ant-input-number-wrapper {
      .antd-input-number-group-addon {
        padding: 0 8px !important;
      }
      .ant-input-number {
        width: 100%;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;

        .ant-input-number-input {
          padding-left: 4px !important;
        }
      }
    }
  }
  .ant-modal-footer {
    padding: 0 !important;
    box-shadow: 0px 0px 20px -8px;
    border-radius: 10px;
    //background-color: white;
    margin: 0px 20px 15px 20px;
  }
}

.bill-modal-container-dark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-header .ant-modal-title {
      background-color: #101c35 !important;
      .bill-modal-header {
        background-color: #101c35;
      }
    }
    .ant-modal-body {
      background-color: $mainDarkBackgroundColor;
      .bill-up-container .bill-form-wrapper {
        background-color: $cardModalHeaderDark;
        box-shadow: 0px 0px 4px 2px rgba($color: #ffffff, $alpha: 0.16);
      }
    }
  }
}

.darkDropDown {
  .ant-space {
    .ant-input {
      background-color: $cardModalHeaderDark;
      color: #fff;
      &::placeholder {
        color: #acacca;
      }
    }
    .ant-space-item {
      background-color: $cardModalHeaderDark;
      span {
        color: #fff;
      }
    }
  }
}
