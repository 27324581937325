.reports-category-modal-table {
  cursor: pointer;
}

.category-selected {
  background-color: #fafafa;
}

.root-report-modal-mobile {
  .report-modal-mobile {
    top: 0 !important;
    max-width: 100vw !important;
    margin: 0px !important;
    padding: 0;
    height: 100vh !important;

    .ant-modal-content {
      .ant-modal-body {
        height: calc(100vh - 140px);
        overflow: auto;
      }
      .ant-modal-footer {
        .mondayButtonContainer {
          button {
            .mondayButtonText {
              display: unset !important;
            }
          }
        }
      }
    }
  }

  .ant-modal-wrap {
    overflow: hidden;
  }

  .ant-modal-content {
    height: 100vh;
  }
}

.report-modal {
  flex-grow: 1;

  .searchbar {
    width: 100%;
    max-width: 650px;
    position: absolute;
    // transform: translate(300px, -48px);
    bottom: 20%;
    left: 3%;
  }

  .reports-category-modal-wrapper {
    width: 100%;
    display: flex;
    margin: 40px 0px;
    .reports-category-modal-innerwrapper {
      height: 100%;
      overflow: hidden;
      width: 100%;
    }
  }

  .reports-category-modal-wrapper-mobile {
    display: block;
    margin: 0px;

    .reports-category-modal-innerwrapper-mobile {
      height: unset;
      overflow: unset;
      width: 100%;

      .ant-table-wrapper {
        .ant-spin-nested-loading {
          .ant-spin-container {
            .ant-table {
              .ant-table-container {
                height: 360px !important;
              }
            }
          }
        }
      }
    }
  }

  .ant-modal-root {
    background-color: red;

    .ant-modal-wrap {
      background-color: red;
      .ant-modal {
        max-width: 100vw !important;
        margin: 0px !important;
      }
    }
  }

  .ant-modal-body {
    // background-color: red;
    display: flex;

    height: 100%;
    flex-grow: 1;

    .ant-table-wrapper {
      height: 100%;
      overflow: hidden;

      .ant-spin-nested-loading {
        height: 100%;

        .ant-spin-container {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;

          .ant-table {
            height: 100%;
            max-height: 90%;
            overflow: hidden;

            .ant-table-footer {
              padding: 0;
              background-color: #fafafa;
              display: flex;
              align-items: center;
              justify-content: center;

              div {
                padding: 8px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                max-width: 300px;
                width: 100%;
              }
            }
            .ant-table-container {
              height: 100%;
              max-height: 355px;
              .ant-table-body {
                height: 100%;
                max-height: unset;
              }
            }
          }
        }
      }
    }
  }
}

.sum-category {
  display: flex;
  align-items: center;
  gap: 5px;

  svg {
    margin-right: 11px;
    path {
      fill: black;
    }
  }
}

.sum-count {
  display: flex;
  align-items: center;
  justify-content: center;
}
