@import "/src/index.scss";

.create-team-modal {
  height: calc(100vh - 20px) !important;
  width: calc(100vw - 20px) !important;
  max-height: 687px;
  max-width: 782px;
  .ant-modal-content {
    height: calc(100vh - 20px) !important;
    width: calc(100vw - 20px) !important;
    max-height: 687px;
    max-width: 782px;
    .ant-modal-close {
      height: 45px;
      width: 45px;
      .ant-modal-close-x {
        height: 45px;
        width: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .ant-modal-header {
      height: 45px;
      display: flex;
      align-items: center;
      background-color: #f8f8fa;
    }
    .ant-modal-body {
      height: calc(100% - 100px);
      display: flex;
      flex-direction: column;
      gap: 20px;
      .info-modal-section {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 10px;
        user-select: none;
        p {
          margin: 0px;
        }
        .bold-text {
          font-weight: 600;
          user-select: all;
        }
      }
      .ant-form {
        width: 100%;
        .labeledInputContainer {
          max-width: 300px;
          .ant-form-item {
            margin-bottom: 0px;
          }
        }
      }
      .members-selection {
        width: 100%;
        height: 74.8%;
        .labeledInputContainer {
          width: 100%;
          max-width: 300px;
          // .ant-input-affix-wrapper {
          //   background-color: #fff !important;
          //   .ant-input {
          //     background-color: #fff !important;
          //   }
          // }
        }
        .source,
        .transferredKeys {
          row-gap: 10px;
          .itemCounter {
            user-select: none;
            font-size: 14px;
          }
          .itemsContainer,
          .transferredItemContainer {
            padding: 10px;
            height: calc(100% - 32px);
            width: 100%;
            background-color: #f8f8fa;
            border-radius: 5px;
          }

          .listContainer {
            height: calc(100% - 52px);
            row-gap: 5px;

            .transferLabelContainer {
              width: 100%;
              max-width: 300px;
              background-color: #fff;
            }
          }
        }
      }
    }
    .ant-modal-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .mondayButtonStyle {
        width: 150px;
      }
    }
  }
}

.create-team-modal-dark {
  .ant-modal-content {
    background-color: $mainDarkBackgroundColor;
    .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardDarkBackgroundColor;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      .info-modal-section {
        color: #fff;
      }
    }
  }
}
