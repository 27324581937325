.days-in-scheduleMaster-cell {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
}

.tooltip-overlay-days-in-scheduleMaster-cell {
  .ant-tooltip-inner {
    width: 315px !important;
  }
}
