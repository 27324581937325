@import "../../../../../../../../../../../index.scss";

.vehicleInspectionSignControllers {
  @include flex(flex-start, flex-start);
  flex-wrap: wrap;
  .ant-btn {
    margin-right: 15px;
    border-radius: 5px;
  }
}
