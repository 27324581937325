$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.addDepartmentModal {
  .ant-modal-content {
    .ant-modal-close {
      height: 45px;
      width: 45px;
      .ant-modal-close-x {
        height: 45px;
        width: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .ant-modal-header {
      height: 45px;
      display: flex;
      align-items: center;
      background-color: #f8f8fa;
      .ant-modal-title {
        font-size: 16px;
        font-weight: 600;
        color: #333238;
      }
    }
    .ant-modal-body {
      .ant-form-item-label label {
        font-size: 16px;
        font-weight: 600;
        color: #333238;
      }
      .labeledInputContainer .ant-form-item {
        margin-bottom: 0px;
      }
    }
    .ant-modal-footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 10px;
    }
  }
}

.addDepartmentModalDark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      .ant-form-item-label label {
        color: #fff;
      }
      .ant-input {
        background-color: $inputModalDark !important;
        color: #fff;
        &::placeholder {
            color: #acacca;
        }
      }
      button svg {
        fill: #fff;
      }
    }
  }
}
