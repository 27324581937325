@use "/src/index.scss" as index;

.jobsite-info-window {
  min-width: 300px;
  border-radius: 5px;
  box-shadow: 0px 0px 4px 0px #231f20;
  overflow: hidden;
  font-size: 14px;
  color: #231f20;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif !important;
  .info-header {
    height: 34px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f4f5f6;
    padding: 0px 10px;
    font-weight: 600;
    font-size: 16px;
    .info-title {
      color: #231f20;
    }
    svg {
      fill: #231f20;
      cursor: pointer;
    }
  }
  .info-body {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 10px;
    font-weight: 400;

    .location-information {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 10px;
      svg {
        cursor: pointer;
      }
    }
    .info-data {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      label {
        font-weight: 600;
      }
    }
  }
}

.jobsite-info-window-dark {
  background-color: index.$mainDarkBackgroundColor;
  box-shadow: 0px 0px 4px 0px #f4f5f6;
  .info-header {
    background-color: index.$cardDarkBackgroundColor;
    color: #fff;
    svg {
      fill: #fff !important;
    }
  }
  .info-body {
    color: #fff;
  }
}
