.scheduleDays {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .info {
    width: 25px;
    height: 25px;
    background: transparent;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    margin-left: 5px;
    svg {
      width: 100%;
      height: 100%;
    }
    // width: 25px;
    // height: 25px;
    // background: #1065a4;
    // color: white;
    // font-style: italic;
    // border-radius: 50%;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // cursor: pointer;
  }
  .ag-root-wrapper-body {
    overflow-y: auto;
  }
  .info:hover {
    opacity: 0.8;
    transform: scale(1.1);
  }
}

// .daysInScheduleModal {
//   .ant-modal-content {
//     max-width: calc(100vw - 20px);
//   }
//   .ant-modal-body {
//     overflow-x: auto;
//     overflow-y: hidden;
//   }
// }
// .daysInSchedule {
//   .ag-react-container {
//     width: 100%;
//     height: 100%;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//   }
//   .ag-root-wrapper-body {
//     overflow-y: auto;
//     overflow-x: hidden;
//   }
// }

.crews_detailed_info {
  .ant-popover-content {
    border-radius: 5px;
  }
  .ant-popover-inner {
    border-radius: 5px !important;
    background: #ffffff 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 1px 4px #00000029 !important;
    .ant-popover-title {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 22px;
      background: #1f2a44 0% 0% no-repeat padding-box !important;
      border-top-left-radius: 5px !important;
      border-top-right-radius: 5px !important;
      letter-spacing: 0px !important;
      color: #ffffff !important;
      opacity: 1 !important;
    }
    .ant-popover-inner-content {
      padding: 5px !important;
      max-width: auto !important;
      margin-left: 5px !important;
      ul {
        list-style: none;
        margin: 0 !important;
        padding: 0 !important;
        li {
          text-overflow: ellipsis !important;
          overflow: hidden !important;
          white-space: nowrap !important;
          color: #323338 !important;
        }
      }
    }
  }
}

@media (max-width: 1120px) {
  .daysInScheduleModal {
    padding: 20px 0px !important;
  }
}
