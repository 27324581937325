// make the andt modal full screen
.ant-modal.forecasting-raport {
  width: 100vw !important;
  height: 100vh !important;
  max-width: 100vw !important;
  max-height: 100vh !important;
  margin: 0 !important;
  top: 0 !important;
  left: 0 !important;
  padding: 0 !important;
  overflow: hidden;

  .message {
    transform: translateY(10px);
    transition: opacity 0.5s ease, transform 0.5s ease;
  }

  .fade-in {
    opacity: 1;
    transform: translateY(0);
  }

  .fade-out {
    opacity: 0;
    transform: translateY(10px);
  }

  .forecastingRaport {
    display: flex;
    justify-content: center;
    align-items: center;
    .nameOfProject {
      font-weight: 600;
      font-size: 30px;
    }
  }
  .comparison-container {
    padding: 20px;
    background-color: #f5f5f5;
    min-height: 100vh;
  }

  .title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .comparison-sections {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .comparison-card {
    background: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 16px;
  }

  .section-title {
    font-size: 20px;
    font-weight: 600;
    border-bottom: 2px solid #ddd;
    padding-bottom: 8px;
    margin-bottom: 12px;
  }

  .comparison-group {
    margin-top: 16px;
  }

  .group-title {
    font-size: 18px;
    font-weight: 500;
    color: #007bff;
    margin-bottom: 8px;
  }

  .comparison-table {
    width: 100%;
    border-collapse: collapse;
  }

  .comparison-table th,
  .comparison-table td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
  }

  .forecasting {
    background-color: #e8f4ff;
  }

  .scheduling {
    background-color: #ffe8e8;
  }
  .forecastingRaportContent {
    display: flex;
    gap: 15px;
    width: 100%;

    .content {
      width: 620px;
      height: 70dvh;
      flex: 1;
      border-radius: 10px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      overflow: hidden;
      background: #fff;
      transition: transform 0.3s, box-shadow 0.3s;

      // &:hover {
      //   transform: translateY(-10px);
      //   box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
      // }

      .header {
        padding: 15px;
        color: #fff;
        font-size: 1.5em;
        text-align: center;
      }

      .data {
        padding: 20px;
        color: #333;
        font-size: 1em;
        line-height: 1.6;
        max-height: 850px;
        overflow-y: auto;
        // Main container styling
        .estimation-container {
          max-width: 620px;
          margin: 0 auto;
          // padding: 5px;
          // background-color: #f5f6fa;
          border-radius: 12px;
          // box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
          font-family: "Roboto", sans-serif;
          overflow: hidden; // Prevent overflow
        }

        // Estimation card
        .estimation-card {
          margin-bottom: 35px;
          background-color: #ffffff;
          padding: 20px;
          border-radius: 12px;
          box-shadow: 0 6px 18px rgba(0, 0, 0, 0.08);
          transition: transform 0.3s ease, box-shadow 0.3s ease;

          // &:hover {
          //   transform: translateY(-5px);
          //   box-shadow: 0 12px 32px rgba(0, 0, 0, 0.1);
          // }
        }

        // Estimation title
        .estimation-title {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 20px;
          font-weight: 600;
          margin-bottom: 20px;
          color: #2d3436;

          padding-bottom: 12px;
          text-align: center;
        }

        // Service section
        .service {
          margin-bottom: 25px;
        }

        .service-label {
          font-size: 18px;
          font-weight: 500;
          color: #0984e3;
          margin-bottom: 12px;
          position: relative;
          padding-left: 10px;

          &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 50%;
            width: 4px;
            height: 75%;
            background-color: #0984e3;
            transform: translateY(-50%);
          }
        }

        // Service details block (days, hours, crews, trucks)
        .service-details {
          display: flex;
          justify-content: space-between;
          margin-bottom: 15px;
          font-size: 13px;
          color: #636e72;
        }

        .service-details p {
          margin: 0;
          font-weight: 400;
        }

        .service-details strong {
          color: #2d3436;
          font-weight: 600;
        }

        // Elevation section
        .elevation {
          margin-bottom: 20px;
        }

        .elevation-name {
          font-size: 16px;
          font-weight: 500;
          color: #2d3436;
          margin-bottom: 8px;
          text-transform: capitalize;
        }

        // Elevation details (days, hours, crews, trucks)
        .elevation-details {
          display: flex;
          justify-content: space-between;
          margin-bottom: 15px;
          font-size: 13px;
          color: #636e72;
        }

        .elevation-details p {
          margin: 0;
        }

        .elevation-details strong {
          color: #2d3436;
          font-weight: 600;
        }

        // Table styling for items
        .items-table {
          width: 100%;
          border-collapse: collapse;
          margin-top: 10px;
          max-width: 100%;
          overflow-x: auto; // Allow horizontal scrolling if necessary
        }

        .items-table th,
        .items-table td {
          padding: 10px 12px;
          text-align: left;
          border: 1px solid #e0e0e0;
        }

        .items-table th {
          background-color: #62a8df;
          color: #ffffff;
          font-weight: 600;
          font-size: 12px;
        }

        .items-table td {
          background-color: #fafafa;
          font-size: 13px;
          color: #2d3436;
        }

        .items-table tr:nth-child(even) {
          background-color: #f4f6f7;
        }

        .items-table tr:hover {
          background-color: #dfe6e9;
          transition: background-color 0.3s ease;
        }

        // Responsive design tweaks
        @media screen and (max-width: 768px) {
          .service-details,
          .elevation-details {
            flex-direction: column;
            align-items: flex-start;
          }

          .service-details p,
          .elevation-details p {
            margin-bottom: 8px;
          }

          .items-table {
            width: 100%;
            display: block;
            overflow-x: auto;
          }

          .items-table th,
          .items-table td {
            font-size: 11px;
            padding: 8px;
          }
        }
      }

      // Unique styles for each box
      &:nth-child(1) {
        .header {
          background: linear-gradient(135deg, #ff7f50, #ff6347);
        }
        .data {
          background: #fff5f3;
          height: 100% !important;
        }
      }

      &:nth-child(2) {
        .header {
          background: linear-gradient(135deg, #6a5acd, #483d8b);
        }
        .data {
          background: #f5f3ff;
          height: 100% !important;
        }
      }

      &:nth-child(3) {
        .header {
          background: linear-gradient(135deg, #32cd32, #228b22);
        }
        .data {
          background: #f3fff5;
          height: 100% !important;
        }
      }
    }
  }
  // .forecasting-raport-card {
  //   .ant-card-body {
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;
  //     padding-top: 10;
  //   }
  //   .ant-card-content {
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;
  //     padding: 0;
  //   }
  // }

  .ant-modal-content {
    width: 100vw !important;
    height: 100vh !important;
    display: flex;
    flex-direction: column;
    border-radius: 0 !important;
    box-shadow: none !important;
  }

  .ant-modal-header {
    display: flex;
    align-items: center;
    height: 45px;
    background-color: #f5f5f5;
  }
  .ant-modal-footer {
    display: flex;
    flex-shrink: 0;
    width: 100% !important;
    background-color: #f5f5f5;
  }

  .ant-modal-body {
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .ant-modal-mask {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
  }
}
