.popover-widget-map-container {
  .ant-popover-inner {
    border-radius: 0 0 0 0 !important;
  }

  .ant-popover-inner-content {
    padding: 1px 1px !important;
  }

  .upload-progress-container {
    height: 4px;

    .ant-progress-outer {
      height: 0 !important;
    }

    .ant-progress-inner {
      transform: translateY(-8px);
      border-radius: 0;
    }

    .ant-progress-bg {
      border-radius: 0 0 0 0;
      height: 4px;
    }
  }

  .popover-widget-map {
    height: 320px;
    width: 320px;

    .widget-map {
      height: calc(100% - 4px);
      width: 100%;
    }
  }
}

.location-widget-controller-container {
  position: relative;
  top: 10px;
  left: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 10px;
}
