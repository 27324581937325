.dashboard-small-card-container {
  padding: 20px;
  border-radius: 10px;
  cursor: default;
  background-color: #fff;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.1);

  .small-dashboard-meta {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    width: 100%;
    flex-direction: row;
    gap: 10px;

    .ant-card-meta-avatar {
      height: 80%;
      aspect-ratio: 1;

      svg {
        height: 50%;
        aspect-ratio: 1;
      }
    }

    .data-icon-container {
      height: 100%;
      width: 100%;
      border-radius: 50%;
      display: grid;
      place-items: center;
      border: 2px solid;
      svg,
      path {
        fill: currentColor;
      }
    }

    .ant-card-meta-detail {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;

      .ant-card-meta-title {
        font-weight: 500;
      }

      .ant-card-meta-description {
        font-size: 20px;
        letter-spacing: 0.28px;
        font-weight: 500;
      }
    }
  }
}

.small-card-container-dark {
  background-color: #2a2b3a;
  color: #fff;
}
