.bill-control-panel-container {
  padding: 0 !important;
  .childrenContainer {
    align-items: flex-end;
    justify-content: flex-end;
    gap: 10px;

    .notes-icon {
      margin: 0;
      padding: 0;
    }
  }
}
