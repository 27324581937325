.default-widget-card {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  background-color: #f8f8fa;
  //   overflow: hidden;
  color: #323338;
  user-select: none;
  height: 110px;

  .default-widget-data {
    padding: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .default-widget-title {
      font-size: 14px;
      font-weight: 600;
      color: #323338;
      font-family: "Open Sans";
    }

    .default-widget-table {
    }

    .default-widget-card-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      gap: 10px;
    }
  }
  .default-widget-select {
    padding: 5px;
    border-radius: 0 10px 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .list-selected {
    background-color: #fe4c4a;

    &:hover {
      //   animation: hover-selected 0.5s forwards;
      cursor: pointer;
    }
  }

  .list-default {
    background-color: #71cf48;

    &:hover {
      //   animation: hover-default 0.5s forwards;
      cursor: pointer;
    }
  }
}

.remove-selected {
  animation: slide-left 0.5s ease-in-out;
}

.select-default {
  animation: slide-right 0.5s ease-in-out;
}

.card-fade-in {
  animation: card-fade-in 0.5s ease-in-out;
}

@keyframes slide-left {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes slide-right {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
  }
}

@keyframes card-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
