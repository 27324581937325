.header-task-btn-dark-report {
  background-color: rgb(18, 100, 163);
  border-color: rgb(18, 100, 163);
  color: #fff !important;
  border-radius: 5px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0;
  padding-right: 0;
  span {
    width: 21px;
    height: 21px;
    margin-right: -1px;
  }
  &:hover {
    background-color: #0d4c7c !important;
    border-color: #0d4c7c !important;
    transition: 0.2s;
  }
}

.header-task-btn-dark {
  margin-right: 18px;
  width: 32px;
  height: 32px;
}

.header-tasks-modal-dark {
  .ant-modal {
    .titleCompBasePage {
      display: none;
    }
    .ant-modal-content {
      max-width: none !important;
      width: 80% !important;
      background-color: #12131b;
      border-radius: 10px;
    }
    .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: #12131b;
      border-bottom: none;
      display: flex;
      justify-content: flex-start;
      height: 45px;
      align-items: center;
      .ant-modal-title {
        font-weight: 600;
        font-size: 16px;
        color: #f8f8fa;
      }
    }

    .ant-modal-body {
      background-color: #1f212d;
      padding-bottom: 24px;
      min-height: 250px;
    }
    .filterContainers {
      margin-top: 0;
    }
    .basePageModalFooter {
      border-top: none;
      background-color: #1f212d;
      .secondaryFooterButton {
        border-color: rgb(18, 100, 163);
        background-color: rgb(18, 100, 163);
        color: #fff;
      }
    }
    .agGridContainer {
      .ag-root.ag-layout-normal {
        min-height: 300px !important;
      }
    }
  }
}

.header-task-btn-reports {
  background-color: #1f2a44 !important;
  margin: 0 !important;
  border-color: #1f2a44 !important;
}

.header-task-btn-light-report {
  background-color: rgb(18, 100, 163);
  border-color: rgb(18, 100, 163);
  // box-shadow: rgba(17, 17, 26, 0.5) 0px 4px 16px !important;
  color: #fff !important;
  border-radius: 5px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0;
  padding-right: 0;
  span {
    width: 21px;
    height: 21px;
    margin-right: -1px;
  }
  &:hover {
    background-color: #0d4c7c !important;
    border-color: #0d4c7c !important;
    transition: 0.2s;
  }
}

.header-task-btn-light {
  margin-right: 18px;
  width: 32px;
  height: 32px;
}

.header-tasks-modal-light {
  .ant-modal {
    .titleCompBasePage {
      display: none;
    }
    .ant-modal-content {
      max-width: none !important;
      width: 80% !important;
      // background-color: #12131b;
      border-radius: 10px;
    }
    .ant-modal-close-x {
      color: #12131b;
    }
    .ant-modal-header {
      // background-color: #12131b;
      border-bottom: none;
      display: flex;
      justify-content: flex-start;
      height: 45px;
      align-items: center;
      .ant-modal-title {
        font-weight: 600;
        font-size: 16px;
        color: #333238;
        // font-family: "Helvetica";
      }
    }

    .ant-modal-body {
      // background-color: #1f212d;
      padding-bottom: 24px;
      min-height: 250px;
    }
    .filterContainers {
      margin-top: 0;
    }
    .basePageModalFooter {
      border-top: none;
      // background-color: #1f212d;
      .secondaryFooterButton {
        border-color: rgb(18, 100, 163);
        background-color: rgb(18, 100, 163);
        color: #fff;
      }
    }
    .agGridContainer {
      .ag-root.ag-layout-normal {
        min-height: 300px !important;
      }
    }
  }
}
