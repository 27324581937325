.undoRedoButtonsContainer {
  display: flex;
  gap: 5px;
  margin: 10px;

  .buttonClass {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    background-color: #1264a3;
    border: none;

    :disabled {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      border: none;
    }

    .iconClass {
      font-size: 20px;
    }
  }
}
