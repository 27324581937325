.addon-button {
  &:hover {
    color: inherit;
  }

  .addon-button-number {
    font-weight: bold;

    &.completed {
      color: #1fc74c;
    }
    &.zero {
      color: #a2a2a2;
    }
  }
}
