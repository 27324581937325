@import "../../../../../../../index.scss";

.hoist-pricing-config-card {
  border-radius: 10px;
  box-shadow: 0px 1px 4px #00000029;

  .config-card-header {
    @include flex(center, flex-end);
    gap: 8px;
    @include text(16px, 600, 0, #323338);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #f7f8fa;
    width: stretch;
    padding: 6.5px 20px;
    transition: 0.2s ease-in;

    .title {
      margin-right: auto;
    }
  }

  .config-card-header-tablet {
    flex-wrap: wrap;
    gap: 8px;
  }

  .config-card-body {
    background-color: #fff;
    padding: 20px;
    width: stretch;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    position: relative;
    overflow: scroll;
    transform: 0.2s ease-in;

    .logbtn {
      margin-bottom: 16px;
      display: flex;
      justify-content: flex-end;
      gap: 8px;
    }
  }

  .empty-configs-section {
    @include flex(center, center, column);
  }

  .actions-container {
    @include flex(center, center);
    gap: 10px;
  }

  .configs-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(390px, auto));
    gap: 10px;
  }

  .default-pricing-scheme-selector {
    @include flex(center, center);

    .selector-prefix {
      @include flex(center, center);
      @include text(14px, 400, 0, #fff);
      height: 32px;
      background-color: #1e2942;
      border-radius: 5px 0 0 5px;
      padding: 0 10px;
    }

    .ant-select-selector {
      min-width: 200px;
      height: 32px !important;
      border-radius: 0 5px 5px 0 !important;
      background-color: #1264a3 !important;
      color: #fff !important;
    }
    .ant-select-arrow {
      color: #fff;
    }
  }
}

@media only screen and (max-width: 821px) {
  .hoist-pricing-config-card .actions-container {
    display: grid;
  }
}
@media (max-width: 460px) {
  .hoist-pricing-config-card .config-card-header {
    display: grid;
  }
  .hoist-pricing-config-card .configs-grid {
    display: grid !important;
    grid-template-columns: 100% !important;
  }
  .hoist-pricing-config-card
    .actions-container
    button.ant-switch.css-dev-only-do-not-override-1lhfg1q {
    width: 300px;
  }
  .hoist-pricing-config-card
    .default-pricing-scheme-selector
    .mondayButtonContainer
    .mondayButtonText {
    display: none;
  }
  .hoist-pricing-config-card
    .default-pricing-scheme-selector
    .mondayButtonContainer
    .mondayButtonDivider {
    display: none;
  }
}
