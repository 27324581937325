@import "../../../../../../index.scss";

.subCategoriesContainer-card {
  display: flex;
  flex-direction: column;
  gap: 10px;
  // padding-left: 40px;
  overflow-y: auto;
  position: relative;
  // max-height: 350px;
  padding: 0 5px 10px 5px;
  border-radius: 5px;
  // border-bottom: 1px transparent;
  // box-shadow: 10px;
  background: #f8f8fa;
  margin-top: 10px;

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    height: 40px;
    // margin: 0 0 10px 0;
    font-size: 12;
  }

  .cards {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-y: auto;

    .cardContainer {
      margin-top: 6px;
      background: white !important;
    }
    max-height: 49vh;
  }

  .vertical-line {
    position: absolute;
    top: 50px;
    bottom: 0;
    left: 20px;
    border: 1px solid #33333396;
  }
}

.subCategoriesContainer-cardDark {
  background-color: $cardDarkBackgroundColor;

  .cards {
    .singleCard-containerDark {
      margin-top: 6px;
      color: red;
      background-color: $cardDarkHeaderColor !important;

      .name,
      .total {
        color: #fff;
      }
    }
  }
}
