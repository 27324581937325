.commonChargeAgGrid {
  .checkbox {
    height: 20px;
    width: 20px;
    cursor: pointer;
  }
  .ag-root-wrapper {
    border: 0;
  }
  .ag-header-cell {
    border: 0 !important;
  }
}
