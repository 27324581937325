.reports-view-body {
  height: calc(100vh - 106px);
  width: 100%;
  display: flex;
  justify-content: flex-start;
  transition: width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
  align-items: flex-start;
  overflow-y: auto;

  .layout {
    overflow-x: hidden;
    width: 100%;
    height: fit-content;
    min-height: calc(100vh - 106px);
    background-color: #f5f5f7;
  }

  .react-grid-item.react-grid-placeholder {
    background-color: rgba(0, 0, 0, 0.3);
  }

  .card-header {
    cursor: move;
  }
}

.reports-view-body.reports-body-dark {
  .layout {
    background-color: #20212d;
  }

  .react-grid-item.react-grid-placeholder {
    background-color: rgba(255, 255, 255, 0.3);
  }
}
