$black: #323338;
$grey: #f5f5f7;
$white: #ffffff;
$green: #00a464;
$red: #fe4c4a;
.monday-switch {
  width: 150px;
  height: 32px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  transition: all 0.4s ease-out;

  .monday-switch-toggle {
    width: 24px;
    // height: 24px;
    aspect-ratio: 1;

    background-color: $grey;
    border-radius: 100px;
    position: absolute;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: right 0.4s ease-out;

    svg {
      width: 15px;
      height: 15px;
    }

    &.onIcon {
      background-color: $white;
      right: 3%;
      svg {
        fill: $green;
      }
    }
    &.offIcon {
      background-color: $black;
      right: 98%;
      transform: translate(103%, 0%);
      svg {
        fill: $white;
      }
    }
  }
  .monday-switch-text {
    font-size: 14px;
    user-select: none;
    font-weight: 400;
    -webkit-user-select: none;
  }
  .onText {
    color: $white;
    margin-right: 20px;
  }
  .offText {
    color: $black;
    margin-left: 20px;
  }
}

.small-monday-switch {
  height: 24px;
  width: 71px;
  .monday-switch-toggle {
    width: 18px;
    height: 18px;
    svg {
      width: 10px;
      height: 10px;
    }
  }
}
