@import "../../../../../../../../index.scss";

.hoist-jumps-list-jump-name-container {
  @include flex(center, space-between);
  width: 100%;

  .stop-icons-container {
    @include flex(center, center);
  }
}
