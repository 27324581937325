$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.analyticsDeleteModal {
  height: fit-content !important;
  width: fit-content !important;
  .ant-modal-content {
    height: calc(100vh - 20px) !important;
    width: calc(100vw - 20px) !important;
    max-width: 1200px;
    max-height: 600px;
    .ant-modal-close {
      height: 45px;
      width: 45px;
      .ant-modal-close-x {
        height: 45px;
        width: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .ant-modal-header {
      height: 45px;
      display: flex;
      align-items: center;
      background-color: #f8f8fa;
    }
    .ant-modal-body {
      height: calc(100% - 100px);
      display: flex;
      flex-direction: column;
      gap: 10px;
      .infoSection {
        height: 32px;
        display: flex;
        align-items: center;
        gap: 10px;
      }
      .tableWrapper {
        height: 100%;
      }
    }
    .ant-modal-footer {
      display: flex;
      justify-content: space-between;
    }
  }
}

.analyticsDeleteModalDark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      .infoSection span {
        color: #fff;
      }
      .tableWrapper {
        .ant-table {
          background-color: $cardModalHeaderDark;
          .ant-table-content {
            .ant-table-thead {
              .ant-table-cell {
                background-color: $cardModalHeaderDark;
                border-bottom: 1px solid #acacca;
                color: #fff;
              }
            }
            .ant-table-tbody {
              .ant-table-row {
                .ant-table-cell {
                  background-color: $cardModalBodyDark;
                  color: #fff;
                }
              }
              .ant-table-placeholder {
                background-color: $cardModalBodyDark;
                color: #acacca;
                .ant-table-cell {
                  border-bottom: 1px solid #acacca;
                  &:hover {
                    background-color: #12131b;
                  }
                  .ant-empty {
                    .ant-empty-description {
                      color: #acacca;
                    }
                  }
                }
              }
            }
          }
        }
        .ant-table-pagination {
          .ant-pagination-prev,
          .ant-pagination-next {
            .anticon {
              svg {
                fill: #fff;
              }
            }
          }
          .ant-pagination-disabled {
            .anticon {
              svg {
                fill: #acacca;
              }
            }
          }
          .ant-pagination-item {
            background-color: $inputModalDark;
          }
        }
      }
    }
  }
}
