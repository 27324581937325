@import "../../../../../../../index";

$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.exportDataModal {
  width: fit-content !important;
  max-height: calc(100vh - 20px);
  max-width: calc(100vw - 20px);
  max-height: calc(100dvh - 20px);
  max-width: calc(100dvw - 20px);
  .ant-modal-content {
    height: calc(100vh - 20px);
    height: calc(100dvh - 20px);
    max-height: 820px;
    width: calc(100vw - 20px);
    width: calc(100dvw - 20px);
    max-width: 1500px;
    .ant-modal-close {
      height: 45px;
      width: 45px;
      .ant-modal-close-x {
        height: 45px;
        width: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          fill: #333238;
        }
      }
    }
    .ant-modal-header {
      height: 45px;
      display: flex;
      align-items: center;
      background-color: #f5f5f7;
      .ant-modal-title {
        color: #333238;
        font-size: 16px;
        font-weight: 600;
      }
    }
    .ant-modal-body {
      height: calc(100% - 100px);
      overflow-y: auto;
      overflow-x: hidden;
      .infoContainer {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 10px;
      }
      .ant-form {
        height: 165px;
        display: flex;
        justify-content: space-between;
        gap: 20px;
        .labeledInputContainer {
          min-width: 200px;
          .ant-select-selection-overflow {
            height: fit-content;
            max-height: 156px;
            flex-wrap: nowrap;
            overflow-y: auto;
          }
        }
      }
      .filterActions {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 20px;
      }
      .export-data-grid {
        height: calc(100vh - 480px);
      }
    }
    .ant-modal-footer {
      height: 50px;
      display: flex;
      justify-content: space-between;
      padding: 10px 20px;
      .exportButtons {
        display: flex;
        gap: 10px;
        .mondayButtonExcel {
          @include mondayButton(#217346, #1c643d, #fff);
        }
        .mondayButtonPdf {
          @include mondayButton(#c71111, #b41111, #fff);
        }
      }
    }
  }
}

.exportDataModalDark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      .infoContainer span {
        color: #fff;
      }
      .labeledInputLabel {
        color: #fff;
      }
      .ant-picker {
        background-color: $inputModalDark !important;
        .ant-picker-input {
          background-color: $inputModalDark;
          input {
            color: #f2f2f2;
            &::placeholder {
              color: #acacca;
            }
          }
          .ant-picker-suffix svg {
            color: #acacca;
          }
          .ant-picker-clear {
            background-color: $inputModalDark;
            svg {
              fill: #acacca;
            }
          }
        }
      }
      .ant-picker-disabled {
        .ant-picker-input input {
          color: #acacca !important;
        }
      }
      .ant-select {
        .ant-select-selector {
          background-color: $inputModalDark !important;
          .ant-select-selection-search-input {
            color: #fff;
          }
          .ant-select-selection-item {
            color: #fff;
          }
          .ant-select-selection-placeholder {
            color: #acacca;
          }
        }
        .ant-select-clear {
          background-color: $inputModalDark;
          svg {
            fill: #acacca;
          }
        }
        .ant-select-arrow svg {
          fill: #fff;
        }
      }
      .ant-select-disabled {
        .ant-select-selector {
          .ant-select-selection-search-input {
            color: #acacca !important;
          }
          .ant-select-selection-item {
            color: #acacca !important;
          }
        }
      }
      .export-data-grid {
        .ag-header {
          background-color: $cardModalBodyDark;
          .ag-header-row {
            border-top: none;
          }
          .ag-header-cell-resize::after {
            width: 1px !important;
            background-color: #4a4a4a !important;
          }
        }
        .ag-paging-panel {
          background-color: $cardModalBodyDark;
        }
      }
    }
  }
}
