@import "../../../../../index.scss";

.reportsMenu {
  position: relative;
  flex: 20;
  background-color: #fff;

  .reportsMenuLabel {
    height: 50px;
    width: inherit;
    background-color: #1264a3;
    @include flex(center, center);
    @include text(18px, 600, 0, #fff);
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #1264a32b;
  }

  .ant-menu-item-selected {
    color: #1264a3;
    &::after {
      border-right-color: #1264a3;
    }
  }

  .ant-menu-title-content {
    @include flex(center, space-between);
  }

  .menuItemIcon:hover {
    fill: #eb4345;
  }

  .newReportButton {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
  }

  .ant-menu-item {
    color: #323338;

    &:hover {
      color: #1264a3;
    }
  }
}
