@import "/src/index.scss";

$searchDark: #12131b;

.roles-access-tab {
  .bodyContainer {
    height: calc(100% - 60px);
    .leftContainer {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .user-list-container {
        height: 375px;
        .user-list-table-body {
          height: 215px;
        }
      }
    }
    .rightContainer {
      .collapsedContainer {
        .card-template-body {
          gap: unset;
          .info-and-search {
            display: flex;
            gap: 20px;
            align-items: center;
            padding: 20px;
            .user-access-info-text {
              width: 61%;
            }
            .config-search {
              width: 38%;
              height: 32px;
            }
          }
          .only-info {
            justify-content: center !important;
            .user-access-info-text {
              width: 100% !important;
              justify-content: center !important;
            }
          }
          .user-access-list-header {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 0 20px 0 0;
            border-bottom: 1px solid #bbbfc7;

            div {
              font-size: 16px;
              font-weight: 600;
              color: #323338;
              width: 50%;
              padding-left: 20px;
              &:first-child {
                border-right: 2px solid #dddfe3;
                display: flex;
                align-items: center;
                height: 15px;
              }
            }
          }
          .collapsedContainer-body {
            max-height: 567px;
            overflow-y: auto;
            border-radius: 0 0 10px 10px;
            .ant-tree {
              width: 100%;
            }
          }
          .notification-preferences-tab {
            height: 640px;
            display: flex;
            width: 720px;
            gap: 20px;
            justify-content: center;
            align-self: center;
            .preferencesCollapseLeftSide {
              width: 50%;
            }
            .preferencesCollapseRightSide {
              width: 50%;
              height: 100%;
              display: flex;
              flex-direction: column;
              box-shadow: 0px 1px 4px #00000029;
              font: normal normal 600 16px/22px Open Sans;
              border-radius: 10px;
              background-color: #f8f8fa;
              .search-input {
                padding: 10px 20px 0 10px;
                .ant-form-item {
                  margin-bottom: 10px;
                }
                .ant-input-affix-wrapper {
                  background-color: #fff !important;
                  .ant-input {
                    background-color: #fff !important;
                  }
                }
              }

              .selectedBody {
                display: flex;
                flex-direction: column;
                padding: 0 14px 10px 10px;
                height: 100%;
                overflow-y: auto;
              }
            }
            .empty-right-side {
              align-items: center;
            }
          }
        }
      }
    }
  }
}

.roles-access-tab.settingPaneDark
  .bodyContainer
  .rightContainer
  .collapsedContainer {
  .info-and-search .config-search .ant-input-affix-wrapper {
    background-color: $inputDarkColor !important;
    .ant-input {
      background-color: $inputDarkColor !important;
    }
  }

  .card-template-body {
    .user-access-list-header div {
      color: #fff !important;
    }
    .notification-preferences-tab {
      .preferencesCollapseRightSide {
        background-color: $inputDarkColor !important;
        .search-input .ant-input-affix-wrapper {
          background-color: $searchDark !important;
          .ant-input {
            background-color: $searchDark !important;
            &::placeholder {
              color: #acacca;
            }
          }
          .ant-input-suffix svg {
            fill: #acacca;
          }
        }
      }
      .empty-right-side {
        color: #fff;
      }
    }
  }
}
