@import "../../../../index.scss";

$mainDarkBackgroundColor: #20212d;
$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.normalSizedModal.permitDrawingModalContainer {
  .ant-modal {
    width: 90vw !important;
  }

  .ant-modal-content {
    width: 100% !important;
    height: fit-content;
    max-height: fit-content;
    max-width: calc(100vw - 20px);
    margin: auto;
  }

  .ant-modal-body {
    height: 100%;
    width: 100%;
  }

  // .labeledInputContainer {
  // margin-right: 15px;
  // }

  .labeledInputLabel {
    @include text(14px, 600, 0.21px, #383554);
    margin-bottom: 2px;
  }
  .modalBodyContainer {
    height: calc(100vh - 10%);
    display: grid;
    grid-template-columns: [full-start] 34.5% 65.5% [full-end];
    column-gap: 15px;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .modalBodyContainer-splash {
    grid-column: full;
  }

  .allDrawingsGrid {
    display: grid;
    grid-template-rows: auto 1fr;
    width: 100%;
    // padding: 10px;
    height: 100%;
    .borderedTextCard {
      border: none;
      border-radius: 0;
      border-top: 1px solid black;
      padding: 20px 0px 0px 0px;
      align-items: center;
      .cardTitle {
        // left: 40%;
        color: black;
        font-size: 16px;
      }
      .filtersContainer {
        gap: 10px;
        align-items: center;
        .labeledInputContainer {
          height: 75px;
        }
      }
      .childrenContainer {
        width: 100%;
      }
    }
  }

  .permitDrawingRecordType {
    @include flex(baseline, flex-start);
    height: 60%;
    border-radius: 5px;
    padding-left: 10px;
    width: fit-content;
    background: #f8f8fa;
  }

  .radioTitle {
    @include text(14px, 600, 0, #323338);
    margin-right: 15px;
  }

  .coreInfoContainer {
    max-width: 580px;
    width: 100%;
    border: none;
    border-top: 1px solid black;
    border-radius: 0;
    padding-bottom: 11px;
    padding-left: 0;
    padding-right: 0;
    align-items: center;
    .cardTitle {
      color: black;
      font-size: 16px;
    }
  }

  .cardContainer {
    @include flex(flex-start, flex-start, column);
    flex-wrap: nowrap;
    max-width: 723px;
    max-height: 62dvh;
    overflow-y: auto;
  }
  .inputsContainer {
    // max-width: 570px;
    width: 100%;
    position: relative;
    row-gap: 10px;
    column-gap: 10px;
    @include flex(center, flex-start);
    flex-wrap: wrap;
    padding-right: 5px;

    .permitAvatarsDiv {
      display: flex;
      gap: 20px;
    }
    .labeledInputContainer {
      height: 70px;
    }
  }

  .servicesContainer {
    margin-bottom: 24px;
    .labeledInputLabel .addServiceBtn {
      margin-left: 10px;
    }
  }

  .checkboxContainer {
    @include flex(center, flex-start);
    flex-wrap: wrap;
    gap: 5px;
    .ant-checkbox-wrapper {
      color: #383554;
      border: 1px solid rgba(0, 0, 0, 0.1);
      box-shadow: 1.5px 1.5px 5px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      padding: 5px;
      padding-left: 10px;
      width: 100%;
      margin-top: 5px;
      background-color: #fff;
      min-width: 180px;
      &:hover {
        border-color: #1890ff;
        color: #1890ff;
        background-color: #e2f1ff;
      }
    }
    .ant-form-item {
      margin-bottom: 5px;
    }
  }
  .formGridContainer {
    .labeledInputContainer {
      max-width: 202px;
      width: 100%;
      min-width: 110px;
    }

    .statusCard {
      width: 160px;
    }
  }

  .hint {
    top: 55px;
    left: 485px;
    position: absolute;
    color: #cdcdcd;
  }

  .footerButtons {
    display: flex;
    justify-content: space-between;
    padding: 20px 0 20px 0;
    @media (min-width: 500px) {
      button {
        width: 150px;
      }
    }
  }
}

.addServicePopover {
  .ant-popover-title {
    height: 32px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    background-color: #f8f8fa;
    border-radius: 5px 5px 0px 0px;
    margin-bottom: 5px;
  }
  .ant-popover-inner-content {
    padding: 0px !important;
    .popoverServicesContainer {
      height: 200px;
      width: 305px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      overflow-y: auto;
      overflow-x: hidden;
      padding: 5px 10px;
      gap: 5px;
      .labeledInputContainer {
        width: 100%;
        .ant-form-item {
          margin-bottom: 0px;
        }
      }
      .ant-radio-wrapper {
        width: 100%;
        border-radius: 5px;
        background-color: #f8f8fa;
        padding: 5px;
      }
    }
  }
}

.addServicePopoverDark {
  .ant-popover-arrow::before {
    background-color: $cardModalHeaderDark;
  }
  .ant-popover-inner {
    background-color: $cardModalBodyDark;
    box-shadow: 0 3px 9px 0 #9c9c9c14, 0 3px 6px -4px #9c9c9c14,
      0 5px 10px 4px #9c9c9c14;
    .ant-popover-title {
      background-color: $cardModalHeaderDark;
      color: #fff;
    }
    .ant-popover-inner-content .popoverServicesContainer {
      .ant-input {
        background-color: $inputModalDark !important;
        color: #fff;
        &::placeholder {
          color: #acacca;
        }
      }
      .ant-radio-wrapper {
        background-color: $inputModalDark;
        span {
          color: #fff;
        }
      }
    }
  }
}

.permitDrawingModalContainerDark {
  .ant-modal-content {
    .ant-modal-body {
      .ant-form {
        .permitDrawingRecordType {
          background-color: $inputModalDark;
          .radioTitle {
            color: #fff;
          }
          .ant-radio-group {
            .ant-radio-wrapper {
              color: #fff;
            }
          }
        }
        .coreInfoContainer {
          border-top-color: #fff;
          .cardTitle {
            color: #fff;
            background-color: $cardModalBodyDark;
          }
          .cardContainer {
            .labeledInputLabel {
              color: #fff;
            }
            .inputsContainer {
              .ant-select {
                .ant-select-selector {
                  background-color: $inputModalDark !important;
                  .ant-select-selection-search-input {
                    color: #fff;
                  }
                  .ant-select-selection-item {
                    color: #fff;
                  }
                  .ant-select-selection-placeholder {
                    color: #acacca;
                  }
                }
                .ant-select-clear {
                  background-color: $inputModalDark;
                  svg {
                    fill: #acacca;
                  }
                }
                .ant-select-arrow svg {
                  fill: #fff;
                }
              }
              .ant-input {
                background-color: $inputModalDark !important;
                color: #fff;
                &::placeholder {
                  color: #acacca;
                }
              }
            }
            .servicesContainer {
              .labeledInputLabel {
                color: #fff;
              }
              .checkboxContainer {
                .ant-checkbox-wrapper {
                  background-color: $inputModalDark;
                  span {
                    color: #fff;
                  }
                }
              }
            }
          }
        }
        .filtersCard {
          border-top-color: #fff;
          .cardTitle {
            color: #fff;
            background-color: $cardModalBodyDark;
          }
          .filtersContainer {
            .labeledInputLabel {
              color: #fff;
            }
            .ant-select {
              .ant-select-selector {
                background-color: $inputModalDark !important;
                .ant-select-selection-search-input {
                  color: #fff;
                }
                .ant-select-selection-item {
                  color: #fff;
                }
                .ant-select-selection-placeholder {
                  color: #acacca;
                }
              }
              .ant-select-clear {
                background-color: $inputModalDark;
                svg {
                  fill: #acacca;
                }
              }
              .ant-select-arrow svg {
                fill: #fff;
              }
            }
          }
        }
        .ag-theme-custom-react {
          .ag-root-wrapper {
            .ag-header {
              height: 42px !important;
              min-height: 42px !important;
              .ag-header-cell {
                background-color: $cardModalBodyDark !important;
                .ag-header-cell-text {
                  color: #fff !important;
                }
              }
              .ag-header-cell-resize::after {
                background-color: grey !important;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1000px) {
  .permitDrawingModalContainer {
    .permitDrawingRecordType {
      height: 32px !important;
    }
    .borderedTextCard {
      margin-top: 20px;
    }
    .modalBodyContainer {
      display: block;
      overflow-y: auto;
    }
    .cardContainer {
      height: fit-content;
    }
  }
}

@media (max-width: 768px) {
  .permitDrawingModalContainer {
    padding: 10px 0px !important;
  }
}

@media (max-width: 540px) {
  .permitDrawingModalContainer {
    .permitDrawingRecordType {
      height: 90px !important;
    }
  }
}

@media (max-width: 450px) {
  .permitDrawingModalContainer {
    .permitDrawingRecordType {
      height: 90px !important;
      display: block !important;
    }

    .modalBodyContainer {
      overflow: auto !important;
      display: block !important;
      height: calc(100vh - 200px) !important;
    }
    .cardContainer {
      height: 100% !important;
      width: 100%;
    }
    .inputsContainer {
      width: 100%;
    }
    .formGridContainer .ag-theme-custom-react {
      min-height: 400px;
    }
    .labeledInputContainer {
      min-width: 90% !important;
    }
  }
}
