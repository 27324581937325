@import "../../../../../../index.scss";

$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.task_details_wrapper {
  * {
    font-family: "Open Sans";
    // max-height: 90vh;
  }
  .ant-modal-content {
    border-radius: 10px;
  }
  .ant-modal-header {
    height: 45px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #f8f8f8;
    border-radius: 10px 10px 0px 0px;
    .ant-modal-title {
      width: 100%;
      font-size: 16px;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 30px;
    }
  }
  .ant-modal-close {
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-modal-body {
    padding: 20px;
    // max-height: calc(90vh - 100px);
    overflow-x: hidden;
    overflow-y: auto;
    height: calc(100vh - 199px);
  }
  .ant-steps-navigation {
    padding: 0;
  }
  .detailsModal__info__container {
    display: grid;
    padding: 20px 0;
    gap: 20px;
    grid-template-columns: 2fr 5fr 3fr;
    grid-template-rows: auto;
    grid-template-areas: "a b c";
    overflow-x: auto;
    @media (max-width: 1400px) {
      & {
        grid-template-rows: auto auto;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-areas:
          "b b c"
          "a a a";
      }
    }
    @media (max-width: 1025px) {
      & {
        grid-template-rows: auto auto auto;
        grid-template-columns: 100%;
        grid-template-areas:
          "b"
          "c"
          "a";
      }
    }
    .docUploader {
      // flex: 2;
      grid-area: a;
      .uploadedFilesSection {
        max-height: 60vh;
      }
    }
    .detailsModal__leftSide {
      display: flex;
      flex-direction: column;
      // flex: 5;
      grid-area: b;
      // width: 75%;
      span {
        font-size: 16px;
        display: flex;
        align-items: center;
        h4 {
          font-weight: 600;
          font-size: 16px;
          margin-bottom: 0;
          padding-right: 5px;
        }
      }
    }

    .detailsModal__rightSide {
      grid-area: c;
      display: flex;
      flex-direction: column;
      // flex: 1;
      // padding: 0 15px;
      // width: 25%;
      // flex: 3;

      .task-users-edit-section {
        background-color: #f8f8fa;
        border-radius: 10px;
        .users-section {
          // margin-bottom: 10px;
          // margin-left: 10px;

          padding: 10px;

          .profile-photos-container {
            display: flex;
            cursor: pointer;
          }

          .current_persons_task {
            color: #323338;
            font-weight: 600;
          }
        }
      }

      .task-dates-edit {
        background-color: #f8f8f8;
        border-radius: 10px;
        margin-top: 10px;
      }

      .task-labels {
        background-color: #f8f8f8;
        border-radius: 10px;
        margin-top: 10px;
      }

      .detailsModal__collaborators {
        position: relative;
        display: flex;
        padding: 3px 15px;
        gap: 5px;
        h4 {
          margin-bottom: 0;
          font-size: 1rem;
        }
        h3 {
          margin-bottom: 0;
          color: #1264a3;
          font-size: 0.875rem;
          font-weight: 600;
        }

        .detailsModal__addItem {
          background: white;
          color: #bebebe;
          border-style: none;
          text-decoration: none;
          cursor: pointer;
          font-size: 13px;
        }
        .detailsModal__label {
          display: flex !important;
          flex-wrap: wrap;
          .detailsModal__label {
            color: white !important;
            font-size: 12px !important;
            padding: 0 5px;
            border-radius: 5px;
            // margin-right: 15px;
          }
        }

        .detailsModal__collaborators > button {
          margin-top: 10px;
          padding: 0;
        }

        .detailsModal__collaborators__modal {
          width: 100%;
          display: flex;
          flex-direction: column;
          background: #f8f8f8;
          align-items: center;
          border-radius: 5px;
          justify-content: center;
          padding: 20px;
          position: absolute;
          box-shadow: 0px 0px 11px -8px;
          top: 20px;
          z-index: 1000;

          .ant-select {
            width: 100%;
          }
          .ant-select-selector {
            border-radius: 2px;
          }
          .modal__label {
            padding: 0 !important;
          }
          .ant-picker {
            width: 100%;
          }
          .ant-picker-suffix {
            svg {
              width: 16px;
              fill: #c7c7c7;
            }
          }
        }

        .mondayButtonContainer {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          margin-top: 20px;
          .mondayButtonStyle {
            background-color: #1264a3;
            height: 24px;
            .mondayButtonDivider {
              background-color: #0f5c97;
            }
          }
        }
      }
      .detailsModal__timer {
        display: flex;
        align-items: center;
        justify-content: space-around;
        background: #1264a3;
        width: 120px;
        border-style: none;
        padding: 10px;
        margin-top: 20px;
        border-radius: 5px;
        color: white;
        font-size: 13px !important;
        &:disabled {
          background: #c1c1c1;
        }
      }

      .loggedIn-section {
        display: flex;
        margin-top: 10px;

        .stop-timer-from-task {
          background-color: rgb(240, 79, 78) !important;
          color: #fff;
          .mondayButtonDivider {
            background-color: #eb4345 !important;
          }
        }
      }

      .ant-alert {
        margin-top: 5px;
        padding-top: 0;
        padding-bottom: 0;
        height: 32px;
        .ant-alert-action {
          width: 20px;
          height: 20px;
          background-color: #1264a3;
          border-radius: 2px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: white;
          cursor: help;
        }
      }
    }
  }
  .detailsModal__box-title {
    position: relative;
    text-align: center;
    font-weight: 600;
    color: #323338;
    span {
      display: inline-block;
      position: relative;
      z-index: 11;
      background: #fff;
      padding: 0 12px;
    }
    &::after {
      content: "";
      width: 100%;
      height: 1px;
      display: inline-block;
      position: absolute;
      top: 10px;
      left: 0;
      z-index: 10;
      background-color: #f2f4f6;
    }
    .post-comment-btn path {
      fill: #fff;
    }
  }

  .task_details_footer {
    display: flex;
    gap: 5px;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    overflow: auto;

    span {
      display: flex;
      gap: 5px;
    }
  }

  .close_task_details_button {
    background-color: #ea3d3de3;
    box-shadow: 2px 2px 2px #00000012;
    border: none;
    border-radius: 5px;
    color: #fff;
    &:hover {
      background-color: #961616e3;
      transition: 0.3s;
    }
  }
}

.task_details_wrapper_dark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      .taskStatusSteper {
        .ant-steps {
          .ant-steps-item-finish ::before,
          .ant-steps-item-active::before,
          .ant-steps-item::before {
            border-left-color: $cardModalBodyDark;
          }
          .ant-steps-item-wait {
            background-color: $cardModalHeaderDark;
            &::after {
              border-left-color: $cardModalHeaderDark;
            }
            .ant-steps-item-content .ant-steps-item-title {
              color: #acacca;
            }
          }
        }
      }
      .detailsModal__info__container {
        .detailsModal__leftSide {
          span {
            color: #fff;
          }
          .subtask-depend-wrapper {
            .subtasks-header-text {
              color: #fff;
            }
            .ant-input {
              background-color: $inputModalDark !important;
              color: #fff;
              &::placeholder {
                color: #acacca;
              }
            }
            .ant-input-disabled {
              color: #acacca;
            }
            .modal__subAddElement {
              .subtask-todo {
                background-color: #f1e8a2bb;
              }
              .unChecked {
                background-color: #ecdf7c;
              }
            }
          }
        }
        .detailsModal__rightSide {
          .task-users-edit-section,
          .task-dates-edit,
          .detailsModal__collaborators {
            background-color: $cardModalHeaderDark;
            border-radius: 10px;
            color: #fff;
            span {
              color: #fff;
            }
            .detailsModal__addItem {
              background-color: $cardModalHeaderDark;
              color: #acacca;
            }
          }
          .task-reminder-edit {
            .ant-picker {
              background-color: $inputModalDark !important;
              .ant-picker-input {
                background-color: $inputModalDark;
                input {
                  color: #f2f2f2;
                  &::placeholder {
                    color: #acacca;
                  }
                }
                .ant-picker-suffix svg {
                  color: #acacca;
                }
                .ant-picker-clear {
                  background-color: $inputModalDark;
                  svg {
                    fill: #acacca;
                  }
                }
              }
            }
            .ant-picker-disabled {
              .ant-picker-input input {
                color: #acacca !important;
              }
            }
            .ant-input-wrapper {
              background-color: $inputModalDark !important;
              .ant-input {
                background-color: $inputModalDark !important;
                color: #fff;
                &::placeholder {
                  color: #acacca;
                }
              }
              .ant-input-disabled {
                color: #acacca;
              }
              .ant-input-group-addon {
                background-color: $inputModalDark !important;
              }
            }
            .ant-select {
              .ant-select-selector {
                background-color: $inputModalDark !important;
                .ant-select-selection-overflow-item {
                  .ant-select-selection-item {
                    background-color: #323338;
                    .ant-select-selection-item-remove svg {
                      fill: #fff;
                    }
                  }
                }
                .ant-select-selection-search-input {
                  color: #fff;
                }
                .ant-select-selection-item {
                  color: #fff;
                }
                .ant-select-selection-placeholder {
                  color: #acacca;
                }
              }
              .ant-select-clear {
                background-color: $inputModalDark;
                svg {
                  fill: #acacca;
                }
              }
              .ant-select-arrow svg {
                fill: #fff;
              }
            }
            .ant-select-disabled {
              .ant-select-selector {
                .ant-select-selection-search-input {
                  color: #acacca !important;
                }
                .ant-select-selection-item {
                  color: #acacca !important;
                }
              }
            }
          }
        }
      }
      .detailsModal__box-title {
        span {
          background-color: $cardModalBodyDark;
          color: #fff;
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .task_details_wrapper {
    .detailsModal__info__container {
      .detailsModal__rightSide {
        .task-users-edit-section,
        .task-dates-edit,
        .task-labels,
        .task-reminder-edit {
          width: 100%;
        }

        .detailsModal__timer {
          align-self: flex-start;
        }
      }
    }
  }
}
