@import "../../../../../../../index.scss";
.newUserModalOld {
  width: fit-content !important;
  .newUserModalContent {
    display: grid;
    grid-template-columns: repeat(2, auto);
    column-gap: 70px;

    align-items: center;

    .formSection {
      @include flex(flex-end, center, column);
      gap: 15px;
    }

    .labeledInputContainer {
      @include flex(baseline, center);
      gap: 10px;

      .ant-row,
      .react-tel-input,
      .form-control {
        width: 350px;
      }
    }

    .form-control,
    .flag-dropdown {
      border: 0;
      background-color: #f4f5f6;
    }

    .modalFooter {
      @include flex(center, space-between);
      grid-column: 1 / span 2;
    }
  }
}
