.save-name-modal {
  margin: 0px !important;
  padding: 0px !important;
  .ant-modal-content {
    width: 450px;
    height: 260px;
    border-radius: 10px;
    padding: 0px;
    margin: 0px !important;
    padding: 0px !important;
    .ant-modal-body {
      width: 100%;
      height: 100%;
      margin: 0px !important;
      padding: 0px !important;
      border-radius: 10px 10px 0 0;
      .confirm-delete-modal-content {
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        .confirm-delete-header {
          width: 450px;
          padding: 0;
          height: 80px;
          background-color: #71cf48;
          border-radius: 10px 10px 0px 0px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          font-family: "Open Sans";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
        }
        .confirm-delete-body {
          padding: 20px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .delete-modal-content-title {
            font-family: "Open Sans";
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            text-align: center;
            height: 22px;
          }
          .delete-modal-content-description {
            padding-top: 15px;
            font-family: "Open Sans";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            text-align: center;
            height: 50px;
            .ant-input {
              border-radius: 5px;
              border: none;
              height: 40px;
              padding: 0 10px;
              background: #f5f5f7;
            }
          }
        }
        .confirm-delete-footer {
          padding: 20px 20px 0 20px;
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
}
