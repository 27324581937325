.with-tooltip-popover {
  .ant-popover-content {
    .ant-popover-inner-content {
      padding: 6px 8px;
      .input-container {
        display: flex;
        gap: 5px;
        align-items: flex-end;
        .ant-radio-group {
          display: flex;
          flex-direction: column;
        }

        .labeledInputContainer {
          .labeledInputLabel {
            gap: 5px;
            font-size: 12px;
            margin-left: unset;
          }
        }
      }
    }
  }
}

.dynamic-with-tooltip {
  .ant-tooltip-content {
    .ant-tooltip-inner {
      .dynamic-tooltip {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        .tooltip-title {
          width: 100%;
        }
        .tooltip-icon {
          display: flex;
          align-items: center;
          cursor: pointer;
        }
      }
    }
  }
  &.secondary {
    .ant-tooltip-arrow {
      &:before {
        background: #f8f8fa;
      }
    }
    .ant-tooltip-content {
      .ant-tooltip-inner {
        background: #f8f8fa;
        color: #323338;
        .dynamic-tooltip {
          .tooltip-icon {
            svg {
              path {
                fill: #323338;
              }
            }
          }
        }
      }
    }
  }
}

.dynamic-tooltip-setting-icon {
  &.primary {
    .ant-tooltip-arrow {
      &:before {
        background: #f8f8fa;
      }
    }
    .ant-tooltip-content {
      .ant-tooltip-inner {
        background: #f8f8fa;
        color: #323338;
      }
    }
  }
}
