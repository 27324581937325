@import "../../../../../../../assets/styles/breakpoints.scss";

$mainDarkBackgroundColor: #20212d;
$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.wrapper-create-payment-container {
  overflow: hidden;
}

.create-payment-container {
  top: 0 !important;
  position: initial;
  top: 0 !important;
  width: 100% !important;
  height: 100dvh !important;
  margin: 0;
  padding: 0;
  max-width: 100%;
  &::before {
    display: none !important;
  }
  .ant-modal-content {
    border-radius: 0;
    width: 100%;
    height: 100dvh;
    display: flex;
    flex-direction: column;
    background-color: #f4f5f6;

    .ant-modal-header {
      padding: 0 !important;
      display: flex;

      .ant-modal-title {
        justify-content: space-between;
        padding: 0px 16px;
        font-family: Helvetica;
        font-size: 22px;
        font-weight: bold;
        opacity: 1;
        text-transform: none;
        letter-spacing: 0.41px;
        height: 50px;
        width: 100%;
        display: flex;
        align-items: center;
        background: #1f2a44;
        color: white;

        svg {
          fill: white;
          height: 50px;
          cursor: pointer;
          z-index: 1;
        }
        .payment-modal-title {
          position: absolute;
          width: 100%;
          text-align: center;
        }
        .accountingWriteModeContainerLight {
          z-index: 1;
        }
        .payment-modal-header {
          height: 50px;
          width: 100%;
          display: flex;
          margin-left: 2px;
          align-items: center;
          .close-btn {
            height: 50px;
            display: flex;
            align-items: center;
            svg {
              margin-left: auto;
            }
          }
          .darkModeContainer {
            margin-left: 20px;
            display: flex;
            .firstSquare {
              background-color: white;
            }
            .secondSquare {
              background-color: white;
            }
            .innerCircle {
              background-color: white;
            }
            .outerCircle {
              background-color: #12131b;
            }
          }
        }
      }
    }
    .ant-modal-close-icon {
      svg {
        color: white;
      }
    }
    .ant-modal-body {
      flex: 1;
      display: flex;
      flex-direction: column;
      background-color: #f4f5f6;
      overflow-y: hidden;
      height: calc(100vh - 119px);
      width: 100%;
      padding: 0;
      .payment-modal-content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        padding-right: 10px;
        gap: 20px;
        .documentationsTable.agGridContainer.ag-grid-payments-table {
          height: 400px !important;
        }

        .payment-up-container {
          display: flex;
          gap: 10px;

          @include tablet {
            flex-wrap: wrap;
          }
        }
      }
    }
    .ant-modal-footer {
      padding: 0 !important;
      // box-shadow: 0px 0px 11px -8px;
      background-color: white;
      display: flex;
      align-items: center;

      // margin: 0px 20px 20px 20px;
      > div {
        .body {
          display: flex;
          justify-content: flex-end;
          padding: 10px;
          font-family: inherit;
          .cancelButton {
            background: #fe4c4a !important;
            .mondayButtonDivider {
              background-color: #d25655;
            }
          }

          .mondayButtonContainer {
            margin-left: 10px;
          }
        }
      }
    }
    .ant-select-item {
      line-height: 7px !important;
    }
  }
}

.create-payment-container-dark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-header .ant-modal-title {
      background-color: #101c35 !important;
      .payment-modal-header {
        background-color: #101c35;
      }
    }
    .ant-modal-body {
      background-color: $mainDarkBackgroundColor;
      .payment-up-container .payment-form-wrapper {
        background-color: $cardModalHeaderDark;
        box-shadow: 0px 0px 4px 2px rgba($color: #ffffff, $alpha: 0.16);
        .payment-form-header {
          background-color: $cardModalBodyDark;
          .payment-form-title {
            color: #fff;
          }
          .payment-customer-balance {
            color: #fff;
            span {
              color: #fff;
            }
          }
        }
        .payment-form-body {
          background-color: $cardModalHeaderDark;
          .payment-form-elements {
            .payment-form-element {
              label {
                color: #fff;
              }
              .ant-input .input-form {
                background-color: $cardModalBodyDark !important;
                color: #fff;
                border: none;
                &::placeholder {
                  color: #acacca;
                }
              }
              .ant-input-disabled {
                color: #acacca;
              }
              .ant-select {
                .ant-select-selector {
                  background-color: $cardModalBodyDark !important;
                  .ant-select-selection-search-input {
                    color: #fff;
                  }
                  .ant-select-selection-item {
                    color: #fff;
                  }
                  .ant-select-selection-placeholder {
                    color: #acacca;
                  }
                }
                .ant-select-clear {
                  background-color: $cardModalBodyDark;
                  svg {
                    fill: #acacca;
                  }
                }
                .ant-select-arrow svg {
                  fill: #fff;
                }
              }
              .ant-select-disabled {
                .ant-select-selector {
                  .ant-select-selection-search-input {
                    color: #acacca !important;
                  }
                  .ant-select-selection-item {
                    color: #acacca !important;
                  }
                }
              }
              .ant-picker {
                background-color: $cardModalBodyDark !important;
                .ant-picker-input {
                  background-color: $cardModalBodyDark;
                  input {
                    color: #f2f2f2;
                    &::placeholder {
                      color: #acacca;
                    }
                  }
                  .ant-picker-suffix svg {
                    color: #acacca;
                  }
                  .ant-picker-clear {
                    background-color: $cardModalBodyDark;
                    svg {
                      fill: #acacca;
                    }
                  }
                }
              }
              .ant-picker-disabled {
                .ant-picker-input input {
                  color: #acacca !important;
                }
              }
              .ant-input-number {
                background-color: $cardModalBodyDark !important;
                .ant-input-number-input {
                  color: #fff;
                  &::placeholder {
                    color: #acacca;
                  }
                }
              }
            }
          }
          .payment-method-elements {
            .ant-btn {
              background-color: $cardModalBodyDark;
              color: #fff;
              svg {
                fill: #fff;
              }
            }
          }
          .payment-check-dates {
            .payment-check-dates-details {
              label {
                color: #fff;
              }
              .ant-picker {
                background-color: $cardModalBodyDark !important;
                .ant-picker-input {
                  background-color: $cardModalBodyDark;
                  input {
                    color: #f2f2f2;
                    &::placeholder {
                      color: #acacca;
                    }
                  }
                  .ant-picker-suffix svg {
                    color: #acacca;
                  }
                  .ant-picker-clear {
                    background-color: $cardModalBodyDark;
                    svg {
                      fill: #acacca;
                    }
                  }
                }
              }
              .ant-picker-disabled {
                .ant-picker-input input {
                  color: #acacca !important;
                }
              }
              .ant-select {
                .ant-select-selector {
                  background-color: $cardModalBodyDark !important;
                  .ant-select-selection-search-input {
                    color: #fff;
                  }
                  .ant-select-selection-item {
                    color: #fff;
                  }
                  .ant-select-selection-placeholder {
                    color: #acacca;
                  }
                }
                .ant-select-clear {
                  background-color: $cardModalBodyDark;
                  svg {
                    fill: #acacca;
                  }
                }
                .ant-select-arrow svg {
                  fill: #fff;
                }
              }
              .ant-select-disabled {
                .ant-select-selector {
                  .ant-select-selection-search-input {
                    color: #acacca !important;
                  }
                  .ant-select-selection-item {
                    color: #acacca !important;
                  }
                }
              }
            }
          }
        }
      }
      .payment-projects-container {
        box-shadow: 0px 0px 4px 2px rgba($color: #ffffff, $alpha: 0.16);
        border-radius: 10px;
        .payment-projects-wrapper {
          background-color: $cardModalHeaderDark;
          .payment-projects-header {
            background-color: $mainDarkBackgroundColor;
            div {
              color: #fff;
            }
          }
          .payment-projects-body {
            background-color: $cardModalHeaderDark;
            .payment-project-disabled,
            .payment-project-item {
              background-color: $cardModalBodyDark;
              span {
                color: #fff;
              }
            }
          }
        }
      }
      .ag-grid-payments-table {
        .ag-root-wrapper {
          box-shadow: 0px 0px 4px 2px rgba($color: #ffffff, $alpha: 0.16);
          background-color: $cardModalBodyDark;
          border-radius: 10px 10px 0px 0px;
          .ag-header {
            border-radius: 10px 10px 0px 0px;
            .ag-header-cell-text {
              @include tablet {
                font-size: 16px;
              }
            }
            .ag-header-row {
              border-top: none !important;
            }
            .ag-header-cell-resize::after {
              width: 1px !important;
              background-color: #acacca;
            }
          }
          .ag-cell .payment-project-item {
            background-color: transparent;
          }
        }
      }
      .application-footer {
        background-color: $cardModalHeaderDark;
        border-radius: 0px 0px 10px 10px;
        box-shadow: 0px 0px 2px 1px rgba($color: #ffffff, $alpha: 0.16);
        .footer-cont {
          .footer-name,
          .footer-val {
            color: #fff;
          }
        }
      }
      .payment-details-container {
        background-color: $cardModalHeaderDark;
        box-shadow: 0px 0px 4px 2px rgba($color: #ffffff, $alpha: 0.16);
        .ant-input {
          background-color: $cardModalBodyDark !important;
          color: #fff;
          &::placeholder {
            color: #acacca;
          }
        }
        .ant-input-disabled {
          color: #acacca;
        }
        .payment-amounts-container {
          background-color: $cardModalBodyDark;
          .amounts-text {
            color: #fff;
          }
          .payment-amount-label {
            label,
            span {
              color: #fff;
            }
          }
          hr {
            color: #acacca;
          }
        }
      }
    }
    .ant-modal-footer {
      background-color: $cardModalHeaderDark;
      .payment-footer-container {
        background-color: $cardModalHeaderDark;
      }
    }
  }
}
