@import "../../../index.scss";

.pdfExcelDropdown {
  .mondayButtonStyle {
    width: 150px;
  }
  .emailBoxContainer {
    z-index: 999;
    width: 100%;
    height: fit-content;
    position: absolute;
    bottom: 0px;
    right: 90px;
    display: flex;
    align-items: flex-end;
  }

  .position-fixed {
    position: fixed;
  }
}

.export-email-dropdown-darkmode {
  .ant-dropdown-menu,
  .ant-dropdown-menu-submenu,
  .ant-dropdown-menu-submenu-title,
  .ant-dropdown-menu-submenu-arrow-icon {
    background-color: $cardDarkHeaderColor !important;

    .ant-dropdown-menu-submenu-title:hover,
    .ant-dropdown-menu-item:hover {
      background-color: $mainDarkBackgroundColor !important;
    }

    .ant-dropdown-menu-title-content {
      color: #fff;
    }
  }

  .anticon.anticon-right.ant-dropdown-menu-submenu-arrow-icon {
    color: #fff !important;
  }
}
