@use "~ag-grid-enterprise/styles" as ag;
@include ag.grid-styles();
@import "../../../../../index.scss";

.formGridContainer {
  .filtersContainer {
    @include flex(center, flex-start);
    gap: 0px;
  }
  .statusCard {
    width: 125px;
    height: 30px;
    border-radius: 5px;
    opacity: 1;
    background-color: unset;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    letter-spacing: 0;
    text-transform: uppercase;
    padding: 0 10px;
  }
  .ag-theme-custom-react {
    min-height: 120px;
    .smallLoadingDiamonds {
      position: relative;
      top: 0px;
      left: 0px;
    }
    .ag-theme-alpine {
      --ag-row-border-color: transparent;
      --ag-row-hover-color: null;
      --ag-checkbox-checked-color: #2661ad;
      --ag-range-selection-border-color: transparent;
      --ag-range-selection-background-color: #03305633;
      --ag-header-height: 100px;
    }

    .ag-body-horizontal-scroll {
      min-height: 5px !important;
    }

    .ag-header-cell-label {
      justify-content: space-between;
    }

    .ag-header-cell-text {
      @include text(16px, 600, 0.21px, #383554);
    }
    .ag-header-cell {
      background-color: #fff;
    }
    .ag-root-wrapper {
      border: 0;
      overflow: auto;
    }
    .ag-menu-option-active {
      background-color: #2661ad;
      color: white;
    }
    .ag-row .ag-cell {
      border-right: 0 !important;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
      overflow: hidden;
    }

    .ag-pivot-mode-panel {
      height: fit-content;
      padding: 5px;
    }
    .ag-column-select-header {
      height: fit-content;
      padding: 5px;
    }
    .ag-row-selected {
      background-color: #d1eaff94;
    }
  }
  .clearFiltersBtn {
    border-radius: 5px;
  }
}
