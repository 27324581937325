.planned-trips-view {
  height: calc(100vh - 56px);
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 10px;

  .ant-switch-checked {
    background: #68c142 !important;
  }

  .writeReadSwitchContainer {
    background-color: #1264a3;
    width: 140px;
    height: 23px;
    font-size: 13px;

    .ant-switch-handle {
      padding: 2px;
      background-color: white;
      border-radius: 9px;
    }

    .ant-switch-inner-checked {
      font-size: 13px !important;
    }
    .ant-switch-inner-unchecked {
      font-size: 13px !important;
    }
  }

  .live-grid-status {
    width: 100%;
    height: 93%;
    max-width: 200px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 550;
    letter-spacing: 0.28px;
    border-radius: 5px;
  }

  .live-trips-grid {
    height: 100%;
    width: 100%;

    .ag-root-wrapper-body.ag-layout-normal {
      height: 100% !important;
      min-height: 100% !important;
      overflow-y: unset !important;
    }

    .ag-root-wrapper {
      height: 100%;
      width: 100%;
      max-width: 100%;

      .ag-root-wrapper-body {
        height: 100% !important;
      }

      .ag-header {
        position: sticky;
        top: 0;

        .ag-header-cell {
          background-color: #f8f8fa !important;
        }
      }
    }
  }
}

.planned-trips-view.planned-view-dark {
  background-color: #12131b;

  .writeReadSwitchContainer {
    background-color: #292b3a;
    width: 140px;
    height: 23px;
  }

  .live-trips-grid {
    .ag-root-wrapper {
      background-color: #20212d;
      border-radius: 10px;
      .ag-column-drop {
        background-color: #20212d;
      }
      .ag-header {
        .ag-header-cell {
          background-color: #12131b !important;
        }
        .ag-header-cell-resize::after {
          width: 1px !important;
          background-color: #4a4a4a !important;
        }
      }
    }
    .ag-paging-panel {
      background-color: #20212d;
    }
  }
}
