$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.payroll-activity-modal-wrap {
  overflow: hidden;
}

.payroll-activity-modal-container {
  width: fit-content !important;
  .ant-modal-content {
    width: calc(100vw - 20px);
    width: calc(100dvw - 20px);
    max-width: 1020px;
    height: calc(100vh - 20px);
    height: calc(100dvh - 20px);
    max-height: 900px;
    .ant-modal-header {
      border-radius: 10px 10px 0 0;
      background-color: #f5f5f7;
      display: flex;
      align-items: center;
      height: 45px;

      .ant-modal-title {
        color: #323338;
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        width: 100%;
      }
    }
  }

  .ant-modal-close {
    height: 45px;
    aspect-ratio: 1;
  }

  .ant-modal-body {
    width: 100%;
    height: calc(100% - 120px);
    overflow-y: auto;
    .ant-form {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      gap: 1rem;
      .labeledInputContainer {
        min-width: 250px;
        .ant-form-item {
          margin-bottom: 5px;
          .ant-picker {
            width: 100% !important;
          }
        }
      }
      .positionInfo {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 10px;
      }
      .distanceContainer {
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }
    .geofenceContainer {
      .app-map-geofence {
        height: 350px;
      }
    }
    .modalInfo {
      height: 350px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px dashed #1264a3;
      font-size: 20px;
      color: gray;
    }
  }

  .ant-modal-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    border-radius: 10px;
    button {
      width: 150px;
    }
  }
}

.payroll-activity-modal-container-dark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      .labeledInputLabel {
        color: #fff;
      }
      .distanceContainer {
        color: #fff;
      }
      .ant-input-affix-wrapper {
        background-color: $inputModalDark !important;
        .ant-input-prefix svg {
          fill: #fff;
        }
        .ant-input {
          background-color: $inputModalDark !important;
          color: #fff;
          &::placeholder {
            color: #acacca;
          }
        }
      }
      .ant-picker {
        background-color: $inputModalDark !important;
        .ant-picker-input {
          background-color: $inputModalDark;
          input {
            color: #f2f2f2;
            &::placeholder {
              color: #acacca;
            }
          }
          .ant-picker-suffix svg {
            color: #acacca;
          }
          .ant-picker-clear {
            background-color: $inputModalDark;
            svg {
              fill: #acacca;
            }
          }
        }
      }
      .ant-picker-disabled {
        .ant-picker-input input {
          color: #acacca !important;
        }
      }
      .ant-select {
        .ant-select-selector {
          background-color: $inputModalDark !important;
          .ant-select-selection-overflow-item {
            .ant-select-selection-item {
              background-color: #323338;
              .ant-select-selection-item-remove svg {
                fill: #fff;
              }
            }
          }
          .ant-select-selection-search-input {
            color: #fff;
          }
          .ant-select-selection-item {
            color: #fff;
          }
          .ant-select-selection-placeholder {
            color: #acacca;
          }
        }
        .ant-select-clear {
          background-color: $inputModalDark;
          svg {
            fill: #acacca;
          }
        }
        .ant-select-arrow svg {
          fill: #fff;
        }
      }
      .ant-select-disabled {
        .ant-select-selector {
          .ant-select-selection-search-input {
            color: #acacca !important;
          }
          .ant-select-selection-item {
            color: #acacca !important;
          }
        }
      }
      .ant-input {
        background-color: $inputModalDark !important;
        color: #fff;
        &::placeholder {
          color: #acacca;
        }
      }
      .ant-input-disabled {
        color: #acacca;
      }
      .ant-checkbox-wrapper span {
        color: #fff;
      }
    }
  }
}

@media (max-width: 690px) {
  .payroll-activity-modal-container {
    .ant-modal-content {
      .ant-modal-footer {
        button {
          width: auto !important;
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .payroll-activity-modal-container {
    .ant-modal-content {
      .ant-modal-footer {
        .mondayButtonText {
          display: none !important;
        }
        .mondayButtonDivider {
          display: none !important;
        }
        button {
          width: auto !important;
        }
      }
    }
  }
}

@media (max-width: 450px) {
  .payroll-activity-modal-container {
    .ant-modal-content {
      .ant-modal-body {
        .labeledInputContainer {
          width: 100%;
          .ant-picker {
            width: 100%;
          }
        }
      }
    }
  }
}
