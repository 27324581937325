.panelHeaderInputContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  margin-right: 10px;

  .inputAmount::-webkit-outer-spin-button,
  .inputAmount::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .inputAmount {
    height: 32px;
    margin: 0;
    width: 120px;
    margin-left: 5px;
    background-color: rgb(231, 231, 231);

    &:disabled {
      cursor: not-allowed;
    }
  }

  .imputableAmount {
    width: 90px;
    margin-right: 5px;
    margin-left: 5px;
    font-weight: 600;
  }

  .labelInput {
    color: white;
    margin-left: 20px;
  }

  .addPanelHeaderButton {
    border: 0;
    border-radius: 5px;
    height: 30px;
    width: 30px;
    background-color: #71cf48;
    color: white;
    border: 1px solid #71cf48;
    margin-left: 5px;

    svg {
      width: 14px;
      height: 14px;
      margin: 0 !important;
      padding: 0 !important;
    }

    &:disabled {
      background-color: #b4b4b4;
      color: white;
      border-color: #b4b4b4;
      cursor: not-allowed;

      &:hover {
        background-color: #b4b4b4;
        color: white;
      }
    }

    &:hover {
      background-color: white;
      svg {
        fill: #71cf48 !important;
      }
    }
  }
}
//#71cf48
