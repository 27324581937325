$headerDarkBackground: #12131b;
$bodyDarkBackground: #1f212d;
$inputDarkColor: #12131b;

.updatedPassword {
  .ant-modal-content {
    border-radius: 10px;
    .ant-modal-close {
      height: 45px;
      width: 45px;
      .ant-modal-close-x {
        height: 45px;
        width: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          fill: #323338;
        }
      }
    }
    .ant-modal-header {
      height: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #f7f8fa 0% 0% no-repeat padding-box;
      opacity: 1;
      border-radius: 10px 10px 0px 0px;
      border: none;
      .ant-modal-title {
        width: 100%;
        height: 22px;
        font: normal normal 600 16px/22px Open Sans;
        letter-spacing: 0px;
        color: #323338;
        opacity: 1;
      }
    }
    .ant-modal-body {
      padding: 20px;
      .modal-inputs {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-bottom: 30px;

        .input-info {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 10px;
        }

        label {
          margin: 0 0 0 10px;
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
          color: #323338;
        }

        span {
          border-radius: 5px;
          border: none;
        }
        .ant-input-affix-wrapper {
          background: #f5f5f7;
          border-radius: 5px;
          border: none;
          height: 30px;
          padding: 4px 11px;
        }

        .ant-input {
          background: #f5f5f7;
          border-radius: 5px;
          border: none;
        }

        .ant-input-suffix {
          padding-bottom: 2px;
          svg {
            path {
              fill: #323338;
            }
          }
        }
      }
    }
    .ant-modal-footer {
      border: none;
      padding: 10px 20px 20px 20px;
      border-radius: 0px 0px 10px 10px;
      .actionButtons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }
}

.updatedPasswordDark {
  .ant-modal-content {
    background-color: $bodyDarkBackground;
    .ant-modal-close {
      .ant-modal-close-x {
        svg {
          fill: #fff;
        }
      }
    }
    .ant-modal-header {
      background-color: $headerDarkBackground;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      background-color: $bodyDarkBackground;
      .inputLabel {
        color: #fff !important;
      }
      .ant-input {
        background: $inputDarkColor !important;
        color: #fff;
        &::placeholder {
          color: #acacca;
        }
      }
      .ant-input-affix-wrapper {
        background: $inputDarkColor !important;
        .ant-input {
          background: $inputDarkColor !important;
          color: #fff;
          &::placeholder {
            color: #acacca;
          }
        }
        .ant-input-suffix {
          .ant-icon {
            svg {
              fill: #fff !important;
            }
          }
        }
      }
    }
    .ant-modal-footer {
      background-color: $bodyDarkBackground;
    }
  }
}
