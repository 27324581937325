@import "/src/index.scss";

.editCrewModal {
  height: calc(100vh - 20px) !important;
  height: calc(100dvh - 20px) !important;
  width: calc(100vw - 20px) !important;
  width: calc(100dvw - 20px) !important;
  max-height: 720px;
  max-width: 942px;
  width: fit-content !important;
  overflow: auto;
  overflow-y: auto;
  .ant-modal-content {
    height: calc(100vh - 20px) !important;
    height: calc(100dvh - 20px) !important;
    width: calc(100vw - 20px) !important;
    width: calc(100dvw - 20px) !important;
    max-height: 720px;
    max-width: 942px;
    .ant-modal-close {
      height: 45px;
      width: 45px;
      .ant-modal-close-x {
        height: 45px;
        width: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .ant-modal-header {
      height: 45px;
      display: flex;
      align-items: center;
      background-color: #f4f5f6;
      border: none;
      .ant-modal-title {
        font-size: 16px;
        font-weight: 600;
        width: 100%;
      }
    }
    .ant-modal-body {
      height: calc(100% - 100px);
      overflow-y: auto;
      overflow-x: hidden;
      .updateCrewInfo {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        width: 902px;
        height: 20px;
        margin-bottom: 20px;
        .updateCrewText {
          width: 872px;
          height: 19px;
          font-size: 14px;
          font-weight: 400;
        }
      }
      .ant-form {
        display: flex;
        flex-direction: column;
        gap: 30px;
        .ant-form-item {
          margin-bottom: 0px;
        }
        .fingerCheckFetch {
          display: flex;
          align-items: flex-end;
          justify-content: flex-start;
          width: 685px;
          height: 53px;
          gap: 30px;
          .employeeLatestRate,
          .updateEmployeeData {
            &:hover {
              path {
                fill: #1264a3;
              }
            }
          }
          .editCrewSubcontractor {
            width: 300px;
            .labeledInputLabel {
              font-size: 14px;
            }
          }
          .employeeNumberTextDiv {
            display: flex;
            align-items: center;
            height: 32px;
            gap: 30px;
            .employeeLatestRate {
              display: flex;
              align-items: center;
              gap: 5px;
              .employeeNumberText {
                font-size: 14px;
                font-weight: 400;
                height: 19px;
              }
            }
            .updateEmployeeData {
              display: flex;
              align-items: center;
              gap: 5px;
            }
          }
        }
        .formSection {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 810px;
          height: 53px;
          gap: 30px;
          .labeledInputContainer {
            .labeledInputLabel {
              font-size: 14px;
              font-weight: 600;
            }
          }
          .crewNameUpdate {
            width: 300px;
          }
          .crewPositionSelect {
            width: 300px;
          }
        }
        .firstFormSection {
          display: flex;
          width: 840px;
          height: 53px;
          gap: 30px;
          .labeledInputContainer {
            .labeledInputLabel {
              font-size: 14px;
              font-weight: 600;
            }
          }
          .employeeIdEdit {
            width: 300px;
          }
        }
      }
    }
    .ant-modal-footer {
      .footerButtons {
        display: flex;
        justify-content: space-between;

        .mondayButtonStyle {
          width: 150px;
        }
      }
    }
    .updateCrewFooter {
      display: flex;
      gap: 10px;
      .crewLogsDiv {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        background-color: #1264a3;
        border-radius: 5px;
        .crewLogsIcon {
          width: 18px;
          height: 18px;
          fill: #fff;
        }
      }
    }
  }
}

.editCrewModalDark {
  .ant-modal-content {
    background-color: $mainDarkBackgroundColor;
    .ant-modal-close-x {
      svg {
        fill: #fff !important;
        path {
          fill: #fff !important;
        }
      }
    }
    .ant-modal-header {
      background-color: $cardDarkBackgroundColor;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      background-color: $mainDarkBackgroundColor;
      border-radius: 0px 0px 10px 10px;
      .updateCrewText {
        color: #fff;
      }
      .ant-form {
        .fingerCheckFetch {
          .employeeNumberText {
            color: #fff;
            &:hover {
              color: #1264a3;
            }
          }
          .employeeLatestRate,
          .updateEmployeeData {
            color: #fff;

            path {
              fill: #fff;
            }
          }
        }
        .editCrewDiv {
          label {
            color: #fff;
          }
        }
        .editCrewLabel {
          label {
            color: #fff;
          }
        }
        .labeledInputLabel {
          color: #fff;
        }
        .ant-input {
          background-color: $cardDarkBackgroundColor !important;
          color: #fff;
          &::placeholder {
            color: #acacca;
          }
        }
        .ant-input-disabled {
          color: #acacca;
        }
        .ant-input-affix-wrapper {
          background-color: $cardDarkBackgroundColor !important;
        }
        .ant-input-wrapper {
          background-color: $cardDarkBackgroundColor !important;
          .ant-input-group-addon {
            background-color: $cardDarkBackgroundColor !important;
            color: #fff;
          }
        }
        .ant-select {
          .ant-select-selector {
            background-color: $cardDarkBackgroundColor !important;
            .ant-select-selection-search-input {
              color: #fff;
            }
            .ant-select-selection-item {
              color: #fff;
            }
            .ant-select-selection-placeholder {
              color: #acacca;
            }
          }
          .ant-select-clear {
            background-color: $cardDarkBackgroundColor;
            svg {
              fill: #acacca;
            }
          }
          .ant-select-arrow svg {
            fill: #fff;
          }
        }
        .ant-select-disabled {
          .ant-select-selector {
            .ant-select-selection-search-input {
              color: #acacca !important;
            }
            .ant-select-selection-item {
              color: #acacca !important;
            }
          }
        }
      }
      .ant-checkbox-wrapper {
        color: #fff;
      }
    }
    .ant-modal-footer {
      background-color: $mainDarkBackgroundColor;
    }
  }
  .secondaryFooterButton {
    background-color: #1264a3;
  }
}

@media (max-width: 600px) {
  .editCrewModal {
    max-height: fit-content;
    .ant-modal-content {
      max-height: fit-content;
      .ant-modal-body {
        .ant-form {
          gap: 10px;
          .fingerCheckFetch {
            display: grid;
            grid-template-columns: 50% 50%;
            gap: 10px;
            .mondayButtonStyle {
              width: 80%;
            }
          }
          .formSection {
            flex-direction: column;
            gap: 10px;
            .labeledInputContainer {
              width: 100%;
            }
          }
        }
      }
      .ant-modal-footer {
        gap: 10px;
        .mondayButtonStyle {
          width: 100px;
        }
      }
    }
  }
}
