.rangePickerContainer {
  overflow: hidden;
  height: 100%;
  margin-left: 10px;
  width: 100%;
  padding-right: 2px;
  border-radius: 10px;
  margin: 11px 0 0 10px !important;
  position: relative;
  // background: yellowgreen;
  display: flex;
  &:has(> :last-child:nth-child(1)) {
    justify-content: center;
  }

  .rangeInformation {
    width: 280px;
    height: 268px;
    margin: 1px 0;
    margin-left: 290px;
    border-radius: 10px;
    box-shadow: 0px 1px 4px #00000029;
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    .applicationInformation {
      font-size: 17px;
      font-weight: bold;
    }

    .period {
      margin-top: 10px;
      font-size: 16px;
    }

    .dueDateContainer {
      margin-top: 15px;
      padding: 0 10px;

      .dueDateLabel {
        display: flex;
        justify-content: center;
      }
    }
  }

  .dateInputStyle {
    // margin-top: -36px;
    // position: absolute;
  }

  .monthPickerNewApplications {
    // margin-top: -36px;
    // position: absolute;
  }
}

.ant-picker-panel-container {
  transition: background-color 0.05s ease-in-out;
  @if show {
    background-color: #fff;
  } @else if hide {
    background-color: #4d4d4d09;
  }
}

.show {
  .ant-picker-panel-container {
    background-color: none !important;
  }
}
.hide {
  .ant-picker-panel-container {
    background-color: #4d4d4d04 !important;
    filter: blur(5px);
  }
}

.newApplicationCalendarStyle {
  transition: height 0s ease !important;
  border-radius: 10px;
  overflow: hidden;
  padding: 0px !important;
  box-shadow: 0px 1px 4px #00000029;
  z-index: 1050 !important;

  .ant-picker-panel {
    border: 0px solid #f0f0f0;
  }

  .ant-picker-range-arrow {
    display: none;
  }
  .ant-picker-header {
    border: 0;
  }

  .ant-picker-header-view {
    text-align: center;
    font: normal normal 600 22px/30px Open Sans;
    letter-spacing: 0px;
    color: #323338;

    .ant-picker-header-super-prev-btn {
      display: none;
    }

    .ant-picker-header-super-next-btn {
      display: none;
    }
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(
      .ant-picker-cell-range-start-single
    ):not(.ant-picker-cell-range-end)
    .ant-picker-cell-inner {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    background-color: #1264a3;
    font-weight: bold;
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(
      .ant-picker-cell-range-end-single
    ):not(.ant-picker-cell-range-start)
    .ant-picker-cell-inner {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    background-color: #1264a3;
    font-weight: bold;
  }

  .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
    background-color: #1264a3;
    color: #fff !important;
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(
      .ant-picker-cell-range-start-single
    )::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(
      .ant-picker-cell-range-end-single
    )::before {
    background: #1264a3;
  }

  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    background-color: #1264a3;
  }

  .ant-picker-cell-in-view.ant-picker-cell-in-range {
    .ant-picker-cell-inner {
      color: white;
    }
  }
}
