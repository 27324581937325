@import "../../../../../../index.scss";

$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.assigneesToggleModal {
  .ant-modal-body {
    .assigneesToggleModalContent {
      @include flex(flex-end, center, column);
    }
  }
}

.assigneesToggleModalDark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close .ant-modal-close-x svg {
      fill: #f2f2f2;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #f2f2f2;
      }
    }
    .ant-modal-body {
      .tox {
        border: none;
        .tox-menubar {
          background-color: $cardModalBodyDark;
          .tox-mbtn__select-label {
            color: #fff;
          }
        }
        .tox-toolbar-overlord {
          background-color: $cardModalBodyDark !important;
          .tox-toolbar__primary {
            background-color: $cardModalBodyDark !important;
          }
          .tox-toolbar__group {
            background-color: $cardModalBodyDark !important;
            border: none;
            .tox-split-button:hover {
              border: none;
            }
            .tox-tbtn {
              svg {
                fill: #fff;
              }
              &:focus {
                background-color: $cardModalHeaderDark;
              }
              &:hover {
                background-color: $cardModalHeaderDark;
              }
            }
            .tox-tbtn__select-label {
              color: #fff !important;
            }
          }
        }
      }
    }
  }
}
