@import "../../../../../.././Communication.scss";

.drive-items-dropdown-overlay {

  .ant-dropdown-menu {
    max-height: 300px;
    max-width: 200px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .dropdown-item-button {
    span {
      color: $light-text-color;
      max-width: 150px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: start;
    }

    &:hover {
      span {
        color: inherit;
      }
    }
  }

}

.drive-icon {
  fill: $icon-color;
  color: $icon-color;
}

.drive-items-loading-container {
  position: relative;

  .loading-badge {
    position: absolute;
    right: -1px;
    top: -10px;
  }
}