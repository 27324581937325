.employee-history-marker {
  animation: bounceAnimation 1.5s ease infinite;
  
}
.history-punch-type {
  position: absolute;
  left: 30%;
  bottom: -20px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
}

@keyframes bounceAnimation {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-8px);
  }
  100% {
    transform: translateY(0);
  }
}
