@import "../../../../../../../../../../../../index.scss";

.changeRetenagePercentageApplicationModalLight {
  border-radius: 10px;
  overflow: hidden;

  .ant-modal-content {
    border-radius: 10px;
    overflow: hidden;
    background: #fff;
  }

  .changeRetainageInput {
    border-radius: 5px;
    border: 1px solid #333238;
    background-color: red !important;
  }
  .ant-modal-close {
    height: 45px;
    .ant-modal-close-x {
      height: 100%;
      width: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        fill: #333238;
        height: 15px;
        width: 15px;
      }
    }
  }

  .ant-modal-header {
    height: 45px;
    display: flex;
    align-items: center;
    background-color: #f8f8fa;
    border: none;
    .ant-modal-title {
      color: #333238;
      font-size: 16px;
      font-weight: 600;
    }
  }

  .ant-modal-footer {
    height: 45px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top: 0;
    .ant-btn-primary {
      background: #1679d4;
      border-color: #1679d4;
    }

    .serviceFooterRetainageModal {
      display: flex;
      justify-content: flex-end;
      margin-top: -10px;
      .cancelMondayButton {
        margin-right: 10px;
        @include mondayButton(#fe4c4a, #eb4345, #fff);
      }
      .applyMondayButton {
        @include mondayButton(#71cf48, #68c142, #fff);
        svg {
          height: 20px;
          width: 25px;
          margin-left: -5px;
          margin-right: -5px;
        }
      }
    }
  }
}

.changeRetenagePercentageApplicationModalDark {
  border-radius: 10px;
  // overflow: hidden;

  .ant-modal-content {
    border-radius: 10px;
    overflow: hidden;
    .ant-modal-body {
      .change-ret-actions {
        .service-ret-container {
          .left-side {
            .ant-input-number {
              .ant-input-number-input-wrap {
                input {
                  background-color: white !important;
                  // color: #fff;
                  color: black !important;
                  border-radius: 5px;
                }
              }
            }
          }
        }
      }
    }
  }

  .changeRetainageInput {
    border-radius: 5px;
    border: 1px solid black;
    background: white;
    background-color: red !important;
    &:disabled {
      background: #d9d9d9;
    }
  }
  .input.ant-input-number-input {
    background-color: red !important;
  }
  .ant-modal-close {
    .ant-modal-close-x {
      color: #fff;
      font-size: 1.25rem;
      margin-top: -5px;
    }
  }

  .ant-modal-header {
    height: 45px;
    background-color: #12131b;
    border-bottom-color: #12131b;
    .ant-modal-title {
      color: #fff;
      font-size: 16px;
      letter-spacing: 2px;
      margin-top: -5px;
    }
  }

  .ant-modal-body {
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: baseline;
    width: 100%;
    background: #1f212d;
  }

  .ant-modal-footer {
    height: 55px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top: 0;
    background: #1f212d;
    .ant-btn-primary {
      background: #1679d4;
      border-color: #1679d4;
    }

    .serviceFooterRetainageModal {
      display: flex;
      justify-content: flex-end;
      margin-top: -10px;
      .cancelMondayButton {
        margin-right: 10px;
        @include mondayButton(#fe4c4a, #eb4345, #fff);
      }
      .applyMondayButton {
        @include mondayButton(#71cf48, #68c142, #fff);
        svg {
          height: 20px;
          width: 25px;
          margin-left: -5px;
          margin-right: -5px;
        }
      }
    }
  }
}

.changePercentageApplicationContainer {
  width: fit-content !important;
  .changeRetainageInput {
    @include mondayButton(#1264a3, #0f5c97, #fff);

    &:disabled {
      @include mondayButton(#0b3e64, #0e5185, #548bb3);

      svg {
        fill: #548bb3;
      }
    }
  }
}
