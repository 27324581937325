.dispatch-itinerary-container.ant-modal {
  height: fit-content !important;
  width: fit-content !important;

  button {
    &:disabled {
      color: rgba(0, 0, 0, 0.25) !important;
      background-color: rgba(0, 0, 0, 0.04) !important;
      .mondayButtonDivider {
        background-color: transparent !important;
      }
    }
  }

  .ant-modal-content {
    width: calc(100vw - 20px) !important;
    height: calc(100vh - 20px) !important;
    max-height: 900px !important;
    max-width: 1400px;

    .ant-modal-header {
      height: 50px;
      display: flex;
      align-items: center;
      background-color: #f5f5f7;
      .ant-modal-title {
        font-weight: 600;
        font-size: 16px;
        color: #333238;
      }
    }

    .ant-modal-close {
      height: 50px;
      width: 50px;
      .ant-modal-close-x {
        height: 50px;
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          fill: #333238;
        }
      }
    }

    .ant-modal-body {
      height: calc(100% - 102px);
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 2fr;
      grid-template-rows: 100%;
      padding: 20px 20px 10px 20px;

      .itinerary-list-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 100%;
        width: 100%;
        overflow-y: auto;
        flex-direction: column;
        gap: 25px;
        padding: 3px 10px 5px 5px;

        .itinerary-step-container {
          position: relative;
          display: grid;
          height: fit-content;
          width: 100%;
          gap: 10px;
          place-items: baseline;
          box-shadow: 0 0 3px 1px rgba($color: #000000, $alpha: 0.1);
          padding: 10px;
          box-sizing: border-box;
          border-radius: 5px;
          grid-template-areas:
            "travel-mode travel-mode"
            "pick-up pick-up"
            "drop-off drop-off"
            "depart arrive"
            "footer footer";

          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: repeat(5, auto);

          .remove-step {
            position: absolute;
            top: 10px;
            right: 15px;
            cursor: pointer;
          }

          .inner-itinerary-route {
            width: 100%;
          }

          .travel-modes-container {
            grid-area: travel-mode;

            .ant-radio-button-wrapper {
              height: fit-content;
            }

            .ant-card-meta-avatar {
              display: grid;
              place-items: center;
              margin-top: 4px;
            }

            .ant-card-meta-title {
              font-size: 12px;
              line-height: 20px;
            }

            .ant-radio-button-wrapper:where(
                :not(.ant-radio-button-wrapper-checked)
              ) {
              border-color: #f5f5f7;
              color: #323338;
              background: #f5f5f7;

              svg {
                fill: #323338;
              }
            }

            .ant-radio-button-wrapper-checked {
              border-color: #1264a3;
              color: #fff;
              background: #1264a3;

              &::before {
                background-color: #1264a3;
              }

              svg {
                fill: white;
              }
            }
          }

          .route-input {
            grid-area: pick-up;
          }

          .route-autocomplete {
            grid-area: drop-off;
          }

          .itinerary-depart {
            grid-area: depart;
          }

          .itinerary-arrive {
            grid-area: arrive;
          }

          .ant-picker {
            width: 100%;
          }

          .inner-step-footer-container {
            grid-area: footer;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: row;
            height: auto;
            width: 100%;
            font-size: 16px;
            font-weight: 600;

            .info-text {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              flex-direction: row;
              flex-wrap: nowrap;
              gap: 10px;

              .info-text-title {
                color: #fe4c4a;
              }
            }
          }
        }
      }

      .custom-label {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
        flex-wrap: nowrap;
        white-space: nowrap;

        .required-mark {
          color: red;
        }

        .label-red {
          color: #fe4c4a;
        }
      }

      .map-container {
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: auto 1fr;
      }

      .map-controller {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        padding: 0 10px 0 10px;
        gap: 10px;

        .ant-select-auto-complete {
          width: 300px;
          max-width: 100%;
        }
      }

      .itinerary-map {
        height: 100%;
        width: 100%;
      }

      #itinerary-map {
        .info__location {
          color: #323338;
          font-weight: 450;
          font-size: 14px;
          position: relative;
          padding-left: 10px;
        }

        .info__location.info_origin {
          &::before {
            content: "";
            height: 6px;
            aspect-ratio: 1;
            border-radius: 50%;
            background-color: #00a464;
            position: absolute;
            top: 5px;
            left: 0;
          }
        }

        .info__location.info_destination {
          &::before {
            content: "";
            height: 6px;
            aspect-ratio: 1;
            border-radius: 50%;
            background-color: #f04f4e;
            position: absolute;
            top: 5px;
            left: 0;
          }
        }

        .gm-style-iw.gm-style-iw-c {
          // top: -18px;
          padding: 0 !important;
          .gm-style-iw-d {
            padding: 10px;
            overflow: auto !important;
          }
          button {
            display: none !important;
          }
        }

        .gm-style-iw-ch {
          display: none;
        }

        .gm-style-iw-tc {
          // top: -20px;
          width: 18px;
          &::after {
            width: 18px;
          }
        }
      }
    }

    .ant-modal-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      flex-wrap: nowrap;
      gap: 10px;
    }
  }
}

.dispatch-itinerary-container.dispatch-itinerary-dark {
  color: #ffffff;
  .ant-modal-content {
    // background-color: #2a2b3a;
    background-color: #20212d;
    color: #ffffff;

    .ant-modal-header {
      background-color: #12131b;
      .ant-modal-title {
        color: #ffffff;
      }
    }

    .ant-modal-close {
      .ant-modal-close-x {
        svg {
          fill: #ffffff;
        }
      }
    }

    .ant-modal-body {
      .info-text-value {
        color: #fff;
      }

      .remove-step {
        svg {
          fill: #ffffff;
        }
      }

      .itinerary-list-container {
        .itinerary-step-container {
          background-color: #2a2b3a;
          box-shadow: 0 0 3px 1px rgba($color: #fff, $alpha: 0.1);
          .travel-modes-container {
            .ant-radio-button-wrapper:where(
                :not(.ant-radio-button-wrapper-checked)
              ) {
              background: #3a3c4e;
              border-color: #3a3c4e;
              color: #acacac;

              svg {
                fill: #acacac;
              }
            }

            .ant-radio-button-wrapper-checked {
              border-color: #123754;
              color: #fff;
              background: #123754;

              &::before {
                background-color: #616481;
              }
            }
          }
        }
      }
    }

    .labeledInputLabel {
      color: #fff;
    }

    .ant-select {
      .ant-select-selector {
        background-color: #3a3c4e !important;
        color: #f5f5f7;
        border: 0;
      }

      .ant-select-selection-placeholder {
        color: #f5f5f7a9;
      }

      .ant-select-clear {
        background-color: #3a3c4e;
        svg {
          fill: #acacca;
        }
      }

      .anticon {
        color: #fff;
        background-color: #3a3c4e;
      }
    }

    .ant-select-arrow {
      svg {
        fill: #f5f5f7 !important;
      }
    }

    .ant-select-selection-placeholder {
      color: #acacca;
    }

    input::placeholder {
      color: #acacca;
    }

    .ant-picker {
      background-color: #3a3c4e !important;
      color: #f5f5f7 !important;
      border: 0;

      .anticon {
        color: #fff;
        background-color: #3a3c4e;
      }
      .ant-picker-input {
        input {
          color: #f5f5f7 !important;
        }
      }
    }
  }
}
