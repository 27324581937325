$mainDarkBackgroundColor: #20212d;
$cardDarkHeaderColor: #2a2b3a;
$cardDarkBackgroundColor: #12131b;
$inputDarkColor: #2a2b3a;

.list-search-content {
  background-color: #f8f8fa;
  border-radius: 5px;
  height: 100%;

  .labeledInputContainer {
    padding: 10px;

    .ant-input-affix-wrapper {
      background-color: #fff !important;
      align-items: center;

      .ant-input-prefix {
        svg {
          width: 16px;
          height: 16px;
        }
      }

      .ant-input {
        background-color: #fff !important;
      }
    }
  }

  .list-search-body {
    display: flex;
    padding: 0px 10px 10px 10px;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    border-radius: 5px;
    max-height: calc(100% - 52px);
    width: 100%;

    .list-search-item {
      font-size: 14px;
      background-color: #ffffff;
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      height: 40px;
      border-bottom: 1px solid #dee2eb;
      color: #323238;
      font-family: "Open Sans", sans-serif;
      font-weight: 400;
      padding: 10px;

      &:hover {
        cursor: pointer;
        background-color: #f1f5fd;
        color: #1264a3;
        font-weight: 600;
      }
    }
    .list-search-item.list-search-item-active {
      background-color: #f1f5fd;

      &:hover {
        background-color: #f1f5fd;
        color: #1264a3;
      }
    }
  }
}

.list-search-content-dark {
  background-color: darken($mainDarkBackgroundColor, 5%);

  .labeledInputContainer {
    .ant-input-affix-wrapper {
      background-color: $inputDarkColor !important;

      .ant-input {
        background-color: $inputDarkColor !important;
      }
    }
  }

  .list-search-body {
    // background-color: darken($cardDarkBackgroundColor, 10%);

    .list-search-item {
      background-color: $inputDarkColor;
      color: #ffffff;

      &:hover {
        background-color: lighten($inputDarkColor, 10%) !important;
        color: #ffffff;
      }
    }

    .list-search-item.list-search-item-active {
      background-color: lighten($inputDarkColor, 10%) !important;

      &:hover {
        background-color: lighten($inputDarkColor, 10%) !important;
        color: #ffffff;
      }
    }
  }
}
