@use "/src/index.scss" as index;

.card-component {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 1px 4px 0px #00000026;
  background-color: #fff;
  transition: 0.2s ease-in;
  .card-header {
    height: 45px;
    display: flex;
    align-items: center;
    padding: 0px 20px;
    font-size: 16px;
    font-weight: 600;
    background-color: #f8f8fa;
    border-radius: 10px 10px 0px 0px;
    transition: 0.2s ease-in;
  }
  .card-body {
    height: calc(100% - 45px);
    width: 100%;
    padding: 20px;
  }
}

.card-component-dark {
  box-shadow: 0px 1px 4px 0px #ffffff26;
  background-color: index.$cardDarkHeaderColor;
  .card-header {
    background-color: index.$cardDarkBackgroundColor;
    color: #fff;
  }
}
