@import "../../../../../../index.scss";

.resetContinueWatching-modal {
  .ant-modal-content {
    .ant-modal-header {
      background-color: #f7f8fa;
    }

    .ant-modal-body {
      .videos-container {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .buttons {
          display: flex;
          justify-content: start;
          align-items: flex-start;
          gap: 20px;

          .search {
            flex-grow: 1;
            width: 210px;
            padding: 0;

            .ant-form-item {
              margin: 0;
            }
          }

          .select-button {
            padding-top: 4px;
            font-size: 14px;
            transition: color 0.2s;
            cursor: pointer;

            &.deselect {
              &:hover {
                color: #fe4c4a;
              }
            }

            &.select {
              &:hover {
                color: #1264a3;
              }
            }
          }
        }

        .videos {
          max-height: 300px;
          display: flex;
          flex-direction: column;
          gap: 10px;
          overflow-y: auto;

          .video-container {
            display: flex;
            flex-direction: row;
            gap: 10px;

            .video {
              flex-grow: 1;
              display: flex;
              flex-direction: row;
              gap: 5px;
              padding: 4px;
              border-radius: 5px;
              background: #f8f8fa;
              align-items: center;

              .play-icon-container {
                height: 40px;
                width: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 25px;
                color: #fff;
                background-color: #a87878;
                border-radius: 5px;
                padding: 5px;
              }

              .new-video-card-category-info {
                display: flex;
                flex-direction: column;
                gap: 10px;
                padding: 5px;
                flex: 1;
              }

              .card-text-info {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 380px;
              }
            }
          }
        }

        .footer {
          margin-top: 10px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .mondayButtonRed {
            svg {
              path {
                fill: #fff !important;
              }
            }
          }
        }
      }
    }
  }
}

.resetContinueWatching-modalDark {
  .ant-modal-content {
    .ant-modal-close .ant-modal-close-x svg {
      fill: #f2f2f2;
    }

    .ant-modal-header {
      background-color: $cardDarkBackgroundColor;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      background-color: $cardDarkHeaderColor;

      .videos-container {
        .buttons {
          .select-button {
            color: #fff;
          }
        }

        .videos {
          .video-container {
            .videoCheckbox {
              .ant-checkbox-inner {
                background-color: $cardDarkBackgroundColor;
                border: none;
              }
            }

            .video {
              background-color: $cardDarkBackgroundColor;

              .card-text-info,
              .video-length {
                color: #fff;
              }
            }
          }
        }
      }
    }
    .ant-modal-footer {
      background-color: $cardDarkHeaderColor;
    }
  }
}
