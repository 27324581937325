@import "../../../../../index.scss";
$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
.borderedTextCard {
  position: relative;
  @include flex(flex-start, flex-start, column);
  border-top: 1.5px solid black;
  // border-radius: 10px;
  margin-bottom: 25px;
  min-width: 100%;
  padding: 15px 0px 0px 0px;
  align-items: center;
  .cardTitle {
    position: absolute;
    top: -13px;
    @include text(14px, 600, 0, black);
    font-family: "Open Sans";
    background-color: #fff;
    // z-index: 10;
    padding: 0 5px;
  }
  .childrenContainer {
    @include flex(baseline, flex-start);
    flex-wrap: wrap;
    min-width: 100%;
  }
}
.borderedTextCardDark {
  border-top-color: #fff !important;
  .cardTitle {
    background-color: $cardModalBodyDark;
    color: #fff !important;
  }
}
