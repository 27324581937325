.application-content-footer-dark {
  height: 45px;
  width: stretch;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-left: 40px;
  width: stretch;
  overflow-x: auto;
  overflow-y: hidden;

  .footerSectionContainer {
    display: flex;
    margin-right: 5px;
    width: 320px;

    .label {
      color: var(--unnamed-color-323338);
      text-align: left;
      font: normal normal 600 16px/22px Open Sans;
      letter-spacing: 0px;
      color: white;
      opacity: 1;
      margin-right: 5px;
      width: fit-content;
      white-space: nowrap;
    }
    .amount-diff-from-0 {
      color: var(--unnamed-color-323338);
      text-align: left;
      font: normal normal 600 16px/22px Open Sans;
      letter-spacing: 0px;
      color: #71cf48;
      opacity: 1;
      width: stretch;
    }
    .amount-equal-with-0 {
      color: var(--unnamed-color-323338);
      text-align: left;
      font: normal normal 600 16px/22px Open Sans;
      letter-spacing: 0px;
      color: #eb4345;
      opacity: 1;
      margin-right: 10px;
    }
  }
}
.application-content-footer-light {
  height: 45px;
  width: stretch;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-left: 40px;
  width: stretch;
  overflow-x: auto;
  overflow-y: hidden;

  .footerSectionContainer {
    display: flex;
    margin-right: 5px;
    width: 320px;

    .label {
      color: var(--unnamed-color-323338);
      text-align: left;
      font: normal normal 600 16px/22px Open Sans;
      letter-spacing: 0px;
      color: #323338;
      opacity: 1;
      margin-right: 5px;
      width: fit-content;
      white-space: nowrap;
    }
    .amount-diff-from-0 {
      color: var(--unnamed-color-323338);
      text-align: left;
      font: normal normal 600 16px/22px Open Sans;
      letter-spacing: 0px;
      color: #71cf48;
      opacity: 1;
      width: stretch;
    }
    .amount-equal-with-0 {
      color: var(--unnamed-color-323338);
      text-align: left;
      font: normal normal 600 16px/22px Open Sans;
      letter-spacing: 0px;
      color: #eb4345;
      opacity: 1;
      margin-right: 10px;
    }
  }
}
