@use "src/index.scss" as index;

.filtered-projects-modal {
  height: fit-content !important;
  width: fit-content !important;

  .ant-modal-content {
    min-height: 180px;
    min-width: 300px;
    width: calc(100vw - 20px);
    max-width: 1100px;
    height: calc(100vh - 20px);
    max-height: 720px;

    .ant-modal-close {
      height: 45px;
      width: 45px;
      .ant-modal-close-x {
        height: 45px;
        width: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .ant-modal-header {
      height: 45px;
      display: flex;
      align-items: center;
      background-color: #f8f8fa;
    }

    .ant-modal-body {
      min-height: calc(180px - 97px);
      min-width: 300px;
      height: calc(100% - 45px - 52px);
    }

    .ant-modal-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      flex-wrap: nowrap;
      width: 100%;
    }
  }
}

.filtered-projects-modal.filtered-projects-modal-dark {
  .ant-modal-content {
    background-color: index.$mainDarkBackgroundColor;
    .ant-modal-close .ant-modal-close-x svg {
      fill: #fff !important;
    }
    .ant-modal-header {
      background-color: index.$cardDarkBackgroundColor;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      .grid-template-card {
        background-color: index.$mainDarkBackgroundColor;
      }
    }
  }
}
