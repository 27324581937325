.assistant-chat-input {
  .assistant-input {
    height: 100%;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 10px;
    border-radius: 5px;
    background-color: #f4f5f6;

    .rc-textarea {
      min-height: 50px;
    }

    .ant-form-item {
      padding: 0;
      margin: 0;
    }

    .chat-input-container {
      height: calc(100% - 24px);
      width: 100%;

      .chat-input {
        height: 100%;
        width: 100%;

        textarea {
          height: 100%;
          width: 100%;
          border: 0px solid transparent;
          background: transparent;
          padding: 0px 0px;
          outline: none;
          vertical-align: text-top;
          resize: none;
          color: #231f20;
          font-weight: 400;
          border-radius: 0;

          &:hover {
            border-color: transparent;
            background: transparent;
          }

          &:focus {
            box-shadow: unset;
          }

          &::placeholder {
            color: #acacac;
          }
        }
      }
    }

    .chat-input-actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      flex-wrap: nowrap;
      width: 100%;
    }

    .chat-attachments {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;
      flex-wrap: nowrap;
      gap: 10px;
      color: #1264a3;
      cursor: pointer;

      .hover-button-container {
        color: inherit;
        font-weight: 600;
        padding: 0;

        .button-inner-content {
          padding: 0px 0px;
        }
      }

      .attachmentsCount {
        color: inherit;
        font-weight: 600;
      }

      .send-icon {
        fill: #1264a3;
        stroke-width: 5px;
        transition: transform 0.3s ease;
      }

      .send-icon-disabled {
        fill: #acacac;
        cursor: not-allowed;
      }

      .send-icon:hover {
        transform: scale(1.1);
      }
    }

    .chat-send {
      height: 24px;
      width: 24px;
      transition: color 0.2s ease-in-out;

      svg,
      path {
        fill: currentColor;
      }
    }

    .chat-send.send-disabled {
      color: #acacac;
      cursor: not-allowed;
    }

    .chat-send.send-enabled {
      color: #1264a3;
      cursor: pointer;

      .send-icon {
        transition: transform 0.3s ease;
      }

      .send-icon:hover {
        transform: scale(1.1);
      }
    }
  }

  .assistant-input.input-offline {
    background-color: #e6e9ef;

    .chat-attachments {
      color: #acacac;
      cursor: not-allowed;
    }
  }
}

@media only screen and (max-height: 767px) {
  .assistant-chat-input {
    .assistant-input {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;
      flex-wrap: nowrap;
      height: 49px;

      .chat-input-container {
        height: 100%;
      }
    }
  }
}
