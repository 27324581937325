$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;
.DrawOnPhotoModal {
  max-width: 97dvw !important;
  width: fit-content !important;
  max-height: 90dvh;
  transition: width 5s ease-in-out, height 5s ease-in-out;

  .ant-modal-content {
    .ant-modal-header {
      height: 45px;
      background-color: #f8f8fa;
      padding-top: 10px !important;
      padding-right: 50px !important;
    }
    .ant-modal-body {
      //   padding: 20px 20px 0 20px;
      min-width: 30dvw;
      min-height: 30dvh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .filePreviewCarousel {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        min-height: 50dvh;
        min-width: 30dvw;
        .displayedImage {
          max-height: 68dvh;
          max-width: 90dvw;
          overflow: auto;
          @media (max-width: 768px) {
            max-height: 60dvh;
          }
        }
      }
    }
    .ant-modal-footer {
      padding-bottom: 20px !important;
      .gDriveButtons {
        display: flex;
        justify-content: space-between;
        gap: 10px;

        .driveIcon {
          svg {
            width: 20px;
            height: 20px;
          }
        }
        .mondayButtonContainer {
          padding: unset !important;
        }
      }
    }
  }
}

.canvas-dropdown-menu {
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
  max-width: 165px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  .canvas-dropdown-colors-con {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 5px;
    .canvas-dropdown-colors {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
      .canvas-dropdown-color {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        cursor: pointer;
        display: inline-block;
      }
    }
  }
}

.DrawOnPhotoModalDark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;
      }
    }
    // .ant-modal-body {
    // }
  }
}
