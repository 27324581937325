$inputModalDark: #12131b;
$cardModalBodyDark: #1f212d;

.upload-table-container {
  height: calc(100vh - 240px);
  width: calc(100vw - 60px);
  overflow: auto;

  .table-control-panel {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    .ant-form-item {
      margin-bottom: 0px;
    }
    .ant-input {
      width: 200px;
    }
  }
}

.upload-table-container-dark {
  .table-control-panel {
    .ant-input {
      background-color: $inputModalDark !important;
      color: #fff;
      &::placeholder {
        color: #acacca;
      }
    }
  }
}
