.userChatting {
  display: flex;
  flex-direction: column;
  gap: 40px;
  .userChattingHeaderInfo {
    height: 44px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 45px 25px 0px 25px;
    .userChattingStatus {
      display: flex;
      align-items: center;
      gap: 4px;
      .avatarInitialsUser {
        width: 56px;
        height: 56px;
      }
      .userChattingName {
        display: flex;
        flex-direction: column;
        .sessionBadgeStatus {
          display: flex;
          align-items: center;
          gap: 2px;
        }
        .nameOfUser {
          font-weight: 600;
          font-size: 16px;
        }
      }
    }

    .userActions {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 15px;
      .sendEmailInstead {
        padding: 0 !important;
        .button-inner-content {
          gap: 5px;
        }

        .button-inner-content:hover {
          font-weight: 600;
        }
      }
      .divider {
        display: flex;
        align-items: center;
        width: 2px;
        height: 15px;
        background-color: #ccc;
      }
    }
  }
  .startTypingContent {
    // width: 100%;
    // height: 100%;
    .dateOfLastChat {
      display: flex;
      align-items: center;
      justify-content: center;
      .line {
        flex: 1;
        border: none;
        border-top: 1px dashed #aaa;
        margin: 0 10px;
      }
    }
    .chatData {
      &::-webkit-scrollbar-track {
        background-color: #f4f5f6 !important;
      }
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 15px 10px 15px;
      height: 100%;
      overflow-y: auto;
      max-height: 800px;

      .chat-container {
        display: flex;
        flex-direction: column;
        background-color: #ffffff;
        max-height: 530px;
        width: 100% !important;

        .chat-message {
          display: flex;
          align-items: flex-start;
          margin-bottom: 15px;
        }
        .other-message {
          flex-direction: row-reverse;
        }

        .avatar-sender {
          background-color: #f4f5f6;
          color: #323338;
          width: 40px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 5px;
          margin: 0 10px;
        }

        // .message-content {
        //   max-width: 60%;
        //   background-color: #f4f5f6;
        //   padding: 10px;
        //   border-radius: 10px;
        //   box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
        // }

        // .sender-name {
        //   display: flex;
        //   justify-content: space-between;
        //   font-weight: bold;
        //   margin-bottom: 5px;
        //   align-items: center;
        // }

        // .message-text {
        //   // margin-bottom: 5px;
        //   color: #333;
        // }
        // .space-right {
        //   -moz-user-select: -moz-none;
        //   -khtml-user-select: none;
        //   -webkit-user-select: none;
        //   -ms-user-select: none;
        //   user-select: none;
        // }

        // .message-time {
        //   font-size: 12px;
        //   color: #999;
        //   text-align: right;
        // }
      }

      .textAreaChat {
        position: absolute;
        bottom: 0;
        margin-bottom: 15px;
        width: 810px;
        .chatIcons {
          .uploademojiIcons {
            display: flex;
            align-items: center;
            gap: 5px;
          }
          display: flex;
          justify-content: space-between;
          padding: 0px 15px 0px 15px;
          position: relative;
          top: 90px;
          z-index: 1;
        }
        .chatInput {
          background-color: #f4f5f6;
          border: none !important;
          min-height: 100px;
          overflow-y: auto;
        }
      }
    }
  }
}

.epr-main {
  width: 300px !important;
  height: 350px !important;
  border: none !important;
  .epr_q53mwh {
    padding: 5px !important;
  }
  .epr_-kg0voo {
    display: none !important;
  }
}
