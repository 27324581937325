.chart-cards-popover {
  .chart-types-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
    cursor: pointer;

    label {
      font-size: 18px;
      font-weight: bold;
    }

    .radio-charts {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
      label {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.chart-cards-popover-content-dark {
  .chart-types-wrapper {
    .radio-charts {
      svg {
        path {
          fill: #f2f2f2;
        }
      }
    }
  }
}
