$dark1: #12131b;
$dark2: #20212d;
$dark3: #2a2b3a;

.header-automations-drawer {
  .ant-drawer-header {
    background-color: #fff;
    .ant-drawer-close svg {
      fill: black;
      height: 17px;
      width: 17px;
    }
    .ant-drawer-title {
      color: black;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
    }
  }

  .ant-drawer-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    overflow: hidden;
    padding: 20px 0;

    .selected-tab-content {
      width: 100%;
      height: 100%;
      display: flex;
      align-self: center;
      overflow: hidden;
    }
  }

  &.dark-mode {
    color: #f2f2f2;
    .ant-drawer-header {
      background-color: $dark1;
      svg {
        path {
          fill: #f2f2f2;
        }
      }
      span {
        color: #f2f2f2;
      }
    }
    .ant-drawer-body {
      background-color: $dark3;
      .ant-segmented {
        background: $dark2;
        color: #f2f2f2;

        .ant-segmented-item {
          &:hover {
            color: #f2f2f2;
            background: $dark1;
          }
        }

        .ant-segmented-item-selected {
          color: #f2f2f2;
          background: $dark1;
        }
      }
    }
  }
}
