.recordsToUpdateModal {
  // width: fit-content;
  width: unset !important;
  .ant-modal-content {
    //   width: fit-content;
    .ant-modal-header {
      height: 45px;
      display: flex;
      align-items: center;
      background-color: #f8f8fa;
      overflow: hidden;
      border: none;

      .ant-modal-title {
        font-size: 16px;
        width: 100%;
        font-weight: 600;
        color: #323338;
        padding: 0 !important;
        height: 45px;
        display: flex;
        align-items: center;
      }
    }
    .ant-modal-body {
      max-height: calc(100vh - 200px);
      max-width: calc(100vw - 200px);
      min-width: 380px;
      overflow-y: auto;
      //   width: fit-content;
      .RTUInputs {
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        .switch-group {
          display: flex;
          gap: 10px;
          align-items: center;
          .switch-group-label {
            font-size: 14px;
            font-weight: 600;
            margin-left: 10px;
          }
        }
      }
      .RTUCollapse {
        display: flex;
        width: 1000px;
        flex-wrap: wrap;
        gap: 20px;

        .ant-collapse-item {
          margin-bottom: 0 !important;
          width: 48%;
          height: fit-content;
          //   flex-basis: calc(50% - 10px);
          .ant-collapse-header {
            height: 60px;
            display: flex;
            align-items: center;
            .ant-collapse-extra .extraContainer .searchInput {
              height: 35px;
            }
            .ant-collapse-header-text {
              text-transform: capitalize;
            }
          }
          // .ant-collapse-content {
          // }
        }
      }
    }
  }
}
