.team-Card {
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  height: fit-content;
  flex-wrap: wrap;
  .team-name {
    padding-left: 5px;
    display: flex;
    width: fit-content;
    padding-right: 5px;
    font-size: 16px;
    font-weight: 600;
  }
}

.membersContent-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: auto;
  .header-content {
    width: 100%;
    display: flex;
    padding-left: 0px;
    padding-right: 20px;
    margin-top: 10px;
    .item {
      width: 50%;
      display: flex;
      justify-content: center;
      text-align: center;
      font: normal normal normal 20px/27px Open Sans;
      letter-spacing: 0px;
      color: #323338;
      opacity: 1;
      margin-bottom: 10px;
    }
  }
  .content-membersContent {
    .ImageContainer {
      margin-right: 10px;
      border-radius: 50%;
      width: 55px;
      height: 55px;
      cursor: pointer;

      .return-img {
        overflow: hidden;
      }
    }
    .InitialsContainer {
      cursor: pointer;
      border-radius: 50%;
      height: 55px;
      min-width: 55px;
      margin-right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      .initialsStyle {
        text-align: left;
        font: normal normal 600 35px/47px Open Sans;
        letter-spacing: 0px;
        opacity: 1;
      }
    }
    .item-content {
      height: 66px;
      display: flex;
      align-items: center;
      font: normal normal normal 16px/22px Open Sans;
      letter-spacing: 0px;
      color: #323338;
    }
    .member-content {
      height: 66px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .memberData {
        text-align: left;
        font: normal normal 600 16px/22px Open Sans;
        letter-spacing: 0px;
        opacity: 1;
        .nameContainer {
          color: #1264a3;
          cursor: pointer;
        }

        .groupName {
          font-weight: 400;
        }
        .suspendedName {
          text-decoration: line-through;
          color: red;
        }
        .suspendedGroupName {
          text-decoration: line-through;
        }
      }
    }
  }
}
