.Background-mobile-tabel{
    // background: #fff !important;
    // border-radius: 15px;
    // padding: 10px;
    // box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
    // margin-bottom: 20px;
    .responsiveTable td.pivoted{
        padding-left: calc(45% + 10px) !important;
        // border: 1px solid  rgb(0 0 0 / 16%) !important;
        min-height: 26px;
    }
    .responsiveTable tbody tr{
        background: #fff !important;
        border-radius: 15px;
        padding: 10px;
        box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
        margin-bottom: 20px;
        border: none !important;
        
    }
}

.Background-mobile-tabel-noData{
    background: #fff !important;
    border-radius: 15px;
    padding: 10px;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
    height: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
}

// defaul mobile base page 
.base-mobile-div{
    margin-left: 60px;
    margin-top: 67px;
    padding: 10px;
    overflow: auto;
    height: 82vh;
    }

    // default header base page
.Header-mobile-div{
    // margin-top: 80px;
    // margin-bottom: -60px;
    // margin-left: 70px;
    // margin-right: 10px;
    background: #fff;
    border-radius: 15px;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
    padding: 20px;
    button.ant-btn.ant-btn-default.Header-modal-btn{
        border-radius: 5px;
        border: 0px;
        color: #fff;
        background: #68c142;
    }
}


// mobile  base page with header and no tabs 
.mobile-basePage-With-header-noTabs{
    // margin-top: 110px;
    .Header-mobile-div{
        margin: 10PX;
    }
    .base-mobile-div{
        margin-left: 0px;
        margin-top: 0px;
        padding: 10px;
        overflow: auto;
        height: calc(100vh - 200px);
    }
}


//mobile base page with header and tabs
.mobile-basePage-With-header{
    .base-mobile-div{
        margin-left: 0px;
        margin-top: 0px;
        padding: 10px;
        overflow: auto;
        height: 70vh;
        }
}

.base-mobile-settings-div{
    width: 100%;
    height: 100%;
    overflow: auto;
    padding: 10px;
}
    
@media screen and (min-device-width: 414px) and (max-device-width: 450px) {
.base-mobile-div{
    margin-left: 60px;
    margin-top: 67px;
    padding: 10px;
    overflow: auto;
    height: 82vh;
    }
}
@media (max-width: 390px) {
.base-mobile-div{
    margin-left: 60px;
    margin-top: 67px;
    padding: 10px;
    overflow: auto;
    height: 81vh;
    }
}