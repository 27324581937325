.payment-amounts-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 5px;
  margin-top: 10px;

  .payment-amounts-labels {
    width: 100%;

    .amounts-text {
      font-weight: 600;
      padding: 0 8px;
    }
  }
}
