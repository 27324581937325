.invoiceTableCont {
  grid-area: auto;
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto;
  align-content: start;
  justify-content: center;
  gap: 10px 0;
}
.invoiceTableCont .invoicePaper {
  grid-area: auto;
  width: 100%;
  height: auto;
  padding: 20px 10px;
  width: 100%;
  height: auto;
  // box-shadow: 0 0 7px rgba(0, 0, 0, 0.5803921569);
  box-shadow: 0px 3px 6px #00000029;

  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto;
  align-content: start;
  justify-content: center;
  p {
    margin-top: 0px !important;
  }
}
.invoiceTableCont .invoicePaper .invoiceTable {
  width: 100%;
  height: auto;
  border-left: 1px solid #454545;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto;
  align-content: start;
  justify-content: center;
}
.invoiceTableCont .invoicePaper .invoiceTable .headerTable {
  grid-area: auto;
  width: 100%;
  height: auto;
  background-color: #e5e5e6;
  display: grid;
  grid-template-columns: 17% 32% repeat(3, 17%);
}
.invoiceTableCont .invoicePaper .invoiceTable .headerTable > h3 {
  margin-bottom: 0;
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .invoiceTableCont .invoicePaper .invoiceTable .headerTable {
    grid-template-columns: 15.5% 38% repeat(3, 15%);
  }
}
@media (min-width: 1281px) {
  .invoiceTableCont .invoicePaper .invoiceTable .headerTable {
    grid-template-columns: 15.5% 38% repeat(3, 15.5%);
  }
}
.invoiceTableCont .invoicePaper .invoiceTable .headerTable h3 {
  grid-area: auto;
  width: 100%;
  height: auto;
  font-size: 1rem;
  font-weight: 400;
  font-style: normal;
  color: #000000;
  text-align: center;
  font-size: 7px;
  overflow-wrap: break-word;
  padding: 10px 0;
  color: #101010;
  font-weight: 800;
  border-top: 1px solid #454545;
  border-bottom: 1px solid #454545;
  border-right: 1px solid #454545;
}
@media (min-width: 481px) and (max-width: 767px) {
  .invoiceTableCont .invoicePaper .invoiceTable .headerTable h3 {
    font-size: 1rem;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .invoiceTableCont .invoicePaper .invoiceTable .headerTable h3 {
    font-size: 1rem;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .invoiceTableCont .invoicePaper .invoiceTable .headerTable h3 {
    font-size: 1rem;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .invoiceTableCont .invoicePaper .invoiceTable .headerTable h3 {
    font-size: 1rem;
  }
}
@media (min-width: 1281px) {
  .invoiceTableCont .invoicePaper .invoiceTable .headerTable h3 {
    font-size: 1rem;
  }
}
@media (min-width: 481px) and (max-width: 767px) {
  .invoiceTableCont .invoicePaper .invoiceTable .headerTable h3 {
    font-size: 7px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .invoiceTableCont .invoicePaper .invoiceTable .headerTable h3 {
    font-size: 8px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .invoiceTableCont .invoicePaper .invoiceTable .headerTable h3 {
    font-size: 9px;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .invoiceTableCont .invoicePaper .invoiceTable .headerTable h3 {
    font-size: 12px;
  }
}
@media (min-width: 1281px) {
  .invoiceTableCont .invoicePaper .invoiceTable .headerTable h3 {
    font-size: 12px;
  }
}
.invoiceTableCont .invoicePaper .invoiceTable .datatableCont .itemTable {
  grid-area: auto;
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 17% 32% repeat(3, 17%);
}
.invoiceTableCont .invoicePaper .invoiceTable .datatableCont .itemTable p {
  margin-bottom: 0;
  margin-top: 0px !important;
  // padding-top: 0px !important;
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .invoiceTableCont .invoicePaper .invoiceTable .datatableCont .itemTable {
    grid-template-columns: 15.5% 38% repeat(3, 15%);
  }
}
@media (min-width: 1281px) {
  .invoiceTableCont .invoicePaper .invoiceTable .datatableCont .itemTable {
    grid-template-columns: 15.5% 38% repeat(3, 15.5%);
  }
}
.invoiceTableCont
  .invoicePaper
  .invoiceTable
  .datatableCont
  .itemTable
  section {
  grid-area: auto;
  width: 100%;
  height: auto;
  font-size: 1.6rem;
  font-weight: 600;
  font-style: normal;
  color: #000000;
  padding: 10px 10px;
  border-bottom: 1px solid #454545;
  border-right: 1px solid #454545;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto;
  align-content: start;
  justify-content: center;
}
@media (min-width: 481px) and (max-width: 767px) {
  .invoiceTableCont
    .invoicePaper
    .invoiceTable
    .datatableCont
    .itemTable
    section {
    font-size: 1.6rem;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .invoiceTableCont
    .invoicePaper
    .invoiceTable
    .datatableCont
    .itemTable
    section {
    font-size: 1.7rem;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .invoiceTableCont
    .invoicePaper
    .invoiceTable
    .datatableCont
    .itemTable
    section {
    font-size: 1.7rem;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .invoiceTableCont
    .invoicePaper
    .invoiceTable
    .datatableCont
    .itemTable
    section {
    font-size: 1.7rem;
  }
}
@media (min-width: 1281px) {
  .invoiceTableCont
    .invoicePaper
    .invoiceTable
    .datatableCont
    .itemTable
    section {
    font-size: 1.8rem;
  }
}
.invoiceTableCont
  .invoicePaper
  .invoiceTable
  .datatableCont
  .itemTable
  section
  p {
  grid-area: auto;
  width: 100%;
  height: auto;
  font-size: 1rem;
  font-weight: 400;
  font-style: normal;
  color: #000000;
  text-align: center;
  border: none;
  padding: 0;
  text-align: start;
  color: #101010;
  overflow-wrap: break-word;
  font-size: 6px;
}
@media (min-width: 481px) and (max-width: 767px) {
  .invoiceTableCont
    .invoicePaper
    .invoiceTable
    .datatableCont
    .itemTable
    section
    p {
    font-size: 1rem;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .invoiceTableCont
    .invoicePaper
    .invoiceTable
    .datatableCont
    .itemTable
    section
    p {
    font-size: 1rem;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .invoiceTableCont
    .invoicePaper
    .invoiceTable
    .datatableCont
    .itemTable
    section
    p {
    font-size: 1rem;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .invoiceTableCont
    .invoicePaper
    .invoiceTable
    .datatableCont
    .itemTable
    section
    p {
    font-size: 1rem;
  }
}
@media (min-width: 1281px) {
  .invoiceTableCont
    .invoicePaper
    .invoiceTable
    .datatableCont
    .itemTable
    section
    p {
    font-size: 1rem;
  }
}
@media (min-width: 481px) and (max-width: 767px) {
  .invoiceTableCont
    .invoicePaper
    .invoiceTable
    .datatableCont
    .itemTable
    section
    p {
    font-size: 6px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .invoiceTableCont
    .invoicePaper
    .invoiceTable
    .datatableCont
    .itemTable
    section
    p {
    font-size: 7px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .invoiceTableCont
    .invoicePaper
    .invoiceTable
    .datatableCont
    .itemTable
    section
    p {
    font-size: 8px;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .invoiceTableCont
    .invoicePaper
    .invoiceTable
    .datatableCont
    .itemTable
    section
    p {
    font-size: 12px;
  }
}
@media (min-width: 1281px) {
  .invoiceTableCont
    .invoicePaper
    .invoiceTable
    .datatableCont
    .itemTable
    section
    p {
    font-size: 12px;
  }
}
.invoiceTableCont .invoicePaper .invoiceTable .datatableCont .itemTable p {
  grid-area: auto;
  width: 100%;
  height: auto;
  font-size: 1rem;
  font-weight: 400;
  font-style: normal;
  color: #000000;
  text-align: center;
  overflow-wrap: break-word;
  padding: 10px 10px;
  border-bottom: 1px solid #454545;
  border-right: 1px solid #454545;
  color: #101010;
  text-align: end;
  font-size: 6px;
}
@media (min-width: 481px) and (max-width: 767px) {
  .invoiceTableCont .invoicePaper .invoiceTable .datatableCont .itemTable p {
    font-size: 1rem;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .invoiceTableCont .invoicePaper .invoiceTable .datatableCont .itemTable p {
    font-size: 1rem;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .invoiceTableCont .invoicePaper .invoiceTable .datatableCont .itemTable p {
    font-size: 1rem;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .invoiceTableCont .invoicePaper .invoiceTable .datatableCont .itemTable p {
    font-size: 1rem;
  }
}
@media (min-width: 1281px) {
  .invoiceTableCont .invoicePaper .invoiceTable .datatableCont .itemTable p {
    font-size: 1rem;
  }
}
@media (min-width: 481px) and (max-width: 767px) {
  .invoiceTableCont .invoicePaper .invoiceTable .datatableCont .itemTable p {
    font-size: 6px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .invoiceTableCont .invoicePaper .invoiceTable .datatableCont .itemTable p {
    font-size: 7px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .invoiceTableCont .invoicePaper .invoiceTable .datatableCont .itemTable p {
    font-size: 8px;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .invoiceTableCont .invoicePaper .invoiceTable .datatableCont .itemTable p {
    font-size: 12px;
  }
}
@media (min-width: 1281px) {
  .invoiceTableCont .invoicePaper .invoiceTable .datatableCont .itemTable p {
    font-size: 12px;
  }
}
.invoiceTableCont
  .invoicePaper
  .invoiceTable
  .datatableCont
  .itemTable
  .itemDataStart {
  text-align: center;
  margin-bottom: 0;
}
