@use "/src/index.scss" as index;

.live-deg-payroll {
  height: calc(100dvh - 56px) !important;
  width: 100%;
  .payroll-view-layout {
    padding: 0px;
    .layout-filters-header {
      height: 51px !important;
      width: 100%;
      border-radius: 0px;
      border-bottom: 1px solid #dee2eb;
      background-color: #f4f5f6;
      box-shadow: none;
      .mondayButtonWhite {
        @include index.mondayButton(#fff, #e6e9ef, #323338);
      }
      .filter-buttons-section {
        .ant-input-wrapper {
          .ant-input-affix-wrapper {
            display: flex;
            align-items: center;
            background-color: #fff;
            border: none;
            padding-right: 0px;
            &::after {
              content: "";
              height: 15px;
              width: 2px;
              background-color: #e6e9ef;
            }
            .ant-input {
              background-color: #fff;
              color: #323338;
            }
          }
          .ant-input-group-addon {
            background-color: #fff;
            border: none;
          }
        }
      }
    }
    .layout-children-container {
      height: calc(100% - 71px);
      margin: 0px 10px 10px 10px;
    }
  }
  .actions-section {
    .new-entry-btn {
      width: 150px;
    }
  }
  .table-type-segment {
    background-color: #fff;
    .ant-segmented-group {
      .ant-segmented-item {
        width: 68px;
        &:hover:not(.ant-segmented-item-selected)::after {
          background-color: #fff !important;
        }
      }
      .ant-segmented-item-selected {
        background-color: #f4f5f6;
        box-shadow: none;
      }
      .ant-segmented-thumb,
      .ant-segmented-thumb-motion-appear,
      .ant-segmented-thumb-motion,
      .ant-segmented-thumb-motion-appear-active {
        background-color: #f4f5f6 !important;
      }
    }
  }
  // .ag-center-cols-container[role="rowgroup"] {
  //   .ag-row .ag-cell {
  //     min-width: 150px !important;
  //   }
  // }
  // .ag-header-cell, .ag-row-group {
  //   // .ag-cell
  //   min-width: 150px !important; /* Adjust width */
  // }
}

.live-deg-payroll-dark {
  background-color: index.$mainDarkBackgroundColor;
  .payroll-view-layout .layout-filters-header {
    background-color: index.$cardDarkBackgroundColor;
    border-bottom: 1px solid #616481;
    box-shadow: none !important;
    .filter-buttons-section {
      .ant-input-wrapper {
        .ant-input-affix-wrapper {
          display: flex;
          align-items: center;
          background-color: index.$inputDarkColor;
          border: none !important;
          padding-right: 0px;
          &::after {
            content: "";
            height: 15px;
            width: 2px;
            background-color: #616481;
          }
          .ant-input {
            background-color: index.$inputDarkColor;
            color: #fff;
          }
        }
        .ant-input-group-addon {
          background-color: index.$inputDarkColor;
          border: none !important;
          .ant-select {
            .ant-select-selector {
              min-width: 170px;
            }
          }
        }
      }
    }
  }
  .table-type-segment {
    background-color: index.$inputDarkColor;
    .ant-segmented-group {
      .ant-segmented-item {
        width: 68px;
        .ant-segmented-item-label {
          color: #acacca;
        }
        &:hover:not(.ant-segmented-item-selected)::after {
          background-color: index.$inputDarkColor !important;
        }
      }
      .ant-segmented-item-selected {
        background-color: index.$cardDarkBackgroundColor;
        .ant-segmented-item-label {
          color: #fff;
        }
      }
      .ant-segmented-thumb,
      .ant-segmented-thumb-motion-appear,
      .ant-segmented-thumb-motion,
      .ant-segmented-thumb-motion-appear-active {
        background-color: index.$cardDarkBackgroundColor !important;
      }
    }
  }
}
