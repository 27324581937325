@import "./../../../index.scss";

$headerDarkBackground: #12131b;
$bodyDarkBackground: #1f212d;
$inputModalDark: #12131b;

.jodit-editor-component {
  height: 100%;
  width: 100%;
  &.jodit_fullsize-box_true {
    position: fixed !important;
    top: 0;
    left: 0;
  }
  .jodit-react-container {
    height: 100%;
    width: 100%;
    border-radius: 10px !important;

    .jodit-container {
      height: 100% !important;
      width: 100% !important;
      display: flex;
      flex-direction: column;
      gap: 6px;
      border: none;
      border-radius: 10px;
      background-color: transparent;

      .jodit-toolbar__box {
        overflow: visible !important;
        border: none;

        .jodit-ui-group {
          border-radius: 5px;
          background-color: #f5f5f7;
        }
      }

      .jodit-workplace {
        height: 100% !important;
        > * {
          overflow: auto !important;
        }
        border-radius: 5px;
        .jodit-wysiwyg {
          max-height: 160px;
          overflow-y: auto;
          background-color: #f5f5f7;
        }
      }

      .jodit-status-bar {
        display: none;
      }
    }
  }
}

.jodit-editor-component-white {
  .jodit-container {
    .jodit-toolbar__box {
      background: #fff !important;
      .jodit-ui-group {
        background: #fff !important;
      }
    }

    .jodit-workplace {
      .jodit-wysiwyg {
        background-color: #fff !important;
      }
    }
  }
}

.jodit-editor-component-dark {
  .jodit-container {
    .jodit-toolbar__box {
      background: $headerDarkBackground !important;
      .jodit-ui-group {
        background: $headerDarkBackground !important;

        .jodit-toolbar-button {
          .jodit-toolbar-button__icon {
            svg path {
              fill: #f2f2f2 !important;
            }
          }

          .jodit-toolbar-button__text {
            color: #fff;
          }
          .jodit-toolbar-button__trigger {
            svg path {
              fill: #f2f2f2 !important;
            }
          }
        }
      }
    }

    .jodit-workplace {
      .jodit-wysiwyg {
        background-color: $headerDarkBackground !important;
        color: #fff !important;
      }
    }
  }
}
