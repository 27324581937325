.scrollable-container {
  gap: 5px;
  width: 100%;
  display: flex;
  position: relative;
  height: fit-content;
  align-items: center;
  padding-right: 10px;
  .scrollable-container-arrows {
    width: 44px;
    height: 95%;
    display: flex;
    color: #323338;
    align-self: unset;
    position: absolute;
    align-items: center;
    justify-content: center;
    background-color: #ffffffaa;
  }
  .scrollable-container-arrows.left-arrow {
    left: 0;
  }
  .scrollable-container-arrows.right-arrow {
    right: 0;
  }
  .scrollable-container-body {
    flex: 1;
    gap: 5px;
    display: flex;
    overflow-x: auto;
    height: fit-content;
    padding-bottom: 5px;
    scroll-behavior: smooth;
  }
}
