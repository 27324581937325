.documentContainer {
  width: auto;
  display: flex;
  width: 100%;
  height: 47px;
  justify-content: space-between;
  background-color: #f8f8fa;
  transition: background-color 0.3s ease-in-out;
  &:hover {
    background-color: #f1f5fd;
  }
  .documentTitle {
    display: flex;
    justify-content: space-between;
    width: 50%;
    .documentIcon {
      width: 18px;
      height: 18px;
    }
    .documentName {
      text-align: left;
      font: normal normal normal 14px/19px Open Sans;
      letter-spacing: 0px;
      color: #323338;
      opacity: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 100%;
    }
  }
}
.documentContainer.documentContainerRed {
  background-color: #fe4c4a50 !important;
}
.documentContainer.documentContainerBlue {
  background-color: #1264a350 !important;
}
.documentContainerDark {
  background-color: #292b36;
  &:hover {
    background-color: lighten(#292b36, 10%);
  }
}
