@import "../../../../../../Communication.scss";

.documentation-types-container {
    display: flex;

    .documentation-types-icon {
        fill: $icon-color;
        height: 20px;
        width: 20px;
    }
}

.docs-types-dropdown-overlay {
    .ant-dropdown-menu {
        max-height: 300px;
        max-width: 200px;
        overflow-y: auto;
        overflow-x: hidden;
    }


}


.documentation-types-loading-container {
    position: relative;

    .documentation-types-loading-badge {
        position: absolute;
        right: -5px;
        top: -10px;
    }
}