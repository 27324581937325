.file-name-container {
    cursor: pointer;
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border-radius: 15px;
    height: 100px;
    width: fit-content;

    svg {
        height: 86px;
        width: 71px;
    }

    .file-name-label {
        white-space: nowrap;
        font-size: 14px;
        font-weight: bold;
        line-height: 14px;
    }
}