@import "../../../../../../../../../../index.scss";

.quickSaveDisabled {
  cursor: not-allowed !important;
  opacity: 0.4 !important;

  .mondayButtonDivider {
    background-color: #0d4c7c !important;
  }
}
.sov-header-control-panel-light {
  .sov-header-options {
    height: 50px;
    background: white;
    margin: 15px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.16);
    padding: 0 20px;

    .leftSideContainer {
      display: flex;
      gap: 20px;
      align-items: center;

      .serviceRetractButton {
        height: 32px;
        width: 32px;
        border: 0;
        border-radius: 5px;
        background-color: #1264a3;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          animation: rotateInRight 0.3s;
          transform: rotate(-90deg);
          fill: white;
        }
      }

      .serviceRetractButtonRetracted {
        height: 32px;
        width: 32px;
        border: 0;
        border-radius: 5px;
        background-color: #1264a3;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          animation: rotateInLeft 0.3s;
          transform: rotate(90deg);
          fill: white;
        }
      }

      .total-price-details-div-sov {
        display: flex;
        align-items: center;
        .sov-header-options-price-container {
          display: flex;
          .sov-header-options-price-label {
            margin-right: 5px;
            font-family: "Open Sans", sans-serif;
            font-size: 16px;
            font-weight: 600;
          }

          .sov-header-options-price {
            font-family: "Open Sans", sans-serif;
            font-size: 16px;
            font-weight: bold;
            color: #68c142;
          }
        }
      }
    }

    .rightSideContainer {
      .buttonContainer {
        display: flex;
        align-items: center;

        .exportButtonContainer {
          display: flex;
          align-items: center;
          .wordButton {
            width: 32px;
            height: 32px;
            border: 0;
            margin-right: 20px;
            border-radius: 5px;
            background-color: #1264a3;
            padding: 0;
          }

          .pptButton {
            width: 32px;
            height: 32px;
            border: 0;
            margin-right: 20px;
            border-radius: 5px;
            background-color: #1264a3;
            padding: 0;
          }

          .to-do-modal-button {
            margin-right: 20px !important;
            .mondayButtonContainer {
              // margin-right: 20px !important;
            }
          }
        }

        .saveButtonContainer {
          .quickSaveButton {
            @include mondayButton(#68c142, #71cf48, #fff);

            &:disabled {
              @include mondayButton(#5aa33a, #70b652, #a6c997);

              svg {
                fill: #a6c997;
              }
            }
          }
        }

        .autoSaveSwitchContainer {
          margin-right: 20px;

          .ant-switch {
            background: #fe4c4a;

            .ant-switch-handle {
              content: url("../../../../../../../../BasePage/src/switchAutoSaveOff.svg");
              background-color: white;
              padding: 2px;
              border-radius: 9px;
            }
          }

          .ant-switch-checked {
            background: rgb(113, 207, 72);

            .ant-switch-handle {
              content: url("../../../../../../../../BasePage/src/switchSaveIcon.svg");
              background-color: white;
              border-radius: 9px;
            }
          }
        }
      }
    }
  }
}

.sov-header-control-panel-dark {
  .sov-header-options {
    height: 50px;
    background: #12131b;
    margin: 15px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.16);
    color: white;
    padding: 0 20px;

    .leftSideContainer {
      display: flex;
      gap: 20px;
      align-items: center;

      .serviceRetractButton {
        height: 32px;
        width: 32px;
        border: 0;
        border-radius: 5px;
        background-color: #1264a3;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          animation: rotateInRight 0.3s;
          transform: rotate(-90deg);
          fill: white;
        }
      }

      .serviceRetractButtonRetracted {
        height: 32px;
        width: 32px;
        border: 0;
        border-radius: 5px;
        background-color: #1264a3;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          animation: rotateInLeft 0.3s;
          transform: rotate(90deg);
          fill: white;
        }
      }

      .total-price-details-div-sov {
        display: flex;
        align-items: center;

        .sov-header-options-price-container {
          display: flex;

          .sov-header-options-price-label {
            margin-right: 5px;
            font-family: "Open Sans", sans-serif;
            font-size: 16px;
            font-weight: 600;
          }

          .sov-header-options-price {
            font-family: "Open Sans", sans-serif;
            font-size: 16px;
            font-weight: bold;
            color: #68c142;
          }
        }
      }
    }

    .rightSideContainer {
      display: flex;
      .buttonContainer {
        display: flex;
        align-items: center;

        .exportButtonContainer {
          display: flex;
          align-items: center;
          .wordButton {
            width: 32px;
            height: 32px;
            border: 0;
            margin-right: 20px;
            border-radius: 5px;
            background-color: #1264a3;
            padding: 0;
          }

          .pptButton {
            width: 32px;
            height: 32px;
            border: 0;
            margin-right: 20px;
            border-radius: 5px;
            background-color: #1264a3;
            padding: 0;
          }

          .to-do-modal-button {
            margin-right: 20px !important;
            // width: 20px !important;
            .mondayButtonContainer {
              // button {
              //   width: 32px !important;
              //   padding: 0 !important;
              // }
            }
          }
          button:hover {
            &:hover {
              background-color: #0d4c7c !important;
              border-color: #0d4c7c !important;
              transition: 0.2s;
            }
          }
        }

        .saveButtonContainer {
          .quickSaveButton {
            @include mondayButton(#68c142, #71cf48, #fff);

            &:disabled {
              @include mondayButton(#5aa33a, #70b652, #a6c997);

              svg {
                fill: #a6c997;
              }
            }
          }
        }

        .autoSaveSwitchContainer {
          margin-right: 20px;

          .ant-switch {
            background: #fe4c4a;

            .ant-switch-handle {
              content: url("../../../../../../../../BasePage/src/switchAutoSaveOff.svg");
              background-color: white;
              padding: 2px;
              border-radius: 9px;
            }
          }

          .ant-switch-checked {
            background: rgb(113, 207, 72);

            .ant-switch-handle {
              content: url("../../../../../../../../BasePage/src/switchSaveIcon.svg");
              background-color: white;
              border-radius: 9px;
            }
          }
        }
      }
    }
  }
}

.sov-header-control-panel-dark,
.sov-header-control-panel-light {
  // Media query for all iPads
  @media only screen and (min-width: 768px) and (max-width: 1224px) {
    .sov-header-options {
      .leftSideContainer {
        background-color: red !important;
        font-size: 9px !important;
        .total-price-details-div-sov {
          display: none !important;
        }
      }
    }
  }
}
