.user-view-container {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #32333881;
    cursor: pointer;

    p {
        margin: 0;
        padding: 0;
    }

    .no-image-avatar {
        background-color: #4895ef;
    }

    .avatar-container {
        padding: 2px;
        border-radius: 50%;
        filter: contrast(0.5);
    }

    .user-info {
        display: flex;
        flex-direction: column;
    }

    &.selected {
        color: #323338;

        .avatar-container {
            border: 2px solid #71cf48 !important;
            filter: none;
        }

        .user-name {
            font-weight: 600;
        }
    }
}