.progress-status {
  display: flex;
  flex-direction: column;
  gap: 2px;

  .progress-name {
    font-weight: 600;
  }

  .progress-container {
    width: 100%;
    height: 26px;
    position: relative;
    background-color: #f8f8fa;

    .progress-filled-percentage {
      border-radius: 0 20px 20px 0;
      height: 100%;
      background-image: linear-gradient(to right, #b3d99b, #00a464);
      transition: width 0.5s ease;
    }
    .progress-remaining {
      display: flex;
      align-items: center;
      position: absolute;
      top: 0;
      left: 15px;
      height: 100%;
    }
    .progress-remaining-left {
      display: flex;
      align-items: center;
      position: absolute;
      top: 0;
      right: 15px;
      height: 100%;
    }
  }
}
