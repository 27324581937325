.field-title-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  border-radius: 5px;
  width: 100%;
  background-color: #fff;
  color: #323338;
  padding: 0 20px;

  .field-header-right-actions {
    display: flex;
    align-items: center;
    gap: 1rem;
    .icon-container {
      height: 25px;
      // svg {
      //   fill: #ffffff;
      // }
    }
  }
}
