.container-scroll-arrow-container {
  display: grid;
  place-items: center;
  border-radius: 1.5px;
  height: 10px;
  aspect-ratio: 1;
  background-color: #f5f5f7;
  cursor: pointer;
  z-index: 999;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.16);

  svg {
    fill: #000;
    height: 7.5px;
    width: 8px;
    position: absolute;
    transform: translateY(0.3px);
  }
}

.container-scroll-arrow-container.LEFT {
  position: sticky;
  left: 0;
  top: 50%;
  transform: scale(3.2) translateX(1px);
}

.container-scroll-arrow-container.RIGHT {
  position: sticky;
  right: 0;
  top: 50%;
  transform: rotate(180deg) scale(3.2) translateX(1px);
}

.container-scroll-arrow-container.arrow-container-dark {
  background-color: #3a3c4e;

  svg {
    fill: #fff;
  }
}
