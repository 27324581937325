@import "../../../../../../index.scss";

.logs-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 10px;

  .logsCard {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    padding: 6.5px 10px;
    border-radius: 5px;
    background: #2973ac;
    color: #fff;

    .text {
      flex-grow: 1;
      margin: 0;
    }

    .filter-icon {
      padding: 4px;
      border-radius: 4px;
      font-size: 16px;
      background-color: #f8f8fa;
      color: #1264a3;

      &:hover {
        background-color: #dfdfe1;
      }
    }

    .fetch-icon {
      height: 24px;
      width: 24px;
      padding: 2px;
      border-radius: 4px;
      color: #1264a3;
      cursor: pointer;

      path {
        fill: #1264a3;
      }
    }
  }

  .logs-box {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 16px;
    padding-top: 6px;

    .single-log {
      padding: 6.5px 10px;
      font-size: 14px;
      color: #333;
      background-color: #f8f8fa;

      span {
        font-weight: 700;
      }
    }

    .single-log-noData {
      margin: 5px auto;
      color: gainsboro;
      font-size: 18px;
    }
  }
}

.logs-containerDark {
  .logs-box {
    .single-log {
      background-color: $cardDarkHeaderColor;
      color: #fff;
    }
  }
}

.videoTutorials-filterModal {
  .ant-modal-content {
    .ant-modal-header {
      background-color: #f7f8fa;
    }

    .ant-modal-body {
      label {
        padding-left: 4px;
        font-size: 16px;
        font-weight: 600;
        color: #323338;
      }
    }

    .ant-modal-footer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
}

.videoTutorials-filterModalDark {
  .ant-modal-content {
    .ant-modal-close .ant-modal-close-x svg {
      fill: #f2f2f2;
    }

    .ant-modal-header {
      background-color: $cardDarkBackgroundColor;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      background-color: $cardDarkHeaderColor;

      label {
        color: #fff;
      }
    }
    .ant-modal-footer {
      background-color: $cardDarkHeaderColor;
    }
  }
}
