.tree-graph-container {
  margin: 0 0 5px 5px;
  background-color: white;
  position: relative;
  overflow: auto;

  .vertical-line {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 10px;
    width: 1px;
    background-color: #323338;
  }
}

.tree-graph-container-dark {
  margin: 0 0 5px 5px;
  background-color: #1f212d;
  position: relative;
  overflow: auto;

  .vertical-line-dark {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 10px;
    width: 1px;
    background-color: #fff;
  }
}
