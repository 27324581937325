@use "src/index.scss" as index;

.fleet-service-modal-modal {
  height: fit-content !important;
  width: fit-content !important;
  max-height: calc(100vh - 20px);
  max-width: calc(100vw - 20px);
  .ant-modal-content {
    min-height: 40px;
    min-width: 500px;
    max-height: calc(100vh - 20px);
    max-width: calc(100vw - 20px);
    .ant-modal-close {
      height: 45px;
      width: 45px;
      .ant-modal-close-x {
        height: 45px;
        width: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .ant-modal-header {
      height: 45px;
      display: flex;
      align-items: center;
      background-color: #f8f8fa;
    }
    .ant-modal-body {
      min-height: calc(180px - 97px);
      min-width: 300px;
      .ant-form {
        label {
          font-weight: 600;
          font-size: 16px;
        }
        .labeledInputLabel {
          font-size: 14px;
        }
        .cost {
          display: flex;
          justify-content: space-between;
          gap: 20px;
          .ant-input-number {
            width: 100%;
          }
        }
      }
    }
    .ant-modal-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row-reverse;
      flex-wrap: nowrap;
      width: 100%;
      .mondayButtonStyle {
        width: 150px;
      }
    }
  }
}

.fleet-service-modal-modal.fleet-service-modal-modal-dark {
  .ant-modal-content {
    background-color: index.$mainDarkBackgroundColor;
    .ant-modal-close .ant-modal-close-x svg {
      fill: #fff !important;
    }
    .ant-modal-header {
      background-color: index.$cardDarkBackgroundColor;
      .ant-modal-title {
        color: #fff;
      }
    }
  }
}
