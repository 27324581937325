@import "../../../../index.scss";

$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.estimationModalContainer {
  .footerContainer {
    padding: 10px 0px !important;
  }
  .borderedTextCard {
    border: none;
    border-radius: 0;
    border-top: 1px solid #323338;
    align-items: center;
    .cardTitle {
      font-size: 16px;
      color: #323338;
    }
  }
  .assigneesToggleSwitch {
    position: absolute;
    top: -5px;
    right: -15px;
  }

  // .ant-modal-body {
  //   padding-bottom: 55px;
  // }

  .modalBodyContainer {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 15px;
    padding-top: 10px;
    max-height: calc(100vh - 180px);
    overflow-x: hidden;
    overflow-y: auto;
  }
  .inputFieldContainer {
    display: grid;
    grid-template-columns: 250px 250px;
  }
  .labeledInputContainer {
    padding-right: 15px;

    .labeledInputLabel {
      @include text(14px, 600, 0.21px, #383554);
      margin-bottom: 2px;
    }
  }
  .ant-picker {
    width: 100%;
  }

  .ant-input,
  .ant-select-selector,
  .ant-picker {
    border: 0 !important;
    background-color: #f5f5f7 !important;
    border-radius: 5px !important;
  }

  .membersButton {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10;
    position: relative;
    top: 23px;
    width: 235px;
  }
  .newFooterContainer {
    button {
      width: 150px;
    }
  }
}

.estimationModalContainerDark {
  .modalBodyContainer {
    .estimationInformation {
      border-top-color: #f2f2f2;
      .cardTitle {
        background-color: $cardModalBodyDark;
        color: #f2f2f2;
      }
      .childrenContainer {
        .inputFieldContainer {
          .labeledInputLabel {
            color: #f2f2f2;
          }
          .ant-input {
            background-color: $inputModalDark !important;
            color: #f2f2f2;
            &::placeholder {
              color: #acacca;
            }
          }
          .ant-select {
            .ant-select-selector {
              border: none;
              background-color: $inputModalDark !important;
              color: #f2f2f2;
              .ant-select-selection-search-input {
                background-color: $inputModalDark;
                color: #f2f2f2;
              }
              .ant-select-selection-placeholder {
                color: #acacca;
                z-index: 2;
              }
            }
            .ant-select-arrow svg {
              fill: #f2f2f2;
            }
          }
          .ant-form-item-control-input-content {
            .ant-picker {
              background-color: $inputModalDark !important;
              .ant-picker-input {
                background-color: $inputModalDark;
                input {
                  color: #f2f2f2;
                  &::placeholder {
                    color: #acacca;
                  }
                }
                .ant-picker-suffix svg {
                  color: #acacca;
                }
              }
            }
            .ant-picker-dropdown {
              .ant-picker-panel-container {
                background-color: $inputModalDark;
                .ant-picker-panel {
                  .ant-picker-header {
                    color: #f2f2f2;
                    .ant-picker-header-super-prev-btn,
                    .ant-picker-header-prev-btn,
                    .ant-picker-header-next-btn,
                    .ant-picker-header-super-next-btn {
                      color: #acacca;
                    }
                  }
                  .ant-picker-body {
                    .ant-picker-content {
                      th {
                        color: #f2f2f2;
                      }
                      .ant-picker-cell {
                        color: #acacca;
                      }
                      .ant-picker-cell-in-view {
                        color: #f2f2f2;
                      }
                    }
                  }
                }
                .ant-picker-footer {
                  border-top-color: $cardModalBodyDark;
                }
              }
            }
          }
        }
      }
    }
    .formGridContainer {
      .filtersCard {
        border-top-color: #f2f2f2;
        .cardTitle {
          color: #f2f2f2;
          background-color: $cardModalBodyDark;
        }
        .filtersContainer {
          .labeledInputLabel {
            color: #f2f2f2;
          }
          .ant-select {
            .ant-select-selector {
              border: none;
              background-color: $inputModalDark !important;
              color: #f2f2f2;
              .ant-select-selection-search-input {
                background-color: $inputModalDark;
                color: #f2f2f2;
              }
              .ant-select-selection-placeholder {
                color: #acacca;
                z-index: 2;
              }
            }
            .ant-select-arrow svg {
              fill: #f2f2f2;
            }
          }
        }
      }
    }
    .dark-ag-theme {
      .ag-root-wrapper-body {
        .ag-header {
          .ag-header-cell {
            background-color: $cardModalHeaderDark;
            .ag-header-cell-text {
              color: #f2f2f2 !important;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1700px) {
  .estimationModalContainer {
    .borderedTextCard {
      padding: 20px 0 0;
    }
    .formGridContainer {
      margin-left: 2rem;
      width: 70rem;
      .ant-select-selector {
        width: 250px;
      }
    }
  }
}

@media (max-width: 1280px) {
  .estimationModalContainer {
    .modalBodyContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .borderedTextCard .childrenContainer {
      justify-content: center;
    }
    .footerContainer {
      display: flex;
      justify-content: space-between;
    }
  }
}

@media (max-width: 550px) {
  .estimationModalContainer {
    padding: 20px 0px !important;
    .inputFieldContainer {
      display: grid;
      grid-template-columns: 250px;
    }
  }
}
