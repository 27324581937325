.custom-progress-bar {
  background-color: #d8d8d8;
  border-radius: 5px;
  position: relative;
  margin: 5px 0;
  height: 32px;
  min-width: 210px;
  width: fit-content !important;
  .progress-done {
    border-radius: 5px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 0px;
    opacity: 0;
    transition: 1s ease 0.5s;
  }
}

.breakdown-prop-progress {
  background-color: #d8d8d8;
  border-radius: 2px;
  position: relative;
  margin: 9px 0;
  height: 20px;
  min-width: 160px;
  width: fit-content !important;
  .breakdown-progress-done {
    border-radius: 2px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 0px;
    opacity: 0;
    transition: 1s ease 0.3s;
  }
}

.custom-progress-bar-small {
  background-color: #d8d8d8;
  border-radius: 5px;
  position: relative;
  margin: 5px 0;
  height: 32px;
  width: 8.5vw;
  .progress-done {
    border-radius: 5px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 0px;
    opacity: 0;
    transition: 1s ease 0.5s;
  }
}
