$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.breakdown-container-modal-schedule {
  animation: breakdownContainerAnim 1s;
}

.breakdowns-modal-dark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      .labeledInputContainer {
        .labeledInputLabel {
          color: #fff;
        }
        .ant-input {
          background-color: $inputModalDark !important;
          color: #fff;
          &::placeholder {
            color: #acacca;
          }
        }
        .ant-input-affix-wrapper {
          height: 32px;
          background-color: $inputModalDark !important;
          .ant-input {
            background-color: $inputModalDark !important;
            color: #fff;
            &::placeholder {
              color: #acacca;
            }
          }
          .ant-input-suffix svg {
            fill: #fff;
          }
        }
        .ant-select {
          .ant-select-selector {
            background-color: $inputModalDark !important;
            .ant-select-selection-search-input {
              color: #fff;
            }
            .ant-select-selection-item {
              color: #fff;
            }
            .ant-select-selection-placeholder {
              color: #acacca;
            }
          }
          .ant-select-clear {
            background-color: $inputModalDark;
            svg {
              fill: #acacca;
            }
          }
          .ant-select-arrow svg {
            fill: #fff;
          }
        }
      }
      table {
        th {
          color: #fff;
        }
        td {
          .ant-input {
            background-color: $inputModalDark !important;
            color: #fff;
            &::placeholder {
              color: #acacca;
            }
          }
          color: #fff;
        }
      }
    }
  }
}

@keyframes breakdownContainerAnim {
  from {
    opacity: 0;
    filter: blur(2);
  }
  to {
    opacity: 1;
    filter: blur(0);
  }
}
