.two-factor-authenticator-body {
  height: 100vh;
  width: 100vw;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  background-color: white;

  .title {
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 700px;
  }

  .authenticator-container {
    width: 50vw;
    height: 400px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }

  .authenticator-apps-container {
    margin-top: 20px;
    align-items: center;
    gap: 20px;
  }

  .authenticator-apps {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .information-apps {
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
  }

  .information {
    align-items: center;
    flex-direction: column;
    gap: 10px;
  }

  .qrCode {
    margin-top: 20px;
    align-self: center;
  }

  .otpInput {
    align-self: center;
    .ant-otp-input {
      width: 50px;
      height: 50px;
      padding-inline: 15px !important;
    }
  }
  .footerButtons {
    gap: 10px;
    padding: 10px;
  }

  .nextStep {
    align-self: flex-end;

    svg {
      fill: white;
    }
  }
}
