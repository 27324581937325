$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.custom-modal-mask {
  background: rgba(33, 37, 75, 0.4);
  backdrop-filter: blur(5px);
  width: 100vw;
  height: 100vh;
  z-index: 9990;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: fixed;
}

.custom-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 9999;
  transform: translate(-50%, -50%);
  .custom-modal-content {
    display: flex;
    flex-direction: column;
    width: fit-content;
    height: fit-content;
    background: #fff;
    border-radius: 10px;
    .custom-modal-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #f8f8fa;
      border-radius: 10px 10px 0px 0px;
      padding: 20px 11.5px;
      height: 45px;
      .custom-modal-header-title {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #323338;
        padding: 0;
        margin: 0;
      }
      .custom-modal-header-close {
        cursor: pointer;
        width: 32px;
        height: 32px;
        background: transparent;
        border: 0;
        svg {
          fill: #323338;
        }
      }
    }
    .custom-modal-body {
      padding: 20px 20px 5px 20px;
      height: 100%;
    }
    .custom-modal-footer {
      padding: 5px 20px 20px 20px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 1rem;
    }
  }
}

.custom-modal-dark {
  .custom-modal-content {
    background-color: $cardModalBodyDark;
    overflow: hidden;
    .custom-modal-header {
      background-color: $cardModalHeaderDark;
      .custom-modal-header-title {
        color: #fff;
      }
      .custom-modal-header-close svg {
        fill: #fff;
      }
    }
    .custom-modal-body {
      background-color: $cardModalBodyDark;
    }
  }
}
