@import "../../../../../../index.scss";

.formRequestCard {
  padding: 20px;
  background-color: #fff;
  border-radius: 20px;
  min-width: 300px;
  min-height: 150px;
  animation: shake 1000ms both;
  @include flex(center, space-between, column);

  .btnContainer {
    @include flex(center, space-evenly);
    width: 100%;
    .mondayButtonContainer {
      height: 30px;
    }
  }

  .requestContent {
    margin-bottom: 20px;

    .cargoLine {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;
      line-height: unset;
      height: fit-content;

      span,
      strong {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 2rem !important;
      }
    }
  }

  .requestCardHeader {
    @include flex(center, center);
    position: relative;
    width: 100%;
    margin-bottom: 10px;
  }

  .actionIcon {
    @include flex(center, center);
    position: absolute;
    right: 0;
    width: 35px;
    height: 35px;
    cursor: pointer;
    top: 10px;

    &:hover {
      background-color: #f4f5f6;
      border-radius: 50%;
    }
  }

  .copyIcon {
    @include flex(center, center);
    position: absolute;
    left: 0;
    top: 10px;
    width: 35px;
    height: 35px;
    cursor: pointer;
  }

  .requestInfo {
    margin-bottom: 10px;
    color: #323338;
  }

  .requestMetaData {
    @include flex(baseline, space-between, row-reverse);
  }

  .emailIcon {
    fill: #1264a3;
    margin-right: 5px;
    height: 13px;
  }
  .requestReference {
    @include flex(center, flex-start);
    color: #1264a3;
    cursor: pointer;
    font-weight: 600;
    font-size: 12px;
  }

  .jobsiteAddress {
    color: #1264a3;
    cursor: pointer;
    font-weight: 600;
  }
  .requestDate {
    color: #887b81;
    font-size: 12px;
  }

  .requestType {
    border-radius: 5px;
  }

  .recordIcon {
    justify-self: center;
  }

  .actionIcon {
    justify-self: flex-end;
  }

  .tickIcon {
    width: 23px;
  }

  .declineBtn {
    @include mondayButton(#fe4c4a, #eb4345);
  }

  .approveBtn {
    @include mondayButton(#71cf48, #68c142);
  }
}

.approvalRequestMenuOption {
  height: fit-content;
  .ant-popover-inner {
    height: fit-content;
    overflow: hidden;
  }
  .ant-popover-inner-content {
    padding: 0 !important;
    overflow: hidden !important;
    border-radius: 8px !important;
  }
  .requestMenuOption {
    cursor: pointer;
    display: block;
    padding: 16px 12px;
    &:hover {
      background-color: #f1f1f1;
    }
  }
}

@keyframes shake {
  from,
  to {
    transform: translate3d(0, 0, 0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translate3d(-10px, 0, 0);
  }

  20%,
  40%,
  60%,
  80% {
    transform: translate3d(10px, 0, 0);
  }
}
