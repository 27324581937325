.user-auth-view-container {
    width: 150px;


    @media screen and (max-width: 1440px) {

        .mondayButtonText,
        .mondayButtonDivider {
            display: none;
        }

        width: 32px;

    }

    &.mobile {


        .mondayButtonText,
        .mondayButtonDivider {
            display: none;
        }

        width: 32px;

        .button-styles {
            width: 32px;

        }
    }

    .mondayButtonDivider {
        background-color: #0F5C97 !important;

    }

    .button-styles {
        background-color: #1264A3;
        width: 100%;

        &:active,
        &:visited,
        &:focus {
            background-color: #1264A3;

            .mondayButtonDivider {
                background-color: #1264A3 !important;
            }
        }

        &:hover {
            background-color: #0F5C97 !important;
            opacity: 0.9;

            .mondayButtonDivider {
                background-color: #1264A3 !important;
            }
        }

    }


    svg {
        height: 16px !important;
        width: 16px !important;
        fill: #fff !important;
    }
}