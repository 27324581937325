.calendar-container {
  width: 301px;
  height: 480px;
  background-color: #fff;
  border: 1px solid transparent;
  box-sizing: border-box;
  box-shadow: 4px 1px 4px rgba(0, 0, 0, 0.16);
  position: relative;
}

.datePickerHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px;
  position: relative;
}

.datePickerHeader::after {
  content: "";
  position: absolute;
  bottom: -20px;
  right: 0;
  width: 100%;
  border: 1px dashed #707070;
}

.currentMonth {
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #323338;
}

.leftRight-buttons {
  display: flex;
  gap: 20px;
  align-items: center;
}

.calendar-container .navIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  color: #fff;
  background: #1264a3;
  border-radius: 1px;
}

.weekContainer {
  margin: 20px;
  width: calc(100% - 40px);
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #323338;
  position: relative;
}

.weekContainer2 {
  margin: 0 20px;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #323338;
  position: relative;
}

.weekContainer2::after {
  content: "";
  position: absolute;
  top: -65px;
  bottom: 0;
  left: -20px;
  border: 1px dashed #707070;
}

.day {
  margin: 5px;
  width: 28px;
  height: 28px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #323338;
}

.day:hover {
  background-color: #ebedf0;
  border-radius: 3px;
}

.weekNames {
  margin: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #323338;
  cursor: default;
}

.weekNames:hover {
  background-color: #fff;
}

.inactiveDay {
  width: 28px;
  height: 28px;
  visibility: hidden;
  color: #9e9e9e;
  pointer-events: none;
}

.today {
  border: 1.5px solid #1264a3;
  border-radius: 3px;
}

.selectedDay {
  color: white;
  background: #1264a3;
  border-radius: 3px;
  pointer-events: none;
}

.disableDates {
  color: #9e9e9e;
  border-radius: 3px;
  pointer-events: none;}

.betweenDay {
  color: white;
  background: rgba(18, 100, 163, 0.2);
  color: #1264a3;
  border-radius: 3px;
}

.calendar-container .buttons {
  position: absolute;
  left: 15px;
  bottom: 20px;
  width: calc(100% - 40px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px auto 0px auto;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
}

.calendar-container .buttons button {
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transform-origin: 50% 0%;
}

.okCancel-btn {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 32px;
}

.dateRange-btn {
  background-color: #1264a3;
}

.calendar-container .dateRange-inputs .addDate-paragraph {
  color: #717171;
  font-weight: 400;
}

.calendar-container .buttons .cancel-btn {
  padding: 7px 10px;
  background: #fe4c4a;
}

.calendar-container .buttons .ok-btn {
  padding: 7px 20px;
  background: #1264a3;
}

.calendar-container .buttons .okBtn-disable {
  cursor: default;
}

.calendar-container .buttons .okBtn-disable:hover::after {
  content: "Select Date";
  position: absolute;
  top: -20px;
  right: -5px;
  width: 70px;
  height: 25px;
  color: red;
}

.calendar-container .buttons button:hover {
  filter: brightness(120%);
  scale: 1.02;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.showCalendarResult {
  position: absolute;
  top: 490px;
}

.showCalendarResult h4 {
  color: gray;
}

.showCalendarResult h4 span {
  color: black;
  font-weight: 700;
}

.dateRange-inputs {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin-top: 10px;
  cursor: pointer;
}

.dateRange-inputs .showDate {
  padding: 2px 5px;
  width: 45%;
  text-align: center;
  border: 1px solid #1264a3;
}

.dateRange-inputs p {
  margin: 0;
  font-weight: 500;
}

