.jsonViewer {
  font-family: "Courier New", Courier, monospace;
  padding: 20px;
  strong {
    color: #007acc;
  }
  input,
  textarea {
    font-family: "Courier New", Courier, monospace;
    margin-left: 10px;
    padding: 5px;
  }
}
