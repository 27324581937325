$cardModalBodyDark: #1f212d;

.noDataFoundModal {
  .ant-modal-header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 687px;
    height: 80px;
    background: #fe4c4a;
    border-radius: 10px 10px 0px 0px;
  }
  .ant-modal-content {
    width: 687px;
    height: 242px;
    border-radius: 10px;
  }
  .no-data-div {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .no-data-texts {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .no-data-btns {
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
  }
  .noDataIcon {
    animation: noDataIcon 0.8s linear;
    animation-iteration-count: 2;
  }
}

.noDataFoundModalDark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-body {
      .no-data-texts {
        color: #fff;
      }
    }
  }
}

@keyframes noDataIcon {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
