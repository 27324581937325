.serviceDetailsBodyLight {
  background: white;
  overflow-y: auto;
  height: 650px;
  border-radius: 20px;
  .ant-collapse {
    margin: 20px;
    overflow: visible;
    border: 0;
    background: white;
    width: 1678px;

    .ant-collapse-item {
      background: #15568c;
      border-radius: 10px;
      margin-bottom: 10px;

      .ant-collapse-header {
        padding: 0;
        height: 45px !important;
        display: flex;
        align-items: center;
        cursor: default;

        .ant-collapse-arrow {
          margin-left: 15px;
          svg {
            fill: white;
          }
        }
        .extendRetractedButtonPanelHeader {
          border: 0;
          width: 30px;
          height: 30px;
          border-radius: 5px;
          background-color: royalblue;
        }

        .headerContainerDetailsPanel {
          display: flex;
          justify-content: space-between;
          width: 100%;
          .service-details-card-collapse-panel {
            margin: 0;
            display: flex;
            align-items: center;
            color: var(--unnamed-color-ffffff);
            text-align: left;
            letter-spacing: 0px;
            color: #ffffff;
            opacity: 1;
          }
        }
      }
    }
    .ant-collapse-content-active {
      background: #f8f8fa;
      border-bottom-left-radius: 9px;
      border-bottom-right-radius: 9px;
      overflow: hidden;
      border: 0;

      .ant-collapse-content-box {
        padding: 0;
      }
    }
  }
}

//details body dark mode
.serviceDetailsBodyDark {
  background: #1f212d;
  overflow-y: auto;
  border-radius: 0 !important;
  height: 650px;

  .ant-collapse {
    margin: 20px;
    overflow: visible;
    border: 0;
    background: #1f212d;
    width: 1678px;

    .ant-collapse-item {
      background: #12131b;
      border-radius: 10px;
      margin-bottom: 10px;
      border: 0 !important;

      .ant-collapse-header {
        padding: 0;
        height: 45px !important;
        display: flex;
        align-items: center;
        cursor: default;

        .ant-collapse-arrow {
          margin-left: 15px;
          svg {
            fill: white;
          }
        }

        .extendRetractedButtonPanelHeader {
          border: 0;
          width: 30px;
          height: 30px;
          border-radius: 5px;
          background-color: #15568c;
          margin-left: 8px;
        }

        .headerContainerDetailsPanel {
          display: flex;
          justify-content: space-between;
          width: 100%;

          .service-details-card-collapse-panel {
            margin: 0;
            display: flex;
            align-items: center;
            color: var(--unnamed-color-ffffff);
            text-align: left;
            letter-spacing: 0px;
            color: #ffffff;
            opacity: 1;
          }
        }
      }
    }
    .ant-collapse-content-active {
      background: #f8f8fa;
      border-bottom-left-radius: 9px;
      border-bottom-right-radius: 9px;
      overflow: hidden;
      border: 0;

      .ant-collapse-content-box {
        padding: 0;
      }
    }
  }
}
