.more-options-rentals-panel__dark {
  display: flex;
  flex-direction: row;
  gap: 20px;
  //   background-color: #2a2b3a;
}
.more-options-rentals-panel__light {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
