$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.logOutModal {
  width: calc(100vw - 20px) !important;
  max-width: 567px;

  .ant-modal-content {
    .ant-modal-close {
      height: 45px;
      width: 62px;

      .ant-modal-close-x {
        height: 45px;
        width: 62px;
        overflow: hidden;
        cursor: default;

        .warningIcon {
          width: 67px;
          height: 59px;
          display: flex;
          transform: rotate(-25deg) translate(0px, -10px);

          path {
            fill: #323338;
            opacity: 0.15;
          }
        }
      }
    }

    .ant-modal-header {
      height: 45px;
      display: flex;
      align-items: center;
      background-color: #f8f8fa;

      .ant-modal-title {
        .warningIcon {
          height: 15px;
          width: 17px;
          margin-right: 5px;

          path {
            height: 15px;
            width: 17px;
            fill: #323338;
          }
        }
      }
    }

    .ant-modal-body {
      padding: 10px 30px;
      text-align: center;
    }

    .ant-modal-footer {
      display: flex;
      justify-content: center;
      gap: 20px;
      padding-bottom: 20px;
    }
  }
}

.logOutModalDark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;

    .ant-modal-close .ant-modal-close-x .warningIcon path {
      fill: #2d3041 !important;
      opacity: 0.5;
    }

    .ant-modal-header {
      background-color: $cardModalHeaderDark;

      .ant-modal-title {
        span .warningIcon path {
          fill: #fff;
        }

        color: #fff;
      }
    }

    .ant-modal-body .logout-modal-body {
      color: #fff;
    }
  }
}

@media (max-width: 350px) {
  .logOutModal {
    .ant-modal-footer {
      flex-direction: column;
      gap: 10px !important;
      align-items: center;
    }
  }
}