.LoaderButton .spinning.glyphicon {
    margin-right: 7px;
    top: 2px;
    animation: spin 1s infinite linear;
  }
  @keyframes spin {
    from { transform: scale(1) rotate(0deg); }
    to { transform: scale(1) rotate(360deg); }
  }
  
  .blured_loader {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .statuses_blured_loader {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 0 35px;
  }