.serviceGroupItem {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;

  display: flex;
  align-items: center;
  gap: 20px;
  min-width: fit-content;

  cursor: pointer;
  border-radius: 5px;

  .disabled {
    pointer-events: none;
  }

  &:hover {
    opacity: 0.7;
  }

  .serviceContainer {
    display: flex;
    flex-direction: column;

    .groupItemLabel {
      font-weight: 600 !important;
      font-size: 14px !important;
      line-height: 17px;
      margin-bottom: 0 !important;
      height: 22px;
      color: #ffffff;
    }

    .groupItemSubLabel {
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: #ffffff;
    }
  }
}
