.darkModeToggleDivContainer {
  position: relative;
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  .firstSquare {
    position: absolute;
    width: 100%;
    height: 100%;
    background: white;
    // background: #1264a3;
    border-radius: 2px;
    z-index: 1;
    transform: rotate(-90deg);
    transition: transform 0.4s;
  }
  .secondSquare {
    position: absolute;
    width: 100%;
    height: 100%;
    background: white;
    // background: #1264a3;
    border-radius: 2px;
    z-index: 2;
    transform: rotate(135deg);
    transition: transform 0.4s;
  }

  .outerCircle {
    position: absolute;
    width: 64%;
    height: 64%;
    // background: white;
    background: #12131b;
    z-index: 3;
    border-radius: 50%;
  }

  .innerCircle {
    position: absolute;
    width: 56%;
    height: 56%;
    background: white;
    // background: #1264a3;
    z-index: 4;
    border-radius: 50%;
    margin-left: -32%;
    transition: margin 0.4s;
  }
}

.lightModeToggleDivContainer {
  position: relative;
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  .firstSquare {
    position: absolute;
    width: 100%;
    height: 100%;
    // background: #2a2828;
    background: white;
    border-radius: 2px;
    z-index: 1;
    transform: rotate(90deg);
    transition: transform 0.4s;
  }
  .secondSquare {
    position: absolute;
    width: 100%;
    height: 100%;
    // background: #2a2828;
    background: white;
    border-radius: 2px;
    z-index: 2;
    transform: rotate(-45deg);
    transition: transform 0.4s;
  }

  .outerCircle {
    position: absolute;
    width: 64%;
    height: 64%;
    // background: white;
    background: #1264a3;
    z-index: 3;
    border-radius: 50%;
  }

  .innerCircle {
    position: absolute;
    width: 48%;
    height: 48%;
    // background: #2a2828;
    background: white;
    z-index: 4;
    border-radius: 50%;
    transition: margin 0.4s;
  }
}
