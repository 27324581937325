@import "../../../index.scss";

.customComments {
  height: 100%;
  // width: stretch;
  // height: stretch;
  position: relative;
  @include flex(flex-start, flex-start, column);

  .commentsContainer {
    @include flex(flex-start, flex-start, column);
    height: 175px;
    flex-grow: 1;
    // flex-shrink: 6;
    flex-basis: 0;
    // height: 100%;
    width: 100%;
    overflow-y: auto;
  }

  .noCommentsLabel {
    @include flex(center, center);
    align-self: center;
    height: 100%;
  }

  .newCommentContainer {
    @include flex(flex-start, flex-start, column);
    width: 100%;
    flex-grow: 5;
    flex-basis: 0;
    border: 0.2px solid rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    // padding: 1rem;
    .richTextComp {
      flex: 1;
      width: 100%;
      .tox-tinymce {
        height: 100% !important;
      }
    }
  }

  .btnSection {
    margin-top: 10px;
    width: 100%;
    @include flex(center, flex-end);
    padding-right: 10px;
    .ant-btn {
      border-radius: 10px;
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  .ant-comment-nested {
    display: flex;
  }

  .ant-comment-actions {
    margin-top: 5px;
  }

  .ant-comment-comment {
    animation: fadeIn 1000ms both;
  }

  .newCommentBtn {
    position: absolute;
    bottom: 10px;
    right: 30px;
    border-radius: 10px;
  }
  .hidden {
    visibility: hidden;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
