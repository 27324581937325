.rich-editor-description {
  position: relative;
  width: 100%;
  // overflow: auto;
  .addDateHourBtns {
    position: absolute;
    display: flex;
    gap: 10px;
    top: 8px;
    right: 0px;

    .anticon {
      cursor: pointer;
      font-size: 17px;
    }
  }
  .react-quill-container {
    .ql-toolbar {
      height: 56px;
      width: calc(100% - 55px);
      border: none;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      padding: 5px 0;
      .ql-formats {
        display: flex;
        align-items: center;
        margin: 0 15px 5px 0;
      }
    }
    .ql-container {
      position: relative;
      margin-top: 10px;
      background: #fff;
      border: none;
      box-sizing: border-box;
      .ql-editor {
        resize: vertical;
        padding: 7px 15px !important;
        // border: 1px solid #e2e4ec !important;
        min-height: 79px !important;
        background: #ffffff;
        border-radius: 5px;
        font-size: 0.875rem !important;
        max-height: 500px;

        p {
          color: #323338;
        }
      }
      .ql-mention-list-container {
        transform: translateY(-100%);
        // height: 200px;
        max-height: 266px;
        top: 0px !important;
        left: 0 !important;
      }
    }
  }
}

.ReactQuillRichTextDark {
  .addDateHourBtns {
    color: #f8f8fa !important;
  }

  .ql-toolbar {
    .ql-formats {
      color: #fff;
      .ql-picker-label {
        color: #fff;
        &::before {
          color: #fff;
        }
        .ql-stroke {
          stroke: #fff;
        }
      }
      button .ql-stroke {
        stroke: #fff;
      }
    }
  }
  .ql-container {
    background: #2a2b3a !important;
    .ql-editor {
      background: #2a2b3a !important;
      p,
      ol,
      blockquote,
      ul {
        color: #f8f8fa !important;
      }
      .mention {
        background-color: #000000;
      }

      &::before {
        color: #f8f8fa9f;
      }
    }
    .ql-mention-list {
      background: #1f212d;
      color: #f8f8fa;
    }
    .ql-mention-list-item.selected {
      background: #12131b;
    }
  }
}
