@import "../../../../../../../index.scss";

.priceTablePPURenderer {
  @include flex(center, space-between);

  .ppuDifferenceContainer {
    @include flex(center, flex-start);
    font-size: 12px;
    .higher {
      svg {
        fill: #71cf48;
      }
      transform: rotate(90deg);
    }

    .lower {
      transform: rotate(-90deg);

      svg {
        fill: #fe4c4a;
      }
    }
  }

  .suggestionContainer {
    height: 25px;
    padding: 0 5px;
  }
}
