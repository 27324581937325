.chargedIndicatorContainerApplications {
  width: stretch;
  height: stretch;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  margin-left: -15px;
  margin-right: -15px;

  .allChargedIconHolder {
    svg {
      width: 25px;
      height: 25px;
      fill: #68c142;
    }
  }
  .partiallyChargedIconHolder {
    svg {
      width: 25px;
      height: 25px;
      fill: #fcc94a;
    }
  }

  .notChargedIconHolder {
    svg {
      width: 20px;
      height: 20px;
      fill: #fe4c4a;
    }
  }
}
