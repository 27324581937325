.rentalsPreviewDark {
  //   .ant-modal {
  //     width: fit-content !important;
  //   }

  .exportToButton {
    border: 0;
    width: 32px;
    height: 32px;
    border-radius: 5px;
    margin-right: 20px;
    background-color: #1264a3;
    &:hover {
      background-color: #0d4c7c;
      border-color: #0d4c7c;
      transition: 0.2s;
    }
  }
  .ag-wrapper.ag-input-wrapper.ag-text-field-input-wrapper {
    color: black !important;
  }
  .ant-modal-content {
    border-radius: 10px;
    overflow: hidden;
    width: 100%;

    .ant-modal-close-x {
      justify-content: center;
      align-items: center;
      display: flex;
      height: 45px;
      width: 45px;
      padding: 0;
      svg {
        fill: white;
      }
    }
  }

  .ant-modal-title {
    color: white;
  }

  .ant-modal-close {
    height: 45px;
    width: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-modal-header {
    height: 45px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    background-color: #12131b;
    color: white !important;
    border-bottom: 0;
  }

  .ant-modal-footer {
    height: 45px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 15px;
    background-color: #12131b;
    border-top: 0;
  }

  .ant-modal-body {
    background-color: #1f212d;
  }
  .ag-cell {
    // justify-content: flex-start;
  }
}

.exportToButton {
  border: 0;
  width: 32px;
  height: 32px;
  border-radius: 5px;
  margin-right: 20px;
  margin-left: 10px;
  background-color: #1264a3;
  &:hover {
    background-color: #0d4c7c;
    border-color: #0d4c7c;
    transition: 0.2s;
  }
}
