@import "../../../../../index.scss";

.opportunityAltAddContainer {
  max-height: 545px;
  overflow-y: auto;
  .placesInputContainer {
    // @include flex(center, flex-start);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    flex-direction: column;

    .addressInput {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;
      flex-wrap: nowrap;
      width: 100%;

      //   .auto {
      .placesInput {
        width: 80%;
        width: 100% !important;
        // }
      }
    }

    .actionButtons {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 20%;

      .addressCollapse {
        cursor: pointer;
      }

      .addHide {
        animation: hide 0.3s ease-in-out forwards;
      }

      .addShow {
        animation: show 0.3s ease-in-out forwards;
      }

      @keyframes show {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(90deg);
        }
      }

      @keyframes hide {
        from {
          transform: rotate(90deg);
        }
        to {
          transform: rotate(0deg);
        }
      }
    }

    .addressDetailsContainer {
      width: 375px;
      overflow: hidden;
      transition: height 0.5s ease-in-out;

      .label {
        font-size: 14px;
        font-weight: 600;
        color: #383554;
        margin-left: 10px;
      }

      .additionalInfo {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .state {
          width: 60%;
        }

        .postalCode {
          width: 35%;
        }
      }
    }

    .detailsShow {
      height: 210px;
    }

    .detailsHide {
      height: 0;
    }
  }
  .removeIcon {
    color: #ababab;
    font-size: 18px;
    margin-right: 5px;
  }
  .placesInput {
    margin-right: 15px;
    width: 375px;
  }
  .addButton {
    color: white;
    background-color: #09a734;
  }
  .controllerButtons {
    @include flex(center, flex-start);
    .ant-btn {
      width: calc(100% / 3);
      margin-right: 5px;
    }
  }
}
