$headerDarkBackground: #12131b;
$bodyDarkBackground: #1f212d;
$inputModalDark: #12131b;

.newQuestionForFleetModal {
  .ant-modal-content {
    border-radius: 10px;
    .ant-modal-close {
      height: 45px;
      width: 45px;
      .ant-modal-close-x {
        height: 45px;
        width: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          fill: #333238;
        }
      }
    }
    .ant-modal-header {
      height: 45px;
      display: flex;
      align-items: center;
      background-color: #f5f6f8;
      border-radius: 10px 10px 0px 0px;
      border: none;
      .ant-modal-title {
        font-size: 16px;
        font-weight: 600;
        color: #333238;
      }
    }
    .ant-modal-body {
      max-height: calc(100vh - 280px);
      overflow-y: auto;
      overflow-x: hidden;
      .requiredSelect {
        .ant-select-selector {
          border: none;
          border-radius: 5px;
          background-color: #f4f5f6;
        }
      }
      .ant-form-item {
        margin-bottom: 10px;
        .ant-form-item-label {
          font-weight: 600;
          label {
            font-size: 15px;
          }
        }
      }
    }
    .ant-modal-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: none;
    }
  }
  .docOptionsContainer {
    .optionField {
      width: calc(100% - 60px);
    }
    .added {
      top: 28px;
    }

    .notAdded {
      top: 28px;
    }
  }
}

.newQuestionForFleetModalDark {
  .ant-modal-content {
    background-color: $bodyDarkBackground;
    .ant-modal-close .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $headerDarkBackground;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      .ant-form-item-label label {
        color: #fff;
      }
      .ant-input {
        background-color: $inputModalDark !important;
        color: #fff;
        &::placeholder {
          color: #acacca;
        }
      }
      .ant-select {
        .ant-select-selector {
          background-color: $inputModalDark !important;
          .ant-select-selection-search-input {
            color: #fff !important;
          }
          .ant-select-selection-item {
            color: #fff !important;
          }
          .ant-select-selection-placeholder {
            color: #acacca;
          }
        }
        .ant-select-arrow {
          svg {
            fill: #fff !important;
            path {
              fill: #fff !important;
            }
          }
        }
      }
    }
  }
}

.childContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  animation: scopesFadeInBottom 500ms both;
  .ant-form-item-row {
    width: 100%;
    .ant-form-item-control-input-content {
      display: flex;
      .childTitle {
        width: calc(100% - 40px);
      }
    }
  }
  .removeButton {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fe4c4a;
    border-radius: 5px;
    margin: 2px 0px 0px 10px;
    cursor: pointer;
  }
}
.addChildContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  .ant-form-item-row {
    width: 100%;
    .ant-form-item-control-input-content {
      display: flex;
      .addChildInput {
        width: calc(100% - 40px);
      }
    }
  }
  .addButton {
    width: 30px;
    height: 30px;
    background-color: #71cf48;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2px 0px 0px 10px;
    cursor: pointer;
    transition: 0.4s ease;
  }
  .disabled {
    @extend .addButton;
    background-color: #cfcfcf;
  }
}
