$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.dark-mode-previous-rental {
  border-radius: 5px 5px 0 0;
  .agGridRentalsDetailsStyleDark .ag-ltr .ag-column-drop-horizontal {
    padding-left: 18px;
    background-color: $cardModalBodyDark;
    color: white;
  }
  .agGridRentalsDetailsStyleDark .ag-column-drop-empty-message {
    color: white;
  }
  .agGridRentalsDetailsStyleDark .ag-paging-panel {
    background-color: $cardModalBodyDark;
  }
  .ant-modal-content {
    border-radius: 10px;
    background-color: $cardModalBodyDark;
    .ant-modal-close-x svg {
      fill: #fff;
    }
  }
  .ant-modal-header {
    height: 45px;
    display: flex;
    align-items: center;
    background-color: $cardModalHeaderDark;
    border-bottom: none;
    border-radius: 10px 10px 0 0;
    .ant-modal-title {
      color: #fff;
      display: flex;
      justify-content: center;
      font-size: 16px;
      font-weight: 600;
    }
  }
  .ant-modal-body {
    background-color: $cardModalBodyDark;
    height: 600px;
    overflow-y: auto;
  }
  .ant-modal-footer {
    background-color: $cardModalBodyDark;
    border-top: none;
    border-radius: 0 0 10px 10px;
    .previousRentalInfoFooter {
      display: flex;
      justify-content: space-between;
    }
    .ant-btn {
      background-color: $inputModalDark;
      border: 1px solid $inputModalDark;
      color: white;
      &:hover {
        background-color: $inputModalDark;
        border: 1px solid $inputModalDark;
        color: white;
      }
    }
  }
  span.ant-modal-close-x {
    color: white;
  }
  .ag-header-group-cell-label,
  .ag-header-cell-label {
    justify-content: center;
  }
}

.light-mode-previous-rental {
  border-radius: 5px 5px 0 0;
  .agGridRentalsDetailsStyleDark .ag-ltr .ag-column-drop-horizontal {
    padding-left: 18px;
    background-color: #ebeff1;
    color: white;
  }
  .agGridRentalsDetailsStyleDark .ag-column-drop-empty-message {
    color: white;
  }
  .agGridRentalsDetailsStyleDark .ag-paging-panel {
    background-color: #f8f8fa;
  }
  .ant-modal-content {
    border-radius: 10px;
  }
  .ant-modal-header {
    height: 45px;
    display: flex;
    align-items: center;
    background-color: #f8f8fa;
    border-bottom: none;
    border-radius: 10px 10px 0 0;
    .ant-modal-title {
      // color: white;
      display: flex;
      justify-content: center;
      font-size: 16px;
      font-weight: 600;
      color: #333238;
    }
  }
  .ant-modal-body {
    background-color: white;
    height: 600px;
    overflow-y: auto;
    .agGridRentalsDetailsStyleDark {
      border: 1px solid white;
    }
  }
  .ant-modal-footer {
    // background-color: #f8f8fa;
    // border-top: #1f212d;
    border-radius: 0 0 10px 10px;
    .previousRentalInfoFooter {
      display: flex;
      justify-content: space-between;
    }
  }
  span.ant-modal-close-x {
    // color: white;
  }
  .ag-header-group-cell-label,
  .ag-header-cell-label {
    justify-content: center;
  }
}
