@import "../../../config.scss";
@import "../../../index.scss";
@import '../../../assets/styles/breakpoints.scss';

:root {
  --primaryColor: #ffffff;
  --secondaryColor: #1264a3;
  --unselectedColor: #6f7782;
}
$mainDarkBackgroundColor: #20212d;
$cardDarkHeaderColor: #2a2b3a;
$cardDarkBackgroundColor: #12131b;
$inputDarkColor: #2a2b3a;
//ag-grid to fill available height
.ant-tabs {
  // height: 100% !important;
  height: calc(100vh - 70px);
  @include mobile{
    // header on <tablet is larger
    height: calc(100vh - 150px) !important;
  }

  .ant-tabs-content-holder {
    height: 100% !important;
    .ant-tabs-content {
      height: 100% !important;
      .ant-tabs-tabpane {
        height: 100% !important;
        .dynamicBasePage {
          height: 100% !important;
          .ag-paging-panel {
            gap: 75px !important;
          }
        }
        .dynamicBasePageDark {
          height: 100% !important;
          .ag-paging-panel {
            gap: 75px !important;
            height: 48px !important;
          }
          .ag-body {
            background-color: $mainDarkBackgroundColor !important;
          }
        }
      }
    }
  }
}

.filterTemplateModalContainer {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  gap: 10px;
  .radioContainer {
    span {
      padding: 0px;
    }
    .optionFilterContainer {
      display: flex;
      width: 200px;
      justify-content: space-between;
    }
  }
  .newFilterContainer {
    display: flex;
    gap: 20px;
  }
}
.dynamicBasePage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  // height: -webkit-fill-available;
  // height: calc(100dvh - 300px);
  flex-grow: 1;
  width: 100%;
  gap: 10px;
  height: 100%;
  // margin-top: 5px;
  // margin-top: 70px;
  // width: calc(100% - 250px);
  // margin-left: 250px;
  background: #f4f5f6;
  // height: calc(100vh - 70px);
  padding: 9px;
  // padding-top: 15px;
  transition: 0.2s ease-in;
  .filterModal {
    width: 1109px;
    height: 437px;
    position: absolute;
    left: 0;
    height: 0;
    top: 0;
    bottom: 0;
  }
  .titleCompBasePage {
    font: normal normal bold 18px Open Sans;
    text-align: left;
    letter-spacing: 0px;
    color: #323338;
    opacity: 1;
    position: fixed;
    top: 0px;
    left: var(--leftPosition);
    padding: 28px 30px 20px 20px;
    max-width: 500px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    z-index: 3;
    transition: 0.2s ease;
  }
  .filterContainers {
    .filterContainers-export-mobile {
      .pdfExcelDropdown {
        .emailBoxContainer {
          div {
            .EmailBoxContainer {
              right: 0;
              transform: translate(90px, 0px);
            }
          }
        }
      }
    }
    .filter__wrapper {
      width: max-content;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 5px;
      border-radius: 5px;
      font-weight: 600;
      // color: white;
      .trucks-modal-checkbox {
        .ant-checkbox-inner {
          width: 24px;
          height: 24px;
          background: #e6e6ee 0% 0% no-repeat padding-box;
          border-radius: 5px;
          border: none !important;
        }
        span.ant-checkbox.ant-checkbox-checked {
          .ant-checkbox-inner {
            background: #1264a3 0% 0% no-repeat padding-box;
          }

          .ant-checkbox-inner:after {
            width: 7px;
            height: 14px;
          }
        }
      }
    }
    display: flex;
    align-items: center;
    justify-content: space-between;
    // position: relative;
    padding: 10px;
    background: #ffffff;
    width: 100%;
    height: fit-content;
    min-height: 52px;
    // margin-top: 10px;
    box-shadow: 0px 1px 4px #00000029;
    // margin-bottom: 5px;
    margin-right: 15px;
    transition: all 0.5s ease-in-out;
    border-radius: 10px;
    overflow: hidden;
    overflow-x: auto;
    .filterContainerRight {
      display: flex;
      align-items: center;
      justify-content: center;
      height: fit-content;

      > button,
      > div,
      > span {
        margin-left: 10px !important;
      }
      .dynamicSelect {
        width: 180px;
        height: 32px;
        .ant-select-selector {
          background: #f5f5f7;
          border-radius: 5px;
          border: 0;
          .ant-select-selection-item {
            font-family: "Open Sans";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
          }
        }
      }
      // .ant-select-selector {
      // 	width: 200px;
      // 	border-radius: 5px;
      // 	height: 35px;
      // }
      // .saveFiltersButton {

      // }

      .filterIconsRight {
        cursor: pointer;
        // margin: 7px 0;
        width: 32px;
        height: 32px;
        background: #1264a3;
        fill: white;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;

        .anticon {
          > svg {
            width: 20px;
            height: 20px;
          }
        }
      }
      .newButton {
        // width: 150px;
        margin-left: 25px;
        margin-left: 0 !important;
        @include mondayButton(#71cf48, #68c142);
        > svg {
          width: 14px;
          height: 14px;
        }
        .mondayButtonText {
          text-align: left;
          font: normal normal 400 14px/7px Open Sans;
          letter-spacing: 0px;
          color: #fff;
          opacity: 1;
        }
      }
    }
    .filterContainerLeft {
      display: flex;
      align-items: center;
      gap: 10px;
      .IconStyle {
        display: flex;
        justify-self: center;
        align-self: center;
        width: 20px;
        cursor: pointer;
        height: 20px;
        #Component_209_1 {
          fill: #1f2a44;
        }
      }
      .customBlueButton {
        background-color: rgb(18, 100, 163);
        border: none;
        color: #fff;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;

        &:disabled {
          background-color: #f5f5f7;
          color: #c0c0c0;
        }
      }
      .liveButton {
        width: 120px;
        // margin-right: 25px;
        @include mondayButton(#ff4d4f, #eb4345);
        .mondayButtonText {
          text-align: left;
          font: italic normal 800 20px/24px Open Sans;
          letter-spacing: 0px;
          color: #ffffff;
          opacity: 1;
          padding-left: 10px;
          padding-right: 10px;
        }
      }
      .fetchAllButton {
        // margin-right: 20px;
        @include mondayButton(#1264a3, #0f5c97);
        .mondayButtonText {
          text-align: left;
          font: normal normal 400 14px/7px Open Sans;
          letter-spacing: 0px;
          color: #fff;
          opacity: 1;
        }
      }
      .filterIcons {
        cursor: pointer;
        // margin: 7px 0;
        width: 32px;
        height: 32px;
        background: #1264a3;
        fill: white;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;

        .anticon {
          > svg {
            width: 20px;
            height: 20px;
          }
        }
      }
      .filterButton {
        // margin-right: 20px;
        @include mondayButton(#1264a3, #0f5c97);
        .mondayButtonText {
          text-align: left;
          font: normal normal 400 14px/7px Open Sans;
          letter-spacing: 0px;
          color: #fff;
          opacity: 1;
        }
      }
      .clearButtonReverse {
        @include mondayButton(#fe4c4a, #eb4345);
        .mondayButtonText {
          text-align: left;
          font: normal normal 400 14px/7px Open Sans;
          letter-spacing: 0px;
          color: #fff;
          opacity: 1;
        }
        .mondayButtonIcon {
          fill: #fff;
        }
      }
      .clearButton {
        @include mondayButton(#f5f5f7, #e6e9ef, #323338);
        .mondayButtonText {
          text-align: left;
          font: normal normal 400 14px/7px Open Sans;
          letter-spacing: 0px;
          opacity: 1;
        }
        .mondayButtonIcon {
          fill: #323338;
        }
        .path {
          color: #323338;
        }
      }
      .searchBasePage {
        align-self: center;
        margin-left: 20px;
        width: 300px;
        // .ant-input-wrapper{
        //   display: flex;
        //   background-color: #FCFCFC !important;
        //   flex-direction: row-reverse;
        //   .ant-input {
        //     border-radius: 0 10px 10px 0;
        //     margin-left: 30px;
        //     background-color: inherit;
        //   }
        //   .ant-input-group-addon>button {
        //     border-radius: 10px 0 0 10px;
        //     border-right: 0;
        //     background-color: inherit;
        //     >span{
        //       margin-left: 5px;
        //     }
        //   }
        // }
      }
      .search-Div {
        align-self: center;
        display: flex;
        align-items: center;
        // margin-left: 20px;
        height: 32px !important;
        min-width: 150px;
        .ant-input-affix-wrapper {
          border: none;
        }
        .search-comp {
          background-color: #f4f5f6;
          .ant-input {
            background-color: #f4f5f6;
          }
          .ant-input-group-wrapper {
            border: none !important;
          }
          // .ant-input {
          // background-color: #f4f5f6 !important;
          // }
          .ant-input-affix-wrapper {
            height: 32px !important;
            border: none;
            background: #f5f5f7 0% 0% no-repeat padding-box;
            .ant-input {
              background-color: #f4f5f6;
              width: 200px;
              // background: #f5f5f7 0% 0% no-repeat padding-box;
              border: none;
            }
            .ant-input-prefix {
              .SearchLogoIcon {
                fill: #323338;
              }
            }
          }
          .ant-input-group-addon {
            height: 32px;
            border: none;
            background: #f5f5f7 0% 0% no-repeat padding-box;
            border-left: 1px solid rgba(0, 0, 0, 0.25);
            .select-comp {
              .ant-select-selector {
                height: 32px;
                width: 180px;
                border: none;
                border-radius: 0px 5px 5px 0px;
                opacity: 1;
                padding-left: 0px;
              }
            }
            .ant-select {
              .ant-select-arrow {
                color: #323338 !important;
              }
            }
          }
          // .ant-select {
          //   height: inherit;
          //   box-sizing: border-box;
          //   height: 32px;
          //   margin: 0;
          //   padding: 0;
          //   width: 100%;
          //   color: #323338;
          //   font-size: 14px;
          //   font-variant: tabular-nums;
          //   line-height: 1.5715;
          //   list-style: none;
          //   font-feature-settings: "tnum", "tnum";
          //   position: relative;
          //   display: inline-block;
          //   cursor: pointer;
          //   z-index: 2;
          // }
        }
      }
    }
  }
  .agGridHeaderDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: full;
    height: 45px;
    border-radius: 10px 10px 0px 0px;
    background-color: #f8f8fa;
    padding: 15px;
    margin-bottom: -10px;
    box-shadow: 0px 1px 4px 0px #00000026;
    .agGridHeaderText {
      @include text(16px, 600, 0.21, #323338);
    }
    .agGridHeaderData {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 10px;
      .header-cont:not(:last-child) {
        display: flex;
        gap: 5px;
        align-items: center;
        height: 15px;
        padding-right: 10px;
        border-right: 2px solid #dee2eb;
        .header-val {
          font-size: 14px;
          font-weight: 600;
        }
        .header-name {
          font-size: 14px;
          font-weight: 400;
        }
      }
      .header-cont {
        display: flex;
        gap: 5px;
        align-items: center;
        .header-val {
          font-size: 14px;
          font-weight: 600;
        }
      }
      .agGridHeaderDivider {
        height: 15px;
        background: #fff;
        color: #000;
        border-left: 2px solid #dee2eb;
      }
    }
  }
  .agGridContainer {
    background: #ffffff;
    width: 100%;
    // margin-top: 5px;
    box-shadow: 0px 1px 2px 0px #00000029;
    transition: all 0.5s ease-in-out;
    border-radius: 0px 0px 10px 10px;
    height: -webkit-fill-available;
    overflow-y: auto;
    // height: calc(100% - 150px) !important;
    // .ag-react-container {
    //   width: 100%;
    //   height: 100%;
    //   display: flex;
    //   align-items: center;
    // }
    a {
      font-weight: 600;
    }

    .ag-column-drop {
      padding-top: 5px;
      padding-bottom: 5px;
      .ag-column-drop-title-bar {
        margin-right: 0;
        .ag-column-drop-icon {
          background-image: url("../../../assets/icons/GroupColumnsIcon.svg");
          width: 18px;
          height: 12px;
          &::before {
            display: none;
          }
        }
      }
      .ag-column-drop-list {
        height: 32px;
        min-width: 20%;
        border-radius: 5px;
        padding: 6.5px 10px;
        background-color: #f5f5f7;
        font-size: 14px;
      }
    }
    // span.ag-column-drop-empty-message .ag-column-drop-horizontal-empty-message {
    //   display: flex;
    //   align-items: center;
    //   padding: 5px;
    //   margin-top: 10px;
    //   width: 354px;
    //   height: 32px;
    //   border-radius: 5px;
    //   background-color: #f5f5f7;
    // }
    .ag-cell {
      border-right: solid 0px #4f4f4f !important;
    }
    .ag-header-cell-label {
      justify-content: center;
    }
    .statusCell {
      width: 100%;
      > div {
        width: calc(100% - 40px) !important;
        max-width: 340px !important;
        .statusCard {
          overflow: hidden;
        }
      }
    }
    .light-ag-theme .ag-root-wrapper {
      border: 0;
      height: inherit;
      border-radius: 10px;
      box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
      // .ag-root-wrapper-body {
      // height: 100%;
      // overflow: auto;
      // }
    }
    // .ag-body-horizontal-scroll-viewport {
    //   // background: #1c1c1c;
    //   // height: 17px !important;
    //   // min-height: 17px !important;
    //   // max-height: 17px !important;
    //   .ag-body-horizontal-scroll-container {
    //     // height: 17px !important;
    //     // min-height: 17px !important;
    //     // max-height: 17px !important;
    //   }
    // }
    .ag-paging-panel {
      gap: 75px !important;
    }
    .ag-status-bar {
      height: 48px;
      width: 100%;
      .ag-status-bar-right {
        position: relative;
        right: 200px;
        .ag-react-container {
          display: flex;
          align-items: center;
        }
      }
    }
    .statusCard {
      @include square(fit-content, 30px, 5px, unset);
      @include flex(center, center);
      // width: 200px;
      width: 100%;
      // max-width: 200px;
      // min-width: 150px;
      letter-spacing: 0px;
      text-transform: uppercase;
      padding: 0 10px;
    }
  }

  .agGridContainerDark {
    background-color: $cardDarkBackgroundColor;
    // .statusCard {
    //   color: rgb(random(255), random(255), random(255)) !important;
    // }
    .dark-ag-theme {
      background-color: $cardDarkBackgroundColor;
      .ag-column-drop {
        background-color: $cardDarkBackgroundColor;
        .ag-column-drop-list {
          height: 32px;
          min-width: 20%;
          border-radius: 5px;
          padding: 6.5px 10px;
          background-color: $inputDarkColor;
          font-size: 14px;
          .ag-column-drop-empty-message {
            color: #acacca;
          }
        }
      }
      .ag-header {
        min-height: 0px !important;
        box-shadow: none;
        background-color: $cardDarkBackgroundColor;
        .ag-header-cell {
          padding-left: var(--ag-cell-horizontal-padding) !important;
          padding-right: var(--ag-cell-horizontal-padding) !important;
          .ag-header-cell-text {
            font-size: 16px;
            font-weight: 600;
          }
        }
      }
      .ag-row-even {
        background-color: $cardDarkHeaderColor !important;
      }
      .ag-row-odd {
        background-color: $mainDarkBackgroundColor !important;
      }
      .ag-side-buttons {
        background-color: $cardDarkBackgroundColor;
      }
      .ag-paging-panel {
        border: none;
        background-color: $cardDarkBackgroundColor;
      }
      .application-footer {
        background-color: $cardDarkBackgroundColor;
      }
    }
  }
}

.dynamicBasePageDark {
  background-color: $mainDarkBackgroundColor;
  .agGridHeaderDiv {
    // background-color: $cardDarkBackgroundColor;
    background-color: $cardDarkHeaderColor;
    color: #fff;
    span {
      color: #fff !important;
    }
  }
  .titleCompBasePage {
    color: #f2f2f2;
    svg path {
      fill: #f2f2f2;
    }
  }
  .filterContainers {
    background-color: $cardDarkBackgroundColor;
    .IconStyle svg path {
      fill: #f2f2f2 !important;
    }
    .filter__wrapper span {
      color: #fff;
    }
    .ant-input-affix-wrapper {
      background-color: $inputDarkColor !important;
      .ant-input-prefix svg {
        fill: #fff;
      }
      .ant-input {
        background-color: $inputDarkColor !important;
        color: #fff;
        &::placeholder {
          color: #acacca;
        }
      }
    }
    .ant-select {
      .ant-select-selector {
        background-color: $inputDarkColor !important;
        .ant-select-selection-search-input {
          color: #fff !important;
        }
        .ant-select-selection-item {
          color: #fff !important;
        }
        .ant-select-selection-placeholder {
          color: #acacca;
        }
      }
      .ant-select-arrow {
        svg {
          fill: #fff !important;
          path {
            fill: #fff !important;
          }
        }
      }
      .ant-select-clear {
        background-color: $inputDarkColor;
        svg {
          fill: #acacca;
        }
      }
    }
  }
  .agGridHeaderDiv {
    background: $cardDarkHeaderColor;
    .agGridHeaderText {
      color: #fff;
    }
    .agGridHeaderData .header-cont {
      color: #fff !important;
      .header-val {
        color: #fff !important;
      }
      .header-name {
        color: #fff !important;
      }
    }
  }
}

.applic-tasks-wrapper-dark,
.schOfVal-tasks-wrapper-dark {
  .filter__wrapper {
    color: white;
  }

  margin: 0;
  width: 100%;
  background: #1f212d;
  height: auto;
  padding: 0;
  .agGridContainer {
    background-color: #1f212d;
    padding: 0;
    .light-ag-theme {
      // .ag-body-viewport.ag-layout-normal.ag-row-no-animation{
      //   min-height: 100px;
      // }
      .ag-header-cell {
        background: #12131b;
        border-bottom: 1px solid #525151 !important ;

        &:hover {
          background: darkgrey !important;
        }

        &:active {
          background: darkgrey !important;
        }
        .ag-header-cell-text {
          @include text(16px, 600, 0, #f2f2f2);
        }
        &:hover {
          background-color: #f2f2f2;
        }
      }
      .ag-center-cols-viewport {
        background: #12131b;
      }
      .ag-body-horizontal-scroll-viewport {
        background: #1c1c1c;
      }

      ::-webkit-scrollbar-thumb {
        background: #35415e;
        border-radius: 5px;
        //    &:hover {
        //      background-color: #35415e;
        //    }
      }

      .ag-header-viewport {
        background-color: #12131b;
      }

      .ag-row {
        background: #292b3a !important;
        color: #f5f5f5;
        border-color: #4a4a4a;
      }

      .ag-row-even {
        background: #1f212d !important;
        color: #f7f8fa;
        border-color: #4a4a4a;
      }

      .ag-cell-value {
        // border: 0 !important;
        border-right-color: #dde2eb !important;
      }
      .ag-cell {
        border-right: 1px solid #4a4a4a !important;
      }
      .ag-root-wrapper {
        border: 0;
        border-radius: 10px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      }

      .ag-ltr .ag-side-bar-right {
        border: 0;
        color: #757575;
        fill: #757575;
      }

      .ag-paging-panel {
        border: 0;
      }

      .ag-column-drop {
        background-color: #fff;
        border-bottom: 0;
      }

      .ag-column-drop-horizontal-title-bar {
        margin-right: 10px;
      }
    }
    .ag-column-drop-wrapper,
    .ag-unselectable {
      background-color: #12131b !important;
      color: #fff !important;
      .ag-column-drop-list {
        span {
          color: #fff !important;
        }
      }
      .ag-paging-page-summary-panel {
        .ag-paging-button {
          color: #fff !important;
        }
      }
      .ag-side-buttons {
        color: #fff !important;
      }
      .ag-tool-panel-wrapper {
        border: 1px solid #fff;
        .ag-pivot-mode-panel {
          background-color: #12131b;
        }
        .ag-column-select {
          background-color: #12131b;
          .ag-column-select-header {
            .ag-column-select-header-filter-wrapper {
              .ag-text-field-input-wrapper {
                .ag-text-field-input {
                  background-color: #12131b !important;
                  color: #fff !important;
                  &::placeholder {
                    color: #fff !important;
                    opacity: 0.7 !important;
                  }
                }
              }
            }
          }
        }
        .ag-filter-toolpanel {
          .ag-filter-toolpanel-search {
            background-color: #1f212d;
            .ag-filter-toolpanel-search-input {
              background-color: #1f212d !important;
            }
            .ag-text-field-input-wrapper {
              .ag-input-field-input {
                background-color: #1f212d;
                color: #fff !important;
                &::placeholder {
                  color: #fff !important;
                  opacity: 0.7 !important;
                }
              }
            }
          }
          .ag-filter-list-panel {
            .ag-filter-toolpanel-group-wrapper {
              .ag-filter-toolpanel-group-container {
                background-color: #1f212d;
                .ag-filter-toolpanel-instance-filter {
                  background-color: #1f212d !important;
                  // border-left: 0;
                  .ag-input-field {
                    .ag-text-field-input {
                      background-color: #1f212d !important;
                      &::placeholder {
                        color: #fff !important;
                        opacity: 0.7 !important;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.applic-tasks-wrapper-white,
.schOfVal-tasks-wrapper-white {
  margin: 0;
  width: 100%;
  height: auto;
  background-color: #fff;
  padding: 0;
  .agGridContainer {
    padding: 0;
    overflow-y: auto;
  }
}

.application-filter-wrapper-dark,
.schOfVal-filter-wrapper-dark {
  background-color: #12131b !important;
}

.application-filter-wrapper-white,
.schOfVal-filter-wrapper-white {
  background-color: #fff !important;
}

.applic-task-btn-dark,
.schOfVal-task-btn-dark {
  background-color: rgb(18, 100, 163) !important;
  border-color: rgb(18, 100, 163) !important;
  color: #fff !important;
  border-radius: 5px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0;
  padding-right: 0;
  span {
    width: 21px;
    height: 21px;
    margin-right: -1px;
  }
  &:hover {
    background-color: #0d4c7c !important;
    border-color: #0d4c7c !important;
    transition: 0.2s;
  }
}

.schOfVal-task-btn-white,
.schOfVal-task-btn-dark {
  margin-right: 18px;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
}

.applic-task-btn-white,
.schOfVal-task-btn-white {
  // background-color: rgb(18, 100, 163) !important;
  // border-color: rgb(18, 100, 163) !important;
  // color: #fff !important;
  // border-radius: 5px !important;
  // span {
  //   width: 21px;
  //   height: 21px;
  // }

  background-color: rgb(18, 100, 163) !important;
  border-color: rgb(18, 100, 163) !important;
  color: #fff !important;
  border-radius: 5px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0;
  padding-right: 0;
  span {
    width: 21px;
    height: 21px;
    margin-right: -1px;
  }
  &:hover {
    background-color: #0d4c7c !important;
    border-color: #0d4c7c !important;
    transition: 0.2s;
  }
}

.ant-modal.chooseTemplateModal {
  .ant-modal-header {
    background: #f8f8f8;
  }
}

.chooseTemplateModalDark {
  .ant-modal-content {
    background-color: $cardDarkHeaderColor;
    .ant-modal-close .ant-modal-close-x svg {
      fill: #fff !important;
      path {
        fill: #fff !important;
      }
    }
    .ant-modal-header {
      background-color: $cardDarkBackgroundColor !important;
      .ant-modal-title {
        color: #fff !important;
      }
    }
    .ant-modal-body {
      background-color: $cardDarkHeaderColor;
      .info-text {
        color: #fff !important;
      }
      .ant-input {
        background-color: $cardDarkBackgroundColor !important;
        color: #fff;
        &::placeholder {
          color: #acacca;
        }
      }
    }
  }
}

.darkDropDown {
  background-color: $cardDarkBackgroundColor !important;
  box-shadow: 0px 1px 4px rgba(255, 255, 255, 0.2) !important;
  .ant-select-item-empty {
    color: #fff;
  }
  .ant-select-item-option-content {
    color: #fff;
  }
  .ant-select-item-option-disabled {
    .ant-select-item-option-content {
      color: #acacca;
    }
  }
  .rc-virtual-list {
    .rc-virtual-list-holder {
      .rc-virtual-list-holder-inner {
        .ant-select-item-option-selected {
          background: $inputDarkColor;
        }
        .ant-select-item {
          border: none !important;
          color: #acacca;
          &:hover {
            background-color: $inputDarkColor;
            .ant-select-item-option-content {
              color: #fff;
            }
          }
        }
        .ant-select-item-option-active {
          background-color: $inputDarkColor;
        }
      }
    }
  }
  .ant-empty-description {
    color: #acacca;
  }
}

.darkDateDropDown {
  .ant-picker-panel-container {
    width: 100%;
    .ant-picker-panel-layout {
      width: 100%;
    }

    .ant-picker-panel-layout > div {
      width: 100%;
    }
    background-color: $cardDarkBackgroundColor;
    .ant-picker-panel {
      width: 100%;
      .ant-picker-header {
        color: #f2f2f2;
        border-bottom: 1px solid #2a2b3a;
        .ant-picker-header-super-prev-btn,
        .ant-picker-header-prev-btn,
        .ant-picker-header-next-btn,
        .ant-picker-header-super-next-btn {
          color: #acacca;
        }
      }
      .ant-picker-body {
        .ant-picker-content {
          th {
            color: #f2f2f2;
          }
          .ant-picker-cell {
            color: #acacca;
          }
          .ant-picker-cell-in-view {
            color: #f2f2f2;
          }
          .ant-picker-cell-disabled {
            &::before {
              background-color: #212331;
            }
            .ant-picker-cell-inner {
              color: #acacca;
            }
          }
          .ant-picker-cell-in-range::before {
            background-color: $inputDarkColor;
          }
          .ant-picker-cell-range-start,
          .ant-picker-cell-range-end {
            &::before {
              background-color: $inputDarkColor;
            }
          }
        }
      }
      .ant-picker-time-panel {
        border-left: 1px solid #2a2b3a;
        .ant-picker-time-panel-cell-selected {
          .ant-picker-time-panel-cell-inner {
            background-color: #2a2b3a;
          }
        }
        .ant-picker-time-panel-column:not(:first-child) {
          border-inline-start: 1px solid #2a2b3a;
        }
        .ant-picker-time-panel-column {
          .ant-picker-time-panel-cell-inner {
            color: #f2f2f2;
          }
        }
      }
    }
    .ant-picker-cell-today {
      background-color: $cardDarkHeaderColor !important;
    }
    .ant-picker-footer {
      border-top-color: $cardDarkHeaderColor;
      .progress-ant-date-picker-extra {
        color: #f2f2f2;
        .ant-progress-text {
          color: #f2f2f2;
        }
        circle {
          stroke: #6161614f;
        }
      }
    }
  }
  .ant-picker-presets ul li {
    color: #fff;
  }
  .ant-picker-ranges {
    .ant-picker-ok {
      .ant-btn-primary:disabled {
        color: #acacca;
      }
    }
  }
  .ant-picker-range-arrow::before {
    background-color: $cardDarkBackgroundColor;
  }
}

.dark-popover {
  .ant-popover-arrow::before {
    background-color: #2f2e3d;
    box-shadow: 0px 1px 4px rgba(255, 255, 255, 0.25);
  }
  .ant-popover-content {
    .ant-popover-inner {
      background-color: #2f2e3d;
      box-shadow: 0px 1px 4px rgba(255, 255, 255, 0.25);
      .ant-popover-inner-content {
        .ant-btn {
          border: none !important;
        }
        div {
          color: #fff !important;
        }
      }
    }
  }
}

@media (max-width: 1728px) {
  .dynamicBasePage {
    .titleCompBasePage {
      top: 25px;
    }
  }
}

@media (max-width: 1225px) {
  .dynamicBasePage .filterContainers {
    flex-wrap: nowrap;
    justify-content: space-between !important;
    overflow-x: scroll !important;
    padding-top: 10px !important;
  }
}
@media screen and (min-device-width: 1000px) and (max-device-width: 1181px) and (orientation: landscape) {
  .leadModalContainer .leadModalBodyContainer {
    display: block;
  }
  .dobTabTypeCard .labelContainer {
    font-size: initial;
  }
  // .statusesPanelContainer .statusesPanelContent {
  //   flex-wrap: wrap;
  // }
  .estimationModalContainer .modalBodyContainer {
    display: block;
  }
  .contactModalContainer .contactModalBodyContainer {
    display: block;
  }
  .contactModalContainer .ant-modal {
    width: 100% !important;
  }
  .clientModalContainer .clientModalBodyContainer {
    display: block;
  }
  .approvalsPageControlPanel {
    justify-content: center !important;
    // flex-wrap: wrap !important;
    gap: 5px !important;
    padding-top: 6px !important;
  }
  .newDispatchModalContainer .mainContainer {
    display: block;
  }
  .newDispatchModalContainer .routeCardsContainer {
    height: 288px;
    overflow: hidden;
  }
  .reportsCategoriesView .categoryCreatorSection {
    flex-wrap: wrap;
  }
  .normalSizedModal .ant-modal-content {
    width: auto !important;
  }
  .allUserConfiguration .allUsersHeader {
    flex-wrap: wrap !important;
    gap: 5px;
  }
  .dynamicBasePage .filterContainers {
    height: 52px !important;
    min-height: 52px !important;
  }
}

@media (max-width: 821px) {
  .statusSquircleContainer {
    min-width: 90px;
  }
  // .dynamicBasePage
  //   .filterContainers
  //   .filterContainerLeft
  //   .search-Div
  //   .SearchLogoIcon {
  //   right: 190px;
  // }
  .dynamicBasePage .filterContainers .filterContainerLeft {
    margin-top: 6px;
    margin-left: 10px;
  }
  .dynamicBasePage .filterContainers .filterContainerRight {
    margin-top: 10px;
    margin-right: 13px;
  }
  .dynamicBasePage .filterContainers .filterContainerRight > button,
  .dynamicBasePage .filterContainers .filterContainerRight > div,
  .dynamicBasePage .filterContainers .filterContainerRight > span {
    margin-left: 5px !important;
  }
  .dynamicBasePage .filterContainers {
    // display: block !important;
    // height: 120px !important;
    padding-top: 5px !important;
    padding-bottom: 5px;
    padding-right: 5px;
    padding-left: 5px;
    overflow: hidden;
  }

  .statusesPanelContainer {
    height: auto;
  }
  .dynamicBasePage .filterContainers {
    .filterContainerLeft .search-Div {
      .search-comp .ant-select-selector {
        width: 200px;
      }
      .SearchLogoIcon {
        right: 190px;
      }
    }
    .filterContainerRight .ant-select-selector {
      width: 126px;
      margin-left: 20px;
    }
  }
  .dynamicSelectDropdown {
    width: 125px !important;
  }
  .dynamicBasePage
    .filterContainers
    .filterContainerLeft
    .search-Div
    .search-comp
    .ant-select-selector {
    width: 200px;
  }
  .approvalsPageControlPanel {
    gap: 5px !important;
    padding-top: 6px !important;
  }
  .approvalsPageControlPanel .searchBar {
    min-width: 100px !important;
    max-width: 100px !important;
  }

  .dashboardHeader {
    margin-top: 10px;
  }
  .leadModalContainer .leadModalBodyContainer {
    display: block !important;
  }
  .opportunityModalContainer .opportunityModalBodyContainer {
    display: block !important;
  }
  .estimationModalContainer .modalBodyContainer {
    display: block !important;
  }
  .normalSizedModal .ant-modal-content {
    width: 100%;
  }
  .contactModalContainer .contactModalBodyContainer {
    display: block !important;
  }
  .overViewStepContainer .mainContainer {
    align-items: flex-start;
  }
  .overViewStepContainer .mainGrid {
    display: block;
  }
  .dobStepContainer .controlPanel {
    display: block;
  }
  .clientModalContainer .clientModalBodyContainer {
    display: block;
  }
  .ant-modal-wrap.basePageModal.newInspectionModal.ant-modal-centered {
    text-align: start;
  }
  .newViolationModalContainer .inputContainer {
    width: 100% !important;
  }
  .newDispatchModalContainer .mainContainer {
    display: block;
  }
  .newDispatchModalContainer .routeCard {
    width: 84% !important;
    min-width: 40% !important;
  }
  .labeledInputContainer {
    min-width: 134px;
    // max-width: 270px;
  }
  .newDispatchModalContainer .inputContainer {
    width: 96%;
  }
  .newDispatchModalContainer .filtersCard {
    min-width: 77%;
  }
  .contactModalContainer .editorsContainer {
    display: block;
  }
}

@media (max-width: 459px) {
  .dynamicBasePage {
    .liveButton {
      width: 40px !important;
    }
    .titleCompBasePage {
      width: 100%;
      padding: 0px 30px 10px 10px;

      margin-top: 30px;
      .title {
        max-width: 85%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
}

.dynamicSelectDropdown {
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
  @media (max-width: 919px) {
    min-width: 200px !important;
  }
  .rc-virtual-list {
    .rc-virtual-list-holder {
      .rc-virtual-list-holder-inner {
        .ant-select-item-option-selected {
          background: #f1f5fd;
          .ant-select-item-option-content {
            color: #1264a3;
          }
        }
        .ant-select-item {
          padding-left: 10px;
          padding-right: 10px;
          padding-top: 6.5px;
          padding-bottom: 6.5px;
          .ant-select-item-option-content {
            font-family: "Open Sans";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            margin: 0;
            padding: 0;
          }
          &:not(:last-child) {
            border-bottom: 1px solid #efefef;
          }
          &:hover {
            background: #f1f5fd;
            .ant-select-item-option-content {
              color: #1264a3;
            }
          }
        }
      }
    }
  }
}

.chooseTemplateModal {
  width: auto !important;
  .ant-modal-content {
    border-radius: 10px;
    width: calc(100vw - 20px);
    max-width: 655px;

    .ant-modal-header {
      display: flex;
      // background: red;
      width: 100%;
      height: 45px;
      background: #f8f8fa;
      border-radius: 10px 10px 0px 0px;
      padding: 10px 20px;
      // height: 100%;
      // justify-content: center;
      align-items: center;
      .ant-modal-title {
        font-weight: 600;
        font-size: 16px;
        color: #323338;
        height: 100%;
        justify-content: center;
        align-items: center;
      }
    }
    .ant-modal-close {
      height: 45px;
      .ant-modal-close-x {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .ant-modal-body {
      .info-text {
        font-weight: 500;
        font-size: 14px;
        color: #323338;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        gap: 10px;
      }
      .filterTemplateModalContainer {
        display: flex;
        flex-direction: row;
        width: 100%;
        flex-wrap: wrap;
        label.ant-radio-wrapper.radioContainer {
          .ant-radio {
            display: none !important;
          }
        }
      }

      .optionFilterContainer {
        width: 190px;
        display: flex;
        gap: 10px;
        .delete-icon-container {
          background-color: red;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 5px;
          width: 32px;
          svg {
            fill: white;
          }
        }
        .ant-radio {
          display: none !important;
        }
        .ant-radio-wrapper {
          .ant-radio {
            display: none !important;
          }
        }

        .radio-button-text {
          min-width: 140px;
          width: auto;
          height: 32px;
          border-radius: 5px;
          background: #f5f5f7;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-left: 10px;
          padding-right: 10px;
        }
        .radio-button-text__checked {
          min-width: 140px;
          width: auto;
          height: 32px;
          border-radius: 5px;
          background: #dbf1dc;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-left: 5px;
          padding-right: 5px;
          svg {
            fill: #68c142;
          }
        }
      }
    }
    .ant-modal-footer {
      display: flex;
      justify-content: center;
      gap: 20px;
      border: none;
      .mondayButtonContainer .mondayButtonRed {
        svg {
          path {
            fill: #fff;
          }
        }
      }
    }
  }
}

@media (max-width: 720px) {
  .chooseTemplateModal {
    .newFilterContainer {
      flex-wrap: wrap;
      gap: 10px !important;
    }
  }
}

.ag-status-bar {
  position: absolute;
  bottom: 0;
  border: none !important;
}
.schedule-modal-wrapper {
  overflow: hidden !important;
  .schedulingOverviewModal {
    width: 100dvw !important;
    height: 100dvh;
    top: 0px !important;
    max-width: none !important;
    border-radius: 0px !important;

    .ant-modal-body {
      display: flex;
      padding: 10px !important;
      height: calc(100dvh - 45px);
      background: #f4f5f6;
      .dynamicBasePage {
        height: 100% !important;
        padding: 0px !important;
      }
      .agGridContainer {
        width: 100% !important;
        height: 100%;

        .light-ag-theme .ag-root-wrapper {
          border: 0;
          height: inherit;
          border-radius: 10px;
          box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
          // .ag-root-wrapper-body {
          // height: 100%;
          // overflow: auto;
          // @media only screen and (max-width: 414px) {
          //   overflow: auto;
          //   overflow-x: auto;
          // }
          // }
        }
      }
    }

    .ant-modal-close-x {
      justify-content: center;
      align-items: center;
      display: flex;
      height: 45px;
      width: 45px;
      padding: 0;
      svg {
        fill: white;
        height: 15px;
        width: 15px;
      }
    }

    .ant-modal-header {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      background-color: #1f2a44;
      padding: 20px;
      height: 45px;
      border: 0;
      border-radius: 0px;
    }
    .ant-modal-title {
      width: 100%;
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: white;
      .customTitle {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .ant-form-item {
          margin: 0px;
          padding-right: 40px;
          width: 400px;
        }
      }
    }

    .ant-modal-footer {
      display: none;
    }

    .titleCompBasePage {
      display: none;
    }

    .fullScreenTable {
      width: 100%;
      height: 100%;
      .approvalsTable {
        height: 100%;
      }
    }
  }
}

.schedulingOverviewModalDark {
  .ant-modal-content {
    background-color: $mainDarkBackgroundColor;
    .ant-modal-header {
      background-color: #101c35;
      .ant-modal-title .customTitle {
        .ant-input-affix-wrapper {
          background-color: $inputDarkColor !important;
          .ant-input {
            background-color: $inputDarkColor !important;
            color: #fff;
            &::placeholder {
              color: #acacca;
            }
          }
          .ant-input-prefix svg {
            fill: #fff;
          }
        }
      }
    }
    .ant-modal-body {
      background-color: $mainDarkBackgroundColor;
      // .agGridContainerDark .documentationsTable {
      .ag-root-wrapper {
        background-color: $cardDarkBackgroundColor;
        box-shadow: rgba(105, 105, 105, 0.2) 0px 2px 8px 0px;
        border-radius: 5px;
        .ag-unselectable {
          background-color: $cardDarkBackgroundColor;
        }
        .ag-header .ag-header-cell {
          background-color: $cardDarkBackgroundColor;
          .ag-header-cell-resize::after {
            width: 1px !important;
            background-color: #4a4a4a !important;
          }
        }
        .ag-paging-panel {
          background-color: $cardDarkBackgroundColor;
        }
      }
      // }
    }
  }
}
