.assistant-connecting-view {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-direction: column;
  flex-wrap: nowrap;
  background-color: #fff;
  padding: 10px;

  svg,
  path {
    fill: #acacac;
  }

  .reload-rotate-icon {
    animation: rotate 2s linear infinite;
  }

  .connecting-message {
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    line-height: 19.07px;
    color: #acacac;
    text-align: center;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
