.previewInvCont {
  display: flex;
  flex-direction: column;
  // gap: 10px;
  width: 100%;
  height: auto;
  justify-content: center;
  align-self: center;

  .optionsContainer {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
    height: auto;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 3px 6px #00000029;
    flex-wrap: wrap;
    flex-basis: fit-content;
    align-items: center;
    padding: 10px 15px;

    .flexDiv {
      display: flex;
      flex-wrap: wrap;
      flex-basis: fit-content;
      gap: 10px;
      align-items: center;
    }
    .backAndTitle {
      .invoiceNumber {
        font-weight: 600;
        font-size: 1rem;
        width: 150px !important;
      }
    }

    .pageControls {
      height: 32px;
      align-items: start;
      .pagination {
        align-items: start;
        p {
          margin: 0;
          color: #bdcee0;
          align-self: center;
        }
        .paginationInput {
          width: 50px;
          height: 32px;
          align-items: start;
        }
        .unselectable {
          -webkit-touch-callout: none;
          -webkit-user-select: none;
          -khtml-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
        }
      }
    }

    .optionButtons {
      .notes-icon {
        margin: 0;
      }
      .searchInput {
        height: 32px;
        margin-bottom: 3px;
      }
    }

    .iconCont {
      width: 32px;
      height: 32px;
      fill: #ffffff;
      border-radius: 5px;
      align-items: center;
      justify-content: center;
      display: flex;
      cursor: pointer;
    }
  }
  .optionsContainerDark {
    background-color: #12131b;
    .invoiceNumber {
      color: #ffffff;
    }
  }

  .viewModeListCont {
    grid-area: auto;
    width: 100%;
    height: fit-content;
    text-align: center;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto;
  }
}
@media (max-width: 500px) {
  .previewInvCont .optionsContainer {
    flex-direction: column;
  }
}
