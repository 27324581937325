@import "../../../../../../../index.scss";

$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.general-reports-filters {
  @include flex(flex-end, flex-start);
  @include cardLayout();
  gap: 20px;
  padding: 10px;
}

.general-reports-filter-report-search {
  background-color: #f4f5f6 !important;
  .ant-input {
    background-color: #f4f5f6;
  }
  :hover {
    background-color: #f4f5f6;
  }
}

.general-reports-filter-modal {
  .filters-grid {
    display: grid;
    width: stretch;
    grid-template-columns: repeat(4, minmax(200px, auto));
    column-gap: 15px;

    .labeledInputContainer {
      max-width: 500px;
    }
  }
  .ant-modal-footer {
    border: none;
    .general-reports-footer {
      display: flex;
      justify-content: space-between;
    }
  }
}

.general-reports-filter-modal-dark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      .labeledInputLabel {
        color: #fff;
      }
      .ant-select {
        .ant-select-selector {
          background-color: $inputModalDark !important;
          .ant-select-selection-search-input {
            color: #fff;
          }
          .ant-select-selection-item {
            color: #fff;
          }
          .ant-select-selection-placeholder {
            color: #acacca;
          }
        }
        .ant-select-clear {
          background-color: $inputModalDark;
          svg {
            fill: #acacca;
          }
        }
        .ant-select-arrow svg {
          fill: #fff;
        }
      }
    }
  }
}

.filterReportsButton {
  @include mondayButton(#1264a3, #0f5c97);
}
.mondayButtonGreenReports {
  margin-left: 1030px;
}

@media (max-width: 1605px) {
  .general-reports-filters {
    overflow-x: auto;
    .filters-grid {
      margin-bottom: 20px;
    }
  }
}
