@import "../../../../../../../../../../index.scss";

.live-layout-card {
  background-color: #fff;
  color: #000;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.161);
  border-radius: 10px;
  overflow: hidden;

  .card-header {
    width: 100%;
    height: 45px;
    font-weight: 600;
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f8f8fa;

    .ant-form-item {
      margin-bottom: 0px;
      .ant-picker {
        background-color: #fff !important;
        .ant-picker-input {
          background-color: #fff;
        }
        .ant-picker-clear {
          background-color: #fff;
        }
      }
    }

    .header-title {
      color: #323338;
    }

    .header-actions {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex-direction: row;
      flex-wrap: nowrap;
    }
  }

  .card-body {
    height: calc(100% - 45px);
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    .total-container {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-direction: row;
      flex-wrap: nowrap;
      flex-grow: 2;
      min-height: 80px;
      max-height: 120px;
      width: 90%;
      padding: 1rem 5px 0 5px;
      gap: 1rem;
      cursor: default;

      .inner-total {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        gap: 7px;
        width: max-content;

        .inner-total-label {
          font-size: 1.1rem;
          font-weight: bold;
          letter-spacing: 0.15px;
        }
      }

      .inner-total-right {
        .inner-total-value {
          width: 100%;
          text-align: end;
        }
      }
    }

    .filters-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      flex-wrap: nowrap;
      flex-grow: 1;
      min-height: 50px;
      max-height: 50px;
      border-bottom: 1px solid white;
      width: 100%;
    }

    .chart-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: 6;
      width: 100%;
    }
    .pie-chart-container {
      height: 100%;
      canvas {
        position: relative;
        bottom: calc(12%);
        scale: 0.65;
      }
    }
  }
}

.live-layout-card.layout-card-dark {
  background-color: #1f212d;
  color: #ffffff;
  box-shadow: 0px 1px 4px rgba(255, 255, 255, 0.161);

  .card-header {
    background-color: #12131b;
    .ant-form-item {
      margin-bottom: 0px;
      .ant-picker {
        background-color: $inputDarkColor !important;
        .ant-picker-input {
          background-color: $inputDarkColor;
          input {
            color: #f2f2f2;
            &::placeholder {
              color: #acacca;
            }
          }
          .ant-picker-suffix svg {
            color: #acacca;
          }
        }
        .ant-picker-clear {
          background-color: $inputDarkColor;
          .anticon-close-circle svg {
            fill: #acacca !important;
            path {
              fill: #acacca !important;
            }
          }
        }
      }
    }
    .header-title {
      color: #ffffff;
    }
  }

  .react-resizable-handle {
    background-image: none;
    display: flex;
    justify-content: center;
    align-items: center;

    &::after {
      display: none;
    }
  }
}

.layout-card-dropdown.layout-dropdown-dark {
  .ant-dropdown-menu {
    background-color: $mainDarkBackgroundColor;
    box-shadow: 0px 1px 4px rgba(255, 255, 255, 0.161);
    .ant-dropdown-menu-item {
      color: #ffffff;
      &:hover {
        background-color: $cardDarkHeaderColor;
      }
    }

    .ant-dropdown-menu-submenu-title {
      color: #ffffff;
      &:hover {
        background-color: $cardDarkHeaderColor;
        color: #1890ff;
      }
    }

    .ant-dropdown-menu-submenu-arrow {
      svg {
        fill: #ffffff;
      }
    }
  }
}
.layout-dropdown-sub-dark {
  .ant-dropdown-menu-sub {
    background-color: $mainDarkBackgroundColor;
    box-shadow: 0px 1px 4px rgba(255, 255, 255, 0.161);
    .ant-dropdown-menu-item {
      color: #fff;
      &:hover {
        background-color: $cardDarkHeaderColor;
      }
    }
  }
}
.layout-card-dropdown {
  .ant-dropdown-menu [title="remove"]:hover {
    color: #fe4c4a !important;
  }
}
