.chart-card-three-doted-actions {
  display: grid;
  border-radius: 5px;
  overflow: hidden;
  .ActionElementThreeDots {
    text-align: left;
    padding: 4px 12px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    cursor: pointer;
    &:not(:last-child) {
      border-bottom: 1px solid #efefef;
    }
    &:hover {
      background: #f1f5fd;
      color: #1264a3;
    }
  }
}
