.rangePickerContainer {
  overflow: hidden;
  height: 100%;
  margin-left: 10px;
  width: 100%;
  padding-right: 2px;
  border-radius: 10px;
  margin: 11px 0 0 10px !important;
  position: relative;
  display: flex;
  &:has(> :last-child:nth-child(1)) {
    justify-content: center;
  }
  .rangeInformation {
    width: 280px;
    height: 268px;
    margin: 0 5px;
    // margin-left: 290px;
    border-radius: 10px;
    box-shadow: 0px 1px 4px #00000029;
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    .applicationInformation {
      font-size: 17px;
      font-weight: bold;
    }

    .period {
      margin-top: 10px;
      font-size: 16px;
    }

    .dueDateContainer {
      margin-top: 15px;
      padding: 0 10px;

      .dueDateLabel {
        display: flex;
        justify-content: center;
      }
    }
  }

  .calendarContainer {
    width: 290px;
    border: 0.2px solid #f0f0f0;
    border-radius: 10px;
    height: fit-content;
    box-shadow: 0px 1px 4px #00000029;
    .headerSelect {
      padding: 10px;
      display: flex;
      justify-content: center;
      .ant-select-selection-item {
        line-height: 24px;
      }
      .ant-select-selection-search-input {
        height: 24px;
      }
      .ant-select-selector {
        height: 24px;
        font-size: 12;
      }
      .ant-select-arrow {
        height: 14px;
      }
    }
    .ant-picker-content {
      height: 205px;
    }
  }

  .dateInputStyle {
    // margin-top: -36px;
    // position: absolute;
    height: 32px;
  }

  .monthPickerNewApplications {
    margin-top: -36px;
    position: absolute;
    z-index: 1050 !important;
  }
}

// .ant-picker-month-panel {
//   transition: background-color 0.05s ease-in-out;
//   @if show {
//     background-color: #fff;
//   } @else if hide {
//     background-color: #4d4d4d09;
//   }
// }

// .show {
//   .ant-picker-month-panel {
//     background-color: none !important;
//   }
// }
// .hide {
//   .ant-picker-month-panel {
//     background-color: #4d4d4d04 !important;
//     filter: blur(5px);
//   }
// }

.monthPickerNewApplicationsDropdown {
  // background: transparent !important;
  box-shadow: none !important;
  z-index: 1050 !important;

  .ant-picker-panel-container {
    border-radius: 10px;
    // height: 305px;
  }

  .ant-picker-header {
    margin-bottom: 13px;
    border: none;
    border-radius: 5px;
    // background: none !important;
  }

  .ant-picker-body {
    .ant-picker-content {
      height: 216px;
    }
  }

  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    width: 90px;
    color: #fff;
    background: #1890ff;
    border-radius: 5px;
  }
}
