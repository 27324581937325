.ListBody {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  .ListContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    align-self: flex-start;
    padding-right: 10px;
    margin-right: -10px;
    margin-left: 0px;
    max-height: 600px;
    // width: 680px; the reason of commented: logsModal width showed scrolls between rows
    overflow-y: auto;
    .columnHeaderContainer {
      flex: 1;
      display: flex;
      flex-direction: column;
      .headerDiv {
        display: flex;
        justify-content: center;
        .SortIcon {
          margin: 3px;
        }
      }
      .columnHeaderTitle {
        min-width: 124px;
        display: flex;
        justify-content: center;
        text-align: left;
        font: normal normal 600 16px Open Sans;
        letter-spacing: 0px;
        color: #323338;
        opacity: 1;
        margin-bottom: 5px;
      }
      .columnContentContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 1px;
        min-height: 50px;
        transition: all 1s ease-in-out;
        -webkit-transition: all 1s ease-in-out;
        .cellTextConteiner {
          display: flex;
          justify-content: center;
          align-items: center;
          padding-top: 2px;
          overflow: hidden;
          .textStyle {
            max-height: 400px;
            text-align: left;
            letter-spacing: 0px;
            opacity: 1;
            color: rgb(50, 51, 56);

            overflow-y: auto;
            // overflow-x: auto;
            height: 40px;
            display: flex;
            align-items: center;
          }
          .removed {
            color: #ff4d4f !important;
            height: 36px;
          }
          .granted {
            color: #71cf48 !important;
          }
          p {
            margin-bottom: 0 !important;
          }
          ol {
            height: 100%;
          }
          ul {
            height: 100%;
          }
          img {
            max-height: 40px;
          }
        }
      }
    }
  }
}
.showNotesModal {
  width: fit-content !important;
  .ant-modal-content {
    border-radius: 10px;
    .ant-modal-close {
      height: 45px;
      width: 45px;
      .ant-modal-close-x {
        height: 45px;
        width: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
        fill: #323338;
      }
    }
    .ant-modal-header {
      height: 45px;
      display: flex;
      align-items: center;
      border-radius: 10px 10px 0px 0px;
      background-color: #f5f5f7;
      border: none;
      .ant-modal-title {
        font-size: 16px;
        font-weight: 600;
      }
    }
    .ant-modal-body {
      border-radius: 0px 0px 10px 10px;
      padding: 15px;
      .noteContainer {
        min-width: 500px;
        max-height: 500px;
        display: flex;
        border: 1px solid #adadad;
        border-radius: 10px;
        padding: 10px;
        overflow-y: auto;
      }
    }
  }
}
@keyframes rotateTop {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}
@keyframes rotateBottom {
  from {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.scrollBottomContainer {
  margin-bottom: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(0deg);
  animation: rotateBottom 0.5s linear;
}
.scrollTopContainer {
  margin-bottom: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: rotateTop 0.5s linear;
  transform: rotate(180deg);
}
