.serviceAddonsContainer {
  width: 100%;
}

.serviceAddonsBody {
  padding: 20px;
  margin-bottom: 10px;
}

.addonCardContainer {
  display: flex;
  gap: 10px;
  margin-top: 15px;
}

.addonCardBody {
  width: 24%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 10px;
}

.addonCardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f8f8fa;
  border-bottom: 0;
}

.addonCardTitle {
  margin: 0;
  max-width: 75%;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #323338;
}

.addonCardColumns {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 10px;
  overflow: scroll;
}
.addonCardValues {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  height: fit-content;
  padding: 8px 10px;
  border-radius: 5px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #323338;
}
.addonCardDescription {
  flex-grow: 1;
  margin-bottom: auto;
  margin: 10px 5px 5px 5px;
  padding: 10px;
  min-height: 80px;
  background: #f5f5f5;
  color: black;
  border-radius: 5px;
}
.addonCardDescriptionText {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #323338;
}
