@use "src/index.scss" as index;

.form-slide {
}

.form-slide-dark {
  .ant-slider-rail {
    background-color: index.$cardDarkBackgroundColor;
  }
  .ant-slider-track {
    background-color: index.$blue;
  }
  .ant-slider-mark-text {
    color: #fff;
  }
}
