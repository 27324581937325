.change-ret-actions {
	color: white;
	align-items: center;
	display: flex;
	flex-direction: column;
	gap: 20px;
	flex-wrap: nowrap;
	justify-content: center;
	.service-ret-container {
		display: flex;
		flex-direction: row;
		width: 500px;
		justify-content: space-between;
		// background-color: red;
		gap: 20px;
		align-items: center;
		.right-cont,
		.left-side {
			width: 50%;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
		.left-side {
			margin-left: -30px;
		}
		.label-service-ret {
			display: flex;
			width: 100%;
			margin-right: 20px;
			justify-content: flex-end;
		}
	}
}
