@mixin buttons($className, $backgroundColor, $divider) {
  #{$className} {
    @include size(121px, 32px);
    background: $backgroundColor 0% 0% no-repeat padding-box;
    border-radius: 5px;
    margin-top: 80px;
    margin-left: 10px;
    padding-top: 10px;
    #{$className + "Divider"} {
      @include divide($divider);
    }
    #{$className + "Image"} {
      position: absolute;
      top: 5px;
      fill: #ffffff;
      z-index: 2;
      left: 95px;
      @include size(20px, 20px);
      font-size: 41px;
    }
    #{$className + "Text"} {
      @include currentTextStyle(left);
      font: normal normal normal 14px/19px Open Sans;
      margin-left: -20px;
    }
  }
  #{$className}:hover {
    @include size(121px, 32px);
    background: $divider 0% 0% no-repeat padding-box;
    border-radius: 5px;
    margin-top: 80px;
    #{$className + "Divider"} {
      @include divide($backgroundColor);
    }
  }
}
@mixin currentTextStyle($align) {
  text-align: $align;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}
@mixin divide($color) {
  position: absolute;
  top: -11px;
  z-index: 2;
  left: 88px;
  font-size: 31px;
  color: $color;
  font-weight: 50;
}
@mixin div($direction, $align, $justify) {
  display: flex;
  flex-direction: $direction;
  align-items: $align;
  justify-content: $justify;
}

@mixin size($width, $height) {
  width: $width;
  height: $height;
}

@mixin textStyle($align) {
  text-align: $align;
  letter-spacing: 0px;
  color: #323338;
  opacity: 1;
}
@mixin bodyElement() {
  @include div(row, center, center);
  @include size(33%, 36px);
  background-color: $labelColor;
  @include textStyle(left);
}
$primaryColor: #efefef 0% 0% no-repeat padding-box;
$borderColor: #d9d9d9;
$secondaryColor: #e6e9ef;
$labelColor: #eceff4;
$mainDarkBackgroundColor: #20212d;
$cardDarkHeaderColor: #2a2b3a;
$cardDarkBackgroundColor: #12131b;
$inputDarkColor: #2a2b3a;

.departmentRolePage {
  // @include div(column, center, flex-start);
  // @include size(100%, 100%);
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 10px;
  height: auto;

  .first-row {
    display: flex;
    flex-direction: row;
    gap: 10px;

    .left {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      gap: 30px;
      padding: 68px 10px;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 1px 4px #00000029;
      border-radius: 10px;

      .actions {
        @include div(column, center, space-evenly);
        .header {
          font: normal normal 600 16px/22px Open Sans;
          letter-spacing: 0px;
          color: #323338;
        }
        .searchCont {
          .ant-select {
            width: 150px !important;
            height: 32px !important;
            .ant-select-selector {
              .ant-input-group-addon .ant-btn {
                border: none;
                border-left: 1px solid #d6d6d6;
              }
              .ant-input {
                height: 32px;
                border: none;
              }
            }
          }
          .ant-input-group {
            width: 150px;
            opacity: 1;
          }
          .ant-input {
            background: $primaryColor;
            border-bottom-left-radius: 5px;
            border-top-left-radius: 5px;
            text-align: center;
          }
          .ant-input-search-button {
            background: $primaryColor;
            border-bottom-right-radius: 5px;
            border-top-right-radius: 5px;
            border-bottom-left-radius: 0px;
            border-top-left-radius: 0px;
            width: 25px;
            text-align: center;
          }
          .anticon svg {
            margin-top: -5px;
          }
          .ant-input:hover {
            border-color: $borderColor;
          }
          .ant-btn:hover {
            border-color: $borderColor;
          }
          .ant-input-search .ant-input-search-button:not(.ant-btn-primary) {
            border-left-color: $borderColor;
          }
          .ant-input:focus .ant-input-focused {
            box-shadow: none;
          }
        }

        .searchCont:hover {
          .ant-input:hover {
            border-color: white;
          }
          .ant-btn:hover {
            border-color: white;
          }
          .ant-input-search .ant-input-search-button:not(.ant-btn-primary) {
            border-left-color: white;
          }
          .ant-input-search-button {
            border-color: white;
            background: $secondaryColor 0% 0% no-repeat padding-box;
          }
          .ant-input {
            background: $secondaryColor 0% 0% no-repeat padding-box;
          }
        }
      }

      .entryPageDepartmentName {
        @include div(row, "", center);
        width: 320px;
        height: 161px;
        display: flex;
        flex-direction: column;
        text-align: center;
        // background: #F04F4E 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 4px #00000029;
        border-radius: 10px;
        // cursor: pointer;

        .header {
          flex: 1;
          padding: 12px 5px 6px 5px;
          background: #f7f8fa;
          font: normal normal 600 16px/22px Open Sans;
          letter-spacing: 0px;
          color: #323338;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          display: flex;
          align-items: center;
          justify-content: center;

          p {
            margin-bottom: 0px;
          }
        }

        .body {
          flex: 4;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border-radius: 0 0 10px 10px;
          color: #fff;

          p {
            margin: 0;
          }

          .number {
            margin: 0;
            font: normal normal 600 56px/76px Open Sans;
            letter-spacing: 0px;
          }

          .text {
            font: normal normal normal 17px/23px Open Sans;
            letter-spacing: 0px;
            white-space: nowrap;
            width: 90%;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      .submitButtonContainer {
        .backButton {
          @include buttons(".previewButton", #1264a3, #0f578d);
          border-radius: 5px;
          width: 100px;
        }
        .tourButton {
          .mondayButtonText,
          .mondayButtonDivider {
            display: none;
          }
        }
      }
    }

    .right {
      position: relative;
      flex-grow: 3;
      display: flex;
      flex-direction: column;
      gap: 40px;
      padding: 20px;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 1px 4px #00000029;
      border-radius: 10px;

      .entryPageDescription {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        text-align: center;
        gap: 5px;
        font: normal normal normal 14px/19px Open Sans;
        letter-spacing: 0px;
        color: #323338;

        .infoIcon {
          border-radius: 50%;
          background-color: #1264a3;
          color: #fff;
        }
      }

      .usersList {
        @include div(column, center, "");
        // margin-top: 80px;
        height: fit-content;
        // margin-bottom: 20px;
        max-height: 500px;
        // margin-top: 80px;
        @include buttons(".previewButton", #195aaf, #0d4691);

        .usersListHeader {
          @include size(650px, 36px);
          @include div(row, "", space-evenly);

          .usersListHeaderTitle {
            @include div(row, center, center);
            @include size(33%, 36px);
            @include textStyle(left);
            font: normal normal bold 20px/27px Open Sans;
          }
        }

        .usersListBody {
          @include div(column, "", "");
          height: fit-content;
          max-height: 380px;
          overflow-y: auto;
          margin-top: 10px;
          .usersListBodyElement {
            @include size(650px, 36px);
            @include div(row, "", space-evenly);
            margin-bottom: 2px;
            .userlistBodyElementName:hover {
              color: #0073ea;
            }
            .userlistBodyElementName {
              @include bodyElement();
              font: normal normal 600 14px/24px Open Sans;
              letter-spacing: 0px;
              color: #1264a3;
              border-left: 8px solid #0073ea;
              margin-right: 2px;
              cursor: pointer;
            }
            .userlistBodyElementLog {
              @include bodyElement();
            }
            .userListBodyElementDelete {
              @include bodyElement();

              .cancelButton {
                @include size(25px, 25px);
                background: red 0% 0% no-repeat padding-box;
                opacity: 1;
                border-radius: 5px;
                cursor: pointer;
                @include div(row, center, center);
                .cancelButtonImage {
                  z-index: 2;
                  @include size(20px, 20px);
                }
              }
              .cancelButton:hover {
                background: rgb(177, 0, 0) 0% 0% no-repeat padding-box;
              }
            }
          }
        }
      }

      .nofound {
        @include size(fit-content, 37px);
        padding-left: 20px;
        padding-right: 20px;
        margin-top: 10px;
        @include textStyle(left);
        font: normal normal bold 24px/39px Open Sans;
        @include div(row, "", center);
      }
    }
  }

  .second-row {
    .entryRolePage-unselected {
      overflow: auto;
      display: flex;
      height: 300px;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
      padding: 10px;
      background: #fff 0% 0% no-repeat padding-box;
      box-shadow: 0px 1px 4px #00000029;
      border-radius: 10px;
      overflow-y: auto;

      .entryPageDepartmentName {
        width: 320px;
        min-width: 300px;
        height: 161px;
        display: flex;
        flex-direction: column;
        text-align: center;
        box-shadow: 0px 1px 4px #00000029;
        border-radius: 10px;

        .header {
          flex: 1;
          padding: 12px 5px 6px 5px;
          background: #f7f8fa;
          font: normal normal 600 16px/22px Open Sans;
          letter-spacing: 0px;
          color: #323338;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          display: flex;
          align-items: center;
          justify-content: center;
          p {
            margin: 0;
          }
        }

        .body {
          flex: 4;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border-radius: 0 0 10px 10px;
          color: #fff;
          cursor: pointer;
          p {
            margin: 0;
          }

          &:hover {
            filter: brightness(85%);
          }

          .number {
            margin: 0;
            font: normal normal 600 56px/76px Open Sans;
            letter-spacing: 0px;
          }

          .text {
            font: normal normal normal 17px/23px Open Sans;
            letter-spacing: 0px;
            white-space: nowrap;
            width: 90%;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }

    .entryRolePage-unselected-mobile {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .body {
        padding: 10px 0;
      }
    }
  }

  .entryPageDepartments {
    @include div(column, center, center);
    margin-top: 30px;
    .entryPageDepartmentName {
      @include textStyle(left);
      font: normal normal bold 24px/39px Open Sans;
      margin: 2;
      cursor: pointer;
    }

    .entryPageDepartmentName:hover {
      background: $secondaryColor 0% 0% no-repeat padding-box;
      padding-left: 10px;
      padding-right: 10px;
      border-radius: 7px;
    }
  }
}

/////////////// Dark Mode ///////////////////
.departmentRolePageDark {
  .first-row {
    .left {
      background-color: $cardDarkBackgroundColor;
      .actions {
        .header {
          color: #fff !important;
        }
        .ant-select {
          .ant-input {
            background-color: $inputDarkColor;
            color: #fff;
            &::placeholder {
              color: #acacca;
            }
          }
          .ant-input-group-addon .ant-btn {
            background-color: $inputDarkColor;
            border-left: 1px solid $mainDarkBackgroundColor !important;
            .ant-btn-icon .anticon {
              svg {
                fill: #fff;
              }
            }
          }
        }
        .searchCont:hover {
          .ant-input-search-button {
            background: $mainDarkBackgroundColor 0% 0% no-repeat padding-box;
          }
          .ant-input {
            background: $mainDarkBackgroundColor 0% 0% no-repeat padding-box;
          }
        }
        .anticon-user {
          svg {
            fill: #fff;
          }
        }
      }
      .entryPageDepartmentName {
        .header {
          border-radius: 10px 10px 0px 0px;
          background-color: $cardDarkHeaderColor;
          .title {
            color: #fff;
          }
        }
      }
    }
    .right {
      position: relative;
      background-color: $cardDarkBackgroundColor;
      .entryPageDescription {
        color: #fff;
        .infoIcon {
          svg {
            fill: #12131b;
          }
        }
      }
      .usersList {
        .usersListHeader {
          .usersListHeaderTitle {
            color: #fff;
          }
        }
        .usersListBody {
          .usersListBodyElement {
            .userlistBodyElementName,
            .userlistBodyElementLog,
            .userListBodyElementDelete {
              background-color: $cardDarkHeaderColor;
              color: #fff;
            }
          }
        }
      }
    }
  }
  .second-row {
    .entryRolePage-unselected {
      background-color: $cardDarkBackgroundColor;
      .entryPageDepartmentName {
        .header {
          border-radius: 10px 10px 0px 0px;
          background-color: $cardDarkHeaderColor;
          .title {
            color: #fff;
          }
        }
      }
    }
  }
}
/////////////// Dark Mode ///////////////////

@media (max-width: 1300px) {
  .departmentRolePage {
    .first-row {
      flex-direction: column;
    }
  }
}

@media (max-width: 800px) {
  .departmentRolePage {
    .first-row {
      .right {
        position: relative;
        box-sizing: border-box;
        overflow: scroll;
        min-height: 300px;
        .usersList {
          position: absolute;
          top: 100px;
          left: 0;
        }
      }
    }
  }
}
