@import "../../../../index.scss";

$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.leadModalContainer {
  .ant-modal-centered::before {
    display: none;
  }
  .ant-modal-content {
    // max-height: fit-content;
    max-height: calc(100vh - 20px);
    max-width: calc(100vw - 20px);
  }
  .ant-modal-body {
    padding-bottom: 55px;
  }
  .businessAddressSectionContainer {
    padding-top: 15px;
  }

  .businessAddContainer {
    @include flex(flex-start, flex-end);
  }

  .splitAddContainer {
    // width: 100%;
    @include flex(flex-start, flex-start);
    flex-wrap: wrap;
  }

  .streetViewIcon {
    height: 35px;
    cursor: pointer;
  }
  .shippingAddIcon {
    position: absolute;
    top: 50px;
    right: 110px;
  }

  .billingAddIcon {
    position: absolute;
    left: 272px;
    top: 50px;
  }
  // .addressCheckbox {
  //   position: absolute;
  //   top: 0;
  //   right: 0;
  // }
  .leadModalBodyContainer {
    display: grid;
    grid-template-columns: [full-start] auto auto [full-end];
    column-gap: 15px;
    max-height: calc(100vh - 250px);
    padding-top: 10px;
    overflow-x: hidden;
    overflow-y: auto;

    .leadBorderedTextCard {
      border: none;
      border-radius: 0;
      border-top: 1px solid #323338;
      width: max-content;
      max-width: 62.5rem;
      padding: 20px 0 20px;
      max-height: 500px;
      align-items: center !important;
      .leadAvatarsDiv {
        display: flex;
        gap: 20px;
        position: relative;
        top: 32px;
      }

      .labeledInputContainer {
        height: 70px;
      }
      .cardTitle {
        left: 45%;
        font-size: 16px;
        color: #323338;
      }
      #leadCompany,
      #leadEmail,
      #leadWebsite {
        width: 250px;
      }
      #leadFirstName,
      #leadMiddleName,
      #leadLastName,
      .form-control,
      #phoneExtension {
        width: 225px;
      }
      #preQualificationWebsite,
      #preQualificationDate {
        width: 335px;
      }
      .ant-select-selector {
        min-width: 225px;
        max-width: 650px;
      }
      .googlePickerButtonContainer {
        margin: 10px;
      }
      .configElementContainer {
        height: 32px;
        width: 200px;
        position: relative;
        // margin-left: 40px;
        top: 40px;
        .configElementTitle {
          font-size: 16px;
        }
        .bluContainer {
          // background-color: #5fb13b;
          .navyBlueContainer {
            box-shadow: 0 0 3px 3px rgb(255 255 255 / 0.2);
            background-color: #fff;
          }
        }
      }
    }
    .billingBorderedTextCard {
      border: none;
      border-radius: 0;
      border-top: 1px solid #323338;
      max-height: 500px;
      margin-left: 30px;
      align-items: center;
      .cardTitle {
        font-size: 16px;
        color: #323338;
      }
      .billingAndShippingAddressesContainer {
        max-width: 800px;
      }
      .shippingAddIcon {
        right: 6.6rem !important;
      }
      .labeledInputContainer {
        height: 70px;
        width: 300px;
        .ant-input {
          height: 32px;
        }
      }
    }
  }
  .newFooterContainer {
    button {
      width: 150px;
    }
  }
  .leadModalBodyContainer-splash {
    grid-column: full;
  }
  .labeledInputContainer {
    margin-right: 15px;

    .labeledInputLabel {
      @include text(14px, 600, 0.21px, #383554);
      margin-bottom: 2px;
    }
  }

  .radioTitle {
    @include text(14px, 600, 0, #323338);
    margin-right: 15px;
  }

  .bodyTypeRadio {
    @include flex(baseline, flex-start);
  }
}

.leadModalContainerDark {
  .leadModalBodyContainer {
    .leadBorderedTextCard,
    .billingBorderedTextCard {
      border-top-color: #f2f2f2;
      .cardTitle {
        background-color: $cardModalBodyDark;
        color: #f2f2f2;
      }
      .childrenContainer {
        .labeledInputLabel {
          color: #f2f2f2;
        }
        .ant-input {
          background-color: $inputModalDark !important;
          color: #f2f2f2;
          &::placeholder {
            color: #acacca;
          }
        }
        .ant-select {
          .ant-select-selector {
            background-color: $inputModalDark !important;
            color: #f2f2f2;
            .ant-select-selection-search-input {
              color: #f2f2f2;
            }
            .ant-select-selection-item {
              color: #f2f2f2;
            }
            .ant-select-selection-placeholder {
              color: #acacca;
              z-index: 2;
            }
          }
          .ant-select-arrow svg {
            fill: #f2f2f2;
          }
          .ant-select-clear {
            background-color: $inputModalDark;
            svg {
              fill: #f2f2f2;
            }
          }
        }
        .react-tel-input {
          .form-control {
            background-color: $inputModalDark;
            color: #f2f2f2;
            &::placeholder {
              color: #acacca;
            }
          }
          .flag-dropdown {
            background-color: $inputModalDark;
            .arrow {
              border-top-color: #f2f2f2;
              border-bottom-color: #f2f2f2;
            }
            &:hover {
              background-color: $inputModalDark;
            }
            &:focus {
              background-color: $inputModalDark;
            }
          }
          .open {
            background-color: $inputModalDark;
            border-radius: 5px 0px 0px 5px;
          }
          .country-list {
            background-color: $inputModalDark;
            .country.highlight {
              background-color: $inputModalDark;
              .country-name,
              .dial-code {
                color: #f2f2f2;
              }
            }
          }
        }
        .ant-picker {
          background-color: $inputModalDark !important;
          .ant-picker-input {
            background-color: $inputModalDark;
            input {
              color: #f2f2f2;
            }
            input::placeholder {
              color: #acacca;
            }
            .ant-picker-suffix svg {
              fill: #acacca;
            }
          }
        }
        .googlePickerButtonContainer {
          .ant-btn {
            border: none;
          }
        }
        .configElementContainer {
          background-color: $inputModalDark;
          .configElementTitle {
            color: #f2f2f2;
          }
        }
      }
      .copyIconDiv {
        svg path {
          fill: #f2f2f2 !important;
        }
      }
    }
  }
}

.teamBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  Button {
    height: 30px;
    border-radius: 5px !important;
  }
}

@media (max-width: 1750px) {
  .leadModalContainer .leadModalBodyContainer {
    display: block;
  }
}
@media (max-width: 1100px) {
  .leadBorderedTextCard {
    display: table-cell !important;
  }
  .leadModalContainer .leadModalBodyContainer {
    display: block !important;
    max-width: calc(100vw - 100px);
    .billingBorderedTextCard {
      margin-top: 0;
    }
    .shippingAddIcon {
      position: absolute;
      right: 9.5rem !important;
    }
  }
  .leadModalContainer:first-child {
    margin-left: -3rem;
  }
  .billingBorderedTextCard {
    margin-top: 3rem;
  }
}

@media (max-width: 850px) and (min-width: 700px) {
  .billingBorderedTextCard {
    padding: 0;
    .billingAndShippingAddressesContainer {
      margin-top: 1rem;
    }
    .billingAddIcon {
      left: 17rem !important;
    }
    .shippingAddIcon {
      left: 39.5rem !important;
    }
    .copyIconDiv {
      left: 44%;
    }
  }
}

@media (max-width: 750px) {
  .leadModalContainer {
    .businessAddContainer {
      display: grid !important;
    }
    .shippingAddIcon {
      left: 295px;
      top: 295px;
    }
  }
}

@media (max-width: 735px) {
  .leadModalContainer .shippingAddIcon {
    top: 455px;
  }
}

@media (max-width: 480px) {
  .leadModalContainer {
    .leadBorderedTextCard {
      display: initial;
      .cardTitle {
        left: 20% !important;
      }
      #leadCompany,
      #leadEmail,
      #leadWebsite,
      #leadFirstName,
      #leadMiddleName,
      #leadLastName,
      .ant-select-selector,
      .form-control,
      #phoneExtension,
      #preQualificationWebsite,
      #preQualificationDate {
        width: 260px !important;
      }
    }
    .leadModalBodyContainer {
      .configElementContainer {
        width: 250px !important;
        .configElementTitle {
          font-size: 14px !important;
        }
      }
      .labeledInputContainer:first-child {
        margin-top: 1rem;
      }
      .leadModalBodyContainer-splash {
        margin-top: 30rem;
      }
    }

    .childrenContainer {
      .react-tel-input .form-control {
        width: 100%;
      }
      .businessAddContainer .splitAddContainer {
        .labeledInputContainer {
          min-width: 100% !important;
        }
        .labeledInputContainer:first-child {
          margin-right: 0px;
          left: 20px;
        }
      }
    }
    .shippingAddIcon {
      top: 555px;
      left: 85%;
    }
    .streetViewIcon {
      left: 85%;
    }
    .billingBorderedTextCard {
      margin: 6rem 0 0 0 !important;
      .copyIconDiv {
        position: absolute;
        top: 29rem;
      }
      .labeledInputContainer {
        max-width: 150px;
      }
      .splitAddContainer {
        margin-bottom: 4rem;
      }
    }
  }
  .newFooterContainer {
    button {
      width: 100px !important;
    }
  }
}
