.tableFields-emailTemplateModal {
  .ant-modal-close {
    height: 45px;
    width: 45px;
    .ant-modal-close-x {
      width: 45px;
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        fill: #323338;
      }
    }
  }

  .ant-modal-header {
    display: flex;
    align-items: center;
    height: 45px;
    background-color: #f5f5f7;
    .ant-modal-title {
      color: #323338;
      font-size: 16px;
      font-weight: 600;
      width: 100%;
    }
  }

  .ant-modal-body {
    .checkAllContainer {
      padding: 20px;
    }
    .tableFieldsBody {
      padding: 20px;
      display: grid;
      grid-template-columns: auto auto;
    }
  }

  .ant-modal-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
