@import "../../../../../../../../../assets/styles/breakpoints.scss";

.payment-form-container {
  margin-bottom: 10px;
  width: 100%;
  .payment-form-wrapper {
    box-shadow: 0px 0px 11px -8px;
    height: 100%;
    background-color: #ffffff;
    border-radius: 10px;
    .payment-form-header {
      padding: 0 20px;
      display: flex;
      justify-content: space-between;
      span {
        font-weight: 600;
      }
    }
    .payment-form-header {
      display: flex;
      height: 45px;
      background: #f7f8fa 0% 0% no-repeat padding-box;
      align-items: center;
      z-index: 2;
      padding: 0 10px 0 5px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      .payment-customer-balance {
        display: flex;
        gap: 8px;
        white-space: nowrap;
      }
      .payment-form-title {
        width: calc(100% - 35px);
        letter-spacing: 0px;
        color: #323338;
        opacity: 1;
        cursor: pointer;
        font-weight: 600;
        font-size: 16px;
        font-family: inherit !important;
      }
    }

    .payment-form-body {
      width: 100%;
      padding: 20px;
      font-family: inherit;
      background-color: white;
      border-radius: 0 0 8px 8px;

      .payment-form {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 25px;
        padding: 20px;

        .payment-form-elements {
          display: flex;
          gap: 10px;
          width: inherit;
          flex-wrap: wrap;

          > div {
            display: flex;
            margin-right: 15px;
            width: 100%;
          }

          input[type="number"] {
            text-align: right;
          }

          .ant-picker {
            background-color: #f4f5f6 !important;
            border: 0;
            input {
              text-align: right;
            }
          }

          .payment-form-element {
            display: flex;
            flex-direction: column;
            flex: 1 0 15%;

            @include mobile {
              flex: 1 0 100%;
            }

            > label {
              text-align: center;
              width: 230px;
              margin-right: 50px;
              font-weight: 600;
            }
            .input-form {
              width: 100%;
              height: 32px;
              background-color: #f4f5f6 !important;
              padding: 2px;
              border: 2px solid transparent;
              text-align: right;
            }
          }
          .auto-complete {
            width: 100%;
            display: flex;
            align-items: center;
            > div {
              background-color: #f4f5f6 !important;
              border-radius: 5px;
              height: 32px;
              border: 0;
            }
          }

          .auto-complete:focus {
            border-color: #87c0ef6d !important;
            border-width: 2px;
            outline: 0;
            -webkit-box-shadow: 0 0 0 0px;
            box-shadow: 0 0 0 0px rgba(87, 168, 233, 0.2);
          }

          .auto-complete:hover {
            border-color: #87c0ef6d !important;
            border-width: 2px;
            outline: 0;
            -webkit-box-shadow: 0 0 0 0px;
            box-shadow: 0 0 0 0px rgba(87, 168, 233, 0.2);
          }

          .ant-input-number-input {
            // padding: 0 !important;
            // padding: 0 0 0 0 !important;
            padding-top: 2px;
            display: flex;
            height: 100%;
            align-items: center;
            text-align: right;
          }

          .ant-select-disabled.ant-select:not(.ant-select-customize-input)
            .ant-select-selector {
            color: black;
            background: #f5f5f5;
            cursor: not-allowed;
          }
          .input-form:focus {
            border-radius: 5px;
            border: 2px solid #87c0ef6d !important;
            outline: 0;
            -webkit-box-shadow: 0 0 0 0px;
            box-shadow: 0 0 0 0px rgba(87, 168, 233, 0.2);
          }

          .input-form:hover {
            outline: 0;
            -webkit-box-shadow: 0 0 0 0px;
            box-shadow: 0 0 0 0px rgba(87, 168, 233, 0.2);
          }
        }
      }
    }
  }
}
