@import "../../../../../assets/styles/breakpoints.scss";

$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;
$whiteColor: #ffffff;
$grayColorLight: #f4f5f6;

.wrapperCreateInvoiceContainer {
  overflow: hidden;
}

.createInvoiceContainer {
  // position: initial;
  top: 0 !important;
  padding: 0 !important;
  width: 100% !important;
  height: 100% !important;
  margin: 0;
  max-width: 100%;

  .ant-modal-close-x {
    svg {
      fill: white;
    }
  }
  .ant-modal-content {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: $grayColorLight;
    .ant-modal-header {
      padding: 0 !important;
      display: flex;

      .ant-modal-title {
        justify-content: space-between;
        padding: 0px 16px;
        font-family: Helvetica;
        font-size: 22px;
        font-weight: bold;
        opacity: 1;
        text-transform: none;
        letter-spacing: 0.41px;
        height: fit-content;
        width: 100%;
        display: flex;
        align-items: center;
        background: #1f2a44;
        color: white;
        svg {
          fill: white;
          height: 50px;
          cursor: pointer;
          z-index: 1;
        }
        .invoice-modal-title {
          // position: absolute;
          text-align: start;
          flex: 1 1 50%;

          @include tablet {
            font-size: 16px;
          }
        }

        .invoice-modal-item-name {
          font-size: 16px;

          @include tablet {
            display: none;
          }
        }

        .accountingWriteModeContainerLight {
          z-index: 1;
        }
      }
    }
    .ant-modal-close-icon {
      svg {
        color: white;
      }
    }

    .ant-modal-body {
      height: -webkit-fill-available;
      display: flex;
      flex-direction: column;
      gap: 20px;
      background-color: $grayColorLight;
      overflow: overlay;

      .editWrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;
        // justify-content: space-between;
      }
    }
    .ant-modal-footer {
      padding: 0 !important;
      box-shadow: 0px 0px 11px -8px;
      border-radius: 10px;
      background-color: white;
      margin: 0 20px 20px 20px !important;
      > div {
        .body {
          display: flex;
          justify-content: flex-end;
          padding: 10px;
          font-family: inherit;
          .cancelButton {
            background: #fe4c4a !important;
            .mondayButtonDivider {
              background-color: #d25655;
            }
          }

          .mondayButtonContainer {
            margin-left: 10px;
          }
        }
      }
    }
    .ant-select-item {
      line-height: 7px !important;
    }
    .invoice-modal-header {
      display: flex;
      width: 100%;
      margin-left: 2px;
      align-items: center;
      padding: 0px !important;

      svg {
        margin-left: auto;
      }
      .darkModeContainer {
        flex: 1 1 45%;
        margin-left: 20px;
        display: flex;
        .firstSquare {
          background-color: white;
        }
        .secondSquare {
          background-color: white;
        }
        .innerCircle {
          background-color: white;
        }
        .outerCircle {
          background-color: #12131b;
        }
      }
    }

    .footerInvoiceModal {
      display: "flex";
      align-items: "center";
      justify-content: "space-between";
    }
  }
}

.createInvoiceContainer-dark {
  @extend .createInvoiceContainer;

  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-header {
      .ant-modal-title {
        background: $cardModalHeaderDark;
        color: $whiteColor;
      }
    }
    .ant-modal-body {
      background-color: $cardModalBodyDark;
    }
    .ant-modal-footer {
      box-shadow: 0px 0px 4px 2px #71cf48;
      background-color: $cardModalBodyDark;
      color: $whiteColor !important;
    }
    .footerInvoiceModal {
      h4 {
        color: $whiteColor !important;
      }
    }
  }
}
