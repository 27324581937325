$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;

.multi-logs-header {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.multi-logs-body {
  // overflow: auto;
  height: 100%;
  max-height: 80vh;
  width: 100%;
  // margin-bottom: 5px;
  display: flex;
  flex-direction: column;

  .search-logs {
    width: 240px;
    margin-bottom: 16px;
  }

  .logs-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .no-logs {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: xx-large;
    color: gainsboro;
  }
}

.multi-logs-header {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.multi-logs-body-dark {
  overflow: auto;
  max-height: 80vh;
  width: 100%;
  margin-bottom: 5px;

  .no-logs-dark {
    width: 100%;
    height: calc(100% - 48px);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: xx-large;
    color: gainsboro;
  }
}
