$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.dispatchDaysChangesModal {
  .ant-modal {
    width: 900px;
  }
  .ant-modal-header {
    display: flex;
    align-items: center;
    background-color: #f8f8fa;
    height: 45px;
  }
  .ant-modal-footer {
    display: flex;
    justify-content: flex-end;
  }
  .dispatchDaysChangesTable {
    width: 100%;
    border-collapse: collapse !important;
  }
  .dispatchDaysChangesTableHeader {
    th {
      border: 1px solid #d9d9d9;
      border-collapse: collapse !important;
    }
  }
  .dispatchDaysChangesTableBody {
    td {
      border: 1px solid #d9d9d9;
      border-collapse: collapse !important;
      padding-left: 10px;
    }
  }
  //
  .dispatchDaysChangesTableBodyOldDate {
    color: red;
  }
  .dispatchDaysChangesTableBodyNewDate {
    color: green;
  }
  .dispatchDaysChangesInfo {
    display: flex;
    flex-direction: row;
    text-align: center;
    margin-bottom: 5px;
    span {
      font-style: italic;
    }
  }
}

.dispatchDaysChangesModalDark {
  color: #fff;
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      background-color: $cardModalBodyDark;
      .dispatchDaysChangesInfo {
        color: #fff;
      }
      .dispatchDaysChangesTable {
        .dispatchDaysChangesTableHeader {
          background-color: #20212d;
          th {
            color: #fff;
            border-color: grey;
          }
        }
      }
    }
  }
}
