.apply-credit-payment-container {
  width: 1200px;
  // height: 600px;
  .ag-wrapper.ag-input-wrapper.ag-text-field-input-wrapper {
    color: black !important;
  }
  .inputs-container {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // align-items: center;
    gap: 20px;
    .input-holder {
      display: flex;
      width: 500px;
      .label-input {
        width: 200px;
        display: flex;
        flex-direction: column;
        gap: 5px;
      }
      justify-content: space-between;
      gap: 10px;
      align-items: center;
    }
  }
}
