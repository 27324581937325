// .search-input.table-view {
//   width: 200px;
// }
$mainDarkBackgroundColor: #20212d;
$cardDarkHeaderColor: #2a2b3a;
$cardDarkBackgroundColor: #12131b;
$inputDarkColor: #2a2b3a;

.react-tel-input {
  .form-control {
    height: 32px;
  }

  .country-list .search-emoji {
    display: none;
  }

  .form-control,
  .flag-dropdown {
    border: 0;
    background-color: #f4f5f6;
  }
}

.labeledInputContainer {
  .ant-select {
    width: 100%;
  }
  .ant-input-group,
  .ant-input-group-addon,
  .ant-input-affix-wrapper,
  .ant-input-number-affix-wrapper,
  .ant-select-selector,
  .ant-picker,
  .ant-input-number,
  .ant-input-number-input,
  .ant-input {
    height: 32px;
    border: 0 !important;
    border-radius: 5px !important;
    background-color: #f4f5f6 !important;
    transition: background-color 0.2s ease-in;

    &::placeholder {
      color: #acacac;
    }

    .select-after {
      .ant-select-selector {
        background-color: rgba(0, 0, 0, 0.06) !important;
      }
    }
    .ant-input-prefix,
    .ant-input-suffix {
      margin-right: 10px;
      svg {
        width: 16px;
        height: 16px;
        path {
          fill: #323338 !important;
        }
      }
    }

    .ant-input-group-addon {
      padding: 0 0 0 10px;
      margin: 0;
      svg {
        width: 16px;
        height: 16px;
        path {
          fill: #323338 !important;
        }
      }
    }
  }

  .ant-input-affix-wrapper .ant-input {
    height: 100%;
  }

  .ant-input-number-group-addon {
    border: 0;
  }

  .ant-select-selector {
    min-height: 32px;
    height: 100%;
  }

  &.dark-mode {
    .labeledInputLabel {
      color: #ffffff;
    }

    .ant-input-group,
    .ant-input-group-addon,
    .ant-input-affix-wrapper,
    .ant-input-number-affix-wrapper,
    .ant-select-selector,
    .ant-picker,
    .ant-input-number,
    .ant-input-number-input,
    .ant-input {
      border: 0 !important;
      border-radius: 5px !important;
      background-color: #12131b !important;
      color: #fff !important;
      &::placeholder {
        color: #acacca;
      }

      .ant-input-group-addon,
      .ant-input-prefix,
      .ant-input-suffix {
        svg {
          path {
            fill: #acacca !important;
          }
        }
      }
    }

    .ant-select-selection-placeholder,
    .ant-picker-input input::placeholder {
      color: #acacca !important;
    }

    .ant-picker-range-separator,
    .ant-select-arrow,
    .ant-picker-suffix {
      svg {
        fill: #acacca !important;
      }
    }
  }
}

.dynamicDropdownInputComponent {
  background: #ffffff !important;
  border-radius: 5px !important;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25) !important;

  @media (max-width: 769px) {
    max-width: 150px;
  }

  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background: transparent;
  }

  .rc-virtual-list {
    .rc-virtual-list-holder {
      .rc-virtual-list-holder-inner {
        .ant-select-item-option-selected {
          background: #f1f5fd;

          .ant-select-item-option-content {
            color: #1264a3;
          }
        }

        .ant-select-item {
          padding-left: 10px;
          padding-right: 10px;
          padding-top: 6.5px;
          padding-bottom: 6.5px;

          .ant-select-item-option-content {
            font-family: "Open Sans";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            margin: 0;
            padding: 0;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          &:not(:last-child) {
            border-bottom: 1px solid #efefef;
          }

          &:hover {
            background: #f1f5fd;

            .ant-select-item-option-content {
              color: #1264a3;
            }
          }
        }
      }
    }
  }
}

.lightDropDown {
  width: 50%;
  min-width: 300px !important;

  .ant-select-item-option-content {
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
  }
}

.darkDropDown {
  background-color: $cardDarkBackgroundColor !important;
  box-shadow: 0px 1px 4px rgba(255, 255, 255, 0.2) !important;
  .ant-select-item-empty {
    color: #fff;
  }
  .ant-select-item-option-content {
    color: #fff;
  }
  .ant-select-item-option-disabled {
    .ant-select-item-option-content {
      color: #acacca;
    }
  }
  .rc-virtual-list {
    .rc-virtual-list-holder {
      .rc-virtual-list-holder-inner {
        .ant-select-item-option-selected {
          background: $inputDarkColor;
        }
        .ant-select-item {
          border: none !important;
          color: #acacca;
          &:hover {
            background-color: $inputDarkColor;
            .ant-select-item-option-content {
              color: #fff;
            }
          }
        }
        .ant-select-item-option-active {
          background-color: $inputDarkColor;
        }
      }
    }
  }
  .ant-empty-description {
    color: #acacca;
  }
}

.multiSelectDesign {
  .ant-select-status-success {
    .ant-select-selection-overflow-item-suffix {
      position: absolute;
      min-height: 32px;
    }
  }

  .ant-select-selector {
    height: 32px;

    .ant-select-selection-overflow {
      background: transparent;
      border-radius: 0 0 5px 5px;
      min-width: 200px !important;
      padding: 5px;
      padding-top: 3px;
      flex-direction: column-reverse;
      gap: 2px;

      .ant-select-selection-overflow-item {
        width: 100%;
        margin-bottom: 10px;

        .ant-select-selection-search {
          margin-inline-start: 0px !important;
        }

        .ant-select-selection-item {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background: transparent;
          border: none;
          border-radius: 3px;
          padding-left: 0px;

          .ant-select-selection-item-remove {
            width: 22px;
            display: flex;
            align-items: center;
            justify-content: center;

            .anticon-close svg {
              height: 18px;
              width: 18px;
              fill: #fff;
              font-weight: 600;
              background-color: #fe4c4a;
              border-radius: 3px;
              padding: 2px;

              &:hover {
                opacity: 0.7;
              }
            }
          }
        }
      }
    }
  }
}

.darkDropDown {
  background-color: $cardDarkBackgroundColor !important;
  width: 50%;
  min-width: 300px !important;
  .ant-select-item-option-content {
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
  }
  box-shadow: 0px 1px 4px rgba(255, 255, 255, 0.2) !important;
  .ant-select-item-empty {
    color: #fff;
  }
  .ant-select-item-option-content {
    color: #fff;
  }
  .ant-select-item-option-disabled {
    .ant-select-item-option-content {
      color: #acacca;
    }
  }
  .rc-virtual-list {
    .rc-virtual-list-holder {
      .rc-virtual-list-holder-inner {
        .ant-select-item-option-selected {
          background: $inputDarkColor;
        }
        .ant-select-item {
          border: none !important;
          color: #acacca;
          &:hover {
            background-color: $inputDarkColor;
            .ant-select-item-option-content {
              color: #fff;
            }
          }
        }
        .ant-select-item-option-active {
          background-color: $inputDarkColor;
        }
      }
    }
  }
  .ant-empty-description {
    color: #acacca;
  }
}

.darkDateDropDown {
  .ant-picker-panel-container {
    width: 100%;
    .ant-picker-panel-layout {
      width: 100%;
    }

    .ant-picker-panel-layout > div {
      width: 100%;
    }
    background-color: $cardDarkBackgroundColor;
    .ant-picker-panel {
      width: 100%;
      .ant-picker-header {
        color: #f2f2f2;
        border-bottom: 1px solid #2a2b3a;
        .ant-picker-header-super-prev-btn,
        .ant-picker-header-prev-btn,
        .ant-picker-header-next-btn,
        .ant-picker-header-super-next-btn {
          color: #acacca;
        }
      }
      .ant-picker-body {
        .ant-picker-content {
          th {
            color: #f2f2f2;
          }
          .ant-picker-cell {
            color: #acacca;
          }
          .ant-picker-cell-in-view {
            color: #f2f2f2;
          }
          .ant-picker-cell-disabled {
            &::before {
              background-color: #212331;
            }
            .ant-picker-cell-inner {
              color: #acacca;
            }
          }
          .ant-picker-cell-in-range::before {
            background-color: $inputDarkColor;
          }
          .ant-picker-cell-range-start,
          .ant-picker-cell-range-end {
            &::before {
              background-color: $inputDarkColor;
            }
          }
        }
      }
      .ant-picker-time-panel {
        border-left: 1px solid #2a2b3a;
        .ant-picker-time-panel-cell-selected {
          .ant-picker-time-panel-cell-inner {
            background-color: #2a2b3a;
          }
        }
        .ant-picker-time-panel-column:not(:first-child) {
          border-inline-start: 1px solid #2a2b3a;
        }
        .ant-picker-time-panel-column {
          .ant-picker-time-panel-cell-inner {
            color: #f2f2f2;
          }
        }
      }
    }
    .ant-picker-cell-today {
      background-color: $cardDarkHeaderColor !important;
    }
    .ant-picker-footer {
      border-top-color: $cardDarkHeaderColor;
      .progress-ant-date-picker-extra {
        color: #f2f2f2;
        .ant-progress-text {
          color: #f2f2f2;
        }
        circle {
          stroke: #6161614f;
        }
      }
    }
  }
  .ant-picker-presets ul li {
    color: #fff;
  }
  .ant-picker-ranges {
    .ant-picker-ok {
      .ant-btn-primary:disabled {
        color: #acacca;
      }
    }
  }
  .ant-picker-range-arrow::before {
    background-color: $cardDarkBackgroundColor;
  }
}

@media (max-width: 1200px) {
  .ant-picker-dropdown {
    height: 45%;

    .ant-picker-range-wrapper {
      height: 100%;

      .ant-picker-panel-container {
        overflow: auto;

        .ant-picker-panel-layout {
          flex-direction: column;

          .ant-picker-presets {
            display: none;
          }

          .ant-picker-panels {
            flex-direction: column;
          }
        }
      }
    }
  }
}

.darkDropDown
  .ant-picker-range-wrapper
  .ant-picker-panel-container
  .ant-picker-panel-layout {
  .ant-picker-presets {
    background: $cardDarkHeaderColor;

    ul li {
      color: #fff;
    }
  }
  div .ant-picker-panels .ant-picker-panel .ant-picker-date-panel {
    .ant-picker-header {
      background: $cardDarkHeaderColor;
      .ant-picker-header-super-prev-btn span {
        color: #fff;
      }
      .ant-picker-header-prev-btn span {
        color: #fff;
      }
      .ant-picker-header-view {
        color: #fff;
      }
      .ant-picker-super-next-icon {
        color: #fff;
      }
      .ant-picker-next-icon {
        color: #fff;
      }
    }
    .ant-picker-body {
      background: $mainDarkBackgroundColor;
      .ant-picker-content {
        tbody tr td {
          color: #fff;
        }
        thead tr th {
          color: #fff;
        }
      }
    }
  }
}
