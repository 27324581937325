.LiveCard {
  display: flex;
  padding-top: 10px;
  margin-bottom: 20px;
  .LiveContent {
    display: flex;
    align-items: center;
    flex: 0.7;
    text-align: left;
    font: normal normal normal 20px/27px Open Sans;
    letter-spacing: 0px;
    color: #323338;
    opacity: 1;
  }
  .LiveButton {
    margin-right: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    flex: 0.3;
    width: 202px;
    height: 57px;
    background: #ff4d4f 0% 0% no-repeat padding-box;
    opacity: 1;
    border-radius: 10px;
    .LiveButtonText {
      text-align: left;
      font: italic normal 800 44px/33px Open Sans;
      letter-spacing: 0px;
      color: #ffffff;
      opacity: 1;
      margin-left: 30px;
    }
    .LiveButtonDivider {
      margin-left: 40px;
      width: 2px;
      height: 57px;
      background: #eb4345 0% 0% no-repeat padding-box;
      opacity: 1;
    }
    .LiveButtonIcon {
      margin-left: 1px;
    }
  }
}
