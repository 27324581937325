.boarding-navigation-buttons {
  margin-top: 20px;
  display: flex;
  gap: 30px;
  position: relative;
  .btn {
    &:hover {
      color: #1264a3;
      font-weight: 600;
      cursor: pointer;
      &:after {
        content: "";
        position: absolute;
        width: 17px;
        height: 10px;
        background-size: cover;
        transition: opacity 0.3s ease;
      }
    }
  }

  .btn-back {
    &:hover {
      &:after {
        top: 7px;
        left: -25px;
        background-image: url("../../assets/icons/NextIcon.svg");
        transform: rotate(180deg);
      }
    }
  }
  .btn-next {
    &:hover {
      &:after {
        top: 6px;
        right: -25px;
        background-image: url("../../assets/icons/NextIcon.svg");
      }
    }
  }
}
