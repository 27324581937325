.descriptionButtonInvoiceContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .descriptionButtonInvoiceBody {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 10px;

    .selectInput {
      width: 120px;
    }

    .generateButton {
      background: #6c757d;
      border: 1px solid #6c757d;
    }
  }
}

.descriptionContainerInside {
  display: block;
  overflow: hidden;
  width: -webkit-fill-available;
  border-radius: 5;
  height: 100%;
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
}
