.estimationPickerMainContainer {
  border-radius: 10px;
  width: 100%;
  height: 450px;
  display: flex;
  gap: 14px;
  overflow-x: hidden;
  overflow-y: auto;

  .estimationPickerContainer {
    width: 343px;
    border-radius: 10px;
    height: 90%;
    box-shadow: 0px 1px 4px #00000029;
    padding: 15px 10px;
    overflow-x: hidden;
    overflow-y: auto;

    .tittle {
      font-weight: bold;
      font-size: 18px;
      margin: 0 0 5px 5px;
    }
  }

  .servicePickerContainer {
    width: 343px;
    box-shadow: 0px 1px 4px #00000029;
    border-radius: 10px;
    height: 90%;
    padding: 15px;
    overflow-x: hidden;
    overflow-y: auto;
    
    .isChangeOrderContainer{
      display: flex;
      justify-content: space-between;
      width: 250px;
    }

    .ant-checkbox-wrapper{
      margin: 10px 0 !important;
      width: 315px !important;
    }

    .tittle {
      font-weight: bold;
      font-size: 18px;
      margin: 0 0 5px 5px;
    }
  }
}

@media (max-width: 750px) {
  .estimationPickerMainContainer{
    display: grid;
    grid-template-columns: 49% 49%;
    gap: 10px;
    .servicePickerContainer{
      width: 100%;
    }
    .estimationPickerContainer{
      width: 100%;
    }
  }
}

@media (max-width: 600px) {
  .estimationPickerMainContainer{
    grid-template-columns: 100%;
    gap: 10px;
    padding: 10px 5px;
    .servicePickerContainer{
      height: 320px;
    }
    .estimationPickerContainer{
      height: 320px;
    }
  }
}
