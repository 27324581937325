$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.add-task-modal {
  // top: 20px !important;

  .ant-modal-content {
    max-height: calc(100vh - 0px);
    max-width: calc(100vw - 20px);
    border-radius: 10px !important;

    .ant-modal-close-x {
      height: 45px !important;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .ant-modal-header {
      padding: 0;
      height: 45px;
      display: flex;
      align-items: center;
      padding-left: 20px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      border-bottom: none;
      background-color: #f8f8fa;
      .ant-modal-title {
        color: #323338;
        font-weight: 600;
        font-size: 1rem;
        width: 100%;
      }
    }

    .ant-modal-body {
      .ant-form {
        ///////////////////////////////////////////////////////////////

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 20px;
        width: 100%;

        .modal-box-section {
          height: calc(100vh - 200px);
          width: 100%;
          display: flex;
          gap: 20px;
          overflow-x: hidden;
          overflow-y: auto;
          @media (max-width: 1400px) {
            flex-direction: column;
          }
        }

        .left-section {
          // max-width: 1136px;
          flex: 3;
          .ant-steps {
            padding-top: 0;
          }
          .left-section-details {
            display: flex;
            gap: 20px;
            .docUploader {
              margin-top: 20px;
              flex: 1;
              .uploadedFilesSection {
                max-height: 60vh;
              }
            }
            .block-section {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              gap: 20px;
              flex: 2;
            }
            @media screen and (max-width: 1025px) {
              flex-direction: column-reverse;
              .docUploader {
                margin-top: 0px;
              }
            }
          }
        }
        .right-section {
          flex: 2;
          display: flex;
          justify-content: flex-start;
          // flex-wrap: wrap; /////////////////////////////////////////////////////////////////
          flex-direction: column;

          .login-for-events {
            padding: 10px 5px;
            border-radius: 10px;
            background: #f5f5f5;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
          }
        }
      }
    }

    .ant-modal-footer {
      display: flex;
      flex-direction: row !important;
      justify-content: space-between;
      align-items: center;
      border-top: none;

      .create_task_button {
        border-radius: 5px;
        background-color: #71cf48;
        border-color: #71cf48;
        display: flex;
        align-items: center;
        &:disabled {
          background-color: #f8f8fa;
          border-color: #f8f8fa;
        }
      }
    }
  }

  @media only screen and (max-width: 414px) {
    .ant-modal-body {
      // padding: 18px;
      // .ant-btn-primary {
      //   border-radius: 5px;
      // }
      .task-statuses-dropdown {
        width: 100%;
        margin-bottom: 10px;
        .ant-select {
          width: 100%;
          .ant-select-selector {
            border-radius: 5px;
          }
        }
      }
    }
  }
}

.add-task-modal-dark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      .modal-box-section {
        .left-section {
          .taskStatusSteper {
            .ant-steps {
              .ant-steps-item-finish ::before,
              .ant-steps-item-active::before,
              .ant-steps-item::before {
                border-left-color: $cardModalBodyDark;
              }
              .ant-steps-item-wait {
                background-color: $cardModalHeaderDark;
                &::after {
                  border-left-color: $cardModalHeaderDark;
                }
                .ant-steps-item-content .ant-steps-item-title {
                  color: #acacca;
                }
              }
            }
          }
          .block-section {
            .labeledInputLabel {
              color: #fff;
              span {
                color: #fff;
              }
            }
            .predictInputContainer {
              background-color: $inputModalDark;
              .ant-input {
                background-color: $inputModalDark !important;
                color: #fff;
                &::placeholder {
                  color: #acacca;
                }
              }
            }
            svg path {
              fill: #fff;
            }
            .ant-select {
              .ant-select-selector {
                background-color: $inputModalDark !important;
                .ant-select-selection-search-input {
                  color: #fff;
                }
                .ant-select-selection-overflow-item .ant-select-selection-item {
                  color: #fff;
                  background-color: #323338;
                  .ant-select-selection-item-remove svg {
                    fill: #fff;
                  }
                }
                .ant-select-selection-item {
                  color: #fff;
                }
                .ant-select-selection-placeholder {
                  color: #acacca;
                }
              }
              .ant-select-clear {
                background-color: $inputModalDark;
                svg {
                  fill: #acacca;
                }
              }
              .ant-select-arrow svg {
                fill: #fff;
              }
            }
            .quill {
              background-color: $cardModalBodyDark !important;
              .ql-toolbar {
                background-color: #15161f !important;
                .ql-formats {
                  color: #fff;
                  .ql-picker-label {
                    color: #fff;
                    &::before {
                      color: #fff;
                    }
                    .ql-stroke {
                      stroke: #fff;
                    }
                  }
                  button .ql-stroke {
                    stroke: #fff;
                  }
                }
              }
              .ql-container {
                background-color: $inputModalDark !important;
                .ql-editor {
                  background-color: $inputModalDark !important;
                  &::before {
                    color: #acacca !important;
                  }
                  p {
                    color: #fff !important;
                  }
                }
              }
            }
          }
        }
        .right-section {
          .login-for-events {
            background-color: $cardModalHeaderDark;
            span {
              color: #fff;
            }
          }
          .task-dates-section {
            .taskStartDate,
            .taskEndDate,
            .taskDeadline {
              .durationSpan {
                color: #fff;
              }
              .durationContent {
                background-color: $cardModalHeaderDark;
                .durationEnd {
                  color: #fff !important;
                }
                .ant-picker {
                  background-color: $cardModalBodyDark !important;
                  .ant-picker-input {
                    background-color: $cardModalBodyDark;
                    input {
                      color: #f2f2f2;
                      &::placeholder {
                        color: #acacca;
                      }
                    }
                    .ant-picker-suffix svg {
                      color: #acacca;
                    }
                    .ant-picker-clear {
                      background-color: $cardModalBodyDark;
                      svg {
                        fill: #acacca;
                      }
                    }
                  }
                }
              }
            }
          }
          .task-reminder-section {
            .timeDatePicker {
              background-color: $cardModalHeaderDark;
              .clock-container {
                .selectTimeContainer {
                  .selectTimeText p,
                  .doubleDots {
                    color: #fff;
                  }
                  .timeButtons {
                    .hour,
                    .minute {
                      color: #fff;
                      background-color: $cardModalBodyDark;
                    }
                    .amORpm {
                      .am,
                      .pm {
                        color: #fff;
                        // background-color: $cardModalBodyDark;
                      }
                    }
                    .timeButtonsDeActive {
                      background-color: $cardModalBodyDark !important;
                    }
                    .timeButtonsActive {
                      background-color: #2c2f4e !important;
                    }
                  }
                  .input-section {
                    .ant-input-group-wrapper {
                      background-color: $cardModalBodyDark !important;
                      border-radius: 5px;
                      .ant-input-wrapper {
                        background-color: $cardModalBodyDark !important;
                        height: 32px;
                        .ant-input {
                          background-color: $cardModalBodyDark !important;
                          color: #fff;
                          &::placeholder {
                            color: #acacca;
                          }
                        }
                        .ant-input-group-addon {
                          background-color: $cardModalBodyDark !important;
                          .ant-select {
                            background-color: $cardModalBodyDark !important;
                            border-radius: 0px 5px 5px 0px;
                            .ant-select-selector {
                              background-color: $cardModalBodyDark !important;
                              .ant-select-selection-search-input {
                                color: #fff;
                              }
                              .ant-select-selection-item {
                                color: #fff;
                              }
                              .ant-select-selection-placeholder {
                                color: #acacca;
                              }
                            }
                            .ant-select-clear {
                              background-color: $cardModalBodyDark;
                              svg {
                                fill: #acacca;
                              }
                            }
                            .ant-select-arrow svg {
                              fill: #fff;
                            }
                          }
                        }
                      }
                    }
                  }
                }
                .clock-block {
                  .clock {
                    background-color: $cardModalBodyDark;
                    .number .valueOfNumber {
                      color: #fff;
                    }
                  }
                  .ownComponent2 {
                    .labeledInputLabel {
                      color: #fff;
                    }
                    .ant-select {
                      .ant-select-selector {
                        background-color: $cardModalBodyDark !important;
                        .ant-select-selection-search-input {
                          color: #fff;
                        }
                        .ant-select-selection-item {
                          color: #fff;
                          background-color: #323338;
                          .ant-select-selection-item-remove svg {
                            fill: #fff;
                          }
                        }
                        .ant-select-selection-placeholder {
                          color: #acacca;
                        }
                      }
                      .ant-select-clear {
                        background-color: $cardModalBodyDark;
                        svg {
                          fill: #acacca;
                        }
                      }
                      .ant-select-arrow svg {
                        fill: #fff;
                      }
                    }
                  }
                }
              }
            }
            .task-users {
              .assignee-label {
                color: #fff;
              }
              .modal__multiSelect {
                .ant-select {
                  .ant-select-selector {
                    background-color: $inputModalDark !important;
                    .ant-select-selection-search-input {
                      color: #fff;
                    }
                    .ant-select-selection-item {
                      color: #fff;
                    }
                    .ant-select-selection-placeholder {
                      color: #acacca;
                    }
                  }
                  .ant-select-clear {
                    background-color: $inputModalDark;
                    svg {
                      fill: #acacca;
                    }
                  }
                  .ant-select-arrow svg {
                    fill: #fff;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 414px) {
    .ant-modal-body {
      // padding: 18px;
      // .ant-btn-primary {
      //   border-radius: 5px;
      // }
      .task-statuses-dropdown {
        width: 100%;
        margin-bottom: 10px;
        .ant-select {
          width: 100%;
          .ant-select-selector {
            border-radius: 5px;
          }
        }
      }
    }
  }
}
