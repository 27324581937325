.attachments-container {
    display: flex;
    align-items: center;
    gap: 10px;
    width: fit-content;

    .attachment-item {
        background: inherit;
        border: 1px solid rgba(173, 173, 173, 0.459);

        &:hover {
            opacity: 0.7;
        }
    }

    .handle-more-files {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        border: 1px solid rgba(173, 173, 173, 0.459);
        font-size: 12px;
        height: 30px;
        width: 30px;


        &:hover {
            opacity: 0.7;
        }

    }
}