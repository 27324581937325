@import "../../../../../../index.scss";
$headerDarkBackground: #12131b;
$bodyDarkBackground: #1f212d;
$inputModalDark: #12131b;

.filePreviewModal {
  max-width: 97dvw !important;
  width: fit-content !important;
  max-height: 90dvh;
  transition: width 5s ease-in-out, height 5s ease-in-out;
  // overflow: hidden;
  // z-index: 9990 !important;
  @media screen and (max-width: 450px) {
    width: 97dvw !important;
  }

  .ant-modal-content {
    .ant-modal-header {
      height: 45px;
      background-color: #f8f8fa;
      padding-top: 10px !important;
      padding-right: 50px !important;
    }
    .ant-modal-body {
      padding: 20px 20px 0 20px;

      min-width: 30dvw;
      min-height: 30dvh;
      @include flex(center, center, column);
      // border-bottom: 1px solid #414141;
      .singleFilePreviewContainer {
        min-height: 50dvh;
        min-width: 50dvw;
      }

      .bodyContent {
        @include flex(center, center, column);
        .displayedFileFrame {
          height: 70dvh;
          width: 90dvw;
          // border: 0;
          // border: 1px solid red;
        }
        .video-player-container {
          background-color: #00000020;
          border-radius: 10px;
          width: 100%;
          height: 500px;
          > div {
            // height: 500px !important;
            height: 100% !important;
            width: 100%;
            > video {
              height: 100% !important;
              width: 100% !important;
            }
          }
        }

        .displayedImage {
          max-height: 70dvh;
          max-width: 90dvw;
          overflow: auto;
        }
      }
      .filePreviewFooter {
        padding: 10px 0px;
        font-weight: 600;
      }
    }
    .ant-modal-footer {
      padding-bottom: 20px !important;
      .gDriveButtons {
        @include flex(center, space-between, row);
        // gap: 10px;
        // padding: 10px;
        // align-self: end;

        .gDriveButtonsRight {
          display: flex;
          gap: 10px;
          .driveIcon {
            svg {
              width: 20px;
              height: 20px;
            }
          }
        }
        .mondayButtonContainer {
          padding: unset !important;
        }
      }
    }
  }
  .filePreviewCarousel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    min-height: 50dvh;
    min-width: 30dvw;

    .filePreviewContent {
      height: 100%;
      width: 100%;
      padding: 0 20px;
    }

    .filePreviewLeft,
    .filePreviewRight {
      position: relative;
      z-index: 3;
      display: flex;
      align-items: center;
      .mondayButtonContainer {
        box-shadow: 0px 0px 4px #0000003d;
        border-radius: 5px !important;
        padding: unset !important;
        button {
          padding: 0 6px;
          height: 32px !important;
          width: 32px !important;
          background-color: #f8f8fa !important;
          svg {
            fill: #000 !important;
          }
        }
      }
    }
    .filePreviewLeft {
      left: 20px;
    }
    .filePreviewRight {
      right: 20px;
    }

    .carouselContent {
      flex-grow: 1;
      // Styles for content
    }
    @media screen and (max-width: 450px) {
      min-width: 100% !important;
      .progress-bar-container {
        min-width: 250px !important;
      }
    }
  }
}

.carouselFilePreviewModal {
  .ant-modal-body {
    padding: 20px 0 0 0 !important;
  }
}

.noRightPadding {
  .ant-modal-body {
    padding: 20px 0px 0 20px !important;
  }
}
.noLeftPadding {
  .ant-modal-body {
    padding: 20px 20px 0 0px !important;
  }
}

.filePreviewModalDark {
  .ant-modal-content {
    background-color: $bodyDarkBackground;
    .ant-modal-close .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $headerDarkBackground;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      .filePreviewFooter {
        color: #fff;
      }
      .progressPercentageNumber {
        color: #fff;
      }
    }
  }
}
