$lineColor: #323338;
$fontFamily: "Open Sans";

.service-modal-container {
  padding: 0 20px 20px 20px;

  .ant-modal-header {
    background-color: #f8f8fa;
  }

  .ant-modal-content {
    width: fit-content;
    height: fit-content;

    .service-modal-body {
      padding: 0 20px;
      .estimation-services-body {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
        width: fit-content;
        padding-right: 20px;
        padding-bottom: 20px;
      }

      .service-sub-total {
        font-weight: 800;
        font-size: 14px;
        padding-bottom: 20px;
        border-bottom: 1px solid $lineColor;
      }

      .estimation-name {
        padding: 10px 0;
      }

      .groupContainer {
        display: flex;
        margin-right: 9px;
      }
      .InformationSelectedGroupContainer,
      .InformationSelectedGroupContainerElevations {
        width: 250px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-right: 10px;
      }
      .serviceTitle {
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
      }
      .elevationInformation {
        max-width: 100%;
        height: fit-content;
        white-space: pre-wrap;
        text-align: start;
        font: normal normal 14px/19px Open Sans;
      }
      .lineDivider {
        width: 1px;
        background: $lineColor;
        height: 35px;
      }
    }

    .service-modal-footer {
      font-family: $fontFamily;
      font-style: normal;
      font-weight: 800;
      font-size: 16px;
      line-height: 22px;
      margin-left: 20px;
      padding-top: 10px;
    }
  }
}
