
.rentalDetailsHeaderLight {
  width: stretch;
  height: 45px;
  color: black;
  display: flex;
  align-items: center;
  background-color: white;
  border-bottom: 1px solid #c4c7cb;

  .tittle {
    margin-left: 15px;
    font-size: 16px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
      "Open Sans", "Helvetica Neue", sans-serif;
  }
}

.rentalDetailsHeaderDark {
  width: stretch;
  height: 45px;
  color: white;
  display: flex;
  align-items: center;
  background-color: #12131b;
  border-bottom: 1px solid #4a4a4a;

  .tittle {
    margin-left: 15px;
    font-size: 16px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
      "Open Sans", "Helvetica Neue", sans-serif;
  }
}
