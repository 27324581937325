.alternative-addresses-select {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  .alternative-addresses-label {
    display: flex;
    gap: 5px;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 600;
    padding-left: 10px;
    .alternative-addresses-label-checkbox {
      font-weight: 400;
    }
  }
  .alternative-addresses-checkbox {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #f4f5f6;
    border-radius: 5px;
    gap: 5px;
    padding: 5px;
    .alternative-addresses-checkbox-item {
      width: 100%;
    }
  }
}
.alternative-addresses-select-dark {
  .alternative-addresses-label {
    color: #fff;
  }
  .alternative-addresses-checkbox {
    background-color: #12131b;
    .alternative-addresses-checkbox-item {
      color: #fff;
    }
  }
}
