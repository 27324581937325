.tooltip-container {
  width: 100%;
  height: 100%;
  display: inline-block;

  .tooltip-content {
    width: max-content;
    position: absolute;
    display: none;
    background-color: rgba($color: #000000, $alpha: 0.85);
    color: white;
    padding: 10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 999 !important;
    border-radius: 8px;
  }

  &:hover .tooltip-content {
    display: inline-block;
  }

  .tooltip-top {
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    &::after {
      content: "";
      position: absolute;
      bottom: 100%;
      left: 50%;
      margin-left: -5px;
    }
  }

  // .tooltip-top::after {
  //   content: "";
  //   position: absolute;
  //   top: 100%;
  //   left: 50%;
  //   margin-left: -5px;
  // }

  .tooltip-right {
    top: -5px;
    left: 125%;
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: 100%;
      margin-top: -5px;
    }
  }

  // .tooltip-right::after {
  //   content: "";
  //   position: absolute;
  //   top: 50%;
  //   right: 100%;
  //   margin-top: -5px;
  // }

  .tooltip-bottom {
    top: 100%;
  }

  // .tooltip-bottom::after {
  //   content: "";
  //   position: absolute;
  //   bottom: 100%;
  //   left: 50%;
  //   margin-left: -5px;
  // }

  .tooltip-left {
    top: -5px;
    bottom: auto;
    right: 128%;
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 100%;
      margin-top: -5px;
    }
  }

  // .tooltip-left::after {
  //   content: "";
  //   position: absolute;
  //   top: 50%;
  //   left: 100%;
  //   margin-top: -5px;
  // }

  .arrow {
    position: absolute;
    left: 48%;
    top: -10px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 10px solid #fff;
    background-color: rgba($color: #000000, $alpha: 0.85);
    z-index: 1;
  }
}
