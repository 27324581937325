.forcastingPage {
  display: flex;
  flex-direction: column;
  gap: 10px;
  .forecastingContent {
    width: 1650px;
    // height: 743px;
    background-color: #ffffff;
    border-radius: 10px;
    padding-top: 20px;
    .workTypesCards {
      display: flex;
      justify-content: space-around;
    }
    .workTypesLabels {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 130px;
      padding-top: 30px;
      // padding-right: 60px;
    }
    .forecastingCollapses {
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 10px 15px 0px 15px;
      // height: 585px;
      overflow-y: auto;
      .estimationForecastContent {
        display: flex;
        justify-content: space-between;
        width: 100%;
        .forecasting-container__extra__total__date-picker {
          width: 96px;
          border: none;
          background-color: #ffffff;
        }
        Input {
          width: 50px !important;
          border: none !important;
          background-color: #ffffff !important;
        }
        .ant-input-affix-wrapper {
          width: 50px !important;
          border: none !important;
          background-color: #ffffff !important;
        }
        .estimationForecast {
          .elevationLabelForecasting {
            // width: 10px;
            // text-overflow: ellipsis;
            // overflow: hidden;
            // white-space: nowrap;
          }
          .expandAll {
            color: #1264a3;
          }
        }
        .forecastingEstimateExtra {
          display: flex;
          align-items: center;
          gap: 75px;

          .forecastingEstimateExtra__item {
            display: flex;
            gap: 5px;
            align-items: center;
          }
        }
      }

      .ant-collapse-header {
        display: flex;
        align-items: center;
        height: 40px;
      }

      .ant-collapse-content-box {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 10px !important;
        border: none !important;
      }
    }
  }
  .forecastingFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    height: 52px !important;
    padding-left: 10px;
    padding-right: 10px;
  }
}
