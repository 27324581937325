.geofenceLogsModalWrapper {
  display: grid;
  gap: 10px;
  .geofenceLogsModalContent {
    display: flex;
    flex: 1;
    gap: 10px;
    .leftSide {
      flex: 1;
      .leftSideContent {
        display: grid;
        gap: 5px;
        border: dotted 1px #cfcfcf;
        border-radius: 10px;
        padding: 5px;
        margin-bottom: 10px;
      }
      .card {
        border-radius: 10px;
        background: #fff;
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.16);
        .cardheader {
          height: 45px;
          display: flex;
          padding: 5px;
          background-color: #f8f8fa;
          border-radius: 10px 10px 0px 0px;
          display: flex;
          align-items: center;
        }
        .body {
        }
      }
    }
    .rightSide {
      flex: 1;
      .rightSideContent {
        display: grid;
        gap: 5px;
        border: dotted 1px #cfcfcf;
        border-radius: 10px;
        padding: 5px;
        margin-bottom: 10px;
      }
      .card {
        border-radius: 10px;
        background: #fff;
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.16);
        .cardheader {
          height: 45px;
          display: flex;
          padding: 5px;
          background-color: #f8f8fa;
          border-radius: 10px 10px 0px 0px;
          display: flex;
          align-items: center;
        }
        .body {
        }
      }
    }
  }
}
