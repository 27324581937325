.audit-updated-plan-card {
  box-shadow: 0 0 4px 2px rgba(55, 111, 153, 0.3);

  .locations-card-info-container {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0px 10px 10px 10px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
  }

  .audit-update-timeline {
    background-color: #fff;
    border-radius: 5px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 0px 10px 0px 10px;

    .update-action-buttons {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 100%;
      gap: 10px;
    }

    .update-action-button {
      height: 21px;
      width: 21px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      cursor: pointer;
    }

    .update-action-button.red {
      background-color: #f64c4a;
    }

    .update-action-button.green {
      background-color: #52c41a;
    }

    .update-action-button.blue {
      background-color: #1264a3;
    }
  }

  .default-audited {
    background-color: rgba(132, 173, 111, 0.4);
  }

  .ant-timeline {
    padding-top: 20px;
  }

  .ant-timeline-item-head {
    top: 18px;
  }

  .ant-timeline-item-tail {
    top: 28px;
  }

  .single-card-location {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    transition: transform 0.3s ease-in-out;

    .location-arrive-time {
      font-size: 0.75rem;
    }

    .location-arrive {
      font-weight: 525;
      letter-spacing: 0.18px;
    }

    .old-time {
      text-decoration: line-through;
      background-color: #f64c4a70;
      margin: 0 0.3rem;
    }

    .new-time {
      background-color: #53c41a70;
    }
  }

  .no-match-warning {
    width: 100%;
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;

    .no-match-text {
      text-align: center;
      font-weight: 550;
      letter-spacing: 0.28px;
      font-size: 0.85rem;
    }
  }
}

.audit-updated-plan-card.updated-plan-dark {
  box-shadow: 0 0 4px 2px rgba(255, 255, 255, 0.3);

  .no-match-text {
    color: #f2f2f2;
  }

  .audit-update-timeline {
    background-color: #2a2b3a;

    .ant-timeline-item-tail {
      border-inline-start: 2px solid #acacca;
    }

    .ant-timeline-item-head {
      background-color: transparent;
    }

    .single-card-location {
      color: #f2f2f2;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      transition: transform 0.3s ease-in-out;
      .location-arrive-time {
        font-size: 0.75rem;
      }

      .location-arrive {
        font-weight: 525;
        letter-spacing: 0.18px;
      }

      .old-time {
        text-decoration: line-through;
        background-color: #f64c4a70;
        margin: 0 0.3rem;
      }

      .new-time {
        background-color: #53c41a70;
      }
    }

    .no-match-warning {
      width: 100%;
      height: 90%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 10px;

      .no-match-text {
        text-align: center;
        font-weight: 550;
        letter-spacing: 0.28px;
        font-size: 0.85rem;
      }
    }
  }

  .default-audited {
    background-color: rgba(132, 173, 111, 0.4);
  }
}
