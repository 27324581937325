@import "../../../../../../../../../index.scss";

$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.maintenanceModalContainer {
  width: 100%;
  .ant-modal-body {
    padding-bottom: 50px;
  }

  .borderedTextCard {
    align-items: center;
    border: none;
    border-radius: 0;
    border-top: 1px solid black;
    padding: 0;
    padding-top: 15px;
    padding-right: 5px;
    .cardTitle {
      font-size: 16px;
      color: black;
    }
    .childrenContainer {
      .tox-tinymce {
        border: none;
        // .tox-sidebar-wrap
        .tox-sidebar-wrap {
          border-radius: 5px;
          .tox-edit-area {
            border-radius: 5px;
            .tox-edit-area__iframe {
              margin-top: 10px;
              background: #f5f5f7;
              border-radius: 5px;
              border: none;
            }
          }
        }
        .tox-anchorbar {
          display: none;
        }
        .tox-toolbar__primary {
          border: none;
          border-radius: 10px;
          background-color: #f5f5f7;
        }
        .tox-toolbar__group {
          border: none;
        }
        .tox-menubar {
          display: none;
        }
        .tox-statusbar {
          display: none;
        }
      }
    }
  }
  .labeledInputContainer {
    margin-right: 15px;

    .labeledInputLabel {
      @include text(14px, 600, 0.21px, #383554);
      margin-bottom: 2px;
    }
  }
  .contentContainer {
    width: 60vw;
    height: calc(100vh - 181px);
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 10px;
  }

  .details-text-card {
    .childrenContainer {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 1rem;
    }
  }

  .ant-checkbox-wrapper {
    color: #383554;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 1.5px 1.5px 5px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 5px;
    padding-left: 10px;
    width: 100%;
    margin-top: 5px;
    margin-right: 5px;
    background-color: #fff;
    min-width: 180px;
    &:hover {
      border-color: #1890ff;
      color: #1890ff;
      background-color: #e2f1ff;
    }
  }

  .checkboxItemsContainer {
    @include flex(flex-start, flex-start);
    flex-wrap: wrap;
    margin-bottom: 15px;
    .ant-form-item {
      margin-bottom: 0;
    }

    .labeledInputContainer {
      color: #383554;
      border: none !important;
      box-shadow: none !important;
      background-color: #f5f5f7 !important;
      border-radius: 10px;
      padding: 5px;
      padding-left: 10px;
      margin-top: 5px;
      background-color: #fff;
      min-width: 180px;
      transition: 0.3s ease-out;
      animation: scopesFadeInBottom 500ms both;
      .ant-radio-wrapper {
        font-weight: 400;
      }
    }
    .Poor {
      background-color: #ff9c46 !important;
      .ant-radio-wrapper-disabled {
        color: #333238 !important;
      }
      .ant-radio-disabled {
        .ant-radio-inner {
          background-color: #f5f5f7 !important;
        }
      }
    }

    .Defective {
      background-color: #ff5549 !important;
      .ant-radio-wrapper-disabled {
        color: #333238 !important;
      }
      .ant-radio-disabled {
        .ant-radio-inner {
          background-color: #f5f5f7 !important;
        }
      }
    }
  }
  .checkboxContainer {
    margin-right: 5px;
  }
}

.maintenanceModalContainerDark {
  .ant-modal-body {
    .childrenContainer {
      .labeledInputContainer .labeledInputLabel {
        color: #fff;
      }
      .ant-radio-wrapper span {
        color: #fff;
      }
      .ant-radio-wrapper-disabled span {
        color: #acacca;
      }
      .ant-input {
        background-color: $inputModalDark !important;
        color: #fff;
        &::placeholder {
          color: #acacca;
        }
      }
      .ant-input-disabled {
        color: #acacca;
      }
      .ant-select {
        .ant-select-selector {
          background-color: $inputModalDark !important;
          .ant-select-selection-search-input {
            color: #fff;
          }
          .ant-select-selection-item {
            color: #fff;
          }
          .ant-select-selection-placeholder {
            color: #acacca;
          }
        }
        .ant-select-clear {
          background-color: $inputModalDark;
          svg {
            fill: #acacca;
          }
        }
        .ant-select-arrow svg {
          fill: #fff;
        }
      }
      .ant-select-disabled {
        .ant-select-selector {
          .ant-select-selection-search-input {
            color: #acacca !important;
          }
          .ant-select-selection-item {
            color: #acacca !important;
          }
        }
      }
      .ant-picker {
        background-color: $inputModalDark !important;
        .ant-picker-input {
          background-color: $inputModalDark;
          input {
            color: #f2f2f2;
            &::placeholder {
              color: #acacca;
            }
          }
          .ant-picker-suffix svg {
            color: #acacca;
          }
          .ant-picker-clear {
            background-color: $inputModalDark;
            svg {
              fill: #acacca;
            }
          }
        }
      }
      .ant-picker-disabled {
        .ant-picker-input input {
          color: #acacca !important;
        }
      }
      .tox {
        border: none;
        .tox-menubar {
          background-color: $cardModalHeaderDark;
          border-radius: 5px 5px 0px 0px;
          .tox-mbtn__select-label {
            color: #fff;
          }
        }
        .tox-mbtn--select:hover {
          background-color: $cardModalBodyDark;
        }
        .tox-toolbar-overlord {
          background-color: $cardModalHeaderDark !important;
          .tox-toolbar__primary {
            background-color: $cardModalHeaderDark !important;
          }
          .tox-toolbar__group {
            background-color: $cardModalHeaderDark !important;
            border: none;
            .tox-split-button:hover {
              border: none;
            }
            .tox-tbtn {
              svg {
                fill: #fff;
              }
              &:focus {
                background-color: $cardModalBodyDark;
              }
              &:hover {
                background-color: $cardModalBodyDark;
              }
            }
            .tox-tbtn__select-label {
              color: #fff !important;
            }
          }
        }
      }
    }
    .checkboxItemsContainer {
      .labeledInputContainer {
        background-color: $inputModalDark !important;
        .labeledInputLabel {
          color: #fff;
        }
        .ant-btn {
          border: none;
        }
      }
      .Poor {
        background-color: #ff9436be !important;
      }
      .Defective {
        background-color: #ff5549be !important;
      }
      .ant-radio-wrapper span {
        color: #fff;
      }
      .ant-radio-wrapper-disabled span {
        color: #acacca;
      }
    }
  }
}

@media (max-width: 821px) {
  .maintenanceModalContainer .contentContainer {
    width: 100%;
  }
}

@media (max-width: 450px) {
  .maintenanceModalContainer .contentContainer {
    width: 100% !important;
  }
}

@keyframes scopesFadeInBottom {
  from {
    opacity: 0;
    transform: translate3d(0, -50%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
