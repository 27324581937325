.AddressBody {
  display: flex;
  width: initial;
  height: 400px;
  justify-content: center;
  margin-left: -20px;
  margin-right: -15px;
}

.AddressBody.noAddress {
  height: 100px;
  .noAddressFound {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-size: 14px;
    font-weight: 400;
  }
}
