@import "../../../../../../../../index.scss";

$headerDarkBackground: #12131b;
$bodyDarkBackground: #1f212d;
$inputDarkColor: #12131b;

.documentationLogsModal {
  display: flex;
  align-items: center;
  justify-content: center;
  .ant-modal {
    .ant-modal-content {
      padding-bottom: 20px;

      .ant-modal-body {
        padding: 10px;
        padding-bottom: 20px !important;
      }
      .content {
        @include flex(center, center, column);
        .descritpion {
          display: flex;
          flex-direction: column;

          .description-content {
            flex-grow: 1;
          }

          .back-button {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 10px;
            width: 30%;
            padding: 5px;
            color: #fff;
            background-color: #1264a3;
            border: none;
            border-radius: 5px;
            &:hover {
              background-color: #236ba1;
            }
          }
        }
      }
      // .ant-modal-footer {
      //   padding: 10px 20px 20px 20px;
      //   border-radius: 0px 0px 10px 10px;
      // }
      .actionButton {
        @include mondayButton(#f5f5f7, #e6e9ef, #000);
      }
      .ListContainer {
        width: 80vw;
      }
    }
  }
}

.documentationLogsModalDark {
  .ant-modal-content {
    background-color: $bodyDarkBackground;
    .ant-modal-close-x {
      svg {
        fill: #fff !important;
      }
    }

    .ant-modal-header {
      background-color: $headerDarkBackground;
      .ant-modal-title {
        color: #fff;
      }
    }

    .ant-modal-body {
      background-color: $bodyDarkBackground;
      border-radius: 10px;
      .content {
        .ListBody {
          .ListContainer {
            .columnHeaderContainer {
              .columnHeaderTitle {
                color: #fff;
              }
            }
            .columnHeaderContainer {
              .columnContentContainer {
                background-color: $headerDarkBackground !important;
              }
            }
          }
        }
      }
    }

    .ant-modal-footer {
      background-color: $bodyDarkBackground;
      border-radius: 0px 0px 10px 10px;
    }
    .actionButton {
      @include mondayButton(#fe4c4a, #eb4345, #fff);
    }
  }
}

@media (max-width: 1350px) {
  .documentationLogsModal::before {
    display: none !important;
  }
  .documentationLogsModal {
    padding: 20px 0px;
    .ant-modal {
      // top: 30% !important;
    }
  }
}

@media (max-width: 1300px) {
  .documentationLogsModal {
    .ListContainer {
      width: 90vw;
    }
  }
}

@media (max-width: 820px) {
  .documentationLogsModal {
    .ant-modal-content {
      width: 100%;
    }
    .ListBody {
      width: 100%;
      overflow-x: auto;
    }
    .ListContainer {
      width: fit-content;
      margin: 0px auto 10px auto;
      align-self: none;
    }
  }
}

.rentalPaymentsLogsModal {
  .ant-modal-body {
    padding: 10px;
    padding-bottom: 10px !important;
    width: 80vw;
  }
  .content {
    @include flex(flex-end, center, column);
    width: 100%;

    .ListBody {
      width: 100%;

      .ListContainer {
        width: 100%;
      }
    }

    .descritpion {
      display: flex;
      flex-direction: column;

      .description-content {
        flex-grow: 1;
      }

      .back-button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 10px;
        width: 30%;
        padding: 5px;
        color: #fff;
        background-color: #1264a3;
        border: none;
        border-radius: 5px;
        &:hover {
          background-color: #236ba1;
        }
      }
    }
  }
  .ListContainer {
    width: 80vw;
  }
}
