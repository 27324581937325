.includeRentalsModalDark {
  .ant-modal {
    width: fit-content !important;
    overflow-x: hidden;
  }

  .ant-modal-content {
    border-radius: 10px;
    // overflow: hidden;
    // width: fit-content;
    // overflow-x: hidden;
  }

  .ant-modal-title {
    color: white;
  }

  .ant-modal-close {
    height: 45px;
    width: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-modal-header {
    height: 45px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    background-color: #12131b;
    color: white !important;
    border-bottom: 0;
  }

  .ant-modal-footer {
    height: 45px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 15px;
    background-color: #12131b;
    border-top: 0;
  }

  .ant-modal-body {
    background-color: #1f212d;
    width: fit-content;
    height: 600px;
    display: flex;
    gap: 15px;
    overflow-y: hidden;

    .rentalListMainDivContainer {
      background-color: #12131b;
      border-radius: 10px;
      display: flex;
      padding: 20px;
      overflow-y: scroll;
      overflow-x: hidden;

      .rentalsListContainer {
        height: stretch;
        padding: 0 !important;
        width: 500px;
        color: white !important;
        .ant-checkbox-group {
          height: fit-content;
          display: flex;
          flex-direction: column;
          gap: 10px;

          .ant-checkbox-wrapper {
            height: 40px !important;
            width: 380px;
            overflow: hidden;
            display: flex;
            align-items: center;
            background-color: #292b3a;
            color: white;

            &:hover {
              background-color: #808080;

              .ant-checkbox-inner {
                border: 10px solid #fe4c4a !important;
                background-color: #e03d3a;
                margin: 0px !important;
                border-radius: 0 !important;
                height: 40px;
                width: 40px;
              }
            }
            &:disabled {
              background-color: #292b3a;
              color: white !important;
            }

            .ant-checkbox {
              height: 100%;
              width: 40px;
              margin: 0;
              margin-top: -5px !important;
            }
            .ant-checkbox-inner {
              border: 4px solid #fe4c4a !important;
              background-color: #e03d3a;
              margin: 0px !important;
              border-radius: 0 !important;
              height: 100%;
              width: 0%;
              &:hover {
                border: 10px solid #fe4c4a !important;
                background-color: #e03d3a;
                margin: 0px !important;
                border-radius: 0 !important;
                height: 40px;
                width: 40px;
                position: relative;
              }
              &::after {
                width: 0;
                height: 0;
              }
            }
          }
          .ant-checkbox-wrapper-disabled {
            background-color: #524c42;
            color: red;
            color: white !important;
            .ant-checkbox-disabled {
              color: white !important;
            }
            span {
              color: white !important;
            }
          }

          .ant-checkbox-wrapper-checked {
            position: relative;
            &:hover {
              &::after {
                border: 10px solid #68c142 !important;
              }
            }

            &::after {
              display: flex;
              justify-content: center;
              align-items: center;
              border: 10px solid #71cf48 !important;
              content: url("../../../../../../../../../../../pages/Settings/settingsComponents/Roles/src/Tick.svg");
              fill: black !important;
              background-color: white;
              width: 40px;
              height: 40px;
              border-radius: 0px;
              position: absolute;
              z-index: 5;
            }
          }
        }
      }

      .buttonListContainer {
        display: flex;
        flex-direction: column;
        gap: 18px;

        .redirectButton {
          height: 40px;
          width: 140px;
          border: 0;
          background-color: #0f5d98;
          color: white;
          border-radius: 5px;
        }
      }
    }

    .selectedRentalsDetailsInformationDiv {
      background-color: #12131b;
      border-radius: 10px;
      width: stretch;
      padding: 15px;
      overflow-y: scroll;
      overflow-x: hidden;

      .includedRentals {
        width: 1250px;
        // width: 100%;
        height: 200px;
        border-radius: 10px;
        margin-bottom: 15px;
        overflow: hidden;
        box-shadow: 0px 0px 6px 3px #71cf48;

        // .header {
        // 	height: 45px;
        // 	background-color: black;
        // 	width: stretch;
        // 	display: flex;
        // 	align-items: center;
        // 	padding: 0 20px;
        // 	color: white;
        // 	border-bottom: 1px solid darkgrey;
        // }

        .body {
          width: stretch;
          height: stretch;
        }
      }
    }

    .noRentalsContainer {
      width: stretch;
      height: stretch;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .textContainer {
        color: white;
      }
    }
  }
}

.includeRentalsModalLight {
  .ant-modal {
    width: fit-content !important;
    overflow-x: hidden;
  }

  .ant-modal-content {
    border-radius: 10px;
    // overflow: hidden;
    // width: fit-content;
  }

  .ant-modal-title {
    color: black;
  }

  .ant-modal-close {
    height: 45px;
    width: 45px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      fill: black;
    }
  }

  .ant-modal-header {
    height: 45px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    background-color: #f7f8fa;
    border-bottom: 0;
  }

  .ant-modal-footer {
    background-color: #f7f8fa;
    justify-content: flex-end;
    align-items: center;
    padding: 0 15px;
    display: flex;
    border-top: 0;
    height: 45px;
    gap: 15px;
  }
  .ant-modal-body {
    background-color: white;
    width: fit-content;
    height: 600px;
    display: flex;
    gap: 15px;

    .rentalListMainDivContainer {
      background-color: #f7f8fa;
      border-radius: 10px;
      display: flex;
      padding: 20px;
      overflow-y: scroll;
      overflow-x: hidden;
      box-shadow: 0px 0px 6px 3px lightgray;

      .rentalsListContainer {
        height: stretch;
        padding: 0 !important;
        width: 500px;
        .ant-checkbox-group {
          height: fit-content;
          display: flex;
          flex-direction: column;
          gap: 10px;

          .ant-checkbox-wrapper {
            height: 40px !important;
            overflow: hidden;
            width: 280px;
            display: flex;
            align-items: center;
            background-color: white;

            &:hover {
              background-color: #d3d3d3;

              .ant-checkbox-inner {
                border: 10px solid #fe4c4a !important;
                background-color: #e03d3a;
                margin: 0px !important;
                border-radius: 0 !important;
                height: 40px;
                width: 40px;
              }
            }

            .ant-checkbox {
              height: 100%;
              width: 40px;
              margin: 0;
              margin-top: -5px !important;
            }
            .ant-checkbox-inner {
              border: 4px solid #fe4c4a !important;
              background-color: #e03d3a;
              margin: 0px !important;
              border-radius: 0 !important;
              height: 100%;
              width: 0%;
              &:hover {
                border: 10px solid #fe4c4a !important;
                background-color: #e03d3a;
                margin: 0px !important;
                border-radius: 0 !important;
                height: 40px;
                width: 40px;
                position: relative;
              }
              &::after {
                width: 0;
                height: 0;
              }
            }
          }

          .ant-checkbox-wrapper-checked {
            position: relative;
            &:hover {
              &::after {
                border: 10px solid #68c142 !important;
              }
            }

            &::after {
              display: flex;
              justify-content: center;
              align-items: center;
              border: 10px solid #71cf48 !important;
              content: url("../../../../../../../../../../../pages/Settings/settingsComponents/Roles/src/Tick.svg");
              fill: black !important;
              background-color: white;
              width: 40px;
              height: 40px;
              border-radius: 0px;
              position: absolute;
              z-index: 5;
            }
          }
        }
      }

      .buttonListContainer {
        display: flex;
        flex-direction: column;
        gap: 18px;

        .redirectButton {
          height: 40px;
          width: 140px;
          border: 0;
          background-color: #0f5d98;
          color: white;
          border-radius: 5px;
        }
      }
    }

    .selectedRentalsDetailsInformationDiv {
      background-color: #f7f8fa;
      border-radius: 10px;
      width: stretch;
      padding: 15px;
      overflow-y: scroll;
      overflow-x: hidden;
      box-shadow: 0px 0px 6px 3px lightgray;

      .includedRentals {
        width: 750px;
        height: 200px;
        border-radius: 10px;
        margin-bottom: 15px;
        overflow: hidden;
        box-shadow: 0px 0px 6px 3px #71cf48;

        .header {
          height: 45px;
          background-color: white;
          width: stretch;
          display: flex;
          align-items: center;
          padding: 0 20px;
          border-bottom: 1px solid darkgrey;
        }

        .body {
          width: stretch;
          height: stretch;
        }
      }
    }

    .noRentalsContainer {
      width: stretch;
      height: stretch;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .textContainer {
        color: white;
      }
    }
  }
}
