@import "../../../../../../../../../../../../index.scss";

.rentalPayingTermsContainer {
  .rentalPayingTermsButton {
    @include mondayButton(#1264a3, #0f5c97, #fff);

    &:disabled {
      @include mondayButton(#0b3e64, #0e5185, #548bb3);

      svg {
        fill: #548bb3;
      }
    }
  }
}
.buttonRentalTermsNotClickable {
  cursor: not-allowed !important;
  opacity: 0.4 !important;
}
.rentalPayingTermsModalDarkMode {
  .ant-modal {
    width: fit-content !important;
  }
  .ant-modal-close-x {
    height: 45px;
    width: 45px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      fill: white;
    }
  }
  .ant-modal-content {
    border-radius: 10px;
    overflow: hidden;
    width: 700px !important;

    .ant-modal-header {
      height: 45px;
      border-bottom: 0;
      background-color: #12131b;
      display: flex;
      align-items: center;
      .ant-modal-title {
        font: normal normal 600 16px/39px Open Sans;
        color: white;
      }
    }

    .ant-modal-footer {
      height: 45px;
      display: flex;
      padding: 0 15px;
      align-items: center;
      justify-content: flex-end;
      gap: 10px;
      background-color: #12131b;
      color: white;
      border-top: 0;

      .rentalPayingTermsSaveButton {
        @include mondayButton(#71cf48, #68c142, #fff);
        svg {
          margin-right: -5px;
          margin-left: -5px;
        }
      }
      .rentalPayingTermsCancelButton {
        @include mondayButton(#fe4c4a, #eb4345, #fff);
      }
    }

    .ant-modal-body {
      padding: 15px;
      background-color: #1f212d;

      .informationContainer {
        color: white;
        margin-bottom: 10px;
      }

      .container {
        display: flex;
        flex-direction: column;
        align-items: center;

        .nameInputContainer {
          .nameInputText {
            width: 550px;
            font: normal normal 600 16px/22px Open Sans;
            color: white;
          }

          .nameInput {
            width: 550px;
            background-color: #f5f5f7;
            border-radius: 5px;
            border: 0 !important;
          }
        }
      }

      .inputSwitcherContainer {
        padding: 0 60px;
        height: 78px;

        .switcherHeader {
          font: normal normal 600 16px/22px Open Sans;
          color: white;
        }
        .switcherBody {
          display: flex;
          gap: 15px;
          align-items: center;
          justify-content: center;
          .ant-form-item {
            margin: 0 !important;
          }

          .ant-row {
            display: flex;
            align-items: center;
            margin-bottom: 0 !important;
            width: stretch;
          }

          .dateInput {
            width: stretch;
            background-color: #f5f5f7;
            border-radius: 5px;
            border: 0 !important;
          }
        }
      }

      .rentalPayingTermsDescriptions {
        .tox-edit-area__iframe {
          background-color: #f5f5f7;
          border-radius: 10px;
          border-color: #dbdbdb;
        }

        .tox-toolbar__primary {
          background-color: #1f212d;

          svg {
            fill: white;
          }
        }
        margin-bottom: -25px;
      }
    }
  }
}

.rentalPayingTermsModalLightMode {
  .ant-modal {
    width: fit-content !important;
  }
  .ant-modal-close-x {
    height: 45px;
    width: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-modal-content {
    border-radius: 10px;
    overflow: hidden;
    width: 700px !important;

    .ant-modal-header {
      height: 45px;
      border-bottom: 0;
      background-color: #f7f8fa;
      display: flex;
      align-items: center;
      .ant-modal-title {
        font: normal normal 600 16px/39px Open Sans;
      }
    }

    .ant-modal-footer {
      height: 45px;
      display: flex;
      padding: 0 15px;
      align-items: center;
      justify-content: flex-end;
      gap: 10px;
      background-color: #f7f8fa;
      border-top: 0;

      .rentalPayingTermsSaveButton {
        @include mondayButton(#71cf48, #68c142, #fff);
        svg {
          margin-right: -5px;
          margin-left: -5px;
        }
      }
      .rentalPayingTermsCancelButton {
        @include mondayButton(#fe4c4a, #eb4345, #fff);
      }
    }

    .ant-modal-body {
      padding: 15px;

      .informationContainer {
        margin-bottom: 10px;
      }

      .container {
        display: flex;
        flex-direction: column;
        align-items: center;

        .nameInputContainer {
          .nameInputText {
            width: 550px;
            font: normal normal 600 16px/22px Open Sans;
          }

          .nameInput {
            width: 550px;
            background-color: #f5f5f7;
            border-radius: 5px;
            border: 0 !important;
          }
        }
      }

      .inputSwitcherContainer {
        padding: 0 60px;
        height: 78px;

        .switcherHeader {
          font: normal normal 600 16px/22px Open Sans;
        }
        .switcherBody {
          display: flex;
          gap: 15px;
          align-items: center;
          justify-content: center;

          .ant-row {
            display: flex;
            align-items: center;
            margin-bottom: 0 !important;
            width: stretch;
          }

          .dateInput {
            width: stretch;
            background-color: #f5f5f7;
            border-radius: 5px;
            border: 0 !important;
          }
        }
      }

      .rentalPayingTermsDescriptions {
        .tox-edit-area__iframe {
          background-color: #f5f5f7;
          color: white !important;
          border-color: #dbdbdb;
          border-radius: 10px;
        }
        margin-bottom: -25px;
      }
    }
  }
}
