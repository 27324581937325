@import "../../../../index.scss";

.statusCountCard {
  @include flex(center, center, column);
  @include square(unset, 100px, 20px, unset);
  @include text(12px, 400, 0, #fff, capitalize);
  min-width: 120px;
  text-align: center;
  padding: 2px;
  box-shadow: 0px 3px 6px #272d3b33;
  user-select: none;
  // margin: 0 4px 30px;
  overflow: hidden;
  width: 100px;
  height: 130px;
  @include transition(0.3s);

  @media (max-width: 940px) {
    margin: 0 15px 30px;
  }
}

.statusCount {
  font-size: 40px;
  font-weight: bold;
}

.statusCountNr {
  font-size: 19px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  padding: 0 5px;
  box-sizing: border-box;
}

@media (min-width: 1700px) {
  .statusCountCard {
    width: 134px;
    height: 150px;
    font-size: 20px;
  }

  .statusCount {
    font-size: 50px;
  }
}

@media (max-width: 770px) {
  .statusCountNr {
    font-size: 16px;
  }
}
