@import "../../../../../../index.scss";

.missingFilesContainer {
  .tooltipContent {
    @include flex(center, center, column);
  }
}

.missingFilesTooltip {
  .ant-tooltip-content {
    border-radius: 10px;
    overflow: hidden;
  }
}
