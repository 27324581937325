.signatureModal {
  .ant-modal-content {
    width: 540px !important;

    .ant-modal-header {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
    }

    .ant-modal-body {
      .signContainer {
        padding: 0;
        margin-top: 0;
        height: 160px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
          rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
      }
    }
  }
}
@media screen and (max-width: 540px) {
  .signatureModal {
    .ant-modal-content {
      max-width: 100%;
    }
  }
}
