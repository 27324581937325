.mergePdfsContainer {
  display: flex;
  flex-direction: column;
  width: 1800px;
  height: 750px;
  gap: 10px;

  .mergePdfsBodyContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    .mergePdfsBodyDragger {
      width: 100vw !important;
      height: 100vh !important;
      .proposalDraggerDocuments {
        .ant-upload {
          width: 100%;
          height: 100%;
          // margin-bottom: 20px;
          border-radius: 2px !important;
          border-color: #323338 !important;
          background-color: white;
        }
      }
    }
    .customFilesContainer {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 15vw !important;
      border: 1px dashed #323338;
      // background-color: gray;
      // height: 750px;
      border-radius: 5px;
      height: 650px;
      // overflow: scroll;
      .pdf-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        max-height: 650px; /* Set the fixed height */
        overflow-y: auto; /* Enable vertical scrolling */
      }
      .customFilesContainerItem {
      }
    }
    .mergePdfsIframe {
      width: 90vw !important;
    }
  }
}

.document_merger_actions_header {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 20px;
  margin-bottom: 5px;
}
