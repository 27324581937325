.email-box-footer-container {
  display: flex;
  align-items: center;
  gap: 20px;

  &.dark-mode {

    svg {
      fill: #fff !important;
    }

    svg path {
      fill: #fff;
    }

  }

  @media only screen and (max-width: 767px) {
    padding-left: 0px;
    margin-top: 10px;
    gap: 12px;
  }
}