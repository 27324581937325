.receieved-by-input {
  .ant-select-selector {
    border-radius: 5px;
    background-color: #f4f5f6 !important;
    border: 2px solid transparent;
    text-align: left;
    margin-left: 5px;
    width: 200px;
  }
}
