.smallLoadingDiamonds {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  width: 50px;
  height: 50px;
  border: 10px solid #35415e;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  border: 10px solid #35415e;
  z-index: 10;
  span {
    width: 30px;
    height: 30px;
    background: #35415e;
    display: block;
    float: left;
    animation: animate 2s linear infinite;
  }
  span:nth-child(3) {
    animation-delay: 0.8s;
  }
  span:nth-child(2),
  span:nth-child(6) {
    animation-delay: 0.6s;
  }
  span:nth-child(1),
  span:nth-child(5),
  span:nth-child(9) {
    animation-delay: 0.4s;
  }
  span:nth-child(4),
  span:nth-child(8) {
    animation-delay: 0.2s;
  }
  span:nth-child(7) {
    animation-delay: 0s;
  }
  @keyframes animate {
    0% {
      transform: scale(1);
    }
    30% {
      transform: scale(0);
    }
    50% {
      transform: scale(0);
    }
    75% {
      transform: scale(1);
    }
    100% {
      transform: scale(1);
    }
  }
}

.smallLoadingDiamondsDark {
  border-color: #fff;
  span {
    background-color: #fff;
  }
}
