@import "../../../../../../../index.scss";

.permitsFilterCard {
  @include flex(center, space-between);

  .leftSection {
    @include flex(center, center);
    .mondayButtonContainer:not(:last-child) {
      margin-right: 20px;
    }
  }

  .clearFilterButton {
    @include mondayButton(#f5f5f7, #e6e9ef, #000);
  }

  .filterButton {
    @include mondayButton(#1264a3, #0f5c97);
  }

  .activeFilters {
    @include mondayButton(#fd4b4a, #eb4345);
  }

  .filterSelector {
    margin-right: 20px;
    min-width: 150px;

    .ant-select-selector {
      background-color: #1264a3;
      color: #fff;
      border-radius: 5px;
      border: 0;
    }
    .ant-select-selection-placeholder,
    .ant-select-arrow {
      color: #fff;
    }
  }
}
