$blue-icon-button: hsla(206, 80%, 35%, 1);
$green-icon-button: hsla(102, 58%, 55%, 1);
$red-icon-button: hsla(1, 99%, 64%, 1);
$gray-icon-button: hsla(210, 10%, 96%, 1);
$white-icon-button: hsla(0, 0%, 100%, 1);
$black-icon-button: hsla(234, 15%, 27%, 1);

$white-color: hsla(210, 10%, 96%, 1);
$black-color: hsla(345, 6%, 13%, 1);

.custom-icon-button {
  color: $white-color;
  padding: 0px;
  border: none !important;
  border-radius: 5px !important;

  &:hover {
    color: $white-color !important;
  }

  &.small-icon-button {
    width: 24px;
    height: 24px;
  }
  &.middle-icon-button {
    width: 32px;
    height: 32px;
  }
  &.large-icon-button {
    width: 44px;
    height: 44px;
  }

  &.blue-icon-button {
    background-color: $blue-icon-button;
    &:hover {
      //   background-color: darken($blue-icon-button, 6%) !important;
      background-color: hsla(206, 82%, 33%, 1) !important;
    }
    &:active {
      //   box-shadow: inset 0px 0px 0px 5px lighten($blue-icon-button, 15%);
      box-shadow: inset 0px 0px 0px 3px hsla(206, 59%, 49%, 1);
    }
  }

  &.green-icon-button {
    background-color: $green-icon-button;
    &:hover {
      //   background-color: darken($green-icon-button, 7.2%) !important;
      background-color: hsla(102, 51%, 51%, 1) !important;
    }
    &:active {
      //   box-shadow: inset 0px 0px 0px 3px lighten($green-icon-button, 29.4%);
      box-shadow: inset 0px 0px 0px 3px hsla(102, 71%, 66%, 1);
    }
  }

  &.red-icon-button {
    background-color: $red-icon-button;
    &:hover {
      //   background-color: darken($red-icon-button, 7.8%) !important;
      background-color: hsla(359, 81%, 59%, 1) !important;
    }
    &:active {
      //   box-shadow: inset 0px 0px 0px 3px lighten($red-icon-button, 8.5%);
      box-shadow: inset 0px 0px 0px 3px hsla(1, 99%, 64%, 1);
    }
  }

  &.gray-icon-button {
    color: $black-color !important;
    background-color: $gray-icon-button;
    &:hover {
      color: $black-color;
      //   background-color: lighten($gray-icon-button, 1.05%) !important;
      background-color: hsla(220, 75%, 97%, 1) !important;
    }
    &:active {
      //   box-shadow: inset 0px 0px 0px 3px darken($gray-icon-button, 5.15%);
      box-shadow: inset 0px 0px 0px 3px hsla(220, 22%, 92%, 1);
    }
  }

  &.white-icon-button {
    color: $black-color !important;
    background-color: $white-icon-button;
    &:hover {
      color: $black-color;
      //   background-color: darken($white-icon-button, 3%) !important;
      background-color: hsla(220, 75%, 97%, 1) !important;
    }
    &:active {
      //   box-shadow: inset 0px 0px 0px 3px darken($white-icon-button, 5.15%);
      box-shadow: inset 0px 0px 0px 3px hsla(220, 22%, 92%, 1);
    }
  }

  &.dark-icon-button {
    color: $white-color !important;
    background-color: $black-icon-button;
    &:hover {
      //   background-color: darken($black-icon-button, 26%) !important;
      background-color: hsla(236, 16%, 20%, 1) !important;
    }
    &:active {
      //   box-shadow: inset 0px 0px 0px 3px lighten($black-icon-button, 35%);
      box-shadow: inset 0px 0px 0px 3px hsla(234, 15%, 27%, 1);
    }
  }

  &.transparent-icon-button {
    background-color: transparent;
  }
}
