$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.jobsiteMatchDetailsModal {
  height: fit-content !important;
  width: fit-content !important;

  .ant-modal-content {
    width: calc(100vw - 20px) !important;
    max-width: 1500px;

    .ant-modal-close {
      height: 45px;
      width: 45px;
      .ant-modal-close-x {
        height: 45px;
        width: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          fill: #333238;
        }
      }
    }

    .ant-modal-header {
      height: 45px;
      display: flex;
      align-items: center;
      background-color: #f5f5f7;
      .ant-modal-title {
        font-weight: 600;
        font-size: 16px;
        color: #333238;
      }
    }

    .ant-modal-body {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;

      .modalActions {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 1rem;
      }

      .grid-container {
        height: 100%;
        width: 100%;
        // flex: 1;
      }
    }
    .ant-modal-footer {
      .mondayButtonStyle {
        width: 150px;
      }
    }
  }
}

.jobsiteMatchDetailsModalDark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      .grid-container {
        .ag-root-wrapper {
          background-color: $cardModalBodyDark;
          .ag-header {
            background-color: $cardModalBodyDark;
            .ag-header-row {
              border-top: none;
            }
            .ag-header-cell-resize::after {
              width: 1px !important;
              background-color: #4a4a4a;
            }
          }
          .ag-paging-panel {
            background-color: $cardModalBodyDark;
          }
        }
      }
    }
  }
}
