.editable-cell-container {
  display: flex;
  align-items: center;
  padding: 10px;
  &.disabled {
    cursor: not-allowed;
  }

  .editable-cell-icon {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    right: 10px;

    svg {
      fill: white;
    }
  }
}
