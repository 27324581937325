.reports-pdf-export-container {
  height: fit-content !important;
  width: fit-content !important;

  .ant-modal-content {
    width: calc(100vw - 20px) !important;
    max-width: 1100px;
    height: 80vh;

    .ant-modal-close {
      height: 45px;
      width: 45px;
      .ant-modal-close-x {
        height: 45px;
        width: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          fill: #333238;
        }
      }
    }

    .ant-modal-header {
      height: 45px;
      display: flex;
      align-items: center;
      background-color: #f5f5f7;
      .ant-modal-title {
        font-weight: 600;
        font-size: 16px;
        color: #333238;
      }
    }

    .ant-modal-body {
      height: calc(100% - 52px - 45px);
      width: 100%;
      padding: 10px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-direction: row;
      flex-wrap: wrap;
      overflow-y: auto;
      gap: 10px;

      .configuration-section {
        width: 50%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        flex-wrap: nowrap;
        gap: 1rem;

        .choose-size-section {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 10px;
        }

        .change-fields-section {
          width: 100%;
          height: -moz-fill-available;
          height: -webkit-fill-available;
          overflow-x: hidden;
          overflow-y: auto;

          .dynamic-fields-container {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-direction: column;
            gap: 1rem;

            .field-list {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              flex-direction: column;
              list-style-type: circle;
              gap: 1rem;
              width: 100%;
            }

            .dynamic-field {
              width: 100%;
              display: list-item;
              transition: box-shadow 0.2s ease-in-out;
              border-radius: 5px;
              cursor: pointer;

              &:hover {
                box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
              }

              .inner-field {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
              }
            }

            .fields-button-container {
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 2rem;
              padding-top: 0.5rem;
            }

            .report-choose {
              height: fit-content;
              min-height: 100px;
              width: 100%;
              border-radius: 5px;
              border: 1px solid #12131b;
              display: flex;
              justify-content: flex-start;
              align-items: flex-start;
              padding: 10px;
            }
          }
          .card-remove {
            animation: removeCard 0.3s ease-in-out forwards;
          }
        }
      }

      .preview-section {
        height: 100%;
        .preview-frame {
          background-color: #fff;
          border: 1.5px solid rgba(0, 0, 0, 0.2);
        }
      }
    }

    .ant-modal-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      flex-direction: row;

      .nested-footer {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        gap: 10px;
      }

      .mondayButtonStyle {
        width: 150px;
      }
    }
  }
}

.pdf-export-container-dark {
  .ant-modal-content {
    background-color: #1f212d;

    .ant-modal-header {
      background-color: #12131b;
      .ant-modal-title {
        color: #fff;
      }
    }

    .ant-modal-close {
      .ant-modal-close-x {
        svg {
          fill: #fff;
        }
      }
    }

    .ant-modal-body {
      .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-checked) {
        background: #12131b;
        color: #ffffff;
      }

      .configuration-section .change-fields-section {
        .dynamic-fields-container {
          .dynamic-field {
            color: #ffffff;
            &:hover {
              box-shadow: 0 0 2px 1px rgba(255, 255, 255, 0.2);
            }
          }
        }
      }

      .preview-label {
        color: #ffffff;
      }

      .ant-radio-button-wrapper-checked {
        background: #1264a3;
        border-color: #3386c5;
      }

      .report-choose {
        border: 1px solid #fff !important;
      }

      .preview-section {
        .preview-frame {
          border: 0;
        }
      }
    }
  }
}

@media (max-width: 935px) {
  .reports-pdf-export-container {
    .ant-modal-content {
      .ant-modal-body {
        overflow: auto;
        height: calc(100vh - 120px);
        .configuration-section {
          width: 100%;
        }
      }
    }
  }
}

@keyframes removeCard {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }
  100% {
    opacity: 0;
    transform: translateY(-100px);
    height: 0px;
    padding: 0px;
    margin-top: -10px;
  }
}

@media (max-width: 825px) {
  .reports-pdf-export-container {
    .ant-modal-content {
      height: 96vh;

      .ant-modal-body {
        .configuration-section {
          width: 100%;
        }

        .preview-section {
          max-width: 100%;
        }
      }
    }
  }
}
