$mainDarkBackgroundColor: #20212d;
$cardDarkHeaderColor: #2a2b3a;
$cardDarkBackgroundColor: #12131b;
$inputDarkColor: #2a2b3a;
$bodyModalDarkBackground: #1f212d;

.SettingsBody {
  display: flex;
  flex-direction: column;
  .settingsPageHeader {
    margin: 10px;
    margin-bottom: 0px;
    border-radius: 5px;
    padding: 0px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
    background-color: #fff;
    transition: 0.2s ease-in;
    .ant-page-header-content {
      padding: 5px 5px 5px 20px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      .ant-breadcrumb {
        font-size: 18px;
      }
    }
    .tourButton {
      .mondayButtonText,
      .mondayButtonDivider {
        display: none;
      }
    }
  }
  .formContainer {
    display: grid;
    grid-template-columns: 50% 50%;
    height: 100%;
    margin: 5px;
    margin-top: 0px;
    background: transparent 0% 0% no-repeat padding-box;
    border-radius: 5px;
    h2 {
      font: normal normal 600 25px Open Sans;
      margin-top: 20px;
      text-transform: uppercase;
    }
    .SigUpDivRow {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .formElement {
        .ant-select-selection-overflow {
          position: relative;
          display: flex;
          flex: auto;
          flex-wrap: nowrap;
          max-width: 100%;
        }
        .ant-select-selector {
          display: flex;
          background: #efefef 0% 0% no-repeat padding-box;
          padding-top: 5px;
          border-radius: 5px;
          border: 0px;
        }
        .ant-input {
          font-feature-settings: "tnum", "tnum";
          position: relative;
          display: inline-block;
          border: 0px;
          padding: 4px 11px;
          font-size: 14px;
          line-height: 1.5715;
          background: #efefef 0% 0% no-repeat padding-box;
          border-radius: 5px;
          transition: all 0.3s;
        }
        .labeledInputLabel {
          display: flex;
          flex-direction: row;
          justify-content: center;
          padding-right: 15px;
          padding-top: 7px;
          font: normal normal 600 18px Open Sans;
        }
        .labeledInputLabel span {
          display: none;
        }
      }
      .formButtonElement {
        width: 220px;
        height: 40px;
        margin-right: 120px;
        background: #f9db55 0% 0% no-repeat padding-box;
        border-radius: 5px;
        border: 0px;
      }
      .buttonText {
        align-items: center;
        font: normal normal bold 18px Open Sans;
        letter-spacing: 0px;
        color: #000c17;
      }
    }

    .CreateNewUser,
    .PasswordConfirmation,
    .NewUserTeamSelect {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 451px;
      width: 99%;
      margin: 10px 5px;
      background-color: white;
      border-radius: 5px;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
      transition: 0.2s ease-in;
      .formElement {
        width: 500px;
        .ant-select-selection-overflow {
          position: relative;
          display: flex;
          flex: auto;
          flex-wrap: nowrap;
          max-width: 100%;
        }
        .ant-select-selector {
          display: flex;
          align-items: center;
          width: 100%;
          height: 32px !important;
          background: #f5f5f7 0% 0% no-repeat padding-box;
          border-radius: 5px;
          border: 0px;
        }
        .ant-input {
          margin: 0;
          width: 100%;
          border: none;
          height: 100%;
          padding: 4px 11px;
          font-size: 14px;
          line-height: 1.5715;
          background: #f5f5f7 0% 0% no-repeat padding-box;
          border-radius: 5px;
          transition: all 0.3s;
        }
        .labeledInputLabel {
          display: flex;
          flex-direction: row;
          justify-content: center;
          padding-left: 10px;
          padding-top: 7px;
          font: normal normal 600 16px Open Sans;
          span {
            display: none;
          }
        }
      }
    }
    .CreateNewUser {
      padding: 10px 5%;
      .formInputs {
        display: grid;
        width: 85.6%;
        grid-template-columns: repeat(2, 3fr);
        overflow-y: auto;
        .react-tel-input .form-control {
          width: 100%;
          height: 32px;
        }
        .labeledInputContainer,
        .formElement {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          width: 100%;
          .ant-form-item {
            width: 93%;
          }
        }
      }
    }
    .NewUserTeamSelect {
      display: flex;
      flex-direction: column;
      gap: 20px;
      .NewUserTeamSelectHeader {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        h2,
        p {
          margin: 0;
        }
      }
      .formInputs {
        display: flex;
        justify-content: center;
        width: 100%;
        .labeledInputContainer {
          width: 100%;
          max-width: 300px;
          .ant-select {
            width: 100%;
            max-width: unset;
            .ant-select-selector {
              max-width: unset;
              width: 100%;
            }
          }
        }
      }
      .controls {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        width: 100%;
        font-size: 16px;
        .backButtonTeam {
          .button-inner-content {
            flex-direction: row-reverse;
          }
        }
        .controls-buttons {
          display: flex;
          gap: 10px;
          justify-content: center;

          .button-inner-text {
            font-size: 16px;
            font-weight: 600;
          }
        }
      }
    }
    .PasswordConfirmation {
      padding: 50px 12%;
      .formInputs {
        display: grid;
        grid-template-columns: 50% 50%;
        width: 104%;
        .labeledInputContainer {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-between;
          width: 91.6%;
          .labeledInputLabel {
            padding-left: 25px;
          }
          .ant-form-item {
            width: 100%;
            margin-left: 15px;
          }
        }
      }
      .pascodeInputs {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        .otpInput {
          display: flex;
          height: 100%;
          padding-left: 28px;
          padding-top: 40px;
          .inputStyle {
            width: 70px;
            height: 86px;
            font-size: 35px;
            margin-right: 2rem;
            margin-bottom: 2rem;
            border: none;
            border-radius: 10px;
            .focusStyle {
              border: 1px solid #cfd3db;
              outline: none;
            }
            .ant-input-number-input {
              text-align: center !important;
              font-size: 30px !important;
              width: 100% !important;
              height: 85px !important;
              background-color: #f5f5f7 !important ;
              border-radius: 10px !important;
            }
          }
        }
        // .otpInput {
        //   width: 100%;
        //   .inputStyle {
        //     border: none;
        //     border-radius: 10px !important;
        //     background-color: #f5f5f7;
        //     width: 70% !important;
        //     height: 86px !important;
        //     font-size: 35px !important;
        //     margin: 5px;
        //     padding: 0px;
        //   }
        // }
      }
    }
    .LatestUserList {
      width: 199%;
      height: 340px;
      background-color: #fff;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
      border-radius: 5px;
      margin: 0px 5px;
      transition: 0.2s ease-in;
      .informationText {
        display: flex;
        margin: 10px 20px;
        .informationIcon {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #1264a3;
          color: white;
          font: normal normal bold 14px Open Sans;
          margin-right: 10px;
        }
      }
      .latestUsersSearch {
        display: flex;
        flex-direction: row;
        margin-bottom: 10px;
        margin-left: 20px;
        .ant-input {
          width: 300px;
          border-radius: 5px;
          margin-right: 30px;
          background-color: #f5f5f7;
          border: none;
        }
      }
      .userList {
        height: 245px;
        padding: 10px;
        object-fit: contain;
        display: grid;
        gap: 10px 5px;
        grid-auto-columns: 250px;
        grid-auto-rows: 70px;
        grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
        overflow-y: auto;
      }
    }
    .formButtonElement {
      width: 100%;
      height: 32px;
      background: #71cf48 0% 0% no-repeat padding-box;
      border-radius: 5px;
      border: 0px;
    }
    .buttonText {
      align-items: center;
      font: normal normal 400 14px Open Sans;
      color: #fff;
    }
  }
}

.SettingsBodyDark {
  .settingsPageHeader {
    background-color: $cardDarkBackgroundColor;
    .ant-breadcrumb {
      .ant-breadcrumb-link {
        color: #acacca;
      }
      .ant-breadcrumb-separator {
        color: #fff;
      }
    }
  }
  .formContainer {
    .CreateNewUser,
    .NewUserTeamSelect {
      background-color: $cardDarkBackgroundColor;
      color: #fff;
      svg {
        path {
          fill: #fff;
        }
      }
      .formInputs {
        .labeledInputLabel {
          color: #fff;
        }
        .ant-form-item {
          .ant-input {
            background-color: $inputDarkColor !important;
            color: #fff;
            &::placeholder {
              color: #acacca;
            }
          }
          .ant-select {
            .ant-select-selector {
              background-color: $inputDarkColor !important;
              color: #fff;
              .ant-select-selection-search {
                background-color: $inputDarkColor;
                color: #fff;
                .ant-select-selection-search-input {
                  color: #fff;
                }
              }
              .ant-select-selection-placeholder {
                color: #acacca !important;
                z-index: 2;
              }
            }
            .ant-select-arrow {
              svg {
                fill: #fff;
                path {
                  fill: #fff !important;
                }
              }
            }
          }
          .react-tel-input {
            .form-control {
              background-color: $inputDarkColor;
              color: #fff;
              &::placeholder {
                color: #acacca;
              }
            }
            .flag-dropdown {
              background-color: $inputDarkColor;
              border-radius: 5px 0px 0px 5px;
              .selected-flag {
                &:hover {
                  background-color: $inputDarkColor;
                }
                &:focus {
                  background-color: $inputDarkColor;
                }
              }
              .arrow {
                border-top: 4px solid #fff;
              }
            }
            .open {
              background-color: $inputDarkColor;
              border-radius: 5px 0px 0px 5px;
              .selected-flag {
                background-color: $inputDarkColor;
                .arrow {
                  border-bottom: 4px solid #fff;
                  border-top: 4px solid transparent;
                }
              }
            }
          }
        }
      }
    }
    .PasswordConfirmation {
      background-color: $cardDarkBackgroundColor;
      color: #fff;
      svg {
        path {
          fill: #fff;
        }
      }
      .formInputs {
        .labeledInputLabel {
          color: #fff;
        }
        .ant-form-item {
          .ant-input {
            background-color: $inputDarkColor !important;
            color: #fff;
            &::placeholder {
              color: #acacca;
            }
          }
        }
      }
      .pascodeInputs .otpInput {
        .inputStyle {
          background-color: $inputDarkColor;
          .ant-input-number-input {
            background-color: $inputDarkColor !important;
            color: #fff;
          }
        }
      }
    }
    .LatestUserList {
      background-color: $cardDarkBackgroundColor;
      .informationText {
        color: #fff;
      }
      .latestUsersSearch {
        .ant-input {
          background-color: $inputDarkColor;
          color: #fff;
          &::placeholder {
            color: #acacca;
          }
        }
      }
      .userList {
        .previewMember .previewMember__text {
          label {
            color: #cecece;
          }
        }
      }
    }
  }
}

.WarningSignupModal {
  .ant-modal-content {
    border-radius: 10px;
    overflow: hidden;
    margin-left: 50px;
    .ant-modal-body {
      padding: 0px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .warningHeader {
        display: flex;
        justify-content: center;
        padding: 10px;
        background-color: #fe4c4a;
        color: #fff;
      }
      .warningBody {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px;
        h3 {
          font-size: 18px;
          font-weight: 600;
        }
        .ant-btn {
          width: 25%;
          height: 34px;
          border: none;
          border-radius: 5px;
          background-color: #1264a3;
          color: #fff;
        }
      }
    }
  }
}

.WarningSignupModalDark {
  .ant-modal-content {
    background-color: $bodyModalDarkBackground;
    .ant-modal-body {
      background-color: $bodyModalDarkBackground;
      .warningHeader {
        background-color: $cardDarkBackgroundColor;
        svg {
          path {
            fill: #fe4c4a;
          }
        }
      }
      .warningBody {
        color: #fff;
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .SettingsBody .formContainer .PasswordConfirmation .pascodeInputs .otpInput {
    .inputStyle {
      .ant-input-number-input {
        padding: 0 !important;
        width: 40px !important;
        height: 65px !important;
        font-size: 20px !important;
      }
    }
  }
  .inputStyle {
    width: 40px !important;
    height: 65px !important;
    font-size: 20px !important;
  }
}

@media screen and (max-width: 1200px) {
  .SettingsBody .formContainer {
    .CreateNewUser,
    .PasswordConfirmation,
    .NewUserTeamSelect {
      padding: 20px 40px;
      p {
        margin-bottom: 5px;
      }
      h2 {
        margin-top: 5px;
        margin-bottom: 5px;
        font-size: 20px;
      }
    }
    .PasswordConfirmation .formInputs {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }
  }
}

@media only screen and (max-width: 821px) {
  .SettingsBody .formContainer {
    display: flex;
    flex-direction: column;
    margin-left: 0px;
    margin-right: 0px;
    .CreateNewUser,
    .PasswordConfirmation,
    .NewUserTeamSelect {
      width: 98%;
      .pascodeInputs .otpInput .inputStyle .ant-input-number-input {
        height: 86px !important;
        font-size: 35px !important;
      }
    }
    .LatestUserList {
      width: 98%;
    }
    .inputStyle {
      height: 86px !important;
    }
  }
}

@media only screen and (max-width: 450px) {
  .SettingsBody .formContainer {
    margin-top: 20px !important;
    .RegisterDiv {
      .labeledInputContainer.formElement {
        margin-bottom: 10px;
      }
    }
    .pascodeInputs {
      .otpInput {
        padding-left: 15px !important;
        padding-top: 40px !important;
      }
    }
  }
}

.flip-in-hor-bottom {
  -webkit-animation: flip-in-hor-bottom 0.5s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: flip-in-hor-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.flip-out-hor-top {
  -webkit-animation: flip-out-hor-top 0.45s
    cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  animation: flip-out-hor-top 0.45s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

@-webkit-keyframes flip-in-hor-bottom {
  0% {
    -webkit-transform: rotateX(80deg);
    transform: rotateX(80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
    opacity: 1;
  }
}
@keyframes flip-in-hor-bottom {
  0% {
    -webkit-transform: rotateX(80deg);
    transform: rotateX(80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
    opacity: 1;
  }
}

@-webkit-keyframes flip-out-hor-top {
  0% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotateX(70deg);
    transform: rotateX(70deg);
    opacity: 0;
  }
}
@keyframes flip-out-hor-top {
  0% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotateX(70deg);
    transform: rotateX(70deg);
    opacity: 0;
  }
}
