@import "../../../../../../../../../index.scss";

.newApplicationFooterContainer {
  display: flex;
  justify-content: space-between;
  height: 45px;
  border-top: 0;
  width: stretch;
  padding: 5px 15px 10px 0px;
  background-color: #f8f8fa;

  .rightContainerNewApplication {
    display: flex;

    .clearSelection {
      margin-left: 20px;
      @include mondayButton(#1264a3, #0f5c97, #fff);
      height: 32px;
      &.svg {
        fill: white;
      }
    }

    .dropdownClassNameNewApplication {
      width: fit-content;
      height: fit-content;
      overflow: hidden;
      border-radius: 5px;
      margin-left: 10px;

      :disabled {
        background: #f5f5f7 !important;
        border-color: #e6e9ef !important;
      }
      &:hover {
        .ant-btn {
          background: #71cf48;
          border-color: #68c142;
        }
      }
      .ant-btn {
        background: #68c142;
        border-color: #71cf48;

        svg {
          fill: white;
          margin-top: -2px;
        }
      }
    }
  }

  .cancelButton {
    margin-left: 15px;
    @include mondayButton(#fe4c4a, #eb4345, #fff);
    height: 32px;

    &.svg {
      fill: white;
    }
  }
}
