@import "../../../../../../../../../../../index.scss";

.serviceHeaderDetailsDetailsModalLightMode {
  .ant-modal {
    width: 90% !important;
    height: 80vh;
    border-radius: 20px;
    overflow: hidden;

    .ant-modal-content {
      border-radius: 20px;

      .ant-modal-close {
        margin-top: 0px;
      }
    }

    .ant-modal-body {
      padding: 0 0 20px 0;
      overflow: hidden;

      .service-details-card-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #f8f8fa;
        height: 55px;
        padding: 0 50px 0 20px;
        border-bottom: 0;

        .service-details-card-header-title {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;
          font-size: 1.5rem;
        }

        .service-details-card-header-title-color {
          display: inline-block;
          width: 10px;
          height: 10px;
          border-radius: 50%;
        }

        .service-details-card-header-options {
          margin-left: 5px;
          display: flex;

          .service-details-card-header-option {
            margin-left: 15px;
            @include mondayButton(#1264a3, #0f5c97, #fff);
          }
        }
      }
    }
  }

  .service-details-card {
    background: white;
    overflow-y: auto;
    height: 650px;
    border-radius: 20px;

    .ant-collapse {
      margin: 20px;
      // overflow-y: auto;
      border: 0;
      // overflow-y: hidden;
      background: white;

      .ant-collapse-item {
        background: #15568c;
        border-radius: 10px;
        margin-bottom: 10px;
        border: 0 !important;

        .ant-collapse-header {
          padding: 0;
          height: 45px !important;
          display: flex;
          align-items: center;

          .ant-collapse-arrow {
            margin-left: 15px;
            svg {
              fill: white;
            }
          }

          .service-details-card-collapse-panel {
            margin-left: 10px;
            color: var(--unnamed-color-ffffff);
            text-align: left;
            letter-spacing: 0px;
            color: #ffffff;
            opacity: 1;
          }
        }
      }
      .ant-collapse-content-active {
        background: #f8f8fa;
        border-bottom-left-radius: 9px;
        border-bottom-right-radius: 9px;
        // overflow: hidden;
        border: 0;

        .ant-collapse-content-box {
          padding: 0;
        }
      }
    }
  }

  .oddHeaderClassName {
    background-color: #bbbbbb !important;
  }

  .evenHeaderClassName {
    background-color: #cfcece !important;
  }
}

.serviceHeaderDetailsModalDarkMode {
  .ant-modal {
    width: 90% !important;
    height: 80vh;
    border-radius: 20px;
    overflow: hidden;

    .ant-modal-content {
      border-radius: 20px;
      background: #1f212d;

      .ant-modal-close {
        margin-top: 0px;
      }
    }

    .ant-modal-body {
      padding: 0 0 20px 0;
      overflow: hidden;
      // background: black;

      .service-details-card-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #12131b !important;
        height: 55px;
        padding: 0 50px 0 20px;
        border-bottom: 0;
        .service-details-card-header-title {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;
          font-size: 1.5rem;
          color: #f8f8fa;
        }

        .service-details-card-header-title-color {
          display: inline-block;
          width: 10px;
          height: 10px;
          border-radius: 50%;
        }

        .service-details-card-header-options {
          margin-left: 5px;
          display: flex;

          .service-details-card-header-option {
            margin-left: 15px;
            @include mondayButton(#1264a3, #0f5c97, #fff);
          }
        }
      }
    }
  }

  .service-details-card {
    background: #1f212d;
    overflow-y: auto;
    height: 650px;
    border-radius: 20px;

    .ant-collapse {
      margin: 20px;
      overflow: visible;
      border: 0;
      background: #1f212d;

      .ant-collapse-item {
        background: #12131b;
        border-radius: 10px;
        margin-bottom: 10px;
        border: 0 !important;

        .ant-collapse-header {
          padding: 0;
          height: 45px !important;
          display: flex;
          align-items: center;

          .ant-collapse-arrow {
            margin-left: 15px;
            svg {
              fill: white;
            }
          }

          .service-details-card-collapse-panel {
            margin-left: 0px;
            color: var(--unnamed-color-ffffff);
            text-align: left;
            letter-spacing: 0px;
            color: white;
            opacity: 1;
          }
        }
      }
      .ant-collapse-content {
        background: #1c1c1c !important;
      }
      .ant-collapse-content-active {
        background: #1c1c1c !important;
        border-bottom-left-radius: 9px;
        border-bottom-right-radius: 9px;
        border: 0;

        .ant-collapse-content-box {
          padding: 0;
        }
      }
    }
  }

  .oddHeaderClassName {
    background-color: #292b3a !important;
  }

  .evenHeaderClassName {
    background-color: #1f212d !important;
  }
}

.service-header-details-modal-grid {
  background-color: black;
}
