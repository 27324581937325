.inspection-full-screen-modal {
  width: 100% !important;
  max-width: 100% !important;
  height: 100% !important;
  padding: 10px !important;
  margin: 0 !important;

  > div {
    height: inherit;
  }

  .ant-modal-content {
    height: inherit;
    display: flex;
    flex-direction: column;

    .ant-modal-close {
      .ant-modal-close-x {
        color: hsla(345, 6%, 13%, 1);
      }
    }

    .ant-modal-header {
      padding: 11.5px 20px;
      background: hsla(210, 10%, 96%, 1);
    }

    .ant-modal-body {
      flex: 1;
      overflow-y: auto;
      padding: 0 !important;
    }
  }
}

/* Custom Modal */
.custom-inspection-modal {
  .ant-modal-header {
    padding: 11.5px 20px !important;
    background: hsla(210, 10%, 96%, 1);
  }
}
