.field-table-filters-modal {
  width: unset !important;
  .ant-modal-content {
    .ant-modal-header {
      height: 45px;
      display: flex;
      align-items: center;
      background-color: #f8f8fa;
      overflow: hidden;
      border: none;
      color: #323338;
    }
    .ant-modal-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      .labeledInputContainer {
        height: 65px;
      }
      .ant-divider {
        margin: 10px 0;
      }
    }
    .ant-modal-footer {
      padding-bottom: 20px !important;
    }
  }
}
@media screen and (max-width: 1200px) {
  .ant-picker-dropdown
    .ant-picker-range-wrapper
    .ant-picker-panel-container
    .ant-picker-panel-layout
    .ant-picker-presets {
    display: unset;
    max-width: unset;
    ul {
      height: auto;
      display: flex;
      flex-direction: column;
      gap: 3px;
      li {
        font-size: 12px;
        margin: 0;
      }
    }
  }
}
