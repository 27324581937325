$headerDarkBackground: #12131b;
$bodyDarkBackground: #1f212d;
$inputDarkColor: #12131b;

.documentationLogsModal {
  .ant-modal-header {
    height: 45px;
    background-color: #f5f5f7;
    border: none;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    align-items: center;

    .ant-modal-title {
      font-weight: 600;
      font-size: 16px;
      color: #323338;
    }
  }

  .filter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding-bottom: 20px;
  }

  .footer {
    display: flex;
    justify-content: flex-start;
    width: 100%;
  }

  .pagination-wrapper {
    display: flex;
    justify-content: flex-end;
  }
}

.dark-mode {
  .filter {
    background-color: inherit;
    padding-bottom: 20px;

    input {
      background-color: inherit !important;
    }
  }

  .ant-modal-content {
    background: $bodyDarkBackground;

    .ant-modal-close {
      color: #fff;
    }

    .ant-modal-header {
      background: $headerDarkBackground;

      .ant-modal-title {
        color: #fff;
      }
    }
  }
}
