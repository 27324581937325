.attachmentItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  padding: 5px 10px;
  border-radius: 5px;
}

.fileInfo {
  display: flex;
  align-items: center;
  gap: 10px;
}

.fileName {
  font-size: 14px;
  line-height: 19px;
  color: #231f20;
}

.actions {
  display: none;
  gap: 10px;
}

.actions {
  cursor: pointer;
}

.white {
  background-color: #ffffff;
}

.gray {
  background: #f4f5f6;
}

.attachmentItem:hover .actions {
  display: flex;
}

.attachmentItem:hover.delete .fileName {
  color: #eb4345;
}

.attachmentItem:hover.download .fileName {
  color: #1264a3;
}

.deleteIcon {
  width: 12px;
  height: 12px;
  fill: #eb4345;
}

.downloadIcon {
  width: 12px;
  height: 12px;
  fill: #1264a3;
}
