@import "../../../../../../index.scss";

.estimationsStepContainer {
  .estimationsGrid {
    width: 100%;
    @include flex(center, flex-start, column);

    h4 {
      font-size: 14px;
    }
  }
}
