$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

//This style is also used in CarouselModal component

.galleryInspection {
  .ant-modal {
    width: calc(100vw - 20px);
    max-width: 700px;
    .ant-modal-content {
      min-height: 150px;
      // width: fit-content !important;
      max-width: 700px;
      .ant-modal-header {
        .ant-modal-title {
          margin-right: 20px;
        }
      }
      .ant-modal-body {
        .smallLoadingDiamonds {
          margin-top: 30px;
        }
        // height: calc(100vh - 150px);
        // max-height: 600px;
        padding: 20px 72px !important;
        position: relative;

        .noImages {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
        }
        .gallery-xIcon {
          position: absolute;
          top: 20px;
          right: 20px;
          width: 17px;
          height: 17px;
        }

        .fileInfo {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          p {
            margin-bottom: 0px;
          }
        }

        .ant-carousel {
          .galleryCarousel {
            .slick-list {
              width: calc(100vw - 60px) !important;
              max-width: 560px;
            }
            .slick-slide {
              height: calc(100vh - 190px);
              width: calc(100vw - 60px) !important;
              max-height: 560px;
              max-width: 560px;
              div:first-child {
                height: 100%;
              }
              .fileContainer {
                height: 100%;
                width: 100%;
                .ant-image {
                  display: flex !important;
                  align-items: center;
                }
                .imageFile,
                .iframeFile {
                  // height: 100%;
                  // width: 100%;
                  // max-height: 560px;
                  border: none;
                  border-radius: 5px;
                  object-fit: fill;
                }
                .iframeFile {
                  height: 100%;
                  width: 100%;
                }
              }
              .fileContainer-mobile {
                width: 80% !important;
              }
            }
            .slick-prev,
            .slick-prev:hover,
            .slick-prev:focus {
              width: 32px;
              height: 32px;
              padding: 4px 0;
              font-size: 22px;
              left: -22px;
              z-index: 2;
              color: black;
              background: #f5f5f7;
              box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
              border-radius: 5px;
            }
            .slick-next,
            .slick-next:hover,
            .slick-next:focus {
              width: 32px;
              height: 32px;
              padding: 4px 0;
              font-size: 22px;
              right: -26px;
              z-index: 2;
              color: black;
              background: #f5f5f7;
              box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
              border-radius: 5px;
            }

            .slick-dots {
              height: 36px;
              margin: auto 0;
              bottom: -10px;
              display: flex;
              flex-direction: row;
              gap: 12px;
              li {
                margin-inline: 0px;

                button {
                  height: 15px;
                  background-color: transparent;
                  border: 2px solid #fff;
                }

                &.slick-active {
                  margin: 0;
                  width: 15px;
                  height: 15px;
                  background-color: #fff !important;
                }
              }
            }
          }
        }

        .thumbnailContainer {
          display: flex;
          flex-direction: row;
          justify-content: center;
          gap: 16px;
          padding: 10px 10px 20px 10px;
          overflow: auto;

          .thumbnail {
            display: flex;
            justify-content: center;
            cursor: pointer;
            width: 57px;
            height: 65px;

            .react-pdf__Document {
              height: 100%;
              overflow: hidden;
            }

            .excel-thumbnail {
              display: flex;
              align-items: center;
              justify-content: center;
              svg {
                height: 60%;
                width: 60%;
              }
            }

            img {
              width: 57px;
              height: 65px;
              border-radius: 5px;
              cursor: pointer;
              transition: transform 0.01s ease-in-out;
              object-fit: contain;
            }

            &.active {
              img {
                transform: scale(1.2);
              }
            }
          }
        }
      }
    }
  }
}

.galleryInspectionDark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      .fileInfo {
        p {
          color: #fff;
        }
      }
      .noImages {
        color: #fff;
      }
    }
  }
}
