@import "../../../index.scss";

.servicesInspectedCell {
  @include flex(center, center);
  @include square(25px, 25px, 5px, #1065a4);
  color: #fff;
  border: 1px solid #1065a4;
  cursor: pointer;
  margin: 0 auto;
}

.docShownInModal {
  width: fit-content !important;
  .ant-modal-content {
    width: 300px;
    .ant-modal-close {
      height: 45px;
      width: 45px;
      .ant-modal-close-x {
        height: 45px;
        width: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .ant-modal-header {
      height: 45px;
      display: flex;
      align-items: center;
      background-color: #f8f8fa;
      .ant-modal-title {
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
}
