.emails-modal {
  width: 100% !important;
  height: 100%;
  top: 0 !important;
  padding-bottom: 0 !important;
  max-width: 100% !important;

  > div {
    width: 100% !important;
    height: inherit;

    .ant-modal-content {
      width: inherit;
      height: inherit;
      border-radius: 0 !important;

      display: flex;
      flex-direction: column;

      .ant-modal-close {
        .ant-modal-close-x {
          color: hsla(345, 6%, 13%, 1);
        }
      }

      .ant-modal-header {
        padding: 11.5px 20px;
        background: hsla(210, 10%, 96%, 1);
      }

      .ant-modal-body {
        flex-grow: 1;
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }
  }
}
