.HeaderDateInput {
	display: flex;
	flex-direction: row;
	gap: 20px;
	.datePickerInputHeaderRentals {
		background-color: #f5f5f7;
		border-radius: 5px;

		.ant-picker-suffix {
			svg {
				fill: #bfcbdc;
			}
		}
	}
}
