$headerDarkBackground: #12131b;
$bodyDarkBackground: #1f212d;
$inputModalDark: #12131b;

.sortFieldsModal {
  .ant-modal-body {
    min-width: 31rem;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .radioGroup {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
  }
  .droppableDiv {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: 500px;
    overflow-y: auto;
    padding-right: 10px;
  }
  .draggableDiv {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    background-color: #f8f8fa;
    border-radius: 5px;
    .draggableSpan {
      max-width: 90%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      user-select: none;
    }
  }

  .sortFieldsModalFooter {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}
.sortFieldsModalDark {
  .radioGroup {
    .ant-radio-group {
      .ant-radio-button-wrapper {
        background-color: $inputModalDark;
        color: #fff;
        border: 1px solid $inputModalDark;
      }
      .ant-radio-button-wrapper-checked {
        border: 1px solid #fff;
        &::before {
          background-color: #fff;
        }
      }
    }
  }
  .draggableDiv {
    background-color: $inputModalDark;
    .draggableSpan {
      color: #fff;
    }
    svg {
      fill: #fff;
    }
  }
  .labeledInputLabel {
    color: #fff !important;
  }
  .ant-select {
    .ant-select-selector {
      background-color: $inputModalDark !important;
      .ant-select-selection-search-input {
        color: #fff !important;
      }
      .ant-select-selection-item {
        color: #fff !important;
      }
      .ant-select-selection-placeholder {
        color: #acacca;
      }
    }
  }
  .ant-select-arrow {
    svg {
      fill: #fff !important;
      path {
        fill: #fff !important;
      }
    }
  }
}
