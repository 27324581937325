$headerDarkBackground: #12131b;
$bodyDarkBackground: #1f212d;
$inputDarkColor: #12131b;
$inputHoverDark: #2a2b3a;

.drawerRole {
  .ant-drawer-content-wrapper {
    width: 540px !important;
  }
  .submitButtonContainer {
    display: flex;
    justify-content: center;
  }
  .dividerContainer {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    .divider {
      border-bottom: 3px solid #e6e9ef;
      width: 225px;
    }
  }
  .ant-drawer-header {
    padding: 10px;
    .ant-drawer-close {
      svg {
        fill: #323338;
        height: 18px;
        width: 18px;
      }
    }
  }
  .actionPosition {
    width: 180px;
  }
  .drawerBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    .drawerAllParents {
      @keyframes wobble {
        0% {
          transform: translateX(30px);
        }
        100% {
          transform: translateX(0);
        }
      }
      animation: wobble 0.4s;
      .drawerContent {
        display: flex;
        flex-direction: column;

        .stepChildContainer {
          display: flex;
          flex-direction: column;
          max-width: 360px;
          .stepChildElement {
            margin-top: 10px;
            display: flex;
            justify-content: space-between;
            .stepChildTitle {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 270px;
              margin-right: 10px;
              height: 32px;
              background: #f5f6f8 0% 0% no-repeat padding-box;
              opacity: 1;
              text-align: center;
              font: normal normal normal 18px/39px Open Sans;
              letter-spacing: 0px;
              color: #323338;
              opacity: 1;
            }
            .actionPosition {
              width: 140px;
              min-width: 140px;
              .optionsContainer {
                .optionButtonFalse {
                  width: 67px;
                }
                .optionButtonTrue {
                  width: 67px;
                }
              }
            }
          }
        }
        .childContainer {
          display: flex;
          flex-direction: column;
          max-width: 450px;
          .childElement {
            margin-top: 10px;
            display: flex;
            justify-content: space-between;
            .childTitle {
              width: 270px;
              margin-right: 10px;
              height: 36px;
              background: #f5f6f8 0% 0% no-repeat padding-box;
              opacity: 1;
              text-align: center;
              font: normal normal normal 20px/39px Open Sans;
              letter-spacing: 0px;
              color: #323338;
              opacity: 1;
            }
          }
        }
        .parentContainer {
          display: flex;
          max-width: 450px;
          margin-top: 13px;
          @keyframes wobble {
            0% {
              transform: translateX(30px);
            }
            100% {
              transform: translateX(0);
            }
          }
          animation: wobble 0.4s;
          .configElementSelectedContainer {
            background-color: #eceff4;
            height: 36px;
            width: 265px;
            display: flex;
            margin-right: 10px;
            opacity: 1;
            margin-bottom: 5px;
            cursor: pointer;
            transition: width 0.5s;
            .greenContainer {
              width: 8px;
              background-color: #71cf48;
              transition: width 0.5s;
              .navyBlueContainer {
                margin: 9px;
                width: 18px;
                height: 18px;
                background-color: #68c53f;
                overflow: hidden; /* Hide the element content, while height = 0 */
                opacity: 0;
                transition: opacity 300ms 0ms;
              }
              .ant-checkbox-inner::after {
                position: absolute;
                display: table;
                border: 2px solid #0060b9;
                border-top: 0;
                border-left: 0;
                transform: rotate(45deg) scale(1) translate(-50%, -50%);
                opacity: 1;
                transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
                content: " ";
              }
              .ant-checkbox-checked::after {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border: 1px solid white;
                border-radius: 2px;
                visibility: hidden;
                -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
                animation: antCheckboxEffect 0.36s ease-in-out;
                -webkit-animation-fill-mode: backwards;
                animation-fill-mode: backwards;
                content: "";
              }
              .ant-checkbox-inner::after {
                position: absolute;
                display: table;
                border-top: 0;
                border-left: 0;
                transform: rotate(45deg) scale(1) translate(-50%, -50%);
                opacity: 1;
                transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
                content: " ";
              }
              .ant-checkbox-inner {
                position: relative;
                top: -3px;
                left: 0;
                display: block;
                width: 18px;
                height: 18px;
                direction: ltr;
                background-color: white;
                border: 1px solid #d9d9d9;
                border-radius: 2px;
                border-collapse: separate;
                transition: all 0.3s;
              }
            }
            .redContainer {
              width: 8px;
              background-color: #ff4d4f;
              transition: width 0.5s;
              .navyBlueContainer {
                margin: 9px;
                width: 18px;
                height: 18px;
                background-color: #ec1316;
                overflow: hidden; /* Hide the element content, while height = 0 */
                opacity: 0;
                transition: opacity 300ms 0ms;
              }
              .ant-checkbox-inner::after {
                position: absolute;
                display: table;
                border: 2px solid #0060b9;
                border-top: 0;
                border-left: 0;
                transform: rotate(45deg) scale(1) translate(-50%, -50%);
                opacity: 1;
                transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
                content: " ";
              }
              .ant-checkbox-checked::after {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border: 1px solid white;
                border-radius: 2px;
                visibility: hidden;
                -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
                animation: antCheckboxEffect 0.36s ease-in-out;
                -webkit-animation-fill-mode: backwards;
                animation-fill-mode: backwards;
                content: "";
              }
              .ant-checkbox-inner::after {
                position: absolute;
                display: table;
                border-top: 0;
                border-left: 0;
                transform: rotate(45deg) scale(1) translate(-50%, -50%);
                opacity: 1;
                transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
                content: " ";
              }
              .ant-checkbox-inner {
                position: relative;
                top: -3px;
                left: 0;
                display: block;
                width: 18px;
                height: 18px;
                direction: ltr;
                background-color: white;
                border: 1px solid #d9d9d9;
                border-radius: 2px;
                border-collapse: separate;
                transition: all 0.3s;
              }
            }

            .bluContainer {
              width: 8px;
              background-color: #0073ea;
              transition: width 0.5s;
              .navyBlueContainer {
                margin: 9px;
                width: 18px;
                height: 18px;
                background-color: #0060b9;
                overflow: hidden; /* Hide the element content, while height = 0 */
                opacity: 0;
                transition: opacity 300ms 0ms;
              }
              .ant-checkbox-inner::after {
                position: absolute;
                display: table;
                border: 2px solid #0060b9;
                border-top: 0;
                border-left: 0;
                transform: rotate(45deg) scale(1) translate(-50%, -50%);
                opacity: 1;
                transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
                content: " ";
              }
              .ant-checkbox-checked::after {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border: 1px solid white;
                border-radius: 2px;
                visibility: hidden;
                -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
                animation: antCheckboxEffect 0.36s ease-in-out;
                -webkit-animation-fill-mode: backwards;
                animation-fill-mode: backwards;
                content: "";
              }
              .ant-checkbox-inner::after {
                position: absolute;
                display: table;
                border-top: 0;
                border-left: 0;
                transform: rotate(45deg) scale(1) translate(-50%, -50%);
                opacity: 1;
                transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
                content: " ";
              }
              .ant-checkbox-inner {
                position: relative;
                top: -3px;
                left: 0;
                display: block;
                width: 18px;
                height: 18px;
                direction: ltr;
                background-color: white;
                border: 1px solid #d9d9d9;
                border-radius: 2px;
                border-collapse: separate;
                transition: all 0.3s;
              }
            }
            .configElementTitle {
              text-align: left;
              font: normal normal normal 20px/39px Open Sans;
              letter-spacing: 0px;
              color: #323338;
              opacity: 1;
              margin-left: 7px;
            }
          }
          .configElementSelectedContainer:hover {
            background-color: #e6e9ef;
            .navyBlueContainer {
              opacity: 1;
            }
            .greenContainer {
              width: 36px;
              background-color: #71cf48;
            }
            .bluContainer {
              width: 36px;
              background-color: #0073ea;
            }
            .redContainer {
              width: 36px;
              background-color: #ff4d4f;
            }
          }

          .configElementContainer {
            background-color: #eceff4;
            height: 36px;
            width: 450px;
            display: flex;
            margin-right: 10px;
            opacity: 1;
            margin-bottom: 5px;
            cursor: pointer;
            transition: width 0.5s;
            .greenContainer {
              width: 8px;
              background-color: #71cf48;
              transition: width 0.5s;
              .navyBlueContainer {
                margin: 9px;
                width: 18px;
                height: 18px;
                background-color: #68c53f;
                overflow: hidden; /* Hide the element content, while height = 0 */
                opacity: 0;
                transition: opacity 300ms 0ms;
              }
              .ant-checkbox-inner::after {
                position: absolute;
                display: table;
                border: 2px solid #0060b9;
                border-top: 0;
                border-left: 0;
                transform: rotate(45deg) scale(1) translate(-50%, -50%);
                opacity: 1;
                transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
                content: " ";
              }
              .ant-checkbox-checked::after {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border: 1px solid white;
                border-radius: 2px;
                visibility: hidden;
                -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
                animation: antCheckboxEffect 0.36s ease-in-out;
                -webkit-animation-fill-mode: backwards;
                animation-fill-mode: backwards;
                content: "";
              }
              .ant-checkbox-inner::after {
                position: absolute;
                display: table;
                border-top: 0;
                border-left: 0;
                transform: rotate(45deg) scale(1) translate(-50%, -50%);
                opacity: 1;
                transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
                content: " ";
              }
              .ant-checkbox-inner {
                position: relative;
                top: -3px;
                left: 0;
                display: block;
                width: 18px;
                height: 18px;
                direction: ltr;
                background-color: white;
                border: 1px solid #d9d9d9;
                border-radius: 2px;
                border-collapse: separate;
                transition: all 0.3s;
              }
            }
            .redContainer {
              width: 8px;
              background-color: #ff4d4f;
              transition: width 0.5s;
              .navyBlueContainer {
                margin: 9px;
                width: 18px;
                height: 18px;
                background-color: #ec1316;
                overflow: hidden; /* Hide the element content, while height = 0 */
                opacity: 0;
                transition: opacity 300ms 0ms;
              }
              .ant-checkbox-inner::after {
                position: absolute;
                display: table;
                border: 2px solid #0060b9;
                border-top: 0;
                border-left: 0;
                transform: rotate(45deg) scale(1) translate(-50%, -50%);
                opacity: 1;
                transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
                content: " ";
              }
              .ant-checkbox-checked::after {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border: 1px solid white;
                border-radius: 2px;
                visibility: hidden;
                -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
                animation: antCheckboxEffect 0.36s ease-in-out;
                -webkit-animation-fill-mode: backwards;
                animation-fill-mode: backwards;
                content: "";
              }
              .ant-checkbox-inner::after {
                position: absolute;
                display: table;
                border-top: 0;
                border-left: 0;
                transform: rotate(45deg) scale(1) translate(-50%, -50%);
                opacity: 1;
                transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
                content: " ";
              }
              .ant-checkbox-inner {
                position: relative;
                top: -3px;
                left: 0;
                display: block;
                width: 18px;
                height: 18px;
                direction: ltr;
                background-color: white;
                border: 1px solid #d9d9d9;
                border-radius: 2px;
                border-collapse: separate;
                transition: all 0.3s;
              }
            }

            .bluContainer {
              width: 8px;
              background-color: #0073ea;
              transition: width 0.5s;
              .navyBlueContainer {
                margin: 9px;
                width: 18px;
                height: 18px;
                background-color: #0060b9;
                overflow: hidden; /* Hide the element content, while height = 0 */
                opacity: 0;
                transition: opacity 300ms 0ms;
              }
              .ant-checkbox-inner::after {
                position: absolute;
                display: table;
                border: 2px solid #0060b9;
                border-top: 0;
                border-left: 0;
                transform: rotate(45deg) scale(1) translate(-50%, -50%);
                opacity: 1;
                transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
                content: " ";
              }
              .ant-checkbox-checked::after {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border: 1px solid white;
                border-radius: 2px;
                visibility: hidden;
                -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
                animation: antCheckboxEffect 0.36s ease-in-out;
                -webkit-animation-fill-mode: backwards;
                animation-fill-mode: backwards;
                content: "";
              }
              .ant-checkbox-inner::after {
                position: absolute;
                display: table;
                border-top: 0;
                border-left: 0;
                transform: rotate(45deg) scale(1) translate(-50%, -50%);
                opacity: 1;
                transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
                content: " ";
              }
              .ant-checkbox-inner {
                position: relative;
                top: -3px;
                left: 0;
                display: block;
                width: 18px;
                height: 18px;
                direction: ltr;
                background-color: white;
                border: 1px solid #d9d9d9;
                border-radius: 2px;
                border-collapse: separate;
                transition: all 0.3s;
              }
            }
            .configElementTitle {
              text-align: left;
              font: normal normal normal 20px/39px Open Sans;
              letter-spacing: 0px;
              color: #323338;
              opacity: 1;
              margin-left: 7px;
            }
          }
          .configElementContainer:hover {
            background-color: #e6e9ef;
            .navyBlueContainer {
              opacity: 1;
            }
            .greenContainer {
              width: 36px;
              background-color: #71cf48;
            }
            .bluContainer {
              width: 36px;
              background-color: #0073ea;
            }
            .redContainer {
              width: 36px;
              background-color: #ff4d4f;
            }
          }
        }
        .childContainer {
          display: flex;
        }
      }
    }
    .optionsContainer {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      margin-bottom: 5px;
      @keyframes wobble {
        0% {
          transform: translateX(30px);
        }
        100% {
          transform: translateX(0);
        }
      }
      animation: wobble 0.4s;
      .optionButtonTrue {
        width: 89px;
        height: 35px;
        background-color: #71cf48;
        border-color: #68c142;
        border-radius: 5px;
        margin-left: 5px;
        .textOptionButton {
          text-align: center;
          font: italic normal normal 16px Open Sans;
          letter-spacing: 0px;
          color: #ffffff;
          opacity: 1;
        }
      }
      .optionButtonTrue:hover {
        width: 89px;
        height: 35px;
        border-radius: 5px;
        background-color: #68c142;
        border-color: #68c142;
      }
      .optionButtonFalse {
        width: 89px;
        height: 35px;
        background-color: #ff4d4f;
        border-color: #fe4c4a;
        border-radius: 5px;
        margin-left: 5px;
        .textOptionButton {
          text-align: center;
          font: italic normal normal 18px Open Sans;
          letter-spacing: 0px;
          color: #ffffff;
          opacity: 1;
        }
      }
      .optionButtonFalse:hover {
        width: 89px;
        height: 35px;
        border-radius: 5px;
        width: 89px;
        height: 35px;
        background-color: #fe4c4a;
        border-radius: 5px;
        border-color: #fe4c4a;
        .textOptionButton {
          text-align: center;
          font: italic normal normal 18px Open Sans;
          letter-spacing: 0px;
          color: #ffffff;
          opacity: 1;
        }
      }
    }
    .drawerTitleContainer {
      margin-top: 30px;
      width: 100%;
      border-bottom: 3px solid #e6e9ef;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-bottom: 18px;
      .drawerTitle {
        width: 100%;
        text-align: center;
        font: normal normal bold 24px/39px Open Sans;
        letter-spacing: 0px;
        color: #323338;
        opacity: 1;
      }
    }
  }
}

///////////////// Dark Mode /////////////////////////

.drawerRoleDark {
  .ant-drawer-header {
    background-color: $headerDarkBackground;
    .ant-drawer-close {
      svg {
        fill: #fff;
        path {
          fill: #fff;
        }
      }
    }
  }
  .ant-drawer-body {
    background-color: $bodyDarkBackground;
    .drawerBody {
      color: #fff;
      .drawerTitleContainer {
        border-color: $inputDarkColor;
        .drawerTitle {
          color: #fff;
        }
      }
      .drawerAllParents {
        .drawerContent {
          .parentContainer {
            .configElementContainer {
              background-color: $inputDarkColor;
              &:hover {
                background-color: $inputHoverDark;
              }
              .configElementTitle {
                color: #fff;
              }
            }
            .configElementSelectedContainer {
              background-color: $inputDarkColor;
              &:hover {
                background-color: $inputHoverDark;
              }
              .configElementTitle {
                color: #fff;
              }
            }
          }
          .childContainer {
            .childElement {
              .childTitle {
                background-color: $inputDarkColor;
                color: #fff;
                &:hover {
                  background-color: $inputHoverDark;
                }
              }
            }
            .stepChildContainer .stepChildTitle {
              background-color: $inputDarkColor;
              color: #fff;
              &:hover {
                background-color: $inputHoverDark;
              }
            }
          }
          .divider {
            border-color: $inputDarkColor;
          }
        }
      }
    }
  }
}
