.service_price_total {
    margin-left: 8px;
    display: flex;
    align-items: center;
    border-left: 2px solid #1264a3;
}

.service_price_total h4 {
    margin-bottom: 0;
    font-size: 0.9rem;
    margin: 0 10px;
}

.service_price_total .service_horizontal_line hr {
    width: 35px;
    height: 1px;
    background-color: #71cf48;
    margin: 0;
}

.service_price_total .pre_horizontal_line hr {
    width: 20px;
    height: 1px;
    background-color: #1264a3;
    margin: 0;
}

.service_price_total .est_horizontal_line hr {
    width: 60px;
    height: 2px;
    background-color: #71cf48;
    margin: 0;
}

.service_price_total .service_price_wrapper {
    display: flex;
    align-items: center;
    color: #71cf48;
    margin-left: 10px;
    font-size: 0.9rem;
}