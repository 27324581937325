$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;

.preventCreationOfSchedule {
  .ant-modal-header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 500px;
    height: 80px;
    background: #fe4c4a;
    border-radius: 10px 10px 0px 0px;
  }
  .ant-modal-content {
    border-radius: 10px;
    width: 500px;
    height: fit-content;
  }
  .preventContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 0.5rem;
    .preventTitle {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #000000;
    }
    .preventDesc {
      width: 400px;
      height: 38px;
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      text-align: center;
    }
  }
  .estContnet {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    // margin-top: 23px;
    gap: 20px;
  }
  .preventSchAddress {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    // color: #000000;
    color: #fe4c4a;
  }
  .preventEstButton {
    width: 145px;
    height: 32px;
    background: #1264a3;
    border-radius: 5px;
    border: none;
    color: #fff;
  }
}

.preventCreationOfScheduleDark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-body {
      .preventTitle, .preventDesc {
        color: #fff;
      }
    }
  }
}