.checkBox {
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fe4c4a 0% 0% no-repeat padding-box;
  border-radius: 50%;
}
.checkedActive {
  background: #71cf48 0% 0% no-repeat padding-box;
}
