.InformationBody {
  display: flex;
  flex-direction: column;
  padding: 20px 0px 20px 0px;
  .InforamtionBodyElementContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    .InformationBodyElementContainerTitle {
      text-align: left;
      margin-bottom: 10px;
      font: normal 600 normal 16px Open Sans;
      letter-spacing: 0px;
      color: #323338;
      opacity: 1;
    }
    .progressInformationContainer {
      height: 30px;
      display: flex;
      justify-content: space-evenly;
      margin-left: -20px;
      .progressInformationContainerActive {
        width: 268px;
        height: 29px;
        border-radius: 16px;
        background: #f4f5f6 0% 0% no-repeat padding-box;
        background: linear-gradient(to right, #ff0000, #fff200, #1e9600);
        .progressInformationContainerSelected {
          width: 270px;
          height: 30px;
          border-radius: 16px;
          background: radial-gradient(
            circle at 0px 15px,
            rgba(255, 0, 0, 0) 18px,
            rgba(255, 0, 0, 0) 18px,
            #f4f5f6 0px
          );
        }
      }
    }
    .InforamtionBodyElementContainerContent {
      @extend .InformationBodyElementContainerTitle;
      display: flex;
      color: #767b81;
      margin-bottom: 10px;
      font-weight: 400;

      button {
        margin-left: 6px;
      }
    }
  }
  .configElementContainer {
    width: fit-content;
    padding-right: 10px;
  }

  .workOrderNoInfo {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600 !important;
    font-size: 1.1rem;
  }
}
