.new-checkbox-item {
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  width: 48px;
  height: 36px;
  line-height: 36px;
  background: transparent;
  font-size: 1.125rem;
  cursor: not-allowed;

  .ant-checkbox-wrapper {
    height: 36px !important;
    display: flex;
    align-items: center;
    color: inherit;

    //unchecked
    .ant-checkbox {
      border: 5px solid #fe4c4a;
      border-radius: 2px;

      .ant-checkbox-inner {
        border-radius: 0;
        border: 2px solid #ff7274;
        background-color: #eb4345;
        height: 14px;
        width: 14px;
      }
    }

    //checked
    .ant-checkbox-checked {
      border: 5px solid #68c142;

      .ant-checkbox-inner {
        border-radius: 0;
        border: 2px solid #71cf48;
        background-color: #68c142;
        height: 14px;
        width: 14px;
        content: url("../../pages/Settings/settingsComponents/Roles/src/TickIcon.svg");
      }
    }

    //indeterminate
    .ant-checkbox-indeterminate {
      border: 5px solid #1264a3;
      border-radius: 2px;

      .ant-checkbox-inner {
        border-radius: 0;
        border: 2px solid #1c7ac2;
        background-color: #0f5c97;
        height: 14px;
        width: 14px;
        padding: 2px;
        content: "";
        &::after {
          background-color: white;
        }
      }
    }

    //disabled
    .ant-checkbox-disabled {
      border: 5px solid #a2a4a5;
      border-radius: 2px;

      .ant-checkbox-inner {
        border-radius: 0;
        border: 2px solid #aeb8c0;
        background-color: #8d959c;
        height: 14px;
        width: 14px;
        padding: 2px;
        content: "";
        &::after {
          background-color: white;
        }
      }
    }
  }
}
