$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.servicesModalEstimation {
  width: 783px;
  max-height: 100%;
  height: fit-content;

  .ant-modal-header {
    display: flex;
    align-items: center;
    height: 45px;
    font-weight: 600px;
    background-color: #f5f5f5;
  }
  // .ant-modal-content {
  //   border-radius: 10px !important;
  // }
  .ant-modal-body {
    padding-left: 0px !important;
    padding-right: 0px !important;
    // margin-top: 10px;
  }
  .services-table {
    height: fit-content;
    max-height: 800px;
    overflow-y: auto;
  }
}

.servicesModalEstimationDark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      table {
        border-color: #acacca;
        tr {
          th {
            background-color: $cardModalHeaderDark !important;
            color: #fff;
          }
          td {
            color: #fff;
          }
        }
      }
    }
  }
}
