.user-sidebar-container {
  display: flex;
  justify-content: center;
  align-items: center;
}


.drawer-right-tablet {
  .close-icon {
    color: red !important;
    fill: red !important;
  }

  .ant-drawer-content-wrapper {

    // border-radius: 10px 0 0 10px;
    .ant-drawer-content {

      // border-radius: 10px 0 0 10px;
      .ant-drawer-header-title {
        .ant-drawer-title {
          font-size: 18px;
          font-weight: 600;
          color: #fff;
        }

        .ant-drawer-close {
          fill: #fff;
        }
      }
    }
  }

  .ant-drawer-header {
    height: 45px;
    padding: 20px 11.5px;
    background-color: #1f2a44;
    border: 0;
  }
}

.drawer-right-tablet-content {
  list-style: none;
  margin: 0;
  padding: 0;

  .header-icon {
    width: 24px !important;
    fill: #222a44 !important;

    svg {
      fill: #222a44 !important;
    }
  }

  .drawer-right-tablet-content-item {
    display: flex;
    justify-content: space-between;
    align-content: center;
    margin-bottom: 10px;


    label {
      font-size: 1rem;
      font-weight: 600;
      color: #323232;
    }

  }
}