@import "../../../../../../index.scss";

$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.approvalsPopOver {
  .ant-drawer-header {
    padding: 12px 24px;

    .ant-drawer-extra {
      .approvalsPopOverHeader {
        display: flex;
        gap: 10px;
        margin-left: 20px;
        padding-left: 2%;

        .guide-icon {
          path {
            fill: #17598b !important;
          }
          // display: flex;
          // justify-content: center;
          // align-items: center;
          // width: 80px;
          // height: 30px;
          // background-color: #1264a3;
          // border-radius: 100%;
          // color: #fff;

          &:hover {
            cursor: pointer;
          }
        }

        @include flex(center, space-between);

        .approvalsPopOverTitle {
          @include text(16px, 600, 0, black);
          padding-bottom: 3px;
        }

        .approvalsPopOverOptions {
          @include flex(center, space-between);
          gap: 10px;

          .requestsSearchField {
            border: 0;
            border-radius: 5px;
            flex-grow: 1;
          }
        }
        .ant-form-item {
          margin-bottom: 0px;
        }
      }
    }
  }

  .ant-drawer-body {
    padding: 0;

    .approvalsPopOverContent {
      width: 100%;
      height: stretch;
      overflow: hidden;
      padding: 20px;
      padding-right: 5px;

      .requestsSection {
        height: 100%;
        border-radius: 5px;
        padding-right: 5px;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .actionLogsTable {
          height: 100%;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .action-log-rows {
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 10px;
          }

          .logRowContainer {
            padding-top: 5px;
            padding-bottom: 2px;

            .logRow {
              display: flex;
              justify-content: space-between;
              background-color: #f4f5f6;
              border-radius: 5px;
              padding: 5px 10px 5px 0px;
              align-items: center;

              button {
                border-radius: 5px;
                background-color: #1264a3;
                border: none;
                padding: 5px 5px 4px 5px;
                margin-bottom: 2px;
                margin-left: 10px;
                height: fit-content;
              }

              .infoContainer {
                padding-left: 10px;
                line-height: 35px;

                .keyInfo {
                  font-weight: 600;
                  width: fit-content;
                }
              }
            }
          }

          .pageButtons {
            display: flex;
            justify-content: center;
            align-items: center;
            max-height: 30px;
            margin-bottom: 10px;
            gap: 20px;
            width: 98%;
            padding: 0 10px;

            .ant-pagination-prev {
              position: absolute;
              left: 0;
              margin-left: 20px;
            }

            .ant-pagination-next {
              position: absolute;
              right: 0;
              margin-right: 10px;
            }

            .ant-pagination-item {
              border-radius: 5px;
              background-color: #f5f5f7;
              border: none;
              margin-inline-end: unset !important;
              color: #323338;
              font-weight: 500;
            }

            .ant-pagination-item-active {
              border-color: #17598b;
            }

            .page-text {
              user-select: none;
              cursor: disabled;
            }

            .page-text-active {
              color: #1264a3;
              cursor: pointer;
            }

            .ant-pagination-options {
              display: none;
            }
          }
        }
      }

      .requestCategory {
        background-color: #f4f5f6;
        padding: 20px;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        gap: 10px;

        &:not(:last-child) {
          margin-bottom: 20px;
        }
      }

      .requestsContainer {
        display: grid;
        gap: 20px;
        grid-template-columns: 1fr 1fr;
        line-height: 64px;
      }

      .informative-text {
        display: flex;
        align-items: center;
        gap: 20px;

        p {
          @include text(14px, 400, 0, #323338);
        }
      }

      .todoControllers {
        display: flex;
        gap: 10px;
        width: 98%;

        .ant-input-affix-wrapper {
          background-color: #f8f8fa;
          width: 100%;
          max-height: 30px;
          border: unset;

          .ant-input {
            border-radius: 5px;
            background-color: #f8f8fa;
            border: none;
            padding: 5px 7px 5px 5px;
            width: 100%;
            max-height: 30px;
          }
        }

        button {
          border-radius: 5px;
          background-color: #1264a3;
          border: none;
          width: 35px;
          height: 32px;

          svg {
            fill: #fff;
          }
        }

        .pageButtons {
          span {
            svg {
              fill: black;
            }
          }
        }
      }

      .todosContainer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 92%;
        overflow-y: auto;

        &.is-mobile {
          .todoInfo {
            grid-template-columns: 50% 20% 40% 5% !important;
          }

          .todoTitle {
            width: 75% !important;
          }
        }

        .allTodos {
          margin-top: 20px;
          display: flex;
          flex-direction: column;
          gap: 10px;

          .todoRow {
            display: inline-grid;
            grid-template-columns: 65% 35%;
            gap: 10px;
            margin-bottom: 7px;
            border-radius: 4px;
            background-color: #f8f8fa;
            padding: 5px 10px;
            width: 100%;
            align-items: center;
            font-style: Open Sans;
            font-weight: 400;
            font-size: 14px;

            .customDotToDoList {
              color: red;
              margin: 5px;
              font-size: 30px;
              position: absolute;
              top: 50%;
              transform: translateY(-60%);
              left: -20px;
            }

            .todoTitle {
              width: 95%;
              max-width: 100%;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: left;
            }

            .todoInfo {
              display: inline-grid;
              grid-template-columns: 25% 20% 40% 5%;
            }

            .todoCategory {
              width: 95%;
              text-align: center;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            .todoRecord {
              text-align: center;
              width: 95%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            .todoActions {
              justify-content: space-around;
              display: flex;
              align-items: center;

              .todoRedirect {
                background-color: #1264a3;
                border-radius: 5px;
                padding: 5px 0 0 6px;
                width: 26px;
                height: 26px;
                cursor: pointer;

                svg {
                  fill: #fff;
                  width: 14px;
                  height: 14px;
                }
              }

              .todoStatus {
                padding: 5px 10px;
                border-radius: 4px;
                max-height: 30px;
                width: fit-content;
                text-overflow: unset;
                min-width: 100px;
                text-align: center;
              }
            }
          }

          .doneTodo {
            position: relative;
          }

          .doneTodo::after {
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: black;
          }
        }

        .pageButtons {
          display: flex;
          justify-content: center;
          align-items: center;
          max-height: 30px;
          margin-bottom: 30px;
          gap: 20px;
          width: 98%;

          .ant-pagination-item {
            border-radius: 5px;
            background-color: #f5f5f7;
            border: none;
            margin-inline-end: unset !important;
            color: #323338;
            font-weight: 500;
          }

          .ant-pagination-item-active {
            border-color: #17598b;
          }

          .ant-pagination-options {
            .ant-select {
              .ant-select-selector {
                border: none;
                background-color: #f5f5f7;
              }
            }
          }

          .page-text {
            user-select: none;
            cursor: disabled;
          }

          .page-text-active {
            color: #1264a3;
            cursor: pointer;
          }
        }
      }
    }
  }

  .todoRowHeader {
    background-color: unset !important;
    font-weight: 600 !important;

    .todoActions {
      // align-items: flex-start !important;
      justify-content: space-between !important;
      padding-right: 10px;
    }
  }

  @media (max-width: 767px) {
    .ant-drawer-body
      .approvalsPopOverContent
      .requestsSection
      .requestCategory
      .requestsContainer {
      grid-template-columns: 1fr;
    }
  }

  .todoEmailBox {
    width: 100%;
    display: flex;
    justify-content: center;
    // align-items: center;

    .EmailBoxContainer {
      height: 600px;
      z-index: 99999;
      position: absolute;
      bottom: 0;
      left: 0;
      display: flex !important;
      justify-content: flex-start !important;
      flex-direction: column !important;

      .ant-form-item {
        width: 100%;
        margin-bottom: 0;
      }

      .ant-form {
        display: flex !important;
        justify-content: flex-start !important;
        flex-direction: column !important;
        height: 100% !important;

        .emailBoxContainer {
          // height: 100% !important;
          .tox-tinymce {
            border: none;
            border-radius: 5px;

            .tox-menubar {
              display: none;
            }

            .tox-statusbar {
              display: none;
            }

            .tox-toolbar.tox-toolbar--scrolling:nth-child(n + 1) {
              display: none;
            }

            .tox-toolbar__primary {
              display: flex;
              justify-content: flex-start;
              width: 100%;

              .tox-toolbar__group {
                border: none;
              }
            }

            .tox-sidebar-wrap {
              border-radius: 5px;

              .tox-edit-area {
                border-radius: 5px;

                .tox-edit-area__iframe {
                  margin-top: 10px;
                  border-radius: 5px;
                  border: none;
                }
              }
            }
          }
        }
      }

      .ant-upload-wrapper.uploadDocumentsClass
        .ant-upload-list
        .ant-upload-list-item
        .ant-upload-list-item-name {
        width: 200px;
        overflow: hidden;
      }
    }
  }
}

.todoFilters {
  .ant-modal-content {
    max-width: 400px;

    .ant-modal-header {
      height: 45px;
      display: flex;
      align-items: center;
      background-color: #f8f8fa;

      .ant-modal-title {
        font-size: 16px;
        font-weight: 600;
        color: #333238;
      }
    }
  }
}

.approvalsPopOverDark {
  .ant-drawer-header {
    background-color: $cardModalBodyDark !important;

    .ant-drawer-header-title {
      background-color: $cardModalBodyDark !important;

      .ant-drawer-close {
        svg {
          fill: #fff;
        }
      }

      .ant-drawer-title {
        color: white;

        .approvalsPopOverHeader {
          background-color: $cardModalBodyDark !important;

          .approvalsPopOverTitle {
            @include text(16px, 600, 0, white);
          }
        }

        .requestsSearchField {
          background-color: $cardModalBodyDark;

          .ant-input-prefix svg {
            fill: #fff;
          }

          .ant-input {
            background-color: $cardModalBodyDark !important;
            color: #fff;

            &::placeholder {
              color: #acacca;
            }
          }

          .ant-input-suffix svg {
            fill: #acacca;
          }
        }
      }
    }
  }

  .ant-drawer-body {
    background-color: $cardModalBodyDark;

    .requestCategory {
      display: flex;
      flex-direction: column;
      gap: 8px;
      background-color: $cardModalHeaderDark !important;

      .categoryLabel,
      .noRequestLabel {
        color: #fff;
      }

      .requestsContainer {
        .formRequestCard {
          background-color: $cardModalBodyDark;

          .requestContent {
            .requestCardHeader {
              // .ant-popover-open:hover {
              .actionIcon:hover {
                background-color: #13151d;
              }

              svg {
                fill: #fff;

                path {
                  fill: #fff;
                }
              }
            }

            .requestCardBody {
              .requestInfo,
              .cargoLine,
              .requestDate {
                color: #fff;
              }
            }
          }
        }
      }
    }

    .approvalsPopOverContent {
      .informative-text {
        p {
          color: #fff !important;
        }
      }

      .requestsSection {
        .actionLogsTable {
          .ant-pagination {
            .ant-pagination-prev,
            .ant-pagination-next {
              color: #fff;
            }

            .ant-pagination-item {
              background-color: $cardModalHeaderDark !important;
              color: #fff;
            }
          }
        }
      }
    }

    .todoControllers {
      .ant-input-affix-wrapper {
        background-color: $inputModalDark !important;

        .ant-input-prefix {
          color: #acacca;
        }

        .ant-input-suffix,
        .ant-input {
          background-color: $inputModalDark !important;
          color: #fff;
        }
      }

      .ant-input {
        background-color: $inputModalDark !important;
        color: #fff;

        &::placeholder {
          color: #acacca;
        }
      }
    }

    .todosContainer {
      .allTodos {
        .todoRow {
          background-color: $cardModalHeaderDark !important;

          .doneTodo::after {
            background-color: #fff;
          }

          .todoDate {
            display: flex;
            justify-content: center;
            color: white;
          }

          .todoInfo {
            color: #fff !important;
          }
        }

        .todoActions {
          .todoRedirect {
            background-color: #1264a3;
            border-radius: 5px;
            padding: 5px 5px 0px 5px;
            cursor: pointer;
          }

          .todoStatus {
            color: white;
          }
        }
      }

      .pageButtons {
        color: #fff;

        span {
          svg {
            fill: #fff !important;
          }
        }

        button {
          color: white !important;
          background-color: $inputModalDark !important;
        }
      }
    }

    .pageButtons {
      color: #fff;
    }
  }
}

.todoFiltersDark {
  .ant-modal-content {
    .ant-modal-close-x {
      svg {
        fill: #fff !important;
      }
    }

    .ant-modal-header {
      background-color: $cardModalHeaderDark !important;

      .ant-modal-title {
        color: #fff !important;
      }
    }

    .ant-modal-body {
      background-color: $cardModalBodyDark !important;

      .labeledInputLabel {
        color: #fff !important;
      }

      .ant-input-affix-wrapper {
        background-color: $inputModalDark !important;

        .ant-input-suffix,
        .ant-input {
          background-color: $inputModalDark !important;
          color: #fff;
        }
      }

      .ant-input {
        background-color: $inputModalDark !important;
        color: #fff;

        &::placeholder {
          color: #acacca;
        }
      }

      .ant-input-disabled {
        color: #acacca;
      }

      .ant-select {
        .ant-select-selector {
          background-color: $inputModalDark !important;

          .ant-select-selection-search-input {
            color: #fff;
          }

          .ant-select-selection-item {
            color: #fff;
          }

          .ant-select-selection-placeholder {
            color: #acacca;
          }

          .ant-select-selection-overflow-item .ant-select-selection-item {
            background-color: #323338;

            .ant-select-selection-item-remove svg {
              fill: #fff;
            }
          }
        }

        .ant-select-clear {
          background-color: $inputModalDark;

          svg {
            fill: #acacac;
          }
        }

        .ant-select-arrow svg {
          fill: #fff;
        }
      }

      .ant-select-disabled {
        .ant-select-selector {
          .ant-select-selection-search-input {
            color: #acacac !important;
          }

          .ant-select-selection-item {
            color: #acacac !important;
          }
        }
      }

      .ant-picker {
        background-color: $inputModalDark !important;

        .ant-picker-input {
          background-color: $inputModalDark;

          input {
            color: #f2f2f2;

            &::placeholder {
              color: #acacca;
            }
          }

          .ant-picker-suffix svg {
            color: #acacca;
          }

          .ant-picker-clear {
            background-color: $inputModalDark;

            svg {
              fill: #acacac;
            }
          }
        }
      }

      .ant-picker-disabled {
        .ant-picker-input input {
          color: #acacac !important;
        }
      }
    }

    .ant-modal-footer {
      background-color: $cardModalHeaderDark !important;
    }
  }
}
