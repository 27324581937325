.RentalDetailsViewLight {
  height: 360px;
  width: 100%;
  .ScheduleDetails-container {
    background: red;
    // margin: 20px;
    height: 340px;
    display: flex;
    border-radius: 15px;
    box-shadow: 0px 1px 4px rgb(0 0 0 / 16%);
    .item-label-contanier {
      width: 25%;
      height: 100%;
      background: #1264a3;
      overflow: auto;
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;
      .item-label {
        padding: 5px;
        cursor: pointer;
        font-size: 19px;
        font-weight: 500;
        margin-top: 5px;
        margin-bottom: 5px;
        // background: #1f212d;
        color: #fff;
      }
      .item-label-active {
        padding: 5px;
        cursor: pointer;
        font-size: 19px;
        font-weight: 500;
        background: #299af0a4;
        margin-top: 5px;
        margin-bottom: 5px;
        color: #fff;
      }
    }
    .notes-content {
      width: 75%;
      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;
      background: #1264a3;
      padding: 15px;
      .name-div {
        font-size: 20px;
        color: #fff;
        width: 100%;
        .name-content {
          background: #fff;
          width: 100%;
          border-radius: 5px;
          color: #1f212d;
          margin-bottom: 10px;
          height: 31.42px;
        }
        .name-content-desc {
          background: #fff;
          width: 100%;
          border-radius: 5px;
          color: #1f212d;
          height: 120px;
          overflow: auto;
        }
      }
      .select-service {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
      }
    }
  }
}
