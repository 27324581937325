.includeRentalContainerDark {
  width: stretch;
  height: fit-content;
  background-color: #12131b;
  box-shadow: 0px 0px 6px 3px #71cf48;
  border-radius: 10px;
  overflow: hidden;
  .ag-root-wrapper-body.ag-layout-normal {
    height: fit-content !important;
  }
}

.includeRentalContainerLight {
  width: stretch;
  height: fit-content;
  background-color: #f8f8f8;
  box-shadow: 0px 0px 6px 3px #71cf48;
  border-radius: 10px;
  overflow: hidden;
  .ag-root-wrapper-body.ag-layout-normal {
    height: fit-content !important;
  }
}
