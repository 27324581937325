@import "../../../../../../../../../assets/styles/breakpoints.scss";

.charge-main-header {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  .saveChanges {
    height: 30px;
    span {
      font-weight: 500 !important;
    }
  }
  span {
    color: white;
    font-weight: 600;
  }
  .charge-title-header-left {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .textTitle {
      font-weight: 5px;
      padding: 5px 11px;
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #ffffff;

      @include tablet {
        display: none;
      }
    }
  }
  .charge-title-header-right {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include tablet {
      font-size: 14px;

      > span {
        margin-left: 10px;
      }
    }

    .chargeCategoryFrom {
      min-width: fit-content;
      margin: 0 10px;
      text-align: center;

      @include tablet {
        display: none;
      }
    }
  }
  .darkModeContainer {
    margin-left: 20px;
    .firstSquare {
      background-color: white;
    }
    .secondSquare {
      background-color: white;
    }
    .innerCircle {
      background-color: white;
    }
    .outerCircle {
      background-color: #12131b;
    }
  }
}
