.live-map-filters {
  position: relative;
  top: 60px;
  height: 100px;
  width: 100%;
  box-sizing: border-box;
  z-index: 2;
  background-color: transparent;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 1rem;

  .live-status {
    height: 90%;
    background-color: rgb(91, 153, 226);
    border-radius: 5px;
    box-shadow: 0 0 7px 3px rgba(12, 27, 70, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    opacity: 0.9;
    cursor: pointer;
    color: #fff;

    .status-value {
      font-size: 2rem;
      font-weight: 600;
      letter-spacing: 0.28px;
    }

    .status-name {
      font-weight: 550;
    }
  }

  .live-status-selected {
    opacity: 1;
    border: 2px solid white;
  }

  .Idling {
    background-color: rgb(223, 196, 0);
  }

  .Moving {
    background-color: rgb(124, 202, 0);
  }

  .Stopped {
    background-color: rgb(220, 64, 69);
  }

  .InMotion {
    background-color: rgb(10, 136, 0);
  }
}
