@import "../../../index.scss";
@import "../../../assets/styles/breakpoints.scss";

.accountingWriteModeContainerDark {
  display: flex;
  align-items: center;
  gap: 20px;

  .writableToggleSwitchContainer {
    width: 150px;
    display: flex;
    justify-content: center;
  }

  .writeReadSwitchContainer {
    background-color: #292b3a !important;
    width: 140px !important;
    height: 23px;
    font-size: 13px;

    @include laptop {
      font-size: 10px;
    }

    .ant-switch-handle {
      padding: 2px;
      content: url("../../../../src/components/SidebarPages/DynamicView/src/switchEyeIcon.svg");
      background-color: white;
      border-radius: 9px;
    }
  }

  .ant-tooltip-disabled-compatible-wrapper {
    background-color: transparent !important;

    .ant-switch-disabled {
      background-color: #292b3a !important;
      width: 140px !important;
    }
  }

  .ant-switch-checked {
    background-color: #71cf48 !important;
    width: 140px;

    .ant-switch-handle {
      content: url("../../../../src/components/SidebarPages/DynamicView/src/switchEditIcon.svg");
      background-color: white;
      border-radius: 9px;
      svg {
        padding: 3px !important;
      }
    }
  }

  .statusContainer {
    display: flex;
    gap: 5px;
    align-items: center;
    color: white;
    // margin-left: 20px;

    .statusLabel {
      font-family: "Open Sans", sans-serif;
      // font-size: 16px;
      font-weight: 500;
      color: white;
    }

    .statusValue {
      height: 23px;
      min-width: 130px;
      width: fit-content;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 20px;
      color: white;
      font-family: "Open Sans", sans-serif;
      font-size: 16px;
      text-align: center;
      border-radius: 5px;
      font-weight: 400;

      @include laptop {
        font-size: 14px;
      }
    }
  }
}

.accountingWriteModeContainerLight {
  display: flex;
  gap: 20px;
  align-items: center;

  .ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
    background: #68c142;
  }

  .ant-switch:hover:not(.ant-switch-disabled) {
    background: #0f5c97;
  }

  .mondayButtonWhite {
    @include mondayButton(#f5f5f7, #e6e9ef, black);
  }

  .writableToggleSwitchContainer {
    width: 150px;
    display: flex;
    justify-content: center;
  }

  .writeReadSwitchContainer {
    background-color: #1264a3;
    width: 140px;
    height: 23px;
    font-size: 13px;

    @include laptop {
      font-size: 10px;
    }

    .ant-switch-handle {
      padding: 2px;
      content: url("../../../../src/components/SidebarPages/DynamicView/src/switchEyeIcon.svg");
      background-color: white;
      border-radius: 9px;
    }

    .ant-switch-inner-checked {
      font-size: 13px !important;

      @include laptop {
        font-size: 10px;
      }
    }
    .ant-switch-inner-unchecked {
      font-size: 13px !important;

      @include laptop {
        font-size: 10px;
      }
    }
  }

  .ant-tooltip-disabled-compatible-wrapper {
    background-color: transparent !important;

    .ant-switch-disabled {
      background-color: #0c548b !important;
      width: 140px !important;
    }
  }

  .ant-switch-checked {
    background-color: #71cf48;
    width: 140px;

    .ant-switch-handle {
      content: url("../../../../src/components/SidebarPages/DynamicView/src/switchEditIcon.svg");
      background-color: white;
      border-radius: 9px;
      svg {
        padding: 3px !important;
      }
    }
  }

  .statusContainer {
    display: flex;
    gap: 5px;
    align-items: center;
    color: white;

    .statusLabel {
      font-family: "Open Sans", sans-serif;
      // font-size: 16px;
      font-weight: 600;
      color: white;

      @include laptop {
        font-size: 14px;
      }
    }

    .statusValue {
      height: 23px;
      min-width: 130px;
      width: fit-content;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 20px;
      color: white;
      font-family: "Open Sans", sans-serif;
      font-size: 14px;
      font-weight: 600;
      text-align: center;
      border-radius: 5px;
      flex: 1 0 auto;
      word-wrap: break-word;
      font-weight: 400;
    }
  }
}

.accessRequestNotificationDark {
  display: flex;
  flex-direction: column;
  border-radius: 10px !important;
  padding: 0 !important;

  .ant-notification-notice-message {
    border-radius: 10px 10px 0 0 !important;
    width: 100% !important;
    background-color: #fe4c4a;
    margin: 0 !important;
    height: 45px;
    display: flex;
    font: normal normal 600 16px/39px Open Sans;
    color: white;
    .header {
      display: flex;
      padding: 5px;
      align-items: center;
      // background-color: red;
      .icon {
        display: flex;
        align-items: center;
        height: 45px;
        width: 45px;
      }

      svg {
        fill: white;
        margin: 0 !important;
        padding: 0 !important;
        height: 25px;
        width: 25px;
      }
      .title-indc {
        // width: 100%;
        // margin-left: -164px;
        font-size: 13px;

        @include laptop {
          font-size: 10px;
        }
      }
    }
  }

  .ant-notification-notice-close {
    top: 10 !important;
    right: 0;
    height: 45px;
    width: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
