.redirectToScheduleContainer {
  .mondayButtonBlue {
    .iconHolder {
      // width: 32px;
      // height: 32px;
      svg {
        fill: white !important;
      }
    }
  }
}
