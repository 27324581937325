$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.dashboard-boarding {
  width: 100%;
  //   min-width: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  height: 100%;
  max-height: calc(100% - 100px);
  overflow: hidden;

  .dashboard-boarding-list {
    // flex: 1;
    min-width: 350px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: flex-start;
    align-items: center;
    height: 100%;

    .dashboard-boarding-list-title {
      font-size: 16px;
      font-weight: 600;
      color: #323338;
      font-family: "Open Sans";
    }
    .default-list {
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: center;
      align-content: center;
      width: 100%;
      max-height: calc(100% - 50px);
      overflow-y: auto;
    }

    .no-widget-list-body {
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: center;
      align-content: center;
      justify-content: center;
      height: 100%;
    }
  }
}

.dashboard-boarding-footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.dashboard-boarding-dark {
  .dashboard-boarding-list {
    .dashboard-boarding-list-title {
      color: #fff;
    }

    .default-list {
      .default-widget-card {
        background-color: $inputModalDark;
        color: #fff;

        .default-widget-title {
          color: #fff;
        }
      }
    }
  }
}
