.simple-checkbox {
  .ant-checkbox {
    // .ant-checkbox-input {
    // }
    .ant-checkbox-inner {
      height: 20px;
      width: 20px;
      background-color: #f4f5f6;
      border-color: #f4f5f6;
      &:after {
        border: none !important;
        content: "";
        display: block;
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20width%3D%2210%22%20height%3D%2210%22%20viewBox%3D%220%200%2010%2010%22%20fill%3D%22none%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Cpath%20d%3D%22M3.42514%207.70252L1.4798%205.39052C1.41778%205.32763%201.34388%205.27769%201.2624%205.24361C1.18091%205.20952%201.09346%205.19197%201.00514%205.19197C0.91681%205.19197%200.829363%205.20952%200.747878%205.24361C0.666392%205.27769%200.592492%205.32763%200.530471%205.39052C0.467558%205.45341%200.417653%205.52809%200.383605%205.61028C0.349556%205.69246%200.332031%205.78055%200.332031%205.86952C0.332031%205.95848%200.349556%206.04657%200.383605%206.12875C0.417653%206.21094%200.467558%206.28562%200.530471%206.34852L2.9438%209.13251C3.00626%209.19585%203.08068%209.24614%203.16274%209.28046C3.24479%209.31479%203.33286%209.33246%203.4218%209.33246C3.51075%209.33246%203.59881%209.31479%203.68087%209.28046C3.76293%209.24614%203.83735%209.19585%203.8998%209.13251L9.46647%201.82452C9.52938%201.76162%209.57929%201.68694%209.61334%201.60475C9.64738%201.52257%209.66491%201.43448%209.66491%201.34552C9.66491%201.25655%209.64738%201.16846%209.61334%201.08628C9.57929%201.00409%209.52938%200.929414%209.46647%200.866516C9.40445%200.803627%209.33055%200.75369%209.24906%200.719605C9.16758%200.685521%209.08013%200.667969%208.9918%200.667969C8.90348%200.667969%208.81603%200.685521%208.73454%200.719605C8.65306%200.75369%208.57916%200.803627%208.51714%200.866516L3.42514%207.70252Z%22%20fill%3D%22%231264A3%22/%3E%3C/svg%3E");

        width: 12px;
        height: 12px;
        transform: rotate(5deg) translate(-1px, -5px);
        position: absolute;
        background-size: contain;
        background-repeat: no-repeat;
        opacity: 0;
        transition: opacity 0.3s;
      }
    }
  }
  .ant-checkbox-checked {
    // .ant-checkbox-input {
    // }
    .ant-checkbox-inner {
      &:after {
        opacity: 1;

        //?tick made with border
        // border-color: #1264a3;
        // border-width: 1px;
        // width: 6px;
        // height: 11px;
      }
    }
  }
  &:hover {
    .ant-checkbox {
      .ant-checkbox-inner {
        border-color: #1264a3 !important;
        background-color: #f1f5fd !important;
      }
    }
  }
}

.simple-checkbox-dark {
  color: #ededed !important;
}
