$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.day-note-change-modal {
  .ant-modal-header {
    width: 511px;
    height: 45px;
    background: #f7f8fa 0% 0% no-repeat padding-box;
    border-radius: 10px 10px 0px 0px;
    opacity: 1;
    display: flex;
    // justify-content: center;
    align-items: center;
  }
  .ant-modal-content {
    border-radius: 10px;
  }
  .ant-modal-title {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 25px;
    width: 100%;
    height: 22px;
    color: var(--unnamed-color-323338);
    text-align: left;
    font: normal normal 600 16px/39px Open Sans;
    letter-spacing: 0px;
    color: #323338;
    opacity: 1;
  }
  .ant-modal-close-x {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 45px;
    width: 45px;
    // padding: 0;
    color: #323338;
    // font-size: 20px;
  }
  .reason-note-change {
    width: 471px;
    height: 216px;
    background: var(--unnamed-color-f5f5f7) 0% 0% no-repeat padding-box;
    background: #f5f5f7 0% 0% no-repeat padding-box;
    border: 1px solid #dbdbdb;
    border-radius: 5px;
    opacity: 1;
  }
}

.day-note-change-modal-dark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      background-color: $cardModalBodyDark;
      .notes-container {
        div {
          color: #fff !important;
        }
        .ant-input {
          background-color: $inputModalDark !important;
          color: #fff;
          border: none;
          &::placeholder {
            color: #acacca;
          }
        }
      }
    }
  }
}