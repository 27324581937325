.previewRentalsTerms {
  .ant-modal-content {
    background-color: #3a3c4e;
    color: #fff; //modal body
    .antd-modal-body {
      background-color: #3a3c4e;
      color: #fff;
      min-width: 800px;
      min-height: 600px;
      .previewRentalsTerms_container {
        background-color: #3a3c4e;
        color: #fff;
        height: 300px;
      }
    }
    .ant-modal-header {
      background-color: #1264a3;
      color: #fff;
      .ant-modal-title {
        color: #fff !important;
      }
    }
  }
}
