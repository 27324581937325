.serviceButtonContainerRentalsDark {
  background-color: #292b3a;
  margin-top: 3px;
  height: 32px;
  display: flex;
  transition: background-color 0.3s ;
 
  .ant-checkbox-wrapper {
    height: 36px !important;
    display: flex;
    padding-top: 3.5px;

    .ant-checkbox {
      border: 5px solid #fe4c4a;
      border-radius: 2px;

      .ant-checkbox-inner {
        border-radius: 0;
        border: 2px solid #ff7274;
        background-color: #eb4345;
        height: 14px;
        width: 14px;
      }
    }

    //checked
    .ant-checkbox-checked {
      border: 5px solid #68c142;

      .ant-checkbox-inner {
        border-radius: 0;
        border: 2px solid #71cf48;
        background-color: #68c142;
        height: 14px;
        width: 14px;
        content: url("../../../../../../../../../pages/Settings/settingsComponents/Roles/src/TickIcon.svg");
      }
    }

  }

  .selectServiceButton {
    border: 0;
    width: stretch;
    height: 32px;
    background: transparent;
    color: white;
    display: flex;
    // justify-content: flex-start;
    align-items: center;
    padding: 0 0 0 10px;
    position: relative;
    white-space: nowrap !important;
    overflow: hidden;
    gap: 7px;

    .suffix {
      height: stretch;
      width: 0px;
      position: absolute;
      right: 0;
      transition: width 0.3s;
    }

    &:hover {
      .suffix {
        width: 10px;
      }
    }
  }
  
  .retracted{
    padding-left: 15px !important;
  }

  .selectedService {
    border: 0;
    width: stretch;
    height: 32px;
    background-color: #3f4258;
    transition: background-color 0.3s ;
    color: white;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 0 0 10px;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    gap: 7px;

    .suffix {
      height: stretch;
      width: 10px;
      position: absolute;
      right: 0;
    }
  }
}

.serviceButtonContainerRentalsLight {
  margin-top: 3px;
  height: 32px;
  display: flex;
  transition: background-color 0.3s ;

  .ant-checkbox-wrapper {
    height: 36px !important;
    display: flex;
    padding-top: 3.5px;

    .ant-checkbox {
      border: 5px solid #fe4c4a;
      border-radius: 2px;

      .ant-checkbox-inner {
        border-radius: 0;
        border: 2px solid #ff7274;
        background-color: #eb4345;
        height: 14px;
        width: 14px;
      }
    }

    //checked
    .ant-checkbox-checked {
      border: 5px solid #68c142;

      .ant-checkbox-inner {
        border-radius: 0;
        border: 2px solid #71cf48;
        background-color: #68c142;
        height: 14px;
        width: 14px;
        content: url("../../../../../../../../../pages/Settings/settingsComponents/Roles/src/TickIcon.svg");
      }
    }  
  }

  .selectServiceButton {
    border: 0;
    width: stretch;
    height: 32px;
    background-color:#F5F7F9; 
    transition: background-color 0.3s ;
    color: black;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 0 0 10px;
    position: relative;
    white-space: nowrap !important;
    overflow: hidden;
    gap: 7px;

    .suffix {
      height: stretch;
      width: 0px;
      position: absolute;
      right: 0;
      transition: width 0.3s;
    }

    &:hover {
      .suffix {
        width: 10px;
      }
    }
  }

  .selectedService {
    border: 0;
    width: stretch;
    height: 32px;
    background: #cfcfcf;
    transition: background-color 0.3s ;
    color: black;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 0 0 10px;
    position: relative;
    white-space: nowrap !important;
    overflow: hidden;
    gap: 7px;

    .suffix {
      height: stretch;
      width: 10px;
      position: absolute;
      right: 0;
    }
  }
}
