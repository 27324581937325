.services-inspected-container {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  align-items: flex-end;

  .services-inspected-details {
    display: flex;
    flex-direction: column;
    gap: 5px;
    white-space: nowrap;

    p {
      padding: 0px;
      margin: 0px;
    }

    .service-details-container {
      display: flex;
      gap: 5px;
      font-weight: 600;
    }

    .questions-details-container {
      display: flex;
      flex-direction: column;
      gap: 5px;
      padding-inline-start: 20px;
      margin: 0px;
    }
  }
}
