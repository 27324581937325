@import "../../../../../../../index.scss";

$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.newChargeModalWrapper {
  top: 100px !important;
  right: 100px !important;
  bottom: 100px !important;
  left: 100px !important;
  border-radius: 10px;
  overflow: visible;
}
.ant-modal.newChargeModal {
  height: -webkit-fill-available;
  .ant-modal-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
.newChargeModal {
  @include square(auto, auto, unset, unset);
  min-width: -webkit-fill-available;
  // max-width: 1200px;
  // width: fit-content !important;
  padding: 0px !important;
  top: 0px !important;
  height: 100vh;
  .ant-modal {
    width: 1620px !important;
  }
  .ant-modal-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .newChargeModalContainer {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
    .newChargeContainer {
      @include square(100%, auto, unset, unset);
      @include flex(center, center);
      flex-wrap: wrap;
      gap: 10px;
    }
  }
  .ant-modal-body {
    height: 500px;
    padding: 0 10px !important;
    overflow-y: auto;
  }
  .ant-modal-close-icon {
    display: none;
  }

  // ↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓
  // ↓↓↓↓↓↓↓↓↓↓AG
  // GRID
  // STYLE↓↓↓↓↓↓↓↓↓↓↓↓
  // ↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓v↓↓↓↓
  .choseChargeAgGrid {
    height: calc(100% - 50px);

    > div {
      overflow: auto;
    }
    .ag-layout-normal {
      height: 100% !important;
      overflow: auto;
    }
  }
  .ag-row-even {
    background: #f8f8fa !important;
  }
  .ag-row-odd {
    background: white !important;
  }
  .ag-row {
    border-top-style: none !important;
    border-bottom-style: none !important;
    cursor: pointer;
  }
  .ag-row:hover {
    background: #f1f1f5 !important;
  }
  .ag-row-selected {
    background: #f1f1f5 !important;
  }
  .ag-header {
    border-style: none !important;
  }
  .ag-cell {
    padding: 2px !important;
    background-color: inherit !important;
    border-width: 0 !important;
  }
  .ag-header-cell-resize {
    display: none !important;
  }
  .ag-checkbox-input,
  .ag-checkbox-input-wrapper {
    height: 25px;
    width: 25px;
  }
  .ag-header-cell-label {
    display: flex;
    justify-content: center;
  }
  // ↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑
  .informativeText {
    display: flex;
    margin: 21px 0px 19px 20px;
    .icon {
      margin-right: 10px;
    }
    > div {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: #323338;
    }
  }
  .cellContainer {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #323338;
    font-family: "Open Sans";
    font-style: normal;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ag-react-container {
    display: flex;
    justify-content: center;
  }
  .newChargeItemContainer {
    overflow: auto;
  }
  .GroupContainer {
    display: flex;
    align-items: center;
    margin: 10px 0 10px 15px;
    flex-wrap: nowrap;
    overflow-x: auto;
    margin-right: auto;

    .groupContainer {
      display: flex;
      align-items: center;
      margin-right: 9px;
    }
    .InformationSelectedGroupContainer,
    .InformationSelectedGroupContainerElevations {
      width: 250px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-right: 10px;
    }
    .serviceTitle {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
    }
    .elevationInformation {
      max-width: 100%;
      height: fit-content;
      white-space: pre-wrap;
      text-align: start;
      font: normal normal 14px/19px Open Sans;
    }
    .lineDivider {
      width: 1px;
      background: #323338;
      height: 35px;
    }
  }
  .chargeStatusContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    svg {
      padding: 3px;
    }
    .Not {
      path {
        fill: #eb4345 !important;
      }
    }
    .Practically {
      path {
        fill: #2a9bdd !important;
      }
    }
    .Fully {
      path {
        fill: #68c142 !important;
      }
    }
  }
  .ant-progress-text {
    color: white !important;
  }
}

.commonModal {
  border-radius: 5px !important;
  .ant-modal-content {
    border-radius: 10px;
  }
  .ant-modal-header,
  .ant-modal-title,
  .modalHeader {
    width: 100%;
  }
  .ant-modal-header {
    @include square(unset, 45px, unset, #f8f8fa);
    @include flex(center, flex-start);
    border-radius: 10px 10px 0 0;
    border-bottom: 0px;
    .ant-modal-title {
      font: normal normal bold 16px/39px Open Sans;
      width: 100%;
      color: #323338;
    }
  }
  .ant-modal-close-x {
    display: none;
  }
  .modalHeader {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    > svg {
      cursor: pointer;
    }
  }
  .ant-modal-footer {
    border-style: none !important;
    .footerContainer {
      @include flex(center, space-between);
      .NewItemChargeFooterCancel {
        @include mondayButton(#fe4c4a, #eb4345, #fff);
        border-radius: 5px;
      }
      .NewItemChargeFooterSave {
        @include mondayButton(#68c142, #71cf48, #fff);
        border-radius: 5px;
      }
    }
  }
}

.newChargeModalDark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      .choseChargeAgGrid {
        .informativeText div {
          color: #fff;
        }
        .ag-row-even {
          background-color: #15161f !important;
        }
        .ag-row-odd {
          background-color: #2a2d3d !important;
        }
        .ag-cell {
          background-color: $cardModalBodyDark !important;
          .cellContainer,
          div {
            color: #fff !important;
          }
        }
      }
    }
  }
}

@media (max-width: 800px) {
  .newChargeModalWrapper {
    right: 10px !important;
    left: 10px !important;
  }
}
