.vehicle-distances-container {
  max-height: 230px;
  width: 400px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding-top: 1.7rem;
  padding-bottom: 0.5rem;

  .distance-container-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: grid;
    grid-template-columns: calc(60% - 15px) 20% 20%;
    background-color: #fff;
    height: 2rem;
    padding: 0.5rem;
    background-color: #dee2eb;

    .distances-info-close {
      position: absolute;
      top: 2px;
      right: 5px;
      cursor: pointer;
      color: black;
    }
  }

  .vehicle-distance {
    width: 100%;
    display: grid;
    grid-template-columns: 60% 20% 20%;
    padding: 0.3rem;
    border-bottom: 1px solid #dee2eb;

    &:hover {
      background-color: rgba(60, 60, 77, 0.1);
      cursor: pointer;
    }

    .vehicle-name {
      font-weight: 500;
      letter-spacing: 0.18px;
    }

    .vehicle-status {
      font-weight: 550;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      gap: 5px;

      .status-dot {
        height: 0.4rem;
        aspect-ratio: 1;
        border-radius: 50%;
      }
    }
  }
}

.trip-details-container {
  padding: 0 10px;
  .route-detail {
    padding: 0.2rem 0;
    .detail-title {
      margin-left: 6px;
      margin-right: 8px;
    }

    svg {
      fill: #ea4325;
    }

    .redButton {
      padding: 0.3rem;
      border-radius: 5px;
      cursor: pointer;
    }
  }

  #detail-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 5px;
  }
}
