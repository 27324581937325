$headerDarkBackground: #12131b;
$bodyDarkBackground: #1f212d;
$inputModalDark: #12131b;
$whiteText: #f9f9f9;

.changeDepartmentModal {
  width: unset !important;
  min-width: 450px !important;

  .ant-modal-header {
    height: 45px;
    display: flex;
    align-items: center;
    background-color: #f5f6f8;
    border-radius: 10px 10px 0px 0px;
    border: none;
    .ant-modal-title {
      font-size: 16px;
      font-weight: 600;
      color: #333238;
    }
  }
  .ant-modal-body {
    .changeDepartmentInputContainer {
      @media screen and (min-width: 1000px) {
        display: flex;
        gap: 30px;
        min-height: 340px;
        min-width: 840px;
        justify-content: center;
        .labeledInputContainer {
          min-width: 300px !important;
        }
      }
    }
    .PinInput .ant-input-suffix svg path {
      fill: #333238;
    }
  }
  .ant-modal-footer {
    padding: 20px !important;
    .changeDepartmentFooterContainer {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      @media screen and (min-width: 1000px) {
        .mondayButtonContainer button {
          width: 150px !important;
        }
      }
    }
  }

  @media screen and (max-width: 800px) {
    min-width: unset !important;
    .ant-modal-header .ant-modal-title {
      font-size: 14px !important;
      padding-right: 50px !important;
    }
  }
}

.changeDepartmentModalDark {
  .ant-modal-content {
    background-color: $bodyDarkBackground;
    .ant-modal-close .ant-modal-close-x svg {
      fill: $whiteText;
    }
    .ant-modal-header {
      background-color: $headerDarkBackground;
      .ant-modal-title {
        color: $whiteText;
      }
    }
    .ant-modal-body {
      .PinInput .ant-input-suffix svg path {
        fill: $whiteText;
      }
      .labeledInputLabel {
        color: $whiteText;
      }
      .ant-form-item-label label {
        color: $whiteText;
      }
      .ant-input {
        background-color: $inputModalDark !important;
        color: $whiteText;
        &::placeholder {
          color: #acacca;
        }
      }
      .ant-select {
        .ant-select-selector {
          background-color: $inputModalDark !important;
          .ant-select-selection-search-input {
            color: $whiteText !important;
          }
          .ant-select-selection-item {
            color: $whiteText !important;
          }
          .ant-select-selection-placeholder {
            color: #acacca;
          }
        }
        .ant-select-arrow {
          svg {
            fill: $whiteText !important;
            path {
              fill: $whiteText !important;
            }
          }
        }
      }
    }
  }
}
