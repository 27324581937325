.live-audit-view {
  height: calc(100vh - 56px);
  width: -moz-fill-available;
  width: -webkit-fill-available;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;

  .audit-control {
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    flex: 1;
  }
}
