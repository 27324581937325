.idleClassName {
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background: #2a2424 0% 0% no-repeat padding-box;
  .timeIdleContainer {
    position: absolute;
    bottom: 20px;
    left: 50px;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .hourIdleText {
      text-align: center;
      font: normal normal normal 62px/84px Open Sans;
      letter-spacing: 0px;
      color: #ffffff;
      text-shadow: 0px 1px 2px #00000029;
    }
    .DateIdleText {
      text-align: center;
      font: normal normal 300 49px/66px Open Sans;
      letter-spacing: 0px;
      color: #ffffff;
      text-shadow: 0px 1px 2px #00000029;
    }
  }
  .idleTextAttempts {
    text-align: left;
    font: normal normal normal 15px/30px Open Sans;
    letter-spacing: 0px;
    color: #fe4c4a;
    position: relative;
    top: -15px;
    left: -80px;
  }
  .enterPinContainer {
    width: 868px;
    height: 386px;
    background: rgba($color: #000000, $alpha: 0.5);
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    z-index: 2;
    padding: 0 25px 0 25px;
    overflow: hidden;

    .informationData {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px 20px;
    }
    .idleLogo {
      fill: white;
      min-width: 240px;
      min-height: 90px;
      .sidebarCompanyLogo {
        width: 100%;
        height: 100%;
        filter: brightness(0) invert(1);
      }
    }
    .idleText {
      text-align: center;
      font: normal normal normal 22px/30px Open Sans;
      letter-spacing: 0px;
      color: #ffffff;
      .idleUser {
        font: normal normal bold 22px/30px Open Sans;
      }
    }
    .inputIdleContainer {
      display: flex;
      gap: 20px;
      .pinInput {
        width: 200px;
        height: 50px;
        border-radius: 5px;
      }
      .pinButton {
        width: 50px;
        height: 50px;
        border-radius: 5px;
        display: grid;
        place-items: center;
      }
    }
    .idleGoToLogin {
      color: #1264a3;
      font-size: 14px;
      font-weight: 600;
      cursor: pointer;
      margin-top: 20px;
      text-decoration: underline;
    }
  }
  .enterPinTryAgain {
    @extend .enterPinContainer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    .idleText {
      text-align: center;
      font: normal normal normal 19px/30px Open Sans;
      letter-spacing: 0px;
      color: #ffffff;
    }
    .informationData {
      animation: linear;
      animation-name: run;
      animation-duration: 0.5s;
      flex: 1;
    }
    .matrapiku {
      animation: linear;
      animation-name: run;
      animation-duration: 0.5s;
    }
    .idleGoToLogin {
      color: #1264a3;
      font-size: 14px;
      font-weight: 600;
      cursor: pointer;
      margin-top: 20px;
      text-decoration: underline;
    }
  }
  .idleImageClassName {
    width: 100%;
    height: 100%;
    opacity: 1;
    filter: brightness(80%) blur(5px);
  }
}
@keyframes run {
  0% {
    transform: translateX(-100px);
  }
  100% {
    transform: translateX(0);
  }
}
.shake {
  animation-name: shake;
  animation-duration: 0.7s, 0.35s;
  animation-iteration-count: 1, 2;
}
@keyframes shake {
  0%,
  20%,
  40%,
  60%,
  80% {
    transform: translateX(8px);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateX(-8px);
  }
}
