@import "../../../../../index.scss";

$cardDarkBackgroundColor: #12131b;
$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;
$checkboxDark: #2a2b3a;

.newReportModal {
  width: calc(100vw - 20px) !important;
  height: calc(100vh - 20px) !important;
  max-width: 1570px;
  max-height: 783px;
  .ant-modal-content {
    width: calc(100vw - 20px) !important;
    height: calc(100vh - 20px) !important;
    max-width: 1570px;
    max-height: 783px;
    .ant-modal-header {
      display: flex;
      align-items: center;
      height: 45px;
      background-color: #f5f5f7;
      .ant-modal-title {
        color: #323338;
        font-size: 16px;
        font-weight: 600;
        width: 100%;
      }
    }
    .ant-modal-body {
      height: calc(100% - 100px);
      .reportModal-container {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        .reportModal-info {
          display: flex;
          align-items: center;
          width: 100%;
          gap: 10px;

          .createReport-text {
            font-size: 14px;
            margin: 0;
            .reportModal-text {
              font-size: 14px;
              font-weight: 600;
            }
          }
        }
        .newReportModalContent {
          display: flex;
          height: 586px;
          gap: 30px;
          .inputs {
            display: flex;
            flex-direction: column;
            row-gap: 20px;
            width: 420px;
            height: 586px;
            .newReportModal-inputs {
              width: 420px;
              height: 53px;
              .labeledInputLabel {
                height: 19px;
                font-size: 14px;
              }
            }
            .reportModal-checkBoxItem {
              display: flex;
              align-items: center;
              gap: 20px;
              width: 420px;
              height: 32px;
              .ant-form-item {
                margin: 0;
              }
              .reportModal-checkBox {
                width: 200px;
              }
            }

            .descriptionContainer {
              width: 420px;
              height: 461px;
              .description-label {
                font-size: 14px;
                font-weight: 600;
                color: #323338;
              }
              .ant-form-item {
                margin: 0;
              }
              .description-textarea {
                .richTextComp {
                  width: 420px;
                  height: 440px;
                  background-color: #f5f5f7;
                  border-radius: 5px;
                  border: none;
                  margin: 0;
                  .quill {
                    height: 100%;
                    gap: 10px;
                    .ql-toolbar,
                    .ql-container {
                      background-color: #f5f5f7;
                      border-radius: 5px;
                      border: none;
                      border-bottom: none !important;
                    }
                  }
                }
              }
            }
            .edit-descriptionContainer {
              width: 420px;
              height: 461px;
              .description-label {
                font-size: 14px;
                font-weight: 600;
                color: #323338;
                .ant-form-item {
                  margin: 0;
                }
              }
              .description-textarea {
                .richTextComp {
                  width: 420px;
                  height: 367px;
                  background-color: #f5f5f7;
                  border-radius: 5px;
                  border: none;
                  margin: 0;
                  .quill {
                    height: 100%;
                    gap: 10px;
                    .ql-toolbar,
                    .ql-container {
                      background-color: #f5f5f7;
                      border-radius: 5px;
                      border: none;
                      border-bottom: none !important;
                    }
                  }
                }
              }
            }
          }
          .datasetsContainer {
            width: 1080px;
            height: 586px;
            .custom-checkbox-wrapper {
              width: 100%;
              max-width: 469px;
              background-color: #ffffff !important;
            }
          }
        }
      }
    }
    .ant-modal-footer {
      .footerContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .mondayButtonStyle {
          width: 150px;
        }
      }
    }
  }
}

.newReportModalDark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    overflow: hidden;
    .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      background-color: $cardModalBodyDark;
      .reportModal-container {
        .reportModal-info,
        .reportModal-text {
          color: #fff;
        }
        .newReportModalContent {
          .inputs {
            .descriptionContainer {
              .description-label {
                color: #fff;
              }
              .description-textarea {
                .richTextComp {
                  background-color: $cardDarkBackgroundColor;
                  border-radius: 0;
                  .quill {
                    .ql-toolbar,
                    .ql-container {
                      background-color: $cardDarkBackgroundColor;
                      border-radius: 0;
                    }
                  }
                }
              }
            }
            .edit-descriptionContainer {
              .description-label {
                color: #fff;
              }
              .description-textarea {
                .richTextComp {
                  background-color: $cardDarkBackgroundColor;
                  border-radius: 0;
                  .quill {
                    .ql-toolbar,
                    .ql-container {
                      background-color: $cardDarkBackgroundColor;
                      border-radius: 0;
                    }
                  }
                }
              }
            }
          }
          .datasetsContainer {
            .ant-checkbox-wrapper {
              background-color: $inputModalDark;
            }
          }
        }
      }
    }
  }
}

// .cardLayoutModal {
//   color: #323338;

//   .ant-modal {
//     width: unset !important;
//   }

// }

@media (max-width: 1100px) {
  .newReportModalContent {
    .datasetsContainer {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media (max-width: 750px) {
  .newReportModalContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    .datasetsContainer {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

@media (max-width: 400px) {
  .cardLayoutModal {
    .ant-modal-title {
      font-size: 14px;
    }
    .newDegVersionModalContent .versionInfoForm .formInputs {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}
