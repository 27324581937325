.communication-item-view-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    padding: 12px;
    // cursor: pointer;
    border-bottom: 1px solid #ebebeb;

    &.unread {
        background-color: #f4f7ff;
    }

    p {
        margin: 0;
        padding: 0;
    }

    .email-icon-container {
        display: flex;
        align-items: center;
    }

    .communication-item-view-content-container {
        display: flex;
        gap: 10px;

    }

    .created-at {
        text-wrap: nowrap;
    }

}