.tree-node-container {
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 10px;

  .vertical-line {
    position: relative;
    bottom: 11px;
    right: 10px;
    width: 1px;
    background-color: #333238;
  }

  .tree-element {
    width: 100%;
    position: relative;
    display: flex;
    gap: 10px;
    align-items: center;
    margin-top: 10px;
    .horizontal-line {
      position: absolute;
      left: -11px;
      width: 15px;
      border-top: 1px solid #333238;
    }
    .tree-element-content {
      width: 100%;
      display: flex;
      gap: 5px;
      margin-left: 10px;
    }
  }
}

.tree-node-container-dark {
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 10px;

  .horizontal-line {
    position: absolute;
    left: -11px;
    width: 15px;
    border-top: 1px solid white;
  }

  .vertical-line-dark {
    position: relative;
    bottom: 11px;
    right: 10px;
    width: 1px;
    background-color: #fff;
  }
  
  .tree-element {
    width: 100%;
    position: relative;
    display: flex;
    gap: 10px;
    align-items: center;
    margin-top: 10px;

    .tree-element-content {
      width: 100%;
      display: flex;
      margin-left: 10px;
      gap: 5px;
    }
  }
}
