@import "../../../../../../../../../../index.scss";

//light mode header style
.serviceListServiceHeaderHoistLight {
	background-color: #f8f8f8;
	color: #323338;
	height: 45px;
	display: flex;
	align-items: center;
	padding: 0 20px;
	gap: 15px;
	width: stretch;
	white-space: nowrap;

	.completeAllContainer {
		display: flex;
		color: #323338;

		.compleatedLabel {
			margin: -1px 12px 0 4px;
			font: normal normal 600 16px/22px Open Sans;
		}
	}

	.tittle {
		color: var(--unnamed-color-323338);
		text-align: left;
		font: normal normal 600 16px/22px Open Sans;
		letter-spacing: 0px;
		color: #323338;
		opacity: 1;
	}

	.headerSectionContainer {
		display: flex;
		margin-right: 5px;
		width: 280px;

		.label {
			color: var(--unnamed-color-323338);
			text-align: left;
			font: normal normal 600 16px/22px Open Sans;
			letter-spacing: 0px;
			color: black;
			opacity: 1;
			margin-right: 5px;
			width: fit-content;
			white-space: nowrap;
		}
		.amount-diff-from-0 {
			color: var(--unnamed-color-323338);
			text-align: left;
			font: normal normal 600 16px/22px Open Sans;
			letter-spacing: 0px;
			color: #71cf48;
			opacity: 1;
			width: stretch;
		}
		.amount-equal-with-0 {
			color: var(--unnamed-color-323338);
			text-align: left;
			font: normal normal 600 16px/22px Open Sans;
			letter-spacing: 0px;
			color: #eb4345;
			opacity: 1;
			margin-right: 10px;
		}
	}
}

//dark mode header style
.serviceListServiceHeaderHoistDark {
	color: white;
	height: 45px;
	display: flex;
	align-items: center;
	padding: 0 20px;
	gap: 15px;
	width: stretch;
	white-space: nowrap;

	.rightContainer {
		display: flex;
		color: white;
		width: 100%;
		justify-content: flex-end;
	}

	.completeAllContainer {
		display: flex;
		color: #f5f5f5;
		.compleatedLabel {
			margin: -1px 12px 0 4px;
			font: normal normal 600 16px/22px Open Sans;
		}
	}

	.tittle {
		color: var(--unnamed-color-323338);
		text-align: left;
		font: normal normal 600 16px/22px Open Sans;
		letter-spacing: 0px;
		color: #f5f5f5;
		opacity: 1;
	}

	.detailsApplicationButton {
		@include mondayButton(#1264a3, #0f5c97, #fff);
	}

	.headerSectionContainer {
		display: flex;
		margin-right: 5px;
		min-width: 230px;

		.label {
			color: var(--unnamed-color-323338);
			text-align: left;
			font: normal normal 600 16px/22px Open Sans;
			letter-spacing: 0px;
			color: white;
			opacity: 1;
			margin-right: 5px;
			width: fit-content;
			white-space: nowrap;
		}
		.amount-diff-from-0 {
			color: var(--unnamed-color-323338);
			text-align: left;
			font: normal normal 600 16px/22px Open Sans;
			letter-spacing: 0px;
			color: #71cf48;
			opacity: 1;
			width: stretch;
		}
		.amount-equal-with-0 {
			color: var(--unnamed-color-323338);
			text-align: left;
			font: normal normal 600 16px/22px Open Sans;
			letter-spacing: 0px;
			color: #eb4345;
			opacity: 1;
			margin-right: 10px;
		}
	}
}
