.moreChargeServices {
  display: flex;
  gap: 10px;
  flex-direction: column;
  width: 100%;
  align-items: center;

  .ant-design-button-services {
    margin-top: -10px;
    margin-bottom: 10px;
    margin-left: -10px;
    justify-content: center;
    width: 70% !important;
  }
}
