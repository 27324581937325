$mainDarkBackgroundColor: #20212d;
$cardDarkHeaderColor: #2a2b3a;
$cardDarkBackgroundColor: #12131b;
$inputDarkColor: #2a2b3a;

.fleetMaintenanceTabs {
  margin-left: -20px;
  padding: 0px 10px;
  transform: translateX(10px);
  background-color: #fff;
  height: 50px;
  padding-left: 40px;
  width: 100%;
  border-bottom: 2px solid #c3c6c9;
  position: sticky;
  top: 0px;
  z-index: 2;

  .ant-breadcrumb {
    height: 100%;
    ol {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: row;
      flex-wrap: nowrap;
      width: 100%;

      overflow-x: auto;
      overflow-y: hidden;
      @for $i from 1 through 30 {
        :nth-child(#{$i}) {
          animation: tabs calc(1s + $i * 0.2s - 0.2s);
        }
      }
    }
    ol,
    li {
      height: 100%;
      .ant-breadcrumb-link {
        display: flex;
        height: calc(100% - 2px);
        align-items: center;
        white-space: nowrap;
      }
    }
    .ant-breadcrumb-separator {
      display: none;
    }
    .breadcrumbItem {
      display: flex;
      align-items: center;
      height: 100%;
      color: #323338;
      font: 600 normal 18px Open Sans;
      transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      cursor: pointer;
      margin-right: 30px;
      transform: translateY(2px);
    }
    .active {
      color: #1264a3;
      border-bottom: 2px solid #000;
    }
  }
}

.fleetMaintenanceTabsDark {
  background-color: $mainDarkBackgroundColor;
  border-bottom: 2px solid $cardDarkBackgroundColor;
  .ant-breadcrumb {
    .breadcrumbItem:not(.active) {
      color: #fff;
    }
    .active {
      border-bottom: 2px solid #f2f2f2;
    }
  }
}

@keyframes tabs {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
