.waiting-room-proposal {
  width: 100%;
  height: 100vh;
  display: flex;
  height: 100dvh;
  overflow-y: hidden;
  flex-direction: column;
  background-color: #f4f4f4;
  .waiting-room-proposal_body {
    flex: 1;
    gap: 10px;
    padding: 10px;
    display: flex;
    overflow-y: hidden;
    flex-direction: column;
    .waiting-room-proposal_body-cards {
      flex: 1;
      overflow-y: hidden;
      .card-template-body {
        flex: 1;
        gap: 10px;
        padding: 20px;
        display: grid;
        overflow-y: auto !important;
        grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
      }
    }
  }
}
.waiting-room-proposal.dark {
  .waiting-room-proposal_body {
    background-color: #2a2b3a;
    .waiting-room-proposal_body-cards {
      .card-template-title {
        background-color: #12131b;
      }
      .card-template-body {
        background-color: #20212d;
      }
    }
  }
}
