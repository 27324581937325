$controller-height: 32px;

.inspection-report-container {
  .card-body {
    display: grid;
    grid-template-rows: $controller-height calc(
        100% - $controller-height - 20px
      );
    gap: 20px;

    .report-controller-section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      flex-wrap: nowrap;
      gap: 10px;
      overflow-y: hidden;
      overflow-x: auto;
      width: 100%;
      padding: 20px 0;

      .ant-radio-group {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
        flex-wrap: nowrap;
        width: 50%;
        width: fit-content;

        .ant-radio-button-wrapper:hover {
          color: rgba(0, 0, 0, 0.5);
        }

        .ant-radio-button-wrapper-checked {
          border-color: transparent;
          background: #fff;

          &::before {
            background: #fff;
          }
        }
      }
    }

    .report-items {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      max-height: 100%;
      overflow-y: auto;
      flex-direction: column;
    }

    .ant-list {
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;

      .ant-list-item {
        border-radius: 5px;
        padding: 10px;
        background-color: #f5f5f7;
      }
    }
  }
}

.inspection-report-container.grid-card-dark {
  .labeledInputContainer {
    input::placeholder {
      color: #acacca;
    }
  }

  .report-controller-section {
    .ant-radio-group {
      .ant-radio-button-wrapper {
        color: #acacac;
        background: #3a3c4e;
        border: 1px solid #acacac;

        &::before {
          background-color: #acacac;
        }
      }

      .ant-radio-button-wrapper:hover {
        color: #acacacaa;
      }

      .ant-radio-button-wrapper-checked {
        border-color: transparent;
        background: #3a3c4e;

        &::before {
          background: #3a3c4e;
        }
      }
    }
  }

  .ant-list {
    .ant-list-item {
      color: #fff;
      border-radius: 5px;
      padding: 10px;
      background-color: #20212d;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
