$mainDarkBackgroundColor: #20212d;
$cardDarkHeaderColor: #2a2b3a;
$cardDarkBackgroundColor: #12131b;
$inputDarkColor: #2a2b3a;

.card-template {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  background: #ffffff;
  border-radius: 10px;
  flex: 1;
  width: 100%;
  box-shadow: 0px 1px 4px #00000029;

  .card-template-title {
    height: 45px;
    border-radius: 10px 10px 0 0;
    font-size: 16px;
    // font-size: calc(1vw + 1vh);
    color: #1f2a44;
    font-weight: 600;
    background-color: #f7f8fa;
    padding: 10px 20px;
    width: 100%;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .card-template-body {
    width: 100%;
    flex: 1;
    padding: 20px 0 0 0;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
}

.card-template-dark {
  background-color: $cardDarkBackgroundColor;
  .card-template-title {
    color: #fff !important;
    background-color: $cardDarkHeaderColor;
    span {
      color: #fff;
    }
  }
}
