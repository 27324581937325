$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.newDocumentationModal {
  .documentation-modal-content {
    display: flex;
    flex-direction: column;
    width: 500px;
    .loader {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 24px;
    }
  }
  .original-cards {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-y: auto;
    overflow-x: hidden;
    .original-card-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      background-color: #f5f5f7;
      border-radius: 5px;
      padding: 5px 10px;
      cursor: pointer;
      transition: 0.3s ease-out;
      &.selected {
        background-color: #e6e9ef;
      }
      .card-name {
        .doc-title {
          font-weight: 600;
        }
      }
      .doc-status {
        height: fit-content;
        border-radius: 5px;
        color: #fff;
        padding: 3px 10px;
      }
    }
  }
  .ant-modal-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    .mondayButtonStyle {
      width: 150px;
    }
  }
}

.newDocumentationModalDark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;
      }
    }
    .documentation-modal-content {
      .labeledInputLabel {
        color: #fff;
      }
      .ant-select {
        .ant-select-selector {
          background-color: $inputModalDark !important;
          .ant-select-selection-search-input {
            color: #fff;
          }
          .ant-select-selection-item {
            color: #fff;
          }
          .ant-select-selection-placeholder {
            color: #acacca;
          }
        }
        .ant-select-clear {
          background-color: $inputModalDark;
          svg {
            fill: #acacca;
          }
        }
        .ant-select-arrow svg {
          fill: #fff;
        }
      }
      .ant-radio-group {
        .ant-radio-wrapper span {
          color: #fff;
        }
      }
    }
  }
  .original-cards {
    .original-card-container {
      background-color: #2a2b3a;
      &.selected {
        background-color: $inputModalDark;
      }
      .card-name {
        .doc-created,
        .doc-title {
          color: #fff;
        }
      }
    }
  }
}

@media (max-width: 555px) {
  .newDocumentationModal .documentation-modal-content {
    width: 400px;
  }
}

@media (max-width: 460px) {
  .newDocumentationModal .documentation-modal-content {
    width: 300px;
  }
}
