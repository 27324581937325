.ant-modal.progress-track-modal {
  width: 100vw !important;
  height: 100vh !important;
  max-width: 100vw !important;
  max-height: 100vh !important;
  margin: 0 !important;
  top: 0 !important;
  left: 0 !important;
  padding: 0 !important;
  overflow: hidden;
  .progress-track-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    .progress-track-alert {
      width: 100% !important;
      padding: 5px 7px 5px 7px !important;
      .progress-track-alert-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .progress-track-alert-content-item {
          display: flex;
          gap: 5px;
          :nth-child(1) {
            font-weight: 600;
          }
        }
      }
    }
  }

  .ant-modal-content {
    width: 100vw !important;
    height: 100vh !important;
    display: flex;
    flex-direction: column;
    border-radius: 0 !important;
    box-shadow: none !important;
  }

  .ant-modal-header {
    display: flex;
    align-items: center;
    height: 45px;
    background-color: #f5f5f5;
  }
  .ant-modal-footer {
    display: flex;
    flex-shrink: 0;
    width: 100% !important;
    background-color: #f5f5f5;
  }

  .ant-modal-body {
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .ant-modal-mask {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
  }
}
