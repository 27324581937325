.sov-main-header-light {
  background: #1264a3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;

  .sov-main-header-options {
    display: flex;
    align-items: center;

    .sov-main-header-title {
      color: white;
      font-size: 18px;
      margin: 0 30px 0 15px;
    }
  }

  .darkModeSwitchContainer {
    margin-left: 20px;
  }

  .close-btn {
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}

.sov-main-header-dark {
  background: #12131b;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;

  .sov-main-header-options {
    display: flex;
    align-items: center;

    .sov-main-header-title {
      color: white;
      font-size: 18px;
      margin: 0 30px 0 15px;
    }

    .darkModeSwitchContainer {
      margin-left: 20px;
      .firstSquare {
        background: white;
      }
      .secondSquare {
        background: white;
      }
      .innerCircle {
        background: white;
      }
      .outerCircle {
        background: #2a2828;
      }
    }
  }

  .close-btn {
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}
