.user-access-writes-container {
  .card-template-body {
    gap: unset;
    .info-and-search {
      display: flex;
      gap: 20px;
      align-items: center;
      padding: 20px;
      .user-access-info-text {
        width: 61%;
      }
      .config-search {
        width: 38%;
        height: 32px;
      }
    }
    .user-access-list-header {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 0 20px 0 0;
      border-bottom: 1px solid #bbbfc7;

      div {
        font-size: 16px;
        font-weight: 600;
        color: #323338;
        width: 50%;
        padding-left: 20px;
        &:first-child {
          border-right: 2px solid #dddfe3;
          display: flex;
          align-items: center;
          height: 15px;
        }
      }
    }

    .user-access-list-body {
      // max-height: 570px;
      height: calc(100vh - 350px);
      overflow-y: auto;
      border-radius: 0 0 10px 10px;
    }
  }
}
