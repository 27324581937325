.pieContainer {
  display: flex;
  justify-content: center;

  .PieBodyStyle {
    width: 360px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 350px;
    margin-left: -10px;
    padding-top: 20px;
    padding-bottom: 20px;

    // .Pie {
    //   width: 100% !important;
    //   height: 100% !important;
    // }
  }
}
.toolTipAccGraph {
  width: 400px !important;
  display: flex;

  .ant-tooltip-arrow {
    display: none !important;
  }
  .ant-tooltip-inner {
    background: inherit;
    box-shadow: none;
    width: 100%;
    height: 100%;
  }
}

.pieContainerDark {
  .PieBodyStyle {
    color: #f2f2f2 !important;
    canvas {
      color: #f2f2f2 !important;
    }
    .ant-result-warning {
      .ant-result-title,
      .ant-result-subtitle {
        color: #f2f2f2;
      }
    }
  }
}
