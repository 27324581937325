@import "/src/index.scss";

.upload-employees-step {
  display: flex;
  flex-direction: column;
  gap: 10px;
  .upload-dragger-container {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }
  .labeledInputContainer {
    margin-top: 10px;
    .ant-form-item {
      margin-bottom: 10px;
    }
  }
  .uploadCompanyDiv {
    display: flex;
    align-items: center;
    width: 537px;
    height: 53px;
    gap: 30px;
    margin-top: 30px;
    margin-bottom: 20px;
    .uploadCompanySelect {
      width: 300px;
    }
    button.ant-btn.css-dev-only-do-not-override-1rkatjr,
    .ant-btn-default.mondayButtonStyle.false,
    .mondayButtonBlue.fingerCheckButton {
      background-color: transparent;
    }
    .uploadFingerCheckButton {
      display: flex;
      align-items: center;
      gap: 5px;
      padding-top: 25px;
      color: #1264a3;
      transition: .2s ease;
      .uploadFingerCheckText {
        font-size: 14px;
        font-weight: 400;
        height: 19px;
      }
      path {
        fill: #1264a3;
      }
    }
    .uploadFingerCheckButtonDiv {
      display: flex;
      align-items: center;
      gap: 5px;
      padding-top: 25px;
    }
  }
  .ant-upload-wrapper {
    height: 95px;
    .ant-upload {
      padding: 5px;
      animation: scopesFadeInBottom 500ms both;
      border-color: #323338;
      background-color: #fff;
    }
    .ant-upload-drag-container {
      .ant-upload-drag-div {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
      }
    }
  }
  .ant-file-preview {
    display: flex;
    align-items: center;
    width: 550px;
    height: 32px;
    background-color: #f8f8fa;
    border-radius: 5px;
    .ant-file-div {
      display: flex;
      width: 100%;
      margin-left: 10px;
      gap: 90px;
      .ant-file-name {
        display: flex;
        align-items: center;
      }
      .ant-file-data {
        display: flex;
        align-items: center;
        gap: 10px;
        .ant-file-size,
        .ant-file-column,
        .ant-file-entries {
          display: flex;
          gap: 5px;
        }
        .bolded-text {
          font-weight: 600;
        }
        .fileEl {
          display: flex;
          &::after {
            content: "";
            height: 15px;
            padding-right: 10px;
            align-self: center;
            border-right: 2px solid #dee2eb;
          }
        }
      }
    }
  }
}

.upload-employees-step-dark {
  .labeledInputLabel {
    color: #fff;
  }
  .uploadFingerCheckButtonDiv {
    color: #acacca;
    svg path {
      fill: #acacca;
    }
  }
  .ant-select {
    .ant-select-selector {
      background-color: $cardDarkBackgroundColor !important;
      .ant-select-selection-search-input {
        color: #fff !important;
      }
      .ant-select-selection-item {
        color: #fff;
      }
      .ant-select-selection-placeholder {
        color: #acacca;
      }
    }
    .ant-select-clear {
      background-color: $cardDarkBackgroundColor;
      svg {
        fill: #acacca;
      }
    }
    .ant-select-arrow svg {
      fill: #fff;
    }
  }
  .ant-upload-wrapper {
    .ant-upload-drag {
      border-color: #acacca;
    }
    .ant-upload {
      background-color: $cardDarkHeaderColor;
      .ant-upload-drag-div {
        color: #fff;
      }
      .ant-upload-drag-container {
        .ant-upload-drag-icon svg {
          fill: #fff;
        }
        .ant-upload-text {
          color: #fff;
        }
      }
      
    }
    .ant-upload-disabled {
      .ant-upload-drag-container {
        .ant-upload-drag-icon svg {
          fill: #acacca;
        }
        .ant-upload-text {
          color: #acacca;
        }
      }
    }
    .ant-upload-list-item-container {
      .ant-upload-list-item {
        .ant-upload-icon svg {
          fill: #fff;
        }
        .ant-upload-list-item-name {
          color: #fff;
        }
      }
    }
  }
  .ant-file-preview {
    background-color: $cardDarkBackgroundColor;
  }
}

@keyframes scopesFadeInBottom {
  from {
    opacity: 0;
    transform: translate3d(0, -80%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
