.applyCreditModal {
  min-width: 1400px;
  padding: 10px;
  height: 900px;
  overflow: hidden;
  .agGridCustomStyle {
    .applyAmount {
      background-color: #1264a3 !important;
      color: #fff !important;
      svg {
        g {
          stroke: #fff;
        }
        path {
          fill: white !important;
        }
      }
    }
  }
}
