@import "../../../../../../index.scss";

$headerDarkColor: #12131b;
$inputDarkColor: #2a2b3a;
$mainDarkBackgroundColor: #20212d;

.hidden-cards-drawer {
  .ant-drawer-content-wrapper {
    width: 455px !important;
  }

  .ant-drawer-header {
    height: 45px;
    border-bottom: 0;
    background-color: #1f2a44;

    .ant-drawer-header-title {
      .titleContainer {
        display: flex;
        justify-content: center;
        background-color: #1f2a44;
        color: white;
        font-weight: 600;
      }
      .ant-drawer-close {
        svg {
          width: 17px;
          height: 17px;
        }
      }
    }
  }

  .anticon-close {
    svg {
      width: 14px;
      height: 14px;
    }
  }

  .ant-drawer-body {
    padding: 0;
    overflow-y: hidden;
    overflow-x: auto;
    .hiddenCardsDashboard {
      margin: 10px 0;
      width: 100%;
      height: 26px;
      display: flex;

      &:hover {
        background-color: #f4f5f6;
        .hiddenDecorativeDiv {
          width: 10px;
          height: 26px;
          background-color: #1f2a44;
        }
      }

      .hiddenValue {
        display: flex;
        align-items: center;
        width: 455px;
        height: 26px;
        padding-left: 30px;
        font-size: 16px;
        font-weight: bold;
        mask: 100;
        @include textEllipsis();
      }
      .showButton {
        width: 26px;
        height: 26px;
        margin-right: 20px;
        background-color: rgba(0, 0, 0, 0);
        border: 0px;
        border-radius: 5px;
        padding: 0px;
      }
      .hiddenDecorativeDiv {
        width: 0px;
        height: 26px;
      }
    }

    .drawerHiddenZeroCards {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 1rem;
      margin-top: 270px;
      span {
        color: #323338;
      }
    }
  }

  .ant-drawer-footer {
    padding: 0;
    border-top: 0;
    padding-right: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 45px;
    background-color: #f7f8fa;

    .saveLayoutMondayButton {
      font-size: 16px;
      @include mondayButton(#71cf48, #68c142, #fff);
      svg {
        width: 17px;
        height: 20px;
      }
    }
  }

  &.dark {
    .ant-drawer-header {
      background-color: $headerDarkColor;
      .ant-drawer-title {
        background-color: $headerDarkColor;
      }
      .ant-drawer-header-title {
        .titleContainer {
          background-color: $headerDarkColor;
        }
      }
    }
    .ant-drawer-body {
      background-color: $inputDarkColor;
      .drawerHiddenZeroCards {
        span,
        strong {
          color: #f2f2f2;
        }
      }
      .hiddenCardsDashboard {
        transition: 0.2s ease-in;
        .hiddenValue {
          color: #f2f2f2;
        }
        button svg path {
          fill: #f2f2f2 !important;
        }
      }
      .hiddenCardsDashboard:hover {
        background-color: $mainDarkBackgroundColor;
        .hiddenDecorativeDiv {
          background-color: #12131b;
        }
      }
    }
    .ant-drawer-footer {
      background-color: $inputDarkColor;
    }
  }
}
