@import "../../../../../index.scss";

$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.fullScreenModal {
  height: 100%;
  width: 100%;
  .ant-modal-content {
    height: fit-content;
    // max-height: 90vh;
    // overflow-x: hidden;
    // overflow-y: auto;
    // transform: translateY(-5vh);
    border-radius: 10px;
    width: 100%;
    .ant-modal-close {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 45px;
    }
    .ant-modal-header {
      width: 100%;
      height: 45px;
      background: #f8f8fa;
      padding: 10px 20px;
      height: 45px;
      border-bottom: 0;
      border-radius: 10px 10px 0 0;
      .ant-modal-title {
        @include text(16px, 600, 0, #323338);
      }
    }
    .ant-modal-body {
      width: inherit;
      height: inherit;
      padding: 20px;
      border-radius: 10px;
      overflow-x: hidden;
      overflow-y: auto;
      .footerContainer {
        width: 100%;
        background-color: inherit;
        @include flex(center, flex-end);
        .cancelFooterButton {
          background-color: #fe4c4a;
          border: 0;
          border-radius: 5px;
          color: #fff;
          margin-right: 15px;
          &:hover {
            color: #fff;
            border: 0;
            background-color: #eb4345;
          }
        }

        .secondaryFooterButton {
          letter-spacing: 0.24px;
          color: #fff;
          border: 0;
          background-color: #1890ff;
          border-radius: 5px;
          margin-right: 15px;
        }

        .primaryFooterButton {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          font-family: "Open Sans";
          background-color: #1264a3;
          color: #fff;
          font-family: "Open Sans";
          border: 0;
          border-radius: 5px;
          &:hover {
            color: #fff;
            border: 0;
            background-color: #0f5c97;
          }
        }
        .disabledFooterButton {
          font-family: "Open Sans";
          border: 0;
          border-radius: 5px;
          margin-right: 1rem;
          span {
            margin-right: 0.5rem;
          }
        }
      }
    }
  }
}

.fullScreenModalDark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;
      }
    }
  }
}