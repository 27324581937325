@import "../../../../../../../index.scss";

$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.new-application-modal {
  * {
    animation-duration: 0s !important;
  }
  .ant-modal {
    width: fit-content !important;
  }
  .ant-modal-content {
    width: 918px;
    height: fit-content;
    border-radius: 10px;
    overflow: hidden;

    .ant-modal-body {
      height: calc(100% - 114px);
      padding: 0 !important;
      width: fit-content;

      .information1 {
        width: 100%;
        height: 30px;
        margin: 5px 15px;
        display: flex;
        align-items: center;
        font-size: 13px;
        // font-weight: bold;

        .ant-alert {
          border-radius: 10px;
          padding: 2px 15px;
        }
      }

      .container {
        display: flex;

        .rightSideContainer {
          width: fit-content;
        }
      }
    }

    .ant-modal-close-x {
      height: 45px;
      width: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .ant-modal-header {
      background-color: #f8f8fa;
      border-bottom: 0;
      height: 45px;
      display: flex;
      align-items: center;

      .ant-modal-title {
        height: fit-content;
        font-size: 16px;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
          Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
        width: 100%;
      }
    }
    .ant-modal-footer {
      display: flex;
      width: stretch;
      height: fit-content;
      padding: 0 !important;
      margin: 10px 0 0 0 !important;
      border-top: 0 !important;
      background-color: #fff;
    }
  }
}

.new-application-modal-dark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      .information1 {
        .ant-alert-warning {
          background-color: #7c6d1f8c;
          .ant-alert-content {
            color: #fff;
          }
        }
      }
      .container {
        .sovPickerContainer {
          box-shadow: 0px 1px 4px #ffffff94;
          background-color: $cardModalHeaderDark;
          .title {
            color: #fff;
          }
          .ant-radio-wrapper span {
            color: #fff;
          }
        }
        .rightSideContainer {
          .dateTypePickerContainer {
            box-shadow: 0px 1px 4px #ffffff94;
            background-color: $cardModalHeaderDark;
            .ant-picker {
              background-color: $inputModalDark !important;
              border: none;
              .ant-picker-input {
                background-color: $inputModalDark;
                input {
                  color: #f2f2f2;
                  &::placeholder {
                    color: #acacca;
                  }
                }
                .ant-picker-suffix svg {
                  color: #acacca;
                }
                .ant-picker-clear {
                  background-color: $inputModalDark;
                  svg {
                    fill: #acacca;
                  }
                }
              }
            }
            .ant-picker-disabled {
              .ant-picker-input input {
                color: #acacca !important;
              }
            }
          }
          .rangePickerContainer {
            .calendarContainer {
              box-shadow: 0px 1px 4px #ffffff94;
              background-color: $cardModalHeaderDark;
              border: none;
              .ant-picker-calendar {
                background-color: $cardModalHeaderDark;
                .headerSelect {
                  .ant-select {
                    .ant-select-selector {
                      background-color: $cardModalBodyDark !important;
                      border: none;
                      .ant-select-selection-search-input {
                        color: #fff;
                      }
                      .ant-select-selection-item {
                        color: #fff;
                      }
                      .ant-select-selection-placeholder {
                        color: #acacca;
                      }
                    }
                    .ant-select-clear {
                      background-color: $cardModalBodyDark;
                      svg {
                        fill: #acacca;
                      }
                    }
                    .ant-select-arrow svg {
                      fill: #fff;
                    }
                  }
                  //Disabled
                  .ant-select-disabled {
                    .ant-select-selector {
                      .ant-select-selection-search-input {
                        color: #acacca !important;
                      }
                      .ant-select-selection-item {
                        color: #acacca !important;
                      }
                    }
                  }
                }
                .ant-picker-panel {
                  background-color: $cardModalBodyDark;
                  border-top: 1px solid #acacca;
                  .ant-picker-cell {
                    color: #fff;
                  }
                  .ant-picker-cell-disabled {
                    color: #acacca;
                    &::before {
                      background-color: #00000049;
                    }
                  }
                }
              }
            }
          }
          .rangeInformation {
            box-shadow: 0px 1px 4px #ffffff94;
            background-color: $cardModalHeaderDark;
            .applicationInformation,
            .period {
              color: #fff;
            }
          }
        }
      }
    }
    .ant-modal-footer .newApplicationFooterContainer {
      background-color: $cardModalBodyDark;
    }
  }
}

.logout-warning-modal {
  z-index: 3071 !important;
}

@media (max-width: 980px) {
  .new-application-modal {
    .ant-modal-content {
      max-width: calc(100vw - 100px) !important;
      width: fit-content !important;
      .ant-modal-body {
        max-height: calc(100vh - 200px) !important;
        height: fit-content !important;
        overflow: scroll !important;
        width: 100%;
        .information1 {
          width: 98% !important;
          text-wrap: wrap;
        }
        .container {
          flex-direction: column;
          padding: 10px 0 0 0;
          gap: 10px;
          .rightSideContainer,
          .sovPickerContainer {
            width: 100% !important;
          }
          .sovPickerContainer {
            height: auto;
          }
          .rightSideContainer {
            margin-left: 0px !important;
            .dateTypePickerContainer {
              margin-left: 0px !important;
              width: 100% !important;
            }
            .rangePickerContainer {
              justify-content: center !important;
              padding-bottom: 10px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 700px) {
  .new-application-modal .ant-modal-content {
    max-width: calc(100vw - 30px) !important;
    width: fit-content !important;
    .ant-modal-body .container .rightSideContainer .dateTypePickerContainer {
      justify-content: center !important;
      padding: 0 !important;
    }
    .rangePickerContainer {
      flex-direction: column !important;
      gap: 10px;
      width: fit-content;
    }
  }

  .newApplicationCalendarStyle {
    .ant-picker-panels {
      flex-direction: column !important;
      max-height: 450px !important;
      overflow: scroll !important;
    }
  }
}
