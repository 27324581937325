.live-locations-view {
  height: calc(100vh - 56px);
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 10px;

  .fleet-name-status {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .name-dot {
      height: 0.8rem;
      width: 0.8rem;
      border-radius: 50%;
    }

    .name-dot-pending {
      animation: pendingDot 1.5s ease-in-out forwards infinite;
    }

    .name-dot-hold {
      background-color: #f64c4a;
    }
  }

  .ant-switch-checked {
    background: #68c142 !important;
  }

  .writeReadSwitchContainer {
    background-color: #1264a3;
    width: 140px;
    height: 23px;
    font-size: 13px;

    .ant-switch-handle {
      padding: 2px;
      background-color: white;
      border-radius: 9px;
    }

    .ant-switch-inner-checked {
      font-size: 13px !important;
    }
    .ant-switch-inner-unchecked {
      font-size: 13px !important;
    }
  }

  .live-grid-status {
    width: 100%;
    height: 93%;
    max-width: 200px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 550;
    letter-spacing: 0.28px;
    border-radius: 5px;
  }

  .layout-children-container {
    max-height: calc(100vh - 56px - 48px - 3rem);
  }

  height: 100%;
  width: 100%;

  .ag-root-wrapper {
    height: 100%;
    max-height: 100%;
    width: 100%;
    max-width: 100%;

    .ag-root-wrapper-body.ag-layout-normal {
      height: calc(100% - 100px) !important;
    }

    .ag-header {
      position: sticky;
      top: 0;

      .ag-header-cell {
        background-color: #f8f8fa !important;
      }
    }
  }

  .job-type-editor-field {
    .ant-select {
      width: 100%;
    }
  }
}

.live-locations-view.live-locations-dark {
  background-color: #12131b;

  .writeReadSwitchContainer {
    background-color: #292b3a;
    width: 140px;
    height: 23px;
  }

  .ag-root-wrapper {
    background-color: #20212d;
    border-radius: 10px;

    .ag-column-drop {
      background-color: #20212d;
    }
    .ag-header {
      .ag-header-cell {
        background-color: #12131b !important;
      }
      .ag-header-cell-resize::after {
        width: 1px !important;
        background-color: #4a4a4a !important;
      }
    }
  }
  .ag-paging-panel {
    background-color: #20212d;
  }

  .ant-checkbox-disabled.ant-checkbox-checked {
    .ant-checkbox-inner {
      background: #616481;

      &::after {
        border-color: #fff;
      }
    }
  }

  .job-type-editor-field {
    .ant-select {
      .ant-select-clear {
        background: #12131b;
        color: #616481;
      }
    }
  }
}

.stop-status {
  width: 100%;
  max-width: 200px;
  border-radius: 5px;
  display: grid;
  place-content: center;
  padding: 3px 7px;
  color: #fff;
  height: 80%;
  font-weight: 500;
  overflow: hidden;
}

.stop-status.stop-idling {
  background-color: #f6cb52;
}

.stop-status.stop-off {
  background-color: #fe4c4a;
}

@keyframes pendingDot {
  0% {
    background-color: rgba(0, 128, 0, 0.444);
  }
  25% {
    background-color: green;
  }
  75% {
    background-color: green;
  }
  100% {
    background-color: rgba(0, 128, 0, 0.444);
  }
}
