// $cardModalHeaderDark: #1c1c1c;

$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;
.proposalStepper {
  height: 56px;
  padding: 10px 20px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15);
  .antCustomStepper {
    .ant-steps {
      overflow-y: hidden;
      .ant-steps-item-wait:not(:last-child)::before {
        border-left-color: #f8f8fa !important;
      }
      .ant-steps-item-active::before,
      .ant-steps-item-finish ::before,
      .ant-steps-item-process::before,
      .ant-steps-item::before {
        border-left-color: #f8f8fa !important;
      }
    }
  }
}
.new-proposal-modal-headerStepper {
  .choose_source_wrapper {
    gap: 20px;
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: center;
  }
}

// .proposalStepper.dark {
//   .antCustomStepper {
//     .ant-steps {
//       .ant-steps-item-finish ::before,
//       .ant-steps-item-active::before,
//       .ant-steps-item::before {
//         border-left-color: $cardModalBodyDark !important;
//       }
//       .ant-steps-item-wait {
//         background-color: $cardModalHeaderDark !important;
//         .ant-steps-item-content .ant-steps-item-title {
//           color: #acacca;
//         }
//         &::after {
//           border-left: 18px solid $cardModalHeaderDark !important;
//         }
//       }
//     }
//   }
// }
