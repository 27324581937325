@import "../../../../../index.scss";
@import "../../Communication.scss";

.customFilterComponent {
  display: flex;
  flex-direction: column;
  height: fit-content;
  position: absolute;
  width: 450px;
  padding: 10px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  animation: customFilterOpen 0.5s ease;
  z-index: 1;
  overflow: hidden;



  @media screen and (max-width: 1440px) {
    max-width: 300px;
  }

  @media screen and (max-width: 1024px) {
    right: 1%;
  }

  &.mobile {
    right: 0;
    margin: 0 10px;
  }

  .customFilterContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    // height: fit-content;
    // display: grid;
    // grid-template-columns: 100px auto;
    // grid-template-rows: 40px 40px 40px 40px;
    // margin-bottom: 20px;

    .ant-form-item {
      margin-bottom: 0px;
    }

    .ant-input {
      background-color: #f5f5f7 !important;
    }

    .emailBoxDate {
      margin-bottom: 40px !important;
    }

    .from-to-container {
      display: flex;
      align-items: center;
      gap: 10px;

      @media screen and (max-width: 1440px) {
        flex-direction: column;
      }

      .from-input-container,
      .to-input-container,
      .subject-container,
      .range-picker-container {
        display: flex;
        flex-direction: column;
        gap: 2px;
        width: 100%;
      }

      .range-picker-container {
        max-width: 275px;
      }
    }

    .LabelCustomFilterStyle {
      -webkit-font-smoothing: antialiased;
      font-size: 16px;
      letter-spacing: 0.2px;
      font-weight: 600;
      color: #000;
      display: flex;
      user-select: none;
      align-items: flex-end;
      padding-left: 10px;
    }

    .emailBoxSubject {
      background-color: #f5f5f7 !important;
      border-radius: 5px;
    }

    .emailBoxSubject:focus {
      box-shadow: none;
      border-color: #cfcfcf;
      border-width: 0px 0px 1px 0px !important;
    }

    .emailBoxSubject:hover {
      box-shadow: none;
      border-color: #cfcfcf;
      border-width: 0px 0px 1px 0px !important;
    }

    .emailBoxDate {
      position: relative;
      width: 100%;
      margin: 0;
      height: 40px;
      border-radius: 0px;
      border-color: #cfcfcf;
      border-width: 0px 0px 1px 0px !important;

      input {
        user-select: none;
        border: 0px !important;
      }

      .ant-picker {
        border: 0px;
      }
    }

    .emailBoxDate:focus {
      box-shadow: none;
      border-color: #cfcfcf;
      border-width: 0px 0px 1px 0px !important;
    }

    .emailBoxDate:hover {
      box-shadow: none;
      border-color: #cfcfcf;
      border-width: 0px 0px 1px 0px !important;
    }

    .hasAttachmentsStyle {
      margin-top: 10px;
      user-select: none;

      .configElementContainer {
        min-width: 410px;
      }
    }
  }

  .CustomFilterFooter {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    width: 100%;

    .SearchStyle {
      background-color: $main-light-background !important;
      width: 150px;

      @media screen and (max-width: 1440px) {
        width: 130px;
      }

      .mondayButtonDivider {
        background-color: #0f5c97 !important;
      }

      &:hover {
        background-color: rgba($main-light-background, 0.9) !important;

        .mondayButtonDivider {
          background-color: rgba(#0f5c97, 0.9) !important;
        }
      }
    }

    .ClearStyle {
      width: 150px;
      @include mondayButton(#fe4c4a, #e6e9ef, #fff);

      &:hover {
        background-color: rgba(#fe4c4a, 0.9) !important;
      }

      svg>path {
        fill: #f5f5f7 !important;
      }

      .mondayButtonDivider {
        background-color: #eb4345 !important;
      }

      @media screen and (max-width: 1440px) {
        width: 130px;
      }

      &:disabled {
        @include mondayButton(#e6e9ef, #f5f5f7, #000);
        color: #f5f5f7 !important;

        &:hover {
          background-color: #e6e9ef !important;

          .mondayButtonDivider {
            background-color: #f5f5f7 !important;
          }
        }

        svg>path {
          fill: #f5f5f7 !important;
        }
      }
    }
  }

  @keyframes customFilterOpen {
    0% {
      height: 0;
      opacity: 0;
    }

    100% {
      height: 250px;
      opacity: 1;
    }
  }

  @keyframes customFilterClose {
    0% {
      height: 250px;
    }

    100% {
      height: 0;
    }
  }

  &.dark-mode {

    .ant-input,
    .emailBoxSubject {
      background-color: $cardDarkBackgroundColor !important;

      .ant-input::placeholder {
        color: #acacca !important;
      }
    }

    .ClearStyle {
      &:disabled {
        background-color: rgba($cardDarkBackgroundColor, 0.9) !important;
        color: #f5f5f7 !important;

        &:hover {
          background-color: rgba($cardDarkBackgroundColor, 0.8) !important;

          .mondayButtonDivider {
            background-color: #f5f5f7 !important;
          }
        }
      }
    }
  }
}