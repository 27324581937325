.workOrderCardItemsContainer {
  width: calc(100% + 40px) !important;
  transform: translateX(-30px);
  max-height: 600px;
  overflow-x: hidden;
  overflow-y: auto;

  .ant-table-thead {
    .ant-table-cell {
      font-weight: 550;
    }
  }

  .noItems {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
    font-weight: 500;
    font-size: 1.1rem;
    letter-spacing: 0.24px;
  }
}
