// .schedule-of-value-view {
//   position: fixed;
//   top: 0;
//   left: 0;
//   // height: 100vh;
//   // max-height: 100vh;
//   width: 100vw;
//   display: flex;
//   flex-direction: column;
//   background: #f8f8f8;
//   z-index: 10;

//   .schedule-of-value-view-body {
//     overflow-y: auto;

//     .scheduleOfValuesMainBodyContainer {
//       min-height: calc(100vh - 135px);
//       height: fit-content;
//       padding: 0 20px;
//       display: flex;
//       gap: 50px;
//     }
//     &__service-list {
//       overflow-y: auto;
//       width: 100%;
//       height: calc(100vh - 80px);
//       padding: 5px;
//     }
//     &__header-container {
//       // background-color: red;
//       position: sticky;
//       top: 0;
//       z-index: 100;
//       // background-color: #1f212d;
//     }
//   }
// }
.schedule-of-value-view {
  position: fixed;
  top: 0;
  left: 0;
  // height: 100vh;
  // max-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  background: #f8f8f8;
  z-index: 10;

  .schedule-of-value-view-body {
    overflow-y: auto;
    .scheduleOfValuesMainBodyContainer {
      min-height: calc(100vh - 135px);
      height: fit-content;
      padding: 0 20px;
      margin-bottom: 100px;
      display: flex;
      gap: 10px;
      &__service-list {
        overflow-y: auto;
        width: 100%;
        height: calc(100vh - 150px);
        padding: 5px;
      }
    }
    &__header-container {
      // background-color: red;
      position: sticky;
      top: 0;
      z-index: 100;
      // background-color: #1f212d;
    }
  }
}
.schedule-of-value-view-dark {
  position: fixed;
  top: 0;
  left: 0;
  // height: 100vh;
  // max-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  background: #1f212d;
  z-index: 10;
  height: fit-content;
  .schedule-of-value-view-body {
    overflow-y: auto;
    .scheduleOfValuesMainBodyContainer {
      // height: calc(100vh - 200px);
      // height: fit-content;
      padding: 0 20px;
      display: flex;
      gap: 10px;
      &__service-list {
        overflow-y: auto;
        width: 100%;
        height: calc(100vh - 150px);
        padding: 5px;
      }
      &__service-menu {
        overflow-y: scroll;
        // width: 100%;
      }
    }
    &__header-container {
      // background-color: red;
      position: sticky;
      top: 0;
      z-index: 100;
      // background-color: #1f212d;
    }
  }
}

.schOfVal-task-btn-dark {
  background-color: rgb(18, 100, 163) !important;
  border-color: rgb(18, 100, 163) !important;
  color: #fff !important;
  border-radius: 5px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0;
  padding-right: 0;
  span {
    width: 21px;
    height: 21px;
    margin-right: -1px;
  }
  &:hover {
    background-color: #0d4c7c !important;
    border-color: #0d4c7c !important;
    transition: 0.2s;
  }
}

.schOfVal-task-btn-dark,
.notes-icon {
  margin-right: 18px;
  width: 32px;
  height: 32px;
}

.schOfVal-tasks-modal-dark {
  .ant-modal {
    .titleCompBasePage {
      display: none;
    }
    .ant-modal-content {
      max-width: none !important;
      width: 80% !important;
      background-color: #12131b;
      border-radius: 20px;
    }
    .ant-modal-close-x {
      color: #fff;
    }
    .ant-modal-header {
      background-color: #12131b;
      border-bottom: none;
      display: flex;
      justify-content: flex-start;
      height: 55px;
      align-items: center;
      .ant-modal-title {
        font-weight: 500;
        font-size: 1.5rem;
        color: #f8f8fa;
        font-family: "Helvetica";
      }
    }

    .ant-modal-body {
      background-color: #1f212d;
      padding-bottom: 24px;
      min-height: 250px;
      height: 100%;
    }
    .filterContainers {
      margin-top: 0;
    }
    .basePageModalFooter {
      border-top: none;
      background-color: #1f212d;
      .secondaryFooterButton {
        border-color: rgb(18, 100, 163);
        background-color: rgb(18, 100, 163);
        color: #fff;
      }
    }
    .agGridContainer {
      .ag-root.ag-layout-normal {
        min-height: 300px !important;
      }
    }
  }
}
.application-tasks-modal-light,
.schOfVal-tasks-modal-light {
  .ant-modal {
    .titleCompBasePage {
      display: none;
    }
    .ant-modal-content {
      max-width: none !important;
      width: 80% !important;
    }
    // .ant-modal-header {
    //   border-bottom: none;
    //   display: flex;
    //   justify-content: flex-start;
    //   align-items: center;
    //   .ant-modal-title {
    //     font-weight: 500;
    //     font-size: 1.5rem;
    //     // color: #f8f8fa;
    //     font-family: "Helvetica";
    //   }
    // }
    // .ant-modal-content {
    //   border-radius: 20px;
    // }
    // .ant-modal-body {
    //   padding-bottom: 24px;
    //   min-height: 250px;
    // }
    // .filterContainers {
    //   margin-top: 0;
    // }
    // .basePageModalFooter {
    //   border-top: none;
    //   .secondaryFooterButton {
    //     border-color: rgb(18, 100, 163);
    //     background-color: rgb(18, 100, 163);
    //     color: #fff;
    //   }
    // }
  }
}
