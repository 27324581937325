.notification-cards {
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  gap: 20px;
  width: 100%;

  .topicsContainer {
    height: 100%;
    width: 50%;
    box-shadow: unset;
    border-radius: 5px;
    .topicsContent {
      max-height: 100%;
    }
  }
  .notification-actions {
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    animation: scopesFadeInRight 500ms both;
    background-color: #f8f8fa;
    padding: 10px;
    border-radius: 5px;
    transition: background-color 0.2s ease-in;

    .labeledInputContainer {
      .ant-form-item {
        margin-bottom: 10px;
      }
      .ant-input-affix-wrapper {
        background: #fff !important;
        .ant-input {
          background: #fff !important;
        }
      }
    }

    .notification-actions-buttons {
      margin: 10px 0;
      display: flex;
      gap: 30px;
      justify-content: space-between;
      .btn {
        text-wrap: nowrap;
        &:hover {
          font-weight: 600;
          cursor: pointer;
          &:after {
            content: "";
            position: absolute;
            background-size: cover;
            transition: opacity 0.3s ease;
          }
        }
      }

      .btn-disable {
        position: relative;

        &:hover {
          color: #fe4c4a;
          &:after {
            width: 10px;
            height: 11px;
            top: 6px;
            right: -15px;
            background-image: url("./icons/RedCloseIcon.svg");
          }
        }
      }
      .btn-enable {
        position: relative;

        &:hover {
          color: #1264a3;
          &:after {
            width: 12px;
            height: 11px;
            top: 4px;
            right: -18px;
            background-image: url("./icons/BlueTickIcon.svg");
          }
        }
      }
    }
  }

  &.dark-mode {
    .notification-actions {
      background-color: #2a2b3a;

      .labeledInputContainer {
        .ant-input-affix-wrapper {
          background: #12131b !important;
          .ant-input {
            background: inherit !important;
          }
        }
      }

      .notification-actions-buttons {
        .btn {
          color: #ffffff;
        }
        .btn-disable {
          &:hover {
            color: #fe4c4a;
          }
        }
        .btn-enable {
          &:hover {
            color: #1264a3;
          }
        }
      }
    }
  }
}
