$cardDarkBackgroundColor: #12131b;

.tasks-base-page {
  padding: 10px;
  height: 100%;
  .dynamicBasePage {
    padding: 0;
  }
}

.tasks-base-page-dark {
  background-color: $cardDarkBackgroundColor;
}
