$headerDarkColor: #12131b;
$inputDarkColor: #2a2b3a;
$mainDarkBackgroundColor: #20212d;

.tasksDrawer {
  .ant-drawer-content {
    .ant-drawer-header {
      background-color: #fff;

      .ant-drawer-header-title {
        .ant-drawer-close svg {
          fill: black;
          height: 17px;
          width: 17px;
        }

        .ant-drawer-title {
          color: black;
          font-size: 16px;
        }
      }
    }

    .ant-drawer-body {
      @mixin inner_actions {
        width: auto;
        display: flex;
        align-items: center;
        color: #fff;
        padding-right: 5px;
        border-radius: 5px;
      }

      .loggedTaskHeader {
        display: flex;
        flex-direction: column;
        align-items: center;

        .informationText {
          display: flex;
          align-items: center;
          width: 100%;

          .informationIcon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 20px;
            height: 20px;
            background-color: #1264a3;
            border-radius: 50%;
            color: #fff;
            margin-right: 10px;
          }
        }

        // justify-content: space-between;
        .headerSearch {
          background-color: #f5f5f5;
          display: flex;
          border-radius: 5px;
          width: 100%;
          height: 32px;
        }

        .iconSearch {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .searchInput {
          background-color: #f5f5f5;
          border: none;
          border-radius: 5px;
          margin-left: 0px;
        }

        input.ant-input {
          background-color: #f5f5f5;
        }
      }

      .loggedIn_task {
        padding: 0 5px;
        height: 46px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background-color: #f2f5fa;
        margin-top: 8px !important;
        border-radius: 5px;

        &:hover {
          cursor: pointer;
          filter: brightness(95%);
          transition: 0.3s;
        }

        &:first-child {
          margin-top: 0 !important;
        }

        h6 {
          margin-bottom: 0;
          width: 200px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 0.95rem;
          color: #323338;
        }

        .timer {
          @include inner_actions;
          height: 32px !important;
          background-color: #33b661;

          div {
            border-right: 1px solid #68c142;
            padding: 3px 10px;
            margin-right: 5px;
          }

          svg {
            height: 20px;

            path {
              fill: #fff;
            }
          }
        }
      }
    }
  }

  .no_logged_tasks {
    padding: 20px 60px 25px;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;

    h2 {
      color: #1f2a44;
      font-weight: 600;
      font-size: 16px;
      margin-top: 0.5rem;
    }

    h5 {
      color: #1f2a44;
      margin-bottom: 0.7rem;
      font-size: 1rem;
    }

    .mondayButtonContainer {
      .go-tasks-btn {
        background: #1264a3;
        border-radius: 5px;

        .mondayButtonDivider {
          background: #0f5c97;
        }

        &:hover {
          color: #fff;
          filter: brightness(1.3);
          transition: 0.3s;
        }

        &:focus {
          color: #fff;
        }
      }
    }

    .optionClassName {
      display: flex;
      width: 300px;
      height: 32px;
      align-items: center;
      border-radius: 5px;

      .dropDownLabel {
        font-family: "Open Sans";
        font-size: 16px;
        font-weight: 600;
        color: #fff;
        line-height: 19px;
      }
    }
  }

  .buttons button {
    // Use this styling only for this case not globaly
    width: 100px;
  }
}

.tasksDrawerDark {
  .ant-drawer-content-wrapper {
    .ant-drawer-content {
      .ant-drawer-header {
        background-color: $headerDarkColor;

        .ant-drawer-header-title {
          .ant-drawer-close svg {
            fill: #fff;
          }

          .ant-drawer-title {
            color: #fff;
          }
        }
      }

      .ant-drawer-body {
        background-color: $mainDarkBackgroundColor;

        .loggedTaskHeader {
          .informationText {
            color: #fff;
          }

          .headerSearch {
            background-color: $headerDarkColor !important;

            .searchInput {
              background-color: $headerDarkColor !important;

              .ant-input-prefix svg {
                fill: #fff;
              }

              .ant-input {
                background-color: $headerDarkColor !important;
                color: #fff;

                &::placeholder {
                  color: #acacca;
                }
              }

              .ant-input-suffix svg {
                fill: #acacca;
              }
            }
          }
        }

        .loggedIn_task {
          background-color: $inputDarkColor;

          h6 {
            color: #fff;
          }
        }
      }
    }

    .no_logged_tasks {
      h2 {
        color: #fff;
      }

      h5 {
        color: #fff;
      }
    }
  }
}
