@import "../../../../../../../../index.scss";

.servicesCard {
  $border-style: 1px solid #ececec;
  width: 100%;
  padding: 10px;
  border-top: $border-style;

  .labeledInputLabel {
    margin: 0;
  }

  &:last-child {
    border-bottom: $border-style;
  }

  .serviceLabel {
    @include text(18px, 400, 0, #000);
    text-align: center;
  }
}

.servicesStepCheckboxContainer {
  width: 100%;
  @include flex(center, center);

  .checkBoxes {
    width: 77%;
    @include flex(center, center);
    flex-wrap: wrap;
  }
}
