$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.upload-records-modal {
  width: fit-content !important;
  height: fit-content !important;
  max-width: 80vw;

  .ant-modal-content {
    overflow: hidden;
    .ant-modal-close {
      height: 45px;
      width: 45px;
      .ant-modal-close-x {
        height: 45px;
        width: 45px;
        display: flex;
        align-items: center;
      }
    }
    .ant-modal-header {
      height: 45px;
      display: flex;
      align-items: center;
      background-color: #f8f8fa;
    }
    .ant-modal-footer {
      margin-bottom: 20px;
      height: 50px;
      display: flex;
      justify-content: space-between;
      gap: 10px;
    }
  }

  .upload-modal-footer {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}

.upload-records-modal-dark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      .ant-steps {
        .ant-steps-item-finish ::before,
        .ant-steps-item-active::before,
        .ant-steps-item::before {
          border-left-color: $cardModalBodyDark;
        }
        .ant-steps-item-wait {
          background-color: $cardModalHeaderDark;
          &::after {
            border-left-color: $cardModalHeaderDark;
          }
          .ant-steps-item-content .ant-steps-item-title {
            color: #acacca;
          }
        }
      }
    }
  }
}
