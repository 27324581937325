.ActionContent {
  display: flex;
  flex-direction: column;
  padding: 8px 0px;
  margin: 0px;
  border-radius: 5px;
  .ActionElement:hover {
    background: var(--unnamed-color-1264a3) 0% 0% no-repeat padding-box;
    background: #1264a3 0% 0% no-repeat padding-box;
    color: #ffffff;
  }
  .ActionElement {
    cursor: pointer;
    height: 25px;
    padding: 2px 0 0 10px;
    color: #323338;
  }
}
