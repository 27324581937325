.service-rent-header-dark {
  color: white;
  width: 100%;
  height: 60px;
  background-color: #12131b;
  display: flex;
  align-items: center;
  border-radius: 5px;
  justify-content: space-between;
  margin-bottom: 20px;
  .left-container {
    display: flex;
    align-items: center;
    margin-left: 40px;
    // font-weight: bold;
    width: 80%;
    gap: 20px;
    .service-name {
      margin-right: 20px;
    }
    .information {
      font-size: 16px;
      color: white;
      display: flex;
      align-items: center;
      .currencyPercentageContainer {
        margin-left: 5px;
        color: #71cf48;
      }

      .currencyPercentageContainerZero {
        margin-left: 5px;
        color: #eb4345;
      }
    }
  }
  .right-container {
    width: 50%;
    display: flex;
  }
}

.service-rent-header-light {
  color: black;
  width: 100%;
  height: 60px;
  background-color: white;
  box-shadow: 3px 3px 5px rgb(0 0 0 / 16%);
  display: flex;
  align-items: center;
  border-radius: 5px;
  justify-content: space-between;
  margin-bottom: 20px;
  .left-container {
    display: flex;
    align-items: center;
    margin-left: 40px;
    // font-weight: bold;
    width: 80%;
    gap: 20px;
    .service-name {
      margin-right: 20px;
    }
    .information {
      font-size: 16px;
      color: black;
      display: flex;
      align-items: center;
      .currencyPercentageContainer {
        margin-left: 5px;
        color: #71cf48;
      }

      .currencyPercentageContainerZero {
        margin-left: 5px;
        color: #eb4345 !important;
      }
    }
  }
  .right-container {
    width: 50%;
    display: flex;
  }
}
