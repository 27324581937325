@import "../../../../../../../../index.scss";

.docViewActionButtons {
  width: 100%;
  @include flex(center, space-evenly);

  .leftButtonSection {
    @include flex(center, center);
  }

  .deleteButton {
    @include mondayButton(#fe4c4a, #eb4345);
    .mondayButtonIcon {
      width: 23px;
      filter: brightness(10);
    }
  }

  .logsButton {
    @include mondayButton(#1264a3, #0f5c97);
    .mondayButtonIcon {
      width: 18px;
    }
  }

  .cancelButton {
    @include mondayButton(#f5f5f7, #e6e9ef, #323338);
    // margin-left: 20px;
  }

  .saveButton {
    @include mondayButton(#71cf48, #68c142);
  }
}

.notEditing {
  justify-content: flex-end;
  .cancelButton {
    margin-right: 20px;
  }
}
