.inner-schedule {
  display: flex;
  flex-direction: column;
  // gap: 10px;

  // height: 115px;
  // background: #fff 0% 0% no-repeat padding-box !important;
  // border-radius: 10px 10px 0px 0px;
  opacity: 1;
  padding: 0px 10px 10px 5px;
  animation: days 0.5s;

  &:hover {
    background: #e6e9ef !important;
    cursor: pointer;
  }

  // &:nth-child(2) {
  //   background: #f5f5f5 !important;
  // }

  // &:nth-child(1) {
  //   background: red !important;
  // }

  .date-values-schedule {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 10px;
  }
  .bottom-structure-day-inner {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
}
.weather-component-popover {
  div {
    text-align: center;
    width: 100%;
  }
}

.delete-schedule-day-X {
  display: flex;
  justify-content: flex-end;
  padding-top: 5px;

  // animation: xIcon 0.2s;
  // display: none;
  // margin-left: -30px;
  // padding-bottom: 80px;
  // height: fit-content;
  // margin-bottom: -18px;
}

// .inner-schedule:nth-child(1) {
//   background: #f5f5f5 !important;
// }

// .inner-schedule:nth-child(2) {
//   background: red !important;
// }

// 772

// @keyframes days {
//   from {
//     opacity: 0;
//     filter: blur(2px);
//     transform: translateX(-50px);
//   }
//   to {
//     opacity: 1;
//     filter: blur(0);
//     transform: translateX(0px);
//   }
// }

.add-days-popover-shortcut {
  .ant-popover-inner {
    border-radius: 10px !important;
    animation: popAnim 0.5s;
  }

  .ant-popover-inner-content {
    padding: 15px !important;
  }
}
.add-days-popover-shortcutdark {
  .ant-popover-inner {
    border-radius: 10px !important;
    animation: popAnim 0.5s;
    background: #1a1a1a !important;
  }

  .ant-popover-inner-content {
    padding: 15px !important;
  }
}

@keyframes popAnim {
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}
