.automation-responses-container {
  padding: 10px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 0 10px;
  }
  .automation-responses {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow: auto;
    padding: 0 10px;

    .ant-alert {
      padding: 10px;
    }
  }
}
