$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;
.invoiceHistoryReportModal {
  // max-height: 80vh;
  overflow-y: auto;
  // // padding: 24px;
  // background-color: #f9fafb;
  // bporder-radius: 10px;
  /* Modal Title */
  //  background-color: #7c2020 !important;
  border-radius: 10px 10px 0 0;
  // .ant-modal {
  //   // max-width: 1000px;
  //   // width: 100%;
  //   // bporder-radius: 10px;
  //   // padding: 0;
  //   background-color: #7c2020 !important;
  //   // box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  // }

  .ant-modal-header {
    // padding: 16px 24px;
    // border-bottom: 1px solid #e5e7eb;
    background-color: #f8f8fa !important;
    border-radius: 10px 10px 0 0;
    // border-radius: 10px 10px 0 0;
  }

  .ant-modal-title {
    font-size: 1rem;
    font-weight: 700;
    text-align: center;
    color: #2a2a2a;
    margin-bottom: 24px;
  }

  .invoiceHistoryBodyWrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    border-radius: 10px;

    .invoiceHistoryReportHeader {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
      gap: 16px;
      background-color: #ffffff;
      padding: 16px 20px;
      border-radius: 10px;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);

      .headerItem {
        font-size: 0.95rem;
        color: #4a4a4a;
        line-height: 1.5;

        strong {
          font-weight: 700;
          color: #2a2a2a;
          display: block;
          margin-bottom: 4px;
        }
      }
    }

    .invoiceHistoryTableWrapper {
      background-color: #ffffff;
      border-radius: 10px;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);

      .ant-table-wrapper {
        border: none;

        .ant-table {
          border-radius: 10px;
          overflow: hidden;

          .ant-table-thead > tr > th {
            background-color: #f3f4f6;
            font-size: 0.9rem;
            font-weight: 600;
            text-align: left;
            color: #2a2a2a;
            padding: 12px 16px;
            border-bottom: 1px solid #e5e7eb;
          }

          .ant-table-tbody > tr > td {
            font-size: 0.9rem;
            color: #4a4a4a;
            padding: 12px 16px;
            border-bottom: 1px solid #e5e7eb;
          }

          .ant-table-tbody > tr:last-child > td {
            border-bottom: none;
          }

          /* Hover Effect */
          .ant-table-tbody > tr:hover {
            background-color: #f9fafb;
          }
        }
      }
    }

    .invoiceHistoryReportFooter {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 16px;
      background-color: #ffffff;
      padding: 16px 20px;
      border-radius: 10px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

      .footerItem {
        font-size: 0.95rem;
        color: #4a4a4a;

        strong {
          font-weight: 700;
          color: #2a2a2a;
        }
      }
    }
  }
}
