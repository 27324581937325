@import "../../../index.scss";

:root {
  --color: #71cf48;
  --base-color: #efefef;
  --process-color: #71cf4871;
  --completed-color: #71cf48;
  --active-color: #1f2a44;
}

.antCustomStepper-mobile {
  .ant-steps {
    gap: 10px;
    .ant-steps-item {
      border-radius: 25px;

      &::after {
        visibility: hidden;
      }
    }
  }
}

.antCustomStepper {
  $completed-color: #71cf48;
  $process-color: #71cf4871;
  $base-color: #efefef;
  $active-color: #1f2a44;
  $completed-inner-triangle-border: 18px solid var(--completed-color);
  $active-inner-triangle-border: 18px solid var(--active-color);
  $process-inner-triangle-border: 18px solid var(--process-color);
  $waiting-inner-triangle-border: 18px solid var(--base-color);
  $outer-triangle-border: 25px solid #fff;
  width: 100%;

  .innerTriangle {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border: 0;
    top: 0;
    left: 100%;
    margin: 0;
    border-left: 18px solid var(--color);
    border-top: 18px solid transparent;
    border-bottom: 18px solid transparent;
    z-index: 1;
    transform: unset;
  }

  .outerTriangle {
    content: "";
    position: absolute;
    width: 0 !important;
    height: 0;
    border: 0;
    top: -20%;
    left: 100% !important;
    margin: 0;
    background-color: transparent;
    border-left: 25px solid #fff;
    border-top: 25px solid transparent;
    border-bottom: 25px solid transparent;
    z-index: 1;
    transform: unset;
    transition: none;
  }

  .ant-steps-item {
    background-color: var(--base-color);
    position: relative;
    &:first-child {
      border-top-left-radius: 25px;
      border-bottom-left-radius: 25px;
    }
    &:last-child {
      border-top-right-radius: 25px;
      border-bottom-right-radius: 25px;
    }
    &::before {
      background-color: transparent !important;
    }
  }

  .ant-steps-navigation {
    padding: 0 !important;
    .ant-steps-item-container {
      @include flex(center, center);
      width: 100%;
      height: auto;
      padding: 6px 0 6px;
      margin-left: auto !important;

      .ant-steps-item-content {
        align-self: center;
      }
    }

    .ant-steps-item-icon {
      display: none !important;
    }

    .ant-steps-item-wait:not(:last-child) {
      &::before {
        @extend .outerTriangle;
      }
      &::after {
        @extend .innerTriangle;
        border-left: $waiting-inner-triangle-border;
      }
    }

    .ant-steps-item-finish {
      background-color: var(--completed-color);
      .ant-steps-item-title {
        color: #fff !important;
      }
      &::before {
        @extend .outerTriangle;
      }
      &::after {
        @extend .innerTriangle;
      }
    }

    .ant-steps-item-active {
      background-color: $active-color;
      .ant-steps-item-title {
        color: #fff !important;
      }
      &::before {
        @extend .outerTriangle;
      }

      &::after {
        @extend .innerTriangle;
        border-left: $active-inner-triangle-border;
      }
    }

    .ant-steps-item-process {
      background-color: var(--process-color);
      .ant-steps-item-title {
        font-size: 16px;
        font-weight: 400;
        font-family: "Open Sans";
        color: #fff !important;
      }
      &::before {
        @extend .outerTriangle;
      }
      &::after {
        @extend .innerTriangle;
        border-left: $process-inner-triangle-border;
      }
    }
  }
}

@media (max-width: 768px) {
  .antCustomStepper {
    .ant-steps {
      gap: 5px;
      .ant-steps-item {
        height: 35px !important;
        border-radius: 50px;
        &::before {
          display: none !important;
        }
        &::after {
          display: none !important;
        }
        .ant-steps-item-content {
          height: 22px !important;
          min-height: 22px !important;
        }
      }
    }
  }
}
