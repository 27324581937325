.progressInformationContainer {
  height: 30px;
  display: flex;
  justify-content: space-evenly;
  margin-left: -20px;
  .progressInformationContainerActive {
    width: 268px;
    height: 29px;
    border-radius: 16px;
    background: #f4f5f6 0% 0% no-repeat padding-box;
    background: linear-gradient(to right, #ff0000, #fff200, #1e9600);
    .progressInformationContainerSelected {
      width: 270px;
      height: 30px;
      border-radius: 16px;
      background: radial-gradient(
        circle at 0px 15px,
        rgba(255, 0, 0, 0) 18px,
        rgba(255, 0, 0, 0) 18px,
        #f4f5f6 0px
      );
    }
  }
}
.sliderStyle.MuiSlider-valueLabel.css-qtlk2l-MuiSlider-valueLabel {
  display: flex;
  left: calc(-50% - 4px);
  z-index: 1;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.2;
  letter-spacing: 0.01071em;
  -webkit-transition: -webkit-transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  top: -40px;
  transform-origin: bottom center;
  -webkit-transform: scale(1) !important;
  -moz-transform: scale(1) !important;
  -ms-transform: scale(1) !important;
  transform: scale(1) !important;
  position: absolute;
}
