$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.tutorial-modal {
  .ant-drawer-content {
    .ant-drawer-body {
      display: flex;
      flex-direction: row;
      height: 100%;
      position: relative;
      padding: 0 !important;

      .line {
        position: absolute;
        top: 70px;
        left: 20px;
        right: 20px;
        height: 1px;
        background-color: #323338;
      }
    }
  }
}

.tutorial-modal-dark {
  .ant-drawer-content {
    background-color: $inputModalDark !important;
    .ant-drawer-header {
      .ant-drawer-extra {
        .ant-btn {
          background-color: $cardModalBodyDark !important;
        }

        .ant-btn-icon {
          color: #fff;
        }
      }

      .ant-drawer-header-title {
        .ant-drawer-close svg {
          fill: #fff;
        }

        .ant-drawer-title {
          color: #fff;
        }
      }
    }

    .ant-drawer-wrapper-body {
      .ant-drawer-header {
        .ant-drawer-header-title {
          .ant-drawer-title {
            color: #fff;
          }
          .ant-drawer-close {
            .anticon svg {
              fill: #fff !important;
            }
          }
        }
      }
      .ant-drawer-body {
        background-color: $inputModalDark !important;
        border-radius: 10px;
        .line {
          background-color: #acacca;
        }
        .categoriesContainer-card {
          .search-container {
            .ant-input-affix-wrapper {
              background-color: $cardModalBodyDark !important;
            }
            .ant-input-wrapper {
              background-color: $cardModalBodyDark !important;
              .ant-input-prefix svg {
                fill: #fff;
              }
              .ant-input {
                background-color: $cardModalBodyDark !important;
                color: #fff;
                &::placeholder {
                  color: #acacca;
                }
              }
            }
          }
        }
        .allCards {
          .newCategories-container {
            .newCategory {
              &:hover {
                background-color: #ee8a35;
              }
              .newVideosCategoryInfo .newVideosCategoryInfoSmallconts span {
                color: #fff;
              }
            }
          }
          .categories .categoriesCards-container {
            .card-container {
              &:hover {
                background-color: #292b3b;
              }
              background-color: $cardModalBodyDark;
              svg path {
                fill: #fff;
              }
              span {
                color: #fff;
              }
            }
          }
        }
        .subCategoriesContainer-card {
          background-color: $cardModalBodyDark !important;
          .vertical-line {
            background-color: #acacca;
          }
          .header span {
            color: #fff;
          }
          .cards {
            .card-container {
              &:hover {
                background-color: #292b3b;
              }
              background-color: $cardModalBodyDark;
              span {
                color: #fff;
              }
            }
          }
        }
        .videosContainer-card {
          .vertical-line {
            background-color: #acacca;
          }
          .header span {
            color: #fff;
          }
          .videos {
            .videoCard-container {
              background-color: $cardModalBodyDark;
              &:hover {
                background-color: #292b3b;
              }
              .newVideosCategoryInfoSmallconts span {
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
}

.videoTutorial-dropdownDark {
  .ant-dropdown-menu {
    background-color: $cardModalBodyDark !important;
  }
}
