.mobile-header-buttons {
  display: flex;
  flex-direction: column;
  gap: 4px;

  div {
    width: 100%;

    button {
      width: 100% !important;
    }
  }
}
