$flex-color: #c6538c;

.filter_container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-top: 1px solid #d2d6dc;
  padding: 10px 0px;
  // margin: 10px 0px;
  // margin-top: -10px !important;
  //   border: 2px solid green;
  .filter_title {
    font: normal normal 600 16px Open Sans;
  }
  .filter_btns {
    display: flex;
    gap: 10px;
    margin-left: 10px;
  }
}

.filter_button {
  width: auto;
  display: flex;
  flex-direction: row !important;
  align-items: center;
  justify-content: space-evenly;
  border: 1px solid lightgray;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  padding: 3px 5px;
  cursor: pointer;
  text-align: left;
  font: normal normal 600 12px Open Sans;
  letter-spacing: 0px;
  color: #323338;
  opacity: 1;
  transition: background 0.3s ease, color 0.3s ease, border 0.3s ease;

  &:hover {
    background: #1264a3;
    color: white;
    border: 1px solid #1264a3;
  }
}
.filter_button:focus {
  background: #1264a3;
  color: white;
  border: 1px solid #1264a3;
}

.active_filter_button {
  background: #1264a3;
  color: white;
  border: 1px solid #1264a3;
}

.filter_button:after {
  background: #1264a3;
  color: white;
  border: 1px solid #1264a3;
}
