@import "../../../../../../../../index.scss";
$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.docUploader {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 10px;

  .uploadSection {
    @include flex(center, space-evenly, row);
    max-width: 450px;
    width: 100%;
    height: 55px;
    border: 1px dashed #323338;
    border-radius: 2px;
    gap: 5px;
    padding: 0 5px;
    .uploaderIcon {
      color: #0f5c97;
    }
    .uploaderTitle {
      @include text(14px, 400, 0, #0f5c97);
    }
  }

  .uploadedFilesSection {
    display: flex;
    flex-direction: column;
    max-height: 240px;
    max-width: 450px;
    overflow-y: auto;
    background-color: #f8f8fa;
    width: 100%;
    gap: 5px;
    padding: 10px;
    border-radius: 5px;
    .warningMessage {
      font-size: 14px;
      cursor: pointer;
      color: #fe4c4a;
      display: flex;
      gap: 10px;
      font-weight: 600;
      align-items: center;
    }
    .uploadedFilesMapping {
      display: flex;
      flex-direction: column;
      gap: 5px;
      width: auto;
      scroll-behavior: smooth;
      .uploadedFilesCard {
        display: flex;
        padding: 10px;
        // min-width: 300px;
        border-radius: 5px;
        height: 60px;
        gap: 10px;
        width: auto;
        overflow-x: auto;
        background-color: #fff;
        animation: fadeInDown 1000ms both;
        transition: background-color 0.3s ease-in-out;
        height: auto;

        .filesIcon {
          align-self: center;
          height: 15px;
        }

        .uploadedFile {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          // min-width: 300px;
          cursor: pointer;
          height: fit-content;
          .fileName {
            font-size: 14px;
            font-weight: 400;
            color: #323338;
            // overflow: hidden;
            // text-overflow: ellipsis;
            // white-space: nowrap;
            // max-width: 300px;
          }
          .fileAuthor {
            display: flex;
            // justify-content: space-between;
            gap: 5px;
            color: #acacac;
            font-size: 12px;
            flex-wrap: wrap;
          }
        }
        .actionButtons {
          display: flex;
          align-items: center;
          gap: 5px;
          .closeIconContainer {
            color: #323338;
            justify-self: flex-end;
            width: 20px;
            height: 15px;
            border-radius: 5px;
            cursor: pointer;
            @include flex(center, center);
            svg {
              fill: #323338 !important;
            }
            &:hover {
              svg {
                fill: #1264a3 !important;
              }
            }
          }
          .closeIconContainerDelete {
            svg {
              width: 12px;
              height: 17px;
              fill: #323338 !important;
            }
            &:hover {
              svg {
                fill: #fe4c4a !important;
              }
            }
          }
        }
      }
      .uploadedFilesCard.uploadedFilesCardRed {
        // background-color: #fcd3d3;
        background-color: #fe4c4a50 !important;
      }
      .uploadedFilesCard.uploadedFilesCardBlue {
        // background-color: #f1f5fd;
        background-color: #1264a350 !important;
      }
    }
  }
}

//Dark Mode
.docUploaderDark {
  .uploadSection {
    border: 1px dashed #acacca;
  }
  .uploadedFilesSection {
    background-color: $inputModalDark !important;
    .uploadedFilesMapping .uploadedFilesCard {
      background-color: $cardModalBodyDark !important;
      .filesIcon {
        svg {
          fill: #fff !important;
        }
      }
      .uploadedFile {
        .fileName {
          color: #fff !important;
        }
        svg {
          fill: #fff !important;
        }
        .fileAuthor span {
          color: #acacca !important;
        }
      }
      .actionButtons {
        .closeIconContainer {
          color: #fff !important;
          svg {
            fill: #fff !important;
          }
          .closeIconContainerDelete {
            color: #fff !important;
            svg {
              fill: #fff !important;
            }
          }
        }
      }
    }
  }
}

//turns uploadedFilesSection to ROW
.responsiveDocUploader {
  align-items: center;
  .uploadSection {
    max-width: auto !important;
  }
  .uploadedFilesSection {
    width: 100% !important;
    max-width: unset !important;
    .uploadedFilesMapping {
      width: 100% !important;
      display: flex !important;
      flex-wrap: nowrap !important;
      padding-bottom: 5px !important;
      flex-direction: row;
      overflow-x: auto !important;
      gap: 1rem !important;
      .uploadedFilesCard {
        width: unset !important;
        .uploadedFile {
          .fileName {
            max-width: unset !important;
            white-space: nowrap !important;
          }
          .fileAuthor {
            white-space: nowrap !important;
            flex-wrap: nowrap !important;
          }
        }
      }
    }
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
