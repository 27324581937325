.DescriptionCard {
  display: flex;
  justify-content: space-between;
  opacity: 1;
  margin-bottom: 20px;
  margin-right: 20px;
  height: 30px;
  align-items: center;
  padding-right: 10px;
  margin-top: 20px;
  height: auto;
  max-height: 300px;
  overflow-y: auto;
  .descriptionValue {
    // top: 100px;
    text-align: left;
    font: normal normal normal 20px/27px Open Sans;
    letter-spacing: 0px;
    color: #323338;
    opacity: 1;
  }
  .noDscText {
    display: flex;
    align-items: center;
    text-align: left;
    // font: normal normal normal 20px/27px Open Sans;
    font-size: 14px;
    letter-spacing: 0px;
    color: #323338;
    opacity: 1;
    gap: 10px;
  }
}

.DescriptionCardDark {
  .descriptionValue {
    color: #fff;
  }
  .noDscText {
    color: #fff;
  }
}
