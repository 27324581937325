$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.est_ammount_modal {
  width: fit-content !important;
  .ant-modal-content {
    border-radius: 10px;
    .ant-modal-header {
      background-color: #f8f8f8;
      display: flex;
      align-items: center;
      height: 40px;
      padding: 0 20px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      .ant-modal-title {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        h2 {
          margin-bottom: 0;
          font-size: 16px;
          font-weight: 600;
          &:nth-child(2) {
            margin-left: 25px;
            color: #71cf48 !important;
          }
        }
      }
    }
    .ant-modal-body {
      max-height: 600px;
      overflow-y: auto;
      min-width: 300px;
      .priceModalBody {
        display: flex;
        flex-direction: column;
        gap: 20px;
        .priceVersionContainer {
          display: flex;
          flex-direction: column;
          padding-bottom: 20px;
          .priceVersionHeader {
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
            .price {
              color: #71cf48 !important;
            }
          }
        }
        .priceVersionContainer:not(:last-child) {
          border-bottom: 1px solid #e8e8e8;
        }
      }
    }
  }
  .ant-modal-footer {
    display: flex;
    justify-content: flex-end;
  }
}

.est_ammount_modal_dark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close .ant-modal-close-x svg {
      fill: #f2f2f2;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #f2f2f2;
      }
    }
    .ant-modal-body {
      .priceModalBody {
        .priceVersionContainer {
          .priceVersionHeader:first-child {
            color: #f2f2f2;
          }
        }
      }
      .service_price_total {
        h4 {
          color: #f2f2f2;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .est_ammount_modal {
    .ant-modal-header .ant-modal-title {
      h2 {
        font-size: 16px;
      }
    }
  }
}
