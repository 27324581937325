.field-mobile-header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-radius: 5px;
  background-color: #fff;
  width: 100%;
  align-items: center;
  height: 44px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  .hamburger-icon {
    height: 15px;
    width: 22.5px;
  }
}

.mobile-menu-drawer {
  // border-radius: 0 5px 5px 0;
  padding-top: 30px;
  background-color: #1f2a44 !important;
  .ant-drawer-header {
    display: none;
  }
  .ant-drawer-body {
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
    .drawer-header {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .drawer-menu-body {
      flex: 1;
      .ant-menu {
        height: calc(100dvh - 253px);
        overflow-y: auto;
        .ant-menu-item {
          color: #fff;
          font-size: 14px;
          font-weight: 400;
          border-radius: 0;
          padding: 10px 20px;
          padding-left: 10px !important;
          margin: 0;
          // gap: 5px;
          svg {
            height: 20px !important;
            width: 20px !important;
            fill: #fff !important;
          }
        }
        .ant-menu-item-selected {
          // background-color: lighten(#1f2a44, 10);
          background-color: #3a3c4e;
          border-right: 10px solid #fff;
        }
      }
      .drawer-footer {
        color: #fff;
        height: 48px;
        font-size: 14px;
        gap: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        // border-radius: 0 0 5px 0;
      }
      .logout {
        justify-content: left;
        padding: 10px;
      }
      .language {
        .ant-dropdown-trigger {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
