.usersToChat {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  .usersToChatContent {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    .searchUserInput {
      width: 91%;
      border-radius: 5px;
      background-color: #f4f5f6;
    }
  }
  .allUsers {
    &::-webkit-scrollbar-track {
      background-color: #f4f5f6 !important;
    }
    overflow-y: auto;
    height: calc(100vh - 150px);
    margin-right: -5px;

    .usersData {
      display: flex;
      align-items: center;
      padding: 0px 10px 0px 10px;
      width: 100%;
      .userContent {
        display: flex;
        align-items: center;
        gap: 5px;
        height: 58px;
        width: 100%;
        .badgeAvatar {
          .ant-badge-dot {
            position: absolute !important;
            top: 4px;
            right: 4px;
            padding: 5px;
          }
        }
        .avatarInitials {
          background-color: #f4f5f6;
          color: #231f20;
          width: 32px;
          height: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 5px;
        }
        .userInfo {
          display: flex;
          flex-direction: column;
          .messageUserName {
            display: flex;
            align-items: center;
            gap: 10px;
          }
          .bottomContent {
            display: flex;
            align-items: center;
            gap: 10px;
            .lastMessage {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              color: #acacac;
            }
            .lastMessageDate {
              font-size: 12px;
              color: #acacac;
            }
          }
        }
        .userInfo.unread {
          color: #231f20 !important;
        }
      }
    }
    .usersData.userSelected {
      background-color: #f1f5fd !important;
    }
    .usersData.unread {
      background-color: #f4f5f6;
      .userContent {
        .userInfo.unread {
          .messageUserName {
            span {
              font-weight: 600;
            }
          }
          .bottomContent {
            span {
              color: #231f20 !important;
            }
          }
        }
      }
    }
    :hover {
      cursor: pointer;
      background-color: #f1f5fd;
    }
  }

  .messagesFromLastWeek {
    display: flex;
    align-items: center;
    justify-content: center;
    .line {
      flex: 1;
      border: none;
      border-top: 1px dashed #aaa;
      margin: 0 10px;
    }
  }
}

.threeDotsPopoverChat {
  padding: 0px !important;
  .threeDotsPopoverChatContent {
    width: 200px;
    .threeDotsPopoverChatItem {
      display: flex;
      align-items: center;
      height: 32px;
      border-bottom: 1px solid #f0f0f0;
      cursor: pointer;
      padding: 0px 10px;
    }
    :hover {
      color: #1264a3;
      background-color: #f1f5fd;
    }
    .threeDotsPopoverChatItem.delete:hover {
      color: #fe4c4a;
      background-color: #fde5e4;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
  .ant-popover-inner {
    border-radius: 10px;
    padding: 0px !important;
    box-shadow: 0px 1px 4px #00000029 !important;
    .ant-popover-inner-content {
      padding: 0px !important;
    }
  }
}
