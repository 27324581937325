$inputModalDark: #12131b;

.EditDropdown {
  .ant-form-item {
    margin-bottom: 0px;
  }
  .EditDropdownComponent {
    width: 350px !important;
    height: 32px;
    background: #f5f5f7 0% 0% no-repeat padding-box;
    opacity: 1;
    border-radius: 5px;
    border: 0px;
    .ant-select-arrow::before {
      display: none !important;
    }
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #f5f5f7;
    border: 0px;
    border-radius: 5px;
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(
      .ant-select-customize-input
    )
    .ant-select-selector {
    box-shadow: none;
  }
}
.EditDropdownDark {
  .ant-select {
    background-color: $inputModalDark !important;
    .ant-select-selector {
      background-color: $inputModalDark !important;
      color: #f2f2f2;
      .ant-select-selection-search-input {
        background-color: $inputModalDark !important;
        color: #f2f2f2;
      }
      .ant-select-selection-placeholder {
        color: #acacca;
        z-index: 2;
      }
    }
    .ant-select-arrow svg {
      fill: #f2f2f2 !important;
    }
  }
}
.EditDropDownClassName {
  background: #ffffff !important;
  border-radius: 5px !important;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25) !important;
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background: transparent;
  }
  .rc-virtual-list {
    .rc-virtual-list-holder {
      .rc-virtual-list-holder-inner {
        .ant-select-item-option-selected {
          background: #f1f5fd;
          .ant-select-item-option-content {
            color: #1264a3;
          }
        }
        .ant-select-item {
          padding-left: 10px;
          padding-right: 10px;
          padding-top: 6.5px;
          padding-bottom: 6.5px;
          .ant-select-item-option-content {
            font-family: "Open Sans";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            margin: 0;
            padding: 0;
          }
          &:not(:last-child) {
            border-bottom: 1px solid #efefef;
          }
          &:hover {
            background: #f1f5fd;
            .ant-select-item-option-content {
              color: #1264a3;
            }
          }
        }
      }
    }
  }
}
