$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.signatureModalDark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;
      }
    }
    .basePageModalFooter {
      background-color: $cardModalBodyDark;
      .ant-btn:disabled {
        border-color: #acacca;
        color: #acacca;
      }
    }
  }
}
