@use "/src/index.scss" as index;

.payroll-live-map-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;

  .payroll-live-map {
    height: 100%;
    width: 100%;
    min-height: calc(100dvh - 56px);
    min-width: calc(100dvw - 590px);
    display: flex;
    justify-content: center;
    .gm-style-mtc ul[role="menu"] {
      top: -37px !important;
      border-radius: 2px 2px 0px 0px !important;
    }
    .gm-style .gm-style-iw-c {
      border-radius: 5px !important;
      overflow: visible;
    }

    .gmnoprint.gm-style-mtc-bbw {
      left: 10px !important;
    }
    img[alt="Google"] {
      display: none !important;
    }
    .gm-style-iw {
      padding-left: 0px !important;
      .gm-style-iw-chr {
        display: none;
      }
      .gm-style-iw-d {
        overflow: visible !important;
      }
    }
    .elementInfoContainer {
      .elementHeader {
        height: 27px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 10px;
        background-color: #222a44;
        color: #fff;
        font-weight: 600;
        gap: 5px;
        .closeIcon {
          height: 14px;
          width: 14px;
          fill: #fff;
          cursor: pointer;
          padding: 0px 1.5px;
        }
      }
      .elementInfo {
        display: flex;
        flex-direction: column;
        gap: 5px;
        padding: 0px 10px;
        padding-bottom: 5px;
        .infoWrapper {
          height: 24px;
          display: flex;
          align-items: center;
          gap: 5px;
          box-shadow: 0 3px 2px -2px #ebebeb;
          padding-bottom: 4px;
          color: #000 !important;
          &:first-child {
            padding-top: 6px;
          }
          .elementStatusInfo {
            border-radius: 2px;
            color: #fff;
            padding: 1px 5px;
          }
          .custom-progress-bar {
            height: 20px;
            width: 120px;
            .progress-done {
              height: 20px;
            }
          }
          label {
            font-weight: 600;
          }
        }
        .projectManagers {
          height: fit-content;
          max-width: 600px;
          align-items: flex-start;
          label {
            height: 25px;
            width: 120px;
            display: flex;
            align-items: center;
          }
          .managersContainer {
            max-height: 55px;
            display: flex;
            flex-direction: column;
            gap: 5px;
            align-items: flex-start;
            justify-content: flex-start;
            &::-webkit-scrollbar {
              width: 4px;
              height: 4px;
            }
            .managerItem {
              width: 100%;
              box-shadow: 0 3px 2px -2px #ebebeb;
            }
          }
        }
        .projectServices {
          display: flex;
          align-items: flex-start;
          gap: 5px;
          label {
            font-weight: 600;
            width: 85px;
          }
          .servicesContainer {
            max-height: 80px;
            width: 65%;
            overflow-y: auto;
            &::-webkit-scrollbar {
              width: 4px;
              height: 4px;
            }
            .serviceItemContainer {
              box-shadow: 0 3px 2px -2px #ebebeb;
              .serviceItem {
                display: flex;
                gap: 5px;
                margin-bottom: 5px;
                .serviceTitle {
                  width: 100%;
                }
                label {
                  width: fit-content;
                }
              }
            }
          }
        }
        .scheduleDays {
          display: flex;
          align-items: flex-start;
          gap: 5px;
          label {
            font-weight: 600;
          }
          .scheduleDaysContainer {
            max-height: 65px;
            display: flex;
            flex-direction: column;
            gap: 5px;
            overflow-y: auto;
            &::-webkit-scrollbar {
              width: 4px;
              height: 4px;
            }
            .scheduleDay {
              display: flex;
              gap: 10px;
              box-shadow: 0 3px 2px -2px #ebebeb;
              .dayStatus {
                border-radius: 2px;
                color: #fff;
                padding: 1px 5px;
              }
            }
          }
        }
      }
    }
  }
}

.payroll-live-map-container-dark {
  background-color: index.$mainDarkBackgroundColor;
  .gm-style .gm-style-iw-c {
    background-color: index.$mainDarkBackgroundColor !important;
  }
  .gm-style .gm-style-iw-tc::after {
    background: index.$mainDarkBackgroundColor !important;
  }
}
