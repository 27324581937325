@import "../../../../../../../../../../../../../index.scss";

.serviceDetailsFooterContainerDark {
  background: #12131b !important;
  color: #f8f8fa;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 45px;

  .informationContainer {
    margin-left: 20px;
    font-size: 16px;
    font-weight: 600;
    display: flex;

    .label {
      margin-right: 7px;
      font-weight: bold;
    }

    .amount {
      color: #71cf48;
    }
  }

  .detailsModalFooterContainer {
    margin-right: 50px;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      margin-right: 5px;
      fill: red;
    }
  }

  .buttonContainer {
    display: flex;
    align-items: center;
    margin-right: 20px;
    .saveMondayButton {
      margin-left: 15px;
      @include mondayButton(#71cf48, #68c142, #fff);

      &:disabled {
        @include mondayButton(#b4b4b4, #999999, #fff);
      }

      svg {
        width: 23px;
        height: 23px;
        margin-left: -5px;
        margin-right: -5px;
      }
    }
    .discardMondayButton {
      margin-left: 15px;
      @include mondayButton(#fe4c4a, #eb4345, #fff);
    }
  }
}

.serviceDetailsFooterContainerLight {
  background: #f8f8fa;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 45px;

  .informationContainer {
    margin-left: 20px;
    font-size: 16px;
    font-weight: 600;
    display: flex;

    .label {
      margin-right: 7px;
      font-weight: bold;
    }

    .amount {
      color: #71cf48;
    }
  }

  .detailsModalFooterContainer {
    margin-right: 50px;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      margin-right: 5px;
      fill: red;
    }
  }

  .buttonContainer {
    display: flex;
    align-items: center;
    margin-right: 20px;
    .saveMondayButton {
      margin-left: 15px;
      @include mondayButton(#71cf48, #68c142, #fff);

      svg {
        width: 23px;
        height: 23px;
        margin-left: -5px;
        margin-right: -5px;
      }
    }
    .discardMondayButton {
      margin-left: 15px;
      @include mondayButton(#fe4c4a, #eb4345, #fff);
    }
  }
}
