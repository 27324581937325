.live-marker {
  position: relative;
  bottom: 15px;
  right: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30px;
  height: 30px;
  gap: 5px;

  .marker-status-icon {
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    svg {
      fill: black;
    }
  }

  .marker-status-icon.Idling {
    height: 28px;
    width: 28px;
    border: 2px solid white;
    background-color: rgb(223, 196, 0);
    svg {
      border-radius: 50%;
      height: 19px;
      width: 19px;
      fill: white;
    }
  }

  .marker-status-icon.Moving {
    height: 28px;
    width: 28px;
    border: 2px solid white;
    background-color: rgb(124, 202, 0);
    svg {
      fill: white;
    }
  }

  .marker-status-icon.Stopped {
    height: 30px;
    width: 30px;
    background-color: white;
    svg {
      fill: rgb(220, 64, 69);
      height: 29px;
      width: 29px;
    }
  }

  .marker-status-icon.InMotion {
    height: 28px;
    width: 28px;
    border: 2px solid white;
    background-color: rgb(10, 136, 0);
    svg {
      height: 28px;
      width: 28px;
      fill: white;
    }
  }

  .marker-status-icon.Play {
    height: 28px;
    width: 28px;
    border: 2px solid white;
    background-color: white;
    svg {
      fill: rgb(10, 136, 0);
      height: 22px;
      width: 22px;
    }
  }
}
