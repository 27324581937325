@import "../../../../index.scss";

$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.contactModalContainer {
  .ant-modal {
    width: 70% !important;
    .ant-modal-content {
      .ant-modal-body {
        padding: 10 0 0 0;
        .ant-input {
          height: 32px !important;
        }
      }
    }
  }
  .newFooterContainer button {
    width: 150px;
  }

  .bodyTypeRadio {
    @include flex(baseline, flex-start);
    border-radius: 5px;
    background: #f8f8fa;
    height: 60%;
    width: fit-content;
    padding-left: 10px;
  }
  .editorsContainer {
    width: 100%;
    @include flex(flex-start, flex-start);
  }
  .contactModalBodyContainer {
    display: grid;
    grid-template-columns: [full-start] auto 30% [full-end];
    column-gap: 15px;
    height: calc(100vh - 240px);
    max-height: 630px;
    max-width: 1440px;
    overflow-x: hidden;
    overflow-y: auto;
    .billingBorderedTextCard {
      border: none;
      border-radius: 0;
      border-top: 1px solid #323338;
      max-height: 500px;
      padding: 0;
      align-items: center;

      .cardTitle {
        color: black;
      }
      .shippingAddIcon {
        top: 2.7rem !important;
        right: 0%;
      }
      .labeledInputContainer {
        margin-top: 7px;
      }
    }
    .contactBorderedTextCard {
      padding-right: 0;
      padding-left: 0px;
      height: fit-content !important;
      border: none;
      border-radius: 0;
      border-top: 1px solid #323338;
      max-height: 500px;
      align-items: center;
      .cardTitle {
        color: black;
      }
      .formTextEditorContainer {
        margin-top: 7px;
        margin-right: 0;
        margin-bottom: 10px;
      }
      .contactDetailsRow {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        align-items: flex-start;
        width: 100%;
        gap: 7.5px;
      }
      .opportunity {
        .jobsite {
          width: 35%;
        }
        .firstName {
          width: 20%;
        }
        .middleName {
          width: 20%;
        }
        .lastName {
          width: 20%;
        }
        .contactRole {
          width: 27%;
        }
        .contactAccountName {
          width: 40%;
        }
        .contactEmail {
          width: 30%;
        }
        .contactPhone {
          width: 27.5%;
          .form-control {
            width: 100%;
          }
        }
        .phoneExtension {
          width: 24%;
        }
        .contactMobile {
          width: 20%;
          .form-control {
            width: 100%;
          }
        }
        .beveragePreference {
          width: 24%;
        }
      }
      .default {
        .firstName {
          width: 24%;
        }
        .middleName {
          width: 24%;
        }
        .lastName {
          width: 24%;
        }
        .contactRole {
          width: 24%;
        }
        .contactAccountName {
          width: 29%;
        }
        .contactEmail {
          width: 29%;
        }
        .contactPhone {
          width: 39%;
          .form-control {
            width: 100%;
          }
        }
        .phoneExtension {
          width: 25%;
        }
        .contactMobile {
          width: 32%;
          .form-control {
            width: 100%;
          }
        }
        .beveragePreference {
          width: 40%;
        }
      }
    }
  }

  .billingAndShippingAddressesContainer {
    .labeledInputContainer {
      margin-right: 0;
    }
    .shippingAddIcon {
      right: 0px;
    }
  }
  .contactModalBodyContainer-splash {
    grid-column: full;
    .radioTitle {
      @include text(14px, 600, 0, #323338);
      margin-right: 15px;
    }
  }

  .map {
    grid-column: full;
    padding: 20px;
  }

  .labeledInputContainer {
    .labeledInputLabel {
      @include text(14px, 600, 0.21px, #383554);
      margin-bottom: 2px;
    }
    .ant-radio-wrapper {
      @extend .labeledInputLabel;
    }
  }

  .button-dropdown {
    @include mondayButton(#71cf48, #68c142);
    width: 140px;
  }
}

.contactModalContainerDark {
  .ant-modal-content .ant-modal-body {
    .ant-form .contactModalBodyContainer {
      .bodyTypeRadio {
        background-color: $inputModalDark;
        .radioTitle {
          color: #fff;
        }
        .ant-radio-group {
          .ant-radio-wrapper {
            .ant-radio-inner {
              background-color: $cardModalBodyDark;
            }
            color: #fff;
          }
        }
      }
      .borderedTextCard {
        border-top: 1px solid #acacca;
        .cardTitle {
          background-color: $cardModalBodyDark;
          color: #fff;
        }
        .labeledInputLabel {
          color: #fff;
        }
        .ant-input {
          background-color: $inputModalDark !important;
          color: #fff;
          &::placeholder {
            color: #acacca;
          }
        }
        .ant-select {
          .ant-select-selector {
            background-color: $inputModalDark !important;
            .ant-select-selection-search-input {
              color: #fff;
            }
            .ant-select-selection-item {
              color: #fff;
            }
            .ant-select-selection-placeholder {
              color: #acacca;
            }
          }
          .ant-select-arrow svg {
            fill: #fff;
          }
        }
        .react-tel-input {
          .form-control {
            background-color: $inputModalDark !important;
            color: #fff;
            &::placeholder {
              color: #acacca;
            }
          }
          .flag-dropdown {
            background-color: $inputModalDark;
            border-radius: 5px 0px 0px 5px;
            .selected-flag {
              &:hover {
                background-color: $inputModalDark;
              }
              &:focus {
                background-color: $inputModalDark;
              }
            }
            .arrow {
              border-top: 4px solid #fff;
            }
          }
          .open {
            background-color: $inputModalDark;
            border-radius: 5px 0px 0px 5px;
            .selected-flag {
              background-color: $inputModalDark;
              .arrow {
                border-bottom: 4px solid #fff;
                border-top: 4px solid transparent;
              }
            }
          }
        }
        .tox {
          border: none;
          .tox-menubar {
            background-color: $cardModalHeaderDark;
            border-radius: 5px 5px 0px 0px;
            .tox-mbtn__select-label {
              color: #fff;
            }
          }
          .tox-mbtn--select:hover {
            background-color: $cardModalBodyDark;
          }
          .tox-toolbar-overlord {
            background-color: $cardModalHeaderDark !important;
            .tox-toolbar__primary {
              background-color: $cardModalHeaderDark !important;
            }
            .tox-toolbar__group {
              background-color: $cardModalHeaderDark !important;
              border: none;
              .tox-split-button:hover {
                border: none;
              }
              .tox-tbtn {
                svg {
                  fill: #fff;
                }
                &:focus {
                  background-color: $cardModalBodyDark;
                }
                &:hover {
                  background-color: $cardModalBodyDark;
                }
              }
              .tox-tbtn__select-label {
                color: #fff !important;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1440px) {
  .contactModalContainer {
    .ant-modal {
      width: 85% !important;
      .bodyTypeRadio {
        align-items: flex-start;
        margin-bottom: 10px;
      }
      .map {
        margin-top: 5rem;
      }
    }
  }
}

@media (max-width: 1350px) {
  .contactModalContainer {
    .ant-modal {
      width: 85% !important;

      .billingBorderedTextCard {
        .shippingAddIcon {
          right: 0 !important;
        }
      }
    }
  }
}
@media (max-width: 1170px) {
  .contactModalContainer {
    .bodyTypeRadio {
      flex-direction: column;
      height: 12% !important;
      padding-left: 10px;
    }
    .contactModalBodyContainer {
      display: block;
      max-height: 80vh;
    }
    .billingBorderedTextCard {
      margin-top: 9rem;
      .childrenContainer {
        .labeledInputContainer {
          width: 300px !important;
          margin-left: 40px;
        }
        .labeledInputContainer:first-child {
          width: 300px !important;
        }
        .labeledInputContainer:nth-child(2) {
          margin-left: 40px !important;
        }
      }
      .shippingAddIcon {
        left: 310px !important;
      }
    }

    .contactBorderedTextCard {
      margin-top: 20px !important;
      .opportunity {
        .beveragePreference {
          width: 50% !important;
        }
      }
    }
  }
}

@media (max-width: 820px) {
  .contactModalContainer .footerContainer {
    justify-content: space-between;
  }
  .contactModalContainer {
    .bodyTypeRadio {
      flex-direction: column;
      height: 15% !important;
      padding-left: 10px;
    }
    .contactModalBodyContainer {
      display: block;
    }
  }
}

@media (max-width: 450px) {
  .contactModalContainer {
    .ant-modal {
      width: 100% !important;
      .bodyTypeRadio {
        flex-direction: column;
        height: 30% !important;
        .radioTitle {
          width: 100%;
          margin-bottom: 10px;
          text-align: center;
          font-size: 15px;
        }
        .ant-radio-group {
          display: flex;
          flex-direction: column;
          margin-left: 10px;
        }
      }
      .borderedTextCard {
        .childrenContainer {
          display: block !important;
          width: 100% !important;
          .labeledInputContainer {
            min-width: 100%;
            .form-control {
              width: 100%;
            }
          }
        }
      }
      .billingBorderedTextCard {
        margin-top: 41rem;
        .shippingAddIcon {
          left: 270px !important;
        }
        .billingAndShippingAddressesContainer .businessAddContainer {
          display: flex;
          .splitAddContainer {
            width: 100%;
            flex-direction: column;
            flex-wrap: nowrap;
            .labeledInputContainer {
              min-width: 90% !important;
            }
          }
        }
      }
    }
  }
}
