@import "../../Communication.scss";


.communication-header-container {
    height: 50px;
    display: flex;
    box-shadow: 0px 0px 11px -8px;
    border-radius: 10px;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    padding: 10px 20px;
    margin-bottom: 10px;

    &.mobile {
        padding: 8px 10px;

        .communication-header-container-left {
            width: 0;
        }

        .communication-header-container-right .users-info-container {
            justify-content: start;
        }
    }


    .communication-header-container-left {
        display: flex;
        align-items: center;
        gap: 20px;
        width: 100%;

        .communication-icon {
            height: 32px;
            width: 32px;
            fill: $icon-color;
        }

        .communication-title {
            margin-left: 10px;
            text-align: left;
            font: normal normal 800 18px/24px Open Sans;
            letter-spacing: 0px;
            color: #323338;
        }

        .communication-search-container {
            position: relative;

            @media screen and (max-width: 1440px) {
                .filter-select {
                    max-width: 300px;
                }
            }

            .ant-select-selection-placeholder {
                padding: 0;
                line-height: 40px;
                transition: all 0.3s;
                margin-left: 30px;
                font-size: 16px;
            }

            .ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
                margin-left: 30px;
                width: calc(100% - 65px);
                padding: 0;
                background: transparent;
                border: none;
                outline: none;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
            }

            input {
                border: 1px solid #d9d9d9;
                padding: 0.625rem 0.75rem;
                border-radius: 0.375rem;
                line-height: 1.15;
                font-size: 16px !important;
            }

            .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
                height: 32px;
            }

            .ant-select-selection-placeholder {
                font-size: bold;
                font-size: 14px;
                /* Or any other font-weight value */
            }

            .ant-select {
                width: 450px;
                height: 32px;

                .ant-select-selector {
                    height: 32px;
                    background-color: #f5f5f7;
                    border: 0px;
                    border-radius: 5px;
                }
            }

            .searchIcon {
                position: absolute;
                left: 10px;
                top: 8px;

                height: 16px;
                width: 16px;
            }

            .optionsIcon {
                position: absolute;
                right: 10px;
                top: 8px;
                display: flex;
                cursor: pointer;
                justify-content: center;
                align-items: center;

                height: 16px;
                width: 16px;
            }

            .optionsIcon:hover {
                background-color: #e3e5e6;
                border-radius: 10px;
            }
        }
    }

    .communication-header-container-right {
        display: flex;
        align-items: center;
        gap: 20px;
        width: 100%;
        justify-content: flex-end;

        .signInButton {
            @include mondayButton(#1264a3, #0f5c97);
        }

        .UserContainer {
            display: flex;
            justify-content: flex-start;
            gap: 10px;
            align-items: center;
            cursor: pointer;

            .logOutButton {
                margin-left: 10px;
                width: 32px;
                height: 32px;
                background: #1264a3 0% 0% no-repeat padding-box;
                border-radius: 5px;
                display: flex;
                justify-content: center;
                align-items: center;

                svg {
                    width: 30px;
                    height: 20px;
                    fill: #fff;
                }
            }

            .UserContainerIcon {
                height: 51px;
                width: 51px;
                border-radius: 50%;
                background-color: white;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 32px;
                border: 1px solid #1264a3;

                .imageStyle {
                    overflow: hidden;
                    border-radius: 50%;
                    height: 49px;
                    width: 50px;
                }

                .userIcon {
                    overflow: hidden;
                    border-radius: 50%;
                    height: 45px;
                    width: 45px;
                    background-color: #ca4739;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: white;

                    img {
                        width: 60px;
                        height: 60px;
                    }
                }
            }

            .UserContainerDetails {
                display: flex;
                flex-direction: column;
                justify-content: center;

                .UserContainerName {
                    text-align: left;
                    font: normal normal normal 14px/19px Open Sans;
                    letter-spacing: 0px;
                    color: #323338;
                }
            }
        }

        .divider {
            border: 1px solid #DDDFE3;
            height: 30px;
        }

        .paginationStyle {
            text-align: left;
            font: normal normal normal 18px/24px Open Sans;
            letter-spacing: 0px;
            color: #323338;
        }

        .backStyle {
            margin-left: 20px;
            margin-top: 3px;
            transform: rotate(180deg);
        }

        .forwardStyle {
            margin-left: 50px;
        }

        .communication-header-container-right-actions {
            display: flex;
            align-items: center;
            gap: 8px;

            .search-trigger {
                background-color: $main-light-background !important;

                .ant-btn-icon {
                    svg>path {
                        fill: $white !important;
                    }
                }
            }
        }

        .users-info-container {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 10px;
            width: 100%;
            margin-right: 20px;
        }
    }

    &.dark-mode {
        background-color: $cardDarkBackgroundColor;
        color: $dark-text-color;
        box-shadow: none;

        .communication-header-container-left {

            .communication-icon,
            .communication-title {
                color: $dark-text-color;
                fill: $dark-text-color;
            }

            .communication-search-container {

                .customFilterComponent {
                    background-color: $cardDarkHeaderColor;

                    .customFilterContainer {
                        .LabelCustomFilterStyle {
                            color: $dark-text-color;
                        }
                    }

                    .ant-input {
                        background-color: $cardDarkBackgroundColor !important;
                        border-color: #acacca;
                        color: $dark-text-color;
                    }

                    .ant-picker {
                        background-color: $cardDarkBackgroundColor !important;
                        overflow: hidden;


                        .ant-picker-range-separator {
                            svg {
                                color: $dark-text-color;
                            }
                        }

                        .ant-picker-suffix svg path {
                            fill: $dark-text-color;
                        }

                        .ant-picker-input {
                            background-color: $cardDarkBackgroundColor;

                            input {
                                color: #f2f2f2;

                                &::placeholder {
                                    color: #acacca;
                                }
                            }



                            .ant-picker-clear {
                                background-color: $inputDarkColor;

                                svg {
                                    fill: #acacca;
                                }
                            }
                        }
                    }
                }

                .ant-select {
                    width: 450px;

                    .ant-select-selector {
                        background-color: $inputDarkColor;
                        color: $dark-text-color;

                        .ant-select-selection-placeholder {
                            color: $dark-text-color;
                        }
                    }
                }



                .searchIcon {
                    fill: $dark-text-color;
                }

                .optionsIcon {
                    &:hover {
                        background-color: rgba($dark-text-color, 0.2);
                    }

                    svg path {
                        fill: $dark-text-color;
                    }
                }
            }
        }

        .communication-header-container-right {
            .user-view-container {
                color: rgba($dark-text-color, 0.6);

                &.selected {
                    color: $dark-text-color;
                }
            }
        }
    }
}