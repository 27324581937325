.absoluteStepperContainer {
  width: 1490px;
  height: 80px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
  // margin: 0px 30px 5px 30px;
  pointer-events: none;
  overflow: hidden;
  box-shadow: 0px 1px 4px #00000029;
  .StepperCard {
    margin-bottom: 12px;
    width: 1440px;
  }
  .absuluteDividerStyle {
    min-height: 7px;
    width: 1480px;
    background: transparent
      linear-gradient(90deg, #ffffff 0%, #f1f3f4 51%, #ffffff 100%) 0% 0%
      no-repeat padding-box;
    opacity: 1;
  }
  .ant-steps-navigation .ant-steps-item-container .ant-steps-item-title {
    width: 150%;
    font-size: 16px !important;
    margin-left: 15px !important;
    color: #acacac !important;
  }
}
.AbsoluteHeaderComponentHeaderActions {
  position: relative;
  top: -100px;
  left: 1490px;
}
.AbsoluteHeaderComponentHeaderActionWindow {
  width: 140px;

  .ant-popover-arrow {
    display: none !important;
  }
  .ant-popover-inner {
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 5px;
    box-shadow: 0px 0px 8px -4px;
  }
  .ant-popover-inner-content {
    padding: 0 !important;
  }
}
// @media screen and (max-width: 1900px) {
//   .absoluteStepperContainer {
//     width: 750px;
//     .absuluteDividerStyle {
//       width: 700px;
//     }
//   }
//   .ant-steps-navigation .ant-steps-item-container .ant-steps-item-title {
//     width: 150%;
//     font-size: 11px;
//     margin-left: 40px;
//   }
//   .AbsoluteHeaderComponentHeaderActions {
//     position: relative;
//     top: -100px;
//     left: 750px;
//   }
// }
