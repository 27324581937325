@import url("https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap");

body {
  overflow: hidden;
}

.wrapper {
  background: #f8f8f8;
  height: 100%;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  /*z-index: 1000;*/
  display: flex;
}
.wrapper1 {
  background: #f8f9fe;
  height: 100%;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  /*z-index: 1000;*/
  display: flex;
}

.left-side,
.right-side {
  height: 100%;
}

.left-side {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  z-index: 0;
  /* background: rgb(8,42,227);
    background: linear-gradient(30deg, rgba(8,42,227,1) 0%, rgba(10,214,238,1) 100%); */
}

.left-side::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  /* background: linear-gradient(30deg, rgba(3, 32, 194, 0.75) 0%, rgba(0, 223, 253, 0.3) 100%); */
  z-index: -1;
}

.right-side {
  background: #fff;
  width: 30%;
  height: 50%;
  margin-top: 10%;
  display: flex;
  margin-bottom: 0px;
  margin-right: auto;
  margin-left: auto;
  flex-direction: column;
  justify-content: center;
}
.right-side1 {
  background: #f8f9fe;
  width: 30%;
  height: 50%;
  margin-top: 10%;
  display: flex;
  margin-bottom: 0px;
  margin-right: auto;
  margin-left: auto;
  flex-direction: column;
  justify-content: center;
}

.logo-container {
  width: 100%;
  text-align: center !important;
}

.logo-container img {
  height: 90px;
  margin-top: 4em;
  margin-left: 2em;
}

.right-side .form-container {
  padding: 2em;
  /* margin: 0 auto; */
}

.input-field-container {
  position: relative;
  height: 50px;
  padding-top: 10px;
  border: 2px solid #ededed;
  border-radius: 6px;
  padding-bottom: 16px;
}

.input-field-container .icon .fas {
  font-size: 17px;
  position: absolute;
  right: 1.25em;
  top: 50%;
  transform: translateY(-50%);
  color: #5a5a5a;
}

.input-field-container,
.input-field-container .input-label,
.input-field-container .input-field {
  -webkit-transition: cubic-bezier(0.165, 0.84, 0.44, 1) 320ms all;
  transition: cubic-bezier(0.165, 0.84, 0.44, 1) 320ms all;
}

.input-field-container .input-label {
  font-size: 0.9em;
  margin-left: 1em;
  position: absolute;
  top: 50%;
  padding: 5px;
  transform: translateY(-50%);
  color: #8a8a8a;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
}

.input-field-container .input-field {
  position: absolute;
  width: 90%;
  left: 0;
  top: 50%;
  height: 80%;
  left: 2px;
  border: none;
  transform: translateY(-50%);
  background: none;
  padding-left: 1.2em;
  padding-right: 1.2em;
  border-radius: 0;
  background: transparent;
  z-index: 2;
  font-size: 1em;
  font-family: "Roboto", sans-serif;
}

.input-field::placeholder {
  color: transparent;
}
.input-field:focus::placeholder {
  color: #8a8a8a;
}

.input-field-container .input-field:focus + label,
.input-field-container .input-field:not(:placeholder-shown) + label {
  top: -1px;
  background: white;
  color: black;
}

.input-field:focus ~ .icon .fas,
.input-field-container .input-field:not(:placeholder-shown) ~ .icon .fas {
  color: #f9db55;
}

.mat-error {
  color: rgb(243, 88, 88);
  transition: cubic-bezier(0.19, 1, 0.22, 1) all 320ms;
  font-weight: 500;
  padding-top: 2px;
  padding-left: 5px;
  font-family: "Roboto", sans-serif;
}

.justify-space-between {
  justify-content: space-between;
}

.login-btn {
  font-family: "Roboto", sans-serif;
  background: transparent;
  border: none;
  letter-spacing: 1px;
  padding: 0.5em 1.5em;
  border-radius: 36px;

  font-size: 1.05em;
  line-height: 1.2;
  font-weight: 700;
  background: #f9db55;
  color: black;
  /* color: rgb(20, 53, 240);
    border: 2px solid rgb(20, 53, 240); */

  -webkit-transition: cubic-bezier(0.19, 1, 0.22, 1) 320ms all;
  transition: cubic-bezier(0.19, 1, 0.22, 1) 320ms all;
}

.login-btn:hover,
.login-btn:focus {
  background: #f9db55;
  color: black;
}

input:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0px 9999px white;
}

@media (max-width: 769px) {
  .wrapper {
    display: block;
  }

  .right-side {
    height: auto;
    z-index: 9999;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    transform: translate(-50%, -50%);
  }

  .left-side {
    width: 100%;
    background-size: cover;
    background-position: center;
  }

  .form-container {
    width: 100% !important;
  }

  .input-field-container {
    height: 5em !important;
  }

  .input-label {
    font-size: 18px !important;
  }
}
