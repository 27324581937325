$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.docWarningsModal {
  .ant-modal-content {
    .ant-modal-body {
      display: flex;
      flex-direction: column;
      gap: 20px;
      .ant-tree-list-holder {
        max-height: 70vh;
        overflow-y: auto;
        .ant-tree-treenode {
          .ant-tree-node-content-wrapper {
            .treeLeafTitle {
              font-size: 14px;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}

.docWarningsModalDark {
  .ant-modal-content {
    .ant-tree {
      background-color: $cardModalBodyDark !important;
      .ant-tree-switcher {
        svg {
          fill: #fff;
        }
      }
      .ant-tree-node-content-wrapper {
        &:hover {
          background-color: #3a3c4e !important;
        }
      }
      .ant-tree-title {
        color: #fff;
      }
    }
  }
}
