@import "../../../../../index.scss";

.documentationPageCategorySelector {
  margin: 0;
  min-height: 60px;
  .categorySelectorContent {
    border-radius: 10px;
    overflow-y: hidden;
    overflow-x: auto;
    .categoryButtonsContainer {
      @include flex(flex-start, flex-start);
      gap: 20px;
    }

    .noDocsMessage {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      height: 40px;
      font-size: 18px;
    }
    .docCategoryButton {
      @include flex(center, center);
      @include text(18px, 600, 0, #fff);
      min-width: 150px;
      height: 40px;
      width: fit-content;
      white-space: nowrap;
      padding: 5px 10px;
      border-radius: 10px;
      background-color: #1264a3;

      // &:not(:last-child) {
      //   margin-right: 20px;
      // }

      &:hover {
        cursor: pointer;
        background-color: #0f5c97;
      }
    }

    .selectedDocCategory:hover {
      cursor: pointer;
      background-color: #1264a3;
    }

    .waitingDocCategory {
      background-color: #e6e9ef;
      color: #323338;

      &:hover {
        background-color: #1264a3;
        color: #fff;
      }
    }
  }
}

.documentationCategorySelectorDark {
  background-color: #12131b;
}

@media (max-width: 821px) {
  .documentationPageCategorySelector .docCategoryButton {
    min-width: 110px;
    font-size: 14px !important;
  }
  .approvalsPageControlPanel {
    // flex-wrap: wrap;
    padding: 10px;
    margin-bottom: 10px;
    gap: 5px;
  }
}
