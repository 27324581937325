.addressCardContainer {
  height: fit-content;
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  padding-right: 7%;
  display: flex;
  justify-content: baseline;
  align-items: center;
  flex-direction: column;

  .checkSameAsBilling {
    height: fit-content;
    padding-top: 0.3rem;
    padding-top: 0.3rem;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    color: #383554;

    .ant-checkbox {
      margin-left: 1rem;
    }
  }

  .addDetails {
    height: fit-content;
    overflow: visible;
    width: 100%;
    display: flex;
    justify-content: baseline;
    align-items: center;
    flex-direction: column;
  }

  .detailsContainer {
    width: fit-content;
    overflow: hidden;
    transition: height 0.5s ease-in-out;
  }

  .detailsShow {
    height: 280px;
    input {
      border-radius: 5px;
      border: none;
    }
  }

  .detailsHide {
    height: 0;
  }

  .removeIcon {
    position: relative;
    top: 0;
    left: 68%;
    color: #ababab;
    font-size: 14px;
    margin-right: 5px;
    cursor: pointer;
  }

  .addressCollapse {
    position: relative;
    top: 0;
    left: 70%;
    cursor: pointer;
  }

  .addHide {
    animation: hide 0.3s ease-in-out forwards;
  }

  .addShow {
    animation: show 0.3s ease-in-out forwards;
  }

  @keyframes show {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(90deg);
    }
  }

  @keyframes hide {
    from {
      transform: rotate(90deg);
    }
    to {
      transform: rotate(0deg);
    }
  }

  .label {
    font-size: 14px;
    font-weight: 600;
    color: #383554;
    margin-bottom: 5px;
    margin-left: 10px;
  }

  .address {
    width: 100%;
    margin-top: 1rem;
  }

  .apartment {
    width: 100%;
    margin-top: 1rem;
  }
  .street {
    width: 100%;
    margin-top: 1rem;
  }
  .city {
    width: 100%;
    margin-top: 1rem;
  }
  .additionalInfo {
    width: 100%;
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    .state {
      width: calc(50% - 10px);
    }
    .postalCode {
      width: calc(50% - 10px);
    }
  }
}
.addressCardContainerDark {
  .addDetails {
    .address .label {
      color: #fff;
    }
    .detailsContainer {
      .street,
      .city,
      .additionalInfo {
        .label {
          color: #fff;
        }
        .ant-input {
          background-color: #fff;
        }
      }
    }
  }
}

.confirmContainer {
  height: fit-content;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin-top: 3rem;
  background-color: #fff;

  .cancelButton {
    margin-right: 10px;
    background-color: #fe4c4a;
    .cancelButtonDivider {
      color: #f14543;
    }
  }
  .cancelButton:hover {
    background-color: #f14543;
    .cancelButtonDivider {
      color: #fe4c4a;
    }
  }
  .confirmButton {
    background-color: #71cf48;
  }
  .confirmButtonDivider {
    color: #69c242;
  }
  .confirmButton:hover {
    background-color: #69c242;
    .confirmButtonDivider {
      color: #71cf48;
    }
  }

  .viewButton {
    margin-right: 10px;
    background-color: var(--secondaryColor);

    .viewButtonDivider {
      color: #105386;
    }
  }
  .viewButton:hover {
    background-color: #105386;
    .viewButtonDivider {
      color: var(--secondaryColor);
    }
  }

  .buttonDisabled {
    margin-right: 10px;
    .viewButtonDivider {
      color: lightgray;
    }

    &:hover {
      .viewButtonDivider {
        color: lightgray;
      }
    }
  }
}

@media (max-width: 590px) {
  .addressContainer {
    .confirmContainer {
      height: fit-content;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      .styleInput {
        margin-bottom: 10px;
      }
    }
  }
}

@media (max-width: 450px) {
  .addressCardContainer {
    align-items: flex-start;
    .addDetails {
      width: 80%;
      justify-content: flex-start;
      // .address{
      //   width: 80%;
      // }
    }
  }
}
