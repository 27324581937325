@import "../../../../../../index.scss";

.priceTableSchemeModalContent {
  .core-information {
    @include flex(center, space-between);
    @include text(16px, 600, 0, #323338);

    .ant-select-selector {
      border: 0 !important;
      border-radius: 5px !important;
      background-color: #f4f5f6 !important;
      min-width: 200px;
    }
  }
}
