.automation-notification-config {
  width: 330px;
  overflow: auto;
  background-color: #f8f8fa;
  padding: 10px;
  border-radius: 5px;

  display: flex;
  flex-direction: column;
  gap: 10px;
  .automation-function {
    background: #ffffff;
    .custom-checkbox-wrapper {
      background: inherit;
    }
  }

  &.dark-mode {
    background: #2a2b3a;
    .automation-function {
      span {
        color: #ffffff;
      }
      background: #20212d;
    }
  }
}
