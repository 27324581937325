.session-logs-modal {
  width: fit-content !important;
  height: fit-content !important;
  // min-width: 65dvw;
  .ant-modal-content {
    height: fit-content !important;

    .ant-modal-header {
      height: 45px;
      display: flex;
      align-items: center;
      background-color: #f8f8fa;
      overflow: hidden;
      border: none;

      .ant-modal-title {
        font-size: 16px;
        width: 100%;
        font-weight: 600;
        color: #323338;
        padding: 0 !important;
        height: 45px;
        display: flex;
        align-items: center;
      }
    }
    .ant-modal-body {
      max-height: 85dvh;
      overflow: auto;
      .session-logs-modal-container {
        display: flex;
        flex-direction: column;
        gap: 10px;
        height: 100%;
        width: 100%;
      }
    }
    .ant-modal-footer {
      padding-bottom: 20px;
    }
  }
}
