:root {
  --header-height-px: 70px;
  --primary-background-color: "";
  --secondary-background-color: #fff;
  --sidebar-width-px: 250px;
}
$sidebar-width-px: 250px;
// .sidebarWrap {
// 	display: flex;
// 	position: fixed;
// 	height: 100vh;
// 	top: 0;
// 	left: 0;
// }
// .ant-tabs-content {
// 	position: unset !important;
// 	width: 100%;
// }

// // global css dont remove it
// .ant-tabs-content {
//   position: unset !important;
// }
