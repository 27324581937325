.formsModal {
  top: 0 !important;
  width: 99.9% !important;
  padding-bottom: 0 !important;
  *:not(.ag-icon):not(.ag-checkbox-input-wrapper.ag-checked:after) {
    font-family: "Lato", sans-serif !important;
  }
  .ant-modal-close-x {
    color: #f5f5f5;
  }
  .ant-modal-header {
    background-color: #f8f8f8;
    border-color: #4a4a4a;
    border-radius: 0;
    .ant-modal-title {
      color: #f5f5f5;
    }
  }
  .dataEntryTitle {
    font-size: 28px !important;
    margin-left: 25px;
    color: #f5f5f5;
  }

  .ant-switch {
    border-color: #4a4a4a;
  }
  .ant-modal-body {
    border-radius: 0;
    padding: 24px 0 0 0 !important;
    overflow: auto;
    height: calc(100vh - 57px) !important;
    background-color: #f8f8f8;
  }
  .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    background-color: #f5f5f5 !important;
    width: 3px;
  }
  .ant-tabs-bar {
    border-color: #4a4a4a !important;
  }
  .ant-tabs-tab {
    transition: 250ms;
    margin: 0 !important;
    padding: 0 !important;
    border-bottom: solid 1px #4a4a4a;
    &:first-child {
      border-top: solid 1px #4a4a4a;
    }
    .tabText {
      color: #aeaeae;
      transition: 200ms;
      font-weight: bold;
      font-size: 15px;
      writing-mode: vertical-rl;
      text-orientation: mixed;
      transform: rotate(180deg);
      &:hover {
        transition: 400ms;
        color: #f5f5f5;
      }
    }
  }
  .ant-tabs-tab-active {
    .tabText {
      color: #ffffff;
    }
  }
  .site-page-header {
    border: 2px solid rgb(235, 237, 240);
    color: #32325d;
    font-weight: 600;
    font-size: medium;
    vertical-align: middle;
    height: 35px;
  }
  .ant-tabs-content {
    border: 0;
  }
  .optionText {
    color: #f5f5f5;
    font-size: 20px;
    border-top: solid 1px #dfe6ee;
    margin-top: 15px;
    padding-top: 10px;
  }
  .ant-collapse {
    border-color: #dfe6ee;
    background-color: #ececec;
    .ant-collapse-item:hover {
      .ant-collapse-header {
        background-color: #dfe6ee;
      }
      .ant-collapse-content {
        background-color: #dfe6ee;
      }
      .elevationLabel {
        background-color: #000000;
      }
      .gridResizeHandler {
        background-color: #007ac3;
      }
    }
    .ant-collapse-header {
      height: 50px;
      transition: 300ms;
    }
    .ant-collapse-content {
      background-color: #f0f3f8;
      border-color: #4a4a4a;
      transition: 300ms;
    }
  }
  .ant-collapse-item {
    border-color: #4a4a4a;
  }
  .gridResizeHandler {
    transition: 300ms;
    background-color: transparent;
    padding-bottom: 2px;
    cursor: ns-resize;
    border-radius: 0 0 2px 2px;
    &:hover {
      padding-bottom: 5px;
      background-color: #12a3fa !important;
    }
    &:active {
      background-color: #09659b !important;
    }
  }
}

.docModal {
  top: 0 !important;
  width: 100% !important;
  padding-bottom: 0 !important;
  *:not(.ag-icon):not(.ag-checkbox-input-wrapper.ag-checked:after) {
    font-family: "Lato", sans-serif !important;
  }
  .ant-modal-close-x {
    color: #f5f5f5;
  }

  .ant-modal-header {
    background-color: #f8f8f8;
    border-color: #4a4a4a;
    border-radius: 0;
    .ant-modal-title {
      color: #f5f5f5;
    }
  }
  .dataEntryTitle {
    font-size: 28px !important;
    margin-left: 25px;
    color: #f5f5f5;
  }

  .ant-switch {
    border-color: #4a4a4a;
  }
  .ant-modal-body {
    border-radius: 0;
    padding: 24px 0 0 0 !important;
    overflow: auto;
    height: calc(100vh - 57px) !important;
    background-color: #f8f8f8;
  }
  .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    background-color: #f5f5f5 !important;
    width: 3px;
  }
  .ant-tabs-bar {
    border-color: #4a4a4a !important;
  }
  .ant-tabs-tab {
    transition: 250ms;
    margin: 0 !important;
    padding: 0 !important;
    border-bottom: solid 1px #4a4a4a;
    &:first-child {
      border-top: solid 1px #4a4a4a;
    }
    .tabText {
      color: #aeaeae;
      transition: 200ms;
      font-weight: bold;
      font-size: 15px;
      writing-mode: vertical-rl;
      text-orientation: mixed;
      transform: rotate(180deg);
      &:hover {
        transition: 400ms;
        color: #f5f5f5;
      }
    }
  }
  .ant-tabs-tab-active {
    .tabText {
      color: #ffffff;
    }
  }
  .site-page-header {
    border: 2px solid rgb(235, 237, 240);
    color: #32325d;
    font-weight: 600;
    font-size: medium;
    vertical-align: middle;
    height: 35px;
  }
  .ant-tabs-content {
    border: 0;
  }
  .optionText {
    color: #f5f5f5;
    font-size: 20px;
    border-top: solid 1px #dfe6ee;
    margin-top: 15px;
    padding-top: 10px;
  }
  .ant-collapse {
    border-color: #dfe6ee;
    background-color: #ececec;
    .ant-collapse-item:hover {
      .ant-collapse-header {
        background-color: #dfe6ee;
      }
      .ant-collapse-content {
        background-color: #dfe6ee;
      }
      .elevationLabel {
        background-color: #000000;
      }
      .gridResizeHandler {
        background-color: #007ac3;
      }
    }
    .ant-collapse-header {
      height: 50px;
      transition: 300ms;
    }
    .ant-collapse-content {
      background-color: #f0f3f8;
      border-color: #4a4a4a;
      transition: 300ms;
    }
  }
  .ant-collapse-item {
    border-color: #4a4a4a;
  }
  .gridResizeHandler {
    transition: 300ms;
    background-color: transparent;
    padding-bottom: 2px;
    cursor: ns-resize;
    border-radius: 0 0 2px 2px;
    &:hover {
      padding-bottom: 5px;
      background-color: #12a3fa !important;
    }
    &:active {
      background-color: #09659b !important;
    }
  }
}

.stepperModal {
  top: 0 !important;
  width: 58% !important;
  padding-bottom: 0 !important;

  *:not(.ag-icon):not(.ag-checkbox-input-wrapper.ag-checked:after) {
    font-family: "Lato", sans-serif !important;
  }
  .ant-modal-close-x {
    color: #f5f5f5;
  }
  .ant-modal-header {
    background-color: #f8f8f8;
    border-color: #4a4a4a;
    border-radius: 0;
    .ant-modal-title {
      color: #f5f5f5;
    }
  }
  .dataEntryTitle {
    font-size: 28px !important;
    margin-left: 25px;
    color: #f5f5f5;
  }

  .ant-switch {
    border-color: #4a4a4a;
  }
  .ant-modal-body {
    border-radius: 0;
    padding: 24px 0 0 0 !important;
    overflow: auto;
    height: 60%;
    background-color: #f8f8f8;
  }
  .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    background-color: #f5f5f5 !important;
    width: 3px;
  }
  .ant-tabs-bar {
    border-color: #4a4a4a !important;
  }
  .ant-tabs-tab {
    transition: 250ms;
    margin: 0 !important;
    padding: 0 !important;
    border-bottom: solid 1px #4a4a4a;
    &:first-child {
      border-top: solid 1px #4a4a4a;
    }
    .tabText {
      color: #aeaeae;
      transition: 200ms;
      font-weight: bold;
      font-size: 15px;
      writing-mode: vertical-rl;
      text-orientation: mixed;
      transform: rotate(180deg);
      &:hover {
        transition: 400ms;
        color: #f5f5f5;
      }
    }
  }
  .ant-tabs-tab-active {
    .tabText {
      color: #ffffff;
    }
  }
  .site-page-header {
    border: 2px solid rgb(235, 237, 240);
    color: #32325d;
    font-weight: 600;
    font-size: medium;
    vertical-align: middle;
    height: 35px;
  }
  .ant-tabs-content {
    border: 0;
  }
  .optionText {
    color: #f5f5f5;
    font-size: 20px;
    border-top: solid 1px #4a4a4a;
    margin-top: 15px;
    padding-top: 10px;
  }
  .ant-collapse {
    margin-top: 15px;
    border-color: #4a4a4a;
    background-color: #272b2d;
    .ant-collapse-item:hover {
      .ant-collapse-header {
        background-color: #ffffff;
      }
      .ant-collapse-content {
        background-color: #ffffff;
      }
      .elevationLabel {
        background-color: #ffffff;
      }
      .gridResizeHandler {
        background-color: #007ac3;
      }
    }
    .ant-collapse-header {
      color: #f5f5f5;
      height: 50px;
      transition: 300ms;
    }
    .ant-collapse-content {
      background-color: #f0f3f8;
      border-color: #4a4a4a;
      transition: 300ms;
    }
  }
  .ant-collapse-item {
    border-color: #ffffff;
  }
  .gridResizeHandler {
    transition: 300ms;
    background-color: transparent;
    padding-bottom: 2px;
    cursor: ns-resize;
    border-radius: 0 0 2px 2px;
    &:hover {
      padding-bottom: 5px;
      background-color: #12a3fa !important;
    }
    &:active {
      background-color: #09659b !important;
    }
  }
}
.smallModal {
  top: 30px !important;
  width: 700px !important;
  padding-bottom: 0 !important;
  *:not(.ag-icon):not(.ag-checkbox-input-wrapper.ag-checked:after) {
    font-family: "Lato", sans-serif !important;
  }
  .ant-modal-close-x {
    color: #f5f5f5;
  }
  .ant-modal-header {
    background-color: #f8f8f8;
    border-color: #4a4a4a;
    border-radius: 0;
    .ant-modal-title {
      color: #f5f5f5;
    }
  }
  .dataEntryTitle {
    font-size: 28px !important;
    margin-left: 25px;
    color: #f5f5f5;
  }

  .ant-switch {
    border-color: #4a4a4a;
  }
  .ant-modal-body {
    top: 15px;
    border-radius: 0;
    padding: 14px 0 0 0 !important;
    height: 500px;
    background-color: #f8f8f8;
  }
}
.rowClassName {
  display: flex;
  .LabelsContainer {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    height: 85vh;
    justify-content: center;
    width: 50%;
  }
  .SwitchContainer {
    display: flex;
    flex-direction: column;
    height: 85vh;
    justify-content: center;
  }
}
// .companyComp {
//   display: flex;
//   margin-top: 60px;
//   z-index: 10000000;
//   justify-content: center;
//   align-items: center;
//   .dropdownLogo {
//     margin-left: 10px !important;
//     width: 60px !important;
//     height: 30px !important;
//     filter: brightness(10) !important;
//   }
//   .companyOptionStyle {
//     padding: 0px !important;
//     padding-bottom: 3px !important;
//     background-color: transparent !important;
//   }
//   .ant-select-selector {
//     position: relative !important;
//     background-color: #f6f6f6 !important;
//     border-radius: 8px !important;
//     margin-top: -10px !important;
//     width: 300px !important;
//     min-width: 161px !important;
//     height: 40px !important;
//     border: 0px !important;
//     padding: 0 !important;
//     transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
//   }
//   .ant-select-arrow {
//     margin-top: -10px !important;
//     margin-right: 0px !important;
//     color: white !important;
//     z-index: 3 !important;
//   }
//   .ant-select {
//     box-sizing: border-box !important;
//     margin: 0 !important;
//     padding: 0 !important;
//     width: "100%" !important;
//     color: transparent !important;
//     font-size: 14px;
//     font-variant: tabular-nums !important;
//     line-height: 1.5715 !important;
//     list-style: none !important;
//     font-feature-settings: "tnum", "tnum" !important;
//     position: relative !important;
//     display: inline-block !important;
//     cursor: pointer !important;
//     z-index: 2 !important;
//   }
//   .ant-select-selection-item {
//     color: transparent !important;
//     z-index: 1000 !important;
//   }
// }
.ant-select-dropdown {
  z-index: 10000;
}

.choose_company_entry {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  background: url(../../../icons/Auth_icons/background.png) no-repeat center
    center fixed;
  background-size: cover;
  z-index: 9980;
  .welcome_info {
    width: 50%;
    padding: 0 8rem;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    h3 {
      color: #1f2a44;
      font-size: 28px;
    }
    h2 {
      color: #1f2a44;
      font-size: 33px;
      font-weight: 600;
      letter-spacing: -1px;
    }
  }
  .companyComp {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    div {
      display: flex;
      width: 420px;
      height: 50px;
      align-items: center;
      padding-left: 25px;
      border-radius: 12px;
      cursor: pointer;
      margin: 12px 0;
      &:hover {
        box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.2);
        transition: 0.1.5s;
      }
    }
    .dropdownLogo {
      width: 80px;
      height: 38px;
    }
    span {
      color: #fff;
      margin-left: 10px;
      font-size: 1.25rem;
    }
  }
  .core_link_container {
    width: auto;
    position: absolute;
    display: flex;
    align-items: center;
    bottom: 0;
    margin-bottom: 15px;
    img {
      height: 13px;
    }
    .ant-btn-text {
      padding: 0 5px;
      color: #1f2a44;
      font-size: 1rem;
      &:hover {
        background: transparent;
      }
    }
  }
}

.forecast-weather-modal {
  .ant-modal-header {
    width: 800px;
    height: 40px;
  }
  .ant-modal-content {
    width: 800px;
  }
}
