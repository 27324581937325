@use "/src/index.scss" as index;

.schedule-info-window {
  min-width: 420px;
  border-radius: 5px;
  box-shadow: 0px 0px 4px 0px #231f20;
  overflow: hidden;
  font-size: 14px;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif !important;
  color: #231f20;
  .info-header {
    height: 34px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f4f5f6;
    padding: 0px 10px;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    .info-title {
      color: #231f20;
    }
    svg {
      fill: #231f20;
      cursor: pointer;
    }
  }
  .info-body {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 10px;
    font-weight: 400;
    .status-pin {
      height: 8px;
      width: 8px;
      border-radius: 50%;
    }
    .location-information {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 10px;
      svg {
        cursor: pointer;
      }
    }
    .info-data {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      label {
        font-weight: 600;
      }
      .schedule-status {
        display: flex;
        align-items: center;
        gap: 5px;
      }
      .custom-progress-bar {
        height: 24px;
        border-radius: 2px;
        background-color: #f4f5f6 !important;
        overflow: hidden;
        cursor: default !important;
        .progress-done {
          height: 24px !important;
          border-radius: 0px;
          background-color: #f04f4e !important;
        }
      }
    }
    .schedule-days {
      width: 100%;
      .days-container {
        width: 100%;
        max-height: 128px;
        display: flex;
        flex-direction: column;
        gap: 5px;
        padding: 5px;
        overflow-y: auto;
        margin-top: 5px;
        border-radius: 5px;
        &.scroll {
          background-color: #f4f5f6;
          padding-right: 0px;
        }
        .day-row {
          height: 19px;
          display: flex;
          align-items: center;
          gap: 10px;
          .day-status {
            display: flex;
            align-items: center;
            gap: 5px;
          }
          .day-separator {
            height: 15px;
            width: 2px;
            background-color: #e6e9ef;
          }
        }
      }
    }
  }
}

.schedule-info-window-dark {
  background-color: index.$mainDarkBackgroundColor;
  box-shadow: 0px 0px 4px 0px #f4f5f6;
  .info-header {
    background-color: index.$cardDarkBackgroundColor;
    .info-title {
      color: #fff;
    }
    svg {
      fill: #fff !important;
    }
  }
  .info-body {
    color: #fff;
    .schedule-days {
      .days-container {
        &.scroll {
          background-color: index.$cardDarkHeaderColor;
        }
        .day-row {
          .day-separator {
            background-color: #41435d;
          }
        }
      }
    }
    .info-data {
      .custom-progress-bar {
        background-color: #3a3c4e !important;
      }
    }
  }
}
