.reminderModal {
  .ant-modal-body {
    padding: 20px !important;
  }
  .body {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .information {
      display: flex;
      flex-direction: row;
      gap: 10px;
      // flex-wrap: wrap;
      align-items: center;
    }
    .content {
      display: flex;
      flex-direction: row;
      // gap: 110px;
      flex-wrap: wrap;
      height: 100%;
      flex: 1 1;
      justify-content: space-between;
      width: 100%;
      .task-deadline {
        .calendar-container,
        .timeDatePicker {
          // flex-wrap: wrap;
          height: 100%;
          box-shadow: none;
          background: #f8f8fa;
          border-radius: 10px;

          .selectTimeText {
            padding-top: 20px;
            letter-spacing: 0;
            padding-bottom: 20px;
            padding-left: 0px;
            p {
              padding-top: 0px !important;
            }
          }
          .weekContainer {
            margin-bottom: 0px;
          }

          .weekContainer2::after {
            border: 0px dashed #707070;
            margin: 0px;
          }
          .weekContainer2 {
            .day {
              margin-bottom: 20px;
              font-size: 14px;
            }
          }

          .timeButtons {
            .timeButtonsActive {
              background: #e2e9f8;
            }
            .hour {
              font-size: 40px !important;
            }
            .minute {
              font-size: 40px !important;
            }
          }
          .timeButtons > input {
            border: 0px solid;
            font: Open Sans 600 40px;
            font-size: 40px !important;
          }

          .clock {
            background-color: #ffffff;
          }
        }
      }
      .reminderDays {
        padding: 10px;
        // max-width: 307px;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
        background: #f8f8fa;
        max-height: 380px;
        overflow-y: auto;
        border-radius: 10px;
        height: 100%;
        .reminderDay {
          display: flex;
          align-items: flex-start;
          width: 100%;
          height: 100%;
          align-items: center;
          font-weight: 600;
          gap: 10px;
          .dayName {
            cursor: pointer;
          }
        }
        .actionSpanRed {
          background: #fe4c4a 0% 0% no-repeat padding-box;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 5px;
          cursor: pointer;
          height: 32px;
          width: 32px;
        }
      }
    }
  }
  .footerContainer {
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 10px;
    padding-bottom: 0px;
    .footer {
      display: flex;
      flex: 1 1;
      justify-content: center;
      flex-direction: row;
      gap: 10px;
    }
  }
}
