.proposal-builder-sidebar_wrapper {
  gap: 2px;
  left: 0px;
  display: flex;
  margin-top: 86px;
  overflow-y: auto;
  position: absolute;
  flex-direction: row;
  background-color: transparent;
  height: calc(100dvh - 225px) !important;

  .sidebar-content {
    width: 52px;
    height: 100%;
    display: flex;
    overflow-y: auto;
    overflow-x: hidden;
    align-items: center;
    border-radius: 10px;
    flex-direction: column;
    background-color: #fff;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15);

    .sidebar-item {
      width: 100%;
      height: 44px;
      display: grid;
      cursor: pointer;
      place-items: center;
      transition: background-color 0.3s;
      &:hover {
        background-color: #f1f5fd;
      }
      svg {
        fill: #323338;
      }
    }
    .sidebar-item.selected {
      background-color: #f1f5fd;
      svg {
        fill: #1264a3;
      }
    }
  }
  .main-content {
    gap: 10px;
    z-index: 10;
    width: 450px;
    padding: 10px;
    display: flex;
    overflow-y: auto;
    position: relative;
    height: fit-content;
    border-radius: 10px;
    flex-direction: column;
    background-color: #fff;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15);
    .main-content-title {
      font-size: 14px;
      font-weight: 600;
    }
  }
}

// .proposal-builder_custom_variable_card {
//   height: 50px;
//   width: 60px;
//   background-color: grey;
//   width: 45%;
// }

// .cardProposalDragger {
//   width: 300px;
//   border: 1px solid #ccc;
//   border-radius: 8px;
//   overflow: hidden;
//   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
//   margin-bottom: 20px;
//   background-color: #fff;

//   .card-image {
//     width: 100%;
//     height: auto;
//     display: block;
//   }

//   .card-content {
//     padding: 20px;
//   }

//   .card-title {
//     font-size: 1.5rem;
//     margin-bottom: 10px;
//   }

//   .card-description {
//     font-size: 1rem;
//     color: #666;
//   }
// }
