@import "../../../../../../../../../../../index.scss";

$inputModalDark: #12131b;

.select-price-configurator {
  @include flex(center, center, column);
  gap: 10px;

  .ant-input-number,
  .ant-input {
    width: stretch;
    border: 0 !important;
    border-radius: 5px !important;
    background-color: #f4f5f6 !important;
  }

  .select-price-option {
    @include flex(flex-center, center);
    gap: 10px;
  }

  .input-container {
    @include flex(center, center);
    gap: 10px;
  }

  .option-container {
    @include text(16px, 600, 0, #323338);
  }

  .action-buttons-container {
    @include flex(center, center);
    gap: 10px;
  }

  .action-button {
    @include flex(center, center);

    border-radius: 5px;
    height: 30px;
    width: 30px;
    svg {
      fill: #fff;
    }
  }

  .add {
    @include backgroundColor(#71cf48, #68c142, pointer);
  }
  .delete {
    @include backgroundColor(#fe4c4a, #eb4345, pointer);
  }
}

.select-price-configurator-dark {
  .ant-input, .ant-input-number {
    background-color: $inputModalDark !important;
    color: #fff;
    &::placeholder {
      color: #acacca;
    }
    .ant-input-number-input {
      color: #fff;
      &::placeholder {
        color: #acacca;
      }
    }
  }
}
