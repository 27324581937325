.informationHolder {
  height: 275px;
  width: 600px;
  border-radius: 10px;
  margin: 10px 20px 10px 10px;
  box-shadow: 0px 1px 4px #00000029;
  padding: 7px 20px;
  overflow-x: hidden;
  overflow-y: auto;

  .informationDataHolder {
    overflow-x: hidden;
    overflow-y: auto;
    margin-bottom: 20px;

    .gap {
      width: 7px;
    }

    .estimationSelected {
      width: 100%;
      background-color: #1890ff;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px 10px;
      border-radius: 5px;
    }

    .titleHolderInformation {
      font-size: 16px;
      font-weight: 600;
      margin-top: 5px;
      display: flex;
      align-items: center;
    }

    .contentHolderInformation {
      display: flex;
      margin-left: 10px;
      .priceHolder {
        color: #71cf48;
      }
    }
  }

  .select-options-information-title {
    font-size: 22px;
    font-weight: 500;
  }
}
