$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.analyticsCostDistributionModal {
  width: fit-content !important;
  .ant-modal-content {
    height: calc(100vh - 20px);
    height: calc(100dvh - 20px);
    max-height: 800px;
    width: calc(100vw - 20px);
    width: calc(100dvw - 20px);
    max-width: 1600px;

    .ant-modal-close {
      height: 45px;
      width: 45px;
      .ant-modal-close-x {
        height: 45px;
        width: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          fill: #333238;
        }
      }
    }
    .ant-modal-header {
      height: 45px;
      display: flex;
      align-items: center;
      background-color: #f5f5f7;
      .ant-modal-title {
        font-size: 16px;
        font-weight: 600;
        color: #333238;
      }
    }

    .ant-modal-body {
      height: calc(100% - 150px);

      .ant-form {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: fit-content;
        width: 100%;
        flex-direction: row;
        gap: 1rem;
      }

      .cost-dispersion-grid {
        height: 100%;
        width: 100%;
      }
      .ag-root-wrapper {
        // height: 100%;
        // width: 100%;
        // max-width: 100%;

        // .ag-root-wrapper-body {
        //   height: 100% !important;
        // }

        .ag-header {
          position: sticky;
          top: 0;

          .ag-header-cell {
            background-color: #f8f8fa !important;
          }
        }
        .ag-cell-expandable {
          width: 100%;
        }
      }

      .ag-header-container,
      .ag-header-row {
        background-color: #32333806;
      }
    }
  }
}

.analyticsCostDistributionModalDark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      .labeledInputLabel {
        color: #fff;
      }
      .ant-picker {
        background-color: $cardModalHeaderDark !important;
        .ant-picker-input {
          background-color: $cardModalHeaderDark;
          input {
            color: #f2f2f2;
            &::placeholder {
              color: #acacca;
            }
          }
          .ant-picker-suffix svg {
            color: #acacca;
          }
          .ant-picker-clear {
            background-color: $cardModalHeaderDark;
            svg {
              fill: #acacca;
            }
          }
        }
      }
      .ant-picker-disabled {
        .ant-picker-input input {
          color: #acacca !important;
        }
      }
      .ant-btn:disabled {
        border: 1px solid #acacca;
        color: #acacca;
      }
      .ag-root-wrapper .ag-header .ag-header-cell {
        background-color: $cardModalHeaderDark !important;
      }
      .cost-dispersion-grid {
        .ag-header-row {
          border-top: none;
        }
      }
    }
  }
}
