@import "../../../../../../../../../../../../../../index.scss";

.richTextModalContainer {
  display: flex;
  justify-content: center;
  .openNotesButton {
    @include mondayButton(#1264a3, #0f5c97, #fff);

    &:disabled {
      @include mondayButton(#0b3e64, #0f5c97, #548bb3);

      svg {
        fill: #548bb3;
      }
    }
  }

  svg {
    width: 20px;
    height: 20px;
  }
}

.richTextNotesButtonRentalContainer {
  display: flex;
  justify-content: flex-end;
  .richTextCloseRentalMondayButton {
    @include mondayButton(#fe4c4a, #eb4345, #fff);
    margin-right: 15px;
    margin-bottom: 4px;
  }

  .richTextSaveRentalMondayButton {
    @include mondayButton(#71cf48, #68c142, #fff);
    margin-right: 4px;
    margin-bottom: 4px;
  }
}
