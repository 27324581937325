.informationHeader {
  width: stretch;
  height: 40px;
  box-shadow: 0px 1px 4px #00000029;
  border-radius: 10px;
  display: flex;
  gap: 15px;
  align-items: center;
  padding: 0 15px;
  font-size: 14px;
  font-weight: 400;

  .selectedButton {
    border-radius: 5px;
    border: 1px solid #1890ff;
    background-color: #1890ff;
    color: white;
    padding: 0 10px;
    height: 32px;
  }

  .unselectedButton {
    border-radius: 5px;
    border: 0;
    background-color: white;
    border: 1px solid #1890ff;
    color: #1890ff;
    padding: 0 10px;
    height: 32px;
  }
}
