.editMembersContent {
  display: flex;
  flex-direction: column;
  padding: 20px 0px 20px 0px;
  .editMembersContentElementContainer {
    display: grid;
    max-height: fit-content;
    overflow-y: auto;
    grid-template-columns: 0.8fr 1.2fr;
    .editMembersContentElementContainerTitle {
      text-align: left;
      margin-bottom: 10px;
      font: normal normal normal 20px/27px Open Sans;
      letter-spacing: 0px;
      color: #323338;
      opacity: 1;
    }
    .editMembersContentElementContainer {
      @extend .editMembersContentElementContainerTitle;
      color: #767b81;
      margin-bottom: 10px;
    }
  }
  .confirmContainer {
    display: flex;
    margin-top: 20px;
    justify-content: center;
    .cancelButton {
      margin-right: 10px;
      background-color: #fe4c4a;
    }
    .cancelButtonDivider {
      color: #f14543;
    }
    .cancelButton:hover {
      background-color: #f14543;
      .cancelButtonDivider {
        color: #fe4c4a;
      }
    }
    .confirmButton {
      margin-right: 10px;
      background-color: #71cf48;
    }
    .confirmButtonDivider {
      color: #69c242;
    }
    .confirmButton:hover {
      background-color: #69c242;
      .confirmButtonDivider {
        color: #71cf48;
      }
    }
  }
}

.swalMembersCard {
  padding: 20px;
}
