.geoFencStepContainer {
  .geofencestep-card-container {
    border-radius: 10px;
    box-shadow: 0px 1px 4px #00000029;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    flex: 1 1;
    min-width: 49%;
    .geofencestep-card-header-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 45px;
      background-color: #f8f8fa;
      border-bottom: 0;
      font-size: 16px;
      color: #323338;
      font-weight: 700;
      padding: 10px 20px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
  }
}
