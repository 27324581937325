$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.crewTeamModal {
  width: calc(100vw - 20px) !important;
  height: calc(100vh - 20px) !important;
  max-height: 735px;
  max-width: 1000px;
  .ant-modal-content {
    width: calc(100vw - 20px) !important;
    height: calc(100vh - 20px) !important;
    max-height: 735px !important;
    max-width: 1000px !important;
    .ant-modal-close {
      height: 45px;
      width: 45px;
      .ant-modal-close-x {
        height: 45px;
        width: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          fill: #333238;
        }
      }
    }
    .ant-modal-header {
      height: 45px;
      display: flex;
      align-items: center;
      background-color: #f5f5f7;
      .ant-modal-title {
        color: #333238;
        font-size: 16px;
        font-weight: 600;
        width: 100%;
      }
    }
    .ant-modal-body {
      height: calc(100% - 100px);
      .createNewCrewInfo {
        display: flex;
        align-items: center;
        width: 100%;
        // height: 38px;
        gap: 10px;
        padding-bottom: 20px;
        .createNewCrewText {
          width: 915px;
          height: 38px;
          font-size: 14px;
          font-weight: 600;
          .createNewCrwBtn {
            color: #1264a3;
            cursor: pointer;
          }
        }
      }

      .ant-form {
        height: 100%;
        display: flex;
        .fields-section {
          height: calc(100% - 32px);
          width: 100%;
          display: flex;
          align-items: center;
          flex-direction: column;
          gap: 50px;
          .team-fields {
            width: 100%;
            height: 53px;
            display: grid;
            gap: 30px;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            .crewTeamInput {
              .labeledInputLabel {
                font-size: 14px;
              }
            }
          }
          .members-container {
            width: 77.6%;
            height: 100%;
            max-height: 407px;
            .labeledInputContainer {
              width: 100%;
              max-width: 300px;
              .ant-input-affix-wrapper {
                background-color: #fff;
                .ant-input {
                  background-color: #fff;
                }
              }
            }

            .source,
            .transferredKeys {
              row-gap: 10px;
              .itemCounter {
                user-select: none;
                font-size: 14px;
              }
              .itemsContainer,
              .transferredItemContainer {
                padding: 10px;
                height: calc(100% - 32px);
                width: 100%;
                background-color: #f8f8fa;
                border-radius: 5px;
              }

              .listContainer {
                row-gap: 5px;
              }
            }
          }
        }

        .custom-checkbox-wrapper {
          width: 100%;
          max-width: 300px;
          background-color: #ffffff !important;
        }

        .fields-section-mobile {
          display: flex;
          flex-direction: column;

          .team-fields {
            display: flex;
            flex-direction: column;
            width: 100%;

            .labeledInputContainer {
              width: 100%;
            }
          }

          .members-container {
            width: 100%;
            height: 140px;
            overflow-y: scroll;
          }
        }
      }
    }
    .ant-modal-footer {
      .mondayButtonStyle:not(.smallButton) {
        width: 150px;
      }
      .createCrewFooter {
        display: flex;
        justify-content: space-between;
      }
      .editCrewFooter {
        display: flex;
        gap: 10px;
      }
    }
  }
}

.crewTeamModalDark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      .createNewCrewInfo,
      .labeledInputLabel {
        color: #fff;
      }
      .ant-input-affix-wrapper {
        background-color: $inputModalDark !important;
      }
      .ant-input {
        background-color: $inputModalDark !important;
        color: #fff;
        &::placeholder {
          color: #acacca;
        }
      }
      .ant-input-disabled {
        color: #acacca;
      }
      .ant-select {
        .ant-select-selector {
          background-color: $inputModalDark !important;
          .ant-select-selection-overflow-item {
            .ant-select-selection-item {
              background-color: #333238;
            }
            .ant-select-selection-item-remove svg {
              fill: #fff;
            }
          }
          .ant-select-selection-search-input {
            color: #fff;
          }
          .ant-select-selection-item {
            color: #fff;
          }
          .ant-select-selection-placeholder {
            color: #acacca;
          }
        }
        .ant-select-clear {
          background-color: $inputModalDark;
          svg {
            fill: #acacca;
          }
        }
        .ant-select-arrow svg {
          fill: #fff;
        }
      }
      .ant-checkbox-wrapper {
        background-color: $inputModalDark;
      }
      .ant-select-disabled {
        .ant-select-selector {
          .ant-select-selection-search-input {
            color: #acacca !important;
          }
          .ant-select-selection-item {
            color: #acacca !important;
          }
        }
      }
    }
  }
}
