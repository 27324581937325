.rentalsBodyResizable {
  position: relative;
  width: stretch !important;

  &:hover {
    .rentalsBodyResizableGripContainer {
      opacity: 1;
      transition: opacity 0.3s;
    }
  }
}

.agGridRentalsDetailsStyleDark .ag-cell.ag-cell-range-selected {
  border-color: #4a4a4a !important;
  border: 1px solid white !important;
}
.agGridRentalsDetailsStyleLight .ag-cell.ag-cell-range-selected {
  border-color: #4a4a4a !important;
  border-top: 1px solid lightcoral !important;
}
.rentalExpandPanelDark {
  border: none !important;
  background-color: #292b3a !important;
  .ant-collapse-content-box,
  .ant-collapse {
    background-color: #292b3a !important;
    border: none !important;
    .ant-collapse-header {
      ant-collapse-expand-icon {
        svg,
        path {
          fill: white !important;
          color: white !important;
        }
      }
    }
  }
}
.RentalBreakdownDark {
  color: white !important;
  background-color: #12131b;

  .ant-collapse {
    .ant-collapse-item {
      .ant-collapse-header {
        svg.ant-collapse-arrow {
          fill: white !important;
        }
        .ant-collapse-expand-icon {
          color: white !important;
        }
        .ant-collapse-header-text {
          color: white;
          fill: white;

          svg {
            fill: white;
          }
        }
      }
    }
  }
}
