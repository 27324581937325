.applications-service-list {
  width: stretch;
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin: 10px 30px;
  .applications-service-list__toolbar {
    // background-color: red;
    display: flex;
    height: 20px;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    .mondayButtonBlue {
      height: 20px;
    }
  }
  .includedRentalsMainHeaderDark {
    width: stretch;
    height: 70px;
    background-color: #12131b;
    border-radius: 10px;
    font-family: "Helvetica";
    color: white;
    display: flex;
    align-items: center;
    padding: 0 20px;
    font-size: 18px;
    box-shadow: 0 3px 5px rgb(0 0 0 / 16%);
    font-family: "Helvetica";
  }

  .includedRentalsMainHeaderLight {
    width: stretch;
    height: 70px;
    background-color: #1264a3;
    border-radius: 10px;
    font-family: "Helvetica";
    color: white;
    display: flex;
    align-items: center;
    padding: 0 20px;
    font-size: 18px;
    box-shadow: 0 3px 5px rgb(0 0 0 / 16%);
    font-family: "Helvetica";
  }
}
