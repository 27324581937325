@import "../../../../../config.scss";
@import "../../../../../index.scss";

$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.FilterModalMask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1031;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(33, 37, 75, 0.4) !important;
  backdrop-filter: blur(5px) !important;

  .FilterModalComponent {
    z-index: 1032;
    position: absolute;
    cursor: auto;
    // max-width: 1200px;
    // min-width: 500px;
    height: fit-content;
    margin: auto;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 10px;

    .FilterModalComponentHeader {
      height: 45px;
      background: #f7f8fa 0% 0% no-repeat padding-box;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .ModalTitle {
        text-align: left;
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #323338;
        opacity: 1;
        margin-left: 25px;
      }
      .clearIcon {
        width: 17px;
        height: 17px;
        margin-right: 15px;
        opacity: 1;
        cursor: pointer;
      }
    }
    .FilterModalComponentBody {
      width: fit-content;
      height: fit-content;
      padding-top: 20px;
      .ant-select-arrow::before {
        display: none !important;
      }
      .FilterModalComponentBodyContainer {
        display: flex;
        flex-direction: column;
        padding: 20px;
        // justify-content: space-between;
        // align-items: center;
        .FilterName {
          font-family: "Open Sans";
          // font: normal normal 600 16px Open Sans;
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
          color: #323338;
          height: fit-content;
          // position: relative;
          // top: 42px;
          // left: 40px;
        }
        .FilterModalComponentBodyContainerRightDivider {
          // height: 200px;
          width: 2px;
          // border: 1px solid #c9c9ca;
          // margin-left: auto;
        }
        .FilterModalComponentBodyContainerRight {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-top: 30px;
          .FilterModalComponentBodyContainerRightDateRangeContainer {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            

            .nameLables {
              font: normal normal 600 16px/7px Open Sans;
              color: #323338;
              position: relative;
              bottom: 2px;
            }
            .FilterModalComponentBodyContainerRightDatePickerContainer {
              display: flex;
              justify-content: center;
              flex-direction: column;
              gap: 15px;
              // position: absolute;
              // top: 34.5%;
              // right: 1%;
              // flex-wrap: wrap;
              // gap: 15px;
              // flex: auto;
            }
            .FilterModalComponentBodyContainerDropdownElement {
              margin-left: 10px;
              margin-right: 10px;
              .FilterName {
                font: normal normal 600 16px/7px Open Sans;
                letter-spacing: 0px;
                color: #323338;
              }
            }
            .ant-picker {
              margin: 0px;
              background: #f5f5f7 0% 0% no-repeat padding-box;
              border-radius: 5px;
              width: 255px;
              height: 32px;
              .anticon-calendar {
                display: none;
              }
              .ant-picker-suffix svg path {
                fill: #333238;
              }
            }
            .ant-picker::after {
              content: "";
              width: 1px;
              // height: 35px;
              display: block;
              position: relative;
              background: #c9c9ca 0% 0% no-repeat padding-box;
              right: 25px;
            }
            .FilterModalComponentBodyContainerRightDateRangeComponents {
              display: flex;
              justify-content: center;
              align-content: center;
              height: fit-content;
              gap: 1rem;
              // padding-left: 90px;
              // margin-bottom: 10px;
              .FilterModalComponentBodyContainerRightDateRangeComponentsIcons {
                display: flex;
                padding-bottom: 10px;
                align-items: center;
              }
              .ant-picker {
                // width: 255px;
                height: 32px;
                // margin-top: 33px;
                position: relative;
                // right: 20px;
                .anticon-calendar {
                  display: none;
                }
                // .ant-picker-suffix::before {
                //   content: url("../../src/calendarIcon.svg");
                //   display: block;
                //   // margin-left: calc(100% - 15px);
                // }
              }
              .ant-picker::after {
                content: "";
                width: 1px;
                display: block;
                position: relative;
                background: #c9c9ca 0% 0% no-repeat padding-box;
                // right: 25px;
              }
            }
            .FilterModalComponentBodyContainerRightDateRangeContainerCategories {
              display: flex;
              align-self: flex-end;
              gap: 1rem;
              // position: relative;
              // top: 52px;
              // right: 10px;
              // flex-direction: row;
              .previousButton {
                width: 35px;
                height: 35px;
                background: #f5f5f7 0% 0% no-repeat padding-box;
                border-radius: 5px;
                // margin: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                position: relative;
                // bottom: 10px;
                svg {
                  fill: #323338;
                }
              }

              .nextButton {
                @extend .previousButton;
              }
              .ant-form-item .ant-select .ant-input {
                width: 255px !important;
                height: 32px;
              }
              .ant-select-selector {
                background: #f5f5f7 0% 0% no-repeat padding-box;
                border-radius: 5px;
                height: 35px;
              }
              .ant-select-arrow {
                display: flex;
                .ant-select-suffix {
                  display: none;
                }
              }

              // .ant-select-arrow::after {
              //   content: url("../../src/dropdownIcon.svg");
              //   display: block;
              //   margin-left: calc(100% - 15px);
              //   width: 11px;
              //   height: 7px;
              // }
              .ant-select-arrow::before {
                content: "";
                width: 1px;
                height: 35px;
                display: block;
                position: relative;
                margin-top: -11px;
                background: #c9c9ca 0% 0% no-repeat padding-box;
                left: 640px;
                top: 10px;
              }
            }
          }
        }
        .FilterModalComponentBodyContainerLeft {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          // grid-template-rows: repeat(3, 100%);
          // display: flex;
          // justify-content: center;
          // flex-wrap: wrap;
          gap: 10px;
          // flex: 1 1 50%;
          // margin: auto;
          .FilterModalComponentBodyContainerDropdownElement {
            margin-left: 20px;
            margin-right: 20px;
            .FilterName {
              text-align: left;
              font: normal normal 600 16px/7px Open Sans;
              letter-spacing: 0px;
              color: #323338;
            }
            .location {
              width: 350px !important;
              .ant-select-selector {
                width: 350px !important;
              }
            }
            .ant-form-item .ant-select {
              margin-top: 2px;
              width: 255px;
              height: 32px;
            }

            .ant-select-selector {
              // display: flex;
              // flex-direction: row;
              // gap: 10px;
              background: #f5f5f7 0% 0% no-repeat padding-box;
              // border: 1px solid #707070;
              border-radius: 5px;
            }
            .ant-select-arrow {
              display: flex;
              .ant-select-suffix {
                display: none;
              }
            }
            // .ant-select-arrow::before {
            //   content: "";
            //   width: 1px;
            //   height: 35px;
            //   display: block;
            //   position: absolute;
            //   margin-top: -11px;
            //   background: #707070 0% 0% no-repeat padding-box;
            //   right: 25px;
            // }
          }
        }
      }
    }
    .FilterModalComponentFooter {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 1em;
      // margin: 25px;
      padding: 20px;
      .filterButton {
        // margin-right: 25px;
        @include mondayButton(#1264a3, #0f5c97);

        .mondayButtonText {
          text-align: left;
          font: normal normal 400 14px/7px Open Sans;
          letter-spacing: 0px;
          color: #fff;
          opacity: 1;
        }
      }
      .clearButton {
        @include mondayButton(#f5f5f7, #e6e9ef);

        .mondayButtonText {
          text-align: left;
          font: normal normal 400 14px/7px Open Sans;
          letter-spacing: 0px;
          color: #323338;
          opacity: 1;
        }
        .mondayButtonIcon {
          fill: #323338;
        }
      }
      .clearButtonReverse {
        @include mondayButton(#fe4c4a, #eb4345);

        .mondayButtonText {
          text-align: left;
          font: normal normal 400 14px/7px Open Sans;
          letter-spacing: 0px;
          color: #fff;
          opacity: 1;
        }
        .mondayButtonIcon {
          fill: #fff;
        }
      }
    }
  }
  .FilterModalComponentDark {
    background-color: $cardModalBodyDark;
    .FilterModalComponentHeader {
      background-color: $cardModalHeaderDark;
      .ModalTitle {
        color: #f2f2f2;
      }
      .clearIcon svg {
        fill: #f2f2f2;
      }
    }
    .FilterModalComponentBody {
      .FilterModalComponentBodyContainer {
        .FilterName {
          color: #f2f2f2 !important;
        }
        .FilterModalComponentBodyContainerLeft {
          .FilterName {
            color: #f2f2f2 !important;
          }
        }
        .FilterModalComponentBodyContainerRight {
          .FilterName {
            color: #f2f2f2 !important;
          }
          .FilterModalComponentBodyContainerRightDateRangeContainer {
            .previousButton,
            .nextButton {
              background-color: $inputModalDark !important;
              svg {
                fill: #f2f2f2;
              }
            }
            .FilterModalComponentBodyContainerRightDateRangeComponentsIcons
              svg
              path {
              fill: #f2f2f2 !important;
            }
          }
        }
      }
    }
  }
}
.EditMultipleDropDownClassName .ant-select-item {
  padding: 3px 12px;
  width: auto;
}

@media (max-width: 700px) {
  .FilterModalMask .FilterModalComponent {
    width: 100%;
    .FilterModalComponentBody {
      width: 100%;
      .FilterModalComponentBodyContainer
        .FilterModalComponentBodyContainerLeft {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .FilterModalComponentBodyContainer
        .FilterModalComponentBodyContainerRight {
        .FilterModalComponentBodyContainerRightDateRangeContainer {
          align-items: center;
          .FilterModalComponentBodyContainerRightDateRangeComponents {
            flex-direction: column;
            align-items: center;
            gap: 10px;
            .FilterModalComponentBodyContainerRightDateRangeComponentsIcons {
              transform: rotate(90deg);
              display: flex;
              padding-bottom: 10px;
              align-items: center;
            }
          }
        }
      }
    }
    .FilterModalComponentFooter {
      justify-content: space-between;
    }
  }
}
