.task_details_wrapper {
  .TaskCommentsInEdit {
    .all-comments-container {
      .reply__container {
        max-width: unset !important;
        width: 100%;
      }
    }
  }
}

@media (max-width: 700px) {
  .task_details_wrapper {
    .comments-section {
      .alert-container .ant-alert-error {
        width: 100%;
      }
      .reply__container {
        flex-direction: column;
        .rich-editor-description {
          width: 100%;
        }
      }

      .detailsModal__comment {
        .rich-editor-description {
          .ql-toolbar {
            width: calc(100% - 70px);
          }
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .task_details_wrapper {
    .comments-section {
      .detailsModal__comment {
        padding: 0;
      }
    }
  }
}
