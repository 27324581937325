@use "src/index.scss" as index;

.dispatch-excludes {
  height: fit-content !important;
  width: 100% !important;
  max-height: 100dvh !important;
  max-width: 100dvw !important;

  .ant-modal-content {
    height: 100dvh;
    width: 100%;
    border-radius: 0;

    .ant-modal-close {
      height: 45px;
      width: 45px;
      .ant-modal-close-x {
        height: 45px;
        width: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .ant-modal-header {
      height: 45px;
      display: flex;
      align-items: center;
      background-color: #f8f8fa;
    }

    .ant-modal-body {
      height: calc(100% - 52px);
      width: 100%;
      display: grid;
      grid-template-columns: 100%;
      grid-template-rows: 52px calc(100% - 62px);
      gap: 10px;

      .dispatch-excludes-controller {
        padding: 10px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 10px;
        background-color: #f8f8fa;
        gap: 10px;
        flex-direction: row;
        flex-wrap: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
        box-shadow: 0 0 3px 1px rgba($color: #000000, $alpha: 0.1);

        .labeledInputContainer {
          border-radius: 5px;
          overflow: hidden;
          .ant-select,
          .ant-picker {
            width: 100%;
            background-color: #fff !important;
          }
        }

        .controller-section {
          display: flex;
          align-items: center;
          flex-direction: row;
          flex-wrap: nowrap;
          gap: 10px;

          &:nth-child(1) {
            justify-content: flex-start;
          }

          &:nth-child(2) {
            justify-content: flex-end;
          }
        }
      }

      .grid-template-card {
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.16);
      }

      .excludes-grids {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 100%;
        gap: 10px;

        @media (max-width: 960px) {
          grid-template-columns: 100%;
          grid-template-rows: 100% 100% 100%;
          overflow-y: auto;
        }
      }
    }

    .ant-modal-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      flex-wrap: nowrap;
      width: 100%;
    }
  }
}

.dispatch-excludes.no-controller {
  .ant-modal-content {
    .ant-modal-body {
      height: calc(100% - 52px);
      width: 100%;
      display: grid;
      grid-template-columns: 100%;
      grid-template-rows: 100%;
      gap: 10px;
      overflow: auto;
    }
  }
}

.dispatch-excludes.dispatch-excludes-dark {
  .ant-modal-content {
    background-color: index.$mainDarkBackgroundColor;
    .ant-modal-close .ant-modal-close-x svg {
      fill: #fff !important;
    }

    .ant-modal-header {
      background-color: index.$cardDarkBackgroundColor;
      .ant-modal-title {
        color: #fff;
      }
    }

    .ant-modal-body {
      .dispatch-excludes-controller {
        background-color: #12131b;
      }

      .grid-template-card {
        box-shadow: 0px 1px 4px 0px rgba(255, 255, 255, 0.16);
      }

      .labeledInputContainer {
        .ant-select,
        .ant-picker {
          width: 100%;
          background-color: #3a3c4e !important;
        }
      }
    }
  }
}
