$inputModalDark: #12131b;

.gridInput {
  width: 100%;
  height: 100%;
  border-radius: 0px;
}

.gridSelect {
  width: 100%;
  height: 100%;
  .ant-select-selector {
    border-radius: 0px !important;
  }
}

.servicesSelect {
  .ant-select-selector
  .ant-select-selection-overflow {
    display: none;
  }
}

/// dark mode
.gridInputDark {
    background-color: $inputModalDark !important;
    color: #fff;
    border-color: #acacca;
    &::placeholder {
      color: #acacca;
    }
  }

.gridSelectDark {
  .ant-select-selector {
    background-color: $inputModalDark !important;
    border-color: #acacca;
    .ant-select-selection-search-input {
      color: #fff;
    }
    .ant-select-selection-item {
      color: #acacca !important;
    }
    .ant-select-selection-placeholder {
      color: #acacca;
    }
  }
  .ant-select-clear {
    background-color: $inputModalDark;
    svg {
      fill: #acacca;
    }
  }
  .ant-select-arrow svg {
    fill: #fff;
    path {
      fill: #fff;
    }
  }
}
