.records-creation-progress-wrapper {
  .ant-modal-content {
    .ant-modal-close {
      margin-right: 6px;
    }
    .ant-modal-header {
      background-color: #f8f8fa;
      padding: 10px 24px;
    }
    .ant-modal-body {
      .records-creation-progress {
        display: flex;
        flex-direction: column;
        gap: 20px;
        .header-left {
          display: flex;
          gap: 5px;
          p {
            margin: 0;
          }
        }
        .records-creation-progress-content {
          .progress-statuses {
            display: flex;
            gap: 20px;
            flex-direction: column;
          }
        }
      }
    }
  }
}

.records-creation-progress-wrapper-dark {
  .ant-modal-content {
    background-color: #12131b;
    .ant-modal-close-x {
      svg {
        fill: #fff;
      }
    }
    .ant-modal-header {
      background-color: #20212d;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      .records-creation-progress-header {
        color: #fff;
      }
      .records-creation-progress-content {
        .progress-statuses {
          .progress-status {
            .progress-name {
              color: #fff;
            }
            .progress-container {
              background-color: #2a2b3a;
              .progress-remaining {
                color: #fff;
              }
              .progress-remaining-left {
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .records-creation-progress-wrapper {
    width: 100% !important;
  }
}
