.dashboard-chart-card-container {
  border-radius: 10px;
  cursor: default;
  background-color: #fff;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.1);

  .chart-card-container {
    height: 100%;

    .chart-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  }

  .ant-card-head {
    min-height: 45px;
    background-color: #f5f5f7;
  }

  .ant-card-body {
    height: calc(100% - 45px);
  }

  .ant-card-body {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.chart-card-container-dark {
  background-color: #2a2b3a;
  color: #fff;

  .ant-card {
    background-color: #2a2b3a;
  }

  .ant-card-head {
    background-color: #12131b;
    color: #fff;
  }

  .ant-card-body {
    background-color: #2a2b3a;
    color: #fff;
  }
}
