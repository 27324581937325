.map-sidebar {
  height: 100%;
  transition: width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #fff;

  .sidebar-title {
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.2rem;
    font-weight: 650;
    letter-spacing: 0.28px;
    padding-left: 10%;
    border-bottom: 1px solid #dee2eb;
    gap: 1rem;
    cursor: default;
  }

  .change-list {
    cursor: pointer;
    height: 80%;
    aspect-ratio: 1;
    border-radius: 50%;
    transition: background-color 0.2s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background-color: rgba(60, 60, 77, 0.1);
    }
  }

  .sidebar-search {
    padding: 0.5rem 0 0.5rem 5%;
    width: 100%;
    border-bottom: 1px solid #dee2eb;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .ant-input-affix-wrapper {
      height: 32px;
      border: 0;
      background-color: #f5f5f7;

      input {
        background-color: #f5f5f7;
      }
    }
  }

  .trucks-section {
    width: 100%;
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .geofence-info {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 10px;
    padding-left: 5%;
    white-space: normal;
    background-color: #fff;

    &:hover {
      color: #1065a4;
      svg {
        fill: #1065a4;
      }
    }

    .fence-name {
      word-wrap: break-word;
      width: 90%;
      font-size: 0.9rem;
      font-weight: 550;
      letter-spacing: 0.18px;
    }
  }

  .location-info,
  .geofence-info {
    width: 100%;
    padding: 1rem 0.3rem;
    border-top: 1px solid #dee2eb;
    border-radius: 0;
    cursor: pointer;

    &:first-child {
      border-top: 0 !important;
    }

    .location-header {
      .name-section {
        cursor: pointer;

        &:hover {
          color: #1065a4;
          svg {
            fill: #1065a4;
          }
        }
      }
    }

    .location-body {
      .info-data {
        font-size: 0.8rem;
      }
    }
  }
}

.map-sidebar.map-side-expanded {
  // min-width: 350px;
  width: 18vw;
  border-right: 1px solid #dee2eb;
}

.map-sidebar.map-side-collapsed {
  width: 0;
  border: 0;
}

.map-sidebar.map-sidebar-dark {
  background-color: #20212d;
  color: #ffffff;

  .sidebar-title {
    border-bottom: 1px solid #616481;
  }

  .change-list {
    &:hover {
      background-color: rgb(60, 60, 77);
    }
  }

  .sidebar-search {
    border-bottom: 1px solid #616481;

    .ant-input-affix-wrapper {
      background-color: rgb(0, 0, 0);
      color: #ffffff;

      .ant-input-clear-icon {
        color: #3a3c4e;
      }

      input {
        background-color: rgba(0, 0, 0, 0);
        color: #ffffff;
        &::placeholder {
          color: #ffffffd5;
        }
      }
      &:active {
        border-color: #616481;
      }
      &:hover {
        border-color: #616481;
      }
    }
  }

  .location-info {
    border-top: 1px solid #616481;
  }

  .geofence-info {
    background-color: #20212d;
    color: #f2f2f2;

    &:hover {
      color: #1065a4;
      svg {
        fill: #1065a4;
      }
    }

    .fence-name {
      word-wrap: break-word;
      width: 90%;
      font-size: 0.9rem;
      font-weight: 550;
      letter-spacing: 0.18px;
    }
  }

  .location-info,
  .geofence-info {
    border-top: 1px solid #616481;
  }
}

.map-sidebar.map-sidebar-dark.map-side-expanded {
  border-right: 1px solid #616481;
}
