@import "../../../../../../../../../index.scss";

$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.hoistPriceItemModal {
  width: 650px !important;
  .hoist-price-item-modal-content {
    .inputs-container {
      @include flex(center, center);
      gap: 40px;
    }

    .footer {
      @include flex(center, space-between);
    }

    .price-configurator-input {
      flex: 1;
      .price-configurator-input-label {
        @include text(16px, 600, 0, #323338);
      }

      .ant-input-number,
      .ant-input {
        width: stretch;
        border: 0 !important;
        border-radius: 5px !important;
        background-color: #f4f5f6 !important;
      }
    }

    .price-configurator {
      // border: 1px solid #dfe3ec;
      border-radius: 5px;
      margin-bottom: 20px;

      .price-configurator-header {
        @include flex(center, center);
        @include text(16px, 600, 0, #323338);
        padding: 5px;
        background-color: #f7f8fa;
        border-radius: 5px 5px 0 0;
      }
      .price-configurator-content {
        width: stretch;
        padding: 10px;
      }
    }
  }
}

.hoistPriceItemModalDark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      .hoist-price-item-modal-content .inputs-container {
        .labeledInputLabel {
          color: #fff;
        }
        .ant-input {
          background-color: $inputModalDark !important;
          color: #fff;
          &::placeholder {
            color: #acacca;
          }
        }
        .ant-select {
          .ant-select-selector {
            background-color: $inputModalDark !important;
            color: #fff;
            .ant-select-selection-search-input {
              background-color: $inputModalDark;
              color: #fff;
            }
            .ant-select-selection-item {
              color: #fff;
            }
            .ant-select-selection-placeholder {
              color: #acacca;
              z-index: 2;
            }
          }
          .ant-select-arrow svg {
            fill: #fff;
          }
        }
      }
    }

    .price-configurator {
      border: none;
      .price-configurator-header {
        background-color: $cardModalHeaderDark;
        color: #fff;
      }
      .ant-input-number {
        background-color: $inputModalDark !important;
        .ant-input-number-input {
          height: 32px;
          background-color: $inputModalDark;
          color: #fff;
          &::placeholder {
            color: #acacca;
          }
        }
      }
      .price-configurator-content {
        background-color: #2a2b3a !important;
        border-radius: 0px 0px 10px 10px;
        .option-container {
          color: #fff;
        }
        .singleDimensionPriceTable {
          .priceTable {
            .table-labels {
              color: #fff;
              .dimensionInputWrapper {
                .ant-input {
                  background-color: #1f2a44;
                }
              }
            }
          }
        }
      }
    }
    .price-configurator-input-label {
      color: #fff;
    }
  }
}

@media (max-width: 460px) {
  .hoist-price-item-modal-content .inputs-container {
    display: flex;
    flex-direction: column;
  }
}
