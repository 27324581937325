@import "../../../../../../index.scss";

.categoriesContainer-card {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  width: 100%;
  position: relative;

  .search-container {
    position: relative;
    padding: 10px;
    .customSearchNew {
      background-color: #f7f7f7 !important;
      border-radius: 5px;
      margin-bottom: 10px;

      .ant-input-wrapper {
        .ant-input-affix-wrapper {
          padding: 8px 10px;
          background-color: #f7f7f7 !important;
          border: none;

          .ant-input {
            background-color: transparent;
          }
        }

        .ant-input-group-addon {
          display: none;
        }
      }
    }

    .searchResult-container {
      position: absolute;
      bottom: -170px;
      height: 200px;
      left: 0;
      right: 0;
      background-color: #f8f8fa;
      border-radius: 0 0 6px 6px;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      overflow-y: auto;
      z-index: 999;

      &.noVideo {
        bottom: -35px;
        height: 65px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        color: #dcdcdc;
        overflow: hidden;
      }

      p {
        padding-left: 8px;
        font-size: 16px;
        font-weight: 600;
      }
    }
  }

  .searchResult-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 5px;
  }

  .allCards {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;

    .newCategories-container {
      display: flex;
      flex-direction: column;
      gap: 10px;
      height: fit-content;
      max-height: 220px !important;
      overflow-y: auto;
    }

    .newTutorialsCard {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      padding: 6.5px 10px;
      border-radius: 5px;
      background: #f6872c31;
      cursor: pointer;

      .new-text {
        padding: 1.5px 5px;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        color: #fff;
        background-color: #ee8a35;
        border-radius: 2px;
      }

      .text {
        flex-grow: 1;
        margin: 0;
      }

      .videoPlayerIcon {
        path {
          fill: #ee8a35;
        }
      }

      &.active {
        background-color: #ee8a35;

        .new-text {
          color: #ee8a35;
          background-color: #fff;
        }

        .text {
          color: #fff;
        }

        .videoPlayerIcon {
          path {
            fill: #fff;
          }
        }
      }

      &:hover {
        background-color: #ee8a35;

        .new-text {
          color: #ee8a35;
          background-color: #fff;
        }

        .text {
          color: #fff;
        }

        .videoPlayerIcon {
          path {
            fill: #fff;
          }
        }
      }
    }

    .unfinishedTutorialsCard-container {
      position: relative;

      .unfinishedTutorialsCard {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        padding: 6.5px 10px;
        border-radius: 5px;
        background: #f6872c31;
        cursor: pointer;

        .video-icon {
          font-size: 20px;
          path {
            fill: #ee8a35;
          }
        }

        .new-text {
          padding: 1.5px 5px;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          color: #fff;
          background-color: #ee8a35;
          border-radius: 2px;
        }

        .text {
          flex-grow: 1;
          margin: 0;
        }

        .videoPlayerIcon {
          path {
            fill: #ee8a35;
          }
        }

        &.active {
          background-color: #ee8a35;

          .new-text {
            color: #ee8a35;
            background-color: #fff;
          }

          .text {
            color: #fff;
          }

          .video-icon {
            path {
              fill: #fff;
            }
          }

          .videoPlayerIcon {
            path {
              fill: #fff;
            }
          }
        }

        &:hover {
          background-color: #ee8a35;

          .new-text {
            color: #ee8a35;
            background-color: #fff;
          }

          .text {
            color: #fff;
          }

          .video-icon {
            path {
              fill: #fff;
            }
          }

          .videoPlayerIcon {
            path {
              fill: #fff;
            }
          }
        }
      }
    }

    .favTutorialsCard {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      padding: 6.5px 10px;
      border-radius: 5px;
      background: #f6ca5285;
      cursor: pointer;

      .star-icon {
        font-size: 20px;
        path {
          fill: #f6cb52;
        }
      }

      .text {
        flex-grow: 1;
        margin: 0;
      }

      .videoPlayerIcon {
        path {
          fill: #f6cb52;
        }
      }

      &.active {
        background-color: #f6cb52;

        .text {
          color: #fff;
        }

        .videoPlayerIcon {
          path {
            fill: #fff;
          }
        }

        .star-icon {
          path {
            fill: #fff;
          }
        }
      }

      &:hover {
        background-color: #f6cb52;

        .text {
          color: #fff;
        }

        .videoPlayerIcon {
          path {
            fill: #fff;
          }
        }

        .star-icon {
          path {
            fill: #fff;
          }
        }
      }
    }

    .categories {
      display: flex;
      flex-direction: column;
      gap: 10px;
      overflow-y: auto;
      .categoriesCards-container {
        .cards {
          .card-container {
            background-color: white;
            color: black;
            &:hover {
              border-radius: 5px;
              background: #f1f5fd;
              color: #1264a3;
            }
          }
        }
      }
    }
  }

  .buttons {
    position: sticky;
    bottom: 0px;
    left: 0;
    right: 0;
    margin-top: auto;
    padding: 12px 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1607843137);
    transition: 0.2s ease-in;
  }
}

.categoriesContainer-cardDark {
  .search-container {
    .searchResult-container {
      background-color: $cardDarkBackgroundColor;
      color: #fff;
    }
  }

  .allCards {
    color: #fff;
  }

  .buttons {
    background-color: $cardDarkBackgroundColor;
  }
}
