.mobile-menu-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) {
  .menu-drower {
    .ant-drawer-content-wrapper {
      width: 100% !important;

      .ant-drawer-header {
        background: #1f2a44;
        padding: 15px 5px;

        .ant-drawer-header-title {
          .ant-drawer-title {
            color: #fff;
            font-size: 18px;
            font-weight: 500;
          }
        }

        border-bottom: 2px solid #fff;
      }
    }

    span.anticon.anticon-close {
      color: #fff !important;
      font-size: 18px;
      font-weight: 500;
    }

    .ant-drawer-body {
      padding: 0px;
      background: #1f2a44;
    }

    .ant-drawer-body::-webkit-scrollbar {
      display: none;
    }

    .mobile-menu {
      border: none;
    }

    .mobile-menu .ant-menu-inline > .ant-menu-item,
    .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title,
    .ant-menu-vertical-left > .ant-menu-item,
    .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
    .ant-menu-vertical-right > .ant-menu-item,
    .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
    .ant-menu-vertical > .ant-menu-item,
    .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title {
      height: 50px;
      width: 100%;
      line-height: 40px;
      border-radius: 0px;
    }

    .ant-menu-submenu {
      border-radius: 0px;
    }

    .ant-menu-dark .ant-menu-inline.ant-menu-sub {
      background-color: #1f2a44;
    }

    .settingsMenu {
      font-size: 16px;
      color: #fff;
      background-color: #1f2a44;

      .ant-menu-item {
        font-size: 16px;
        background-color: #1f2a44;
        border-radius: 0px;

        .ant-menu-title-content {
          color: #fff;
        }
      }

      .ant-menu-submenu {
        font-size: 16px;
        color: #fff;

        .ant-menu-item {
          border-radius: 0px !important;
        }

        .ant-menu-item-icon {
          svg {
            width: 18px;
            height: 18px;
          }
        }
      }

      .ant-menu-item::after {
        border-right: solid 11px #fff !important;
      }

      .ant-menu-item-selected {
        background-color: #ffffff52 !important;
        color: #fff !important;
      }
    }

    .ant-menu {
      .ant-menu-item {
        .li-icon {
          width: 23px;
          height: 23px;
          fill: white;
          display: flex;
          justify-content: center;
        }
      }
      .ant-menu-submenu {
        .ant-menu-submenu-title {
          margin-inline: 0 !important;
          margin-block: 0 !important;

          .li-icon {
            width: 23px;
            height: 23px;
            fill: white;
            display: flex;
            justify-content: center;
          }

          .ant-menu-submenu-arrow {
            color: white !important;
          }
        }
        .ant-menu-item {
          margin-block: 0 !important;
          .li-icon {
            width: 20px;
            height: 20px;
            fill: white;
            display: flex;
            justify-content: center;
          }
        }
      }
    }

    .ant-menu.ant-menu-light {
      background-color: #1f2a44 !important;

      /* --> Selected menu item */
      .ant-menu-item-selected {
        border-radius: 0 !important;
        background-color: #192236 !important;
      }
      /* --> Menu item & submenu item active */
      .ant-menu-submenu-selected {
        .ant-menu-submenu-title {
          background-color: #192236 !important;
        }
      }
    }
  }
}

@media (max-width: 390px) {
  .ant-drawer.ant-drawer-left.ant-drawer-open.menu-drower {
    .ant-drawer-header {
      background: #1f2a44;
    }

    span.anticon.anticon-close {
      color: #fff !important;
      font-size: 18px;
      font-weight: 500;
    }

    .ant-drawer-title {
      display: flex;
      justify-content: center;
      color: #fff !important;
      font-size: 20px;
      font-weight: 500;
    }
  }

  .ant-drawer.ant-drawer-left.ant-drawer-open.menu-drower {
    .ant-drawer-body {
      padding: 0px !important;
    }

    .ant-drawer-content-wrapper {
      height: auto !important;
    }

    .ant-drawer-content-wrapper {
      width: 100% !important;
    }
  }

  .main-header-div {
    background: #fff;
    border-radius: 15px;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
    margin-bottom: 15px;
    display: grid;

    .clear-btn {
      background: #ea3943;
      border-radius: 5px;
      width: 35px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 20px;
      margin-left: 10px;
    }

    .search-div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 10px 10px 0px 10px;
    }

    .item-header {
      margin: 5px;
      justify-content: center;
      align-items: center;
      height: 30px;
      border-radius: 10px;
      color: #fff;
      background: #1f2a44;
      display: flex;
    }

    .item-header-active {
      margin: 5px;
      justify-content: center;
      align-items: center;
      height: 30px;
      border-radius: 10px;
      color: #fff;
      background: #18aa73;
      display: flex;
    }
  }

  .mobile-menu .ant-menu-inline > .ant-menu-item,
  .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title,
  .ant-menu-vertical-left > .ant-menu-item,
  .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
  .ant-menu-vertical-right > .ant-menu-item,
  .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
  .ant-menu-vertical > .ant-menu-item,
  .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title {
    height: 50px;
    width: 100%;
    line-height: 40px;
    border-radius: 0px !important;
  }

  li.ant-menu-item.ant-menu-item-active.sigurt-mobile {
    padding-left: 16px !important;
    height: 50px;
    display: flex;
    align-items: center;
    font-size: 20px;

    span.ant-menu-title-content {
      color: #fff !important;
    }
  }

  li.ant-menu-item.sigurt-mobile {
    padding-left: 16px !important;
    height: 50px;
    display: flex;
    align-items: center;
    font-size: 20px;

    span.ant-menu-title-content {
      color: #fff !important;
    }
  }

  ul.ant-menu.ant-menu-root.ant-menu-vertical.ant-menu-light.mobile-menu {
    background: #1f2a44;
  }

  .mobile-menu li.ant-menu-item.ant-menu-item-selected {
    background: #1f2a44 !important;
    height: 50px;
    display: flex;
    align-items: center;
    font-size: 20px;
  }

  .mobile-menu li.ant-menu-item {
    height: 50px;
    display: flex;
    align-items: center;
    font-size: 20px;
  }
}

@media (max-width: 450px) {
  .ant-menu-submenu.ant-menu-submenu-selected {
    .ant-menu-submenu-title {
      .ant-menu-title-content {
        color: #fff !important;
      }
    }
  }

  li.ant-menu-submenu.ant-menu-submenu-vertical.subMenu-konfig {
    span.ant-menu-title-content {
      color: #fff !important;
    }
  }

  li.ant-menu-item.ant-menu-item-danger.subMenu-konfig {
    path#Path_8190 {
      fill: #fff !important;
    }

    path#Path_8191 {
      fill: #fff !important;
    }

    path#Path_8192 {
      fill: #fff !important;
    }

    circle#Ellipse_228 {
      fill: #fff !important;
    }
  }

  .main-header-div {
    background: #fff;
    border-radius: 15px;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
    margin-bottom: 15px;
    display: grid;

    .clear-btn {
      background: #ea3943;
      border-radius: 5px;
      width: 35px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 20px;
      margin-left: 10px;
    }

    .search-div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 10px 10px 0px 10px;
    }

    .item-header {
      margin: 5px;
      justify-content: center;
      align-items: center;
      height: 30px;
      border-radius: 10px;
      color: #fff;
      background: #1f2a44;
      display: flex;
    }

    .item-header-active {
      margin: 5px;
      justify-content: center;
      align-items: center;
      height: 30px;
      border-radius: 10px;
      color: #fff;
      background: #18aa73;
      display: flex;
    }
  }
}
