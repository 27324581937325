$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.jobsiteModal {
  width: calc(100vw - 20px) !important;
  width: calc(100dvw - 20px) !important;
  height: calc(100vh - 20px) !important;
  height: calc(100dvh - 20px) !important;
  max-width: 1250px;
  max-height: 800px;
  .ant-modal-content {
    height: calc(100vh - 20px) !important;
    height: calc(100dvh - 20px) !important;
    max-height: 800px;
    border-radius: 10px;
    .ant-modal-close {
      height: 45px;
      width: 45px;
      .ant-modal-close-x {
        height: 45px;
        width: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          fill: #323338;
        }
      }
    }
    .ant-modal-header {
      height: 45px;
      display: flex;
      align-items: center;
      background-color: #f5f5f7;
      border-radius: 10px 10px 0px 0px;
      .ant-modal-title {
        color: #323338;
        font-size: 16px;
        font-weight: 600;
      }
    }
    .ant-modal-body {
      height: calc(100vh - 97px);
      max-height: 703px;
      overflow-x: hidden;
      overflow-y: auto;
      .ant-form {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        gap: 10px !important;
        .jobNameField,
        .jobsiteAddressField {
          width: 370px;
        }
        .labeledInputContainer {
          min-width: 200px;
          .ant-picker {
            height: 32px;
            width: 100%;
          }
          .ant-input {
            height: 32px;
          }
        }

        .ratesTable {
          width: 100%;
          height: fit-content;
          margin-bottom: 10px;
        }
        .reimbursement {
          
            height: 57px;
            display: flex;
            align-items: flex-end;
          .report-check {
            width: auto;
            .checkbox-container {
              background-color: #1f6fac;

              .ant-checkbox-inner {
                background-color: #125d97;
                border-color: #125d97;
              }
            }

            .check-title {
              width: auto;
            }
            .checkbox-container.container-checked {
              .ant-checkbox-wrapper {
                &:hover {
                  .ant-checkbox-inner {
                    background-color: #0f5c97;
                    border-color: #0f5c97;
                  }
                }
              }
              .ant-checkbox-inner {
                background-color: #125d97;
                border-color: #125d97;
              }
            }
          }
        }
      }
      .payrollActivityMapContainer {
        height: 300px;
        width: 100%;
      }
      .app-map-geofence {
        height: 300px;
      }
      .modalInfo {
        height: 250px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px dashed #1264a3;
        font-size: 20px;
        color: gray;
      }
    }
    .ant-modal-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 20px;
      .mondayButtonStyle {
        width: 150px;
      }
    }
  }
}

.jobsiteModalDark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      .labeledInputLabel {
        color: #fff;
      }
      .ant-input {
        background-color: $inputModalDark !important;
        color: #fff;
        &::placeholder {
          color: #acacca;
        }
      }
      .ant-input-disabled {
        color: #acacca;
      }
      .ant-select {
        height: 32px;
        .ant-select-selector {
          background-color: $inputModalDark !important;
          .ant-select-selection-search-input {
            color: #fff;
          }
          .ant-select-selection-overflow-item {
            .ant-select-selection-item {
              background-color: #323338;
              .ant-select-selection-item-remove svg {
                fill: #fff;
              }
            }
          }
          .ant-select-selection-item {
            color: #fff;
          }
          .ant-select-selection-placeholder {
            color: #acacca;
          }
        }
        .ant-select-clear {
          background-color: $inputModalDark;
          svg {
            fill: #acacca;
          }
        }
        .ant-select-arrow svg {
          fill: #fff;
        }
      }
      .ant-select-disabled {
        .ant-select-selector {
          .ant-select-selection-search-input {
            color: #acacca !important;
          }
          .ant-select-selection-item {
            color: #acacca !important;
          }
        }
      }
      .ant-input-wrapper {
        background-color: $inputModalDark !important;
        .ant-input-group-addon {
          background-color: $inputModalDark !important;
          color: #fff;
        }
      }
      .report-check .check-title {
        color: #fff;
      }
      .ant-checkbox-wrapper span {
        color: #fff;
      }
      .ratesTable {
        .jobsite-rates-grid-container {
          .ag-root-wrapper {
            border-radius: 10px;
            .ag-header {
              height: 50px !important;
              min-height: 50px !important;
              background-color: $cardModalBodyDark !important;
              .ag-header-row {
                border-top: none;
              }
              .ag-header-cell {
                height: 48px !important;
                min-height: 0px !important;
                .ag-header-cell-resize:after {
                  width: 1px !important;
                  background-color: #4a4a4a;
                }
              }
            }
          }
        }
        .editInfo span {
          color: #fff;
        }
      }
    }
  }
}
