.jobsite-rates-grid-container {
  height: 100%;
  width: 100%;
  .editInfo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
    flex-direction: row;
    height: fit-content;
  }

  .ag-root-wrapper {
    height: 100% !important;
    .ag-root-wrapper-body {
      height: 100% !important;
    }
  }
}
