.predictInputContainer {
  position: relative;
  animation: stretch-animation 800ms ease;
  background-color: #f5f5f7;
  border-radius: 5px;
  z-index: 1;
  height: 32px;

  .predictInput {
    outline: none;
    border: none;
    background-color: transparent !important;
    position: absolute;
    height: 32px;
    font-weight: 500;
    // color: #000000;
    font-size: 14px;
    // padding: 0 18px;
    z-index: 10 !important;
  }

  .suggestion {
    width: inherit;
    height: inherit;
    position: absolute;
    z-index: 4;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    padding: 2px 0px 0px 11px;
    // padding: 0 18px;
    font-size: 14px;
    color: #8d8c8c;
  }
}
