.collapse-info-modal-container {
  .ant-modal-content {
    .ant-modal-close {
      height: 45px;
      width: 45px;
      .ant-modal-close-x {
        height: 45px;
        width: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          fill: #333238;
        }
      }
    }

    .ant-modal-header {
      height: 45px;
      display: flex;
      align-items: center;
      background-color: #f5f5f7;
      .ant-modal-title {
        font-weight: 600;
        font-size: 16px;
        color: #333238;
      }
    }

    .ant-modal-body {
      max-height: 80vh;
      overflow-y: auto;

      .info-container {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 10px;
        flex-direction: row;
        flex-wrap: nowrap;
        margin-bottom: 10px;
        width: 100%;

        svg {
          width: 28px;
          aspect-ratio: 1;
        }
      }

      .single-collapse-info {
        margin-bottom: 10px;
      }

      .no-warning-container {
        padding: 40px 0;
        font-size: 16px;
        font-weight: 600px;
        text-align: center;
      }
    }
  }
}

.collapse-info-modal-container.collapse-modal-dark {
  .ant-modal-content {
    background-color: #1f212d;
    color: #ffffff;

    .ant-modal-header {
      background-color: #12131b;
      .ant-modal-title {
        color: #ffffff;
      }
    }

    .ant-modal-close {
      .ant-modal-close-x {
        svg {
          fill: #ffffff;
        }
      }
    }
  }
}
