$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.details-for-day-modal {
  .ant-modal-header {
    display: flex;
    align-items: center;
    height: 45px;
    background: #f8f8fa;
  }
}

.details-for-day-modal-dark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close-x svg {
      fill: #fff;
    }
  }
  .ant-modal-header {
    background-color: $cardModalHeaderDark;
    .ant-modal-title {
      color: #fff;
    }
  }
  .ant-modal-body {
    .tox {
      border: none;
      .tox-menubar {
        background-color: $cardModalHeaderDark;
        border-radius: 5px 5px 0px 0px;
        .tox-mbtn__select-label {
          color: #fff;
        }
      }
      .tox-mbtn--select:hover {
        background-color: $cardModalBodyDark;
      }
      .tox-editor-header {
        border-radius: 5px !important;
        background-color: $cardModalBodyDark;
      }
      .tox-toolbar-overlord {
        background-color: $cardModalHeaderDark !important;
        border-radius: 5px;
        .tox-toolbar__primary {
          background-color: $cardModalHeaderDark !important;
          border-radius: 5px;
        }
        .tox-toolbar__group {
          background-color: $cardModalHeaderDark !important;
          border: none;
          .tox-split-button:hover {
            border: none;
          }
          .tox-tbtn {
            svg {
              fill: #fff;
            }
            &:focus {
              background-color: $cardModalBodyDark;
            }
            &:hover {
              background-color: $cardModalBodyDark;
            }
          }
          .tox-tbtn__select-label {
            color: #fff !important;
          }
        }
      }
    }
  }
}
