.videoModal {
  .ant-modal-content {
    .ant-modal-close {
      margin-right: 6px;
    }
    .ant-modal-header {
      background-color: #f8f8fa;
      padding: 10px 24px;
    }
    .ant-modal-body {
      .videos {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .video {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 5px;
          border-radius: 5px;
          background-color: #f8f8fa;
          cursor: pointer;

          &:hover {
            filter: brightness(95%);
          }

          .video-icon {
            border-radius: 5px;
            background: #a87878
              url("https://icon-library.com/images/play-icon-transparent-background/play-icon-transparent-background-22.jpg")
              0% 0% no-repeat padding-box;
            width: 50px;
            background-size: 50px;
            height: 50px;
            display: block;
            z-index: 99999999999999;
            position: absolute;
          }

          .video-info {
            padding-left: 55px;
            .title {
              padding-left: 5px;
              margin: 0;
              font-weight: 600;
              font-size: 16px;
              line-height: 22px;
              color: #323338;
            }

            .body {
              padding: 5px;
              display: flex;
              flex-direction: row;
              gap: 10px;
              background-color: transparent;
              .category-name {
                font-size: 12px;
                line-height: 16px;
              }
              .circle {
                width: 5px;
                height: 5px;
                border-radius: 50%;
              }

              .video-length {
                font-size: 12px;
                line-height: 16px;
              }
            }
          }
        }
      }
    }
  }
}

.videoModal-dark {
  .ant-modal-content {
    background-color: #12131b;
    .ant-modal-close-x {
      svg {
        fill: #fff;
      }
    }
    .ant-modal-header {
      background-color: #20212d;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      .videos {
        .video {
          p {
            color: #fff;
          }
        }
      }
    }
  }
}
