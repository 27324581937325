@import "../../../../../../../index.scss";

.hidden-charts-sider {
  .ant-drawer-content-wrapper {
    width: 27vw !important;
    .ant-drawer-header {
      height: 45px;
      flex: none;
      @include flex(center);
      padding: 0px 5px;
      gap: 10px;
      background-color: #f8f8fa;
      border: none;
      .ant-drawer-header-title {
        .ant-drawer-close {
          height: 45px;
          width: 45px;
          @include flex(center, center);
        }
      }
    }
    .ant-drawer-body {
      .hidden-cards-list {
        display: flex;
        flex-direction: column;
        gap: 10px;
        .card-select-container {
          @include flex(center, space-between, row);
          animation: cardFadeIn 0.5s ease-out;
          padding: 6.5px 10px;
          padding-left: 15px;
          border-radius: 5px;
          background-color: #f8f8fa;
          gap: 10px;
          cursor: pointer;
          &:hover {
            background-color: #f1f5fd;
            color: #1264a3;
          }
          .card-title {
            font-weight: 600;
          }
        }
      }
    }
  }
}

.hidden-charts-sider-dark {
  .ant-drawer-content-wrapper {
    .ant-drawer-header {
      background-color: $cardDarkBackgroundColor;
      .ant-drawer-header-title {
        .ant-drawer-close svg {
          fill: #fff;
        }
        .ant-drawer-title {
          color: #fff;
        }
      }
    }
    .ant-drawer-body {
      background-color: $mainDarkBackgroundColor;
      .ant-form {
        .ant-input-affix-wrapper {
          .ant-input-prefix svg {
            fill: #fff !important;
          }
          .ant-input {
            &::placeholder {
              color: #acacca !important;
            }
          }
        }
      }
      .hidden-cards-list {
        .card-select-container {
          background-color: $cardDarkHeaderColor;
          &:hover {
            background-color: #303141;
          }
          .card-title {
            color: #fff;
          }
        }
      }
    }
  }
}

@keyframes cardFadeIn {
  0% {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
