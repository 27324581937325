.lockedCellDetailsRentals {
  width: stretch;
  display: flex;
  justify-content: center;
  align-items: center;
  .lockButton {
    height: fit-content;
    width: fit-content;
    border: 0;
    background-color: transparent;

    &:disabled {
      cursor: not-allowed;
    }
  }
}
