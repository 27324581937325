.editSafetyContacts {
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
  .contacts-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100%;
    flex: 1;
    overflow-y: auto;
    max-height: 550px;
    .contact-checkbox {
      max-width: 250px;
    }
  }
}
