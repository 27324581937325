@use "src/index.scss" as index;

.get-payroll-report-modal {
  height: fit-content !important;
  width: fit-content !important;
  max-height: calc(100vh - 20px);
  max-width: calc(100vw - 20px);
  .ant-modal-content {
    min-height: 180px;
    min-width: 300px;
    max-height: calc(100vh - 20px);
    max-width: calc(100vw - 20px);
    .ant-modal-close {
      height: 45px;
      width: 45px;
      .ant-modal-close-x {
        height: 45px;
        width: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .ant-modal-header {
      height: 45px;
      display: flex;
      align-items: center;
      background-color: #f8f8fa;
    }
    .ant-modal-body {
      min-height: calc(180px - 97px);
      min-width: 300px;
      .report-control-panel {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        gap: 10px;
        .upload-button,
        .ant-form-item {
          margin-bottom: 10px;
        }
        .upload-button {
          display: flex;
          flex-direction: column-reverse;
        }
        .button-inner-content {
          height: 32px;
          margin-bottom: 10px;
        }
      }
      .generated-files-container {
        width: 100%;
        // min-height: 200px;
        max-height: 150px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        overflow-y: auto;
        margin-bottom: 10px;
        .file-container {
          min-height: 32px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: #f4f5f6;
          border-radius: 5px;
          padding: 0px 10px;
          label {
            font-weight: 600;
            cursor: pointer;
            &:hover {
              fill: index.$blue;
            }
          }
          .file-actions {
            width: 30%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            label {
              font-weight: 600;
              cursor: pointer;
              &:hover {
                color: index.$blue;
              }
            }
            .selected {
              color: index.$blue;
            }
          }
          .download-file {
            cursor: pointer;
            transition: 0.3s ease-out;
            &:hover {
              fill: index.$blue;
            }
          }
          &.active {
            background-color: #cdd5dd;
          }
        }
        .empty-files {
          height: 100%;
          width: 100%;
          height: 150px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          color: #acacac;
        }
      }
      .warnings-table {
        width: 1200px;
        label {
          width: 100%;
          height: 32px;
          display: flex;
          font-size: 16px;
          font-weight: 600;
          align-items: center;
          justify-content: center;
          background-color: #f4f5f6;
        }
      }
    }
    .ant-modal-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      flex-wrap: nowrap;
      width: 100%;
      .mondayButtonStyle {
        width: 150px;
      }
    }
  }
}

.get-payroll-report-modal.get-payroll-report-modal-dark {
  .ant-modal-content {
    background-color: index.$mainDarkBackgroundColor;
    .ant-modal-close .ant-modal-close-x svg {
      fill: #fff !important;
    }
    .ant-modal-header {
      background-color: index.$cardDarkBackgroundColor;
      .ant-modal-title {
        color: #fff;
      }
    }

    .ant-modal-body {
      .ant-upload-icon svg,
      .ant-upload-list-item-name {
        color: #acacca;
          fill: #acacca;
      }
      .generated-files-container {
        .file-container {
          background-color: index.$cardDarkHeaderColor;
          label {
            color: #fff;
          }
          .file-actions {
            label,
            .download-file {
              fill: #fff;
              &:hover {
                fill: index.$blue;
              }
            }
          }
          &.active {
            background-color: index.$cardDarkBackgroundColor;
          }
        }
        .empty-files {
          color: #acacca;
        }
      }
      .warnings-table {
        label {
          color: #fff;
          background-color: index.$cardDarkBackgroundColor;
        }
        .ag-root-wrapper {
          .ag-header,
          .ag-paging-panel {
            background-color: index.$mainDarkBackgroundColor;
            .ag-header-row {
              border-top: none;
            }
          }
        }
      }
    }
  }
}
