.assistant-chat-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 10px;
  color: #fff;
  cursor: default;
  line-height: normal;
  position: relative;

  svg,
  path {
    fill: #fff;
  }

  .header-avatar {
    background-color: #fff;
    svg,
    path {
      fill: #fff;
    }
  }

  .avatar-initials {
    font-weight: 600;
    color: #231f20;
  }

  .robot-icon-container {
    display: grid;
    place-items: center;
    height: 70px;
    width: 70px;
  }

  .header-user-greeting {
    font-size: 20px;
    font-weight: 600;
  }

  .chat-status {
    font-weight: 400;
    font-size: 16px;
  }

  .chat-resize {
    position: absolute;
    top: 0px;
    right: 10px;
    cursor: pointer;

    svg,
    path {
      fill: #fff;
    }
  }

  .new-chat-button {
    position: absolute;
    bottom: 0px;
    right: 10px;
    cursor: pointer;

    svg,
    path {
      fill: #fff;
    }
  }
}

@media only screen and (max-width: 767px) {
  .assistant-chat-header {
    .header-user-greeting {
      font-size: 18px;
    }
  }
}
