$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.shiftEditModalContainer {
  height: fit-content !important;
  width: fit-content !important;

  button {
    &:disabled {
      color: rgba(0, 0, 0, 0.25) !important;
      background-color: rgba(0, 0, 0, 0.04) !important;
      .mondayButtonDivider {
        background-color: transparent !important;
      }
    }
  }

  .ant-modal-content {
    width: calc(100vw - 20px) !important;
    max-height: calc(100vh - 20px) !important;
    max-width: 1300px;

    .ant-modal-close {
      height: 45px;
      width: 45px;
      .ant-modal-close-x {
        height: 45px;
        width: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          fill: #333238;
        }
      }
    }

    .ant-modal-header {
      height: 45px;
      display: flex;
      align-items: center;
      background-color: #f5f5f7;
      .ant-modal-title {
        font-weight: 600;
        font-size: 16px;
        color: #333238;
      }
    }

    .ant-modal-body {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: row;
      min-height: 30dvh;
      flex-wrap: wrap;

      .employee-info-section {
        flex: 1;
        height: 100% !important;
        min-height: 30dvh;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        overflow-y: hidden !important;

        .info-title {
          width: 100%;
          padding: 0.5rem 0;
          color: #f5f5f7;
          background-color: #1264a3;
          font-size: 1.1rem;
          font-weight: 600;
          letter-spacing: 0.28px;
          text-align: center;
          margin-bottom: 1rem;
        }

        .emp-info {
          width: 95%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-direction: row;
          margin-bottom: 0.8rem;
          font-size: 0.8rem;
          white-space: nowrap;
        }

        .oldCurrency {
          text-decoration-line: line-through;
          background-color: rgba(255, 0, 0, 0.2);
        }

        .newCurrency {
          background-color: rgba(0, 128, 0, 0.2);
        }
        .ant-select-selection-overflow {
          height: fit-content;
          max-height: 25dvh;
          overflow-y: auto;
          flex-wrap: nowrap;
          .ant-select-selection-overflow-item:nth-last-child(2) {
            margin-top: 10px;
            .ant-select-selection-item {
              padding-left: 5px;
            }
          }
        }
      }

      .ant-form {
        max-height: calc(100vh - 170px);
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        .borderedTextCard {
          padding: 15px 0px 0px 0px;
          border-radius: 0;
          border: none;
          border-top: 1px solid black;
          align-items: center;
          margin-bottom: 10px;
          .cardTitle {
            font-size: 16;
            color: black;
            z-index: unset !important;
          }
          .childrenContainer {
            display: flex;
            column-gap: 1.2rem;
            justify-content: flex-start;
            align-items: center;
          }
        }

        .shiftCard {
          .childrenContainer {
            display: flex;
            align-items: flex-start !important;
            flex-direction: column !important;
          }
        }

        .app-map-geofence {
          height: 250px;
        }
        .warningContainer {
          display: flex;
          flex-direction: column;
          padding-bottom: 10px;
          gap: 10px;
          .warningInfo {
            width: 100%;
            display: flex;
            align-items: center;
            font-weight: 600;
            gap: 5px;
            svg path {
              fill: #fad753;
            }
          }
        }
      }

      .no-info {
        flex: 1;
        padding-left: 0;
        border-left: 0;
        transform: translateX(0);
        width: 100%;
      }

      .with-info {
        flex: 3;
        padding-left: 1rem;
        border-left: 1px solid #1264a36c;
        transform: translateX(-1px);
      }

      .with-employee-multiselect {
        flex: 3;
      }
    }

    .ant-modal-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      flex-direction: row-reverse;
      .mondayButtonStyle {
        width: 150px;
      }
    }
  }
}

.shiftEditModalContainerDark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      .warningContainer .warningInfo {
        color: #fff;
      }
      .labeledInputLabel {
        color: #fff;
      }
      .ant-input-affix-wrapper {
        background-color: $inputModalDark !important;
        .ant-input-prefix svg {
          fill: #fff;
        }
        .ant-input {
          background-color: $inputModalDark !important;
          color: #fff;
          &::placeholder {
            color: #acacca;
          }
        }
      }
      .ant-picker {
        background-color: $inputModalDark !important;
        .ant-picker-input {
          background-color: $inputModalDark;
          input {
            color: #f2f2f2;
            &::placeholder {
              color: #acacca;
            }
          }
          .ant-picker-suffix svg {
            color: #acacca;
          }
          .ant-picker-clear {
            background-color: $inputModalDark;
            svg {
              fill: #acacca;
            }
          }
        }
      }
      .ant-picker-disabled {
        .ant-picker-input input {
          color: #acacca !important;
        }
      }
      .ant-select {
        .ant-select-selector {
          background-color: $inputModalDark !important;
          .ant-select-selection-overflow-item {
            .ant-select-selection-item {
              background-color: #323338;
              .ant-select-selection-item-remove svg {
                fill: #fff;
              }
            }
          }
          .ant-select-selection-search-input {
            color: #fff;
          }
          .ant-select-selection-item {
            color: #fff;
          }
          .ant-select-selection-placeholder {
            color: #acacca;
          }
        }
        .ant-select-clear {
          background-color: $inputModalDark;
          svg {
            fill: #acacca;
          }
        }
        .ant-select-arrow svg {
          fill: #fff;
        }
      }
      .ant-select-disabled {
        .ant-select-selector {
          .ant-select-selection-search-input {
            color: #acacca !important;
          }
          .ant-select-selection-item {
            color: #acacca !important;
          }
        }
      }
      .ant-input {
        background-color: $inputModalDark !important;
        color: #fff;
        &::placeholder {
          color: #acacca;
        }
      }
      .ant-input-disabled {
        color: #acacca;
      }
      .ant-checkbox-wrapper span {
        color: #fff;
      }
      .employee-info-section {
        .emp-info {
          color: #fff;
        }
      }
    }
  }
}
