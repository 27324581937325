.versionComparisonContainer {
  display: flex;
  gap: 10px;
  width: 100%;
}

.serviceVersion {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}
