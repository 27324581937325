.newCreditMemo {
  .invoiceChargeTable {
    width: -webkit-fill-available;
  }
  .invoicesContainer {
    width: -webkit-fill-available;
    .invoiceChargeTable {
      width: -webkit-fill-available;
    }
  }
}
