.basePageModal div div .ant-modal-content .ant-modal-body {
  background: #fff;
}
.basePageModalDark div div .ant-modal-content .ant-modal-body {
  background: #000;
}

.basePageModal {
  .ant-modal .ant-modal-content .ant-modal-body {
    height: 70dvh;
  }
}
