$mainDarkBackgroundColor: #171927;
$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.this-day-weather-popover {
  z-index: 1000 !important;
  .ant-popover-title {
    padding-left: 15px !important;
  }
  .ant-popover-content {
    width: 350px;
    .ant-popover-inner {
      border-radius: 10px;
    }
    .ant-popover-title {
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 16px;
      font-weight: 600;
      background: #f8f8fa;
      border-radius: 10px 10px 0px 0px;
      line-height: 22px;
    }
    .ant-popover-inner-content {
      padding: 20px;
    }

    .inline-info {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      font-size: 16px;
      span {
        text-align: center;
      }
    }
  }
}

.this-day-weather-popover-dark {
  .ant-popover-title {
    padding-left: 15px !important;
  }
  .ant-popover-content .ant-popover-inner {
    background-color: $cardModalBodyDark;
    .ant-popover-title {
      background-color: $cardModalHeaderDark;
      color: #fff;
    }
    .ant-popover-inner-content {
      .ui-tablist {
        color: #fff;
      }
      hr {
        border-color: #fff !important;
      }
    }
  }
  .ant-popover-arrow::before {
    background-color: $cardModalBodyDark;
  }
}

.fullWeekWeather {
  z-index: 9999;
  .ant-modal-body {
    padding: 0px !important;
    height: 600px;
    // overflow-y: auto;
    .MuiTableBody-root {
      .MuiTableCell-root:nth-child(2) {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
      }
    }
  }
}

.fullWeekWeatherDark {
  .ant-modal-body {
    // .MuiTableHead-root {
    //   .MuiTableCell-root {
    //     color: #fff;
    //     background-color: $mainDarkBackgroundColor;
    //     border-bottom-color: grey;
    //   }
    // }
    // .MuiTableBody-root {
    //   .MuiTableCell-root {
    //     color: #fff;
    //     border-bottom-color: grey;
    //   }
    // }
    .ant-table-wrapper {
      background-color: $cardModalBodyDark;
      .ant-table {
        background-color: $cardModalBodyDark;
        .ant-table-container {
          border-start-start-radius: 0px;
          border-start-end-radius: 0px;
        }
        .ant-table-header {
          border-radius: 0px;
          table {
            border-radius: 0px;
          }
          .ant-table-cell {
            text-align: center;
            background-color: $mainDarkBackgroundColor;
            color: #fff;
          }
        }
        .ant-table-body {
          .ant-table-tbody {
            .ant-table-row {
              background-color: $cardModalBodyDark;
              .ant-table-cell {
                color: #fff;

                span {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 5px;
                }
              }
              .ant-table-cell-row-hover {
                background-color: $inputModalDark;
              }
            }
          }
        }
      }
    }
  }
}
