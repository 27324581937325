.code-confirmation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .otpInput {
    display: flex;
    gap: 4px;
    .inputStyle {
      width: 80px;
      height: 80px;
      border: none;
      border-radius: 10px;
      background-color: #ffffff;

      .focusStyle {
        border: 1px solid #cfd3db;
        outline: none;
      }
      .ant-input-number-input {
        text-align: center !important;
        font-size: 30px !important;
        width: 100% !important;
        height: 80px !important;
        background-color: #ffffff !important ;
        border-radius: 10px !important;
      }
    }
  }
}
