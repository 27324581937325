@use "/src/index.scss" as index;

.grouped-reports-card.card-component {
  height: 100%;
  width: 100%;
  .card-header .custom-header {
    display: flex;
    align-items: center;
    gap: 20px;
    .ant-form-item {
      margin-bottom: 0px;
      .ant-select-selector {
        background-color: #fff !important;
        .ant-select {
          background-color: #fff !important;
        }
      }
    }
  }
  .card-body {
    padding: 10px 0px !important;
    .reports-container {
      height: calc(100% - 20px);
      display: flex;
      flex-direction: column;
      padding: 10px;
      overflow-y: auto;
      gap: 10px;
      .report-card {
        height: 130px;
        min-height: 130px;
        background-color: #f4f5f6;
        border-radius: 5px;
        padding: 10px;
        overflow: hidden;
        animation: cardFadeIn 0.8s ease-out;
        box-shadow: 0px 2px 4px 0px #211f2027;
        &.hr {
          box-shadow: 0px 2px 4px 0px #1bd60a50;
        }
        &.noLunch {
          box-shadow: 0px 2px 4px 0px #dfab0199;
        }
        &.incorrect {
          box-shadow: 0px 2px 4px 0px #f54d4a99;
        }
        .report-header {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 20px;
          .header-title {
            display: flex;
            align-items: center;
            gap: 20px;
          }
          label {
            font-weight: 600;
            &:first-child {
              font-size: 16px;
            }
          }
          .hr {
            color: #1bd60a;
            text-transform: capitalize;
          }
          .noLunch {
            color: #dfab01;
            text-transform: capitalize;
          }
          .incorrect {
            color: #f54d4a;
            text-transform: capitalize;
          }
        }
        .report-body {
          display: grid;
          grid-template-columns: repeat(5, calc(20% - 10px));
          gap: 10px;
          .punch-type-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            .button-inner-text {
              font-weight: 600;
            }
            .button-inner-active {
              cursor: pointer;
              &:hover {
                color: index.$blue;
              }
            }
            .entries-container {
              max-height: 87px;
              width: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
              gap: 5px;
              overflow-y: auto;
              label {
                cursor: pointer;
                user-select: none;
                &.incorrect-entry {
                  color: index.$red;
                }
                &:hover {
                  font-weight: 600;
                  color: index.$blue;
                }
              }
            }
          }
        }
      }
    }
    .no-reports-message {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      color: #acacac;
    }
  }
  .reports-count {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 5px 20px 0px 20px;
    color: #acacac;
  }
}

.grouped-reports-card.card-component-dark {
  .card-header .custom-header {
    display: flex;
    gap: 20px;
    .ant-form-item {
      margin-bottom: 0px;
      .ant-select-selector {
        background-color: index.$inputDarkColor !important;
        .ant-select {
          background-color: index.$inputDarkColor !important;
        }
      }
    }
  }
  .card-body {
    .reports-container {
      .report-card {
        background-color: index.$mainDarkBackgroundColor;
        .report-header {
          color: #fff;
        }
        .report-body {
          .punch-type-container {
            .button-inner-text {
              color: #fff;
            }
          }
          .entries-container {
            color: #fff;
          }
        }
      }
    }
    .no-reports-message {
      color: #acacca;
    }
    .reports-count {
      color: #acacca;
    }
  }
}

@keyframes cardFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
