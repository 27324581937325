$inputModalDark: #12131b;
$inputModalDarkSecondary: #1f212d;

.MultipleAutoComplete {
  .ant-form-item {
    margin-bottom: 0px;
  }
  .EditDropdownComponent {
    width: 350px;
    height: 33px;
    background: #f5f5f7 0% 0% no-repeat padding-box;
    opacity: 1;
    border-radius: 5px;
    border: 0px;
    .ant-select-selection-overflow {
      position: relative;
      display: flex;
      flex: auto;
      flex-wrap: nowrap;
      max-width: 100%;
      overflow-x: auto;
      overflow-y: hidden;
      .ant-select-selection-item-remove {
        display: flex;
        align-items: center;
      }
    }
    ::-webkit-scrollbar {
      width: 10px;
      height: 5px;
      background-color: transparent;
    }
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #f5f5f7;
    border: 0px;
    border-radius: 5px;
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(
      .ant-select-customize-input
    )
    .ant-select-selector {
    box-shadow: none;
  }
  .EditDropDownClassName {
    padding: 0;
    border-radius: 5px;

    .ant-select-item {
      padding: 3px 12px;
      width: 350px;
    }
  }
}

.MultipleAutoCompleteDark {
  .ant-select {
    background-color: $inputModalDark !important;
    .ant-select-selector {
      background-color: $inputModalDark !important;
      color: #f2f2f2;
      .ant-select-selection-search-input {
        background-color: $inputModalDark;
        color: #f2f2f2;
      }
      .ant-select-selection-placeholder {
        color: #acacca;
        z-index: 2;
      }
      .ant-select-selection-overflow {
        .ant-select-selection-item {
          background-color: $inputModalDarkSecondary;
          .ant-select-selection-item-content {
            color: #f2f2f2;
          }
          .ant-select-selection-item-remove svg {
            fill: #f2f2f2;
          }
        }
      }
    }
    .ant-select-arrow svg {
      fill: #f2f2f2;
    }
  }
}
