.idle-duration-picker {
  .time-select-section {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    gap: 10px;
  }

  .ant-input {
    width: 80px;
    border-end-start-radius: 0;
    border-end-end-radius: 0;
  }

  .ant-form-item-control-input-content {
    width: max-content;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-input-group-addon {
    padding: 0;
  }
  .idle-addon-after-select {
    .ant-form-item {
      margin-bottom: 0;
    }
  }

  .buttons-section {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
  }
}

.idle-duration-picker.trips-picker-form-dark {
  .time-select-section {
    .ant-select-selector {
      background-color: #3a3c4e !important;
      color: #f5f5f7 !important;
      border: 0;
    }
    .ant-select-arrow,
    .ant-picker-suffix {
      color: #f5f5f7 !important;
    }

    .ant-input,
    .ant-input-group {
      background-color: #3a3c4e !important;
    }

    .labeledInputContainer .ant-input-group-addon {
      background-color: #3a3c4e !important;
    }

    .ant-input {
      input {
        background-color: #3a3c4e !important;
      }
    }

    .ant-select-arrow {
      svg {
        fill: #f5f5f7 !important;
      }
    }

    .anticon {
      color: #fff;
      background-color: #3a3c4e;
    }

    .ant-select-selection-placeholder {
      color: #acacca;
    }

    input::placeholder {
      color: #acacca;
    }

    .ant-picker {
      background-color: #3a3c4e !important;
      color: #f5f5f7 !important;
      border: 0;

      .ant-picker-input {
        input {
          color: #f5f5f7 !important;
        }
      }
    }
  }
}
