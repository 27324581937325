@import "../../../../../index.scss";

.deleteModal {

    .ant-modal-header {
        height: 45px;
        background: #f8f8fa;
        border-radius: 10px 10px 0 0;

        .ant-modal-title {
            font-weight: 600;
            font-size: 16px;
            color: #323338;
        }
    }

    .ant-modal-content {
        height: 13rem;
        border-radius: .7em;
    }

    .ant-modal-body {
        height: calc(100% - 97px);
        font-weight: 400;
        font-size: 1.1rem;
        text-align: center;
    }

    .ant-modal-footer {
        height: 52px;
        width: 97%;
        margin-left: 1.5%;
        border: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;

        & .close {
            background-color: #fe4c4a;
            width: 150px;

            .mondayButtonDivider {
                background-color: #eb4345;
            }

            &:hover {
                background-color: #e64442;
            }
        }

        & .submit {
            width: 150px;
        }
    }

}