.schedule-crews-modal {
  .ant-modal-header {
    display: flex;
    align-items: center;
    height: 45px;
    background-color: #f5f5f5;
  }
  .previewCrewsModal {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .filterSect {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
      .ant-input {
        width: 200px !important;
        border: none !important;
        background-color: #f5f5f5 !important;
      }
      .ant-select {
        width: 200px !important;
      }
      .ant-select-selector {
        border: none !important;
        background-color: #f5f5f5 !important;
      }
    }
  }
}
