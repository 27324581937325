.overview-data-container {
  height: 100%;
  width: 100%;
  // border: 1px solid orange;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  flex-wrap: nowrap;

  .overview-dynamic-data {
    width: 100%;
    flex-grow: 5;
    .overview-filter-totals {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;
      gap: 2rem;

      strong {
        font-weight: 500;
        color: #323338df;
      }
    }
    // border: 1px solid crimson;
    .overview-grid-container {
      height: 100%;
      flex: 1;
    }
  }

  .overview-totals-container {
    // border: 1px solid blue;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
  }
}
