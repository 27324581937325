.event-cell-renderer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  padding: 0 10px;

  .event-name {
    font-size: 16px;
  }
}

.event-cell-renderer-dark {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  padding: 0 10px;

  .event-name {
    font-size: 16px;
    color: white;
  }
}
