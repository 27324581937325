.inventoryItemsEditCardContainer {
  .openInventoryLink {
    width: 100%;
    padding: 0.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-table-wrapper {
    width: 100% !important;
    overflow-x: scroll;
    overflow-y: auto;
    max-height: 550px;
  }

  .fleetItemsEditGridContainer {
    min-width: 843px;
    min-height: 300px;
    max-height: 500px;

    .gridInput {
      width: 100px !important;
      height: 32px !important;
      min-width: unset !important;
      input {
        text-align: center;
      }
    }

    .totalInput {
      width: 100px !important;
      height: 32px !important;
      min-width: unset !important;
      input {
        text-align: center;
      }
    }
    .ant-form-item {
      margin: 0;
    }
  }

  table {
    min-width: 843px;

    .ant-table-thead {
      .ant-table-cell {
        font-weight: 550;
        font-size: 0.8rem;
        white-space: nowrap;
      }
    }

    .ant-table-cell {
      .gridInput {
        width: 90px !important;
        height: 32px !important;
        min-width: unset !important;
        padding: 0;
        // transform: translateY(-50%);
        input {
          text-align: center;
        }
      }
      .ant-form-item {
        margin: 0;
      }
    }
  }

  .addItemsSection {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    gap: 4rem;
  }

  .actionButtons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 1rem;
    button {
      background-color: #fe4c4a;
      svg {
        fill: #fff;
      }
    }
  }

  .confirmContainer {
    display: flex;
    margin-top: 20px;
    justify-content: center;
    gap: 5rem;
    .cancelButton {
      margin-right: 10px;
      background-color: #fe4c4a;
    }
    .cancelButtonDivider {
      color: #f14543;
    }
    .cancelButton:hover {
      background-color: #f14543;
      .cancelButtonDivider {
        color: #fe4c4a;
      }
    }
    .confirmButton {
      margin-right: 10px;
      background-color: #71cf48;
    }
    .confirmButtonDivider {
      color: #69c242;
    }
    .confirmButton:hover {
      background-color: #69c242;
      .confirmButtonDivider {
        color: #71cf48;
      }
    }
  }
}

.inventoryItemsEditCardContainer.inventory-items-dark {
  background-color: #1f212d;
  color: #ffffff;

  .labeledInputLabel {
    color: #ffffff;
  }

  button {
    border-color: transparent;
  }

  .ant-select {
    .ant-select-selector {
      background-color: #12131b !important;
      .ant-select-selection-search-input {
        color: #fff;
      }
      .ant-select-selection-item {
        color: #fff;
      }
      .ant-select-selection-placeholder {
        color: #acacca;
      }
      .ant-select-selection-overflow-item .ant-select-selection-item {
        background-color: #323338;
        .ant-select-selection-item-remove svg {
          fill: #fff;
        }
      }
    }
    .ant-select-clear {
      background-color: #12131b;
      svg {
        fill: #acacac;
      }
    }
    .ant-select-arrow svg {
      fill: #fff;
    }
  }

  .ant-table {
    background-color: #1f212d;
    .ant-table-thead {
      .ant-table-cell {
        background-color: #12131b;
        color: #fff;
      }
    }
    .ant-table-tbody {
      .ant-table-cell {
        background-color: #1f212d;
        color: #fff;
      }
    }
  }

  textarea,
  input,
  .ant-input-group-addon,
  .ant-input-group {
    background-color: #12131b !important;
    color: #ffffff !important;
  }
}
