@import "../../../../../../../index.scss";

.chartCardModal {
  width: calc(100vw - 20px) !important;
  height: calc(100vh - 20px) !important;
  max-width: 1300px;
  max-height: 800px;
  .ant-modal-content {
    width: calc(100vw - 20px) !important;
    height: calc(100vh - 20px) !important;
    max-width: 1300px;
    max-height: 800px;
    .ant-modal-close {
      height: 45px;
      width: 45px;
      .ant-modal-close-x {
        height: 45px;
        width: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .ant-modal-header {
      height: 45px;
      display: flex;
      align-items: center;
      background-color: #f4f5f6;
    }
    .ant-modal-body {
      height: calc(100% - 100px);
      .chart-creation-container {
        height: 100%;
        width: 100%;
        display: flex;
        gap: 10px;
        .chart-form-container {
          height: 100%;
          width: 30%;
          .main-form-fields {
            width: 100%;
            display: flex;
            flex-direction: column;
          }
          .ant-form-item {
            margin-bottom: 10px;
          }
          .form-cards-container {
            height: calc(100% - 200px);
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 10px;
            .add-action-container {
              width: 100%;
              .mondayButtonBlue {
                width: 100%;
              }
            }
            .dataset-cards-container {
              height: calc(100% - 42px);
              width: 100%;
              display: flex;
              flex-direction: column;
              gap: 10px;
              overflow-y: auto;
              overflow-x: hidden;
              .dataset-card {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                background-color: #f4f5f6;
                border-radius: 5px;
                padding: 10px;
                gap: 10px;
                animation: showFormCard 0.4s ease;
                .field-container {
                  width: 100%;
                  display: flex;
                  flex-direction: column;
                  gap: 10px;
                  .ant-form-item {
                    margin-bottom: 0px;
                    .ant-select {
                      .ant-select-selector {
                        background-color: #fff !important;
                      }
                      .ant-select-clear {
                        background-color: #fff;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .chart-overview-container {
          height: 100%;
          width: 70%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .ant-modal-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

.chartCardModalDark {
  .ant-modal-content {
    background-color: $mainDarkBackgroundColor;
    .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardDarkBackgroundColor;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      .labeledInputLabel {
        color: #fff;
      }
      .chart-creation-container {
        .chart-form-container {
          .form-cards-container {
            .dataset-cards-container {
              .dataset-card {
                background-color: $cardDarkBackgroundColor;
                .field-container {
                  .ant-form-item {
                    .ant-select {
                      .ant-select-selector {
                        background-color: $mainDarkBackgroundColor !important;
                        .ant-select-selection-overflow-item {
                          .ant-select-selection-item {
                            background-color: #333238;
                            .ant-select-selection-item-remove svg {
                              fill: #fff;
                            }
                          }
                        }
                        .ant-select-selection-search-input {
                          color: #fff;
                        }
                        .ant-select-selection-item {
                          color: #fff;
                        }
                        .ant-select-selection-placeholder {
                          color: #acacca;
                        }
                      }
                      .ant-select-clear {
                        background-color: $mainDarkBackgroundColor;
                        svg {
                          fill: #acacca;
                        }
                      }
                      .ant-select-arrow svg {
                        fill: #fff;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .chart-overview-container {
          height: 100%;
          width: 60%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}

@media (max-width: 910px) {
  .chartCardModal {
    .ant-modal-content {
      .ant-modal-body .chart-creation-container {
        flex-direction: column-reverse;
        align-items: center;
        justify-content: flex-end;
        .chart-form-container {
          height: 50%;
          width: 100%;
          display: flex;
          gap: 10px;
          .form-cards-container {
            height: 100%;
          }
        }
        .chart-overview-container {
          height: 50%;
        }
      }
    }
  }
}

@keyframes showFormCard {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
