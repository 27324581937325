@import "../../../../../../../../../../index.scss";

.sovModalContainer {
	display: flex;
	width: 100%;
	gap: 20px;
	justify-content: flex-end;
	.dropDownButtonNewSOV {
		margin: -5px 5px 0 10px;

		.ant-btn-primary {
			border-top-left-radius: 5px;
			border-bottom-left-radius: 5px;
		}

		.ant-dropdown-trigger {
			border-top-right-radius: 5px;
			border-bottom-right-radius: 5px;
			border-top-left-radius: 0px;
			border-bottom-left-radius: 0px;
		}
	}

	.cancelButton {
		margin-top: -5px;
		@include mondayButton(#fe4c4a, #eb4345, #fff);
	}
	.saveButton {
		margin-top: -5px;
		@include mondayButton(#68c142, #71cf48, #fff);
	}
}
