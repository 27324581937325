@import "../../../../../../index.scss";

.uploadedFilesModal {
  .fileName {
    @include text(17px, normal, 0, #0984ff);
    cursor: pointer;
  }
  .infoValue {
    @include text(17px, normal, 0, #767b81);
  }

  .filesContainer {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 30px;
    padding: 20px;
  }
}
