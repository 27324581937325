@import "../../../index.scss";

.custom-checkbox-wrapper {
  height: 32px;
  min-height: 32px;
  width: 100%;
  display: flex;
  overflow: hidden;
  background-color: #f5f5f7;
  border: none;
  border-radius: 0px;
  cursor: pointer;
  padding: 0;
  margin: 0;
  font-size: 16px;

  .ant-checkbox-wrapper {
    height: 32px !important;
    display: flex;
    align-items: center;
    width: 100%;

    .ant-checkbox {
      .ant-checkbox-inner {
        border-radius: 0;
        background-color: #fe4c4a;
        width: 8px;
        height: 32px;
        border: none;
      }
    }

    //checked
    .ant-checkbox-checked {
      .ant-checkbox-inner {
        border-radius: 0;
        background-color: #68c142;
        width: 8px;
        height: 32px;
        border: none;

        &::after {
          content: url("../../pages/Settings/settingsComponents/Roles/src/TickIcon.svg");
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          opacity: 0;
          transition: visibility 0.2s, opacity 0.2s;
          border: none;
        }
      }
    }

    //indeterminate
    .ant-checkbox-indeterminate {
      .ant-checkbox-inner {
        border-radius: 0;
        background-color: #0f5c97;
        width: 8px;
        height: 32px;
        border: none;

        &::after {
          content: url("../../../icons/minus.svg");
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          opacity: 0;
          margin-top: 4px;
          transition: visibility 0.2s, opacity 0.2s;
          border: none;
        }
      }
    }

    .ant-checkbox-disabled {
      .ant-checkbox-inner {
        width: 8px;
        height: 32px;
        background-color: #8d959c;
        border: none;
      }
    }
  }

  &:hover {
    .ant-checkbox-wrapper {
      .ant-checkbox {
        .ant-checkbox-inner {
          width: 32px;
          border: 6px solid #ff7274;
          background-color: #eb4345;
        }
      }

      .ant-checkbox-checked {
        .ant-checkbox-inner {
          border: 6px solid #71cf48 !important;
          background-color: #68c142 !important;
          padding: 2px;

          &::after {
            opacity: 1;
          }
        }
      }

      .ant-checkbox-indeterminate {
        .ant-checkbox-inner {
          border: 6px solid #1c7ac2;
          background-color: #0f5c97;
          width: 32px;
          padding: 2px;

          &::after {
            opacity: 1;
            background-color: transparent;
          }
        }
      }

      .ant-checkbox-disabled {
        .ant-checkbox-inner {
          border-radius: 0;
          border: 6px solid #aeb8c0;
          background-color: #8d959c;
          width: 32px;
        }
      }
    }
  }
}
.custom-checkbox-wrapper-dark {
  background-color: $cardDarkBackgroundColor !important;
  span {
    color: #fff;
  }
}
