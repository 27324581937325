.userLinkCardNotLinkedContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: nowrap;
  row-gap: 2rem;
  padding-top: 4rem;
  padding-bottom: 4rem;

  .warningContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: nowrap;
    row-gap: 1rem;
    span {
      font-size: 1.2rem;
      font-weight: 500;
    }
  }

  .buttonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 1.5rem;
  }
}

.userLinkCardLinkedContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
  flex-wrap: nowrap;
  row-gap: 2rem;
  padding-top: 1rem;
  padding-bottom: 0rem;
  transform: translateX(-10px);

  .cardLogoutActionButton {
    background-color: #1264a3;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border-radius: 4px;
    height: 25px;
    display: grid;
    justify-items: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    &:hover {
      background-color: #0f568d;
    }
  }

  .infoContainer {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto auto;
    column-gap: 4rem;
    row-gap: 0.5rem;
  }

  .buttonsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 1rem;
  }

  .sessionGridContainer {
    min-height: 7rem;
    max-height: 10rem;
    width: 100%;
    overflow: auto;
    .ag-overlay-no-rows-center {
      display: none;
    }
  }
}

.userLinkCardNotLinkedContainerDark,
.userLinkCardLinkedContainerDark {
  .warningContainer {
    color: #fff;
  }
  .infoContainer {
    .info {
      span,
      strong {
        color: #fff;
      }
    }
  }
}
