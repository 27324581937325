.field-view-container {
  height: 100dvh;
  width: 100dvw;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 10px;
  gap: 10px;
  background-color: #f4f5f6;
  .field-body-section {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    height: calc(100dvh - 74px);

    // flex: 1;
  }

  @media only screen and (max-width: 460px) {
    ::-webkit-scrollbar {
      display: block !important;
    }
  }
}
