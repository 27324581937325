$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.specificWarningsWrap {
  overflow: hidden;
}

.specificWarningsModal {
  height: fit-content;
  width: fit-content !important;

  .ant-modal-content {
    min-width: 45dvw;
    height: 50dvh;

    .ant-modal-header {
      height: 45px;
      width: 100%;
      display: flex;
      align-items: center;
      background-color: #f8f8f8;

      .ant-modal-title {
        color: #323338;
        font-weight: 600;

        .titleInfoSpan {
          color: #fe4c4a;
        }
      }
    }

    .ant-modal-body {
      height: calc(100% - 45px);
      overflow-x: hidden;
      overflow-y: auto;

      .ant-table-wrapper {
        height: 100%;

        .ant-spin-nested-loading {
          height: 100%;

          .ant-spin-container {
            height: 100%;

            .ant-table {
              height: calc(100% - 35px);
            }

            .ant-pagination {
              padding: 0;
              margin: 0;
            }
          }
        }
      }
    }
  }
}

.specificWarningsModalDark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      .ant-table {
        background-color: $cardModalBodyDark;
        .ant-table-thead {
          th {
            background-color: $cardModalHeaderDark;
            color: #fff;
          }
        }
        td {
          color: #fff;
        }
        // .ant-table-wrapper
        //   .ant-table-tbody
        //   .ant-table-row
        //   >
        .ant-table-cell-row-hover {
          background-color: #272a3a;
        }
      }
      .ant-pagination {
        .ant-pagination-prev,
        .ant-pagination-next {
          .ant-pagination-item-link {
            color: #fff;
          }
        }
        .ant-pagination-disabled {
          .ant-pagination-item-link {
            color: #acacca;
          }
        }
        .ant-pagination-item a {
          color: #fff;
        }
        .ant-pagination-item-ellipsis {
          color: #fff;
        }
        .ant-pagination-item-active {
          background-color: $cardModalBodyDark;
        }
      }
    }
  }
}
