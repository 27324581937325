@import "/src/index.scss";
.project-match-grid-view-container {
  height: calc(100vh - 184px);
  border-radius: 10px;
  overflow: hidden;

  .grid-container {
    height: 100%;
  }

  .ag-details-row {
    padding: 0;
  }

  .main-address-class {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    cursor: pointer;

    .main-address-arrow {
      color: #dee2eb;
      transition: transform 0.3s ease-in-out;
      fill: currentColor !important;
      path {
        fill: currentColor !important;
      }
    }

    .address-class-divider {
      height: 15px;
      width: 2px;
      margin-left: 10px;
      background-color: #dee2eb;
    }

    .address-route-no {
      margin-left: 10px;
    }
  }

  .light-ag-theme {
    .ag-details-row {
      .ag-root {
        .ag-header {
          background-color: #fff !important;
          border-bottom: 1px solid transparent;
          .ag-header-container {
            .ag-header-row {
              .ag-header-cell {
                background-color: #fff !important;
                .ag-header-cell-resize {
                  &::after {
                    background-color: transparent !important;
                  }
                }
              }
            }
          }
        }

        .ag-body-viewport {
          .ag-row-even,
          .ag-row-odd {
            border-bottom: 1px solid transparent;
            background-color: #fff;
          }
        }
      }
    }

    .ag-root-wrapper {
      .ag-header-cell {
        background-color: #f8f8fa !important;
      }
    }

    .ag-side-bar {
      background-color: #f8f8fa !important;
    }
  }

  .dark-ag-theme {
    .ag-details-row {
      background-color: $cardDarkHeaderColor;
      .ag-unselectable {
        background-color: #2b2b3a;
      }

      .ag-root {
        .ag-header-row {
          border-top: 1px solid transparent;
        }

        .ag-center-cols-viewport {
          background-color: #2b2b3a;
        }

        .ag-header {
          background-color: #2b2b3a;
          border-bottom: 1px solid transparent;

          .ag-header-container {
            .ag-header-row {
              .ag-header-cell {
                font-weight: 600;
                .ag-header-cell-resize {
                  &::after {
                    background-color: transparent !important;
                  }
                }
              }
            }
          }
        }

        .ag-body-viewport {
          .ag-row-even,
          .ag-row-odd {
            border-bottom: 1px solid transparent;
            background-color: #2b2b3a;
          }
        }
      }
    }
    .main-address-class {
      .main-address-arrow {
        color: #f9f9f9;
        transition: transform 0.3s ease-in-out;
        fill: currentColor !important;
        path {
          fill: currentColor !important;
        }
      }

      .address-class-divider {
        background-color: #616481;
      }

      .address-route-no {
        color: #acacac;
      }
    }
  }
}
