.rental-view-light {
  position: fixed;
  top: 0;
  left: 0;
  height: stretch;
  max-height: stretch;
  width: stretch;
  display: flex;
  flex-direction: column;
  background: #f8f8f8;
  z-index: 10;
  overflow-x: hidden;
  overflow-y: auto;
  transition: background-color 0.3s;

  .rental-view-body {
    overflow-y: auto;
  }

  .rentalViewBodyContainer {
    display: flex;
    height: fit-content;
    width: stretch;
    gap: 20px;
    padding: 0 25px;
  }
}
.rental-view-dark {
  .ant-input-number-group-addon {
    background-color: #f8f8f8;
  }
  .ant-input-number-disabled {
    background-color: #f8f8f8 !important;
  }
  position: fixed;
  top: 0;
  left: 0;
  height: stretch;
  max-height: stretch;
  width: stretch;
  display: flex;
  flex-direction: column;
  background: #1f212d;
  z-index: 10;
  overflow-x: hidden;
  overflow-y: auto;
  transition: background-color 0.3s;

  .rental-view-body {
    overflow-y: auto;
  }

  .rentalViewBodyContainer {
    display: flex;
    height: fit-content;
    width: stretch;
    gap: 20px;
    padding: 0 25px;
  }
}
