.avatar-image-container {
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  > * {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-avatar {
    border: 1px solid white;
    font-size: 12px !important;
  }

  .ant-image {
    padding: 2px;
    img {
      border-radius: 100%;
      height: 100%;
    }
  }
}
