$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.schedulingPickerMainContainer {
  border-radius: 10px;
  width: 100%;
  height: 450px;
  display: flex;
  gap: 14px;
  overflow-x: hidden;
  overflow-y: auto;

  .schedulingPickerContainer {
    width: 343px;
    border-radius: 10px;
    height: 90%;
    box-shadow: 0px 1px 4px #00000029;
    padding: 15px 10px;
    overflow-x: hidden;
    overflow-y: auto;

    .tittle {
      font-weight: bold;
      font-size: 18px;
      margin: 0 0 5px 5px;
    }

    .typeOfWorkContainer {
      display: flex;
      .installation {
        color: #71cf48;
        margin-left: 3px;
      }
      .removal {
        color: #fe4c4a;
        margin-left: 3px;
      }
    }
  }

  .servicePickerContainer {
    width: 343px;
    box-shadow: 0px 1px 4px #00000029;
    border-radius: 10px;
    height: 90%;
    padding: 15px;
    overflow-x: hidden;
    overflow-y: auto;

    .tittle {
      font-weight: bold;
      font-size: 18px;
      margin: 0 0 5px 5px;
    }
  }
}

.schedulingPickerMainContainerDark {
  .servicePickerContainer,
  .schedulingPickerContainer {
    box-shadow: 0px 1px 4px #ffffff94;
    .tittle {
      color: #fff;
    }
    .no-schedule-of-values {
      color: #fff;
    }
    .ant-radio-wrapper {
      background-color: $inputModalDark !important;
      &:hover {
        background-color: #2b2e41 !important;
      }
      span {
        color: #fff;
      }
    }
  }
}

@media (max-width: 750px) {
  .schedulingPickerMainContainer {
    display: grid;
    grid-template-columns: 49% 49%;
    gap: 10px;
    .servicePickerContainer {
      width: 100%;
    }
    .schedulingPickerContainer {
      width: 100%;
    }
  }
}

@media (max-width: 600px) {
  .schedulingPickerMainContainer {
    grid-template-columns: 100%;
    gap: 10px;
    padding: 10px 5px;
    .servicePickerContainer {
      height: 320px;
    }
    .schedulingPickerContainer {
      height: 320px;
    }
  }
}
