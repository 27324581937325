.payment-wire-form-container {
  display: flex;
  gap: 20px;

  .payment-wire-date-details {
    display: flex;
    flex-direction: column;
    label {
      text-align: center;
      font-weight: 600;
    }
  }

  .input-form {
    width: 100%;
    height: 32px;
    width: 200px;
    border-radius: 5px;
    background-color: #f4f5f6 !important;
    padding: 2px;
    padding-left: 5px;
    border: 2px solid transparent;
  }

  .input-form:focus {
    border-radius: 5px;
    border: 2px solid #87c0ef6d !important;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0px;
    box-shadow: 0 0 0 0px rgba(87, 168, 233, 0.2);
  }

  .input-form:hover {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0px;
    box-shadow: 0 0 0 0px rgba(87, 168, 233, 0.2);
  }
}
