@import "../../../config.scss";
:root {
  --primaryColor: #ffffff;
  --secondaryColor: #1264a3;
  --unselectedColor: #6f7782;
}

.dynamicViewPage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  // margin-top: 70px;
  // width: calc(100% - 250px);
  // margin-left: 250px;
  background-color: var(--primaryColor);
}
// @media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
//   .dynamicViewPage {
//     width: calc(100% - 100px) !important;
//     margin-left: 100px !important;
//     height: calc(100vh - 70px) !important;
//     .titleComp {
//       display: none;
//     }
//   }
// }

@media only screen and (max-width: 768px) {
  .dynamicViewPage {
    .titleComp {
      display: none;
    }
  }
}

@media only screen and (max-width: 440px) {
  .dynamicViewPage {
    .tabContainer .tabComponent .ant-tabs-nav {
      width: 100% !important;
    }
    .titleComp {
      display: none;
    }
  }
}
