@import "../../../../../../index.scss";

.dobInfoModalContent {
  .ant-input-affix-wrapper,
  .ant-select-selector,
  .ant-picker,
  .ant-input {
    border: 0;
    border-radius: 5px;
    background-color: #f5f5f7;
  }

  .infoText {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    height: 30px;
    margin-bottom: 10px;
    .text {
      font-size: 16px;
      font-weight: 400;
      font-family: "Open Sans";
    }
  }

  .inlineInputs {
    width: 100%;
    max-width: 864px;
    // display: flex;
    // justify-content: flex-start;
    display: grid;
    grid-template-columns: 37% 35% 22.5%;
    gap: 20px;
    height: 85px;
    // flex-wrap: wrap;

    // .dobAddress {
    //   .ant-form-item {
    //     .ant-input {
    //       width: 100%;
    //       // max-width: 322px;
    //       height: 32px;
    //     }
    //   }
    // }
    // .dobBlock {
    //   .ant-form-item {
    //     .ant-input {
    //       width: 100%;
    //       // max-width: 302px;
    //       height: 32px;
    //     }
    //   }
    // }
    // .dobLot {
    //   .ant-form-item {
    //     .ant-input {
    //       width: 100%;
    //       // max-width: 200px;
    //       height: 32px;
    //     }
    //   }
    // }
    .labeledInputContainer {
      width: 100%;
      min-width: none !important;
      height: fit-content;
    }
  }

  .textEditor {
    border-radius: 5px;
    height: 200px;
    margin-bottom: 10px;
    .richTextComp {
      max-width: 864px;
      border-radius: 5px;
      .tox-tinymce {
        border-radius: 5px;
      }
      .tox-editor-header {
        // background: #f5f5f7;
        border-radius: 5px;
        display: flex;
        justify-content: space-between;
      }
      .toc-toolbar-overlord {
        // background-color: #f5f5f7;
      }
      .tox-toolbar__primary {
        background-color: #f5f5f7;
        display: flex;
        justify-content: space-between;
      }
      .tox-sidebar-wrap {
        border-radius: 5px;
        .tox-edit-area {
          border-radius: 5px;
          .tox-edit-area__iframe {
            margin-top: 10px;
            background: #f5f5f7;
            border-radius: 5px;
            border: none;
          }
        }
      }
    }
  }
  .footer {
    width: 100%;
    display: flex;
    justify-content: space-between;

    svg {
      height: 14px;
      width: 14px;
      fill: white !important;
    }

    .dobFooterCancelButton {
      background-color: #fe4c4a;
      transition: all 0.3s ease-in-out;
      .mondayButtonDivider {
        transition: all 0.3s ease-in-out;
        background: #eb4345 !important;
      }

      &:hover {
        background-color: #e24441;
        .mondayButtonDivider {
          background: #df4042 !important;
        }
      }
    }
  }
}

.dobInfoModalHeader {
  .ant-form-item-control-input-content {
    display: flex;
    align-items: center;
    gap: 20px;
    height: fit-content;
    .labeledInputContainer {
      height: 60px;
    }
  }
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-start;
  align-items: center;
  Input {
    width: 322px;
  }
}

@media (max-width: 660px) {
  .dobInfoModalContent {
    .inlineInputs {
      height: fit-content;
      display: flex !important;
      flex-wrap: wrap !important;
      margin-bottom: 10px;
      .ant-form-item {
        margin-bottom: 0px;
      }
      .labeledInputContainer {
        min-width: none !important;
      }
    }
  }
}
