@import "/src/index.scss";

$headerDarkBackground: #12131b;
$bodyDarkBackground: #20212d;
$inputModalDark: #12131b;

.crewModalContainer {
  width: fit-content !important;
  overflow: auto;
  overflow-y: auto;
  height: calc(100vh - 20px) !important;
  height: calc(100dvh - 20px) !important;
  width: calc(100vw - 20px) !important;
  width: calc(100dvw - 20px) !important;
  max-width: 985px;
  max-height: 720px;
  .ant-modal-content {
    height: calc(100vh - 20px) !important;
    height: calc(100dvh - 20px) !important;
    width: calc(100vw - 20px) !important;
    width: calc(100dvw - 20px) !important;
    max-width: 985px;
    max-height: 720px;
    .ant-modal-close {
      height: 45px;
      width: 45px;
      .ant-modal-close-x {
        height: 45px;
        width: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .ant-modal-header {
      height: 45px;
      display: flex;
      align-items: center;
      background-color: #f4f5f6;
      border: none;
      .ant-modal-title {
        font-size: 16px;
        font-weight: 600;
        width: 100%;
      }
    }
    .ant-modal-body {
      height: calc(100% - 100px);
      overflow-y: auto;
      overflow-x: hidden;
      .newCrewHeaderInfo {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        width: 945px;
        height: 20px;
        margin-bottom: 20px;
        .newCrewHeaderText {
          width: 1000px;
          height: 19px;
          font-size: 14px;
          font-weight: 400;
        }
      }
      .ant-form {
        display: flex;
        flex-direction: column;
        gap: 30px;
        .ant-form-item {
          margin-bottom: 0px;

          .ant-input-number {
            width: 100%;
            .ant-input-number-handler-wrap {
              display: none;
            }
          }
        }
        .fingerCheckFetch {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: calc(100% - 40px);
          height: 53px;
          gap: 30px;
          .createCrewSubcontractor {
            width: 300px;
            .labeledInputLabel {
              font-size: 14px;
            }
          }
          .employeeNumberInput {
            width: 245px;
            .labeledInputLabel {
              font-size: 14px;
            }
          }
          .employeeDataDiv {
            display: flex;
            align-items: center;
            gap: 5px;
            padding-top: 20px;
            color: #1264a3;
            transition: 0.3s ease;
            .employeeNumberUpdate {
              font-size: 14px;
              font-weight: 400;
            }
            path {
              fill: #1264a3;
            }
          }
          .employeeDataDisable {
            display: flex;
            align-items: center;
            gap: 5px;
            padding-top: 20px;
            transition: 0.4s ease-out;
          }
        }
        .formSection {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          height: 53px;
          gap: 30px;
          .labeledInputContainer {
            .labeledInputLabel {
              font-size: 14px;
              font-weight: 600;
            }
          }
          .crewNameInput {
            width: 300px;
          }
          .crewPositionInput {
            width: 300px;
          }
        }
        .firstFormSection {
          width: 660px;
          height: 53px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 30px;
          .labeledInputContainer {
            .labeledInputLabel {
              font-size: 14px;
              font-weight: 600;
            }
          }
          .employeeIdInput {
            width: 300px;
          }
        }
      }
    }
    .ant-modal-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .ant-form-item {
        margin-bottom: 0px;
        .configElementContainer {
          margin-bottom: 0px;
        }
      }
      .mondayButtonStyle {
        width: 150px;
      }
    }
  }

  .ant-input .ant-input-status-success .crew-input {
    background-color: #f4f5f6;
  }
  .crew-select.ant-select-multiple.ant-select-disabled.ant-select:not(
      .ant-select-customize-input
    )
    .ant-select-selector {
    width: 187px;
    border: 0;
    border-radius: 5px;
  }
  .crew-select.ant-select-show-search.ant-select:not(
      .ant-select-customize-input
    )
    .ant-select-selector {
    width: 187px;
    border: 0;
    border-radius: 5px;
    background-color: #f4f5f6;
  }
  .ant-select.ant-select-in-form-item.crew-select.ant-select-multiple.ant-select-show-search {
    width: 187px;
  }
  .foremanDiv,
  .avatarDiv,
  .crewDiv {
    display: flex;
    gap: 20px;
  }
  .accountDiv {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .ant-select.ant-select-in-form-item.crew-select.ant-select-single.ant-select-allow-clear.ant-select-show-arrow {
    width: 187px;
  }
  .crew-select.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border: 0;
    border-radius: 5px;
    background-color: #f4f5f6;
  }
  @media (min-width: 450px) {
    .newFooterContainer button {
      width: 150px;
    }
  }
}

.addOnSelectContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 0px;
}
.hidden-index {
  z-index: 1 !important;
}

.crewModalContainerDark {
  .ant-modal-content {
    background-color: $mainDarkBackgroundColor;
    overflow: hidden;
    .ant-modal-close-x {
      svg {
        fill: #fff;
        path {
          fill: #fff !important;
        }
      }
    }
    .ant-modal-header {
      background-color: $cardDarkBackgroundColor;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      background-color: $mainDarkBackgroundColor;
      .newCrewHeaderText {
        color: #fff;
      }
      .fingerCheckFetch .employeeNumberText {
        font-size: 14px;
        color: #fff;
      }
      .ant-form {
        .employeeDataDisable {
          .employeeNumberUpdate {
            color: #acacca;
          }
          path {
            fill: #acacca !important;
          }
        }
        .labeledInputContainer {
          .ant-input-wrapper {
            height: 32px;
            background-color: $cardDarkBackgroundColor !important;
            .ant-input-group-addon {
              background-color: $cardDarkBackgroundColor !important;
              color: #fff;
            }
          }
          .ant-input-number {
            background-color: $cardDarkBackgroundColor !important;
            // .ant-input-number-handler-wrap {
            //   display: none;
            // }
            .ant-input-number-input {
              background-color: $cardDarkBackgroundColor !important;
              color: #fff;
              &::placeholder {
                color: #acacca;
              }
            }
          }
          .ant-input {
            background-color: $cardDarkBackgroundColor !important;
            color: #fff;
            &::placeholder {
              color: #acacca;
            }
          }
          .ant-input-disabled {
            color: #acacca;
          }
          .ant-input-affix-wrapper {
            background-color: $cardDarkBackgroundColor !important;
          }
          .labeledInputLabel {
            color: #fff;
          }
          .ant-select {
            .ant-select-selector {
              background-color: $cardDarkBackgroundColor !important;
              .ant-select-selection-search-input {
                background-color: $cardDarkBackgroundColor !important;
                color: #fff;
              }
              .ant-select-selection-placeholder {
                color: #acacca;
                z-index: 2;
              }
              .ant-select-selection-item {
                color: #fff;
              }
            }
            .ant-select-arrow {
              svg {
                fill: #fff;
                path {
                  fill: #fff !important;
                }
              }
            }
          }
        }
      }
    }
    .ant-modal-footer {
      .ant-form-item {
        margin-bottom: 0px;
        .configElementContainer {
          margin-bottom: 0px;
          background-color: $inputModalDark;
          .configElementTitle {
            background-color: $inputModalDark;
            color: #fff;
          }
        }
      }
    }
  }
}

@media (max-width: 555px) {
  .crewModalContainer {
    max-height: fit-content;
    .ant-modal-content {
      max-height: fit-content;
      .ant-modal-body {
        .ant-form {
          gap: 10px;
          .fingerCheckFetch {
            display: grid;
            grid-template-columns: 50% 50%;
            .employeeNumberText {
              grid-column: 1 / span 2;
            }
          }
          .formSection {
            flex-direction: column;
            .labeledInputContainer {
              width: 100%;
            }
          }
        }
      }
      .ant-modal-footer {
        gap: 10px;
        .mondayButtonStyle {
          width: 100px;
        }
      }
    }
  }
}

// .crewModalContainer-mobile {
//   .ant-modal-content {
//     .ant-modal-body {
//       .ant-form {
//         .crewDiv {
//           display: flex;
//           flex-direction: column;
//           gap: 0;
//         }

//         .foremanDiv {
//           display: flex;
//           flex-direction: column;
//           gap: 0;
//         }
//       }
//     }
//   }
// }
