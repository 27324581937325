.teams-list-transfer {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  max-width: 1200px;
  width: 100%;
  max-height: 120px;
  overflow-y: auto;
  .teams-list-transfer-item {
    height: 44px;
    border-radius: 5px;
    display: flex;
    width: fit-content;
    gap: 5px;
    align-items: center;
    background-color: #f4f5f6;
    padding: 10px;
    cursor: pointer;
    &:hover {
      background-color: #f1f5fd;
      color: #1264a3;
    }
  }
  .teams-list-transfer-item.selected {
    background-color: #f1f5fd;
    border: 1px solid #1264a3;
    color: #1264a3;
    height: 42px;
  }
  .teams-list-transfer-item.custom {
    background-color: #f1f5fd;
    border: 1px solid orange;
    color: orange;
    height: 42px;
  }
}

.teams-list-transfer-dark {
  .teams-list-transfer-item {
    background-color: #2a2b3a;
    color: #fff;
    &:hover {
      background-color: lighten(#2a2b3a, 10%);
    }
  }
  .teams-list-transfer-item.selected {
    background-color: #2a2b3a;
    font-weight: 600;
  }
}

//region Teams Popover
.teams-popover {
  .ant-popover-inner {
    padding: 10px 20px !important;
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media screen and (min-width: 1600px) {
      width: 550px;
    }
    .ant-popover-title {
      margin: 0;
    }
    .ant-popover-inner-content {
      padding: 0px !important;
      .teams-popover {
        display: flex;
        flex-direction: column;
        gap: 20px;
        .teams-popover-title {
          display: flex;
          gap: 10px;
          font-weight: 600;
          align-items: center;
          height: 15px;
          align-items: center;
          .selectAll {
            border-left: 2px solid #e6e9ef;
            color: #acacac;
            padding-left: 10px;
            font-size: 14px;
            font-weight: 400;
            cursor: pointer;
            &:hover {
              color: #1264a3;
            }
          }
        }
        .teams-popover-content {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          row-gap: 10px;
          column-gap: 10px;
          align-items: center;
          .hover-button-container {
            width: fit-content;
            padding: 0;
          }
          //   .teams-popover-content-item {
          //   }
        }

        .team-popover-footer {
          display: flex;
          justify-content: space-between;
          gap: 20px;
          @media screen and (min-width: 1600px) {
            .mondayButtonContainer button {
              min-width: 150px;
            }
          }
        }
      }
    }
  }
}

.teams-popover-dark {
  .ant-popover-inner {
    background-color: #2a2b3a !important;
    .ant-popover-inner-content {
      .teams-popover {
        .teams-popover-title {
          color: #fff;
          .selectAll {
            color: #acacac;
            &:hover {
              color: #1264a3;
            }
          }
        }
        .teams-popover-content {
          .teams-popover-content-item {
            background-color: #2a2b3a;
            color: #fff;
            &:hover {
              background-color: lighten(#2a2b3a, 10%);
              font-weight: 600;
            }
          }
          .teams-popover-content-item.selected {
            background-color: #2a2b3a;
            font-weight: 600;
          }
        }
        .team-popover-footer {
          .mondayButtonContainer button {
            background-color: #1264a3;
            color: #fff;
            &:hover {
              background-color: #0e4c7c;
            }
          }
        }
      }
    }
  }
}
