@import url(https://fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,700italic,300,400,600,700);
// @import url(../../Desktop/GeogrotesqueStencilA-SbItalic);

body {
  margin: 0;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
  // scrollbar-width: thin !important;
  // scrollbar-color: #222a44 #f1f1f1;
  // scroll-behavior: smooth !important;
}

$mainDarkBackgroundColor: #20212d;
$cardDarkHeaderColor: #2a2b3a;
$cardDarkBackgroundColor: #12131b;
$inputDarkColor: #2a2b3a;
$red: #fe4c4a;
$blue: #1264a3;
$green: #71cf48;
$textColor: #323338;

#webpack-dev-server-client-overlay {
  display: none !important;
}
// * {
// scrollbar-width: thin !important;
// }

.ant-checkbox-wrapper {
  .ant-checkbox-indeterminate {
    .ant-checkbox-inner {
      &::after {
        transform: rotate(0) scale(1) translate(-50%, -50%) !important;
      }
    }
  }
}
// .ag-theme-alpine {
//   .ag-root-wrapper {
//     border: none;
//     .ag-column-drop-horizontal {
//       border: none;
//       background-color: unset;
//     }
//     .ag-side-bar-right {
//       border: none;
//       background-color: unset;
//     }
//     .ag-paging-panel {
//       border: none;
//     }
//   }
// }

//after removed import "bootstrap/dist/css/bootstrap.min.css"
.form-group {
  margin-bottom: 16px;
}
button {
  cursor: pointer;
}

.card {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
} //after removed import "bootstrap/dist/css/bootstrap.min.css"

#root {
  --scroll-thumb-bg: #222a44;
  --eZez: #000;
  height: 100%;
  width: 100%;
  font-family: "Open Sans" !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a:hover {
  text-decoration: none;
}
.ant-message-error,
.ant-message-success,
.ant-message-info,
.ant-message-warning {
  display: flex;
  align-items: center;
}
//SCROLLBAR

/* width */

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

// display none for mobile dimensions
@media only screen and (max-width: 460px) {
  ::-webkit-scrollbar {
    display: none;
  }
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--scrollbar-track-bg, transparent);
}

/* Handle */
::-webkit-scrollbar-thumb {
  // background: #222a44;
  background: var(--scroll-thumb-bg, #222a44);
  border-radius: 5px;
  border: 2px solid var(--scrollbar-track-bg, transparent);
}

/* Button */
::-webkit-scrollbar-button:single-button {
  background-color: transparent;
  display: block;
  background-size: 10px;
  background-repeat: no-repeat;
}

/* Up Arrow*/
::-webkit-scrollbar-button:single-button:vertical:decrement {
  height: 12px;
  width: 10px;
  background-position: center 4px;
  background-image: var(
    --scrollbar-thumb-svg-up,
    url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(34,42,68)'><polygon points='50,00 0,50 100,50'/></svg>")
  );
}

/*Down Arrow*/
::-webkit-scrollbar-button:single-button:vertical:increment {
  height: 10px;
  width: 10px;
  background-position: center 4px;
  background-image: var(
    --scrollbar-thumb-svg-down,
    url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(34,42,68)'><polygon points='0,0 100,0 50,50'/></svg>")
  );
}

/*Left Arrow*/
::-webkit-scrollbar-button:single-button:horizontal:decrement {
  height: 10px;
  width: 10px;
  background-position: 3px center;
  background-image: var(
    --scrollbar-thumb-svg-left,
    url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(34,42,68)'><polygon points='0,50 50,100 50,0'/></svg>")
  );
}

/*Right Arrow*/
::-webkit-scrollbar-button:single-button:horizontal:increment {
  height: 10px;
  width: 10px;
  background-position: 2px center;
  background-image: var(
    --scrollbar-thumb-svg-right,
    url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(34,42,68)'><polygon points='0,0 0,100 50,50'/></svg>")
  );
}

// .ag-center-cols-viewport {
//   overflow-x: hidden !important;
// }
.pad-0-20 {
  padding: 0 20px;
}

.ft-30 {
  font-size: 30px;
}

.flex {
  display: flex;
}

.flex-end {
  justify-content: flex-end;
}

@mixin textEllipsis() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin cardLayout() {
  background-color: #fff;
  box-shadow: 0px 1px 4px #00000029;
  border-radius: 10px;
  .cardHeader {
    @include text(16px, 700, 0, #323338);
    @include flex(center, flex-start);
    background-color: #f8f8fa;
    padding: 0 20px;
    height: 45px;
    margin: -20px -20px 20px;
    border-radius: 10px 10px 0 0;
  }
}

@mixin backgroundColor($color, $hoverColor: $color, $cursor: auto) {
  background-color: $color;
  &:hover {
    background-color: $hoverColor;
    cursor: $cursor;
  }
}

@mixin text(
  $font-size,
  $font-weight,
  $letter-spacing,
  $color,
  $text-transform: none,
  $opacity: 1,
  $important: false
) {
  @if $important==true {
    font-size: $font-size !important;
    font-weight: $font-weight !important;
    opacity: $opacity !important;
    text-transform: $text-transform !important;
    letter-spacing: $letter-spacing !important;
    color: $color !important;
  }
  @if $important==false {
    font-size: $font-size;
    font-weight: $font-weight;
    opacity: $opacity;
    text-transform: $text-transform;
    letter-spacing: $letter-spacing;
    color: $color;
  }
}

@mixin flex(
  $align-items: flex-start,
  $justify-content: flex-start,
  $direction: row
) {
  display: flex;
  flex-direction: $direction;
  align-items: $align-items;
  justify-content: $justify-content;
}

@mixin square($width, $height, $radius, $background: #fff, $opacity: 1) {
  width: $width;
  height: $height;
  border-radius: $radius;
  opacity: $opacity;
  background-color: $background;
}

@mixin circle($size, $color, $opacity: 1) {
  @include square($size, $size, 50%, $color, $opacity);
}

@mixin transition($time) {
  transition: all $time ease-in-out;
}

@mixin absolute(
  $top: unset,
  $right: unset,
  $bottom: unset,
  $left: unset,
  $position: absolute
) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

@mixin mondayButton($backgroundColor, $hoverColor, $textColor: #fff) {
  background-color: $backgroundColor !important;
  color: $textColor !important;
  .mondayButtonDivider {
    background-color: $hoverColor !important;
  }
  &:hover,
  &:active,
  &:visited,
  &:focus {
    background-color: $hoverColor !important;
    color: $textColor;
    .mondayButtonDivider {
      background-color: $backgroundColor !important;
    }
  }

  // &:disabled {
  //   color: rgba(0, 0, 0, 0.25);
  //   background-color: rgba(0, 0, 0, 0.04);

  //   .mondayButtonDivider {
  //     background-color: transparent !important;
  //   }
  // }

  .mondayButtonIcon {
    fill: $textColor !important;
  }
}

.mondayButtonGrey {
  @include mondayButton(#f5f5f7, #e6e9ef, #323338);
}

.mondayButtonRed,
.decline-logout {
  @include mondayButton(#fe4c4a, #eb4345);
}
.mondayButtonDisable {
  @include mondayButton(#e0e0e1, #e0e0e1);
}

.mondayButtonBlue {
  @include mondayButton(#1264a3, #0f5c97);
}

.mondayButtonPrismarine {
  @include mondayButton(#116d6e, #0e6364);
}

// .mondayButtonLightBlue {
//   @include mondayButton(#1264a3, #1264a3);
// }

.mondayButtonYellow {
  @include mondayButton(#f5a623, #e6951e);
}

.mondayButtonGreen {
  @include mondayButton(#71cf48, #68c142);
}

.mondayButtonPdf {
  @include mondayButton(#c71111, #b41111, #fff);
}

.mondayButtonExcel {
  @include mondayButton(#217346, #1c643d, #fff);
}

.mondayButtonLightBlue {
  @include mondayButton(#3887be, #52d3d8, #fff);
}

.ant-tabs-ink-bar {
  z-index: 0;
}

.ag-column-select-list {
  .ag-column-select-column {
    max-height: 25px !important;
  }
}

.ant-select-item-option {
  &:hover {
    background-color: #d4e6f3;
  }
}

::-moz-selection {
  /* Code for Firefox */
  color: #fff;
  background: #06d4da;
}

::selection {
  color: #fff;
  background: #1264a3;
  border-radius: 5px;
}

.buttonNotClickable {
  cursor: not-allowed !important;
  background-color: #0b3e64 !important;
  color: #548bb3 !important;

  > div {
    color: #548bb3 !important;
  }
  > svg {
    > g {
      fill: #548bb3;
    }
    > path {
      fill: #548bb3;
    }
  }
}

.ErrorBoundary {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.ant-message {
  top: 15px;
  .ant-message-notice-content {
    border-radius: 5px;
    .ant-message-custom-content {
      span {
        font-family: "Open Sans";
        font-weight: 500;
        font-size: 15px;
        line-height: 19px;
      }
    }
  }
}
.ant-modal {
  ::-webkit-scrollbar {
    width: 7px !important;
    height: 7px !important;
  }

  .ant-modal-content {
    padding: 0px !important;
    .ant-modal-header {
      margin-bottom: 0px;
      border: none;
      padding: 16px 24px;
    }
    .ant-modal-footer {
      margin-top: 0px;
      border: none;
      padding: 10px 20px;
    }
    .ant-modal-close {
      position: absolute;
      top: 0;
      right: 5px;
      z-index: 10;
      padding: 0;
      color: rgba(0, 0, 0, 0.45);
      font-weight: 700;
      line-height: 1;
      text-decoration: none;
      background: transparent;
      border: 0;
      outline: 0;
      cursor: pointer;
      transition: color 0.3s;
      width: 45px;
      height: 45px;
    }
    .ant-modal-close:hover {
      background: none;
    }
    .ant-modal-body {
      padding: 20px 20px 20px 20px;
    }
  }
}

.ant-menu-item {
  width: 100% !important;
  margin-inline: 0px !important;
}

.ant-select-dropdown {
  padding-right: 0px;
  padding-left: 0px;
  width: 150px;
}

.ant-modal-mask {
  background: rgba(33, 37, 75, 0.4) !important;
  backdrop-filter: blur(5px) !important;
}

.ant-tooltip-inner {
  border-radius: 5px;
}

.ant-message {
  z-index: 12000;
}
.ant-popover-inner {
  padding: 0px !important;
  .ant-popover-inner-content {
    padding: 12px 16px;
    p {
      margin-top: 0px;
    }
  }
}

.custom-tour-dark {
  background: #020c19 !important;
  border: 1px solid #212b39;
  border-radius: 5px;
  width: fit-content;
  max-width: 540px;
  min-width: 340px;
  .ant-tour-arrow {
    &::after {
      // border: 1px solid #212b39;
      box-shadow: 0px 0px 6px rgba(255, 255, 255, 0.5);
      background: #020c19 !important;
    }
  }

  .ant-tour-content {
    border: 1px solid #212b39;
    background: #020c19 !important;
    border-radius: 5px;
    .ant-tour-inner {
      background: #020c19 !important;
      color: #fff;
      border-radius: 5px;
      border: 1px solid #212b39;
      box-shadow: 0px 0px 6px rgba(255, 255, 255, 0.5);
      .ant-tour-footer {
        padding: 20px 10px 10px;
        font-weight: 600;

        .ant-tour-indicators {
          .ant-tour-indicator {
            background: #fff;
          }
          .ant-tour-indicator-active {
            background: #71cf48;
          }
        }
        .ant-btn {
          box-shadow: none;
          background: transparent;
          border: none;
          font-weight: 600;
        }
        .ant-tour-prev-btn {
          color: #75799d;
        }
      }
      .ant-tour-close {
        top: 10px;
        inset-inline-end: 10px;
        svg {
          color: #fff;
          stroke-width: 1px;
        }
      }
      .ant-tour-description {
        padding: 0px 10px;
      }
      .ant-tour-header {
        padding: 10px 10px 10px;
      }
    }
  }
}
.custom-tour-light {
  background: #fff !important;
  border-radius: 5px;
  width: fit-content;
  max-width: 540px;
  min-width: 340px;

  .ant-tour-arrow {
    &::after {
      // border: 1px solid #212b39;
      box-shadow: 0px 0px 6px #1264a3;
      background: #fff !important;
    }
  }

  .ant-tour-content {
    background: #fff !important;
    border-radius: 5px;
    .ant-tour-inner {
      background: #fff !important;
      color: #323338;
      border-radius: 5px;
      box-shadow: 0px 0px 6px #1264a3;
      .ant-tour-footer {
        padding: 20px 10px 10px;
        font-weight: 600;
        .ant-tour-indicators {
          .ant-tour-indicator {
            background: #acacc7;
          }
          .ant-tour-indicator-active {
            background: #71cf48;
          }
        }
        .ant-btn {
          box-shadow: none;
          background: transparent;
          border: none;
          font-weight: 600;
        }
        .ant-tour-prev-btn {
          color: #323338;
        }
        .ant-tour-next-btn {
          color: #1264a3;
        }
      }
      .ant-tour-close {
        top: 10px;
        inset-inline-end: 10px;
        svg {
          color: #323338;
        }
      }
      .ant-tour-description {
        padding: 0px 10px;
      }
      .ant-tour-header {
        padding: 10px 10px 10px;
        font-weight: 600;
      }
    }
  }
}

// .gcv-page-zoomer.gcv-page-zoomer--margin {
//   display: flex;
//   flex-direction: column;
//   align-items: flex-start;
//   justify-content: center;
// }
