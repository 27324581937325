$breakpoint-md: 768px;
$mobile: "(max-width: #{$breakpoint-md})";

.app-map-geofence {
  width: 100%;
  // height: 400px;
  position: relative;

  .confirmation-window-gf {
    display: grid;
    .confirmation-window-head {
      background-color: #f3f3f3;
      border-radius: 5px;
      padding: 4px;
      text-align: center;
      font-weight: 400;
      font-size: 14px;
      margin-bottom: 5px;
    }
    .confirmation-window-body {
      display: grid;
      height: 8vh;
      justify-items: center;
      font-weight: 400;
      font-size: 14px;
      .shape-info-icon {
        color: #0f5c97;
        font-size: 20px;
      }
    }
    .confirmation-window-footer {
      display: flex;
      justify-content: space-between;
    }
  }
  .window-tooltip-info {
    display: grid;
    gap: 5px;
    width: 200px;
    .window-tooltip-head {
      background-color: #f3f3f3;
      text-align: center;
      font-weight: 400;
      font-size: 13px;
      padding: 4px;
      border-radius: 5px;
    }
    .window-tooltip-body {
      display: flex;
      justify-content: space-between;
      background-color: #f3f3f3;
      padding: 4px;
      font-weight: 400;
      font-size: 13px;
      border-radius: 3px;
    }
    .window-tooltip-body-title {
      display: flex;
      flex-direction: column;
      background-color: #f3f3f3;
      padding: 4px;
      font-weight: 600;
      font-size: 13px;
      border-radius: 3px;
      .window-tooltip-body-title-text {
        font-weight: 400;
        font-size: 15px;
      }
    }
    .window-tooltip-footer {
      display: grid;
      gap: 5px;
      justify-items: center;
    }
  }
  .custom-drawing-manager {
    position: absolute;
    top: 10px;
    left: 210px;
    display: flex;
    // gap: 5px;
    align-items: start;

    @media #{$mobile} {
      left: 10px;
      top: 60px;
      flex-direction: column;
    }
  }

  .custom-shape-info {
    display: grid;
    gap: 10px;
    border-radius: 5px;
    position: relative;
    width: 280px;

    .custom-shape-info-header {
      display: flex;
      gap: 5px;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background-color: #f3f3f3;
      border-radius: 5px;
      padding: 3px;
    }
    .shape-info-icon {
      color: #0f5c97;
      font-size: 20px;
      display: flex;
      flex-wrap: wrap;
    }
    .shape-info-text {
      font-weight: 400;
      font-size: 14px;
    }
    @media (max-width: 1280px) {
      .shape-info-text {
        font-weight: 400;
        font-size: 12px;
      }
    }
    .custom-shape-info-head {
      font-weight: 500;
      font-size: 16px;
    }

    .custom-shape-info-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      position: sticky;
      bottom: 0;
      background-color: white;
    }
  }

  @media (max-width: 1280px) {
    .custom-shape-info {
      padding: 0 20px 20px 0;
    }
  }

  .custom-drawing-manager button {
    background: rgb(255, 255, 255);
    display: table-cell;
    cursor: pointer;
    height: 40px;
    vertical-align: middle;
    color: rgb(0, 0, 0);
    font-family: Roboto, Arial, sans-serif;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
    min-width: 36px;
    font-weight: 500;
    border: none;
    align-items: center;
    justify-content: center;
    padding: 0 10px;

    .custom-drawing-manager-text {
      font-weight: 600;
      color: #0f5c97;
      margin: 0 5px 0;
    }
    @media #{$mobile} {
    }
  }
  .custom-drawing-manager button:hover {
    background-color: rgb(226, 226, 226);
  }
}

.ant-popover,
fenceActionsPopover {
  .dividerOfFenceActionsPopover {
    border-bottom: 1px solid #dee2eb;
    padding: 5px 10px 5px 10px;
    align-items: center;
    justify-content: center;
    display: flex;
  }
  .fenceActionsButton {
    cursor: pointer;
  }
  .fenceActionsButton:hover {
    color: #0f5c97;
  }
  .ant-popover-content {
    .ant-popover-inner {
      .ant-popover-inner-content {
        // margin: 0;
        // padding: 0;
      }
    }
  }
}
