.payment-check-dates {
  display: flex;
  gap: 20px;

  .payment-check-dates-details {
    display: flex;
    flex-direction: column;
    label {
      text-align: center;
      font-weight: 600;
    }

    .receieved-by-input {
      .ant-select-selector {
        border-radius: 5px;
        background-color: #f4f5f6 !important;
        // padding: 2px;
        border: 2px solid transparent;
        text-align: left;
        margin-left: 5px;
        // height: 32px;
        width: 200px;
      }
      // width: 100%;
      // height: 32px;
      // width: 200px;
      // border-radius: 5px;
      // background-color: #f4f5f6 !important;
      // padding: 2px;
      // border: 2px solid transparent;
      // text-align: left;
      // padding-left: 5px;
    }
    .input-form {
      width: 100%;
      height: 32px;
      width: 200px;
      border-radius: 5px;
      background-color: #f4f5f6 !important;
      padding: 2px;
      padding-left: 5px;
      border: 2px solid transparent;
      text-align: right;
    }

    .ant-select-disabled.ant-select:not(.ant-select-customize-input)
      .ant-select-selector {
      // color: black;
      background: #f5f5f5;
      cursor: not-allowed;
    }
    .input-form:focus {
      border-radius: 5px;
      border: 2px solid #87c0ef6d !important;
      outline: 0;
      -webkit-box-shadow: 0 0 0 0px;
      box-shadow: 0 0 0 0px rgba(87, 168, 233, 0.2);
    }

    .input-form:hover {
      outline: 0;
      -webkit-box-shadow: 0 0 0 0px;
      box-shadow: 0 0 0 0px rgba(87, 168, 233, 0.2);
    }
  }
}
