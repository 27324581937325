@import "/src/index.scss";

.main-payroll-view {
  height: calc(100vh - 70px);
  height: calc(100dvh - 70px);
  width: 100%;

  .ant-tabs {
    height: 100%;
    .ant-tabs-nav {
      height: 50px;
      background-color: #fff;
      margin-bottom: 0px;
      padding: 0px 20px;
      .ant-tabs-nav-wrap {
        .ant-tabs-nav-list {
          @for $i from 1 through 7 {
            :nth-child(#{$i}) {
              animation: tabs calc(1s + $i * 0.3s - 0.3s);
            }
          }
          .ant-tabs-tab {
            color: #323338;
            font-size: 18px;
            font-weight: 600;
          }
          .ant-tabs-tab-active .ant-tabs-tab-btn {
            color: $blue;
          }
          .ant-tabs-ink-bar {
            height: 3px;
            background-color: $blue;
            border-radius: 1px;
          }
        }
      }
    }
    .ant-tabs-content {
      height: 100%;

      .ant-tabs-tabpane {
        height: 100%;
      }
    }
  }

  .liveButton {
    width: 120px;
    @include mondayButton(#ff4d4f, #eb4345);
    .mondayButtonText {
      text-align: left;
      font: italic normal 800 20px/24px Open Sans;
      letter-spacing: 0px;
      color: #ffffff;
      opacity: 1;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .payroll-view-layout {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;

    .layout-filters-header {
      height: 52px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 10px;
      background-color: #fff;
      border-radius: 10px;
      box-shadow: 0px 1px 4px #00000029;
      transition: 0.2s ease-in;

      .filter-buttons-section,
      .actions-section {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
        gap: 0.7rem;
        .ant-form-item {
          margin-bottom: 0px;
        }
        .ant-input-wrapper {
          .ant-input-affix-wrapper {
            background-color: #f5f5f7;
            border: none;
            .ant-input {
              background-color: #f5f5f7;
              color: #323338;
            }
          }
          .ant-input-group-addon {
            background-color: #f5f5f7;
            border: none;
            border-left: 1px solid #d9d9d9;
            .ant-select {
              .ant-select-selector {
                min-width: 170px;
              }
            }
          }
        }
      }

      .filterIcons {
        cursor: pointer;
        width: 32px;
        height: 32px;
        background: #1264a3;
        fill: white;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
      }
    }

    .layout-children-container {
      height: 100%;
      border-radius: 10px;
      box-shadow: 0px 1px 4px #00000029;
      transition: 0.2s ease-in;

      .payrollGridWrapper {
        height: 100%;

        .payroll-grid {
          height: 100%;
          .ag-root-wrapper {
            overflow-y: auto;
            transition: 0.2s ease-in;
          }

          .ag-paging-panel {
            min-height: 48px !important;
          }
        }
      }
    }
  }
}

.main-payroll-view-dark {
  background-color: $mainDarkBackgroundColor;
  .ant-tabs {
    .ant-tabs-nav {
      background-color: $mainDarkBackgroundColor;
      padding-bottom: 2px;
      &::before {
        border: none;
      }
      .ant-tabs-nav-wrap {
        box-shadow: 0 3px 3px -3px gray;
        .ant-tabs-nav-list {
          .ant-tabs-tab {
            color: #fff;
          }
          .ant-tabs-ink-bar {
            background-color: #fff;
          }
        }
        .ant-tabs-tab-active .ant-tabs-tab-btn {
          color: $blue;
        }
      }
    }
  }
  .payroll-view-layout {
    background-color: $mainDarkBackgroundColor;
    .layout-filters-header {
      height: 52px;
      background-color: $cardDarkBackgroundColor !important;
      box-shadow: 0px 1px 4px #ffffff29 !important;

      // .ant-input-affix-wrapper {
      //   background: $inputDarkColor !important;

      //   .ant-input {
      //     background: $inputDarkColor !important;
      //     color: #fff !important;

      //     &::placeholder {
      //       color: #fff;
      //     }
      //   }

      //   .ant-input-prefix {
      //     svg path {
      //       fill: #f2f2f2 !important;
      //     }
      //   }
      // }

      .filter-buttons-section {
        .ant-input-wrapper {
          .ant-input-affix-wrapper {
            height: 32px;
            background-color: $inputDarkColor !important;
            .ant-input-prefix svg {
              fill: #fff;
            }
            .ant-input {
              background-color: $inputDarkColor !important;
              color: #fff;
              &::placeholder {
                color: #acacca;
              }
            }
          }
          .ant-input-group-addon {
            border-left: 1px solid #acacca !important;
            background-color: $inputDarkColor !important;
            .ant-select {
              .ant-select-selector {
                background-color: $inputDarkColor !important;
                .ant-select-selection-search-input {
                  color: #fff;
                }
                .ant-select-selection-item {
                  color: #fff;
                }
                .ant-select-selection-placeholder {
                  color: #acacca;
                }
              }
              .ant-select-clear {
                background-color: $inputDarkColor;
                svg {
                  fill: #acacca;
                }
              }
              .ant-select-arrow svg {
                fill: #fff;
              }
            }
            .ant-select-disabled {
              .ant-select-selector {
                .ant-select-selection-search-input {
                  color: #acacca !important;
                }
                .ant-select-selection-item {
                  color: #acacca !important;
                }
              }
            }
          }
        }
      }

      .actions-section {
        .ant-picker {
          background-color: $inputDarkColor !important;
          .ant-picker-input {
            background-color: $inputDarkColor;
            input {
              color: #f2f2f2;
              &::placeholder {
                color: #acacca;
              }
            }
            .ant-picker-suffix svg {
              color: #acacca;
            }
          }
          .ant-picker-clear {
            background-color: $inputDarkColor;
            .anticon-close-circle svg {
              fill: #acacca !important;
              path {
                fill: #acacca !important;
              }
            }
          }
        }
        .ant-select {
          .ant-select-selector {
            background-color: $inputDarkColor !important;
            .ant-select-selection-search-input {
              color: #fff;
            }
            .ant-select-selection-item {
              color: #fff;
            }
            .ant-select-selection-placeholder {
              color: #acacca;
            }
          }
          .ant-select-clear {
            background-color: $inputDarkColor;
            svg {
              fill: #acacca;
            }
          }
          .ant-select-arrow svg {
            fill: #fff;
          }
        }
      }
    }
    .layout-children-container {
      .payrollGridWrapper {
        .payroll-grid {
          .ag-root-wrapper {
            overflow-y: auto;
            border-radius: 10px;
            background-color: $cardDarkBackgroundColor;
            box-shadow: 0px 1px 4px #ffffff29 !important;
            .ag-column-drop {
              background-color: $cardDarkBackgroundColor;
            }
            .ag-header {
              .ag-header-cell {
                background-color: $cardDarkBackgroundColor !important;
                .ag-header-cell-resize::after {
                  width: 1px !important;
                  background-color: gray !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 700px) {
  .main-payroll-view {
    .layout-filters-header {
      display: flex;
      height: 52px;
      p .mondayButtonText {
        display: none;
      }
      .mondayButtonDivider {
        display: none;
      }
    }
  }
}

@keyframes tabs {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
