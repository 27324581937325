.safetyVisualization {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  padding: 10px;
  .uploadedImage {
    position: relative;
    .imageInfoDelete {
      position: absolute;
      z-index: 100;
      right: 10px;
      top: 10px;
      cursor: pointer;
      svg {
        fill: #fe4c4a;
      }
    }
    .ant-image {
      cursor: pointer;
      img {
        object-fit: contain;
        width: 100%;
        height: auto;
      }
      &:after {
        content: "Edit";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
        font-size: 20px;
        font-weight: 600;
        opacity: 0;
        transition: all 0.3s;
        z-index: 1;
        background-color: rgba(0, 0, 0, 0.3);
        height: 100%;
        width: 100%;
        display: grid;
        place-items: center;
      }
      &:hover {
        &:after {
          opacity: 1;
        }
      }
    }
  }
  .uploadedImage.resize {
    .ant-image {
      resize: both;
      overflow: auto;
      display: inline-block;
      &:after {
        display: none;
      }
    }
  }
}
