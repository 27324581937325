.internalHeaderInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 52px;
  padding-left: 10px;
  padding-right: 12px;
  border-bottom: 0.5px solid #e6e9ef;
  width: 100% !important;
  background-color: #f4f5f6;
  .dateInfo {
    display: flex;
    gap: 5px;
    span {
      font-weight: 600;
    }
  }
  .internalHeaderActions {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}
