@import "../../../../index.scss";

.widget-category-container {
  display: flex;
  justify-content: center;
  gap: 30px;

  &.widget-keys-container {
    .widget-content {
      display: flex;
      align-items: center;
      // justify-content: center;
    }
  }

  .widget-category {
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    animation: slideInRightCheckboxAnimation 1000ms both;
    max-height: 500px;

    .widget-text {
      font-size: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 10px 0;
      white-space: nowrap;
    }

    .widget-content {
      width: 50%;
      display: flex;
      flex-direction: column;
      gap: 5px;
      height: 100%;
      overflow: auto;
    }
  }

  &.dark-mode {
    .widget-category {
      .widget-text {
        color: #fff;
      }
    }
  }
}

@keyframes slideInRightCheckboxAnimation {
  from {
    width: 1px;
    height: fit-content;
    visibility: visible;
  }

  to {
    width: 100%;
    height: fit-content;
  }
}

@media (max-width: 480px) {
  .widget-category-container {
    gap: 10px;

    .widget-category {
      .widget-content {
        .ant-radio-wrapper {
          width: auto;
        }
      }
    }
  }
}

.widget-search-input-container {
  display: flex;
  justify-content: center;

  .search-comp {
    max-width: 355px;
  }

}