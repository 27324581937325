.dateTime-container {
  display: flex;
  flex-direction: column;
  position: relative;
}

.dateTime-body {
  display: flex;
  flex-direction: row;
}

.dateTime-container .buttons {
  position: absolute;
  bottom: 20px;
  width: calc(301px - 40px);
  margin-left: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.dateTime-container .buttons button {
  width: 60px;
  height: 32px;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  background: #1264a3;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  transform-origin: 50% 0%;
  cursor: pointer;
}

.dateTime-container .buttons button:hover {
  filter: brightness(120%);
  /* scale: 1.02; */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.dateTime-container .buttons .dateTime-btn {
  position: absolute;
  top: -65px;
  left: 25px;
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.dateTime-container .buttons .dateTime-btn .dateTime-picker {
  z-index: 1;
}

.dateTime-container .buttons .okCancel-btn {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.dateTime-container .buttons .okCancel-btn .cancel-btn {
  background: #fe4c4a;
}

.dateTime-container .buttons .ok-btn {
  padding: 7px 20px;
  background: #1264a3;
}

.dateTime-container .buttons .okBtn-disable {
  cursor: default;
}

.dateTime-container .buttons .okBtn-disable:hover::after {
  content: "Select Date";
  position: absolute;
  top: -20px;
  right: -5px;
  width: 70px;
  height: 25px;
  color: red;
}

.dateTime-container .buttons .swapButton {
  position: absolute;
  top: 40px;
  left: 55px;
  width: 30px;
  height: 20px;
  background-color: #3b76a3;
  z-index: 0;
}

.settingsButton {
  position: absolute;
  bottom: 70px;
  left: -10px;
  padding: 6px;
  color: #fff;
  background-color: #1264a3;
  border: 2px solid #aab7c0;
  border-radius: 50%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  cursor: pointer;
  transition: all ease 0.2s;
}

.settingsButton:hover {
  filter: brightness(110%);
}

.showResult {
  position: absolute;
  bottom: -120px;
}

.showResult h4 {
  color: gray;
}

.showResult h4 span {
  color: black;
  font-weight: 700;
}

.dateWithRange {
  position: absolute;
  bottom: -100px;
  width: 300px;
}

#checkbox {
  display: none;
}

.square {
  transition: 0.4s all ease;
  transform: rotate(0);
}

#checkbox:checked + .square {
  transform: rotate(90deg);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

@media (max-width: 620px) {
  .dateTime-body {
    flex-direction: column;
  }
}
