.notes-icon-content {
  .icon-content {
    cursor: pointer;
    .custom-icon {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      background-color: #1264a3;
      padding: 5px;
    }
  }
  .grey-color {
    .custom-icon {
      background-color: hsla(210, 10%, 96%, 1);

      svg {
        path {
          fill: #000;
        }
      }
    }
  }
}
