.hoistForm-comparison {
  .hoistFormSectionParent {
    margin-bottom: 5px !important;
    padding-bottom: 10px !important;
    display: flex;
    justify-content: space-between;
    .hoistFormSection {
      display: inline-block;
      margin-right: 35px;
      vertical-align: bottom;

      .radioButton.ant-radio-button-wrapper-checked {
        background-color: lightgreen;
        color: white;
      }

      .customSelect .ant-select-selector.ant-select-selector {
        background-color: lightgreen;
      }
      .hoistFormSectionChild {
        display: inline-block;
        margin-left: 5px;
        margin-top: 5px;
        .applyForceHeightBtn {
          width: 120px;
          height: 32px;
          border-radius: 4px;
          color: #fff;
          border: none;
          margin-left: 20px;
        }
        &.tagSelect {
          height: 32px;

          .addonBefore {
            font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            padding: 7px 11px;
            height: 32px;
            display: inline-block;
          }

          .speedComp {
            width: auto;
            min-width: 200px;
            display: inline-block;

            .ant-select-selector {
              border: none;
              height: 32px;
            }
          }
          &.hoistInfo {
            display: flex;
            gap: 5px;
          }

          .stopsInfo {
            display: flex;
            gap: 5px;
            flex-direction: row;
          }

          &.leftPartial {
            display: flex;
            align-items: center;
            flex-direction: row;

            .addonBefore {
              border-radius: 5px 0 0 5px;
            }

            .selectComponent .ant-select-selection {
              border-radius: 0;
            }
          }

          &.rightPartial {
            .selectComponent .ant-select-selection {
              border-bottom-left-radius: 0;
              border-top-left-radius: 0;
            }
          }
        }
      }

      .ant-select {
        width: 150px;
      }
    }

    .hoistFormLabel {
      font-size: 15px;
      font-weight: bold;
      margin-bottom: 10px;
      .ant-checkbox-inner {
        background-color: grey;
        border: none;
        border-radius: 50%;
      }

      .ant-checkbox-checked {
        border-radius: 10px;

        .ant-checkbox-inner {
          background-color: #71cf48;
          border: none;
          border-radius: 10px;
        }
      }
    }
  }
}

.highlight {
  background-color: lightgreen;
}
