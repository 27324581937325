$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.createUserModalContainer {
  width: fit-content !important;
  .ant-modal-content {
    padding-right: 1.7rem;
    width: calc(100vw - 20px);
    max-width: 800px;

    border-radius: 10px;
    .ant-modal-header {
      height: 45px;
      display: flex;
      align-items: center;
      border-radius: 10px 10px 0 0;
      background-color: #f8f8fa;
      padding: 0px 20px;

      .ant-modal-title {
        font-weight: 600;
        cursor: default;
      }
    }

    .ant-modal-body {
      padding-top: 2.5rem;
      padding-bottom: 2.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;

      .ant-form {
        width: 100%;
        display: grid;
        grid-template-columns: 48% 48%;
        column-gap: 20px;
        // row-gap: 1rem;

        .labeledInputContainer {
          width: 100%;
          .react-tel-input .form-control {
            width: 100%;
          }
        }
      }
    }
  }

  .PasswordConfirmation {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 451px;
    width: 99%;
    margin: 10px 5px;
    transform: translateX(5%);
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
    padding: 50px 12%;
    .formInputs {
      display: grid;
      grid-template-columns: 50% 50%;
      width: 104%;
    }
    .labeledInputContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      width: 91.6%;
      .labeledInputLabel {
        padding-left: 25px;
      }
      .ant-form-item {
        width: 100%;
        margin-left: 15px;
      }
    }
    .formElement {
      width: 500px;
      .ant-select-selection-overflow {
        position: relative;
        display: flex;
        flex: auto;
        flex-wrap: nowrap;
        max-width: 100%;
      }
      .ant-select-selector {
        display: flex;
        align-items: center;
        width: 100%;
        height: 32px !important;
        background: #f5f5f7 0% 0% no-repeat padding-box;
        border-radius: 5px;
        border: 0px;
      }
      .ant-input {
        margin: 0;
        width: 100%;
        border: none;
        height: 32px;
        padding: 4px 11px;
        font-size: 14px;
        line-height: 1.5715;
        background: #f5f5f7 0% 0% no-repeat padding-box;
        border-radius: 5px;
        transition: all 0.3s;
      }
      .labeledInputLabel {
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding-left: 10px;
        padding-top: 7px;
        font: normal normal 600 16px Open Sans;
        span {
          display: none;
        }
      }
    }
  }

  .passCodeInputs {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    .otpInput {
      width: 100%;
      .inputStyle {
        border: none;
        border-radius: 10px !important;
        background-color: #f5f5f7;
        width: 70% !important;
        height: 86px !important;
        font-size: 35px !important;
        margin: 5px;
        padding: 0px;
      }
    }
  }

  .ant-modal-footer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
  }
}

.createUserModalContainerDark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      .labeledInputLabel {
        color: #fff;
      }
      .ant-input {
        background-color: $inputModalDark !important;
        color: #fff;
        &::placeholder {
          color: #acacca;
        }
      }
      .ant-input-disabled {
        color: #acacca;
      }
      .ant-select {
        .ant-select-selector {
          background-color: $inputModalDark !important;
          .ant-select-selection-search-input {
            color: #fff;
          }
          .ant-select-selection-item {
            color: #fff;
          }
          .ant-select-selection-placeholder {
            color: #acacca;
          }
        }
        .ant-select-clear {
          background-color: $inputModalDark;
          svg {
            fill: #acacca;
          }
        }
        .ant-select-arrow svg {
          fill: #fff;
        }
      }
      .ant-select-disabled {
        .ant-select-selector {
          .ant-select-selection-search-input {
            color: #acacca !important;
          }
          .ant-select-selection-item {
            color: #acacca !important;
          }
        }
      }
      .react-tel-input {
        .form-control {
          background-color: $inputModalDark !important;
          color: #fff;
          &::placeholder {
            color: #acacca;
          }
        }
        .flag-dropdown {
          background-color: $inputModalDark;
          border-radius: 5px 0px 0px 5px;
          .selected-flag {
            &:hover {
              background-color: $inputModalDark;
            }
            &:focus {
              background-color: $inputModalDark;
            }
          }
          .arrow {
            border-top: 4px solid #fff;
          }
        }
        .open {
          background-color: $inputModalDark;
          border-radius: 5px 0px 0px 5px;
          .selected-flag {
            background-color: $inputModalDark;
            .arrow {
              border-bottom: 4px solid #fff;
              border-top: 4px solid transparent;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .PasswordConfirmation .passCodeInputs .otpInput .inputStyle {
    height: 65px !important;
    font-size: 20px !important;
  }
}

@media screen and (max-width: 1200px) {
  .PasswordConfirmation {
    padding: 20px 40px;
    p {
      margin-bottom: 5px;
    }
    h2 {
      margin-top: 5px;
      margin-bottom: 5px;
      font-size: 20px;
    }
  }
  .PasswordConfirmation .formInputs {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
}

@media only screen and (max-width: 821px) {
  .PasswordConfirmation {
    width: 98%;
    .passCodeInputs .otpInput .inputStyle {
      height: 86px !important;
      font-size: 35px !important;
    }
  }
}

@media only screen and (max-width: 450px) {
  .labeledInputContainer.formElement {
    margin-bottom: 10px;
  }
}
