.absoluteStepperContainer {
  width: initial;
  margin-right: 45px !important;
  margin-left: 30px !important;
  height: 80px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
  pointer-events: unset !important;
  overflow: hidden;
  box-shadow: 0px 1px 4px #00000029;
  .StepperCard {
    margin-bottom: 12px;
    width: 1440px;
  }
  .absuluteDividerStyle {
    min-height: 7px;
    width: 1480px;
    background: transparent
      linear-gradient(90deg, #ffffff 0%, #f1f3f4 51%, #ffffff 100%) 0% 0%
      no-repeat padding-box;
    opacity: 1;
  }
  .ant-steps-navigation .ant-steps-item-container .ant-steps-item-title {
    width: 150%;
    font-size: 18px;
    margin-left: 40px;
  }
}
.AbsoluteHeaderComponentHeaderActions {
  position: relative;
  top: -100px;
  left: 1490px;
}
.AbsoluteHeaderComponentHeaderActionWindow {
  width: 140px;

  .ant-popover-arrow {
    display: none !important;
  }
  .ant-popover-inner {
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 5px;
    box-shadow: 0px 0px 8px -4px;
  }
  .ant-popover-inner-content {
    padding: 0 !important;
  }
}
