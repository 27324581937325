.LineChartBodyStyle {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 350px;
  overflow-x: auto;
  overflow-y: hidden;

  .LineChart {
    width: 600px !important;
    height: 300px !important;
  }
}
.toolTipAccGraph {
  ::-webkit-scrollbar-thumb {
    background-color: grey;
    opacity: 0.8;
  }
  width: 400px !important;
  display: flex;

  .ant-tooltip-arrow {
    display: none !important;
  }
  .ant-tooltip-inner {
    background: inherit;
    box-shadow: none;
    width: 100%;
    height: 100%;
  }
}
