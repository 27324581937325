@import "../../../../../index.scss";

.filter-modal-dashboard-style {
  .ant-modal {
    width: fit-content;
  }

  .ant-modal-content {
    border-radius: 10px;
    overflow: hidden;
    width: fit-content;

    .ant-modal-header {
      height: 45px;
      display: flex;
      align-items: center;
      background-color: #f7f8fa;
      border-bottom: 0;
      font-size: 16px;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
        Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    }

    .ant-modal-footer {
      height: 45px;
      display: flex;
      align-items: center;
      background-color: #f7f8fa;
      display: flex;
      justify-content: flex-end;
      padding: 0;
      border-top: 0;

      .footerContainer {
        display: flex;

        .clearFilterButton {
          margin-right: 10px;
          @include mondayButton(#fe4c4a, #eb4345, #fff);
        }

        .filterButton {
          margin-right: 10px;
          @include mondayButton(#1264a3, #0f5c97, #fff);
        }
      }
    }

    .ant-modal-body {
      height: fit-content;
      overflow-x: hidden;
      overflow-y: auto;
      width: 600px;
      padding: 0;
      max-height: 750px;
      overflow-y: auto;
    }
  }

  .ant-modal-close-x {
    svg {
      margin-top: -10px;
    }
  }
}
