.longBarChartBody {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 800px;
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  justify-content: flex-start;

  .BarChartFull {
    margin: 50px 50px 70px 50px;
    width: 1400px !important;
    height: 700px !important;
  }
}
