$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.mondayButtonContainer.scheduled-days-time-button-wrapper {
  height: 26px !important;
}

.employees-time-pli {
  .ant-modal-content {
    background: #ffffff;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    .ant-modal-close-x {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 21px;
      font-weight: bold;
      height: 45px;
    }
    .ant-modal-header {
      width: 1300px;
      height: 45px;
      background: #f8f8fa;
      border-radius: 10px 10px 0px 0px;
      display: flex;
      align-items: center;
      .ant-modal-title {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #323338;
      }
    }
    .ant-modal-body {
      .labeledInputContainer {
        .ant-form-item {
          margin-bottom: 0px;
        }
      }
    }
  }
  .main-content {
    width: 100%;
    max-height: 600px;
    height: fit-content;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .footer-employe-pli-content {
    display: flex;
    justify-content: space-between;
  }
}

.employees-time-pli-dark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      table {
        th {
          color: #fff;
        }
        td {
          color: #fff;
          .ant-select {
            .ant-select-selector {
              background-color: $inputModalDark !important;
              .ant-select-selection-search-input {
                color: #fff;
                &::placeholder {
                  color: #acacca;
                }
              }
              .ant-select-selection-item {
                color: #fff;
              }
              .ant-select-selection-placeholder {
                color: #acacca;
              }
            }
            .ant-select-clear {
              background-color: $inputModalDark;
              svg {
                fill: #acacca;
              }
            }
            .ant-select-arrow svg {
              fill: #fff;
            }
          }
          .ant-picker {
            background-color: $inputModalDark !important;
            .ant-picker-input {
              background-color: $inputModalDark;
              input {
                color: #f2f2f2;
                &::placeholder {
                  color: #acacca;
                }
              }
              .ant-picker-suffix svg {
                color: #acacca;
              }
              .ant-picker-clear {
                background-color: $inputModalDark;
                svg {
                  fill: #acacca;
                }
              }
            }
          }
        }
      }
    }
  }
}
