$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.routeDetailsShower {
  height: fit-content;
  width: fit-content !important;
  cursor: default;
  max-width: 100vw;

  .ant-modal-content {
    height: fit-content;
    width: fit-content;
    border-radius: 10px;
    max-width: 100vw;

    .ant-modal-header {
      border-radius: 10px 10px 0 0;
      height: 41px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #f7f8fa;
      .ant-modal-title {
        color: #323338;
        font-size: 1rem;
        font-weight: 550;
      }
    }

    .ant-modal-close {
      height: 41px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .ant-modal-body {
      max-width: 100vw;
      width: 100%;
      height: fit-content;
      max-height: 70vh;
      overflow: auto;
      padding-top: 1rem;

      .routes-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin-bottom: 1rem;
      }
    }

    .singleRouteContainer {
      background-color: #f7f8fa;
      padding: 1rem;
      border-radius: 5px;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .routeLocationContainer {
        width: 100%;
        height: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 0.5rem;
        margin-bottom: 0.7rem;
        margin-top: 0.7rem;
        font-size: 1rem;
        font-weight: 550;

        .routeLocation {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: row;
          flex-wrap: nowrap;
          gap: 0.3rem;
        }
      }

      .routeInfoSection {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 1rem;

        .routeInfo {
          display: flex;
          align-items: flex-start;
          gap: 0.25rem;
        }

        .mapButton {
          grid-column: -2 / -1;
          justify-self: end;
        }
      }

      .routeInfoSectionNoCargo {
        width: 80%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
        place-items: start;
        .routeInfo {
          display: flex;
          align-items: flex-start;
          gap: 0.25rem;
        }

        .mapButton {
          grid-column: -2 / -1;
          justify-self: end;
        }
      }
    }

    .routeNo {
      position: relative;
      left: 25px;
      bottom: -16px;
      background-color: #1f2a44;
      color: #fff;
      padding: 0.2rem 0.5rem;
      width: fit-content;
      z-index: 1;
    }

    .routeNo.auditNo {
      bottom: -45px;
    }

    .fromAuditHeader {
      position: relative;
      right: calc(-100% + 150px);
      bottom: -16px;
      background-color: #fe4c4a;
      color: #fff;
      padding: 0.2rem 0.5rem;
      width: fit-content;
      z-index: 1;
    }
  }
}

.routeDetailsShowerDark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;

    .ant-modal-close .ant-modal-close-x svg {
      fill: #fff;
    }

    .ant-modal-header {
      background-color: $cardModalHeaderDark !important;
      .ant-modal-title {
        color: #fff !important;
      }
    }

    .ant-modal-body {
      .routeNo {
        background-color: #383554;
      }

      .singleRouteContainer {
        background-color: $cardModalHeaderDark;

        .routeLocationContainer {
          .routeLocation {
            color: #fff;
          }
          .arrow svg {
            fill: #fff;
          }
        }

        .routeInfoSection,
        .routeInfoSectionNoCargo {
          .routeInfo {
            span,
            strong {
              color: #fff !important;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 610px) {
  .routeDetailsShower {
    .ant-modal-content {
      .routeInfoSection {
        grid-template-columns: repeat(3, 1fr) !important;
      }
    }
  }
}

@media (max-width: 505px) {
  .routeDetailsShower {
    .singleRouteContainer {
      .routeLocationContainer {
        flex-direction: column !important;

        .routeLocation {
          width: 100%;
          white-space: nowrap;
          font-size: 0.9rem;
        }

        .arrow {
          display: none;
        }
      }

      .routeInfoSection,
      .routeInfoSectionNoCargo {
        grid-template-columns: repeat(2, 1fr) !important;
        font-size: 0.75rem;

        .mapButton {
          grid-column: span 2;
          justify-self: start;
        }
      }
    }
  }
}

@media (max-width: 450px) {
  .routeDetailsShower {
    .singleRouteContainer {
      .routeLocationContainer {
        .routeLocation {
          font-size: 0.7rem;
        }
      }

      .routeInfoSection,
      .routeInfoSectionNoCargo {
        font-size: 0.65rem;
      }
    }
  }
}
