@import "../../../../../../index.scss";

.userVideoLogsModal {
  width: 1000px !important;

  .ant-modal-content {
    .ant-modal-header {
      height: 45px;
      background-color: #f5f5f7;
      border: none;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      display: flex;
      align-items: center;

      .ant-modal-title {
        font-weight: 600;
        font-size: 16px;
        color: #323338;
      }
    }

    .ant-modal-body {
      .body-container {
        label {
          padding-left: 4px;
          font-weight: 700;
        }

        .buttons {
          display: flex;
          flex-direction: row;
          gap: 10px;
        }
      }
    }

    .ant-modal-footer {
      display: flex;
      justify-content: flex-end;
    }
  }
}

.userVideoLogsModal-dark {
  .ant-modal-content {
    .ant-modal-close .ant-modal-close-x svg {
      fill: #f2f2f2;
    }

    .ant-modal-header {
      background-color: $cardDarkBackgroundColor;

      .ant-modal-title {
        color: #fff;
      }
    }

    .ant-modal-body {
      background-color: $cardDarkHeaderColor;

      .body-container {
        label {
          color: #fff;
        }
      }
    }

    .ant-modal-footer {
      background-color: $cardDarkHeaderColor;
    }
  }
}
