.CustomStatusContainer {
  display: flex;
  flex-direction: column;
  .CustomStatusDevider {
    width: auto;
    height: 7px;
    background: transparent
      linear-gradient(90deg, #ffffff 0%, #f1f3f4 51%, #ffffff 100%) 0% 0%
      no-repeat padding-box;
    opacity: 1;
  }
  .CustomStatusButtonContainer {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    // margin-left: -50px;
    justify-content: center;
    .CustomStatusContainerButtonStyle {
      width: 100px;
      height: 44px;
      border-radius: 10px;
      opacity: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin-left: 20px;
      margin-right: 20px;

      .CustomStatusContainerButtonText {
        text-align: left;
        font: normal normal 600 20px/27px Open Sans;
        letter-spacing: 0px;
        color: #ffffff;
        opacity: 1;
      }
    }
  }
  .confirmContainer {
    display: flex;
    margin-top: 20px;
    // margin-left: -30px;
    justify-content: center;
    .cancelButton {
      margin-right: 10px;
      background-color: #fe4c4a;
    }
    .cancelButtonDivider {
      color: #f14543;
    }
    .cancelButton:hover {
      background-color: #f14543;
      .cancelButtonDivider {
        color: #fe4c4a;
      }
    }
    .confirmButton {
      margin-right: 10px;
      background-color: #71cf48;
    }
    .confirmButtonDivider {
      color: #69c242;
    }
    .confirmButton:hover {
      background-color: #69c242;
      .confirmButtonDivider {
        color: #71cf48;
      }
    }
  }
}
