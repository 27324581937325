.headerBtnsCont {
  grid-area: auto;
  width: 100%;
  height: auto;
  height: auto;
  min-height: auto;
  background-color: #ffffff;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.4078431373),
    0 1px 5px rgba(0, 0, 0, 0.3568627451);
  border-radius: 5px;
  padding: 5px 10px;
  display: grid;
  grid-template-columns: 20% 60% 20%;
  grid-template-rows: auto;
  align-items: center;
  justify-content: center;
  gap: 20px 0%;
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .headerBtnsCont {
    height: auto;
  }
}
@media (min-width: 1281px) {
  .headerBtnsCont {
    height: auto;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .headerBtnsCont {
    min-height: 40px;
  }
}
@media (min-width: 1281px) {
  .headerBtnsCont {
    min-height: 40px;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .headerBtnsCont {
    padding: 0;
  }
}
@media (min-width: 1281px) {
  .headerBtnsCont {
    padding: 0;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .headerBtnsCont {
    grid-template-columns: 40% 20% 40%;
  }
}
@media (min-width: 1281px) {
  .headerBtnsCont {
    grid-template-columns: 40% 20% 40%;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .headerBtnsCont {
    grid-template-rows: 100%;
  }
}
@media (min-width: 1281px) {
  .headerBtnsCont {
    grid-template-rows: 100%;
  }
}
.headerBtnsCont i {
  width: fit-content;
  height: fit-content;
  padding: 5px 10px;
  background-color: #1264a3;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem;
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .headerBtnsCont i {
    font-size: 1rem;
  }
}
@media (min-width: 1281px) {
  .headerBtnsCont i {
    font-size: 1rem;
  }
}
.headerBtnsCont i:hover {
  color: auto;
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .headerBtnsCont i:hover {
    color: #00c3ff;
  }
}
@media (min-width: 1281px) {
  .headerBtnsCont i:hover {
    color: #00c3ff;
  }
}
.headerBtnsCont .lsBtnsCont {
  grid-area: 1/1/2/2;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: inline-flex;
  flex-wrap: wrap;
  background-color: #ffffff;
  flex-direction: column-reverse;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .headerBtnsCont .lsBtnsCont {
    flex-direction: row;
  }
}
@media (min-width: 1281px) {
  .headerBtnsCont .lsBtnsCont {
    flex-direction: row;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .headerBtnsCont .lsBtnsCont {
    justify-content: flex-end;
  }
}
@media (min-width: 1281px) {
  .headerBtnsCont .lsBtnsCont {
    justify-content: flex-end;
  }
}
.headerBtnsCont .inputCont {
  grid-area: 1/2/2/3;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  z-index: 2;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto;
  justify-content: center;
  align-items: center;
  gap: 5px 5px;
}
.headerBtnsCont .inputCont > p {
  margin-bottom: 0;
  margin-top: 0;
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .headerBtnsCont .inputCont {
    grid-template-columns: 100px;
  }
}
@media (min-width: 1281px) {
  .headerBtnsCont .inputCont {
    grid-template-columns: 100px;
  }
}
.headerBtnsCont .inputCont input {
  grid-area: 1/1/2/-1;
  width: 100%;
  height: auto;
  font-size: 1rem;
  text-align: center;
  padding: 2px 10px;
  outline: none;
  background-color: rgba(0, 0, 0, 0.0823529412);
  border-radius: 5px;
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.769),
    inset 0 0 4px rgba(0, 0, 0, 0.609);
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.769),
    inset 0 0 4px rgba(0, 0, 0, 0.609);
  color: #000000;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
@media (min-width: 481px) and (max-width: 767px) {
  .headerBtnsCont .inputCont input {
    font-size: 1rem;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .headerBtnsCont .inputCont input {
    font-size: 1.1rem;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .headerBtnsCont .inputCont input {
    font-size: 1.1rem;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .headerBtnsCont .inputCont input {
    font-size: 1.1rem;
  }
}
@media (min-width: 1281px) {
  .headerBtnsCont .inputCont input {
    font-size: 1.1rem;
  }
}
.headerBtnsCont .inputCont p {
  grid-area: auto;
  width: fit-content;
  height: min-content;
  align-self: start;
  line-height: 1.5rem;
  word-wrap: break-word;
  font-size: 1rem;
  font-weight: 400;
  font-style: normal;
  color: #000000;
  text-align: center;
  justify-self: center;
  color: rgba(0, 0, 0, 0.4431372549);
  font-weight: 600;
  padding: 0 10px;
  transform: auto;
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .headerBtnsCont .inputCont p {
    grid-area: 1/1/2/-1;
  }
}
@media (min-width: 1281px) {
  .headerBtnsCont .inputCont p {
    grid-area: 1/1/2/-1;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .headerBtnsCont .inputCont p {
    align-self: center;
  }
}
@media (min-width: 1281px) {
  .headerBtnsCont .inputCont p {
    align-self: center;
  }
}
@media (min-width: 481px) and (max-width: 767px) {
  .headerBtnsCont .inputCont p {
    font-size: 1rem;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .headerBtnsCont .inputCont p {
    font-size: 1rem;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .headerBtnsCont .inputCont p {
    font-size: 1rem;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .headerBtnsCont .inputCont p {
    font-size: 1rem;
  }
}
@media (min-width: 1281px) {
  .headerBtnsCont .inputCont p {
    font-size: 1rem;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .headerBtnsCont .inputCont p {
    justify-self: start;
  }
}
@media (min-width: 1281px) {
  .headerBtnsCont .inputCont p {
    justify-self: start;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .headerBtnsCont .inputCont p {
    transform: translateX(100px);
  }
}
@media (min-width: 1281px) {
  .headerBtnsCont .inputCont p {
    transform: translateX(100px);
  }
}
.headerBtnsCont .rsBtnsCont {
  grid-area: 1/3/2/-1;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  z-index: 2;
  display: flex;
  display: inline-flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .headerBtnsCont .rsBtnsCont {
    flex-direction: row;
  }
}
@media (min-width: 1281px) {
  .headerBtnsCont .rsBtnsCont {
    flex-direction: row;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .headerBtnsCont .rsBtnsCont {
    justify-content: flex-start;
  }
}
@media (min-width: 1281px) {
  .headerBtnsCont .rsBtnsCont {
    justify-content: flex-start;
  }
}
.headerBtnsCont .inputSarchText {
  display: none;
  grid-area: 2/1/3/-1;
  width: 90%;
  height: fit-content;
  justify-self: center;
  font-size: 1rem;
  text-align: center;
  padding: 2px 10px;
  outline: none;
  background-color: rgba(0, 0, 0, 0.0823529412);
  border-radius: 5px;
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.769),
    inset 0 0 4px rgba(0, 0, 0, 0.609);
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.769),
    inset 0 0 4px rgba(0, 0, 0, 0.609);
  color: #000000;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .headerBtnsCont .inputSarchText {
    display: block;
  }
}
@media (min-width: 1281px) {
  .headerBtnsCont .inputSarchText {
    display: block;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .headerBtnsCont .inputSarchText {
    position: absolute;
  }
}
@media (min-width: 1281px) {
  .headerBtnsCont .inputSarchText {
    position: absolute;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .headerBtnsCont .inputSarchText {
    grid-area: 1/1/2/-1;
  }
}
@media (min-width: 1281px) {
  .headerBtnsCont .inputSarchText {
    grid-area: 1/1/2/-1;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .headerBtnsCont .inputSarchText {
    width: 70%;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .headerBtnsCont .inputSarchText {
    width: 70%;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .headerBtnsCont .inputSarchText {
    width: 20%;
  }
}
@media (min-width: 1281px) {
  .headerBtnsCont .inputSarchText {
    width: 20%;
  }
}
@media (min-width: 481px) and (max-width: 767px) {
  .headerBtnsCont .inputSarchText {
    font-size: 1rem;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .headerBtnsCont .inputSarchText {
    font-size: 1.1rem;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .headerBtnsCont .inputSarchText {
    font-size: 1.1rem;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .headerBtnsCont .inputSarchText {
    font-size: 1.1rem;
  }
}
@media (min-width: 1281px) {
  .headerBtnsCont .inputSarchText {
    font-size: 1.1rem;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .headerBtnsCont .inputSarchText {
    background-color: #ffffff;
  }
}
@media (min-width: 1281px) {
  .headerBtnsCont .inputSarchText {
    background-color: #ffffff;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .headerBtnsCont .inputSarchText {
    box-shadow: none;
  }
}
@media (min-width: 1281px) {
  .headerBtnsCont .inputSarchText {
    box-shadow: none;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .headerBtnsCont .inputSarchText {
    -webkit-box-shadow: none;
  }
}
@media (min-width: 1281px) {
  .headerBtnsCont .inputSarchText {
    -webkit-box-shadow: none;
  }
}
.headerBtnsCont ._ON_SEARCH_ {
  display: flex;
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .headerBtnsCont ._ON_SEARCH_ {
    animation: searchText 0.1s 0s ease forwards;
  }
}
@media (min-width: 1281px) {
  .headerBtnsCont ._ON_SEARCH_ {
    animation: searchText 0.2s 0s ease forwards;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .headerBtnsCont ._ON_SEARCH_ {
    display: unset;
  }
}
@media (min-width: 1281px) {
  .headerBtnsCont ._ON_SEARCH_ {
    display: unset;
  }
}
.headerBtnsCont .infoCont {
  grid-area: 3/1/4/-1;
  width: 90%;
  height: fit-content;
  justify-self: center;
  align-self: start;
  padding: 10px 10px;
  background-color: rgba(0, 0, 0, 0.0823529412);
  border-radius: 5px;
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.769),
    inset 0 0 4px rgba(0, 0, 0, 0.609);
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.769),
    inset 0 0 4px rgba(0, 0, 0, 0.609);
  color: #000000;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  display: none;
  flex-basis: 100%;
  justify-content: center;
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .headerBtnsCont .infoCont {
    display: block;
  }
}
@media (min-width: 1281px) {
  .headerBtnsCont .infoCont {
    display: block;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .headerBtnsCont .infoCont {
    position: absolute;
  }
}
@media (min-width: 1281px) {
  .headerBtnsCont .infoCont {
    position: absolute;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .headerBtnsCont .infoCont {
    grid-area: 1/1/2/-1;
  }
}
@media (min-width: 1281px) {
  .headerBtnsCont .infoCont {
    grid-area: 1/1/2/-1;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .headerBtnsCont .infoCont {
    width: 70%;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .headerBtnsCont .infoCont {
    width: 70%;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .headerBtnsCont .infoCont {
    width: 30%;
  }
}
@media (min-width: 1281px) {
  .headerBtnsCont .infoCont {
    width: 30%;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .headerBtnsCont .infoCont {
    height: 0px;
  }
}
@media (min-width: 1281px) {
  .headerBtnsCont .infoCont {
    height: 0px;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .headerBtnsCont .infoCont {
    background-color: #ffffff;
  }
}
@media (min-width: 1281px) {
  .headerBtnsCont .infoCont {
    background-color: #ffffff;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .headerBtnsCont .infoCont {
    box-shadow: none;
  }
}
@media (min-width: 1281px) {
  .headerBtnsCont .infoCont {
    box-shadow: none;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .headerBtnsCont .infoCont {
    -webkit-box-shadow: none;
  }
}
@media (min-width: 1281px) {
  .headerBtnsCont .infoCont {
    -webkit-box-shadow: none;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .headerBtnsCont .infoCont {
    display: flex;
  }
}
@media (min-width: 1281px) {
  .headerBtnsCont .infoCont {
    display: flex;
  }
}
.headerBtnsCont .infoCont p {
  font-size: 1rem;
  font-weight: 400;
  font-style: normal;
  color: #000000;
  text-align: center;
  text-align: center;
  word-wrap: break-word;
}
@media (min-width: 481px) and (max-width: 767px) {
  .headerBtnsCont .infoCont p {
    font-size: 1rem;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .headerBtnsCont .infoCont p {
    font-size: 1rem;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .headerBtnsCont .infoCont p {
    font-size: 1rem;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .headerBtnsCont .infoCont p {
    font-size: 1rem;
  }
}
@media (min-width: 1281px) {
  .headerBtnsCont .infoCont p {
    font-size: 1rem;
  }
}
.headerBtnsCont ._ON_info {
  display: flex;
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .headerBtnsCont ._ON_info {
    animation: infoContainerAnim 0.1s 0s ease forwards;
  }
}
@media (min-width: 1281px) {
  .headerBtnsCont ._ON_info {
    animation: infoContainerAnim 0.2s 0s ease forwards;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .headerBtnsCont ._ON_info {
    display: unset;
  }
}
@media (min-width: 1281px) {
  .headerBtnsCont ._ON_info {
    display: unset;
  }
}

@keyframes infoContainerAnim {
  0% {
    transform: translateY(0%);
    height: 0px;
  }
  50% {
    transform: translateY(34%);
    height: 0px;
  }
  100% {
    transform: translateY(35%);
    height: auto-fit;
    min-height: 150px;
    box-shadow: 0 0 4px #1264a3, 0 0 7px #1264a3;
    -webkit-box-shadow: 0 0 4px #1264a3, 0 0 7px #1264a3;
  }
}
@keyframes searchText {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(150%);
    box-shadow: 0 0 4px #1264a3, 0 0 7px #1264a3;
    -webkit-box-shadow: 0 0 4px #1264a3, 0 0 7px #1264a3;
  }
}
