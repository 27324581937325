@import "../../../../../../index.scss";
.jumps-modal-content-wrapper {
  .jumps-modal-content {
    @include flex(stretch, flex-start);
    gap: 20px;
    width: 100%;
    height: 100%;
  }

  .footer {
    @include flex(center, flex-end);
    width: 100%;
  }
}
