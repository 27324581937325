.fleet-live-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  width: 100%;
  background-color: #f5f5f7;
  color: #323338;
  padding-left: 10px;
  padding-right: 20px;
  border-bottom: 1px solid #dee2eb;
  gap: 1rem;
  overflow-x: auto;

  .ant-form-item {
    margin-bottom: 0;
  }

  .title-section {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    width: fit-content;
    height: 100%;
    gap: 10px;

    .live-title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 100%;
      width: 190px;
      border-right: 1px solid #dee2eb;

      .menu-icon {
        height: 80%;
        aspect-ratio: 1;
        transition: background-color 0.2s ease-in-out;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
          cursor: pointer;
          background-color: rgba(60, 60, 77, 0.1);
        }
      }

      .title-text {
        font-family: Open Sans;
        font-weight: 800;
        margin-right: 1rem;
        margin-left: 1rem;
        white-space: nowrap;
        font-size: 1.1rem;
        letter-spacing: 0.28px;
        cursor: default;
      }
    }

    .accountingWriteModeContainerLight .statusContainer .statusLabel {
      color: #323338;
    }

    #no-connection-span {
      height: 23px;
      min-width: 130px;
      width: -moz-fit-content;
      width: fit-content;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 20px;
      color: white;
      font-family: "Open Sans", sans-serif;
      font-size: 16px;
      text-align: center;
      border-radius: 5px;
      font-weight: 400;
      background-color: #eb4345;
      color: #fff;
      cursor: default;
    }
  }

  .control-section {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
    gap: 1rem;

    .ant-select-selector {
      border: 0;
    }

    .ant-picker {
      border: 0;
    }

    #update-span {
      font-size: 14px;
      font-weight: 400;
      cursor: default;
      white-space: nowrap;
    }
  }
}

.fleet-live-header.live-header-dark {
  background-color: #12131b;
  color: #fffeff;
  border-bottom: 1px solid #616481;

  .title-section {
    .live-title {
      border-right: 1px solid #616481;
    }

    .menu-icon {
      &:hover {
        cursor: pointer;
        background-color: rgb(60, 60, 77);
      }
    }
  }

  .control-section {
    .ant-select-selector {
      background-color: #3a3c4e;
      color: #f5f5f7;
    }
    .ant-select-arrow,
    .ant-picker-suffix {
      color: #f5f5f7;
    }

    .ant-picker {
      background-color: #3a3c4e;
      color: #f5f5f7;

      .ant-picker-input {
        input {
          color: #f5f5f7;
        }

        input[disabled] {
          color: #acacca;
        }
      }
    }
  }
}
