.ErrorInfoModal {
  width: fit-content !important;
  height: fit-content !important;
  .ant-modal-content {
    width: fit-content;
    height: fit-content;
    padding: 0;
    border-radius: 10px;
    .ant-modal-close {
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .ant-modal-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 10px;
      height: 45px;
      border: 0;
      background: #f8f8fa;
      border-radius: 10px 10px 0 0;
      .ant-modal-title {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #323338;
      }
    }
    .ant-modal-body {
      width: fit-content;
      height: fit-content;
      padding: 20px;
      display: flex;
      .errorMessage {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 82px;
        color: #323338;
      }
      .errorStack {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #323338;
      }
      .textAreaContainer {
        .textAreaLabel {
          font-size: 16px;
          font-weight: 600;
          margin-left: 10px;
        }
        .textAreaExplanation {
          background-color: #f5f5f7;
          border-radius: 10px;
          font-size: 14px;
          .ant-input {
            background-color: #f5f5f7;
            border-radius: 10px;
            font-size: 14px !important;
          }
        }
      }
    }
    .ant-modal-footer {
      padding: 0 20px 20px 20px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      border: 0;
    }
  }
}

@media screen and (max-width: 768px) {
  .ErrorInfoModal {
    .ant-modal-content {
      .ant-modal-body {
        max-width: 95vw;
        max-height: 80dvh;
        .ErrorInfoModal {
          height: 100%;
          overflow: auto;
          .errorMessage {
            line-height: 1.3;
          }
          .errorStack {
            height: 300px;
            overflow-y: auto;
          }
        }
      }
    }
  }
}
