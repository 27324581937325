.pli-notes {
  animation: pliNotes 0.5s;
}

@keyframes pliNotes {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
