@import "../../../../index.scss";

$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.opportunityModalContainer {
  .ant-modal-content {
    max-width: 1724px;
    .ant-modal-body {
      height: calc(100vh - 185px);
      overflow-x: hidden;
      overflow-y: auto;
      .opportunityModalStepper {
        height: 100%;
        .stepContentWrapper {
          height: 100%;
          .stepContent {
            display: flex;
            align-items: flex-end;
            gap: 20px;
            flex-wrap: wrap;

            &.typeOfWorkStep {
              align-items: start;
              .customAntdTransfer {
                height: 500px;
              }
            }

            > *:not(.mondayButtonContainer, .app-map-geofence) {
              flex: 1 0 0;
              min-width: 300px;

              .ant-picker,
              input {
                width: 100%;
                max-width: unset !important;
              }
            }

            .mondayButtonContainer {
              align-self: flex-end;
            }

            .dynamicAvatarsDiv {
              flex: unset;
              min-width: unset;
            }

            .googlePickerButtonContainer {
              justify-content: unset;
            }

            .labeledInputContainer {
              .ant-form-item {
                margin: unset;
              }
            }
          }
        }

        .opportunityTypeContainer {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          max-width: 933px;
          text-wrap: nowrap;
          @include flex(center, flex-start);
          background-color: #f8f8fa;
          border-radius: 5px;
          padding: 10px;
          .radioTitle {
            font-size: 16px;
          }
          .labeledInputContainer {
            .ant-form-item {
              margin: 0;
              .ant-radio-group {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                gap: 10px;
              }
              .ant-radio-wrapper {
                flex: 1;
                font-size: 16px !important;
                // margin-inline-end: 10px;
              }
            }
          }
        }
      }

      .newFooterContainer {
        .mondayButtonStyle {
          width: 150px;
          svg {
            path {
              fill: #fff;
            }
          }
        }
      }
    }
  }

  .labeledInputContainer {
    .labeledInputLabel {
      @include text(14px, 600, 0.21px, #383554);
      margin-bottom: 2px;
    }
  }

  .splitContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;

    .labeledInputContainer {
      width: 50%;
    }
  }

  .radioTitle {
    @include text(14px, 600, 0, #323338);
    margin-right: 15px;
  }

  .defaultInputsStyle {
    @include flex(center, flex-start);
    width: stretch;
    flex-wrap: wrap;
  }

  .ant-transfer-list {
    height: 400px;
  }
  .ant-btn {
    border-radius: 5px;
  }
}

.opportunityModalContainerDark {
  .opportunityModalStepper {
    .antCustomStepper {
      .ant-steps {
        .ant-steps-item-finish ::before,
        .ant-steps-item-active::before,
        .ant-steps-item::before {
          border-left-color: $cardModalBodyDark;
        }
        .ant-steps-item-wait {
          background-color: $cardModalHeaderDark;
          &::after {
            border-left-color: $cardModalHeaderDark;
          }
          .ant-steps-item-content .ant-steps-item-title {
            color: #acacac;
          }
        }
      }
    }

    .opportunityTypeContainer {
      background: $inputModalDark !important;
      .radioTitle,
      .ant-radio-wrapper {
        color: #fff;
      }
    }

    .configElementContainer {
      background-color: $inputModalDark;
      .configElementTitle {
        color: #f2f2f2;
      }
    }
  }
}

.alternativeAddressesContainer {
  .ant-popover-inner {
    border-radius: 10px;
    .ant-popover-title {
      padding-left: 20px;
      padding-top: 10px;
      .opportunityModalPopoverTitle {
        @include text(14px, 600, 0.21px, #383554);
      }
    }
  }
  .ant-popover-inner-content {
    .opportunityAltAddContainer {
      .placesInputContainer .addressDetailsContainer {
        input {
          border-radius: 5px;
        }
      }
      .controllerButtons {
        .addButton {
          background-color: #71cf48;
          border: none;
        }
        .ant-btn {
          border-radius: 5px;
        }
      }
    }
  }
}

.alternativeAddressesContainerDark {
  .ant-popover-inner {
    background-color: $cardModalHeaderDark;
    .ant-popover-title {
      .opportunityModalPopoverTitle {
        color: #fff;
      }
    }
    .ant-popover-inner-content {
      .placesInputContainer {
        .ant-input {
          background-color: $cardModalBodyDark !important;
          border: none;
          color: #fff;
          &::placeholder {
            color: #acacca;
          }
        }
        .ant-select {
          .ant-select-selector {
            background-color: $cardModalBodyDark !important;
            .ant-select-selection-search-input {
              color: #fff;
            }
            .ant-select-selection-item {
              color: #fff;
            }
            .ant-select-selection-placeholder {
              color: #acacca;
            }
          }
          .ant-select-clear {
            background-color: $cardModalBodyDark;
            svg {
              fill: #acacca;
            }
          }
          .ant-select-arrow svg {
            fill: #fff;
          }
        }
        .actionButtons {
          svg {
            fill: #fff;
          }
        }
        .label {
          color: #fff;
        }
      }
    }
  }
}
