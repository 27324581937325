.payrollActivityMapContainer {
  .payrollActivityMap {
    height: 450px;
    .drawing-buttons {
      position: absolute;
      top: 10px;
      left: 185px;
      .draw-button {
        height: 40px;
        display: flex;
        align-items: center;
        border-radius: 2px;
        border: none;
        font-size: 17px;
        font-weight: 600;
        gap: 5px;
        color: #000;
      }
    }
  }
}
