@import "../../../../../../../index.scss";

$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.documentationTemplatesModal {
  .ant-modal-content {
    max-height: calc(100vh - 20px);
    max-width: calc(100vw - 20px);
  }
  .ant-modal-body {
    padding: 0;
    min-width: 890px !important;
  }
  .ant-modal-header .ant-modal-title {
    font-weight: 600;
  }
  .documentationTemplatesModalContainer {
    // height: calc(100vh - 180px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 30px 0px;
    // border: 1px solid red;
    .search-comp {
      margin-top: 2px;
      margin-bottom: 20px;
      .ant-select-selector {
        padding-left: 30px;
        height: 32px;
        width: 300px;
        background-color: #f7f8fa;
        border: none;
        border-radius: 5px;
        .ant-select-selection-search-input {
          padding-left: 20px;
        }
      }
      .ant-select {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        width: "100%";
        color: rgba(0, 0, 0, 0.85);
        font-size: 14px;
        font-variant: tabular-nums;
        line-height: 1.5715;
        list-style: none;
        font-feature-settings: "tnum", "tnum";
        position: relative;
        display: inline-block;
        cursor: pointer;
        z-index: 2;
      }
    }
    .search-Div {
      width: 300px;
      display: flex;
      align-items: center;
    }
    .search-logo {
      font-size: 16px;
      position: relative;
      right: 290px;
    }
    .docsLengthLabel {
      @include text(18px, 700, 0, #1264a3);
    }
    .configElementContainer {
      margin: 0 !important;
    }
    .labeledInputLabel {
      height: 0 !important;
      margin: 0 !important;
    }

    .checkBoxGrid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
      .ant-form-item {
        margin-bottom: 0 !important;
      }
      .ant-row {
        margin-top: 0 !important;
      }
    }

    .checkboxContainer-123 {
      margin-bottom: 30px;
      min-width: 810px;
      height: calc(100vh - 350px);
      overflow-x: hidden;
      overflow-y: auto;
      // border: 1px solid red;
      // min-height: 260px;
    }
    .checkboxLabel {
      display: flex;
    }

    .templateCheckbox {
      width: 400px;
      background-color: #f7f8fa;
      margin-bottom: 0 !important;
    }
    .templateLabel {
      font-size: 16px;
      width: 259px;
      display: inline-block;
      @include textEllipsis();
    }

    .tooltipContent {
      @include flex(center, center, column);
    }

    .templateModalActionButtons {
      @include flex(center, center);
    }

    .rejectButton {
      @include mondayButton(#f5f5f7, #e6e9ef, #333238);
      margin-right: 30px;
    }

    .submitButton {
      @include mondayButton(#71cf48, #68c142);
    }
  }
}

.documentationTemplatesModalDark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      .documentationTemplatesModalContainer {
        .search-div {
          .ant-select {
            .ant-select-selector {
              background-color: $inputModalDark !important;
              .ant-select-selection-search-input {
                color: #fff;
              }
              .ant-select-selection-item {
                color: #fff;
              }
              .ant-select-selection-placeholder {
                color: #acacca;
              }
            }
            .ant-select-clear {
              background-color: $inputModalDark;
              svg {
                fill: #acacca;
              }
            }
            .ant-select-arrow svg {
              fill: #fff;
            }
          }
          .search-logo path {
            fill: #fff;
          }
        }
        .checkboxesContainer,
        .checkboxContainer-123 {
          .templateCheckbox-123,
          .templateCheckbox {
            background-color: $inputModalDark;
            .configElementTitle {
              color: #fff;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 850px) {
  .documentationTemplatesModal .documentationTemplatesModalContainer {
    padding: 20px;
    .checkBoxGrid {
      grid-template-columns: 100%;
      justify-items: center;
    }
    .checkboxContainer-123 {
      min-width: 100%;
    }
  }
}

@media (max-width: 450px) {
  .documentationTemplatesModal .documentationTemplatesModalContainer {
    .search-div,
    .search-comp {
      width: 100%;
      .ant-select-selector {
        width: 100%;
      }
    }
  }
}

@media (max-width: 380px) {
  .documentationTemplatesModal .documentationTemplatesModalContainer {
    .templateModalActionButtons {
      .mondayButtonContainer {
        justify-content: center;
        .rejectButton {
          margin-right: 0px;
        }
      }
      .mondayButtonContainer:last-child {
        margin: 0px !important;
      }
    }
  }
}

@media (max-width: 300px) {
  .documentationTemplatesModal
    .documentationTemplatesModalContainer
    .templateCheckbox {
    width: 230px !important;
    min-width: 230px !important;
  }
}
