.reply-container {
    border-left: 1px solid #ccc;
    color: #800080;
    padding: 10px 15px 0px 10px;
    position: relative;
    margin: 30px 0px;


    .reply-date-container {
        position: absolute;
        top: -20px;
        left: -5px;
    }

    .reply-container-chilren-wrapper {
        margin: 20px 10px
    }

    .reply-body-container {
        margin-bottom: 30px;
        margin-left: 6px;
    }

}