// CustomModalHeader
.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 45px);
  height: 100%;
  .buttons {
    display: flex;
    gap: 10px;
  }
  .tourButton {
    height: 32px;
    position: relative;
    bottom: 4px;
    width: 32px;
    .mondayButtonText,
    .mondayButtonDivider {
      display: none;
    }
  }
}
@media screen and (max-width: 1000px) {
  .titleContainer {
    width: 95% !important;
  }
}
@media screen and (max-width: 450px) {
  .titleContainer {
    width: 90% !important;
  }
}
.sortFieldsIcon {
  cursor: pointer;
  height: 23px;
  width: 23px;
  transform: rotate(90deg);
  background-color: #1264a3;
  border-radius: 50%;
  fill: #fff;
  font-size: 10px;
  rect {
    fill: #1264a3;
    border-radius: 50%;
  }
  path {
    fill: #fff;
    transform: scale(0.8);
    transform-origin: center;
  }
}

.fullTitleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  .buttons {
    display: flex;
    gap: 10px;
  }
  .tourButton {
    height: 32px;
    position: relative;
    bottom: 4px;
    width: 32px;
    .mondayButtonText,
    .mondayButtonDivider {
      display: none;
    }
  }
}
// CustomModalHeader END

// PlayVideoTutorial
.customVideoTutorialModal {
  max-width: 70vw !important;
  min-width: 300px;
  //   max-height: 40vh !important;
  width: unset !important;
}
@media screen and (max-width: 1200px) {
  .customVideoTutorialModal {
    max-width: 95vw !important;
    max-height: 60vh !important;
  }
}
