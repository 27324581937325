@import "../index.scss";

.ErrorComponent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  .ErrorComponent-errorMessage {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 60px;
    line-height: 82px;
    color: #1f2a44;
  }
  .ErrorComponent-errorDescription {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: #c3c3cd;
  }
  .ErrorComponent-buttonWrapper {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  .ErrorComponent-button {
    @include mondayButton(#1264a3, #0f5c97);
    width: 150px;
  }
  .ErrorComponent-button-feedback {
    @include mondayButton(#fe4c4a, #eb4345);
    width: fit-content;
    border: 0;
    border-radius: 5px;
    height: 32px;
    font-family: "Open Sans";
    font-weight: 400;
    font-size: 14px;
  }
}

@media screen and (max-width: 768px) {
  .ErrorComponent {
    .ErrorComponent-errorMessage {
      font-size: 26px;
    }
    .ErrorComponent-errorDescription {
      font-size: 16px;
      text-align: center;
    }
  }
}
