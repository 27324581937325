@import "../../../../index";

$mainDarkBackgroundColor: #20212d;
$cardDarkHeaderColor: #2a2b3a;
$cardDarkBackgroundColor: #12131b;
$inputDarkColor: #2a2b3a;
$header-height: 118px;
$sidebar-width: 250px;

.reportsBasePage {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: hidden;
  .approvalsPageControlPanel {
    height: 85px !important;
  }
  .reportsPageContainer {
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;
    overflow-y: hidden;

    // overflow: auto;

    // margin-top: $header-height;

    // height: calc(100vh - #{$header-height});
    // width: calc(100vw - #{$sidebar-width});
    // margin-left: $sidebar-width;

    flex: 1;

    // @include flex(flex-start, flex-start, column);

    .reportsContainer {
      // @include cardLayout();
      overflow-y: auto;
      overflow-x: hidden;
      // padding: 20px;
      width: 100%;
      height: 100%;
      background: #ffffff;
    }

    .controlPanel {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      margin-bottom: 10px;
      gap: 10px;
    }

    .cardLayout {
      @include cardLayout();
      padding: 20px 20px;
      overflow: hidden;
      max-height: 500px;
      overflow-y: auto;
    }
    .collapsed {
      height: 46px;
      overflow: hidden;
      padding: 0;
      .cardHeader {
        margin: 0;
        overflow: hidden;
      }
      @media (max-width: 450px) {
        overflow: hidden !important;
        padding: 0 !important;
      }
    }
    .cardHeader {
      svg {
        margin-right: 10px;
      }
    }

    .reportsGrid {
      display: grid;
      width: 100%;
      gap: 10px;
      grid-template-columns: repeat(5, minmax(200px, 1fr));
      position: relative;
    }

    .favoriteReportsContainer {
      grid-column: 1 / span 2;
    }

    .lastOpenedReportsContainer {
      grid-column: 3 / span 2;
      // overflow: scroll;
    }

    .favoriteCategoriesContainer {
      grid-column: 1 / span 2;
    }

    .filtersCard {
      $inputs-color: #f5f5f7;
      grid-column: 3 / span 2;

      .searchBarContainer {
        display: flex;
      }

      .filterSelectors {
        margin-top: 20px;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(247px, 1fr));
        column-gap: 130px;
        row-gap: 20px;
      }

      .ant-input {
        background-color: $inputs-color;
      }
      .ant-input-affix-wrapper,
      .ant-select-selector,
      .ant-picker {
        border: 0;
        border-radius: 5px;
        background-color: $inputs-color;
      }
      .filterInput {
        @include flex(stretch, center, column);
        min-width: 247px;

        .filterInputLabel {
          font-family: "Open Sans";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
          color: #323338;
          margin-left: 10px;
        }
        .dynamicSelect {
          height: 32px;
          .ant-select-selector {
            background: #f5f5f7;
            border-radius: 5px;
            border: 0;
            .ant-select-selection-item {
              font-family: "Open Sans";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
            }
          }
        }
      }

      .filterBtn {
        @include mondayButton(#1264a3, #0f5c97);
        margin-left: 20px;
      }
    }
  }
}
.reportsBasePage {
  .reportsPageContainerDark {
    background-color: $mainDarkBackgroundColor;
    .reportsContainer {
      background-color: $mainDarkBackgroundColor;
    }
    .lastOpenedReportsContainer,
    .favoriteReportsContainer {
      background-color: $cardDarkBackgroundColor;
      .cardHeader {
        background-color: $cardDarkHeaderColor;
        color: #fff;
      }
      .listContainer {
        .favoriteReportLabel {
          svg path {
            fill: #fff;
          }
          div {
            color: #fff;
          }
          .verticalDivider {
            background-color: #acacca;
          }
          &:hover {
            background-color: #2a2b3a;
          }
        }
      }
    }
  }
}

.dynamicSelectDropdown {
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
  .rc-virtual-list {
    .rc-virtual-list-holder {
      .rc-virtual-list-holder-inner {
        .ant-select-item-option-selected {
          background: #f1f5fd;
          .ant-select-item-option-content {
            color: #1264a3;
          }
        }
        .ant-select-item {
          padding-left: 10px;
          padding-right: 10px;
          padding-top: 6.5px;
          padding-bottom: 6.5px;
          .ant-select-item-option-content {
            font-family: "Open Sans";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            margin: 0;
            padding: 0;
          }
          &:not(:last-child) {
            border-bottom: 1px solid #efefef;
          }
          &:hover {
            background: #f1f5fd;
            .ant-select-item-option-content {
              color: #1264a3;
            }
          }
        }
      }
    }
  }
}

// @media only screen and (max-width: 768px) {
//   .reportsPageContainer {
//     width: calc(100% - 100px);
//     margin-left: 100px;
//     margin-top: 110px;
//     height: calc(100vh - 110px);
//   }
// }
@media only screen and (max-width: 1440px) {
  .reportsPageContainer {
    .controlPanel .favoriteReportsContainer,
    .lastOpenedReportsContainer {
      grid-column: 1 / span 4;
    }
    .reportsGrid {
      grid-template-columns: repeat(4, minmax(200px, 1fr));
    }
  }
}
@media only screen and (max-width: 1100px) {
  .reportsPageContainer {
    .controlPanel .favoriteReportsContainer,
    .lastOpenedReportsContainer {
      grid-column: 1 / span 4;
    }
    .reportsGrid {
      grid-template-columns: repeat(3, minmax(200px, 1fr));
    }
  }
}
@media only screen and (max-width: 850px) and (min-width: 450px) {
  .reportsPageContainer {
    .controlPanel .favoriteReportsContainer,
    .lastOpenedReportsContainer,
    .filtersCard,
    .favoriteCategoriesContainer {
      grid-column: 1 / span 4;
    }
    .reportsGrid {
      grid-template-columns: repeat(2, minmax(200px, 1fr));
    }
  }
}
@media only screen and (max-width: 450px) {
  .reportsPageContainer {
    width: 100%;
    margin-left: 0px;
    // margin-top: 110px;
    padding: 10px !important;
    height: calc(100vh - 110px);
  }
  .reportsPageContainer .controlPanel {
    display: block !important;
  }
  .reportsPageContainer .cardLayout {
    margin-bottom: 15px !important;
  }
  .reportsPageContainer .lastOpenedReportsContainer,
  .favoriteReportsContainer {
    overflow: auto;
    overflow-x: scroll !important;
  }
  .reportsPageContainer .reportsGrid {
    grid-template-columns: repeat(1, minmax(250px, 1fr)) !important;
    justify-items: center;
  }
  .reportsPageContainer .general-reports-container {
    margin-bottom: 20px;
  }
}

.main-reports {
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
}
