$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.folderAccessChanger {
  width: fit-content !important;
  .ant-modal-content {
    .ant-modal-header {
      height: 45px;
      display: flex;
      align-items: center;
      background-color: #f8f8fa;
      overflow: hidden;
      border: none;

      .ant-modal-title {
        font-size: 16px;
        width: 100%;
        font-weight: 600;
        color: #323338;
        padding: 0 !important;
        height: 45px;
        display: flex;
        align-items: center;
      }
    }
    .ant-modal-body {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .ant-form {
        max-height: 75dvh;
        overflow-y: auto;
        width: 500px;
      }
      .add-new-container {
        padding: 10px;
        border-top: 1px solid #e8e8e8;
        &:hover {
          background-color: unset;
        }
        .labeledInputContainer {
          .ant-select {
            width: 80%;
          }
        }
        .add-new-right {
          display: flex;
        }
      }
    }
    .ant-modal-footer {
      display: flex;
      justify-content: space-between;
      @media screen and (min-width: 1500px) {
        button {
          width: 150px;
        }
      }
    }
  }
}

.folderAccessChanger-dark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      .add-new-container {
        background-color: rgb(26.16, 27.6133333333, 39.24) !important;
      }
      .labeledInputLabel {
        color: #fff;
      }
      .ant-select {
        .ant-select-selector {
          background-color: $inputModalDark !important;
          .ant-select-selection-search-input {
            color: #fff;
          }
          .ant-select-selection-item {
            color: #fff;
          }
          .ant-select-selection-placeholder {
            color: #acacca;
          }
        }
        .ant-select-clear {
          background-color: $inputModalDark;
          svg {
            fill: #acacca;
          }
        }
        .ant-select-arrow svg {
          fill: #fff;
        }
      }
      .ant-select-disabled {
        .ant-select-selector {
          .ant-select-selection-search-input {
            color: #acacca !important;
          }
          .ant-select-selection-item {
            color: #acacca !important;
          }
        }
      }
    }
  }
}
