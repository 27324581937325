.googleScopesInfoModal {
  .ant-modal-body {
    min-width: 400px;
    display: grid;
    .googleScopesInfoModal__radioGroup {
      justify-self: center;
      .ant-radio-button-wrapper {
        padding-top: 5px;
      }
      .radioGoogleAccount {
        .accountIcon {
          font-size: 17px;
          color: #3873e8;
          margin-right: unset !important;
          position: relative;
          top: -3px !important;
        }
      }
    }
    .listContainer {
      max-height: 600px;
      overflow-y: auto;
      margin-top: 20px;
      .scopeListItem {
        display: flex;
        align-items: center;
        p {
          max-width: 400px;
        }
      }
      .accountIcon {
        font-size: 20px;
        color: #3873e8;
        margin-right: 10px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .googleScopesInfoModal {
    min-width: 350px;
    .ant-modal-body {
      min-width: 300px;
      .googleScopesInfoModal__radioGroup {
        .radioGoogleAccount {
          .accountIcon {
            font-size: 15px;
          }
        }
      }
      .listContainer {
        .accountIcon {
          font-size: 17px;
        }
      }
    }
  }
}
