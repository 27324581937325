.profile-image {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 30px;
  .user-image {
    width: 98px;
    height: 98px;
    cursor: pointer;

    .image-holder {
      position: relative;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .image {
        height: 100%;
        width: 100%;
        border-radius: 50%;
        overflow: hidden;
      }
      .remove-img-btn {
        position: absolute;
        top: 40%;
        right: -8px;

        button {
          max-width: 17px;
          min-width: 17px;
          max-height: 17px;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            width: 12px;
            height: 12px;
          }
        }
      }
    }
    .ant-avatar {
      width: 100%;
      height: 100%;
      transition: 0.3s;
      .ant-avatar-string {
        font-size: 48px;
      }
      &:hover {
        scale: 1.05;
        box-shadow: rgb(38, 57, 77) 0px 7px 10px -3px;
      }
    }
  }
  .left-buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    .google-sign-out {
      display: flex;
      gap: 10px;
    }
    .mondayButtonContainer {
      button {
        width: 100%;
      }
    }
  }

  .warning-modal {
    display: none !important;
  }

  &.dark-mode {
    .user-image {
      .image {
        background-color: #2a2b3a;
      }
    }
  }
}
