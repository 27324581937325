.cloneDepartmentAccess {
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .ant-form {
    display: flex;
    gap: 10px;
    width: 100%;
    .labeledInputContainer {
      height: 58px;
      width: 50%;
      .ant-form-item {
        margin-bottom: 0;
      }
    }
  }
}
