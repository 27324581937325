.maintenance-view-info-modal {
  .ant-modal-body {
    gap: 20px;
  }

  .info-defect {
    display: grid;
    place-items: center;
    padding: 3px 7px;
    min-width: 100px;
    color: #fff;
    font-weight: 550;
    border-radius: 5px;
  }

  .info-defect.Defective {
    background-color: #f6cb52;
  }

  .info-defect.Poor {
    background-color: #fe4c4a;
  }
}
