@import "../../../../../../../../index.scss";

.doc-members {
  width: 100%;
  height: auto;
  background-color: #f8f8fa;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  flex-direction: column;

  .membersButtonDiv {
    display: flex;
    flex-direction: row;
    height: fit-content;

    .docMembersButton {
      top: 25px;
      margin-right: 7px;
      width: 32px;
      height: 32px;
      padding: 0 6px;
      .mondayButtonText,
      .mondayButtonDivider {
        display: none;
      }
    }
  }

  .doc-selectTeam {
    width: 100%;
    border-radius: 5px;
    height: 70px;
    .labeledInputLabel {
      @include text(16px, 600, 0, #323338);
      margin-left: 10px;
    }
    .ant-select {
      min-height: 32px;
      max-width: 370px;
      border-radius: 5px;
      background-color: #ffffff !important;
      .ant-select-selector {
        width: 100%;
        max-height: 40px;
        height: 32px;
        overflow-y: auto;
        background-color: #ffffff !important;
      }
    }
  }
}
