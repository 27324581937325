@import "../../../../../../../../../index.scss";
.dependency-wrapper {
  margin-top: 18px;
  width: 35%;
  .button__addDependency {
    width: 100%;
    background: #1264a3;
    color: #fff;
    height: 32px;
    border: none;
    border-radius: 5px;
    font-size: 0.875rem;
  }
  .detailsModal__dependency {
    position: relative;
  }
  .dependency-dropdown {
    position: absolute;
    background: white;
    display: flex;
    flex-direction: column;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    z-index: 1000;
    width: 100%;
    margin-top: 3px;
    box-shadow: 0px 0px 11px -8px;
    span {
      cursor: pointer;
      padding: 5px 10px;
      font-size: 14px;
      color: #222;
      &:hover {
        background-color: #d4e6f3;
      }
    }
  }
  .dependency--container-wrapper {
    margin-top: 10px;
    max-height: 250px;
    overflow-y: auto;
    padding-right: 5px;
    &::-webkit-scrollbar:vertical {
      width: 5px;
    }
    .detailsModal__dependency__options {
      margin-bottom: 10px;
      h3 {
        margin-bottom: 4px;
        font-size: 14px;
        font-weight: 600;
        line-height: 1 !important;
        padding-left: 5px;
      }
      .detailsModal__dependency__buttons {
        display: flex;
        margin-top: 10px;
        .mondayButtonContainer {
          .mondayButtonStyle {
            height: 24px;
            .mondayButtonText {
              display: flex;
              align-items: center;
            }
          }

          &:first-child {
            margin-right: 10px;
            span {
              width: 12px;
            }
            .ant-btn {
              background-color: rgb(240, 79, 78);
            }
            .mondayButtonDivider {
              background: #eb4345;
            }
          }
        }
      }
      .ant-select {
        width: 100%;
        .ant-select-selector {
          border: none;
          background-color: #f5f5f7;
          border-radius: 5px;
          .ant-select-selection-item {
            font-size: 14px;
            color: #c7c7c7;
          }
        }
        .ant-select-arrow {
          svg {
            fill: #c7c7c7 !important;
          }
        }
      }
      .detailsModal__dependency__item {
        display: flex;
        align-items: center;
        padding-left: 5px;
        margin-bottom: 5px;
        &:first-child {
          margin-top: 5px !important;
        }
        h3 {
          font-weight: normal;
          font-size: 14px;
          margin-bottom: 0;
          line-height: 1.8;
          height: 15px;
          @include textEllipsis();
        }
        span {
          width: 15px;
          height: 15px;
          margin-left: 5px;
          background-color: #eb4345;
          fill: #fff;
          padding: 4px;
          border-radius: 2px;
          cursor: pointer;
        }
      }
    }
  }
}
