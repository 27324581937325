.assignProjectGrid {
  margin-left: -20px;
  width: 104%;
  .noRelatedProjects {
    font-size: 24px;
    padding: 20px;
    font-weight: 500;
  }
}
.assignProjectGridDark {
  .noRelatedProjects {
    color: white;
  }
}
