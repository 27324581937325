.includeRentalHeaderSovDark {
  padding: 0 20px;
  height: 45px;
  width: stretch;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #4a4a4a;
  gap: 35px;

  .rentalInformationContainer {
    display: flex;
    gap: 5px;
    .label {
      font: normal normal 600 16px/22px Open Sans;
      color: white;
    }

    .value {
      font: normal normal 600 16px/22px Open Sans;
      color: white;
    }
  }
}

.includeRentalHeaderSovLight {
  padding: 0 20px;
  height: 45px;
  width: stretch;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dcdfe3;
  gap: 35px;

  .rentalInformationContainer {
    display: flex;
    gap: 5px;
    .label {
      font: normal normal 600 16px/22px Open Sans;
      color: black;
    }

    .value {
      font: normal normal 600 16px/22px Open Sans;
      color: black;
    }
  }
}
