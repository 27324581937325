.mainGridContainer {
  margin: 10px 0 0 0;
  /* height: 670px; */
  height: 100%;
  background-color: white;
  overflow: hidden;
  box-shadow: 0 3px 3px #00000029;
  border-radius: 10px;
  padding: 20px;
}

.mainGridContainer.dark-ag-theme .ag-column-drop{
  background-color: #12131B;
}

.mainGridContainerDark {
  background-color: #12131B;
  box-shadow: 0 -1px 2px #ffffff94;
}