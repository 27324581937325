.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  .text {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
  }
}

.containerDark {
  background-color: transparent;
  .text {
    color: #fff;
  }
}
