@import "../../../../../../../index.scss";

$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.googleDriveAccessModalWrap {
  height: 100%;
  z-index: 995;
  position: fixed;
  top: 0;
  right: 0;
  bottom: unset !important;
  left: unset !important;
  overflow: hidden;
  outline: 0;
  .googleDriveAccessModal {
    width: 1000px !important;
    // min-height: 100% ;
    margin-right: 550px !important;
    margin: 0px auto 0px auto;
    pointer-events: all;

    .ant-modal-content {
      border-radius: 10px;
      .ant-modal-footer {
        border: none;
        padding: 10px 16px;
      }
      .ant-modal-body {
        min-height: 510px !important;
      }
      .ant-modal-close {
        width: 45px;
        height: 45px;
        .ant-modal-close-x {
          width: 45px;
          height: 45px;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            fill: #323338;
          }
        }
      }
    }
    .ant-modal-header {
      border-radius: 10px 10px 0px 0px;
      height: 45px;
      background-color: #f7f8fa;
      display: flex;
      align-items: center;
      border: none;
      // padding: 16px 24px;
      .ant-modal-title {
        text-align: left;
        font: normal normal 600 16px/39px Open Sans;
        letter-spacing: 0px;
        color: #323338;
      }
    }
    .googleDriveAccessModalFooter {
      display: flex;
      justify-content: space-between;
      .cancelFooterClassName {
        @include mondayButton(#fe4c4a, #eb4345, #fff);
      }
    }
    .googleDriveAccessModalBody {
      display: flex;
      flex-direction: column;

      .googleDriveAccessModalBodyForlders {
        display: flex;
        gap: 10px;
        justify-content: space-between;
        .googleDriveAccessModalBodyAddUser {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .googleDriveAccessModalBodyForldersName {
          display: flex;
          gap: 10px;
          max-width: 800px;
          overflow-x: auto;
          overflow-y: hidden;
          .foldersOption {
            width: 250px;
            height: 42px;
            background: #1264a3 0% 0% no-repeat padding-box;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            font-weight: 600;
            cursor: pointer;
            padding: 5px;
          }
          .foldersOptionSelected {
            @extend .foldersOption;
            background: #f5f5f7 0% 0% no-repeat padding-box;
            color: #323338;
          }
        }
      }
      .googleDriveFoldersHierarchy {
        flex: 1;
        width: 100%;

        .folders-hierarchy {
          position: relative;
          padding: 30px 0;
          padding-left: 15px;
          .folderName {
            display: flex;
            align-items: flex-start;
            .anticon-folder {
              font-size: 20px;
            }
          }
          h3 {
            margin-left: 10px;
          }
          .hierarchy-line-left-bottom {
            position: absolute;
            width: 8px;
            height: 42px;
            top: 0;
            left: 2px;
            border-left: 1px solid black;
            border-bottom: 1px solid black;
          }
        }
      }
      .usersTable {
        flex: 3;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        width: 100%;

        margin: 20px 50px;
        .usersTableSearchContainer {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .tableHeader {
          display: flex;
          gap: 1px;
          .headerCell {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 200px;
            height: 40px;
            font-weight: 600;
          }
        }
        .userTableNotFound {
          width: 100%;
          display: flex;
          justify-content: center;
          padding-top: 20px;
          .noUserLabel {
            text-align: left;
            font: normal normal 600 16px/7px Open Sans;
          }
        }
        .tableFooter {
          width: 100%;
          // border: 1px solid black;
          background-color: #e6e9ef;
          padding: 5px;
          border-radius: 0 0 10px 10px;
          margin: 0 -35px 0 0;
          display: flex;
          justify-content: center;
          align-items: center;
          .scrollTopContainer,
          .scrollBottomContainer {
            margin-bottom: 0 !important;
          }
        }
        .usersTableBody {
          width: 100%;
          max-height: 220px;
          overflow: overlay;
          display: flex;
          flex-direction: column;
          gap: 1px;
          margin: 0 -45px 0 0;

          .tableBody {
            @extend .tableHeader;
            gap: 1px;
            &:hover {
              .bodyCell,
              .bodyCellEdit {
                background-color: #e6e9ef;
              }
            }
            .bodyCell {
              @extend .headerCell;
              background-color: #f7f8fa;
              font-weight: 400;
            }
            .bodyCellEdit {
              @extend .bodyCell;
              color: #1264a3;
              cursor: pointer;
              font-weight: 600;
            }
            .bodyCellDelete {
              background-color: #fe4c4a;
              fill: #fff;
              padding: 5px 10px;
              // margin-right: 10px;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              margin-left: 3px;
            }
          }
        }
        .usersTableBody::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }
  .googleDriveAccessModalSingle {
    @extend .googleDriveAccessModal;
    margin: 0px auto 0px auto !important;
    animation-name: toRight;
    animation-duration: 0.35s;
    animation-iteration-count: 1, 2;
  }
  .googleDriveAccessModalDark,
  .googleDriveAccessModalSingleDark {
    .ant-modal-content {
      background-color: $cardModalBodyDark;
      .ant-modal-close .ant-modal-close-x svg {
        fill: #f2f2f2;
      }
      .ant-modal-header {
        background-color: $cardModalHeaderDark;
        .ant-modal-title {
          color: #f2f2f2;
        }
      }
      .ant-modal-body {
        .googleDriveAccessModalBody {
          .googleDriveFoldersHierarchy {
            h3 {
              color: #fff;
            }
            .folders-hierarchy {
              .folderName {
                span svg,
                h3 {
                  fill: #fff;
                  color: #fff;
                }
              }
              .hierarchy-line-left-bottom {
                border-color: #fff;
              }
            }
          }
          .googleDriveAccessModalBodyForldersName {
            .foldersOptionSelected {
              background-color: $inputModalDark;
              color: #f2f2f2;
            }
          }
          .usersTable {
            .ant-input-affix-wrapper {
              background-color: $inputModalDark !important;
              .ant-input-prefix svg {
                fill: #f2f2f2;
              }
              .ant-input {
                background-color: $inputModalDark !important;
                color: #f2f2f2;
                &::placeholder {
                  color: #acacca;
                }
              }
            }
            .tableHeader .headerCell {
              color: #f2f2f2;
            }
            .usersTableBody {
              .tableBody {
                .bodyCell {
                  background-color: $inputModalDark;
                  color: #f2f2f2;
                }
                .bodyCellEdit {
                  background-color: $inputModalDark;
                }
              }
            }
            .tableFooter {
              background-color: #2a2b3a;
              .scrollBottomContainer,
              .scrollTopContainer {
                svg path {
                  fill: #f2f2f2;
                }
              }
            }
          }
        }
      }
    }
  }
}
.googleDriveAccessModalWrapSingle {
  @extend .googleDriveAccessModalWrap;
  bottom: 0 !important;
  left: 0 !important;
}

.userDriveAccessWrap {
  height: 100%;
  z-index: 995;
  position: fixed;
  top: 0;
  right: 0;
  bottom: unset !important;
  left: unset !important;
  overflow: visible;
  outline: 0;
  .ant-zoom-leave {
    animation-name: toRightUser;
    animation-duration: 0.35s;
    animation-iteration-count: 1, 2;
  }
  animation-name: toLeftUser !important;
  animation-duration: 0.35s !important;
  animation-iteration-count: 1, 2 !important;
  .userDriveAccess {
    width: 402px !important;
    // min-height: 710px !important;
    // height: 100% !important;
    margin-right: 100px !important;
    margin: 0px auto;
    pointer-events: all;

    .ant-modal-content {
      border-radius: 10px;
      .ant-modal-header {
        border: none;
      }
      .ant-modal-body {
        min-height: 510px !important;
      }
      .ant-modal-footer {
        border: none;
      }
      .ant-modal-close {
        width: 45px;
        height: 45px;
        .ant-modal-close-x {
          width: 45px;
          height: 45px;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            fill: #323338;
          }
        }
      }
    }
    .ant-modal-header {
      border-radius: 10px 10px 0px 0px;
      height: 45px;
      background-color: #f7f8fa;
      display: flex;
      align-items: center;
      .ant-modal-title {
        text-align: left;
        font: normal normal 600 16px/39px Open Sans;
        letter-spacing: 0px;
        color: #323338;
      }
    }
    .userDriveAccessFooter {
      display: flex;
      justify-content: space-between;
      .cancelFooterClassName {
        @include mondayButton(#fe4c4a, #eb4345, #fff);
      }
    }
    .userDriveAccessBody {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      min-height: inherit;
      margin: -5px 0px -45px 0px;

      .folderSelector .ant-select-selection-overflow {
        top: 32px;
        right: 4px;
        background: transparent;
        border-radius: 0 0 5px 5px;
        min-width: 255px !important;
        padding: 5px;
        flex-wrap: wrap;
        .ant-select-selection-overflow-item {
          .ant-select-selection-item {
            display: flex;
            align-items: center;
            background: transparent;
            border: none;
            border-radius: 3px;
            .ant-select-selection-item-remove {
              width: 22px;
              display: flex;
              align-items: center;
              justify-content: center;
              .anticon-close svg {
                height: 18px;
                width: 18px;
                fill: #fff;
                font-weight: 600;
                background-color: #fe4c4a;
                border-radius: 3px;
                padding: 2px;
              }
            }
          }
        }
      }

      .ant-form-item-label {
        text-align: left;
        font: normal normal 600 16px/22px Open Sans;
        color: #323338;
      }
      .userDriveAccessBodyInput,
      .ant-select-selector {
        width: 255px;
        height: 32px;
        background: #f5f5f7 0% 0% no-repeat padding-box;
        border-radius: 5px;
        border: none;
      }
    }
  }
  .userDriveAccessDark {
    .ant-modal-content {
      background-color: $cardModalBodyDark;
      .ant-modal-close .ant-modal-close-x svg {
        fill: #f2f2f2;
      }
      .ant-modal-header {
        background-color: $cardModalHeaderDark;
        .ant-modal-title {
          color: #f2f2f2;
        }
      }
      .ant-modal-body {
        .ant-form-item-label label {
          color: #f2f2f2;
        }
        .ant-select {
          background-color: $inputModalDark !important;
          border-radius: 5px;
          .ant-select-selector {
            background-color: $inputModalDark !important;
            .ant-select-selection-search-input {
              color: #fff;
            }
            .ant-select-selection-item {
              color: #fff;
            }
            .ant-select-selection-placeholder {
              color: #acacca;
            }
          }
          .ant-select-clear {
            background-color: $inputModalDark;
            svg {
              fill: #acacca;
            }
          }
          .ant-select-arrow svg {
            fill: #fff;
          }
        }
      }
    }
  }
}

@media (max-width: 1560px) {
  .googleDriveAccessModalWrap {
    left: 0 !important;
    bottom: 0 !important;
    .googleDriveAccessModal {
      margin-right: auto !important;
    }
  }
  .userDriveAccessWrap {
    width: 100vw !important;
    .userDriveAccess {
      margin-right: auto !important;
    }
  }
}

@media (max-width: 630px) {
  .googleDriveAccessModalWrap .googleDriveAccessModal {
    .ant-modal-body {
      padding: 10px;
    }
    .googleDriveAccessModalBody .usersTable {
      margin: 20px 40px 20px 5px;
    }
    .googleDriveAccessModalFooter {
      flex-direction: column;
      align-items: center;
      gap: 5px;
    }
  }
}

@media (max-width: 380px) {
  .userDriveAccessWrap .userDriveAccess .ant-modal-header .ant-modal-title {
    font-size: 14px;
  }
}

@keyframes toRight {
  0% {
    transform: translateX(-20px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes toRightUser {
  0% {
    transform: translateX(30px);
  }
  100% {
    transform: translateX(500px);
  }
}
@keyframes toLeftUser {
  0% {
    transform: translateX(1000px);
  }
  100% {
    transform: translateX(30px);
  }
}
@keyframes toLeft {
  0% {
    transform: translateX(20px);
  }
  100% {
    transform: translateX(0);
  }
}
.toRight {
  animation: linear;
  animation-name: toRight;
  animation-duration: 0.5s;
}

.shake {
  animation-name: shake;
  animation-duration: 0.7s, 0.35s;
  animation-iteration-count: 1, 2;
}
@keyframes shake {
  0%,
  20%,
  40%,
  60%,
  80% {
    transform: translateX(8px);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateX(-8px);
  }
}
