@import "../../../../index.scss";

.reportViewerWrapper {
  width: 90vw;
  min-width: 90vw !important;
  height: 85vh;
  position: "relative";
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  // position: absolute;
  // width: 100vw;
  // height: 100vh;
  // top: 0;
  // left: 0;
  // z-index: 420;
}

.report-progress-bar {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;

  .report-progress-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
  }
}


.sub-modal {

  .custom-modal-mask,
  .custom-modal {
    z-index: 1 !important;
  }
}