$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.overtimeNamesWrap {
  overflow: hidden;
}

.overtimeNamesModal {
  height: fit-content;
  width: fit-content !important;

  .ant-modal-content {
    height: calc(100dvh - 20px);
    width: calc(100dvw - 20px);
    max-height: 650px;
    max-width: 1300px;

    .ant-modal-header {
      height: 45px;
      width: 100%;
      display: flex;
      align-items: center;
      font-weight: 600;
      border-radius: 10px 10px 0 0;
      background-color: #f8f8f8;

      .ant-modal-title {
        color: #323338;
        font-weight: 600;

        .titleInfoSpan {
          color: #fe4c4a;
        }
      }
    }

    .ant-modal-body {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      height: calc(100% - 56px);
      width: 100%;

      .gridHeader {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 10px;
        .mondayButtonText {
          height: 100%;
          display: flex;
          align-items: center;
        }
      }

      .overtimeGridContainer {
        height: 100%;
        width: 100%;
      }
    }
    .ant-modal-footer {
      .mondayButtonStyle {
        width: 150px;
      }
    }
  }
}

.overtimeNamesModalDark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      .deg-grid-container {
        .ag-root-wrapper {
          background-color: $cardModalBodyDark;
          .ag-header {
            background-color: $cardModalBodyDark;
            .ag-header-row {
              border-top: none;
            }
            .ag-header-cell-resize::after {
              width: 1px !important;
              background-color: #4a4a4a;
            }
          }
          .ag-paging-panel {
            background-color: $cardModalBodyDark;
          }
        }
      }
    }
  }
}
