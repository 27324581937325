@import "../../../../index.scss";

.loaderContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  @include absolute($position: fixed, $top: 0, $left: 0);
}

.absoluteLoaderContainer {
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(3px);
}
