@import "../../../../../../../../../../../../../assets/styles/breakpoints.scss";

.payment-amount-label {
  display: flex;
  justify-content: space-between;
  font-size: 18px;

  @include tablet {
    font-size: 16px;
  }

  .label-amount {
    font-weight: 700;
  }
}
