$mainDarkBackgroundColor: #20212d;
$cardDarkHeaderColor: #2a2b3a;
$cardDarkBackgroundColor: #12131b;
$inputDarkColor: #2a2b3a;

.loadingEmailIcon {
  position: fixed;
  top: 125px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.EmailBoxContainerMinimaze {
  max-height: 40px;
  width: 200px;
  margin: 0 0 0 10px;
  transition: width 0.3s ease-out;
  position: absolute;
  left: 20px;
  bottom: 0;

  &.second {
    left: 250px;
  }

  @media screen and (max-width: 768px) {
    position: absolute;
    bottom: 50px;
    width: 300px;
    right: 0px;
    border-bottom: none;
  }

  @media screen and (max-width: 810px) and (min-width: 767px) {
    width: 400px;

    .emailBoxHeaderMaximaze {
      display: none;
    }
  }

  .emailBoxContent {
    display: none;
  }

  .emailBoxFooter {
    display: none;
  }

  .emailBoxHeader {
    cursor: pointer;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    height: 40px;
    width: inherit;
    background-color: #404040;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px 0 10px;

    .titleContainer {
      display: flex;
      gap: 10px;
      align-items: center;
      width: calc(100% - 60px);

      .emailBoxHeaderTitle {
        -webkit-font-smoothing: antialiased;
        font-family: Roboto, RobotoDraft, Helvetica, Arial, sans-serif;
        font-size: 0.875rem;
        letter-spacing: 0.2px;
        font-weight: 500;
        color: white;
        max-width: calc(100% - 10px);
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    .emailBoxHeaderClose {
      pointer-events: initial;

      svg {
        fill: #fff !important;
      }
    }

    .emailBoxHeaderMaximaze {
      pointer-events: initial;
      margin-right: 20px;
      fill: white;

      @media screen and (max-width: 768px) {
        margin-right: 0px;
      }
    }
  }
}

.EmailBoxContainer {
  height: 600px;
  width: 100%;
  max-width: 580px;
  width: 580px;
  background-color: white;
  border-top-right-radius: 12px;
  margin-left: 10px;
  margin-right: 10px;
  border-top-left-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  transition: height 1s;
  position: absolute;
  bottom: 0;
  right: 55px;
  border-right: 1px solid #e0e0e0;
  border-left: 1px solid #e0e0e0;
  overflow: hidden;
  z-index: 10;

  &.maximize-width {
    max-width: 100%;
    border-bottom: 1px solid #e0e0e0;
  }

  .footer-toolbar {
    display: flex;
    align-items: center;
  }

  &.second {
    right: 40%;
  }

  @media only screen and (max-width: 767px) {
    right: 0px;
    margin: 0px;
    width: 100%;
    top: 0px;

    .footerRightPart {
      position: absolute;
      bottom: 12px;
      right: 200px;
    }

    .footerLeftPart {
      padding-left: 0px !important;
    }
  }

  @media screen and (max-width: 810px) and (min-width: 767px) {
    right: -9px;
    max-width: 99.9%;

    .footerRightPart {
      margin-right: 80px !important;
    }
  }

  //  .badgeStyle {
  //   position: absolute;
  //   top: 3px;
  //   right: 10px;
  //   transform: translate(84%, -60%);
  //   transform-origin: 100% 0%;
  // }
  .emailBoxHeader {
    cursor: pointer;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    height: 40px;
    width: inherit;
    background-color: #404040;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px 0 10px;

    .titleContainer {
      display: flex;
      gap: 10px;
      align-items: center;
      width: calc(100% - 60px);

      @media screen and (max-width: 810px) and (min-width: 767px) {
        width: calc(100% - 100px) !important;
      }

      .emailBoxHeaderTitle {
        -webkit-font-smoothing: antialiased;
        font-family: Roboto, RobotoDraft, Helvetica, Arial, sans-serif;
        font-size: 0.875rem;
        letter-spacing: 0.2px;
        font-weight: 500;
        color: white;
        max-width: calc(100% - 10px);
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .ant-spin-spinning {
        position: static;
        display: inline-block;
        opacity: 1;
        color: white;
        display: flex;
        gap: 10px;
      }

      .ant-spin-dot-item {
        background-color: white;
      }
    }

    .emailBoxHeaderClose {
      @media screen and (max-width: 810px) and (min-width: 767px) {
        margin-right: 20px !important;

        .emailBoxHeaderMaximaze {
          display: none;
        }
      }

      svg {
        fill: #fff !important;
      }
    }

    .emailBoxHeaderMaximaze {
      margin-right: 20px;
      fill: white;

      @media screen and (max-width: 810px) and (min-width: 767px) {
        display: none !important;
      }
    }
  }

  .emailBoxContent {
    height: calc(100% - 90px);
    width: inherit;
    display: flex;
    flex-direction: column;
    padding: 10px;
    text-align: left !important;

    .email-box-header-inputs {
      display: flex;
      flex-direction: column;
      padding: 0px 8px;
      border-bottom: 1px solid #d9d9d9;

      @media only screen and (max-width: 768px) {
        padding: 0px 4px;
        border-bottom: 1px solid #d9d9d9;
      }

      .top-inputs {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .to-input-container {
          width: 100%;
        }
      }

      .bottom-inputs {
        display: flex;
        flex-direction: column;

        .cc-container,
        .bcc-container {
          display: flex;
          align-items: center;
          width: 100%;
          gap: 10px;

          .cc-input {
            width: 100%;
          }

          .remove-icon-container {
            width: 10%;
            display: flex;
            justify-content: center;

            .remove-cc-label,
            .remove-bcc-label {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 20px;
              width: 20px;
              border-radius: 50%;
              border: 1px solid #e0e0e0;
              cursor: pointer;
            }
          }
        }
      }
    }

    .subject-container {
      border-bottom: 1px solid #d9d9d9;
    }

    .ccBccOptions {
      display: flex;
      justify-content: flex-end;
      width: 20%;
    }

    .removeCC {
      cursor: pointer;
      position: relative;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      border: 1px solid #cfcfcf;
      left: calc(100% - 40px);
      top: -27px;
      margin-bottom: -20px;

      .removeCcLabel {
        margin-top: -3px;
        font-size: 20px;
      }
    }

    .ant-select-arrow {
      display: none;
    }

    .ant-form-item-has-success {
      margin: 0px;
      padding: 0px;
    }

    .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
      border-color: white;
      border-right-width: 0px !important;
    }

    .ant-select-focused:not(.ant-select-disabled).ant-select:not(
        .ant-select-customize-input
      )
      .ant-select-selector {
      border-color: white;
      border-right-width: 0px !important;
      outline: 0;
      box-shadow: none;
    }

    .ant-select-selection-placeholder {
      padding: 0;
      line-height: 35px;
      transition: all 0.3s;
    }

    .ant-select-selection-search-input {
      height: 35px !important;
    }

    .ant-form-item {
      // max-height: 36px;
      margin-bottom: 0px !important;
    }

    .ant-select-focused:not(.ant-select-disabled).ant-select:not(
        .ant-select-customize-input
      )
      .ant-select-selector {
      border-color: white;
      border-right-width: 0px !important;
      outline: 0;
      box-shadow: none;
    }

    .emailBoxCC {
      cursor: pointer;
      margin-right: 10px;

      &:hover {
        text-decoration: underline;
      }
    }

    .ant-select-multiple {
      // select border bottom

      // border-bottom: 1px solid #cfcfcf;
    }

    .emailBoxTo {
      position: relative;
      width: calc(100% - 30px);
      margin: 0 15px 0 15px;
      border-bottom: 1px solid #cfcfcf;
    }

    .emailBoxBcc {
      position: relative;
      width: calc(100% - 30px);
      margin: 0px 15px 0px 15px;
      border-bottom: 1px solid #cfcfcf;
    }

    .emailBoxSubject {
      position: relative;
      width: calc(100% - 30px);
      margin: 0px 15px 0px 15px;
      // border-bottom: 1px solid #cfcfcf;
    }

    .emailBoxTo:focus {
      box-shadow: none;
    }

    .emailBoxBcc:focus {
      box-shadow: none;
    }

    .ant-select-selector {
      border: 0px solid #d9d9d9 !important;
    }

    //Rich text style
    .emailBoxEditorContainer {
      position: relative;

      .tox .tox-toolbar-overlord {
        background-color: #fff;
        box-shadow: 1px 0px 4px -1px #000000;
        margin: 8px;
      }

      .tox-menubar {
        display: none;
      }

      .tox-editor-header {
        position: absolute;
        z-index: 1 !important;
        bottom: -6px;

        .tox .tox-menubar + .tox-toolbar,
        .tox .tox-menubar + .tox-toolbar-overlord .tox-toolbar__primary {
          display: flex;
          border: 1px solid #ccc;
          margin-top: -1px;
        }
      }

      .tox-statusbar {
        display: none;
      }

      .tox-tinymce {
        border: 0px solid white;
        height: inherit !important;
      }
    }

    .emailBoxEditorContainerWithoutToolbar {
      overflow-y: auto;

      .tox-menubar {
        display: none;
      }

      .tox-editor-header {
        display: none;
      }

      .tox-statusbar {
        display: none;
      }

      .tox-tinymce {
        border: 0px solid white;
        height: inherit !important;
      }
    }

    //footer
  }

  .emailBoxContentWithCC {
    @extend .emailBoxContent;

    .tox-tinymce {
      border: 0px solid white;
    }

    //footer
    .emailBoxFooter {
      bottom: 0px;
      position: absolute;
      height: 60px;
      width: 100%;
      display: flex;
      justify-content: space-between;

      .footerLeftPart {
        display: flex;
        align-items: center;
        padding-left: 10px;

        .sendButtonEmailBox {
          width: 100px !important;

          .ant-btn:hover,
          .ant-btn:focus {
            color: white;
            border-color: white;
          }

          .mondayButtonDivider {
            display: none;
          }
        }
      }
    }
  }

  .emailBoxFooter {
    bottom: 0px;
    position: absolute;
    height: 60px;
    width: 580px;
    display: flex;
    justify-content: space-between;

    .footerLeftPart {
      display: flex;
      align-items: center;
      padding-left: 10px;

      .ant-upload-list-item-card-actions-btn {
        opacity: 1;
      }

      .ant-upload-list-item-container {
        .ant-upload-list-item {
          .ant-upload-list-item-name {
            cursor: pointer !important;
            max-width: 200px;
            overflow: hidden;
            text-overflow: ellipsis;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }

      .sendButtonEmailBox {
        width: 100px !important;
        background-color: #1a73e8;

        &:disabled {
          background-color: #1a73e8ce !important;
          color: white !important;
        }

        .ant-btn:hover,
        .ant-btn:focus {
          color: white;
          border-color: white;
        }

        .mondayButtonDivider {
          display: none;
        }
      }

      .emailBoxFooterIconContainer {
        width: 25px;
        height: 25px;
        padding-bottom: 5px;
        padding-left: 4px;
        margin-left: 15px;

        .ant-upload-wrapper .ant-upload-list::before {
          display: none !important;
        }

        .ant-scroll-number.ant-badge-count {
          position: absolute;
          top: 3px;
          right: 10px;
          transform: translate(84%, -60%);
          transform-origin: 100% 0%;
        }

        .ant-select-single:not(.ant-select-customize-input)
          .ant-select-selector {
          width: 10px;
          height: 32px;
          padding: 0 11px;
        }

        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
          position: relative;
          background-color: #fff;
          opacity: 0;
          left: -10px;
          top: -3px;
          border: 1px solid #d9d9d9;
          border-radius: 2px;
          z-index: 2;
          transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        }

        .ant-select-arrow {
          opacity: 0;
        }

        .uploadDocumentsClassNone {
          .ant-upload-list-text-container {
            display: none;
          }
        }

        .uploadDocumentsClass {
          .ant-upload-list-text-container {
            display: block;
          }

          .ant-upload-span {
            cursor: pointer;
          }
        }

        .costomizeRichTextIcon {
          max-width: 16px;
          max-height: 16px;
          fill: #404040;
          margin-top: -7px;
          cursor: pointer;
        }

        .attachIconTemplates {
          @extend .costomizeRichTextIcon;

          svg {
            width: 20px;
            height: 20px;
          }

          position: relative;
          top: -30px;
          left: 2px;
          z-index: 1;
        }

        .attachIcon {
          @extend .costomizeRichTextIcon;
          width: 20px;
          height: 20px;
          margin-left: -2px;
        }

        .linkIcon {
          @extend .costomizeRichTextIcon;
          transform: rotate(45deg);
        }

        &:active {
          background-color: rgba(32, 33, 36, 0.122);
          border-radius: 5px;
        }
      }
    }

    .footerRightPart {
      @extend .footerLeftPart;
      margin-right: 10px;

      .documentsStyle {
        @extend .costomizeRichTextIcon;
        cursor: pointer;
        margin-right: 20px;
        user-select: none;
        margin-top: 5px;

        &:active {
          background-color: rgba(32, 33, 36, 0.122);
          border-radius: 5px;
        }
      }

      .removeIcon {
        @extend .costomizeRichTextIcon;
        cursor: pointer;
        margin-right: 20px;
      }
    }
  }

  .ant-upload-list-item {
    justify-self: center;
    align-self: center;
  }

  .ant-upload-list-item-info {
    height: unset !important;
    padding: unset !important;
    transition: unset !important;
  }

  .ant-upload-list-item-info > span {
    position: absolute;
    width: 300px;
    height: 100%;
    top: -60px;
    left: -140px;
  }
}

.dropdownClassName {
  width: max-content !important;
  min-width: 150px !important;
}

.ModalMode {
  position: fixed;
  width: auto;
  height: calc(100% - 240px);
  margin: 120px 120px 120px 370px;
  left: 0;
  right: 0;
  top: 0;
  z-index: 3;

  .EmailBoxContainer {
    height: 100%;
    width: 100%;
    border-radius: 12px;
    transition: none;
    position: absolute;

    .emailBoxContent .ccBccOptions,
    .EmailBoxContainer .emailBoxContentWithCC .ccBccOptions {
      position: relative;
      left: -10px;
      justify-content: flex-end;
      display: flex;
    }
  }

  .emailBoxFooter {
    width: 100%;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
  }

  .driveDocumentsStyle {
    display: flex;
    flex-direction: column;
  }
}

.EmailBoxContainerDark,
.EmailBoxContainerMinimazeDark {
  background-color: $cardDarkBackgroundColor;
  border-right: none;
  border-left: none;

  .emailBoxHeader {
    background-color: $cardDarkHeaderColor;
  }

  .emailBoxContent,
  .emailBoxContentWithCC {
    .ant-select {
      .ant-select-selector {
        background-color: $cardDarkBackgroundColor !important;

        .ant-select-selection-overflow {
          .ant-select-selection-item {
            background-color: #333238;

            .ant-select-selection-item-remove svg {
              fill: #fff;
            }
          }
        }

        .ant-select-selection-search-input {
          color: #fff;
        }

        .ant-select-selection-item {
          color: #fff;
        }

        .ant-select-selection-placeholder {
          color: #acacca;
        }
      }

      .ant-select-clear {
        background-color: $cardDarkBackgroundColor;

        svg {
          fill: #acacca;
        }
      }

      .ant-select-arrow svg {
        fill: #fff;
      }
    }

    .emailBoxCC {
      color: #fff;
    }

    .removeCC .removeCcLabel {
      color: #fff;
    }

    .emailBoxEditorContainerWithoutToolbar {
      .tox-edit-area {
        html {
          #tinymce {
            background-color: #12131b !important;
          }
        }
      }
    }
  }

  .emailBoxFooter {
    .footerLeftPart {
      .emailBoxFooterIconContainer {
        svg {
          fill: #fff;
        }
      }

      .attachIcon path {
        stroke: #fff !important;
      }

      .ant-upload-list-item-container {
        .ant-upload-list-item {
          .ant-upload-list-item-name {
            cursor: pointer;
            text-align: center;
            color: #acacca;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }

    .footerRightPart {
      .removeIcon svg {
        fill: #fff;
      }
    }
  }
}

@media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
  .ModalMode {
    margin: 120px 120px 120px 230px;
  }
}
