$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.categoryCostModal {
  width: fit-content !important;
  .ant-modal-content {
    height: calc(100vh - 20px);
    height: calc(100dvh - 20px);
    max-height: 800px;
    width: calc(100vw - 20px);
    width: calc(100dvw - 20px);
    max-width: 1600px;
    .ant-modal-close {
      height: 45px;
      width: 45px;
      .ant-modal-close-x {
        height: 45px;
        width: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          fill: #333238;
        }
      }
    }
    .ant-modal-header {
      height: 45px;
      display: flex;
      align-items: center;
      background-color: #f5f5f7;
      .ant-modal-title {
        font-size: 16px;
        font-weight: 600;
        color: #333238;
      }
    }
    .ant-modal-body {
      height: calc(100% - 100px);
      .cost-dispersion-grid {
        height: 100%;
        width: 100%;
      }
      .ag-root-wrapper {
        // height: 100%;
        // width: 100%;
        // max-width: 100%;

        // .ag-root-wrapper-body {
        //   height: 100% !important;
        // }

        .ag-header {
          position: sticky;
          top: 0;

          .ag-header-cell {
            background-color: #f8f8fa !important;
          }
        }
        .ag-cell-expandable {
          width: 100%;
          justify-content: space-between;
        }
      }

      .ag-header-container,
      .ag-header-row {
        background-color: #32333806;
      }
    }
    .ant-modal-footer {
      display: flex;
      align-items: flex-end;
      .mondayButtonStyle {
        width: 150px;
      }
    }
  }
}

.categoryCostModalDark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      .cost-dispersion-grid {
        .ag-root-wrapper {
          background-color: $cardModalBodyDark;
          border-radius: 10px;
          .ag-details-row {
            background-color: $cardModalBodyDark;
          }
          .ag-header {
            background-color: $cardModalHeaderDark !important;
            .ag-header-row {
              border-top: none;
              border-radius: 10px 10px 0px 0px;
            }
            .ag-header-cell {
              background-color: $cardModalHeaderDark !important;
            }
            .ag-header-cell-resize::after {
              width: 1px !important;
              background-color: #4a4a4a;
            }
          }
          .ag-paging-panel {
            background-color: $cardModalBodyDark;
          }
        }
      }
    }
  }
}
