$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.schedule-driverSelection-wrap {
  overflow: hidden;
}

.schedule-driverSelection-container {
  width: fit-content !important;
  .ant-modal-content {
    height: 70vw;
    height: 70dvh;
    width: 1020px;
    max-width: 85vw;
    max-width: 85dvw;
    .ant-modal-header {
      background-color: #f5f5f7;
      display: flex;
      align-items: center;
      height: 45px;

      .ant-modal-title {
        color: #323338;
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
      }
    }

    .ant-modal-body {
      height: 85%;
      width: 100%;
      max-width: 85vw;
      max-width: 85dvw;
      .schedule-driver-grid {
        height: 80%;
        width: 100%;
        max-width: 90vw;
        max-width: 90dvw;
        margin-bottom: 1rem;

        .ag-root-wrapper {
          // height: 100%;
          // width: 100%;
          // max-width: 100%;
          // overflow: auto;
          // .ag-root-wrapper-body {
          // height: 100% !important;
          // }

          .ag-header {
            position: sticky;
            top: 0;

            .ag-header-cell {
              background-color: #f8f8fa !important;
            }
          }
        }

        .schedule-driver-grid-status {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 80%;
          max-width: 130px;
          border-radius: 7px;
          font-weight: 500;
          color: white;
          letter-spacing: 0.28px;

          .ReadytoWork {
            width: 100%;
            height: 100%;
            border-radius: 7px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #5cbdce;
          }

          .Approved {
            width: 100%;
            height: 100%;
            border-radius: 7px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #7cca00;
          }
        }
      }
    }

    .ant-modal-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      flex-wrap: nowrap;
    }
  }
}

.schedule-driverSelection-container-dark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      .header-search {
        .ant-select {
          .ant-select-selector {
            background-color: $inputModalDark !important;
            .ant-select-selection-search-input {
              color: #fff;
            }
            .ant-select-selection-item {
              color: #fff;
            }
            .ant-select-selection-placeholder {
              color: #acacca;
            }
          }
          .ant-select-clear {
            background-color: $inputModalDark;
            svg {
              fill: #acacca;
            }
          }
          .ant-select-arrow svg {
            fill: #fff;
          }
        }
      }
      .schedule-driver-grid {
        .ag-root-wrapper .ag-header {
          height: 41px !important;
          min-height: 41px !important;
          .ag-header-cell {
            background-color: $cardModalHeaderDark !important;
            padding: 0px var(--ag-cell-horizontal-padding) !important;
            .ag-header-cell-resize::after {
              width: 1px !important;
              background-color: gray !important;
            }
          }
        }
      }
    }
  }
}
