$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.playerModal {
  max-width: 90vw !important;
  max-height: 98vh !important;
  width: unset !important;
  top: 20px !important;

  .ant-modal-header {
    background-color: #f8f8fa;
    .ant-modal-title {
      display: flex;
      flex-direction: row;
      gap: 10px;
      padding-right: 60px;

      .title {
        flex-grow: 1;
      }
    }
  }

  .ant-modal-close-x {
    justify-content: center;
    align-items: center;
    display: flex;
    height: 45px;
    width: 45px;
    padding: 0;
    svg {
      width: 15px;
      height: 15px;
      margin-top: 12px;
    }
  }

  .ant-modal-body {
    .videoModal-container {
      display: flex;
      flex-direction: row;
      gap: 16px;
      height: 80vh !important;
      padding-top: 0px !important;
      overflow-y: auto;

      .containerOpenModal {
        flex: 3;
        max-height: 100% !important;
        display: flex;
        flex-direction: column;
        gap: 10px;
        overflow-y: auto;
      }

      .transcript-container {
        flex: 3;
      }
    }

    .videoModal-container-tablet {
      display: flex;
      flex-direction: column;
    }
  }

  .ant-modal-footer {
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;

    .right-buttons {
      display: flex;
      flex-direction: row;
      gap: 10px;
    }

    .requestEmailBox {
      .EmailBoxContainer {
        height: 600px;
      }
      //   // height: fit-content;
      //   display: flex;
      //   align-items: flex-start;
      //   justify-content: flex-start;
    }
  }

  @media (max-width: 990px) {
    .ant-modal-body {
      flex-direction: column !important;
      .containerOpenModal {
        margin-left: unset !important;
        width: 100% !important;
        p.newVideosCategoryText {
          display: unset !important;
        }
      }
      .reactPlayerDiv {
        width: 100% !important;
        height: 500px !important;
      }
    }
  }
}

.playerModalDark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalBodyDark;
      .ant-modal-title {
        &::after {
          background-color: #fff;
        }
        span {
          color: #fff;
        }
      }
    }
    .ant-modal-body {
      .containerOpenModal {
        .videoCard-container {
          background-color: $inputModalDark;
          .newVideosCategoryInfoSmallconts span {
            color: #fff;
          }
        }
      }
    }
  }
}

.playerModal-drawer {
  .ant-drawer-wrapper-body {
    .ant-drawer-body {
      overflow: hidden;
      padding: 10px;
      position: relative;

      .playerModal-drawer {
        display: flex;
        flex-direction: column;
      }
    }
  }
  .ant-drawer-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .right-buttons {
      display: flex;
      flex-direction: row;
      gap: 8px;
    }
  }
}

.playerModal-drawerDark {
  .ant-drawer-header {
    background-color: $cardModalHeaderDark;
    color: #fff;
  }

  .ant-drawer-body {
    background-color: $cardModalBodyDark;
  }

  .ant-drawer-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: $cardModalHeaderDark;

    .right-buttons {
      display: flex;
      flex-direction: row;
      gap: 8px;
    }
  }
}
