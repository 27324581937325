.rowData-grid {
  width: 100%;
  height: 100%;
  overflow: auto;

  .HeaderContentWrapper {
    .HeaderContentWrapper-header {
      .header-left {
        .labeledInputContainer {
          min-width: 30%;
        }
      }
    }

    .HeaderContentWrapper-content {
      display: flex;
      flex-direction: column;

      .agGridTable {
        height: 100%;
        flex: 1;

        .ag-paging-panel {
          gap: 45px !important;
          .ag-paging-page-summary-panel {
            width: 205px;
          }
        }

        .ag-column-drop {
          .ag-column-drop-title-bar {
            margin-right: 0;
            .ag-column-drop-icon {
              background-image: url("../../../assets/icons/GroupColumnsIcon.svg");
              width: 18px;
              height: 12px;
              &::before {
                display: none;
              }
            }
          }
          .ag-column-drop-list {
            height: 32px;
            width: 20%;
            border-radius: 5px;
            padding: 6.5px 10px;
            background-color: #f5f5f7;
            font-size: 14px;
          }
        }

        .ag-root-wrapper-body {
          .ag-root {
            .ag-body {
              min-height: 100px;
              .ag-row-hover {
                &::before {
                  background-color: #f1f5fd;
                }
              }
              .highlighted-row {
                background: #f1f5fd;
              }
            }
          }
        }

        .ag-status-bar {
          height: 52px;
          width: 100%;

          .ag-status-bar-right {
            position: relative;
            right: 205px;
          }
        }
      }
    }
  }

  .HeaderContentWrapper-dark {
    .HeaderContentWrapper-content {
      .ag-root-wrapper-body {
        .ag-root {
          .ag-body {
            .highlighted-row {
              background: #1264a3 !important;
            }
          }
        }
      }
    }
  }
}
