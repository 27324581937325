.new-add-item-text-card {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  padding-top: 10px;

  textarea {
    height: 130px !important;
    resize: none;
  }
}
