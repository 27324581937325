@import "../../../../../../../../../index.scss";

.new-price-scheme-section {
  @include flex(center, center);
  gap: 5px;
  padding: 0 5px;

  .ant-btn {
    border-radius: 5px;
  }

  .ant-input {
    border: 0 !important;
    border-radius: 5px !important;
    background-color: #f4f5f6 !important;
  }
}

.price-scheme-selector {
  min-width: 200px;

  .ant-select-selector {
    height: 32px !important;
    border-radius: 5px !important;
    background-color: #1264a3 !important;
    color: #fff !important;
  }

  .ant-select-arrow {
    color: #fff;
  }
}

.price-selector-config-label {
  @include flex(center, space-between);
}
