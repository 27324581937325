// Make this full screen antd

// FULL SCREEN ANTD MODAL
.docuseal-modal {
  top: 0 !important;
  padding: 0 !important;
  width: 100% !important;
  height: 100dvh !important;
  margin: 0;
  max-width: 100%;
  overflow: hidden;
  &::before {
    display: none !important;
  }
  .ant-modal-content {
    border-radius: 0;
    width: 100%;
    height: 100dvh;
    .ant-modal-header {
      border-radius: 0 !important;
      background-color: #f5f5f7;
      // padding: 20px;
      display: flex;
      align-items: center;
      height: 45px;

      .ant-modal-title {
        color: #323338;
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        width: 100%;

        .titleContainer .buttons .settingsIcon {
          height: 22px;
          width: 22px;

          cursor: pointer;
        }
      }
    }

    .ant-modal-close {
      height: 45px;
      aspect-ratio: 1;
    }

    .ant-modal-body {
      height: calc(100vh - 119px);
      width: 100%;
      padding: 10px 10px 0 10px !important;
      padding: 0;
      .stepper-section {
        margin-bottom: 1rem;
      }

      .stepper-section-mobile {
        .ant-form-item {
          margin-bottom: 0;
        }
      }
    }
    .ant-modal-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      flex-wrap: nowrap;
      button {
        width: 150px;
      }
      .buttonContainer {
        display: flex;
        flex-direction: row;
        gap: 10px;
        .safety-notes-button {
          border-radius: 5px;
          background-color: #1264a3;
          // color: white !important;
          padding: 5px 5px 0 5px;
          span {
            color: white !important;
            align-items: center;
          }
        }
      }
      .previewButton {
        width: 36px !important;
        .mondayButtonText,
        .mondayButtonDivider {
          display: none !important;
        }
      }
    }
  }
}
