@use "/src/index.scss" as index;

.payroll-live-sidebar {
  transition: 0.3s ease-out;
  border-right: 1px solid #dee2eb;
  &.open-sidebar {
    width: 200px !important;
    min-width: 200px !important;
  }
  &.closed-sidebar {
    width: 50px !important;
    min-width: 50px !important;
  }
  .sidebar-wrapper {
    display: flex;
    .sidebar-navigator {
      width: 100%;
      display: flex;
      flex-direction: column;
      .navigation-item {
        height: 51px;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 0px 5px;
        border-bottom: 1px solid #dee2eb;
        cursor: pointer;
        transition: 0.2s ease-out;
        &:hover {
          background-color: #e6e9ef;
        }
        &.active {
          background-color: #e6e9ef;
        }
        .navigation-icon {
          height: 51px;
          width: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            height: 23px;
            width: 23px;
            fill: #323338 !important;
          }
        }
        .navigation-title {
          font-size: 14px;
          text-align: center;
          overflow: hidden;
          text-wrap: nowrap;
        }
      }
    }
  }
}

.payroll-live-sidebar-dark {
  background-color: index.$cardDarkBackgroundColor;
  border-right: 1px solid #616481;
  .sidebar-wrapper .sidebar-navigator {
    .navigation-item {
      border-bottom: 1px solid #616481;
      color: #fff;
      .navigation-icon {
        svg {
          fill: #fff !important;
        }
      }
      &:hover {
        background-color: index.$inputDarkColor;
      }
      &.active {
        background-color: index.$inputDarkColor;
      }
    }
  }
}
