@import "../../../../index.scss";

.basePageControlPanel {
  @include square(100%, fit-content, 14px);
  margin-bottom: 30px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

.basePageControlPanelHeader {
  @include flex(center, space-between);
  padding: 20px;

  button {
    background-color: #4791ff;
    border-color: #4791ff;
    color: #fff;
    height: 40px;
    text-transform: capitalize;
    box-shadow: 2px 2px 2px #00000012;
    border-radius: 4px;
    margin-left: 20px;
  }
}

.basePageStatusCardsContainer {
  display: flex;
  padding-bottom: 20px;
}

.basePageStatusCard {
  text-align: center;
  @include flex(center, center, column);
  @include text(16px, 600, 0px, #fff, uppercase);
  cursor: pointer;
  user-select: none;
  padding: 10px 0 15px 0;

  span {
    font-size: 35px;
    font-weight: 700;
    margin-bottom: -10px;
  }
}

.basePageStatusCard:hover {
  opacity: 0.8;
}

.basePageControlPanelHeaderButtonsContainer {
  img {
    cursor: pointer;
    padding: 0 10px;
  }
}

.basePageFilterContainer {
  @include flex(flex-end, space-between);
  margin: 0 20px;
  padding: 20px 0;
  border-top: 1px solid #dfdfdf;

  .ant-form-item {
    margin: 0;
    min-width: 150px;
    height: 40px !important;
  }

  .ant-picker {
    background-color: #f8f8f8 !important;
    margin-right: 20px;
    min-width: 150px;
    max-width: 200px;
    height: 40px !important;
    cursor: pointer;

    input {
      cursor: pointer;
    }
  }

  .ant-picker-suffix {
    @include flex(center, center);
    @include square(30%, 38px, 0, #dfdfdf);
    color: #383554;
    margin-top: -11px;
    margin-bottom: -11px;
    margin-right: -11px !important;
    border-radius: 0;
    opacity: 1;
    background-color: #dfdfdf;
    color: #383554;
  }

  .ant-select-selector {
    background-color: #f8f8f8 !important;
    height: 40px !important;
  }

  .ant-select-arrow {
    @include flex(center, center);
    @include square(22%, 38px, 0, #dfdfdf);
    margin-top: -20px;
    margin-right: -10px;
    color: #383554;
  }

  .ant-select-selection-item {
    padding-right: 30px !important;
    @include flex(center, flex-start);
  }
}
.selectFilterContainer {
  padding-right: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
  border-right: 1px solid #dfdfdf;
}
.singleDateFilterContainer {
  padding-right: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
  border-right: 1px solid #dfdfdf;
}

.datePickerContainer {
  display: flex;
  padding-left: 10px;
  padding-right: 0px;
  margin-right: 10px;
  border-left: 1px solid #dfdfdf;
  border-right: 1px solid #dfdfdf;
}

.dateFilterContainer {
  @include flex(flex-end, unset);
  margin-bottom: 20px;
  margin-right: 10px;
}
