$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.add-widget-modal {
  .ant-modal-content {
    .ant-modal-close {
      height: 45px;
      width: 45px;
      .ant-modal-close-x {
        height: 45px;
        width: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .ant-modal-header {
      height: 45px;
      display: flex;
      align-items: center;
      background-color: #f8f8fa;
      .ant-modal-title {
        font-size: 16px;
        font-weight: 600;
      }
    }
    .ant-modal-body {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }

  &.dark-mode {
    .ant-modal-content {
      background-color: $cardModalBodyDark;
      .ant-modal-close-x svg {
        fill: #fff;
      }
      .ant-modal-header {
        background-color: $cardModalHeaderDark;
        .ant-modal-title {
          color: #fff;
        }
      }
      .ant-modal-body {
        .antCustomStepper {
          .ant-steps {
            .ant-steps-item-finish ::before,
            .ant-steps-item-active::before,
            .ant-steps-item::before {
              border-left-color: $cardModalBodyDark;
            }
            .ant-steps-item-wait {
              background-color: $cardModalHeaderDark;
              &::after {
                border-left-color: $cardModalHeaderDark;
              }
              .ant-steps-item-content .ant-steps-item-title {
                color: #acacac;
              }
            }
          }
        }
        .labeledInputLabel {
          color: #fff;
        }
        .ant-input-affix-wrapper {
          background-color: $inputModalDark !important;
          .ant-input {
            background-color: $inputModalDark !important;
            color: #fff;
            &::placeholder {
              color: #acacca;
            }
          }
          .ant-input-suffix svg {
            fill: #fff;
          }
        }
      }
    }
  }
}
