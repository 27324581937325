.rentalMainHeaderDarkMode {
  width: stretch;
  height: 50px;
  display: flex;
  background-color: #12131b;
  justify-content: space-between;
  overflow-y: hidden;
  overflow-x: hidden;
  white-space: nowrap;
  transition: background-color 0.3s ;

  .leftSideContainerRentalMainHeader {
    width: fit-content;
    height: 50px;
    display: flex;
    align-items: center;

    .tittleContainer {
      font-size: 18px;
      color: white;
      margin: 0 15px;
    }

    .darkModeContainer {
      margin-left: 20px;
      .firstSquare {
        background-color: white;
      }
      .secondSquare {
        background-color: white;
      }
      .innerCircle {
        background-color: white;
      }
      .outerCircle {
        background-color: #12131b;
      }
    }
  }
  .rightSideContainerRentalMainHeader {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor:pointer;
  }
}

.rentalMainHeaderLightMode {
  width: stretch;
  height: 50px;
  display: flex;
  background-color: #1264a3;
  justify-content: space-between;
  overflow-y: hidden;
  overflow-x: hidden;
  white-space: nowrap;
  transition: background-color 0.3s ;

  .leftSideContainerRentalMainHeader {
    width: fit-content;
    height: 50px;
    display: flex;
    align-items: center;

    .tittleContainer {
      font-size: 18px;
      color: white;
      margin: 0 15px;
    }
 
    .darkModeContainer {
      margin-left: 20px;
      .firstSquare {
        background-color: white;
      }
      .secondSquare {
        background-color: white;
      }
      .innerCircle {
        background-color: white;
      }
      .outerCircle {
        background-color: #1264a3;
      }
    }
  }
  .rightSideContainerRentalMainHeader {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
