$mainDarkBackgroundColor: #20212d;
$cardDarkHeaderColor: #2a2b3a;
$cardDarkBackgroundColor: #12131b;
$inputDarkColor: #2a2b3a;

.inspection-days-overview-modal {
  min-width: 100%;
  top: 0 !important;
  margin: 0 !important;
  padding-bottom: 0 !important;

  .ant-modal {
    width: 100vw !important;
    max-width: 100vw !important;
    height: 100vh !important;
  }
  .ant-modal-header {
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
    background: #1f2a44 !important;
    border-radius: 0px;
  }
  .ant-modal-title {
    display: flex;
    align-items: center;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #ffffff !important;
  }
  .ant-modal-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 52px;
    background: #ffffff;
  }

  .ant-modal-body {
    padding: 0px !important;
    height: 100%;
    background-color: #f4f5f6 !important;
  }
  .ag-header {
    height: 48px !important;
    min-height: 48px !important;
    .ag-header-cell {
      font-size: 16px;
      font-weight: 600;
      padding-left: var(--ag-cell-horizontal-padding) !important;
      padding-right: var(--ag-cell-horizontal-padding) !important;
      .ag-header-cell-resize {
        &::after {
          border: 1px solid grey;
        }
      }
    }
  }
  .ag-body {
    height: 100% !important;
    overflow: auto !important;
    background-color: #f4f5f6 !important;
  }
}

.inspection-days-overview-modal-dark {
  .ant-modal-content {
    background-color: $cardDarkBackgroundColor;
  }
  .ant-modal-header {
    background-color: #101c35 !important;
  }
  .ant-modal-body {
    background-color: $cardDarkBackgroundColor !important;
    .ant-select {
      .ant-select-selector {
        background-color: $cardDarkBackgroundColor !important;
        .ant-select-selection-search-input {
          color: #fff;
        }
        .ant-select-selection-item {
          color: #fff;
        }
        .ant-select-selection-placeholder {
          color: #acacca;
        }
      }
      .ant-select-clear {
        background-color: $cardDarkBackgroundColor;
        svg {
          fill: #acacca;
        }
      }
      .ant-select-arrow svg {
        fill: #fff;
      }
    }
    .ant-picker {
      background-color: $cardDarkBackgroundColor !important;
      .ant-picker-input {
        background-color: $cardDarkBackgroundColor;
        input {
          color: #f2f2f2;
          &::placeholder {
            color: #acacca;
          }
        }
        .ant-picker-suffix svg {
          color: #acacca;
        }
      }
    }
  }
  .ant-modal-footer {
    background-color: $cardDarkBackgroundColor;
  }
}
