.task-reminder-edit {
  .reminder-text {
    margin-bottom: 0;
    margin-top: 20px;
    font-size: 1rem;
    span {
      font: normal normal 600 16px Open Sans;
    }
    svg {
      width: 14px;
      fill: #ea3d3de3;
    }
  }
  .remindMe-update {
    .ant-form-item-control-input {
      width: 100%;
    }
    display: flex;
    .ant-checkbox {
      top: 0;
    }
    .ant-checkbox-inner::after {
      width: 10px;
      height: 13px;
      left: 32%;
    }
    .ant-checkbox-inner {
      // width: 30px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .modal__label {
      padding: 0;
      margin-top: 10px;
      display: flex;
      flex-direction: column;
    }
    .ant-input-group-addon {
      height: 32px;
      padding: 0px;
      width: 35%;
    }
    .ant-select {
      width: 100%;
      // padding: 0px;
      .ant-select-selection-placeholder {
        color: #c7c7c7;
        font-weight: normal;
      }
      .ant-select-selector {
        border: none;
        min-height: 30px;
        // padding: 0px;
        border-radius: 5px;
        background-color: #f5f5f7;
        width: 100%;
      }

      .ant-select-arrow {
        svg {
          width: 10.78px;
        }
      }
    }
    .ant-picker {
      width: 100%;
      border: none;
      border-radius: 5px !important;
      background-color: #f5f5f7 !important;
      height: 32px;
      padding: 0px 11px !important;
      input {
        height: 32px;
        color: #c7c7c7 !important;
        &::placeholder {
          color: #c7c7c7;
          font-weight: normal;
        }
      }
      .ant-picker-input {
        height: 32px;
      }
      .ant-picker-suffix {
        svg {
          width: 14px;
        }
      }
    }
  }
}
