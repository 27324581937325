@import "/src/index.scss";

.charts-pdf-export {
  height: calc(100vh - 20px) !important;
  height: calc(100dvh - 20px) !important;
  width: calc(100vw - 20px) !important;
  width: calc(100dvw - 20px) !important;
  max-height: 750px;
  max-width: 1200px;
  .ant-modal-content {
    height: calc(100vh - 20px) !important;
    height: calc(100dvh - 20px) !important;
    width: calc(100vw - 20px) !important;
    width: calc(100dvw - 20px) !important;
    max-height: 750px;
    max-width: 1200px;
    .ant-modal-close {
      height: 45px;
      width: 45px;
      .ant-modal-close-x {
        height: 45px;
        width: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .ant-modal-header {
      height: 45px;
      display: flex;
      align-items: center;
      background-color: #f4f5f6;
    }
    .ant-modal-body {
      height: calc(100% - 100px);
      overflow-y: auto;
      overflow-x: hidden;
      .pdf-selection {
        height: 100%;
        width: 100%;
        display: flex;
        gap: 10px;
        .charts-selection,
        .preview-section {
          height: calc(100% - 0px);
          width: 50%;
        }
        .charts-selection {
          display: flex;
          flex-direction: column;
          gap: 5px;
          overflow-y: auto;
          overflow-x: hidden;
          .choose-size-section {
            width: 100%;
            display: flex;
            align-items: center;
            gap: 10px;
            margin-bottom: 5px;
            .ant-form-item {
              margin-bottom: 0px;
            }
          }
          .report-check {
            background: linear-gradient(to right, #f4f5f6, #fff);
          }
        }
        .preview-section {
          display: flex;
          justify-content: flex-end;
          .preview-frame {
            height: 100%;
            width: calc(87%);
          }
        }
      }
    }
    .ant-modal-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .mondayButtonStyle {
        width: 150px;
      }
    }
  }
}

.charts-pdf-export-dark {
  .ant-modal-content {
    background-color: $mainDarkBackgroundColor;
    .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardDarkBackgroundColor;
      .ant-modal-title {
        color: #ffff;
      }
    }
    .ant-modal-body {
      .pdf-selection {
        .charts-selection {
          .choose-size-section {
            .preview-label {
              color: #fff;
            }
            .ant-radio-group {
              .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-checked) {
                &::before {
                  background-color: #acacca;
                }
                &:hover {
                  color: #1677ff;
                }
                background-color: $cardDarkBackgroundColor;
                border: 1px solid #acacca;
                color: #fff;
              }
            }
          }
          .report-check {
            color: #fff;
            background: linear-gradient(
              to right,
              $cardDarkHeaderColor,
              $mainDarkBackgroundColor
            );
          }
        }
      }
    }
  }
}
