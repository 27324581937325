.responseEvaluatorContainer {
  display: flex;
  align-items: center;
  gap: 10px;

  .responseEvaluatorButtonContainer {
    display: flex;
    gap: 10px;
  }

  .responseMessage {
    font-size: 12px;
  }

  .button {
    font-size: 12px;
    cursor: pointer;
    border: none;
    background: transparent;
    transition: font-weight 0.3s ease, transform 0.3s ease, color 0.3s ease;

    &.buttonYes:hover {
      color: #71cf48;
    }

    &.buttonNo:hover {
      color: #fe4c4a;
    }

    &:hover {
      font-weight: 600;
      transform: scale(1.01);
    }
  }
}

.responseFeedbackMessage {
  font-size: 12px;
  color: #71cf48;
}
