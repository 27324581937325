@import "../../../index.scss";

.customAntdTransfer {
  // width: fit-content;
  height: 100%;
  width: 100%;
  .listContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-y: auto;
    height: calc(100% - 52px);
  }
  .transfersContainer {
    height: 100%;
    @include flex(flex-start, center);
    width: 100%;
    gap: 20px;

    .listContainer {
      @include flex(flex-start, flex-start, column);
      gap: 5px;
    }

    .transferColumn {
      width: 100%;
      height: 100%;
      @include flex(flex-start, flex-start, column);
      min-width: 200px;

      .itemCounter {
        @include text(16px, 600, 0, #323338);
        align-self: center;
      }
      .transferContent {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        background-color: #f8f8fa;
        padding: 10px;
        border-radius: 5px;
        overflow: hidden;
        transition: background-color 0.2s ease-in;
        .labeledInputContainer {
          width: 100%;
          .ant-form-item {
            margin-bottom: 10px;
            .ant-input-affix-wrapper {
              background-color: #fff !important;
              .ant-input {
                background-color: #fff !important;
              }
            }
          }
        }
        .transferItems {
          display: flex;
          flex-direction: column;
          gap: 5px;
          height: 100%;
          overflow: auto;
          padding: 0 3px;
          .custom-checkbox-wrapper {
            background-color: #eceff4;
          }
        }
      }
    }

    .transferIcon {
      @include flex(center, center);
      fill: #323338;
      width: 40px;
      align-self: stretch;

      svg {
        cursor: pointer;
      }
    }
  }

  .transferLabel {
    @extend .itemCounter;
    align-self: flex-start;
    width: fit-content;
  }

  &.dark-mode {
    .transfersContainer {
      .transferColumn {
        .itemCounter {
          color: #fff;
        }
        .transferContent {
          background-color: #2a2b3a !important;
          .labeledInputContainer {
            .ant-form-item {
              .ant-input-affix-wrapper {
                background-color: #12131b !important;
                .ant-input {
                  background-color: #12131b !important;
                  &::placeholder {
                    color: #acacca;
                  }
                }
                .ant-input-suffix svg {
                  fill: #acacca;
                }
              }
            }
          }
          .transferItems {
            background: #2a2b3a !important;
            .custom-checkbox-wrapper {
              background-color: #12131b;
            }
          }
        }
      }

      .transferIcon {
        svg {
          rect {
            fill: #2a2b3a;
          }
          path {
            fill: #fff;
          }
        }
      }
    }
  }
}
