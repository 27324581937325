$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.floors-modal {
  .ant-modal-header {
    display: flex;
    // justify-content: center;
    align-items: center;
    width: "100%";
    background-color: #f5f5f5;
    border-radius: 10px 10px 0px 0px;
    height: 45px;
  }
  .ant-modal-title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
    color: black;
  }
  .ant-modal-footer {
    display: flex;
    justify-content: space-between;
  }
  .ant-modal-body {
    height: 100%;
    max-height: 600px;
    overflow: auto;
  }
  .ant-modal-content {
    width: fit-content;
    height: fit-content;
    max-width: "100%";
    max-height: "100%";
    border-radius: 10px;
  }

  .ant-modal-close-x {
    color: black;
    font-size: 20px;
  }
  .mainContainerFloorsModal {
    display: flex;
    flex-direction: column;
    gap: 8;
    min-width: "20dvw";
    width: "100%";
    max-width: "95dvw";
    overflow: "auto";
    .header-container-floors-modal {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 32px;
      background-color: #f5f5f7;
      padding: 0px 10px 0px 10px;
      border-radius: 5px;
    }
    .floors-modal-container-data {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
    }
    .allJumpsContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      flex-wrap: wrap;
      .inputFloors {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        span {
          padding-top: 4px;
        }
      }
    }

    .input-floors-modal {
      // .ant-input {
      //   width: 80px !important;
      // }
      // .ant-form-item {
      //   margin-bottom: 5px !important;
      //   width: 80px !important;
      // }
    }
  }
}

.floors-modal-dark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      span {
        color: #fff;
      }
    }
  }
}
