.newConversation {
  display: flex;
  flex-direction: column;
  width: 100%;
  .newConversationButton {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 44px;
    background-color: #f4f5f6;
    padding-right: 20px;
    .popupNewConversation {
      .ant-popover-inner {
        border-radius: 10px;
        box-shadow: 0px 0px 10px 0px #0000001a;
      }
      .ant-popover-inner-content {
        padding: 15px !important;
      }
    }
    .newConversationText {
      .button-inner-content {
        gap: 5px;
      }

      .button-inner-content:hover {
        font-weight: 600;
      }
    }
  }
}
