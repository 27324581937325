.linksContainer {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .popoverContentItem {
    padding: 10px 16px;
    cursor: pointer;
    &:hover {
      color: #1264a3;
      background-color: #f1f5fd;
    }
  }
  .popoverContentItem:not(:last-child) {
    border-bottom: 1px solid #efefef;
  }
}

.linksContainerDark {
  background-color: #12131b;
  border-radius: 5px !important;
  .popoverContentItem {
    color: #ffffff;
    &:hover {
      //   color: #1264a3;
      color: #fff;
      background-color: #292b3a;
    }
  }
  .popoverContentItem:not(:last-child) {
    border-bottom: 1px solid #1f2a44;
  }
}
