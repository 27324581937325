.applicationDateAdjustDark {
	.ant-modal {
		// width: 600px;
	}

	.ant-modal-content {
		border-radius: 10px;
		overflow: hidden;
		// width: fit-content;
	}

	.ant-modal-title {
		color: white;
	}

	.ant-modal-close {
		height: 45px;
		width: 45px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.ant-modal-header {
		height: 45px;
		padding: 0 15px;
		display: flex;
		align-items: center;
		background-color: #12131b;
		color: white !important;
		border-bottom: 0;
	}

	.ant-modal-footer {
		display: none;
	}

	.ant-modal-body {
		background-color: #1f212d;
		// width: fit-content;
		// height: auto;
		// display: flex;
		// gap: 50px;
		.application-date-adjust {
			label {
				color: white !important;
			}
			color: white !important;
			.ant-col.ant-form-item-label {
				color: white !important;
			}
		}
		color: white !important;
	}
}
