@import "../../../../index.scss";

$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.clientModalContainer {
  .clientModalBodyContainer {
    max-height: calc(100vh - 185px);
    display: grid;
    grid-template-columns: [full-start] auto auto [full-end];
    column-gap: 15px;
    padding-top: 10px;
    overflow-x: hidden;
    overflow-y: auto;
    .clientBorderedTextCard {
      border: none;
      border-radius: 0;
      border-top: 1px solid #323338;
      // width: max-content;
      max-width: 900px;
      padding: 20px 0 20px;
      align-items: center;

      .cardTitle {
        font-size: 16px;
        color: #323338;
      }
      .clientAvatarsDiv {
        display: flex;
        position: relative;
        gap: 20px;
        .clientAvatars {
          margin-top: 25px;
        }
      }
      .labeledInputContainer {
        height: 70px;
      }
      .employeesAnimation {
        animation: employeesAnimation 0.5s ease;
      }

      .employeesHide {
        display: none;
      }

      .employeesAnimationReverse {
        animation: employeesAnimationReverse 0.5s ease;
        display: block;
      }

      .email-website-animation {
        animation: emailWebsiteAnimation 0.5s ease;
      }

      .email-website-animation-reverse {
        animation: emailWebsiteAnimationReverse 0.5s ease;
      }
      .tox-tinymce {
        height: 140px !important;
        .tox-edit-area {
          height: 90px;
        }
      }
      @media (min-width: 1600px) {
        #accountName {
          width: 400px;
        }
        .ant-select-selector {
          min-width: 285px;
          max-width: 650px;
        }
        #preQualificationDate {
          width: 250px;
        }
        .form-control {
          width: 280px;
        }
        #phoneExtension {
          width: 170px;
        }
        #accountEmail,
        #website {
          width: 200px;
        }
        #preQualificationWebsite {
          width: 280px;
        }
        .membersButton {
          top: 25px;
        }
        .configElementContainer {
          height: 32px;
          width: 200px;
          position: relative;
          top: 30px;
          .configElementTitle {
            font-size: 16px;
          }
          .bluContainer {
            // background-color: #fe4c4a;
            .navyBlueContainer {
              box-shadow: 0 0 3px 3px rgb(255 255 255 / 0.2);
              background-color: #fff;
            }
          }
        }
      }
    }

    .billingBorderedTextCard {
      border: none;
      border-radius: 0;
      border-top: 1px solid #323338;
      margin-left: 30px;
      max-height: 550px;
      align-items: center;
      .cardTitle {
        font-size: 16px;
        color: #323338;
      }
      .billingAndShippingAddressesContainer {
        max-width: 770px;
        .shippingAddIcon {
          right: 6rem !important;
        }
        .copyIconDiv {
          top: 32%;
        }
        .labeledInputContainer {
          height: 70px;
          width: 300px;
          .ant-input {
            height: 32px;
          }
        }
        @media (max-width: 1600px) {
          .shippingAddIcon {
            right: 10px;
          }
        }
        @media (max-width: 1440px) {
          .shippingAddIcon {
            left: 295px;
            top: 460px;
          }
          .copyIconDiv {
            top: 50%;
            left: 30%;
          }
        }
        // @media (max-width: 1181px) {
        //   .shippingAddIcon {
        //     top: 460px;
        //   }
        // }
        @media (max-width: 1181px) {
          .shippingAddIcon {
            top: 460px;
          }
          .copyIconDiv {
            top: 50%;
            left: 30%;
          }
        }
        @media (max-width: 830px) {
          .shippingAddIcon {
            top: 318px;
            left: 270px !important;
          }
          .copyIconDiv {
            top: 45%;
            left: 40%;
          }
        }
        @media (max-width: 480px) {
          .shippingAddIcon {
            top: 532px;
            left: 86% !important;
          }
          .streetViewIcon {
            left: 85%;
          }
          .copyIconDiv {
            top: 49%;
            left: 40%;
          }
        }
      }
    }
  }

  .clientModalBodyContainer-splash {
    grid-column: full;
  }

  .labeledInputContainer {
    margin-right: 15px;

    .labeledInputLabel {
      @include text(14px, 600, 0.21px, #383554);
      margin-bottom: 2px;
    }

    .ant-checkbox-wrapper {
      @extend .labeledInputLabel;
    }
  }
  .newFooterContainer {
    button {
      width: 150px;
    }
  }
}

.clientModalContainerDark {
  .ant-modal-content {
    .ant-modal-body {
      .clientModalBodyContainer {
        .borderedTextCard {
          border-top-color: #fff;
          .cardTitle {
            color: #fff;
            background-color: $cardModalBodyDark;
          }
          .childrenContainer {
            .labeledInputLabel {
              color: #fff;
            }
            .ant-input {
              background-color: $inputModalDark !important;
              color: #fff;
              &::placeholder {
                color: #acacca;
              }
            }
            .ant-select {
              .ant-select-selector {
                background-color: $inputModalDark !important;
                .ant-select-selection-search-input {
                  color: #fff;
                }
                .ant-select-selection-item {
                  color: #fff;
                }
                .ant-select-selection-placeholder {
                  color: #acacca;
                }
              }
              .ant-select-clear {
                background-color: $inputModalDark;
                svg {
                  fill: #acacca;
                }
              }
              .ant-select-arrow svg {
                fill: #fff;
              }
            }
            .react-tel-input {
              .form-control {
                background-color: $inputModalDark !important;
                color: #fff;
                &::placeholder {
                  color: #acacca;
                }
              }
              .flag-dropdown {
                background-color: $inputModalDark;
                border-radius: 5px 0px 0px 5px;
                .selected-flag {
                  &:hover {
                    background-color: $inputModalDark;
                  }
                  &:focus {
                    background-color: $inputModalDark;
                  }
                }
                .arrow {
                  border-top: 4px solid #fff;
                }
              }
              .open {
                background-color: $inputModalDark;
                border-radius: 5px 0px 0px 5px;
                .selected-flag {
                  background-color: $inputModalDark;
                  .arrow {
                    border-bottom: 4px solid #fff;
                    border-top: 4px solid transparent;
                  }
                }
              }
            }
            .ant-picker {
              background-color: $inputModalDark !important;
              .ant-picker-input {
                background-color: $inputModalDark;
                input {
                  color: #f2f2f2;
                  &::placeholder {
                    color: #acacca;
                  }
                }
                .ant-picker-suffix svg {
                  color: #acacca;
                }
                .ant-picker-clear {
                  background-color: $inputModalDark;
                  svg {
                    fill: #acacca;
                  }
                }
              }
            }
            .ant-btn {
              border: none;
            }
            .ant-checkbox-wrapper {
              span {
                color: #fff;
              }
            }
            .configElementContainer {
              background-color: $inputModalDark;
              .configElementTitle {
                color: #fff;
              }
            }
            .copyIconDiv svg path {
              fill: #fff;
            }
            .tox {
              border: none;
              .tox-menubar {
                background-color: $cardModalHeaderDark;
                border-radius: 5px 5px 0px 0px;
                .tox-mbtn__select-label {
                  color: #fff;
                }
              }
              .tox-mbtn--select:hover {
                background-color: $cardModalBodyDark;
              }
              .tox-toolbar-overlord {
                background-color: $cardModalHeaderDark !important;
                .tox-toolbar__primary {
                  background-color: $cardModalHeaderDark !important;
                }
                .tox-toolbar__group {
                  background-color: $cardModalHeaderDark !important;
                  border: none;
                  .tox-split-button:hover {
                    border: none;
                  }
                  .tox-tbtn {
                    svg {
                      fill: #fff;
                    }
                    &:focus {
                      background-color: $cardModalBodyDark;
                    }
                    &:hover {
                      background-color: $cardModalBodyDark;
                    }
                  }
                  .tox-tbtn__select-label {
                    color: #fff !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1440px) {
  .clientModalContainer .clientModalBodyContainer {
    .react-tel-input .form-control {
      width: 250px;
    }
    .billingBorderedTextCard {
      margin-top: 0;
      max-height: 800px;
      .billingAndShippingAddressesContainer {
        width: 400px;
        .splitAddContainer {
          margin-bottom: 3.5rem;
        }

        .businessAddContainer {
          display: grid;
        }
      }
    }
  }
}

@media (max-width: 1000px) {
  .clientModalContainer .clientModalBodyContainer {
    display: block;
  }
}

@media (max-width: 768px) {
  .clientModalContainer {
    padding: 10px 0px !important;
  }
}

@media (max-width: 600px) {
  .clientModalContainer .clientModalBodyContainer {
    .borderedTextCard:first-child {
      .childrenContainer {
        display: grid;
        grid-template-columns: 100%;
        .labeledInputContainer .react-tel-input .form-control {
          width: 100%;
        }
        .formTextEditorContainer {
          width: 100% !important;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .clientModalContainer .clientModalBodyContainer .billingBorderedTextCard {
    margin-left: 0;
    padding-left: 0;
    max-height: 950px !important;
    .billingAndShippingAddressesContainer {
      width: fit-content;
      .splitAddContainer {
        .labeledInputContainer {
          min-width: 100% !important;
          margin-left: 0 !important;
        }
      }
    }
  }
}

@keyframes employeesAnimation {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }
  100% {
    opacity: 0;
    transform: translateY(20px);
  }
}

@keyframes employeesAnimationReverse {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
@keyframes emailWebsiteAnimation {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes emailWebsiteAnimationReverse {
  0% {
    transform: translateX(-40%);
  }
  100% {
    transform: translateX(0%);
  }
}
