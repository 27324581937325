.dateCollisionContainer {
  justify-content: center;
  display: flex;

  .dateCollisionModalButton {
    svg {
      height: 20px;
      width: 20px;
      fill: white;
    }
  }
}

.dateConflictsModalDark {
  .ant-modal {
    width: fit-content !important;
  }

  .ant-modal-content {
    width: fit-content;
    border-radius: 10px;
    overflow: hidden;
  }

  .ant-modal-close {
    .ant-modal-close-x {
      height: 45px;
      width: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      margin: 0;
      svg {
        fill: white;
      }
    }
  }

  .ant-modal-header {
    height: 45px;
    border-bottom: 0;
    background-color: #1f212d;
    display: flex;
    align-items: center;

    .ant-modal-title {
      color: white;
    }
  }

  .ant-modal-footer {
    height: 45px;
    border-top: 0;
    background-color: #1f212d;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .ant-modal-body {
    height: 300px;
    width: 730px;
    color: white;
    background-color: #12131b;
    display: flex;
    flex-direction: column;
    gap: 15px;
    overflow-x: hidden;
    overflow-y: auto;

    // .conflictsContainerDiv {
    // display: flex;
    // gap: 10px;
    // align-items: center;

    .customDateCard {
      width: 115px;
      height: 32px;
      flex-shrink: 0;
      border-radius: 5px;
      background: #12131b;
      box-shadow: 0px 0px 3px 0px #f6cb51;
      color: #fff;
      font-family: Open Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        cursor: pointer;
        background: #f6cb51;
        color: #12131b;
      }
    }

    .messageContainer {
      font: normal normal normal 16px/25px Open Sans;
    }
    .dateRangeContainerGap {
      font: normal normal normal 16px/25px Open Sans;
      color: red;
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      // justify-content: center;

      .iconCOntainer {
        svg {
          height: 22px;
          width: 22px;
        }
      }
    }
    .redirectButton {
    }
  }
  // }
}

.dateConflictsModalLight {
  .ant-modal {
    width: fit-content !important;
  }

  .ant-modal-content {
    width: fit-content;
    border-radius: 10px;
    overflow: hidden;
  }

  .ant-modal-close {
    .ant-modal-close-x {
      height: 45px;
      width: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      margin: 0;
    }
    svg {
      fill: black;
    }
  }

  .ant-modal-header {
    height: 45px;
    border-bottom: 0;
    background-color: #f8f8fa;
    display: flex;
    align-items: center;
  }

  .ant-modal-footer {
    height: 45px;
    border-top: 0;
    background-color: #f8f8fa;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .ant-modal-body {
    height: 300px;
    width: 960px;
    color: black;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    gap: 15px;
    overflow-x: hidden;
    overflow-y: auto;

    .conflictsContainerDiv {
      display: flex;
      gap: 10px;
      align-items: center;

      .iconCOntainer {
        svg {
          height: 22px;
          width: 22px;
        }
      }
      .messageContainer {
        font: normal normal normal 16px/25px Open Sans;
      }
      .dateRangeContainer {
        font: normal normal normal 16px/25px Open Sans;
        color: red;
      }
      .redirectButton {
      }
    }
  }
}
