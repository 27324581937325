.upload-image-step {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;

  .image-holder {
    height: 224px;
    width: 224px;
    background: #f5f5f7;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    .remove-img-btn {
      position: absolute;
      top: 45%;
      right: -11px;
    }
  }
  .file-uploader-container {
    margin-top: -10px;
  }
  &.dark-mode {
    .image-holder {
      background: #2a2b3a;
    }
  }
}

@media (max-width: 1024px) {
  .upload-image-step {
    .camera-icon {
      svg {
        width: 60px !important;
        height: 60px !important;
      }
    }
    .user-icon {
      svg {
        width: 110px;
        height: 110px;
      }
    }
    .image-holder {
      height: 350px;
      width: 350px;
    }
  }
}

@media (max-width: 460px) {
  .upload-image-step {
    .camera-icon {
      svg {
        width: 32px !important;
        height: 32px !important;
      }
    }
    .user-icon {
      svg {
        width: 80px;
        height: 80px;
      }
    }
    .image-holder {
      height: 200px;
      width: 200px;
    }
  }
}
