$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
.driveNavigateModalDark {
  overflow: hidden;
  .ant-tree-list-holder-inner {
    background-color: $cardModalBodyDark !important;
    color: #fff;
  }
  .ant-tree-node-selected {
    background-color: #11394e !important;
  }
  .ant-modal-content {
    // background-color: red;
    overflow: hidden;
    background-color: $cardModalBodyDark;
    color: #fff;
    .treeHolderContainer {
      display: flex;
      flex-direction: row;
      gap: 20px;

      .treeHolderData {
        width: 25%;
        padding: 20px;
        height: 650px;
        overflow-y: scroll;
      }
      .folderHolderContainer {
        padding: 20px;
        width: 75%;
        display: flex;
        flex-direction: row;
        gap: 30px;
        height: 650px;
        overflow-y: scroll;
        flex-wrap: wrap;
        width: 1780px;

        .folderHolderWrapper {
          // background-color: red;
          max-height: 250px;
          .folderHolder {
            display: flex;
            width: 150px;
            padding: 10px;
            // background-color: red;
            &:hover {
              background-color: #11394e;
              border-radius: 10px;
              cursor: pointer;
              color: #fff;
            }
          }
          &:hover {
            background-color: #11394e;
            color: #fff;
            border-radius: 10px;
            cursor: pointer;
          }
        }
        .noFilesFoundContainer {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          color: white;
          .ant-result {
            .ant-result-title,
            .ant-result-subtitle {
              color: white;
            }
          }
        }
      }
      // display: "flex";
      // flex-direction: "row";
      // height: 600px;
      // overflow-y: scroll;
      // width: 1780px;
      // .folderHolderContainer {
      //   //   background-color: yellow;
      //   background-color: #f8f8fa;
      //   display: flex;
      //   flex-flow: wrap;
      //   gap: 30px;
      //   max-width: calc(100vw - 300px);
      //   max-height: calc(100vh - 400px) !important;
      //   .folderHolder {
      //     :hover {
      //       background-color: #a5e888;
      //       border-radius: 10px;
      //       cursor: pointer;
      //     }
      //   }
      // }
    }
    .ant-modal-body {
      //   background-color: green;
      height: 100%;
      // overflow: hidden;
      // max-height: 100vh;
      overflow-y: hidden;
      height: calc(100vh - 200px);

      // minH
    }
    .ant-modal-footer {
      height: 80px;
      // background-color: #fff;
      // border-top: 1px solid #e8e8e8;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: $cardModalHeaderDark;
      .driveNavigateModalFooter {
        .actionsFolderContain {
          nav {
            li {
              color: white;
              &:hover {
                cursor: pointer;
              }
            }
          }
        }
      }
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;
      }
    }
    // .ant-modal-footer {
    //   height: 80px;
    //   background-color: #f8f8fa;
    //   border-top: 1px solid #e8e8e8;
    //   display: flex;
    //   justify-content: space-between;
    //   align-items: center;
    //   .ant-btn {
    //     height: 40px;
    //     width: 100px;
    //     border-radius: 5px;
    //     font-size: 14px;
    //     font-weight: 500;
    //     color: #fff;
    //     background-color: #a5e888;
    //     border: none;
    //     :hover {
    //       background-color: #a5e888;
    //       color: #fff;
    //     }
    //     .ant-btn-default {
    //       // Cancel
    //       background-color: red;
    //       color: #000;
    //       border: 1px solid #e8e8e8;
    //       :hover {
    //         background-color: #f8f8fa;
    //         color: #000;
    //         border: 1px solid #e8e8e8;
    //       }
    //     }
    //   }
    // }
  }

  .driveNavigateModalFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 10px;
    height: 60px;
  }
}
.driveNavigateModal {
  overflow: hidden;

  .ant-modal-content {
    // background-color: red;
    overflow: hidden;
    background-color: #f8f8fa;

    .treeHolderContainer {
      display: flex;
      flex-direction: row;
      gap: 20px;
      .treeHolderData {
        width: 25%;
        padding: 20px;
        height: 650px;
        overflow-y: scroll;
      }
      .folderHolderContainer {
        padding: 20px;
        width: 75%;
        display: flex;
        flex-direction: row;
        gap: 30px;
        height: 650px;
        overflow-y: scroll;
        flex-wrap: wrap;
        width: 1780px;
        .folderHolderWrapper {
          // background-color: red;
          max-height: 250px;
          .folderHolder {
            display: flex;
            width: 150px;
            padding: 10px;
            // background-color: red;
            &:hover {
              background-color: #40a2d8;
              border-radius: 10px;
              cursor: pointer;
              color: #fff;
            }
          }
          &:hover {
            background-color: #40a2d8;
            color: #fff;
            border-radius: 10px;
            cursor: pointer;
          }
        }
        .noFilesFoundContainer {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
        }
      }
      // display: "flex";
      // flex-direction: "row";
      // height: 600px;
      // overflow-y: scroll;
      // width: 1780px;
      // .folderHolderContainer {
      //   //   background-color: yellow;
      //   background-color: #f8f8fa;
      //   display: flex;
      //   flex-flow: wrap;
      //   gap: 30px;
      //   max-width: calc(100vw - 300px);
      //   max-height: calc(100vh - 400px) !important;
      //   .folderHolder {
      //     :hover {
      //       background-color: #a5e888;
      //       border-radius: 10px;
      //       cursor: pointer;
      //     }
      //   }
      // }
    }
    .ant-modal-body {
      //   background-color: green;
      height: 100%;
      // overflow: hidden;
      // max-height: 100vh;
      overflow-y: hidden;
      height: calc(100vh - 200px);

      // minH
    }
    .ant-modal-footer {
      height: 80px;
      background-color: #fff;
      border-top: 1px solid #e8e8e8;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    // .ant-modal-footer {
    //   height: 80px;
    //   background-color: #f8f8fa;
    //   border-top: 1px solid #e8e8e8;
    //   display: flex;
    //   justify-content: space-between;
    //   align-items: center;
    //   .ant-btn {
    //     height: 40px;
    //     width: 100px;
    //     border-radius: 5px;
    //     font-size: 14px;
    //     font-weight: 500;
    //     color: #fff;
    //     background-color: #a5e888;
    //     border: none;
    //     :hover {
    //       background-color: #a5e888;
    //       color: #fff;
    //     }
    //     .ant-btn-default {
    //       // Cancel
    //       background-color: red;
    //       color: #000;
    //       border: 1px solid #e8e8e8;
    //       :hover {
    //         background-color: #f8f8fa;
    //         color: #000;
    //         border: 1px solid #e8e8e8;
    //       }
    //     }
    //   }
    // }
  }

  .driveNavigateModalFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 10px;
    height: 60px;
  }
}
