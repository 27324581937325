// @import "../../../../../index.scss";
$mainDarkBackgroundColor: #20212d;

.NotFoundViewContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: transparent;
  background-color: #f8f9fe;
  .title {
    font-weight: 550;
    font-size: 60px;
    text-align: center;
    letter-spacing: -3px;
    color: #1f2a44;
  }
  .subtitle {
    font-size: 18px;
    text-align: center;
    letter-spacing: 0px;
    color: #c3c3cd;
    opacity: 1;
  }
  .buttonStyle {
    // background: #09a734 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    width: 150px;
    height: 32px;
    .buttonText {
      text-align: left;
      font-size: 18px;
      font-family: "Open Sans";
      letter-spacing: 0px;
      color: #ffffff;
      opacity: 1;
    }
  }
}

.NotFoundViewContainerDark {
  background-color: $mainDarkBackgroundColor;
  .title {
    color: #ffffff;
  }
  .subtitle {
    color: #acacca;
  }
}
