.ul .disabled-title {
  background-color: #f1f1f1;
  padding: 10px;
  white-space: nowrap;
}
.ul {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 5px;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.ul .li {
  padding: 10px;
}

.ul .li:hover {
  background-color: #e8f8f5;
}
