$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.removedAnalyticsModal {
  height: fit-content !important;
  width: fit-content !important;
  .ant-modal-content {
    height: calc(100vh - 20px) !important;
    width: calc(100vw - 20px) !important;
    max-width: 1500px;
    max-height: 700px;
    .ant-modal-close {
      height: 45px;
      width: 45px;
      .ant-modal-close-x {
        height: 45px;
        width: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .ant-modal-header {
      height: 45px;
      display: flex;
      align-items: center;
      background-color: #f8f8fa;
    }
    .ant-modal-body {
      height: calc(100% - 100px);
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding-bottom: 0px;
      .infoSection {
        height: 32px;
        display: flex;
        align-items: center;
        gap: 10px;
      }
      .searchSection {
        .labeledInputContainer {
          max-width: 500px;
          .ant-form-item {
            margin-bottom: 0px;
          }
        }
      }
      .tableWrapper {
        height: calc(100% - 20px);
        border-radius: 10px;
        overflow: hidden;
        .ag-header-row {
          border: none;
        }
      }
    }
    .ant-modal-footer {
      display: flex;
      justify-content: space-between;
      padding: 10px 20px;
    }
  }
}

.removedAnalyticsModalDark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      .infoSection span {
        color: #fff;
      }
      .searchSection {
        .labeledInputContainer {
          .ant-input {
            background-color: $inputModalDark !important;
            color: #fff;
            &::placeholder {
              color: #acacca;
            }
          }
        }
      }
      .tableWrapper {
        .ag-header-cell {
          background-color: $cardModalHeaderDark;
        }
        .ag-side-bar {
          background-color: $cardModalHeaderDark;
        }
      }
    }
  }
}
