$header-height: 45px;
$filters-height: 52px;
$filters-padding: 0px;

.grid-template-card {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
  color: black;
  background-color: #fff;
  max-height: 100%;
  max-width: 100%;
  height: 100%;

  .card-title {
    cursor: default;
    height: $header-height;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    width: 100%;
    background-color: #f5f5f7;
    letter-spacing: 0.18px;
    padding: 0 20px;
  }

  .card-body {
    width: 100%;
    position: relative;
    display: grid;
    grid-template-columns: 100%;
    height: 100%;

    .filters-container {
      height: $filters-height;
      padding: 0px 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      flex-direction: row;
      flex-wrap: nowrap;
      overflow-x: auto;

      .inner-filter-section {
        display: flex;
        align-items: center;
        gap: 10px;
        width: calc(50% - 5px);

        .report-check {
          width: min-content;
          white-space: nowrap;
        }

        &:last-child {
          justify-content: flex-end;
        }

        &:first-child {
          justify-content: flex-start;
        }
      }

      .labeledInputContainer {
        .ant-form-item {
          margin: 0;
        }
      }
    }

    .grid-container {
      height: 100%;

      .ag-root-wrapper-body.ag-layout-normal {
        height: calc(100% - 40px) !important;
        min-height: calc(100% - 40px) !important;
        overflow-y: unset !important;
      }

      .ag-column-drop {
        padding-top: 5px;
        padding-bottom: 5px;
        .ag-column-drop-title-bar {
          margin-right: 0;
          .ag-column-drop-icon {
            background-image: url("../../../../../../../assets/icons/GroupColumnsIcon.svg");
            width: 18px;
            height: 12px;
            &::before {
              display: none;
            }
          }
        }
        .ag-column-drop-list {
          height: 32px;
          min-width: 20%;
          border-radius: 5px;
          padding: 6.5px 10px;
          background-color: #f5f5f7;
          font-size: 14px;
        }
      }

      .ag-paging-panel.ag-unselectable {
        min-height: 50px;
      }
    }
  }

  .card-body.with-title {
    height: calc(100% - $header-height);
  }

  .card-body.with-title.with-filters {
    grid-template-rows: calc($filters-height + $filters-padding) calc(
        calc(100% - $header-height - 10px)
      );
  }

  .card-body.no-title {
    height: 100%;
  }

  .card-body.no-title.with-filters {
    grid-template-rows: calc($filters-height + $filters-padding) calc(
        100% - calc($filters-height + $filters-padding)
      );
  }

  .card-body.no-filters {
    grid-template-rows: 100%;
  }
}

.grid-template-card.grid-template-card-dark {
  color: #fff;
  background-color: #2a2b3a;

  .card-title {
    background-color: #12131b;
  }

  .grid-container {
    .ag-side-bar {
      background-color: #12131b;
    }

    .ag-column-drop {
      background-color: #12131b;

      .ag-column-drop-title-bar {
        .ag-column-drop-icon {
          background-image: url("../../../../../../../assets/icons/GroupColumnsIconDarkMode.svg");
        }
      }

      .ag-column-drop-list {
        background-color: #2a2b3a;
        .ag-column-drop-empty-message {
          color: #acacca;
        }
      }
    }
  }
}
