.chainServiceModal {
  .ant-modal-content {
    border-radius: 10px;
    overflow: hidden;
    background: #fff;
  }
  // antd modal body
  .ant-modal-body {
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: baseline;
    width: 100%;
    // background: red;
    .chainServiceContainer {
      display: flex;
      flex-direction: column;
      gap: 20px;
      .label-text-chain-service {
        font-size: 14px;
        font-weight: 500;
        // color: #000;
        margin-bottom: 10px;
      }
      .animated-toggler-cont {
        margin-bottom: 5px;
      }
      .chainServiceSelect {
        .ant-select.select-service__select.ant-select-single.ant-select-show-arrow.ant-select-show-search {
          border: none;
          .ant-select-selector {
            border: none;
            // border-bottom: 1px solid #000;
            border-radius: 0;
            background-color: #f5f5f7;
            border-radius: 5px;
            // padding: 0;
            height: 30px;
            .ant-select-selection-item {
              font-size: 14px;
              font-weight: 500;

              // font-weight: 700;
              // color: #000;
            }
          }
        }
      }
      .chainServiceAutoSum {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        .toggler {
          margin-top: 5px;
        }
        .animated-toggler-cont {
          padding-bottom: 5px;
        }
        .chainServiceAutoSumInput {
          width: 100%;
        }
        .chainServiceAutoSumSelect {
          width: 100%;
        }
      }
    }
  }
}
