@import "./../../../../index.scss";

.notes-component-wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;

  .ant-collapse {
    border: none;
    background: transparent;
    .ant-collapse-item {
      background: #f8f8fa;
      border-radius: 10px;
      border: none;
      margin: 7px 0;
      height: auto;
      &:last-child {
        border-radius: 10px;
      }
      overflow: hidden;
      .ant-collapse-header {
        display: flex;
        align-items: center;
        font-weight: bold;
      }
      .ant-collapse-content {
        .ant-collapse-content-box {
          .notes-container {
            max-height: 400px;
            overflow: auto;
          }
        }
      }

      .ant-collapse-content-active {
        border-top: none;
      }
      .ant-collapse-extra {
        width: 30px;
        height: 30px;
        background: #1264a3;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        border-radius: 5px;
        align-self: center;
      }
      .ant-collapse-expand-icon {
        align-self: center;
      }
    }
  }
}

.actionsDropdown-dark {
  .ant-dropdown-menu {
    background-color: $cardDarkHeaderColor;
    .ant-dropdown-menu-title-content {
      color: #fff;
    }
  }
}
