.LongTextBodyEdit {
  display: flex;
  flex-direction: column;
  padding: 20px 20px 20px 0px;
  .InformationBodyElementContainerTitle {
    text-align: left;
    margin-bottom: 10px;
    font: normal normal normal 20px/27px Open Sans;
    letter-spacing: 0px;
    color: #323338;
    opacity: 1;
  }
  .dobContainerInput {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .LongTextBodyElementContainer {
    max-height: 500px;
    width: 630px;
  }
  .confirmContainer {
    display: flex;
    margin-top: 20px;
    justify-content: center;
    .cancelButton {
      margin-right: 10px;
      background-color: #fe4c4a;
    }
    .cancelButtonDivider {
      color: #f14543;
    }
    .cancelButton:hover {
      background-color: #f14543;
      .cancelButtonDivider {
        color: #fe4c4a;
      }
    }
    .confirmButton {
      margin-right: 10px;
      background-color: #71cf48;
    }
    .confirmButtonDivider {
      color: #69c242;
    }
    .confirmButton:hover {
      background-color: #69c242;
      .confirmButtonDivider {
        color: #71cf48;
      }
    }
  }
}
