@import "../../../../../../Communication.scss";

.attach-files-icon {
  cursor: pointer;
  color: $icon-color;
  margin-top: 5px;

  &.dark-mode {
    color: #fff;
  }
}

.attach-files-container {
  .ant-upload-wrapper .ant-upload-list::before {
    display: none;
  }

  .ant-upload-list.ant-upload-list-text {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    position: absolute;
    left: 10px;
    right: 20px;
    bottom: 50px;
    height: 50px;
    overflow-y: auto;
    overflow-x: hidden;

    .ant-upload-list-item-container {
      .ant-upload-list-item {
        margin-top: 0;
      }
    }
  }
}
