$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.newToDoModal {
  width: fit-content !important;
  max-width: 80%;

  @media (max-width: 700px) {
    max-width: 100% !important;
    padding: 20px;
  }

  .ant-modal-content {
    .ant-modal-close {
      height: 45px;
      width: 45px;
      .ant-modal-close-x {
        height: 45px;
        width: 45px;
        display: flex;
        align-items: center;
        justify-self: center;
        svg {
          fill: #333238;
        }
      }
    }
    .ant-modal-header {
      height: 45px;
      display: flex;
      align-items: center;
      background-color: #f8f8fa;
      .ant-modal-title {
        font-size: 16px;
        font-weight: 600;
        color: #333238;
        width: 100%;

        .new-todo-modal-header {
          width: 97%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          gap: 10px;
          background-color: transparent;

          .title {
            margin-right: auto;
          }

          svg {
            width: 20px;
            height: 20px;
            cursor: pointer;

            path {
              fill: #323338;
            }
          }
        }
      }
    }

    .ant-modal-body {
      display: flex;
      flex-direction: column;
      gap: 20px;
      overflow: hidden;

      // width: 100%;
      .stepper-section-mobile {
      }
      .todo-form-section {
        padding: 0;
        // max-width: 60vw;
        width: 100%;
        margin-top: unset;
        // max-width: 100%;
        height: fit-content;
        // display: flex;
        // gap: 20px;
        max-height: calc(100vh - 250px);
        overflow-y: auto;
        display: flex;
        flex-direction: row;
        gap: 10px;

        .todo-form__leftSide {
          width: 25%;
          .docUploader {
            height: 100%;
            .uploadedFilesSection {
              // height: 80%;
              max-height: calc(100% - 95px) !important;
            }
          }
        }
        .ant-form {
          width: 75%;
          display: flex;
          gap: 20px;
          flex-direction: row;
          flex-wrap: wrap;

          .labeledInputContainer {
            width: 42%;
            height: fit-content;
            .ant-form-item {
              margin: 0;
              .ant-picker {
                width: 100%;
              }
            }
            .ant-form-item-control-input-content {
              height: unset;
            }
            .tox-tinymce {
              height: 140px !important;
              .tox-edit-area {
                height: 90px;
              }
            }
            .richTextComp .tox {
              .tox-editor-container {
                .tox-editor-header {
                  background-color: #f8f8fa;
                  border-radius: 5px;
                  .tox-toolbar-overlord {
                    background-color: #f8f8fa;
                    border-radius: 5px;
                    .tox-toolbar__primary {
                      display: flex;
                      gap: 10px;
                      background-color: #f8f8fa;
                      border-radius: 5px;
                      max-width: 600px;
                      overflow-x: auto;
                      .tox-toolbar__group {
                        display: flex;
                        gap: 10px;
                        .tox-tbtn {
                          max-width: 20px;
                        }
                      }
                    }
                    .labeledInputLabel {
                    }
                    .ant-form-item {
                      .ant-row {
                        .ant-col {
                          .ant-form-item-control-input {
                            .ant-form-item-control-input-content {
                              .ant-picker {
                                width: 100%;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              .tox-sidebar-wrap {
                margin-top: 5px;
                .tox-edit-area {
                  .tox-edit-area__iframe {
                    border: none;
                    background-color: #f8f8fa;
                  }
                }
              }
            }
          }

          .date-input {
            width: fit-content;
          }
          .time-input {
            width: fit-content;
          }
          .related-to {
            min-width: 170px;
            width: fit-content;
          }
          .todo-comment-section {
            width: 100%;
            .todo-comment {
              width: 100%;
              .all-comments-container {
                display: flex;
                flex-wrap: wrap;
                flex-direction: row-reverse;
                width: 100%;
                justify-content: space-between;
                // max-height: 450px;
                // overflow-y: auto;
                .comments-left-side {
                  height: fit-content;
                  width: 50%;
                }
                .ant-divider {
                  max-height: 40px;
                }
                .current-comments {
                  max-height: 400px;

                  margin-top: 50px;
                  width: 49%;
                  overflow-y: auto;
                }
              }
            }
          }
        }
      }
      .EmailBoxContainer {
        z-index: 5;
      }
    }
    .ant-modal-footer {
      .buttonContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 10px;
        button {
          width: 100px;
        }
      }
    }
  }
}

.preTodoModal {
  .ant-modal-content {
    .ant-modal-close {
      height: 45px;
      width: 45px;
      .ant-modal-close-x {
        height: 45px;
        width: 45px;
        display: flex;
        align-items: center;
        justify-self: center;
        svg {
          fill: #333238;
        }
      }
    }
    .ant-modal-header {
      height: 45px;
      display: flex;
      align-items: center;
      background-color: #f8f8fa;
      .ant-modal-title {
        font-size: 16px;
        font-weight: 600;
        color: #333238;
      }
    }

    .ant-modal-body {
      .default-topicContainer {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        .modal__default__props {
          display: flex;
          align-items: center;
          padding: 0 10px;
          opacity: 0.6;
          font-style: italic;
          user-select: none;
          cursor: default;
          border-radius: 5px;
          background-color: #fffbe6;
          border-left: 3px solid #d8c568;
          margin: 18px 20px 18px 0;
          h3 {
            display: flex;
            align-items: center;
            font-size: 13px;
            margin-bottom: 0;
            margin-right: 3px;
          }
          p {
            text-align: start;
            color: #a29a37;
            font-size: 13px;
            letter-spacing: 0.5px;
            display: flex;
            align-items: center;
            margin-bottom: 0;
            b {
              font-weight: bold;
              font-size: 1.1rem;
              letter-spacing: 0.5px;
            }
          }
        }

        .default-topic {
          margin: 18px 20px 29px 0;
        }
      }

      .labeledInputContainer {
        .ant-select {
          width: 100%;
        }
      }

      .settings {
        display: flex;
        flex-direction: column;
        gap: 24px;

        .ant-select-selector {
          background: #f5f5f7 0% 0% no-repeat padding-box;
          border-radius: 5px;
          font-weight: 400;
          border-style: none;
          transition: 0s;
          opacity: 1;
          min-height: 32px;
          width: 100%;
          height: fit-content;
        }

        .label-input {
          display: flex;
          flex-direction: column;
          gap: 2px;

          .fieldLabel {
            margin-left: 10px;
            font-size: 16px;
            font-weight: 600;
          }
        }
      }
    }
  }
}

.preTodoModalDark {
  .ant-modal-content {
    background-color: $cardModalBodyDark !important;
    .ant-modal-close {
      svg {
        fill: #fff !important;
      }
    }

    .ant-modal-header {
      background-color: $cardModalHeaderDark !important;
      .ant-modal-title {
        color: #fff !important;
      }
    }

    .ant-modal-body {
      background-color: $cardModalBodyDark !important;

      .labeledInputContainer {
        .labeledInputLabel {
          color: #fff !important;
        }
      }

      .settings {
        .label-input {
          label {
            color: #fff;
          }
        }

        .ant-select {
          .ant-select-selector {
            background-color: $inputModalDark !important;
            .ant-select-selection-search-input {
              background-color: $inputModalDark;
              color: #fff;
              &::placeholder {
                color: #acacca;
                z-index: 2;
              }
            }
            .ant-select-selection-item {
              color: #fff;
            }
          }
          .ant-select-arrow svg {
            fill: #fff;
          }
        }
      }
    }
  }
}

@media (max-width: 1450px) {
  .newToDoModal
    .ant-modal-content
    .ant-modal-body
    .todo-form-section
    .ant-form
    .todo-comment-section
    .todo-comment
    .all-comments-container {
    display: flex;
    // flex-wrap: wrap;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    // max-height: 450px;
    // overflow-y: auto;
    .comments-left-side {
      height: fit-content;
      width: 100%;
    }
    .ant-divider {
      max-height: 40px;
    }
    .current-comments {
      max-height: 400px;

      margin-top: 50px;
      width: 100%;
      overflow-y: auto;
    }
  }
}

@media (max-width: 1400px) {
  .newToDoModal {
    .ant-modal-content {
      .ant-modal-body {
        .todo-form-section {
          display: flex;
          flex-direction: column;
          gap: 20px;
          .todo-form__leftSide {
            width: 100%;
          }
          .ant-form {
            width: 100%;
          }
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .newToDoModal {
    .ant-modal-content {
      .ant-modal-body {
        max-width: 100% !important;
        max-height: calc(100vh - 200px);
        .todo-form-section {
          .ant-form {
            .labeledInputContainer {
              width: 100%;
            }
            .date-input {
              width: 100%;
            }
            .time-input {
              width: 100%;
            }
            .related-to {
              min-width: 170px;
              width: 100%;
            }
          }
        }
      }
      .ant-modal-footer {
        .buttonContainer {
          .mondayButtonText {
            display: none !important;
          }
          .mondayButtonDivider {
            display: none !important;
          }
          button {
            width: auto !important;
          }
        }
      }
    }
  }
}

.warning-modal {
  .ant-modal-content {
    .ant-modal-header {
      background-color: #f8f8fa;
      height: 45px;
      .ant-modal-title {
        color: #333238;
        margin-bottom: 15px;
      }
    }
    .ant-modal-body {
      .warning-modal-body {
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: center;
      }
    }
    .ant-modal-footer {
      .buttons {
        display: flex;
        justify-content: center;
        gap: 20px;
      }
    }
  }
}

.warning-modal-dark {
  .ant-modal-content {
    background-color: $cardModalBodyDark !important;
    .ant-modal-close {
      svg {
        fill: #fff !important;
      }
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark !important;
      .ant-modal-title {
        color: #fff !important;
      }
    }
    .ant-modal-body {
      background-color: $cardModalBodyDark !important;
      .warning-modal-body {
        color: #fff;
        .warning-modal-body-title {
          color: #fff;
        }
      }
    }

    .ant-modal-footer {
      background-color: $cardModalHeaderDark !important;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
}

.newToDoModalDark {
  background-color: $cardModalBodyDark !important;

  .ant-modal-content {
    .ant-modal-close-x {
      svg {
        fill: #fff !important;
      }
    }

    .ant-modal-header {
      background-color: $cardModalHeaderDark !important;
      .ant-modal-title {
        color: #fff !important;

        .new-todo-modal-header {
          svg {
            path {
              fill: #fff;
            }
          }
        }
      }
    }

    .ant-modal-body {
      background-color: $cardModalBodyDark !important;
      // .stepper-section {
      .ant-steps {
        .ant-steps-item-finish ::before,
        .ant-steps-item-active::before,
        .ant-steps-item::before {
          border-left-color: $cardModalBodyDark !important;
        }
        .ant-steps-item-wait {
          background-color: $cardModalHeaderDark;
          .ant-steps-item-content .ant-steps-item-title {
            color: #acacca;
          }
        }
      }
      // }
      .todo-form-section {
        background-color: $cardModalBodyDark !important;
        .ant-form {
          .labeledInputLabel {
            color: #fff !important;
          }
          .ant-input-affix-wrapper {
            background-color: $inputModalDark !important;
            .ant-input-suffix,
            .ant-input {
              background-color: $inputModalDark !important;
              color: #fff;
            }
          }
          .ant-input {
            background-color: $inputModalDark !important;
            color: #fff;
            &::placeholder {
              color: #acacca;
            }
          }

          .ant-input-disabled {
            color: #acacca;
          }
          .ant-select {
            .ant-select-selector {
              background-color: $inputModalDark !important;
              .ant-select-selection-search-input {
                color: #fff;
              }
              .ant-select-selection-item {
                color: #fff;
              }
              .ant-select-selection-placeholder {
                color: #acacca;
              }
              .ant-select-selection-overflow-item .ant-select-selection-item {
                background-color: #323338;
                .ant-select-selection-item-remove svg {
                  fill: #fff;
                }
              }
            }
            .ant-select-clear {
              background-color: $inputModalDark;
              svg {
                fill: #acacca;
              }
            }
            .ant-select-arrow svg {
              fill: #fff;
            }
          }
          .ant-select-disabled {
            .ant-select-selector {
              .ant-select-selection-search-input {
                color: #acacca !important;
              }
              .ant-select-selection-item {
                color: #acacca !important;
              }
            }
          }
          .ant-picker {
            background-color: $inputModalDark !important;
            .ant-picker-input {
              background-color: $inputModalDark;
              input {
                color: #f2f2f2;
                &::placeholder {
                  color: #acacca;
                }
              }
              .ant-picker-suffix svg {
                color: #acacca;
              }
              .ant-picker-clear {
                background-color: $inputModalDark;
                svg {
                  fill: #acacca;
                }
              }
            }
          }

          .ant-picker-disabled {
            .ant-picker-input input {
              color: #acacca !important;
            }
          }
        }

        .quill {
          background-color: $cardModalBodyDark;
          .ql-toolbar {
            background-color: $inputModalDark;
            .ql-formats {
              color: #fff !important;
              .ql-picker-label {
                color: #fff;
                &::before {
                  color: #fff;
                }
                .ql-stroke {
                  stroke: #fff;
                }
              }
              button .ql-stroke {
                stroke: #fff;
              }
            }
          }
          .ql-container {
            background-color: $inputModalDark;
            border: none;
            .ql-editor {
              &::before {
                color: #acacca;
              }
              p {
                color: #fff !important;
              }
            }
          }
        }
      }
    }
  }
  .ant-modal-footer {
    background-color: $cardModalHeaderDark !important;
  }
  .warning-modal-dark {
    .ant-modal-content {
      .ant-modal-header {
        background-color: $cardModalHeaderDark !important;
      }
      .ant-modal-body {
        background-color: $cardModalBodyDark !important;
      }
    }
  }
}

.ampm-dark-dropdown {
  background-color: $inputModalDark !important;
  .rc-virtual-list {
    .rc-virtual-list-holder {
      .rc-virtual-list-holder-inner {
        .ant-select-item {
          color: #fff;
          background-color: $cardModalHeaderDark !important;
          .ant-select-item-option {
            color: #fff;
            background-color: $cardModalHeaderDark !important;
          }
          .ant-select-item-option-active {
            color: #1264a3 !important;
          }
        }
      }
    }
  }
}
