.changeRentalRetDark {
  .ant-modal-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: #12131b;
    padding: 20px;
    height: 45px;
    border: 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    .ant-modal-title {
      color: #fff;
      font-size: 16px;
      font-weight: 600;
    }
  }

  .ant-modal-body {
    padding: 0;
    background: #1f212d;
    color: #fff;
    .ant-modal-content {
      border: 0;
      border-radius: 0;
      .ant-modal-close-x {
        color: #fff;
      }
    }
  }

  .ant-modal-footer {
    background: #1f212d;
    border: none;
  }
}
