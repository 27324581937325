@use "src/index.scss" as index;

.entry-modal {
  height: fit-content !important;
  width: fit-content !important;
  max-height: calc(100vh - 20px);
  max-width: calc(100vw - 20px);
  .ant-modal-content {
    max-height: 700px;
    max-width: 1400px;
    height: calc(100vh - 20px);
    width: calc(100vw - 20px);
    .ant-modal-close {
      height: 45px;
      width: 45px;
      .ant-modal-close-x {
        height: 45px;
        width: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .ant-modal-header {
      height: 45px;
      display: flex;
      align-items: center;
      background-color: #f8f8fa;
    }
    .ant-modal-body {
      min-height: calc(100% - 97px);
      min-width: 300px;
      display: flex;
      gap: 20px;
      padding-bottom: 10px;
      .link-text {
        color: index.$blue;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
      .entry-form-container {
        width: calc(50% - 10px);
        display: grid;
        grid-template-columns: 50% 50%;
        gap: 10px;
      }
      .info-map-container {
        width: calc(50% - 10px);
        display: flex;
        flex-direction: column;
        gap: 5px;
        .position-details {
          display: flex;
          align-items: flex-end;
          gap: 20px;
          .distanceContainer {
            display: flex;
            gap: 10px;
            .detail-label {
              font-weight: 600;
            }
          }
        }
        .modalInfo,
        .payrollActivityMapContainer {
          height: calc(100% - 10px);
          width: 100%;
          border-radius: 5px;
          overflow: hidden;
          .payrollActivityMap {
            height: 100%;
            width: 100%;
          }
        }
        .modalInfo {
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 600;
          background-color: #f8f8fa;
          font-size: 16px;
          color: #32333897;
        }
      }
    }
    .ant-modal-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      flex-wrap: nowrap;
      width: 100%;
      .mondayButtonStyle {
        width: 150px;
      }
    }
  }
}

.entry-modal.entry-modal-dark {
  .ant-modal-content {
    background-color: index.$mainDarkBackgroundColor;
    .ant-modal-close .ant-modal-close-x svg {
      fill: #fff !important;
    }
    .ant-modal-header {
      background-color: index.$cardDarkBackgroundColor;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      .info-map-container .position-details {
        color: #fff;
      }
    }
  }
}

@media (max-width: 1180px) {
  .entry-modal.entry-modal {
    .ant-modal-content .ant-modal-body {
      .labeledInputContainer .labeledInputLabel {
        font-size: 14px;
      }
    }
  }
}
