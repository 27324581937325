@import "../../../../../../../../index.scss";

.uploadEntriesModal {
  width: calc(100vw - 20px) !important;
  height: calc(100vh - 20px) !important;
  max-width: 820px !important;
  max-height: 550px !important;
  .ant-modal-content {
    width: calc(100vw - 20px) !important;
    height: calc(100vh - 20px) !important;
    max-width: 820px !important;
    max-height: 500px !important;
    .ant-modal-close {
      height: 45px;
      width: 45px;
      .ant-modal-close-x {
        height: 45px;
        width: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .ant-modal-header {
      height: 45px;
      display: flex;
      align-items: center;
      background-color: #f8f8fa;
    }
    .ant-modal-body {
      height: calc(100% - 100px);
      .ant-form {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
      .labeledInputContainer {
        .ant-form-item {
          margin-bottom: 0px;
        }
      }
    }
    .ant-modal-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

.uploadEntriesModalDark {
  .ant-modal-content {
    background-color: $mainDarkBackgroundColor;
    .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardDarkBackgroundColor;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      .labeledInputContainer .labeledInputLabel {
        color: #fff;
      }
      .upload-section {
        .ant-upload {
          border: 1px dashed #acacca;
          background-color: #2a2d41;
          .ant-upload-drag-icon span {
            color: #fff;
          }
          .ant-upload-text {
            color: #fff;
          }
        }
      }
    }
  }
}
