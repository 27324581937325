@import "../../../../../../index.scss";

$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.documentationSelectorModal {
  .selectorContainer {
    width: 100%;
    @include flex(center, space-between, column);
    gap: 10px;
  }

  .docSelector {
    margin-bottom: 20px;
    width: 400px;
    .ant-select {
      border: 0;
      border-radius: 10px;
      background-color: #f5f5f7;
    }
  }

  .docSelectorActionButtons {
    @include flex(center, center);
  }

  .cancelButton {
    @include mondayButton(#f5f5f7, #e6e9ef, #323338);
    margin-right: 20px;
  }

  .saveButton {
    @include mondayButton(#71cf48, #68c142);
    min-width: 150px;
  }

  .labeledInputContainer .ant-form-item {
    margin-bottom: 0px;
  }

  .original-cards {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-y: auto;
    overflow-x: hidden;
    .original-card-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      background-color: #f5f5f7;
      border-radius: 5px;
      padding: 5px 10px;
      cursor: pointer;
      transition: 0.3s ease-out;
      &.selected {
        background-color: #e6e9ef;
      }
      .card-name {
        .doc-title {
          font-weight: 600;
        }
      }
      .doc-status {
        height: fit-content;
        border-radius: 5px;
        color: #fff;
        padding: 3px 10px;
      }
    }
  }
}

.documentationSelectorModalDark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      .labeledInputLabel {
        color: #fff;
      }
      .ant-select {
        .ant-select-selector {
          background-color: $inputModalDark !important;
          .ant-select-selection-search-input {
            color: #fff;
          }
          .ant-select-selection-item {
            color: #fff;
          }
          .ant-select-selection-placeholder {
            color: #acacca;
          }
        }
        .ant-select-clear {
          background-color: $inputModalDark;
          svg {
            fill: #acacca;
          }
        }
        .ant-select-arrow svg {
          fill: #fff;
        }
      }
      .ant-select-disabled {
        .ant-select-selector {
          .ant-select-selection-search-input {
            color: #acacca !important;
          }
          .ant-select-selection-item {
            color: #acacca !important;
          }
        }
      }
      .ant-radio-group {
        .ant-radio-wrapper span {
          color: #fff;
        }
      }
    }
  }
  .original-cards {
    .original-card-container {
      background-color: #2a2b3a;
      &.selected {
        background-color: $inputModalDark;
      }
      .card-name {
        .doc-created,
        .doc-title {
          color: #fff;
        }
      }
    }
  }
}

@media (max-width: 460px) {
  .documentationSelectorModal .docSelector {
    width: 300px;
  }
}
