$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.jump-breakdown-schedule-modal {
  .breakdownInputContainer {
    .labeledInputContainer {
      width: 100%;
      display: flex;
      align-items: center;
      .ant-form-item {
        width: 300px !important;
        margin: 0 !important;
      }
    }
    .mondayButtonContainer {
      margin: 0 !important;
    }
  }
  .AutomaticCompletionContainer {
    width: 180px;
    .body {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      > button {
        margin-bottom: 10px;
        width: 50px;
      }
      > div {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        height: 100%;
        gap: 6px;
      }
    }
  }
  .ProgressByDayContainer {
    > div > div {
      display: flex;
      flex-direction: column;
    }
  }
  .ant-modal-header {
    display: flex;
    justify-content: center;
    width: "fit-content";
    height: 45px;
    background: #f7f8fa 0% 0% no-repeat padding-box;
    border-radius: 10px 10px 0px 0px;
    opacity: 1;
  }
  .ant-modal-close-x {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #323338;
    height: 45px;
  }
  .ant-modal-title {
    display: flex;
    align-items: center;
    width: 100%;
    height: 22px;
    color: var(--unnamed-color-323338);
    text-align: left;
    font: normal normal 600 16px/39px Open Sans;
    letter-spacing: 0px;
    color: #323338;
    opacity: 1;
  }
  .ant-modal-content {
    border-radius: 10px;
  }
  .ant-modal-body {
    max-height: 80dvh;
    overflow-y: auto;
    width: "fit-content";
    height: "fit-content";
    padding: 20px !important;

    .documentationsTable {
      .ag-root-wrapper-body.ag-layout-normal {
        height: fit-content !important;
      }
    }
  }
}

.jump-breakdown-schedule-modal-dark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      .labeledInputContainer {
        .ant-input {
          background-color: $inputModalDark !important;
          color: #fff;
          &::placeholder {
            color: #acacca;
          }
        }
        .ant-input-disabled {
          color: #acacca;
        }
        .ant-select {
          .ant-select-selector {
            background-color: $inputModalDark !important;
            .ant-select-selection-search-input {
              color: #fff;
            }
            .ant-select-selection-item {
              color: #fff;
            }
            .ant-select-selection-placeholder {
              color: #acacca;
            }
          }
          .ant-select-clear {
            background-color: $inputModalDark;
            svg {
              fill: #acacca;
            }
          }
          .ant-select-arrow svg {
            fill: #fff;
          }
        }
        .ant-select-disabled {
          .ant-select-selector {
            .ant-select-selection-search-input {
              color: #acacca !important;
            }
            .ant-select-selection-item {
              color: #acacca !important;
            }
          }
        }
      }
    }
  }
}
