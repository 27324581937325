$cardDarkHeaderColor: #2a2b3a;
$cardDarkBackgroundColor: #12131b;
$inputDarkColor: #12131b;

.topicsContainer {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 4px #00000029;
  background-color: #ffff;
  font: normal normal 600 16px/22px Open Sans;
  border-radius: 10px;
  transition: all 0.3s;

  .topicsHeader {
    height: 45px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    background: #f8f8fa 0% 0% no-repeat padding-box;
    border-radius: 10px 10px 0px 0px;
    transition: 0.2s ease-in;

    .topicTitle {
      text-wrap: nowrap;
    }
  }
  .topicsContent {
    height: 100%;
    display: flex;
    justify-content: flex-start;
    // max-height: 800px;
    flex-direction: column;
    overflow-y: auto;
    background: #f8f8fa;
    border-radius: inherit;

    .search-input {
      padding: 10px 20px 0 10px;
      .ant-form-item {
        margin-bottom: 10px;
      }
      .ant-input-affix-wrapper {
        background: #fff !important;
        .ant-input {
          background: #fff !important;
        }
      }
    }
    .actionButtons {
      display: flex;
      align-items: center;
      gap: 15px;
    }

    .widget-content {
      .ant-radio-group {
        width: 100%;
        overflow: hidden;
      }
    }

    .topic-options {
      height: 100%;
      padding: 0 14px 10px 10px;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
    }

    .topic-elements {
      .widget-content {
        .ant-radio-group {
          border-radius: 5px;
          background: #ffffff;
          .topicOption {
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 10px;
            border-bottom: 1px solid #dee2eb;

            // .ant-radio-wrapper span:nth-child(2) {
            //   font-size: 16px;
            // }

            .ant-radio-wrapper {
              width: 100%;
              span {
                color: #323338;
                font-weight: 400;
              }
              .ant-radio-inner {
                background-color: #fff;

                &::after {
                  background-color: #1264a3;
                  transform: scale(0.525);
                }
              }
            }
          }
          .selected-topic {
            background-color: #f1f5fd;
            span {
              color: #1264a3 !important;
            }
          }
          .darkSingleRouteContainer {
            span:nth-child(2) {
              color: #fff;
            }
          }
        }
      }
    }

    .topic-elements-2 {
      &:not(:last-child) {
        margin-right: 30px;
      }
      animation: slideInRightCheckboxAnimation 1000ms both;

      .widget-content {
        .ant-radio-group {
          display: flex;
          gap: 5px;
          flex-direction: column;

          .topicOption {
            position: relative;
            background-color: transparent;
            .ant-radio-wrapper {
              background: #ffffff;
              width: 100%;
              height: 40px;
              overflow: hidden;
              display: flex;
              align-items: center;

              &:hover {
                background-color: #d3d3d3;

                .ant-radio-inner {
                  border: 10px solid #fe4c4a !important;
                  background-color: #e03d3a;
                  margin: 0px !important;
                  border-radius: 0 !important;
                  height: 40px;
                  width: 40px;
                }
              }

              .ant-radio {
                height: 100%;
              }

              .ant-radio-inner {
                border: 4px solid #fe4c4a !important;
                background-color: #e03d3a;
                margin: 0px !important;
                border-radius: 0 !important;
                height: 100%;
                width: 0%;
                &:hover {
                  border: 10px solid #fe4c4a !important;
                  background-color: #e03d3a;
                  margin: 0px !important;
                  border-radius: 0 !important;
                  height: 40px;
                  width: 40px;
                  position: relative;
                }
                &::after {
                  width: 0;
                  height: 0;
                }
              }
            }
            .actionButtons {
              position: absolute;
              top: 10px;
              right: 20px;
            }
          }
        }

        .ant-radio-wrapper-checked {
          position: relative;
          &:hover {
            &::after {
              border: 10px solid #68c142 !important;
            }
          }

          .ant-radio {
            height: 100%;
            width: 40px;
          }

          &::after {
            display: flex;
            justify-content: center;
            align-items: center;
            border: 10px solid #71cf48 !important;
            content: url("../../../../pages/Settings/settingsComponents/Roles/src/TickIcon.svg");
            background-color: #68c142;
            width: 40px;
            height: 40px;
            position: absolute;
            z-index: 5;
          }
        }
      }
    }
  }
  .topicsCollapse {
    position: absolute;
    right: -8px;
    bottom: 40px;
    width: 32px;
    border-radius: 5px;
    height: 32px;
    z-index: 9;
    background-color: #fff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1607843137);

    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.collapsed {
    width: 25px !important;
    min-width: 25px !important;
    flex: 0 !important;
    background: transparent;
    box-shadow: unset;
  }
}

.topicsContainerDark {
  background-color: $cardDarkBackgroundColor;
  color: #fff !important;
  .topicsHeader {
    background-color: $cardDarkHeaderColor;
    color: #fff;
  }
  .topicsContent {
    background-color: #2a2b3a;
    .search-input {
      .ant-input-affix-wrapper {
        background-color: $inputDarkColor !important;
        .ant-input {
          background-color: $inputDarkColor !important;
          &::placeholder {
            color: #acacca;
          }
        }
        .ant-input-suffix svg {
          fill: #acacca;
        }
      }
    }
    .topic-elements {
      .widget-content {
        .ant-radio-group {
          background: #20212d !important;
          span {
            color: #ffffff !important;
          }

          .topicOption {
            border-bottom: 1px solid #616481 !important;
          }
          .selected-topic {
            background-color: #41435d !important;
            span {
              color: #ffffff !important;
            }
          }
        }
      }
    }
    .topic-elements-2 {
      .widget-content {
        .ant-radio-group {
          .ant-radio-wrapper {
            background: #20212d !important;
            color: #ffffff !important;
          }
        }
      }
    }
  }

  ::-webkit-scrollbar-track {
    background: #41435d;
  }

  ::-webkit-scrollbar-thumb {
    background: #222a44;
  }

  /*Up Arrow*/
  ::-webkit-scrollbar-button:single-button:vertical:decrement {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(249, 249, 249)'><polygon points='50,00 0,50 100,50'/></svg>");
  }

  /*Down Arrow*/
  ::-webkit-scrollbar-button:single-button:vertical:increment {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(249, 249, 249)'><polygon points='0,0 100,0 50,50'/></svg>");
  }
}
