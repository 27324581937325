$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.newNoteModal {
  .note-modal-content {
    display: flex;
    flex-direction: column;
    width: 500px;
    .loader {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 24px;
    }
    .modalFooter {
      margin-top: 20px;
      align-self: center;
    }
  }
}

.newNoteModalDark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      .note-modal-content {
        .labeledInputLabel {
          color: #fff;
        }
        .ant-select {
          .ant-select-selector {
            background-color: $inputModalDark !important;
            .ant-select-selection-search-input {
              color: #fff;
            }
            .ant-select-selection-item {
              color: #fff;
            }
            .ant-select-selection-placeholder {
              color: #acacca;
            }
          }
          .ant-select-clear {
            background-color: $inputModalDark;
            svg {
              fill: #acacca;
            }
          }
          .ant-select-arrow svg {
            fill: #fff;
          }
        }
      }
    }
  }
}

@media (max-width: 555px) {
  .newNoteModal .note-modal-content {
    width: 400px;
  }
}

@media (max-width: 460px) {
  .newNoteModal .note-modal-content {
    width: 300px;
  }
}
