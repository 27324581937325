@use "/src/index.scss" as index;

.payroll-live-analytics {
  height: calc(100vh - 56px);
  width: 100%;
  .analytics-control-panel {
    height: 51px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    border-bottom: 1px solid #dee2eb;
    padding: 0px 10px;
  }
}

.payroll-live-analytics-dark {
  .analytics-control-panel {
    border-bottom: 1px solid #616481;
    background-color: index.$cardDarkBackgroundColor;
  }
}
