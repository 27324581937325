@import "../../../index.scss";

$mainDarkBackgroundColor: #20212d;
$cardDarkHeaderColor: #2a2b3a;
$cardDarkBackgroundColor: #12131b;
$inputDarkColor: #2a2b3a;
$dark-text-color: #fff;
$icon-color: #323338;
$main-light-background: #1264a3;
$secondary-light-background: #f1f5fd;
$white: #fff;
$black: #000;
$border-color: #dee2eb;
$light-text-color: #323338;

.communication-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  p {
    padding: 0;
    margin: 0;
  }

  @media only screen and (max-width: 768px) {
    .ant-layout-sider {
      max-width: 60px !important;
      min-width: 60px !important;
      width: 60px !important;
    }
  }
}

.CommunicationContainerDark {
  .inbox {
    background-color: #ce6060 !important;
  }

  .CommunicationContainerBody {
    background-color: $cardDarkBackgroundColor;

    .communicationsCategoiesContainer {
      #categoryDiv {
        .composeEmail {
          background-color: $cardDarkBackgroundColor;
          box-shadow: 0px 3px 6px rgba(255, 255, 255, 0.5);

          svg path {
            fill: #fff !important;
          }
        }

        .categoryLogoContainer {
          span svg path {
            fill: #fff;
          }

          &:hover {
            background-color: $cardDarkHeaderColor;
          }
        }

        .other {
          background-color: $cardDarkHeaderColor;
        }
      }

      .CategoryHover {
        background-color: $cardDarkBackgroundColor;
        box-shadow: 6px 0 5px -2px rgba(255, 255, 255, 0.2);

        .composeEmail {
          background-color: $cardDarkBackgroundColor;
          box-shadow: 0px 3px 6px rgba(255, 255, 255, 0.4);

          .composeIconhover {
            background-color: $cardDarkBackgroundColor;

            svg path {
              fill: #fff !important;
            }
          }

          .composeLabel {
            background-color: $cardDarkBackgroundColor;
            color: #fff;
          }
        }

        .categoryLogoContainerHover {
          .categoryExtended {
            .categoryIconWithLabel {
              svg path {
                fill: #fff;
              }

              .categoryLable {
                color: #fff;
              }
            }

            .categoryWithNo .EmailsNo {
              color: #fff;
            }
          }
        }

        .categoryLogoContainerHover:hover {
          background-color: $cardDarkHeaderColor;

          .categoryExtended {
            background-color: $cardDarkHeaderColor;

            .categoryIconWithLabel,
            .categoryLable,
            .categoryWithNo {
              background-color: $cardDarkHeaderColor;
            }
          }
        }

        .selectedCategory {
          background-color: #ce6060;

          .categoryExtended {
            background-color: #ce6060;

            .categoryIconWithLabel,
            .categoryLable {
              background-color: #ce6060;
            }
          }
        }
      }

      .collapseButton {
        background-color: $inputDarkColor;
      }
    }

    .communiationEmailContainer {
      .utilsContainer {
        .refreshIcon,
        .threeDotsIcon {
          svg {
            fill: #fff;
          }
        }
      }

      .emailBlockContainer {
        .emailItemContainer {
          background-color: $inputDarkColor;

          .emailItemContainerLeft {
            background-color: $inputDarkColor;

            .userEmailItemLabelRead,
            .emailItemSubjectRead span {
              color: #fff;
            }
          }

          .emailItemContainerRight .emailItemContainerRight {
            color: #fff;
          }
        }
      }

      .utilsContainer {
        #Path_5272,
        #keyboard_backspace_black_24dp_1_ {
          fill: #fff;
        }
      }

      .EmailViewContainer {
        .emailViewContainerHeader .subjectName {
          color: #fff;
        }

        .emailItemComponentCollapsed,
        .emailItemComponent {
          .mainEmailBody {
            color: #fff;

            span div {
              background-color: $cardDarkBackgroundColor !important;
              color: #acacca !important;
            }
          }
        }

        .footerButtonsContainer {
          .footerButtonStyle {
            border: 1px solid #acacca;

            svg {
              fill: #fff;
            }

            .footerButtonLabel {
              color: #fff;
            }
          }
        }
      }
    }
  }
}

.hide-header {
  .communication-header-container {
    display: none !important;
  }
}
