$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;

.view-logs-container {
  width: 100%;

  .view-logs-header {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    text-align: center;
    font-size: "20px";
  }
  .view-logs-item-body {
    display: flex;
    gap: 10px;
  }

  .view-logs-item {
    padding: 20px;
    width: 100%;
    background: #f5f5f7;
    border-radius: 5px;
    border: none;
    text-align: center;
  }
}

.view-logs-container-dark {
  width: 100%;

  .view-logs-header {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    text-align: center;
    font-size: "20px";
  }
  .view-logs-item-body-dark {
    display: flex;
    gap: 10px;
    strong {
      color: #fff;
    }
  }

  .view-logs-item-dark {
    padding: 20px;
    width: 100%;
    background: $cardModalHeaderDark;
    border-radius: 5px;
    border: none;
    text-align: center;
    color: #fff;
  }
}
