.invoiceHeaderCont {
  p,h3{
    margin-bottom: 0 !important;
  }
}
.invoiceHeaderCont {
  grid-area: auto;
  width: 100%;
  margin-top: 40px;
  height: auto;
  display: grid;
  padding-bottom: 20px;
  grid-template-columns: repeat(2, 45%);
  grid-template-rows: auto;
  justify-content: center;
}
.invoiceHeaderCont img {
  grid-area: auto;
  width: 70px;
  height: 10px;
  justify-self: start;
  background-position: "50% 50%";
  background-repeat: "no-repeat";
  object-fit: "contain";
}
@media (min-width: 1281px) {
  .invoiceHeaderCont img {
    width: 100px;
  }
}
@media (min-width: 1281px) {
  .invoiceHeaderCont img {
    height: 20px;
  }
}
.invoiceHeaderCont div {
  grid-area: auto;
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto;
  align-content: start;
  justify-content: center;
  gap: 5px 0;
}
.invoiceHeaderCont div h3 {
  grid-area: auto;
  width: "fit-content";
  height: auto;
  justify-self: start;
  font-size: 1rem;
  font-weight: 400;
  font-style: normal;
  color: #000000;
  text-align: center;
  font-weight: 800;
  text-align: start;
  font-size: 9px;
  color: #101010;
}
@media (min-width: 481px) and (max-width: 767px) {
  .invoiceHeaderCont div h3 {
    font-size: 1rem;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .invoiceHeaderCont div h3 {
    font-size: 1rem;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .invoiceHeaderCont div h3 {
    font-size: 1rem;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .invoiceHeaderCont div h3 {
    font-size: 1rem;
  }
}
@media (min-width: 1281px) {
  .invoiceHeaderCont div h3 {
    font-size: 1rem;
  }
}
@media (min-width: 481px) and (max-width: 767px) {
  .invoiceHeaderCont div h3 {
    font-size: 9px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .invoiceHeaderCont div h3 {
    font-size: 10px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .invoiceHeaderCont div h3 {
    font-size: 11px;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .invoiceHeaderCont div h3 {
    font-size: 14px;
  }
}
@media (min-width: 1281px) {
  .invoiceHeaderCont div h3 {
    font-size: 14px;
  }
}
.invoiceHeaderCont div p {
  grid-area: auto;
  width: "fit-content";
  height: auto;
  justify-self: start;
  font-size: 1rem;
  font-weight: 400;
  font-style: normal;
  color: #000000;
  text-align: center;
  text-align: start;
  font-size: 7px;
  color: #1c1c1c;
}
@media (min-width: 481px) and (max-width: 767px) {
  .invoiceHeaderCont div p {
    font-size: 1rem;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .invoiceHeaderCont div p {
    font-size: 1rem;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .invoiceHeaderCont div p {
    font-size: 1rem;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .invoiceHeaderCont div p {
    font-size: 1rem;
  }
}
@media (min-width: 1281px) {
  .invoiceHeaderCont div p {
    font-size: 1rem;
  }
}
@media (min-width: 481px) and (max-width: 767px) {
  .invoiceHeaderCont div p {
    font-size: 7px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .invoiceHeaderCont div p {
    font-size: 8px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .invoiceHeaderCont div p {
    font-size: 9px;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .invoiceHeaderCont div p {
    font-size: 13px;
  }
}
@media (min-width: 1281px) {
  .invoiceHeaderCont div p {
    font-size: 13px;
  }
}
.invoiceHeaderCont .leftPartCont {
  grid-area: auto;
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto;
  align-content: start;
  justify-content: center;
  gap: 15px 0;
}
.invoiceHeaderCont .rightPartCont {
  grid-area: auto;
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto;
  align-content: start;
  justify-content: center;
  gap: 15px 0;
}
.invoiceHeaderCont .rightPartCont h1 {
  grid-area: auto;
  width: "fit-content";
  height: auto;
  justify-self: end;
  font-size: 1.6rem;
  font-weight: 600;
  font-style: normal;
  color: #000000;
  text-align: center;
  font-size: 12px;
  color: #101010;
  text-align: end;
  transform: translateY(15px);
}
@media (min-width: 481px) and (max-width: 767px) {
  .invoiceHeaderCont .rightPartCont h1 {
    font-size: 1.6rem;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .invoiceHeaderCont .rightPartCont h1 {
    font-size: 1.7rem;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .invoiceHeaderCont .rightPartCont h1 {
    font-size: 1.7rem;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .invoiceHeaderCont .rightPartCont h1 {
    font-size: 1.7rem;
  }
}
@media (min-width: 1281px) {
  .invoiceHeaderCont .rightPartCont h1 {
    font-size: 1.8rem;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .invoiceHeaderCont .rightPartCont h1 {
    text-align: end;
  }
}
@media (min-width: 1281px) {
  .invoiceHeaderCont .rightPartCont h1 {
    text-align: end;
  }
}
@media (min-width: 481px) and (max-width: 767px) {
  .invoiceHeaderCont .rightPartCont h1 {
    font-size: 12px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .invoiceHeaderCont .rightPartCont h1 {
    font-size: 15px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .invoiceHeaderCont .rightPartCont h1 {
    font-size: 17px;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .invoiceHeaderCont .rightPartCont h1 {
    font-size: 24px;
  }
}
@media (min-width: 1281px) {
  .invoiceHeaderCont .rightPartCont h1 {
    font-size: 24px;
  }
}
.invoiceHeaderCont .rightPartCont h3 {
  justify-self: end;
  font-size: 9px;
}
@media (min-width: 481px) and (max-width: 767px) {
  .invoiceHeaderCont .rightPartCont h3 {
    font-size: 9px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .invoiceHeaderCont .rightPartCont h3 {
    font-size: 10px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .invoiceHeaderCont .rightPartCont h3 {
    font-size: 11px;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .invoiceHeaderCont .rightPartCont h3 {
    font-size: 14px;
  }
}
@media (min-width: 1281px) {
  .invoiceHeaderCont .rightPartCont h3 {
    font-size: 14px;
  }
}
.invoiceHeaderCont .rightPartCont p {
  justify-self: end;
  font-size: 7px;
}
@media (min-width: 481px) and (max-width: 767px) {
  .invoiceHeaderCont .rightPartCont p {
    font-size: 7px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .invoiceHeaderCont .rightPartCont p {
    font-size: 8px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .invoiceHeaderCont .rightPartCont p {
    font-size: 9px;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .invoiceHeaderCont .rightPartCont p {
    font-size: 13px;
  }
}
@media (min-width: 1281px) {
  .invoiceHeaderCont .rightPartCont p {
    font-size: 13px;
  }
}
.invoiceHeaderCont .rightPartCont .tableDatat {
  grid-area: auto;
  height: auto;
  width: 90%;
  justify-self: end;
  border: 4px double black;
  display: grid;
  grid-template-columns: repeat(2, 50%);
  gap: 0;
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .invoiceHeaderCont .rightPartCont .tableDatat {
    width: 80%;
  }
}
@media (min-width: 1281px) {
  .invoiceHeaderCont .rightPartCont .tableDatat {
    width: 80%;
  }
}
.invoiceHeaderCont .rightPartCont .tableDatat p {
  width: 100%;
  height: auto;
  overflow-wrap: break-word;
  text-align: center;
  padding: 2px 5px;
}
.invoiceHeaderCont .rightPartCont .tableDatat h3 {
  grid-area: auto;
  width: 100%;
  height: auto;
  text-align: center;
  padding: 2px 5px;
  border-top: 4px double black;
  overflow-wrap: break-word;
}
.invoiceHeaderCont .rightPartCont .tableDatat .tableTextright {
  border-right: 4px double black;
}
.invoiceHeaderCont ._ON_highlight {
  background-color: rgba(0, 0, 0, 0.1882352941);
}