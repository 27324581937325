.rentalBreakdownListContainer {
  width: stretch;
  transition: width 2s;
}
.viewport {
  width: 100%;
  overflow-y: auto;
}
.item {
  height: auto;
}
