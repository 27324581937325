.newInspectionModal {
  div {
    height: fit-content !important;
  }
}

.newInspectionModalDark {
  .ant-modal-body {
    background-color: #1f212d !important;

    .newInspNextContainer {
      .newInspNextTitle {
        color: #f0f0f0;
      }

      .newInspServicesContainer {
        h3 {
          color: #f0f0f0;
        }

        .ant-collapse {
          border: 1px solid rgb(43, 46, 61);
          background-color: #252735;

          .ant-collapse-content-box {
            background-color: #252735;

            span {
              color: #f0f0f0;
            }
          }

          .ant-collapse-expand-icon {
            color: #f0f0f0;
          }
          .ant-collapse-header-text span {
            color: #f0f0f0;
          }
        }
      }
    }
  }
}
