$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.productsTableContainer {
  background-color: white;
  padding: 0px;
  flex: 1;
  border-radius: 10px;
  box-shadow: 0px 0px 11px -8px;
  overflow: hidden;
  height: -webkit-fill-available;
  .checkbox {
    display: flex;
    justify-content: center;
  }
  .dissabled-checkbox {
    cursor: not-allowed;
    .ant-checkbox,
    .ant-checkbox-input,
    .ant-checkbox-inner {
      cursor: not-allowed !important;
    }
  }
  .editableCellContainer .disabled {
    cursor: not-allowed;
  }
  .descriptionContainer .disabled {
    cursor: not-allowed;
  }
  .productsTableContainer {
    max-height: 90%;
  }
  .header {
    width: 100%;
    justify-content: space-between;
    display: flex;
    height: 45px;
    background: #f7f8fa 0% 0% no-repeat padding-box;
    align-items: center;
    z-index: 2;
    padding: 2px 20px 2px 20px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    .title {
      letter-spacing: 0px;
      color: #323338;
      opacity: 1;
      cursor: pointer;
      font-weight: 600;
      font-size: 16px;
      font-family: inherit !important;
    }

    > div {
      display: flex;
      align-items: center;
    }
    .autoRowToggleContainer {
      font-style: inherit;
      margin-right: 10px;
      label {
        margin: 0;
      }
    }
  }
  // .addNewLineButton {
  //   margin-left: auto;
  // }
  // .agGridContainer {
  // height: -webkit-fill-available;
  // margin: 0 0px 10px 10px;
  // .ag-header {
  //   height: 49px !important;
  //   min-height: 49px !important;
  // }
  // }
  // .ag-root-wrapper-body {
  //   overflow: scroll;
  //   overflow-x: auto;
  // }
  .ag-row-animation {
    overflow: scroll;
    overflow-x: auto;
  }
  .createInvoiceTable {
    .ag-cell {
      padding: 0;
    }
    .ag-react-container {
      width: -webkit-fill-available;
    }
  }
}

.productsTableContainer-dark {
  @extend .productsTableContainer;

  background-color: #20242c;
  box-shadow: 0px 0px 4px 2px #71cf48 !important;

  .header {
    background: #12131b 0% 0% no-repeat padding-box;

    .title {
      color: #fff;
    }
    .servicesPricePercentage {
      color: #fff;
      input {
        background-color: $cardModalBodyDark;
        color: #fff;
        border: none;
        &::placeholder {
          color: #acacca;
        }
      }
    }

    .autoRowToggleContainer {
      label {
        color: #fff;
      }
    }
  }
  .ag-root-wrapper-body {
    background-color: #20242c !important;
  }
  .createInvoiceTable {
    .ag-cell {
      .ant-select {
        .ant-select-selector {
          background-color: $inputModalDark !important;
          .ant-select-selection-search-input {
            color: #fff;
          }
          .ant-select-selection-item {
            color: #fff;
          }
          .ant-select-selection-placeholder {
            color: #acacca;
          }
        }
        .ant-select-clear {
          background-color: $inputModalDark;
          svg {
            fill: #acacca;
          }
        }
        .ant-select-arrow svg {
          fill: #fff;
        }
      }
      //Disabled
      .ant-select-disabled {
        .ant-select-selector {
          .ant-select-selection-search-input {
            color: #acacca !important;
          }
          .ant-select-selection-item {
            color: #acacca !important;
          }
        }
      }
      .editableCellContainer {
        color: #fff !important;
        background-color: transparent !important;
      }

      .descriptionContainer {
        background: $inputModalDark !important;
        color: #fff !important;
      }
    }
  }
}
