.addonSchemePicker__modal__dark {
  // antd modal header
  border-radius: 10px;
  .price-modal-container {
    .price-list-container {
      .price-list-header {
        .price-list-column-header {
          background-color: #12131b !important;
          color: #fff;
        }
      }
      .price-list-body {
        border: 1px solid #12131b !important;
        .price-list-row {
          background-color: #1f212d !important;
          border: solid 1px #c1c7d0;
          border-bottom: none;
          .price-list-cell {
            background-color: #1f212d !important;
            color: #fff;
          }
        }
      }
    }
  }
  .ant-modal-content {
    background-color: #12131b !important;
    border-radius: 10px;
  }

  .ant-modal-header {
    background-color: #12131b !important;
    border-bottom: none;
    border-radius: 10px 10px 0 0;

    .ant-modal-title {
      color: #ffffff;
      font-size: 16px;
      font-weight: 600;
    }
  }

  // antd modal body

  .ant-modal-body {
    background-color: #1f212d;
    border-bottom: none;
    color: #ffffff;
    border-radius: 0px;
    .price-sheet-width-label,
    .price-sheet-height-label {
      color: #fff !important;
    }
    .ant-modal-title {
      color: #ffffff;
      font-size: 16px;
      font-weight: 600;
    }
  }

  // antd footer style
  .ant-modal-footer {
    background-color: #1f212d;
    border: none;
    border-radius: 0px 0px 10px 10px;
  }

  .addonSchemeModal__footer {
    display: flex;
    justify-content: space-between;
    background-color: #1f212d;
  }
  .aaddonSchemePicker__container {
    // background-color: red;

    .sheet__renderer {
      .price-sheet-component {
        color: #fff;
        .price-sheet-width-label {
          color: #fff;
        }
        .price-sheet-height-table-element {
          .price-sheet-height-label {
            color: #fff;
          }
        }
      }
    }
    .addonSchemePicker__header {
      .addonSchemePicker__header__actions {
        .indicator_title {
          // label title style
          font-size: 14px;
          font-weight: 600;
          color: #ffffff;

          margin-bottom: 0px;
        }
        .selector__priceScheme {
          // antd select style
          width: 250px;
          margin-left: 10px;
          border: none;
          //   background-color: #1264a3;
          //   border: 1px solid #d9d9d9;
          .ant-select-selector {
            background-color: #f5f5f7;
            border: none;
            // color: #ffffff;
          }
          // antd select dropdown style
        }
      }
    }
  }
}

.addonSchemePicker__modal {
  // antd modal header
  border-radius: 10px;

  .ant-modal-content {
    background-color: #f5f5f7 !important;
    border-radius: 10px;
  }

  .ant-modal-header {
    background-color: #f5f5f7 !important;
    border-bottom: none;
    border-radius: 10px 10px 0 0;
    .ant-modal-title {
      color: #000000;
      font-size: 16px;
      font-weight: 600;
    }
  }

  // antd modal body

  .ant-modal-body {
    background-color: #fff;
    border-bottom: none;
    border-radius: 0px;
    .ant-modal-title {
      //   color: #ffffff;
      font-size: 16px;
      font-weight: 600;
    }
  }

  // antd footer style
  .ant-modal-footer {
    background-color: #fff;
    border: none;
    border-radius: 0px 0px 10px 10px;
  }

  .addonSchemeModal__footer {
    display: flex;
    justify-content: space-between;
    background-color: #fff;
  }
  .aaddonSchemePicker__container {
    // background-color: red;
    .addonSchemePicker__header {
      .addonSchemePicker__header__actions {
        .indicator_title {
          // label title style
          font-size: 14px;
          font-weight: 600;
          color: #000000;
          margin-bottom: 0px;
        }
        .selector__priceScheme {
          // antd select style
          width: 250px;
          margin-left: 10px;
          border: none;
          //   background-color: #1264a3;
          //   border: 1px solid #d9d9d9;
          .ant-select-selector {
            background-color: #f5f5f7;
            border: none;
            // color: #ffffff;
          }
          // antd select dropdown style
        }
      }
    }
  }
}
