.rentals-more-options-drawer__dark {
  // antd drawer black mode
  // background-color: #2a2b3a;
  .ant-drawer-header {
    background-color: #12131b;
    height: 50px;
    border-bottom: none;
    display: flex;
    align-items: center;
    .ant-drawer-close {
      color: #fff;
      margin-bottom: 3px;
    }
    .ant-drawer-title {
      color: #fff;
      // close icon
      .anticon {
        color: #fff;
      }
    }
  }
  .ant-drawer-content-wrapper {
    background-color: #1f212d;
    color: #fff;
    border-radius: 10px;
    .ant-drawer-content {
      background-color: #1f212d;
      .ant-drawer-body {
        background-color: #1f212d !important;
        .more-options-rentals-panel__dark {
          background-color: #1f212d !important;
        }
      }
    }
  }
}

// rentals more options light mode
.rentals-more-options-drawer__light {
  .ant-drawer-header {
    background-color: #f8f8fa;
    height: 50px;
    border-bottom: none;
    display: flex;
    align-items: center;
    .ant-drawer-close {
      color: #000;
      margin-bottom: 3px;
    }
    .ant-drawer-title {
      color: #000;
      // close icon
      .anticon {
        color: #000;
      }
    }
  }
  .ant-drawer-content-wrapper {
    background-color: #fff;
    color: #000;
    border-radius: 10px;
    .ant-drawer-content {
      background-color: #fff;
      .ant-drawer-body {
        background-color: #fff;
      }
    }
  }
}
