@import "../../../../../../index.scss";

.scope-selector-modal-content {
  .scope-selector {
    min-width: 300px;
    margin-bottom: 20px;

    .ant-select-selector {
      border: 0 !important;
      border-radius: 5px !important;
      background-color: #f5f5f7 !important;
    }

    .ant-select-selection-item {
      background-color: rgb(228, 228, 228);
    }
    .ant-select-selection-item-remove {
      @include flex(center, center);
    }
  }

  .footer {
    @include flex(center, flex-end);
    width: stretch;
  }
}
