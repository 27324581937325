@import "../../../index.scss";

.confirmationModalContainer {
  .ant-modal-header {
    height: 45px;
    padding: 20px;
    border-radius: 10px 10px 0 0;
    .ant-modal-title {
      @include text(16px, 600, 0, #323338);
    }
  }

  .ant-modal-body {
    padding: 20px;
  }

  .question {
    @include text(16px, 400, 0, #1f2a44);
    margin-bottom: 20px;
  }
  .buttonContainer {
    width: 100%;
    @include flex(center, center);
    padding-bottom: 20px;

    .ant-btn {
      border-radius: 5px;
      &:not(:last-child) {
        margin-right: 15px;
      }
    }
  }
}
