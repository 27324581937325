.tree-text-header {
  margin: 0;
  font-weight: 600;
  border-radius: 5px;
  border: none;
}

.tree-text-header-dark {
  margin: 0;
  font-weight: 600;
  color: #fff;
  border-radius: 5px;
  border: none;
}
