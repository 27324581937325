.small-info-card {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  box-shadow: 0 0 5px 0.5px rgba(0, 0, 0, 0.07);
  border-radius: 10px;

  .small-info-title {
    font-weight: 550;
    font-size: 16px;
    width: 100%;
    padding: 10px 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    overflow: hidden;
  }

  .info-card-no-info {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    padding: 20px;
    font-weight: 600;
    gap: 10px;
  }

  .small-info-data {
    display: grid;
    grid-template-columns: minmax(auto, 140px) auto;
    place-items: start;
    width: 100%;
    padding: 0px 20px 20px 20px;
    gap: 10px;

    &:last-child {
      padding-bottom: 10px;
    }

    .data-title {
      white-space: nowrap;
      font-weight: 550;
      place-self: start end;
    }
  }
}

.small-info-card.small-info-dark {
  background-color: #2a2b3a;

  .small-info-title {
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  }
}
