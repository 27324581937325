.comment-component {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative; //for first line

  .comment_wrapper {
    padding: 15px;
  }

  .replies_wrapper {
    animation: fadeIn 0.7s ease-in-out;
    padding: 15px;
  }

  .comment-container {
    width: 100%;
    display: flex;
    align-items: flex-start;

    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    // NOTE: Please do not change style of this classname
    //main first line of comment
    .first-line-L {
      left: 37px;
      position: absolute;
      height: calc(100% - 35px);
      border-left: 2px solid #dbe1e7;
      z-index: 9999999;
      top: 66px;
    }

    .comment__icon {
      width: 45px;
      height: 45px;
      padding: 10px;
      border-radius: 360px;
      background-color: #ca4739;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.4rem;
    }

    .comment_data {
      margin-left: 13px;
      width: calc(100% - 58px);

      .top_details {
        width: 100%;
        display: flex;
        gap: 5px;
        align-items: center;
        // min-width: 340px;
        justify-content: space-between;
        .top_details_left {
          display: flex;
          align-items: center;
          gap: 5px;
        }
        h5 {
          margin: 0;
          color: #323338;
          font-size: 1.05rem;
          font-weight: bold;
        }
        h6 {
          margin: 0;
          // margin-left: 8px;
          font-size: 0.75rem;
          opacity: 0.5;
        }
      }
      .schedule-note-type {
        font-size: 0.75rem;
        margin-bottom: 0.6em;
      }
      .comment-content {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
        height: auto;
        .comment-text {
          width: 100%;
          h6 {
            width: fit-content;
            font-size: 12px;
            opacity: 0.5;
          }
        }
        .delete-edit-icons {
          height: fit-content;
          cursor: pointer;
          display: flex;
          justify-content: space-around;
          gap: 5px;
          span {
            padding: 5px;
            font-size: 22px;
            border-radius: 5px;
          }
          .anticon-edit:hover {
            background: #1264a3;
            color: #fff !important;
          }
          .anticon-delete:hover {
            background: #fe4c4a;
            color: #fff !important;
          }
        }
        p {
          margin-bottom: 0;
        }
        p > img {
          width: auto;
          height: 60px;
          border-radius: 0;
        }
        ul > li > img {
          width: auto;
          height: 60px;
          border-radius: 0;
        }
        ol > li > img {
          width: auto;
          height: 60px;
          border-radius: 0;
        }
        blockquote > img {
          width: auto;
          height: 60px;
          border-radius: 0;
        }
      }
    }
  }

  .reply_btn {
    width: 100%;
    padding: 0 10px 5px 10px;
    box-sizing: border-box;

    background: transparent;
    .ant-btn {
      overflow: hidden;
      border: none;
      width: 100%;
      background: #ffffff;
      text-align: left;
      display: flex;
      align-items: center;
      span {
        color: #acacca !important;
      }
      .ant-btn-icon {
        width: auto;
        fill: #acacca;
        transform: rotate(180deg); // Rotate the icon by 180 degrees
      }
    }
  }

  .showReplies {
    color: #1264a3;
    cursor: pointer;
    display: flex;
    align-items: center;
    span {
      margin-left: 5px;
      font-size: 0.75rem !important;
    }
    > svg {
      > path {
        fill: #1264a3 !important;
      }
    }
  }
}

.CommentDark {
  .comment-container {
    .comment_data {
      h5,
      h6,
      .comment-content {
        color: #f8f8fa !important;
        p {
          .mention {
            background: #1f212d !important;
          }
        }
      }
    }
  }
  .reply_btn {
    button {
      background: #2a2b3a !important;
    }
  }
}

@media (max-width: 480px) {
  .comment-component {
    .comment-container {
      .comment_data {
        .top_details {
          flex-direction: column;
          align-items: flex-start;
        }
      }
    }
  }
}
