.timelineProgressModal {
  min-width: 400px !important;
  width: fit-content !important;
  .ant-modal-content {
    width: fit-content !important;
    .ant-modal-header {
      display: flex;
      align-items: center;
      height: 45px;
      background-color: #f5f5f5;
    }
    .ant-modal-body {
      width: fit-content !important;
      .ant-steps {
        .ant-steps-item {
          .ant-steps-item-container {
            .ant-steps-item-content {
              .ant-steps-item-title {
                div {
                  font-size: 14px !important;
                  font-weight: 400 !important;
                  color: rgba(0, 0, 0, 0.88) !important;
                }
              }
              .ant-steps-item-description {
                div {
                  font-size: 14px !important;
                  font-weight: 600 !important;
                  color: rgba(0, 0, 0, 0.88) !important;
                }
              }
            }
          }
        }
      }
      .ant-timeline {
        max-height: 60dvh;
        overflow-y: auto;
        padding-top: 12px;
        width: max-content;
        min-width: 550px;
        .ant-timeline-item {
          width: auto;
          padding-bottom: 30px;
          .ant-timeline-item-content {
            width: max-content;
          }
        }
        .ant-timeline-item-last {
          padding: 0 !important;
        }
      }
      .timelineProgress {
        animation: timelineProgress 0.5s;
        width: 100%;
      }
    }
  }
}

@keyframes timelineProgress {
  0% {
    opacity: 0;
    transform: translateX(50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
