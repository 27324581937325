.audit-action-container {
  height: calc(100vh - 156px);
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  gap: 10px;
  padding: 10px;
  background-color: #fff;
  cursor: default;

  .audit-locations-card {
    height: 100%;
    width: 33%;
    border-radius: 5px;
    background-color: #f8f8fa;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 10px;

    .locations-card-title {
      font-size: 1rem;
      font-weight: 550;
      letter-spacing: 0.28px;
      text-align: center;
      padding: 10px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 95%;
    }

    .locations-card-title.card-edit-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .audit-no-vehicle-selected {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;

    .icon-no-selection {
      height: 56px;
      width: 56px;
      svg {
        height: 100%;
        width: 100%;
      }
    }

    .text-no-selection {
      font-weight: 550;
      font-size: 0.8rem;
      letter-spacing: 0.18px;
    }
  }
}

.audit-action-container.audit-full-height {
  height: calc(100vh - 106px);
}

.audit-action-container.action-container-dark {
  background-color: #12131b;

  .audit-locations-card {
    background-color: #20212d;
    .locations-card-title {
      color: #f2f2f2;
    }
  }

  .audit-no-vehicle-selected {
    .icon-no-selection {
      svg {
        fill: #f2f2f2;
      }
    }

    .text-no-selection {
      color: #f2f2f2;
    }
  }
}
