@import "../../../../../../../../../../index.scss";
.serviceHeaderHoistLightMode {
  align-items: center;
  display: flex;
  height: 45px;
  padding: 0 15px;

  .serviceHeaderServiceHoistTittle {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font: normal normal 600 16px/22px Open Sans;
  }

  .details-container {
    display: flex;
    gap: 15px;

    .titleContainerHoist {
      display: flex;
      gap: 15px;
      .buttonContainer {
        .changeRetainageSOVButton {
          @include mondayButton(#1264a3, #0f5c97, #fff);
          &:disabled {
            opacity: 0.8;
          }
        }
        .changeTemplateSOVButton {
          @include mondayButton(#1264a3, #0f5c97, #fff);
          &:disabled {
            opacity: 0.8;
          }
        }
        .detailsSOVButton {
          @include mondayButton(#1264a3, #0f5c97, #fff);
        }
      }
    }

    .sovHeaderInformationContainer {
      display: flex;
      align-items: center;

      .sovHeaderLabel {
        font: normal normal 600 16px/22px Open Sans;
        margin-right: 5px;
        // color: #f5f5f5;
      }

      .sovHeaderData {
        font: normal normal 600 16px/22px Open Sans;
        color: #68c142;
      }
    }
  }
}

.serviceHeaderHoistDarkMode {
  display: flex;
  align-items: center;
  height: 45px;
  padding: 0 15px;

  .serviceHeaderServiceHoistTittle {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font: normal normal 600 16px/22px Open Sans;
    color: #f5f5f5;
  }

  .details-container {
    display: flex;
    gap: 15px;

    .titleContainerHoist {
      display: flex;
      height: fit-content;
      gap: 15px;

      .buttonContainer {
        .changeRetainageSOVButton {
          @include mondayButton(#1264a3, #0f5c97, #fff);
          &:disabled {
            opacity: 0.8;
          }
        }
        .changeTemplateSOVButton {
          @include mondayButton(#1264a3, #0f5c97, #fff);
          &:disabled {
            opacity: 0.8;
          }
        }
        .detailsSOVButton {
          @include mondayButton(#1264a3, #0f5c97, #fff);
        }
      }
    }

    .sovHeaderInformationContainer {
      display: flex;
      align-items: center;

      .sovHeaderLabel {
        font: normal normal 600 16px/22px Open Sans;
        margin-right: 5px;
        color: #f5f5f5;
      }

      .sovHeaderData {
        font: normal normal 600 16px/22px Open Sans;
        color: #68c142;
      }
    }
  }
}
