@import "../../../../../index.scss";
* {
  box-sizing: border-box;
}

$mainDarkBackgroundColor: #20212d;
$cardDarkHeaderColor: #2a2b3a;
$cardDarkBackgroundColor: #12131b;
$inputDarkColor: #2a2b3a;

.row:after {
  content: "";
  clear: both;
  display: table;
}
.footer1 {
  padding: 70px;
  // position: relative;
  margin-left: 40%;
  margin-top: -2%;
}
// .previewButton {
//   //display: flex;
//   //flex-wrap: nowrap;
//   //padding: 10px;
//   //margin-left: 82%;
//   //margin-top: -7%;
//   //position: absolute;
//   //width: 15%;
// }

.datePicker input {
  height: 20px !important;
  padding: 11px;
  background-color: #fff;
}

.scheduleModal {
  max-width: 100% !important;
  height: 100%;
  > div {
    &:nth-child(2) {
      height: 100% !important;
    }
    // height: 100%;
  }
  .ag-root-wrapper-body {
    overflow: auto !important;
    &::-webkit-scrollbar {
      width: 5px !important;
      height: 5px !important;
      // background: transparent; /* make scrollbar transparent */
    }
  }
  .ant-modal-centered .ant-modal {
    top: 0;
    display: inline-block;
    /* padding-bottom: 0; */
    text-align: left;
    vertical-align: middle;
  }
  .ant-modal {
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
    pointer-events: none;
    position: relative;
    top: 100px;
    width: auto;
    margin: 0 auto;
    padding: 0 0 24px;
    padding-bottom: 0 !important;

    .ant-modal-footer {
      padding: 10px 16px;
      text-align: right;
      background: #ffffff;
      border-radius: 0px 0px 2px 2px;
      margin-top: -50px;
    }
  }

  .cardLayout {
    background-color: #fff;
    border-radius: 20px;
    padding: 20px;
    box-shadow: 0px 1px 4px #00000029;
  }

  .header {
    @extend .cardLayout;
    width: inherit;
    margin: 20px;
    padding: 10px 30px 30px;
    // .ant-steps-navigation {
    //   padding-top: 20px;
    // }
  }
  .scheduleStepper {
    width: 97%;
    height: 40px;
  }
  .scheduleCoreInfo {
    @extend .cardLayout;
    margin-bottom: 20px;
  }

  .selection-form {
    padding: 0;
    padding-left: 20px;
  }

  .schedule-calendar-view {
    @extend .cardLayout;
    min-height: 300px;
    // margin-bottom: 20px;

    .date-buttons {
      // margin-bottom: 20px;
      // display: flex;
      // justify-content: center;
      // align-items: center;

      .ant-radio-button-wrapper {
        border: 0;

        &::before {
          display: none;
        }
      }

      .ant-radio-button-wrapper-checked {
        border-radius: 5px;
      }
    }

    .dates-range span {
      background: #fff !important;
    }
  }

  .rightSection {
    width: 100%;
    padding: 20px;
    padding-top: 0;
  }

  .schedule-middle-div {
    @extend .cardLayout;
    width: 100%;
    margin-bottom: 20px;
    height: 500px !important;
  }

  .noWeatherContainer {
    @include flex(center, center);
  }

  .weatherLabel {
    @include text(16px, 600, 0, #323338);
  }

  .weather-view {
    @extend .cardLayout;
    margin-top: 0 !important;

    .weather-container {
      margin: 0 !important;
    }

    .period-name {
      height: fit-content !important;
    }

    .period-container {
      @include flex(center, space-between, column);
      border-radius: 20px;
      background-color: #f4f5f6;
      padding: 10px;
    }

    .period-image-container {
      @include flex(center, flex-end, column);
    }
  }

  .secondaryBtn {
    @include mondayButton(#1264a3, #0f5c97);
  }

  .dangerBtn {
    @include mondayButton(#fe4c4a, #eb4345);
  }

  .schedule-simple-selection:last-child {
    margin-bottom: 0;
  }
  *:not(.ag-icon):not(.ag-checkbox-input-wrapper.ag-checked:after) {
    font-family: "Lato", sans-serif !important;
  }
  .ant-modal-close-x {
    color: #f5f5f5;
  }

  .dataEntryTitle {
    font-size: 28px !important;
    margin-left: 25px;
    color: #f5f5f5;
  }

  .ant-switch {
    border-color: #4a4a4a;
  }
  .ant-modal-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100dvh;
    border-radius: 0px;
    .ant-modal-header {
      display: flex;
      align-items: center;
      width: 100%;
      height: 45px;
      background-color: #1e2a44;
      border-radius: 0px;
      .ant-modal-title {
        width: 100%;
        color: #f5f5f5;
        .scheduleModalHeader {
          display: flex;
          align-items: center;
          flex-direction: row;
          height: 45px;
          background: #1f2a44;
          color: #fff;
          width: 100%;
          justify-content: space-between;
        }
      }
    }
  }
  .ant-modal-body {
    border-radius: 0;
    padding: 0 !important;
    overflow-y: auto;
    background-color: #f4f5f6;
    height: -webkit-fill-available;
    .scheduleStepperContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      object-fit: contain;
      height: 56px;
      background: #fff;
      margin: 10px 10px 0px 10px;
      border-radius: 10px;
      .antCustomStepper {
        display: flex;
        align-items: center;
      }
    }
    .form-section {
      // display: flex;
      // width: 609px;
      // flex: 2;
      // border-radius: 10px;
      // background-color: #fff;
      // margin-top: 10px;
      // padding: 0px 10px;
      .formSectionLabel {
        font-weight: 600;
      }
      .labeledInputContainer {
        width: 100%;
        max-width: 437px;
      }
    }
    .filter-schedules {
      width: 609px;
      flex: 1 1 40%;
      background: #fff;
      border-radius: 10px;
      margin-top: 10px;
      .filter-schedules-header {
        display: flex;
        justify-content: space-around;
        .button-filter-schedule {
          margin-top: 15px;
          .filterButton {
            border-radius: 2px;
            border: none;
          }
        }
      }
      .specificSelectedDate {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #eff4ff;
        height: 34px;
        margin-top: 15px;
        span {
          font-size: 16px;
          color: #323338;
          line-height: 22px;
        }
      }
      .filterTableContainer {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 100%;
        .filterTableHeader {
          width: 100%;
          th {
            font-weight: 600;
            font-size: 16px;
            max-width: 280px;
            width: 400px;
          }
        }
        .filterTableBody {
          height: 325px;
          overflow: auto;
        }
      }
    }
    .form-section-table-Data {
      width: -webkit-fill-available;
      display: flex;
      flex-direction: column;
      transition: all 0.5s ease;
      .table-data-container {
        display: flex;
        width: -webkit-fill-available;
        gap: 10px;
        height: 100%;
        .table-data-card {
          margin-top: 10px;
          width: 100%;
          height: -webkit-fill-available;
          border-radius: 10px;
          background: #fff;
          transition: all 0.5s ease;
          .card-content {
            display: flex;
            justify-content: center;
            flex-direction: column;
            gap: 0.5rem;
            align-items: center;
            height: 100%;
            margin: auto;
            .noDataMessage {
              font-size: 25px;
              font-weight: 600;
            }
          }
        }
      }
      .weatherContainer {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        justify-content: center;
        background: #ffffff;
        height: 67px;
        margin-top: 10px;
        border-radius: 10px;
        padding: 10px;
        transition: all 0.5s ease;
        .weatherItem {
          display: flex;
          flex-direction: column;
          align-items: center;
          .dayName {
            // font-weight: 600;
            font-size: 12;
            width: 100%;
            color: #323338;
          }
        }
      }
    }
    .schedule-data-details-container {
      height: 100%;
      .schedule-details-header {
        display: flex;
        // justify-content: center;
        align-items: center;
        height: 45px;
        border-radius: 10px 10px 0px 0px;
        padding-left: 10px;
        background-color: #f2f2f2;

        .addressName {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: row;
          gap: 1rem;
          font-weight: 600;
          font-size: 16px;
        }
      }
      .ag-body {
        height: fit-content !important;
        max-height: 680px;
        overflow: auto !important;
      }
      // .schedule-details-table {
      //   width: 100%;
      //   overflow: auto;
      //   table {
      //     width: 100%;
      //     border-collapse: collapse;
      //     th {
      //       font-weight: 600;
      //       font-size: 16px;
      //       color: #323338;
      //       padding-left: 0.3rem;
      //     }
      //   }
      // }
    }
    .scheduleDaysStep {
      height: 100%;
      display: flex;
      .helper-buttons-innerDay-form {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 10px;

        #days-container-scroll {
          height: fit-content;
          width: 440px;
          overflow-y: auto;
          &::-webkit-scrollbar {
            width: 5px !important;
            height: 5px !important;
          }
        }
        .schedule-day-card {
          .inner-schedule {
            // &:hover {
            //   background-color: #323c5d !important;
            // }
            background-color: #fff;
            .delete-schedule-day-X svg {
              margin-right: -5px;
            }
            .date-values-schedule {
              .values-container {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                .value-title {
                  font-weight: 600;
                }
                // .ant-btn {
                //   background-color: #3f598b !important;
                // }
                // .ant-picker {
                //   background-color: $cardDarkBackgroundColor !important;
                //   .ant-picker-input {
                //     background-color: $cardDarkBackgroundColor;
                //     input {
                //       color: #f2f2f2;
                //       &::placeholder {
                //         color: #acacca;
                //       }
                //     }
                //     .ant-picker-suffix svg {
                //       color: #acacca;
                //     }
                //   }
                // }
              }
            }
            // .bottom-structure-day-inner {
            //   .day-hours {
            //     color: #fff;
            //   }
            // }
          }
          // .collapse-of-missing-days {
          //   .ant-collapse-header {
          //     background-color: #3f4155;
          //     .ant-collapse-expand-icon svg path,
          //     .ant-collapse-header-text {
          //       fill: #fff;
          //       color: #fff;
          //     }
          //   }
          //   .add-day-div {
          //     background-color: #64a747;
          //     border-color: #337218;
          //     .ant-btn {
          //       background-color: #64a747 !important;
          //     }
          //   }
          //   .weekend {
          //     background-color: #e65558;
          //     border-color: #b4171a;
          //     .ant-btn {
          //       background-color: #e65558 !important;
          //     }
          //   }
          // }
        }
        .schedule-day-card:nth-child(even) {
          .inner-schedule {
            background-color: #ededed;
          }
        }
      }
      .services-schedule-container-data {
        width: 100%;
        overflow-y: auto;
        padding-top: 10px;
      }
    }
    .services-schedule-container-data {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .service-menu-wrapper {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
        .est-progress-status-schedule .est-progress-status-schedule-body {
          width: 100%;
          display: flex;
          flex-direction: column;
          padding: 5px 5px 0px 5px;
        }
      }
    }
  }
  .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    background-color: #f5f5f5 !important;
    width: 3px;
  }
  .ant-tabs-bar {
    border-color: #4a4a4a !important;
  }
  // .ant-modal-footer {
  //   position: relative;
  //   bottom: 55px;
  // }
  .ant-tabs-tab {
    transition: 250ms;
    margin: 0 !important;
    padding: 0 !important;
    border-bottom: solid 1px #4a4a4a;
    &:first-child {
      border-top: solid 1px #4a4a4a;
    }
    .tabText {
      color: #aeaeae;
      transition: 200ms;
      font-weight: bold;
      font-size: 15px;
      writing-mode: vertical-rl;
      text-orientation: mixed;
      transform: rotate(180deg);
      &:hover {
        transition: 400ms;
        color: #f5f5f5;
      }
    }
  }
  .ant-tabs-tab-active {
    .tabText {
      color: #ffffff;
    }
  }
  .site-page-header {
    border: 2px solid rgb(235, 237, 240);
    color: #32325d;
    font-weight: 600;
    font-size: medium;
    vertical-align: middle;
    height: 35px;
    margin: 10px 15px;
  }
  .ant-tabs-content {
    border: 0;
  }
  .optionText {
    color: #f5f5f5;
    font-size: 20px;
    border-top: solid 1px #dfe6ee;
    margin-top: 15px;
    padding-top: 10px;
  }
  .gridResizeHandler {
    transition: 300ms;
    background-color: transparent;
    padding-bottom: 2px;
    cursor: ns-resize;
    border-radius: 0 0 2px 2px;
    &:hover {
      padding-bottom: 5px;
      background-color: #12a3fa !important;
    }
    &:active {
      background-color: #09659b !important;
    }
  }
}

// Dark Mode
.scheduleModalDark {
  .ant-modal-content {
    background-color: $cardDarkBackgroundColor;
    .ant-modal-header {
      background: #101c35;
      .ant-modal-title .scheduleModalHeader {
        background: #101c35;
      }
    }
    .ant-modal-body {
      background-color: $cardDarkBackgroundColor;
      .scheduleStepperContainer {
        background-color: $cardDarkHeaderColor;
        .ant-steps {
          .ant-steps-item-finish ::before,
          .ant-steps-item-active::before,
          .ant-steps-item::before {
            border-left-color: $cardDarkHeaderColor;
          }
          .ant-steps-item-wait {
            background-color: $cardDarkBackgroundColor;
            // background-color: $mainDarkBackgroundColor;
            &::after {
              border-left-color: $cardDarkHeaderColor;
            }
            .ant-steps-item-content .ant-steps-item-title {
              color: #acacca;
            }
          }
        }
      }
      .form-section {
        background-color: $cardDarkHeaderColor;
        .formSectionLabel {
          color: #fff;
        }
        .ant-select {
          .ant-select-selector {
            background-color: $cardDarkBackgroundColor !important;
            .ant-select-selection-search-input {
              color: #fff;
            }
            .ant-select-selection-item {
              color: #fff;
            }
            .ant-select-selection-placeholder {
              color: #acacca;
            }
            .ant-select-selection-overflow-item {
              .ant-select-selection-item {
                background-color: $mainDarkBackgroundColor;
              }
              .ant-select-selection-item-remove {
                svg {
                  fill: #fff;
                }
              }
            }
          }
          .ant-select-clear {
            background-color: $cardDarkBackgroundColor;
            svg {
              fill: #acacca;
            }
          }
          .ant-select-arrow svg {
            fill: #fff;
          }
        }
        .ant-input {
          background-color: $cardDarkBackgroundColor !important;
          color: #fff;
          &::placeholder {
            color: #acacca;
          }
        }
      }
      .filter-schedules {
        background-color: $cardDarkHeaderColor;
        .specificSelectedDate {
          background-color: #323c5d;
          span {
            color: #fff;
          }
        }
        .filterTableContainer {
          .filterTableHeader th,
          .filterTableBody td {
            color: #fff;
          }
        }
      }
      .form-section-table-Data {
        .table-data-card {
          background-color: $cardDarkHeaderColor;
          .card-content {
            svg {
              g {
                path {
                  fill: $mainDarkBackgroundColor;
                }
              }
            }
            .noDataMessage {
              color: #fff;
            }
          }
        }
        .weatherContainer {
          background-color: $cardDarkHeaderColor;
          .weatherItem .dayName {
            color: #fff;
          }
        }
      }
      .schedule-data-details-container {
        .schedule-details-header {
          background-color: $cardDarkHeaderColor;
        }
        .schedule-details-header .addressName {
          color: #fff;
        }
        .schedule-details-table {
          table {
            th {
              color: #fff;
            }
            .schedule-data-details-rowData {
              td {
                border-color: gray !important;
              }
              td,
              .ant-badge-status-text {
                color: #fff !important;
              }
            }
          }
        }
      }
      .scheduleDaysStep {
        .helper-buttons-innerDay-form {
          .navbar-buttons-schedule {
            background-color: $cardDarkHeaderColor;
          }
          .schedule-day-card {
            .inner-schedule {
              background-color: $cardDarkHeaderColor;
              .delete-schedule-day-X svg {
                fill: #fff;
              }
              &:hover {
                background-color: #323c5d !important;
              }
              .date-values-schedule {
                .values-container {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  .value-title {
                    font-weight: 600;
                  }
                  .ant-btn {
                    background-color: #3f598b !important;
                  }
                  .ant-picker {
                    background-color: $cardDarkBackgroundColor !important;
                    .ant-picker-input {
                      background-color: $cardDarkBackgroundColor;
                      input {
                        color: #f2f2f2;
                        &::placeholder {
                          color: #acacca;
                        }
                      }
                      .ant-picker-suffix svg {
                        color: #acacca;
                      }
                    }
                  }
                }
              }
              .bottom-structure-day-inner {
                .day-hours {
                  color: #fff;
                }
              }
              .date-values-schedule {
                .values-container {
                  .value-title {
                    color: #fff;
                  }
                }
              }
            }
            .collapse-of-missing-days {
              .ant-collapse-header {
                background-color: #3f4155;
                .ant-collapse-expand-icon svg path,
                .ant-collapse-header-text {
                  fill: #fff;
                  color: #fff;
                }
              }
              .add-day-div {
                background-color: #64a747;
                border-color: #337218;
                .ant-btn {
                  background-color: #64a747 !important;
                }
              }
              .weekend {
                background-color: #e65558;
                border-color: #b4171a;
                .ant-btn {
                  background-color: #e65558 !important;
                }
              }
            }
          }
          .schedule-day-card:nth-child(even) {
            .inner-schedule {
              background-color: $mainDarkBackgroundColor;
            }
          }
        }
      }
      .services-schedule-container-data {
        .service-menu-wrapper {
          .est-progress-status-schedule {
            background-color: $cardDarkHeaderColor;
            .est-progress-status-schedule-body {
              span {
                color: #fff;
              }
            }
          }
          .serviceSchedulingSiderMenu {
            background-color: $cardDarkHeaderColor;
            .dragable-services-container {
              background-color: $cardDarkBackgroundColor !important;
              .dragable-services-label {
                color: #fff !important;
              }
            }
          }
        }
        .serviceContainerSch {
          background-color: $cardDarkHeaderColor;
          .ant-badge-status-text span {
            color: #fff;
          }
          .services-panel-header {
            .ant-collapse-header {
              background-color: $mainDarkBackgroundColor;
              .ant-collapse-expand-icon svg {
                fill: #fff;
              }
              .ant-collapse-header-text {
                color: #fff;
              }
              .ant-select {
                .ant-select-selector {
                  background-color: $cardDarkBackgroundColor !important;
                  .ant-select-selection-search-input {
                    color: #fff;
                  }
                  .ant-select-selection-item {
                    color: #fff;
                  }
                  .ant-select-selection-placeholder {
                    color: #acacca;
                  }
                  .ant-select-selection-overflow-item {
                    .ant-select-selection-item {
                      background-color: $mainDarkBackgroundColor;
                    }
                    .ant-select-selection-item-remove {
                      svg {
                        fill: #fff;
                      }
                    }
                  }
                }
                .ant-select-clear {
                  background-color: $cardDarkBackgroundColor;
                  svg {
                    fill: #acacca;
                  }
                }
                .ant-select-arrow svg {
                  fill: #fff;
                }
              }
            }
            .ant-collapse-content {
              background-color: $cardDarkHeaderColor;
              border-top-color: $cardDarkBackgroundColor;
              .hoist-elements div {
                color: #fff !important;
              }
              .scheduleGrid {
                .ag-column-drop-wrapper .ag-column-drop {
                  background-color: $mainDarkBackgroundColor !important;
                }
                .ag-root-wrapper.ag-layout-normal {
                  background-color: $cardDarkHeaderColor;
                }
                .ag-root-wrapper-body {
                  .ag-header {
                    background-color: $mainDarkBackgroundColor;
                    .ag-header-cell-resize::after {
                      background-color: #f2f2f2;
                    }
                    .ag-header-cell {
                      background-color: $mainDarkBackgroundColor !important;
                      .ag-header-cell-text {
                        color: #fff;
                      }
                    }
                    .ag-header-row .ag-header-group-cell {
                      background-color: #1c1d27 !important;
                      color: #fff;
                    }
                  }
                  .ag-body {
                    background-color: $cardDarkHeaderColor !important;
                    .ag-cell {
                      color: #fff;
                      svg {
                        fill: #fff;
                      }
                      .ant-select {
                        .ant-select-selector {
                          background-color: $cardDarkBackgroundColor !important;
                          .ant-select-selection-search-input {
                            color: #fff;
                          }
                          .ant-select-selection-item {
                            color: #fff;
                          }
                          .ant-select-selection-placeholder {
                            color: #acacca;
                          }
                          .ant-select-selection-overflow-item {
                            .ant-select-selection-item {
                              background-color: $mainDarkBackgroundColor;
                            }
                            .ant-select-selection-item-remove {
                              svg {
                                fill: #fff;
                              }
                            }
                          }
                        }
                        .ant-select-clear {
                          background-color: $cardDarkBackgroundColor;
                          svg {
                            fill: #acacca;
                          }
                        }
                        .ant-select-arrow svg {
                          fill: #fff;
                        }
                      }
                    }
                    .ag-row-even {
                      background-color: #1b1b25;
                    }
                    .ag-row-odd {
                      background-color: $cardDarkHeaderColor;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .ant-modal-footer {
      color: #fff;
    }
  }
}
//Dark Mode

.canceled-day-popover {
  .ant-popover-inner-content {
    padding: 0 !important;
    width: 440px;
    padding: 10px;

    .form-group {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
  }
}

.this-day-weather-popover {
  .ant-popover-inner {
    .ant-popover-title {
      display: flex;
      align-items: center;
      padding-top: 0px !important;
    }
  }
  .ui-tablist-item h4 {
    font-size: 14px;
  }
}

.scheduleStepperContainer {
  animation: step 0.5s;
}

.goToPrevSchButton {
  animation: goToPrevSchButton 0.5s;
}
.previousStep {
  animation: previousStep 0.5s;
}
.nextStep {
  animation: nextStep 0.5s;
}
.disabledSaveButton {
  animation: nextStep 0.5s;
}
.notDisabledSaveButton {
  animation: saveButton 0.5s;
}
// @keyframes saveButton {
//   from {
//     opacity: 0;
//     filter: blur(2px);
//   }
//   to {
//     opacity: 1;
//     filter: blur(0);
//   }
// }

// @keyframes nextStep {
//   from {
//     opacity: 0;
//     filter: blur(2px);
//     transform: translateX(-50px);
//   }
//   to {
//     opacity: 1;
//     filter: blur(0);
//     transform: translateX(0px);
//   }
// }
// @keyframes previousStep {
//   from {
//     opacity: 0;
//     filter: blur(2px);
//     transform: translateX(50px);
//   }
//   to {
//     opacity: 1;
//     filter: blur(0);
//     transform: translateX(0px);
//   }
// }
// @keyframes goToPrevSchButton {
//   from {
//     opacity: 0;
//     filter: blur(2px);
//     transform: translateX(-50px);
//   }
//   to {
//     opacity: 1;
//     filter: blur(0);
//     transform: translateX(0px);
//   }
// }

// @keyframes step {
//   from {
//     opacity: 0;
//     filter: blur(2px);
//     transform: translateY(-50px);
//   }
//   to {
//     opacity: 1;
//     filter: blur(0);
//     transform: translateY(0px);
//   }
// }

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .form-row {
    width: 92%;
  }
}

@media (min-width: 450px) {
  .scheduleModal .schedule-main-row .step-0 .selection-form {
    display: grid !important;
  }
}
.selectPreviewSchedule {
  .ant-form-item {
    margin-bottom: 0 !important;
  }
}
