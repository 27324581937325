.maskLoadingDiamonds {
  background: rgba(33, 37, 75, 0.2) !important;
  backdrop-filter: blur(2px) !important;
  z-index: 9990;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .loadingDiamonds {
    transform: rotate(45deg);
    width: 110px;
    height: 110px;
    border: 10px solid #35415e;
    z-index: 9999;
    span {
      width: 30px;
      height: 30px;
      background: #35415e;
      display: block;
      float: left;
      animation: animate 2s linear infinite;
    }
    span:nth-child(3) {
      animation-delay: 0.8s;
    }
    span:nth-child(2),
    span:nth-child(6) {
      animation-delay: 0.6s;
    }
    span:nth-child(1),
    span:nth-child(5),
    span:nth-child(9) {
      animation-delay: 0.4s;
    }
    span:nth-child(4),
    span:nth-child(8) {
      animation-delay: 0.2s;
    }
    span:nth-child(7) {
      animation-delay: 0s;
    }
    @keyframes animate {
      0% {
        transform: scale(1);
      }
      30% {
        transform: scale(0);
      }
      50% {
        transform: scale(0);
      }
      75% {
        transform: scale(1);
      }
      100% {
        transform: scale(1);
      }
    }
  }
  .loadingDiamondsDark {
    border-color: #acacca;
    span {
      background-color: #acacca;
    }
  }
}

.fullWidth {
  width: 100vw !important;
  left: 0;
}
