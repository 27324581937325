@use "/src/index.scss" as index;

.map-sidebar {
  transition: 0.3s ease-out;
  border-right: 1px solid #dee2eb;
  &.open-sidebar {
    width: 380px !important;
    min-width: 380px !important;
  }
  &.closed-sidebar {
    width: 50px !important;
    min-width: 50px !important;
  }
  .sidebar-control-panel {
    height: 200px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
    padding-bottom: 10px;
    .menu-icon {
      height: 51px;
      width: 51px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      svg {
        height: 20px;
        width: 20px;
        fill: #000 !important;
      }
    }
    .data-type-select {
      height: 51px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border-bottom: 1px solid #dee2eb;
      background-color: #f5f6f7;
      .sidebar-tabs {
        height: 100%;
        width: 100%;
        display: flex;
        .tab-item {
          height: 100%;
          width: calc(100% / 3);
          display: flex;
          align-items: center;
          justify-content: center;
          transition: 0.3s ease-in;
          color: #acacac;
          cursor: pointer;
          user-select: none;
          transition: 0.3s ease;
          &:hover {
            color: index.$blue;
            font-weight: 600;
          }
          &.active {
            color: #323338;
            font-weight: 600;
          }
        }
      }
    }
    .search-field {
      padding: 0px 10px 10px 10px;
      .sorting-buttons {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 10px;
        .mondayButtonStyle {
          width: 100px;
          .mondayButtonText {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
          }
        }
      }
      .ant-form-item {
        margin-bottom: 0px;
      }
      .data-total-count {
        height: 30px;
        display: flex;
        align-items: flex-end;
      }
    }
  }
  .data-holder-container {
    width: 100%;
    display: flex;
    overflow-y: auto;
    flex-direction: column;
    // height: calc(100% - 130px);
    height: calc(100% - 200px);
    padding: 2px 10px 10px 10px;
  }
}

.map-sidebar-dark {
  border-right: 1px solid #616481;
  .sidebar-control-panel {
    .menu-icon svg {
      fill: #fff !important;
    }
    .data-type-select {
      border-bottom: 1px solid #616481;
      background-color: index.$cardDarkBackgroundColor;
      .sidebar-tabs {
        height: 100%;
        width: 100%;
        display: flex;
        .tab-item {
          color: #acacca;
          &.active {
            color: #fff;
          }
        }
      }
    }
    .ant-slider .ant-slider-rail {
      background-color: #616481 !important;
    }
  }
}

@media (max-width: 1024px) {
  .map-sidebar {
    &.open-sidebar {
      width: 250px !important;
      min-width: 250px !important;
    }
    .sidebar-control-panel {
      .data-type-select {
        .sidebar-tabs .tab-item {
          font-size: 12px;
        }
      }
    }
  }
}
