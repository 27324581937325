// @import "../../../../../../index.scss";

// .helper-buttons {
//   @include flex(center, space-evenly);
//   position: relative;
//   top: 50px;
//   width: 770px;
//   margin-bottom: 80px;
//   margin-left: 2px;
//   background-color: #fff;
//   border-radius: 20px;
//   padding: 10px;
//   box-shadow: 0px 1px 4px #00000029;
//   .helper-buttons-structure {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     flex-direction: row;
//     gap: 1.5rem;
//   }

//   .logs {
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//   }

//   .settingIcon {
//     height: 25px;
//     cursor: pointer;
//   }

//   .notesIcon {
//     height: 25px;
//     width: 25px;
//   }

//   .add-remove-row-container-2 {
//     & *:not(:last-child) {
//       margin-right: 20px;
//     }
//   }

//   .dayBtn {
//     background-color: #f5f5f7;
//     color: #323338;
//     width: 60px;
//     height: 25px;
//     text-align: center;
//     padding: 0 10px;
//     border-radius: 5px;
//   }

//   .notificationIcon {
//     cursor: pointer;
//   }

//   .calendarIcon {
//     cursor: pointer;
//   }

//   .schedule-settings-dialog-button {
//     padding: 0;

//     &:hover {
//       background: #ffffff;
//     }
//   }

//   .schedule-form-notes-drawer-button {
//     margin: auto 0;
//     cursor: pointer;
//   }
// }

.navbar-buttons-schedule {
  width: 440px;
  display: flex;
  // justify-content: center;
  // width: 420px;
  min-height: 42px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  padding: 0px 10px;

  .buttons-schedule-structure {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    gap: 0.5rem;
  }
  .add-day-btn-schedule {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    background: #71cf48 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    color: #ffffff;
    border: none;
    cursor: pointer;
    &:hover {
      background: #71cf48 0% 0% no-repeat padding-box;
      border-radius: 5px;
      opacity: 1;
      color: #ffffff;
      border: none;
    }
    svg {
      width: 20px;
      height: 20px;
    }
  }
  .remove-day-schedule {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    color: #ffffff;
    background: #fe4c4a 0% 0% no-repeat padding-box;
    border: none;
    border-radius: 5px;
    opacity: 1;
    cursor: pointer;
    // font-size: 24px;
  }
}
