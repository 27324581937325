.multiple-dispatch-addresses-modal {
  width: fit-content !important;
  height: fit-content !important;

  .ant-modal-content {
    width: 750px;
    height: fit-content;

    max-width: 90vw;
    max-height: 90vh;

    .ant-modal-header {
      height: 45px;
      display: flex;
      align-items: center;
      background-color: #f5f5f7;
      .ant-modal-title {
        font-weight: 600;
        font-size: 16px;
        color: #333238;
      }
    }

    .ant-modal-body {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      gap: 10px;

      height: fit-content;
      max-height: calc(90vh - 120px);
      overflow-y: auto;

      .info-section {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 10px;
        flex-wrap: nowrap;
      }

      .multiple-dispatches-grid {
        height: -moz-fill-available;
        height: -webkit-fill-available;
        width: 100%;
      }
    }

    .ant-modal-footer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      gap: 10px;
    }
  }
}

.multiple-dispatch-addresses-modal.multiple-addresses-dark {
  .ant-modal-content {
    background-color: #1f212d;
    color: #ffffff;

    .ant-modal-header {
      background-color: #12131b;
      .ant-modal-title {
        color: #ffffff;
      }
    }

    .ant-modal-close {
      .ant-modal-close-x {
        svg {
          fill: #ffffff;
        }
      }
    }
  }
}
