.includeRentalFooterDarkMode {
  height: 45px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  color: white;
  font: normal normal 600 16px/22px Open Sans;
  border-top: 1px solid #4a4a4a;
}

.includeRentalFooterLightMode {
  height: 45px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  color: black;
  font: normal normal 600 16px/22px Open Sans;
  border-top: 1px solid #dcdfe3;
}
