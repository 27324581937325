$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.users-details-modal {
  width: unset !important;

  //   max-width: 60vw;

  .ant-modal-content {
    .ant-modal-close {
      height: 45px;
      width: 45px;
      .ant-modal-close-x {
        height: 45px;
        width: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .ant-modal-header {
      height: 45px;
      display: flex;
      align-items: center;
      background-color: #f8f8fa;
    }
  }
  .ant-modal-body {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
    max-height: 85vh;
    overflow-y: auto;
    .user-details-item {
      display: flex;
      align-items: center;
      gap: 10px;
      .user-labels {
        display: flex;
        flex-direction: column;
        .user-userName {
          font-size: 16px;
          font-weight: 600;
          color: #323338;
        }
        .user-data {
          font-size: 14px;
          font-weight: 400;
          color: #323338;
        }
      }
      &:hover {
        cursor: pointer;
        .user-userName {
          color: #1264a3;
        }
      }
    }
  }
}

.users-details-modal-dark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      .user-labels {
        .user-userName,
        .user-data {
          color: #fff;
        }
      }
    }
  }
}
