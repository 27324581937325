.collapse-of-missing-days {
  // width: 430px;
  // background: #f8f8fa 0% 0% no-repeat padding-box;
  // background: red;

  .missing-days-collapse {
    .ant-collapse-header {
      background-color: #e8e8ec;
      border-radius: 0px !important;
    }
    .ant-collapse-header-text {
      color: black;
    }
  }
  .missingDays-container-panel {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .add-day-div {
    display: flex;
    color: #323338;
    align-items: center;
    border: 1px dashed #52a42f;
    background-color: #b1e29e;
    cursor: pointer;

    &.weekend {
      display: flex;
      color: #323338;
      align-items: center;
      border: 1px dashed #eb4345;
      background-color: #f8a09f;
      cursor: pointer;
    }

    // &:hover {
    //   box-shadow: inset 0 0 15px 6px #feff83b3;
    //   cursor: pointer;
    // }
  }
}
