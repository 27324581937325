$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.schedRentalTimelineDark {
  .ant-timeline-item-label {
    color: #fff;
  }
  .ant-timeline-item-tail {
    border-inline-start: 2px solid #acacca;
  }
  .ant-timeline-item-head {
    background-color: $cardModalBodyDark;
  }
  .ant-card {
    border: none;
    background-color: $cardModalHeaderDark;
    .ant-card-body {
      background-color: $cardModalHeaderDark !important;
      color: #fff;
    }
  }
}
