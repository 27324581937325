.live-map-controller {
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding-left: 20px;
  padding-right: 20px;
  border-bottom: 1px solid #dee2eb;
  overflow-x: auto;
  transition: width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
  gap: 10px;

  .map-side-toggle-icon {
    height: 80%;
    aspect-ratio: 1;
    transition: background-color 0.2s ease-in-out;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      cursor: pointer;
      background-color: rgba(60, 60, 77, 0.1);
    }
  }

  .locations-input {
    min-width: 300px;
    .ant-select {
      width: 100%;
    }
  }

  .list-controls {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    gap: 0.5rem;

    .ant-select-selector {
      border: 0;
      background-color: #f4f5f6;
    }

    .ant-select-selection-overflow {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;
      flex-wrap: nowrap;
      max-width: 200px;
      min-width: 150px;
      overflow-x: auto;
      overflow-y: hidden;
    }

    .map-side-toggle-icon {
      position: sticky;
      left: 0;
      z-index: 1;
      height: 80%;
      aspect-ratio: 1;
      flex-shrink: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      transition: background-color 0.2s ease-in-out;
      background-color: #fff;

      &:hover {
        cursor: pointer;
        background-color: rgba(60, 60, 77, 0.1);
      }
    }
  }

  .map-controls {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    gap: 0.5rem;

    .ant-select-selector {
      border: 0;
      background-color: #f4f5f6;
    }
  }
}

.live-map-controller.map-controller-dark {
  background-color: #20212d;
  border-bottom: 1px solid #616481;
  color: #fffeff;

  .ant-select {
    .ant-select-selector {
      background-color: #3a3c4e !important;
      color: #f5f5f7;
      border: 0;
    }

    .ant-select-selection-placeholder {
      color: #f5f5f7a9;
    }

    .ant-select-clear {
      background-color: #3a3c4e;
      svg {
        fill: #acacca;
      }
    }
  }

  .map-side-toggle-icon {
    &:hover {
      cursor: pointer;
      background-color: rgb(60, 60, 77);
    }
  }

  .ant-select-selector {
    background-color: #3a3c4e;
    color: #f5f5f7;
    border: 0;
  }

  .ant-select-clear {
    background-color: #3a3c4e;
    svg {
      fill: #acacca;
    }
  }

  .ant-select-selection-placeholder {
    color: #f5f5f7a9;
  }

  .ant-select-arrow,
  .ant-picker-suffix {
    color: #f5f5f7;
  }

  .ant-picker {
    background-color: #3a3c4e;
    color: #f5f5f7;
    border: 0;

    .ant-picker-input {
      input {
        color: #f5f5f7;
      }
    }
  }

  .map-side-toggle-icon {
    background-color: #20212d;
    color: #fffeff;
  }
}
