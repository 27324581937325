$mainDarkBackgroundColor: #20212d;
$cardDarkBackgroundColor: #12131b;
$inputDarkColor: #2a2b3a;

.tableTemplate {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  .tableTemplate-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #bbbfc7;
    span {
      font-size: 16px;
      font-weight: 600;
      color: #323338;
      padding-left: 10px;
      display: flex;
      align-items: center;
      margin: 15px 0;
      height: 15px;
      &:not(:last-child) {
        border-right: 2px solid #dddfe3;
      }
      &:not(:first-child) {
        padding-left: 20px;
      }
    }
  }
  .tableTemplate-body {
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;

    .tableTemplate-item {
      height: 40px;
      padding: 10px;
      border-bottom: 1px solid #dee2eb;
      display: flex;
      justify-content: space-between;

      &:nth-child(odd) {
        background-color: #fcfcfc;
      }
      &:nth-child(even) {
        background-color: #fff;
      }
      &:last-child {
        border-bottom: none;
      }
      &:hover {
        background-color: #f1f5fd;
        cursor: pointer;
      }
      span {
        &:not(:first-child) {
          text-align: center;
        }
      }
    }
    .tableTemplate-item.active-row {
      background-color: #f1f5fd;
    }
  }
}
.tableTemplateDark {
  background-color: $cardDarkBackgroundColor;
  color: #fff;
  .tableTemplate-header {
    span {
      color: #fff;
    }
  }
  .tableTemplate-body {
    background-color: $cardDarkBackgroundColor;
    .tableTemplate-item {
      &:nth-child(odd) {
        background-color: lighten($cardDarkBackgroundColor, 4%) !important;
      }
      &:nth-child(even) {
        background-color: $cardDarkBackgroundColor !important;
      }
      &:hover {
        background-color: lighten($cardDarkBackgroundColor, 10%) !important;
      }
    }
    .tableTemplate-item.active-row {
      background-color: lighten($cardDarkBackgroundColor, 10%) !important;
    }
  }
}
