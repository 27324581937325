.inactivity-edit-card {
  width: calc(100vw - 60px);
  max-width: 920px;

  height: calc(100vh - 100px);
  max-height: 520px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 10px;

  .inactivity-controller {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 10px;
    overflow-y: hidden;
    overflow-x: auto;
    width: 100%;
  }

  .inactivity-body {
    height: calc(100% - 140px);
    width: 100%;

    overflow-y: auto;

    .form-editor {
      width: 100%;
      .ant-form-item {
        margin-bottom: 0;

        .ant-picker {
          width: 100%;
        }
      }
    }
  }
}

.inactivity-edit-card.inactivity-edit-dark {
  .labeledInputLabel {
    color: #fff;
  }
  .ant-picker-input-placeholder {
    input {
      color: #cacaca;
    }
  }
}
