.nearby-places {
  display: flex;
  flex-direction: column;
  gap: 10px;
  .places-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .place-card {
      background-color: #f4f5f6;
      border: 1px solid #f4f5f6;
      border-radius: 5px;
      padding: 10px;
    }
    .place-card.selected {
      background-color: #f1f5fd;
      border: 1px solid #1264a3;
    }
  }
}

.nearby-places.nearby-places-dark {
  color: #fff;
  .place-card {
    background-color: #2a2b3a;
    border: 1px solid #2a2b3a;
  }
}
