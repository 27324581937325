@import "../../../../index.scss";

$darkBackground: #12131b;
$inputDarkColor: #2a2b3a;

.shortcutWrapper {
  width: 100%;
  height: 100%;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;

    // * hide scroll for mobile dimensions
    @media only screen and (max-width: 426px) {
      ::-webkit-scrollbar {
        display: none;
      }
    }
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: #35415e;
    border-radius: 5px;

    &:hover {
      background-color: #35415e;
    }
  }

  .shortcutHeader {
    h5 {
      margin: 0;
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #ffffff;
      background: transparent;
    }

    .newshortcutpopover-actions {
      display: flex;
      flex-direction: column;
      gap: 10px;

      @media (min-width: 768px) {
        flex-direction: row;
      }

      .save-cancel-buttons-container {
        display: flex;
        justify-content: center;
        gap: 10px;
        width: 100%;

        &.action-happened {
          justify-content: space-between;
        }
      }
    }

    .search-Div {
      display: flex;
      align-items: center;
      height: 32px !important;
      position: relative;

      .mondayButtonContainer {
        button {
          &:disabled {
            color: #fff !important;
            background-color: #71cf48 !important;
          }
        }
      }

      .search-comp {
        width: 100% !important;
        .ant-select-selector {
          padding-left: 30px;
          width: 250px;
          border: none;
          border-radius: 5px;
          opacity: 1;

          .ant-select-selection-search-input {
            padding-left: 20px;
          }
        }
      }

      .SearchLogoIcon {
        position: absolute;
        top: 8px;
        left: 5px;
        opacity: 0.8;
      }
    }
  }

  .shortcutContent {
    height: 100% !important;
    overflow-x: auto;

    .categories {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;

      @media (min-width: 768px) {
        flex-direction: row;
        align-items: flex-start;
      }

      .category {
        display: flex;
        flex-direction: column;
        gap: 5px;
        padding-bottom: 10px;

        .categoryItems {
          display: flex;
          flex-direction: column;
          gap: 5px;
          width: auto;
          max-height: 600px;
          overflow-y: auto;
          min-height: 300px;

          //styling scrollbar
          &::-webkit-scrollbar-thumb {
            background: #505c76;
            border-radius: 5px;

            &:hover {
              background-color: #35415e;
            }
          }
        }
      }
    }
  }
}

.shortcutWrapperDark {
  .shortcutHeader {
    .ant-select {
      .ant-select-selector {
        background-color: $inputDarkColor;

        .ant-select-selection-search-input {
          background-color: $inputDarkColor;
          color: #f2f2f2;
        }

        .ant-select-selection-placeholder {
          color: #acacca;
          z-index: 2;
        }
      }
    }

    .anticon svg {
      fill: #f2f2f2;
    }
  }

  .shortcutContent {
    .categories .category .categoryItems .labeledIconContainer {
      background-color: $inputDarkColor;

      &:hover {
        background-color: #21222e;
      }
    }
  }
}

.new-accounting-project-modal-dark {
  .ant-modal-content {
    background-color: $inputDarkColor;

    .ant-modal-close-x svg {
      fill: #fff;
    }

    .ant-modal-header {
      background-color: $darkBackground;

      .ant-modal-title {
        color: #fff;
      }
    }

    .accounting-project-modal {
      .labeledInputLabel {
        color: #fff;
      }

      .ant-select {
        .ant-select-selector {
          background-color: $darkBackground !important;

          .ant-select-selection-search-input {
            color: #fff;
          }

          .ant-select-selection-item {
            color: #fff;
          }

          .ant-select-selection-placeholder {
            color: #acacca;
          }
        }

        .ant-select-clear {
          background-color: $darkBackground;

          svg {
            fill: #acacca;
          }
        }

        .ant-select-arrow svg {
          fill: #fff;
        }
      }
    }
  }
}

@media (max-width: 1225px) {
  .shortcutContentContainer {
    display: grid;
    grid-template-columns: repeat(2, auto);
  }
}

@media (max-width: 1366px) and (max-height: 1024px) {
  .shortcutWrapper {
    .shortcutContent {
      .categories {
        .category {
          .categoryItems {
            .labeledIconContainer {
              width: 150px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 770px) {
  .ant-popover {
    .shortcutPopover.ant-popover-placement-right {
      left: 96px !important;
    }
  }
}

@media (max-width: 650px) {
  .ant-popover.shortcutPopover.ant-popover-placement-right {
    left: 96px !important;
    width: 350px !important;
  }

  .shortcutContentContainer {
    display: grid !important;
    gap: 10px;
  }
}

.accounting-project-modal {
  display: flex;
  flex-direction: column;
  width: 500px;

  .loader {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
  }

  .modalFooter {
    align-self: center;
  }
}

@media (max-width: 555px) {
  .accounting-project-modal {
    width: 400px;
  }
}

@media (max-width: 460px) {
  .accounting-project-modal {
    width: 300px;
  }
}
