@import "../../../../index.scss";

$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.inspection-modal {
  width: 100px;
}

.basePageModal {
  .ant-modal {
    width: 100% !important;
  }
  .ant-modal-content {
    border-radius: 10px;
    max-width: 750px;
    width: 100vw !important;
    overflow: hidden;
    margin: auto;
  }

  .ant-modal-header {
    @include flex(center, center);
    height: 45px;
    background-color: #f8f8f8;
    border: none;

    .ant-modal-title {
      @include text(16px, 600, 0.41px, #383554, capitalize);
    }
  }

  .ant-modal-body {
    // padding-bottom: 100px !important;
    height: auto !important;

    .dynamicBasePage {
      .filterContainers {
        margin-top: 10px;
      }
    }
    .inspectedServiceName {
      @include text(16px, 400, 0.41px, #636078);
      display: flex;
      align-items: center;
      span {
        margin-left: 20px;
      }
    }
  }

  .ant-modal-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
  }

  .basePageModalFooter {
    @include flex(center, flex-end);
    // @include absolute($bottom: 0, $left: 0, $right: 0);
    padding: 10px 20px;
    background-color: #fff;
    margin-top: 25px !important;

    button {
      height: 36px;
      box-shadow: 2px 2px 2px #00000012;
      border-radius: 5px;
      margin-left: 10px;
    }

    button:hover {
      color: #4791ff;
      background-color: #fff;
      border: 1px solid #4791ff;
    }

    .ant-btn[disabled]:hover {
      color: rgba(0, 0, 0, 0.25);
      background: #f5f5f5;
      border-color: #d9d9d9;
    }
  }

  .primaryFooterButton {
    color: #fff;
    background-color: #4791ff;
    border: 1px solid #4791ff;
  }

  .secondaryFooterButton {
    color: #383554;
    background-color: #f3f3f3;
    border: 1px solid #bfbfbf;
  }

  .disbledFooterButton {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    border-color: #d9d9d9;
  }
}

.noTitleBasePageModal {
  .ant-modal-header {
    display: none;
  }
}

.newInspectionModalDark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff !important;
      }
    }
    .ant-modal-body {
      .inspectedServiceName {
        color: #fff;
      }
      .labeledInputLabel {
        color: #fff;
      }
      .ant-select {
        .ant-select-selector {
          background-color: $inputModalDark !important;
          .ant-select-selection-search-input {
            color: #fff;
          }
          .ant-select-selection-item {
            color: #fff;
          }
          .ant-select-selection-placeholder {
            color: #acacca;
          }
        }
        .ant-select-clear {
          background-color: $inputModalDark;
          svg {
            fill: #acacca;
          }
        }
        .ant-select-arrow svg {
          fill: #fff;
        }
      }
      .ant-input {
        background-color: $inputModalDark !important;
        color: #fff;
        &::placeholder {
          color: #acacca;
        }
      }
      .ant-picker {
        background-color: $inputModalDark !important;
        .ant-picker-input {
          background-color: $inputModalDark;
          input {
            color: #f2f2f2;
            &::placeholder {
              color: #acacca;
            }
          }
          .ant-picker-suffix svg {
            color: #acacca;
          }
          .ant-picker-clear {
            background-color: $inputModalDark;
            svg {
              fill: #acacca;
            }
          }
        }
      }
      .basePageModalFooter {
        background-color: $cardModalBodyDark;
      }
    }
  }
}

@media (max-width: 960px) {
  .newInspectionModal {
    text-align: start !important;
    margin-left: 20px;
  }
}

@media (max-width: 780px) {
  .newInspectionModal {
    text-align: center !important;
    margin-left: 0px;
    .ant-modal-body {
      display: grid !important;
      grid-template-columns: 100% !important;
      min-width: 100% !important;
      width: 100% !important;
      .labeledInputContainer {
        min-width: 100% !important;
        width: 100% !important;
      }
    }
  }
}
