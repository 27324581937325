@import "../../../../../../../../assets/styles/breakpoints.scss";

.credit-header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .totalCreditHolder {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .saveChanges {
    height: 30px;
    span {
      font-weight: 500 !important;
    }
  }
  .disabled {
    background-color: white;
    cursor: not-allowed;
    .mondayButtonDivider {
      background-color: #e8e5e5;
    }
  }

  .textTitle {
    font-weight: 5px;
    padding: 5px 11px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;
  }
  .totalCreditInput {
    width: 100%;
    height: 30px;
    border: 1px solid #e8e5e5;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 0 10px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #fff !important;
    width: 100px;
    .ant-input-number-input {
      color: #fff !important;
    }
  }

  .headerTitleText {
    @include tablet {
      display: none;
    }
  }
}
