@use "/src/index.scss" as index;

.non-user-modal {
  height: calc(100vh - 20px) !important;
  width: calc(100vw - 20px) !important;
  max-height: 529px;
  max-width: 985px;
  .ant-modal-content {
    height: calc(100vh - 20px) !important;
    width: calc(100vw - 20px) !important;
    max-height: 529px;
    max-width: 985px;
    .ant-modal-close {
      height: 45px;
      width: 45px;
      .ant-modal-close-x {
        height: 45px;
        width: 45px;
        display: flex;
        align-items: center;
        justify-self: center;
      }
    }
    .ant-modal-header {
      height: 45px;
      display: flex;
      align-items: center;
      background-color: #f8f8fa;
    }

    .ant-modal-body {
      height: calc(100% - 100px);
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
      gap: 40px;
      .info-section {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 10px;
      }
      .form-container {
        width: 70%;
        .labeledInputLabel {
          font-size: 14px;
        }
        .form-header {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 24px;
          font-weight: 600;
          margin-bottom: 30px;
          gap: 10px;
          svg path {
            fill: index.$textColor !important;
          }
        }
        .ant-form {
          display: grid;
          grid-template-columns: calc(50% - 10px) calc(50% - 10px);
          column-gap: 20px;
        }
        .link-suggestion-card {
          height: 70px;
          width: 100%;
          display: flex;
          align-items: flex-end;
          flex-direction: column;
          justify-content: center;
          background-color: #f8f8fa;
          border-radius: 10px;
          padding: 0px 10px;
          animation: fadeInFromAbove 0.5s ease-out;
          .link-info {
            display: flex;
            align-items: center;
            gap: 10px;
            .info-text {
              width: calc(100% - 40px);
              b {
                font-weight: 600;
              }
              .link {
                cursor: pointer;
                margin-left: 20px;
                font-weight: 600;
                color: index.$blue;
              }
              .linked {
                color: index.$red;
              }
            }
          }
        }
      }
    }
    .ant-modal-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      .mondayButtonStyle {
        width: 150px;
      }
    }
  }
}

.eng-arch-warning {
  .ant-modal-content {
    .ant-modal-body {
      .logout-modal-body {
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
        span {
          width: 35px;
          height: 35px;
        }
        p {
          margin: 0.9rem 0;
          font-size: 15px;
        }
      }
      .buttons {
        display: flex !important;
        justify-content: center !important;
        gap: 10px !important;
        .mondayButtonContainer {
          .mondayButtonText {
            font-size: 14px;
          }
          .mondayButtonDivider {
            height: 100%;
          }
        }
        .decline-logout {
          background-color: #fe4c4a;
          margin-right: 10px;
          .mondayButtonDivider {
            background: #eb4345 !important;
          }
        }
      }
    }
  }
}

.non-user-modal-dark {
  .ant-modal-content {
    background-color: index.$mainDarkBackgroundColor;
    .ant-modal-close .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: index.$cardDarkBackgroundColor;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      .info-section {
        color: #fff;
      }
      .form-container {
        width: 70%;
        .labeledInputLabel {
          color: #fff;
        }
        .form-header {
          color: #fff;
          svg path {
            fill: #fff !important;
          }
        }
        .link-suggestion-card {
          background-color: index.$cardDarkHeaderColor;
          .link-info {
            color: #fff;
            .info-text {
              b {
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
}

@keyframes fadeInFromAbove {
  0% {
    transform: translateY(-60%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}
