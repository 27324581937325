/* Service Template Card */
.serviceTemplateCard {
  gap: 5px;
  height: 50px;
  cursor: grab;
  padding: 10px;
  display: flex;
  position: relative;
  border-radius: 5px;
  align-items: center;
  background-color: #f4f5f6;
  justify-content: space-between;
  transition: background-color 0.3s ease, transform 0.3s ease,
    box-shadow 0.3s ease;

  .serviceTemplateCard_title {
    // flex: 1;
    width: 80%;
    color: #000;
    font-size: 12px;
    text-wrap: wrap;
    font-weight: 400;
  }
  .serviceTemplateCard_icons {
    width: 20%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .iconOpen {
      flex: 1;
      height: 100%;
      display: flex;
      cursor: pointer;
      justify-content: center;
    }
  }
  svg {
    width: 12px;
    height: 12px;
    fill: #231f20;
    font-size: 14px !important;
  }
}

.serviceTemplateCard:hover {
  opacity: 1;
  background-color: #f1f5fd;
}

/* Service Templates */
.serviceTemplates {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-left: 10px;
  margin-top: 10px;
}

.serviceTemplates .serviceTemplateItem {
  flex-basis: calc(50% - 5px);
  padding: 10px;
  background-color: #1264a3;
  opacity: 0.5;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
