.file-item-container {
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: #f8f8fa;
    padding: 5px;
    border-radius: 5px;
    max-width: 150px;
    min-width: 165px;
    cursor: pointer;

    h5 {
        max-width: 200px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-weight: 400;
        color: #323338;
    }


    &.selected {
        border: 1px solid #1264a3;
        background-color: #f1f5fd;
    }

}