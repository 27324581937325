.last-progress-day {
  animation: lastProgressDay 0.5s ease forwards;
}

.prjFullName {
  animation: changeName 0.3s;
}

@keyframes changeName {
  0% {
    filter: brightness(1);
  }
  50% {
    filter: brightness(0.5);
  }
  100% {
    filter: brightness(1);
  }
}

@keyframes lastProgressDay {
  0% {
    opacity: 0;
    transform: rotateX(90def);
  }
  50% {
    opacity: 1;
    transform: rotateX(720deg);
  }
  100% {
    opacity: 1;
    transform: rotateX(720deg);
  }
}
