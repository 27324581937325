@import "../../../../../index.scss";

$cardDarkHeaderColor: #2a2b3a;
$cardDarkBackgroundColor: #12131b;
$inputDarkColor: #2a2b3a;

.approvalsPageControlPanel {
  width: 100%;
  height: 85px;
  border-radius: 10px;
  // margin: 10px 0px;
  padding: 10px;
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000029;
  overflow-x: auto;
  gap: 10px;
  max-height: 52px;

  .report-search {
    background-color: #f4f5f6;
    .ant-input {
      background-color: #f4f5f6;
    }
  }

  @include flex(center, space-between);

  .controlSection {
    display: flex;
    flex-direction: row;
    gap: 20px;
    @include flex(flex-start, flex-start);
  }

  .controlSection-right {
    display: flex;
    gap: 20px;
  }

  .filterBtn {
    @include mondayButton(#1264a3, #0f5c97);
    margin-right: 20px;
  }

  .downloadIcons {
    display: flex;
    flex-direction: row;
    gap: 20px;
    .filterIcons {
      cursor: pointer;
      // margin: 7px 0;
      width: 32px;
      height: 32px;
      background: #1264a3;
      fill: white;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
    }
  }

  .clearBtn {
    @include mondayButton(#f5f5f7, #e6e9ef, #323338);
    .mondayButtonText {
      text-align: left;
      font: normal normal 400 14px/7px Open Sans;
      letter-spacing: 0px;
      opacity: 1;
    }
    .mondayButtonIcon {
      fill: #323338;
    }
    .path {
      color: #323338;
    }
  }
  .clearBtnFull {
    @include mondayButton(#fe4c4a, #eb4345);
    .mondayButtonText {
      text-align: left;
      font: normal normal 400 14px/7px Open Sans;
      letter-spacing: 0px;
      color: #fff;
      opacity: 1;
    }
    .mondayButtonIcon {
      fill: #fff;
    }
  }

  .newRecordBtn {
    @include mondayButton(#71cf48, #68c142);
    width: fit-content;
  }

  .request-dropdown {
    @include mondayButton(#71cf48, #68c142);
    width: 15rem;
  }

  .requestButton {
    display: flex;
  }

  .search-Div {
    align-self: center;
    display: flex;
    align-items: center;
    margin-left: 20px;
    height: 32px !important;
    .search-comp {
      margin-top: 2px;
      .ant-select-selector {
        padding-left: 30px;
        height: 32px;
        width: 300px;
        background: #f5f5f7 0% 0% no-repeat padding-box;
        border: none;
        border-radius: 5px;
        opacity: 1;
        .ant-select-selection-search-input {
          padding-left: 20px;
        }
      }
      .ant-select {
        height: inherit;
        box-sizing: border-box;
        height: 32px;
        margin: 0;
        padding: 0;
        width: 100%;
        color: #323338;
        font-size: 14px;
        font-variant: tabular-nums;
        line-height: 1.5715;
        list-style: none;
        font-feature-settings: "tnum", "tnum";
        position: relative;
        display: inline-block;
        cursor: pointer;
        z-index: 2;
      }
    }
    .SearchLogoIcon {
      position: relative;
      right: 290px;
      opacity: 0.5;
    }
  }
}

.approvalsPageControlPanelDark {
  background-color: $cardDarkBackgroundColor !important;
  box-shadow: 0px 3px 6px rgba(255, 255, 255, 0.161) !important;
  .ant-select {
    .ant-select-selector {
      background-color: $inputDarkColor !important;
      .ant-select-selection-search-input {
        color: #fff;
      }
      .ant-select-selection-item {
        color: #fff;
      }
      .ant-select-selection-placeholder {
        color: #acacca;
      }
    }
    .ant-select-clear {
      background-color: $inputDarkColor;
      svg {
        fill: #acacca;
      }
    }
    .ant-select-arrow svg {
      fill: #fff;
    }
  }
}

.ant-dropdown-menu {
  max-height: 250px;
  overflow: auto;
}
