@import "../../../../../index.scss";

.reportDesignerContainer {
  flex: 147;
  height: 100%;
  #viewer-host,
  #designer-host {
    height: 100%;
  }
  .mondayButtonContainer {
    height: 0px !important;
    .mondayButtonBlue {
      position: absolute;
      right: 6%;
      top: 2px;
      height: 26px !important;
      border-radius: 1px !important;
      z-index: 4;
    }
  }
  .gc-sidebar-panel--fill-container {
    overflow: scroll;
  }
}
