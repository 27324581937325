.dashboardCardsPanel {
  height: 100%;
  width: 100%;
  overflow: auto;
  overflow-x: auto;
  display: flex;
  gap: 10px;

  @media (max-width: 767px) {
    margin-top: 20px;
    flex-direction: row;
    flex-wrap: nowrap;
  }
}
