.application-footer_dynamicPage {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: #fff;
  gap: 20px;
  width: 100%;
  transition: all 0.5s ease-in-out;
  border-radius: 10px;
  padding: 0 20px;
  .footer-cont {
    display: flex;
    gap: 5px;
    align-items: center;
    flex-direction: row;
    .footer-name {
      font-size: 20px;
      // color: #323338;
    }
    .footer-val {
      // color: #323338;
      font-size: 19px;
      font-weight: bold;
    }
  }
}

.application-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  // background-color: red;
  background-color: #fff;
  gap: 20px;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
  transition: all 0.5s ease-in-out;
  border-radius: 5px 5px 10px 10px;
  padding: 0 20px;
  opacity: 0.9;
  .footer-cont {
    display: flex;
    gap: 5px;
    align-items: center;
    flex-direction: row;
    .footer-name {
      font-size: 20px;
      // color: #323338;
    }
    .footer-val {
      // color: #323338;
      font-size: 19px;
      font-weight: bold;
    }
  }
}

.application-footer-dark {
  background-color: #2a2b3a;
  box-shadow: 0px 3px 6px #ffffff29;
  .footer-cont span {
    color: #fff;
  }
}
