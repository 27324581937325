.previewMember {
  display: flex;
  gap: 5px;
  height: 70px;
  .previewAvatar {
    height: 70px;
    width: 70px;
    border-radius: 5px;
    background: #d9d9d9;
    display: flex;
    align-items: center;
    justify-content: center;
    .previewAvatar__img {
      height: 70px;
      width: 70px;
      border-radius: 5px;
    }
  }
  .previewMember__text {
    display: flex;
    flex-direction: column;
    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    .previewMember__textParagraph {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      padding: 0;
      margin: 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .unSelectable {
      user-select: none;
      -moz-user-select: none;
      -khtml-user-select: none;
      -webkit-user-select: none;
      -o-user-select: none;
    }
    .previewMember__textBold {
      font-weight: 600;
    }
    .previewMember__suspended {
      text-decoration: line-through;
    }
    .suspended__textBold {
      color: #fe4c4a;
    }
  }
}
