.serviceCardContainer {
  display: flex;
  flex-direction: column;
  border: 1px solid #f1f1f1;
  border-radius: 5px;
  padding: 5px;
  width: 100%;
}

.circleTitleShape {
  background: #f80606;
  padding-left: 5px;
  margin: 0px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  margin: 0 11px 4px 5px;
  display: inline-block;
}

.serviceTitle {
  font-size: 18px;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 5px;
}
