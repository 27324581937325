.time-and-date {
  display: flex;
  gap: 90px;
  height: 330px;
  margin-top: -30px;
  margin-left: 80px;
}

.time-actions {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 390px;
  margin-top: 150px;
  position: absolute;
  flex-direction: column;
  .forward-arrow {
    display: flex;
    justify-content: flex-start !important;
  }
}
.notifyNow {
  background-color: #f5f5f7 !important;
  width: 200px !important;
  .container-checked {
    background-color: #71cf48 !important;
    .ant-checkbox-inner {
      background-color: #68c142 !important ;
      border-color: #68c142 !important;
    }
  }
}

.timepicker .css-4l7j15 {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 4px #00000029;
  border-radius: 20px;
  opacity: 1;
  height: 400px;

  .css-nnbavb {
    display: none !important;
  }
}
.daterange .css-4l7j15 {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 4px #00000029;
  border-radius: 20px;
  opacity: 1;
  max-height: 400px;
}

.divider-vertical {
  border-left: 1px solid rgba(128, 128, 128, 0.342);
  height: 270px;
  position: absolute !important;
  padding-left: 200px;

  display: block;
}

.create-event-modal .ant-modal-content {
  display: flex;
  flex-direction: column;
  .ant-modal-header {
    background: #f2f3f6 0% 0% no-repeat padding-box;
    border-top-left-radius: 10px;
    padding-top: 0px;
    padding-bottom: 0px;
    display: flex;
    align-items: center;
    height: 45px;
    .ant-modal-title {
      width: 100%;
    }
  }
  .ant-modal-footer {
    padding: 20px;
  }
}

.create-event-modal .ant-modal-mask {
  background-color: rgba(97, 93, 93, 0.294) !important;
  pointer-events: none;
}

.add-event-form {
  display: flex;
  flex-direction: row;
  gap: 20px;

  .right-form {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow: auto;

    .ant-form-item {
      width: 100%;

      .ant-form-item-control {
        display: flex;
        flex-direction: row;

        .ant-form-item-control-input {
          width: 100%;
        }

        label {
          color: #323338;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }

        .react-quill-container {
          margin: 0 !important;
          width: 100%;

          .quill {
            .ql-toolbar {
              background-color: #f5f5f7;
              border-radius: 5px;
              border: none;
              margin-bottom: 5px;
              .ql-picker,
              button {
                border-radius: 5px;
                transition: 0.2s ease;
                &:hover {
                  background-color: #e6e6e6;
                }
              }
            }
            .ql-container {
              border: 1px solid #f8f8fa;
              .ql-editor {
                min-height: 110px;
                border-radius: 5px;
                background-color: #f8f8fa;
              }
            }
          }
        }

        .ant-form-item-control-input {
          .card {
            height: auto;
            min-height: 32px;
            width: 200px;
            display: flex;
            flex-direction: row;
            gap: 10px;
            background-color: #f5f5f7;
            margin-bottom: 10px;
            border: none;
            border-radius: 0px;
            overflow: hidden;
            position: relative;
            cursor: pointer;

            &.unHide {
              animation: appearFromRight 0.5s;
            }

            &.hide {
              animation: appearFromLeft 0.5s;
            }

            .checked {
              width: 8px;
              height: 32px;
              background-color: #71cf48;
              border: 4px solid #71cf48;
              display: flex;
              justify-content: center;
              align-items: center;
              transition: width 200ms;

              .svg {
                display: none;
                color: white;
                margin: 0px;
                padding: 0px;
              }

              .tickStyle {
                fill: #ffffff;
                z-index: 2;
                margin: 0;
                font-size: 20px;
              }
            }

            &:hover {
              .checked {
                width: 32px;
                border: none;
              }

              .unChecked {
                width: 32px !important;
                border: 6px solid #fe4c4a;
                background-color: #eb4345;
              }
            }

            .unChecked {
              width: 8px;
              height: 32px;
              background-color: #fe4c4a;
              transition: width 200ms;

              .svg {
                display: none;
              }

              .tickStyle {
                display: none;
              }
            }

            .TextContainer {
              height: 32px;
              display: flex;
              align-items: center;
              color: #323338;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
        }
      }
    }
  }

  .left-form {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .ant-form-item {
      width: 100%;
      margin: 0;
      display: flex;
      flex-direction: column;
      gap: 6px;

      .ant-form-item-row {
        .ant-form-item-label {
          label {
            padding-left: 10px;
            color: #323338;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
        }
      }

      .ant-form-item-control {
        .ant-form-item-control-input {
          input {
            width: 100% !important;
            border: none;
            border-radius: 5px;
            background-color: #f5f5f7;
          }
        }
      }
    }

    .timeDate {
      .time-date-wrapper {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .startTime {
          flex: 1;
        }

        .endTime {
          flex: 1;
        }

        .date-container {
          display: flex;
          flex-direction: column;

          label {
            padding-left: 10px;
            color: #323338;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            .required-icon {
              color: red;
            }
          }

          .ant-picker {
            margin: 0 !important;
            border: none;
          }
        }
      }
    }

    .participantsCalendar {
      display: flex;
      flex-direction: row;
      gap: 10px;

      .ant-form-item {
        flex: 1;

        .ant-form-item-row {
          flex-direction: initial;
          .ant-form-item-label {
            width: 100%;
            flex-grow: 1;
            overflow: visible;
          }
          .ant-select {
            .ant-select-selector {
              border: none;
              background-color: #f5f5f7;
            }
          }
        }
      }
    }
  }
}

.timepicker {
  .css-4l7j15 {
    transform: scale(0.7, 0.7);
  }
}

.daterange {
  .css-4l7j15 {
    transform: scale(0.7, 0.7);
  }
}

.ant-click-animating-node {
  display: none;
}

.react-quill-container {
  .ql-container {
    box-sizing: border-box;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 13px;
    height: 100%;
    margin: 0px;
    position: relative;
    border-radius: 0 0 10px 10px;
    background: #f5f5f7;
  }
  .ql-snow.ql-toolbar {
    border-radius: 10px 10px 0 0;
  }
  .ql-container.ql-snow {
    height: 190%;
  }
}

.calendar-selector {
  border-radius: 10px;
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    border: 1px solid #d9d9d9;
    border-radius: 15px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
}

.calendar-selector {
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: #ffffff;
    font-weight: 600;
    background-color: #0f5c97;
  }
}

.calendar-select-ant.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  position: relative;
  background-color: #f5f5f7;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
// .calendar-select-ant.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
//   .ant-select-selector {
//   border-color: #ff0e42;
//   box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2);
//   border-right-width: 1px !important;
//   outline: 0;
// }

.tags__select__wrapper.ant-select-show-search.ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border-radius: 5px;
  background: #f5f5f7;
  overflow-x: scroll;
  cursor: pointer;
}

.tags__select__wrapper .ant-select-selection-overflow {
  position: relative;
  display: flex;
  flex: auto;
  flex-wrap: nowrap;
  max-width: 100%;
  overflow-x: scroll;
}

.tags__select__wrapper.ant-select-multiple .ant-select-selection-item {
  background: #0f5c97;
  color: white;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
}

.tags__select__wrapper.ant-select-multiple .ant-select-selection-item-remove {
  color: white;
}

.tags__select__wrapper .ant-select-selection-overflow {
  position: relative;
  display: flex;
  flex: auto;
  flex-wrap: nowrap;
  max-width: 100%;
  color: #323338;
  overflow-x: scroll;
}

// .tags-selector {
//   border-radius: 5px;
//   .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
//     color: rgba(0, 0, 0, 0.85);
//     font-weight: 600;
//     background-color: #0f5c97;
//     border-radius: 2px;
//     // margin-top: 2px;
//     color: white;
//   }
// }

.notify-switch.ant-switch-checked {
  background-color: #1264a3;
}

// .timeDate {
//   .ant-form-item-explain-error {
//     margin-left: 40px;
//   }
// }
