.container {
  width: 25px;
  height: 25px;
  border-radius: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}
.checked {
  background: #1264a3;
}
.unchecked {
  background: #e6e6ee;
}
