.small-warning-info-card {
  background-color: #eb434544;
  border-radius: 10px;
  border: 2px solid #eb4345cc;
  width: 100%;
  height: fit-content;

  .warning-title-container {
    font-size: 1.1rem;
    font-weight: 500;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    padding: 5px 12px;
    gap: 10px;

    .warning-title {
      color: #eb4345;
    }
  }

  .warning-description {
    padding: 5px 12px;
    width: 100%;
  }
}
