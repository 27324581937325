.informationContainerLight {
  display: flex;
  gap: 10px;
  align-items: center;

  .sovHeaderInformationContainer {
    display: flex;
    align-items: center;

    .sovHeaderLabel {
      font: normal normal 600 16px/22px Open Sans;
      margin-right: 5px;
    }

    .sovHeaderData {
      font: normal normal 600 16px/22px Open Sans;
      color: #68c142;
    }
  }
}

.informationContainerDark {
  display: flex;
  gap: 10px;
  align-items: center;
  .sovHeaderInformationContainer {
    display: flex;
    align-items: center;

    .sovHeaderLabel {
      font: normal normal 600 16px/22px Open Sans;
      margin-right: 5px;
      color: #f5f5f5;
    }

    .sovHeaderData {
      font: normal normal 600 16px/22px Open Sans;
      color: #68c142;
    }
  }
}
