.attachEmailModalContainer {

  .mondayButton {
    background-color: #1264A3;
    width: 100%;

    &:active,
    &:visited,
    &:focus {
      background-color: #1264A3;

      .mondayButtonDivider {
        background-color: #1264A3 !important;
      }
    }

    &:hover {
      background-color: #0F5C97 !important;
      opacity: 0.8;

      .mondayButtonDivider {
        background-color: #1264A3 !important;
      }
    }
  }

  .ant-modal-root .ant-modal-wrap .ant-modal {
    justify-content: flex-end;
  }
}

.attachModal {
  padding: 0;

  .ant-modal-content {
    border-radius: 10px;

    .ant-modal-close {
      height: 45px;
      display: flex;
      justify-content: center;
      align-items: center;

      .ant-modal-close-x {
        height: 45px;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          fill: #333238;
        }
      }
    }

    .ant-modal-header {
      display: flex;
      align-items: center;
      background-color: #f8f8fa;
      border-radius: 10px 10px 0px 0px !important;
      height: 45px;

      .ant-modal-title {
        font-size: 16px;
        font-weight: 600;
      }
    }

    .ant-modal-footer {
      .modalButtonsContainer {
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
      }
    }
  }

  .attachEmailBody {
    width: 100%;
    height: 300px;
    display: flex;
    flex-direction: column;
    padding: 20px;

    .inputValueWithButton {
      display: flex;
      width: 100%;
      gap: 20px;

      .inputValue {
        height: 32px;
        border-radius: 5px;
      }

      .submitButton {
        height: 32px;
        border-radius: 5px;
        border: none;
        background-color: #71cf48;
      }
    }

    .selectedBody {
      display: flex;
      flex-direction: column;
      max-height: 400px;
      max-width: inherit;
      overflow: auto;

      .urlItem {
        display: flex;
        justify-content: space-between;
        margin: 10px 0 0 0;

        .urlItemName {
          max-width: calc(100% - 50px);
        }

        .removeIcon {
          cursor: pointer;
        }
      }
    }
  }
}