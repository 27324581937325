@import "react-pdf/dist/esm/Page/AnnotationLayer.css";
@import "react-pdf/dist/esm/Page/TextLayer.css";

.galleryInspection {
  .ant-carousel {
    .galleryCarousel {
      span::after {
        display: none !important;
      }
    }
  }
}
