.saveReportModal {
  .select-save {
    display: flex;
    gap: 20px;
    justify-content: center;
    .save-option {
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      height: 60px;
      width: 150px;
      background-color: #f4f5f6;
      font-size: 16px;
      &:hover {
        cursor: pointer;
        background-color: #f1f5fd;
        height: 60px;
        border: 1px solid #1264a3;
        color: #1264a3;
      }
    }
    .save-option.selected {
      background-color: #f1f5fd;
      height: 60px;
      border: 1px solid #1264a3;
      color: #1264a3;
    }
  }
}

.saveReportModalDark {
  .select-save {
    .save-option {
      background-color: #2c2c2c;
      color: #fff;
      &:hover {
        background-color: #2c2c2c;
        border: 1px solid lighten(#1264a3, 20%);
        color: lighten(#1264a3, 20%);
      }
    }
    .save-option.selected {
      background-color: #2c2c2c;
      border: 1px solid lighten(#1264a3, 20%);
      color: lighten(#1264a3, 20%);
    }
  }
}
