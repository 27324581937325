@use "src/index.scss" as index;

.card-individual-filter-modal {
  height: fit-content !important;
  width: fit-content !important;
  max-height: calc(100vh - 20px);
  max-width: calc(100vw - 20px);
  .ant-modal-content {
    height: 400px;
    width: 600px;
    max-height: calc(100vh - 20px);
    max-width: calc(100vw - 20px);
    .ant-modal-close {
      height: 45px;
      width: 45px;
      .ant-modal-close-x {
        height: 45px;
        width: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .ant-modal-header {
      height: 45px;
      display: flex;
      align-items: center;
      background-color: #f8f8fa;
    }
    .ant-modal-body {
      height: calc(100% - 100px);
    }
    .ant-modal-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      flex-wrap: nowrap;
      width: 100%;
    }
  }
}

.card-individual-filter-modal.card-individual-filter-modal-dark {
  .ant-modal-content {
    background-color: index.$mainDarkBackgroundColor;
    .ant-modal-close .ant-modal-close-x svg {
      fill: #fff !important;
    }
    .ant-modal-header {
      background-color: index.$cardDarkBackgroundColor;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      .labeledInputContainer {
        .ant-select {
          .ant-select-selector {
            .ant-select-selection-overflow
              .ant-select-selection-overflow-item
              .ant-select-selection-item {
              background-color: index.$textColor;
              .ant-select-selection-item-remove svg {
                fill: #fff;
              }
            }
          }
          .ant-select-clear svg {
            fill: #acacca;
          }
        }
      }
    }
  }
}
