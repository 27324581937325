@import "../../../../../index.scss";

.formCard {
  @include flex(center, center, column);
  position: relative;
 // background: #ffffff;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 1px 4px #00000029;
  margin-bottom: 15px;
  margin-right: 15px;
  transition: all 0.5s ease-in-out;
  .formCardTitle {
    @include text(16px, 600, 0.38px, #1f2a44);
    background-color: #f7f8fa;
    padding: 10px 20px;
    height: 45px;
    width: 100%;
    border-radius: 10px 10px 0 0;
  }
  .childrenContainer {
    position: relative;
    @include flex(flex-start, flex-start);
    width: 100%;
    flex-wrap: wrap;
    transition: all 0.5s ease-in-out;
    padding: 10px;
  }

  .collapseIcon {
    position: absolute;
    right: 15px;
    font-size: 20px;
    color: grey;
    transition: all 0.5s ease-in-out;
  }

  .ant-pagination {
    position: absolute;
    top: -5em;
    right: 0;

    .ant-pagination-options {
      display: none;
    }
  }

  &.dark-mode {
    * {
      color: #f2f2f2;
    }
    background: #20212d;
    .formCardTitle {
      background: #2a2b3a;
    }
    .childrenContainer {
      background: #12131b;
    }
  }
}
