.subtask-depend-wrapper {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  .subtasks-wrapper {
    width: 65%;
    margin-right: 20px;
    .subtasks-content-wrapper {
      padding: 10px 5px 10px 0;
      height: auto;
      max-height: 250px;
      overflow-y: auto;
      &::-webkit-scrollbar:vertical {
        width: 5px;
      }
    }
    .subtasks-header-text {
      b {
        color: rgb(240, 79, 78);
        line-height: 1 !important;
      }
      h3 {
        font-weight: 600 !important;
        font-size: 16px;
        margin-bottom: 0;
        line-height: 1 !important;
      }
    }
    .subtasks-buttons {
      display: flex;
      margin: 10px 0;
      .mondayButtonContainer {
        .mondayButtonStyle {
          height: 24px;
          .mondayButtonText {
            display: flex;
            align-items: center;
          }
          svg {
            width: 14px;
          }
        }

        &:first-child {
          margin-right: 10px;
          span {
            width: 12px;
          }
          .ant-btn {
            background-color: rgb(240, 79, 78);
          }
          .mondayButtonDivider {
            background: #eb4345;
          }
        }
      }
    }
    .modal__subAddElement {
      padding-bottom: 10px;
      display: flex;
      // justify-content: space-between;
      &:last-child {
        padding-bottom: 0;
      }
      span {
        width: 100%;
      }
      button {
        border-style: none !important;
        // border-top-right-radius: 5px;
        // border-bottom-right-radius: 5px;
        width: 60px;
      }
      .delete {
        width: 30px;
        background-color: rgb(240, 79, 78);
        color: white;
        border-radius: 0px 5px 5px 0px;
        &:hover {
          background-color: rgb(240, 79, 78);
        }
      }
      .subtask-done {
        text-decoration: line-through;
        background-color: #71cf4871;
        padding: 2px 0 2px 10px;
        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px;
      }
      .subtask-todo {
        background-color: #f1e8a2;
        padding: 2px 0 2px 10px;
        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px;
      }
      .checked {
        background-color: #71cf48;
        color: #fff;
      }
      .unChecked {
        background-color: rgb(236, 222, 124);
      }
    }

    .leftSide__input {
      margin-top: 2px;
      border-style: none;
      background: #f5f5f7 !important;
      height: 32px;
      border-radius: 5px;
    }
  }
}
