.RentalTermsTO__modal__darkMode {
  // antd modal
  .tox .tox-tbtn--bespoke .tox-tbtn__select-label {
    color: white;
  }
  .ant-modal-content {
    border-radius: 20px;
    overflow: hidden;
    // width: 700px !important;
    background: none !important;

    .ant-modal-close-x {
      //   display: none;
      //   background-color: red;
      height: 45px;
      svg {
        fill: white;
        font-weight: bold;
      }
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .ant-modal-header {
      height: 45px;
      border-bottom: 0;
      background-color: #12131b;
      display: flex;
      align-items: center;
      .ant-modal-title {
        font: normal normal 600 16px/39px Open Sans;
        color: white;
      }
    }
  }
  .ant-modal-body {
    background-color: #1f212d;
    .informationContainer {
      display: flex;
      gap: 10px;
      font-size: 14px;
      justify-content: flex-start;
      align-items: center;
      color: white;
      margin-bottom: 10px;

      .iconRentalContainer {
        background-color: red;
        width: 18px;
        height: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        margin-left: -2px;
      }
    }
    .container {
      display: flex;
      flex-direction: row;
      align-items: center;

      .nameInputContainer {
        .nameInputText {
          width: 550px;
          font: normal normal 600 16px/22px Open Sans;
          color: white;
        }

        .nameInput {
          width: 550px;
          background-color: #12131b;
          border-radius: 5px;
          border: 0 !important;
          color: #fff;
        }
      }
    }
    .rentalDaysContainer {
      display: flex;
      align-items: center;
      height: 80px;
    }

    .inputSwitcherContainer {
      padding: 0 10px;
      height: 78px;

      .switcherHeader {
        font: normal normal 600 16px/22px Open Sans;
        color: white;
      }
      .switcherBody {
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: space-between;
        // justify-content: center;
        .ant-form-item {
          margin: 0 !important;
        }
        .ant-row {
          display: flex;
          align-items: center;
          margin-bottom: 0 !important;
          width: stretch;
        }

        .dateInput {
          width: stretch;
          background-color: #12131b;
          color: #fff;
          border-radius: 5px;
          border: 0 !important;
          .ant-picker-input {
            color: #fff !important;
            input {
              color: #fff !important;
            }
            .ant-picker-clear {
              background-color: #12131b !important;
              svg {
                fill: #fff !important;
              }
            }
          }
        }
        .ant-input-number-input {
          color: #fff !important;
        }
      }
    }

    .rentalPayingTermsDescriptions {
      .tox-edit-area__iframe {
        background-color: #f5f5f7;
        border-radius: 10px;
        border-color: #dbdbdb;
      }

      .tox-toolbar__primary {
        // background-color: #1f212d;

        svg {
          fill: white;
        }
      }
      margin-bottom: -25px;
    }
  }
  .ant-modal-footer {
    height: 45px;
    display: flex;
    padding: 0 15px;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    background-color: #12131b;
    color: white;
    border-top: 0;
    .RentalTermsTO__modal_footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      width: 100%;
    }
  }
}

.RentalTermsTO__modal__lightMode {
  // antd modal
  .tox .tox-tbtn--bespoke .tox-tbtn__select-label {
    color: white;
  }
  .ant-modal-content {
    background-color: #fff;
    border-radius: 20px;
    overflow: hidden;
    // width: 700px !important;
    background: none !important;

    .ant-modal-close-x {
      //   display: none;
      //   background-color: red;
      height: 45px;
      svg {
        fill: white;
        font-weight: bold;
      }
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .ant-modal-header {
      height: 45px;
      border-bottom: 0;
      background-color: #0f5c97;
      color: #000;
      display: flex;
      align-items: center;
      .ant-modal-title {
        font: normal normal 600 16px/39px Open Sans;
        color: white;
      }
    }
  }
  // .ant-modal-body {
  //   background-color: #fff;
  //   color: #000 !important;
  //   .informationContainer {
  //     display: flex;
  //     gap: 10px;
  //     font-size: 14px;
  //     justify-content: flex-start;
  //     align-items: center;
  //     color: #000 !important;
  //     margin-bottom: 10px;

  //     .iconRentalContainer {
  //       // background-color: red;
  //       width: 18px;
  //       height: 18px;
  //       display: flex;
  //       justify-content: center;
  //       align-items: center;
  //       border-radius: 50%;
  //       margin-left: -2px;
  //     }
  //   }
  //   .container {
  //     display: flex;
  //     flex-direction: row;
  //     align-items: center;

  //     .nameInputContainer {
  //       .nameInputText {
  //         width: 550px;
  //         font: normal normal 600 16px/22px Open Sans;
  //         color: #000 !important;
  //       }

  //       .nameInput {
  //         width: 550px;
  //         background-color: #f5f5f7;
  //         border-radius: 5px;
  //         border: 0 !important;
  //         color: #fff;
  //       }
  //     }
  //   }

  //   .inputSwitcherContainer {
  //     padding: 0 10px;
  //     height: 78px;

  //     .switcherHeader {
  //       font: normal normal 600 16px/22px Open Sans;
  //       color: #000 !important;
  //     }
  //     .switcherBody {
  //       display: flex;
  //       gap: 10px;
  //       align-items: center;
  //       justify-content: space-between;
  //       // justify-content: center;
  //       .ant-form-item {
  //         margin: 0 !important;
  //       }
  //       .ant-row {
  //         display: flex;
  //         align-items: center;
  //         margin-bottom: 0 !important;
  //         width: stretch;
  //       }

  //       .dateInput {
  //         width: stretch;
  //         background-color: #f5f5f7;
  //         color: #000 !important;
  //         border-radius: 5px;
  //         border: 0 !important;
  //         .ant-picker-input {
  //           color: #000 !important;
  //           input {
  //             color: #000 !important;
  //           }
  //           .ant-picker-clear {
  //             background-color: #f5f5f7 !important;
  //             svg {
  //               fill: #000 !important;
  //             }
  //           }
  //         }
  //       }
  //       .ant-input-number-input {
  //         color: #000 !important;
  //       }
  //     }
  //   }

  //   .rentalPayingTermsDescriptions {
  //     .tox-edit-area__iframe {
  //       background-color: #f5f5f7;
  //       border-radius: 10px;
  //       border-color: #dbdbdb;
  //     }

  //     .tox-toolbar__primary {
  //       background-color: #0f5c97;
  //       border-radius: 5px;
  //       svg {
  //         fill: white;
  //       }
  //     }
  //     margin-bottom: -25px;
  //   }
  // }
  .ant-modal-footer {
    height: 45px;
    display: flex;
    padding: 0 15px;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    background-color: #f5f5f7;
    color: #000 !important;
    border-top: 0;
    .RentalTermsTO__modal_footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      width: 100%;
    }
  }
}
