.pagination-buttons-cc {
  display: flex;
  align-items: center;
  gap: 8px;
  width: fit-content;
  .pagination-button {
    cursor: pointer;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: #1264a3;
    border: none;
    background: transparent;
    span {
      font-weight: 600;
      font-size: 20px;
    }
  }
  .pagination-button:disabled {
    color: #959595;
    cursor: no-drop;
  }

  .page-number-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    background: #f5f5f7;
    border-radius: 2px;
    font-weight: 500;
    color: #323338;
    border: none;
  }
  .active-page {
    border: 0.5px solid #1264a3;
    cursor: no-drop;
  }
}
