$mainDarkBackgroundColor: #20212d;
$cardDarkHeaderColor: #2a2b3a;
$cardDarkBackgroundColor: #12131b;
$inputDarkColor: #2a2b3a;
.departments-info-card {
  padding-bottom: 20px;
  color: #323338;
  @media screen and (min-width: 1400px) {
    max-width: 500px;
  }
  // max-width: 500px;
  .card-template-body {
    gap: 10px;
    .departments-info-card-title {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      svg {
        height: 33px !important;
        width: 33px !important;
      }
      span {
        font-size: 24px;
        font-weight: 600;
      }
    }
    .departments-info-card-body {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      .department-info-card-list-item {
        display: flex;
        justify-content: space-between;
        height: 40px;
        align-items: center;
        padding: 0 20px;
        span {
          display: flex;
          justify-content: center;
          min-width: 90px;
          &:hover {
            cursor: pointer;
            color: #1264a3;
            font-size: 16px;
            font-weight: 600;
          }
        }
        &:hover:not(.header) {
          background-color: #f1f5fd !important;
        }
      }
      .department-info-card-list {
        padding: 0;
        margin: 0;
        height: 160px;
        overflow-y: auto;
      }
      .department-info-card-list-item.header {
        font-size: 16px;
        font-weight: 600;
        border-bottom: 1px solid #acacac;
      }
      .department-info-card-list-item.child {
        font-size: 14px;
        font-weight: 400;
        &:not(:last-child) {
          border-bottom: 1px solid #dee2eb;
        }
        &:nth-child(odd) {
          background-color: #fff;
        }
        &:nth-child(even) {
          background-color: #fcfcfc;
        }
      }
      .department-info-card-list-item.active-role {
        background-color: #f1f5fd !important;
      }
    }
  }
}

.departments-info-card-dark {
  .departments-info-card-title {
    svg path {
      fill: #fff;
    }
    span {
      color: #fff;
    }
  }
  .departments-info-card-body {
    .department-info-card-list-container {
      .department-info-card-list {
        background-color: $cardDarkBackgroundColor;
      }
      .department-info-card-list-item {
        background-color: $cardDarkBackgroundColor !important;
        span {
          color: #fff;
        }
        &:hover:not(.header) {
          background-color: lighten($mainDarkBackgroundColor, 10%) !important;
        }
      }
      .department-info-card-list-item.header {
        color: #fff;
      }
      .department-info-card-list-item.child {
        color: #fff;
      }
      .department-info-card-list-item.child.active-role {
        background-color: lighten($mainDarkBackgroundColor, 10%) !important;
      }
    }

    .ant-form {
      .labeledInputContainer.dark-mode {
        .labeledInputLabel {
          color: #fff;
        }
        .ant-form-item {
          .ant-select-selector {
            background-color: $inputDarkColor !important;
            color: #fff;
          }
        }
      }
    }
  }
}
