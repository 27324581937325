@import "../../../../index.scss";

// $darkBackground: #12131b;
$darkBackground: #1b1d27;
$inputDarkColor: #2a2b3a;

.new-button-container {
  display: flex;
  justify-content: center;
  margin: 10px 0px 20px 0px;

  .add-button {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-around;
    gap: 8px;
    max-width: 120px;
    height: 32px;
    background-color: transparent;
    border: 1px solid #ffffff;
    color: #ffffff;
    border-radius: 5px;
    opacity: 1;

    .ant-btn-icon {
      margin-right: 0px !important;
    }
  }
}

.shortcutPopover {
  width: 100vw;
  height: 100vh;
  top: 0 !important;

  // * Tablet (below) viewport styles
  @media (max-width: 1023px) {
    .ant-popover-content {
      height: 100% !important;
      .ant-popover-inner {
        height: 100% !important;
        .ant-popover-inner-content {
          height: 100% !important;
        }
      }
    }
  }

  // * Desktop viewport styles
  @media (width >= 1024px) {
    top: 65px !important;
    left: 252px !important;
    height: 80vh !important;
    width: calc(100vw - 252px) !important;
  }

  @media (width >= 1500px) {
    width: 80vw !important;
  }

  .ant-popover-arrow {
    display: none !important;
  }
}

.sidebar-collapsed {
  @media (width >= 1024px) {
    left: 82px !important;
    width: calc(100vw - 82px) !important;
  }
  @media (width >= 1500px) {
    width: 80vw !important;
  }
}

.shortcutPopoverDark {
  .ant-popover-arrow::before {
    background-color: $darkBackground !important;
  }

  .ant-popover-inner {
    background-color: $darkBackground !important;
    box-shadow: 1px 1px 1px 1px $inputDarkColor;
  }
}
