.schedulesTypeChangerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  .ant-select-selector {
    border-radius: 5px !important;
    border: 0 !important;
    background-color: #a2ca71 !important;
    overflow: hidden;
    display: flex;
    justify-content: center;

    .ant-select-selection-item {
      color: white;
    }
  }
  .ant-select-arrow {
    height: 32px;
    width: 32px;
    border-left: 1px solid #a2ca71;
    margin-top: -15px !important;
    margin-right: -10px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 0px;
    svg {
      fill: white;
    }
  }
}
