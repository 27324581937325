.audit-sidebar {
  height: 100%;
  transition: width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #fff;
  white-space: nowrap;

  .sidebar-search {
    padding: 0.5rem 0 0.5rem 5%;
    height: 50px;
    width: 100%;
    border-bottom: 1px solid #dee2eb;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .ant-input-affix-wrapper {
      height: 32px;
      border: 0;
      background-color: #f5f5f7;

      input {
        background-color: #f5f5f7;
      }
    }
  }

  .trucks-section {
    width: 100%;
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
  }

  #location-selected {
    background-color: #f1f5fd;
  }

  .location-info {
    width: 100%;
    padding: 1rem 0.7rem;
    border-top: 1px solid #dee2eb;
    border-radius: 0;
    cursor: pointer;

    &:first-child {
      border-top: 0 !important;
    }

    .location-header {
      .name-section {
        cursor: pointer;

        &:hover {
          color: #1065a4;
          svg {
            fill: #1065a4;
          }
        }
      }
    }

    .location-body {
      .info-data {
        font-size: 0.8rem;
      }
    }
  }
}

.audit-sidebar.audit-side-expanded {
  width: 18dvw;
  max-width: 20dvw;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.audit-sidebar.audit-side-collapsed {
  width: 0;
  border: 0;
}

.audit-sidebar.audit-side-dark {
  background-color: #20212d;
  color: #ffffff;

  .sidebar-search {
    border-bottom: 1px solid #616481;

    .ant-input-affix-wrapper {
      background-color: rgb(0, 0, 0);
      color: #ffffff;

      .ant-input-clear-icon {
        color: #3a3c4e;
      }

      input {
        background-color: rgba(0, 0, 0, 0);
        color: #ffffff;
        &::placeholder {
          color: #ffffffd5;
        }
      }
      &:active {
        border-color: #616481;
      }
      &:hover {
        border-color: #616481;
      }
    }
  }

  #location-selected {
    background-color: #41435e;
  }

  .location-info {
    border-top: 1px solid #616481;
  }
}

.audit-sidebar.audit-side-dark.audit-side-expanded {
  border-right: 1px solid #616481;
}
