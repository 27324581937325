.DOBCard {
  display: flex;
  flex-direction: column;
  opacity: 1;
  margin-bottom: 30px;
  margin-right: 20px;
  height: 30px;
  padding-right: 10px;
  margin-top: 20px;
  height: auto;
  max-height: 300px;
  overflow-y: auto;
  .InformationBodyElementContainerTitle {
    text-align: left;
    margin-bottom: 10px;
    font: normal 600 normal 16px Open Sans;
    letter-spacing: 0px;
    color: #323338;
    opacity: 1;
  }
  .InforamtionBodyElementContainerContent {
    @extend .InformationBodyElementContainerTitle;
    color: #767b81;
    margin-bottom: 10px;
    font-weight: 400;
  }
  .InforamtionBodyElementContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .descriptionValue {
    top: 100px;
    text-align: left;
    font: normal normal normal 20px/27px Open Sans;
    letter-spacing: 0px;
    color: #323338;
    opacity: 1;
  }
}
