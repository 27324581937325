.dispatch-project-match-wrap {
  &::before {
    display: none !important;
  }
}

.dispatch-project-match {
  height: 100dvh !important;
  width: 100dvw !important;
  max-height: 100dvh !important;
  max-width: 100dvw !important;

  .ant-modal-content {
    height: 100dvh;
    width: 100dvw;
    border-radius: 0;

    .ant-modal-header {
      height: 50px;
      display: flex;
      align-items: center;
      background-color: #f5f5f7;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      .ant-modal-title {
        font-weight: 600;
        font-size: 16px;
        color: #333238;

        .inner-modal-title-container {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-direction: row;
          gap: 10px;
          flex-wrap: nowrap;
        }
      }
    }

    .ant-modal-close {
      height: 50px;
      width: 50px;
      .ant-modal-close-x {
        height: 50px;
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          fill: #333238;
        }
      }
    }

    .ant-modal-body {
      width: 100%;
      height: calc(100% - 102px);
      padding: 0 0 0 0 !important;
      box-sizing: border-box;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 10px;
      box-sizing: border-box;

      .ag-root-wrapper-body.ag-layout-normal {
        height: 100% !important;
        min-height: 100% !important;
        overflow-y: unset !important;
      }

      .layout-container {
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        display: grid;
        gap: 10px;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 10px;
      }

      .project-match-controller-container {
        grid-area: controller;
        box-shadow: 0 0 3px 1px rgba($color: #000000, $alpha: 0.1);
      }

      .project-match-grid-view-container {
        grid-area: mainGrid;
        box-shadow: 0 0 3px 1px rgba($color: #000000, $alpha: 0.1);
      }

      .details-map-container-card {
        grid-area: map;
      }

      .details-schedule-info-card {
        grid-area: list;
      }

      .related-dispatches-container {
        grid-area: dispatches;
      }

      .project-match-card {
        display: grid;
        grid-template-rows: 45px calc(100% - 45px);
        grid-template-columns: 100%;
        border-radius: 10px;
        overflow: hidden;
        height: 100%;
        box-shadow: 0 0 3px 1px rgba($color: #000000, $alpha: 0.1);

        .project-match-card-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px 20px;
          font-size: 16px;
          font-weight: 600;
        }

        .project-match-body {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          height: 100%;
          overflow-x: hidden;
          overflow-y: auto;
        }
      }

      .project-match-card-header {
        background-color: #f5f5f7;
      }

      .layout-container.cl-3 {
        grid-template-areas: "map controller" "map dispatches" "list dispatches";

        grid-template-rows: 52px calc(40% - 52px) calc(60% - 20px);
        grid-template-columns: 450px calc(100% - 460px);

        @media (max-width: 1200px) {
          grid-template-columns: 32% calc(100% - 32% - 10px);
        }

        @media (max-width: 769px) {
          grid-template-areas: "controller" "map" "list" "dispatches";
          grid-template-columns: 100%;
          grid-template-rows: 52px calc(100% - 52px) 100% 100%;
        }
      }

      .layout-container.cl-1 {
        grid-template-areas: "controller" "mainGrid";
        grid-template-rows: 52px calc(100% - 62px);
        grid-template-columns: 100%;
      }
    }

    .ant-modal-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      flex-direction: row;
      border-top: 1px solid #dee2eb;
      overflow-x: auto;
      overflow-y: hidden;
      gap: 10px;
      background-color: #f5f5f7;
    }
  }

  .ag-details-grid-fixed-height {
    .ag-header-cell-resize {
      &::after {
        background-color: transparent !important;
      }
    }
  }

  .dark-ag-theme,
  .light-ag-theme {
    .ag-ltr {
      .ag-cell {
        border-right: none !important;
      }
    }
  }

  .ant-select {
    .ant-select-selector {
      background-color: #fff !important;
    }
  }

  .ant-picker {
    background-color: #fff !important;
  }
}

.dispatch-project-match.dispatch-project-match-dark {
  color: #ffffff;
  .ant-modal-content {
    background-color: #2a2b3a;
    color: #ffffff;

    .ant-modal-header {
      background-color: #12131b;
      .ant-modal-title {
        color: #ffffff;
      }
    }

    .ant-modal-close {
      .ant-modal-close-x {
        svg {
          fill: #ffffff;
        }
      }
    }
  }

  .ant-modal-body {
    .project-match-card-header {
      background-color: #12131b;
    }

    .project-match-body {
      background-color: #20212d;
    }
  }

  .ant-modal-footer {
    background-color: #12131b;
  }

  .ant-select {
    .ant-select-selector {
      background-color: #3a3c4e !important;
      color: #f5f5f7;
      border: 0;
    }

    .ant-select-selection-placeholder {
      color: #f5f5f7a9;
    }

    .ant-select-clear {
      background-color: #3a3c4e;
      svg {
        fill: #acacca;
      }
    }

    .anticon {
      color: #fff;
      background-color: #3a3c4e;
    }
  }

  .ant-select-arrow {
    svg {
      fill: #f5f5f7 !important;
    }
  }

  .ant-select-selection-placeholder {
    color: #acacca;
  }

  input::placeholder {
    color: #acacca;
  }

  .ant-picker {
    background-color: #3a3c4e !important;
    color: #f5f5f7 !important;
    border: 0;

    .anticon {
      color: #fff;
      background-color: #3a3c4e;
    }
    .ant-picker-input {
      input {
        color: #f5f5f7 !important;
      }
    }
  }
}
