@import "../../../../Communication.scss";


.attachments-divider {
    margin: 20px 0px 10px 70px;
    border: 0.5px dotted $border-color;
}

.attachments-container {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-left: 60px;
}