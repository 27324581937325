.message-bubble {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 5px;
  background-color: #f4f5f6;
  text-align: left;
  width: 100%;
  position: relative;
  color: #231f20;
  padding: 10px 10px 10px 10px;
  border-radius: 5px;
  line-height: normal;

  .message-bubble-title {
    width: 100%;
    text-align: inherit;
    font-weight: 600;
    color: inherit;
    margin: 0;
  }

  .space-right {
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .message-bubble-error-title {
    color: inherit;
    width: 100%;
    text-align: inherit;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 10px;
    height: 24px;
    color: #eb4345;

    .message-error-description {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;
      flex-wrap: nowrap;
      gap: 5px;
    }

    .message-error-text {
      font-weight: 400;
      font-size: 12px;
    }

    svg,
    path {
      fill: currentColor;
    }
  }

  .resend-icon {
    height: 16px;
    width: 16px;
    cursor: pointer;
  }

  .icon-spin {
    animation: rotate 1s linear infinite;
  }

  .message-bubble-body {
    width: 100%;
    color: inherit;
    text-align: inherit;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 5px;

    .typing-bubbles {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      height: 14px;
      gap: 5px;

      span {
        animation: dot 0.9s linear infinite;
        height: 7px;
        width: 7px;
        background-color: #acacac;
        border-radius: 50%;
        transition: height 0.2s ease-in-out, width 0.2s ease-in-out;

        &:nth-child(1) {
          animation-delay: 0s;
        }
        &:nth-child(2) {
          animation-delay: 0.3s;
        }
        &:nth-child(3) {
          animation-delay: 0.6s;
        }
      }
    }

    .message-content {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      gap: 5px;
      text-align: inherit;
      color: inherit;
      font-weight: 400;

      .message-text {
        width: 100%;
      }

      .inner-content-container {
        width: 100%;
        position: relative;

        ul {
          padding-left: 20px;
        }
        ol {
          padding-left: 20px;
        }

        & > * {
          margin: 10px 0;
        }
      }

      .message-footer {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        gap: 10px;
        flex-wrap: wrap;
      }

      .message-attachments {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 5px;
        color: #231f20;

        .file-type-button {
          color: #231f20;
        }
      }
    }

    .inline-date {
      // position: absolute;
      // bottom: 5px;
      // right: 6px;
      margin-left: auto;
      font-size: 12px;
      font-weight: 400;
      color: #acacac;
    }
  }
}

.message-bubble.with-attachments {
  padding: 10px 10px 25px 10px;
}

.message-bubble.message-bubble-error {
  background-color: #fde5e4;

  .message-bubble-title {
    color: #eb4345;
  }
}

.message-bubble.message-bubble-pending {
  animation: pending 1s linear infinite;
}

@keyframes pending {
  50% {
    background-color: #f1f5fd;
  }
}

@keyframes dot {
  33% {
    height: 9px;
    width: 9px;
  }

  66% {
    height: 7px;
    width: 7px;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
