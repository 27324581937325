.secondary-header-container-dark {
  display: flex;
  padding: 10px;
  margin: 0 2px 0 2px;
  background-color: #12131b;
  justify-content: space-between;

  .secondary-header-left {
    display: flex;
    gap: 10px;
  }

  .secondary-header-right {
    display: flex;
    gap: 10px;
    padding: 10px;

    .secondary-header-logs {
      width: 32px;
      height: 32px;
      border: 0;
      border-radius: 5px;
      background-color: #1264a3;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .secondary-header-item {
      margin-right: -20px;
    }
  }
}

.secondary-header-container {
  @extend .secondary-header-container-dark;
  background-color: #1264a3 !important;
}
