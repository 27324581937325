.refreshButtonContainer {
  margin-right: 20px;
  .ant-btn-primary {
    width: 32px;
    height: 32px;
    padding: 0;
    background: #1264a3;
    border-radius: 5px;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .svgContainer {
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        fill: white;
      }
    }
  }
}
