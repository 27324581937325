.foremanNotesModal {
  .ant-modal-header {
    display: flex;
    align-items: center;
    height: 45px;
    background-color: #f5f5f5;
  }
  .foremanNotesModal-content {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}
