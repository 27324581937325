@import "../../../../../../../../../../index.scss";

.rentalsControlPanelLightMode {
  width: stretch;
  height: 100px;
  display: flex;
  align-items: center;

  .optionsContainer {
    background-color: white;
    box-shadow: 0 3px 5px rgb(0 0 0 / 16%);
    height: 70px;
    width: stretch;
    border-radius: 10px;
    margin: 0 25px;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    transition: background-color 0.3s;

    .leftSideContainer {
      display: flex;
      gap: 20px;
      align-items: center;
      .information {
        font-size: 16px;
        color: #12131b;
        display: flex;
        align-items: center;
        .currencyPercentageContainer {
          margin-left: 5px;
          color: #71cf48;
        }

        .currencyPercentageContainerZero {
          margin-left: 5px;
          color: #eb4345;
        }
      }

      .serviceRetractButton {
        height: 32px;
        width: 32px;
        border: 0;
        border-radius: 5px;
        background-color: #1264a3;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          animation: rotateInRight 0.3s;
          transform: rotate(-90deg);
          fill: white;
        }
      }

      .serviceRetractButtonRetracted {
        height: 32px;
        width: 32px;
        border: 0;
        border-radius: 5px;
        background-color: #1264a3;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          animation: rotateInLeft 0.3s;
          transform: rotate(90deg);
          fill: white;
        }
      }

      .autoSaveContainer {
        display: flex;
        align-items: center;
        .rentalsAutoSaveSwitchContainer {
          background-color: #fe4c4a;
          width: 120px;
          display: flex;
          justify-content: center;

          .ant-switch-handle {
            content: url("../../../../../../../../BasePage/src/switchAutoSaveOff.svg");
            background-color: white;
            padding: 2px;
            border-radius: 9px;
          }
        }
        .ant-switch-checked {
          background-color: #71cf48;
          width: 120px;
          display: flex;
          justify-content: center;

          .ant-switch-handle {
            content: url("../../../../../../../../BasePage/src/switchSaveIcon.svg");
            background-color: white;
            border-radius: 9px;
          }
        }
      }
    }
    .rightSideContainer {
      display: flex;
      gap: 20px;
      align-items: center;
      .exportButtons {
        width: 32px;
        height: 32px;
        border: 0;
        border-radius: 5px;

        background-color: #1264a3;
      }

      .quickSaveApplicationHeaderRental {
        @include mondayButton(#68c142, #71cf48, #fff);

        &:disabled {
          @include mondayButton(#5aa33a, #70b652, #a6c997);

          svg {
            fill: #a6c997;
          }
        }
      }
    }
  }
}

.rentalsControlPanelDarkMode {
  width: stretch;
  height: 100px;
  display: flex;
  align-items: center;
  .optionsContainer {
    background-color: #12131b;
    height: 70px;
    width: stretch;
    border-radius: 10px;
    margin: 0 25px;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    transition: background-color 0.3s;

    .leftSideContainer {
      display: flex;
      gap: 20px;
      align-items: center;

      .information {
        font-size: 16px;
        color: white;
        display: flex;
        align-items: center;
        .currencyPercentageContainer {
          margin-left: 5px;
          color: #71cf48;
        }

        .currencyPercentageContainerZero {
          margin-left: 5px;
          color: #eb4345;
        }
      }

      .serviceRetractButton {
        height: 32px;
        width: 32px;
        border: 0;
        border-radius: 5px;
        background-color: #1264a3;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          animation: rotateInRight 0.3s;
          transform: rotate(-90deg);
          fill: white;
        }
      }

      .serviceRetractButtonRetracted {
        height: 32px;
        width: 32px;
        border: 0;
        border-radius: 5px;
        background-color: #1264a3;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          animation: rotateInLeft 0.3s;
          transform: rotate(90deg);
          fill: white;
        }
      }

      .autoSaveContainer {
        display: flex;
        align-items: center;
        .rentalsAutoSaveSwitchContainer {
          background-color: #fe4c4a;
          width: 120px;
          display: flex;
          justify-content: center;

          .ant-switch-handle {
            content: url("../../../../../../../../BasePage/src/switchAutoSaveOff.svg");
            background-color: white;
            padding: 2px;
            border-radius: 9px;
          }
        }
        .ant-switch-checked {
          background-color: #71cf48;
          width: 120px;
          display: flex;
          justify-content: center;

          .ant-switch-handle {
            content: url("../../../../../../../../BasePage/src/switchSaveIcon.svg");
            background-color: white;
            border-radius: 9px;
          }
        }
      }
    }
    .rightSideContainer {
      display: flex;
      gap: 20px;
      align-items: center;

      .exportButtons {
        width: 32px;
        height: 32px;
        border: 0;
        border-radius: 5px;
        background-color: #1264a3;
      }

      .quickSaveApplicationHeaderRental {
        @include mondayButton(#68c142, #71cf48, #fff);

        &:disabled {
          @include mondayButton(#5aa33a, #70b652, #a6c997);

          svg {
            fill: #a6c997;
          }
        }
      }
    }
  }
}

@keyframes rotateInRight {
  from {
    transform: rotate(90deg);
  }

  to {
    transform: rotate(270deg);
  }
}

@keyframes rotateInLeft {
  from {
    transform: rotate(-90deg);
  }

  to {
    transform: rotate(90deg);
  }
}

.quickSaveButtonUnClickable {
  opacity: 0.62 !important;
  cursor: not-allowed !important;
}
