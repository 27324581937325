.InformationBodyEdit {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 180px);
  overflow-x: hidden;
  overflow-y: auto;
  padding-top: 10px;

  .DateRangeBodyElementContainer {
    display: grid;
    max-height: fit-content;
    overflow-y: auto;
    grid-template-columns: 0.8fr 1.2fr;
    .InformationBodyElementContainerTitle {
      text-align: left;
      margin-bottom: 5px;
      margin-top: 5px;
      font: normal 600 normal 16px Open Sans;
      letter-spacing: 0px;
      color: #323338;
      opacity: 1;
    }

    .InforamtionBodyElementContainerContent {
      @extend .InformationBodyElementContainerTitle;
      color: #767b81;
      margin-bottom: 10px;
      font-weight: 400;
    }
  }
  .confirmContainer {
    display: flex;
    margin-top: 20px;
    justify-content: center;
    .cancelButton {
      margin-right: 10px;
      background-color: #fe4c4a;
    }
    .cancelButtonDivider {
      color: #f14543;
    }
    .cancelButton:hover {
      background-color: #f14543;
      .cancelButtonDivider {
        color: #fe4c4a;
      }
    }
    .confirmButton {
      margin-right: 10px;
      background-color: #71cf48;
    }
    .confirmButtonDivider {
      color: #69c242;
    }
    .confirmButton:hover {
      background-color: #69c242;
      .confirmButtonDivider {
        color: #71cf48;
      }
    }
  }
}
.EditDatePickerComponent {
  width: 350px;
  height: 33px;
  background: #f5f5f7 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 5px;
  border: 0px;
  margin-top: 5px;
  margin-bottom: 5px;
}
