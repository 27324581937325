.sidebar-routes-activity {
  width: 100%;
  height: calc(100vh - 350px);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  border-top: 1px solid rgba(0, 0, 0, 0.2);

  .trip-details-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding: 10px 0;
    cursor: pointer;
  }

  .trip-location-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    width: 100%;
    padding: 0 5px;
    gap: 5px;
    font-weight: 550;

    .title-arrive-info {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;

      .arrive-location {
        font-weight: 550;
        letter-spacing: 0.18px;
      }

      .arrive-time {
        font-size: 0.8rem;
      }
    }
  }

  .expandable-info {
    background-color: #f1f5fd;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    gap: 5px;
    transition: height 0.3s ease-in-out;
    padding: 0 0.5rem 10px;
    padding-left: 1rem;

    .trip-location {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-direction: row;
      flex-wrap: nowrap;
      gap: 10px;
    }
  }

  .expandable-info.info-collapsed {
    height: 0;
    padding: 0;
    margin-top: 0;
    overflow: hidden;
  }
  .expandable-info.info-expanded {
    height: fit-content;
    margin-top: 0.5rem;
  }
}

.trip-details-container.trip-selected {
  background-color: #f1f5fd;
}

.trip-details-container.trip-unselected {
  background-color: inherit;
}

.sidebar-routes-activity.routes-activity-dark {
  color: #ffffff;
  border-top: 1px solid #616481;

  .trip-details-container {
    border-bottom: 1px solid #616481;

    .expandable-info {
      background-color: #41435e;
    }
  }

  .trip-details-container.trip-selected {
    background-color: #41435e;
  }

  .trip-details-container.trip-unselected {
    background-color: inherit;
  }
}
