.button-dropdown-overlay {
  border-radius: 5px;
  width: fit-content;
  .ant-dropdown-menu {
    border-radius: 5px;
    background: #ffffff;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    padding: 0;
    .ant-dropdown-menu-item {
      height: 32px;
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 6.5px;
      padding-bottom: 6.5px;
      &:not(:last-child) {
        border-bottom: 1px solid #efefef;
      }
      .ant-dropdown-menu-title-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 0.625rem;
        p {
          font-family: "Open Sans";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 32px;
          color: #323338;
          padding: 0;
          margin: 0;
        }
      }
    }
  }
}
