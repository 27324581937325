.invoiceActivity {
  margin-top: 30px;
  margin-bottom: -20px;
  user-select: none;

  .vertical-line {
    content: "";
    width: 3px;
    min-height: 25px;
    margin-left: 4px;
    margin-top: 12px;
    background: #babec5;
  }

  .ant-steps {
    .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
      background-color: #2ca01c !important;
      border-width: 4px !important;
      border-style: solid;
      border-color: #d0f39b !important;
      width: 20px;
      height: 20px;
    }

    .ant-steps-item-finish
      .ant-steps-item-icon
      > .ant-steps-icon
      .ant-steps-icon-dot {
      margin-left: -2px;
      border: none;
      width: 14px;
      height: 14px;
    }

    .ant-steps-icon .ant-steps-icon-dot {
      margin-top: -5px;
      margin-left: -4px;
    }

    .ant-steps-item-disabled {
      cursor: default;
    }

    .ant-steps-item-wait
      .ant-steps-item-icon
      > .ant-steps-icon
      .ant-steps-icon-dot {
      padding: 6px;
      border: 1px solid rgba(0, 0, 0, 0.25) !important;
      background: transparent !important;
    }

    .ant-steps-item-container .ant-steps-item-content {
      font-weight: 600;
    }

    .ant-steps-vertical
      .ant-steps-dot
      .ant-steps-item
      > .ant-steps-item-container
      > .ant-steps-item-tail::after {
      margin-inline-start: 4.5px;
    }

    .ant-steps-item-finish
      > .ant-steps-item-container
      > .ant-steps-item-tail::after {
      background-color: #babec5;
      width: 3px;
    }
  }

  .ant-steps-vertical > .ant-steps-item {
    height: 80px;
  }
}

.invoiceActivityDark {
  @extend .invoiceActivity;
}
