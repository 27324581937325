.changeSovModalDark {
  .ant-modal {
    width: fit-content !important;

    .ant-modal-content {
      .ant-modal-body {
        .changeSovModalBody {
          .sovHeaderData {
            .ant-input-number-group-wrapper {
              .ant-input-number-wrapper {
                .ant-input-number-group-addon {
                  color: white !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
.changeSovModalWhite {
  .ant-modal {
    width: fit-content !important;

    .ant-modal-content {
      .ant-modal-body {
        .changeSovModalBody {
          .sovHeaderData {
            .ant-input-number-group-wrapper {
              .ant-input-number-wrapper {
                .ant-input-number-group-addon {
                  color: black;
                }
              }
            }
          }
        }
      }
    }
  }
}
