.control-panel-container {
  display: flex;
  padding: 10px;
  justify-content: space-between;
  width: 100%;
  overflow: auto;

  .control-panel-left {
    display: flex;
    gap: 10px;
  }

  .control-panel-right {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;

    .control-panel-logs {
      width: 32px;
      height: 32px;
      border: 0;
      border-radius: 5px;
      background-color: #1264a3;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .control-panel-item {
      margin-right: -20px;
    }
  }
}
