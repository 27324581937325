@use "/src/index.scss" as index;

.payroll-live {
  height: 100vh;
  width: 100vw;
  background-color: #f4f5f6;
  .payroll-live-body {
    height: calc(100% - 64px);
    display: flex;
  }
}

.payroll-live-dark {
  background-color: index.$mainDarkBackgroundColor;
}
