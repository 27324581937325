$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.breakdown-selectedDays-modal {
  .ant-modal-header {
    display: flex;
    align-items: center;
    background: #f8f8fa;
    height: 45px;
  }
  .ant-modal-content {
    height: fit-content;
  }

  .ant-modal-body {
    padding: 0 !important;
  }
  .footerButtons {
    display: flex;
    justify-content: space-between;
    gap: 15px;
  }

  .breakdown-selectedDays-info {
    display: flex;
    gap: 5px;
  }

  .breakdown-selectedDays-containter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    // align-items: center;
    gap: 50px;
  }
  .progressByDayContainer {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding-top: 13px;
  }
  .progressByDayContainerlnf {
    display: flex;
    flex-direction: column;
    gap: 17px;
    padding-top: 13px;
  }
  .progressByDay {
    display: flex;
    flex-direction: row;
    // justify-content: center;
    align-items: center;
    gap: 10px;
    Button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
      border-radius: 5;
      border: none;
    }
  }

  .ant-input-affix-wrapper {
    background-color: #f8f8fa;
    border: none;
    border-radius: 5px;
    .ant-input {
      background-color: #f8f8fa;
    }
  }

  .checkBoxPrimarySchDays {
    width: 8px;
    background-color: #71cf48;
    .checkBoxSecondarySchDays {
      margin: 9px;
      width: 18px;
      height: 18px;
      background-color: #71cf48;
      overflow: hidden; /* Hide the element content, while height = 0 */
      opacity: 0;
      transition: opacity 300ms 0ms;
    }
    // transition: width 0.5s;
  }
  .checkBoxContainerSchDays:hover {
    .checkBoxPrimarySchDays {
      background-color: #fff !important;
    }
    .checkBoxSecondarySchDays {
      opacity: 1;
      background-color: #71cf48 !important;
    }
  }

  .checkBoxPrimary {
    width: 8px;
    background-color: #71cf48;
    .checkBoxSecondary {
      margin: 9px;
      width: 18px;
      height: 18px;
      // background-color: #fff;
      overflow: hidden; /* Hide the element content, while height = 0 */
      opacity: 0;
      transition: opacity 300ms 0ms;
    }
    // transition: width 0.5s;
  }
  .checkBoxContainer:hover {
    // .checkBoxPrimary {
    // background-color: white !important;
    // }
    .checkBoxSecondary {
      opacity: 1;
      // background-color: red !important;
    }
  }

  .checkAnimSch {
    animation: checkAnim 0.5s;
  }
}

.breakdown-selectedDays-modal-dark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      .breakdown-selectedDays-containter {
        color: #fff;
        .checkAnimSch {
          .checkBoxContainer {
            background-color: $inputModalDark;
            .configElementTitle {
              color: #fff;
            }
          }
        }
      }
      .breakdown-selectedDays-info {
        color: #fff;
      }
      .breakdown-selectedDays-containter {
        .checkAnim {
          .checkBoxContainerSchDays {
            background-color: $inputModalDark;
            .configElementTitle {
              color: #fff;
            }
          }
          .checkBoxContainerSchDays:hover {
            .checkBoxPrimarySchDays {
              background-color: $cardModalBodyDark !important;
            }
          }
        }
        .progressByDayContainer .progressByDay {
          .ant-btn {
            background-color: $inputModalDark;
          }
          .ant-input-affix-wrapper {
            background-color: $inputModalDark;
            border: none;
            border-radius: 5px;
            .ant-input {
              background-color: $inputModalDark;
              color: #fff;
            }
            .ant-input-suffix svg {
              fill: #fff;
            }
          }
        }
      }
    }
  }
}

@keyframes checkAnim {
  0% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}

.breakdownForemanFilesModal {
  .docUploader {
    .uploadedFilesSection {
      max-width: unset;
    }
  }
}
