@import "./../../../index.scss";

.SelectWithCustomInputs {
  display: flex;
  flex-direction: column;
  gap: 2px;

  label {
    margin-left: 10px;
    font-size: 16px;
    font-weight: 600;
  }

  .ant-select {
    .ant-select-selector {
      border: 0;
      border-radius: 5px;
      background-color: #f4f5f6;
    }
  }
}

.SelectWithCustomInputs-dark {
  .title {
    color: #fff;
  }
}

.SelectWithCustomInputs-selectDark {
  background-color: $cardDarkBackgroundColor;

  .rc-virtual-list {
    background-color: $cardDarkBackgroundColor;

    .ant-select-item-option-content {
      color: #fff;
    }
  }

  .ant-empty-image {
    svg {
      fill: #fff;
    }
  }
  .ant-empty-description {
    color: #fff;
  }

  .ant-space {
    .ant-input {
      background-color: $cardDarkHeaderColor;
      color: #fff;
      &::placeholder {
        color: #acacca;
      }
    }
    .ant-space-item {
      background-color: $cardDarkHeaderColor;
      span {
        color: #fff;
      }
    }
  }
}
