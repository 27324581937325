@import "../../../../../../../../../index.scss";

.payment-footer-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  gap: 10px;
  padding: 10px;
  width: 100%;

  .payment-footer-body {
    display: flex;
    gap: 8px;
    justify-content: space-between;
  }
  .cancel-button {
    @include mondayButton(#fe4c4a, #eb4345, #fff);
  }
  .save-button {
    margin-left: auto;
  }
}
