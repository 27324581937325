.on-boarding-container {
  top: 0 !important;
  margin: 0 !important;
  padding: 0;
  max-width: 100%;
  .ant-modal-content {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    border-radius: 0;
    background-color: #f4f5f6;

    .mondayButtonContainer {
      width: 168px;
      button {
        height: 100%;
        width: 100%;
      }
    }

    .ant-modal-close {
      height: 52px !important;

      .ant-modal-close-x {
        svg {
          fill: white;
        }
      }
    }

    .ant-modal-header {
      height: 52px;
      border-radius: 0;
      background-color: #1f2a44;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      height: 100%;
      max-height: 100%;
      padding: 0 !important;
      overflow: auto;

      .boarding-content {
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 10px;

        .step-component-wrapper {
          padding: 10px;
          min-height: 15em;
          height: 100%;
          flex: 1;
          overflow: auto;
          background-color: #ffffff;
          border-radius: 10px;
          box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15);

          .step-lines {
            padding: 10px 0;
            display: flex;
            flex-direction: column;
            gap: 20px;
          }
        }
      }
    }

    .ant-modal-footer {
      height: 52px;
      background-color: #ffffff;
      padding: 16px 20px !important;
      display: flex;
      justify-content: space-between;
      gap: 10px;
      align-items: center;
      text-wrap: nowrap;

      .footer-left {
        display: flex;
        gap: 10px;
        overflow: auto;
      }
    }
  }

  &.dark-mode {
    color: #f9f9f9;
    svg:not(.filled) {
      path {
        fill: #f9f9f9;
      }
    }

    .ant-modal-content {
      .ant-modal-header {
        background-color: #12131b;
      }
      .ant-modal-body {
        .boarding-content {
          background-color: #2a2b3a;
          .step-component-wrapper {
            background-color: #20212d;
          }
        }
      }
      .ant-modal-footer {
        background-color: #12131b;
      }
    }

    ::-webkit-scrollbar-track {
      background: #41435d;
    }
    ::-webkit-scrollbar-thumb {
      background: #222a44;
    }
    /*Up Arrow*/
    ::-webkit-scrollbar-button:single-button:vertical:decrement {
      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(249, 249, 249)'><polygon points='50,00 0,50 100,50'/></svg>");
    }

    /*Down Arrow*/
    ::-webkit-scrollbar-button:single-button:vertical:increment {
      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(249, 249, 249)'><polygon points='0,0 100,0 50,50'/></svg>");
    }
  }
}

@media (max-width: 1024px) {
  .on-boarding-container {
    * {
      font-size: 16px;
    }
    .ant-modal-content {
      .ant-modal-header {
        height: 64px;
      }
      .ant-modal-footer {
        height: 64px;
      }
    }
    .mondayButtonContainer {
      height: 44px !important;
    }
  }
}

@media (max-width: 460px) {
  .on-boarding-container {
    * {
      font-size: 14px;
    }
    .ant-modal-content {
      .boarding-content {
        .step-component-wrapper {
          .step-lines {
            gap: 10px;
          }
        }
      }
      .ant-modal-footer {
        .mondayButtonContainer {
          width: 70px;
        }
      }
    }
  }
}
