.estimationHolder {
  height: 275px;
  width: 600px;
  border-radius: 10px;
  margin: 10px 10px 10px 20px;
  box-shadow: 0px 1px 4px #00000029;
  padding: 10px;
  .select-options-estimations-title {
    font-size: 22px;
    font-weight: 500;
  }

  .selectOptionsEstimationsNewSov {
    height: 210px;
    margin-top: 10px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0 !important;

    .ant-checkbox-group {
      height: fit-content;
      display: flex;
      flex-direction: column;
      gap: 10px;

      .ant-checkbox-wrapper {
        height: 40px !important;
        width: 580px;
        overflow: hidden;
        display: flex;
        align-items: center;
        background: #f8f8fa;

        .newSovEstimationLabelContainer {
          // width: 320px;
          display: flex;
          justify-content: space-between;
          gap: 20px;
        }

        &:hover {
          background-color: #d3d3d3;

          .ant-checkbox-inner {
            border: 10px solid #fe4c4a !important;
            background-color: #e03d3a;
            margin: 0px !important;
            border-radius: 0 !important;
            height: 40px;
            width: 40px;
          }
        }

        .ant-checkbox {
          height: 100%;
          width: 40px;
          margin: 0;
          margin-top: -5px !important;
        }
        .ant-checkbox-inner {
          border: 4px solid #fe4c4a !important;
          background-color: #e03d3a;
          margin: 0px !important;
          border-radius: 0 !important;
          height: 100%;
          width: 0%;
          &:hover {
            border: 10px solid #fe4c4a !important;
            background-color: #e03d3a;
            margin: 0px !important;
            border-radius: 0 !important;
            height: 40px;
            width: 40px;
            position: relative;
          }
          &::after {
            width: 0;
            height: 0;
          }
        }
      }

      .ant-checkbox-wrapper-checked {
        position: relative;
        &:hover {
          &::after {
            border: 10px solid #68c142 !important;
          }
        }

        &::after {
          display: flex;
          justify-content: center;
          align-items: center;
          border: 10px solid #71cf48 !important;
          content: url("../../../../../../../../pages/Settings/settingsComponents/Roles/src/Tick.svg");
          fill: black !important;
          background-color: white;
          width: 40px;
          height: 40px;
          border-radius: 0px;
          position: absolute;
          z-index: 5;
        }
      }
    }
  }
}
