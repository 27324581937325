@import "../../../../../../index.scss";

.hoist-pricing-info-modal-content {
  @include flex(stretch, flex-start, column);
  max-height: 80vh;
  overflow-y: auto;
  gap: 20px;
  // min-width: 1700px !important;

  .pricing-items-section {
    @include flex(stretch, flex-start, column);
    gap: 10px;
  }

  .addons-section {
    @include flex(stretch, flex-start, column);
    gap: 5px;
  }

  .totals-section {
    @extend .addons-section;
  }
}

.hoist-pricing-info-modal {
  width: fit-content !important;

  .ant-modal-content {
    // width: fit-content !important;
    // background-color: $cardModalBodyDark;
    // color: white;
    // border-radius: 10px;
    // padding: 20px;
    // box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  }
}
