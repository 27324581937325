$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.new-proposal-modal {
  height: fit-content !important;
  width: fit-content !important;
  .ant-modal-content {
    .ant-modal-header {
      height: 45px;
      display: flex;
      align-items: center;
      background-color: #f8f8fa;
      overflow: hidden;
      border: none;

      .ant-modal-title {
        font-size: 16px;
        width: 100%;
        font-weight: 600;
        color: #323338;
        padding: 0 !important;
        height: 45px;
        display: flex;
        align-items: center;
      }
    }
    .ant-modal-body {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 98vw;
      height: 85vh;
      .antStepperHolder {
        width: 100%;
        flex: 1;
      }
      .footer-actions {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        margin-top: auto;
        .footer-actions-right {
          display: flex;
          gap: 10px;
        }
        @media screen and (min-width: 1023px) {
          .mondayButtonContainer button {
            width: 150px;
          }
        }
      }
      @media screen and (min-width: 1023px) {
        min-width: 80vw;
        min-height: 40vh;
      }
    }
  }
}

.new-proposal-modal.dark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      .antCustomStepper {
        .ant-steps {
          .ant-steps-item-finish ::before,
          .ant-steps-item-active::before,
          .ant-steps-item::before {
            border-left-color: $cardModalBodyDark !important;
          }
          .ant-steps-item-wait {
            background-color: $cardModalHeaderDark !important;
            .ant-steps-item-content .ant-steps-item-title {
              color: #acacca;
            }
            &::after {
              border-left: 18px solid $cardModalHeaderDark !important;
            }
          }
        }
      }
    }
  }
}

// .proposal-header-filter-dropdown {
//   .ant-dropdown-menu-item {
//     &:hover {
//       background-color: #d5f1c9 !important;
//     }
//   }
// }
