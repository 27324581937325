@import "../../../../../../../../../../index.scss";

.application-service-list-service-header-light {
  background-color: #f8f8f8;
  color: #323338;
  height: 45px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  gap: 15px;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  justify-content: space-between;

  .leftContainer{
    display: flex;
    gap: 15px;
    height: 45px;
    align-items: center;
  }

  .completeAllContainer {
    display: flex;
    color: #323338;

    .compleatedLabel {
      margin: -1px 12px 0 4px;
      font: normal normal 600 16px/22px Open Sans;
    }
  }

  .tittle {
    color: var(--unnamed-color-323338);
    text-align: left;
    font: normal normal 600 16px/22px Open Sans;
    letter-spacing: 0px;
    color: #323338;
    opacity: 1;
  }

  .detailsApplicationButton {
    @include mondayButton(#1264a3, #0f5c97, #fff);
  }

  .label {
    color: var(--unnamed-color-323338);
    text-align: left;
    font: normal normal 600 16px/22px Open Sans;
    letter-spacing: 0px;
    color: #323338;
    opacity: 1;
    margin-right: 5px;
  }
  .total-amount-price {
    color: var(--unnamed-color-323338);
    text-align: left;
    font: normal normal 600 16px/22px Open Sans;
    letter-spacing: 0px;
    color: #71cf48;
    opacity: 1;
    margin-right: 10px;
  }
  .total-amount-price-dont-have-yet {
    color: var(--unnamed-color-323338);
    text-align: left;
    font: normal normal 600 16px/22px Open Sans;
    letter-spacing: 0px;
    color: #eb4345;
    opacity: 1;
    margin-right: 10px;
  }
}

.application-service-list-service-header-dark {
  color: white;
  height: 45px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  gap: 15px;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  justify-content: space-between;

  .leftContainer{
    display: flex;
    gap: 15px;
    height: 45px;
    align-items: center;
  }

  .completeAllContainer {
    display: flex;
    color: #f5f5f5;
    .compleatedLabel {
      margin: -1px 12px 0 4px;
      font: normal normal 600 16px/22px Open Sans;
    }
  }

  .tittle {
    color: var(--unnamed-color-323338);
    text-align: left;
    font: normal normal 600 16px/22px Open Sans;
    letter-spacing: 0px;
    color: #f5f5f5;
    opacity: 1;
  }

  .detailsApplicationButton {
    @include mondayButton(#1264a3, #0f5c97, #fff);
  }
  .label {
    color: var(--unnamed-color-323338);
    text-align: left;
    font: normal normal 600 16px/22px Open Sans;
    letter-spacing: 0px;
    color: #f5f5f5;
    opacity: 1;
    margin-right: 5px;
  }
  .total-amount-price {
    color: var(--unnamed-color-323338);
    text-align: left;
    font: normal normal 600 16px/22px Open Sans;
    letter-spacing: 0px;
    color: #71cf48;
    opacity: 1;
    margin-right: 10px;
  }

  .total-amount-price-dont-have-yet {
    color: var(--unnamed-color-323338);
    text-align: left;
    font: normal normal 600 16px/22px Open Sans;
    letter-spacing: 0px;
    color: #eb4345;
    opacity: 1;
    margin-right: 10px;
  }
}
