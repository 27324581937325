.hover-button-container {
  background-color: transparent;
  border: 0;
  outline: none;
  font-size: 14px;
  font-weight: 400;
  color: #323338;
  font-family: Open Sans, sans-serif;

  .button-inner-content {
    height: fit-content;
    width: min-content;
    padding: 5px 7px;
    color: inherit;
    white-space: nowrap;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 5px;
    flex-wrap: nowrap;

    .button-inner-text,
    .button-inner-icon {
      transition: color 0.2s ease-in-out;
    }

    .button-inner-icon {
      transition: width 0.2s ease-in-out, opacity 0.3s ease-in-out;

      svg,
      path {
        fill: currentColor;
      }
    }
  }

  .button-inner-content.icon-show {
    .button-inner-icon {
      overflow: visible;
      display: grid;
      place-items: center;
      width: 14px;
      opacity: 1;
      visibility: visible;
    }
  }

  .button-inner-content.icon-hover {
    .button-inner-icon {
      display: grid;
      place-items: center;
      visibility: hidden;
      opacity: 0;
      width: 0;
      overflow: hidden;
    }
  }

  .button-inner-content:where(:not(.content-disabled)):hover {
    .type-new {
      color: #71cf48;
    }
    .type-decline {
      color: #fe4c4a;
    }
    .type-action {
      color: #1264a3;
    }
    .type-save {
      color: #71cf48;
    }

    .button-inner-icon {
      overflow: visible;
      width: 14px;
      opacity: 1;
      visibility: visible;
    }
  }

  &:disabled {
    cursor: not-allowed;
    color: rgba(0, 0, 0, 0.5);
  }
}

.hover-button-container.hover-button-dark {
  color: #fff;

  &:disabled {
    color: #acacca;
  }
}
