.images-list {
  .image {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover !important;
  }
  .ant-image {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover !important;
  }
}
