.groupTableContainer {
  width: 100%;
  height: -webkit-fill-available;
  margin-top: 15px;
  // >div{
  //     width: 100% !important;
  // }
  .ag-header-cell-text {
    font-size: 14px;
  }
  .ag-header-row-column {
    height: 16px;
  }
  .ag-pivot-off {
    height: 13px;
    min-height: 15px;
  }
  .ag-root-wrapper {
    height: 100%;
    overflow: auto;
  }
  .ag-react-container {
    display: flex;
    justify-content: center;
  }
  .ag-row {
    cursor: pointer;
  }
  .ag-cell {
    padding: 0;
    .ag-checkbox-input {
      cursor: pointer;
    }
  }
}
