$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.shortcut-pliDays-modal {
  .ant-modal-header {
    display: flex;
    align-items: center;
    height: 45px;
    background-color: #f5f5f5;
  }
  .ant-modal-footer {
    display: flex;
    justify-content: space-between;
  }
  .mainContentShortcutDays {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .actionsShortcutDays {
    display: flex;
    // justify-content: center;
    align-items: center;
    gap: 20px;
    .shortcutForBreakdowns {
      width: 250px;
      border: none;
      background-color: #f8f8fa;
    }
  }

  .daysContainerShortcut {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 1502px;
    flex-wrap: wrap;
  }
  .shortcut-pliDays-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    background-color: #f8f8fa;
    border: none;
    border-radius: 5px;
  }
  .allDaysShortcutPLIButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 124px;
    height: 32px;
    border-radius: 5px;
    border: none;
    background-color: #f8f8fa;
    color: #333238;
  }
}

.shortcut-pliDays-modal-dark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      .actionsShortcutDays {
        .ant-alert-info {
          background-color: #373c4b;
          .ant-alert-message span {
            color: #fff;
          }
        }
      }
      .documentationsTable {
        // .ag-root-wrapper-body.ag-layout-normal {
        // height: fit-content !important;
        // }
        .ag-header {
          height: 41px !important;
          max-height: 41px !important;
          .ag-header-cell {
            padding-left: var(--ag-cell-horizontal-padding) !important;
            padding-right: var(--ag-cell-horizontal-padding) !important;
            .ag-header-cell-resize::after {
              background-color: grey !important;
            }
          }
        }
      }
      .daysContainerShortcut {
        .ant-btn {
          background-color: $inputModalDark;
          color: #fff;
        }
      }
    }
  }
  .mondayButtonGreyDark {
    color: #fff;
    background-color: #363746;

    .mondayButtonDivider {
      background-color: #2a2b3a !important;
    }
    .mondayButtonIcon {
      fill: #fff;
    }
  }
}

.transfer-days-modal {
  .ant-modal-header {
    display: flex;
    align-items: center;
    height: 45px;
    background-color: #f5f5f5;
  }
  .ant-modal-footer {
    display: flex;
    justify-content: space-between;
  }
  .ant-modal-content {
    width: 1000px;
    height: fit-content;
  }
  // .daysInPLiGrid {
  //   .ag-body {
  //     height: 400px !important;
  //     overflow: auto !important;
  //   }
  // }
}
