.card-horizontal-progress-chart {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  .progress-items {
    height: 100%;
    width: 100%;
    padding: 0 5px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    overflow: auto;
  }
}
