.proposalDocumentSelector {
  gap: 20px;
  display: flex;
  min-width: 60vw;
  min-height: 60vh;
  width: fit-content;
  height: fit-content;
  padding-bottom: 20px;
  flex-direction: column;
  justify-content: space-between;
  .document-selector-body {
    flex: 1;
    gap: 20px;
    display: flex;
    flex-direction: column;
    .tabs-container {
      gap: 20px;
      display: flex;
      .tab {
        height: 32px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 10px;
        padding: 5px;
        margin: 0;
        background: #f5f5f7 0% 0% no-repeat padding-box;
        color: #323338;

        border-radius: 5px;
        cursor: pointer;
        &:hover {
          background: #f1f5fd 0% 0% no-repeat padding-box;
          color: #1264a3;
        }
      }
      .tab.selected {
        background: #f1f5fd 0% 0% no-repeat padding-box;
        color: #1264a3;
      }
    }
  }
  .document-selector-footer {
    display: flex;
    justify-content: space-between;
  }
}
