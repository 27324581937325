$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.jumps-config-modal {
  .ant-modal-header {
    display: flex;
    align-items: center;
    width: 993px;
    height: 45px;
    background: #f8f8fa 0% 0% no-repeat padding-box;
    border-radius: 10px 10px 0px 0px;
    opacity: 1;
  }
  .ant-modal-close-x {
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    font-size: 20px;
  }

  .ant-modal-title {
    display: flex;
    align-items: center;
    width: 100%;
    height: 22px;
    color: var(--unnamed-color-323338);
    text-align: left;
    font: normal normal 600 16px/39px Open Sans;
    letter-spacing: 0px;
    color: #323338;
    opacity: 1;
  }

  .ant-modal-content {
    border-radius: 10px;
  }
  .ant-modal-body {
    overflow: auto;
    height: fit-content;
    max-height: 750px;
    overflow: auto;
  }
  .header-content {
    display: flex;
    justify-content: space-between;
    div {
      width: 88px;
      height: 22px;
      color: var(--unnamed-color-323338);
      text-align: left;
      font: normal normal 600 16px/39px Open Sans;
      letter-spacing: 0px;
      color: #323338;
      opacity: 1;
    }
  }
  .upIconCss {
    width: 22;
    height: 22;
    border-radius: 5px;
    background-color: #1264a3;
  }
  .footer-content-jump-modal {
    display: flex;
    justify-content: space-between;
    .flex-center-jump-icons {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .addJumpIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      width: 32px;
      height: 32px;
      background: #71cf48 0% 0% no-repeat padding-box;
      border-radius: 5px;
      opacity: 1;
      color: #fff;
    }
    .removeJumpIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      width: 32px;
      height: 32px;
      background: #fe4c4a 0% 0% no-repeat padding-box;
      border-radius: 5px;
      opacity: 1;
      color: #fff;
    }
  }
  .hoistConfigContent {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .floorsContent {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .infoIconFloor {
    display: flex;
    flex-direction: row;
    gap: 5px;
  }
  .content-div-floors {
    display: flex;
    flex-direction: row;
    gap: 5px;
    max-width: 1080px;
    flex-wrap: wrap;
  }
  .scheduleFloors-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    background-color: #f8f8fa;
    border: none;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
  }
  .inputBtnDiv {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
  }
  .ant-popover-content {
    width: 531px;
    height: fit-content;
    background: #f8f8fa 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #00000029;
    border-radius: 10px;
    opacity: 1;
  }
}

.jumps-config-modal-dark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      .hoistConfigContent {
        .infoIconFloor span {
          color: #fff;
        }
        .content-div-floors {
          .ant-btn-default {
            background-color: $inputModalDark !important;
            color: #fff;
            &:disabled {
              color: #acacca !important;
            }
          }
        }
        .inputBtnDiv {
          .ant-input {
            background-color: $inputModalDark !important;
            color: #fff;
            &::placeholder {
              color: #acacca;
            }
          }
          .ant-input-disabled {
            color: #acacca;
          }
        }
      }
    }
  }
}
