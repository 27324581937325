@import "../../../../../../../index.scss";

$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.addNewRental {
  top: 0 !important;
  padding: 0 !important;
  width: 100% !important;
  // height: 100dvh !important;
  margin: 0;
  max-width: 100%;
  overflow: hidden;
  &::before {
    display: none !important;
  }
  .ag-root-wrapper-body.ag-layout-normal {
    height: fit-content !important;
  }
  // .ant-modal {
  //   width: 100vw !important;
  //   height: 100vh !important;
  //   padding: 0 !important;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  // }

  .ant-modal-content {
    border-radius: 0;
    width: 100%;
    height: 100dvh;
    // overflow: hidden;

    .ant-modal-header {
      width: stretch;
      border-bottom: 0;
      height: 45px;
      display: flex;
      align-items: center;
      background-color: #f8f8fa;

      .ant-modal-title {
        font-size: 16px;
        font-weight: 600;
      }
    }
    .ant-modal-close-x {
      width: 45px;
      height: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        height: 15px !important;
        width: 15px !important;
      }
    }

    .ant-modal-footer {
      background-color: #fff;
      width: stretch;
      border-top: 0;
      padding: 0;
      display: flex;
      justify-content: flex-end;
    }

    .ant-modal-body {
      height: calc(100dvh - 150px);
      width: 100vw;
      padding: 0;

      .currencyContainer {
        font-weight: 600;
        margin-left: 5px;
        color: #71cf48;
      }

      .currencyContainerZero {
        font-weight: 600;
        margin-left: 5px;
        color: #eb4345;
      }

      .ant-radio-wrapper {
        height: 40px;
        width: 320px;
        overflow: hidden;
        display: flex;
        align-items: center;
        background-color: #f8f8fa;

        &:hover {
          background-color: #d3d3d3;

          .ant-radio-inner {
            border: 10px solid #fe4c4a !important;
            background-color: #e03d3a;
            margin: 0px !important;
            border-radius: 0 !important;
            height: 40px;
            width: 40px;
          }
        }

        .ant-radio {
          height: 100%;
          width: 40px;
          margin: 0;
          margin-top: -5px !important;
        }
        .ant-radio-inner {
          border: 4px solid #fe4c4a !important;
          background-color: #e03d3a;
          margin: 0px !important;
          border-radius: 0 !important;
          height: 100%;
          width: 0%;
          &:hover {
            border: 10px solid #fe4c4a !important;
            background-color: #e03d3a;
            margin: 0px !important;
            border-radius: 0 !important;
            height: 40px;
            width: 40px;
            position: relative;
          }
          &::after {
            width: 0;
            height: 0;
          }
        }
      }

      .ant-radio-wrapper-checked {
        position: relative;
        &:hover {
          &::after {
            border: 10px solid #68c142 !important;
          }
        }

        &::after {
          display: flex;
          justify-content: center;
          align-items: center;
          border: 10px solid #71cf48 !important;
          content: url("../../../../../../pages/Settings/settingsComponents/Roles/src/Tick.svg");
          fill: black !important;
          background-color: white;
          width: 40px;
          height: 40px;
          border-radius: 0px;
          position: absolute;
          z-index: 5;
        }
      }

      .ant-checkbox-wrapper {
        height: 40px !important;
        width: 380px;
        overflow: hidden;
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        background-color: #f8f8fa;

        &:hover {
          background-color: #d3d3d3;

          .ant-checkbox-inner {
            border: 10px solid #fe4c4a !important;
            background-color: #e03d3a;
            margin: 0px !important;
            border-radius: 0 !important;
            height: 40px;
            width: 40px;
          }
        }

        .ant-checkbox {
          height: 100%;
          width: 40px;
          margin: 0;
          margin-top: -5px !important;
        }
        .ant-checkbox-inner {
          border: 4px solid #fe4c4a !important;
          background-color: #e03d3a;
          margin: 0px !important;
          border-radius: 0 !important;
          height: 100%;
          width: 0%;
          &:hover {
            border: 10px solid #fe4c4a !important;
            background-color: #e03d3a;
            margin: 0px !important;
            border-radius: 0 !important;
            height: 40px;
            width: 40px;
            position: relative;
          }
          &::after {
            width: 0;
            height: 0;
          }
        }
      }

      .ant-checkbox-wrapper-checked {
        position: relative;
        &:hover {
          &::after {
            border: 10px solid #68c142 !important;
          }
        }

        &::after {
          display: flex;
          justify-content: center;
          align-items: center;
          border: 10px solid #71cf48 !important;
          content: url("../../../../../../pages/Settings/settingsComponents/Roles/src/Tick.svg");
          fill: black !important;
          background-color: white;
          width: 40px;
          height: 40px;
          border-radius: 0px;
          position: absolute;
          z-index: 5;
        }
      }

      .container {
        padding: 15px;
        display: flex;
        flex-direction: column;
        gap: 15px;
        height: 800px;
        .body {
          display: flex;
        }
      }
    }
  }
}

.addNewRentalDark {
  .ag-root-wrapper-body.ag-layout-normal {
    height: fit-content !important;
  }
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      .container {
        .informationHeader {
          box-shadow: 0px 1px 4px #ffffff94;
          color: #fff;
          .unselectedButton {
            background-color: $cardModalBodyDark;
          }
        }
      }
    }
    .ant-modal-footer {
      background-color: $cardModalBodyDark;
      .newRentalFooterButtonContainer {
        background-color: $cardModalBodyDark;
      }
    }
  }
}
.estimationPickerMainContainerRental {
  display: flex;
  gap: 20px;
  flex-direction: row !important;
  max-height: 500px;
  height: 500px;
  // background-color: green;
  .estimationPickerContainer {
    // background-color: red;
    max-width: 900px !important;
    flex: 1;
    overflow-y: auto;
  }
  .servicePickerContainer {
    // background-color: yellow !important;
    flex: 1;
    max-width: 900px !important;
    flex-wrap: wrap;
    .ant-checkbox-wrapper {
      width: 720px !important;
    }
  }
  // .estimationPickerContainer {
  //   box-shadow: 0px 1px 4px #ffffff94;
  //   .tittle {
  //     color: #fff;
  //   }
  //   .ant-radio-group {
  //     .ant-radio-wrapper {
  //       background-color: $inputModalDark;
  //       span {
  //         color: #fff;
  //         svg {
  //           fill: #fff;
  //         }
  //       }
  //     }
  //   }
  // }
  // .servicePickerContainer {
  //   box-shadow: 0px 1px 4px #ffffff94;
  //   .tittle {
  //     color: #fff;
  //   }
  //   .no-schedule-of-values {
  //     color: #fff;
  //   }
  //   .ant-checkbox-group {
  //     .ant-checkbox-wrapper {
  //       background-color: $inputModalDark;
  //       span {
  //         color: #fff;
  //       }
  //     }
  //   }
  // }
}
@media (max-width: 767px) {
  .addNewRental {
    .ant-modal-content {
      width: 100%;
      .ant-modal-body {
        min-width: calc(100vw - 30px);
        .container {
          max-width: calc(100vw - 20px);
          width: 100%;
          // .estimationPickerMainContainer {
          //   display: grid;
          //   grid-template-columns: 49% 49%;
          //   .estimationPickerContainer,
          //   .servicePickerContainer {
          //     width: 100%;
          //     overflow-x: hidden;
          //     overflow-y: auto;
          //   }
          // }
        }
      }
    }
  }
}

@media (max-width: 450px) {
  .addNewRental {
    .ant-modal {
      top: 0px !important;
    }
    .ant-modal-content {
      height: calc(100vh - 40px);
      .ant-modal-body {
        height: calc(100vh - 140px);
        .container {
          .informationHeader {
            height: fit-content;
            flex-wrap: wrap;
            padding: 5px 15px;
          }
          // .estimationPickerMainContainer {
          //   display: flex;
          //   flex-direction: column;
          //   align-items: flex-start;
          //   .estimationPickerContainer,
          //   .servicePickerContainer {
          //     width: 88vw;
          //   }
          // }
        }
      }
      // .ant-modal-footer
      // .newRentalFooterButtonContainer{
      //   height: fit-content;
      //   flex-direction: column;
      //   align-items: center;
      // }
    }
  }
}

@media (max-width: 320px) {
  .addNewRental
    .ant-modal-content
    .ant-modal-footer
    .newRentalFooterButtonContainer {
    // height: fit-content;
    // flex-direction: column;
    // align-items: center;
    .dropDownButtonNewRental .ant -space-compact-block {
      width: auto;
    }
    padding: 5px 15px;
  }
}
