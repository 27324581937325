.shortCutContainer {
  display: flex;
  align-items: center;

  .thisPeriodTotal {
    width: 150px;
    height: 32px;
    margin-right: 10px;
    // background-color: lightgray;
    border-radius: 5px;
    color: #71cf48;
    font-size: 16px;
    display: flex;
    justify-content: left;
    align-items: center;
  }

  .balanceInput {
    width: 150px;
    height: 32px;
    margin-right: 10px;
    background-color: lightgray;
    color: black;
    -webkit-appearance: none;
  }

  .warningIcon {
    width: 20px;
    height: 20px;
    position: relative;
    margin-left: 5px;

    svg {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 0;
      fill: red;
    }
  }

  .addButton {
    width: 50px;
    height: 32px;
    margin-right: 10px;
    border: 0;
    border-radius: 5px;

    background-color: #0f5c97;
    font-size: 14px;
  }

  .resetButton {
    width: 50px;
    height: 32px;
    margin-right: 10px;
    border: 0;
    border-radius: 5px;
    border-radius: 5px;
    text-align: center;
    background-color: #0f5c97;
    font-size: 14px;
  }

  .labels {
    // background-color: lightgreen;
    margin-left: 10px;
    margin-right: 5px;
    font-size: 16px;
  }
}
