@import "../../../index.scss";

$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.dynamicAvatarsDiv:hover {
  font-size: 14px;
  font-weight: 600;
  .dynamicAvatar {
    width: 35px;
    height: 35px;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid white;
    cursor: pointer;
  }
  .ant-avatar-string {
    cursor: pointer;
    color: #1264a3;
  }
}

.dynamicAvatarsModal {
  width: fit-content !important;
  overflow: hidden;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;

  .ant-modal-content {
    width: calc(100% - 32px);
    .ant-modal-close {
      height: 45px;
      width: 45px;
      .ant-modal-close-x {
        width: 45px;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .ant-modal-header {
      height: 45px;
      display: flex;
      align-items: center;
      background-color: #f4f5f6;
      border: none;
      .ant-modal-title {
        font-size: 16px;
        font-weight: 600;
      }
    }
    .ant-modal-body {
      overflow-y: auto;
      max-height: 404px;

      .dynamicAvatarHeader-container {
        display: flex;
        flex-direction: column;
        gap: 5px;

        .dynamicAvatarModal-name {
          font-size: 24px;
          font-weight: 600;
          color: #323338;
        }
        .dynamicAvatarDiv {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          .dynamicAvatar-item {
            display: flex;
            align-items: center;
            gap: 10px;
            .dynamicAvatarLength {
              font-size: 14px;
              font-weight: 400;
              color: #323338;
            }
            .dynamicAvatarHeader {
              display: flex;
              gap: 5px;
              .dynamicAvatarForeman {
                font-weight: 600;
              }
            }
          }
          .dynamicAvatarData {
            display: flex;
            align-items: center;
            gap: 10px;
            height: 19px;
            .dynamicAvatar-created {
              font-weight: 600;
            }
          }
        }
      }

      .dynamicAvatarsModalDiv {
        // margin-top: 40px;
        display: grid;
        gap: 30px;
        grid-template-columns: repeat(4, 1fr);
        .dynamicAvatarsItem {
          display: flex;
          align-items: center;
          gap: 10px;
          .dynamicAvatarLabel {
            display: flex;
            flex-direction: column;
            .dynamicAvatarName {
              font-size: 16px;
              font-weight: 600;
              color: #323338;
            }
            .dynamicAvatarRole {
              font-size: 14px;
              font-weight: 400;
              color: #323338;
            }
          }
        }
      }
    }
    .ant-modal-footer {
      .mondayButtonStyle {
        width: 150px;
      }
    }
  }
}

.dynamicAvatarsModalDark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      .dynamicAvatarHeader-container {
        .dynamicAvatarModal-name {
          color: #fff;
        }
        .dynamicAvatarDiv {
          .dynamicAvatar-item {
            color: #fff;
            .dynamicAvatarLength {
              color: #fff;
            }
          }
          .dynamicAvatarData {
            color: #fff;
          }
        }
      }
      .dynamicAvatarsModalDiv {
        .dynamicAvatarsItem {
          .dynamicAvatarLabel {
            .dynamicAvatarName {
              color: #fff;
            }
          }
        }
      }
    }
  }
}
