.preview-modal__takeoff {
  // antd modal body
  // i want margin top

  .ant-modal-content {
    margin-top: 50px;
  }

  .ant-modal-header {
    background-color: red;
    height: 45px;
    background: #f8f8fa;
    border-radius: 10px 10px 0px 0px;
    display: flex;
    align-items: center;
  }

  // position the x of modal in the center vertically of antd header

  .ant-modal-close-x {
    justify-content: center;
    align-items: center;
    display: flex;
    height: 45px;
    width: 45px;
    padding: 0;
    svg {
      fill: #333238;
      height: 15px;
      width: 15px;
    }
  }

  .ant-modal-body {
    // background-color: red;
    // set height to be scrollable
    height: 80vh;
    overflow-y: auto;
    padding: 0 !important;
    p {
      font-size: 14px !important;
      font-family: "Open Sans" !important;
      // line-height: 19px !important;
    }

    .elevation_note_takeoff_alert {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      p {
        margin: 0 !important;
      }
    }
    .address_preview_takeoff {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      display: flex;
      justify-content: center;
      color: #323338;
    }
    .serviceNote__takeoffPreview {
      display: flex;
      gap: 10px;
      strong {
        color: red;
      }
    }
    .takeoff_preview__title {
      display: flex;
      align-items: center;
      height: "10px";
      gap: 10px;
      margin-bottom: 10px;
      .circleshape {
        width: 11px;
        height: 11px;
        border-radius: 50%;
        // margin: 0 11px 4px 3px;
        display: inline-block;
        // background: red;
        display: flex;
        align-items: center;
      }
      l .serviceIndic__takeoff_preview {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background-color: blue;
      }
    }
    .client_preview_takeoff {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: #323338;
      display: flex;
      justify-content: center;
    }
    .serviceParent__takeoffPreview {
      //   background-color: red !important;
      //   background: yellow !important;
      padding: 20px;
    }
  }
}

.preview-modal__takeoff__dark {
  // antd modal body
  // i want margin top

  .ant-modal-content {
    margin-top: 50px;
    border-radius: 20px;
  }

  .ant-modal-header {
    height: 45px;
    background: #12131b;
    border-radius: 10px 10px 0px 0px;
    color: #fff;
    display: flex;
    align-items: center;
    border: none;
    .ant-modal-title {
      color: #fff;
    }
  }
  .ant-modal-close-x {
    justify-content: center;
    align-items: center;
    display: flex;
    height: 45px;
    width: 45px;
    padding: 0;
    svg {
      width: 15px;
      height: 15px;
      fill: #fff;
    }
  }
  .ant-modal-body {
    // background-color: red;
    // set height to be scrollable
    border: none !important;
    height: 80vh;
    padding: 0 !important;
    overflow-y: auto;
    background-color: #1f212d;
    color: #fff;
    .address_preview_takeoff,
    .client_preview_takeoff {
      color: #fff !important;
    }

    p {
      font-size: 14px !important;
      font-family: "Open Sans" !important;
      // line-height: 19px !important;
    }

    .elevation_note_takeoff_alert {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      p {
        margin: 0 !important;
      }
    }
    .address_preview_takeoff {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      display: flex;
      justify-content: center;
      color: #323338;
    }
    .serviceNote__takeoffPreview {
      display: flex;
      gap: 10px;
      strong {
        color: red;
      }
    }
    .takeoff_preview__title {
      display: flex;
      align-items: center;
      height: "10px";
      gap: 10px;
      margin-bottom: 10px;
      .circleshape {
        width: 11px;
        height: 11px;
        border-radius: 50%;
        // margin: 0 11px 4px 3px;
        display: inline-block;
        // background: red;
        display: flex;
        align-items: center;
      }
      l .serviceIndic__takeoff_preview {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background-color: blue;
      }
    }
    .client_preview_takeoff {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: #323338;
      display: flex;
      justify-content: center;
    }
    .serviceParent__takeoffPreview {
      //   background-color: red !important;
      //   background: yellow !important;
      padding: 20px;
      background: #12131b !important;
    }
  }
}
