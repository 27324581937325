.noConversationContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 10px;
  .welcomeToInternalChat {
    font-weight: 600;
    font-size: 18px;
    color: #231f20;
  }
  .texts {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
