.all-comments-container {
  .current-comments {
    padding: 5px;
  }
}

.CommentsContainerDark {
  .ant-divider {
    .ant-divider-inner-text {
      color: #f8f8fa;
    }
    &::before {
      background-color: #f8f8fa;
    }
    &::after {
      background-color: #f8f8fa;
    }
  }
}
