.location-info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  border-radius: 5px;
  padding: 0.5rem 0.3rem;
  cursor: default;

  .location-header {
    display: grid;
    width: calc(100% - 20px);
    grid-template-columns: auto auto;
    column-gap: 1.2rem;
    margin-bottom: 0.3rem;

    .name-section {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 14;
      font-weight: 600;
      letter-spacing: 0.28px;
      width: 100%;
      gap: 10px;
    }

    .status-section {
      font-size: 14px;
      font-weight: 400;
      color: rgb(91, 153, 226);
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 5px;

      .status-circle {
        width: 8px;
        height: 8px;
        aspect-ratio: 1;
        border-radius: 50%;
        display: block;
        background-color: rgb(91, 153, 226);
      }
    }

    .Idling {
      color: rgb(223, 196, 0);
      .status-circle {
        background-color: rgb(223, 196, 0);
      }
    }

    .Moving {
      color: rgb(124, 202, 0);
      .status-circle {
        background-color: rgb(124, 202, 0);
      }
    }

    .Stopped {
      color: rgb(220, 64, 69);
      .status-circle {
        background-color: rgb(220, 64, 69);
      }
    }

    .InMotion {
      color: rgb(10, 136, 0);
      .status-circle {
        background-color: rgb(10, 136, 0);
      }
    }

    .location-info-close {
      position: absolute;
      top: 2px;
      right: 5px;
      cursor: pointer;
      color: black;
    }
  }

  .location-vin {
    font-size: 0.7rem;
    color: rgba(0, 0, 0, 0.7);
    text-align: left;
    width: 100%;
    margin-bottom: 1rem;
  }

  .location-body {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    row-gap: 5px;
    column-gap: 15px;
    width: 100%;

    .info-data {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;
      flex-wrap: nowrap;
      gap: 2px;

      .info-title {
        font-weight: bold;
        letter-spacing: 0.28px;
      }
    }
  }

  .info-footer {
    margin-top: 0.8rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }
}

.location-info.location-info-dark {
  background-color: #20212d;

  .location-header {
    .name-section {
      color: #ffffff;
    }
  }
}

.map-location-info {
  .location-header {
    width: 100%;

    .name-section {
      font-size: 1rem;
      font-weight: bold;
    }

    .status-section {
      font-size: 1rem;
      font-weight: bold;
    }

    .status-circle {
      height: 0.6rem;
      width: 0.6rem;
      aspect-ratio: 1;
    }
  }
}
