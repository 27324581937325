@import "../../../../../../index.scss";

.subcontractorsStepContainer {
  .bodyContainer {
    @include flex(center, center);
    flex-wrap: wrap;
    width: 100%;
  }

  .inputsContainer {
    // width: 100%;
    display: grid;
    grid-template-columns: max-content max-content;
    column-gap: 100px;
    row-gap: 40px;
  }

  .inputsPair {
    @include flex(center, flex-start, column);
    width: 300px;
  }

  .labeledInputContainer {
    width: 100%;
  }
}

@media (max-width: 820px) {
  .subcontractorsStepContainer {
    .inputsContainer {
      grid-template-columns: 45% 45%;
      column-gap: 50px;
    }
    .inputsPair {
      width: 100%;
    }
  }
}

@media (max-width: 575px) {
  .subcontractorsStepContainer {
    .inputsContainer {
      display: flex;
      flex-direction: column;
      row-gap: 0px;
    }
  }
}
