.dashboard-step {
  height: 100%;
  max-height: calc(100% - 20px);

  .dashboard-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      path {
        fill: #323338;
      }
    }
  }
  .boarding-navigation-buttons {
    align-self: center;
  }
}
