@import "../../../../../../index.scss";

$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.newFleetFormContainer {
  // width: 100%;
  // height: 100%;
  // object-fit: contain;
  // overflow: auto;

  .borderedTextCard {
    align-items: center;
  }
  .uppercaseInput {
    .ant-input {
      text-transform: uppercase;
    }
  }

  .ant-form {
    width: 100%;
  }

  .cardsContainer {
    @include flex(unset, space-between);
  }

  .labeledInputContainer {
    margin-right: 15px;

    .labeledInputLabel {
      @include text(14px, 600, 0.21px, #383554);
      margin-bottom: 2px;
    }
  }

  // .ant-input {
  //   font-weight: 400;
  //   border: 0.5px solid rgba(0, 0, 0, 0.25) !important;
  //   border-radius: 2px !important;
  //   width: 100%;
  // }

  // .ant-picker {
  //   border: 0.5px solid rgba(0, 0, 0, 0.25) !important;
  //   border-radius: 2px !important;
  //   width: 100%;
  //   .ant-picker-input > input {
  //     font-weight: 400;
  //   }
  // }

  .radioButtonOptions {
    align-self: center;
    .ant-form-item {
      margin-bottom: 0;
      .ant-radio-wrapper {
        @include text(14px, 400, 0, #383554);
      }
    }
  }

  // .ant-select-selector {
  //   background: #f8f8f8 !important;
  //   overflow: hidden !important;
  //   border: 0.5px solid rgba(0, 0, 0, 0.25) !important;
  //   border-radius: 2px !important;
  //   font-weight: 400;
  //   width: 100% !important;
  //   .ant-select-selection-search {
  //     background-color: #fff;
  //     margin-right: 7px;
  //     margin-left: -11px;
  //     padding-left: 11px;
  //     border-right: 0.5px solid #dbdbdb;
  //   }
  //   .ant-select-selection-item {
  //     font-weight: 400;
  //     padding-right: 32px !important;
  //   }
  // }

  .ql-container {
    margin-bottom: 24px;
    .ql-editor {
      height: 80px;
    }
  }

  .uploadButton {
    font-family: Helvetica;
    @include text(14, 400, 00.21px, #7b7b7b);
    background-color: #f8f8f8;
  }

  .googlePickerContainer {
    @include flex(flex-start, flex-start);

    .uploadedFileNamesContainer {
      @include flex(flex-start, flex-start, column);
      @include text(11px, 400, 0, #d69615);
      margin-left: 10px;
    }
  }
}

.ant-dropdown-menu-item-active {
  color: #1890ff !important;
}

.new_fleet_modal {
  .footerButtons {
    display: flex;
    justify-content: space-between;
  }
  // height: 100%;
  .ant-modal {
    width: calc(100vw - 20px) !important;
    // max-width: 1500px !important;
    max-width: 1500px;
  }
  overflow: hidden;
  .ant-modal-content {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .ant-modal-body {
      display: flex;
      flex-direction: column;
      // height: 100%;
      max-height: calc(95vh - 100px);
      // overflow-x: hidden;
      // overflow-y: auto;
      .newFleetFormContainer {
        overflow-y: auto;
        overflow-x: hidden;
        .borderedTextCard {
          padding: 15px 0px 0px 0px;
          border-radius: 0;
          border: none;
          border-top: 1px solid black;
          align-items: center;
          .cardTitle {
            font-size: 16;
            color: black;
          }
          .childrenContainer {
            width: 100%;
          }
        }
        .vehicleInfoContainer {
          margin-top: 20px;
          .borderedTextCard {
            .labeledInputContainer {
              margin-right: 0;
            }
            .childrenContainer {
              display: flex;
              gap: 10px;
              align-items: center;
            }
          }
        }
        .dmv-description-container {
          display: grid;
          grid-template-columns: [full-start] 40% 60% [full-end];
          column-gap: 10px;

          .borderedTextCard.dmvContainer {
            .childrenContainer {
              display: flex;
              gap: 10px;
            }
            .labeledInputContainer {
              margin-right: 0;
              // max-width: 285px;
              width: 49%;
              min-width: 115px;
            }
          }
          .borderedTextCard.descriptionContainer {
            padding-right: 10px;
            .richTextComp {
              border-radius: 5px;
              .tox-tinymce {
                border-radius: 5px;

                .tox-editor-container {
                  max-height: 145px;
                }
                .tox-toolbar__primary {
                  background-color: #f5f5f7;
                  display: flex;
                  justify-content: flex-start;
                  width: 100%;
                  // gap: 5%;
                  // .tox-toolbar__group {
                  // display: flex;
                  // flex-wrap: nowrap;
                  // width: max-content;
                  // gap: 3%;
                  // }
                }
                .tox-sidebar-wrap {
                  border-radius: 5px;
                  .tox-edit-area {
                    border-radius: 5px;
                    .tox-edit-area__iframe {
                      margin-top: 10px;
                      background: #f5f5f7;
                      border-radius: 5px;
                      border: none;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1400px) {
  .dmv-description-container {
    display: flex !important;
    flex-direction: column !important;
  }
}

@media (max-width: 750px) {
  .vehicleInfoContainer {
    .childrenContainer {
      .labeledInputContainer {
        width: 48% !important;
        .ant-form-item {
          .ant-form-item-control-input {
            .ant-picker {
              width: 100% !important;
            }
          }
        }
      }
    }
  }
  .borderedTextCard.dmvContainer {
    .labeledInputContainer {
      width: 48% !important;
    }
  }
}

.newFleetFormContainerDark {
  .borderedTextCard {
    border-top: 1px solid #fff;
    .cardTitle {
      background-color: $cardModalBodyDark;
      color: #fff !important;
    }
    .childrenContainer {
      .labeledInputLabel {
        color: #fff;
      }
      .ant-input {
        background-color: $inputModalDark !important;
        color: #fff;
        &::placeholder {
          color: #acacca;
        }
      }
      .ant-select {
        .ant-select-selector {
          background-color: $inputModalDark !important;
          .ant-select-selection-search-input {
            color: #fff;
          }
          .ant-select-selection-overflow-item {
            .ant-select-selection-item {
              color: #fff;
              background-color: #333238;
              .ant-select-selection-item-remove svg {
                fill: #fff;
              }
            }
          }
          .ant-select-selection-item {
            color: #fff;
          }
          .ant-select-selection-placeholder {
            color: #acacca;
          }
        }
        .ant-select-clear {
          background-color: $inputModalDark;
          svg {
            fill: #acacca;
          }
        }
        .ant-select-arrow svg {
          fill: #fff;
        }
      }
      .ant-picker {
        background-color: $inputModalDark !important;
        .ant-picker-input {
          background-color: $inputModalDark;
          input {
            color: #f2f2f2;
            &::placeholder {
              color: #acacca;
            }
          }
          .ant-picker-suffix svg {
            color: #acacca;
          }
          .ant-picker-clear {
            background-color: $inputModalDark;
            svg {
              fill: #acacca;
            }
          }
        }
      }
      .ant-input-group-wrapper {
        .ant-input-wrapper {
          background-color: $inputModalDark !important;
          .ant-input-group-addon {
            color: #fff !important;
            background-color: $inputModalDark !important;
          }
        }
      }
      .tox {
        border: none;
        .tox-menubar {
          background-color: $cardModalHeaderDark;
          border-radius: 5px 5px 0px 0px;
          .tox-mbtn__select-label {
            color: #fff;
          }
        }
        .tox-mbtn--select:hover {
          background-color: $cardModalBodyDark;
        }
        .tox-toolbar-overlord {
          background-color: $cardModalHeaderDark !important;
          .tox-toolbar__primary {
            background-color: $cardModalHeaderDark !important;
          }
          .tox-toolbar__group {
            background-color: $cardModalHeaderDark !important;
            border: none;
            .tox-split-button:hover {
              border: none;
            }
            .tox-tbtn {
              svg {
                fill: #fff;
              }
              &:focus {
                background-color: $cardModalBodyDark;
              }
              &:hover {
                background-color: $cardModalBodyDark;
              }
            }
            .tox-tbtn__select-label {
              color: #fff !important;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 450px) {
  .vehicleInfoContainer {
    .childrenContainer {
      .labeledInputContainer {
        width: 100% !important;
        .ant-form-item {
          .ant-form-item-control-input {
            .ant-picker {
              width: 100% !important;
            }
          }
        }
      }
    }
  }
  .borderedTextCard.dmvContainer {
    .labeledInputContainer {
      width: 100% !important;
    }
  }
}
