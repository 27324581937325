.assistant-chat-container {
  background-color: #fff;
  background-image: url("../../../../../icons/BuildingsDimmed.png");
  background-repeat: no-repeat;
  background-size: calc(100% + 10px) 200px;
  background-position-x: -5px;
  background-position-y: -5px;
  border-radius: 10px;
  box-sizing: border-box;
  max-height: calc(100dvh - 120px);
  max-width: calc(100dvw - 168px);
  padding: 10px 0px;
  height: 742px;
  width: 500px;
  display: grid;
  row-gap: 10px;
  overflow: hidden;
  transition: height 0.2s ease-in-out, width 0.2s ease-in-out,
    grid-template-rows 1s ease-in-out;

  .assistant-chat-header {
    grid-area: "header";
    padding: 0px 20px;
  }

  .assistant-chat-body {
    grid-area: "chat";
  }

  .assistant-chat-input {
    grid-area: "input";
    padding: 0px 20px;
  }

  .assistant-chat-footer {
    grid-area: "footer";
    padding: 10px 20px;
  }

  .assistant-connecting-view {
    grid-area: "connecting";
  }
}

.assistant-chat-container.chat-connecting {
  grid-template-areas: "header" "connecting" "footer";
  grid-template-rows: 70px min(593px, calc(100% - 129px)) 39px;
}

.assistant-chat-container.chat-connected {
  grid-template-areas: "header" "chat" "input" "footer";
  grid-template-rows: 70px min(483px, calc(100% - 239px)) 100px 39px;
}

.assistant-chat-container.chat-resized {
  height: calc(100dvh - 74px);
  width: calc((100dvh - 74px) * 0.67);
  max-width: calc(100dvw - 168px);
  transition: height 0.2s ease-in-out, width 0.2s ease-in-out,
    grid-template-rows 0s ease-in-out;
}

.assistant-chat-container.chat-resized.chat-connecting {
  grid-template-areas: "header" "connecting" "footer";
  grid-template-rows: 70px calc(100% - 129px) 39px;
}

.assistant-chat-container.chat-resized.chat-connected {
  grid-template-areas: "header" "chat" "input" "footer";
  grid-template-rows: 70px calc(100% - 239px) 100px 39px;
}

@media only screen and (max-width: 767px) {
  .assistant-chat-container {
    max-height: 650px;
    width: calc(100dvw - 40px);
    max-width: 500px;
    transform: translateX(10px);
  }

  .assistant-chat-container.chat-resized {
    max-height: calc(100dvh - 94px);
    height: calc(100dvh - 94px);
    width: calc(100dvw - 40px);
    max-width: calc((100dvh - 40px) * 0.67);
  }
}

@media only screen and (max-height: 767px) {
  .assistant-chat-container,
  .assistant-chat-container.chat-resized {
    height: calc(100dvh - 90px);
    padding-top: 0px;
    max-width: 500px;
    width: calc(100dvw - 40px);
    background-image: unset;
  }

  .assistant-chat-container.chat-connecting,
  .assistant-chat-container.chat-resized.chat-connecting {
    display: grid;
    grid-template-areas: "connecting" "footer";
    grid-template-rows: calc(100% - 59px) 39px;
    row-gap: 10px;

    .assistant-chat-header {
      display: none;
    }
  }

  .assistant-chat-container.chat-connected,
  .assistant-chat-container.chat-resized.chat-connected {
    grid-template-areas: "chat" "input" "footer";
    grid-template-rows: calc(100% - 109px) 49px 39px;

    .assistant-chat-header {
      display: none;
    }
  }
}
