$cardModalBodyDark: #1f212d;

.est-container-data {
  display: flex;
  // flex-direction: column;
  flex-wrap: wrap;
  gap: 1.5rem;
  width: 100%;
  overflow: auto;
  padding: 20px 0px;
  gap: 10px;

  .serviceEstContainer-data {
    padding-top: 3px;
    flex: 1 1 50%;
    width: 100%;
    max-height: 242px;
    background: #f8f8fa 0% 0% no-repeat padding-box;
    border-radius: 10px;
    width: 370px;
    height: 100%;
    opacity: 1;
    overflow-y: auto;
  }

  .estimation-label-header {
    // width: 213px;
    width: 100%;
    height: 22px;
    text-align: left;
    font: normal normal 600 16px/39px Open Sans;
    letter-spacing: 0px;
    color: #323338;
    opacity: 1;
  }
  .servicePercent-data {
    display: flex;
    flex-direction: column;
    flex: 1;
    // flex-wrap: wrap;
    margin-top: 20px;
    padding-left: 8px;
    overflow: auto;
  }
  .servicePercent-container {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 0.625rem;
  }
  .servicePercent-label {
    font: normal normal normal 16px/22px Open Sans;
    letter-spacing: 0px;
    color: #323338;
    opacity: 1;
    width: 70%;

    .newTitle {
      font-size: 13px;
      font-weight: 600;
    }
  }
}

.est-container-data-dark {
  .serviceEstContainer-data {
    background-color: $cardModalBodyDark;
    .estimation-label-header span {
      color: #fff;
    }
    .servicePercent-data {
      .servicePercent-label {
        color: #fff;
      }
    }
  }
}