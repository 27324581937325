.comment-content-component {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  background: #f8f8fa;

  .comment-content-replies {
    position: relative; // for vertical line & for horizontal line
    width: 100%;
    padding-left: 60px;

    .comment-component {
      // NOTE: Please do not change style of this classname
      .horizontal-line-L {
        position: absolute;
        top: 30px;
        left: -23px;
        width: 35px;
        border-left: 2px solid #dbe1e7;
        border-bottom: 2px solid #dbe1e7;
        z-index: 9999999;
        &:after {
          content: "";
          position: absolute;
          top: 50%;
          right: -1px;
          width: 0;
          height: 0;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
          border-left: 5px solid #959595;
          transform: translateY(-45%);
        }
      }
    }

    // NOTE: Please do not change style of this classname
    .vertical-line-L {
      left: 37px;
      position: absolute;
      height: 100%;
      border-left: 2px solid #dbe1e7;
      z-index: 9999999;
      top: 32px;
    }
  }

  .reply__container {
    width: 100%;
    max-width: 500px;
  }
}

.CommentContentDark {
  background-color: #12131b;
}
