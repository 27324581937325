@import "../../../../Communication.scss";

.custom-dropdown-menu-items-dark-mode {
    .ant-dropdown-menu {
        background-color: $cardDarkHeaderColor !important;

        .ant-dropdown-menu-item {
            background-color: $cardDarkHeaderColor !important;

            span {
                color: $white;

                &:hover {
                    color: #1264a3;
                }
            }
        }
    }
}


.custom-dropdown-container {
    .ant-dropdown-menu {
        max-height: 300px;
        max-width: 200px;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .dropdown-item-button {
        span {
            color: $light-text-color;
            max-width: 150px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: start;
        }

        &:hover {
            span {
                color: inherit;
            }
        }
    }

    &.dark-mode {

        .ant-dropdown-menu,
        .ant-dropdown-menu-submenu,
        .ant-dropdown-menu-submenu-title,
        .ant-dropdown-menu-submenu-arrow-icon,
        .ant-dropdown-menu-item {
            background-color: $cardDarkHeaderColor !important;

            span {
                color: $white;

                &:hover {
                    color: #1264a3;
                }
            }

            .ant-dropdown-menu-submenu-title:hover,
            .ant-dropdown-menu-item:hover {
                background-color: $mainDarkBackgroundColor !important;
            }

            .ant-dropdown-menu-title-content {
                color: #fff;
            }
        }

        .anticon.anticon-right.ant-dropdown-menu-submenu-arrow-icon {
            color: #fff !important;
        }
    }
}