$toggle-color-primary-on: #00a464;
$toggle-color-primary-off: #fe4c4a;

$toggle-color-secondary-on: green;
$toggle-color-secondary-off: red;

$toggle-background-filled-off: #f5f5f7;
$toggle-background-filled-on: #71cf48;
$toggle-color-filled: #323338;

$toggle-background-on: #01c06a;
$toggle-background-off: #fe4c4a;

$toggle-color-white: #ffffff;

.toggle-switch-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  min-width: 150px;
  height: 32px;
  border-radius: 20px;
  overflow: hidden;
  padding: 4px;
  cursor: pointer;
  transition: border-color 0.3s ease;
  background: transparent;

  &.filled {
    background: $toggle-background-filled-off;

    .toggle-ball {
      background: $toggle-color-filled;
    }
  }

  &.light {
    background: $toggle-color-white;

    .toggle-ball {
      background: $toggle-color-filled;
      .toggle-ball-icon {
        svg path {
          fill: $toggle-color-white;
        }
      }
    }
  }

  &.primary {
    .toggle-ball {
      background: $toggle-color-primary-off;
    }
  }

  &.secondary {
    background-color: $toggle-background-off;
    .toggle-text {
      color: $toggle-color-white;
    }
    .toggle-ball {
      background: $toggle-color-secondary-off;
    }
  }
}

.toggle-switch-container.active {
  &.filled {
    background: $toggle-background-filled-on;

    .toggle-ball {
      background: $toggle-color-white;

      .toggle-ball-icon {
        svg path {
          fill: $toggle-background-filled-on;
        }
      }
    }

    .toggle-text {
      color: $toggle-color-white;
    }
  }

  &.primary {
    .toggle-ball {
      background: $toggle-color-primary-on;
    }
  }

  &.secondary {
    background-color: $toggle-background-on;
    .toggle-ball {
      background: $toggle-color-secondary-on;
    }
  }
}

.toggle-switch-container.disabled {
  cursor: not-allowed;
}

.toggle-text {
  font-size: 12px;
  font-weight: 400;
  transition: padding 0.3s ease;
  padding-left: 30px;
  padding-right: 5px;
  user-select: none;
  padding-top: 1px;
  white-space: nowrap;
}

.toggle-text.active {
  padding-left: 5px;
  padding-right: 30px;
}

.toggle-ball {
  position: absolute;
  left: 4px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  transition: left 0.3s ease;
}

.toggle-ball.active {
  left: calc(100% - 28px);
}

.toggle-ball .toggle-ball-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;

  > svg {
    width: 20px;
    height: 20px;
  }
}
