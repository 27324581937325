@import "../../index.scss";

$mainDarkBackgroundColor: #20212d;
$cardDarkHeaderColor: #2a2b3a;
$cardDarkBackgroundColor: #12131b;
$inputDarkColor: #2a2b3a;

.tabContainer {
  background-color: var(--primaryColor);
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .titleComp {
    font: normal normal bold 18px Open Sans;
    text-align: left;
    letter-spacing: 0px;
    color: #323338;
    opacity: 1;
    top: 0px;
    // left: var(--leftPosition);
    padding: 22px 30px 20px 20px;
    max-width: 700px;
    // text-overflow: ellipsis;
    // overflow: hidden;
    // white-space: nowrap;
    z-index: 3;
    transition: 0.2s ease;

    .title {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .breadcrumpContainer {
    min-height: 25px;
    padding-left: 20px;

    .ant-breadcrumb {
      // padding-top: 10px;

      .ant-breadcrumb-link {
        a {
          font-family: "Open Sans";
          font-size: 14px;
          font-weight: 500;

          &:hover {
            color: #1264a3;
            font-size: 15px;
          }
        }
      }
    }
  }

  .titleCompBasePage {
    display: none;
  }

  .ant-tabs-content-holder {
    flex: auto;
    min-width: 0;
    min-height: 0;
  }

  .tabComponent {
    background-color: var(--primaryColor);
    height: 100%;

    .ant-badge-count,
    .ant-badge-dot,
    .ant-badge .ant-scroll-number-custom-component {
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(84%, -60%);
      transform-origin: 100% 0%;
    }

    .ant-tabs-content {
      height: 100%;
    }

    .tabBody {
      background: #f4f5f6;
      width: 100%;
      height: 100%;
      max-height: calc(100dvh - 155px);
    }

    .tabContainer {
      min-width: calc(100% - 60px);
      max-width: calc(100% - 60px);
      width: 100%;
      background: #f4f5f6;
      display: flex;

      ::-webkit-scrollbar {
        width: 0px;
        height: 5px;
      }

      .absoluteStepperContainer {
        width: 100%;
        margin-right: 0px !important;
        margin-left: 0px !important;
        height: 108px;
        background: #ffffff 0% 0% no-repeat padding-box;
        border-radius: 10px;
        opacity: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        pointer-events: unset !important;
        overflow-x: auto;
        &::-webkit-scrollbar {
          width: 11px;
          height: 11px;
        }

        .antCustomStepper .ant-steps-navigation {
          .ant-steps-item-finish::before,
          .ant-steps-item-active::before,
          .ant-steps-item::before {
            top: -19%;
          }
        }

        .StepperCard {
          margin-bottom: 0;
          width: calc(100% - 10px);
          pointer-events: none;
        }

        .absuluteDividerStyle {
          min-height: 7px;
          width: calc(100% - 10px);
          background: transparent
            linear-gradient(90deg, #ffffff 0%, #f1f3f4 51%, #ffffff 100%) 0% 0%
            no-repeat padding-box;
          opacity: 1;
        }

        box-shadow: 0px 1px 4px #00000029;
      }
    }

    .ant-tabs-nav {
      border-bottom: 1px solid #c3c6c9;
      background-color: white;
      width: 100%;
      z-index: 2;
      margin: 0 0 0 0;

      .ant-tabs-nav-list {
        // @if parseInt(var(--tabElementsLength)) {
        @for $i from 1 through 2 {
          :nth-child(#{$i}) {
            animation: tabs calc(1s + $i * 0.3s - 0.3s);
          }
        }
      }
      .ant-tabs-nav-list .ant-tabs-tab:first-child {
        margin-left: 20px;
      }

      @media only screen and (min-width: 768px) and (max-width: 1023px) {
        // background-color: #76b3e2;
        width: 100%;
      }
    }

    .ant-tabs-tabpane {
      background: #f4f5f6;
      width: 100%;
      height: 100%;
    }

    .ant-tabs-nav .ant-tabs-tab {
      position: relative;
      display: inline-block;
      box-sizing: border-box;
      height: 100%;
      font-size: 18px;
      margin: 0 10px 0 40px;
      padding: 11px 0px;
      text-decoration: none;
      cursor: pointer;
      transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    .ant-tabs-ink-bar {
      position: absolute;
      border-radius: 100px;
      height: 3px;
      background: #1264a3;
      pointer-events: none;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #323338;
      font: normal normal 14 Open Sans;
      letter-spacing: 0px;
      color: #1264a3;
      opacity: 1;
    }

    .ant-tabs-nav-wrap {
      margin-bottom: -2px;
    }

    .ant-tabs-tab-btn {
      color: #323338;
      font: normal normal bold 14px;
      font-weight: 600;
      letter-spacing: 0px;
    }

    .ant-tabs-nav-operations {
      box-shadow: -10px 0px 10px rgba(0, 0, 0, 0.1);

      svg {
        font-size: 25px;
      }
    }
  }

  .tabComponent3 {
    @extend .tabComponent;

    .ant-tabs-nav {
      .ant-tabs-nav-list {
        @for $i from 1 through 3 {
          :nth-child(#{$i}) {
            animation: tabs calc(1s + $i * 0.3s - 0.3s);
          }
        }
      }
    }
  }

  .tabComponent4 {
    @extend .tabComponent;

    .ant-tabs-nav {
      .ant-tabs-nav-list {
        @for $i from 1 through 4 {
          :nth-child(#{$i}) {
            animation: tabs calc(1s + $i * 0.3s - 0.3s);
          }
        }
      }
    }
  }

  .tabComponent5 {
    @extend .tabComponent;

    .ant-tabs-nav {
      .ant-tabs-nav-list {
        @for $i from 1 through 5 {
          :nth-child(#{$i}) {
            animation: tabs calc(1s + $i * 0.3s - 0.3s);
          }
        }
      }
    }
  }

  .tabComponent6 {
    @extend .tabComponent;

    .ant-tabs-nav {
      .ant-tabs-nav-list {
        @for $i from 1 through 6 {
          :nth-child(#{$i}) {
            animation: tabs calc(1s + $i * 0.3s - 0.3s);
          }
        }
      }
    }
  }

  .tabComponent7 {
    @extend .tabComponent;

    .ant-tabs-nav {
      .ant-tabs-nav-list {
        @for $i from 1 through 7 {
          :nth-child(#{$i}) {
            animation: tabs calc(1s + $i * 0.3s - 0.3s);
          }
        }
      }
    }
  }

  .tabComponent8 {
    @extend .tabComponent;

    .ant-tabs-nav {
      .ant-tabs-nav-list {
        @for $i from 1 through 8 {
          :nth-child(#{$i}) {
            animation: tabs calc(1s + $i * 0.3s - 0.3s);
          }
        }
      }
    }
  }

  .tabComponent9 {
    @extend .tabComponent;

    .ant-tabs-nav {
      .ant-tabs-nav-list {
        @for $i from 1 through 9 {
          :nth-child(#{$i}) {
            animation: tabs calc(1s + $i * 0.3s - 0.3s);
          }
        }
      }
    }
  }

  .tabComponent10 {
    @extend .tabComponent;

    .ant-tabs-nav {
      .ant-tabs-nav-list {
        @for $i from 1 through 10 {
          :nth-child(#{$i}) {
            animation: tabs calc(1s + $i * 0.3s - 0.3s);
          }
        }
      }
    }
  }

  .tabComponent11 {
    @extend .tabComponent;

    .ant-tabs-nav {
      .ant-tabs-nav-list {
        @for $i from 1 through 11 {
          :nth-child(#{$i}) {
            animation: tabs calc(1s + $i * 0.3s - 0.3s);
          }
        }
      }
    }
  }

  .tabComponent12 {
    @extend .tabComponent;

    .ant-tabs-nav {
      .ant-tabs-nav-list {
        @for $i from 1 through 12 {
          :nth-child(#{$i}) {
            animation: tabs calc(1s + $i * 0.3s - 0.3s);
          }
        }
      }
    }
  }

  .tabComponent13 {
    @extend .tabComponent;

    .ant-tabs-nav {
      .ant-tabs-nav-list {
        @for $i from 1 through 13 {
          :nth-child(#{$i}) {
            animation: tabs calc(1s + $i * 0.3s - 0.3s);
          }
        }
      }
    }
  }

  .tabComponent14 {
    @extend .tabComponent;

    .ant-tabs-nav {
      .ant-tabs-nav-list {
        @for $i from 1 through 14 {
          :nth-child(#{$i}) {
            animation: tabs calc(1s + $i * 0.3s - 0.3s);
          }
        }
      }
    }
  }

  .tabComponent15 {
    @extend .tabComponent;

    .ant-tabs-nav {
      .ant-tabs-nav-list {
        @for $i from 1 through 15 {
          :nth-child(#{$i}) {
            animation: tabs calc(1s + $i * 0.3s - 0.3s);
          }
        }
      }
    }
  }

  .tabComponent16 {
    @extend .tabComponent;

    .ant-tabs-nav {
      .ant-tabs-nav-list {
        @for $i from 1 through 16 {
          :nth-child(#{$i}) {
            animation: tabs calc(1s + $i * 0.3s - 0.3s);
          }
        }
      }
    }
  }

  .tabComponent17 {
    @extend .tabComponent;

    .ant-tabs-nav {
      .ant-tabs-nav-list {
        @for $i from 1 through 17 {
          :nth-child(#{$i}) {
            animation: tabs calc(1s + $i * 0.3s - 0.3s);
          }
        }
      }
    }
  }

  .tabComponent18 {
    @extend .tabComponent;

    .ant-tabs-nav {
      .ant-tabs-nav-list {
        @for $i from 1 through 18 {
          :nth-child(#{$i}) {
            animation: tabs calc(1s + $i * 0.3s - 0.3s);
          }
        }
      }
    }
  }

  .tabComponent19 {
    @extend .tabComponent;

    .ant-tabs-nav {
      .ant-tabs-nav-list {
        @for $i from 1 through 19 {
          :nth-child(#{$i}) {
            animation: tabs calc(1s + $i * 0.3s - 0.3s);
          }
        }
      }
    }
  }

  .backTop {
    height: 40px;
    width: 40px;
    line-height: 40px;
    border-radius: 4px;
    position: absolute;
    color: #ffffff;
    background-color: #1f2a44;
    text-align: center;
    font-size: 14px;
    z-index: 5;
    cursor: pointer;
    bottom: 70px;
    right: 20px;

    .backTopIcon {
      fill: #ffffff;
    }
  }
}

.tabContainerDark {
  background-color: $mainDarkBackgroundColor;

  .titleComp {
    color: #f2f2f2;

    svg path {
      fill: #f2f2f2;
    }
  }

  .breadcrumpContainer {
    li {
      color: #acacca;

      .active {
        color: #f2f2f2;
      }

      a {
        color: #acacca;
      }
    }
  }

  .tabComponent {
    .ant-tabs-nav {
      background-color: $mainDarkBackgroundColor;
      border-bottom-color: $cardDarkBackgroundColor;

      &::before {
        border-bottom-color: #1b1c29;
      }

      .ant-tabs-nav-wrap {
        .ant-tabs-tab-btn {
          color: #f2f2f2;
        }
      }

      .ant-tabs-nav-more svg {
        fill: #fff;
      }
    }

    .ant-tabs-ink-bar {
      background-color: #f2f2f2;
    }

    .ant-tabs-content-holder .ant-tabs-content {
      .tabBody {
        background-color: $mainDarkBackgroundColor;

        .OverviewPage {
          background-color: $mainDarkBackgroundColor;

          .tabContainer {
            background-color: $mainDarkBackgroundColor;

            .absoluteStepperContainer {
              background-color: $cardDarkBackgroundColor;
              border-radius: 10px;

              .ant-steps {
                .ant-steps-item-finish ::before,
                .ant-steps-item::before {
                  border-left-color: $cardDarkBackgroundColor;
                }

                .ant-steps-item-wait {
                  background-color: $inputDarkColor;

                  &::after {
                    border-left-color: $inputDarkColor;
                  }

                  .ant-steps-item-content .ant-steps-item-title {
                    color: #acacca;
                  }
                }
              }
            }
          }
        }
      }
    }

    .ant-tabs-tabpane {
      background-color: $mainDarkBackgroundColor;
    }
  }
}

@media (max-width: 1728px) {
  .tabContainer .titleComp {
    top: 25px;
  }
}

@media (max-width: 768px) {
  .tabContainer .tabComponent .tabContainer {
    min-width: calc(100vw - 70px);
    max-width: calc(100vw - 70px);
  }

  .tabContainer .backTop {
    bottom: 145px;
  }
}

@media (max-width: 575px) {
  .tabContainer {
    .OverviewPage .leftPart {
      min-width: 100%;
    }

    .tabComponent {
      .tabContainer {
        min-width: 0px;

        .absoluteStepperContainer {
          height: 250px;
          justify-content: flex-start;

          .StepperCard {
            height: 36px;
            gap: 10px;
          }

          .ant-steps-navigation.ant-steps-vertical
            > .ant-steps-item-active:after,
          .ant-steps-navigation.ant-steps-vertical
            > .ant-steps-item-finish:after {
            left: 100%;
            top: -60px;
            transform: rotate(0deg);
          }

          .ant-steps-navigation.ant-steps-vertical
            > .ant-steps-item:last-child::after {
            display: none;
          }

          .ant-steps-navigation.ant-steps-vertical > .ant-steps-item:before {
            display: none;
          }
        }
      }
    }
  }
}

@media (max-width: 459px) {
  .tabContainer {
    .titleComp {
      width: 100%;
      padding-top: 0;
      margin-top: 30px;

      .title {
        max-width: 85%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }

  .tabContainer .tabComponent .tabContainer {
    min-width: calc(100vw - 70px);
    max-width: calc(100vw - 70px);

    .absoluteStepperContainer
      .ant-steps-navigation.ant-steps-vertical
      > .ant-steps-item-active:after,
    .ant-steps-navigation.ant-steps-vertical > .ant-steps-item-finish:after {
      top: -60px;
    }
  }
}

@media only screen and (max-width: 450px) {
  .tabContainer .tabComponent .tabContainer {
    margin-left: 0px;
    height: 250px;

    .absoluteStepperContainer {
      height: 100%;

      .ant-steps-navigation.ant-steps-vertical > .ant-steps-item-active:after,
      .ant-steps-navigation.ant-steps-vertical > .ant-steps-item-finish:after {
        top: -34.8px;
      }
    }
  }
}

@media only screen and (max-width: 414px) {
  .tabComponent {
    .ant-tabs-nav {
      width: calc(100% - 60px) !important;
    }
  }

  .tabContainer .tabComponent .tabBody {
    height: calc(100vh - 165px);
  }

  .tabContainer .backTop {
    bottom: 125px;
  }
}

@keyframes tabs {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
