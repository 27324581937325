.audit-actual-plan-card {
  .arrow-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 10px;
    cursor: pointer;
  }

  .arrow-container-text {
    white-space: nowrap;
    font-size: 0.9rem;
    cursor: default;
  }

  .actual-activity-title {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }

  .location-option-panel {
    width: 100%;
    height: 19px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    cursor: default;
    margin-top: -10px;
  }

  .location-option-selected {
    color: #1264a3;
    font-weight: 600;
    cursor: pointer;
  }

  .locations-card-info-container {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0px 10px 10px 10px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
    cursor: default;

    .single-card-location.location-stop.stop {
      background: #fe4c4a50 !important;
    }

    .single-card-location.location-stop.idle {
      background-color: #f6cb5244 !important;
    }

    .single-card-location {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      flex-wrap: nowrap;
      gap: 10px;
      padding: 12px 8px;
      background-color: #fff;
      border-radius: 5px;
      width: 100%;

      @media screen and (max-width: 1440px) {
        flex-direction: column;
        align-items: start;
      }

      .single-card-location-info {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        flex-direction: row;
        gap: 10px;
        width: 80%;

        .location-dot {
          height: 10px;
          aspect-ratio: 1;
          display: block;
          box-sizing: border-box;
          background-color: transparent;
          border: 3px solid;
          border-radius: 50%;
          transform: translateY(-5px);
        }

        .location-dot.enter-dot {
          border-color: #ff4d4f;
        }

        .location-dot.exit-dot {
          border-color: #52c41a;
        }

        .single-location-info {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          flex-direction: column;
          width: 100%;

          .location-arrive-time {
            font-size: 0.75rem;
            opacity: 0.85;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            white-space: nowrap;
            column-gap: 10px;

            @media (max-width: 1440px) {
              flex-direction: column;
              align-items: start;
            }
          }

          .location-arrive {
            font-size: 14px;
            font-weight: 400;
          }
        }
      }

      .location-info-control-buttons {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 100%;
        gap: 10px;
        flex-direction: row;
        flex-wrap: nowrap;
        width: 100%;

        .location-handler {
          height: 20px;
          width: 20px;
          background-color: #f64c4a;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 5px;
          cursor: pointer;
        }

        .stop-link-handler {
          background-color: #1264a3;
        }

        .location-discard-handle {
          background-color: #f64c4a;
        }

        .location-drag-handle {
          cursor: grab;
          transform: translateY(2px);
        }
      }
    }

    .single-card-location.manual-created-card {
      background-color: #dafacd !important;
    }

    .single-card-location.manual-created-card-dark {
      background-color: #2d531e !important;
    }

    .single-card-location.update-enter {
      background-color: rgba(137, 148, 196, 0.3);
    }

    .single-card-location.activity-disregard {
      animation: actual-slide-right 0.3s ease-in-out 0.2s forwards;
    }
  }
}

.audit-actual-plan-card.actual-plan-dark {
  .location-option-panel {
    color: #acacac;
  }

  .location-option-selected {
    color: #1264a3;
  }

  .locations-card-info-container {
    .single-card-location {
      background-color: #2a2b3a;
      color: #f2f2f2;
    }

    .single-card-location.update-enter {
      background-color: rgba(137, 148, 196, 0.3);
    }

    .single-card-location.activity-disregard {
      animation: actual-slide-right 0.3s ease-in-out 0.2s forwards;
    }
  }
}

@keyframes actual-slide-right {
  from {
    transform: translateX(0);
    opacity: 1;
  }

  to {
    transform: translateX(70%);
    opacity: 0;
    display: none;
  }
}
