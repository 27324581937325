.timeDatePicker {
  width: 301px;
  height: 480px;
  text-align: center;
  background: #ffffff;
  box-shadow: 4px 1px 4px rgba(0, 0, 0, 0.16);
}

.clock-container-mobile{
  display: grid !important;
  place-items: center;
}

.clock-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 0;
}

.selectTimeText p {
  margin: 0;
  padding-top: 20px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #323338;
}

.timeButtons {
  display: flex;
  flex-direction: row;
  margin: 0px 26px 0px 20px;
}

input {
  border: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.timeButtons .hour {
  width: 95px;
  height: 58px;
  padding: 2px 25px;
  font-weight: 600;
  font-size: 40px;
  line-height: 54px;
  color: #323338;
  background: #f5f5f7;
  border-radius: 2px;
  cursor: text;
}

.timeButtons .minute {
  width: 95px;
  height: 58px;
  padding: 2px 25px;
  font-weight: 600;
  font-size: 36px;
  line-height: 54px;
  color: #323338;
  background: #f5f5f7;
  border-radius: 2px;
  cursor: text;
}

.timeButtons .timeButtonsActive {
  background: #eff4ff;
}

.timeButtons .timeButtonsDeActive {
  background: #f5f5f7;
}

.timeButtons .doubleDots {
  margin: 0 4px;
  font-weight: 600;
  font-size: 40px;
  line-height: 54px;
  color: #323338;
}

.timeButtons .amORpm {
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-left: 10px;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #323338;
  cursor: pointer;
}

.timeButtons .amORpm .am {
  padding: 1.5px 6px;
  border-radius: 2px;
}

.timeButtons .amORpm .pm {
  padding: 1.5px 6px;
  border-radius: 2px;
}

.clock {
  width: 222px;
  height: 222px;
  background-color: #f5f5f7;
  border-radius: 50%;
  position: relative;
}

.centerOfClock {
  position: absolute;
  top: calc(50% - 7px);
  left: calc(50% - 8.5px);
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: #1264a3;
}

.clock .number {
  --rotation: 0;
  position: absolute;
  left: 95px;
  width: 13%;
  height: 100%;
  text-align: center;
  padding-top: 10px;
  font-weight: 500;
  transform: rotate(var(--rotation));
}

.clock .number .valueOfNumber {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: transparent;
  cursor: pointer;
}

.clock .selectedNumber::after {
  content: "";
  position: absolute;
  top: 38px;
  bottom: 3px;
  left: 13px;
  width: 3px;
  background-color: #1264a3;
}

.clock .number1 {
  height: 50%;
  --rotation: 30deg;
  transform-origin: bottom;
}

.clock .number2 {
  --rotation: 60deg;
  height: 50%;
  transform-origin: bottom;
}

.clock .number3 {
  --rotation: 90deg;
  height: 50%;
  transform-origin: bottom;
}

.clock .number4 {
  --rotation: 120deg;
  height: 50%;
  transform-origin: bottom;
}

.clock .number5 {
  --rotation: 150deg;
  height: 50%;
  transform-origin: bottom;
}

.clock .number6 {
  --rotation: 180deg;
  height: 50%;
  transform-origin: bottom;
}

.clock .number7 {
  --rotation: 210deg;
  height: 50%;
  transform-origin: bottom;
}
.clock .number8 {
  --rotation: 240deg;
  height: 50%;
  transform-origin: bottom;
}
.clock .number9 {
  --rotation: 270deg;
  height: 50%;
  transform-origin: bottom;
}
.clock .number10 {
  --rotation: 300deg;
  height: 50%;
  transform-origin: bottom;
}
.clock .number11 {
  --rotation: 330deg;
  height: 50%;
  transform-origin: bottom;
}

.clock .number12 {
  --rotation: 0deg;
  height: 50%;
  transform-origin: bottom;
}

.clock .number1 .valueOfNumber {
  transform: rotate(-30deg);
}

.clock .number2 .valueOfNumber {
  transform: rotate(-60deg);
}

.clock .number3 .valueOfNumber {
  transform: rotate(-90deg);
}

.clock .number4 .valueOfNumber {
  transform: rotate(-120deg);
}

.clock .number5 .valueOfNumber {
  transform: rotate(-150deg);
}

.clock .number6 .valueOfNumber {
  transform: rotate(-180deg);
}

.clock .number7 .valueOfNumber {
  transform: rotate(-210deg);
}

.clock .number8 .valueOfNumber {
  transform: rotate(-240deg);
}

.clock .number9 .valueOfNumber {
  transform: rotate(-270deg);
}

.clock .number10 .valueOfNumber {
  transform: rotate(-300deg);
}

.clock .number11 .valueOfNumber {
  transform: rotate(-330deg);
}

.clock .number12 .valueOfNumber {
  transform: rotate(-360deg);
}

.clock-container .buttons {
  width: calc(100% - 40px);
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
}

.clock-container .buttons button {
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transform-origin: 50% 0%;
}

.clock-container .okCancel-btn {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 32px;
}

.clock-container .buttons .cancel-btn {
  padding: 7px 10px;
  background: #fe4c4a;
}

.clock-container .buttons .ok-btn {
  padding: 7px 20px;
  background: #1264a3;
}

.clock-container .buttons button:hover {
  filter: brightness(120%);
  scale: 1.02;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.showClockResult {
  position: absolute;
  top: 490px;
  max-width: 300px;
}

.showClockResult h4 {
  color: gray;
}

.showClockResult h4 span {
  color: black;
  font-weight: 700;
}
