@use "/src/index.scss" as index;

.map-status-cards {
  height: 109px;
  width: calc(100% - 20px);
  display: flex;
  align-items: end;
  justify-content: center;
  z-index: 0;
  gap: 20px;
  .status-card {
    height: 52px;
    width: calc(calc(100% / 3) - 10px);
    max-width: 177px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    font-size: 14px;
    color: #ffffff;
    z-index: 2;
    gap: 5px;
    cursor: pointer;
    user-select: none;
    transition: 0.2s ease-out;
    box-shadow: 0px 1px 4px 0px #231f204d;
    svg {
      height: 30px;
      width: 30px;
      fill: #fff !important;
    }
  }
  .In {
    // background-color: #00a465a9;
    // background-color: #bebebe9b;
    background-color: #787677a9;
    svg g path:nth-child(2) {
      fill: #00a464a9 !important;
    }
    &:hover {
      background-color: #00a464;
    }
    &.active {
      background-color: #00a464;
    }
  }
  .Out {
    background-color: #787677a9;
    svg g path:nth-child(2) {
      fill: #cdcdcd !important;
    }
    &:hover {
      background-color: #dfdfdf;
      color: #323338;
    }
    &.active {
      background-color: #dfdfdf;
      color: #323338;
    }
  }
  .NoPunch {
    // background-color: #f04f4ea9;
    background-color: #787677a9;
    svg g path:nth-child(2) {
      fill: #f04f4ea9 !important;
    }
    &:hover {
      background-color: #f04f4e;
    }
    &.active {
      background-color: #f04f4e;
    }
  }
  // .no-match {
  .DontMatch {
    // background-color: #e9c466a9;
    background-color: #787677a9;
    svg g path:nth-child(2) {
      fill: #e9c466a9 !important;
    }
    &:hover {
      background-color: #e9c466;
    }
    &.active {
      background-color: #e9c466;
    }
  }
  .Unscheduled {
    // background-color: #e9c466a9;
    background-color: #787677a9;
    svg g path:nth-child(2) {
      fill: #7FB5B5a9 !important;
    }
    &:hover {
      background-color: #7FB5B5;
    }
    &.active {
      background-color: #7FB5B5;
    }
  }
  .total-employees-count {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 16px;
    padding: 5px 10px;
    border-radius: 5px;
    background-color: #f5f5f5c1;
    cursor: default;
    span {
      font-weight: 600;
    }
  }
}

@media (max-width: 1450px) {
  .map-status-cards {
    height: 109px;
  }
}

@media (max-width: 1024px) {
  .map-status-cards {
    gap: 10px;
    .status-card {
      font-size: 13px;
    }
  }
}
