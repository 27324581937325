@import "../../../../../../../../index.scss";

.documentationViewCard {
  @include flex(center, space-between);
  @include text(16px, 600, 0, #323338);

  height: 80px;
  width: stretch;
  background-color: #f7f8fa;
  border-radius: 10px 10px 0px 0px;
  padding: 0 10px;
  position: relative;
  cursor: pointer;
  animation: 0.8s ease-in-out 0s 1 loadAnimation;
  transition: 0.3s ease;

  &:hover {
    background-color: #e7e9ec;
  }

  .leftSection {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    font-size: 14px;
    text-align: left;
    gap: 10px;
    .docStatusLabel {
      height: 25px;
      display: flex;
      gap: 5px;
      width: auto;
      padding: 2px 10px;
      border-radius: 5px;
      font-weight: 400;
    }
  }

  .docCreationTime {
    @include text(12px, 400, 0, rgba(0, 0, 0, 0.45));
  }

  .renewalStamp {
    position: absolute;
    color: #fff;
    background-color: #1264a3;
    border-radius: 5px;
    padding: 0 5px;
    height: 25px;
    // width: 25px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    // text-align: right;
    top: -15px;
    right: 0px;
    font-size: 12px;
    line-height: 100%;
    @include flex(center, flex-end);
    &:hover {
      overflow: visible;
      white-space: normal;
      text-overflow: unset;
      transform: scale(1.03);
    }
  }

  // .docStatusLabel {
  //   @include text(14px, 400, 0, #fff);
  //   text-align: center;
  //   padding: 6px 8px;
  //   border-radius: 5px;
  //   // width: 72px;
  //   margin: 0 15px;
  //   white-space: nowrap;
  //   overflow: hidden;
  //   text-overflow: ellipsis;
  // }

  .uploadedFilesLabel {
    @include flex(center, center);
    @include text(14px, 400, 0, #fff);
    background-color: #71cf48;
    border-radius: 5px;
    text-align: center;
    padding: 6px 8px;
    width: 132px;
  }

  .warningIcon {
    color: $red;
    font-size: 18px;
  }

  .noFiles {
    background-color: $red;
  }

  .warningText {
    color: $red;
  }

  .docTitle {
    max-width: 300px;
    .docType {
      @include textEllipsis();
    }
  }
  &.documentationViewCardDark:hover {
    background-color: $mainDarkBackgroundColor !important;
  }

  &.documentationViewCardDark {
    @include text(16px, 600, 0, #fff);
    background-color: $cardDarkHeaderColor !important;
  }
}

.children-drop-down {
  @include flex(center, flex-start, column);
  background-color: #f7f8fa;
  border-radius: 0px 0px 10px 10px;
  gap: 5px;
  .children-list-container {
    width: 100%;
    @include flex(center, flex-start, column);
    gap: 5px;
    margin-right: 3px;
    overflow-y: auto;
    animation: dropdownOpen 0.5s both;
  }
  .child-card-container {
    @include flex(center, space-between);
    width: calc(100% - 20px);
    padding: 10px;
    transition: 0.3s ease;
    border-radius: 5px;
    background-color: #fff;
    &:hover {
      background-color: #e7e9ec;
      cursor: pointer;
    }
    .docStatusLabel {
      padding: 2px 10px;
      border-radius: 5px;
    }
    .child-doc-type {
      display: flex;
      gap: 10px;
      font-size: 12px;
      font-weight: 600;

      .docCreationTime {
        font-size: 12px;
        color: $textColor;
      }
    }
    .renewal {
      color: #6bbed1;
    }
    .revised {
      color: #6bd1bd;
    }
    .files-info svg {
      margin-left: 5px;
    }
  }
  .drop-down-action {
    width: 100%;
    height: 35px;
    @include flex(center, center);
    border-radius: 0px 0px 10px 10px;
    text-align: center;
    transition: 0.3s ease;
    gap: 10px;
    animation: 0.8s ease-in-out 0s 1 loadAnimation;
    padding-bottom: 10px;
    &:hover {
      cursor: pointer;
    }
    & > div {
      margin-left: -70px;
    }
    svg {
      transition: 0.3s ease;
    }
  }

  .warningIcon {
    color: $red;
    font-size: 18px;
  }

  .warningText {
    color: $red;
  }

  &.children-drop-down-dark {
    background-color: $cardDarkHeaderColor;
    .child-card-container {
      background-color: $mainDarkBackgroundColor;
      color: #fff;
      .docType {
        color: #fff;
      }
      // .child-doc-type .docCreationTime {
      // }
      &:hover {
        background-color: $cardDarkBackgroundColor;
      }
    }
    .drop-down-action {
      color: #fff;

      svg {
        fill: #fff;
      }
    }
  }
}

@keyframes loadAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes dropdownOpen {
  0% {
    height: 0px;
    overflow: hidden;
  }
  100% {
    height: 200px;
  }
}
