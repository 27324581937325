@use "/src/index.scss" as index;

.late-reports-card.card-component {
  height: 100%;
  width: 100%;
  .custom-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    .mondayButtonContainer .ant-btn {
      padding: 0px 5px !important;
    }
  }
  .card-body {
    height: calc(100% - 62px);
    width: 100%;
    padding: 10px 0px;
    .cards-wrapper {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px !important;
      overflow-y: auto;
      padding: 0px 10px;
    }
    .report-card {
      width: 100%;
      padding: 10px;
      border-radius: 5px;
      background-color: #f4f5f6;
      animation: cardFadeIn 0.8s ease-out;
      box-shadow: 0px 1px 2px 0px #211f2027;
      .report-header {
        font-weight: 600;
        label {
          cursor: pointer;
          user-select: none;
          &:hover {
            color: index.$blue;
          }
        }
      }
      .report-body {
        display: flex;
        justify-content: space-between;
      }
    }
    .no-data {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      color: #acacac;
    }
  }
  .reports-count {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: #acacac;
    padding: 0px 20px;
  }
}

.late-reports-card.card-component-dark {
  .card-body {
    .report-card {
      background-color: index.$mainDarkBackgroundColor;
      .report-header {
        label {
          color: #fff;
        }
      }
      .report-body {
        color: #fff;
      }
    }
    .no-data {
      color: #acacca;
    }
    .reports-count {
      color: #acacca;
    }
  }
}

@keyframes cardFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
