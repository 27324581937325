.field-user-profile {
  width: 100%;
  max-height: calc(100dvh - 60px);
  .user-profile-container {
    .user-profile-content {
      padding: 0 0 20px 0;
    }
    .user-profile-footer {
      border-radius: 5px;
    }
  }
}
