@import "../../../../../../index.scss";

.reportConfigurationDesignerContainer {
  height: 100%;
  width: 100%;
  position: relative;

  .backButton {
    @include circle(32px, #1264a3);
    @include flex(center, center);
    position: absolute;
    bottom: 70px;
    left: 70px;
    cursor: pointer;

    &:hover {
      background-color: #0f5c97;
    }
  }
  .previewButton {
    position: absolute;
    @include circle(32px, #1264a3);
    background-color: #1264a3;
    // border-radius: 5px;
    color: white;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    bottom: 110px;
    left: 70px;
    &:hover {
      background-color: #0f5c97;
    }
  }
}
