.iconHolder {
  background-color: #1264a3;
  width: 32px;
  height: 32px;
  border: none;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  // font-size: 12px;
}

.desired-rent-modal__dark {
  .ant-modal-content {
    background-color: #3a3c4e;
    color: #fff; //modal body
    .ant-modal-body {
      background-color: #3a3c4e;
      color: #fff;
    }
    .ant-modal-header {
      background-color: #1264a3;
      color: #fff;
      .ant-modal-title {
        color: #fff !important;
      }
    }
  }
}

/// Elevation Content of Agrid

.dataEntryModal .serviceContainer {
  background-color: #ffffff;
  .service-actions {
    button {
      background-color: #1264a3 !important;
      color: #ffffff !important;
    }
  }
  .price {
    color: #6d9c4a !important;
  }
  .rent {
    color: #f6cb51 !important;
  }
}
