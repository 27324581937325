$greyColor: #f8f8fa;
$colorBlack: #12131b;
$colorDark: #3a3c4e;

.open-balance-table-header {
  background: $greyColor;
  height: 45px;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px 10px 0 0;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

  .total-invoices {
    display: flex;
    gap: 10px;

    .invoice-count {
      display: flex;
      gap: 5px;
    }
  }
}

.open-balance-table-header-dark {
  @extend .open-balance-table-header;

  background: $colorBlack;
}
