.sov-service-list {
  .service-list-service {
    border-radius: 10px;
    overflow: hidden;
    animation: fadeInSOVServiceUp 0.3s;
  }

  .service-list-service-dark {
    border-radius: 10px;
    background: #12131b;
    border-radius: 10px;
    overflow: hidden;
    animation: fadeInSOVServiceUp 0.3s;
  }
}

@keyframes fadeInSOVServiceUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
