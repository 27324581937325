.switchSchedulesPopover {
  .ant-popover-content {
    // height: fit-content;
    // max-height: 800px;
    // padding-left: 30px;
    padding: 0 10px 10px 10px !important;
    .ant-popover-inner {
      border-radius: 10px;
      padding: 0 10px 10px 10px !important;
    }
    .ant-popover-title {
      height: 35px;
      width: fit-content;
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 600;
      // background: #f8f8fa;
      border-radius: 10px 10px 0px 0px;
      padding: 10px;
    }
    .ant-popover-inner-content {
      padding: 0 10px 10px 10px !important;
      // display: flex;
      // flex-wrap: wrap;
      // padding-bottom: 20px;
    }
  }
  .switchSchedulesPopoverButton {
    border: none;
    background-color: lightblue;
    border-radius: 5px;
  }
}
