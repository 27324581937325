@use "/src/index.scss" as index;

.action-log-row {
  padding: 10px 20px;
  background-color: #f8f8f8;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  min-height: 40px;
  color: #323238;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;

  .action-log-time {
    color: #4aa4ee;
    font-size: 12px;
  }
}

.action-log-row-dark {
  background-color: index.$inputDarkColor;
  color: #fff;

  .action-log-time {
    color: #4aa4ee;
    font-size: 12px;
  }
}
