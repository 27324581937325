.replies-view-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: 20px;

    .show-replies-btn {
        all: unset;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        padding: 0px;
        padding-bottom: 6px;
        margin: 0px;
        width: 24px;
        height: 11px;
        background-color: #f1f1f1;
        border-radius: 5px;
        border-color: #f1f1f1 !important;
        cursor: pointer;
    }

    .reply-list-container {
        overflow-x: auto;
        max-height: 300px;
        padding-left: 5px;
        margin-right: 10px;
    }

}