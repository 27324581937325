.assignProjectGridEdit {
  min-height: 300px;
  width: 800px;
  position: relative;
  color: #000;
  .search-Div {
    width: 200px;
    margin-bottom: 10px;
    .searchComp {
      width: inherit;
    }
  }
}
.assignProjectGridEditDark {
  color: #fff;
  .grid-container {
    .ag-column-drop-wrapper .ag-unselectable {
      background-color: #292b36;
    }
  }
}
