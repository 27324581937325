$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.schedulePreviewModal {
  .ant-modal {
    width: 1251px;
  }

  .ant-modal-header {
    display: flex;
    align-items: center;
    height: 45px;
    background-color: #f8f8fa;
  }
  .schedulePreviewModal-content {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .schedulePreviewModal-days {
      display: flex;
      align-items: center;
      gap: 10px;
      width: 1340px;
      flex-wrap: wrap;
    }
    .schedulePreviewModal-buttons {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
      background-color: #f8f8fa;
      border: none;
      border-radius: 5px;
    }
    .schedulePreviewModal-buttons-allDays {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 124px;
      height: 32px;
      border-radius: 5px;
      border: none;
      background-color: #f8f8fa;
      color: #333238;
    }
  }

  .ant-collapse-header {
    display: flex !important;
    align-items: center !important;
    height: 45px !important;
  }
  .ant-collapse-content-box {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px !important;
    animation: collapse 0.5s;
  }
  .viewProgressDays {
    display: flex;
    padding-top: 10px;
    justify-content: flex-end;
  }
}

.schedulePreviewModalDark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      .schedulePreviewModal-days {
        .schedulePreviewModal-buttons,
        .schedulePreviewModal-buttons-allDays {
          background-color: $inputModalDark;
          color: #fff;
        }
      }
      .ant-collapse {
        background-color: #ffffff08;
        .ant-collapse-header {
          .ant-collapse-expand-icon svg path {
            fill: #fff;
          }
          .ant-collapse-header-text {
            color: #fff;
          }
        }
        .ant-collapse-content-box {
          table {
            th,
            td {
              color: #fff;
            }
          }
        }
      }
    }
  }
}

@keyframes collapse {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
