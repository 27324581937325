@import "../../../../../../../../index.scss";

.violationCheckmarkContainer {
  @include flex(center, center, column);
  padding: 20px;
  cursor: pointer;

  .checkmarkIcon {
    width: 100px;
    margin-bottom: 10px;
  }

  .violationInfoText {
    @include flex(center, center, column);
    text-align: center;
  }

  .activeViolations {
    @include text(20px, 800, 0, #1f2a44, uppercase);
    text-align: center;
  }

  .totalViolations {
    @include text(18px, 500, 0, #1f2a44);
    text-align: center;
  }
}
