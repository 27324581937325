.report-view-controller {
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding-left: 20px;
  padding-right: 20px;
  border-bottom: 1px solid #dee2eb;
  transition: width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
  overflow-x: auto;
  gap: 10px;

  .controller-section-left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;

    .ant-form-item {
      margin-bottom: 0;
    }
  }

  .controller-section-right {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
  }
}

.report-view-controller.report-view-controller-dark {
  background-color: #20212d;
  border-bottom: 1px solid #616481;

  .controller-section-left {
    .ant-select-selector {
      background-color: #3a3c4e !important;
      color: #f5f5f7 !important;
      border: 0;
    }
    .ant-select-arrow,
    .ant-picker-suffix {
      color: #f5f5f7 !important;
    }

    .ant-select-arrow {
      svg {
        fill: #f5f5f7 !important;
      }
    }

    .anticon {
      color: #fff;
      background-color: #3a3c4e;
    }

    .ant-select-selection-placeholder {
      color: #acacca;
    }

    input::placeholder {
      color: #acacca;
    }

    .ant-picker {
      background-color: #3a3c4e !important;
      color: #f5f5f7 !important;
      border: 0;

      .ant-picker-input {
        input {
          color: #f5f5f7 !important;
        }
      }
    }
  }
}
