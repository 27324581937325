$border-color: #acacca;
$base-color: #1f2a44;
$input-background-color: #ffffff;

.sign-up-form {
  margin-top: 40px;

  .react-tel-input {
    height: 50px;
    border: 1px solid $border-color;
    border-radius: 5px;
    background-color: $input-background-color;
    .form-control {
      height: 100%;
      background-color: $input-background-color;
    }
    .flag-dropdown {
      background-color: $input-background-color;
    }
  }
}
.already-have-account {
  color: $base-color;
  font-weight: bold;
}
