$mainDarkBackgroundColor: #20212d;
$cardDarkHeaderColor: #2a2b3a;
$cardDarkBackgroundColor: #12131b;
$inputDarkColor: #2a2b3a;
$searchDark: #12131b;
$bodyDarkBackground: #1f212d;

.radio-group-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  border-bottom: 1px solid #dee2eb;

  .radio-group-label {
    width: 50%;
    padding-left: 20px;
    font-size: 14px;
    height: 100%;
    display: flex;
    gap: 10px;
    align-items: center;
  }
  .checkbox-group-options {
    display: flex;
    align-items: center;
    gap: 10px;
    .ant-checkbox-wrapper-checked {
      .ant-checkbox-inner {
        background-color: #71cf48;
        border-color: #71cf48;
      }
      .ant-checkbox-indeterminate {
        .ant-checkbox-inner {
          background-color: #1677ff !important;
          border-color: #1677ff !important;
          &:after {
            box-sizing: border-box;
            position: absolute;
            top: 50%;
            inset-inline-start: 25%;
            display: table;
            width: 5.7142857142857135px;
            height: 9.142857142857142px;
            border: 2px solid #fff;
            border-top: 0;
            border-inline-start: 0;
            opacity: 1;
            transform: rotate(45deg) scale(1) translate(-50%, -50%) !important;
            content: "";
            transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s !important;
          }
        }
      }
    }
    .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
      .ant-checkbox-checked:not(.ant-checkbox-disabled)
      .ant-checkbox-inner {
      background-color: #68c142;
      border-color: #68c142;
    }
    .ant-checkbox-wrapper:not(:last-child) {
      border-right: 2px solid #dee2eb;
      height: 15px;
      display: flex;
      align-items: center;
    }
  }

  //--------------------------- FORM ITEM WITH RADIO GROUP ---------------------------
  .ant-form-item {
    width: 50%;
    margin: 0;

    .ant-radio-group {
      width: 100%;
      display: flex;
      align-items: center;

      > .ant-radio-wrapper:nth-child(1),
      > .ant-radio-wrapper:nth-child(2) {
        border-right: 2px solid #dddfe3;
        height: 15px;
        display: flex;
        align-items: center;
      }
      .ant-radio-wrapper {
        .ant-radio-inner {
          border-color: #707070 !important;
          background-color: #fff !important;
        }
      }
      .ant-radio-wrapper-checked {
        .ant-radio-inner {
          border-color: #71cf48 !important;
          background-color: #fff !important;
          &:after {
            background-color: #71cf48 !important;
            transform: scale(0.5) !important;
          }
        }
      }

      .ant-radio-wrapper-checked.radio-button-none {
        .ant-radio-inner {
          border-color: #f4554e !important;
          background-color: #fff !important;
          &:after {
            background-color: #f4554e !important;
            transform: scale(0.5) !important;
          }
        }
      }
    }
  }

  //------------------------ FORM ITEM WITH RADIO GROUP (END) -------------------------

  &:nth-child(odd) {
    background-color: #fcfcfc;
  }
  &:nth-child(even) {
    background-color: #fff;
  }
  &:last-child {
    border-bottom: none;
  }
  &:hover {
    background-color: #f1f5fd;
  }
}
.access-tree {
  padding-left: 40px;
  border-bottom: 1px solid #dee2eb;
  border-radius: 0 !important;
  .ant-tree-treenode {
    width: 100%;
    .ant-tree-indent-unit::before {
      border-color: #323338 !important;
    }
    .ant-tree-switcher {
      // line-height: 30px !important;
      .ant-tree-switcher-leaf-line {
        &::after {
          height: 15px !important;
        }
        &::before,
        &::after {
          border-color: #323338 !important;
        }
      }
    }

    .ant-tree-node-content-wrapper {
      width: 100%;
      .ant-tree-title {
        width: 100%;

        //-------------radio group--------------
        .access-tree-radio-group {
          height: 29px;
          background-color: transparent;
          .radio-group-label {
            padding-left: 0;
          }
        }
      }
    }
    .ant-tree-node-selected {
      background-color: unset !important;
    }
  }
}

.drive-access-radio-group {
  .radio-group-label:hover {
    cursor: pointer;
    color: #1264a3 !important;
    font-weight: 600;
  }
}
.drive-access-radio-group.radio-group-wrapper-dark {
  .ant-radio-wrapper,
  .ant-radio-wrapper-disabled {
    color: #fff !important;
    span {
      color: #fff !important;
    }
  }
}

.radio-group-wrapper.radio-group-wrapper-dark {
  .radio-group-label {
    color: #fff;
  }

  &:nth-child(odd) {
    background-color: lighten($cardDarkBackgroundColor, 4%) !important;
  }
  &:nth-child(even) {
    background-color: $cardDarkBackgroundColor !important;
  }
  &:hover {
    background-color: $inputDarkColor !important;
  }
  .ant-checkbox-wrapper,
  .ant-checkbox-wrapper-disabled {
    color: #fff !important;
    span {
      color: #fff !important;
    }
  }
}

.access-tree-dark {
  // background-color: $cardDarkBackgroundColor !important;
  background-color: $bodyDarkBackground !important;
  .ant-tree-treenode {
    color: #fff !important;
    .ant-tree-indent-unit::before {
      border-color: #616481 !important;
    }
    .ant-tree-switcher {
      // line-height: 30px !important;
      .ant-tree-switcher-leaf-line {
        &::before,
        &::after {
          border-color: #616481 !important;
        }
      }
    }
  }
  .ant-tree-list {
    .access-tree-radio-group {
      .radio-group-label {
        color: #fff !important;
      }
      .ant-checkbox-wrapper {
        color: #fff !important;
      }
    }
  }
  svg {
    fill: #fff !important;
    path: {
      fill: #fff !important;
    }
  }
}
