.compare-changes-service {
  .ag-body {
    height: fit-content !important;
    max-height: 600px !important;
    overflow-y: auto !important;
  }
  .ant-modal-header {
    display: flex;
    align-items: center;
    height: 45px;
    background-color: #f5f5f5;
  }
  .ant-modal-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f5f5f5;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .compare-changes-service-content {
    display: flex;
    flex-direction: column;
    .compareBtnFilters {
      display: flex;
      flex-direction: row;
      gap: 10px;
      margin-bottom: 10px;
    }
  }
}
