.progressFiltersModal {
  .ant-modal-header {
    display: flex;
    align-items: center;
    height: 45px;
    background-color: #f5f5f5;
  }
  .progressFiltersModalContent {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 20px;
    row-gap: 0px;
  }
  .ant-modal-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f5f5f5;
    border-radius: 0px 0px 10px 10px !important;
    span {
      color: #acacac;
    }
    @media screen and (min-width: 1600px) {
      .mondayButtonContainer button {
        min-width: 150px;
      }
    }
  }
}
