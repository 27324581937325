$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.shiftsToSplitModal {
  width: calc(100vw - 20px) !important;
  max-width: 1500px;
  .ant-modal-content {
    width: calc(100vw - 20px) !important;
    max-width: 1500px;
    .ant-modal-close {
      height: 45px;
      width: 45px;
      .ant-modal-close-x {
        height: 45px;
        width: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          fill: #333238;
        }
      }
    }
    .ant-modal-header {
      height: 45px;
      display: flex;
      align-items: center;
      background-color: #f5f5f7;
      .ant-modal-title {
        font-size: 16px;
        font-weight: 600;
        color: #333238;
      }
    }
    .ant-modal-body {
      .ant-form {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .labeledInputContainer {
          min-width: 250px;
        }
      }
      .deg-grid-container {
        .ag-layout-normal {
          min-height: 15rem;
        }
      }
      .overallData {
        display: flex;
        justify-content: space-between;
        .dataContainer {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          .dataLabel {
            font-weight: 600;
          }
        }
      }
    }
    .ant-modal-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .mondayButtonStyle {
        width: 150px;
      }
    }
  }
}

.shiftsToSplitModalDark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      .deg-grid-container {
        .ag-root-wrapper {
          background-color: $cardModalBodyDark;
          .ag-header {
            background-color: $cardModalBodyDark;
            .ag-header-row {
              border-top: none;
            }
            .ag-header-cell-resize::after {
              width: 1px !important;
              background-color: #4a4a4a;
            }
          }
          .ag-paging-panel {
            background-color: $cardModalBodyDark;
          }
        }
      }
    }
  }
}
