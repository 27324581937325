$headerDarkBackground: #12131b;
$bodyDarkBackground: #1f212d;
$inputModalDark: #12131b;

.ip-address-whitelist-modal-user-session {
  width: unset !important;
  .ant-modal-content {
    border-radius: 10px;
    .ant-modal-close {
      height: 45px;
      width: 45px;
      .ant-modal-close-x {
        height: 45px;
        width: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .ant-modal-header {
      height: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #f7f8fa 0% 0% no-repeat padding-box;
      opacity: 1;
      border-radius: 10px 10px 0px 0px;
      border: none;
      .ant-modal-title {
        width: 100%;
        height: 22px;
        font: normal normal 600 16px/22px Open Sans;
        letter-spacing: 0px;
        color: #323338;
        opacity: 1;
      }
    }
    .ant-modal-body {
      .ip-address-whitelist-modal-body {
        display: flex;
        flex-direction: column;
        gap: 20px;
        .descOfIpAddressesConf {
          display: flex;
          gap: 5px;
          .readMore {
            cursor: pointer;
            color: #aeb2b8;
            font-weight: 600;
            &:hover {
              color: #357;
            }
          }
        }
        .ipAddressDevicesColumn {
          display: flex;
          justify-content: space-between;
          width: 100%;
          gap: 5px;
          align-items: center;
          .ipAddressDeviceColumn {
            background-color: #f8f8fa;
            height: 32px;
            width: 50%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 5px 5px;
            border-radius: 5px;
            border: 1px solid #dde2eb;
            &.allowed {
              background-color: #d5f1c9;
              border: 1px solid #71cf48;
            }
            &.notAllowed {
              background-color: #f8d7da;
              border: 1px solid #fe4c4a;
            }
          }
        }

        .ipAddressDeleteString {
          cursor: pointer;
          display: flex;
          align-items: center;
          gap: 20px;
          .deleteIcon {
            color: #fe4c4a;
            font-size: 19px;
          }
          .editIcon {
            fill: #1264a3;
            width: 15;
          }
        }

        .modal-input-container {
          .labeledInputContainer {
            height: 55px;
            .labeledInputLabel {
              font-size: 14px;
            }
          }

          .submissionOfIpAddressFromAllUsers {
            display: flex;
            justify-content: space-between;
          }

          .formOfIpCreationDevices {
            display: flex;
            gap: 10px;
            .formOfIpCreationDevice {
              background-color: #f5f5f7;
              border-radius: 5px;
              cursor: pointer;
              height: 32px;
              width: 95px;
              color: #323338;
              font-weight: 400;
              display: flex;
              justify-content: center;
              align-items: center;
              transition: background-color 0.2s ease;
              &:hover {
                background-color: #d5f1c9;
              }
              &.active {
                background-color: #cdf0be;
              }
            }
          }
        }
      }
    }
    .ant-modal-footer {
      padding: 20px !important;
      .modal-footer-ip-address-whitelist {
        display: flex;
        justify-content: space-between;
      }
    }
  }
  @media screen and (min-width: 1300px) {
    width: 840px !important;
    .ant-modal-body {
      .labeledInputContainer {
        width: 300px !important;
      }
    }
    .ant-modal-footer .modal-footer-ip-address-whitelist button {
      min-width: 150px !important;
    }
  }
}

.ip-address-whitelist-modal-user-session-dark {
  .ant-modal-content {
    background-color: $bodyDarkBackground;
    overflow: hidden;
    .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $headerDarkBackground;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      background-color: $bodyDarkBackground;
      .labeledInputContainer {
        .labeledInputLabel {
          color: #fff;
        }
      }
      span {
        color: #fff !important;
      }

      .ant-input {
        background-color: $inputModalDark;
        color: #fff;
        &::placeholder {
          color: #acacca;
        }
      }
    }
    .ant-modal-footer {
      background-color: $bodyDarkBackground;
    }
  }
}
