.checkbox-with-label-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 50px;
  width: 100%;
  border-radius: 5px;

  &:hover {
    opacity: 0.7;
  }
}
