.columnWrapper .col {
  display: "flex";
}

.dynamic-delete-button {
  cursor: pointer;
  position: relative;
  top: 4px;
  font-size: 24px;
  color: #999;
  transition: all 0.3s;
}

.dx-datagrid-checkbox-size .dx-checkbox-icon {
  border-color: #1691ff !important;
}

.oddClassName {
  background: #d4e1ee 0% 0% no-repeat padding-box;
  border: 1px solid #88929e;
  width: 40%;
  opacity: 1;
}

.evenClassName {
  border: 1px solid #88929e;
  background: #ffffff;
  width: 40%;
  opacity: 1;
}

.dynamic-delete-button:hover {
  color: #777;
}

.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

.formWrapper {
  height: 100%;
  position: fixed;
  width: 100%;
  margin-top: 10%;
  left: 0;
  z-index: 1000;
  display: flex;
}

.generalInformation-side,
.services-side {
  height: 100%;
}

.generalInformation-side {
  width: 50%;
  height: 100%;
  margin-top: 4%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  z-index: 0;
  /* background: rgb(8,42,227);
    background: linear-gradient(30deg, rgba(8,42,227,1) 0%, rgba(10,214,238,1) 100%); */
}

.generalInformation-side::after {
  content: "";
  position: absolute;
  margin-top: 4%;
  width: 50%;
  height: 100%;
  top: 0;
  left: 0;
  /* background: linear-gradient(30deg, rgba(3, 32, 194, 0.75) 0%, rgba(0, 223, 253, 0.3) 100%); */
  z-index: -1;
}

.services-side {
  width: 50%;
  height: 50%;
  margin-top: -7%;
  display: flex;
  margin-bottom: 0px;
  margin-right: auto;
  margin-left: auto;
  flex-direction: column;
  justify-content: center;
}

@media (max-width: 769px) {
  .formWrapper {
    display: block;
  }

  .services-side {
    height: auto;
    z-index: 9999;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50%;
    transform: translate(-50%, -50%);
  }

  .generalInformation-side {
    margin-top: 12%;
    width: 50%;
    background-size: cover;
    background-position: center;
  }

  .form-container {
    width: 100% !important;
  }

  .input-field-container {
    height: 5em !important;
  }

  .input-label {
    font-size: 18px !important;
  }
}
