$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.filters-modal {
  .ant-modal-content {
    max-height: calc(100vh - 20px);

    .ant-modal-header {
      height: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #f7f8fa 0% 0% no-repeat padding-box;
      .ant-modal-title {
        width: 100%;
      }
    }
    .ant-modal-body {
      max-height: calc(100vh - 120px);
      overflow: auto;
      padding: 20px;

      .filters-modal-inputs {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px;

        .filters-modal-element {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;

          .ant-divider {
            margin: 10px;
          }

          .filters-modal-text {
            font-size: 16px;
            font-weight: 600;
            line-height: normal;
            margin-bottom: 10px;
          }
          .filters-modal-input {
            width: 75%;

            .labeledInputContainer {
              width: 100%;
              .ant-form-item {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
}

.filters-modal-dark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      .filters-modal-text {
        color: #fff !important;
      }
      .ant-select {
        .ant-select-selector {
          background-color: $inputModalDark !important;
          .ant-select-selection-search-input {
            color: #fff;
          }
          .ant-select-selection-item {
            color: #fff;
          }
          .ant-select-selection-placeholder {
            color: #acacca;
          }
        }
        .ant-select-clear {
          background-color: $inputModalDark;
          svg {
            fill: #acacca;
          }
        }
        .ant-select-arrow svg {
          fill: #fff;
        }
      }
    }
  }
}
