@import "../../../../Communication.scss";

.more-actions-dropdown-overlay {

  .ant-dropdown-menu {
    .ant-dropdown-menu-item {
      .ant-dropdown-menu-title-content {
        .ant-btn-link {
          display: flex;
          align-items: center;
          color: $light-text-color;
        }

        .ant-btn-icon {
          color: $icon-color;
          fill: $icon-color;

          .not-important-icon path {
            stroke: $icon-color;
          }
        }

        .select-emails-to-see-more-actions {
          color: $light-text-color;
          opacity: 0.5;
        }
      }
    }

    .ant-dropdown-menu-item:hover {
      opacity: 0.7;
    }
  }

  &.dark-mode {
    .ant-dropdown-menu {
      background: #2a2b3a;
      color: #fff;

      .ant-dropdown-menu-item {
        .ant-dropdown-menu-title-content {
          .ant-btn-link {
            color: $dark-text-color;
          }

          .ant-btn-icon {
            color: $white;
            fill: $white;

            .not-important-icon path {
              stroke: $white;
            }
          }

          .select-emails-to-see-more-actions {
            color: $white;
            opacity: 0.5;
          }
        }
      }

      .ant-dropdown-menu-item:hover {
        background-color: rgba(#2a2b3a, 0.5);
        opacity: 0.7;
      }
    }
  }
}