.live-sidebar-trip {
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  background-color: white;
  transition: width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s,
    padding 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
  overflow: hidden;
  cursor: default;

  .ant-tabs {
    width: 100%;
    height: calc(100vh - 370px) !important;
  }

  .ant-tabs-ink-bar {
    background: #1065a4;
  }

  .ant-tabs-nav {
    margin: 0;
    width: 100%;
    .ant-tabs-content-holder {
      width: 100%;
    }

    &::before {
      border-color: rgba(0, 0, 0, 0.2);
    }

    .ant-tabs-nav-list {
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;

      .ant-tabs-tab {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
      }

      .ant-tabs-tab.ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          color: #1065a4;
        }
      }
    }
  }

  .trip-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 10px 0 10px;

    .trip-title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
      font-size: 1.1rem;
      font-weight: 550;
      letter-spacing: 0.28px;
      padding-bottom: 0.3rem;
      cursor: pointer;

      &:hover {
        color: #1065a4;
        svg {
          fill: #1065a4;
        }
      }
    }

    .trip-close {
      cursor: pointer;
    }
  }

  .trip-vin {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    font-size: 0.8rem;
    margin-bottom: 1rem;
    padding-bottom: 0.3rem;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding: 0 10px 5px 10px;

    .vin-status {
      font-weight: 550;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-direction: row;
      flex-wrap: nowrap;
      gap: 5px;

      .status-dot {
        height: 0.4rem;
        width: 0.4rem;
        border-radius: 50%;
      }
    }
  }

  .trip-body {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    flex: 1;
    gap: 1.2rem;

    .next-location-info {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      gap: 0.3rem;
      width: 100%;
      padding: 0 10px;

      .next-location-title {
        font-size: 1.15rem;
        font-weight: 550;
        letter-spacing: 0.18px;
        gap: 8px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
      }
    }

    .title-hover {
      &:hover {
        color: #1065a4;
        cursor: pointer;
      }
    }

    .custom-location-renderer {
      width: 100%;
      padding: 0 10px;
      .ant-select {
        width: 100%;
      }
    }

    .sidebar-route-details {
      padding: 0 10px;
      .route-detail {
        .detail-title {
          margin-left: 6px;
          margin-right: 8px;
        }

        svg {
          fill: #ea4325;
        }
      }
    }

    .sidebar-route-history {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      overflow-x: hidden;
      overflow-y: auto;
      border-top: 1px solid rgba(0, 0, 0, 0.2);

      .single-route-details-container {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        width: 100%;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        padding: 10px 0;
        cursor: pointer;

        .route-location-title {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-direction: row;
          width: 100%;
          padding: 0 5px;
          gap: 5px;

          .title-arrive-info {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;

            .arrive-location {
              font-weight: 550;
              letter-spacing: 0.18px;
            }

            .arrive-time {
              font-size: 0.8rem;
            }
          }
        }

        .expandable-info {
          background-color: #f1f5fd;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          flex-direction: column;
          width: 100%;
          gap: 5px;
          transition: height 0.3s ease-in-out;
          padding: 0 0.5rem;
          padding-left: 1rem;

          .trip-alert-info {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: row;
            gap: 5px;

            .alert-info {
              display: flex;
              justify-content: flex-start;
              align-items: flex-start;
              flex-direction: column;

              .alert-text {
                font-size: 0.8rem;
                font-weight: 550;
                letter-spacing: 0.1px;
              }

              .alert-date-time {
                font-size: 0.6rem;
              }
            }
          }
        }

        .expandable-info.info-collapsed {
          height: 0;
          padding: 0;
          margin-top: 0;
          overflow: hidden;
        }
        .expandable-info.info-expanded {
          height: fit-content;
          margin-top: 0.5rem;
        }
      }

      .single-route-details-container.single-route-selected {
        background-color: #f1f5fd;
      }

      .single-route-details-container.single-route-unselected {
        background-color: inherit;
      }
    }
  }
}

.live-sidebar-trip.live-sidebar-expanded {
  width: 18vw;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
}

.live-sidebar-trip.live-sidebar-collapsed {
  width: 0px;
  padding: 0px;
}

.live-sidebar-trip.sidebar-trip-dark {
  background-color: #20212d;

  .ant-tabs-nav {
    margin: 0;
    width: 100%;

    &::before {
      border-color: #616481;
    }

    .ant-tabs-tab-btn:where(:not([aria-selected="true"])) {
      color: #ffffff;
    }
  }

  .trip-header {
    background-color: #2a2b3a;

    .trip-title {
      color: #ffffff;
      &:hover {
        color: #1065a4;
      }
    }
  }

  .trip-vin {
    background-color: #2a2b3a;
    color: #ffffff;
    border-bottom: 1px solid #616481;
  }

  .trip-body {
    color: #ffffff;

    .next-location-info {
      &:hover {
        color: #1065a4;
        cursor: pointer;
      }
    }

    .custom-location-renderer {
      .labeledInputLabel {
        color: #ffffff;
      }
      .ant-select {
        .ant-select-selector {
          background-color: #3a3c4e !important;
          color: #f5f5f7;
          border: 0;
        }

        .ant-select-selection-placeholder {
          color: #f5f5f7a9;
        }

        .ant-select-clear {
          background-color: #3a3c4e;
          svg {
            fill: #acacca;
          }
        }
      }
    }

    .sidebar-route-details {
      padding: 0 10px;
      .route-detail {
        .detail-title {
          margin-left: 6px;
          margin-right: 8px;
        }

        svg {
          fill: #ea4325;
        }
      }
    }
  }

  .sidebar-route-history {
    border-top: 1px solid #616481;
    color: #ffffff;

    .single-route-details-container {
      border-bottom: 1px solid #616481;

      .expandable-info {
        background-color: #41435e;
      }
    }

    .single-route-details-container.single-route-selected {
      background-color: #41435e;
    }

    .single-route-details-container.single-route-unselected {
      background-color: inherit;
    }
  }
}
