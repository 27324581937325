$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.newDispatchModalWrap {
  overflow: hidden;
}

.newDispatchModalContainer {
  width: fit-content !important;
  .ant-modal-content {
    height: 98vh;
    width: 98vw;
    height: 98dvh;
    width: 98dvw;
    max-height: 98vh;
    max-width: 98vw;
    max-height: 98dvh;
    max-width: 98dvw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;
    .ant-modal-header {
      width: 100%;
      font-weight: 700;
      border-radius: 10px 10px 0 0;
      background-color: #f8f8f8;
      padding: 10px 24px;
      margin-bottom: 12px;

      .ant-modal-title {
        color: #323338;
        font-weight: 600;

        .titleInfoSpan {
          color: #fe4c4a;
        }
      }
    }

    .ant-modal-body {
      width: 100%;
      height: 100%;
      overflow: auto;
      padding: 0 !important;

      .timeSuggestionPopover {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        flex-wrap: nowrap;
      }

      .ant-form {
        width: 100%;
        height: 100%;
        overflow: auto;
        padding: 0 !important;
        display: flex;
        justify-content: baseline;
        align-items: center;
        flex-direction: column;
        gap: 1rem;
      }

      .dispatchModalControl {
        height: fit-content;
        width: calc(100% - 74px);
        max-width: 100%;
        min-height: 75px;
        overflow-x: auto;
        overflow-y: hidden;
        display: flex;
        justify-content: baseline;
        align-items: center;
        flex-direction: row;
        gap: 2rem;
        padding-left: 2rem;
        border-radius: 5px;
        box-shadow: 0 0 3px 0px rgba(0, 0, 0, 0.15);
        margin-top: 1px; // for the sake of shadow
        .labeledInputContainer {
          min-height: 65px;
          height: fit-content;
          .ant-form-item {
            margin-bottom: 0px;
          }
        }

        .mondayButtonRed {
          font: italic normal 800 21px/15px Open Sans;
        }

        .dispatchPickupCheck {
          height: 100%;
          padding: 0;
          width: calc(150px - 1rem) !important;
          min-width: unset !important;
          display: flex;
          justify-content: center;
          align-items: center;
          white-space: nowrap;

          .labeledInputLabel {
            display: none;
          }

          .ant-form-item {
            height: 100%;
            display: flex;
            width: calc(150px - 1rem) !important;
            justify-content: center;
            align-items: center;
            margin: 0 !important;
            padding: 0 !important;
            white-space: nowrap;
          }
          label {
            transform: translate(-1rem, 50%);
            span {
              font-size: 14px;
              font-weight: 600 !important;
              color: #383554;
            }
          }
        }
      }

      .overallRouteContainer {
        overflow-y: auto;
        max-width: 98%;
        scroll-behavior: smooth;
        display: flex;
        justify-content: baseline;
        align-items: center;
        flex-direction: column;
        gap: 1rem;
        padding-top: 5px;
        padding-bottom: 5px;

        .removeDispatchRoute {
          position: relative;
          top: -1rem;
          left: calc(100% - 1.5rem);
          cursor: pointer;
        }

        .notesArea {
          max-width: 500px;
        }
      }

      .nonEditable {
        background-color: #f7f7f7;
      }

      .dispatchRouteCard {
        height: auto;
        width: 98%;
        display: flex;
        justify-content: baseline;
        align-items: baseline;
        flex-direction: row;
        flex-wrap: wrap;
        box-shadow: 0 0 3px 0px rgba(0, 0, 0, 0.15);
        border-radius: 5px;
        padding-left: 1rem;
        padding-top: 1rem;
        transition: all 400 ease-out;
        position: relative;

        .cardTitle {
          width: fit-content;
          height: 32px;
          background: #1f2a44;
          border-radius: 5px;
          color: #fff;
          padding-left: 20px;
          padding-right: 20px;
          display: flex;
          align-items: center;
          margin-right: 20px;
          position: relative;
          top: 28px;
          font: normal normal 600 16px/22px Open Sans;
          text-transform: uppercase;
        }

        .ghostElement {
          height: 0 !important;
          width: 0 !important;
          min-width: unset;
          padding: 0;
          .ant-input-affix-wrapper,
          input {
            padding: 0;
          }
          .ant-input-suffix {
            display: none;
          }
        }

        .routePicker {
          max-width: 100%;
        }

        .returnRoute-pickTime {
          width: 150px;
        }

        .next-day-picker {
          width: calc(150px + 1rem);
        }

        .prev-day-picker {
          width: calc(170px);
          margin-right: 1.5rem;
        }

        .itineraryButton {
          margin-right: 1rem;
          transform: translateY(calc(70%));
        }

        // .mondayButtonContainer:has(.itineraryButton) {
        //   margin-right: 1rem;

        //   .itineraryButton {
        //     transform: translateY(calc(100% + 2px));
        //   }
        // }

        .notesArea {
          textarea {
            height: 32px;
          }
        }

        .dispatchRouteDistance {
          width: 100%;
          padding-right: 1rem;
          padding-bottom: 1rem;
          font-size: 15px;
          // color: #ff4d4f;
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;
          row-gap: 1rem;
          span {
            font-weight: 550;
          }

          .dispatchMapsShowButton {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 1rem;
            row-gap: 1rem;
            .ant-form-item {
              margin-bottom: 0 !important;
            }
          }
        }
      }
    }

    .ant-modal-footer {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      flex-wrap: nowrap;
      padding-left: 2%;
      padding-right: 2%;

      button {
        min-width: 150px;
        &:disabled {
          color: rgba(0, 0, 0, 0.25) !important;
          background-color: rgba(0, 0, 0, 0.04) !important;
          .mondayButtonDivider {
            background-color: transparent !important;
          }
        }
      }

      .otherModalButtons {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 1rem;
      }
    }
  }
}

// .dispatchRoutesPopup {
//   div[id="DropOffLocation#0_list"] {
//     .ant-select-item-group:first-child {
//       display: none !important;
//     }
//   }
// }

// .dispatchRoutesRemove {
//   div[id="DropOffLocation#0_list"] {
//     div[id="DropOffLocation#0_list_1"] {
//       display: none !important;
//     }
//     .ant-select-item-group:first-child {
//       display: none !important;
//     }
//   }
// }

.routeShowerForSwitchModal {
  width: fit-content !important;
  .ag-root-wrapper-body.ag-layout-normal {
    height: fit-content !important;
    min-height: fit-content !important;
    overflow-y: auto !important;
  }
  .dispatchPriorityCell {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      height: 80%;
      width: 90%;
      border-radius: 10px;
      color: white;
      font-weight: 550;
      letter-spacing: 0.8px;
      display: flex;
      font-size: 1.02rem;
      justify-content: center;
      align-items: center;
      max-width: 180px;
    }

    .Low {
      background-color: #71cf48;
    }

    .Medium {
      background-color: #fc7300;
    }

    .High {
      background-color: #eb4345;
    }
  }

  .ant-modal-content {
    border-radius: 10px;
    .ant-modal-close {
      height: 41px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .ant-modal-header {
      height: 41px;
      padding-left: 1rem;
      padding-right: 1rem;
      padding-top: 0.3rem;
      padding-bottom: 0.3rem;
      text-align: left;
      background-color: #f7f8fa;
      width: 100%;
      border-radius: 10px 10px 0 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .ant-modal-title {
        color: #323338;
        font-size: 1rem;
        font-weight: 550;
      }
    }

    .ant-modal-body {
      padding: 1rem !important;
      overflow: hidden;
      border-radius: 0 0 10px 10px;

      .switchTableContainer {
        width: fit-content;
        height: fit-content;

        .infoSection {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 1rem;
        }

        .ant-table-wrapper {
          min-height: 16rem;
          max-height: 50vh;
          overflow-y: auto;

          th {
            font-weight: 550;
          }
        }
        .agGridContainer {
          min-height: 16rem;
          max-height: 50vh;
          overflow-y: auto;
          min-width: 50vw;
          margin-top: 1rem;
        }
      }
    }

    .ant-modal-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      flex-direction: row;
      flex-wrap: nowrap;
    }
  }
}

.dispatchMapModal {
  width: fit-content !important;
  .ant-modal-content {
    border-radius: 10px;
    .ant-modal-close {
      height: 41px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .ant-modal-header {
      height: 41px;
      padding-left: 1rem;
      padding-right: 1rem;
      padding-top: 0.3rem;
      padding-bottom: 0.3rem;
      text-align: left;
      background-color: #f7f8fa;
      width: 100%;
      border-radius: 10px 10px 0 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .ant-modal-title {
        color: #323338;
        font-size: 1rem;
        font-weight: 550;
      }
    }

    .ant-modal-body {
      padding: 0 !important;
      overflow: hidden;
      border-radius: 0 0 10px 10px;

      .innerDispatchMap {
        height: 500px;
        width: 837px;
      }
    }
  }
}

.dispatchDriverSwitchModal {
  .ant-modal-content {
    border-radius: 10px;
    .ant-modal-close {
      height: 41px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .ant-modal-header {
      height: 41px;
      padding-left: 1rem;
      padding-right: 1rem;
      padding-top: 0.3rem;
      padding-bottom: 0.3rem;
      text-align: left;
      background-color: #f7f8fa;
      width: 100%;
      border-radius: 10px 10px 0 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .ant-modal-title {
        color: #323338;
        font-size: 1rem;
        font-weight: 550;
      }
    }

    .ant-modal-footer {
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-direction: row;

      .decline-logout {
        background-color: #fe4c4a;
        margin-right: 10px;
        .mondayButtonDivider {
          background: #eb4345 !important;
        }
      }
    }
  }
}

.newDispatchModalContainerDark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      .dispatchModalControl {
        width: calc(100% - 74px);
        background-color: $cardModalHeaderDark;
        border-radius: 5px;
        box-shadow: 0 0 3px 0px rgba(255, 255, 255, 0.4);
        .mondayButtonContainer {
          transform: translateY(0px) !important;
        }
        .labeledInputLabel {
          color: #fff;
        }
        .ant-select {
          .ant-select-selector {
            background-color: $cardModalBodyDark !important;
            .ant-select-selection-search-input {
              color: #fff;
            }
            .ant-select-selection-item {
              color: #fff;
            }
            .ant-select-selection-placeholder {
              color: #acacca;
            }
          }
          .ant-select-clear {
            background-color: $cardModalBodyDark;
            svg {
              fill: #acacca;
            }
          }
          .ant-select-arrow svg {
            fill: #fff;
          }
        }
        .ant-select-disabled {
          .ant-select-selector {
            .ant-select-selection-search-input {
              color: #acacca !important;
            }
            .ant-select-selection-item {
              color: #acacca !important;
            }
          }
        }
        .ant-picker {
          background-color: $cardModalBodyDark !important;
          .ant-picker-input {
            background-color: $cardModalBodyDark;
            input {
              color: #f2f2f2;
              &::placeholder {
                color: #acacca;
              }
            }
            .ant-picker-suffix svg {
              color: #acacca;
            }
            .ant-picker-clear {
              background-color: $cardModalBodyDark;
              svg {
                fill: #acacca;
              }
            }
          }
        }
      }
      .overallRouteContainer {
        .dispatchRouteCard {
          box-shadow: 0 0 3px 0px rgba(255, 255, 255, 0.4);
          background-color: $cardModalHeaderDark;
          .removeDispatchRoute {
            svg {
              fill: #fff;
            }
          }
          .cardTitle {
            background-color: #383554;
          }
          .labeledInputLabel {
            color: #fff;
          }
          .check-title {
            color: #fff;
          }
          .ant-radio-wrapper span {
            color: #fff;
          }
          .ant-input {
            background-color: $cardModalBodyDark !important;
            color: #fff;
            &::placeholder {
              color: #acacca;
            }
          }
          .ant-input-disabled {
            color: #acacca !important;
          }
          .ant-input-affix-wrapper {
            background-color: $cardModalBodyDark !important;
            .ant-input-suffix {
              svg {
                fill: #acacca;
              }
            }
          }
          .ant-input-affix-wrapper-disabled {
            color: #acacca;
          }
          .ant-select {
            .ant-select-selector {
              background-color: $cardModalBodyDark !important;
              .ant-select-selection-search-input {
                color: #fff;
              }
              .ant-select-selection-item {
                color: #fff;
              }
              .ant-select-selection-placeholder {
                color: #acacca;
              }
            }
            .ant-select-clear {
              background-color: $cardModalBodyDark;
              svg {
                fill: #acacca;
              }
            }
            .ant-select-arrow svg {
              fill: #fff;
            }
          }
          .ant-select-disabled {
            .ant-select-selector {
              .ant-select-selection-search-input {
                color: #acacca !important;
              }
              .ant-select-selection-item {
                color: #acacca !important;
              }
            }
          }
          .ant-picker {
            background-color: $cardModalBodyDark !important;
            .ant-picker-input {
              background-color: $cardModalBodyDark !important;
              input {
                color: #f2f2f2;
                &::placeholder {
                  color: #acacca;
                }
              }
              .ant-picker-suffix svg {
                color: #acacca;
              }
              .ant-picker-clear {
                background-color: $cardModalBodyDark;
                svg {
                  fill: #acacca;
                }
              }
            }
          }
          .ant-picker-disabled {
            .ant-picker-input input {
              color: #acacca !important;
            }
          }
          .dispatchRouteDistance {
            span {
              color: #fff;
            }
          }
        }
        .nonEditable {
          opacity: 0.65;
        }
      }
      .background-grid-modal {
        .filtersCard .inputContainer {
          .labeledInputLabel {
            color: #fff;
          }
          .ant-select {
            .ant-select-selector {
              background-color: $inputModalDark !important;
              .ant-select-selection-search-input {
                color: #fff;
              }
              .ant-select-selection-item {
                color: #fff;
              }
              .ant-select-selection-placeholder {
                color: #acacca;
              }
            }
            .ant-select-clear {
              background-color: $inputModalDark;
              svg {
                fill: #acacca;
              }
            }
            .ant-select-arrow svg {
              fill: #fff;
            }
          }
          .ant-select-disabled {
            .ant-select-selector {
              .ant-select-selection-search-input {
                color: #acacca !important;
              }
              .ant-select-selection-item {
                color: #acacca !important;
              }
            }
          }
          .button-div .hideTable {
            background-color: #383554;
          }
        }
        .dark-ag-theme {
          .ag-header-cell {
            background-color: $cardModalHeaderDark !important;
            padding: 0px var(--ag-cell-horizontal-padding) !important;
            .ag-header-cell-text {
              font-size: 16px;
            }
            .ag-header-cell-resize::after {
              width: 1px !important;
              background-color: grey;
            }
          }
        }
      }
      .hideTable {
        background-color: #383554;
      }
    }
  }
}

@media (max-width: 730px) {
  .dispatchTruckNumberSelect {
    min-width: none !important;
  }
  .ant-modal-footer {
    button {
      min-width: unset !important;
    }
  }
  .overallRouteContainer {
    max-height: unset;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
  }

  .dispatchRouteCard {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    flex-wrap: nowrap;
  }

  .routePicker {
    margin-top: 30px;
  }
}

@media (max-width: 527px) {
  .newDispatchModalContainer {
    .ant-modal-footer {
      width: 100% !important;
      overflow-x: auto !important;
      overflow-y: hidden !important;
      gap: 1rem;
      .otherModalButtons {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        flex-wrap: nowrap;
        width: fit-content;
      }
    }
  }
}

@media (max-width: 500px) {
  .newDispatchModalContainer {
    .ant-modal-footer {
      .mondayButtonText {
        display: none !important;
      }
      .mondayButtonDivider {
        display: none !important;
      }
      button {
        width: auto !important;
      }
    }
  }
}
