$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.hearingsModal {
  .modalContent {
    max-height: 75vh;
    min-height: 350px;
    width: 90vw;
    max-width: 95vw;
    overflow: auto !important;

    .formStepper {
      margin-bottom: 25px;
      overflow: hidden;
    }
    .bodyContent {
      display: flex;
      flex-direction: row;
      height: 100%;
      width: 100%;

      .uploaderSection {
        width: 20%;
        height: auto;
        margin-right: 30px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        .uploaderButton {
          cursor: pointer !important;
        }
        .uploadSection {
          width: unset !important;
          max-width: 355px;
          gap: 20px;
          padding: 0px 10px;
        }
        // .uploadedFilesSection {
        //   width: 100%;
        //   padding: 0;
        //   min-height: 120px !important;
        // }
        .anticon-file-text {
          font-size: 30px;
        }
        .fileAuthor {
          display: none;
        }
        .safetyFilesContainer {
          height: auto;
          .safetyFilesTitle {
            display: flex;
            .safetyFilesTitleLine {
              border-top: 1px solid #000;
              margin: 10px 0;
              flex: 1;
            }
            .safetyFilesTitleText {
              margin: 0 10px;
              font-weight: 600;
            }
          }
        }
      }
      .detailsWrapper {
        display: flex;
        flex-direction: column;
        width: 80%;
        // max-height: 65vh;
        // overflow-y: auto;

        .borderedTextCard {
          border: none;
          border-radius: 0;
          border-top: 1px solid #323338;
          align-items: center;
          margin-top: 16px !important;
          padding: 15px 0 0 0;

          .cardTitle {
            font-size: 16px;
            color: #323338;
          }
        }
        .detailsCard {
          width: 100% !important;
          min-width: 500px;
          flex-wrap: wrap;

          .hearingsAvatarsDiv {
            display: flex;
            position: relative;
            height: 35px;
            top: 30px;
          }
          .ant-picker {
            width: 100%;
          }
          @media (min-width: 1100px) {
            width: 68rem;
          }
          @media (max-width: 1100px) and (min-width: 850px) {
            width: 750px;
          }
          .ant-select-dropdown {
            min-width: 150px !important;
            width: 100% !important;
          }

          .rc-virtual-list-holder {
            max-height: 200px !important;
          }
          .labeledInputContainer {
            width: 24%;
            max-width: 24% !important;
            margin-right: 10px;
            #timePredict {
              width: 100% !important;
            }
          }
        }
        .notesCard {
          width: 100% !important;
          .HearingsNotes {
            width: 100% !important;
            .ant-btn {
              display: none;
            }
          }
          .ql-toolbar {
            background-color: #f8f8fa;
            justify-content: center;
          }
          .ql-editor {
            background-color: #f5f5f7;
            height: 200px !important;
          }
        }
      }
    }
  }
  .newFooterContainer {
    button {
      width: 150px;
    }
  }
}

.hearingsModalDark {
  .ant-modal-body {
    .antCustomStepper {
      .ant-steps {
        .ant-steps-item-finish ::before,
        .ant-steps-item-active::before,
        .ant-steps-item::before {
          border-left-color: $cardModalBodyDark;
        }
        .ant-steps-item-wait {
          background-color: $cardModalHeaderDark;
          .ant-steps-item-content .ant-steps-item-title {
            color: #acacca;
          }
        }
      }
    }
    .uploaderSection {
      .uploadSection {
        border-color: #acacca;
      }
    }
    .childrenContainer {
      .labeledInputLabel {
        color: #fff;
      }
      .ant-input {
        background-color: $inputModalDark !important;
        color: #fff;
        &::placeholder {
          color: #acacca;
        }
      }
      .ant-input-disabled {
        color: #acacca;
      }
      .ant-select {
        .ant-select-selector {
          background-color: $inputModalDark !important;
          .ant-select-selection-search-input {
            color: #fff;
          }
          .ant-select-selection-item {
            color: #fff;
          }
          .ant-select-selection-placeholder {
            color: #acacca;
          }
        }
        .ant-select-clear {
          background-color: $inputModalDark;
          svg {
            fill: #acacca;
          }
        }
        .ant-select-arrow svg {
          fill: #fff;
        }
      }
      .ant-select-disabled {
        .ant-select-selector {
          .ant-select-selection-search-input {
            color: #acacca !important;
          }
          .ant-select-selection-item {
            color: #acacca !important;
          }
        }
      }
      .ant-picker {
        background-color: $inputModalDark !important;
        .ant-picker-input {
          background-color: $inputModalDark;
          input {
            color: #f2f2f2;
            &::placeholder {
              color: #acacca;
            }
          }
          .ant-picker-suffix svg {
            color: #acacca;
          }
          .ant-picker-clear {
            background-color: $inputModalDark;
            svg {
              fill: #acacca;
            }
          }
        }
      }
      .ant-radio-wrapper span {
        color: #fff;
      }
      .react-tel-input {
        .form-control {
          background-color: $inputModalDark !important;
          color: #fff;
          &::placeholder {
            color: #acacca;
          }
        }
        .flag-dropdown {
          background-color: $inputModalDark;
          border-radius: 5px 0px 0px 5px;
          .selected-flag {
            &:hover {
              background-color: $inputModalDark;
            }
            &:focus {
              background-color: $inputModalDark;
            }
          }
          .arrow {
            border-top: 4px solid #fff;
          }
        }
        .open {
          background-color: $inputModalDark;
          border-radius: 5px 0px 0px 5px;
          .selected-flag {
            background-color: $inputModalDark;
            .arrow {
              border-bottom: 4px solid #fff;
              border-top: 4px solid transparent;
            }
          }
        }
      }
      .quill {
        background-color: $cardModalBodyDark !important;
        .ql-toolbar {
          background-color: #15161f !important;
          border-radius: 5px;
          .ql-formats {
            color: #fff !important;
            .ql-picker-label {
              color: #fff;
              &::before {
                color: #fff;
              }
              .ql-stroke {
                stroke: #fff;
              }
            }
            button .ql-stroke {
              stroke: #fff;
            }
          }
        }
        .ql-container {
          background-color: $inputModalDark !important;
          border-radius: 5px;
          .ql-editor {
            background-color: $inputModalDark !important;
            &::before {
              color: #acacca;
            }
            p {
              color: #fff !important;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1500px) {
  .hearingsModal .modalContent .bodyContent {
    flex-direction: column !important;
    .uploaderSection {
      width: 90% !important;
      margin-right: 0px !important;
      margin-bottom: 20px !important;
      align-self: center !important;
    }
    .detailsWrapper {
      align-self: center !important;
      width: 100% !important;
      .detailsCard {
        width: 100% !important;
        min-width: 100% !important;
      }
    }
  }
}

@media (max-width: 1100px) {
  .hearingsModal
    .modalContent
    .bodyContent
    .detailsWrapper
    .detailsCard
    .labeledInputContainer {
    width: 31%;
    max-width: 31% !important;
  }
}
@media (max-width: 800px) {
  .hearingsModal .modalContent {
    width: 85vw;
    .bodyContent .detailsWrapper .detailsCard .labeledInputContainer {
      width: 46%;
      max-width: 46% !important;
    }
  }
}

@media (max-width: 575px) {
  .hearingsModal .modalContent .formStepper {
    height: 200px !important;
    width: 100% !important;
    .ant-steps-navigation.ant-steps-vertical > .ant-steps-item {
      height: 35px !important;
    }
    .ant-steps-navigation.ant-steps-vertical
      > .ant-steps-item
      .ant-steps-item-content:last-child {
      min-height: 0px !important;
    }
    .ant-steps-navigation.ant-steps-vertical > .ant-steps-item:after {
      position: relative;
      top: -36px;
      left: 100%;
      transform: rotate(0deg);
    }
    .ant-steps-navigation.ant-steps-vertical
      > .ant-steps-item.ant-steps-item-active:before {
      display: none !important;
    }
    .ant-steps-navigation.ant-steps-vertical
      > .ant-steps-item:last-child:after {
      display: none !important;
    }
    .ant-steps-item {
      margin-bottom: 5px !important;
    }
  }

  .hearingsModal .modalContent {
    overflow-x: hidden !important;
  }
}
@media (max-width: 600px) {
  .hearingsModal {
    padding: 5px;
    .modalContent {
      width: 100%;
      .bodyContent {
        flex-direction: column-reverse !important;

        .detailsWrapper .detailsCard .labeledInputContainer {
          width: 100%;
          max-width: 100% !important;
        }
      }
    }
  }
  .animated-list .listItemDiv {
    flex-direction: column !important;
  }
}

.picker-dialog-bg {
  z-index: 20000 !important;
}

.picker-dialog {
  z-index: 20001 !important;
}
