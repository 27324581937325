$dark1: #12131b;
$dark2: #20212d;
$dark3: #2a2b3a;

.active-automations-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  .active-automations {
    display: flex;
    flex-direction: column;
    .active-automation {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      padding: 15px 30px;
      transition: background-color 0.3s ease, transform 0.3s ease;
      border-top: 1px solid rgba(5, 5, 5, 0.06);

      &:hover {
        background-color: #f5f5f7;
        transform: scale(1.02);
      }
      .description {
        display: flex;
        flex-direction: column;
        gap: 10px;
        .name {
          font-weight: 600;
        }
      }
    }
  }

  &.dark-mode {
    .active-automation {
      &:hover {
        background-color: $dark2;
      }
      .ant-tag {
        color: #f2f2f2;
        background-color: $dark1;
      }
      button {
        background-color: $dark2;
        &:hover {
          background-color: $dark1;
        }
      }
    }
  }
}
