@import "/src/index.scss";

.analytics-charts {
  height: calc(100vh - 125px);
  border: 1px solid transparent; // don't move this
  .charts-controller {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    margin: 10px 10px 3px 10px;
    padding: 0px 10px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.161) !important;
    .ant-form-item {
      margin-bottom: 0px;
    }
    .filter-section,
    .layout-controller {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
  .analytics-charts-body {
    max-height: calc(100% - 60px) !important;
    overflow-y: auto;
  }
  .card-header {
    cursor: grab;
    &:active {
      cursor: grabbing;
    }
  }
}

.analytics-charts-dark {
  background-color: $mainDarkBackgroundColor;
  color: #fff;
  .charts-controller {
    background-color: $cardDarkBackgroundColor;
    box-shadow: 0px 1px 4px rgba(255, 255, 255, 0.1607843137) !important;
    .ant-picker {
      background-color: $inputDarkColor !important;
      .ant-picker-input {
        background-color: $inputDarkColor;
        input {
          color: #f2f2f2;
          &::placeholder {
            color: #acacca;
          }
        }
        .ant-picker-suffix svg {
          color: #acacca;
        }
      }
      .ant-picker-clear {
        background-color: $inputDarkColor;
        .anticon-close-circle svg {
          fill: #acacca !important;
          path {
            fill: #acacca !important;
          }
        }
      }
    }
    .ant-select {
      .ant-select-selector {
        background-color: $inputDarkColor !important;
        .ant-select-selection-search-input {
          color: #fff;
        }
        .ant-select-selection-item {
          color: #fff;
        }
        .ant-select-selection-placeholder {
          color: #acacca;
        }
      }
      .ant-select-clear {
        background-color: $inputDarkColor;
        svg {
          fill: #acacca;
        }
      }
      .ant-select-arrow svg {
        fill: #fff;
      }
    }
  }
}
