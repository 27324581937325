@use "src/index.scss" as index;

.live-sheet-modal {
  height: fit-content !important;
  width: fit-content !important;
  max-height: calc(100vh - 20px);
  max-width: calc(100vw - 20px);
  .ant-modal-content {
    max-height: 700px !important;
    max-width: 900px;
    width: calc(100vw - 20px) !important;
    height: calc(100vh - 20px) !important;

    .ant-modal-close {
      height: 45px;
      width: 45px;
      .ant-modal-close-x {
        height: 45px;
        width: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .ant-modal-header {
      height: 45px;
      display: flex;
      align-items: center;
      background-color: #f8f8fa;
    }
    .ant-modal-body {
      height: calc(100% - 97px);
      width: 100%;

      .ant-form {
        height: calc(100% - 10px);
        width: calc(100% - 10px);
        display: grid;
        gap: 10px;
        grid-template-columns: 40% 60%;
        grid-template-rows: 40px calc(100% - 40px);
        grid-template-areas:
          "controller controller"
          "vehicles config";

        .live-sheet-controller {
          grid-area: controller;
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-direction: row;
          flex-wrap: nowrap;

          .labeledInputContainer {
            width: 280px;
          }

          .controller-inner {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            flex-direction: row;
            flex-wrap: nowrap;
            gap: 10px;
          }
        }

        .vehicle-list {
          grid-area: vehicles;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          gap: 10px;
          flex-direction: column;
          overflow-y: auto;

          .vehicle-option {
            width: 100%;
          }
        }

        .report-check {
          background: linear-gradient(to right, #f4f5f6, #fff);
        }

        .configuration-container {
          grid-area: config;
        }
      }
    }
    .ant-modal-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      flex-wrap: nowrap;
      width: 100%;
    }
  }
}

.live-sheet-modal.live-sheet-modal-dark {
  .ant-modal-content {
    background-color: index.$mainDarkBackgroundColor;
    .ant-modal-close .ant-modal-close-x svg {
      fill: #fff !important;
    }
    .ant-modal-header {
      background-color: index.$cardDarkBackgroundColor;

      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      color: #fff;

      .ant-form {
        .check-title {
          color: #fff;
        }

        .report-check {
          background: linear-gradient(
            to right,
            index.$cardDarkHeaderColor,
            index.$mainDarkBackgroundColor
          );
        }
      }
    }
  }
}

@media (max-width: 580px) {
  .live-sheet-modal {
    .ant-modal-content {
      .ant-modal-body {
        .ant-form {
          height: calc(100% - 20px);
          overflow: auto;
          grid-template-columns: 100%;
          grid-template-rows: 40px 140px calc(100% - 200px);
          grid-template-areas:
            "controller"
            "config"
            "vehicles";
        }
      }
    }
  }
}
