.task-form-fields-section {
  // display: flex;
  // flex-wrap: wrap;
  // // max-width: 564px;
  // justify-content: flex-start;
  // margin-top: 15px;
  // flex: 1 1;
  // height: fit-content;

  .labeledInputContainer {
    width: 100%;
    max-width: unset;
    .ant-form-item-control-input-content {
      height: auto;
    }
  }

  .task-title,
  .task-topic,
  .task-topicId,
  .task-users,
  .task-labels,
  .task-assignTo,
  .task-subtopic,
  .task-subtopicId {
    display: flex;
    flex-direction: column;
    // margin-bottom: 0;

    // margin: 0 20px 20px 0;
    span:first-child {
      display: flex;
      padding-left: 5px;
      color: #323338;
      font-weight: 600;
    }
    .ant-select {
      width: 100%;
    }
    .ant-input,
    .ant-select-selector {
      width: 100%;
      border: none;
      border-radius: 5px;
      background-color: #f8f8fa;
    }
    .task-subtopicId-select {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      span {
        cursor: pointer;
      }
    }
  }
  // .task-title {
  //   min-width: 100%;
  //   .ant-form-item {
  //     // margin-bottom: 0;
  //   }
  // }
  .task-topic {
    min-width: 100%;
    .ant-select-arrow {
      svg {
        width: 10.78px;
      }
    }
  }
  .task-labels {
    // padding-top: 20px;
    min-width: 100%;
    span:first-child {
      color: #fff;
      font-weight: 600;
      padding-left: 5px;
    }
  }
  .task-users {
    // max-width: 24%;
    margin-bottom: 0;
    .ant-select-arrow {
      svg {
        width: 10.78px;
      }
    }
  }
  flex: 1;
  .modal__multiSelect {
    display: flex;
    align-items: flex-end;
    gap: 10px;
  }

  .task-topicId {
    min-width: 100%;
  }
  .task-subtopic,
  .task-subtopicId {
    .ant-select-selector {
      min-width: 200px;
    }
  }
  .ant-select-selection-item-remove {
    display: flex;
    align-items: center;
  }

  .default-topicContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    .modal__default__props {
      display: flex;
      align-items: center;
      padding: 0 10px;
      opacity: 0.6;
      font-style: italic;
      user-select: none;
      cursor: default;
      border-radius: 5px;
      background-color: #fffbe6;
      border-left: 3px solid #d8c568;
      margin: 18px 20px 18px 0;
      h3 {
        display: flex;
        align-items: center;
        font-size: 13px;
        margin-bottom: 0;
        margin-right: 3px;
      }
      p {
        text-align: start;
        color: #a29a37;
        font-size: 13px;
        letter-spacing: 0.5px;
        display: flex;
        align-items: center;
        margin-bottom: 0;
        b {
          font-weight: bold;
          font-size: 1.1rem;
          letter-spacing: 0.5px;
        }
      }
    }

    .default-topic {
      margin: 18px 20px 29px 0;
    }
  }
}
