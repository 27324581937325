.form-section {
  display: flex;
  width: 609px;
  height: 276px;
  border-radius: 10px;
  background: rgb(255, 255, 255);
  margin-top: 10px;
  padding: 0px 10px;
  flex-direction: column;
  justify-content: space-around;
  animation: scheduleForm 0.5s;

  > div {
    display: flex;
    align-items: center;
    gap: 9px;
    justify-content: flex-end;
    padding: 0px 13px;
    > span {
      margin-bottom: 5px;
    }
    .ant-form-item {
      margin-bottom: 0px;
    }
  }
}
