.fenceNavigatorContent {
  width: 300px;
  overflow: auto;
  background-color: white;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #dee2eb;
  &.dark {
    background-color: #20212d;
    color: white;
  }
  .fenceNavigatorContentHeader {
    border-top: 1px solid #dee2eb;
    display: flex;
    padding: 8px 0px 8px 10px;
  }

  .ant-collapse {
    border: 1px solid #dee2eb;
    border-radius: 0px;
    .ant-collapse-item {
      border-radius: 0px;
      border: none;
      .ant-collapse-header {
        border-radius: 0px;
        border: none;
      }
      .ant-collapse-content {
        .ant-collapse-content-box {
          margin: 0;
          padding: 0;
        }
      }
    }
  }

  .fenceNavigatorItem {
    display: flex;
    gap: 5px;
    &.dark {
      color: white;
    }
  }
  .fenceNavigatorItemCollapsed {
    display: grid;
    gap: 5px;
    &.dark {
      color: white;
    }
    .fenceNavigatorDisplay {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #dee2eb;
      padding: 3px 10px 3px 10px;
      &.dark {
        background-color: #20212d;
      }
      div:first-child {
        flex: 1;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .fenceNavigatorButton {
        border: 1px solid #1264a3;
      }
    }
  }
}
