.communication-rich-editor-container {
    max-height: 350px !important;
    overflow-y: auto !important;

    .jodit-wysiwyg {
        max-height: 100% !important;
    }

    .jodit-toolbar__box {
        position: absolute !important;
        bottom: 10% !important;
        z-index: 1;
    }

    &.toolbar-up {
        .jodit-toolbar__box {
            position: relative !important;
            top: 0 !important;
        }
    }

    &.has-attachments {
        .jodit-toolbar__box {
            bottom: 20% !important;
        }
    }
}