@import "../../../../../../../../../../../../index.scss";

.service-header-retainage-modal {
  border-radius: 10px;
  overflow: hidden;

  .ant-modal-content {
    border-radius: 10px;
    overflow: hidden;
    background: white;
  }

  .changeRetainageInput {
    border-radius: 5px;
    border: 1px solid black;
  }
  .ant-modal-close {
    .ant-modal-close-x {
      color: #fff;
      font-size: 1.25rem;
      margin-top: -5px;
    }
  }

  .ant-modal-header {
    height: 45px;
    background-color: #1264a3;
    border-bottom-color: #1264a3;
    .ant-modal-title {
      color: #fff;
      font-size: 1.25rem;
      letter-spacing: 2px;
      margin-top: -5px;
    }
  }

  .ant-modal-footer {
    height: 45px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top: 0;
    .ant-btn-primary {
      background: #1679d4;
      border-color: #1679d4;
    }

    .serviceFooterRetainageModal {
      display: flex;
      justify-content: flex-end;
      margin-top: -10px;
      .cancelMondayButton {
        margin-right: 10px;
        @include mondayButton(#fe4c4a, #eb4345, #fff);
      }
      .applyMondayButton {
        @include mondayButton(#71cf48, #68c142, #fff);
        svg {
          height: 20px;
          width: 25px;
          margin-left: -5px;
          margin-right: -5px;
        }
      }
    }
  }
}

.service-header-retainage-modal-dark {
  border-radius: 10px;
  overflow: hidden;

  .ant-modal-content {
    border-radius: 10px;
    overflow: hidden;
  }

  .changeRetainageInput {
    border-radius: 5px;
    border: 1px solid black;
    background: white;
  }
  .ant-modal-close {
    .ant-modal-close-x {
      color: #fff;
      font-size: 1.25rem;
      margin-top: -5px;
    }
  }

  .ant-modal-header {
    height: 45px;
    background-color: #12131b;
    border-bottom-color: #12131b;
    .ant-modal-title {
      color: #fff;
      font-size: 16px;
      letter-spacing: 2px;
      margin-top: -5px;
    }
  }

  .ant-modal-body {
    height: 100px;
    display: flex;
    align-items: center;
    background: #1f212d;
  }

  .ant-modal-footer {
    height: 45px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top: 0;
    background: #1f212d;
    .ant-btn-primary {
      background: #1679d4;
      border-color: #1679d4;
    }

    .serviceFooterRetainageModal {
      display: flex;
      justify-content: flex-end;
      margin-top: -10px;
      .cancelMondayButton {
        margin-right: 10px;
        @include mondayButton(#fe4c4a, #eb4345, #fff);
      }
      .applyMondayButton {
        @include mondayButton(#71cf48, #68c142, #fff);
        svg {
          height: 20px;
          width: 25px;
          margin-left: -5px;
          margin-right: -5px;
        }
      }
    }
  }
}

.changeRetainageSOVButton {
  @include mondayButton(#1264a3, #0f5c97, #fff);

  &:disabled {
    @include mondayButton(#0b3e64, #0e5185, #548bb3);

    svg {
      fill: #548bb3;
    }
  }
}
