@use "~ag-grid-enterprise/styles" as ag;
@include ag.grid-styles(
  (
    theme: alpine,
  )
);

@import "../../../index.scss";

.documentationPageBasePage {
  // display: unset;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  .dynamicBasePage {
    // height: 100%;
    // height: calc(100% - 80px) !important;
    flex-grow: 1;

    .agGridContainer {
      .ant-btn {
        background-color: #1264a3;
        border: 0;
        border-radius: 5px;

        &:hover {
          background-color: #0f5c97;
        }
      }
    }
  }
}

// .documentationPageWrapper {
//   display: flex;
//   // margin-top: 70px;
//   // margin-left: 250px;
//   background-color: #f4f5f6;
//   // width: calc(100vw - 250px);
//   // height: calc(100vh - 70px);
//   width: 100%;
//   padding: 10px;

//   @include flex(center, flex-start, column);

//   .dynamicViewHeader {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     background-color: #fff;
//     box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1607843137);
//     border-radius: 10px;
//     padding: 10px;
//     width: 100%;
//     .leftSection {
//       .search-Div {
//         display: flex;
//         align-items: center;
//         height: 32px !important;
//         position: relative;
//         .ant-input-affix-wrapper {
//           border: 0 !important;
//           border-radius: 5px !important;
//           background-color: #f4f5f6 !important;
//           min-width: 200px;
//           .ant-input {
//             background: transparent;
//           }
//         }
//       }
//     }
//     .rightSection {
//       @include flex(center, center);
//       gap: 10px;
//       .filterIcons {
//         cursor: pointer;
//         width: 32px;
//         height: 32px;
//         background: #1264a3;
//         fill: white;
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         border-radius: 5px;
//       }
//     }
//   }

//   .docGridCard {
//     margin: 0;
//     margin-top: 10px;
//     flex: 1;
//     border-radius: 10px;
//     padding: 0;
//     overflow-y: auto;

//     .childrenContainer {
//       height: 100%;
//     }

//     .dynamicBasePage {
//       .agGridContainer {
//         // height: 400px;
//       }
//     }
//   }

//   .documentationsTable {
//     width: 100%;
//     height: 100%;
//     border-radius: 10px;

//     .ag-body-horizontal-scroll {
//       min-height: 5px !important;
//     }

//     .ant-btn {
//       background-color: #1264a3;
//       border: 0;
//       border-radius: 5px;

//       &:hover {
//         background-color: #0f5c97;
//       }
//     }
//     .ag-ltr .ag-cell:nth-child(2) {
//       display: flex;
//       align-items: center;
//     }
//   }

//   .statusCard {
//     @include square(fit-content, 30px, 5px, unset);
//     @include flex(center, center);
//     min-width: 200px;
//     letter-spacing: 0px;
//     text-transform: uppercase;
//     padding: 0 10px;
//   }

//   .centeredCell {
//     justify-content: center;
//     .ag-react-container {
//       @include flex(center, center);
//     }
//   }

//   a {
//     color: #1264a3;
//     font-weight: 600;
//   }
// }

.light-ag-theme {
  .ag-theme-alpine {
    --ag-border-radius: 0px;
  }

  $ag-icon-font-code-checkbox-unchecked: "\f14a";
  $ag-icon-font-code-checkbox-checked: "\f14a";
  .ag-header-row {
    background-color: #fff;
  }

  .ag-header-cell {
    .ag-header-cell-text {
      @include text(16px, 600, 0, #323338);
    }
    &:hover {
      background-color: #f2f2f2;
    }
  }

  .ag-cell {
    // border-right: 0 !important;
    border-right-color: #dde2eb !important;
  }

  .ag-cell-value {
    // border: 0 !important;
    border-right-color: #dde2eb !important;
  }

  .ag-root-wrapper {
    border: 0;
    border-radius: 10px;
    // box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }

  .ag-ltr {
    .ag-cell {
      display: flex;
      // align-items: center;
      overflow-x: hidden;
      overflow-y: hidden;
    }
    .ag-side-bar-right {
      border: 0;
      color: #757575;
      fill: #757575;
    }
  }
  .ag-paging-panel {
    border: 0;
  }

  .ag-column-drop {
    background-color: #fff;
    border-bottom: 0;
  }

  .ag-column-drop-horizontal-title-bar {
    margin-right: 10px;
  }
}

span.ant-input-group-wrapper.ant-input-search.searchMissingDoc {
  align-self: flex-end;
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  width: 300px;
}

.search-Div-documentationPage {
  align-self: flex-end;
  margin: 20px 0px 0px 20px;
  display: flex;
  align-items: center;
  margin-left: 20px;
  .search-comp-documentationPage {
    margin-top: 2px;
    .ant-select-selector {
      padding-left: 30px;
      height: 32px;
      width: 300px;
      background: #fcfcfc 0% 0% no-repeat padding-box;
      border: 0.20000000298023224px solid #dedfe1;
      border-radius: 5px;
      opacity: 1;
      .ant-select-selection-search-input {
        padding-left: 20px;
      }
    }
    .ant-select {
      height: inherit;
      box-sizing: border-box;
      height: 32px;
      margin: 0;
      padding: 0;
      width: 100%;
      color: #323338;
      font-size: 14px;
      font-variant: tabular-nums;
      line-height: 1.5715;
      list-style: none;
      font-feature-settings: "tnum", "tnum";
      position: relative;
      display: inline-block;
      cursor: pointer;
      z-index: 2;
    }
  }

  .SearchLogoIcon-documentationPage {
    position: relative;
    right: 290px;
    opacity: 0.5;
  }
}

.dark-ag-theme {
  .ag-theme-alpine {
    --ag-border-radius: 0px;
  }

  .ag-row {
    background-color: #292b3a;
    color: #f5f5f5;
    border-color: #4a4a4a;
  }

  .ag-row-even {
    background-color: #1f212d;
    color: #f5f5f5;
    border-color: #4a4a4a;
  }

  .ag-header-row {
    border-top: 1px solid #4a4a4a;
    &:not(.ag-header-row-column-group):not(.ag-header-row-column:nth-child(2)) {
      height: 100% !important;
    }
  }

  .ag-header {
    max-height: 49px !important;
    // min-height: 0px !important;
    height: 49px !important;
    background-color: #12131b;
    border-color: #4a4a4a;
  }

  .ag-header-cell {
    color: #f5f5f5;
    font-size: 18px;
    font-weight: 400;

    .ag-header-cell-resize::after {
      width: 1px !important;
      background-color: #4a4a4a !important;
    }

    .headerDivContainer {
      width: 100%;
      height: 100%;
      font-size: 16px;
      overflow: hidden;
      padding-bottom: 5px;

      .letterContainer {
        width: 100%;
        height: 36px;
        font-size: 16px;
        font-weight: 500;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-bottom: 2px solid #4a4a4a;
        border-right: 2px solid #4a4a4a;
      }

      .textContainer {
        width: 100%;
        padding-top: 3px;
        font-size: 16px;
        font-weight: 500;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-right: 2px solid #4a4a4a;
      }

      .textContainerStretched {
        width: 100%;
        height: 50px;
        padding-top: 3px;
        font-size: 16px;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
        border-right: 2px solid #4a4a4a;
      }

      .actionsContainer {
        width: 100%;
        height: 26px;
        font-size: 16px;
        font-weight: 500;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-right: 2px solid #4a4a4a;
      }
    }

    &:hover {
      background-color: #4a4a4a;
    }
  }

  // .ag-root-wrapper-body {
  //   height: 100% !important;
  //   min-height: 100% !important;
  //   // padding: 15px;
  // }

  .ag-cell-value {
    border: 0 !important;
    justify-content: center !important;
  }

  .cell-left-aligned {
    justify-content: flex-start !important;
  }
  .cell-center-aligned {
    align-items: center !important;
    justify-content: center !important;
  }
  .ag-root-wrapper {
    border: 0;
  }

  .ag-ltr {
    .ag-cell {
      display: flex;
      align-items: center;
      border-right: 1px solid #4a4a4a !important;
      font-size: 16px !important;
      font-weight: 450 !important;
    }

    .ag-side-bar-right {
      border: 0;
      color: #fff;
      fill: #acacca;
    }
  }

  .ag-paging-panel {
    border: none;
    background-color: #12131b;
  }

  .ag-column-drop {
    background-color: #fff;
    border-bottom: 0;
  }

  .ag-column-drop-horizontal-title-bar {
    margin-right: 10px;
  }

  .ag-center-cols-viewport {
    background: #1f212d;
  }
  .ag-body-horizontal-scroll-viewport {
    background: #1c1c1c;
  }

  .ag-tool-panel-wrapper {
    background-color: #12131b;
    .ag-input-field-input {
      background-color: #2a2b3a !important;
      border-color: #acacca;
      &::placeholder {
        color: #acacca;
      }
    }
  }

  ::-webkit-scrollbar-thumb {
    background: #757575;
    border-radius: 5px;
    &:hover {
      background-color: #35415e;
    }
  }
}

.cell-left-aligned {
  text-align: start !important;
  // align-items: start !important;
  justify-content: flex-start !important;
}

.cell-center-aligned {
  align-items: center !important;
  justify-content: center !important;
}

.grey-ag-theme {
  .ag-theme-alpine {
    --ag-border-radius: 0px;
  }

  .ag-header-cell-resize {
    &:after {
      width: 0px !important;
    }
  }
  .ag-header-row {
    background-color: #fff;
    height: 100% !important;
  }
  .ag-row-odd {
    background-color: #f1f1f1;
  }
  .ag-row-even {
    background-color: #f7f8fa;
  }

  .ag-header {
    border-color: #4a4a4a;
    // height: 120px !important;
    min-height: 87px !important;
    max-height: 87px !important;
  }

  // .ag-center-cols-viewport {
  //   background-color: #f3f3f5;
  // }

  .ag-header-cell {
    color: #757575;
    padding: 0 !important;

    .headerDivContainer {
      width: 100%;
      height: 100%;
      font-size: 16px;
      overflow: hidden;

      .letterContainer {
        width: 100%;
        height: 36px;
        padding: 5px 0;
        font-size: 16px;
        font-weight: 500 !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-bottom: 1px solid #dcdfe3;
        border-right: 1px solid #dcdfe3;
        border-top: 1px solid #dcdfe3;
        color: black;
        background: #cfcece;
      }

      .textContainer {
        width: 100%;
        padding-top: 3px;
        font-size: 16px;
        font-weight: 500 !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-right: 1px solid #cfcece;
        color: black;
        background: #e7e7e7;
      }

      .textContainerStretched {
        width: 100%;
        height: 50px;
        padding-top: 3px;
        font-size: 16px;
        font-weight: 500 !important;
        display: flex;
        justify-content: center;
        align-items: center;
        border-right: 1px solid #cfcece;
        color: black;
        background: #e7e7e7;
      }

      .actionsContainer {
        width: 100%;
        height: 26px;
        font-size: 16px;
        font-weight: 500 !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-right: 1px solid #cfcece;
        color: black;
        background: #e7e7e7;
      }
    }

    &:hover {
      background-color: #f2f2f2;
    }
  }

  .ag-cell {
    border-right: 0 !important;
    justify-content: center !important;
    font-size: 16px !important;
    font-weight: 450 !important;
  }

  .ag-cell-value {
    border: 0 !important;
  }

  .ag-root-wrapper {
    border: 0;
  }

  .ag-ltr {
    .ag-cell {
      border-right: 1px solid #dcdfe3 !important;
    }

    .ag-side-bar-right {
      border: 0;
      color: #757575;
      fill: #757575;
    }
  }

  .ag-paging-panel {
    border: 0;
  }

  .ag-column-drop {
    background-color: #fff;
    border-bottom: 0;
  }

  .ag-column-drop-horizontal-title-bar {
    margin-right: 10px;
  }
}
//======================================================================
.dark-ag-theme-details {
  .ag-theme-alpine {
    --ag-border-radius: 0px;
  }

  //ag-header-row ag-header-row-column-group

  .ag-header-row {
    background-color: #fff;
  }

  .ag-header-row-column-group {
    background-color: black !important;
    border-bottom: 1px solid #525151 !important ;

    .ag-icon {
      color: #f2f2f2;
    }

    .ag-header-group-cell-label {
      color: #f2f2f2;
    }
  }
  .ag-header-cell {
    background: black;
    border-bottom: 1px solid #525151 !important ;

    &:hover {
      background: darkgrey !important;
    }

    &:active {
      background: darkgrey !important;
    }
    .ag-header-cell-text {
      @include text(16px, 600, 0, #f2f2f2);
    }
    &:hover {
      background-color: #f2f2f2;
    }
  }

  .ag-center-cols-viewport {
    background: #1c1c1c;
  }
  .ag-body-horizontal-scroll-viewport {
    background: #1c1c1c;
  }

  ::-webkit-scrollbar-thumb {
    background: #757575;
    border-radius: 5px;
    &:hover {
      background-color: #35415e;
    }
  }

  .ag-ltr {
    .ag-cell {
      border-right: 1px solid #525151 !important;
    }

    .ag-side-bar-right {
      border: 0;
      color: #757575;
      fill: #757575;
    }
  }

  .ag-header-viewport {
    background-color: black !important;
  }

  .ag-row {
    background: #262c2e !important;
    color: #f5f5f5;
    border-color: #4a4a4a;
  }

  .ag-row-even {
    background: #343738 !important;
    color: #f7f8fa;
    border-color: #4a4a4a;
  }

  .ag-cell-value {
    // border: 0 !important;
    border-right-color: #dde2eb !important;
  }

  .ag-root-wrapper {
    border: 0;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }

  .ag-ltr .ag-side-bar-right {
    border: 0;
    color: #757575;
    fill: #757575;
  }

  .ag-paging-panel {
    border: 0;
  }

  .ag-column-drop {
    background-color: #fff;
    border-bottom: 0;
  }

  .ag-column-drop-horizontal-title-bar {
    margin-right: 10px;
  }
}

// @media only screen and (max-width: 768px) {
//   .documentationPageWrapper {
//     // width: calc(100% - 100px);
//     // margin-left: 100px;
//     // margin-top: 110px;
//     // height: calc(100vh - 110px);
//   }
// }

// @media only screen and (max-width: 440px) {
//   .documentationPageWrapper {
//     width: 100%;
//     margin-left: 0px;
//     margin-top: 110px;
//     height: calc(100vh - 110px);
//     padding: 10px;
//     .statusesPanelContainer {
//       display: none;
//     }
//   }
// }

// @media only screen and (max-width: 414px) {
//   .documentationPageWrapper {
//     width: calc(100% - 60px);
//     margin-left: 60px;
//     margin-top: 110px;
//     height: calc(100vh - 110px);
//   }
// }
