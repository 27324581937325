@import "../../../index.scss";

$cardDarkBackgroundColor: #12131b;

.statusesPanelContainer {
  // padding: 10px;
  border-radius: 10px;
  overflow-x: auto;
  overflow-y: hidden;
  user-select: none;
  min-height: 130px;
  margin: 0;
  transition: 0.2s ease-in;

  .statusesPanelContent {
    @include flex(center, stretch);
    flex-wrap: nowrap;
    gap: 10px;
  }
  .clickable {
    cursor: pointer;
    transition: 0.2s ease-in;

    // &:hover {
    //   transform: scale(1.012, 1.03);
    // }
    &:hover {
      transform: scale(1.012, 1.01);
    }
  }
}

.statusesPanelContainerDark {
  background-color: $cardDarkBackgroundColor;
}
