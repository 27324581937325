.MultiBoxContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  max-width: 720px;
  padding-top: 20px;
  .checkboxContainer {
    pointer-events: none;
    .checkBoxStyle {
      width: 200px;
      .greenContainer {
        background-color: rgb(1, 192, 106);
      }
      .redContainer {
        background-color: rgb(255, 77, 79);
      }
    }
  }
}
