.dynamicStatementsFormSection {
  height: fit-content;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;

  .statementSection {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .fieldsSection {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: row;
      flex-wrap: wrap;
      column-gap: 1.2rem;
      row-gap: 1rem;

      .formTextEditorContainer {
        width: 100%;
      }
    }

    .removeCardButton {
      position: relative;
      top: 0.7rem;
      left: calc(100% - 1rem);
    }

    .signings {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      gap: 1rem;
    }
    .signContainer {
      width: 240px;
      height: 150px;
      position: relative;
      overflow: hidden;
      background-color: #fff;
      img {
        object-fit: contain;
        width: 100%;
        height: calc((70vw - 250px) / 3);
        position: absolute;
        top: calc((70vw - 250px) / -10);
        @media (max-width: 1600px) {
          height: calc((70vw - 200px) / 3);
          position: absolute;
          top: calc((70vw - 250px) / -12);
        }
      }
    }
  }
}
