@import "../../../../../index.scss";

$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;
$mainDarkBackgroundColor: #20212d;
$menuHover: #2a2b3a;

.normalSizedModal::before {
  display: none;
}
.normalSizedModal {
  padding: 20px;
  .ant-modal {
    min-width: 50% !important;
    width: fit-content !important;
  }
  .ant-modal-content {
    height: fit-content;
    max-height: calc(100vh - 20px);
    max-width: calc(100vw - 20px);
    border-radius: 10px;
    width: fit-content;
    margin: auto;
    .ant-modal-close-x {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 45px;
      margin-right: 20px;
    }
  }
  .ant-modal-header {
    // @include flex(center, flex-start);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: #f5f5f7;
    padding: 20px;
    height: 45px;
    border: 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .ant-modal-title {
    width: 100%;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #323338;
  }
  .ant-modal-body {
    padding-bottom: 0px !important;
    // max-height: calc(90vh - 100px);
    // overflow-x: hidden;
    // overflow-y: auto;
  }
  .subtitle {
    @include text(15px, 600, 0.24px, #929292);
    margin-bottom: 23px;
  }
  .footerContainer {
    // position: absolute;
    // bottom: 0;
    // right: 0;
    width: 100%;
    padding: 20px 0px;
    background-color: inherit;
    @include flex(center, flex-end);
  }
  .newFooterContainer {
    width: 100%;
    padding: 20px 0px;
    gap: 10px;
    background-color: inherit;
    @include flex(center, space-between);
  }
  .noMarginButton {
    margin: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .cancelFooterButton {
    background-color: #fe4c4a !important;
    border: 0;
    border-radius: 5px;
    color: #fff;
    margin-right: 15px;
    &:hover {
      color: #fff;
      border: 0;
      background-color: #eb4345;
    }
  }
  .secondaryFooterButton {
    letter-spacing: 0.24px;
    background-color: #1890ff;
    color: #fff;
    border: 0;
    border-radius: 5px;
    margin-right: 15px;
    &:hover {
      background-color: #1780e2;
      color: #fff;
    }
  }
  .disabledFooterButton {
    background-color: #1264a3 !important;
    &:hover {
      background-color: #0f5c97 !important;
      color: #fff;
    }
  }
  .primaryFooterButton {
    background-color: #1264a3 !important;
    color: #fff;
    font-family: "Open Sans";
    border: 0;
    border-radius: 5px;
    &:hover {
      color: #fff;
      border: 0;
      background-color: #0f5c97;
    }
    &:active {
      background-color: #0f5c97;
      color: #fff;
      border: 0;
    }
    span {
      margin-right: 0.5rem;
    }
  }
}
.NormalSizedModalDropdown {
  background-color: #f5f5f7 !important;
  border-radius: 5px !important;
  background: #f5f5f7;
  padding: 0 !important;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25) !important;
  .ant-dropdown-menu-item {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 6.5px;
    padding-bottom: 6.5px;
    .ant-dropdown-menu-title-content {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      margin: 0;
      padding: 0;
    }
    &:not(:last-child) {
      border-bottom: 1px solid #efefef;
    }
    &:hover {
      background: #f1f5fd;
      color: #1264a3;
    }
  }
}

.NormalSizedModalDropdownDark {
  background-color: $mainDarkBackgroundColor !important;
  .ant-dropdown-menu-item {
    .ant-dropdown-menu-title-content {
      color: #f2f2f2;
    }
    &:not(:last-child) {
      border-bottom: 1px solid #323338;
    }
    &:hover {
      background: $cardModalBodyDark;
    }
  }

  .ant-dropdown-menu-submenu {
    .ant-dropdown-menu-submenu-title {
      color: #f2f2f2;
      &:hover {
        background: $menuHover;
      }

      .ant-dropdown-menu-submenu-arrow-icon {
        color: #f2f2f2 !important;
      }

      .ant-dropdown-menu-submenu-expand-icon {
        color: #f2f2f2 !important;
      }
    }
  }
}

.submenu-items-dark {
  color: #f2f2f2;

  .ant-dropdown-menu {
    background: $mainDarkBackgroundColor;

    .ant-dropdown-menu-item {
      color: #f2f2f2;

      &:hover {
        background-color: $menuHover;
      }

      .ant-dropdown-menu-title-content {
        color: #f2f2f2;
      }
    }
  }
}

.normalSizedModalDark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close .ant-modal-close-x svg {
      fill: #f2f2f2 !important;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #f2f2f2;
      }
    }
    .ant-modal-body {
      .bodyContent {
        .closeIcon {
          fill: #fff;
          cursor: pointer;
        }
      }
      .ant-form {
        .firstSection {
          .serviceInputs .ant-form-item .ant-row .ant-col {
            .ant-form-item-required {
              color: #fff;
            }
            .ant-form-item-control-input .ant-form-item-control-input-content {
              .ant-input {
                background: black;
                color: #fff;
              }
              .serviceColDef-container header {
                background: #000 !important;
                background-color: #000 !important;
                .title {
                  color: #fff;
                }
              }
            }
          }
          .serviceCheckbox .ant-form-item .ant-row .ant-col label {
            color: #fff;
          }
        }
      }
      .richTextComp
        .tox
        .tox-editor-container
        .tox-editor-header
        .tox-toolbar-overlord
        .tox-toolbar__primary {
        background: black;
        .tox-toolbar__group .tox-tbtn {
          .tox-icon svg {
            fill: #fff !important;
          }
          .tox-tbtn__select-label {
            color: #fff;
          }
          .tox-tbtn__select-chevron svg {
            fill: #fff;
          }
        }
      }
    }
  }
}
