.main-inspection {
  .ag-status-bar {
    width: calc(100% - 330px) !important;
    height: 48px;
    padding: 0 5px 0 0;

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .ag-status-bar-left {
      width: calc(100% - 100px);
      overflow-x: auto;

      .ag-react-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;

        .custom-grid-status-panel {
          width: 100%;
          height: 100%;
          padding-left: 10px;
          padding-right: 10px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 8px;
          user-select: none;
        }
      }
    }

    .ag-status-bar-right {
      right: 0 !important;
    }
  }

  .ant-tabs {
    .ant-tabs-content-holder {
      .ant-tabs-content {
        .ant-tabs-tabpane {
          .dynamicBasePage {
            .ag-paging-panel {
              gap: 0px !important;
            }
          }
          .dynamicBasePageDark {
            .ag-paging-panel {
              gap: 0px !important;
            }
          }
        }
      }
    }
  }
}
