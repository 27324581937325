.next-steps-container {
  .ant-modal-content {
    position: relative;
    min-width: 650px;
    height: 650px;
    width: fit-content;
    overflow: hidden;

    .ant-modal-header {
      background: #f8f8fa !important;
    }

    .ant-modal-body {
      height: calc(90% - 40px);
      padding: 20px !important;
      overflow-y: auto;
      overflow-x: hidden;
    }

    .ant-modal-footer {
      position: sticky;
      bottom: 0;
      left: 0;
      width: 100%;
      background-color: #fff;
    }
  }
}

.stepOptions {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .ant-radio-wrapper {
    height: 36px !important;
    display: flex;
    align-items: center;
    padding: 3.5px;
    background-color: #f5f5f7;
    border-radius: 20px 0 0 20px;

    .ant-radio {
      border: 5px solid #fe4c4a;

      .ant-radio-inner {
        border: 2px solid #ff7274;
        background-color: #eb4345;
        height: 20px;
        width: 20px;
      }
    }

    .ant-radio-checked {
      border: 5px solid #68c142;

      .ant-radio-inner {
        border: 2px solid #71cf48;
        background-color: #68c142;
        height: 20px;
        width: 20px;
      }
    }
    .ant-radio-checked .ant-radio-inner::after {
      transform: scale(0.5);
    }
  }
}

.steps-checkbox-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
}

.no-steps-container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  color: #d8dde6;
}

.steps-body {
  // display: flex;
  // gap: 50px;
  width: 100%;

  .step-stepper {
    width: 100%;
  }
}
