.detailsRentail {
  min-width: 1300px;
  .DetailsContainer {
    width: 100%;
    height: 400px;
  }
}

.weeksRentail {
  min-width: 690px;
  .DetailsContainer {
    width: 100%;
    height: 400px;
  }
}
