$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.warningModal-light {
  // z-index: 1000000000000;
  // .ant-modal {
  //   z-index: 9999 !important;
  // }
  .ant-modal-content {
    border-radius: 10px;
  }
  .ant-modal-header {
    background-color: #f8f8f8;
    border-bottom: none;
    display: flex !important;
    align-items: center !important;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 0 20px;
    height: 45px;
    .ant-modal-title {
      color: #323338;
      font-weight: 600;
      font-size: 16px;
    }
  }
  .ant-modal-close {
    .ant-modal-close-x {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      font-weight: bold;
      height: 45px;
      svg {
        fill: #323338;
      }
    }
  }

  .ant-modal-body {
    .ant-radio-wrapper {
      .ant-radio,
      .ant-radio-inner {
        height: 16px;
        width: 16px;
      }
      span {
        height: fit-content;
        width: fit-content;
      }
    }
  }
  .logout-modal-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;

    .buttons {
      display: flex;
      justify-content: center;
      gap: 20px;
      // margin-top: 20px;
    }
  }

  .footerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    .mondayButtonContainer {
      .mondayButtonText {
        font-size: 14px;
      }
      .mondayButtonDivider {
        height: 100%;
      }
    }
    .decline-logout {
      background-color: #fe4c4a;
      // margin-right: 10px;
      .mondayButtonDivider {
        background: #eb4345 !important;
      }
    }
  }
}

.warningModal-dark {
  .ant-modal-content {
    border-radius: 10px;
    background-color: #1f212d;
  }
  .ant-modal-header {
    background-color: #12131b;
    border-bottom: none;
    display: flex !important;
    align-items: center !important;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 0 20px;
    height: 45px;
    .ant-modal-title {
      color: #fff;
      font-weight: 600;
      font-size: 16px;
    }
  }
  .ant-modal-close {
    .ant-modal-close-x {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      font-weight: bold;
      height: 45px;
      svg {
        fill: #fff;
      }
    }
  }
  .ant-modal-body {
    background-color: #1f212d;
    color: #fff;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    .logout-modal-body {
      text-align: center;
      .ant-table-wrapper {
        .ant-table {
          background-color: $cardModalBodyDark;
          .ant-table-thead {
            .ant-table-cell {
              background-color: $cardModalHeaderDark;
              color: #fff;
            }
          }
          .ant-table-tbody {
            .ant-table-cell {
              background-color: $cardModalBodyDark;
              color: #fff;
            }
          }
        }
        .ant-pagination {
          .ant-pagination-prev svg,
          .ant-pagination-next svg {
            fill: #fff;
          }
          .ant-pagination-disabled svg {
            fill: #acacca;
          }
          .ant-pagination-item {
            background-color: $cardModalHeaderDark;
            a {
              color: #fff;
            }
          }
        }
      }
      .buttons {
        display: flex;
        justify-content: center;
        gap: 20px;
        // margin-top: 20px;
      }
    }
    .ant-radio-wrapper {
      .ant-radio,
      .ant-radio-inner {
        height: 16px;
        width: 16px;
      }
      span {
        height: fit-content;
        width: fit-content;
        color: #fff;
      }
    }
  }
}

.force-to-inspect-modal {
  .ant-modal-content {
    .ant-modal-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 20px;
    }
  }
}
