.fileUploaderWrapper {
  position: relative;
  display: inline-block;
  border-radius: 4px;
}

.loadingContainer {
  height: 128px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.defaultAvatar {
  border-radius: 4px;
}

.uploadPreviewOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  border-radius: 100%;
}

.imagePreview {
  width: 250px !important;
  height: 250px !important;
  border-radius: 50%;
  object-fit: cover;
}
