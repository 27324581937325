@import "../../../index.scss";

:root {
  // light theme custom css variables
  --default-card-bg-color: #fff;
  --default-box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1607843137);
  --default-card-border-radius: 10px;
}
$mainDarkBackgroundColor: #20212d;
$cardDarkHeaderColor: #2a2b3a;
$cardDarkBackgroundColor: #12131b;
$inputDarkColor: #2a2b3a;

@mixin page($left: unset) {
  @include flex(unset, unset, column);
  @include absolute($top: 0, $bottom: 0, $left: $left);
  background-color: rgb(240, 243, 248);
  min-height: 100vh;
  width: calc(100vw - 250px);
}

.main-inspection {
  // overflow: auto;
  // height: 100%;
  width: 100%;
  .ant-tabs-content {
    position: unset !important;
    height: 100%;
    .ant-tabs-tabpane {
      height: 100%;
      .ant-form {
        height: 100%;
      }
    }
  }
  .dynamicBasePage {
    height: 100%;
    padding: 0px !important;
    .allUserConfiguration .allUsersHeader {
      flex-wrap: wrap !important;
      gap: 5px;
    }
    .ag-status-bar {
      .ant-form {
        height: unset !important;
      }
    }
    @media only screen and (max-width: 460px) {
      .dynamicBasePage {
        width: calc(100% - 60px);
        margin-left: 0px;
        margin-top: 110px;
        height: calc(100vh - 110px);
      }
    }

    @media (max-width: 821px) {
      .statusSquircleContainer {
        min-width: 90px;
      }

      .dynamicBasePage .filterContainers {
        display: block !important;
        height: 80px !important;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-right: 5px;
        padding-left: 5px;
        overflow: hidden;
      }
      .statusesPanelContainer {
        height: auto;
      }
      .dynamicBasePage
        .filterContainers
        .filterContainerRight
        .ant-select-selector {
        width: 100px;
      }
      .approvalsPageControlPanel {
        justify-content: center !important;
        flex-wrap: wrap !important;
        gap: 5px !important;
        padding-top: 6px !important;
      }
      .approvalsPageControlPanel .searchBar {
        min-width: 100px !important;
        max-width: 100px !important;
      }
      .tabContainer .titleComp {
        top: 42px !important;
        left: 262px !important;
      }
      .dashboardHeader {
        margin-top: 10px;
      }
      .leadModalContainer .leadModalBodyContainer {
        display: block !important;
      }
      .opportunityModalContainer .opportunityModalBodyContainer {
        display: block !important;
      }
      .estimationModalContainer .modalBodyContainer {
        display: block !important;
      }
      .normalSizedModal .ant-modal-content {
        width: 100%;
      }
      .contactModalContainer .contactModalBodyContainer {
        display: block !important;
      }
      .overViewStepContainer .mainContainer {
        align-items: flex-start;
      }
      .overViewStepContainer .mainGrid {
        display: block;
      }
      .dobStepContainer .controlPanel {
        display: block;
      }
      .clientModalContainer .clientModalBodyContainer {
        display: block;
      }
      .ant-modal-wrap.basePageModal.newInspectionModal.ant-modal-centered {
        text-align: flex-start;
      }
      .newViolationModalContainer .inputContainer {
        width: 100% !important;
      }
      .newDispatchModalContainer .mainContainer {
        display: block;
      }
      .newDispatchModalContainer .routeCard {
        width: 84% !important;
        min-width: 40% !important;
      }
      .labeledInputContainer {
        min-width: 134px;
        max-width: 270px;
      }
      .newDispatchModalContainer .inputContainer {
        width: 96%;
      }
      .newDispatchModalContainer .filtersCard {
        min-width: 77%;
      }
      .contactModalContainer .editorsContainer {
        display: block;
      }
    }

    @media only screen and (max-width: 768px) {
      .dynamicBasePage {
        width: calc(100% - 100px);
        margin-left: 100px;
        margin-top: 110px;
        height: calc(100vh - 110px);
        .filterContainers {
          padding: 15px;
        }
      }
    }

    @media only screen and (max-width: 414px) {
      .dynamicBasePage {
        width: calc(100% - 60px);
        margin-left: 60px;
        margin-top: 110px;
        height: calc(100vh - 110px);
        .filterContainers {
          padding: 10px;
        }
      }
    }
    @media screen and (min-device-width: 1000px) and (max-device-width: 1181px) and (orientation: landscape) {
      .leadModalContainer .leadModalBodyContainer {
        display: block;
      }
      .dobTabTypeCard .labelContainer {
        font-size: initial;
      }
      .statusesPanelContainer .statusesPanelContent {
        flex-wrap: wrap;
      }
      .estimationModalContainer .modalBodyContainer {
        display: block;
      }
      .contactModalContainer .contactModalBodyContainer {
        display: block;
      }
      .contactModalContainer .ant-modal {
        width: 100% !important;
      }
      .clientModalContainer .clientModalBodyContainer {
        display: block;
      }
      .approvalsPageControlPanel {
        justify-content: center !important;
        flex-wrap: wrap !important;
        gap: 5px !important;
        padding-top: 6px !important;
      }
      .newDispatchModalContainer .mainContainer {
        display: block;
      }
      .newDispatchModalContainer .routeCardsContainer {
        height: 288px;
        overflow: hidden;
      }
      .reportsCategoriesView .categoryCreatorSection {
        flex-wrap: wrap;
      }
      .normalSizedModal .ant-modal-content {
        width: auto !important;
      }
      .allUserConfiguration .allUsersHeader {
        flex-wrap: wrap !important;
        gap: 5px;
      }
    }
  }
  .ant-card-body {
    padding: 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: 100%;
    width: 100%;
    .mainCardd {
      height: 100%;
      width: 100%;
      .filters {
        display: flex;

        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 10px;
        gap: 10px;
        @media screen and (max-width: 768px) {
          display: flex;
          justify-content: center !important;
          align-items: center;
        }

        @media screen and (max-width: 414px) {
          justify-content: center;
          width: auto;
        }
        .filter {
          // display: block;
          flex-wrap: inherit;
          @media screen and (max-width: 560px) {
            justify-content: center;
            align-items: center;
          }

          .title {
            font-family: Open Sans;
            font-weight: 600;
            font-size: 16px;
            margin-left: 10px;
          }
          .inputselect {
            width: 255px;
            height: 32px;
          }
        }
      }
      .row2 {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        flex-grow: 1;
        flex-basis: 0;
        gap: 10px;

        .bar-chart {
          position: relative;
          width: 100%;
          height: 40vh;
          max-width: 520px;
          max-height: 305px;

          @media screen and (max-width: 850px) {
            max-width: 400px;
            max-height: 300px;
            .weekNames {
              margin: 10px !important;
              display: flex;
              justify-content: center;
            }
          }

          @media screen and (max-width: 560px) {
            max-width: 300px;
            max-height: 300px;
            justify-content: center;
            align-items: center;
          }
        }

        .charts {
          width: 100%;
          height: 100%;
          max-width: 520px;
        }
        .doughnut {
          width: 400px;
          height: 400px;
          max-width: 520px;
          max-height: 400px;
          margin-top: -10px;
          @media screen and (max-width: 768px) {
            max-width: 340px;
            max-height: 340px;
          }
          @media screen and (max-width: 560px) {
            max-width: 250px;
            max-height: 250px;
            padding: 0px 0px 0px 0px;
          }

          .DoughnutChart {
            width: 100% !important;
            height: 100% !important;
          }
        }
        .circle {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          padding: 40px 0px 0 0;
          @media screen and (max-width: 560px) {
            max-width: 100%;
            max-height: 100%;
            // display: flex;
            justify-content: center;
            align-items: center;
          }

          .CircleProgressBarStatus {
            text-align: center;
            font-weight: bold;
            font-size: 16px;
            margin: -100px 30px 30px 30px;
            .ant-progress-text {
              font-size: 42px;
              color: #fff;
            }
          }
          .info {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: center;
            font-family: "Open Sans";
            font-size: 16px;
            font-weight: 400;
          }
        }
        .rangepicker {
          width: 100%;
          display: flex;
          justify-content: center;
          margin-left: 10px;
        }
      }
    }
  }
  // }

  .weekContainer {
    margin: 20px 20px 0px 20px;
    border: 0px;
  }

  .weekContainer2 {
    margin: 0 0px 0px 30px;
    @media screen and (max-width: 850px) {
      margin: 0px 20px;
    }

    @media screen and (max-width: 560px) {
      max-width: 250px;
      max-height: 250px;
      padding: 0px 0px 0px 0px;
    }
  }

  .datePickerHeader {
    margin-top: 20px;
  }

  .weekContainer2::after {
    border: 0px dashed #707070;
    margin: 0px;
  }
  .calendar-container {
    width: 100%;
    height: 100%;
    box-shadow: none;
    background: #f5f5f7;
    border-radius: 10px;
    .header {
      display: flex;
      justify-content: space-between;
    }
  }
  .weekNames {
    margin: 10px 20px 0px 20px;
    display: flex;
    justify-content: center;
    @media screen and (max-width: 850px) {
      margin: 10px !important;
      display: flex;
      justify-content: center;
      .title-overview-inspection {
        display: none;
      }
    }
    @media screen and (max-width: 560px) {
      margin: 5px !important;
      display: flex;
      justify-content: center;
    }
  }

  .progressBodyContainer {
    padding-right: 0px;
    padding-top: 10px;
  }
  .title-overview-inspection {
    top: 40px;
    position: absolute;
    z-index: 100;
    font: normal normal bold 24px Open Sans;
    letter-spacing: 0px;
    color: #323338;
    opacity: 1;
    top: 25px;
    left: 285px;
  }
  @media only screen and (max-width: 460px) {
    .dynamicBasePage {
      width: 100%;
      margin-left: 0px;
      margin-top: 10px;
      // height: calc(100vh - 110px);
    }
    .title-overview-inspection {
      display: none;
    }
  }

  path#Path_8110 {
    fill: black;
  }
}

.inspectionViewBreadcrumb {
  position: fixed;
  top: 60px;
  left: 20px;
  .cursorPointer {
    cursor: pointer;
  }

  @media (min-width: 570px) {
    left: 250px;
  }
}

.makeCard {
  border-radius: var(--default-card-border-radius);
  box-shadow: var(--default-box-shadow);
  background-color: var(--default-card-bg-color);
  padding: 20px;
  .ant-picker {
    border: none;
  }
}

.inspectionPageWrapperWithHeader {
  @include page(250px);
}

.inspectionPageContainer {
  @include page();
  padding: 100px 55px 0;
}

.inspectionTableContainer {
  overflow: auto;
}

.servicesInspectedCell {
  @include flex(center, center);
  @include square(25px, 25px, 5px, #1065a4);
  color: #fff;
  // box-shadow: 2px 2px 2px #00000012;
  border: 1px solid #1065a4;
  margin: 0 auto;

  cursor: pointer;
}

.inspectionGalleryCell {
  @include flex(center, center);
  @include square(45px, 25px, 5px, #1065a4);
  color: #fff;
  // box-shadow: 2px 2px 2px #00000012;
  border: 1px solid #1065a4;
  margin: 0 auto;

  cursor: pointer;
}
.totalHours-unordered-list {
  list-style: none;
  padding: 0;
  margin: 0;
  li {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    gap: 1em;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    color: #000;
    margin-bottom: 5px;
    p {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
    }
    .bold {
      font-weight: 600;
    }
  }
}
.servicesInspectedCell:hover {
  opacity: 0.8;
}

.statusCell {
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 0 !important;
}

.actionCell {
  // @include flex(center, unset);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.disabledActionButton {
  cursor: not-allowed;
  background-color: rgba($color: #1a3a6a, $alpha: 0.4) !important;
}

.actionButton {
  cursor: pointer;
  margin: 0 auto;

  img {
    @include flex(center, center);
    @include square(30px, 30px, 5px, #1065a4, 1);
    object-fit: contain;
  }
}

.labeledInputCard {
  @extend .makeCard;
  padding: 0;
  flex: 1;
  overflow: hidden;
  width: 100%;

  .labeledInputContainer {
    height: 139px;
    padding: 20px;
  }
  .ant-input {
    height: unset !important;
  }

  .labeledInputLabel {
    @include flex(center, flex-start);
    @include text(18px, 600, 0, #323338, capitalize);
    background-color: #f7f8fa;
    width: inherit;
    margin: -20px -20px 30px;
    padding: 0 20px;
    height: 45px;
  }
}

.inspectionProjectDropdownTag {
  @include flex(center, space-between);

  .servicesTag {
    padding: 2px 10px;
    color: #fff;
    border-radius: 5px;
    background-color: #1264a3;
  }
}

.actionButtonsContainer {
  @include flex(center, space-between);
  margin-left: 10px;
  padding: 0 5px;
  gap: 10px;

  img {
    width: 25px;
    height: 25px;
  }
}

.forceToInspectModalTitle {
  @include text(22px, 500, 0.45px, #383554);
}

// .dispatchCheckboxContainer {
//   border: 0.5px solid #383554;
//   border-radius: 3px;
//   width: 100%;

//   .ant-input-search-button {
//     @include flex(center, center);
//     height: 32px;
//     border-left: none;
//   }
// }

// .dispatchSelectedListContainer {
//   @include flex(center, flex-start);
//   width: 100%;
//   height: 40px;
// }

// .dispatchSelectedListArea {
//   @include flex(center, flex-start);
//   overflow: hidden;
//   overflow-x: scroll;
//   width: calc(100% - 60px);
// }

// .dispatchSelectedListArea::-webkit-scrollbar {
//   display: none;
// }

// .dispatchSelectedItem {
//   @include flex(center, space-between);
//   @include text(12px, 500, 0.24px, #5b6476);
//   padding: 3px 5px;
//   background-color: #e6e7e7;
//   border-radius: 3px;
//   margin-left: 10px;

//   .MuiSvgIcon-root {
//     font-size: 10px;
//     margin-left: 7px;
//     cursor: pointer;
//   }
// }

// .dispatchSelectedListControl {
//   @include flex(center, center);
//   @include absolute($right: 35px);

//   .MuiSvgIcon-root {
//     font-size: 12px;
//     cursor: pointer;
//   }
// }

// .dispatchSelectedListCount {
//   @include flex(center, center);
//   @include text(16px, 500, 0.24px, #fff);
//   margin-left: 5px;
//   background-color: #5b6476;
//   border-radius: 3px;
//   min-width: 20px;
//   padding: 0 3px;
//   user-select: none;
// }

// .dispatchCheckboxArea {
//   @include flex(flex-start, flex-start, column);
//   padding-left: 20px;
//   padding-bottom: 20px;

//   .ant-checkbox-wrapper {
//     @include text(14px, 500, 0.3px, #5b6476);
//     padding: 5px 0;
//   }

//   .ant-checkbox-group {
//     @include flex(flex-start, flex-start, column);
//   }
// }

.inspectionViewPageContainer {
  @include flex(center, flex-start, column);
  width: 100%;
  height: 100%;
  background-color: #f4f5f6;

  .stepperContainer {
    border-radius: var(--default-card-border-radius);
    width: 100%;
    background-color: var(--default-card-bg-color);
    padding: 20px;
    margin-bottom: 0 !important;
    box-shadow: var(--default-box-shadow);
    cursor: default;
  }

  .ant-input,
  .ant-picker,
  .ant-select-selector {
    border-radius: 5px;
  }
  .inspectionContentWrapper {
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .inspection-map {
    width: 100%;
    height: 200px;
    padding: 10px;
    background-color: var(--default-card-bg-color);
    box-shadow: var(--default-box-shadow);
    border-radius: var(--default-card-border-radius);
  }
  .inspectionViewServicesMenu {
    margin-right: 0 !important;
    box-shadow: var(--default-box-shadow) !important;
  }
  .inspection-teams {
    .CardViewComponent {
      margin: 0 !important;
    }
  }
  .serviceCard {
    background-color: var(--default-card-bg-color);
    box-shadow: var(--default-box-shadow);
    border-radius: var(--default-card-border-radius);

    .questionaryServiceName {
      border-top-left-radius: var(--default-card-border-radius);
      border-top-right-radius: var(--default-card-border-radius);
    }
    .questionaryCardBody:last-child {
      border-bottom-left-radius: var(--default-card-border-radius);
      border-bottom-right-radius: var(--default-card-border-radius);
    }
  }
}

.inspectionViewPageContainerDark {
  background-color: $mainDarkBackgroundColor;
  .stepperContainer {
    background-color: $cardDarkBackgroundColor;
    .ant-steps {
      .ant-steps-item-finish ::before,
      .ant-steps-item-active::before,
      .ant-steps-item::before {
        border-left-color: $cardDarkBackgroundColor;
      }
      .ant-steps-item-wait {
        background-color: $cardDarkHeaderColor;
        &::after {
          border-left-color: $cardDarkHeaderColor;
        }
        .ant-steps-item-content .ant-steps-item-title {
          color: #acacca;
        }
      }
    }
  }
  .inspectionViewHeader {
    background-color: $cardDarkBackgroundColor;
    span {
      color: #fff;
    }
    .ant-picker {
      background-color: $inputDarkColor !important;
      .ant-picker-input {
        background-color: $inputDarkColor;
        input {
          color: #f2f2f2;
          &::placeholder {
            color: #acacca;
          }
        }
        .ant-picker-suffix svg path {
          fill: #acacca !important;
        }
        .ant-picker-clear {
          background-color: $inputDarkColor;
          svg {
            fill: #acacca;
          }
        }
      }
    }
  }
  .inspection-map {
    background-color: $cardDarkBackgroundColor;
  }
  .core-information {
    .labeledInputCard {
      background-color: $cardDarkBackgroundColor !important;
      .labeledInputContainer {
        background-color: $cardDarkBackgroundColor;
        .labeledInputLabel {
          background-color: $cardDarkHeaderColor;
          color: #fff;
        }
        .ant-input {
          background-color: $inputDarkColor !important;
          color: #fff;
          &::placeholder {
            color: #acacca;
          }
        }
      }
    }
  }
  .inspectionViewServicesMenu {
    background-color: $cardDarkBackgroundColor;
    .header {
      background-color: $cardDarkHeaderColor;
      color: #fff;
    }
    .bodyContent {
      background-color: $cardDarkBackgroundColor;
      .servicesMenuItem {
        .serviceContainer {
          background-color: $inputDarkColor !important;
          .serviceType,
          .questionsCategoryContainer {
            color: #fff;
          }
        }
      }
    }
  }
  .serviceCard {
    background-color: $cardDarkBackgroundColor;
    border-radius: var(--default-card-border-radius);
    .questionaryCardContent {
      .questionaryCardBody {
        background-color: $cardDarkBackgroundColor;
        .inputQuestionsSection {
          .labeledInputLabel {
            color: #fff;
          }
          .ant-input {
            background-color: $inputDarkColor !important;
            color: #fff;
            &::placeholder {
              color: #acacca;
            }
          }
          .ant-picker {
            background-color: $inputDarkColor !important;
            .ant-picker-input {
              background-color: $inputDarkColor;
              input {
                color: #f2f2f2;
                &::placeholder {
                  color: #acacca;
                }
              }
              .ant-picker-suffix svg path {
                fill: #acacca;
              }
              .ant-picker-clear {
                background-color: $inputDarkColor;
                svg {
                  fill: #acacca;
                }
              }
            }
          }
        }
        .selectionQuestionsSection {
          .labeledInputLabel {
            color: #fff;
          }
          .ant-radio-wrapper {
            color: #fff;
          }
          .questionText {
            color: #fff;
          }
        }
      }
    }
    .questionaryCardContent:not(:last-child) {
      border-bottom: 20px solid $cardDarkBackgroundColor !important;
    }
  }
  .inspectionSignContainer {
    background-color: $cardDarkBackgroundColor;
    .labeledInputLabel {
      color: #fff;
    }
    .ant-input {
      background-color: $inputDarkColor !important;
      color: #fff;
      &::placeholder {
        color: #acacca;
      }
    }
    .signContainer {
      padding: 5px;
    }
    .ant-btn:disabled {
      background-color: $mainDarkBackgroundColor;
      color: #acacca;
      border: 1px solid #acacca;
    }
  }
}

.yellowAbsoluteBackground {
  @include absolute(
    $position: fixed,
    $top: 200px,
    $bottom: 100px,
    $left: 250px
  );
  display: none;
  background-color: #fadb7f;
  width: calc(100% - 260px);
  z-index: 0;
  pointer-events: none;
}

.inspectionViewHeader {
  position: relative;
  @include flex(center, space-between);
  @include text(22px, 700, 0, #323338, capitalize);
  width: 100%;

  .ant-picker {
    max-width: 200px;
    user-select: none;
  }
}

.inspectionStatusPASSED {
  color: #5cb85c !important;
}

.inspectionStatusDRAFT {
  color: #017aff !important;
}

.inspectionStatusFAILED {
  color: #f93a3a !important;
}

.inspectionSignContainer {
  @include flex(flex-start, space-between);
  @extend .makeCard;
  button {
    text-transform: capitalize;
  }
  margin: 0;
  margin-bottom: 10px;
  gap: 20px;

  .signControllers {
    @include flex(center, space-between);
    margin-bottom: 20px;
    .ant-btn {
      border-radius: 5px;
    }
  }

  .signContainer {
    width: 100%;
    height: 150px;
    position: relative;
    overflow: hidden;
    background-color: #fff;
    img {
      object-fit: contain;
      width: 100%;
      height: calc((70vw - 250px) / 3);
      position: absolute;
      top: calc((70vw - 250px) / -10);

      @media (max-width: 1600px) {
        height: calc((70vw - 200px) / 3);
        position: absolute;
        top: calc((70vw - 250px) / -12);
      }
    }
  }
}
.inspectionSignCanvas {
  width: 100%;
  height: 200px;
}

.inspectionFooterController {
  width: calc(100% + 20px);
  padding: 16px 70px 16px 16px !important;
  bottom: -20px;
  background-color: #1f2a44;
  position: sticky;
  opacity: 0.95;
  z-index: 98;
  @include flex(center, flex-end);
  button {
    margin: 0 5px;
    border-radius: 6px;
    text-transform: capitalize;
  }
  .tourButton {
    .mondayButtonText,
    .mondayButtonDivider {
      display: none;
    }
  }
}

.questionaryServiceName {
  @include text(18px, 600, 0, #383554);
  @include flex(center, flex-start);
  margin: 0 !important;
  padding: 12px 20px;
  background-color: #f7f8fa;
}

.questionaryCardHeader {
  @include text(14px, 700, 0, #383554, capitalize);
  @include flex(center, space-between);
  width: fit-content;
  min-width: 280px;
  margin: 20px 0;
  padding: 10px 20px;
  background: #f3f6ff;
  border: none;
  border-radius: 0px 5px 5px 0px;
  cursor: pointer;
  user-select: none;
  height: 28px;
}

.questionaryCardContent {
  &:not(:last-child) {
    border-bottom: 20px solid #f7f8fa;
  }
}

.questionaryCardBody {
  @include flex(flex-start, flex-start);
  flex-wrap: wrap;
  background: #ffffff 0% 0% no-repeat padding-box;
  // border: 0.05px solid #383554;
  // border-top: none;
  // border-radius: 0px 0px 10px 10px;
  padding: 0 30px;

  overflow: hidden;

  transition: height 0.2s ease-in-out, padding-top 0.2s ease-in-out,
    padding-bottom 0.2s ease-in-out;
}

.inputQuestionsSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  .labeledInputContainer {
    max-width: 250px;
    margin: 5px;
  }
}

@mixin questionaryBooleanButton($background, $color: #fff) {
  @include text(16px, 500, 0.23px, $color, capitalize, $important: true);
  background: $background !important;
  border-radius: 4px !important;
  min-width: 90px;
  margin-right: 20px;
  border-color: $background !important;
  text-align: center;
}

.questionContainer {
  margin-bottom: 15px;

  .ant-radio-button-wrapper {
    @include questionaryBooleanButton(#e6e6e6, #383554);
  }

  .ant-radio-button-wrapper:not(:first-child)::before {
    width: 0 !important;
  }

  .ant-radio-button-wrapper-checked {
    @include questionaryBooleanButton(#71cf48);
  }

  .ant-radio-button-wrapper-checked:not(:first-child) {
    @include questionaryBooleanButton(#fe4c4a);
  }
}

.questionText {
  @include flex(center, unset);
  @include text(16px, 400, 0.3px, #383554);

  img {
    margin-left: 20px;
  }
}

.booleanButtonsContainer {
  margin-top: 10px;
  margin-bottom: 10px;
}

.thumbnailsContainer {
  margin-top: 20px;
  margin-left: -5px;
  display: flex;
}

.questionThumbnail {
  width: 65px;
  height: 65px;
  object-fit: cover;
  cursor: pointer;
  border: 1px solid #707070;
  border-radius: 5px;
  margin: 0 5px;
}

.carouselModal {
  .ant-modal-content {
    border-radius: 7px;
    overflow: hidden;
    height: 100vh;
    min-width: 80vw;
    width: fit-content;
    @include absolute($position: fixed, $top: 0, $left: 10vw);
  }

  .ant-modal-body {
    padding: 100px 20px;
    background-color: #ffffff;
    height: 100vh;
    @include flex(flex-start, center);

    .carouselPanel {
      height: 100%;
      @include flex(flex-start, flex-start, column);
      width: fit-content;
      overflow-y: auto;
    }

    .carouselThumb {
      border: 0.5px solid #707070;
      width: 130px;
      min-height: 90px;
      border-radius: 5px;
      overflow: hidden;
      margin: 10px 0;
      cursor: pointer;
      object-fit: cover;
      opacity: 0.5;
    }

    .carouselThumb:hover {
      opacity: 1;
    }

    .carouselCurrentImage {
      @include square(auto, 100%, 8px, transparent);
      max-width: 70vw;
      object-fit: contain;
      margin-right: 30px;
    }
  }
}

.newInspectionModal {
  .ant-modal-header {
    display: flex;
    justify-content: flex-start;
  }
  .ant-modal-title {
    width: 99%;
  }
  .ant-modal-body {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    min-width: 750px;
    // max-width: 700px;
    .teams {
      max-width: 410px;
      width: 100%;
      .ant-select-selector {
        width: 100%;
        height: fit-content;
      }
    }

    .executive-date-wrapper {
      display: flex;
      gap: 10px;

      .labeledInputContainer {
        .labeled-qb-input {
          .qb-input {
            width: 100%;
            overflow: hidden;
            padding: 0;

            :nth-child(1) {
              max-width: 150px !important;
            }
            :nth-child(2) {
              max-width: 20px !important;
            }

            .ant-form-item {
              width: 100%;
              max-width: 150px;
              // border-radius: 0px !important;
              .ant-row {
                // border-radius: 0px !important;
                .ant-col {
                  // border-radius: 0px !important;
                  .ant-form-item-control-input {
                    // border-radius: 0px !important;
                    .ant-form-item-control-input-content {
                      // border-radius: 0px !important;
                      .ant-input {
                        border-radius: 0px !important;
                      }
                      .ant-picker {
                        border-radius: 0px !important;
                        max-width: 20px;
                      }
                    }
                  }
                }
              }
            }
          }

          .qb-error {
            z-index: 9999999;
          }
        }
      }
    }
    .projectExecutive {
      max-width: 200px;
      .ant-select-selector {
        width: 100%;
        height: 36px;
      }
    }
    .labeledInputContainer {
      min-width: 210px;

      .ant-input {
        height: 36px;
        width: 200px;
      }

      .ant-select-selector {
        min-height: 36px;
      }

      .ant-picker {
        width: 200px;
        height: 36px;
      }
    }
  }
}

.newInspCancelAlert {
  padding-bottom: 50px !important;

  .ant-alert-action {
    @include absolute($bottom: 15px, $right: 15px);
  }
}

.newInspNextContainer {
  width: 100%;
  overflow: hidden;

  .ant-table {
    margin-left: -1px;
  }

  .ant-table-cell {
    text-align: center;
    border-left: 1px solid #93bff5;
  }

  .ant-table-thead {
    .ant-table-cell {
      @include text(16px, 600, 0.18px, #383554, capitalize);
      background-color: #c7e0ff;
    }
  }

  .ant-table-tbody {
    .ant-table-cell {
      border-top: 1px solid #93bff5;
    }
  }

  .ant-checkbox-wrapper {
    margin-right: 10px;
    .ant-checkbox-inner {
      width: 18px;
      height: 18px;
    }
  }
}

.newInspNextTitle {
  @include text(24px, 700, 0, #383554, capitalize);
  margin-bottom: 15px;
}

.coloredRow {
  background-color: #dcecfe;
}

.serviceDropdownCheckboxContainer {
  @include flex(center, unset);
}

.serviceCardContainer {
  width: 100%;
  min-width: 16rem;
  @include flex(center, space-between);
  background-color: #f6f9fc;
  padding: 15px 20px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  margin-bottom: 3px;
  cursor: pointer;
}
.serviceCardTitle {
  @include text(18px, 600, 0.24px, #383554, capitalize);
}

.elevationCardContainer {
  width: 100%;
  @include flex(center, space-between);
  background-color: #dfe6ee;
  padding: 12px 20px;
  margin-bottom: 3px;
  cursor: pointer;
}
.elevationCardTitle {
  @include text(16px, 400, 0, #383554, capitalize);
}

@media (max-width: 1712px) {
  .inspectionViewPageContainer .inspectionContentWrapper {
    height: 100%;
  }
}

@media only screen and (max-width: 1270px) {
  .inspectionSignContainer .signContainer img {
    height: 150px;
    width: 150px;
  }
}

@media (min-width: 1060px) {
  .inspectionViewPageContainer {
    .inspectionFooterController {
      justify-content: flex-end;
    }
  }
}

@media (max-width: 1060px) {
  .inspectionViewPageContainer {
    .inspectionFooterController {
      justify-content: flex-start;
      overflow-x: auto;
    }
  }
}

@media (max-width: 900px) {
  .inspectionViewPageContainer {
    .inspectionViewHeader {
      font-size: 18px;
      flex-direction: column;
      .ant-picker {
        margin-top: 5px;
      }
    }
  }
}

@media only screen and (max-width: 769px) {
  .serviceDropdownCheckboxContainer {
    width: 2rem;
  }
}

@media only screen and (max-width: 767px) {
  .inspectionViewPageContainer {
    padding-bottom: 72px;
    .inspectionFooterController {
      bottom: 60px;
    }
  }
}

@media only screen and (max-width: 450px) {
  .inspectionViewPageContainer {
    .inspectionViewHeader {
      font-size: 16px;
      padding: 10px;
    }
    .inspectionFooterController {
      padding: 15px 70px 15px 10px !important;
    }
  }
  .newInspectionModal .ant-modal-body {
    grid-template-columns: 100% !important;
    display: grid !important;
    min-width: 100% !important;
    // width: 100% !important;
    width: 95vw !important;
    max-height: calc(100vh - 150px);
    overflow: scroll;
    .executive-date-wrapper {
      flex-direction: column;
      gap: 10px;
      #newInspectionProjectExecutive {
        width: 100% !important;
      }
      .ant-picker {
        width: 100% !important;
      }
    }
  }
  .newInspectionModal .ant-modal-body .labeledInputContainer {
    min-width: 100% !important;
    width: 100% !important;
  }
  .serviceDropdownCheckboxContainer {
    width: 2rem;
  }
}

@media only screen and (max-width: 414px) {
  .inspectionViewPageContainer {
    padding-bottom: 40px;
    gap: 15px;

    .inspectionFooterController {
      width: calc(100% + 40px);
    }
  }
}

@media only screen and (max-width: 391px) {
  .inspectionFooterController {
    .ant-btn {
      padding: 5px;
    }
  }
  .inspectionViewServicesMenu {
    min-width: 255px !important;
  }
  .labeledInputCard {
    margin-right: 0px !important;
  }
}
.inspectedServiceName {
  @include text(16px, 400, 0.41px, #636078);
  display: flex;
  align-items: center;
  span {
    margin-left: 20px;
  }

  .questions-status-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-left: 20px;
    flex: 1;

    p {
      margin: 0px;
    }

    .status-section {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }
}
