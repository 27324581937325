.membersContent {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-top: 10px;
  padding-bottom: 10px;
  .columnTitleStyle {
    text-align: center;
    font: normal normal normal 20px/27px Open Sans;
    letter-spacing: 0px;
    color: #323338;
    opacity: 1;
    margin-bottom: 10px;
  }
  .columnContentContainer {
    display: flex;
    justify-content: flex-start;
    .InitialsContainer {
      border-radius: 50%;
      height: 65px;
      width: 65px;
      margin: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      .initialsStyle {
        text-align: left;
        font: normal normal 600 35px/47px Open Sans;
        letter-spacing: 0px;
        color: #ffffff;
        opacity: 1;
      }
    }
    .memberDetailsContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 10px;
      .nameStyle {
        text-align: left;
        font: normal normal 600 16px/22px Open Sans;
        letter-spacing: 0px;
        color: #1264a3;
        opacity: 1;
      }
      .roleStyle {
        text-align: left;
        font: normal 600 normal 16px/22px Open Sans;
        letter-spacing: 0px;
        color: #323338;
        opacity: 1;
      }
    }
  }
}
.currentUserStyleContainer {
  width: 70px;
  height: 28px;
  background: #5270e0 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
  margin-top: -16px;
  margin-left: 4px;
  .currentUserStyleText {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    font: normal normal normal 17px/23px Open Sans;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
  }
}
