@import "../../../../../../../../../index.scss";

.docOptionsContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 10px;
  .optionField {
    // height: 68px !important;
    display: flex;
    // align-items: flex-end;
    gap: 10px;
    .labeledInputContainer {
      width: 100%;
      max-width: 300px;
      height: 65px;
      margin: 0 !important;
    }
  }
  .fieldOptionButton {
    @include flex(center, center);
    height: 30px;
    padding: 5px;
    width: 30px;
    fill: #fff;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    align-self: flex-end;
  }

  .closeSvgIcon {
    width: 18px;
  }

  .added {
    background-color: #ff4d4f;
    &:hover {
      background-color: #eb4345;
    }
  }

  .notAdded {
    background-color: #71cf48;
    &:hover {
      background-color: #68c142;
    }
  }
}
