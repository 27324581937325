$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.payroll-deg-modal-wrap {
  overflow: hidden !important;
}

.payroll-deg-modal-container {
  width: fit-content !important;
  max-width: 100vw !important;
  max-width: 100dvw !important;
  top: 0px !important;
  padding: 0px !important;
  z-index: 99999 !important;
  .ant-modal-content {
    width: fit-content;
    height: fit-content;
    max-width: 100vw;
    max-height: 100dvw;
    .ant-modal-header {
      border-radius: 10px 10px 0 0;
      background-color: #f5f5f7;
      display: flex;
      align-items: center;
      height: 45px;

      .ant-modal-title {
        color: #323338;
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        width: 100%;
      }
    }

    .ant-modal-close {
      height: 45px;
      aspect-ratio: 1;
    }

    .ant-modal-body {
      width: 100%;
      height: fit-content;
      max-height: 95vh;
      max-height: 95dvh;
      overflow: hidden;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      flex-wrap: nowrap;
      gap: 0.5rem;

      .upload-step {
        height: fit-content;
        width: 800px;
        min-height: 20dvh;
        max-height: 80dvh;
        max-width: 95dvw;
        // height: 80dvh;
        // width: 95dvw;
        .upload-section {
          .ant-upload .ant-upload-text {
            font-size: 14px;
          }
        }
        .labeledInputContainer {
          width: 100%;
          .ant-form-item {
            margin-bottom: 0px;
          }
        }
      }

      .deg-step {
        height: calc(100dvh - 183px);
        width: 100vw;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        flex-wrap: nowrap;
        padding: 0.5rem;
        .deg-actions {
          height: 32px;
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .headerFiler {
            height: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-direction: row;
            gap: 10px;
            .mondayButtonRed {
              margin-left: 10px;
            }
            .labeledInputContainer {
              .labeledInputLabel,
              .ant-form-item {
                margin-bottom: 0px !important;
              }
            }
            .ant-input-affix-wrapper {
              height: 32px;
              min-width: 200px;
            }
            .ant-input-group-addon {
              .ant-select {
                min-width: 170px;
                border-left: 1px solid #d9d9d9;

                .ant-select-selector {
                  min-width: 170px;
                }
              }
            }
          }

          .control-buttons {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: row;
            flex-wrap: wrap;
            align-self: baseline;
            gap: 1rem;
            margin-left: 10px;
            .grid-actions {
              position: relative;
              display: flex;
              flex-direction: column;
              // align-self: flex-end;
              gap: 5px;
              // margin-top: 5px;
              z-index: 5;
              .cancelActions {
                background-color: gray;
              }
              .action-buttons {
                display: flex;
                flex-direction: column;
                gap: 5px;
                animation: showGridActions 0.3s ease-out;
              }
              .hide-buttons {
                animation: hideGridActions 0.3s ease-out;
              }
            }
            .ant-form {
              padding: 0px;
              .labeledInputContainer {
                .labeledInputLabel {
                  display: none;
                }
                .ant-form-item {
                  margin: 0px;
                  .ant-input {
                    height: 32px;
                  }
                }
              }
            }

            button {
              &:disabled {
                svg {
                  fill: #fff;
                }
                background-color: grey !important;
                .mondayButtonDivider {
                  background-color: transparent !important;
                }
              }
              .mondayButtonText {
                display: flex;
                align-items: center;
                height: 100%;
              }
            }
            .mondayButtonText {
              display: flex;
              align-items: center;
              height: 100%;
            }
          }
        }
        .deg-grid-container {
          height: 100%;
          width: 100%;

          .ag-root-wrapper-body {
            height: calc(100% - 42px - 2rem) !important;
          }
        }
      }

      .ant-form {
        height: fit-content;
        max-height: calc(95dvh - 150px);
        overflow-y: auto;
        overflow-x: hidden;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 1rem;
        padding: 1rem 0;
      }
      .FilterModalComponent {
        .ant-form {
          flex-direction: column;
          flex-wrap: nowrap;
          padding: 0px;
          .FilterModalComponentHeader,
          .FilterModalComponentFooter {
            width: 100%;
          }
        }
      }
      .gridDropdownSelect {
        .ant-select-selector {
          border-radius: 0px;
        }
      }
    }

    .ant-modal-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      flex-wrap: nowrap;
      border-radius: 10px;
      padding: 10px 20px;
      button {
        min-width: 150px;
      }
    }
  }
}

.shiftsBreakWarning {
  .ant-modal {
    width: 1000px !important;
  }
}

.payroll-edit-line-modal {
  height: fit-content !important;
  width: fit-content !important;
  .ant-modal-content {
    height: fit-content;
    max-height: 80dvh;
    width: 1020px;
    max-width: 90dvw;

    .ant-modal-header {
      border-radius: 10px 10px 0 0;
      background-color: #f5f5f7;
      display: flex;
      align-items: center;
      height: 45px;

      .ant-modal-title {
        color: #323338;
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        width: 100%;
      }
    }

    .ant-modal-close {
      height: 45px;
      aspect-ratio: 1;
    }

    .ant-modal-body {
      height: calc(80dvh - 120px);
      overflow: auto;

      .ant-form {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 1rem;
      }
      .gridDropdownSelect {
        .ant-select-arrow svg {
          fill: #fff !important;
        }
      }
    }

    .ant-modal-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      flex-wrap: nowrap;
      border-radius: 10px;
      button {
        width: 150px;
      }
    }
  }
}

.payroll-deg-modal-container-dark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      .deg-stepper {
        .ant-steps {
          .ant-steps-item-finish ::before,
          .ant-steps-item-active::before,
          .ant-steps-item::before {
            border-left-color: $cardModalBodyDark;
          }
          .ant-steps-item-wait {
            background-color: $cardModalHeaderDark;
            &::after {
              border-left-color: $cardModalHeaderDark;
            }
            .ant-steps-item-content .ant-steps-item-title {
              color: #acacca;
            }
          }
        }
      }
      .upload-step {
        .labeledInputContainer {
          .labeledInputLabel {
            color: #fff;
          }
          .ant-select {
            .ant-select-selector {
              background-color: $inputModalDark !important;
              .ant-select-selection-search-input {
                color: #fff;
              }
              .ant-select-selection-item {
                color: #fff;
              }
              .ant-select-selection-placeholder {
                color: #acacca;
              }
            }
            .ant-select-clear {
              background-color: $inputModalDark;
              svg {
                fill: #acacca;
              }
            }
            .ant-select-arrow svg {
              fill: #fff;
            }
          }
        }
        .upload-section {
          .ant-upload {
            border: 1px dashed #acacca;
            background-color: #2a2d41;
            .ant-upload-drag-icon span {
              color: #fff;
            }
            .ant-upload-text {
              color: #fff;
            }
          }
        }
      }
      .deg-actions {
        .labeledInputContainer .ant-input-wrapper {
          background-color: $inputModalDark !important;
          .ant-input-affix-wrapper {
            background-color: $inputModalDark !important;
          }
          .ant-input-prefix svg {
            fill: #fff;
          }
          .ant-input {
            background-color: $inputModalDark !important;
            color: #fff;
            &::placeholder {
              color: #acacca;
            }
          }
          .ant-input-group-addon {
            background-color: $inputModalDark !important;
            .ant-select {
              // background-color: $inputModalDark !important;
              border-left: 1px solid #acacca;
              .ant-select-selector {
                background-color: $inputModalDark !important;
                .ant-select-selection-search-input {
                  color: #fff;
                }
                .ant-select-selection-item {
                  color: #fff;
                }
                .ant-select-selection-placeholder {
                  color: #acacca;
                }
              }
              .ant-select-clear {
                background-color: $inputModalDark;
                svg {
                  fill: #acacca;
                }
              }
              .ant-select-arrow svg {
                fill: #fff;
              }
            }
            .ant-select-disabled {
              .ant-select-selector {
                .ant-select-selection-search-input {
                  color: #acacca !important;
                }
                .ant-select-selection-item {
                  color: #acacca !important;
                }
              }
            }
          }
        }
        .ant-input {
          background-color: $inputModalDark !important;
          color: #fff;
          &::placeholder {
            color: #acacca;
          }
        }
      }
      .overview-grid-container,
      .deg-grid-container {
        .ag-root-wrapper {
          background-color: $cardModalBodyDark;
          .ag-column-drop {
            background-color: $cardModalBodyDark;
          }
          .ag-header-row {
            border-top: none;
            .ag-header-cell {
              background-color: $cardModalBodyDark;
              .ag-header-cell-resize::after {
                width: 1px !important;
                background-color: #4a4a4a;
              }
            }
          }
          .ag-paging-panel {
            background-color: $cardModalBodyDark;
          }
        }
      }
    }
  }
}

@media (max-width: 690px) {
  .payroll-deg-modal-container {
    .ant-modal-content {
      .ant-modal-footer {
        button {
          width: auto !important;
        }
      }
    }
  }
  .payroll-edit-line-modal {
    .ant-modal-content {
      .ant-modal-footer {
        button {
          width: auto !important;
        }
      }
    }
  }
}
@media (max-width: 575px) {
  .payroll-deg-modal-container .ant-modal-content .deg-stepper {
    height: 120px !important;
    width: 100% !important;
    .ant-steps-navigation.ant-steps-vertical > .ant-steps-item {
      height: 35px !important;
    }
    .ant-steps-navigation.ant-steps-vertical
      > .ant-steps-item
      .ant-steps-item-content:last-child {
      min-height: 0px !important;
    }
    .ant-steps-navigation.ant-steps-vertical > .ant-steps-item:after {
      position: relative;
      top: -36px;
      left: 100%;
      transform: rotate(0deg);
    }
    .ant-steps-navigation.ant-steps-vertical
      > .ant-steps-item.ant-steps-item-active:before {
      display: none !important;
    }
    .ant-steps-navigation.ant-steps-vertical
      > .ant-steps-item:last-child:after {
      display: none !important;
    }
    .ant-steps-item {
      margin-bottom: 5px !important;
    }
  }
}

@media (max-width: 500px) {
  .payroll-deg-modal-container {
    .ant-modal-content {
      .ant-modal-footer {
        .mondayButtonText {
          display: none !important;
        }
        .mondayButtonDivider {
          display: none !important;
        }
        button {
          width: auto !important;
        }
      }
    }
  }

  .payroll-edit-line-modal {
    .ant-modal-content {
      .ant-modal-footer {
        .mondayButtonText {
          display: none !important;
        }
        .mondayButtonDivider {
          display: none !important;
        }
        button {
          width: auto !important;
        }
      }
    }
  }
}

@media (max-width: 450px) {
  .payroll-deg-modal-container {
    .ant-modal-content {
      .ant-modal-body {
        .labeledInputContainer {
          width: 100%;
          .ant-picker {
            width: 100%;
          }
        }
      }
    }
  }
  .payroll-edit-line-modal {
    .ant-modal-content {
      .ant-modal-body {
        .labeledInputContainer {
          width: 100%;
          .ant-picker {
            width: 100%;
          }
        }
      }
    }
  }
}

@keyframes showGridActions {
  0% {
    transform: translateY(-32px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes hideGridActions {
  0% {
    transform: translateY(0px);
    opacity: 1;
  }
  100% {
    transform: translateY(-32px);
    opacity: 0;
  }
}
