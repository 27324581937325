.viewsInfo {
  .ant-modal-content {
    .ant-modal-close {
      margin-right: 6px;
    }
    .ant-modal-header {
      background-color: #f8f8fa;
      padding: 10px 24px;
    }
    .ant-modal-body {
      .body {
        p {
          font-size: 14px;
          font-weight: 700;
        }
      }
    }

    .ant-modal-footer {
      display: flex;
      justify-content: flex-end;
    }
  }
}

.viewsInfo-dark {
  .ant-modal-content {
    background-color: #12131b;
    .ant-modal-close-x {
      svg {
        fill: #fff;
      }
    }
    .ant-modal-header {
      background-color: #20212d;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      .body {
        p {
          color: #fff;
        }

        ul {
          li {
            color: #fff;
          }
        }
      }
    }
  }
}
