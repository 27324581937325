.estimates_values_wrapper {
    display: flex;
    flex-direction: column;
}

.estimation_title .ant-btn {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    background-color: #1264a3 !important;
    padding: 0;
} 

.estimation_title .ant-btn:focus {
    border-color: transparent;
}

.est_price_wrapper .anticon-plus {
    font-size: 0.6em !important;
}

.estimation_title {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    height: 40px;
}

.estimation_title h4 {
    margin: 0 6px;
    font-size: 1rem;
    font-weight: 700;
}

.estimation_title .est_horizontal_line hr {
    width: 60px;
    height: 2px;
    background-color: #71cf48;
    margin: 0;
}

.estimation_title .est_price_wrapper {
    display: flex;
    align-items: center;
    color: #71cf48;
    font-weight: bold;
    margin-left: 10px;
    font-size: 1.15rem;
}

.services_wrapper_show {
    height: auto;
    max-height: 9999px;
    /* transition: max-height 1s ease-in-out; */
}

.services_wrapper_hidden {
    max-height: 0;
    overflow: hidden;
    /* transition: max-height 1s ease-in-out; */
}