@use "src/index.scss" as index;

.analytic-shift-view {
  height: calc(100vh - 20px) !important;
  width: calc(100vw - 20px) !important;
  max-height: 800px;
  max-width: 1500px;
  .ant-modal-content {
    max-height: 800px;
    max-width: 1500px;
    height: calc(100vh - 20px);
    width: calc(100vw - 20px);
    .ant-modal-close {
      height: 45px;
      width: 45px;
      .ant-modal-close-x {
        height: 45px;
        width: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .ant-modal-header {
      height: 45px;
      display: flex;
      align-items: center;
      background-color: #f8f8fa;
    }
    .shift-data {
      grid-area: shift-info;
    }
    .selected-entry-data {
      grid-area: entry-info;
    }
    .google-map {
      grid-area: map;
    }
    .ant-modal-body {
      height: calc(100% - 100px);
      display: grid;
      grid-template-areas: "shift-info map map map" "entry-info map map map";
      gap: 10px;
      .shift-data,
      .selected-entry-data {
        background-color: #f8f8fa;
        border-radius: 10px;
        padding: 10px 20px;
        .info-header {
          font-size: 16px;
          font-weight: 600;
        }
        .info-body {
          .info-data {
            display: flex;
            gap: 10px;
            label {
              font-weight: 600;
            }
            .clickable-data {
              color: index.$blue;
              cursor: pointer;
            }
          }
        }
      }
      .drawing-buttons {
        position: absolute;
        top: 10px;
        left: 185px;
        .draw-button {
          height: 40px;
          display: flex;
          align-items: center;
          border-radius: 2px;
          border: none;
          font-size: 17px;
          font-weight: 600;
          gap: 5px;
          color: #000;
        }
      }
      .google-map {
        border-radius: 10px;
        overflow: hidden;
        .map-analytic-view {
          height: 100%;
          width: 100%;
          max-width: none !important;
          border-radius: 10px;
          .gm-style-iw {
            width: 40px !important;
            padding: 5px !important;
            .gm-style-iw-d {
              padding: 0px;
              overflow: hidden !important;
              text-align: center;
            }
            .gm-style-iw-chr,
            .gm-ui-hover-effect {
              display: none !important;
            }
          }
          .gm-style-iw-c,
          .gm-style-iw-tc {
            top: -34px;
          }
        }
      }
    }
    .ant-modal-footer {
      display: flex;
      align-items: center;
      justify-content: start;
      flex-direction: row;
      flex-wrap: nowrap;
      gap: 12px;
      width: 100%;
      .mondayButtonStyle {
        width: 150px;
      }
    }
  }
}

.analytic-shift-view.analytic-shift-view-dark {
  .ant-modal-content {
    background-color: index.$mainDarkBackgroundColor;
    .ant-modal-close .ant-modal-close-x svg {
      fill: #fff !important;
    }
    .ant-modal-header {
      background-color: index.$cardDarkBackgroundColor;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      .shift-data,
      .selected-entry-data {
        background-color: index.$cardDarkHeaderColor;
        .info-header {
          color: #fff;
        }
        .info-body {
          color: #fff;
        }
      }
    }
  }
}
