@import "../../../Communication.scss";

.email-box-footer {
    display: flex;
    justify-content: space-between;
    padding: 8px;
    height: 60px;
    position: relative;

    .left {
        display: flex;
        align-items: center;
        gap: 20px;



        .ant-upload-list-item-card-actions-btn {
            opacity: 1;
        }

        .send-email-button {
            width: 100px;
            background-color: #1a73e8;

            &:hover,
            &:focus {
                background-color: #1a73e8e5 !important;
            }

            &:disabled {
                background-color: #1a73e8ce !important;
            }

            .mondayButtonDivider {
                display: none;
            }

            @media only screen and (max-width: 767px) {
                .mondayButtonText {
                    display: block;
                }
            }
        }

    }

    .right {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        margin-right: 10px;

        .documentsStyle {
            cursor: pointer;
            margin-right: 20px;
            user-select: none;
            margin-top: 5px;

            &:active {
                background-color: rgba(32, 33, 36, 0.122);
                border-radius: 5px;
            }
        }

        .removeIcon {
            cursor: pointer;
            margin-right: 20px;
        }
    }

    &.dark-mode {
        svg {
            fill: $white !important;
            color: $white !important;
        }

        .ant-upload-list-item-name {
            color: $white !important;
        }
    }
}