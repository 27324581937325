@import "../../../../../../index.scss";

.tableOptionBody {
  overflow-y: scroll;
  padding-left: 10px;
  height: 400px;
  width: 100%;
}
.tableOptionBodyDark {
  h3 {
    color: #fff;
  }
}
