@media print {
  body {
    // width: auto !important;
    display: flex;

    .printable-modal__body {
      // width: 80%;
      scale: 0.8;
      // background-color: red;
    }

    justify-content: center;
    font-size: 24px !important ;
    // padding: 10px;
    p,
    strong {
      font-size: 22px !important;
    }
    .serviceParent__takeoffPreview {
      border: 2px solid #a6ece0 !important;
      padding: 20px;
    }
    .elevation_note_takeoff_alert {
      display: flex;
      justify-content: center;
      align-items: center;
      //   height: 100%;
      p {
        margin: 0 !important;
      }
    }
    .takeoff_preview__title {
      display: flex;
      align-items: center;
      height: "10px";
      gap: 10px;
      margin-bottom: 10px;
      .circleshape {
        width: 11px;
        height: 11px;
        border-radius: 50%;
        // margin: 0 11px 4px 3px;
        display: inline-block;
        // background: red;
        display: flex;
        align-items: center;
      }
      l .serviceIndic__takeoff_preview {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background-color: blue;
      }
    }
    .address_preview_takeoff {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      display: flex;
      justify-content: center;
      color: #323338;
    }
    div {
      .address_preview_takeoff {
        p {
          color: red !important;
        }
        color: red !important;
      }
    }
  }

  .printable-area {
    background-color: blue;
  }
}
