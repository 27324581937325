$cardModalHeaderDark: #12131b;
$cardModalBodyDark: #1f212d;
$inputModalDark: #12131b;

.schedule-settings-dialog {
  .ant-modal-header {
    width: 100%;
    min-width: 1000px;
    height: 45px;
    background: #f8f8fa;
    border-radius: 10px 10px 0px 0px;
    display: flex;
    align-items: center;
  }
  .ant-modal-title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #323338;
  }
  .ant-modal-content {
    width: fit-content;
    height: fit-content;
    min-width: 1000px;
    background: #ffffff;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
  }

  .ant-modal-body {
    height: fit-content;
    max-height: 800px;
    overflow-y: auto;
  }
  .ant-modal-close-x {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: #323338;
  }
  .infoPiece {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 0.5rem;
  }
  .servicesContentData {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    width: fit-content;
    min-width: 1000px;
    overflow: auto;
    .settingsScheduleContent {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 1rem;
    }

    .estimationApplyapplied {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 0.5rem;
      width: 190px;
      height: 50px;
      border-radius: 10px;
      background-color: #e0e0e1;
      padding: 10px;

      cursor: pointer;
      .estNrSettings {
        display: flex;
        gap: 0.3rem;
      }
    }

    .estimationApply {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 0.5rem;
      width: 190px;
      height: 50px;
      border-radius: 10px;
      background-color: #f8f8fa;
      padding: 10px;

      cursor: pointer;
      .estNrSettings {
        display: flex;
        gap: 0.3rem;
      }
    }
    .servicesContentSettings {
      display: flex;
      align-items: center;
      width: 1000px;
      height: 50px;
      overflow-x: auto;
      border-radius: 10px;
      background-color: #f8f8fa;
      flex-direction: row;
      gap: 0.5rem;
      padding: 0px 10px 0px 10px;
    }
    .ant-badge-count {
      cursor: pointer;
      z-index: 1000;
    }
    .servicesButtonsSettings {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .serviceLabelSettingsapplied {
      display: flex;
      align-items: center;
      width: 180px;
      height: 32px;
      background: #e0e0e1;
      .checkSettings {
        width: 8px;
        height: 100%;
        background-color: #f15e1c;
      }
      .labelIconContent {
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding: 10px;
        cursor: pointer;
      }
    }
    .serviceLabelSettings {
      display: flex;
      align-items: center;
      width: 180px;
      height: 32px;
      background: #ffffff;

      .checkSettings {
        width: 8px;
        height: 100%;
        background-color: #f15e1c;
      }
      .labelIconContent {
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding: 10px;
        cursor: pointer;
      }
    }
  }
  .animCheck {
    height: 45px;
    width: 420px;
  }
  .ftBt {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.schedule-settings-dialog-dark {
  .ant-modal-content {
    background-color: $cardModalBodyDark;
    .ant-modal-close-x svg {
      fill: #fff;
    }
    .ant-modal-header {
      background-color: $cardModalHeaderDark;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      background-color: $cardModalBodyDark;
      .servicesContentData {
        .settingsScheduleContent {
          .estimationApply {
            background-color: $cardModalHeaderDark;
            .estNrSettings {
              color: #fff;
            }
            path {
              fill: #fff;
            }
          }
          .estimationApplyapplied {
            background-color: #323338;
            .estNrSettings {
              color: #acacca;
            }
            path {
              fill: #acacca;
            }
          }
          .servicesContentSettings {
            background-color: $cardModalHeaderDark;
            .ant-badge-count {
              box-shadow: 0 0 0 1px $cardModalHeaderDark;
            }
            .serviceLabelSettings {
              background-color: $cardModalBodyDark;
              span {
                color: #fff;
                path {
                  fill: #fff;
                }
              }
            }
            .serviceLabelSettingsapplied {
              background-color: #323338;
              span {
                color: #acacca;
                path {
                  fill: #acacca;
                }
              }
            }
          }
        }
      }
      .infoPiece {
        color: #fff;
      }
    }
  }
}
