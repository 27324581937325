@import "../../../../../Communication/Communication.scss";








.dynamic-fields-icon {
  fill: $icon-color;
  color: $icon-color;
}




.dropdown-item-button {
  width: 100%;
  display: flex;
  justify-content: start;

  span {
    color: $light-text-color;
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: start;
  }

  &:hover {
    span {
      color: inherit;
    }
  }
}

.ant-dropdown-menu.ant-dropdown-menu-sub {
  max-height: 300px;
  width: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  right: 10%;
}