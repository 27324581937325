@import "../../../Communication.scss";

.footer-buttons-container {
    display: flex;
    gap: 20px;
    padding: 20px;
    padding-left: 70px;

    @media screen and (max-width: 768px) {
        padding-left: 10px;
    }

    padding: 20px;
    padding-left: 70px;

    @media screen and (max-width: 768px) {
        padding-left: 10px;
    }

    .reply-btn,
    .forward-btn {
        width: 150px;
        width: 150px;
        margin: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;
        border-radius: 5px;
        cursor: pointer;
        color: $icon-color;
        background-color: #f5f5f7;
        height: 32px;
        box-shadow: none;

        @media screen and (max-width: 768px) {
            width: 130px;

            .mondayButtonText,
            .mondayButtonDivider {
                display: block;
            }
        }

        box-shadow: none;

        @media screen and (max-width: 768px) {
            width: 130px;

            .mondayButtonText,
            .mondayButtonDivider {
                display: block;
            }
        }

        &:hover,
        &:active,
        &:visited,
        &:focus {
            color: rgba(0, 0, 0, 0.5) !important;
            background-color: #f5f5f7 !important;
            border-color: none;

            .mondayButtonDivider {
                background-color: #e6e9ef;

                background-color: #e6e9ef;
            }
        }

        .mondayButtonDivider {
            background-color: #e6e9ef;
        }

        svg {
            fill: $icon-color;
        }

        &.cursor-not-allowed {
            cursor: not-allowed;
        }

        .footerButtonLabel {
            margin-left: 10px;
            font-family: "Google Sans", Roboto, RobotoDraft, Helvetica, Arial,
                sans-serif;
            font-size: 0.875rem;
            letter-spacing: 0.25px;
            background: none;
            border-radius: 4px;
            box-sizing: border-box;
            font-weight: 500;
        }
    }

    &.dark-mode {

        .reply-btn,
        .forward-btn {
            color: $white;
            background-color: $inputDarkColor;

            &:hover,
            &:active,
            &:visited,
            &:focus {
                color: $white !important;
                opacity: 0.8;
                background-color: $inputDarkColor;
                border-color: none;

                .mondayButtonDivider {
                    background-color: #e6e9ef;

                    background-color: #e6e9ef;
                }
            }

            .mondayButtonDivider {
                background-color: #e6e9ef;
            }

            svg {
                fill: $white;
            }
        }
    }
}