@import "../../../../../../../../index.scss";

.pricing-row-info {
  @include flex(center, flex-start);
  gap: 20px;
  background-color: #f8f8fa;
  border-radius: 5px;
  padding: 10px;

  .config-item-container {
    min-width: 200px;
  }

  .pricing-row-section {
    @include flex(center, space-between);
    min-width: 200px;
    gap: 10px;

    .pricing-section-label {
      @include text(16px, 600, 0, #323338, capitalize);
    }
    .pricing-section-value {
      @include text(16px, 400, 0, #323338, capitalize);
    }
  }

  .ant-checkbox-disabled + span {
    @include text(16px, 600, 0, #323338, capitalize);
  }

  .input-divider {
    height: 30px;
    width: 1px;
    background-color: #323338;
  }
}
