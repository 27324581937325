$backgroundDark: #12131b;
$inputDark: #3a3c4e;
$darkModeWhite: #f4f5f6;

.proposal-header-filter-wrapper {
  gap: 20px;
  width: 100%;
  height: 52px;
  padding: 10px;
  display: flex;
  color: #231f20;
  overflow-x: auto;
  align-items: center;
  border-radius: 10px;
  background-color: #fff;
  justify-content: space-between;
  box-shadow: 0px 1px 4px #00000029;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;

  .proposal-header-left,
  .proposal-header-right {
    gap: 10px;
    display: flex;
  }
  @media screen and (min-width: 1024px) {
    .mondayButtonContainer .mondayButtonGreen {
      width: 150px !important;
    }
  }
  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  @media screen and (max-width: 780px) {
    overflow-y: hidden;
  }
}
.proposal-header-filter-wrapper.dark {
  color: $darkModeWhite;
  background-color: $backgroundDark;
  .mondayButtonGrey {
    background-color: $inputDark !important;
    svg {
      fill: $darkModeWhite;
      path {
        fill: $darkModeWhite;
      }
    }
  }
  .ant-select {
    border-radius: 5px;
    background-color: $inputDark !important;
    .ant-select-selector {
      background-color: $inputDark !important;
      .ant-select-selection-placeholder {
        z-index: 10;
      }
    }
  }
  .labeledInputContainer {
    background-color: $inputDark !important;
    border-radius: 5px;
    .ant-input-affix-wrapper {
      background-color: $inputDark !important;
    }
    input {
      background-color: $inputDark !important;
    }
  }
}
