.signature-builder {
  gap: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  .ant-divider {
    margin: 0;
  }
  .single-sign {
    gap: 10px;
    height: 50px;
    display: flex;
    align-items: center;
    img {
      width: 100%;
      height: 100%;
      cursor: drag;
      border-radius: 5px;
      object-fit: contain;
      border: 1px solid #1264a3;
      background-color: #f4f5f6;
    }
    .delete-sign {
      width: 40px;
      height: 100%;
      display: grid;
      cursor: pointer;
      place-items: center;
      transition: all 0.3s ease;
      &:hover {
        svg {
          fill: #fe4c4a;
        }
      }
    }
  }
}
