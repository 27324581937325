$cardModalHeaderDark: #12131b;

.container {
  padding: 20px;
  background: #1264a3 0% 0% no-repeat padding-box;
  border-radius: 5px;
  margin: 20px;
  min-height: 62px;

  .header {
    position: relative;
    cursor: pointer;
    user-select: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 20px;
    > h2 {
      margin: 0;
      font-weight: 600;
      font-size: 12px;
      font: normal normal 600 16px/22px Open Sans;
      letter-spacing: 0px;
      color: #ffffff;
      height: fit-content;
    }
    > button {
      color: #393a3d;
      transform: rotate(270deg);
      margin-top: -8px;
      border: 0;
      background: transparent;
      font-size: 13px;
      padding: 0;
      margin-top: 2px;
    }
  }
  .expended {
    transform: rotate(0deg) !important;
  }
}
.clicked {
  background: #f8f8fa 0% 0% no-repeat padding-box;
  .header {
    h2 {
      color: #323338 !important;
      font-weight: 600 !important;
    }
  }
}

.container > .header {
  > h2 {
    color: white;
    font-weight: 500;
  }
}

.containerDark {
  @extend .container;
}

.clickedDark {
  @extend .clicked;
  background-color: $cardModalHeaderDark;
  .header h2 {
    color: #fff !important;
  }
  .main {
    span,
    .address {
      color: #fff;
    }
  }
}
