@import "../../../../../index.scss";

$mainDarkBackgroundColor: #20212d;
$cardDarkHeaderColor: #2a2b3a;
$cardDarkBackgroundColor: #12131b;
$inputDarkColor: #2a2b3a;

.general-reports-wrapper {
  @include flex(stretch, flex-start, column);
  width: 100%;
  height: 100%;
  gap: 10px;
  padding: 10px;

  .smallLoadingDiamonds {
    top: 4.4%;
    left: 46%;
    height: 34px;
    width: 34px;
  }
}

@media only screen and (max-width: 450px) {
  .general-reports-filters {
    display: grid !important;
    grid-template-columns: 100% !important;
  }
  .general-reports-filters .filters-grid {
    display: grid !important;
    grid-template-columns: 100% !important;
  }
}

.general-reports-wrapper-dark {
  .general-reports-filters {
    background-color: $cardDarkBackgroundColor;
  }
  .general-reports-container {
    background-color: $cardDarkBackgroundColor;
    .reportPreviewCardWrapperDark {
      .reportPreviewCardContainer {
        background-color: $mainDarkBackgroundColor;
        box-shadow: 0px 2px 4px rgba(255, 255, 255, 0.4);
        .previewHeader,
        .previewContent {
          background-color: $mainDarkBackgroundColor;
          .reportMetadata {
            background-color: $mainDarkBackgroundColor;
          }
        }
      }
    }
  }
}
