@import "../../../../../../../../index.scss";

.addons-preview-card {
  flex: 1;
  border-radius: 5px;
  border: 1px solid #dfe3ec;

  .addons-header {
    @include flex(center, flex-start);
    @include text(16px, 600, 0, #323338);
    height: 45px;
    padding: 0 20px;
    width: stretch;
    border-radius: 5px 5px 0 0;
    background-color: #f7f8fa;
  }

  .addons-content {
    width: stretch;
    padding: 20px;

    .addon-price-preview {
      @include flex(center, space-between);
    }
  }

  .addons-footer {
    @include flex(center, flex-end);
    @include text(14px, 400, 0, #323338);
    height: 45px;
    width: stretch;
    padding: 0 20px;
    display: flex;
    gap: 10px;
    background-color: #f7f8fa;
    border-radius: 0 0 5px 5px;
  }
}
