.addon-progress-modal {
  .ant-modal-header {
    background-color: #f5f5f5;
    border-bottom: 1px solid #e8e8e8;
    width: 720px;
    border-radius: 10px 10px 0px 0px;
  }
  .ant-modal-title {
    display: flex;
    align-items: center;
    color: #323232;
    font-weight: 600;
  }
  .ant-modal-content {
    width: 720px;
    height: 500px;
    border-radius: 10px;
  }
  .ant-modal-close-x {
    width: 45px;
    height: 45px;
    color: #323232;
  }
  .ant-modal-body {
    height: -webkit-fill-available;
    padding: 0;
    .container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 24px;
      height: -webkit-fill-available;
      margin-bottom: 45px;
      .tableContainer {
        display: flex;
        justify-content: space-around;
        height: -webkit-fill-available;
        max-height: -webkit-fill-available;
        margin-bottom: 20px;
        overflow: auto;
        table {
          height: fit-content;
          max-height: -webkit-fill-available;
          overflow-y: auto;
        }
      }
    }
  }
  .addon-progress-input {
    .ant-form-item {
      margin-bottom: 0px;
    }
  }
}
